import React from "react";
import styles from "./styles.module.scss";

const FormControls = ({ children, style, className }) => {
  return (
    <div style={style} className={`${styles.footer} ${className}`}>
      {children}
    </div>
  );
};

export default FormControls;
