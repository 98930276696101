import React, { useCallback } from "react";
import Button from "antd/es/button";
import dayjs from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import { updateDetail } from "Actions/dataActions";
import { openForm } from "Actions/formActions";
import Alert from "antd/es/alert";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import Modal from "antd/es/modal";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";
import message from "antd/es/message";
import api from "API";

dayjs.extend(isSameOrAfter);

const infoColor = "#e6f7ff";
const infoBorder = "1px solid #91d5ff";
const errorColor = "#ffccc7";
const errorBorder = "1px solid #f97467";

const EstimateStart = ({
  estimateStart,
  _id,
  projectManager,
  estimateEventId,
}) => {
  const dispatch = useDispatch();
  const viewableActivities = useSelector(
    (state) => state.dataState.viewableActivities
  );

  const openSchedule = useCallback(() => {
    dispatch(
      openForm("scheduleEstimate", {
        _id,
        estimateStart,
        projectManager,
        estimateEventId,
      })
    );
  }, [dispatch, _id, estimateStart, projectManager, estimateEventId]);

  const confirmCancel = () => {
    Modal.confirm({
      icon: <QuestionCircleOutlined />,
      centered: true,
      cancelText: "Close",
      okText: "Confirm",
      okButtonProps: { danger: true },
      content: <div>Are you sure you want to cancel this appointment?</div>,
      onOk() {
        return new Promise((resolve, reject) => {
          cancelAppointment(() => resolve(true));
        }).catch((err) => console.log("err: ", err));
      },
      onCancel() {},
    });
  };

  const cancelAppointment = async (cb) => {
    try {
      const res = await api.post(`/estimates/cancel-appointment`, { _id });
      dispatch(
        updateDetail({
          viewableActivities,
          key: "estimateStart",
          value: res.data.value,
          activities: res.data.activities,
        })
      );
      cb();
    } catch (err) {
      console.log("cancelAppointment err", err);
      message.error("Cancelling estimate failed");
    }
  };

  return (
    <Alert
      type={dayjs().isSameOrAfter(dayjs(estimateStart)) ? "error" : "info"}
      style={{
        marginBottom: "1em",
        backgroundColor:
          !estimateStart || dayjs().isSameOrAfter(dayjs(estimateStart))
            ? errorColor
            : infoColor,
        border:
          !estimateStart || dayjs().isSameOrAfter(dayjs(estimateStart))
            ? errorBorder
            : infoBorder,
      }}
      message={
        <>
          <h4
            style={{ textTransform: "capitalize", marginBottom: 3 }}
            // className="withIcon"
          >
            Estimate Scheduled For:
          </h4>
          <p>
            {estimateStart
              ? dayjs(estimateStart).format("dddd, MMM D h:mm A")
              : "Not scheduled"}
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              type="primary"
              size="small"
              block={estimateStart ? false : true}
              onClick={openSchedule}
            >
              {estimateStart ? "Re-Schedule" : "Schedule"}
            </Button>
            {estimateStart && (
              <Button type="danger" size="small" onClick={confirmCancel}>
                Cancel
              </Button>
            )}
          </div>
        </>
      }
    ></Alert>
  );
};

export default EstimateStart;
