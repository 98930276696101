Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _createSvg = require("./utils/createSvg");

var _createSvg2 = _interopRequireDefault(_createSvg);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

exports.default = (0, _createSvg2.default)(
  _react2.default.createElement(
    "g",
    null,
    _react2.default.createElement("path", {
      d:
        "M12,10L8,14H11V20H13V14H16M19,4H5C3.89,4 3,4.9 3,6V18A2,2 0 0,0 5,20H9V18H5V8H19V18H15V20H19A2,2 0 0,0 21,18V6A2,2 0 0,0 19,4Z"
    })
  ),
  "OpenInBrowser"
);
