import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openForm } from "Actions/formActions";
import List from "antd/es/list";
import currencyFormatter from "Utils/currencyFormatter";
import dayjs from "dayjs";
import styles from "./styles.module.scss";
import Button from "antd/es/button";
import Card from "antd/es/card";
import Eye from "Icons/Eye";
import Email from "Icons/Email";
import Printer from "Icons/Printer";
import Receipt from "Icons/Receipt";
import openInNewTab from "Utils/openInNewTab";
import printJS from "print-js";
import Modal from "antd/es/modal";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";

const Collections = ({ collections, remainingBalance }) => {
  return (
    <>
      {collections && collections.length > 0 ? (
        collections.map((collection) => (
          <List
            key={collection._id}
            bordered
            itemLayout="horizontal"
            className={styles.list}
          >
            <CollectionsItem collection={collection} />
          </List>
        ))
      ) : (
        <Card
          bordered={false}
          className={styles.collection}
          bodyStyle={{ padding: 0 }}
        >
          <List
            bordered={false}
            itemLayout="horizontal"
            className={styles.list}
          >
            <List.Item className={`${styles.transactionList} ${styles.none}`}>
              <List.Item.Meta
                avatar={
                  <div className={styles.avatar}>
                    <Receipt color="#108ee9" />
                  </div>
                }
                title="No collections have been logged"
              />
            </List.Item>
          </List>
        </Card>
      )}

      <CollectionsTotal remainingBalance={remainingBalance} />
    </>
  );
};

const CollectionsItem = ({ collection }) => {
  const dispatch = useDispatch();
  const admin = useSelector((state) => state.authState.admin);
  const role = useSelector((state) => state.authState.role);

  const openCollection = (e) => {
    e.stopPropagation();
    openInNewTab(collection.src);
  };

  const printCollection = (e) => {
    e.stopPropagation();
    printJS({
      printable: `${collection.src}?${new Date().getTime()}`,
      type: "pdf",
    });
  };

  const openEmail = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(
        openForm("email", {
          content: [
            {
              _id: collection.src,
              src: collection.src,
              _projectId: collection._projectId,
              type: "receipt",
            },
          ],
        })
      );
    },
    [dispatch, collection.src, collection._projectId]
  );

  const editCollectionOrEditReceipt = () => {
    Modal.confirm({
      zIndex: 4002,
      icon: <QuestionCircleOutlined />,
      centered: true,
      cancelText: "Edit Receipt",
      // cancelButtonProps: { type: "primary" },
      okText: "Edit Collection",
      maskClosable: true,
      content: (
        <div>Do you want to Edit the Collection or Edit the Receipt?</div>
      ),
      onOk() {
        return new Promise((resolve, reject) => {
          editCollection();
          resolve(true);
        }).catch((err) => console.log("err: ", err));
      },
      onCancel() {
        return new Promise((resolve, reject) => {
          editReceipt();
          resolve(true);
        }).catch((err) => console.log("err: ", err));
      },
    });
  };

  const editCollection = useCallback(
    (e) => {
      if (e) e.stopPropagation();
      dispatch(openForm("collection", { ...collection, editing: true }));
    },
    [dispatch, collection]
  );

  const editReceipt = useCallback(
    (e) => {
      if (e) e.stopPropagation();
      dispatch(openForm("receipt", { ...collection, editing: true }));
    },
    [dispatch, collection]
  );

  return (
    <List.Item
      // onClick={
      //   admin === "Yes" || role === "Office" ? editCollection : undefined
      // }
      onClick={
        admin === "Yes" || role === "Office"
          ? editCollectionOrEditReceipt
          : editReceipt
      }
      className={styles.transactionList}
    >
      <List.Item.Meta
        avatar={
          <div className={styles.avatar}>
            <Receipt color="#108ee9" />
          </div>
        }
        title={dayjs(collection.transactionDate).format("M/D/YY")}
        description={currencyFormatter(collection.amount)}
      />
      {collection.src && (
        <>
          <Button
            onClick={openCollection}
            shape="circle"
            type="link"
            style={{ marginLeft: 8 }}
          >
            <Eye />
          </Button>
          <Button
            onClick={openEmail}
            shape="circle"
            type="link"
            style={{ marginLeft: 8 }}
          >
            <Email />
          </Button>
          <Button
            onClick={printCollection}
            shape="circle"
            type="link"
            style={{ marginLeft: 8 }}
          >
            <Printer />
          </Button>
        </>
      )}
    </List.Item>
  );
};

const CollectionsTotal = ({ remainingBalance }) => {
  // const remainingBalance = useSelector(
  //   (state) => state.dataState.remainingBalance
  // );

  return (
    <List bordered itemLayout="horizontal" className={styles.list}>
      <List.Item
        className={`${styles.transactionList} ${styles.none} ${styles.totalRow}`}
      >
        <List.Item.Meta title="Remaining Balance" />

        <List.Item.Meta
          style={{ textAlign: "right" }}
          title={currencyFormatter(remainingBalance)}
        />
      </List.Item>
    </List>
  );
};

export default Collections;
