const scopes = {
  "Guaranteed Repair": {
    initials: "GR",
    color: "#000000",
    permitTrigger: "status",
  },
  Gutters: {
    initials: "G",
    color: "#2eb82e",
    permitTrigger: "status",
  },
  Other: {
    initials: "O",
    color: "#ff00ff",
    permitTrigger: "status",
  },
  "Labor Only": {
    initials: "LO",
    color: "#55d4d2",
    permitTrigger: "status",
  },
  "Roofing - Flat": {
    initials: "RF",
    color: "#a6a6a6",
    permitTrigger: "woCreated",
  },
  "Roofing - Steep": {
    initials: "RS",
    color: "#0066ff",
    permitTrigger: "woCreated",
  },
  "Roof - Flat": {
    initials: "RF",
    color: "#a6a6a6",
    permitTrigger: "woCreated",
  },
  "Roof - Steep": {
    initials: "RS",
    color: "#0066ff",
    permitTrigger: "woCreated",
  },
  Siding: {
    initials: "S",
    color: "#ff8c1a",
    permitTrigger: "woCreated",
  },
  Windows: {
    initials: "W",
    color: "#b366ff",
    permitTrigger: "status",
  },
  "Chimney Repair": {
    initials: "CR",
    color: "#00dede",
    permitTrigger: "status",
  },
};

export default scopes;
