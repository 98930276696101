import React from "react";
import Typography from "antd/es/typography";
import Carousel from "antd/es/carousel";
import styles from "./styles.module.scss";

import wimsattLogo from "Assets/wimsatt_logo.png";
import abcLogo from "Assets/abc_logo.png";
import certainteedLogo from "Assets/certainteed_logo.png";
import atlasLogo from "Assets/atlas_logo.png";
import beaconLogo from "Assets/beacon-logo.png";
import gulfEagleLogo from "Assets/gulf-eagle-logo.png";
import sunriseWindowLogo from "Assets/sunrise-window-logo.svg";

const Partners = () => {
  return (
    <div className={styles.container}>
      <Typography.Text className={styles.offWhite} type="secondary">
        PARTNERS
      </Typography.Text>
      <div className={styles.partners}>
        <Carousel autoplay={true} dots={false}>
          <div className={styles.slide}>
            <img src={atlasLogo} alt="atlas-logo" />
          </div>
          <div className={styles.slide}>
            <img src={certainteedLogo} alt="certainteed-logo" />
          </div>
          <div className={styles.slide}>
            <img src={abcLogo} alt="abc-logo" />
          </div>
          <div className={styles.slide}>
            <img src={wimsattLogo} alt="wimsatt-logo" />
          </div>
          <div className={styles.slide}>
            <img src={beaconLogo} alt="beacon-logo" />
          </div>
          <div className={styles.slide}>
            <img src={gulfEagleLogo} alt="gulf-eagle-logo" />
          </div>
          <div className={styles.slide}>
            <img src={sunriseWindowLogo} alt="sunrise-window-logo" />
          </div>
        </Carousel>
      </div>
    </div>
  );
};

export default Partners;
