import React from "react";
import HighCharts from "highcharts";
import HighChartsReact from "highcharts-react-official";
import Card from "antd/es/card";
import styles from "./styles.module.scss";
import Skeleton from "antd/es/skeleton";

const BacklogReport = ({ options, title, loading }) => {
  return (
    <Card
      bodyStyle={{ padding: 12, height: 345 }}
      className="z-depth-1"
      style={{ overflow: "hidden", width: "100%" }}
      bordered={false}
    >
      <div className={styles.reportTitle}>{title}</div>
      <div className={styles.reportContainer}>
        <Skeleton
          loading={loading}
          active
          paragraph={{ rows: 0 }}
          title={{ width: "100%" }}
        >
          <HighChartsReact
            highcharts={HighCharts}
            options={options}
            updateArgs={[true, true, true]}
          />
        </Skeleton>
      </div>
    </Card>
  );
};

export default BacklogReport;
