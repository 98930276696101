import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signin, init, clearErrorMessage } from "Actions/authActions";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Button from "antd/es/button";
import Typography from "antd/es/typography";
import Card from "antd/es/card";
import message from "antd/es/message";
import Email from "Icons/Email";
import Lock from "Icons/Lock";
import Logo from "Assets/logo-200x96.png";

const { Title } = Typography;

const Signin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const errorMessage = useSelector((state) => state.authState.errorMessage);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    checkSignin();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function checkSignin() {
    try {
      const token = localStorage.getItem("storyToken");
      if (token) {
        const res = dispatch(init(token));
        if (res) navigate("/dashboard");
      }
    } catch (err) {
      console.log("err", err);
    }
  }

  async function onFinish(values) {
    try {
      setLoading(true);
      dispatch(clearErrorMessage());
      values.cb = () => navigate("/dashboard");
      values.failCb = () => setLoading(false);
      dispatch(signin(values));
      // if (res) {
      //   return navigate("/dashboard");
      // } else {
      //   setLoading(false);
      // }
    } catch (err) {
      setLoading(false);
      console.log("signin err", err);
    }
  }

  function onFinishFailed(errorInfo) {
    setLoading(false);
    message.error("All fields must be completed");
  }

  const gotToForgotPassword = () => {
    navigate("/forgot-password");
  };

  return (
    <Row
      type="flex"
      justify="center"
      align="middle"
      style={{ height: "100%", position: "absolute", width: "100%" }}
      className="public-background"
    >
      <Col xs={22} sm={20} md={16} lg={14} xl={12} className="text-center">
        <Row type="flex" justify="center">
          <Col xs={24}>
            <img
              src={Logo}
              alt="story-logo"
              style={{
                display: "block",
                margin: "auto",
                marginBottom: 24,
                width: 200,
              }}
            />
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col style={{ maxWidth: 353 }}>
            <Card>
              <Row type="flex" justify="center">
                <Col xs={24}>
                  <Title
                    level={3}
                    style={{ textAlign: "center", color: "#1890ff" }}
                  >
                    Sign in to get started!
                  </Title>
                </Col>
              </Row>
              <Form
                hideRequiredMark={true}
                colon={false}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
              >
                <Row type="flex" justify="center">
                  <Col xs={24}>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Email!",
                        },
                        {
                          type: "email",
                          message: "Must be a valid Email",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Email"
                        prefix={<Email size={16} type="email" />}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row type="flex" justify="center">
                  <Col xs={24}>
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Password!",
                        },
                      ]}
                    >
                      <Input.Password
                        placeholder="Password"
                        prefix={<Lock size={16} />}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item name="city" className="hidden">
                  <Input />
                </Form.Item>
                {errorMessage && (
                  <div style={{ color: "#ff7056", marginBottom: 12 }}>
                    {errorMessage}
                  </div>
                )}
                <Row type="flex" justify="center">
                  <Col xs={24}>
                    <Form.Item style={{ marginBottom: 0 }}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        block
                        loading={loading}
                        disabled={loading}
                      >
                        Sign in
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
            <Row type="flex" justify="center">
              <Col xs={24} className="text-center">
                <Button
                  style={{ marginTop: 16 }}
                  block
                  type="link"
                  onClick={gotToForgotPassword}
                >
                  Forgot password?
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Signin;
