import React from "react";
import Card from "antd/es/card";
import Statistic from "antd/es/statistic";
import currencyFormatter from "Utils/currencyFormatter";
import OpenInNew from "Icons/OpenInNew";
import styles from "./styles.module.scss";

const StatisticCard = ({ handleClick, title, value, currency = false }) => {
  return (
    <Card
      className="z-depth-1"
      style={{
        overflow: "hidden",
        // background: color,
        marginBottom: 16,
        position: "relative",
      }}
      bordered={false}
      hoverable={handleClick ? true : false}
      onClick={handleClick}
    >
      {handleClick && (
        <OpenInNew
          style={{
            position: "absolute",
            color: "#108ee9",
            fill: "#108ee9",
            fontSize: 18,
            height: 18,
            width: 18,
            top: 4,
            right: 4,
          }}
        />
      )}

      <Statistic
        className={styles.statisticTitle}
        title={title}
        value={currency ? currencyFormatter(value) : value}
      />
    </Card>
  );
};

export default StatisticCard;
