import React, { memo, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelected } from "Actions/dataActions";
import { openForm } from "Actions/formActions";
import List from "antd/es/list";
import Tag from "antd/es/tag";
import dayjs from "dayjs";
import styles from "./styles.module.scss";
import scopes from "Constants/scopes";

const statusObj = {
  Pulled: "#00c292",
  Needed: "#108ee9",
  "Not Needed": "#999999",
  Undetermined: "#ff7056",
};

const PermitsItem = ({ item, style, mobile, headers }) => {
  const selectedRow = useSelector((state) => state.dataState.selectedRow);
  const dispatch = useDispatch();
  const [urgent, setUrgent] = useState(false);

  const renderScope = () => {
    return item.scopes.map((s) => {
      let color = "#ff7056";
      if (scopes[s.name]) {
        if (scopes[s.name].permitTrigger === "status") {
          color = "#00c292";
        } else if (scopes[s.name].permitTrigger === "woCreated") {
          if (s.woCreated) color = "#00c292";
        }
      }

      if (s.workOrder && s.workOrder.schedule === "Urgent" && !urgent)
        setUrgent(true);

      return (
        <Tag key={s._id} className={styles.scopeBadge} color={color}>
          {scopes[s.name].initials}
        </Tag>
      );
    });
  };

  const onClick = useCallback(() => {
    dispatch(setSelected(item._id));
    dispatch(openForm("permit", item));
  }, [item, dispatch]);

  if (!mobile) {
    return (
      <List.Item
        style={style}
        className={`${styles.row} ${
          selectedRow === item._id ? styles.selected : ""
        } ${urgent ? styles.urgent : ""}`}
        onClick={onClick}
      >
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[0].width,
            textAlign: headers[0].align ? headers[0].align : "left",
          }}
          description={item.customerId}
        />
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[1].width,
            textAlign: headers[1].align ? headers[1].align : "left",
          }}
          description={item.customerName}
        />
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[2].width,
            textAlign: headers[2].align ? headers[2].align : "left",
          }}
          description={
            <Tag
              className={styles.statusTag}
              color={statusObj[item.permitStatus]}
            >
              {item.permitStatus}
            </Tag>
          }
        />
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[3].width,
            textAlign: headers[3].align ? headers[3].align : "left",
          }}
          description={item.projectAddress}
        />
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[4].width,
            textAlign: headers[4].align ? headers[4].align : "left",
          }}
          description={renderScope()}
        />
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[5].width,
            textAlign: headers[5].align ? headers[5].align : "left",
          }}
          description={dayjs(item.dateSold).format("MMM D, YYYY")}
        />
      </List.Item>
    );
  } else {
    return (
      <List.Item
        style={style}
        className={`${styles.mobileListItem} ${
          selectedRow === item._id ? styles.selected : ""
        } ${urgent ? styles.urgent : ""}`}
        onClick={onClick}
      >
        <List.Item.Meta className={styles.customerId} title={item.customerId} />
        <List.Item.Meta
          className={styles.status}
          description={
            <Tag
              className={styles.statusTag}
              color={statusObj[item.permitStatus]}
            >
              {item.permitStatus}
            </Tag>
          }
        />
        <List.Item.Meta
          className={styles.date}
          description={dayjs(item.dateSold).format("MMM D, YY")}
        />
        <div className={styles.break} />
        <List.Item.Meta
          className={styles.fullLine}
          style={{ flex: "1 1 100%", paddingBottom: 3 }}
          description={item.customerName}
        />
        <div className={styles.break} />
        <List.Item.Meta
          className={styles.fullLine}
          description={item.projectAddress}
        />
        <div className={styles.break} />
        <List.Item.Meta className={styles.scopes} description={renderScope()} />
      </List.Item>
    );
  }
};

export default memo(PermitsItem);
