import React from "react";
import BarChartWithSwitch from "Reports/BarChartWithSwitch";

export default function SalesByZip() {
  return (
    <BarChartWithSwitch
      path="/reports/salesByZip"
      _value="Value"
      secondary="Count"
      report="SALES BY ZIPCODE"
      headers={["Location", "Total"]}
    />
  );
}
