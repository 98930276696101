export default function arraysEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  let _a = JSON.parse(JSON.stringify(a)),
    _b = JSON.parse(JSON.stringify(b));
  _a.sort();
  _b.sort();

  for (var i = 0; i < _a.length; ++i) {
    if (_a[i] !== _b[i]) return false;
  }
  return true;
}
