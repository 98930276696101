import React from "react";
import BarChart from "Reports/BarChart";

export default function Pipeline() {
  return (
    <BarChart
      path="/reports/pipeline"
      summary={true}
      report="PIPELINE"
      headers={["Salesman", "Total"]}
    />
  );
}
