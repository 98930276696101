import * as actions from "Actions/actionTypes";

const _initMenuItems = localStorage.getItem("menuItems");

const defaultState = {
  token: localStorage.getItem("storyToken"),
  status: localStorage.getItem("status"),
  manager: localStorage.getItem("manager"),
  admin: localStorage.getItem("admin"),
  role: localStorage.getItem("role"),
  reports: localStorage.getItem("reports"),
  name: localStorage.getItem("name"),
  phone: localStorage.getItem("phone"),
  email: localStorage.getItem("email"),
  _user: localStorage.getItem("user"),
  salesman: localStorage.getItem("salesman"),
  menuItems: _initMenuItems !== "undefined" ? JSON.parse(_initMenuItems) : {},
  emailNotifications: localStorage.getItem("emailNotifications"),
  install: localStorage.getItem("install"),
  updateAvailable: false,
  mentions: [],
  notifications: [],
  count: 0,
};

const authReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actions.ADD_ERROR:
      return { ...state, errorMessage: action.payload };
    case actions.CLEAR_ERROR_MESSAGE:
      return { ...state, errorMessage: "" };
    case actions.SIGNIN:
      localStorage.setItem("storyToken", action.payload.token);
      localStorage.setItem("status", action.payload.status);
      localStorage.setItem("manager", action.payload.manager);
      localStorage.setItem("admin", action.payload.admin);
      localStorage.setItem("role", action.payload.role);
      localStorage.setItem("reports", action.payload.reports);
      localStorage.setItem("name", action.payload.name);
      localStorage.setItem("phone", action.payload.phone);
      localStorage.setItem("email", action.payload.email);
      localStorage.setItem("user", action.payload._user);
      localStorage.setItem("salesman", action.payload.salesman);
      localStorage.setItem("install", action.payload.install);
      localStorage.setItem(
        "menuItems",
        JSON.stringify(action.payload.menuItems)
      );
      localStorage.setItem(
        "emailNotifications",
        action.payload.storyEmailNotifications
      );
      return {
        ...state,
        token: action.payload.token,
        status: action.payload.status,
        manager: action.payload.manager,
        admin: action.payload.admin,
        role: action.payload.role,
        reports: action.payload.reports,
        name: action.payload.name,
        phone: action.payload.phone,
        email: action.payload.email,
        _user: action.payload._user,
        mentions: action.payload.mentions,
        salesman: action.payload.salesman,
        menuItems: action.payload.menuItems,
        updateAvailable: action.payload.updateAvailable,
        count: action.payload.notificationsCount,
        emailNotifications: action.payload.storyEmailNotifications,
        install: action.payload.install,
      };
    case actions.SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          ...action.payload.notifications,
        ],
        notificationsLength: action.payload.notificationsLength,
        count: action.payload.count,
      };
    case actions.REPLACE_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload.notifications,
        notificationsLength: action.payload.notificationsLength,
        count: action.payload.count,
      };
    case actions.UPDATE_MENU_ITEMS:
      return {
        ...state,
        menuItems: action.payload,
      };
    case actions.EMAIL_NOTIFICATIONS:
      return {
        ...state,
        emailNotifications: action.payload,
      };
    case actions.SIGNOUT:
      return {
        ...state,
        token: null,
        status: "Inactive",
        manager: "No",
        admin: "No",
        role: undefined,
        reports: "No",
        name: undefined,
        phone: undefined,
        email: undefined,
        _user: undefined,
        mentions: [],
        salesman: "No",
        menuItems: {},
        notifications: [],
        notificationsLength: 0,
        count: 0,
        updateAvailable: false,
        emailNotifications: false,
        install: "No",
      };
    default:
      return state;
  }
};

export default authReducer;
