import React, { forwardRef } from "react";
import styles from "./styles.module.scss";

const HorizontalScroll = ({ children, scrollContainer, style = {} }, ref) => {
  const handleHorizontalScroll = (e) => {
    if (e.deltaY > 0) {
      ref.current.scrollLeft += 100;
      if (
        Math.abs(e.currentTarget.scrollLeft) ===
        e.currentTarget.scrollWidth - e.currentTarget.clientWidth
      ) {
        scrollContainer.style.overflow = "auto";
      }
    } else {
      ref.current.scrollLeft -= 100;
      if (e.currentTarget.scrollLeft === 0) {
        scrollContainer.style.overflow = "auto";
      }
    }
  };

  const disableVerticalScroll = (e) => {
    scrollContainer.style.overflow = "hidden";
  };

  const enableVerticalScroll = (e) => {
    scrollContainer.style.overflow = "auto";
  };

  return (
    <div
      ref={ref}
      className={styles.mediaContainer}
      onWheel={handleHorizontalScroll}
      onMouseEnter={disableVerticalScroll}
      onMouseLeave={enableVerticalScroll}
      style={style}
    >
      {children}
    </div>
  );
};

export default forwardRef(HorizontalScroll);
