import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelected } from "Actions/dataActions";
import { openForm } from "Actions/formActions";
import List from "antd/es/list";
import dayjs from "dayjs";
import styles from "./styles.module.scss";
import currencyFormatter from "Utils/currencyFormatter";

const DrawActivitiesItem = ({
  style,
  _id,
  userName,
  _userId,
  type,
  activity,
  customerId,
  amount,
  transactionDate,
  headers,
  callback,
}) => {
  const selectedRow = useSelector((state) => state.dataState.selectedRow);
  const dispatch = useDispatch();

  const onClick = useCallback(() => {
    dispatch(setSelected(_id));
    if (!amount) {
      dispatch(
        openForm("drawActivity", {
          _id,
          userName,
          _userId,
          type,
          activity,
          transactionDate,
          callback,
          editing: true,
        })
      );
    } else {
      dispatch(
        openForm("marketingActivity", {
          _id,
          userName,
          _userId,
          type,
          amount,
          transactionDate,
          callback,
          editing: true,
        })
      );
    }
  }, [
    dispatch,
    _id,
    userName,
    _userId,
    type,
    amount,
    transactionDate,
    activity,
    callback,
  ]);

  return (
    <List.Item
      style={style}
      className={`${styles.row} ${selectedRow === _id ? styles.selected : ""}`}
      onClick={onClick}
    >
      <List.Item.Meta
        className={styles.col}
        style={{
          width: headers[0].width,
          textAlign: headers[0].align ? headers[0].align : "left",
        }}
        description={userName}
      />
      <List.Item.Meta
        className={styles.col}
        style={{
          width: headers[1].width,
          textAlign: headers[1].align ? headers[1].align : "left",
        }}
        description={type}
      />
      <List.Item.Meta
        className={styles.col}
        style={{
          width: headers[2].width,
          textAlign: headers[2].align ? headers[2].align : "left",
        }}
        description={customerId}
      />
      <List.Item.Meta
        className={styles.col}
        style={{
          width: headers[3].width,
          textAlign: headers[3].align ? headers[3].align : "left",
        }}
        description={currencyFormatter(activity)}
      />
      <List.Item.Meta
        className={styles.col}
        style={{
          width: headers[4].width,
          textAlign: headers[4].align ? headers[4].align : "left",
        }}
        description={dayjs(transactionDate).format("MMM D, YYYY")}
      />
    </List.Item>
  );
};

export default DrawActivitiesItem;
