import React, { useEffect, useState, useRef } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useSelector } from "react-redux";
import styles from "./styles.module.scss";
import api from "API";
import Grid from "antd/es/grid";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Affix from "antd/es/affix";
import useViewShow from "Hooks/useViewShow";
import ViewProject from "Screens/ViewProject";
import ViewContainer from "Components/ViewContainer";
import FormContainer from "Forms/FormContainer";
import Carousel from "antd/es/carousel";
import useIsMounted from "Hooks/useIsMounted";
import Button from "antd/es/button";

import MobileScope from "./MobileScope";
import Scope from "./Scope";
import PostJobInspection from "Forms/PostJobInspection";

import Loader from "Components/Loader";

import smoothlyAnimatePanTo from "Utils/smoothlyAnimatePanTo";
import generateMarkerUrl from "Utils/generateMarkerUrl";
import MapKey from "Components/MapKey";

const { useBreakpoint } = Grid;

const PostJobInspections = () => {
  const postJobInspection = useSelector(
    (state) => state.formState.postJobInspection.open
  );
  const install = useSelector((state) => state.authState.install);
  const screens = useBreakpoint();
  const [scopes, setScopes] = useState([]);
  const [container, setContainer] = useState(null);
  const [markers, setMarkers] = useState({});
  const [postJobInspectionsMap, setPostJobInspectionsMap] = useState();
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState("Showing ALL");
  const [typeFilters, setTypeFilters] = useState([
    "roofing",
    "roofing-flagged",
    "roofing-nc",
    "siding",
    "siding-nc",
  ]);
  const isMounted = useIsMounted();

  const showProject = useViewShow(3, "projects", 4, "view");

  const carouselRef = useRef();

  const mounted = isMounted.current;

  useEffect(() => {
    if (mounted) {
      fetchScopes();
      mapInit();

      if (!window.socket.hasListeners("postJobInspections"))
        window.socket.removeAllListeners("postJobInspections");
      window.socket.on("postJobInspections", async () => {
        fetchScopes();
      });
    }

    return () => {
      if (!window.socket.hasListeners("postJobInspections"))
        window.socket.removeAllListeners("postJobInspections");
    };
  }, [mounted]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (postJobInspectionsMap && scopes) {
      removePins();
      pinInit();
    }
  }, [postJobInspectionsMap, scopes, typeFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchScopes = async () => {
    try {
      const res = await api.get("/post-job-inspections");
      await removePins();
      unstable_batchedUpdates(() => {
        setScopes(res.data);
        setMarkers({});
        setLoading(false);
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const fetchMyScopes = async () => {
    try {
      const res = await api.get("/my-post-job-inspections");
      await removePins();
      unstable_batchedUpdates(() => {
        setScopes(res.data);
        setMarkers({});
        setLoading(false);
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const mapInit = () => {
    setPostJobInspectionsMap(
      new window.google.maps.Map(
        document.getElementById("postJobInspectionsMap"),
        {
          center: { lat: 42.963795, lng: -85.670006 },
          zoom: 9,
          gestureHandling: "greedy",
          disableDefaultUI: true,
          fullscreenControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          zoomControl: false,
          defaultMapTypeId: "hybrid",
          mapTypeId: "hybrid",
          tilt: 0,
        }
      )
    );
  };

  const removePins = async () => {
    try {
      for (const s of scopes) {
        if (s._id && markers[s._id] && markers[s._id].marker) {
          markers[s._id].marker.setMap(null);
        }
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const pinInit = () => {
    let _markers = {};
    scopes.map((s) => {
      if (filterScopes(s)) {
        if (
          s._id &&
          s.workOrder &&
          s.workOrder.projectAddressLat &&
          s.workOrder.projectAddressLng
        ) {
          const scope = s.name === "Siding" ? "siding" : "roofing";
          const nc =
            s.workOrder && s.workOrder.propertyType === "New Construction"
              ? true
              : false;
          const flag =
            s.workOrder &&
            (s.workOrder.difficultyRating === 4 ||
              s.workOrder.difficultyRating === 4.5 ||
              s.workOrder.flag === true)
              ? true
              : false;
          const url = generateMarkerUrl({
            type: "post-job",
            scope,
            nc,
            flag,
          });

          _markers[s._id] = {};
          _markers[s._id].marker = new window.google.maps.Marker({
            position: {
              lat: s.workOrder.projectAddressLat,
              lng: s.workOrder.projectAddressLng,
            },
            map: postJobInspectionsMap,
            draggable: false,
            icon: new window.google.maps.MarkerImage(
              url,
              null,
              null,
              null,
              new window.google.maps.Size(36, 36)
            ),
            zIndex: 1,
          });

          _markers[s._id].mouseover = window.google.maps.event.addListener(
            _markers[s._id].marker,
            "mouseover",
            function () {
              const _scope = document.getElementById(s._id);
              if (_scope) {
                _scope.classList.add("hover");
                const scope = s.name === "Siding" ? "siding" : "roofing";
                const nc =
                  s.workOrder && s.workOrder.propertyType === "New Construction"
                    ? true
                    : false;
                const flag =
                  s.workOrder &&
                  (s.workOrder.difficultyRating === 4 ||
                    s.workOrder.difficultyRating === 4.5 ||
                    s.workOrder.flag === true)
                    ? true
                    : false;
                const url = generateMarkerUrl({
                  type: "post-job",
                  scope,
                  nc,
                  flag,
                  active: true,
                });

                setMarkers((_markers) => {
                  _markers[s._id].marker.setIcon(
                    new window.google.maps.MarkerImage(
                      url,
                      null,
                      null,
                      null,
                      new window.google.maps.Size(36, 36)
                    )
                  );
                  _markers[s._id].marker.setZIndex(10);
                  return _markers;
                });
              }
            }
          );

          _markers[s._id].mouseout = window.google.maps.event.addListener(
            _markers[s._id].marker,
            "mouseout",
            function () {
              const _scope = document.getElementById(s._id);
              if (_scope) {
                _scope.classList.remove("hover");
                const scope = s.name === "Siding" ? "siding" : "roofing";
                const nc =
                  s.workOrder && s.workOrder.propertyType === "New Construction"
                    ? true
                    : false;
                const flag =
                  s.workOrder &&
                  (s.workOrder.difficultyRating === 4 ||
                    s.workOrder.difficultyRating === 4.5 ||
                    s.workOrder.flag === true)
                    ? true
                    : false;
                const url = generateMarkerUrl({
                  type: "post-job",
                  scope,
                  nc,
                  flag,
                });

                setMarkers((_markers) => {
                  _markers[s._id].marker.setIcon(
                    new window.google.maps.MarkerImage(
                      url,
                      null,
                      null,
                      null,
                      new window.google.maps.Size(36, 36)
                    )
                  );
                  _markers[s._id].marker.setZIndex(1);
                  return _markers;
                });
              }
            }
          );

          _markers[s._id].mousedown = window.google.maps.event.addListener(
            _markers[s._id].marker,
            "mousedown",
            function () {
              if (!screens.md) {
                const scope = s.name === "Siding" ? "siding" : "roofing";
                const nc =
                  s.workOrder && s.workOrder.propertyType === "New Construction"
                    ? true
                    : false;
                const flag =
                  s.workOrder &&
                  (s.workOrder.difficultyRating === 4 ||
                    s.workOrder.difficultyRating === 4.5 ||
                    s.workOrder.flag === true)
                    ? true
                    : false;
                const url = generateMarkerUrl({
                  type: "post-job",
                  scope,
                  nc,
                  flag,
                });

                const index = scopes.findIndex((x) => x._id === s._id);
                carouselRef.current.goTo(index);
                setMarkers((_markers) => {
                  _markers[s._id].marker.setIcon(
                    new window.google.maps.MarkerImage(
                      url,
                      null,
                      null,
                      null,
                      new window.google.maps.Size(36, 36)
                    )
                  );
                  _markers[s._id].marker.setZIndex(1);
                  return _markers;
                });
              }
            }
          );

          _markers[s._id].click = window.google.maps.event.addListener(
            _markers[s._id].marker,
            "click",
            function () {
              if (screens.md) {
                const el = document.getElementById(s._id);
                el.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }
            }
          );
        }
        return s;
      }
      return null;
    });
    setMarkers(_markers);
  };

  const handleBeforeCarouselChange = (e) => {
    const _scope = scopes[e];
    if (_scope && _scope._id && markers[_scope._id]) {
      const scope = _scope.name === "Siding" ? "siding" : "roofing";
      const nc =
        _scope.workOrder && _scope.workOrder.propertyType === "New Construction"
          ? true
          : false;
      const flag =
        _scope.workOrder &&
        (_scope.workOrder.difficultyRating === 4 ||
          _scope.workOrder.difficultyRating === 4.5 ||
          _scope.workOrder.flag === true)
          ? true
          : false;
      const url = generateMarkerUrl({
        type: "post-job",
        scope,
        nc,
        flag,
      });

      setMarkers((_markers) => {
        if (_markers[_scope._id]) {
          _markers[_scope._id].marker.setIcon(
            new window.google.maps.MarkerImage(
              url,
              null,
              null,
              null,
              new window.google.maps.Size(36, 36)
            )
          );
          _markers[_scope._id].marker.setZIndex(1);
        }
        return _markers;
      });
    }
  };

  const handleAfterCarouselChange = (e) => {
    const _scope = scopes[e];
    if (
      _scope &&
      _scope.workOrder &&
      _scope.workOrder.projectAddressLat &&
      _scope.workOrder.projectAddressLng
    ) {
      const destLatLng = new window.google.maps.LatLng(
        _scope.workOrder.projectAddressLat,
        _scope.workOrder.projectAddressLng
      );
      smoothlyAnimatePanTo(postJobInspectionsMap, destLatLng);
    }

    if (_scope && _scope._id && markers[_scope._id]) {
      const scope = _scope.name === "Siding" ? "siding" : "roofing";
      const nc =
        _scope.workOrder && _scope.workOrder.propertyType === "New Construction"
          ? true
          : false;
      const flag =
        _scope.workOrder &&
        (_scope.workOrder.difficultyRating === 4 ||
          _scope.workOrder.difficultyRating === 4.5 ||
          _scope.workOrder.flag === true)
          ? true
          : false;
      const url = generateMarkerUrl({
        type: "post-job",
        scope,
        nc,
        flag,
        active: true,
      });

      setMarkers((_markers) => {
        _markers[_scope._id].marker.setIcon(
          new window.google.maps.MarkerImage(
            url,
            null,
            null,
            null,
            new window.google.maps.Size(36, 36)
          )
        );
        _markers[_scope._id].marker.setZIndex(10);
        return _markers;
      });
    }
  };

  const panToPin = (_id) => {
    const _scope = scopes.find((s) => s._id === _id);

    if (
      _scope &&
      _scope.workOrder &&
      _scope.workOrder.projectAddressLat &&
      _scope.workOrder.projectAddressLng
    ) {
      const destLatLng = new window.google.maps.LatLng(
        _scope.workOrder.projectAddressLat,
        _scope.workOrder.projectAddressLng
      );
      smoothlyAnimatePanTo(postJobInspectionsMap, destLatLng);
    }
  };

  const handleFilter = async () => {
    try {
      if (filter === "Showing ALL") {
        fetchMyScopes();
        setFilter("Showing MINE");
      } else {
        fetchScopes();
        setFilter("Showing ALL");
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleTypeFilter = async (type) => {
    if (typeFilters.includes(type)) {
      setTypeFilters(typeFilters.filter((tf) => tf !== type));
    } else {
      setTypeFilters((tf) => [...tf, type]);
    }
  };

  const filterScopes = (scope) => {
    const _scope = scope.name === "Siding" ? "siding" : "roofing";
    const nc =
      scope.workOrder && scope.workOrder.propertyType === "New Construction"
        ? true
        : false;
    const flagged =
      scope.workOrder &&
      (scope.workOrder.difficultyRating === 4 ||
        scope.workOrder.difficultyRating === 4.5 ||
        scope.workOrder.flag === true)
        ? true
        : false;

    const filterString = `${_scope}${flagged ? "-flagged" : ""}${
      nc ? "-nc" : ""
    }`;

    if (typeFilters.includes(filterString)) return true;
    return false;
  };

  return (
    <div className="form" style={{ height: "100%" }}>
      {screens.xs || (screens.sm && !screens.md) ? (
        <div style={{ height: "100%", width: "100%", position: "relative" }}>
          {install === "Yes" && (
            <Row gutter={16}>
              <Col xs={24}>
                <Button
                  type="primary"
                  className="green"
                  style={{
                    position: "fixed",
                    top: 120,
                    right: 12,
                    left: 12,
                    zIndex: 1,
                    width: "calc(100% - 24px)",
                  }}
                  // block
                  onClick={handleFilter}
                >
                  {filter}
                </Button>
              </Col>
            </Row>
          )}

          <div className={styles.projectCarousel} id="carousel">
            {loading ? (
              <div className={styles.mobileLoader}>
                <div>
                  <Loader minHeight="100%" />
                </div>
              </div>
            ) : (
              <Carousel
                ref={carouselRef}
                centerMode={true}
                centerPadding={scopes.length === 1 ? "0px" : "16px"}
                slidesToShow={1}
                // className={styles.carousel}
                dots={false}
                beforeChange={handleBeforeCarouselChange}
                afterChange={handleAfterCarouselChange}
                infinite={false}
              >
                {scopes.map((scope) => {
                  if (
                    filterScopes(scope) &&
                    scope.workOrder &&
                    scope.workOrder.customerName
                  ) {
                    return (
                      <div key={scope._id} className={styles.carouselSlide}>
                        <MobileScope
                          scopes={scopes}
                          scope={scope}
                          container={container}
                          setMarkers={setMarkers}
                          markers={markers}
                          setScopes={setScopes}
                          fetchScopes={fetchScopes}
                          removePins={removePins}
                        />
                      </div>
                    );
                  }
                  return null;
                })}
              </Carousel>
            )}
          </div>
          <div
            style={{
              width: "100%",
              height: "100%",
            }}
            id="postJobInspectionsMap"
          ></div>
          <MapKey
            handleClick={handleTypeFilter}
            typeFilters={typeFilters}
            compact
          />
        </div>
      ) : (
        <div className="content-inner p24" ref={setContainer}>
          <Row gutter={16} style={{ height: "100%" }}>
            <Col xs={24} sm={24} md={13} lg={11}>
              {loading ? (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                  }}
                >
                  <Loader minHeight="100%" />
                </div>
              ) : (
                <>
                  {install === "Yes" && (
                    <Button
                      type="primary"
                      className="green"
                      block
                      onClick={handleFilter}
                      style={{ marginBottom: 12 }}
                    >
                      {filter}
                    </Button>
                  )}
                  {scopes.map((scope) => {
                    if (
                      filterScopes(scope) &&
                      scope.workOrder &&
                      scope.workOrder.customerName
                    ) {
                      return (
                        <Scope
                          key={scope._id}
                          scope={scope}
                          scopes={scopes}
                          setMarkers={setMarkers}
                          markers={markers}
                          fetchScopes={fetchScopes}
                          setScopes={setScopes}
                          container={container}
                          panToPin={panToPin}
                          removePins={removePins}
                        />
                      );
                    }
                    return null;
                  })}
                </>
              )}
            </Col>
            <Col xs={0} sm={0} md={11} lg={13} className={styles.mapContainer}>
              <Affix offsetTop={25} target={() => container}>
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                    marginBottom: 16,
                    borderRadius: 4,
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      marginBottom: 16,
                      borderRadius: 4,
                    }}
                    id="postJobInspectionsMap"
                  ></div>
                  <MapKey
                    handleClick={handleTypeFilter}
                    typeFilters={typeFilters}
                  />
                </div>
              </Affix>
            </Col>
          </Row>
        </div>
      )}

      <ViewContainer
        type="drawer"
        visible={showProject}
        form={ViewProject}
        placement="right"
        width={!screens.sm ? "100%" : 400}
        closable={false}
        mask={false}
      />

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0 },
        }}
        type={!screens.md ? "drawer" : "modal"}
        width={!screens.md ? "100%" : 320}
        title="Post-Job Inspection"
        visible={postJobInspection}
        form={PostJobInspection}
        formType="postJobInspection"
      />
    </div>
  );
};

export default PostJobInspections;
