import React, { useState } from "react";
import Col from "antd/es/col";
import Card from "antd/es/card";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Button from "antd/es/button";
import message from "antd/es/message";
import Comment from "antd/es/comment";
import dayjs from "dayjs";
import api from "API";
import generateAvatar from "Utils/generateAvatar";
import Avatar from "antd/es/avatar";
import styles from "./styles.module.scss";
import nomatch from "Assets/avatars/default.png";

const messageKey = "messageKey";

const Comments = ({ comments = [], _id, setProject }) => {
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      // message.loading("Saving comment...", 0);
      message.loading({
        content: "Saving comment...",
        duration: 0,
        key: messageKey,
      });

      values._projectId = _id;

      const res = await api.post("/permits/comment/create", values);

      setProject((_project) => {
        return {
          ..._project,
          permitComments: res.data._permitComments,
        };
      });

      setLoading(false);
      // message.success("Comment saved");
      message.success({
        content: "Comment saved",
        duration: 1.5,
        key: messageKey,
      });
    } catch (err) {
      console.log("err", err);
      setLoading(false);
      // message.error("Error saving comment");
      message.error({
        content: "Error saving comment",
        duration: 1.5,
        key: messageKey,
      });
    }
  };

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error saving comment");
  };

  return (
    <Col xs={24} sm={8} style={{ paddingLeft: 6 }}>
      <Card
        className="z-depth-1"
        title="New Comment"
        style={{ marginBottom: 12 }}
      >
        <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <Form.Item
            name="comment"
            rules={[{ required: true, message: "Comment is required" }]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>

          <Button
            type="primary"
            block
            loading={loading}
            disabled={loading}
            htmlType="submit"
          >
            Save Comment
          </Button>
        </Form>
      </Card>

      <Card
        className="z-depth-1"
        title="Comments"
        style={{ marginBottom: 12 }}
        bodyStyle={{
          paddingLeft: 24,
          paddingRight: 24,
          paddingTop: 0,
          paddingBottom: 0,
        }}
      >
        {comments.length > 0 ? (
          comments
            .slice(0)
            .reverse()
            .map((comment) => (
              <Comment
                key={comment._id}
                avatar={
                  <Avatar
                    src={generateAvatar(comment.avatar, comment.createdBy)}
                    icon={<img alt="avatar" src={nomatch} />}
                  />
                }
                author={comment.createdBy}
                datetime={dayjs(comment.created).format("MMM D, YYYY h:mm A")}
                content={comment.comment}
                className={styles.comment}
              />
            ))
        ) : (
          <div style={{ paddingTop: 16, paddingBottom: 16 }}>
            No comments have been saved
          </div>
        )}
      </Card>
    </Col>
  );
};

export default Comments;
