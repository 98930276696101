import { useEffect, useState } from "react";
import api from "API";
let isMounted = false;

export default function useProjectManagers() {
  const [projectManagers, setProjectManagers] = useState({});

  useEffect(() => {
    isMounted = true;
    fetchProjectManagers();

    return () => (isMounted = false);
  }, []);

  const fetchProjectManagers = async () => {
    try {
      const res = await api.get("/populateProjectManagers");
      let arr = {};
      for (const s of res.data) {
        if (s.name !== "Install Group") {
          arr[s._id] = {
            _user: s._id,
            name: s.name,
            email: s.email,
            phone: s.phone,
            hoverUserId: s.hoverUserId,
          };
        }
      }

      if (isMounted) setProjectManagers(arr);
    } catch (err) {
      console.log("err", err);
    }
  };

  return projectManagers;
}
