import React, { useEffect, useState, useCallback } from "react";
import Grid from "antd/es/grid";
import { useSelector, useDispatch } from "react-redux";
import { fetchTable, unsetTable, setScrollbar } from "Actions/tableActions";
import Table from "Components/Table";
import TableRow from "Components/Table/TableItems/OnSiteCheckItem";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as Vlist } from "react-window";
import usePrevious from "Hooks/usePrevious";
import useIsMobile from "Hooks/useIsMobile";
import isEmpty from "Utils/isEmpty";
import FormContainer from "Forms/FormContainer";
import OnSiteCheck from "Forms/OnSiteCheck";

const { useBreakpoint } = Grid;

const headers = [
  {
    _name: "customerId",
    name: "ID",
    width: "10%",
  },
  {
    _name: "customerName",
    name: "CUSTOMER",
    width: "15%",
  },
  {
    _name: "stage",
    name: "STAGE",
    width: "15%",
  },
  {
    _name: "workOrder.projectAddress",
    name: "ADDRESS",
    width: "25%",
  },
  {
    _name: "scopes",
    name: "SCOPES",
    width: "15%",
    type: "scope",
  },
  {
    _name: "",
    name: "",
    width: "5%",
    type: "editProject",
  },
  {
    _name: "workOrder.dateSold",
    name: "DATE SOLD",
    width: "15%",
    align: "right",
    type: "date",
  },
];

const OnSiteCheckList = () => {
  const dispatch = useDispatch();
  const formDetails = useSelector(
    (state) => state.formState.orderAerial.formDetails
  );
  const rows = useSelector((state) => state.tableState.rows);
  const fetching = useSelector((state) => state.tableState.fetching);
  const search = useSelector((state) => state.tableState.search);
  const activeFilter = useSelector((state) => state.tableState.activeFilter);
  const collapsed = useSelector((state) => state.settingsState.collapsed);
  const screens = useBreakpoint();
  const mobile = useIsMobile();
  const prevScreens = usePrevious(screens);
  const onSiteCheck = useSelector((state) => state.formState.onSiteCheck.open);
  const [itemSize, setItemSize] = useState(135);

  useEffect(() => {
    initTable();

    return () => dispatch(unsetTable());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTimeout(() => {
      dispatch(setScrollbar());
    });
  }, [rows, dispatch]);

  const initTable = useCallback(() => {
    dispatch(
      fetchTable({
        table: "on-site-check",
        col: "workOrder.dateSold",
        order: -1,
        fetching: false,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (prevScreens && screens) {
      if (prevScreens.md && !screens.md) {
        setItemSize(135);
      } else if (!prevScreens.md && screens.md) {
        setItemSize(47);
      }
    }
  }, [screens, prevScreens]);

  const Row = ({ index, style }) => {
    const item = rows[index];
    if (!mobile)
      return (
        <TableRow style={style} key={item._id} item={item} headers={headers} />
      );
    return <TableRow style={style} key={item._id} item={item} mobile />;
  };

  return (
    <>
      <Table
        loading={fetching}
        mobile={mobile}
        sider={screens.lg}
        collapsed={collapsed}
        filter={
          search ||
          (activeFilter && activeFilter.filter && !isEmpty(activeFilter.filter))
            ? true
            : false
        }
      >
        <Table.Header filterBtn={false} />

        <Table.Filter
          hide={
            search ||
            (activeFilter &&
              activeFilter.filter &&
              !isEmpty(activeFilter.filter))
              ? false
              : true
          }
        />

        {!mobile && <Table.ColumnHeaders headers={headers} />}
        <Table.Body loading={fetching}>
          {rows.length > 0 && (
            <AutoSizer>
              {({ height, width }) => (
                <Vlist
                  height={height}
                  itemCount={rows.length}
                  itemSize={itemSize}
                  width={width}
                >
                  {Row}
                </Vlist>
              )}
            </AutoSizer>
          )}
        </Table.Body>
      </Table>

      <FormContainer
        type={!screens.md ? "drawer" : "modal"}
        width={!screens.md ? "100%" : 320}
        title="On-site Check"
        visible={onSiteCheck}
        form={OnSiteCheck}
        formType="onSiteCheck"
        formDetails={formDetails}
      />
    </>
  );
};

export default OnSiteCheckList;
