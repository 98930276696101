import * as actions from "Actions/actionTypes";

const defaultState = {
  rows: [],
  fetching: true,
  table: undefined,
  col: undefined,
  order: 1,
  search: "",
  globalFilters: [],
  userFilters: [],
  activeFilter: {},
  scrollBarWidth: 0,
};

const tableReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actions.FETCH_TABLE:
      return {
        ...state,
        rows: action.payload.rows,
        fetching: false,
      };
    case actions.FETCH_TABLE_FILTER:
      return {
        ...state,
        globalFilters: action.payload.globalFilters,
        userFilters: action.payload.userFilters,
        activeFilter: action.payload.activeFilter,
      };
    case actions.SET_USER_FILTER:
      return {
        ...state,
        userFilters: action.payload,
      };
    case actions.SET_TABLE_FILTER:
      return {
        ...state,
        globalFilters: action.payload.globalFilters,
        userFilters: action.payload.userFilters,
        activeFilter: action.payload.activeFilter,
      };
    case actions.FETCHING_TABLE:
      return {
        ...state,
        table: action.payload.table,
        col: action.payload.col,
        order: action.payload.order,
        search: action.payload.search,
        activeFilter: action.payload.activeFilter,
        fetching: action.payload.fetching,
      };
    case actions.SET_SCROLLBAR:
      return {
        ...state,
        scrollBarWidth: action.payload,
      };
    case actions.UNSET_TABLE:
      return {
        ...state,
        rows: [],
        fetching: true,
        table: undefined,
        col: undefined,
        order: 1,
        search: "",
        activeFilter: {},
        globalFilters: [],
        userFilters: [],
      };
    default:
      return state;
  }
};

export default tableReducer;
