import React from "react";
import BarChartWithSwitch from "Reports/BarChartWithSwitch";

export default function Sold() {
  return (
    <BarChartWithSwitch
      path="/reports/sold"
      _value="Value"
      secondary="Count"
      report="SALES"
      headers={["Salesman", "Total"]}
    />
  );
}
