import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import Grid from "antd/es/grid";
import { setSelected } from "Actions/dataActions";
import {
  fetchTable,
  fetchTableFilters,
  unsetTable,
  setScrollbar,
} from "Actions/tableActions";
import Table from "Components/Table";
import TableRow from "Components/Table/TableItems/NeedsBrokedownItem";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as Vlist } from "react-window";
import usePrevious from "Hooks/usePrevious";
import useIsMobile from "Hooks/useIsMobile";
import isEmpty from "Utils/isEmpty";

const { useBreakpoint } = Grid;

const headers = [
  {
    _name: "customerId",
    name: "ID",
    width: "10%",
  },
  {
    _name: "customerName",
    name: "CUSTOMER",
    width: "15%",
  },
  {
    _name: "salesman",
    name: "SALESMAN",
    width: "25%",
  },
  {
    _name: "propertyType",
    name: "PROPERTY TYPE",
    width: "15%",
  },
  {
    _name: "collected",
    name: "COLLECTED",
    width: "10%",
  },
  {
    _name: "closedOutDate",
    name: "DATE CLOSED",
    width: "15%",
    align: "right",
  },
  {
    _name: "daysClosedOut",
    name: "DAYS CLOSED",
    width: "10%",
    align: "right",
  },
];

const filters = [
  {
    title: "Salesman",
    type: "salesmen",
    fields: [
      {
        name: "salesman",
        placeholder: "Select an Option(s)",
        values: [],
      },
    ],
  },
];

const NeedsBrokedown = () => {
  const dispatch = useDispatch();
  const screens = useBreakpoint();
  const mobile = useIsMobile();
  const prevScreens = usePrevious(screens);
  const rows = useSelector((state) => state.tableState.rows);
  const fetching = useSelector((state) => state.tableState.fetching);
  const search = useSelector((state) => state.tableState.search);
  const activeFilter = useSelector((state) => state.tableState.activeFilter);
  const table = useSelector((state) => state.tableState.table);
  const col = useSelector((state) => state.tableState.col);
  const order = useSelector((state) => state.tableState.order);
  const collapsed = useSelector((state) => state.settingsState.collapsed);
  const [itemSize, setItemSize] = useState(135);

  useEffect(() => {
    initTable();

    return () => dispatch(unsetTable());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTimeout(() => {
      dispatch(setScrollbar());
    });
  }, [rows, dispatch]);

  const initTable = useCallback(() => {
    dispatch(
      fetchTableFilters({
        table: "needsBrokedown",
        col: "daysClosedOut",
        order: -1,
        search,
      })
    );
  }, [dispatch, search]);

  useEffect(() => {
    if (prevScreens && screens) {
      if (prevScreens.md && !screens.md) {
        setItemSize(135);
      } else if (!prevScreens.md && screens.md) {
        setItemSize(47);
      }
    }
  }, [screens, prevScreens]);

  const Row = ({ index, style }) => {
    const item = rows[index];
    return (
      <TableRow
        style={style}
        key={item._id}
        {...item}
        headers={headers}
        callback={callback}
      />
    );
  };

  const callback = useCallback(() => {
    dispatch(setSelected(null));
    dispatch(
      fetchTable({
        table,
        col,
        order,
        search,
        activeFilter,
        fetching: false,
      })
    );
  }, [dispatch, table, col, order, search, activeFilter]);

  return (
    <>
      <Table
        loading={fetching}
        filter={
          search ||
          (activeFilter && activeFilter.filter && !isEmpty(activeFilter.filter))
            ? true
            : false
        }
        mobile={mobile}
        sider={screens.lg}
        collapsed={collapsed}
      >
        <Table.Header filters={filters} />

        <Table.Filter
          hide={
            search ||
            (activeFilter &&
              activeFilter.filter &&
              !isEmpty(activeFilter.filter))
              ? false
              : true
          }
        />

        {!mobile && <Table.ColumnHeaders headers={headers} />}
        <Table.Body loading={fetching}>
          {rows.length > 0 && (
            <AutoSizer>
              {({ height, width }) => (
                <Vlist
                  height={height}
                  itemCount={rows.length}
                  itemSize={itemSize}
                  width={width}
                >
                  {Row}
                </Vlist>
              )}
            </AutoSizer>
          )}
        </Table.Body>
      </Table>
    </>
  );
};

export default NeedsBrokedown;
