import React from "react";
import Typography from "antd/es/typography";
import Card from "antd/es/card";
import Form from "antd/es/form";
import Checkbox from "antd/es/checkbox";
import DatePicker from "Components/Inputs/DatePicker";
import Select from "Components/Inputs/Select";
import useIsMobile from "Hooks/useIsMobile";

const MaterialDeliveryDetails = ({ show = false }) => {
  const mobile = useIsMobile();

  return (
    <div style={{ paddingTop: 24, display: show ? "block" : "none" }}>
      <Typography.Title level={4}>Communication Details</Typography.Title>

      <Card className="z-depth-1" bordered={false}>
        <Form.Item
          valuePropName="checked"
          name="hoNotified"
          rules={[
            {
              required: show,
              message: "Home Owner must be notified before proceeding",
            },
          ]}
        >
          <Checkbox>Home Owner Notified & Post Created</Checkbox>
        </Form.Item>

        <Form.Item
          valuePropName="checked"
          name="projectManagerWillNotifyHO"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Checkbox>
            Auto create post that Project Owner will notify HO
          </Checkbox>
        </Form.Item>

        <Form.Item
          name="deliveryDate"
          label="Delivery Date"
          rules={[{ required: show, message: "Delivery Date is required" }]}
        >
          <DatePicker
            format="MMMM DD, YYYY"
            getPopupContainer={(trigger) =>
              !mobile
                ? trigger.parentNode.parentNode.parentNode.parentNode
                : document.body
            }
            getCalendarContainer={(trigger) =>
              !mobile
                ? trigger.parentNode.parentNode.parentNode.parentNode
                : document.body
            }
            inputReadOnly={true}
          />
        </Form.Item>

        <Form.Item
          name="deliveryOptions"
          label="Delivery Times"
          rules={[{ required: show, message: "Delivery Times is required" }]}
        >
          <Select
            options={[
              "First AM",
              "Second AM",
              "First PM",
              "Second PM",
              "Anytime",
            ]}
            id="deliveryOptions"
          />
        </Form.Item>
      </Card>
    </div>
  );
};

export default MaterialDeliveryDetails;
