import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateDetail } from "Actions/dataActions";
import Button from "antd/es/button";
import Form from "antd/es/form";
import message from "antd/es/message";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import api from "API";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";

const AddAlternateEmail = ({ toggleAddEmail, _id }) => {
  const dispatch = useDispatch();
  const viewableActivities = useSelector(
    (state) => state.dataState.viewableActivities
  );
  const [loading, setLoading] = useState(false);

  const onFinish = useCallback(
    async (values) => {
      try {
        setLoading(true);
        values._id = _id;
        const res = await api.post("/update/addAlternateEmail", values);

        dispatch(
          updateDetail({
            viewableActivities,
            key: "alternateEmail",
            value: res.data.value,
            activities: res.data.activities,
          })
        );

        setLoading(false);
        toggleAddEmail();
      } catch (err) {
        console.log("err", err);
        setLoading(false);
        message.error("Error submitting Alternate Email");
      }
    },
    [_id, dispatch, viewableActivities, toggleAddEmail]
  );

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error submitting Alternate Email");
  };

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      style={{ marginBottom: 16 }}
    >
      <RemoveAutocomplete />

      <Form.Item
        name="description"
        label="Description"
        rules={[{ required: true, message: "Description is required" }]}
      >
        <InputNoAutoComplete id="description" autoFocus />
      </Form.Item>

      <Form.Item
        name="contact"
        label="Email"
        rules={[
          { required: true, message: "Email is required" },
          { message: "Must be a valid email", type: "email" },
        ]}
      >
        <InputNoAutoComplete id="contact" />
      </Form.Item>

      <div className="editControls">
        <Button
          type="default"
          size="small"
          onClick={toggleAddEmail}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          htmlType="submit"
          type="primary"
          size="small"
          loading={loading}
          disabled={loading}
        >
          Submit
        </Button>
      </div>
    </Form>
  );
};

export default AddAlternateEmail;
