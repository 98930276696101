import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import axios from "axios";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Card from "antd/es/card";
import Typography from "antd/es/typography";
import dayjs from "dayjs";
import api from "API";

import Weather from "./Weather";
import ReviewRatings from "./ReviewRatings";
import Radar from "./Radar";
import Reviews from "./Reviews";
import Partners from "./Partners";
import StageCard from "./StageCard";

import premierLogo from "Assets/premier-connect-logo.png";
import mycoLogo from "Assets/logo-200x96-plain.png";
import mycoLive from "Assets/myco-live.png";
// import mycoLive from "Assets/MyCo_Live.svg";
import * as serviceWorker from "../../serviceWorkerRegistration";

let today = dayjs();

const BullpinDashboard = () => {
  const [date, setDate] = useState(dayjs());
  const [weather, setWeather] = useState({
    today: {},
    tomorrow: {},
    nextDay: {},
  });

  const [stages, setStages] = useState({
    "Pre-Job Check": 0,
    "Ready For Material": 0,
    "Ready For Schedule": 0,
    "Work Scheduled": 0,
    Completed: 0,
    "Post-Job Inspection": 0,
  });

  useEffect(() => {
    serviceWorker.unregister();
    window.socket.on("stageChange", fetchStages);
    fetchWeather();
    fetchStages();

    const timer = setInterval(() => {
      setDate(dayjs());
    }, 15000);

    return () => {
      clearInterval(timer);
      window.socket.removeAllListeners("stageChange");
    };
  }, []);

  useEffect(() => {
    if (today.format("dddd") !== date.format("dddd")) {
      today = dayjs();
      fetchWeather();
      fetchStages();
    }
  }, [date]);

  const fetchWeather = async () => {
    try {
      const res = await axios.get(
        "https://api.openweathermap.org/data/2.5/onecall?lat=42.963795&lon=-85.670006&exclude=minutely,hourly,alerts&appid=15a8e46e839dea77c8a6f473085ca425"
      );
      setWeather({
        today: res.data.daily[0],
        tomorrow: res.data.daily[1],
        nextDay: res.data.daily[2],
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const fetchStages = async () => {
    try {
      const res = await api.get("/bullpin-dashboard");
      setStages(res.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <div className={styles.container}>
      <Row gutter={16} style={{ height: "100%" }}>
        <Col xs={6} style={{ height: "100%" }}>
          <Card bordered={false} className={styles.card}>
            <div style={{ marginBottom: 24 }}>
              <img
                src={premierLogo}
                alt="premier-logo"
                style={{ display: "block", margin: "auto", width: 300 }}
              />
            </div>
            <div style={{ height: "calc(20% - 32px)" }}>
              <Typography.Title
                level={1}
                style={{ fontSize: 52, color: "#ffffff" }}
              >
                {date.format("h:mm A")}
              </Typography.Title>
              <Typography.Title
                className={styles.offWhite}
                level={4}
                style={{ fontWeight: 400, fontSize: 34 }}
              >
                {date.format("MMMM D")}
              </Typography.Title>
              <Typography.Title
                className={styles.offWhite}
                level={5}
                style={{ fontWeight: 200, fontSize: 30 }}
              >
                {date.format("dddd")}
              </Typography.Title>
            </div>
            <div className={styles.fifths}>
              <Weather day={weather.today} title="TODAY" />
            </div>
            <div className={styles.fifths}>
              <Weather day={weather.tomorrow} title="TOMORROW" />
            </div>
            <div className={styles.fifths}>
              <Weather
                day={weather.nextDay}
                title={date.add(2, "day").format("dddd")}
              />
            </div>
            <div className={styles.poweredByContainer}>
              <Typography.Text type="secondary" className={styles.poweredBy}>
                Powered by
              </Typography.Text>{" "}
              <img src={mycoLogo} alt="myco-logo" height={32} />
            </div>
          </Card>
        </Col>
        <Col xs={18} style={{ height: "100%" }}>
          <Row
            gutter={16}
            style={{ height: "calc(14% - 4px)", marginBottom: 16 }}
          >
            <Col xs={24} style={{ height: "100%" }}>
              <ReviewRatings />
            </Col>
          </Row>

          <Row
            gutter={16}
            style={{ height: "calc(86% - 12px)", marginBottom: 16 }}
          >
            <Col xs={18} style={{ height: "100%" }}>
              <Row
                gutter={16}
                style={{ height: "calc(32% - 12px)", marginBottom: 16 }}
              >
                <Col xs={12} style={{ height: "100%" }}>
                  <Card
                    bordered={false}
                    className={styles.card}
                    bodyStyle={{
                      paddingTop: 24,
                      paddingLeft: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                    }}
                  >
                    <Partners />
                  </Card>
                </Col>
                <Col xs={12} style={{ height: "100%" }}>
                  <Card
                    bordered={false}
                    className={styles.card}
                    bodyStyle={{ padding: 0 }}
                  >
                    <Reviews />
                  </Card>
                </Col>
              </Row>
              <Row gutter={16} style={{ height: "calc(68% - 4px)" }}>
                <Col xs={24} style={{ height: "100%" }}>
                  <Radar />
                </Col>
              </Row>
            </Col>
            <Col xs={6} style={{ height: "100%" }}>
              <Row gutter={16} style={{ height: "100%" }}>
                <Col
                  xs={24}
                  style={{ height: "calc(16.667% - 10px)", marginBottom: 16 }}
                  className={styles.mycoLiveContainer}
                >
                  <img src={mycoLive} alt="myco-live-logo" />
                  {/* <object
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                    type="image/svg+xml"
                    data={mycoLive}
                  >
                    svg-animation
                  </object> */}
                </Col>
                <StageCard
                  stage={stages["Aerial Verification"]}
                  title="Office Review"
                />
                <StageCard
                  stage={stages["Pre-Job Check"]}
                  title="Operations Review"
                />
                <StageCard
                  stage={stages["Ready For Material"]}
                  title="Ready For Materials"
                />
                <StageCard
                  stage={stages["Ready For Schedule"]}
                  title="Ready To Be Scheduled"
                />
                <StageCard
                  stage={stages["Work Scheduled"]}
                  title="Work Scheduled"
                />
                {/* <StageCard stage={stages["Completed"]} title="Work Completed" /> */}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default BullpinDashboard;
