import React from "react";
import Button from "antd/es/button";

const CancelButton = ({
  handleClick,
  style = { float: "left", padding: "4px 8px" },
  title = "Cancel",
}) => {
  return (
    <Button type="text" danger style={style} onClick={handleClick}>
      {title}
    </Button>
  );
};

export default CancelButton;
