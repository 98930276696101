import React, { useState, useCallback } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateDetail } from "Actions/dataActions";
import Form from "antd/es/form";
import Button from "antd/es/button";
import message from "antd/es/message";
import useSalesmen from "Hooks/useSalesmen";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import api from "API";
import InputSalesmen from "Components/Inputs/Salesmen";

const Salesmen = ({ value, _id }) => {
  const dispatch = useDispatch();
  const viewableActivities = useSelector(
    (state) => state.dataState.viewableActivities
  );
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const sales = useSalesmen();

  const onFinish = useCallback(
    async (values) => {
      try {
        setLoading(true);
        values._id = _id;
        values.previous = value;

        values.salesObj = [];
        for (const v of values.salesmen) {
          values.salesObj.push(sales[v]);
        }

        let changed = [];
        for (const s of values.salesObj) {
          if (value.find((v) => v._user === s._user)) {
            changed.push(false);
          } else {
            changed.push(true);
          }
        }
        for (const s of values.previous) {
          if (values.salesObj.find((v) => v._user === s._user)) {
            changed.push(false);
          } else {
            changed.push(true);
          }
        }

        if (changed.includes(true)) {
          const res = await api.post("/update/salesmen", values);

          dispatch(
            updateDetail({
              viewableActivities,
              key: "salesmen",
              value: res.data,
              activities: res.data.activities,
            })
          );

          message.success("Customer Name updated");
        }
        unstable_batchedUpdates(() => {
          setLoading(false);
          setEdit(false);
        });
      } catch (err) {
        console.log("err", err);
        message.error("Error updating Customer Name");
        setLoading(false);
      }
    },
    [_id, value, sales, dispatch, viewableActivities]
  );

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error updating Customer Name");
  };

  const toggleEdit = () => {
    setEdit(!edit);
  };

  return (
    <Form
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      initialValues={{ salesmen: value.map((s) => s._user) }}
      style={{ marginBottom: 16 }}
    >
      <RemoveAutocomplete />
      <h4 style={{ marginBottom: 3 }}>Salesmen</h4>
      {!edit && (
        <p
          className="editableRow"
          style={{ marginBottom: 0 }}
          onClick={toggleEdit}
        >
          <span>{value.map((v) => v.name).join(", ")}</span>
        </p>
      )}

      {edit && (
        <>
          <Form.Item
            name="salesmen"
            rules={[
              {
                required: true,
                message: "Must select at least 1 salesman",
                type: "array",
              },
            ]}
          >
            <InputSalesmen
              autoFocus
              sales={sales}
              defaultOpen={true}
              placeholder="Salesmen"
            />
          </Form.Item>

          <div className="editControls">
            <Button
              type="default"
              size="small"
              onClick={toggleEdit}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              size="small"
              loading={loading}
              disabled={loading}
            >
              Submit
            </Button>
          </div>
        </>
      )}
    </Form>
  );
};

export default Salesmen;
