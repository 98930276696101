import React from "react";
import styles from "./styles.module.scss";
import Media from "./Media";

const ImageSelector = ({
  allMedia,
  attachments,
  setAttachments,
  scrollContainer,
}) => {
  return (
    <div className={styles.imageSelectorContainer}>
      {allMedia.map((media) => {
        if (media) {
          return (
            <Media
              key={media._id}
              media={media}
              attachments={attachments}
              setAttachments={setAttachments}
              scrollContainer={scrollContainer}
            />
          );
        }
        return null;
      })}
    </div>
  );
};

export default ImageSelector;
