export default function isVideo(src) {
  if (src) {
    const ext = src.split(".").pop().toLowerCase();
    if (
      ext === "m4v" ||
      ext === "avi" ||
      ext === "mpg" ||
      ext === "mp4" ||
      ext === "mkv" ||
      ext === "gif" ||
      ext === "mov"
    ) {
      return true;
    }
    const dataURI = src.split(";")[0].toLowerCase();
    if (
      dataURI === "data:video/m4v" ||
      dataURI === "data:video/avi" ||
      dataURI === "data:video/mpg" ||
      dataURI === "data:video/mp4" ||
      dataURI === "data:video/mkv" ||
      dataURI === "data:video/gif" ||
      dataURI === "data:video/mov" ||
      dataURI === "data:video/quicktime"
    ) {
      return true;
    }
  }
  return false;
}
