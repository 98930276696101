import React from "react";
import BarChartWithDates from "Reports/BarChartWithDates";

export default function Leads() {
  return (
    <BarChartWithDates
      path="/reports/leads"
      summary={true}
      type="count"
      report="LEADS"
      headers={["Salesman", "Total"]}
    />
  );
}
