import React from "react";
import Carousel from "antd/es/carousel";
import styles from "./styles.module.scss";

const Reviews = () => {
  return (
    <div style={{ width: "100%", height: "100%" }} className={styles.partners}>
      <Carousel autoplay={true} dots={false} autoplaySpeed={9000}>
        <Slide
          userName="Keith Hoffer"
          social=""
          review={`Premier Roofing is a GREAT company to work with. They are
                  friendly, knowledgeable and their work ethic is impeccable.`}
        />
        <Slide
          userName="Terry Michael"
          social="facebook"
          review={`Very professional and great detailed work and very thorough on
                  the cleanup before during and after. Highly recommend Premier
                  roofing &amp; exteriors`}
        />

        <Slide
          userName="Kurtis Dame"
          social=""
          review={`Wayne came out to look at a roof leak and went above and
                  beyond to fix it for us. I will continue to use Premier when
                  we get our roof completely replaced. Highly recommend!`}
        />

        <Slide
          userName="Tricia VanderLaan"
          social=""
          review={`We had a great experience with Premier Roofing. Very easy to
                  work with and the work they did was very well done. Definitely
                  would recommend!`}
        />

        <Slide
          userName="Kristen Campbell"
          social=""
          review={`Premier Roofing and Exteriors was great to work with! They
                  handled everything! Worked with my insurance company so all I
                  had to do was sign a paper! The workers worked from early in
                  the morning to sundown. They were all very polite and I love
                  my new roof!!!`}
        />
      </Carousel>
    </div>
  );
};

const Slide = ({ userName, social, review }) => {
  return (
    <div className={styles.slide}>
      <div className={styles.reviewWrapper} data-type="review-box">
        <div className={styles.innerWrapper}>
          <div>
            <div className={styles.titleContainer}>
              <span
                className={`${styles.sourceLogo} ${
                  social === "facebook" ? styles.facebookLogo : ""
                }`}
                role="img"
                aria-label="google logo"
              ></span>
              <span className={styles.userName}>{userName}</span>
              <div className={styles.stars}>
                <svg height="16" width="16" role="img" aria-label="star">
                  <path
                    d="M8 .391l2.446 5.045 5.555 0.767-4.043 3.885 0.987 5.521-4.944-2.644-4.945 2.462 0.987-5.519-4.043-3.885 5.555-0.526z"
                    fill="#ffdc00"
                  ></path>
                </svg>
                <svg height="16" width="16" role="img" aria-label="star">
                  <path
                    d="M8 .391l2.446 5.045 5.555 0.767-4.043 3.885 0.987 5.521-4.944-2.644-4.945 2.462 0.987-5.519-4.043-3.885 5.555-0.526z"
                    fill="#ffdc00"
                  ></path>
                </svg>
                <svg height="16" width="16" role="img" aria-label="star">
                  <path
                    d="M8 .391l2.446 5.045 5.555 0.767-4.043 3.885 0.987 5.521-4.944-2.644-4.945 2.462 0.987-5.519-4.043-3.885 5.555-0.526z"
                    fill="#ffdc00"
                  ></path>
                </svg>
                <svg height="16" width="16" role="img" aria-label="star">
                  <path
                    d="M8 .391l2.446 5.045 5.555 0.767-4.043 3.885 0.987 5.521-4.944-2.644-4.945 2.462 0.987-5.519-4.043-3.885 5.555-0.526z"
                    fill="#ffdc00"
                  ></path>
                </svg>
                <svg height="16" width="16" role="img" aria-label="star">
                  <path
                    d="M8 .391l2.446 5.045 5.555 0.767-4.043 3.885 0.987 5.521-4.944-2.644-4.945 2.462 0.987-5.519-4.043-3.885 5.555-0.526z"
                    fill="#ffdc00"
                  ></path>
                </svg>
              </div>
            </div>
            <div className={styles.review}>{review}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
