import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Input from "antd/es/input";
import Form from "antd/es/form";
import Select from "antd/es/select";
import Button from "antd/es/button";
import message from "antd/es/message";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import Attachments from "Components/images/Attachments";
import api from "API";
import CancelButton from "Components/CancelButton";
import Divider from "antd/es/divider";
import dayjs from "dayjs";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";
import Suppliers from "Components/Inputs/Suppliers";
import useSuppliers from "Hooks/useSuppliers";
import Steps from "antd/es/steps";
import styles from "./styles.module.scss";
import ImageSelector from "Components/images/ImageSelector";
import { unstable_batchedUpdates } from "react-dom";

import ProcessFeedback from "Components/ProcessFeedback";

const messageKey = "messageKey";

const steps = [
  "Submitting Form",
  "Generating PDF",
  "Saving PDF",
  "Creating Post",
  "Sending Emails",
  "Updating Scope",
  "Done!",
];

const ComposeEmail = ({
  unsavedChanges,
  setUnsavedChanges,
  handleClose,
  type,
}) => {
  const formDetails = useSelector(
    (state) => state.formState.materialEmail.formDetails
  );
  const [attachments, setAttachments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [step, setStep] = useState(0);
  const [scrollContainer, setScrollContainer] = useState(null);
  const [_allMedia, _setAllMedia] = useState([]);
  const suppliers = useSuppliers();

  const _user = useSelector((state) => state.authState._user);
  const [feedBack, setFeedBack] = useState({
    active: "Submitting Form",
    progress: 0,
  });

  useEffect(() => {
    getAllMedia();
    setTimeout(() => {
      const el = document.getElementById("steps");
      el.classList.remove("ant-steps-vertical");
      el.classList.add("ant-steps-horizontal");
      el.classList.add("ant-steps-label-vertical");
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { supplier } = formDetails;
  useEffect(() => {
    if (supplier) {
      if (suppliers && Object.keys(suppliers).length > 0) {
        Object.keys(suppliers).map((s) => {
          if (suppliers[s].companyName === supplier) {
            form.setFieldsValue({
              suppliers: suppliers[s]._sub,
              supplierEmail: suppliers[s].email,
            });
          }
          return s;
        });
      }
    }
  }, [supplier, suppliers]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const scrollRef = document.getElementById("material-order-scroll");
    if (scrollRef) scrollRef.scrollTop = 0;
  }, [step]);

  const getAllMedia = async () => {
    try {
      const res = await api.get(`/get-media/${formDetails._projectId}`);

      const allMedia = res.data._media.concat(res.data._documents);
      const workOrder = allMedia.find(
        (m) => m.src === formDetails.workOrderSrc
      );

      unstable_batchedUpdates(() => {
        _setAllMedia(res.data._media.concat(res.data._documents));
        setAttachments([workOrder]);
      });
    } catch (err) {
      console.log("getAllMedia err", err);
    }
  };

  const updateFeedBack = (feedBack) => {
    setFeedBack(feedBack);
  };

  const onFinish = async () => {
    try {
      window.socket.on(`${_user}-work-order-progress`, updateFeedBack);
      unstable_batchedUpdates(() => {
        setFeedBack({
          active: "Submitting Form",
          progress: 1,
        });
        setLoading(true);
      });

      let values = await form.validateFields();

      // message.loading("Sending Email...", 0);
      message.loading({
        content: "Ordering material...",
        duration: 0,
        key: messageKey,
      });
      values._projectId = formDetails._projectId;
      values._attachments = attachments;

      values = {
        ...formDetails,
        ...values,
        // deliveryDate: formDetails.deliveryDate,
        // deliveryOptions: formDetails.deliveryOptions,
        // hoNotified: formDetails.hoNotified,
        oldWorkOrderSrc: formDetails.workOrderSrc,
      };

      values.suppliers = suppliers[values.suppliers].companyName;

      const res = await api.post("/ready-for-material/ordered", values);

      if (formDetails.callback) {
        formDetails.callback(res.data);
      }

      setFeedBack({
        active: "Done!",
        progress: 100,
      });
      message.success({
        content: "Materials ordered",
        duration: 1.5,
        key: messageKey,
      });
      window.socket.removeAllListeners(`${_user}-work-order-progress`);
      handleClose(true);
    } catch (err) {
      if (
        err &&
        err.errorFields &&
        err.errorFields[0] &&
        err.errorFields[0].name
      ) {
        if (err.errorFields[0].name[0] === "lineItems") {
          const el = document.getElementById("top-of-materail-form");
          el.scrollIntoView({ block: "end", behavior: "smooth" });
        } else {
          form.scrollToField(err.errorFields[0].name[0]);
        }
      }
      // message.error("Error sending email");
      message.error({
        content: "Error ordering material",
        duration: 1.5,
        key: messageKey,
      });
      setLoading(false);
      setFeedBack({
        active: "Submitting Form",
        progress: 0,
      });
      window.socket.removeAllListeners(`${_user}-work-order-progress`);
    }
  };

  const onFinishFailed = (err) => {
    console.log("err", err);
    if (
      err &&
      err.errorFields &&
      err.errorFields[0] &&
      err.errorFields[0].name
    ) {
      if (err.errorFields[0].name[0] === "lineItems") {
        const el = document.getElementById("top-of-material-form");
        el.scrollIntoView({ block: "end", behavior: "smooth" });
      } else {
        form.scrollToField(err.errorFields[0].name[0]);
      }
    }
    message.error("Error sending email");
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  const removeImage = (img) => {
    setAttachments(attachments.filter((i) => i._id !== img._id));
  };

  const cancel = () => {
    handleClose();
  };

  const next = () => {
    setStep(1);
  };

  const previous = () => {
    setStep(0);
  };

  const handleSupplierChange = async (value) => {
    try {
      if (value) {
        let newValue = [suppliers[value].email];
        form.setFieldsValue({
          supplierEmail: suppliers[value].email,
        });
        let toValue = await form.getFieldValue("to");
        if (toValue) {
          for (const t of toValue) {
            newValue.push(t);
          }
        }
        form.setFieldsValue({
          to: newValue,
        });
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <Form
      form={form}
      className="form"
      autoComplete="off"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      scrollToFirstError={true}
      initialValues={{
        // suppliers: formDetails.supplier,
        to: formDetails.emails ? [...formDetails.emails] : undefined,
        subject: `${formDetails.customerName} - ${formDetails.customerId}`,
        body: `Attached is the material order. Please deliver ${
          formDetails.deliveryType
        } on ${dayjs(formDetails.deliveryDate).format("MMMM DD, YYYY")} at ${
          formDetails.deliveryOptions
        }.\n\nNotify our install and project manager of any backorders or if you're unable to fulfill the purchase order on the requested date and time.`,
      }}
    >
      <RemoveAutocomplete />

      <div id="top-of-material-form" />

      <FormBody>
        <div
          id="material-order-scroll"
          className="content-inner"
          ref={setScrollContainer}
          style={{
            paddingLeft: 24,
            paddingTop: 24,
            paddingRight: 24,
            paddingBottom: 8,
            maxHeight: type === "modal" ? "calc(100vh - 161px)" : "unset",
          }}
        >
          <Steps
            id="steps"
            className={styles.soldSteps}
            labelPlacement="vertical"
            size="small"
            current={step}
            style={{ marginBottom: 24 }}
          >
            <Steps.Step title="Files" />
            <Steps.Step title="Email" />
          </Steps>

          <div className={step === 0 && feedBack.progress === 0 ? "" : "hide"}>
            <ImageSelector
              allMedia={_allMedia}
              attachments={attachments}
              setAttachments={setAttachments}
              scrollContainer={scrollContainer}
            />
          </div>

          <div className={step === 1 && feedBack.progress === 0 ? "" : "hide"}>
            <Form.Item
              name="suppliers"
              label="Suppliers"
              rules={[
                {
                  required: true,
                  message: "You must select a supplier",
                },
              ]}
            >
              <Suppliers
                suppliers={suppliers}
                onChange={handleSupplierChange}
              />
            </Form.Item>

            <Form.Item name="supplierEmail" hidden>
              <Input />
            </Form.Item>

            <Divider />

            <Form.Item
              name="to"
              rules={[
                {
                  required: true,
                  message: "You must include at least one email",
                },
              ]}
            >
              <Select mode="tags" placeholder="To" />
            </Form.Item>

            <Form.Item
              name="subject"
              rules={[
                {
                  required: true,
                  message: "You must include a subject",
                },
              ]}
            >
              <InputNoAutoComplete placeholder="Subject" id="subject" />
            </Form.Item>

            <Form.Item
              name="body"
              rules={[{ required: true, message: "You must include a body" }]}
            >
              <Input.TextArea rows={4} placeholder="Compose Email" />
            </Form.Item>

            {attachments.length > 0 && (
              <Attachments
                attachments={attachments}
                removeImage={removeImage}
              />
            )}
          </div>

          <div
            style={{ height: "100%" }}
            className={feedBack.progress === 0 ? "hide" : ""}
          >
            <ProcessFeedback feedBack={feedBack} steps={steps} />
          </div>
        </div>
      </FormBody>
      {feedBack.progress === 0 && (
        <FormControls className="sticky-footer">
          {step === 1 ? (
            <>
              <Button
                type="text"
                danger
                style={{ padding: "4px 8px", float: "left" }}
                onClick={previous}
              >
                Back
              </Button>
              <Button
                type="primary"
                onClick={onFinish}
                loading={loading}
                disabled={loading}
              >
                Send
              </Button>
            </>
          ) : (
            <>
              <CancelButton handleClick={cancel} />
              <Button type="primary" onClick={next}>
                Compose Email
              </Button>
            </>
          )}
        </FormControls>
      )}
    </Form>
  );
};

export default ComposeEmail;
