import React, { useEffect, useState, useCallback } from "react";
import Grid from "antd/es/grid";
import { setSelected } from "Actions/dataActions";
import { useDispatch, useSelector } from "react-redux";
import { openForm } from "Actions/formActions";
import {
  fetchTableFilters,
  unsetTable,
  fetchTable,
  setScrollbar,
} from "Actions/tableActions";
import Table from "Components/Table";
import TableRow from "Components/Table/TableItems/UsersItem";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as Vlist } from "react-window";
import usePrevious from "Hooks/usePrevious";
import useIsMobile from "Hooks/useIsMobile";
import isEmpty from "Utils/isEmpty";

const { useBreakpoint } = Grid;

const headers = [
  {
    _name: "lName",
    name: "LAST NAME",
    width: "20%",
  },
  {
    _name: "fName",
    name: "FIRST NAME",
    width: "15%",
  },
  {
    _name: "status",
    name: "STATUS",
    width: "15%",
  },
  {
    _name: "role",
    name: "ROLE",
    width: "15%",
  },
  {
    _name: "manager",
    name: "MANAGER",
    width: "10%",
  },
  {
    _name: "admin",
    name: "ADMIN",
    width: "10%",
  },
  {
    _name: "created",
    name: "CREATED",
    width: "15%",
    align: "right",
  },
];

const filters = [
  {
    title: "Created",
    type: "datepicker",
    fields: [
      {
        name: "created-start",
        placeholder: "Created After",
      },
      {
        name: "created-end",
        placeholder: "Created Before",
      },
    ],
  },
  {
    title: "Status",
    type: "multi-select",
    fields: [
      {
        name: "status",
        placeholder: "Select an Option(s)",
        values: ["Active", "Suspended", "Deactivate"],
      },
    ],
  },
  {
    title: "Role",
    type: "multi-select",
    fields: [
      {
        name: "role",
        placeholder: "Select an Option(s)",
        values: ["Canvasser", "Office", "Sales"],
      },
    ],
  },
  {
    title: "Manager",
    type: "multi-select",
    fields: [
      {
        name: "manager",
        placeholder: "Select an Option(s)",
        values: ["Yes", "No"],
      },
    ],
  },
  {
    title: "Admin",
    type: "multi-select",
    fields: [
      {
        name: "admin",
        placeholder: "Select an Option(s)",
        values: ["Yes", "No"],
      },
    ],
  },
];

const Users = () => {
  const screens = useBreakpoint();
  const mobile = useIsMobile();
  const prevScreens = usePrevious(screens);
  const rows = useSelector((state) => state.tableState.rows);
  const fetching = useSelector((state) => state.tableState.fetching);
  const search = useSelector((state) => state.tableState.search);
  const activeFilter = useSelector((state) => state.tableState.activeFilter);
  const table = useSelector((state) => state.tableState.table);
  const col = useSelector((state) => state.tableState.col);
  const order = useSelector((state) => state.tableState.order);
  const collapsed = useSelector((state) => state.settingsState.collapsed);
  const dispatch = useDispatch();
  const [itemSize, setItemSize] = useState(135);

  useEffect(() => {
    initTable();

    return () => dispatch(unsetTable());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTimeout(() => {
      dispatch(setScrollbar());
    });
  }, [rows, dispatch]);

  const initTable = useCallback(() => {
    dispatch(
      fetchTableFilters({
        table: "users",
        col: "lName",
        order: 1,
        search,
      })
    );
  }, [dispatch, search]);

  useEffect(() => {
    if (prevScreens && screens) {
      if (prevScreens.md && !screens.md) {
        setItemSize(135);
      } else if (!prevScreens.md && screens.md) {
        setItemSize(47);
      }
    }
  }, [screens, prevScreens]);

  const Row = ({ index, style }) => {
    const item = rows[index];
    return (
      <TableRow
        style={style}
        key={item._id}
        {...item}
        headers={headers}
        callback={callback}
      />
    );
  };

  const callback = useCallback(() => {
    dispatch(setSelected(null));
    dispatch(
      fetchTable({
        table,
        col,
        order,
        search,
        activeFilter,
        fetching: false,
      })
    );
  }, [dispatch, table, col, order, search, activeFilter]);

  const addFunc = useCallback(() => {
    dispatch(openForm("user", { callback }));
  }, [dispatch, callback]);

  return (
    <>
      <Table
        loading={fetching}
        filter={
          search ||
          (activeFilter && activeFilter.filter && !isEmpty(activeFilter.filter))
            ? true
            : false
        }
        mobile={mobile}
        sider={screens.lg}
        collapsed={collapsed}
      >
        <Table.Header filters={filters} addFunc={addFunc} />

        <Table.Filter
          hide={
            search ||
            (activeFilter &&
              activeFilter.filter &&
              !isEmpty(activeFilter.filter))
              ? false
              : true
          }
        />

        {!mobile && <Table.ColumnHeaders headers={headers} />}
        <Table.Body loading={fetching}>
          {rows.length > 0 && (
            <AutoSizer>
              {({ height, width }) => (
                <Vlist
                  height={height}
                  itemCount={rows.length}
                  itemSize={itemSize}
                  width={width}
                >
                  {Row}
                </Vlist>
              )}
            </AutoSizer>
          )}
        </Table.Body>
      </Table>
    </>
  );
};

export default Users;
