import { useEffect, useState } from "react";
import api from "API";
let isMounted = false;

export default function useVendors() {
  const [vendors, setVendors] = useState({});

  useEffect(() => {
    isMounted = true;
    fetchVendors();

    return () => (isMounted = false);
  }, []);

  const fetchVendors = async () => {
    try {
      const res = await api.post("/vendors/get", {});
      let arr = {};
      for (const s of res.data) {
        arr[s._id] = {
          _id: s._id,
          companyName: s.companyName,
        };
      }
      arr["1234"] = {
        _id: "1234",
        companyName: "Other",
      };

      if (isMounted) setVendors(arr);
    } catch (err) {
      console.log("err", err);
    }
  };

  return vendors;
}
