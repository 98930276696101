import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openForm } from "Actions/formActions";
import Card from "antd/es/card";
import styles from "./styles.module.scss";
import ActionButtons from "Components/Details/ActionButtons";
import currencyFormatter from "Utils/currencyFormatter";
import MoldBreakdownPanel from "./MoldBreakdownPanel";
import Collections from "./Collections";
import Expenses from "./Expenses";
// import ExpensesByVendor from "./ExpensesByVendor";
// import ExpensesByScope from "./ExpensesByScope";
import ScopeBreakdown from "./ScopeBreakdown";
import Invoices from "./Invoices";
// import Actions from "./Actions";
import Dumpings from "./Dumpings";
import Documents from "./Documents";
import Add from "Icons/Add";
import Button from "antd/es/button";
import Divider from "antd/es/divider";
import CompletionCertifications from "./CompletionCertifications";

const Related = ({ lead = false }) => {
  const admin = useSelector((state) => state.authState.admin);
  const role = useSelector((state) => state.authState.role);
  const email = useSelector((state) => state.authState.email);
  const invoices = useSelector((state) => state.dataState.invoices);
  const completionCertificates = useSelector(
    (state) => state.dataState.completionCertificates
  );
  // const actions = useSelector((state) => state.dataState.actions);
  const expenses = useSelector((state) => state.dataState.expenses);
  // const expensesByVendor = useSelector(
  //   (state) => state.dataState.expensesByVendor
  // );
  const expensesByScope = useSelector(
    (state) => state.dataState.expensesByScope
  );

  const collections = useSelector((state) => state.dataState.collections);
  const mold = useSelector((state) => state.dataState.mold);
  const breakdown = useSelector((state) => state.dataState.breakdown);
  // const totalActions = useSelector((state) => state.dataState.totalActions);
  const totalExpenses = useSelector((state) => state.dataState.totalExpenses);
  const totalCollections = useSelector(
    (state) => state.dataState.totalCollections
  );
  // const totalInvoiced = useSelector((state) => state.dataState.totalInvoiced);
  const dumpings = useSelector((state) => state.dataState.dumpings);
  const documents = useSelector((state) => state.dataState.documents);
  const phone = useSelector((state) => state.dataState.details.phone);
  const contractPrice = useSelector(
    (state) => state.dataState.details.contractPrice
  );
  const expectedBalance = useSelector(
    (state) => state.dataState.mold.expectedBalance
  );
  const expectedDumping = useSelector(
    (state) => state.dataState.mold.expectedDumping
  );
  const expectedExtras = useSelector(
    (state) => state.dataState.mold.expectedExtras
  );
  const expectedGutters = useSelector(
    (state) => state.dataState.mold.expectedGutters
  );
  const expectedInsulation = useSelector(
    (state) => state.dataState.mold.expectedInsulation
  );
  const expectedLabor = useSelector(
    (state) => state.dataState.mold.expectedLabor
  );
  const expectedMaterials = useSelector(
    (state) => state.dataState.mold.expectedMaterials
  );
  const expectedOverhead = useSelector(
    (state) => state.dataState.mold.expectedOverhead
  );
  const actualDumping = useSelector(
    (state) => state.dataState.breakdown.actualDumping
  );
  const actualOverhead = useSelector(
    (state) => state.dataState.breakdown.actualOverhead
  );
  const actualExtras = useSelector(
    (state) => state.dataState.breakdown.actualExtras
  );
  const actualLabor = useSelector(
    (state) => state.dataState.breakdown.actualLabor
  );
  const actualMaterials = useSelector(
    (state) => state.dataState.breakdown.actualMaterials
  );
  const actualBalance = useSelector(
    (state) => state.dataState.breakdown.actualBalance
  );
  const actualPermit = useSelector(
    (state) => state.dataState.breakdown.actualPermit
  );
  const dispatch = useDispatch();
  const [totalInvoiced, setTotalInvoiced] = useState(0);
  const [remainingBalance, setRemainingBalance] = useState(0);

  const isProjectManager =
    email === "clong@gopremier.net"
      ? true
      : email === "wmills@gopremier.net"
      ? true
      : email === "ksmith@gopremier.net"
      ? true
      : // : email === "chowell@gopremier.net"
        // ? true
        false;

  useEffect(() => {
    let _totalInvoiced = 0;
    for (const invoice of invoices) {
      for (const lineItem of invoice.lineItems) {
        if (lineItem.scopeName !== "Not Billable Detail") {
          _totalInvoiced += lineItem.amount;
        }
      }
    }

    setTotalInvoiced(_totalInvoiced);
    setRemainingBalance(_totalInvoiced - totalCollections);
  }, [invoices, totalCollections]);

  const openDocument = useCallback(() => {
    dispatch(openForm("documentForm"));
  }, [dispatch]);

  const openInvoice = useCallback(() => {
    dispatch(openForm("invoice"));
  }, [dispatch]);

  const openCompletionCertificate = useCallback(() => {
    dispatch(openForm("completionCertificate"));
  }, [dispatch]);

  const openExpense = useCallback(() => {
    dispatch(openForm("expense"));
  }, [dispatch]);

  const openCollection = useCallback(() => {
    dispatch(openForm("collection"));
  }, [dispatch]);

  // const openAction = useCallback(() => {
  //   dispatch(openForm("action"));
  // }, [dispatch]);

  const openDumping = useCallback(() => {
    dispatch(openForm("dumping"));
  }, [dispatch]);

  return (
    <div
      style={{
        height: "calc(100% - 64px - 34px)",
        position: "fixed",
        width: "100%",
        overflow: "hidden",
        background: "#ebebeb",
      }}
    >
      <div
        id="related-container"
        className="content-inner p12"
        style={{ overflowX: "hidden" }}
      >
        <ActionButtons ghost narrow />

        <Card
          className={styles.relatedContainer}
          bordered={false}
          title={<span className={styles.h4}>Documents</span>}
          extra={
            <span>
              <span className={styles.h4}>
                {documents ? documents.length : 0}
              </span>
              <Divider type="vertical" />
              <Button
                size="small"
                type="text"
                shape="circle"
                onClick={openDocument}
              >
                <Add />
              </Button>
            </span>
          }
          bodyStyle={{ padding: 0 }}
        >
          <Documents documents={documents} />
        </Card>

        {!lead && (
          <>
            <Card
              className={styles.relatedContainer}
              bordered={false}
              title={<span className={styles.h4}>Invoices</span>}
              extra={
                <span>
                  <span className={styles.h4}>
                    {currencyFormatter(totalInvoiced)}
                  </span>
                  <Divider type="vertical" />
                  <Button
                    size="small"
                    type="text"
                    shape="circle"
                    onClick={openInvoice}
                  >
                    <Add />
                  </Button>
                </span>
              }
              bodyStyle={{ padding: 0 }}
            >
              <Invoices
                invoices={invoices}
                totalInvoiced={totalInvoiced}
                phone={phone}
              />
            </Card>

            <Card
              className={styles.relatedContainer}
              bordered={false}
              title={<span className={styles.h4}>Expenses</span>}
              extra={
                <span>
                  <span className={styles.h4}>
                    {currencyFormatter(totalExpenses)}
                  </span>
                  {(admin === "Yes" || role === "Office") && (
                    <>
                      <Divider type="vertical" />
                      <Button
                        size="small"
                        type="text"
                        shape="circle"
                        onClick={openExpense}
                      >
                        <Add />
                      </Button>
                    </>
                  )}
                </span>
              }
              bodyStyle={{ padding: 0 }}
            >
              <Expenses expenses={expenses} />
            </Card>

            <Card
              className={styles.relatedContainer}
              bordered={false}
              title={<span className={styles.h4}>Collections</span>}
              extra={
                <span>
                  <span className={styles.h4}>
                    {currencyFormatter(totalCollections)}
                  </span>
                  {(admin === "Yes" || role === "Office") && (
                    <>
                      <Divider type="vertical" />
                      <Button
                        size="small"
                        type="text"
                        shape="circle"
                        onClick={openCollection}
                      >
                        <Add />
                      </Button>
                    </>
                  )}
                </span>
              }
              bodyStyle={{ padding: 0 }}
            >
              <Collections
                collections={collections}
                totalCollections={totalCollections}
                remainingBalance={remainingBalance}
              />
            </Card>

            {/* <Card
              className={styles.relatedContainer}
              bordered={false}
              title={<span className={styles.h4}>Invoice Actions</span>}
              extra={
                <span>
                  <span className={styles.h4}>
                    {currencyFormatter(totalActions)}
                  </span>
                  <Divider type="vertical" />
                  <Button
                    size="small"
                    type="text"
                    shape="circle"
                    onClick={openAction}
                  >
                    <Add />
                  </Button>
                </span>
              }
              bodyStyle={{ padding: 0 }}
            >
              <Actions actions={actions} />
            </Card> */}

            <Card
              className={styles.relatedContainer}
              bordered={false}
              title={<span className={styles.h4}>Dumping</span>}
              extra={
                <span>
                  <span className={styles.h4}>
                    {dumpings ? dumpings.length : 0}
                  </span>
                  <Divider type="vertical" />
                  <Button
                    size="small"
                    type="text"
                    shape="circle"
                    onClick={openDumping}
                  >
                    <Add />
                  </Button>
                </span>
              }
              bodyStyle={{ padding: 0 }}
            >
              <Dumpings dumpings={dumpings} />
            </Card>

            <Card
              className={styles.relatedContainer}
              bordered={false}
              title={<span className={styles.h4}>Invoice Summary</span>}
              extra={
                <span>
                  <Button
                    size="small"
                    type="text"
                    shape="circle"
                    onClick={openCompletionCertificate}
                  >
                    <Add />
                  </Button>
                </span>
              }
              bodyStyle={{ padding: 0 }}
            >
              <CompletionCertifications
                completionCertificates={completionCertificates}
              />
            </Card>

            <Card
              className={styles.relatedContainer}
              bordered={false}
              title={<span className={styles.h4}>Mold</span>}
              extra={
                isProjectManager ? undefined : (
                  <span className={styles.h4}>
                    {currencyFormatter(mold.expectedBalance)}
                  </span>
                )
              }
              bodyStyle={{ padding: 0 }}
            >
              <MoldBreakdownPanel
                type="mold"
                price={contractPrice}
                materials={expectedMaterials}
                overhead={expectedOverhead}
                labor={expectedLabor}
                dumping={expectedDumping}
                extras={expectedExtras}
                gutters={expectedGutters}
                insulation={expectedInsulation}
                balance={expectedBalance}
                isProjectManager={isProjectManager}
              />
            </Card>

            <Card
              className={styles.relatedContainer}
              bordered={false}
              title={<span className={styles.h4}>Projected Breakdown</span>}
              extra={
                isProjectManager ? undefined : (
                  <span className={styles.h4}>
                    {currencyFormatter(breakdown.actualBalance)}
                  </span>
                )
              }
              bodyStyle={{ padding: 0 }}
            >
              <MoldBreakdownPanel
                type="breakdown"
                price={totalCollections}
                materials={actualMaterials}
                overhead={actualOverhead}
                labor={actualLabor}
                dumping={actualDumping}
                permit={actualPermit}
                extras={actualExtras}
                balance={actualBalance}
                isProjectManager={isProjectManager}
              />
            </Card>

            {/* <Card
              className={styles.relatedContainer}
              bordered={false}
              title={<span className={styles.h4}>Expenses By Scope</span>}
              bodyStyle={{ padding: 0 }}
            >
              <ExpensesByScope expensesByScope={expensesByScope} />
            </Card>

            <Card
              className={styles.relatedContainer}
              bordered={false}
              title={<span className={styles.h4}>Expenses By Vendor</span>}
              bodyStyle={{ padding: 0 }}
            >
              <ExpensesByVendor expensesByVendor={expensesByVendor} />
            </Card> */}

            {expensesByScope.map((scope) => (
              <Card
                key={scope._id}
                className={styles.relatedContainer}
                bordered={false}
                title={
                  <span className={styles.h4}>
                    {scope.scope} - Scope Breakdown
                  </span>
                }
                bodyStyle={{ padding: 0 }}
              >
                <ScopeBreakdown
                  invoicedAmount={scope.invoicedAmount}
                  expensedAmount={scope.expensedAmount}
                  materialExpenses={scope.materialExpenses}
                  laborExpenses={scope.laborExpenses}
                  dumpingExpenses={scope.dumpingExpenses}
                  permitExpenses={scope.permitExpenses}
                />
              </Card>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default Related;
