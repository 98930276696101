import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { openForm } from "Actions/formActions";
import List from "antd/es/list";
import styles from "./styles.module.scss";
import MapMarker from "Icons/MapMarker";

const PermitAuthorities = ({ municipalities, _setMunicipalities }) => {
  return (
    <>
      {municipalities.length > 0 ? (
        municipalities.map((municipality) => (
          <List
            bordered
            key={municipality._id}
            itemLayout="horizontal"
            className={styles.list}
          >
            <MunicipalityItem
              municipality={municipality}
              _setMunicipalities={_setMunicipalities}
            />
          </List>
        ))
      ) : (
        <List itemLayout="horizontal" className={styles.list}>
          <List.Item className={`${styles.transactionList} ${styles.none}`}>
            <List.Item.Meta
              avatar={
                <div className={styles.avatar}>
                  <MapMarker color="#108ee9" />
                </div>
              }
              title="No municipalities match your search"
            />
          </List.Item>
        </List>
      )}
    </>
  );
};

const MunicipalityItem = ({ municipality, _setMunicipalities }) => {
  const dispatch = useDispatch();

  const updateMunicipalities = useCallback(
    (data) => {
      _setMunicipalities(data);
    },
    [_setMunicipalities]
  );

  const editPermitAuthority = useCallback(() => {
    dispatch(
      openForm("permitAuthority", {
        ...municipality,
        editing: true,
        update: updateMunicipalities,
      })
    );
  }, [dispatch, municipality, updateMunicipalities]);

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <List.Item className={styles.transactionList}>
      <List.Item.Meta
        avatar={
          <div className={styles.avatar}>
            <MapMarker color="#108ee9" />
          </div>
        }
        title={municipality.location}
        description={
          municipality.required ? (
            <>
              {municipality.sendTo && (
                <div>
                  <b>Send To</b>: {municipality.sendTo}
                </div>
              )}
              {municipality.amount.length > 0 && (
                <>
                  <div>
                    <b>Amounts</b>:
                  </div>
                  {municipality.amount.map((a) => {
                    if (a.amount !== "Fee Schedule") {
                      return (
                        <div
                          style={{ marginBottom: 16, marginLeft: 16 }}
                          key={a.type}
                        >
                          <div>
                            <b>Type: </b> {a.type}
                          </div>
                          <div>
                            <b>Amount: </b>{" "}
                            <span style={{ whiteSpace: "pre-wrap" }}>
                              {a.amount}
                            </span>
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          style={{ marginBottom: 16, marginLeft: 16 }}
                          key={a.type}
                        >
                          <div>
                            <b>Type: </b> {a.type}
                          </div>
                          <div>
                            <a
                              href={a.feeSchedule}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={stopPropagation}
                            >
                              {a.amount}
                            </a>
                          </div>
                        </div>
                      );
                    }
                  })}
                </>
              )}

              {municipality.instructions.length > 0 && (
                <>
                  <div>
                    <b>Instructions: </b>
                  </div>
                  {municipality.instructions.map((i) => (
                    <div
                      style={{ marginBottom: 16, marginLeft: 16 }}
                      key={i.text + i.url}
                    >
                      <div>
                        {i.url ? (
                          <a
                            onClick={(e) => e.stopPropagation()}
                            target="_BLANK"
                            href={i.url}
                            rel="noopener noreferrer"
                          >
                            {i.text}
                          </a>
                        ) : (
                          i.text
                        )}
                      </div>
                    </div>
                  ))}
                </>
              )}

              {municipality.inspectors.length > 0 && (
                <>
                  <div>
                    <b>Inspectors: </b>
                  </div>
                  {municipality.inspectors.map((i) => (
                    <div
                      style={{ marginBottom: 16, marginLeft: 16 }}
                      key={i.name + i.phone}
                    >
                      <div>
                        <b>Name: </b> {i.name}
                      </div>
                      <div>
                        <b>Area: </b> {i.area}
                      </div>
                      <div>
                        <b>Phone: </b> {i.phone}
                      </div>
                    </div>
                  ))}
                </>
              )}
            </>
          ) : (
            <>
              <div>Permit is not required</div>
            </>
          )
        }
        onClick={editPermitAuthority}
      />
    </List.Item>
  );
};

export default PermitAuthorities;
