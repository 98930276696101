import React, { useCallback, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { openImageViewer } from "Actions/dataActions";
import openInNewTab from "Utils/openInNewTab";
import isPdf from "Utils/isPdf";
import isVideo from "Utils/isVideo";
import styles from "./styles.module.scss";
import YouTube from "Icons/YouTube";
import pdf from "Assets/pdf.svg";
import ImageFallback from "Components/images/Image";

const CommentImage = ({ image, removeImage }) => {
  const dispatch = useDispatch();
  const _id = useSelector((state) => state.dataState.details._id);

  const openImage = useCallback(
    (image) => {
      if (removeImage) image.setImages = removeImage;
      image._projectId = _id;
      image.viewOnly = true;
      dispatch(openImageViewer(image));
    },
    [removeImage, _id, dispatch]
  );

  const imageClick = () => {
    !isPdf(image.src) ? openImage(image) : openInNewTab(image.src);
  };

  return (
    <div key={image._id} className={styles.commentImage} onClick={imageClick}>
      {isPdf(image.src) ? (
        <img src={pdf} alt="pdf-icon" />
      ) : !isVideo(image.src) ? (
        <ImageFallback alt="comment-image" image={image} />
      ) : (
        <YouTube color="#f1f1f1" size={64} />
      )}
    </div>
  );
};

export default memo(CommentImage);
