import React from "react";
import BarChartWithDates from "Reports/BarChartWithDates";

export default function LeadConversionRateByOrigin() {
  return (
    <BarChartWithDates
      path="/reports/leadsConversionRateByOrigin"
      summary={true}
      type="percent"
      report="LEAD CONVERSION RATE BY ORIGIN"
      headers={["Origin", "Leads", "Sales", "Convert %"]}
    />
  );
}
