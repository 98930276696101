import React from "react";
import Form from "antd/es/form";
import Input from "antd/es/input";
import DatePicker from "Components/Inputs/DatePicker";
import Checkbox from "antd/es/checkbox";
import Phone from "Components/Inputs/Phone";
import Currency from "Components/Inputs/Currency";
import Percent from "Components/Inputs/Percent";

const HiddenFields = ({ mold }) => {
  return (
    <>
      <Form.Item hidden name="_id">
        <Input tabIndex="-1" />
      </Form.Item>

      <Form.Item hidden name="_projectId">
        <Input tabIndex="-1" />
      </Form.Item>

      <Form.Item hidden name="_activityId">
        <Input tabIndex="-1" />
      </Form.Item>

      <Form.Item hidden name="name">
        <Input tabIndex="-1" />
      </Form.Item>

      <Form.Item hidden name="customerId">
        <Input tabIndex="-1" />
      </Form.Item>

      <Form.Item hidden name="woCreated" valuePropName="checked">
        <Checkbox tabIndex="-1" />
      </Form.Item>

      <Form.Item hidden name="addFlat" valuePropName="checked">
        <Checkbox tabIndex="-1" />
      </Form.Item>

      <Form.Item hidden name="version">
        <Input tabIndex="-1" />
      </Form.Item>

      <Form.Item hidden name="_projectManagerId">
        <Input tabIndex="-1" />
      </Form.Item>

      <Form.Item hidden name="projectManagerName">
        <Input tabIndex="-1" />
      </Form.Item>

      <Form.Item hidden name="projectManagerPhone">
        <Phone tabIndex="-1" />
      </Form.Item>

      <Form.Item hidden name="projectManagerEmail">
        <Input tabIndex="-1" />
      </Form.Item>

      <Form.Item hidden name="extendedWarranty">
        <Input tabIndex="-1" />
      </Form.Item>

      <Form.Item hidden name="workmanshipWarranty">
        <Input tabIndex="-1" />
      </Form.Item>

      <Form.Item hidden name="dateSold">
        <DatePicker tabIndex="-1" format="MMMM DD, YYYY" />
      </Form.Item>

      <Form.Item hidden name="laborOnly" valuePropName="checked">
        <Checkbox tabIndex="-1" />
      </Form.Item>

      <Form.Item hidden name="src">
        <Input tabIndex="-1" />
      </Form.Item>

      {!mold && (
        <>
          <Form.Item name="moldMaterial" hidden>
            <Currency tabIndex="-1" />
          </Form.Item>
          <Form.Item name="moldOverhead" hidden>
            <Currency tabIndex="-1" />
          </Form.Item>
          <Form.Item name="moldLabor" hidden>
            <Currency tabIndex="-1" />
          </Form.Item>
          <Form.Item name="moldDumping" hidden>
            <Currency tabIndex="-1" />
          </Form.Item>
          <Form.Item name="moldExtras" hidden>
            <Currency tabIndex="-1" />
          </Form.Item>
          <Form.Item name="moldBalance" hidden>
            <Currency tabIndex="-1" />
          </Form.Item>
          <Form.Item name="moldProfitPercentage" hidden>
            <Percent tabIndex="-1" />
          </Form.Item>
        </>
      )}
    </>
  );
};

export default HiddenFields;
