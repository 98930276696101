import React, { useState, useEffect, useRef } from "react";
import Select from "antd/es/select";
import api from "API";
import useIsMobile from "Hooks/useIsMobile";

const InputPermits = ({
  value,
  onChange,
  autoFocus,
  placeholder = undefined,
}) => {
  const mobile = useIsMobile();
  const [options, setOptions] = useState([]);

  const ref = useRef();

  useEffect(() => {
    fetchOptions();
  }, []);

  const fetchOptions = async () => {
    try {
      const res = await api.post("/dropdowns/get", {
        selects: ["Permits"],
      });
      setOptions(res.data.Permits.options);
    } catch (err) {
      console.log("err", err);
    }
  };

  const triggerChange = (changedValue) => {
    if (onChange) {
      onChange(changedValue);
    }
  };

  const handleChange = (values) => {
    ref.current.blur();
    triggerChange(values);
  };

  return (
    <Select
      id="permits"
      ref={ref}
      autoComplete="edit-detail-field"
      allowClear
      mode="multiple"
      showSearch={!mobile}
      dropdownClassName={mobile ? "isMobile" : null}
      getPopupContainer={(trigger) =>
        !mobile ? trigger.parentNode : document.body
      }
      onChange={handleChange}
      value={value}
      placeholder={placeholder}
      autoFocus={autoFocus}
    >
      {options.map((o) => (
        <Select.Option key={o} value={o}>
          {o}
        </Select.Option>
      ))}
    </Select>
  );
};

export default InputPermits;
