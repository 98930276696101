import React from "react";
import BarChart from "Reports/BarChart";

export default function PendingAR() {
  return (
    <BarChart
      path="/reports/pendingar"
      summary={true}
      report="PENDING ACCOUNT RECEIVABLES"
      headers={["Salesman", "Total"]}
    />
  );
}
