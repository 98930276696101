import React, { useState, useEffect, useRef, useCallback } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchTable } from "Actions/tableActions";
import { openForm } from "Actions/formActions";
import { openImageViewer } from "Actions/dataActions";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Typography from "antd/es/typography";
import Button from "antd/es/button";
import Form from "antd/es/form";
import Grid from "antd/es/grid";
import message from "antd/es/message";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import Add from "Icons/Add";
import Remove from "Icons/Remove";
import styles from "./styles.module.scss";
import api from "API";
import MaterialColumnHeaders from "Components/materialOrderQuote/MaterialColumnHeaders";
import MaterialRow from "Components/materialOrderQuote/MaterialRow";
import CustomerDetails from "../WorkOrder/CustomerDetails";
import SidingDetails from "../WorkOrder/SidingDetails";
import RoofingSteepDetails from "../WorkOrder/RoofingSteepDetails";
import Divider from "antd/es/divider";
import DeliveryDetails from "../WorkOrder/DeliveryDetails";
import RoofingFlatDetails from "../WorkOrder/RoofingFlatDetails";
import CancelButton from "Components/CancelButton";
import Post from "Icons/Post";
import generateChanges from "Utils/generateChanges";
import currencyFormatter from "Utils/currencyFormatter";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";
import isPdf from "Utils/isPdf";
import openInNewTab from "Utils/openInNewTab";
import Card from "antd/es/card";
import pdf from "Assets/pdf.svg";
import Modal from "antd/es/modal";
// import ProfitCalculator from "./ProfitCalculator";
// import Calculator from "Icons/Calculator";
import isVideo from "Utils/isVideo";
import YouTube from "Icons/YouTube";
import LazyLoad from "Components/LazyLoad";
import HiddenFields from "Forms/WorkOrder/HiddenFields";
import dayjs from "dayjs";
import hidePdfControls from "Utils/hidePdfControls";
import HorizontalScroll from "Components/HorizontalScroll";
import ReviewSection from "Forms/WorkOrder/ReviewSection";
import AerialCalculator from "Forms/WorkOrder/AerialCalculator";
import MaterialDeliveryDetails from "Forms/WorkOrder/MaterialDeliveryDetails";
import Currency from "Components/Inputs/Currency";
import Percent from "Components/Inputs/Percent";
// import Modal from "antd/es/modal";
// import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";
import ImageFallback from "Components/images/Image";
import ProcessFeedback from "Components/ProcessFeedback";
import Input from "antd/es/input";
import useSuppliers from "Hooks/useSuppliers";

const messageKey = "messageKey";
let changedValues = {};

const customerDetails = [
  "customerName",
  "propertyType",
  "pitches",
  "stories",
  "projectAddressStreet",
  "projectAddressCity",
  "projectAddressState",
  "projectAddressZip",
  "projectAddressLat",
  "projectAddressLng",
  "projectAddressCounty",
  "projectAddressPermitAuthority",
  "projectDescription",
  "phone",
];

const steps = [
  "Submitting Form",
  "Generating PDF",
  "Creating Post",
  "Saving PDF",
  "Updating Scope",
  "Done!",
];

const { useBreakpoint } = Grid;

const MoldReview = ({
  unsavedChanges,
  setUnsavedChanges,
  setTitle,
  handleClose,
  type,
}) => {
  const dispatch = useDispatch();
  const formDetails = useSelector(
    (state) => state.formState.moldReview.formDetails
  );
  const table = useSelector((state) => state.tableState.table);
  const col = useSelector((state) => state.tableState.col);
  const order = useSelector((state) => state.tableState.order);
  const search = useSelector((state) => state.tableState.search);
  const screens = useBreakpoint();
  const suppliers = useSuppliers();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [failLoading, setFailLoading] = useState(false);
  const [selected, setSelected] = useState(undefined);
  const [startingRows, setStartingRows] = useState(0);
  const [_lat] = useState(
    formDetails.workOrder ? formDetails.workOrder.projectAddressLat : 42.963795
  );
  const [_lng] = useState(
    formDetails.workOrder ? formDetails.workOrder.projectAddressLng : -85.670006
  );
  const [scrollContainer, setScrollContainer] = useState(null);
  const [addFlat] = useState(
    formDetails.workOrder && formDetails.workOrder.flatManufacturer
      ? true
      : false
  );
  const [laborOnly] = useState(
    formDetails.workOrder && formDetails.workOrder.laborOnly ? true : false
  );
  const [propertyType] = useState(
    formDetails.workOrder && formDetails.workOrder.propertyType
  );
  const [_lineItems] = useState(
    JSON.parse(
      JSON.stringify(
        formDetails.workOrder && formDetails.workOrder.lineItems
          ? formDetails.workOrder.lineItems.slice(0)
          : {}
      )
    )
  );
  const [collapsed, setCollapsed] = useState(true);
  const [_documents, _setDocuments] = useState([]);
  const [_allMedia, _setAllMedia] = useState([]);
  const [_contractPrice, _setContractPrice] = useState(0);
  const [_scopes, _setScopes] = useState([]);
  const [_permitStatus, _setPermitStatus] = useState("Undetermined");

  const _user = useSelector((state) => state.authState._user);
  const [feedBack, setFeedBack] = useState({
    active: "Submitting Form",
    progress: 0,
  });

  const mediaRef = useRef();
  const documentRef = useRef();

  useEffect(() => {
    setTitle(
      `Mold Review${
        formDetails.workOrder
          ? ` - ${formDetails.workOrder.projectManagerName}`
          : ""
      }${formDetails.customerId ? ` (${formDetails.customerId})` : ""}`
    );
    let shingle = [],
      siding = [];

    if (!formDetails.workOrder.shingle && !formDetails.workOrder.siding) {
      if (formDetails.workOrder.manufacturer) {
        shingle.push(formDetails.workOrder.manufacturer);
        siding.push(formDetails.workOrder.manufacturer);
      }
      if (formDetails.workOrder.productLine) {
        shingle.push(formDetails.workOrder.productLine);
        siding.push(formDetails.workOrder.productLine);
      }
      if (formDetails.workOrder.color) {
        shingle.push(formDetails.workOrder.color);
        siding.push(formDetails.workOrder.color);
      }
    } else if (formDetails.workOrder.shingle) {
      shingle = formDetails.workOrder.shingle;
    } else if (formDetails.workOrder.siding) {
      siding = formDetails.workOrder.siding;
    }

    getDetails();
    setStartingRows(formDetails.workOrder.lineItems.length);

    if (formDetails.workOrder.buildDate) {
      form.setFieldsValue({
        ...formDetails.workOrder,
        shingle,
        siding,
        dateSold: dayjs(formDetails.workOrder.dateSold),
        buildDate: dayjs(formDetails.workOrder.buildDate),
      });
    } else {
      form.setFieldsValue({
        ...formDetails.workOrder,
        shingle,
        siding,
        dateSold: dayjs(formDetails.workOrder.dateSold),
      });
    }

    return () => {
      form.resetFields();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (formDetails.supplier) {
      if (suppliers && Object.keys(suppliers).length > 0) {
        Object.keys(suppliers).map((s) => {
          if (suppliers[s].companyName === formDetails.supplier) {
            form.setFieldsValue({
              supplier: suppliers[s]._sub,
              supplierEmail: suppliers[s].email,
            });
          }
          return s;
        });
      }
    }
  }, [suppliers, formDetails.supplier, form]);

  const getDetails = async () => {
    try {
      const res = await api.get(
        `/mold-review/get-details/${formDetails._projectId}`
      );
      unstable_batchedUpdates(() => {
        _setDocuments(res.data._documents);
        _setAllMedia(res.data._allMedia);
        _setContractPrice(res.data._contractPrice);
        _setScopes(res.data._scopes);
        // _setMold(res.data.mold);
        _setPermitStatus(res.data._permitStatus);
        // _setMoldNotes(res.data._moldNotes);
      });

      let expectedProfitPercentage =
        (res.data.mold.expectedBalance / res.data._contractPrice) * 100;
      form.setFieldsValue({
        expectedProfitPercentage,
        expectedBalance: res.data.mold.expectedBalance,
        expectedDumping: res.data.mold.expectedDumping,
        expectedExtras: res.data.mold.expectedExtras,
        expectedGutters: res.data.mold.expectedGutters,
        expectedInsulation: res.data.mold.expectedInsulation,
        expectedLabor: res.data.mold.expectedLabor,
        expectedMaterials: res.data.mold.expectedMaterials,
        expectedOverhead: res.data.mold.expectedOverhead,
        moldOverhead: res.data.mold.expectedOverhead,
        moldContractPrice: res.data._contractPrice,
        expectedContractPrice: res.data._contractPrice,
        moldNotes: res.data._moldNotes,
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    handleSelectedChange();
  }, [selected]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectedChange = async () => {
    try {
      if (!selected) {
        const lineItems = await form.getFieldValue("lineItems");
        if (lineItems) {
          lineItems.forEach((m, i) => {
            if (m.disabled) {
              form.validateFields([
                ["lineItems", i, "material"],
                ["lineItems", i, "type"],
                ["lineItems", i, "color"],
                ["lineItems", i, "quantity"],
              ]);
            }
          });
        }
      }
    } catch (err) {
      console.log("handleSelectedChange err", err);
    }
  };

  const onSave = useCallback(async () => {
    try {
      setSaveLoading(true);
      // message.loading("Saving...", 0);
      message.loading({
        content: "Saving mold review...",
        duration: 0,
        key: messageKey,
      });
      let values = await form.getFieldsValue();

      if (values.shingle) {
        values.manufacturer = values.shingle[0];
        values.productLine = values.shingle[1];
        values.color = values.shingle[2];
      } else if (values.siding) {
        values.manufacturer = values.siding[0];
        values.productLine = values.siding[1];
        values.color = values.siding[2];
        // values.supplier = suppliers[values.supplier].companyName;
        if (values.supplier) {
          values.supplierEmail = suppliers[values.supplier].email;
          values.supplier = suppliers[values.supplier].companyName;
        }
      }

      values = await generateChanges({
        changedValues,
        values,
        customerDetails,
        _lineItems,
        workOrder: formDetails,
        woCreated: true,
      });

      values.projectAddress = `${values.projectAddressStreet}, ${values.projectAddressCity}, ${values.projectAddressState} ${values.projectAddressZip}`;
      values.companyName = formDetails.workOrder.companyName;

      await api.post("/mold-review/save", values);

      changedValues = {};
      unstable_batchedUpdates(() => {
        setUnsavedChanges(false);
        setSaveLoading(false);

        dispatch(
          fetchTable({
            table,
            col,
            order,
            search,
            fetching: false,
          })
        );
      });

      message.success({
        content: "Mold review saved",
        duration: 1.5,
        key: messageKey,
      });
    } catch (err) {
      console.log("err", err);
      setSaveLoading(false);
      message.error({
        content: "Error saving mold review",
        duration: 1.5,
        key: messageKey,
      });
    }
  }, [
    form,
    _lineItems,
    formDetails,
    setUnsavedChanges,
    dispatch,
    table,
    col,
    order,
    search,
    suppliers,
  ]);

  const removeRow = async () => {
    try {
      let lineItems = await form.getFieldValue("lineItems");
      lineItems = lineItems.filter((m, i) => {
        if (
          !m.checkbox ||
          m.disabled ||
          (m.checkbox === true && i + 1 <= startingRows)
        ) {
          return true;
        }
        return false;
      });
      lineItems.forEach((m, i) => {
        if (m.checkbox || m.disabled) {
          m.disabled = true;
          m.checkbox = false;
          changedValues = {
            ...changedValues,
            [i]: {
              ...changedValues[i],
              disabled: true,
              index: i,
            },
          };
        }
      });
      form.setFieldsValue({
        lineItems,
      });
      setSelected(undefined);
    } catch (err) {
      console.log("err", err);
    }
  };

  const removeSingleRow = async (index) => {
    try {
      let lineItems = await form.getFieldValue("lineItems");
      if (index < startingRows) lineItems[index].disabled = true;
      changedValues = {
        ...changedValues,
        [index]: {
          ...changedValues[index],
          disabled: true,
          index,
        },
      };
      form.setFieldsValue({
        lineItems,
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleUnlock = async (index) => {
    try {
      let lineItems = await form.getFieldValue("lineItems");
      lineItems[index].disabled = false;
      changedValues = {
        ...changedValues,
        [index]: {
          ...changedValues[index],
          disabled: false,
          index,
        },
      };
      form.setFieldsValue({
        lineItems,
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const onValuesChange = (values) => {
    if (!unsavedChanges) setUnsavedChanges(true);

    let key = Object.keys(values)[0];
    let value = values[key];
    let index;

    if (key === "lineItems") {
      index = Object.keys(value)[0];

      let subValue = value[index];
      key = Object.keys(subValue)[0];
      value = subValue[key];
    }

    if (index && key !== "checkbox") {
      changedValues = {
        ...changedValues,
        [index]: {
          ...changedValues[index],
          [key]: value,
          index,
        },
      };
    } else if (key !== "checkbox") {
      changedValues = {
        ...changedValues,
        [key]: {
          value,
          key,
        },
      };
    }
  };

  const onFieldsChange = async (fields) => {
    try {
      if (fields.length > 0 && fields[0].name[2] === "checkbox") {
        let lineItems = await form.getFieldValue("lineItems");
        if (lineItems) {
          let arr = [];
          let cnt = 0;
          for (const m of lineItems) {
            if (m.checkbox) {
              arr.push(cnt);
              cnt++;
            }
          }
          if (arr.length === 0) arr = undefined;
          setSelected(arr);
        }
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleCancel = () => {
    handleClose();
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const openActivity = useCallback(() => {
    dispatch(
      openForm("activity", {
        _id: formDetails._projectId,
        customerId: formDetails.customerId,
        customerName: formDetails.customerName,
        projectStatus: "Sold",
        projectManager: {
          name: formDetails.workOrder.projectManagerName,
        },
      })
    );
  }, [dispatch, formDetails]);

  // const openMold = () => {
  //   setMoldVisible(!moldVisible);
  // };

  const goToInNewTab = () => {
    openInNewTab(
      `https://story.gopremier.net/projects/view/details/${formDetails._projectId}`
    );
  };

  const handleMoldChange = async () => {
    try {
      // setTimeout(() => {
      const mold = await form.getFieldsValue([
        "moldMaterial",
        "moldOverhead",
        "moldLabor",
        "moldDumping",
        "moldExtras",
        "moldGutters",
        "moldInsulation",
      ]);

      let totalExpenses = 0;
      Object.entries(mold).map(([key, value]) => {
        if (value) totalExpenses = totalExpenses + value;
        return value;
      });

      let moldBalance = _contractPrice - totalExpenses;
      let moldProfitPercentage = (moldBalance / _contractPrice) * 100;

      form.setFieldsValue({
        moldBalance,
        moldProfitPercentage,
      });
      // });
    } catch (err) {
      console.log("moldChange err", err);
    }
  };

  const confirmFail = () => {
    Modal.confirm({
      zIndex: 4002,
      icon: <QuestionCircleOutlined />,
      centered: true,
      cancelText: "Cancel",
      okText: "Delete",
      okButtonProps: { danger: true },
      content: <div>Are you sure you want to fail this mold review?</div>,
      onOk() {
        return new Promise((resolve, reject) => {
          handleFail(() => resolve(true));
        }).catch((err) => console.log("err: ", err));
      },
      onCancel() {},
    });
  };

  const handleFail = useCallback(
    async (callback) => {
      try {
        setFailLoading(true);
        // message.loading("Marking failed...", 0);
        message.loading({
          content: "Marking mold review failed...",
          duration: 0,
          key: messageKey,
        });
        let values = await form.getFieldsValue();

        if (values.shingle) {
          values.manufacturer = values.shingle[0];
          values.productLine = values.shingle[1];
          values.color = values.shingle[2];
        } else if (values.siding) {
          values.manufacturer = values.siding[0];
          values.productLine = values.siding[1];
          values.color = values.siding[2];
        }

        values = await generateChanges({
          changedValues,
          values,
          customerDetails,
          _lineItems,
          workOrder: formDetails,
          woCreated: true,
        });

        values.projectAddress = `${values.projectAddressStreet}, ${values.projectAddressCity}, ${values.projectAddressState} ${values.projectAddressZip}`;
        values.companyName = formDetails.workOrder.companyName;

        const res = await api.post("/mold-review/fail", values);

        for (const s of res.data.salesObj) {
          window.socket.emit("updateDashboard", { _user: s._user });
          window.socket.emit("updateFeed", { _user: s._user });
        }

        window.socket.emit("update", { _id: formDetails._projectId });

        unstable_batchedUpdates(() => {
          setUnsavedChanges(false);
          setFailLoading(false);
        });

        dispatch(
          fetchTable({
            table,
            col,
            order,
            search,
            fetching: false,
          })
        );

        // message.success("Mold review marked failed");
        message.success({
          content: "Mold review marked failed",
          duration: 1.5,
          key: messageKey,
        });

        // setVisible(false);
        callback();
        handleClose(true);
      } catch (err) {
        console.log("err", err);
        setFailLoading(false);
        // message.error("Error marking mold review as failed");
        message.error({
          content: "Error marking mold review as failed",
          duration: 1.5,
          key: messageKey,
        });
      }
    },
    [
      form,
      // changedValues,
      _lineItems,
      formDetails,
      setUnsavedChanges,
      dispatch,
      table,
      col,
      order,
      search,
      handleClose,
      // setVisible,
    ]
  );

  const updateFeedBack = (feedBack) => {
    setFeedBack(feedBack);
  };

  const onApprove = useCallback(async () => {
    try {
      window.socket.on(`${_user}-work-order-progress`, updateFeedBack);
      unstable_batchedUpdates(() => {
        setFeedBack({
          active: "Submitting Form",
          progress: 1,
        });
        setLoading(true);
      });

      // message.loading("Marking passed...", 0);
      message.loading({
        content: "Marking mold review passed...",
        duration: 0,
        key: messageKey,
      });
      let values = await form.validateFields();

      if (values.shingle) {
        values.manufacturer = values.shingle[0];
        values.productLine = values.shingle[1];
        values.color = values.shingle[2];
      } else if (values.siding) {
        values.manufacturer = values.siding[0];
        values.productLine = values.siding[1];
        values.color = values.siding[2];
        // values.supplier = suppliers[values.supplier].companyName;
        if (values.supplier) {
          values.supplierEmail = suppliers[values.supplier].email;
          values.supplier = suppliers[values.supplier].companyName;
        }
      }

      values = await generateChanges({
        changedValues,
        values,
        customerDetails,
        _lineItems,
        workOrder: formDetails,
        woCreated: true,
      });

      values.projectAddress = `${values.projectAddressStreet}, ${values.projectAddressCity}, ${values.projectAddressState} ${values.projectAddressZip}`;
      values.permitStatus = _permitStatus;
      values.companyName = formDetails.workOrder.companyName;

      const res = await api.post("/mold-review/pass", values);

      for (const s of res.data.salesObj) {
        window.socket.emit("updateDashboard", { _user: s._user });
        window.socket.emit("updateFeed", { _user: s._user });
      }

      window.socket.emit("update", { _id: formDetails._projectId });

      // changedValues = {};
      unstable_batchedUpdates(() => {
        setUnsavedChanges(false);
        setLoading(false);
        setFeedBack({
          active: "Done!",
          progress: 100,
        });
      });

      dispatch(
        fetchTable({
          table,
          col,
          order,
          search,
          fetching: false,
        })
      );

      // message.success("Mold review marked passed");
      message.success({
        content: "Mold review marked passed",
        duration: 1.5,
        key: messageKey,
      });
      window.socket.removeAllListeners(`${_user}-work-order-progress`);
      // setVisible(false);
      handleClose(true);
    } catch (err) {
      console.log("err", err);
      if (
        err &&
        err.errorFields &&
        err.errorFields[0] &&
        err.errorFields[0].name
      ) {
        if (err.errorFields[0].name[0] === "lineItems") {
          const el = document.getElementById("top-of-form");
          el.scrollIntoView({ block: "end", behavior: "smooth" });
        } else {
          form.scrollToField(err.errorFields[0].name[0]);
        }
      }
      // message.error("Error marking mold review as passed");
      message.error({
        content: "Error marking mold review as passed",
        duration: 1.5,
        key: messageKey,
      });
      setLoading(false);
      setFeedBack({
        active: "Submitting Form",
        progress: 0,
      });
      window.socket.removeAllListeners(`${_user}-work-order-progress`);
    }
  }, [
    _user,
    form,
    _lineItems,
    formDetails,
    dispatch,
    table,
    col,
    order,
    search,
    handleClose,
    _permitStatus,
    setUnsavedChanges,
    suppliers,
    // changedValues,
    // setVisible,
  ]);

  const handleLaborBlur = async () => {
    try {
      const data = await form.getFieldsValue(["reviewNotes", "moldLabor"]);
      const reviewNotes = data.reviewNotes;

      form.setFieldsValue({
        reviewNotes,
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      className="form"
      onValuesChange={onValuesChange}
      onFieldsChange={onFieldsChange}
    >
      <FormBody>
        <div
          className="content-inner p24"
          id="work-order-container"
          ref={setScrollContainer}
          style={
            type === "drawer"
              ? { height: `calc(100% - ${collapsed ? "46px" : "161px"})` }
              : {}
          }
        >
          <div id="top-of-form" />
          <div className={feedBack.progress !== 0 ? "hide" : ""}>
            <Button
              style={{ marginBottom: 24 }}
              onClick={goToInNewTab}
              type="primary"
              block
            >
              Go To In New Tab
            </Button>
            <RemoveAutocomplete />
            <AerialCalculator hidden form={form} />
            <HiddenFields mold />
            <MaterialDeliveryDetails />
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <b>Customer Name</b>:{" "}
                {formDetails.workOrder
                  ? formDetails.workOrder.customerName
                  : ""}
              </Col>
              <Col xs={24} sm={12}>
                <b>Address</b>:{" "}
                {formDetails.workOrder
                  ? formDetails.workOrder.projectAddress
                  : ""}
              </Col>
              {formDetails.name === "Siding" ? (
                <Col xs={24} sm={12}>
                  <b>Material</b>:{" "}
                  {formDetails.workOrder
                    ? formDetails.workOrder.siding
                      ? `${formDetails.workOrder.siding[0]} | ${formDetails.workOrder.siding[1]} | ${formDetails.workOrder.siding[2]}`
                      : `${formDetails.workOrder.manufacturer} | ${formDetails.workOrder.productLine} | ${formDetails.workOrder.color}`
                    : ""}
                </Col>
              ) : (
                <Col xs={24} sm={12}>
                  <b>Material</b>:{" "}
                  {formDetails.workOrder
                    ? formDetails.workOrder.shingle
                      ? `${formDetails.workOrder.shingle[0]} | ${formDetails.workOrder.shingle[1]} | ${formDetails.workOrder.shingle[2]}`
                      : `${formDetails.workOrder.manufacturer} | ${formDetails.workOrder.productLine} | ${formDetails.workOrder.color}`
                    : ""}
                </Col>
              )}

              <Col xs={24} sm={12}>
                <b>Contract Price</b>: {currencyFormatter(_contractPrice)}
              </Col>
              <Col xs={24} sm={12}>
                <b>Squares</b>:{" "}
                {formDetails.workOrder ? formDetails.workOrder.squares : ""}
              </Col>
              <Col xs={24} sm={12}>
                <b>Pitches</b>:{" "}
                {formDetails.workOrder
                  ? formDetails.workOrder.pitches.join(", ")
                  : ""}
              </Col>
              {formDetails.name !== "Siding" ? (
                <Col xs={24} sm={12}>
                  <b>Roof Types</b>:{" "}
                  {formDetails.workOrder
                    ? formDetails.workOrder.roofType
                      ? formDetails.workOrder.roofType.join(", ")
                      : formDetails.workOrder.installationMethod
                    : ""}
                </Col>
              ) : (
                <Col xs={24} sm={12}>
                  <b>Siding Types</b>:{" "}
                  {formDetails.workOrder
                    ? formDetails.workOrder.existingSiding
                      ? formDetails.workOrder.existingSiding.join(", ")
                      : ""
                    : ""}
                </Col>
              )}
            </Row>
            <Divider />
            <Typography.Title level={4}>Scopes</Typography.Title>
            <Row gutter={16}>
              {_scopes.map((scope) => (
                <Col key={scope._id} xs={24} sm={12}>
                  <div className={styles.scopeCol}>
                    {scope.workOrder && scope.workOrder.src ? (
                      <a
                        href={scope.workOrder.src}
                        target="_Blank"
                        rel="noopener noreferrer"
                      >
                        {scope.name}
                      </a>
                    ) : (
                      <span>{scope.name}</span>
                    )}
                  </div>
                  <div
                    className={styles.scopeCol}
                    style={{ textAlign: "right" }}
                  >
                    {currencyFormatter(scope.amount)}
                  </div>
                </Col>
              ))}
            </Row>
            <Divider />
            <Typography.Title level={4}>Mold Calculator</Typography.Title>
            <Form.Item label="Mold Notes" name="moldNotes">
              <Input.TextArea autoComplete="dont-use-autocomplete" rows={4} />
            </Form.Item>
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                {/* {moldCheck && ( */}
                <Typography.Title
                  level={4}
                  style={{
                    display: "block",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  Your Estimate
                </Typography.Title>
                {/* )} */}

                <Form.Item
                  name="moldContractPrice"
                  label="ContractPrice"
                  labelCol={{ span: 24 }}
                >
                  <Currency id="moldContractPrice" disabled />
                </Form.Item>

                <Form.Item
                  name="moldMaterial"
                  label="Materials"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "Materials is required",
                    },
                  ]}
                >
                  <Currency id="moldMaterial" onChange={handleMoldChange} />
                </Form.Item>
                <Form.Item
                  labelCol={{ span: 24 }}
                  name="moldOverhead"
                  label="Overhead"
                >
                  <Currency
                    id="moldOverhead"
                    disabled
                    onChange={handleMoldChange}
                  />
                </Form.Item>
                <Form.Item
                  name="moldLabor"
                  label="Labor"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "Labor is required",
                    },
                  ]}
                >
                  <Currency
                    id="moldLabor"
                    onChange={handleMoldChange}
                    onBlur={handleLaborBlur}
                  />
                </Form.Item>
                <Form.Item
                  name="moldDumping"
                  label="Dumping"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "Dumping is required",
                    },
                  ]}
                >
                  <Currency id="moldDumping" onChange={handleMoldChange} />
                </Form.Item>
                <Form.Item
                  name="moldExtras"
                  label="Extras"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "Extras is required",
                    },
                  ]}
                >
                  <Currency id="moldExtras" onChange={handleMoldChange} />
                </Form.Item>
                <Form.Item
                  name="moldGutters"
                  label="Gutters"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "Gutters is required",
                    },
                  ]}
                >
                  <Currency id="moldGutters" onChange={handleMoldChange} />
                </Form.Item>
                <Form.Item
                  name="moldInsulation"
                  label="Insulation"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "Insulation is required",
                    },
                  ]}
                >
                  <Currency id="moldInsulation" onChange={handleMoldChange} />
                </Form.Item>

                <Divider />

                <Form.Item
                  name="moldBalance"
                  label="Balance"
                  labelCol={{ span: 24 }}
                >
                  <Currency
                    id="moldBalance"
                    onChange={handleMoldChange}
                    disabled
                  />
                </Form.Item>
                <Form.Item
                  name="moldProfitPercentage"
                  label="Profit Percentage"
                  labelCol={{ span: 24 }}
                >
                  <Percent
                    id="moldProfitPercentage"
                    disabled
                    onChange={handleMoldChange}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                {/* {moldCheck && ( */}
                <Typography.Title
                  level={4}
                  style={{
                    display: "block",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  Project Managers Estimate
                </Typography.Title>
                {/* )} */}

                {/* <div
                className={`${styles.hiddenMoldCheck} ${
                  moldCheck ? styles.hide : ""
                }`}
              >
                <Eye />
                <div>Click Mold Check to view Project Managers Estimate</div>
              </div> */}

                <Form.Item
                  // hidden={!moldCheck}
                  name="expectedContractPrice"
                  label="ContractPrice"
                  labelCol={{ span: 24 }}
                >
                  <Currency id="expectedContractPrice" disabled />
                </Form.Item>

                <Form.Item
                  // hidden={!moldCheck}
                  name="expectedMaterials"
                  label="Expected Material"
                  labelCol={{ span: 24 }}
                >
                  <Currency disabled />
                </Form.Item>
                <Form.Item
                  // hidden={!moldCheck}
                  name="expectedOverhead"
                  label="Expected Overhead"
                  labelCol={{ span: 24 }}
                >
                  <Currency disabled />
                </Form.Item>
                <Form.Item
                  // hidden={!moldCheck}
                  name="expectedLabor"
                  label="Expected Labor"
                  labelCol={{ span: 24 }}
                >
                  <Currency disabled />
                </Form.Item>
                <Form.Item
                  // hidden={!moldCheck}
                  name="expectedDumping"
                  label="Expected Dumping"
                  labelCol={{ span: 24 }}
                >
                  <Currency disabled />
                </Form.Item>
                <Form.Item
                  // hidden={!moldCheck}
                  name="expectedExtras"
                  label="Expected Extras"
                  labelCol={{ span: 24 }}
                >
                  <Currency disabled />
                </Form.Item>
                <Form.Item
                  // hidden={!moldCheck}
                  name="expectedGutters"
                  label="Expected Gutters"
                  labelCol={{ span: 24 }}
                >
                  <Currency disabled />
                </Form.Item>
                <Form.Item
                  // hidden={!moldCheck}
                  name="expectedInsulation"
                  label="Expected Insulation"
                  labelCol={{ span: 24 }}
                >
                  <Currency disabled />
                </Form.Item>

                {/* <div style={{ display: !moldCheck ? "none" : "block" }}> */}
                <Divider />
                {/* </div> */}

                <Form.Item
                  // hidden={!moldCheck}
                  name="expectedBalance"
                  label="Expected Balance"
                  labelCol={{ span: 24 }}
                >
                  <Currency disabled />
                </Form.Item>
                <Form.Item
                  // hidden={!moldCheck}
                  name="expectedProfitPercentage"
                  label="Profit Percentage"
                  labelCol={{ span: 24 }}
                >
                  <Percent disabled />
                </Form.Item>
              </Col>
            </Row>
            <Divider />
            <Typography.Title level={4}>All Media & Documents</Typography.Title>
            {/* <HorizontalScroll ref={documentRef} scrollContainer={scrollContainer}>
            {_documents.map((media) => (
              <Document
                key={media._id}
                media={media}
                scrollContainer={documentRef}
              />
            ))}
          </HorizontalScroll> */}
            <HorizontalScroll ref={mediaRef} scrollContainer={scrollContainer}>
              {_documents.map((media) => (
                <Document
                  key={media._id}
                  media={media}
                  scrollContainer={documentRef}
                />
              ))}
              {_allMedia.map((media) => (
                <Document
                  key={media._id}
                  media={media}
                  scrollContainer={mediaRef}
                />
              ))}
            </HorizontalScroll>
            <Divider />
            <CustomerDetails hidden _lat={_lat} _lng={_lng} />

            {formDetails.name === "Roofing - Steep" ? (
              <>
                <RoofingSteepDetails
                  form={form}
                  startingRows={startingRows}
                  setStartingRows={setStartingRows}
                  addFlat={addFlat}
                  review
                />
                <Divider />
              </>
            ) : formDetails.name === "Siding" ? (
              <SidingDetails
                form={form}
                startingRows={startingRows}
                setStartingRows={setStartingRows}
                laborOnly={laborOnly}
                propertyType={propertyType}
                review
              />
            ) : (
              <>
                {" "}
                <RoofingFlatDetails
                  form={form}
                  startingRows={startingRows}
                  setStartingRows={setStartingRows}
                />
                <Divider />
              </>
            )}

            <DeliveryDetails hidden laborOnly={laborOnly} />
            <Typography.Title level={4}>
              Materails To Be Delivered
            </Typography.Title>
            {!screens.xs && (
              <MaterialColumnHeaders
                form={form}
                selected={selected}
                setSelected={setSelected}
                scrollContainer={scrollContainer}
              />
            )}
            <Form.List name="lineItems">
              {(fields, { add, remove }) => {
                const handleAdd = () => {
                  add();
                };

                const handleRemove = (i) => {
                  if (i < startingRows) {
                    removeSingleRow(i);
                  } else {
                    remove(i);
                  }
                };

                return (
                  <>
                    {fields.map((field, index) => (
                      <MaterialRow
                        quote={true}
                        key={field.key}
                        fields={fields}
                        field={field}
                        index={index}
                        form={form}
                        selected={selected}
                        handleRemove={handleRemove}
                        handleUnlock={handleUnlock}
                      />
                    ))}
                    <Row gutter={{ xs: 8, sm: 8, md: 12, lg: 24 }}>
                      {!selected ? (
                        <Col xs={24}>
                          <Form.Item style={{ marginRight: 0 }}>
                            <Button
                              className={`${styles.button} green`}
                              style={
                                screens.lg
                                  ? { marginTop: 12 }
                                  : screens.md
                                  ? { marginTop: 6 }
                                  : { marginTop: 12 }
                              }
                              type="primary"
                              onClick={handleAdd}
                              block
                            >
                              <Add size={18} /> Add Material
                            </Button>
                          </Form.Item>
                        </Col>
                      ) : (
                        <Col xs={24}>
                          <Form.Item>
                            <Button
                              className={styles.button}
                              style={
                                screens.lg
                                  ? { marginTop: 12 }
                                  : screens.md
                                  ? { marginTop: 6 }
                                  : { marginTop: 12 }
                              }
                              danger
                              type="primary"
                              onClick={removeRow}
                              block
                            >
                              <Remove size={18} /> Remove Selected
                            </Button>
                          </Form.Item>
                        </Col>
                      )}
                    </Row>
                  </>
                );
              }}
            </Form.List>
            {/* <Divider />

            <Typography.Title level={4}>All Media</Typography.Title> */}
            {/* <HorizontalScroll ref={mediaRef} scrollContainer={scrollContainer}>
            {_allMedia.map((media) => (
              <Document
                key={media._id}
                media={media}
                scrollContainer={mediaRef}
              />
            ))}
          </HorizontalScroll> */}
            <Button
              onClick={openActivity}
              type="primary"
              danger
              shape="circle"
              className={`${styles.fab} ${collapsed ? styles.collapsed : ""}`}
            >
              <Post />
            </Button>
            {/* <Button
              onClick={openMold}
              type="primary"
              danger
              shape="circle"
              className={`${styles.fab} ${styles.mold} ${
                collapsed ? styles.collapsed : ""
              }`}
            >
              <Calculator />
            </Button> */}
            {/* <ProfitCalculator
            visible={moldVisible}
            setVisible={setMoldVisible}
            form={form}
            mold={_mold}
            contractPrice={_contractPrice}
            setUnsavedChanges={setUnsavedChanges}
            handleClose={handleClose}
            changedValues={changedValues}
            _permitStatus={_permitStatus}
            _lineItems={_lineItems}
            projectManagerName={
              formDetails.workOrder
                ? ` - ${formDetails.workOrder.projectManagerName}`
                : ""
            }
            material={
              formDetails.workOrder
                ? formDetails.workOrder.shingle
                  ? `${formDetails.workOrder.shingle[0]} | ${formDetails.workOrder.shingle[1]} | ${formDetails.workOrder.shingle[2]}`
                  : `${formDetails.workOrder.manufacturer} | ${formDetails.workOrder.productLine} | ${formDetails.workOrder.color}`
                : ""
            }
            squares={formDetails.workOrder ? formDetails.workOrder.squares : ""}
            pitches={
              formDetails.workOrder
                ? formDetails.workOrder.pitches.join(", ")
                : ""
            }
            roofTypes={
              formDetails.workOrder
                ? formDetails.workOrder.roofType
                  ? formDetails.workOrder.roofType.join(", ")
                  : formDetails.workOrder.installationMethod
                : ""
            }
          /> */}
          </div>

          <div
            style={{ height: "100%" }}
            className={feedBack.progress === 0 ? "hide" : ""}
          >
            <ProcessFeedback feedBack={feedBack} steps={steps} />
          </div>
        </div>
      </FormBody>

      <ReviewSection
        hideDifficulty
        collapsed={collapsed}
        toggleCollapsed={toggleCollapsed}
      />

      <FormControls className="sticky-footer">
        <CancelButton handleClick={handleCancel} />

        <>
          <Button
            type="text"
            style={{ padding: "4px 8px", marginRight: 8, color: "#1890ff" }}
            onClick={onSave}
            loading={saveLoading}
            disabled={saveLoading}
          >
            Save
          </Button>

          <Button
            type="primary"
            danger
            style={{ marginRight: 8 }}
            loading={failLoading}
            disabled={failLoading}
            onClick={confirmFail}
          >
            Fail
          </Button>

          <Button
            type="primary"
            loading={loading}
            disabled={loading}
            onClick={onApprove}
          >
            Pass
          </Button>
        </>
      </FormControls>
    </Form>
  );
};

const Document = ({ media, scrollContainer }) => {
  const dispatch = useDispatch();

  const type = isPdf(media.src)
    ? "pdf"
    : isVideo(media.src)
    ? "video"
    : "image";

  const handlePdfClick = () => {
    openInNewTab(media.src);
  };

  const handleImageOrVideoClick = useCallback(() => {
    media.viewOnly = true;
    media.link = true;
    dispatch(openImageViewer(media));
  }, [media, dispatch]);

  return (
    <Card
      bordered={false}
      hoverable
      className={styles.activity}
      bodyStyle={{ padding: 0 }}
      style={{ padding: 0 }}
      cover={
        <>
          <div
            className={styles.clickOverlay}
            onClick={type === "pdf" ? handlePdfClick : handleImageOrVideoClick}
          />
          {type === "pdf" ? (
            <LazyLoad
              offsetHorizontal={600}
              height="240px"
              root={scrollContainer}
            >
              <object data={hidePdfControls(media.src)} className={styles.pdf}>
                <div>
                  <img src={pdf} alt="pdf-icon" />
                  <div>{media.name}</div>
                </div>
              </object>
            </LazyLoad>
          ) : type === "image" ? (
            <LazyLoad
              offsetHorizontal={600}
              height="240px"
              root={scrollContainer}
            >
              <ImageFallback alt="mold-review" image={media} />
            </LazyLoad>
          ) : (
            <LazyLoad
              offsetHorizontal={600}
              height="240px"
              root={scrollContainer}
            >
              <div className={styles.video}>
                <YouTube color="#f1f1f1" size={64} />
                <div>{media.name}</div>
              </div>
            </LazyLoad>
          )}
        </>
      }
    ></Card>
  );
};

export default MoldReview;
