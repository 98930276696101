import React from "react";
import Card from "antd/es/card";
import styles from "./styles.module.scss";

const ActionCard = ({
  title,
  description,
  icon,
  color,
  mobile = false,
  action,
  openForm,
}) => {
  const Icon = icon;

  const handleClick = () => {
    openForm(action);
  };

  return (
    <Card
      hoverable
      bordered={false}
      className={`${mobile ? styles.mobile : ""} ${styles.actionCard}`}
      bodyStyle={{ height: "100%", padding: 18 }}
      style={{ background: color }}
      onClick={handleClick}
    >
      <div className={styles.icon}>
        <Icon color="rgba(255,255,255,0.25)" />
      </div>
      <Card.Meta
        className={styles.meta}
        title={title}
        description={description}
      />
    </Card>
  );
};

export default ActionCard;
