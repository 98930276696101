import React from "react";
import BarChartWithSwitch from "Reports/BarChartWithSwitch";

export default function SoldToWorkOrder() {
  return (
    <BarChartWithSwitch
      path="/reports/soldToWorkOrder"
      _value="Average"
      secondary="Median"
      report="TIME TO CREATE WORK ORDER AFTER CONTRACT SIGNED"
      headers={["Salesman", "Total"]}
    />
  );
}
