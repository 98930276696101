import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateMenuItems } from "Actions/authActions";
import Dashboard from "Icons/Dashboard";
import Build from "Icons/Build";
import Group from "Icons/Group";
import Business from "Icons/Business";
import Tune from "Icons/Tune";
import Place from "Icons/Place";
import Image from "Icons/Image";
import Description from "Icons/Description";
import Wallet from "Icons/Wallet";
import ClipboardCheck from "Icons/ClipboardCheck";
import HammerWrench from "Icons/HammerWrench";
import Roof from "Icons/Roof";
import Calendar from "Icons/Calendar";
import AccountCog from "Icons/AccountCog";

import Card from "antd/es/card";
import List from "antd/es/list";
import Switch from "antd/es/switch";
import Form from "antd/es/form";
import styles from "./styles.module.scss";
import message from "antd/es/message";
import { Col, Row } from "antd/es/grid";

const ManageMenu = () => {
  const dispatch = useDispatch();
  const admin = useSelector((state) => state.authState.admin);
  const role = useSelector((state) => state.authState.role);
  const manager = useSelector((state) => state.authState.manager);
  const menuItems = useSelector((state) => state.authState.menuItems);

  const handleChange = useCallback(
    (menuItem, value) => {
      try {
        dispatch(updateMenuItems(menuItem, value));

        message.success("Menu item successfully updated");
      } catch (err) {
        console.log("err", err);
        message.error("Error updating menu item");
      }
    },
    [dispatch]
  );

  return (
    <div className="content-inner p24">
      <Row justify="center">
        <Col xs={24} sm={24} md={12}>
          <Form initialValues={menuItems}>
            <Card
              bodyStyle={{ padding: 0 }}
              style={{ borderRadius: 4, overflow: "hidden" }}
            >
              <List>
                <List.Item className={styles.listItemHeader}>
                  <List.Item.Meta title="MAIN" />
                </List.Item>
                <List.Item
                  className={styles.listItem}
                  actions={[
                    <MenuSwitch
                      menuItem="dashboard"
                      handleChange={handleChange}
                    />,
                  ]}
                >
                  <List.Item.Meta avatar={<Dashboard />} title="Dashboard" />
                </List.Item>
                <List.Item
                  className={styles.listItem}
                  actions={[
                    <MenuSwitch
                      menuItem="roofSchedule"
                      handleChange={handleChange}
                    />,
                  ]}
                >
                  <List.Item.Meta avatar={<Calendar />} title="Schedule" />
                </List.Item>
                <List.Item
                  className={styles.listItem}
                  actions={[
                    <MenuSwitch menuItem="leads" handleChange={handleChange} />,
                  ]}
                >
                  <List.Item.Meta avatar={<Group />} title="Leads" />
                </List.Item>
                <List.Item
                  className={styles.listItem}
                  actions={[
                    <MenuSwitch
                      menuItem="projects"
                      handleChange={handleChange}
                    />,
                  ]}
                >
                  <List.Item.Meta avatar={<Build />} title="Projects" />
                </List.Item>
                <List.Item
                  className={styles.listItem}
                  actions={[
                    <MenuSwitch
                      menuItem="scopes"
                      handleChange={handleChange}
                    />,
                  ]}
                >
                  <List.Item.Meta avatar={<Tune />} title="Scopes" />
                </List.Item>
                <List.Item
                  className={styles.listItem}
                  actions={[
                    <MenuSwitch
                      menuItem="accountReceivables"
                      handleChange={handleChange}
                    />,
                  ]}
                >
                  <List.Item.Meta
                    avatar={<Wallet />}
                    title="Account Receivables"
                  />
                </List.Item>
                <List.Item
                  className={styles.listItem}
                  actions={[
                    <MenuSwitch
                      menuItem="photoAlbums"
                      handleChange={handleChange}
                    />,
                  ]}
                >
                  <List.Item.Meta avatar={<Image />} title="Photo Albums" />
                </List.Item>
                <List.Item
                  className={styles.listItem}
                  actions={[
                    <MenuSwitch
                      menuItem="documents"
                      handleChange={handleChange}
                    />,
                  ]}
                >
                  <List.Item.Meta avatar={<Description />} title="Documents" />
                </List.Item>
                <List.Item
                  className={styles.listItem}
                  actions={[
                    <MenuSwitch
                      menuItem="breakdowns"
                      handleChange={handleChange}
                    />,
                  ]}
                >
                  <List.Item.Meta
                    avatar={<ClipboardCheck />}
                    title="Breakdowns"
                  />
                </List.Item>
                <List.Item
                  className={styles.listItem}
                  actions={[
                    <MenuSwitch menuItem="maps" handleChange={handleChange} />,
                  ]}
                >
                  <List.Item.Meta avatar={<Place />} title="Maps" />
                </List.Item>
              </List>
            </Card>

            {(admin === "Yes" || manager === "Yes") && (
              <Card
                bodyStyle={{ padding: 0 }}
                style={{ borderRadius: 4, overflow: "hidden", marginTop: 24 }}
              >
                <List className={styles.listSpacer}>
                  <List.Item className={styles.listItemHeader}>
                    <List.Item.Meta avatar={<Roof />} title="ROOFING PROCESS" />
                  </List.Item>
                  <List.Item
                    className={styles.listItem}
                    actions={[
                      <MenuSwitch
                        menuItem="overview"
                        handleChange={handleChange}
                      />,
                    ]}
                  >
                    <List.Item.Meta title="Overview" />
                  </List.Item>
                  <List.Item
                    className={styles.listItem}
                    actions={[
                      <MenuSwitch
                        menuItem="aerialVerification"
                        handleChange={handleChange}
                      />,
                    ]}
                  >
                    <List.Item.Meta title="Aerial Verification" />
                  </List.Item>
                  <List.Item
                    className={styles.listItem}
                    actions={[
                      <MenuSwitch
                        menuItem="moldReview"
                        handleChange={handleChange}
                      />,
                    ]}
                  >
                    <List.Item.Meta title="Mold Review" />
                  </List.Item>
                  <List.Item
                    className={styles.listItem}
                    actions={[
                      <MenuSwitch
                        menuItem="officeReview"
                        handleChange={handleChange}
                      />,
                    ]}
                  >
                    <List.Item.Meta title="Office Review" />
                  </List.Item>
                  <List.Item
                    className={styles.listItem}
                    actions={[
                      <MenuSwitch
                        menuItem="readyForMaterial"
                        handleChange={handleChange}
                      />,
                    ]}
                  >
                    <List.Item.Meta title="Ready For Material" />
                  </List.Item>
                  <List.Item
                    className={styles.listItem}
                    actions={[
                      <MenuSwitch
                        menuItem="readyForDumpster"
                        handleChange={handleChange}
                      />,
                    ]}
                  >
                    <List.Item.Meta title="Ready For Dumpster" />
                  </List.Item>
                  <List.Item
                    className={styles.listItem}
                    actions={[
                      <MenuSwitch
                        menuItem="readyForSchedule"
                        handleChange={handleChange}
                      />,
                    ]}
                  >
                    <List.Item.Meta title="Ready For Schedule" />
                  </List.Item>
                </List>
              </Card>
            )}

            {(admin === "Yes" || role === "Office") && (
              <Card
                bodyStyle={{ padding: 0 }}
                style={{ borderRadius: 4, overflow: "hidden", marginTop: 24 }}
              >
                <List className={styles.listSpacer}>
                  <List.Item className={styles.listItemHeader}>
                    <List.Item.Meta avatar={<Business />} title="OFFICE" />
                  </List.Item>
                  <List.Item
                    className={styles.listItem}
                    actions={[
                      <MenuSwitch
                        menuItem="collections"
                        handleChange={handleChange}
                      />,
                    ]}
                  >
                    <List.Item.Meta title="Collections" />
                  </List.Item>
                  <List.Item
                    className={styles.listItem}
                    actions={[
                      <MenuSwitch
                        menuItem="expenses"
                        handleChange={handleChange}
                      />,
                    ]}
                  >
                    <List.Item.Meta title="Expenses" />
                  </List.Item>
                  <List.Item
                    className={styles.listItem}
                    actions={[
                      <MenuSwitch
                        menuItem="dropdownManagement"
                        handleChange={handleChange}
                      />,
                    ]}
                  >
                    <List.Item.Meta title="Dropdown Management" />
                  </List.Item>
                  <List.Item
                    className={styles.listItem}
                    actions={[
                      <MenuSwitch
                        menuItem="tieredDropdownManagement"
                        handleChange={handleChange}
                      />,
                    ]}
                  >
                    <List.Item.Meta title="Tiered Dropdown Management" />
                  </List.Item>
                  <List.Item
                    className={styles.listItem}
                    actions={[
                      <MenuSwitch
                        menuItem="permits"
                        handleChange={handleChange}
                      />,
                    ]}
                  >
                    <List.Item.Meta title="Permits" />
                  </List.Item>
                </List>
              </Card>
            )}

            {(admin === "Yes" || role === "Office") && (
              <Card
                bodyStyle={{ padding: 0 }}
                style={{ borderRadius: 4, overflow: "hidden", marginTop: 24 }}
              >
                <List className={styles.listSpacer}>
                  <List.Item className={styles.listItemHeader}>
                    <List.Item.Meta avatar={<HammerWrench />} title="INSTALL" />
                  </List.Item>
                  <List.Item
                    className={styles.listItem}
                    actions={[
                      <MenuSwitch
                        menuItem="subManagement"
                        handleChange={handleChange}
                      />,
                    ]}
                  >
                    <List.Item.Meta title="Sub Management" />
                  </List.Item>
                  <List.Item
                    className={styles.listItem}
                    actions={[
                      <MenuSwitch
                        menuItem="dumpsterManagement"
                        handleChange={handleChange}
                      />,
                    ]}
                  >
                    <List.Item.Meta title="Dumpster Supplier Management" />
                  </List.Item>
                  <List.Item
                    className={styles.listItem}
                    actions={[
                      <MenuSwitch
                        menuItem="supplierManagement"
                        handleChange={handleChange}
                      />,
                    ]}
                  >
                    <List.Item.Meta title="Supplier Management" />
                  </List.Item>
                  <List.Item
                    className={styles.listItem}
                    actions={[
                      <MenuSwitch
                        menuItem="manageDumpsters"
                        handleChange={handleChange}
                      />,
                    ]}
                  >
                    <List.Item.Meta title="Manage Dumpsters" />
                  </List.Item>
                  <List.Item
                    className={styles.listItem}
                    actions={[
                      <MenuSwitch
                        menuItem="preJobInspections"
                        handleChange={handleChange}
                      />,
                    ]}
                  >
                    <List.Item.Meta title="Pre-Job Inspections" />
                  </List.Item>
                  <List.Item
                    className={styles.listItem}
                    actions={[
                      <MenuSwitch
                        menuItem="postJobInspections"
                        handleChange={handleChange}
                      />,
                    ]}
                  >
                    <List.Item.Meta title="Post-Job Inspections" />
                  </List.Item>
                  <List.Item
                    className={styles.listItem}
                    actions={[
                      <MenuSwitch
                        menuItem="toDos"
                        handleChange={handleChange}
                      />,
                    ]}
                  >
                    <List.Item.Meta title="To-dos" />
                  </List.Item>
                </List>
              </Card>
            )}

            {admin === "Yes" && (
              <Card
                bodyStyle={{ padding: 0 }}
                style={{ borderRadius: 4, overflow: "hidden", marginTop: 24 }}
              >
                <List className={styles.listSpacer}>
                  <List.Item className={styles.listItemHeader}>
                    <List.Item.Meta avatar={<AccountCog />} title="ADMIN" />
                  </List.Item>
                  <List.Item
                    className={styles.listItem}
                    actions={[
                      <MenuSwitch
                        menuItem="users"
                        handleChange={handleChange}
                      />,
                    ]}
                  >
                    <List.Item.Meta title="Users" />
                  </List.Item>
                  <List.Item
                    className={styles.listItem}
                    actions={[
                      <MenuSwitch
                        menuItem="needsBrokedown"
                        handleChange={handleChange}
                      />,
                    ]}
                  >
                    <List.Item.Meta title="Needs Brokedown" />
                  </List.Item>
                  <List.Item
                    className={styles.listItem}
                    actions={[
                      <MenuSwitch
                        menuItem="drawActivities"
                        handleChange={handleChange}
                      />,
                    ]}
                  >
                    <List.Item.Meta title="Draw Activities" />
                  </List.Item>
                  <List.Item
                    className={styles.listItem}
                    actions={[
                      <MenuSwitch
                        menuItem="companyExpenses"
                        handleChange={handleChange}
                      />,
                    ]}
                  >
                    <List.Item.Meta title="Company Expenses" />
                  </List.Item>
                  <List.Item
                    className={styles.listItem}
                    actions={[
                      <MenuSwitch
                        menuItem="version"
                        handleChange={handleChange}
                      />,
                    ]}
                  >
                    <List.Item.Meta title="Version" />
                  </List.Item>
                </List>
              </Card>
            )}
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default ManageMenu;

const MenuSwitch = ({ handleChange, menuItem }) => {
  const onChange = (e) => {
    handleChange(menuItem, e);
  };

  return (
    <Form.Item
      name={menuItem}
      valuePropName="checked"
      style={{ marginBottom: 0 }}
    >
      <Switch
        checkedChildren="on"
        unCheckedChildren="off"
        onChange={onChange}
      />
    </Form.Item>
  );
};
