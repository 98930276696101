import React, { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateActivitiesOnly } from "Actions/dataActions";
import Form from "antd/es/form";
import message from "antd/es/message";
import Button from "antd/es/button";
import Select from "antd/es/select";
import Input from "antd/es/input";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import api from "API";
import CancelButton from "Components/CancelButton";
import useSuppliers from "Hooks/useSuppliers";
import Suppliers from "Components/Inputs/Suppliers";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";
import MarkUpHelper from "Components/MarkUpHelper";

import axios from "axios";
import generateFilePreview from "Utils/generateFilePreview";
import generateFileSize from "Utils/generateFileSize";
import List from "antd/es/list";
import UploadRow from "Components/images/UploadRow";
import isPdf from "Utils/isPdf";
import pdf from "Assets/pdf.svg";
import Upload from "antd/es/upload";
import styles from "./styles.module.scss";
import InboxOutlined from "@ant-design/icons/InboxOutlined";

const messageKey = "messageKey";

const MaterialPickup = ({
  unsavedChanges,
  setUnsavedChanges,
  handleClose,
  type,
}) => {
  const dispatch = useDispatch();
  const formDetails = useSelector(
    (state) => state.formState.materialPickup.formDetails
  );
  const name = useSelector((state) => state.authState.name);
  const phone = useSelector((state) => state.authState.phone);
  const email = useSelector((state) => state.authState.email);
  const viewableActivities = useSelector(
    (state) => state.dataState.viewableActivities
  );
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const suppliers = useSuppliers();

  const [_fileList, _setFileList] = useState([]);
  const [uploads, setUploads] = useState({});
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    const to = ["install@gopremier.net", email];
    if (formDetails.projectManager && formDetails.projectManager.email) {
      if (formDetails.projectManager.email !== email) {
        to.push(formDetails.projectManager.email);
      }
    }

    if (suppliers && Object.keys(suppliers).length > 0) {
      Object.keys(suppliers).map(async (s) => {
        if (suppliers[s].companyName === formDetails.supplier) {
          to.push(suppliers[s].email);

          form.setFieldsValue({
            supplier: suppliers[s]._sub,
            to,
          });
        }
        return s;
      });
    } else {
      form.setFieldsValue({
        to,
      });
    }
  }, [
    suppliers,
    formDetails.supplier,
    formDetails.projectManager,
    form,
    email,
  ]);

  useEffect(() => {
    if (uploading) {
      let _uploading = false;
      Object.keys(uploads).map((key) => {
        if (uploads[key].upload !== 100 && uploads[key].upload !== true) {
          _uploading = true;
          return key;
        }
        return key;
      });
      if (!_uploading) {
        setTimeout(() => {
          setUploading(false);
        }, 1000);
      }
    }
  }, [uploads]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleBeforeUpload = async (file) => {
    try {
      setUploading(true);
      const size = await generateFileSize(file.size);
      let preview;
      if (isPdf(file.name)) {
        preview = pdf;
      } else {
        preview = await generateFilePreview(file);
      }
      setUploads((prv) => {
        return {
          ...prv,
          [file.uid]: {
            preview,
            name: file.name,
            size,
            failed: false,
            upload: 0,
          },
        };
      });
      return file;
    } catch (err) {
      console.log("beforeUpload err", err);
    }
  };

  const handleUpload = async ({ file, onProgress }) => {
    try {
      let fileName = `${new Date().getTime()}-${file.name.replace(
        /[^a-zA-Z0-9.]/gi,
        "_"
      )}`;
      const src = `https://s3.us-east-2.amazonaws.com/pr-crm/${formDetails._id}/activities/${fileName}`;
      let thumbnail;
      const ext = src.split(".").pop().toLowerCase();
      if (ext !== "pdf")
        thumbnail = `https://s3.us-east-2.amazonaws.com/pr-crm-thumbnail/${formDetails._id}/activities/${fileName}`;

      _setFileList((prevState) => [
        ...prevState,
        {
          originFileObj: file,
          _id: file.uid,
          uid: file.uid,
          name: file.name,
          mediaType: isPdf(src) ? "pdf" : "image",
          _projectId: formDetails._id,
          size: file.size,
          type: file.type,
          src,
          thumbnail,
        },
      ]);

      const res = await api.post("/material-pickup/upload", {
        type: file.type,
        name: fileName,
        _projectId: formDetails._id,
      });
      await axios
        .put(res.data, file, {
          headers: {
            "Content-Type": file.type,
          },
          onUploadProgress: (e) => {
            onProgress(e);
          },
        })
        .catch(function (err) {
          console.log("upload err", err);

          setUploads((prv) => {
            return {
              ...prv,
              [file.uid]: {
                ...prv[file.uid],
                failed: true,
              },
            };
          });

          _setFileList((prevState) => {
            const _filteredFileList = prevState.filtered(
              (prv) => prv.uid !== file.uid
            );
            return _filteredFileList;
          });
        });
    } catch (err) {
      console.log("handleUpload err", err);
    }
  };

  const handleProgress = (progress, file) => {
    const _progress = parseInt((progress.loaded * 100) / progress.total, 10);
    setUploads((prv) => {
      return {
        ...prv,
        [file.uid]: {
          ...prv[file.uid],
          upload: _progress,
        },
      };
    });
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const onFinish = useCallback(
    async (values) => {
      try {
        setLoading(true);
        // message.loading("Sending Material Pickup Request...", 0);
        message.loading({
          content: "Sending material pickup request...",
          duration: 0,
          key: messageKey,
        });
        values._id = formDetails._id;
        values.projectAddress = formDetails.projectAddress;
        values.customerId = formDetails.customerId;
        values.images = _fileList;
        values._scopeId = formDetails._scopeId;

        const res = await api.post("/materialPickup", values);

        dispatch(
          updateActivitiesOnly({
            viewableActivities,
            activities: res.data._activities,
            salesObj: res.data.salesObj,
            _projectId: formDetails._id,
          })
        );

        if (formDetails.callback) {
          formDetails.callback();
        }

        // message.success("Request for Material Pickup sent");
        message.success({
          content: "Request for material pickup sent",
          duration: 1.5,
          key: messageKey,
        });
        handleClose(true);
      } catch (err) {
        setLoading(false);
        // message.error("Error sending request");
        message.error({
          content: "Error sending request",
          duration: 1.5,
          key: messageKey,
        });
        console.log("err", err);
      }
    },
    [formDetails, _fileList, dispatch, viewableActivities, handleClose]
  );

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error sending request");
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  const handleSupplierChange = async (value) => {
    try {
      if (value) {
        let newValue = [suppliers[value].email];
        let toValue = await form.getFieldValue("to");
        if (toValue) {
          for (const t of toValue) {
            newValue.push(t);
          }
        }
        form.setFieldsValue({
          to: newValue,
        });
      }
    } catch (err) {
      console.log("handleSupplierChange err", err);
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      className="form"
      initialValues={{
        subject: `Material Return Pickup - ${formDetails.customerId}`,
        body: `Returns are ready to be picked up for ID **${formDetails.customerId}** at **${formDetails.projectAddress}**.\n\nIf these cannot be picked up within 48 hours please notify me ASAP. Thank you!\n\nBest Regards,\n**${name}**\n${phone}\n${email}`,
      }}
    >
      <RemoveAutocomplete />

      <FormBody
        className="content-inner"
        style={{
          paddingLeft: 24,
          paddingTop: 24,
          paddingRight: 24,
          paddingBottom: 8,
          maxHeight: type === "modal" ? "calc(100vh - 161px)" : "unset",
        }}
      >
        <Form.Item
          name="supplier"
          label="Supplier"
          rules={[{ required: true, message: "Supplier is required" }]}
        >
          <Suppliers suppliers={suppliers} onChange={handleSupplierChange} />
        </Form.Item>

        <Form.Item
          label="To"
          name="to"
          rules={[
            {
              required: true,
              message: "You must include at least one email",
            },
          ]}
        >
          <Select mode="tags" />
        </Form.Item>

        <Form.Item
          label="Subject"
          name="subject"
          rules={[
            {
              required: true,
              message: "You must include a subject",
            },
          ]}
        >
          <InputNoAutoComplete id="subject" />
        </Form.Item>

        <Form.Item
          label={<MarkUpHelper title="Body" />}
          name="body"
          rules={[{ required: true, message: "You must include a body" }]}
        >
          <Input.TextArea rows={4} placeholder="Compose Email" />
        </Form.Item>

        <Form.Item
          name="images"
          valuePropName="fileList"
          getValueFromEvent={normFile}
        >
          <Upload.Dragger
            multiple
            beforeUpload={handleBeforeUpload}
            customRequest={handleUpload}
            showUploadList={false}
            onProgress={handleProgress}
            accept="image/*, application/pdf"
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload.
            </p>
          </Upload.Dragger>
        </Form.Item>

        {_fileList.length > 0 && (
          <List className={styles.uploadRow} bordered>
            {Object.keys(uploads).map((key) => {
              return (
                <UploadRow
                  key={key}
                  preview={uploads[key].preview}
                  name={uploads[key].name}
                  size={uploads[key].size}
                  upload={uploads[key].upload}
                  failed={uploads[key].failed}
                />
              );
            })}
          </List>
        )}
      </FormBody>
      <FormControls className="sticky-footer">
        <CancelButton handleClick={handleClose} />
        <Form.Item style={{ margin: 0 }}>
          <Button
            htmlType="submit"
            type="primary"
            loading={loading}
            disabled={loading || !unsavedChanges || uploading}
          >
            Submit
          </Button>
        </Form.Item>
      </FormControls>
    </Form>
  );
};

export default MaterialPickup;
