import { useEffect, useState } from "react";
import api from "API";
let isMounted = false;

export default function useDumpsterSuppliers() {
  const [suppliers, setSuppliers] = useState({});

  useEffect(() => {
    isMounted = true;
    fetchSuppliers();

    return () => (isMounted = false);
  }, []);

  const fetchSuppliers = async () => {
    try {
      const res = await api.post("/vendors/get", { type: "dumpster" });
      let arr = {};
      for (const s of res.data) {
        arr[s._id] = {
          _sub: s._id,
          companyName: s.companyName,
          email: s.email,
          containers: s.containers,
        };
      }

      if (isMounted) setSuppliers(arr);
    } catch (err) {
      console.log("err", err);
    }
  };

  return suppliers;
}
