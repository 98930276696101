import React, { useEffect, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import HighCharts from "highcharts";
import api from "API";
import Row from "antd/es/row";
import Col from "antd/es/col";
import message from "antd/es/message";
import StatisticCard from "./StatisticCard";
import DealFlowReport from "./DealFlowReport";
import BacklogReport from "./BacklogReport";
import TableCard from "./TableCard";
import useIsMounted from "Hooks/useIsMounted";
import styles from "./styles.module.scss";
import TableView from "./TableView";

const ReportsDashboardAdmin = () => {
  const isMounted = useIsMounted();
  const [viewingStats, setViewingStats] = useState(true);
  const [viewingReport, setViewingReport] = useState(undefined);

  const [dealFlowOptions, setDealFlowOptions] = useState({
    chart: {
      type: "column",
      height: 417,
      paddingBottom: 0,
      marginBottom: 40,
      marginRight: 0,
      paddingRight: 0,
      ignoreHiddenSeries: true,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      // visible: false,
      gridLineColor: "transparent",
      gridLineWidth: 0,
      lineColor: "transparent",
      lineWidth: 0,
      categories: ["Leads<br />Generated", "Sat<br />Appointments", "Sold"],
      labels: {
        style: {
          fontSize: "12px",
          fontFamily: "Verdana, sans-serif",
        },
      },
      title: {
        enabled: false,
      },

      tickInterval: 1,
      minPadding: 0,
      maxPadding: 0,
      startOnTick: true,
      endOnTick: true,
      tickWidth: 0,
      min: 0,
    },
    yAxis: {
      title: {
        text: "",
      },
      min: 0,
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      column: {
        pointWidth: 50,
        grouping: false,
        dataLabels: {
          enabled: true,
          formatter: function () {
            return this.y;
          },
        },
      },
    },
    series: [
      {
        name: "Data",
        data: [0, 0, 0],
      },
    ],
  });

  const [backLogCountOptions, setBackLogCountOptions] = useState({
    chart: {
      type: "column",
      height: 300,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      type: "category",
      labels: {
        rotation: -45,
        style: {
          fontSize: "12px",
          fontFamily: "Verdana, sans-serif",
        },
      },
    },
    yAxis: {
      title: {
        text: "",
      },
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: true,
          style: {
            color: "#1890ff",
            textDecoration: "underline",
            cursor: "pointer",
          },
          formatter: function () {
            return this.y;
          },
        },
      },
      series: {
        point: {
          events: {
            click: function (e) {
              handleSwitchView(
                "Backlog Value (Retail) " + e.point.options.name
              );
            },
          },
        },
      },
    },
    series: [
      {
        name: "No Data",
        data: [],
      },
    ],
  });

  const [backLogValueOptions, setBackLogValueOptions] = useState({
    chart: {
      type: "column",
      height: 300,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      type: "category",
      labels: {
        rotation: -45,
        style: {
          fontSize: "12px",
          fontFamily: "Verdana, sans-serif",
        },
      },
    },
    yAxis: {
      title: {
        text: "",
      },
      labels: {
        formatter: function () {
          let label = this.value;
          if (this.value >= 1000000) {
            label = this.value / 1000000 + "M";
          } else if (this.value >= 1000) {
            label = this.value / 1000 + "k";
          } else if (this.value <= -1000) {
            label = this.value / 1000 + "k";
          } else if (this.value <= -1000000) {
            label = this.value / 1000000 + "M";
          }
          return `${label}`;
        },
      },
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: true,
          style: {
            color: "#1890ff",
            textDecoration: "underline",
            cursor: "pointer",
          },
          formatter: function () {
            return "$" + HighCharts.numberFormat(this.y, 2);
          },
        },
      },
      series: {
        point: {
          events: {
            click: function (e) {
              handleSwitchView(
                "Backlog Value (Retail) " + e.point.options.name
              );
            },
          },
        },
      },
    },
    series: [
      {
        name: "No Data",
        data: [],
      },
    ],
  });

  const [backLogCountNCOptions, setBackLogCountNCOptions] = useState({
    chart: {
      type: "column",
      height: 300,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      type: "category",
      labels: {
        rotation: -45,
        style: {
          fontSize: "12px",
          fontFamily: "Verdana, sans-serif",
        },
      },
    },
    yAxis: {
      title: {
        text: "",
      },
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: true,
          style: {
            color: "#1890ff",
            textDecoration: "underline",
            cursor: "pointer",
          },
          formatter: function () {
            return this.y;
          },
        },
      },
      series: {
        point: {
          events: {
            click: function (e) {
              handleSwitchView(
                "Backlog Value (New Construction) " + e.point.options.name
              );
            },
          },
        },
      },
    },
    series: [
      {
        name: "No Data",
        data: [],
      },
    ],
  });

  const [backLogValueNCOptions, setBackLogValueNCOptions] = useState({
    chart: {
      type: "column",
      height: 300,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      type: "category",
      labels: {
        rotation: -45,
        style: {
          fontSize: "12px",
          fontFamily: "Verdana, sans-serif",
        },
      },
    },
    yAxis: {
      title: {
        text: "",
      },
      labels: {
        formatter: function () {
          let label = this.value;
          if (this.value >= 1000000) {
            label = this.value / 1000000 + "M";
          } else if (this.value >= 1000) {
            label = this.value / 1000 + "k";
          } else if (this.value <= -1000) {
            label = this.value / 1000 + "k";
          } else if (this.value <= -1000000) {
            label = this.value / 1000000 + "M";
          }
          return `${label}`;
        },
      },
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: true,
          style: {
            color: "#1890ff",
            textDecoration: "underline",
            cursor: "pointer",
          },
          formatter: function () {
            return "$" + HighCharts.numberFormat(this.y, 2);
          },
        },
      },
      series: {
        point: {
          events: {
            click: function (e) {
              handleSwitchView(
                "Backlog Value (New Construction) " + e.point.options.name
              );
            },
          },
        },
      },
    },
    series: [
      {
        name: "No Data",
        data: [],
      },
    ],
  });

  const [salesYTD, setSalesYTD] = useState([
    { name: "1", value: 0, trend: 0 },
    { name: "2", value: 0, trend: 0 },
    { name: "3", value: 0, trend: 0 },
    { name: "4", value: 0, trend: 0 },
    { name: "5", value: 0, trend: 0 },
    { name: "6", value: 0, trend: 0 },
    { name: "7", value: 0, trend: 0 },
    { name: "8", value: 0, trend: 0 },
    { name: "9", value: 0, trend: 0 },
  ]);

  const [collectedByScopeYTD, setCollectedByScopeYTD] = useState([
    { name: "1", value: 0, trend: 0 },
    { name: "2", value: 0, trend: 0 },
    { name: "3", value: 0, trend: 0 },
    { name: "4", value: 0, trend: 0 },
    { name: "5", value: 0, trend: 0 },
    { name: "6", value: 0, trend: 0 },
    { name: "7", value: 0, trend: 0 },
    { name: "8", value: 0, trend: 0 },
    { name: "9", value: 0, trend: 0 },
  ]);

  const [adCampaignsValue, setAdCampaignsValue] = useState([
    { name: "1", value: 0, trend: 0 },
    { name: "2", value: 0, trend: 0 },
    { name: "3", value: 0, trend: 0 },
    { name: "4", value: 0, trend: 0 },
    { name: "5", value: 0, trend: 0 },
    { name: "6", value: 0, trend: 0 },
    { name: "7", value: 0, trend: 0 },
    { name: "8", value: 0, trend: 0 },
    { name: "9", value: 0, trend: 0 },
  ]);

  const [adCampaignsCount, setAdCampaignsCount] = useState([
    { name: "1", value: 0, trend: 0 },
    { name: "2", value: 0, trend: 0 },
    { name: "3", value: 0, trend: 0 },
    { name: "4", value: 0, trend: 0 },
    { name: "5", value: 0, trend: 0 },
    { name: "6", value: 0, trend: 0 },
    { name: "7", value: 0, trend: 0 },
    { name: "8", value: 0, trend: 0 },
    { name: "9", value: 0, trend: 0 },
  ]);

  const [estimatesYTDBySalesman, setEstimatesYTDBySalesman] = useState([
    { name: "1", value: 0, trend: 0 },
    { name: "2", value: 0, trend: 0 },
    { name: "3", value: 0, trend: 0 },
    { name: "4", value: 0, trend: 0 },
    { name: "5", value: 0, trend: 0 },
    { name: "6", value: 0, trend: 0 },
    { name: "7", value: 0, trend: 0 },
    { name: "8", value: 0, trend: 0 },
    { name: "9", value: 0, trend: 0 },
  ]);

  const [reviewsYTDBySalesman, setReviewsYTDBySalesman] = useState([
    { name: "1", value: 0, trend: 0 },
    { name: "2", value: 0, trend: 0 },
    { name: "3", value: 0, trend: 0 },
    { name: "4", value: 0, trend: 0 },
    { name: "5", value: 0, trend: 0 },
    { name: "6", value: 0, trend: 0 },
    { name: "7", value: 0, trend: 0 },
    { name: "8", value: 0, trend: 0 },
    { name: "9", value: 0, trend: 0 },
  ]);

  const [subProfitabilityOptions, setSubProfitabilityOptions] = useState({
    chart: {
      type: "column",
      height: 300,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      type: "category",
      labels: {
        rotation: -45,
        style: {
          fontSize: "12px",
          fontFamily: "Verdana, sans-serif",
        },
      },
    },
    yAxis: {
      title: {
        text: "",
      },
      labels: {
        formatter: function () {
          let label = this.value;
          if (this.value >= 1000000) {
            label = this.value / 1000000 + "M";
          } else if (this.value >= 1000) {
            label = this.value / 1000 + "k";
          } else if (this.value <= -1000) {
            label = this.value / 1000 + "k";
          } else if (this.value <= -1000000) {
            label = this.value / 1000000 + "M";
          }
          return `${label}`;
        },
      },
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: true,
          formatter: function () {
            return HighCharts.numberFormat(this.y, 2) + "%";
          },
        },
      },
    },
    series: [
      {
        name: "No Data",
        data: [],
      },
    ],
  });

  const [data, setData] = useState({
    loading: true,
    yearOverYearSales: {
      thisYear: 0,
      lastYear: 0,
      trend: 0,
    },
    drawBalance: 0,
    collectionsThisMonth: {
      thisYear: 0,
      lastYear: 0,
      trend: 0,
    },
    collectionsYTD: {
      thisYear: 0,
      lastYear: 0,
      trend: 0,
    },
    leadsNext14Days: 0,
    estimatesYTD: {
      thisYear: 0,
      lastYear: 0,
      trend: 0,
    },
    backlogTotal: 0,
    dealFlow: {
      leads: 0,
      sat: 0,
      sold: 0,
      leadConversion: 0,
      satConversion: 0,
      soldConversion: 0,
    },
    brokedownYTD: {
      thisYear: 0,
      lastYear: 0,
      trend: 0,
    },
    inProduction: 0,
    grossProfitYTD: {
      thisYear: 0,
      lastYear: 0,
      trend: 0,
    },
    completedNotInvoiced: 0,
    invoicedNotCollected: 0,
    closedOutNotBrokedown: 0,
    leadsSatThisMonth: {
      thisYear: 0,
      lastYear: 0,
      trend: 0,
    },
    // yearOverYearReviews: {
    //   thisYear: 0,
    //   lastYear: 0,
    //   trend: 0,
    //   leader: undefined,
    // },
    // downPaymentSurplus: 0,
  });

  useEffect(() => {
    if (viewingReport) {
    } else {
      populateDashboard();
    }
  }, [viewingStats, viewingReport]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   populateDashboard();
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const populateDashboard = async () => {
    try {
      setData((currentData) => {
        return {
          ...currentData,
          loading: true,
        };
      });
      const res = await api.get("/reports-dashboard-admin/populate");

      if (isMounted.current) {
        unstable_batchedUpdates(() => {
          setData({
            yearOverYearSales: res.data.yearOverYearSales,
            drawBalance: res.data.drawBalance,
            collectionsThisMonth: res.data.collectionsThisMonth,
            collectionsYTD: res.data.collectionsYTD,
            leadsNext14Days: res.data.leadsNext14Days,
            estimatesYTD: res.data.estimatesYTD,
            backlogTotal: res.data.backlogTotal,
            dealFlow: res.data.dealFlow,
            brokedownYTD: res.data.brokedownYTD,
            inProduction: res.data.inProduction,
            grossProfitYTD: res.data.grossProfitYTD,
            completedNotInvoiced: res.data.completedNotInvoiced,
            invoicedNotCollected: res.data.invoicedNotCollected,
            closedOutNotBrokedown: res.data.closedOutNotBrokedown,
            leadsSatThisMonth: res.data.leadsSatThisMonth,
            yearOverYearReviews: res.data.yearOverYearReviews,
            // downPaymentSurplus: res.data.downPaymentSurplus,
            loading: false,
          });
          setDealFlowOptions((current) => {
            return {
              ...current,
              series: [
                {
                  name: "Data",
                  data: [
                    res.data.dealFlow.leads,
                    res.data.dealFlow.sat,
                    res.data.dealFlow.sold,
                  ],
                },
              ],
            };
          });
          setBackLogCountOptions((current) => {
            return {
              ...current,
              series: [
                {
                  name: "Data",
                  data: res.data.backlogCount,
                },
              ],
            };
          });
          setSalesYTD(res.data.salesYTD);
          setCollectedByScopeYTD(res.data.collectedByScopeYTD);
          setBackLogValueOptions((current) => {
            return {
              ...current,
              series: [
                {
                  name: "Data",
                  data: res.data.backlogValue,
                },
              ],
            };
          });
          setAdCampaignsValue(res.data.adCampaignsValueYOY);
          setAdCampaignsCount(res.data.adCampaignsCountYOY);
          setEstimatesYTDBySalesman(res.data.estimatesYTDBySalesman);
          setReviewsYTDBySalesman(res.data.reviewsYTDBySalesman);
          setBackLogCountNCOptions((current) => {
            return {
              ...current,
              series: [
                {
                  name: "Data",
                  data: res.data.backlogCountNC,
                },
              ],
            };
          });
          setBackLogValueNCOptions((current) => {
            return {
              ...current,
              series: [
                {
                  name: "Data",
                  data: res.data.backlogValueNC,
                },
              ],
            };
          });
          setSubProfitabilityOptions((current) => {
            return {
              ...current,
              series: [
                {
                  name: "Data",
                  data: res.data.subProfitability,
                },
              ],
            };
          });
        });
      }
    } catch (err) {
      console.log("err", err);
      message.error("Error retrieving data");
    }
  };

  // const getBacklogData = async () => {
  //   try {
  //     const response = await api.get("/get-backlog-list");
  //     if (response.data.length > 0) {
  //       csv("backlog.csv", response.data);
  //     } else {
  //       message.error("No data to download");
  //     }
  //   } catch (err) {
  //     console.log("err", err);
  //     message.error("getBacklog Data failed");
  //   }
  // };

  // const getEstimatesData = async () => {
  //   try {
  //     const response = await api.get("/get-estimates-list");
  //     if (response.data.length > 0) {
  //       csv("estimates.csv", response.data);
  //     } else {
  //       message.error("No data to download");
  //     }
  //   } catch (err) {
  //     console.log("err", err);
  //     message.error("getEstimatesData Data failed");
  //   }
  // };

  const handleSwitchView = (title) => {
    unstable_batchedUpdates(() => {
      setViewingStats(!viewingStats);
      setViewingReport(title);
    });
  };

  return (
    <div style={{ height: "100%" }}>
      <div
        style={{ background: "#f0f2f5", height: "100%", padding: 16 }}
        className={`${viewingStats ? "content-inner" : ""}`}
      >
        <div
          className={`${styles.statsContainer} ${
            viewingStats ? "" : styles.hide
          }`}
        >
          <Row gutter={16}>
            <Col xs={24} sm={12} md={6} className="mb16">
              <StatisticCard
                title="Year Over Year Sales"
                value={data.yearOverYearSales.thisYear}
                decimalPlaces={2}
                trend={data.yearOverYearSales.trend}
                lastYear={data.yearOverYearSales.lastYear}
                loading={data.loading}
                onClick={handleSwitchView}
              />
            </Col>
            <Col xs={24} sm={12} md={6} className="mb16">
              <StatisticCard
                title="Collections This Month"
                value={data.collectionsThisMonth.thisYear}
                decimalPlaces={2}
                trend={data.collectionsThisMonth.trend}
                lastYear={data.collectionsThisMonth.lastYear}
                loading={data.loading}
                onClick={handleSwitchView}
              />
            </Col>
            <Col xs={24} sm={12} md={6} className="mb16">
              <StatisticCard
                title="Collected YTD"
                value={data.collectionsYTD.thisYear}
                decimalPlaces={2}
                trend={data.collectionsYTD.trend}
                lastYear={data.collectionsYTD.lastYear}
                loading={data.loading}
                onClick={handleSwitchView}
              />
            </Col>
            <Col xs={24} sm={12} md={6} className="mb16">
              <StatisticCard
                title="Backlog Total"
                value={data.backlogTotal}
                decimalPlaces={2}
                loading={data.loading}
                onClick={handleSwitchView}
              />
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <DealFlowReport
                options={dealFlowOptions}
                leads={data.dealFlow.leads}
                sold={data.dealFlow.sold}
                leadConversion={data.dealFlow.leadConversion}
                satConversion={data.dealFlow.satConversion}
                soldConversion={data.dealFlow.soldConversion}
                loading={data.loading}
                // onClick={handleSwitchView}
              />
            </Col>
            <Col xs={24} sm={12}>
              <Row gutter={16}>
                <Col xs={24} sm={12} className="mb16">
                  <StatisticCard
                    title="Leads Set Next 14 Days"
                    value={data.leadsNext14Days}
                    loading={data.loading}
                    onClick={handleSwitchView}
                  />
                </Col>
                <Col xs={24} sm={12} className="mb16">
                  <StatisticCard
                    title="In Production"
                    value={data.inProduction}
                    decimalPlaces={2}
                    loading={data.loading}
                    onClick={handleSwitchView}
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={12} className="mb16">
                  <StatisticCard
                    title="YTD Estimated"
                    value={data.estimatesYTD.thisYear}
                    decimalPlaces={2}
                    trend={data.estimatesYTD.trend}
                    lastYear={data.estimatesYTD.lastYear}
                    loading={data.loading}
                    onClick={handleSwitchView}
                  />
                </Col>
                <Col xs={24} sm={12} className="mb16">
                  <StatisticCard
                    title="Completed Not Invoiced"
                    value={data.completedNotInvoiced}
                    decimalPlaces={2}
                    loading={data.loading}
                    onClick={handleSwitchView}
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={12} className="mb16">
                  <StatisticCard
                    title="Brokedown YTD"
                    value={data.brokedownYTD.thisYear}
                    decimalPlaces={2}
                    trend={data.brokedownYTD.trend}
                    lastYear={data.brokedownYTD.lastYear}
                    loading={data.loading}
                    onClick={handleSwitchView}
                  />
                </Col>
                <Col xs={24} sm={12} className="mb16">
                  <StatisticCard
                    title="Invoiced Not Collected"
                    value={data.invoicedNotCollected}
                    decimalPlaces={2}
                    loading={data.loading}
                    onClick={handleSwitchView}
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={12} className="mb16">
                  <StatisticCard
                    title="Gross Profit YTD"
                    value={data.grossProfitYTD.thisYear}
                    decimalPlaces={2}
                    trend={data.grossProfitYTD.trend}
                    lastYear={data.grossProfitYTD.lastYear}
                    loading={data.loading}
                    onClick={handleSwitchView}
                  />
                </Col>
                <Col xs={24} sm={12} className="mb16">
                  <StatisticCard
                    title="Closed Out Not Brokedown"
                    value={data.closedOutNotBrokedown}
                    decimalPlaces={2}
                    loading={data.loading}
                    onClick={handleSwitchView}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12} className="mb16">
              <BacklogReport
                options={backLogCountOptions}
                title="Backlog Count (Retail)"
                loading={data.loading}
                onClick={handleSwitchView}
              />
            </Col>
            <Col xs={24} sm={12} className="mb16">
              <BacklogReport
                options={backLogValueOptions}
                title="Backlog Value (Retail)"
                loading={data.loading}
                onClick={handleSwitchView}
              />
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12} className="mb16">
              <BacklogReport
                options={backLogCountNCOptions}
                title="Backlog Count (New Construction)"
                loading={data.loading}
                onClick={handleSwitchView}
              />
            </Col>
            <Col xs={24} sm={12} className="mb16">
              <BacklogReport
                options={backLogValueNCOptions}
                title="Backlog Value (New Construction)"
                loading={data.loading}
                onClick={handleSwitchView}
              />
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={8} className="mb16">
              <TableCard
                title="YTD Sales"
                content={salesYTD}
                loading={data.loading}
                onClick={handleSwitchView}
              />
            </Col>
            <Col xs={24} sm={8} className="mb16">
              <TableCard
                title="Collected By Project YTD"
                content={collectedByScopeYTD}
                loading={data.loading}
                onClick={handleSwitchView}
              />
            </Col>
            <Col xs={24} sm={12} md={8} className="mb16">
              <TableCard
                title="Estimates YTD"
                content={estimatesYTDBySalesman}
                loading={data.loading}
                // height={224.73}
                onClick={handleSwitchView}
              />
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={8} className="mb16">
              <TableCard
                title="Ad Campaigns Year Over Year (value)"
                content={adCampaignsValue}
                loading={data.loading}
                // onClick={handleSwitchView}
              />
            </Col>
            <Col xs={24} sm={8} className="mb16">
              <TableCard
                title="Ad Campaigns Year Over Year (count)"
                content={adCampaignsCount}
                loading={data.loading}
                // onClick={handleSwitchView}
                count
              />
            </Col>
            <Col xs={24} md={8} className="mb16">
              <TableCard
                title="Reviews YTD"
                content={reviewsYTDBySalesman}
                loading={data.loading}
                // height={224.73}
                onClick={handleSwitchView}
              />
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={16} className="mb16">
              <BacklogReport
                options={subProfitabilityOptions}
                title="Subcontractor Profitability"
                loading={data.loading}
                onClick={handleSwitchView}
              />
            </Col>
            <Col xs={24} sm={8}>
              <Row gutter={16}>
                <Col xs={24} className="mb16">
                  <StatisticCard
                    title="Draw Balance"
                    value={data.drawBalance}
                    decimalPlaces={2}
                    loading={data.loading}
                    onClick={handleSwitchView}
                  />
                </Col>
                <Col xs={24} className="mb16">
                  <StatisticCard
                    title="Leads Sat This Month"
                    value={data.leadsSatThisMonth.thisYear}
                    decimalPlaces={0}
                    trend={data.leadsSatThisMonth.trend}
                    lastYear={data.leadsSatThisMonth.lastYear}
                    loading={data.loading}
                    onClick={handleSwitchView}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div
          className={`${styles.listContainer} ${
            viewingStats ? styles.hide : ""
          }`}
        >
          <TableView
            handleSwitchView={handleSwitchView}
            reportToFetch={viewingReport}
            viewingStats={viewingStats}
          />
        </div>
      </div>
    </div>
  );
};

export default ReportsDashboardAdmin;
