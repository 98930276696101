import React from "react";
import List from "antd/es/list";
import styles from "./styles.module.scss";
import openInNewTab from "Utils/openInNewTab";
import Description from "Icons/Description";

const Scopes = ({ scopes }) => {
  return (
    <>
      {scopes.length > 0 ? (
        scopes.map((scope) => (
          <List
            bordered
            key={scope._id}
            itemLayout="horizontal"
            className={styles.list}
          >
            <ScopesItem scope={scope} />
          </List>
        ))
      ) : (
        <List itemLayout="horizontal" className={styles.list}>
          <List.Item className={`${styles.transactionList} ${styles.none}`}>
            <List.Item.Meta
              avatar={
                <div className={styles.avatar}>
                  <Description color="#108ee9" />
                </div>
              }
              title="No scopes have been created"
            />
          </List.Item>
        </List>
      )}
    </>
  );
};

const ScopesItem = ({ scope }) => {
  const openWorkOrder = (e) => {
    e.stopPropagation();
    openInNewTab(scope.workOrder.src);
  };

  return (
    <List.Item className={styles.transactionList}>
      <List.Item.Meta
        avatar={
          <div className={styles.avatar}>
            <Description
              color={
                scope.workOrder && scope.workOrder.src ? "#108ee9" : "#ff7056"
              }
            />
          </div>
        }
        title={scope.name}
        description={
          scope.workOrder && scope.workOrder.src ? (
            <>
              <div>Repair: {scope.repair ? "Yes" : "No"}</div>
              {(scope.name === "Roofing - Steep" ||
                scope.name === "Roofing - Flat" ||
                scope.name === "Siding") && (
                <div>
                  Replacement Type:{" "}
                  {scope.workOrder && scope.workOrder.roofType
                    ? scope.workOrder.roofType.join(", ")
                    : ""}
                </div>
              )}

              {(scope.name === "Roofing - Steep" ||
                scope.name === "Roofing - Flat") && (
                <div>
                  Pitch(s):{" "}
                  {scope.workOrder && scope.workOrder.pitches
                    ? scope.workOrder.pitches.join(", ")
                    : ""}
                </div>
              )}

              {(scope.name === "Roofing - Steep" ||
                scope.name === "Roofing - Flat" ||
                scope.name === "Siding") && (
                <div>
                  Squares:{" "}
                  {scope.workOrder && scope.workOrder.squares
                    ? scope.workOrder.squares
                    : 0}
                </div>
              )}
            </>
          ) : (
            <div>No work order has been created</div>
          )
        }
        onClick={
          scope.workOrder && scope.workOrder.src ? openWorkOrder : undefined
        }
      />
    </List.Item>
  );
};

export default Scopes;
