import React from "react";
import Form from "antd/es/form";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Currency from "Components/Inputs/Currency";
import Percent from "Components/Inputs/Percent";
import Input from "antd/es/input";

const Mold = ({ form, propertyType }) => {
  const handleMoldChange = async () => {
    try {
      // setTimeout(() => {
      const values = await form.getFieldsValue();
      let contractPrice = 0,
        dumping = 0,
        extras = 0,
        labor = 0,
        materials = 0,
        overhead = 0,
        balance = 0,
        profitPercentage = 0,
        expenses = 0,
        gutters = 0,
        insulation = 0;
      if (
        values.contractPrice === undefined ||
        values.contractPrice === null ||
        values.contractPrice === ""
      ) {
        contractPrice = 0;
      } else {
        contractPrice = values.contractPrice;
      }
      if (
        values.expectedDumping === undefined ||
        values.expectedDumping === null ||
        values.expectedDumping === ""
      ) {
        dumping = 0;
      } else {
        dumping = values.expectedDumping;
      }
      if (
        values.expectedLabor === undefined ||
        values.expectedLabor === null ||
        values.expectedLabor === ""
      ) {
        labor = 0;
      } else {
        labor = values.expectedLabor;
      }
      if (
        values.expectedMaterials === undefined ||
        values.expectedMaterials === null ||
        values.expectedMaterials === ""
      ) {
        materials = 0;
      } else {
        materials = values.expectedMaterials;
      }
      if (
        values.expectedExtras === undefined ||
        values.expectedExtras === null ||
        values.expectedExtras === ""
      ) {
        extras = 0;
      } else {
        extras = values.expectedExtras;
      }
      if (
        values.expectedInsulation === undefined ||
        values.expectedInsulation === null ||
        values.expectedInsulation === ""
      ) {
        insulation = 0;
      } else {
        insulation = values.expectedInsulation;
      }
      if (
        values.expectedGutters === undefined ||
        values.expectedGutters === null ||
        values.expectedGutters === ""
      ) {
        gutters = 0;
      } else {
        gutters = values.expectedGutters;
      }

      expenses = dumping + labor + materials + extras + insulation + gutters;
      overhead = contractPrice * 0.13;
      if (propertyType === "New Construction") {
        overhead = contractPrice * 0.13;
      }
      balance = contractPrice - expenses - overhead;
      profitPercentage = (balance / contractPrice) * 100;
      form.setFieldsValue({
        expectedOverhead: overhead,
        expectedBalance: balance,
        expectedProfitPercentage: profitPercentage,
      });
      // }, 5);
    } catch (err) {
      console.log("handleMoldChange err", err);
    }
  };
  return (
    <>
      <Form.Item
        label="Contract Price"
        name="contractPrice"
        rules={[{ required: true, message: "Contract price required" }]}
      >
        <Currency id="contractPrice" onChange={handleMoldChange} />
      </Form.Item>

      <Form.Item label="Overhead" name="expectedOverhead" initialValue={0}>
        <Currency id="expectedOverhead" disabled />
      </Form.Item>

      <Form.Item
        label="Expected Materials"
        name="expectedMaterials"
        rules={[{ required: true, message: "Expected materials is required" }]}
      >
        <Currency id="expectedMaterials" onChange={handleMoldChange} />
      </Form.Item>

      <Form.Item
        label="Expected Labor"
        name="expectedLabor"
        rules={[{ required: true, message: "Expected labor is required" }]}
      >
        <Currency id="expectedLabor" onChange={handleMoldChange} />
      </Form.Item>

      <Form.Item
        label="Expected Dumping"
        name="expectedDumping"
        rules={[{ required: true, message: "Expected dumping is required" }]}
      >
        <Currency id="expectedDumping" onChange={handleMoldChange} />
      </Form.Item>

      <Form.Item
        label="Expected Extras"
        name="expectedExtras"
        rules={[{ required: true, message: "Expected extras is required" }]}
      >
        <Currency id="expectedExtras" onChange={handleMoldChange} />
      </Form.Item>

      <Form.Item
        label="Expected Gutters"
        name="expectedGutters"
        rules={[{ required: true, message: "Expected gutters is required" }]}
      >
        <Currency id="expectedGutters" onChange={handleMoldChange} />
      </Form.Item>

      <Form.Item
        label="Expected Insulation"
        name="expectedInsulation"
        rules={[{ required: true, message: "Expected insulation is required" }]}
      >
        <Currency id="expectedInsulation" onChange={handleMoldChange} />
      </Form.Item>

      <Form.Item label="Mold Notes" name="moldNotes">
        <Input.TextArea
          defaultValue={`Scope: 
M: 
O: 
L: 
D: 
        
Scope: 
M: 
O: 
L: 
D: `}
          autoComplete="dont-use-autocomplete"
          rows={4}
        />
      </Form.Item>

      <Row gutter={12}>
        <Col xs={12}>
          <Form.Item
            label="Expected Balance"
            name="expectedBalance"
            initialValue={0}
          >
            <Currency id="expectedBalance" disabled />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item
            label="Expected Profit %"
            name="expectedProfitPercentage"
            initialValue={0}
          >
            <Percent id="expectedProfitPercentage" disabled />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default Mold;
