import Button from "antd/es/button";
import message from "antd/es/message";
import React, { useState } from "react";
import api from "API";

let hoverBaseURL = "https://sandbox.hover.to";
if (process.env.NODE_ENV === "production") {
  hoverBaseURL = "https://hover.to";
}

const HoverLinks = ({ hoverJobId, hoverTransferred, _id }) => {
  const [loading, setLoading] = useState(false);

  async function createHoverJob() {
    try {
      setLoading(true);
      await api.post("/hover/create-job", {
        _id,
      });

      setLoading(false);
      message.success("Hover Job successfully created!");
    } catch (err) {
      console.log("createHoverJob err", err);
      setLoading(false);
      message.error("Error creating Hover Job");
    }
  }

  return (
    <>
      <h4 style={{ marginBottom: 3 }}>Hover Links</h4>
      <div style={{ marginBottom: 12 }}>
        <span>
          {hoverTransferred && hoverJobId ? (
            <>
              <a
                target="_Blank"
                rel="noopener noreferrer"
                href={`${hoverBaseURL}/ui/#/property/${hoverJobId}`}
                style={{ marginBottom: 4, display: "block" }}
              >
                Open Hover Details
              </a>
              <a
                target="_Blank"
                rel="noopener noreferrer"
                href={`${hoverBaseURL}/3d/${hoverJobId}`}
                style={{ display: "block" }}
              >
                Open Hover 3d
              </a>
            </>
          ) : hoverJobId ? (
            <>
              <a
                target="_Blank"
                rel="noopener noreferrer"
                href={`hihover://premier-roofing/capture/identifier/${hoverJobId}`}
                style={{ marginBottom: 4, display: "block" }}
              >
                Capture Hover Images (Mobile)
              </a>
              <a
                target="_Blank"
                rel="noopener noreferrer"
                href={`${hoverBaseURL}/ui/#/property/${hoverJobId}`}
              >
                Open Hover Details
              </a>
              {/* TODO: send capture request to homeowner */}
              {/* <a
              target="_Blank"
              rel="noopener noreferrer"
              href={`hihover://premier-roofing/capture/identifier/${hoverJobId}`}
              style={{ display: "block" }}
            >
              Send Capture Request
            </a> */}
            </>
          ) : (
            <Button
              type="primary"
              style={{ width: "100%" }}
              loading={loading}
              disabled={loading}
              onClick={createHoverJob}
            >
              Create Hover Job
            </Button>
          )}
        </span>
      </div>
    </>
  );
};

export default HoverLinks;
