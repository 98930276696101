import React, { useRef } from "react";
import Select from "antd/es/select";
import useIsMobile from "Hooks/useIsMobile";

const InputProjectManager = ({
  value,
  onChange,
  sales,
  placeholder = undefined,
  autoFocus,
  noClear,
}) => {
  const mobile = useIsMobile();
  const ref = useRef();

  const renderSalesmen = Object.keys(sales).map((key) => (
    <Select.Option key={sales[key]._user} value={sales[key]._id}>
      {sales[key].name}
    </Select.Option>
  ));

  const triggerChange = (changedValue) => {
    if (onChange) {
      onChange(changedValue);
    }
  };

  const handleChange = (values) => {
    ref.current.blur();
    triggerChange(values);
  };

  return (
    <Select
      id="projectManager"
      ref={ref}
      autoComplete="edit-detail-field"
      allowClear={!noClear}
      showSearch={!mobile}
      dropdownClassName={mobile ? "isMobile" : null}
      getPopupContainer={(trigger) =>
        !mobile ? trigger.parentNode : document.body
      }
      onChange={handleChange}
      value={value}
      placeholder={placeholder}
      autoFocus={autoFocus}
    >
      {renderSalesmen}
    </Select>
  );
};

export default InputProjectManager;
