import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import FormBody from "Components/FormBody";
import Typography from "antd/es/typography";
import List from "antd/es/list";
import Button from "antd/es/button";
import ImageFallback from "Components/images/Image";

const ProjectView = ({ handleClose, setTitle, type, props }) => {
  const formDetails = useSelector(
    (state) => state.formState.projectView.formDetails
  );
  useEffect(() => {
    setTitle(formDetails.projectAddress);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const addProject = () => {
    formDetails.setSelected((currentSelected) => {
      return [...currentSelected, formDetails];
    });
    formDetails.setMarkers((_markers) => {
      _markers[formDetails._id].marker.setIcon(
        new window.google.maps.MarkerImage(
          "https://s3.us-east-2.amazonaws.com/pr-crm/assets/black_marker.png",
          null,
          null,
          null,
          new window.google.maps.Size(24, 36)
        )
      );
      return _markers;
    });
    handleClose(true);
  };

  const removeProject = () => {
    const _filteredSelected = props.selected.filter(
      (s) => s._id !== formDetails._id
    );
    formDetails.setSelected(_filteredSelected);
    formDetails.setMarkers((_markers) => {
      _markers[formDetails._id].marker.setIcon(
        new window.google.maps.MarkerImage(
          "https://s3.us-east-2.amazonaws.com/pr-crm/assets/blue_marker.png",
          null,
          null,
          null,
          new window.google.maps.Size(24, 36)
        )
      );
      return _markers;
    });
    handleClose(true);
  };

  return (
    <FormBody
      className="content-inner"
      style={{
        padding: 24,
        maxHeight: type === "modal" ? "calc(100vh - 161px)" : "unset",
        height: type === "modal" ? "calc(100% - 77px)" : "100%",
      }}
    >
      <ImageFallback
        alt="project-view"
        image={{
          src: formDetails.starredImageSrc || undefined,
          thumbnail: formDetails.starredImageSrcThumbnail || undefined,
        }}
        style={{
          marginTop: -24,
          marginLeft: -24,
          marginRight: -24,
          width: "calc(100% + 48px)",
          maxHeight: 300,
          minHeight: 300,
          marginBottom: 24,
        }}
      />
      <Typography.Title level={3}>{formDetails.year}</Typography.Title>
      <div>
        {formDetails.crapp1 && (
          <List bordered={false} split={false}>
            <List.Item style={{ padding: 0, paddingBottom: 0 }}>
              <List.Item.Meta
                title={
                  <span>
                    Scopes:{" "}
                    <span className="ant-list-item-meta-description">
                      {formDetails.scopes}
                    </span>
                  </span>
                }
              />
            </List.Item>
            <List.Item style={{ padding: 0, paddingBottom: 0 }}>
              <List.Item.Meta
                title="Manufacturer"
                description={formDetails.manufacturer}
              />
            </List.Item>
            <List.Item style={{ padding: 0, paddingBottom: 0 }}>
              <List.Item.Meta
                title="Product"
                description={formDetails.productLine}
              />
            </List.Item>
            <List.Item style={{ padding: 0, paddingBottom: 0 }}>
              <List.Item.Meta title="Color" description={formDetails.color} />
            </List.Item>
            <List.Item style={{ padding: 0, paddingBottom: 0 }}>
              <List.Item.Meta
                title="Salesman"
                description={formDetails.salesman}
              />
            </List.Item>
          </List>
        )}

        {!formDetails.crapp1 &&
          formDetails &&
          formDetails.scopes &&
          formDetails.scopes.map((scope) => {
            return (
              <div key={scope._id}>
                <List bordered={false} split={false}>
                  <List.Item style={{ padding: 0, paddingBottom: 0 }}>
                    <List.Item.Meta
                      title={
                        <span>
                          Scope:{" "}
                          <span className="ant-list-item-meta-description">
                            {scope.name}
                          </span>
                        </span>
                      }
                    />
                  </List.Item>
                </List>
                {(scope.name === "Roofing - Steep" ||
                  scope.name === "Roof - Steep" ||
                  scope.name === "Roofing - Flat" ||
                  scope.name === "Roof - Flat" ||
                  scope.name === "Siding") && (
                  <List
                    bordered={false}
                    split={false}
                    style={{ marginLeft: 8 }}
                  >
                    {scope.squares && (
                      <List.Item style={{ padding: 0, paddingBottom: 0 }}>
                        <List.Item.Meta
                          title={
                            <span>
                              Squares:{" "}
                              <span className="ant-list-item-meta-description">
                                {scope.squares}
                              </span>
                            </span>
                          }
                        />
                      </List.Item>
                    )}
                    {(scope.manufacturer || scope.shingle || scope.siding) && (
                      <List.Item style={{ padding: 0, paddingBottom: 0 }}>
                        <List.Item.Meta
                          title={
                            <span>
                              Manufacturer:{" "}
                              <span className="ant-list-item-meta-description">
                                {scope.manufacturer
                                  ? scope.manufacturer
                                  : scope.shingle
                                  ? scope.shingle[0]
                                  : scope.siding[0]}
                              </span>
                            </span>
                          }
                        />
                      </List.Item>
                    )}
                    {(scope.productLine || scope.shingle || scope.siding) && (
                      <List.Item style={{ padding: 0, paddingBottom: 0 }}>
                        <List.Item.Meta
                          title={
                            <span>
                              Product:{" "}
                              <span className="ant-list-item-meta-description">
                                {scope.productLine
                                  ? scope.productLine
                                  : scope.shingle
                                  ? scope.shingle[0]
                                  : scope.siding[0]}
                              </span>
                            </span>
                          }
                        />
                      </List.Item>
                    )}
                    {(scope.color || scope.shingle || scope.siding) && (
                      <List.Item style={{ padding: 0, paddingBottom: 0 }}>
                        <List.Item.Meta
                          title={
                            <span>
                              Color:{" "}
                              <span className="ant-list-item-meta-description">
                                {scope.color
                                  ? scope.color
                                  : scope.shingle
                                  ? scope.shingle[0]
                                  : scope.siding[0]}
                              </span>
                            </span>
                          }
                        />
                      </List.Item>
                    )}
                  </List>
                )}
                <List bordered={false} split={false}>
                  <List.Item style={{ padding: 0, paddingBottom: 0 }}>
                    <List.Item.Meta
                      title={
                        <span>
                          Salesman:{" "}
                          <span className="ant-list-item-meta-description">
                            {formDetails.salesman.join(", ")}
                          </span>
                        </span>
                      }
                    />
                  </List.Item>
                </List>
              </div>
            );
          })}
        {props.selected &&
        props.selected.length > 0 &&
        props.selected.filter((s) => s._id === formDetails._id).length > 0 ? (
          <Button
            style={{ marginTop: 16 }}
            danger
            block
            type="primary"
            onClick={removeProject}
          >
            Remove Project
          </Button>
        ) : (
          <Button
            style={{ marginTop: 16 }}
            block
            type="primary"
            onClick={addProject}
          >
            Add Project
          </Button>
        )}
      </div>
    </FormBody>
  );
};

export default ProjectView;
