const hashtags = [
  "aerial",
  "boardwork",
  "chimney",
  "contract",
  "document",
  "extralabor",
  "image",
  "installerflag",
  "insurance",
  "materialflag",
  "materialrun",
  "preexistingdamage",
  "prejobsurvey",
  "protection",
  "sidingleak",
  "xactimate",
];

export default hashtags;
