import React from "react";
import InputNumberNoAutoComplete from "Components/Inputs/InputNumberNoAutoComplete";

const Currency = ({
  onChange,
  onBlur,
  value,
  placeholder = undefined,
  disabled = false,
  align = "left",
  onFocus,
  autoFocus = false,
  id = undefined,
  size = "normal",
}) => {
  const triggerChange = (changedValue) => {
    if (onChange) {
      onChange(changedValue);
    }
  };

  const handleChange = (e) => {
    triggerChange(e);
  };

  const handleBlur = (e) => {
    if (onBlur) {
      onBlur(e);
    }
  };

  return (
    <InputNumberNoAutoComplete
      disabled={disabled}
      onFocus={onFocus}
      value={value}
      onChange={handleChange}
      handleBlur={handleBlur}
      placeholder={placeholder}
      className={align === "right" ? "input-right" : ""}
      autoFocus={autoFocus}
      precision={2}
      numberType="currency"
      id={id}
      size={size}
    />
  );
};

export default Currency;
