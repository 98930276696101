import React, { useState } from "react";
import Modal from "antd/es/modal";
import Typography from "antd/es/typography";
import Button from "antd/es/button";
import InstallImage from "Assets/install-image.png";
const { Title, Text } = Typography;

const AddToHomeScreen = ({
  visible,
  setVisible,
  prompt,
  setPrompt,
  setCheckNotifications,
  setPrompted,
}) => {
  const [loading, setLoading] = useState(false);

  const promptUser = () => {
    prompt.prompt();
    localStorage.setItem("isPrompted", "true");
    prompt.userChoice.then(function (choiceResult) {
      setLoading(false);
      setCheckNotifications(true);
      setPrompted(true);
    });
  };

  const handleClose = () => {
    setVisible(false);
    setPrompt(null);
    setCheckNotifications(true);
    setPrompted(true);
    localStorage.setItem("isPrompted", "true");
  };

  return (
    <Modal
      centered
      visible={visible}
      onCancel={handleClose}
      closable={false}
      footer={null}
      destroyOnClose
      width={380}
      style={{ borderRadius: 4 }}
      bodyStyle={{ padding: 0, borderRadius: 4 }}
    >
      <img
        src={InstallImage}
        style={{
          // margin: "auto",
          marginBottom: 12,
          marginTop: 52,
          // display: "block",
          width: "100%",
        }}
        alt="install"
      />

      <div className="text-center" style={{ padding: 24 }}>
        <Title>INSTALL STORY</Title>

        <Text>
          Install this application on your home screen for quick and easy access
          when you're on the go.
        </Text>
      </div>
      <Button
        style={{
          marginTop: 58,
          marginLeft: 24,
          marginRight: 24,
          marginBottom: 24,
          width: "calc(100% - 48px)",
        }}
        type="primary"
        onClick={promptUser}
        block
        loading={loading}
        disabled={loading}
      >
        Add To Home Screen
      </Button>
      <Button
        style={{
          marginLeft: 24,
          marginRight: 24,
          marginBottom: 24,
          width: "calc(100% - 48px)",
        }}
        type="default"
        block
        onClick={handleClose}
      >
        Not at this Time
      </Button>
    </Modal>
  );
};

export default AddToHomeScreen;
