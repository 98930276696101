import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openForm } from "Actions/formActions";
// import Progress from "antd/es/progress";
import Card from "antd/es/card";
import Col from "antd/es/col";
import Button from "antd/es/button";
import styles from "./styles.module.scss";
import dayjs from "dayjs";
import Typography from "antd/es/typography";
import ImageFallback from "Components/images/Image";
// import CheckCircleEmptyIcon from "Icons/CheckCircleEmpty";
// import CheckCircleIcon from "Icons/CheckCircle";
import stagesObj from "Constants/stages";
import Tag from "antd/es/tag";
import api from "API";
import List from "antd/es/list";

const ActiveProject = ({ scope, updateProjects }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const goTo = (e) => {
  //   e.stopPropagation();
  //   navigate(`/dashboard/projects/view/posts/${scope._projectId}`);
  // };

  const getStatusAndGo = async (e) => {
    try {
      e.stopPropagation();
      const response = await api.get(`/get-state/${scope._projectId}`);
      navigate(`/dashboard/${response.data}/view/posts/${scope._projectId}`);
    } catch (err) {
      console.log("getStatusAndGo err", err);
    }
  };

  const onViewScopeClick = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(
        openForm("updateScope", {
          _id: scope._id,
          projectStatus: scope.projectStatus,
          customerId: scope.customerId,
          customerName: scope.customerName,
          projectAddress: scope.projectAddress,
          name: scope.name,
          amount: scope.amount,
          status: scope.status,
          statusDate: scope.statusDate,
          // progress: scope.progress,
          extendedWarranty: scope.extendedWarranty,
          premierWarranty: scope.premierWarranty,
          repair: scope.repair,
          insurance: scope.insurance,
          stage: scope.stage,
          label: scope.label,
          invoicedAmount: scope.invoicedAmount,
          firstInvoiceDate: scope.firstInvoiceDate,
          collectedAmount: scope.collectedAmount,
          expensedAmount: scope.expensedAmount,
          dashboard: true,
          callback: updateProjects,
        })
      );
    },
    [dispatch, scope, updateProjects]
  );

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <Col xs={24} sm={12} md={8}>
      <Card
        // onClick={getStatusAndGo}
        size="small"
        title={
          <span>
            <strong>{scope.customerId}</strong> - {scope.customerName}
          </span>
        }
        hoverable
        bordered={false}
        className={`z-depth-1 ${styles.scopeCard}`}
        actions={[
          <Button onClick={getStatusAndGo} type="link">
            View Project
          </Button>,
          <Button onClick={onViewScopeClick} type="link">
            Edit Scope
          </Button>,
        ]}
        cover={
          <div style={{ position: "relative" }}>
            <div className={styles.dateStamp}>
              <span className={styles.stamp}>
                {dayjs(scope.dateSold).format("MMMM D, YYYY")}
              </span>
            </div>
            <div style={{ position: "absolute", top: 8, right: -4, zIndex: 1 }}>
              {/* {scope.woCreated ? (
                <Button
                  shape="circle"
                  type="default"
                  className={styles.centerIcon}
                >
                  <CheckCircleIcon color="#00c292" />
                </Button>
              ) : (
                <Button
                  shape="circle"
                  type="default"
                  className={styles.centerIcon}
                  // onClick={openPreJobInspection}
                >
                  <CheckCircleEmptyIcon />
                </Button>
              )} */}
              {scope.stage && (
                <Tag color={stagesObj[scope.stage]}>{scope.stage}</Tag>
              )}
            </div>
            <ImageFallback
              alt="active-project"
              image={{
                src: scope.starredImageSrc || undefined,
                thumbnail: scope.starredImageSrcThumbnail || undefined,
              }}
            />
          </div>
        }
      >
        <Card.Meta
          style={{ width: "100%" }}
          title={
            <div
              className={styles.flex}
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              <strong style={{ paddingRight: 8 }}>
                {scope.label ? scope.label : scope.name}
              </strong>{" "}
            </div>
          }
          description={
            <div style={{ width: "100%" }}>
              {/* <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  paddingRight: 2,
                }}
              >
                <Progress percent={scope.progress} />
              </div> */}

              <List bordered={false} split={false}>
                <List.Item
                  style={{ padding: 0, paddingBottom: 8, width: "100%" }}
                >
                  <List.Item.Meta title="Work Order" />
                  <List.Item.Meta
                    style={{ textAlign: "right" }}
                    title={
                      scope.woCreated ? (
                        <Tag color="#00c292">Created</Tag>
                      ) : (
                        <Tag color="#ff7056">Not Created</Tag>
                      )
                    }
                  />
                </List.Item>
                <List.Item
                  style={{ padding: 0, paddingBottom: 8, width: "100%" }}
                >
                  <List.Item.Meta title="Invoiced" />
                  <List.Item.Meta
                    style={{ textAlign: "right" }}
                    title={
                      scope.invoicedAmount > 0 ? (
                        <Tag color="#00c292">Invoiced</Tag>
                      ) : (
                        <Tag color="#ff7056">Not Invoiced</Tag>
                      )
                    }
                  />
                </List.Item>
              </List>

              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                <strong>Street</strong>:{" "}
                <Typography.Link
                  onClick={stopPropagation}
                  href={`https://www.google.com/maps/place/${scope.lat},${scope.lng}`}
                  target="_blank"
                >
                  {scope.projectAddress}
                </Typography.Link>
              </div>

              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                <strong>Project Owner</strong>:{" "}
                {scope.projectManager && scope.projectManager.name}
              </div>
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              ></div>
            </div>
          }
        />
      </Card>
    </Col>
  );
};

export default ActiveProject;
