import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { closeImageViewer } from "Actions/dataActions";
import Modal from "antd/es/modal";
import styles from "./styles.module.scss";
import Image from "./Image";

const ViewImage = ({ visible }) => {
  const dispatch = useDispatch();
  const close = useCallback(() => {
    dispatch(closeImageViewer());
  }, [dispatch]);

  return (
    <Modal
      visible={visible}
      onCancel={close}
      closable={false}
      width="100%"
      height="100%"
      className={styles.imageViewer}
      destroyOnClose
      forceRender={false}
      style={{ top: 0, padding: 0 }}
      bodyStyle={{ padding: 0 }}
      footer={null}
      zIndex={6000}
    >
      <Image />
    </Modal>
  );
};

export default ViewImage;
