import React from "react";
import LineChartWithInterval from "Reports/LineChartWithInterval";

export default function SalesCountCumulative() {
  return (
    <LineChartWithInterval
      path="/reports/adminSalesCountCumulative"
      type=""
      summary={true}
      lineItems={true}
      aggregate={false}
      report="SALES COUNT CUMULATIVE"
      headers={["Salesman", "Total"]}
    />
  );
}
