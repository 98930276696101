// Form Actions
export const OPEN_FORM = "openForm";
export const CLOSE_FORM = "closeForm";
export const CLOSE_LAST_FORM = "closeLastForm";
export const UPDATE_FORM_DETAILS = "updateFormDetails";

// Table Actions
export const FETCH_TABLE = "fetchTable";
export const FETCH_TABLE_FILTER = "fetchTableFilter";
export const SET_USER_FILTER = "setUserFilter";
export const SET_TABLE_FILTER = "setTableFilter";
export const FETCHING_TABLE = "fetchingTable";
export const SET_SCROLLBAR = "setScrollbar";
export const UNSET_TABLE = "unsetTable";

// Settings Actions
export const SET_COLLAPSED = "setCollapsed";
export const SHOW_MENU = "showMenu";
export const SET_ACTIVE_ROUTE = "setActiveRoute";

// Share Actions
export const OPEN_SHARE = "openShare";
export const CLOSE_SHARE = "closeShare";

// Auth Actions
export const ADD_ERROR = "addError";
export const CLEAR_ERROR_MESSAGE = "clearErrorMessage";
export const SIGNIN = "siginin";
export const SET_NOTIFICATIONS = "setNotifications";
export const REPLACE_NOTIFICATIONS = "replaceNotifications";
export const UPDATE_MENU_ITEMS = "updateMenuItems";
export const EMAIL_NOTIFICATIONS = "emailNotifications";
export const SIGNOUT = "signout";

// Data Actions
export const SET_ALL_DATA = "setAll";
export const UPDATE_DETAILS = "updateDetails";
export const UPDATE_DETAIL = "updateDetail";
export const UPDATE_PROJECT_ADDRESS = "updateProjectAddress";
export const UPDATE_BILLING_ADDRESS = "updateBillingAddress";
export const UPDATE_SALESMEN = "updateSalesmen";
export const SET_SELECTED = "setSelected";
export const UPDATE_ACTIVITIES_AND_ALL_MEDIA = "updateActivitiesAndAllMedia";
export const UPDATE_ACTIVITIES_ONLY = "updateActivitiesOnly";
export const UPDATE_HASHTAGS = "updateHashtags";
export const OPEN_IMAGE_VIEWER = "openImageViewer";
export const CLOSE_IMAGE_VIEWER = "closeImageViewer";
export const UPDATE_ACTIVITIES_AND_SELECTED_ACTIVITY =
  "updateActivitiesAndSelectedActivity";
export const UPDATE_ACTIVITIES_ALL_MEDIA_AND_HASHTAGS =
  "updateActivitiesAllMediaAndHashtags";
export const UPDATE_INVOICES_ACTIVITIES_ALL_MEDIA_AND_HASHTAGS =
  "updateInvoicesActivitiesAllMediaAndHashtags";
export const UPDATE_COMPLETION_CERTIFICATES_ACTIVITIES_ALL_MEDIA_AND_HASHTAGS =
  "updateCompletionCertificatesActivitiesAllMediaAndHashtags";
export const UPDATE_ACTIONS_ACTIVITIES_AND_HASHTAGS =
  "updateActionsActivitiesAndHashtags";
export const UPDATE_DUMPSTERS_ACTIVITIES_AND_HASHTAGS =
  "updateDumpstersActivitiesAndHashtags";
export const UPDATE_EXPENSES_ACTIVITIES_AND_HASHTAGS =
  "updateExpensesActivitiesAndHashtags";
export const UPDATE_DOCUMENTS_ACTIVITIES_AND_HASHTAGS =
  "updateDocumentsActivitiesAndHashtags";
export const UPDATE_COLLECTIONS_ACTIVITIES_AND_HASHTAGS =
  "updateCollectionsActivitiesAndHashtags";
export const UPDATE_SCOPES_ACTIVITIES_AND_HASHTAGS =
  "updateScopesActivitiesAndHashtags";
export const UPDATE_SCOPES = "updateScopes";
export const UPDATE_SCOPES_AND_ACTIVITIES = "updateScopesAndActivities";
export const WORKORDER_CREATED = "workOrderCreated";
export const UPDATE_SELECTED_ACTIVITY = "updateSelectedActivity";
export const ADD_ACTIVITIES = "addActivities";
export const UPDATE_FILTERED_ACTIVITIES = "updateFilteredActivities";
export const FETCHING_DATA = "fetchingData";
export const UNSET_DATA = "unsetData";
export const START_SCOPE = "startScope";
export const STOP_SCOPE = "stopScope";
export const WINDOWS_RECEIVED = "windowsReceived";
// SW Actions
export const SW_INIT = "swInit";
export const SW_UPDATE = "swUpdate";
export const SW_UPDATING = "swUpdating";
