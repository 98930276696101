import React, { useState, useEffect } from "react";
import api from "API";
import AutoComplete from "antd/es/auto-complete";
import Input from "antd/es/input";
import Select from "antd/es/select";
import message from "antd/es/message";
import SearchOutlined from "@ant-design/icons/SearchOutlined";
import Build from "Icons/Build";
import Group from "Icons/Group";
import styles from "./styles.module.scss";

const { OptGroup } = Select;
const { Option } = AutoComplete;
let searchTimeout;

const SearchLeadsAndProjects = ({
  leads = true,
  projects = true,
  handleSelect,
  bordered,
  disableLastViewed = false,
}) => {
  const [results, setResults] = useState([]);

  useEffect(() => {
    return () => clearTimeout(searchTimeout);
  }, []);

  const handleSearch = (search) => {
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(async () => {
      try {
        if (search.length > 0) {
          const res = await api.post(`/globalSearch`, {
            search,
            leads,
            projects,
            disableLastViewed,
          });
          if (res.status !== 200) {
            message.error("Error searching everything");
          } else {
            setResults(res.data);
          }
        } else {
          setResults([]);
        }
      } catch (err) {
        console.log("err", err);
      }
    }, 300);
  };

  function onSelect(e, o) {
    if (o._id) {
      handleSelect({
        _id: o._id,
        customerId: o.customerid,
        projectStatus: o.projectstatus,
        projectManager: o.projectmanager,
        projectAddressStreet: o.projectaddressstreet,
        projectAddressCity: o.projectaddresscity,
        projectAddressState: o.projectaddressstate,
        projectAddressZip: o.projectaddresszip,
        projectAddressLat: o.projectaddresslat,
        projectAddressLng: o.projectaddresslng,
        billingAddressStreet: o.billingaddressstreet,
        billingAddressCity: o.billingaddresscity,
        billingAddressState: o.billingaddressstate,
        billingAddressZip: o.billingaddresszip,
        customerName: o.customername,
        companyName: o.companyname,
        phone: o.phone,
        brokedown: o.brokedown,
        projectDescription: o.projectdescription,
        starredImageSrc: o.starredimagesrc,
        starredImageSrcThumbnail: o.starredimagesrcthumbnail,
      });
      setResults([]);
    } else {
      setResults([]);
    }
  }

  function onBlur() {
    setResults([]);
  }

  function onDropdownVisibleChange(e) {
    if (!e) {
      setResults([]);
    }
  }

  const options = results.map((group, i) => (
    <OptGroup key={group.title} label={group.title}>
      {group.children.length > 0 ? (
        group.children.map((opt) => (
          <Option
            key={`${group.title}-${opt._id}`}
            _id={opt._id}
            customerid={opt.customerId}
            projectstatus={opt.projectStatus}
            projectmanager={opt.projectManager}
            type={group.title}
            value={`${opt.customerId} - ${opt.customerName}`}
            companyname={opt.companyName}
            projectaddressstreet={opt.projectAddressStreet}
            projectaddresscity={opt.projectAddressCity}
            projectaddressstate={opt.projectAddressState}
            projectaddresszip={opt.projectAddressZip}
            projectaddresslat={opt.projectAddressLat}
            projectaddresslng={opt.projectAddressLng}
            billingaddressstreet={opt.billingAddressStreet}
            billingaddresscity={opt.billingAddressCity}
            billingaddressstate={opt.billingAddressState}
            billingaddresszip={opt.billingAddressZip}
            customername={opt.customerName}
            phone={opt.phone}
            brokedown={opt.brokedown}
            projectdescription={opt.projectDescription}
            starredimagesrc={opt.starredImageSrc}
            starredimagesrcthumbnail={opt.starredImageSrcThumbnail}
          >
            <div className={styles.globalSearchDropResult}>
              <div className="gsLeft">
                {group.title === "leads" ? (
                  <Group className="gsIcon" />
                ) : (
                  <Build className="gsIcon" />
                )}
              </div>
              <div className="gsRight">
                <div className="gsTopLine">{`${opt.customerId} - ${opt.customerName}`}</div>
                <div>{opt.projectAddress}</div>
              </div>
            </div>
          </Option>
        ))
      ) : (
        <Option
          key={`no-results-${group.title}`}
          _id={undefined}
          disabled={true}
        >
          No {group.title.charAt(0).toUpperCase() + group.title.substring(1)}{" "}
          Match Your Search
        </Option>
      )}
    </OptGroup>
  ));

  return (
    <AutoComplete
      id="searchLeadsAndProjects"
      className={`${styles.globalSearchDropdown} ${
        bordered ? styles.bordered : ""
      }`}
      autoFocus
      dropdownClassName={styles.globalSearchDropdown}
      dataSource={options}
      onSearch={handleSearch}
      onSelect={onSelect}
      onDropdownVisibleChange={onDropdownVisibleChange}
      onBlur={onBlur}
      defaultActiveFirstOption={false}
      getPopupContainer={(trigger) =>
        trigger.parentNode.parentNode.parentNode.parentNode.parentNode
          .parentNode
      }
      autoComplete="dont-use-autocomplete"
    >
      <div>
        <label htmlFor="" style={{ display: "none" }}>
          hidden label to mislead chrome autocomplete
        </label>
        <Input
          allowClear
          size="large"
          placeholder={
            leads && projects
              ? "Search for Project or Lead"
              : leads
              ? "Search for Lead"
              : "Search for Project"
          }
          prefix={<SearchOutlined />}
          autoComplete="dont-use-autocomplete"
        />
      </div>
    </AutoComplete>
  );
};

export default SearchLeadsAndProjects;
