import React, { useCallback, memo } from "react";
import { useDispatch } from "react-redux";
import { openForm } from "Actions/formActions";
import { updateSelectedActivity, openImageViewer } from "Actions/dataActions";
import { useNavigate, useLocation } from "react-router-dom";
import Row from "antd/es/row";
import Col from "antd/es/col";
import isPdf from "Utils/isPdf";
import isVideo from "Utils/isVideo";
import hidePdfControls from "Utils/hidePdfControls";
import styles from "./styles.module.scss";
import pdf from "Assets/pdf.svg";
import YouTube from "Icons/YouTube";
import LazyLoad from "Components/LazyLoad";
import ImageFallback from "Components/images/Image";

const Image2to4orMore = ({
  images,
  setImages,
  activity,
  _global,
  scrollContainer,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const src1 = images[1].src;
  let src2 = undefined;
  let src3 = undefined;
  if (images.length > 2) {
    src2 = images[2].src;
  }
  if (images.length > 3) {
    src3 = images[3].src;
  }

  const openImage1 = useCallback(() => {
    images[1].setImages = setImages;
    dispatch(openImageViewer(images[1]));
  }, [setImages, dispatch, images]);

  const openDrawer1 = useCallback(() => {
    if (!images[1].legacy) {
      dispatch(updateSelectedActivity(activity));
      if (
        !location.pathname.includes("projects") &&
        !location.pathname.includes("leads")
      ) {
        dispatch(openForm("images", { index: 1 }));
      } else {
        navigate(`${location.pathname}/images/${activity._id}/${1}`);
      }
    } else {
      navigate(`${location.pathname}/legacy-files/${1}`);
    }
  }, [images, dispatch, activity, location, navigate]);

  const openImage2 = useCallback(() => {
    images[2].setImages = setImages;
    dispatch(openImageViewer(images[2]));
  }, [setImages, dispatch, images]);

  const openDrawer2 = useCallback(() => {
    if (!images[2].legacy) {
      dispatch(updateSelectedActivity(activity));
      if (
        !location.pathname.includes("projects") &&
        !location.pathname.includes("leads")
      ) {
        dispatch(openForm("images", { index: 2 }));
      } else {
        navigate(`${location.pathname}/images/${activity._id}/${2}`);
      }
    } else {
      navigate(`${location.pathname}/legacy-files/${2}`);
    }
  }, [images, dispatch, activity, location, navigate]);

  const openImage3 = useCallback(() => {
    images[3].setImages = setImages;
    dispatch(openImageViewer(images[3]));
  }, [setImages, dispatch, images]);

  const openDrawer3 = useCallback(() => {
    if (!images[3].legacy) {
      dispatch(updateSelectedActivity(activity));
      if (
        !location.pathname.includes("projects") &&
        !location.pathname.includes("leads")
      ) {
        dispatch(openForm("images", { index: 3 }));
      } else {
        navigate(`${location.pathname}/images/${activity._id}/${3}`);
      }
    } else {
      navigate(`${location.pathname}/legacy-files/${3}`);
    }
  }, [images, dispatch, activity, location, navigate]);

  const renderCountOverlay = () => {
    return [
      <div key={`${images[3]._id}-cover`} className={styles.cover}></div>,
      <div
        key={`${images[3]._id}-count`}
        className={styles.coverText}
        style={{ fontSize: "200%" }}
      >
        <p>+{images.length - 4}</p>
      </div>,
    ];
  };

  return (
    <Row style={{ height: "50%", width: "100%" }}>
      <Col
        xs={8}
        className={`${styles.border} ${_global ? styles.dark : ""} ${
          styles.height100
        } ${styles.background}`}
      >
        <div
          className={styles.clickOverlay}
          onClick={setImages ? openImage1 : openDrawer1}
        />
        {isPdf(src1) ? (
          <LazyLoad offsetVertical={600} height={"100%"} root={scrollContainer}>
            <object data={hidePdfControls(src1)} className={styles.pdf}>
              <div>
                <img src={pdf} alt="pdf-icon" />
                <div>{images[1].name}</div>
              </div>
            </object>
          </LazyLoad>
        ) : isVideo(src1) ? (
          <LazyLoad offsetVertical={300} height={"100%"} root={scrollContainer}>
            <div className={styles.video}>
              <YouTube color="#f1f1f1" size={64} />
              <div>{images[1].name}</div>
            </div>
          </LazyLoad>
        ) : (
          <LazyLoad offsetVertical={300} height={"100%"} root={scrollContainer}>
            <ImageFallback image={images[1]} alt="activity-drawer" />
          </LazyLoad>
        )}
      </Col>
      <Col
        xs={8}
        className={`${styles.border} ${_global ? styles.dark : ""} ${
          styles.height100
        } ${styles.background}`}
      >
        <div
          className={styles.clickOverlay}
          onClick={setImages ? openImage2 : openDrawer2}
        />

        {isPdf(src2) ? (
          <LazyLoad offsetVertical={600} height={"100%"} root={scrollContainer}>
            <object data={hidePdfControls(src2)} className={styles.pdf}>
              <div>
                <img src={pdf} alt="pdf-icon" />
                <div>{images[2].name}</div>
              </div>
            </object>
          </LazyLoad>
        ) : isVideo(src2) ? (
          <LazyLoad offsetVertical={300} height={"100%"} root={scrollContainer}>
            <div className={styles.video}>
              <YouTube color="#f1f1f1" size={64} />
              <div>{images[2].name}</div>
            </div>
          </LazyLoad>
        ) : (
          <LazyLoad offsetVertical={300} height={"100%"} root={scrollContainer}>
            <ImageFallback image={images[2]} alt="activity-drawer" />
          </LazyLoad>
        )}
      </Col>
      <Col
        xs={8}
        className={`${styles.border} ${_global ? styles.dark : ""} ${
          styles.height100
        } ${styles.background}`}
      >
        <div
          className={styles.clickOverlay}
          style={{ zIndex: 1 }}
          onClick={setImages ? openImage3 : openDrawer3}
        />

        {images.length > 4 ? renderCountOverlay() : null}

        {isPdf(src3) ? (
          <LazyLoad offsetVertical={600} height={"100%"} root={scrollContainer}>
            <object data={hidePdfControls(src3)} className={styles.pdf}>
              <div>
                <img src={pdf} alt="pdf-icon" />
                <div>{images[3].name}</div>
              </div>
            </object>
          </LazyLoad>
        ) : isVideo(src3) ? (
          <LazyLoad offsetVertical={300} height={"100%"} root={scrollContainer}>
            <div className={styles.video}>
              <YouTube color="#f1f1f1" size={64} />
              <div>{images[3].name}</div>
            </div>
          </LazyLoad>
        ) : (
          <LazyLoad offsetVertical={300} height={"100%"} root={scrollContainer}>
            <ImageFallback image={images[3]} alt="activity-drawer" />
          </LazyLoad>
        )}
      </Col>
    </Row>
  );
};

export default memo(Image2to4orMore);
