// import nomatch from "Assets/avatars/default.png";
// import jbeckwith from "Assets/avatars/jbeckwith.png";
// import jkelly from "Assets/avatars/jkelly.png";
// import josh from "Assets/avatars/josh.png";
// import khowell from "Assets/avatars/khowell.png";
// import mhorton from "Assets/avatars/mhorton.png";
// import njanes from "Assets/avatars/njanes.png";
// import paul from "Assets/avatars/paul.png";
// import rick from "Assets/avatars/rick.png";
// import ryan from "Assets/avatars/ryan.png";
// import chowell from "Assets/avatars/chowell.png";
// import postbot from "Assets/avatars/postbot.png";
// import wmills from "Assets/avatars/wmills.png";
// import kbalcom from "Assets/avatars/kbalcom.png";
// import dgater from "Assets/avatars/dgater.png";
// import asmoes from "Assets/avatars/asmoes.png";
// import ddevries from "Assets/avatars/ddevries.png";
// import ksmith from "Assets/avatars/ksmith.png";

// const avatars = {
//   jbeckwith,
//   jkelly,
//   josh,
//   khowell,
//   mhorton,
//   njanes,
//   paul,
//   rick,
//   ryan,
//   chowell,
//   wmills,
//   kbalcom,
//   dgater,
//   asmoes,
//   ddevries,
//   ksmith,
//   postbot,
// };

export default function generateAvatar(avatar, createdBy) {
  if (avatar) {
    avatar = avatar.toLowerCase();
  } else if (!avatar) {
    let _createdBy = createdBy.split(" ");
    if (
      _createdBy[0] === "Rick" ||
      _createdBy[0] === "Josh" ||
      _createdBy[0] === "Ryan" ||
      _createdBy[0] === "Paul"
    ) {
      avatar = _createdBy[0];
    } else if (_createdBy.length === 1) {
      avatar = undefined;
    } else {
      avatar = _createdBy[0][0] + _createdBy[1];
    }
  }
  // return avatars[avatar] ? avatars[avatar] : nomatch;
  return `https://premierroofingandexteriors.s3.us-east-2.amazonaws.com/avatars/${avatar}.png`;
}
