import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { openImageViewer } from "Actions/dataActions";
import generateAvatar from "Utils/generateAvatar";
import styles from "./styles.module.scss";
import CommentImage from "Components/Activities/CommentImage";
import Comment from "antd/es/comment";
import Avatar from "antd/es/avatar";
import Camera from "Icons/Camera";
import ActionRow from "Components/Activities/ActionRow";
import Update from "Components/Activities/Update";
import ImageFallback from "Components/images/Image";
import nomatch from "Assets/avatars/default.png";

const ActivityComment = ({
  comment,
  _activityId,
  _user,
  _projectId,
  _customerId,
  _projectStatus,
  replyTo,
  _global,
  dashboard,
  fetchPosts,
}) => {
  const dispatch = useDispatch();
  const _id = useSelector((state) => state.dataState.details._id);

  const openImage = useCallback(
    (image) => {
      image._projectId = _id;
      image.viewOnly = true;
      dispatch(openImageViewer(image));
    },
    [_id, dispatch]
  );

  const handleOpenImage = () => {
    openImage({ ...comment.replyTo, _activityId });
  };

  if (!replyTo) {
    return (
      <div
        className={`${styles.comment} ${_global ? styles._global : ""}`}
        key={comment._id}
      >
        <Comment
          avatar={
            <Avatar
              src={generateAvatar(comment.avatar, comment.createdBy)}
              icon={<img alt="avatar" src={nomatch} />}
            />
          }
          content={
            <span
              className={styles.content}
              dangerouslySetInnerHTML={{ __html: comment.content }}
            />
          }
        />
        {comment.images.length > 0 && (
          <div className={styles.imageRow}>
            {comment.images.map((image) => (
              <CommentImage key={image._id} image={image} />
            ))}
          </div>
        )}
        <ActionRow
          comment={comment}
          _projectId={_projectId}
          _customerId={_customerId}
          _projectStatus={_projectStatus}
          _user={_user}
          _activityId={_activityId}
          _global={_global}
          dashboard={dashboard}
          fetchPosts={fetchPosts}
        />

        {comment.replies.map((reply, index) => (
          <div className={styles.reply} key={reply._id}>
            {reply.update && !_global ? (
              <Update
                comment={comment}
                key={comment._id}
                _activityId={_activityId}
                _user={_user}
                _projectId={_projectId}
                _customerId={_customerId}
                _projectStatus={_projectStatus}
                reply
                dashboard={dashboard}
              />
            ) : (
              <Comment
                avatar={
                  <Avatar
                    src={generateAvatar(reply.avatar, reply.createdBy)}
                    icon={<img alt="avatar" src={nomatch} />}
                  />
                }
                content={
                  <span
                    className={styles.content}
                    dangerouslySetInnerHTML={{ __html: reply.content }}
                  />
                }
              />
            )}

            {reply.images.length > 0 && (
              <div className={styles.imageRow}>
                {reply.images.map((image) => (
                  <CommentImage key={image._id} image={image} />
                ))}
              </div>
            )}
            <ActionRow
              comment={comment}
              _projectId={_projectId}
              _customerId={_customerId}
              _projectStatus={_projectStatus}
              _user={_user}
              _activityId={_activityId}
              _reply={reply}
              index={index}
              _global={_global}
              dashboard={dashboard}
              fetchPosts={fetchPosts}
            />
          </div>
        ))}
      </div>
    );
  } else {
    return (
      <div
        className={`${styles.comment} ${styles.replyToComment} ${
          _global ? styles._global : ""
        }`}
        key={comment._id}
      >
        <Comment
          author={
            <>
              <div className={styles.replyTo} onClick={handleOpenImage}>
                <div>
                  <div>{comment.replyTo.publisher}</div>
                  <div className={styles.replyToIcon}>
                    <Camera size={14} /> Photo
                  </div>
                </div>
                <div className={styles.replyToImage}>
                  <ImageFallback
                    alt="comment-image"
                    image={{
                      src: comment.replyTo.src || undefined,
                      thumbnail: comment.replyTo.thumbnail || undefined,
                    }}
                  />
                </div>
              </div>
            </>
          }
          avatar={
            <Avatar
              src={generateAvatar(comment.avatar, comment.createdBy)}
              icon={<img alt="avatar" src={nomatch} />}
            />
          }
          content={
            <span
              className={styles.content}
              dangerouslySetInnerHTML={{ __html: comment.content }}
            />
          }
        />
        {comment.images.length > 0 && (
          <div className={styles.imageRow}>
            {comment.images.map((image) => (
              <CommentImage key={image._id} image={image} />
            ))}
          </div>
        )}
        <ActionRow
          comment={comment}
          _projectId={_projectId}
          _customerId={_customerId}
          _projectStatus={_projectStatus}
          _user={_user}
          _activityId={_activityId}
          _global={_global}
          dashboard={dashboard}
          fetchPosts={fetchPosts}
        />

        {comment.replies.map((reply, index) => (
          <div className={styles.reply} key={reply._id}>
            <Comment
              avatar={
                <Avatar
                  src={generateAvatar(reply.avatar, reply.createdBy)}
                  icon={<img alt="avatar" src={nomatch} />}
                />
              }
              content={
                <span
                  className={styles.content}
                  dangerouslySetInnerHTML={{ __html: reply.content }}
                />
              }
            />
            {reply.images.length > 0 && (
              <div className={styles.imageRow}>
                {reply.images.map((image) => (
                  <CommentImage key={image._id} image={image} />
                ))}
              </div>
            )}
            <ActionRow
              comment={comment}
              _projectId={_projectId}
              _customerId={_customerId}
              _projectStatus={_projectStatus}
              _user={_user}
              _activityId={_activityId}
              _reply={reply}
              index={index}
              _global={_global}
              dashboard={dashboard}
              fetchPosts={fetchPosts}
            />
          </div>
        ))}
      </div>
    );
  }
};

export default ActivityComment;
