import React, { useCallback, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { openForm } from "Actions/formActions";
import { useNavigate } from "react-router-dom";
import FormBody from "Components/FormBody";
import api from "API";
import Button from "antd/es/button";
import Card from "antd/es/card";
import styles from "./styles.module.scss";
import Row from "antd/es/row";
import Col from "antd/es/col";
import ViewProject from "Screens/ViewProject";
import ViewContainer from "Components/ViewContainer";
import useViewShow from "Hooks/useViewShow";
import Divider from "antd/es/divider";
import dayjs from "dayjs";
import useSubContractor from "Hooks/useSubContractor";
import Sortable from "sortablejs";
import Star from "Icons/Star";
import Typography from "antd/es/typography";
import LazyLoad from "Components/LazyLoad";
import Tag from "antd/es/tag";
// import Dropdown from "antd/es/dropdown";
// import Menu from "antd/es/menu";
import Modal from "antd/es/modal";
import ImageFallback from "Components/images/Image";
import ArrowLeft from "Icons/ArrowLeft";

import Chat from "Icons/Chat";

const ReadyForSchedule = () => {
  const dispatch = useDispatch();
  const subs = useSubContractor(true);
  const showProject = useViewShow(3, "projects", 4, "view");
  const [scopes, setScopes] = useState([]);
  const [scrollContainer, setScrollContainer] = useState(null);
  const [filter, setFilter] = useState("Showing ALL");

  useEffect(() => {
    fetchCards();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const el = document.getElementById("scheduleSortable");
    if (Sortable.get(el)) Sortable.get(el).destroy();
    Sortable.create(el, {
      animation: 150,
      direction: "grid",
      ghostClass: "placeholder",
      delay: 300,
    });
  }, [scopes]);

  const fetchCards = useCallback(async () => {
    try {
      const res = await api.get("/ready-for-schedule/fetchList");
      setScopes(res.data);
    } catch (err) {
      console.log("err", err);
    }
  }, []);

  const fetchMyCards = useCallback(async () => {
    try {
      const res = await api.get("/ready-for-schedule/fetchMyList");
      setScopes(res.data);
    } catch (err) {
      console.log("err", err);
    }
  }, []);

  const fetchScopes = async () => {
    try {
      if (filter === "Showing ALL") {
        fetchMyCards();
        setFilter("Showing MINE");
      } else {
        fetchCards();
        setFilter("Showing ALL");
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const openEmail = useCallback(
    async (scope) => {
      try {
        let fileName = `${scope.name}-Work-Order-${scope.workOrder.version}`;
        fileName = fileName.replace(" - ", "-");
        fileName = fileName.replace(" ", "-");
        const res = await api.get(`/get-media/${scope._projectId}`);
        const allMedia = res.data._media.concat(res.data._documents);
        const workOrder = allMedia.find((m) => m.name === fileName);

        let emails = [
          scope.workOrder && scope.workOrder.projectManagerEmail,
          "install@gopremier.net",
        ];

        if (scope.assignToSub && scope.assignToSub !== null) {
          emails.push(scope.assignToSub.email);
        }

        dispatch(
          openForm("workRequestRoof", {
            ...scope,
            emails,
            content: [workOrder],
            _scopeId: scope._id,
            _projectId: scope._projectId,
            allMedia,
            customerId: scope.customerId,
            customerName: scope.workOrder.customerName,
            projectAddress: scope.workOrder.projectAddress,
            callback: () =>
              setScopes((_scopes) =>
                _scopes.filter((_scope) => _scope._id !== scope._id)
              ),
          })
        );
      } catch (err) {
        console.log("err", err);
      }
    },
    [dispatch, setScopes]
  );

  return (
    <div className="form" style={{ height: "100%" }}>
      <FormBody>
        <div
          ref={setScrollContainer}
          className="content-inner p24"
          style={{ height: "100%" }}
        >
          <Row gutter={16}>
            <Col xs={24}>
              <Button
                type="primary"
                style={{ marginBottom: 12 }}
                block
                onClick={fetchScopes}
              >
                {filter}
              </Button>
            </Col>
          </Row>

          <div id="scheduleSortable" className={styles.sortableContainer}>
            {scopes.map((scope, i) => (
              <Scope
                scope={scope}
                openEmail={openEmail}
                subs={subs}
                index={i}
                setScopes={setScopes}
                key={scope._id}
                scrollContainer={scrollContainer}
                fetchCards={fetchCards}
              />
            ))}
          </div>
        </div>
      </FormBody>

      <ViewContainer
        type="drawer"
        visible={showProject}
        form={ViewProject}
        placement="right"
        width={400}
        closable={false}
        mask={false}
      />
    </div>
  );
};

const Scope = ({
  scope,
  openEmail,
  subs,
  setScopes,
  scrollContainer,
  fetchCards,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showDescriptions, setShowDescriptions] = useState(false);

  const handleScopeClick = () => {
    openEmail(scope);
  };

  const goTo = (e) => {
    e.stopPropagation();
    navigate(
      `/workflow/ready-for-schedule/projects/view/posts/${scope._projectId}`
    );
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const openDescriptions = (e) => {
    e.stopPropagation();
    setShowDescriptions(true);
  };

  // const updateSubToAssign = async ({ key, domEvent }) => {
  //   try {
  //     domEvent.stopPropagation();
  //     let values = {};
  //     if (key !== "unassign") {
  //       values = {
  //         assignToSub: subs[key],
  //         _id: scope._id,
  //       };
  //     } else {
  //       values = {
  //         assignToSub: undefined,
  //         _id: scope._id,
  //       };
  //     }
  //     const res = await api.post("/ready-for-schedule/assign-to-sub", values);
  //     setScopes(res.data);
  //   } catch (err) {
  //     console.log("err", err);
  //   }
  // };

  const openNote = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(
        openForm("officeReviewNote", {
          _scopeId: scope._id,
          note: scope.officeReviewNote,
          customerId: scope.customerId,
          callback: fetchCards,
        })
      );
    },
    [dispatch, scope, fetchCards]
  );

  // const menu = (
  //   <Menu onClick={updateSubToAssign}>
  //     {scope.assignToSub ? (
  //       <Menu.Item
  //         key="unassign"
  //         style={{
  //           fontWeight: "bold",
  //           borderBottom: "1px solid #f0f0f0",
  //           textAlign: "center",
  //         }}
  //       >
  //         Unassign Sub
  //       </Menu.Item>
  //     ) : null}
  //     {Object.entries(subs).map(([key, value]) => {
  //       return (
  //         <Menu.Item
  //           key={key}
  //         >{`${value.companyName} - ${value.contactName}`}</Menu.Item>
  //       );
  //     })}
  //   </Menu>
  // );

  const confirmSendToDumpingStage = (e) => {
    e.stopPropagation();
    Modal.confirm({
      zIndex: 1001,
      centered: true,
      okButtonProps: {
        danger: true,
      },
      okType: "primary",
      okText: "Confirm",
      content:
        "Are you sure you want to send this back to the ready for dumpster stage?",
      onOk() {
        return new Promise((resolve, reject) => {
          handleSendToDumping(() => resolve(true));
        }).catch((err) => {
          console.log("err", err);
        });
      },
      onCancel() {},
    });
  };

  const handleSendToDumping = async (cb) => {
    try {
      const res = await api.post("/ready-for-schedule/move-to-dumpster", {
        _id: scope._id,
        _projectId: scope._projectId,
        name: scope.name,
        customerId: scope.customerId,
        projectAddress: scope.workOrder.projectAddress,
      });

      setScopes(res.data);

      cb();
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <div>
      <Card
        onClick={handleScopeClick}
        size="small"
        title={
          <span>
            <strong>{scope.customerId}</strong> - {scope.workOrder.customerName}
          </span>
        }
        extra={
          <Button type="link" onClick={goTo} size="small">
            Go To
          </Button>
        }
        hoverable
        bordered={false}
        className={`z-depth-1 ${styles.scopeCard} ${
          scope.workOrder.schedule === "Urgent" ? styles.urgent : ""
        }`}
        cover={
          <LazyLoad offsetVertical={300} height={150} root={scrollContainer}>
            <Button
              style={{ marginRight: 4, position: "absolute", top: 49, left: 8 }}
              // size="small"
              shape="circle"
              type={
                scope.officeReviewNote && scope.officeReviewNote.length > 0
                  ? "primary"
                  : "default"
              }
              onClick={openNote}
            >
              <Chat size={20} />
            </Button>
            <Button
              style={{ marginLeft: 4, position: "absolute", top: 49, right: 8 }}
              // size="small"
              danger
              shape="circle"
              type="primary"
              onClick={confirmSendToDumpingStage}
            >
              <ArrowLeft size={20} color="#ffffff" />
            </Button>
            <ImageFallback
              alt="ready-for-schedule"
              image={{
                src: scope.starredImageSrc || undefined,
                thumbnail: scope.starredImageSrcThumbnail || undefined,
              }}
            />
          </LazyLoad>
        }
      >
        <Card.Meta
          title={
            <div
              className={styles.flex}
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              <strong style={{ paddingRight: 8 }}>{scope.name}</strong>{" "}
              <small>
                {scope.workOrder &&
                scope.workOrder.propertyType === "New Construction"
                  ? "(NC)"
                  : scope.workOrder &&
                    scope.workOrder.propertyType === "Commercial"
                  ? "(C)"
                  : "(R)"}
              </small>{" "}
              {scope.workOrder &&
                scope.workOrder.propertyType === "New Construction" && (
                  <>
                    <Divider type="vertical" />
                    <small>{scope.driedIn ? "Dried In" : "Not Dried In"}</small>
                  </>
                )}
              {scope.workOrder &&
                scope.workOrder.propertyType !== "New Construction" && (
                  <>
                    <Divider type="vertical" /> <Star color="gold" />{" "}
                    {/* TODO: difficultyRating overhaul */}
                    {scope.workOrder && scope.workOrder.difficultyRating}
                  </>
                )}
            </div>
          }
          description={
            <>
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                <div>
                  {scope.workOrder &&
                    scope.workOrder.scopes.map((s, i) => (
                      <Tag
                        key={s.initials + i}
                        className={styles.scopeBadge}
                        color={s.color}
                      >
                        {s.initials}
                      </Tag>
                    ))}
                </div>
                {/* TODO: what is needed for Siding tags */}
                {scope.workOrder.pitches.join(
                  ", "
                )} <Divider type="vertical" /> {scope.workOrder.squares} sq.{" "}
                <Divider type="vertical" />{" "}
                {scope.workOrder.roofType
                  ? scope.workOrder.roofType.join(", ")
                  : scope.workOrder.installationMethod}
              </div>

              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                <strong>Street</strong>:{" "}
                <Typography.Link
                  onClick={stopPropagation}
                  href={`https://www.google.com/maps/place/${scope.workOrder.projectAddressLat},${scope.workOrder.projectAddressLng}`}
                  target="_blank"
                >
                  {scope.workOrder.projectAddressStreet}
                </Typography.Link>
              </div>
              <div>
                <strong>City</strong>:{" "}
                <Typography.Link
                  onClick={stopPropagation}
                  href={`https://www.google.com/maps/place/${scope.workOrder.projectAddressLat},${scope.workOrder.projectAddressLng}`}
                  target="_blank"
                >
                  {scope.workOrder.projectAddressCity}
                </Typography.Link>
              </div>

              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                <strong>Project Owner</strong>:{" "}
                {scope.workOrder.projectManagerName}
              </div>

              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                <strong>Project Manager</strong>:{" "}
                {scope.installProjectManager
                  ? scope.installProjectManager.name
                  : ""}
              </div>

              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                <strong>
                  {scope.dumpsterDelivery
                    ? "Dumpster Delivery"
                    : scope.materialDeliverDate
                    ? "Material Delivery"
                    : "Dumpster Skipped"}
                </strong>
                :{" "}
                {scope.dumpsterDelivery
                  ? dayjs(scope.dumpsterDelivery).format("MMM D, YY")
                  : scope.materialDeliverDate
                  ? dayjs(scope.materialDeliverDate).format("MMM D, YY")
                  : dayjs(scope.dumpsterOrdered).format("MMM D, YY")}
              </div>

              <Row gutter={8} style={{ marginTop: 4 }}>
                <Col xs={24}>
                  <Button
                    type="primary"
                    block
                    size="small"
                    style={{ paddingLeft: 0 }}
                    onClick={openDescriptions}
                  >
                    Descriptions and Notes
                  </Button>
                </Col>
              </Row>

              {/* <Divider style={{ marginTop: 8, marginBottom: 8 }} /> */}

              {/* <Dropdown overlay={menu} trigger={["click"]}>
                <Button
                  onClick={stopPropagation}
                  type={scope.assignToSub ? "primary" : "default"}
                  block
                  style={{ paddingLeft: 0 }}
                >
                  {scope.assignToSub
                    ? scope.assignToSub.companyName
                    : "Assign Sub"}
                </Button>
              </Dropdown> */}
            </>
          }
        />
      </Card>

      <DescriptionModal
        visible={showDescriptions}
        setVisible={setShowDescriptions}
        title="Descriptions and Notes"
        project={scope.workOrder.projectDescription}
        scope={scope.workOrder.scopeDescription}
        dumpster={scope.workOrder.dumpsterNotes}
        admin={scope.workOrder.adminNotes}
      />
    </div>
  );
};

const DescriptionModal = ({
  title,
  visible,
  setVisible,
  project,
  scope,
  dumpster,
  admin,
}) => {
  const onCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={onCancel}
      centered
      footer={null}
    >
      {project && project.length > 0 && (
        <div>
          <Typography.Title style={{ margin: 0 }} level={4}>
            Project Description
          </Typography.Title>
          <Typography.Paragraph style={{ whiteSpace: "pre-line" }}>
            {project}
          </Typography.Paragraph>
        </div>
      )}
      {scope && scope.length > 0 && (
        <div style={{ marginTop: 16 }}>
          <Divider />
          <Typography.Title style={{ margin: 0 }} level={4}>
            Scope Description
          </Typography.Title>
          <Typography.Paragraph style={{ whiteSpace: "pre-line" }}>
            {scope}
          </Typography.Paragraph>
        </div>
      )}
      {dumpster && dumpster.length > 0 && (
        <div style={{ marginTop: 16 }}>
          <Divider />
          <Typography.Title style={{ margin: 0 }} level={4}>
            Dumpster Notes
          </Typography.Title>
          <Typography.Paragraph style={{ whiteSpace: "pre-line" }}>
            {dumpster}
          </Typography.Paragraph>
        </div>
      )}
      {admin && admin.length > 0 && (
        <div style={{ marginTop: 16 }}>
          <Divider />
          <Typography.Title style={{ margin: 0 }} level={4}>
            Admin Notes
          </Typography.Title>
          <Typography.Paragraph style={{ whiteSpace: "pre-line" }}>
            {admin}
          </Typography.Paragraph>
        </div>
      )}
    </Modal>
  );
};

export default ReadyForSchedule;
