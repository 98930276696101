import React, { useState } from "react";
import { useSelector } from "react-redux";
import Form from "antd/es/form";
import Button from "antd/es/button";
import message from "antd/es/message";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import api from "API";
import CancelButton from "Components/CancelButton";
import DatePicker from "Components/Inputs/DatePicker";
import AssignedTo from "Components/Inputs/Salesmen";
import useIsMobile from "Hooks/useIsMobile";
import Input from "antd/es/input";
import MarkUpHelper from "Components/MarkUpHelper";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";
import Phone from "Components/Inputs/Phone";
import Address from "Components/Inputs/Address";
import Map from "Components/Details/fields/Map";
import useProjectManagers from "Hooks/useProjectManagers";
import ImageUploader from "Components/Inputs/ImageUploader";

const messageKey = "messageKey";

const InstallTodoNonPo = ({
  unsavedChanges,
  setUnsavedChanges,
  handleClose,
  type,
}) => {
  const projectManagers = useProjectManagers();
  const formDetails = useSelector(
    (state) => state.formState.installTodoNonPo.formDetails
  );
  const mobile = useIsMobile();
  const [loading, setLoading] = useState(false);
  const [_fileList, _setFileList] = useState([]);
  const [uploading, setUploading] = useState({});
  const [_lat, _setLat] = useState(42.963795);
  const [_lng, _setLng] = useState(-85.670006);
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      setLoading(true);
      // message.loading("Creating to-do...", 0);
      message.loading({
        content: "Creating due date...",
        duration: 0,
        key: messageKey,
      });
      let array = [];
      if (values.assignedTo) {
        for (const a of values.assignedTo) {
          array.push(projectManagers[a]);
        }
      }

      values.image = _fileList.length > 0 ? _fileList[0] : undefined;
      values.assignedTo = array;
      values.projectAddress = `${values.projectAddressStreet}, ${values.projectAddressCity}, ${values.projectAddressState} ${values.projectAddressZip}`;
      const res = await api.post("/install/todos/no-po/create", values);

      if (formDetails.callback) {
        formDetails.callback(res.data);
      }

      // message.success("To-do created");
      message.success({
        content: "To-do created",
        duration: 1.5,
        key: messageKey,
      });
      setUnsavedChanges(false);
      setLoading(false);
      handleClose(true);
    } catch (err) {
      setLoading(false);
      // message.error("Error creating to-do");
      message.error({
        content: "Error creating to-do",
        duration: 1.5,
        key: messageKey,
      });
    }
  };

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error creating to-do");
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  const cancel = () => {
    handleClose();
  };

  const updateCords = ({ lat, lng }) => {
    _setLat(lat);
    _setLng(lng);
  };

  const setProjectAddress = ({ city, state, postcode, lat, lng }) => {
    form.setFieldsValue({
      projectAddressCity: city,
      projectAddressState: state,
      projectAddressZip: postcode,
      projectAddressLat: lat,
      projectAddressLng: lng,
    });

    updateCords({
      lat,
      lng,
    });
  };

  const addAllAsAssignee = () => {
    const assignees = [];
    Object.keys(projectManagers).map((key, index) => {
      assignees.push(key);
      return key;
    });
    form.setFieldsValue({
      assignedTo: assignees,
    });
  };

  return (
    <Form
      form={form}
      className="form"
      autoComplete="off"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
    >
      <RemoveAutocomplete />

      <FormBody
        className="content-inner"
        style={{
          paddingLeft: 24,
          paddingTop: 24,
          paddingRight: 24,
          paddingBottom: 8,
          maxHeight: type === "modal" ? "calc(100vh - 161px)" : "unset",
        }}
      >
        <Form.Item
          name="customerName"
          label="Customer Name"
          rules={[{ required: true, message: "Customer Name is required" }]}
        >
          <InputNoAutoComplete id="customerName" />
        </Form.Item>

        <Form.Item
          name="phone"
          label="Phone"
          rules={[{ required: true, message: "Phone is required" }]}
        >
          <Phone id="phone" />
        </Form.Item>

        <Form.Item
          label="Street"
          name="projectAddressStreet"
          rules={[{ required: true, message: "Project street is required" }]}
        >
          <Address
            autoComplete="projectStreet"
            setAddress={setProjectAddress}
            id="projectAddressStreet"
          />
        </Form.Item>

        <Form.Item
          label="City"
          name="projectAddressCity"
          rules={[
            {
              required: true,
              message: "Project city is required",
              type: "string",
            },
          ]}
        >
          <InputNoAutoComplete id="projectAddressCity" />
        </Form.Item>

        <Form.Item
          label="State"
          name="projectAddressState"
          rules={[
            {
              required: true,
              message: "Project state is required",
              type: "string",
            },
            {
              len: 2,
              message: "State must be abbreviated",
            },
          ]}
        >
          <InputNoAutoComplete id="projectAddressState" />
        </Form.Item>

        <Form.Item
          label="Zip"
          name="projectAddressZip"
          rules={[
            {
              required: true,
              message: "Project zip is required",
              type: "string",
            },
          ]}
        >
          <InputNoAutoComplete type="phone" id="projectAddressZip" />
        </Form.Item>

        <Map
          type="project"
          form={form}
          lat={_lat}
          lng={_lng}
          updateCords={updateCords}
        />

        <Form.Item style={{ display: "none" }} name="projectAddressLat">
          <Input autoComplete="projectLat" type="text" />
        </Form.Item>

        <Form.Item style={{ display: "none" }} name="projectAddressLng">
          <Input autoComplete="projectLng" type="text" />
        </Form.Item>

        <Form.Item
          name="todo"
          label={<MarkUpHelper title="To-do" />}
          rules={[{ required: true, message: "To-do is required" }]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>

        <h4 className="headerWithButton">
          <span>Assignee</span>
          <Button
            type="primary"
            size="small"
            className="green"
            onClick={addAllAsAssignee}
          >
            ADD ALL
          </Button>{" "}
        </h4>
        <Form.Item name="assignedTo">
          <AssignedTo sales={projectManagers} />
        </Form.Item>

        <Form.Item name="dueDate" label="Due Date">
          <DatePicker
            format="MMMM DD, YYYY"
            inputReadOnly={true}
            getPopupContainer={(trigger) =>
              !mobile
                ? trigger.parentNode.parentNode.parentNode.parentNode
                : document.body
            }
            getCalendarContainer={(trigger) =>
              !mobile
                ? trigger.parentNode.parentNode.parentNode.parentNode
                : document.body
            }
          />
        </Form.Item>

        <ImageUploader
          _fileList={_fileList}
          _setFileList={_setFileList}
          uploading={uploading}
          setUploading={setUploading}
          destination="install-to-dos"
          label="Image Upload"
          multiple={false}
        />
      </FormBody>

      <FormControls className="sticky-footer">
        <CancelButton handleClick={cancel} />
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={loading || !unsavedChanges || uploading}
        >
          Submit
        </Button>
      </FormControls>
    </Form>
  );
};

export default InstallTodoNonPo;
