import React, { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import Grid from "antd/es/grid";
import {
  unsetTable,
  fetchTableFilters,
  setScrollbar,
} from "Actions/tableActions";
import { useDispatch, useSelector } from "react-redux";
import { openForm } from "Actions/formActions";
import Table from "Components/Table";
import TableRow from "Components/Table/TableItems/LeadItem";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as Vlist } from "react-window";
import ViewContainer from "Components/ViewContainer";
import ViewLead from "Screens/ViewLead";
import usePrevious from "Hooks/usePrevious";
import useViewShow from "Hooks/useViewShow";
import useIsMobile from "Hooks/useIsMobile";
import isEmpty from "Utils/isEmpty";

const { useBreakpoint } = Grid;

const headers = [
  {
    _name: "customerId",
    name: "ID",
    width: "10%",
  },
  {
    _name: "customerName",
    name: "CUSTOMER",
    width: "15%",
  },
  {
    _name: "projectStatus",
    name: "STATUS",
    width: "14%",
  },
  {
    _name: "address",
    name: "ADDRESS",
    width: "26%",
  },
  {
    _name: "scopes",
    name: "SCOPES",
    width: "20%",
    type: "scope",
  },
  {
    _name: "created",
    name: "CREATED",
    width: "15%",
    align: "right",
    type: "date",
  },
];

const filters = [
  {
    title: "Created",
    type: "datepicker",
    fields: [
      {
        name: "created-start",
        placeholder: "Created After",
      },
      {
        name: "created-end",
        placeholder: "Created Before",
      },
    ],
  },
  {
    title: "Status",
    type: "multi-select",
    fields: [
      {
        name: "projectStatus",
        placeholder: "Select an Option(s)",
        values: [
          "Lead",
          "Lead Needs Remeet",
          "Lead Lost",
          "Lead Insurance WIP",
          "Estimate",
          "Estimate DOA",
          "Estimate WIP",
          "Estimate Next Year",
          "Estimate Sold",
        ],
      },
    ],
  },
  {
    title: "Salesman",
    type: "salesmen",
    fields: [
      {
        name: "salesman",
        placeholder: "Select an Option(s)",
        values: [],
      },
    ],
  },
  {
    title: "Fishing",
    type: "multi-select",
    fields: [
      {
        name: "fishing",
        placeholder: "Select an Option(s)",
        values: ["True", "False"],
      },
    ],
  },
];

const Leads = () => {
  const screens = useBreakpoint();
  const mobile = useIsMobile();
  const location = useLocation();
  const prevScreens = usePrevious(screens);
  const showLead = useViewShow(2, "view");
  const collapsed = useSelector((state) => state.settingsState.collapsed);
  const dispatch = useDispatch();
  const rows = useSelector((state) => state.tableState.rows);
  const fetching = useSelector((state) => state.tableState.fetching);
  const search = useSelector((state) => state.tableState.search);
  const activeFilter = useSelector((state) => state.tableState.activeFilter);
  const _user = useSelector((state) => state.authState);
  const [itemSize, setItemSize] = useState(135);

  useEffect(() => {
    initTable();

    return () => dispatch(unsetTable());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTimeout(() => {
      dispatch(setScrollbar());
    });
  }, [rows, dispatch]);

  const initTable = useCallback(() => {
    let _activeFilter;
    if (location.search) {
      _activeFilter = location.search.substring(1);
    }

    dispatch(
      fetchTableFilters({
        table: "leads",
        col: "customerId",
        order: 1,
        search,
        activeFilter: _activeFilter,
        user: _user,
      })
    );
  }, [dispatch, search, location.search, _user]);

  useEffect(() => {
    if (prevScreens && screens) {
      if (prevScreens.md && !screens.md) {
        setItemSize(135);
      } else if (!prevScreens.md && screens.md) {
        setItemSize(47);
      }
    }
  }, [screens, prevScreens]);

  const Row = ({ index, style }) => {
    const item = rows[index];
    if (!mobile)
      return (
        <TableRow style={style} key={item._id} {...item} headers={headers} />
      );
    return <TableRow style={style} key={item._id} {...item} mobile />;
  };

  const addFunc = useCallback(() => {
    dispatch(openForm("createLead"));
  }, [dispatch]);

  return (
    <>
      <Table
        loading={fetching}
        filter={
          search ||
          (activeFilter && activeFilter.filter && !isEmpty(activeFilter.filter))
            ? true
            : false
        }
        mobile={mobile}
        sider={screens.lg}
        collapsed={collapsed}
      >
        <Table.Header filters={filters} addFunc={addFunc} />
        <Table.Filter
          hide={
            search ||
            (activeFilter &&
              activeFilter.filter &&
              !isEmpty(activeFilter.filter))
              ? false
              : true
          }
        />

        {!mobile && <Table.ColumnHeaders headers={headers} />}
        <Table.Body loading={fetching}>
          {rows.length > 0 && (
            <AutoSizer>
              {({ height, width }) => (
                <Vlist
                  height={height}
                  itemCount={rows.length}
                  itemSize={itemSize}
                  width={width}
                >
                  {Row}
                </Vlist>
              )}
            </AutoSizer>
          )}
        </Table.Body>
      </Table>

      <ViewContainer
        type="drawer"
        visible={showLead}
        form={ViewLead}
        placement="right"
        width={!screens.sm ? "100%" : 400}
        closable={false}
        mask={false}
      />
    </>
  );
};

export default Leads;
