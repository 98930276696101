import React from "react";
import BarChartWithDrilldown from "Reports/BarChartWithDrilldown";

export default function RoofingProducts() {
  return (
    <BarChartWithDrilldown
      path="/reports/roofingProducts"
      type="count"
      summary={true}
      dateRange={true}
      units="SQUARES"
      report="ROOFING PRODUCTS"
      headers={["Manufacturer", "Squares"]}
      subHeaders={["Product", "Squares"]}
    />
  );
}
