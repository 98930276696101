import React from "react";
import Rate from "antd/es/rate";
import Clipboard from "react-clipboard.js";
import message from "antd/es/message";

const OnlineReview = ({ value }) => {
  function copied() {
    message.success("Link copied to clipboard");
  }

  return (
    <>
      <h4 style={{ marginBottom: 3 }}>Online Review</h4>
      <div style={{ marginBottom: 12 }}>
        <span>
          {value ? (
            <Rate defaultValue={value} disabled />
          ) : (
            <Clipboard
              data-clipboard-text="https://g.page/r/CfwpSr1mfzaKEAg/review"
              component="a"
              onSuccess={copied}
            >
              Copy Review Link
            </Clipboard>
          )}
        </span>
      </div>
    </>
  );
};

export default OnlineReview;
