import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Form from "antd/es/form";
import Button from "antd/es/button";
import message from "antd/es/message";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import api from "API";
import CancelButton from "Components/CancelButton";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";
import Sortable from "sortablejs";
import styles from "./styles.module.scss";
import Col from "antd/es/col";
import Row from "antd/es/row";
import DragVertical from "Icons/DragVertical";
import Divider from "antd/es/divider";

const messageKey = "messageKey";

const LeadTimes = ({
  unsavedChanges,
  setUnsavedChanges,
  handleClose,
  type,
}) => {
  const formDetails = useSelector(
    (state) => state.formState.leadTimes.formDetails
  );
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState(
    formDetails.categories1 && formDetails.categories1.map((c) => c.title)
  );
  const [form] = Form.useForm();

  useEffect(() => {
    const el1 = document.getElementById(`sortable1`);
    Sortable.create(el1, {
      group: "shared-categories",
      animation: 150,
      direction: "vertical",
      ghostClass: "placeholder",
      handle: ".handle",
      draggable: ".draggable",
      onAdd: (e) => {
        if (e.target.childNodes.length > 5) {
          const refrenceNode = e.from.childNodes[e.oldIndex];
          e.from.insertBefore(e.item, refrenceNode);
          message.error("Only 5 categories are visible at a time");
        } else {
          const childNodes = e.target.childNodes;
          let _categories = [];
          for (const node of childNodes) {
            _categories.push(node.dataset.title);
          }
          setCategories(_categories);
        }
      },
      onRemove: (e) => {
        setCategories((_categories) => {
          return _categories.filter((c) => c !== e.item.dataset.title);
        });
      },
      onUpdate: (e) => {
        const childNodes = e.target.childNodes;
        let _categories = [];
        for (const node of childNodes) {
          _categories.push(node.dataset.title);
        }
        setCategories(_categories);
      },
    });

    if (formDetails.categories2) {
      const el2 = document.getElementById(`sortable2`);
      Sortable.create(el2, {
        group: "shared-categories",
        animation: 150,
        direction: "vertical",
        ghostClass: "placeholder",
        handle: ".handle",
        draggable: ".draggable",
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onFinish = async (values) => {
    try {
      setLoading(true);

      // message.loading("Setting lead times...", 0);
      message.loading({
        content: "Setting lead times...",
        duration: 0,
        key: messageKey,
      });

      const _categories = [];
      for (const c of categories) {
        _categories.push({
          title: c,
          description: "",
        });
      }

      for (const vc1 of values.categories1) {
        let _thisObj = _categories.filter((c) => c.title === vc1.title)[0];
        if (_thisObj) {
          _thisObj.description = vc1.description;
        }
      }

      if (values.categories2) {
        for (const vc2 of values.categories2) {
          let _thisObj = _categories.filter((c) => c.title === vc2.title)[0];
          if (_thisObj) {
            _thisObj.description = vc2.description;
          }
        }
      } else {
        values.categories2 = [];
      }

      await api.post("/ready-for-material/set-lead-times", _categories);

      setUnsavedChanges(false);

      // message.success("Lead times set");
      message.success({
        content: "Lead times set",
        duration: 1.5,
        key: messageKey,
      });

      setLoading(false);
      handleClose(true);
    } catch (err) {
      setLoading(false);
      console.log("err", err);
      // message.error("Error setting lead times");
      message.error({
        content: "Error setting lead times",
        duration: 1.5,
        key: messageKey,
      });
    }
  };

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error setting lead times");
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  const cancel = () => {
    handleClose();
  };

  return (
    <Form
      form={form}
      className="form"
      autoComplete="off"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      initialValues={{
        categories1: formDetails.categories1,
        categories2: formDetails.categories2,
      }}
    >
      <RemoveAutocomplete />

      <FormBody
        className="content-inner"
        style={{
          paddingTop: 24,
          paddingRight: 0,
          paddingLeft: 0,
          maxHeight: type === "modal" ? "calc(100vh - 161px)" : "unset",
        }}
      >
        <div id="sortable1">
          <Form.List name="categories1">
            {(fields) => {
              return (
                <>
                  {fields.map((field, i) => (
                    <div
                      key={`${field.key}-category`}
                      data-title={
                        formDetails &&
                        formDetails.categories1 &&
                        formDetails.categories1[i] &&
                        formDetails.categories1[i].title
                      }
                      className={`draggable ${styles.dragContainer}`}
                    >
                      <Row>
                        <Col xs={3}>
                          <div className={`handle ${styles.dragHandle}`}>
                            <DragVertical size={32} />
                          </div>
                        </Col>
                        <Col xs={21}>
                          <Form.Item
                            {...field}
                            name={[field.name, "description"]}
                            fieldKey={[field.fieldKey, "description"]}
                            key={`description${field.fieldKey}`}
                            label={
                              formDetails &&
                              formDetails.categories1 &&
                              formDetails.categories1[i] &&
                              formDetails.categories1[i].title
                            }
                            rules={[
                              {
                                required: categories.includes(
                                  formDetails &&
                                    formDetails.categories1 &&
                                    formDetails.categories1[i] &&
                                    formDetails.categories1[i].title
                                ),
                                message: "Description is required",
                              },
                            ]}
                          >
                            <InputNoAutoComplete
                              id={[field.name, "description"]}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  ))}
                </>
              );
            }}
          </Form.List>
        </div>

        {formDetails.categories2 && (
          <>
            <Divider className=".filter">Break Point</Divider>
            <div id="sortable2">
              <Form.List name="categories2">
                {(fields) => {
                  return (
                    <>
                      {fields.map((field, i) => (
                        <div
                          key={`${field.key}-category`}
                          data-title={
                            formDetails &&
                            formDetails.categories2 &&
                            formDetails.categories2[i] &&
                            formDetails.categories2[i].title
                          }
                          className={`draggable ${styles.dragContainer}`}
                        >
                          <Row>
                            <Col xs={3}>
                              <div className={`handle ${styles.dragHandle}`}>
                                <DragVertical size={32} />
                              </div>
                            </Col>
                            <Col xs={21}>
                              <Form.Item
                                {...field}
                                name={[field.name, "description"]}
                                fieldKey={[field.fieldKey, "description"]}
                                key={`description${field.fieldKey}`}
                                label={
                                  formDetails &&
                                  formDetails.categories2 &&
                                  formDetails.categories2[i] &&
                                  formDetails.categories2[i].title
                                }
                                rules={[
                                  {
                                    required: categories.includes(
                                      formDetails &&
                                        formDetails.categories2 &&
                                        formDetails.categories2[i] &&
                                        formDetails.categories2[i].title
                                    ),
                                    message: "Description is required",
                                  },
                                ]}
                              >
                                <InputNoAutoComplete
                                  id={[field.name, "description"]}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      ))}
                    </>
                  );
                }}
              </Form.List>
            </div>
          </>
        )}
      </FormBody>

      <FormControls className="sticky-footer">
        <CancelButton handleClick={cancel} />
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={loading || !unsavedChanges}
        >
          Submit
        </Button>
      </FormControls>
    </Form>
  );
};

export default LeadTimes;
