import React, { useEffect, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import Select from "Components/Inputs/Select";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";
import Form from "antd/es/form";
import Cascader from "antd/es/cascader";
import api from "API";
import Button from "antd/es/button";
import Delete from "Icons/Delete";
import styles from "./styles.module.scss";
import Divider from "antd/es/divider";

const ExistingRoof = () => {
  const [layers, setLayers] = useState([]);
  const [yesNo] = useState(["Yes", "No"]);
  const [decking, setDecking] = useState([]);
  const [gutters, setGutters] = useState("No");
  const [lowSlope, setLowSlope] = useState("No");

  useEffect(() => {
    getOptions();
  }, []);

  const getOptions = async () => {
    try {
      const res = await api.post("/dropdowns/get", {
        selects: ["Layers", "Decking"],
      });
      unstable_batchedUpdates(() => {
        setDecking(res.data.Decking.options);
        setLayers(res.data.Layers.options);
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleGuttersSelect = (value) => {
    setGutters(value);
  };

  const handleLowSlopSelect = (value) => {
    setLowSlope(value);
  };

  return (
    <div style={{ padding: 24 }}>
      <Form.Item
        name="layers"
        label="Existing Roof Layers"
        rules={[
          { required: true, message: "Existing Roof Layers is required" },
        ]}
      >
        <Select mode="multiple" options={layers} id="layers" />
      </Form.Item>

      <Form.Item
        name="gutters"
        label="Gutters"
        rules={[{ required: true, message: "Gutters is required" }]}
      >
        <Select options={yesNo} id="gutters" onChange={handleGuttersSelect} />
      </Form.Item>

      {gutters === "Yes" && (
        <Form.Item
          name="gutterCovers"
          label="Gutter Covers"
          rules={[{ required: true, message: "Gutter Covers is required" }]}
        >
          <InputNoAutoComplete id="gutterCovers" />
        </Form.Item>
      )}

      <Divider />

      <Form.List name="decking">
        {(fields, { add, remove }) => {
          const handleAdd = () => {
            add();
          };

          const onRemove = (option) => {
            remove(option);
          };
          return (
            <>
              <div>
                {fields.map((field, i) => {
                  const handleRemove = () => {
                    onRemove(field.name);
                  };

                  return (
                    <div key={`${field.key}-decking`}>
                      <div className={styles.label} style={{ width: "100%" }}>
                        <label className="ant-form-item-required" title="">
                          <div>
                            {`Decking ${i + 1}`}{" "}
                            <Button
                              tabIndex="-1"
                              type="text"
                              shape="circle"
                              danger
                              className={styles.deleteButton}
                              onClick={handleRemove}
                            >
                              <Delete />
                            </Button>
                          </div>
                        </label>
                      </div>
                      <Form.Item
                        {...field}
                        name={[field.name, "value"]}
                        fieldKey={[field.fieldKey, "value"]}
                        rules={[
                          {
                            required: true,
                            message: "Decking is required",
                          },
                        ]}
                      >
                        <Cascader
                          showSearch
                          options={decking}
                          placeholder=""
                          getPopupContainer={(trigger) =>
                            trigger.parentNode.parentNode.parentNode.parentNode
                              .parentNode
                          }
                        />
                      </Form.Item>
                    </div>
                  );
                })}
              </div>
              <Button
                type="primary"
                className="green"
                block
                onClick={handleAdd}
              >
                Add Decking
              </Button>
            </>
          );
        }}
      </Form.List>

      <Divider />

      <Form.Item
        name="iwStuck"
        label="IW Stuck"
        rules={[{ required: true, message: "IW Stuck is required" }]}
      >
        <Select options={yesNo} id="iwStuck" />
      </Form.Item>

      <Form.Item
        name="dipsAndSags"
        label="Dips & Sags"
        rules={[{ required: true, message: "Dips and Sags is required" }]}
      >
        <InputNoAutoComplete id="dipsAndSags" />
      </Form.Item>

      <Divider />

      <Form.List name="chimney">
        {(fields, { add, remove }) => {
          const handleAdd = () => {
            add();
          };

          const onRemove = (option) => {
            remove(option);
          };
          return (
            <>
              <div>
                {fields.map((field, i) => {
                  const handleRemove = () => {
                    onRemove(field.name);
                  };

                  return (
                    <div key={`${field.key}-chimney`}>
                      <div className={styles.label} style={{ width: "100%" }}>
                        <label className="ant-form-item-required" title="">
                          <div>
                            {`Chimney ${i + 1}`}{" "}
                            <Button
                              tabIndex="-1"
                              type="text"
                              shape="circle"
                              danger
                              className={styles.deleteButton}
                              onClick={handleRemove}
                            >
                              <Delete />
                            </Button>
                          </div>
                        </label>
                      </div>
                      <Form.Item
                        {...field}
                        name={[field.name, "material"]}
                        fieldKey={[field.fieldKey, "material"]}
                        key={[field.fieldKey, "material"]}
                        label="Material"
                        rules={[
                          {
                            required: true,
                            message: "Material is required",
                          },
                        ]}
                      >
                        <InputNoAutoComplete id={[field.name, "material"]} />
                      </Form.Item>

                      <Form.Item
                        {...field}
                        name={[field.name, "over30"]}
                        fieldKey={[field.fieldKey, "over30"]}
                        key={[field.fieldKey, "over30"]}
                        label='Over 30"'
                        rules={[
                          {
                            required: true,
                            message: 'Over 30" is required',
                          },
                        ]}
                      >
                        <Select options={yesNo} />
                      </Form.Item>

                      <Form.Item
                        {...field}
                        name={[field.name, "buildSadle"]}
                        fieldKey={[field.fieldKey, "buildSadle"]}
                        key={[field.fieldKey, "buildSadle"]}
                        label="Build Sadle"
                        rules={[
                          {
                            required: true,
                            message: "Build Sadle is required",
                          },
                        ]}
                      >
                        <Select options={yesNo} />
                      </Form.Item>
                    </div>
                  );
                })}
              </div>
              <Button
                type="primary"
                className="green"
                block
                onClick={handleAdd}
              >
                Add Chimney
              </Button>
            </>
          );
        }}
      </Form.List>

      <Divider />

      <Form.Item
        name="lowSlope"
        label="Low Slope"
        rules={[{ required: true, message: "Low Slope is required" }]}
      >
        <Select options={yesNo} id="lowSlope" onChange={handleLowSlopSelect} />
      </Form.Item>

      {lowSlope === "Yes" && (
        <>
          <Form.Item name="lowSlopeDecking" label="Low Slope Decking">
            <InputNoAutoComplete id="lowSlopeDecking" />
          </Form.Item>

          <Form.Item
            name="shingleTieIn"
            label="Shingle Tie In"
            rules={[{ required: true, message: "Shingle Tie In is required" }]}
          >
            <Select options={yesNo} id="shingleTieIn" />
          </Form.Item>

          <Form.Item
            name="taper"
            label="Taper"
            rules={[{ required: true, message: "Taper is required" }]}
          >
            <Select options={yesNo} id="taper" />
          </Form.Item>
        </>
      )}
    </div>
  );
};

export default ExistingRoof;
