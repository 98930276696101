import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openForm } from "Actions/formActions";
import { closeShare } from "Actions/shareActions";
import Drawer from "antd/es/drawer";
import styles from "./styles.module.scss";
import downloadFile from "Utils/downloadFile";
import api from "API";
import Loader from "Components/Loader";

const Share = () => {
  const dispatch = useDispatch();
  const shareVisible = useSelector((state) => state.shareState.shareVisible);
  const content = useSelector((state) => state.shareState.content);
  const callback = useSelector((state) => state.shareState.callback);
  const [loading, setLoading] = useState(false);

  async function saveImages() {
    try {
      setLoading(true);
      const zip = await api.post("/activity/download-files", content);
      downloadFile(zip.data, "files", "zip");
      setLoading(false);
    } catch (err) {
      console.log("err", err);
    }
  }

  const closeShareMenu = useCallback(() => {
    callback();
    dispatch(closeShare());
  }, [dispatch, callback]);

  const openEmail = useCallback(() => {
    dispatch(openForm("email", { content, callback: closeShareMenu }));
  }, [dispatch, content, closeShareMenu]);

  return (
    <Drawer
      zIndex={3000}
      closable={false}
      className={styles.moreOptions}
      placement="bottom"
      visible={shareVisible}
      onClose={closeShareMenu}
      title={null}
      height={182}
      bodyStyle={{ padding: 24, background: "transparent", overflow: "hidden" }}
    >
      {loading && (
        <div
          style={{
            position: "fixed",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            zIndex: 99999,
            background: "rgba(0,0,0,0.23)",
          }}
        >
          <Loader display={loading ? "flex" : "none"} minHeight="100%" />
        </div>
      )}
      <div onClick={saveImages} className={`${styles.action} `}>
        Download
      </div>
      <div className={styles.action} onClick={openEmail}>
        Email
      </div>
      <div className={styles.cancel} onClick={closeShareMenu}>
        Cancel
      </div>
    </Drawer>
  );
};

export default Share;
