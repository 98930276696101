import React, { memo } from "react";
import Input from "antd/es/input";
import Form from "antd/es/form";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Typography from "antd/es/typography";
import Pitches from "Components/Inputs/Pitches";
import Divider from "antd/es/divider";
import Button from "antd/es/button";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";
import Modal from "antd/es/modal";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";

const OtherDetails = ({ showLaborButton, laborOnly, setLaborOnly }) => {
  const handleAddMaterial = () => {
    setLaborOnly(false);
  };

  const confirmRemoveMaterial = () => {
    Modal.confirm({
      zIndex: 4002,
      icon: <QuestionCircleOutlined />,
      centered: true,
      cancelText: "Cancel",
      okText: "Delete",
      okButtonProps: { danger: true },
      content: (
        <div>
          Are you sure you want to remove the material section? All material
          progress will be lost.
        </div>
      ),
      onOk() {
        return new Promise((resolve, reject) => {
          setLaborOnly(true);
          resolve(true);
        }).catch((err) => console.log("err: ", err));
      },
      onCancel() {},
    });
  };

  return (
    <>
      <Typography.Title level={4}>Project Details</Typography.Title>
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="pitches"
            label="Pitches"
            rules={[{ required: true, message: "Pitches is required" }]}
          >
            <Pitches />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="stories"
            label="Stories"
            rules={[{ required: true, message: "Stories is required" }]}
          >
            <InputNoAutoComplete id="stories" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="projectDescription"
            label="Project Description of Work"
            rules={[
              {
                required: true,
                message: "Project Description of Work is required",
              },
            ]}
          >
            <Input.TextArea autoComplete="dont-use-autocomplete" rows={4} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="scopeDescription"
            label="Scope Description of Work"
            rules={[
              {
                required: true,
                message: "Scope Description of Work is required",
              },
            ]}
          >
            <Input.TextArea autoComplete="dont-use-autocomplete" rows={4} />
          </Form.Item>
        </Col>
      </Row>
      <Divider />

      {showLaborButton && (
        <Button
          type={!laborOnly ? "danger" : "primary"}
          block
          style={{ marginBottom: 24 }}
          onClick={!laborOnly ? confirmRemoveMaterial : handleAddMaterial}
        >
          {!laborOnly ? "Remove Material" : "Add Material"}
        </Button>
      )}
    </>
  );
};

export default memo(OtherDetails);
