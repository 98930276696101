import React from "react";
import Card from "antd/es/card";
import List from "antd/es/list";
import Switch from "antd/es/switch";
import * as serviceWorker from "../../../serviceWorkerRegistration";
import styles from "./styles.module.scss";

const NotificationSwitch = ({
  setNotificationVisible,
  setNotificationsOn,
  notificationsOn,
}) => {
  const handleClick = (e) => {
    if (e) {
      setNotificationVisible(true);
    } else {
      serviceWorker.unsubscribe(() => {
        setNotificationsOn(false);
      });
    }
  };

  return (
    <Card
      className="z-depth-1"
      bordered={false}
      style={{ borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}
    >
      <List bordered={false} className={styles.noMarginTitle}>
        <List.Item>
          <List.Item.Meta title="Push Notifications" />
          <List.Item.Meta
            description={
              <Switch
                checked={notificationsOn}
                // onChange={handleChange}
                onClick={handleClick}
                checkedChildren="ON"
                unCheckedChildren="OFF"
              />
            }
            style={{ textAlign: "right" }}
          />
        </List.Item>
      </List>
    </Card>
  );
};

export default NotificationSwitch;
