import React, { useEffect, useState, useCallback } from "react";
import Grid from "antd/es/grid";
import {
  fetchTableFilters,
  unsetTable,
  setScrollbar,
} from "Actions/tableActions";
import { useDispatch, useSelector } from "react-redux";
import { openForm } from "Actions/formActions";
import Table from "Components/Table";
import TableRow from "Components/Table/TableItems/ProjectItem";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as Vlist } from "react-window";
import ViewContainer from "Components/ViewContainer";
import ViewProject from "Screens/ViewProject";
import usePrevious from "Hooks/usePrevious";
import useViewShow from "Hooks/useViewShow";
import useIsMobile from "Hooks/useIsMobile";
import isEmpty from "Utils/isEmpty";

const { useBreakpoint } = Grid;

const headers = [
  {
    _name: "customerId",
    name: "ID",
    width: "10%",
  },
  {
    _name: "customerName",
    name: "CUSTOMER",
    width: "15%",
  },
  {
    _name: "projectStatus",
    name: "STATUS",
    width: "10%",
  },
  {
    _name: "address",
    name: "ADDRESS",
    width: "30%",
  },
  {
    _name: "scopes",
    name: "SCOPES",
    width: "20%",
    type: "scope",
  },
  {
    _name: "contractDate",
    name: "DATE SOLD",
    width: "15%",
    align: "right",
    type: "date",
  },
];

const filters = [
  {
    title: "Created",
    type: "datepicker",
    fields: [
      {
        name: "created-start",
        placeholder: "Created After",
      },
      {
        name: "created-end",
        placeholder: "Created Before",
      },
    ],
  },
  {
    title: "Date Sold",
    type: "datepicker",
    fields: [
      {
        name: "contractDate-start",
        placeholder: "Sold After",
      },
      {
        name: "contractDate-end",
        placeholder: "Sold Before",
      },
    ],
  },
  {
    title: "Broke Down Date",
    type: "datepicker",
    fields: [
      {
        name: "brokedownDate-start",
        placeholder: "Broke Down After",
      },
      {
        name: "brokedownDate-end",
        placeholder: "Broke Down Before",
      },
    ],
  },
  {
    title: "Status",
    type: "multi-select",
    fields: [
      {
        name: "projectStatus",
        placeholder: "Select an Option(s)",
        values: ["Cancelled", "Closed Out", "On Hold", "Sold"],
      },
    ],
  },
  {
    title: "Permit Status",
    type: "multi-select",
    fields: [
      {
        name: "permitStatus",
        placeholder: "Select an Option(s)",
        values: ["Undetermined", "Needed", "Not Needed", "Pulled"],
      },
    ],
  },
  {
    title: "Property Type",
    type: "multi-select",
    fields: [
      {
        name: "propertyType",
        placeholder: "Select an Option(s)",
        values: ["Commercial", "New Construction", "Residential"],
      },
    ],
  },
  {
    title: "Salesman",
    type: "salesmen",
    fields: [
      {
        name: "salesman",
        placeholder: "Select an Option(s)",
        values: [],
      },
    ],
  },
  {
    title: "Install Project Manager",
    type: "installProjectManager",
    fields: [
      {
        name: "installProjectManager",
        placeholder: "Select an Option(s)",
        values: [],
      },
    ],
  },
];

const Projects = () => {
  const rows = useSelector((state) => state.tableState.rows);
  const fetching = useSelector((state) => state.tableState.fetching);
  const search = useSelector((state) => state.tableState.search);
  const activeFilter = useSelector((state) => state.tableState.activeFilter);
  const screens = useBreakpoint();
  const mobile = useIsMobile();
  const prevScreens = usePrevious(screens);
  const showProject = useViewShow(2, "view");
  const collapsed = useSelector((state) => state.settingsState.collapsed);
  const dispatch = useDispatch();
  const [itemSize, setItemSize] = useState(135);

  useEffect(() => {
    initTable();

    return () => dispatch(unsetTable());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTimeout(() => {
      dispatch(setScrollbar());
    });
  }, [rows, dispatch]);

  const initTable = useCallback(() => {
    dispatch(
      fetchTableFilters({
        table: "projects",
        col: "customerId",
        order: 1,
        search,
      })
    );
  }, [dispatch, search]);

  useEffect(() => {
    if (prevScreens && screens) {
      if (prevScreens.md && !screens.md) {
        setItemSize(135);
      } else if (!prevScreens.md && screens.md) {
        setItemSize(47);
      }
    }
  }, [screens, prevScreens]);

  const Row = ({ index, style }) => {
    const item = rows[index];
    if (!mobile)
      return (
        <TableRow style={style} key={item._id} {...item} headers={headers} />
      );
    return <TableRow style={style} key={item._id} {...item} mobile />;
  };

  const addFunc = useCallback(() => {
    dispatch(openForm("createProject"));
  }, [dispatch]);

  return (
    <>
      <Table
        loading={fetching}
        filter={
          search ||
          (activeFilter && activeFilter.filter && !isEmpty(activeFilter.filter))
            ? true
            : false
        }
        mobile={mobile}
        sider={screens.lg}
        collapsed={collapsed}
      >
        <Table.Header filters={filters} addFunc={addFunc} />

        <Table.Filter
          hide={
            search ||
            (activeFilter &&
              activeFilter.filter &&
              !isEmpty(activeFilter.filter))
              ? false
              : true
          }
        />

        {!mobile && <Table.ColumnHeaders headers={headers} />}
        <Table.Body loading={fetching}>
          {rows.length > 0 && (
            <AutoSizer>
              {({ height, width }) => (
                <Vlist
                  height={height}
                  itemCount={rows.length}
                  itemSize={itemSize}
                  width={width}
                >
                  {Row}
                </Vlist>
              )}
            </AutoSizer>
          )}
        </Table.Body>
      </Table>

      <ViewContainer
        type="drawer"
        visible={showProject}
        form={ViewProject}
        placement="right"
        width={!screens.sm ? "100%" : 400}
        closable={false}
        mask={false}
      />
    </>
  );
};

export default Projects;
