import React, { useCallback } from "react";
import { updateDetails } from "Actions/dataActions";
import { useDispatch, useSelector } from "react-redux";
import { openForm } from "Actions/formActions";
import { fetchTable } from "Actions/tableActions";
import Modal from "antd/es/modal";
import Button from "antd/es/button";
import message from "antd/es/message";
import Grid from "antd/es/grid";
import FormContainer from "Forms/FormContainer";
import Estimate from "Forms/Estimate";
import LeadLost from "Forms/LeadLost";
import LeadSold from "Forms/LeadSold";
import styles from "./styles.module.scss";
import api from "API";
import ChangeStatus from "Forms/ChangeStatus";

const { useBreakpoint } = Grid;

const LeadProjectActions = () => {
  const dispatch = useDispatch();
  const details = useSelector((state) => state.dataState.details);
  const scopes = useSelector((state) => state.dataState.scopes);
  const collections = useSelector((state) => state.dataState.collections);
  const actions = useSelector((state) => state.dataState.actions);
  const viewableActivities = useSelector(
    (state) => state.dataState.viewableActivities
  );
  const table = useSelector((state) => state.tableState.table);
  const col = useSelector((state) => state.tableState.col);
  const order = useSelector((state) => state.tableState.order);
  const search = useSelector((state) => state.tableState.search);
  const activeFilter = useSelector((state) => state.tableState.activeFilter);

  const estimate = useSelector((state) => state.formState.estimate.open);
  const leadLost = useSelector((state) => state.formState.leadLost.open);
  const leadSold = useSelector((state) => state.formState.leadSold.open);
  const changeStatus = useSelector(
    (state) => state.formState.changeStatus.open
  );
  const screens = useBreakpoint();

  const handleReopen = useCallback(
    async (cb) => {
      try {
        const res = await api.post("/leads/reopen", {
          _id: details._id,
          estimateValue: details.estimateValue,
        });

        dispatch(
          updateDetails({
            viewableActivities,
            details: res.data._details,
            activities: res.data._activities,
          })
        );

        dispatch(
          fetchTable({
            table,
            col,
            order,
            search,
            activeFilter,
            fetching: false,
          })
        );

        cb();
      } catch (err) {
        message.error("Error reopening Lead");
      }
    },
    [
      details,
      viewableActivities,
      dispatch,
      table,
      col,
      order,
      search,
      activeFilter,
    ]
  );

  function confirmReopenLead() {
    Modal.confirm({
      zIndex: 1001,
      centered: true,
      content: "Are you sure you want to reopen this Lead?",
      onOk() {
        return new Promise((resolve, reject) => {
          handleReopen(() => resolve(true));
        }).catch((err) => {
          console.log("err", err);
        });
      },
      onCancel() {},
    });
  }

  const handleRemoveHold = useCallback(
    async (cb) => {
      try {
        const res = await api.post("/projects/removeHold", {
          _id: details._id,
        });

        dispatch(
          updateDetails({
            viewableActivities,
            details: res.data._details,
            activities: res.data._activities,
          })
        );

        dispatch(
          fetchTable({
            table,
            col,
            order,
            search,
            activeFilter,
            fetching: false,
          })
        );

        cb();
      } catch (err) {
        message.error("Error removing hold");
      }
    },
    [
      details,
      viewableActivities,
      dispatch,
      table,
      col,
      order,
      search,
      activeFilter,
    ]
  );

  function confirmRemoveHold() {
    Modal.confirm({
      zIndex: 1001,
      centered: true,
      content: "Are you sure you want to remove this project from on hold?",
      onOk() {
        return new Promise((resolve, reject) => {
          handleRemoveHold(() => resolve(true));
        }).catch((err) => {
          console.log("err", err);
        });
      },
      onCancel() {},
    });
  }

  const handleRemoveCancellation = useCallback(
    async (cb) => {
      try {
        const res = await api.post("/projects/removeCancellation", {
          _id: details._id,
        });

        dispatch(
          updateDetails({
            viewableActivities,
            details: res.data._details,
            activities: res.data._activities,
          })
        );

        dispatch(
          fetchTable({
            table,
            col,
            order,
            search,
            activeFilter,
            fetching: false,
          })
        );

        cb();
      } catch (err) {
        message.error("Error removing cancellation");
      }
    },
    [
      details,
      viewableActivities,
      dispatch,
      table,
      col,
      order,
      search,
      activeFilter,
    ]
  );

  function confirmRemoveCancellation() {
    Modal.confirm({
      zIndex: 1001,
      centered: true,
      content: "Are you sure you want to remove this projects cancellation?",
      onOk() {
        return new Promise((resolve, reject) => {
          handleRemoveCancellation(() => resolve(true));
        }).catch((err) => {
          console.log("err", err);
        });
      },
      onCancel() {},
    });
  }

  const openLeadSold = useCallback(() => {
    dispatch(openForm("leadSold", details));
  }, [dispatch, details]);

  const openEstimate = useCallback(() => {
    dispatch(
      openForm("estimate", { _id: details._id, scopes: details.scopes })
    );
  }, [dispatch, details._id, details.scopes]);

  const openChangeStatus = useCallback(() => {
    dispatch(
      openForm("changeStatus", {
        _id: details._id,
        projectStatus: details.projectStatus,
      })
    );
  }, [dispatch, details._id, details.projectStatus]);

  const openLeadLost = useCallback(() => {
    dispatch(openForm("leadLost", { _id: details._id }));
  }, [dispatch, details._id]);

  const openOnHold = useCallback(() => {
    dispatch(openForm("onHold", { _id: details._id }));
  }, [dispatch, details._id]);

  const openProjectCancelled = useCallback(() => {
    dispatch(openForm("projectCancelled", { _id: details._id }));
  }, [dispatch, details._id]);

  const openProjectCloseout = useCallback(() => {
    dispatch(
      openForm("projectCloseout", {
        _id: details._id,
        scopes,
        collections,
        actions,
      })
    );
  }, [dispatch, details._id, scopes, collections, actions]);

  const openGuaranteedRepair = () => {
    dispatch(
      openForm("scope", {
        name: "Guaranteed Repair",
        premierWarranty: "N/A",
        extendedWarranty: "N/A",
        repair: true,
      })
    );
  };

  return (
    <div style={{ marginBottom: 12 }}>
      {details.projectStatus === "Lead Lost" ||
      details.projectStatus === "Estimate Not Sold" ? (
        <div className={styles.actionContainer}>
          <Button
            className={`${styles.redBtn} ${styles.fullWidth}`}
            block
            onClick={confirmReopenLead}
          >
            Re-open Lead
          </Button>
        </div>
      ) : details.projectStatus === "On Hold" ? (
        <div className={styles.actionContainer}>
          <Button
            className={`${styles.redBtn} ${styles.fullWidth}`}
            block
            onClick={confirmRemoveHold}
          >
            Remove Hold
          </Button>
        </div>
      ) : details.projectStatus &&
        details.projectStatus.includes("Lead") &&
        details.projectStatus !== "Lead Lost" ? (
        <div className={styles.actionContainer}>
          <Button className={styles.greenBtn} block onClick={openLeadSold}>
            Mark Sold
          </Button>

          <Button
            className={styles.grayBtn}
            block
            style={{ marginRight: 8, marginLeft: 8 }}
            onClick={openEstimate}
          >
            Estimate
          </Button>

          <Button className={styles.redBtn} block onClick={openLeadLost}>
            Lead Lost
          </Button>
        </div>
      ) : details.projectStatus &&
        details.projectStatus.includes("Estimate") &&
        details.projectStatus !== "Estimate Not Sold" ? (
        <div className={styles.actionContainer}>
          <Button className={styles.greenBtn} block onClick={openLeadSold}>
            Mark Sold
          </Button>

          <Button
            className={styles.grayBtn}
            block
            style={{ marginRight: 8, marginLeft: 8 }}
            onClick={openChangeStatus}
          >
            Chage Status
          </Button>

          <Button
            className={styles.redBtn}
            block
            style={{ marginLeft: 8 }}
            onClick={openLeadLost}
          >
            Lead Lost
          </Button>
        </div>
      ) : details.projectStatus === "Sold" ? (
        <div className={styles.actionContainer}>
          <Button
            className={styles.greenBtn}
            block
            onClick={openProjectCloseout}
          >
            Close Out
          </Button>

          <Button
            className={styles.grayBtn}
            block
            style={{ marginRight: 8, marginLeft: 8 }}
            onClick={openOnHold}
          >
            On Hold
          </Button>

          <Button
            className={styles.redBtn}
            block
            onClick={openProjectCancelled}
          >
            Cancel
          </Button>
        </div>
      ) : details.projectStatus === "Cancelled" ? (
        <div className={styles.actionContainer}>
          <Button
            className={`${styles.redBtn} ${styles.fullWidth}`}
            block
            onClick={confirmRemoveCancellation}
          >
            Remove Cancellation
          </Button>
        </div>
      ) : details.projectStatus === "Closed Out" ? (
        <div className={styles.actionContainer}>
          <Button
            className={`${styles.redBtn} ${styles.fullWidth}`}
            block
            onClick={openGuaranteedRepair}
          >
            Add Guaranteed Repair
          </Button>
        </div>
      ) : null}

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0 },
        }}
        type={!screens.md ? "drawer" : "modal"}
        width={!screens.md ? "100%" : 320}
        title="Estimate Ran"
        visible={estimate}
        form={Estimate}
        formType="estimate"
      />

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0 },
        }}
        type={!screens.md ? "drawer" : "modal"}
        width={!screens.md ? "100%" : 320}
        title="Estimate Ran"
        visible={changeStatus}
        form={ChangeStatus}
        formType="changeStatus"
      />

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0 },
        }}
        type={!screens.md ? "drawer" : "modal"}
        width={!screens.md ? "100%" : 320}
        title="Lead Lost"
        visible={leadLost}
        form={LeadLost}
        formType="leadLost"
      />

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0 },
        }}
        type={!screens.md ? "drawer" : "modal"}
        width={!screens.md ? "100%" : 320}
        title="Lead Sold"
        visible={leadSold}
        form={LeadSold}
        formType="leadSold"
      />
    </div>
  );
};

export default LeadProjectActions;
