import React, { useState, useEffect, useCallback } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchTable } from "Actions/tableActions";
import api from "API";
import Input from "antd/es/input";
import Form from "antd/es/form";
import message from "antd/es/message";
import Button from "antd/es/button";
import Modal from "antd/es/modal";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import CancelButton from "Components/CancelButton";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";
import Select from "Components/Inputs/Select";

const messageKey = "messageKey";

const Supplier = ({
  unsavedChanges,
  setUnsavedChanges,
  handleClose,
  setTitle,
  type,
}) => {
  const dispatch = useDispatch();
  const formDetails = useSelector(
    (state) => state.formState.supplier.formDetails
  );
  const table = useSelector((state) => state.tableState.table);
  const col = useSelector((state) => state.tableState.col);
  const order = useSelector((state) => state.tableState.order);
  const search = useSelector((state) => state.tableState.search);
  const activeFilter = useSelector((state) => state.tableState.activeFilter);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [expenseTypes, setExpenseTypes] = useState([]);

  useEffect(() => {
    fetchExpenseTypes();
    if (!formDetails.editing) {
      setTitle("Create Supplier");
    } else {
      setTitle(
        <span>
          Edit Supplier{" "}
          <Button
            size="small"
            danger
            onClick={confirmDelete}
            style={{ float: "right", marginRight: 28 }}
          >
            Delete
          </Button>
        </span>
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchExpenseTypes = async () => {
    try {
      const res = await api.post("/dropdowns/get", {
        selects: ["Expenses"],
      });

      unstable_batchedUpdates(() => {
        setExpenseTypes(res.data.Expenses.options);
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const confirmDelete = () => {
    Modal.confirm({
      zIndex: 4002,
      icon: <QuestionCircleOutlined />,
      centered: true,
      cancelText: "Cancel",
      okText: "Delete",
      okButtonProps: { danger: true },
      content: <div>Are you sure you want to delete this supplier?</div>,
      onOk() {
        return new Promise((resolve, reject) => {
          deleteSupplier(() => resolve(true));
        }).catch((err) => console.log("err: ", err));
      },
      onCancel() {},
    });
  };

  const deleteSupplier = useCallback(
    async (cb) => {
      try {
        const _id = await form.getFieldValue("_id");
        await api.delete(`/vendor/${_id}`);

        dispatch(
          fetchTable({
            table,
            col,
            order,
            search,
            activeFilter,
            fetching: false,
          })
        );

        unstable_batchedUpdates(() => {
          setUnsavedChanges(false);
          setLoading(false);
        });

        cb();
        message.success("Supplier Deleted");
        handleClose(true);
      } catch (err) {
        console.log("err", err);
        message.error("Error deleting Supplier");
      }
    },
    [
      form,
      dispatch,
      table,
      col,
      order,
      search,
      activeFilter,
      setUnsavedChanges,
      handleClose,
    ]
  );

  const onFinish = useCallback(
    async (values) => {
      try {
        setLoading(true);
        // message.loading("Saving Supplier...", 0);
        message.loading({
          content: "Saving supplier...",
          duration: 0,
          key: messageKey,
        });
        if (!formDetails.editing) {
          await api.post("/suppliers/create", values);
        } else {
          await api.post("/suppliers/edit", values);
        }

        dispatch(
          fetchTable({
            table,
            col,
            order,
            search,
            activeFilter,
            fetching: false,
          })
        );

        unstable_batchedUpdates(() => {
          setUnsavedChanges(false);
          setLoading(false);
        });

        // message.success("Supplier saved");
        message.success({
          content: "Supplier saved",
          duration: 1.5,
          key: messageKey,
        });
        handleClose(true);
      } catch (err) {
        setLoading(false);
        // message.error("Error saving supplier");
        message.error({
          content: "Error saving supplier",
          duration: 1.5,
          key: messageKey,
        });
        console.log("err", err);
      }
    },
    [
      formDetails.editing,
      dispatch,
      table,
      col,
      order,
      search,
      activeFilter,
      setUnsavedChanges,
      handleClose,
    ]
  );

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error saving supplier");
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      initialValues={{
        _id: !formDetails._id ? undefined : formDetails._id,
        companyName: !formDetails.companyName
          ? undefined
          : formDetails.companyName,
        email: !formDetails.email ? undefined : formDetails.email,
        expenseTypes: !formDetails.expenseTypes
          ? undefined
          : formDetails.expenseTypes,
      }}
      className="form"
    >
      <RemoveAutocomplete />

      <FormBody
        className="content-inner"
        style={{
          paddingLeft: 24,
          paddingTop: 24,
          paddingRight: 24,
          paddingBottom: 8,
          maxHeight: type === "modal" ? "calc(100vh - 161px)" : "unset",
        }}
      >
        <Form.Item name="_id" hidden>
          <Input />
        </Form.Item>

        <Form.Item
          name="companyName"
          label="Company Name"
          rules={[{ required: true, message: "Company name is required" }]}
        >
          <InputNoAutoComplete autoFocus id="companyName" />
        </Form.Item>

        <Form.Item
          name="email"
          label="Email"
          rules={[
            { type: "email", message: "Must be valid email" },
            { required: true, message: "Email is required" },
          ]}
        >
          <InputNoAutoComplete type="email" id="email" />
        </Form.Item>

        <Form.Item
          name="expenseTypes"
          label="Expense Types"
          rules={[{ required: true, message: "Expense Types is required" }]}
        >
          <Select mode="multiple" options={expenseTypes} id="expenseTypes" />
        </Form.Item>
      </FormBody>
      <FormControls className="sticky-footer">
        <CancelButton handleClick={handleClose} />
        <Form.Item style={{ margin: 0 }}>
          <Button
            htmlType="submit"
            type="primary"
            loading={loading}
            disabled={loading || !unsavedChanges}
          >
            Submit
          </Button>
        </Form.Item>
      </FormControls>
    </Form>
  );
};

export default Supplier;
