import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "antd/es/button";
import Typography from "antd/es/typography";
import Row from "antd/es/row";
import Col from "antd/es/col";
import styles from "./styles.module.scss";
import _401 from "Assets/401.png";

const { Title, Text } = Typography;

const goToSignin = () => {
  window.location.href = "/";
};

const Unauthorized = () => {
  const navigate = useNavigate();

  const goToBack = () => {
    navigate(-1);
  };

  return (
    <Row
      type="flex"
      justify="center"
      align="middle"
      style={{ height: "100%", position: "absolute", width: "100%" }}
      className="public-background"
    >
      <Col xs={22} sm={20} md={16} lg={14} xl={12} className="text-center">
        <div className={styles.errorContainer}>
          <div
            style={{
              textAlign: "center",
              height: "calc(100% - 50px)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <img
              src={_401}
              style={{ marginBottom: 24, display: "block" }}
              alt="401"
            />
            <Title level={3}>Unauthorized Access</Title>
            <Text>
              You must be logged in in order to use MyCo Story or you are not
              authorized to access the page you are attempting to view.
            </Text>
            <div style={{ width: 200, marginTop: 42 }}>
              <Button
                type="primary"
                onClick={goToBack}
                style={{ marginRight: 12 }}
              >
                Back
              </Button>
              <Button type="primary" onClick={goToSignin}>
                Sign in
              </Button>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Unauthorized;
