import { useEffect, useState } from "react";
import api from "API";
let isMounted = false;

export default function useSuppliers() {
  const [suppliers, setSuppliers] = useState({});

  useEffect(() => {
    isMounted = true;
    fetchSuppliers();

    return () => (isMounted = false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchSuppliers = async () => {
    try {
      const res = await api.post("/vendors/get", { type: "supplier" });
      let arr = {};
      for (const s of res.data) {
        if (s.email !== "fake@fake.com") {
          arr[s._id] = {
            _sub: s._id,
            companyName: s.companyName,
            email: s.email,
          };
        }
      }

      if (isMounted) setSuppliers(arr);
    } catch (err) {
      console.log("err", err);
    }
  };

  return suppliers;
}
