const stages = {
  "Order Aerial": "#F94144",
  "Aerial Verification": "#F3722C",
  "Mold Review": "#ED8607",
  Permit: "#F97939",
  "Pre-Job Check": "#D99A08",
  "Ready For Material": "#7EB356",
  "Ready For Dumpster": "#43AA8B",
  "Ready For Schedule": "#4D908E",
  "Pre-Job Inspection": "#577590",
  "Post-Job Inspection": "#277DA1",
  "Work Scheduled": "#00c292",
  Completed: "#108ee9",
  "Closed Out": "#00c292",
  "On-site Check": "#D99A08",
};

export default stages;
