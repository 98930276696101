import React, { useState } from "react";
import Menu from "antd/es/menu";
import Dropdown from "antd/es/dropdown";
import Button from "antd/es/button";
import MenuDown from "Icons/MenuDown";
import ManageTemplates from "Components/ManageTemplatesAndFilters";
import styles from "./styles.module.scss";

const TemplatesAndFiltersDropdown = ({
  type,
  globalArray = [],
  userArray = [],
  setArray,
  btnStyle = { float: "left", padding: "4px 8px" },
  onClick,
  name,
  activeFilter,
  className,
  activeKey = null,
  size = 0,
}) => {
  const [manageModal, setManageModal] = useState(false);
  const globalMenu = globalArray.map((a) => {
    const handleClick = () => {
      onClick(a);
    };

    return (
      <Menu.Item key={a._id} onClick={handleClick}>
        {a.name}
      </Menu.Item>
    );
  });

  const userMenu = userArray.map((a) => {
    const handleClick = () => {
      onClick(a);
    };

    return (
      <Menu.Item key={a._id} onClick={handleClick}>
        {a.name}
      </Menu.Item>
    );
  });

  const openManageModal = () => {
    setManageModal(true);
  };

  return (
    <>
      <Dropdown
        getPopupContainer={(trigger) => trigger.parentNode}
        overlay={
          <Menu className={styles.menu} selectedKeys={[activeKey]}>
            {globalArray.length > 0 && (
              <Menu.ItemGroup title="Global">{globalMenu}</Menu.ItemGroup>
            )}
            {userArray.length > 0 && (
              <Menu.ItemGroup title="User">{userMenu}</Menu.ItemGroup>
            )}

            {userArray.length > 0 && <Menu.Divider />}
            {userArray.length > 0 && (
              <Menu.Item onClick={openManageModal}>Manage {type}</Menu.Item>
            )}
          </Menu>
        }
        trigger={["click"]}
      >
        <Button
          type="text"
          style={btnStyle}
          className={className ? className : ""}
        >
          <span style={type !== "Template" ? { marginRight: 6 } : {}}>
            {activeFilter && activeFilter.name
              ? activeFilter.name
              : name
              ? name
              : type + "s"}
          </span>
          {type !== "Template" && (
            <>
              <small>({size})</small>
              {(userArray.length > 0 || globalArray.length > 0) && (
                <MenuDown size={18} />
              )}
            </>
          )}
        </Button>
      </Dropdown>

      <ManageTemplates
        array={userArray}
        setArray={setArray}
        visible={manageModal}
        onClose={setManageModal}
        managing={type}
      />
    </>
  );
};

export default TemplatesAndFiltersDropdown;
