import React, { useState } from "react";
import Modal from "antd/es/modal";
import Typography from "antd/es/typography";
import Button from "antd/es/button";
import NotificationImage from "Assets/notification-image.png";
import * as serviceWorker from "../serviceWorkerRegistration";
const { Title, Text } = Typography;

const NotificationPermission = ({
  visible,
  setVisible,
  // setNotificationsPrompted,
  callback,
}) => {
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setLoading(true);
    serviceWorker.subscribe(() => {
      setVisible(false);
      // setNotificationsPrompted(true);
      localStorage.setItem("notificationsPrompted", "true");
      localStorage.setItem("notifications", "true");
      setLoading(false);
      if (callback) callback(true);
    });
  };

  const handleClose = () => {
    setVisible(false);
    // setNotificationsPrompted(true);
    localStorage.setItem("notificationsPrompted", "true");
    localStorage.setItem("notifications", "false");
    if (callback) callback(false);
  };

  return (
    <Modal
      centered
      visible={visible}
      closable={false}
      footer={null}
      destroyOnClose
      forceRender
      maskClosable={false}
      width={380}
      style={{ minHeight: 538 }}
      bodyStyle={{ minHeight: 538 }}
      className="ignore-cancel-animations"
    >
      <div className="text-center">
        <Title>NOTIFICATIONS</Title>

        <img
          src={NotificationImage}
          style={{ margin: "auto", marginBottom: 12, display: "block" }}
          alt="notification"
        />

        <Text>
          By enabling notifications you will never miss any notifications.
        </Text>
      </div>

      <Button
        type="primary"
        block
        style={{ marginTop: 24 }}
        onClick={handleClick}
        loading={loading}
        disabled={loading}
      >
        Sign me up
      </Button>
      <Button
        type="default"
        block
        style={{ marginTop: 12 }}
        onClick={handleClose}
      >
        Not at this time
      </Button>
    </Modal>
  );
};

export default NotificationPermission;
