import React, { useState } from "react";
import Modal from "antd/es/modal";
import Typography from "antd/es/typography";
import Form from "antd/es/form";
import message from "antd/es/message";
import API from "API";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";

const NamingTemplateModal = ({
  visible,
  onClose,
  form,
  update,
  fieldName,
  setSelected,
}) => {
  const [loading, setLoading] = useState(false);

  const onOk = async () => {
    try {
      setLoading(true);

      let array = await form.getFieldValue(fieldName);
      let newArray = array;
      array = array.filter((a) => a.checkbox === true);
      const name = await form.getFieldValue("templateName");

      const res = await API.post(`/templates/save/${name}`, array);

      newArray.forEach((a) => {
        a.checkbox = false;
      });
      form.setFieldsValue({
        material: newArray,
      });

      update(res.data);
      onClose(false);
      setSelected(undefined);
      setLoading(false);
      form.setFieldsValue({ templateName: undefined });
      message.success("Template successfully saved");
    } catch (err) {
      console.log("err", err);
      setLoading(false);
      message.error("An error occured while saving your template");
    }
  };

  const handleClose = () => {
    onClose(false);
    form.setFieldsValue({ templateName: undefined });
  };

  return (
    <Modal
      destroyOnClose
      visible={visible}
      onClose={handleClose}
      onOk={onOk}
      okButtonProps={!loading ? {} : { loading: true, disabled: true }}
      onCancel={handleClose}
      centered
      width={300}
      okText="Save"
      cancelButtonProps={{ style: { float: "left" } }}
      zIndex={4001}
    >
      <Typography.Title level={2}>Save Template</Typography.Title>
      <Form.Item
        label="Template Name"
        name="templateName"
        rules={[
          {
            required: true,
            message: "Template name is required",
          },
        ]}
        style={{ marginBottom: 0 }}
      >
        <InputNoAutoComplete autoFocus id="templateName" />
      </Form.Item>
    </Modal>
  );
};

export default NamingTemplateModal;
