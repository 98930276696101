import React from "react";
import InputNumberNoAutoComplete from "Components/Inputs/InputNumberNoAutoComplete";

const Percent = ({
  onChange,
  autoComplete = "off",
  value,
  placeholder = undefined,
  disabled = false,
  id = undefined,
}) => {
  const triggerChange = (changedValue) => {
    if (onChange) {
      onChange(changedValue);
    }
  };

  const handleChange = (e) => {
    triggerChange(e);
  };

  return (
    <InputNumberNoAutoComplete
      disabled={disabled}
      value={value}
      autoComplete={autoComplete}
      onChange={handleChange}
      placeholder={placeholder}
      numberType="percent"
      id={id}
    />
  );
};

export default Percent;
