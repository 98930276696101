import React from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { openForm } from "Actions/formActions";
import Button from "antd/es/button";
import message from "antd/es/message";
import Call from "Icons/Call";
import Textsms from "Icons/Textsms";
import Directions from "Icons/Directions";
import Link from "Icons/Link";
import removePhoneSymbols from "Utils/removePhoneSymbols";
import Clipboard from "react-clipboard.js";
import styles from "./styles.module.scss";

const ActionButtons = ({ ghost = false, style, narrow }) => {
  const location = useLocation();
  const phone = useSelector((state) => state.dataState.details.phone);
  const projectAddressStreet = useSelector(
    (state) => state.dataState.details.projectAddressStreet
  );
  const projectAddressCity = useSelector(
    (state) => state.dataState.details.projectAddressCity
  );
  const projectAddressState = useSelector(
    (state) => state.dataState.details.projectAddressState
  );
  const projectAddressZip = useSelector(
    (state) => state.dataState.details.projectAddressZip
  );
  const dispatch = useDispatch();

  const copied = () => {
    message.success("URL copied to clipboard");
  };

  const openSMSMobile = () => {
    window.open(`sms:${removePhoneSymbols(phone)}`, "_self");
    dispatch(openForm("activity"));
    return false;
  };

  const openPost = () => {
    dispatch(openForm("activity"));
  };

  let baseURL;
  if (process.env.NODE_ENV === "production") {
    baseURL = "https://story.gopremier.net";
  } else {
    baseURL = "http://localhost:3001";
  }

  return (
    <div
      className={`${styles.actionButtons} ${narrow ? styles.narrow : ""}`}
      style={style}
    >
      <Button style={{ background: ghost ? "transparent" : "initial" }}>
        <a href={`tel:${removePhoneSymbols(phone)}`} onClick={openPost}>
          <Call />
          <div>Call</div>
        </a>
      </Button>
      <Button
        onClick={openSMSMobile}
        style={{ background: ghost ? "transparent" : "initial" }}
      >
        <Textsms />
        <div>Text/SMS</div>
      </Button>
      <Button style={{ background: ghost ? "transparent" : "initial" }}>
        <a
          href={`https://www.google.com/maps/dir/?api=1&destination=${projectAddressStreet}, ${projectAddressCity}, ${projectAddressState} ${projectAddressZip}`}
          target="_Blank"
          rel="noopener noreferrer"
        >
          <Directions />
          <div>Directions</div>
        </a>
      </Button>

      <Button style={{ background: ghost ? "transparent" : "initial" }}>
        <Clipboard
          data-clipboard-text={`${baseURL}${location.pathname}`}
          component="div"
          onSuccess={copied}
        >
          <Link />
          <div>Copy URL</div>
        </Clipboard>
      </Button>

      {/* <Button
        style={{ background: ghost ? "transparent" : "initial" }}
        className={
          !mccEligible
            ? ""
            : mccEstablished === "unsent"
            ? ""
            : mccEstablished === "accepted"
            ? styles.green
            : styles.red
        }
      >
        <a
          href={
            mccEstablished === "unsent"
              ? `https://myco-contact.herokuapp.com/invite/${_id}`
              : `https://myco-contact.herokuapp.com/view/${_mccId}`
          }
          target="_Blank"
          rel="noopener noreferrer"
        >
          {mccEligible ? <Contact /> : <ContactCrossedOut />}
          <div>Contact</div>
        </a>
      </Button> */}
    </div>
  );
};

export default ActionButtons;
