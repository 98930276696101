import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchTable } from "Actions/tableActions";
import List from "antd/es/list";
import Button from "antd/es/button";
import styles from "./styles.module.scss";
import Sort from "Icons/Sort";

const TableColumnHeaders = ({
  headers,
  bordered = true,
  noSideBorders = false,
  notSortable,
}) => {
  const dispatch = useDispatch();
  const table = useSelector((state) => state.tableState.table);
  const col = useSelector((state) => state.tableState.col);
  const order = useSelector((state) => state.tableState.order);
  const search = useSelector((state) => state.tableState.search);
  const activeFilter = useSelector((state) => state.tableState.activeFilter);
  const scrollBarWidth = useSelector(
    (state) => state.tableState.scrollBarWidth
  );

  const handleSort = useCallback(
    (_col) => {
      dispatch(
        fetchTable({
          table,
          col: _col._name,
          order: order === 1 ? -1 : 1,
          search,
          activeFilter,
        })
      );
    },
    [dispatch, table, order, search, activeFilter]
  );

  return (
    <List
      bordered={bordered}
      className={`${styles.tableColumnHeaders} ${
        bordered ? styles.borderBottom : ""
      } ${noSideBorders ? styles.noSideBorders : ""}`}
    >
      <List.Item style={{ width: `calc(100% - ${scrollBarWidth}px)` }}>
        {headers.map((h) => {
          const onClick = () => {
            handleSort(h);
          };

          return (
            <List.Item.Meta
              className={styles.colHeader}
              key={h._name}
              style={{ width: h.width, textAlign: h.align ? h.align : "left" }}
              title={
                notSortable ? (
                  <div
                    className={h._name === col ? styles.active : ""}
                    style={{
                      height: 32,
                      display: "inline-flex",
                      alignItems: "center",
                      justifyContent:
                        h.align && h.align === "right"
                          ? "flex-end"
                          : "flex-start",
                    }}
                  >
                    <span>{h.name}</span>
                  </div>
                ) : (
                  <Button
                    block
                    className={h._name === col ? styles.active : ""}
                    onClick={onClick}
                    // style={{ textAlign: h.align ? h.align : "left" }}
                    style={{
                      justifyContent:
                        h.align && h.align === "right"
                          ? "flex-end"
                          : "flex-start",
                    }}
                  >
                    {h.name}{" "}
                    <Sort
                      className={`${styles.sortIcon} ${
                        order === 1 ? styles.up : styles.down
                      }`}
                    />
                  </Button>
                )
              }
            />
          );
        })}
      </List.Item>
    </List>
  );
};

export default TableColumnHeaders;
