import React, { useState, useEffect } from "react";
import List from "antd/es/list";
import Badge from "antd/es/badge";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Alert from "antd/es/alert";
import currencyFormatter from "Utils/currencyFormatter";
import styles from "./styles.module.scss";

const Audit = ({ collectionTotal, scopes, warnings, actions }) => {
  const [invoicedAmount, setInvoicedAmount] = useState(0);

  useEffect(() => {
    let _invoicedAmount = 0;
    for (const scope of scopes) {
      _invoicedAmount += scope.invoicedAmount;
    }
    setInvoicedAmount(_invoicedAmount);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className={styles.justify}>
        <Badge
          status="success"
          text={<span className={styles.successText}>Success</span>}
          style={{ marginBottom: 12 }}
        />
        <Badge
          status="warning"
          text={<span className={styles.warningText}>Warning</span>}
          style={{ marginLeft: 12, marginBottom: 12 }}
        />
        <Badge
          status="error"
          text={<span className={styles.errorText}>Error</span>}
          style={{ marginLeft: 12, marginBottom: 12 }}
        />
      </div>

      <Alert
        style={{ marginBottom: 12 }}
        type={
          invoicedAmount.toString() !== collectionTotal.toString()
            ? "error"
            : warnings !== 0
            ? "warning"
            : "success"
        }
        message={
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <b>Invoiced Total:</b>
              <div>{currencyFormatter(invoicedAmount)}</div>
            </Col>
            <Col xs={24} sm={12}>
              <b>Collection Total:</b>
              <div>{currencyFormatter(collectionTotal)}</div>
            </Col>
          </Row>
        }
      />

      <List bordered>
        {invoicedAmount.toString() !== collectionTotal.toString() ? (
          <List.Item>
            <List.Item.Meta
              title={
                <div>
                  <Badge
                    status="error"
                    text={
                      <span className={styles.errorText}>
                        The total Invoiced Amount does not equal the total
                        Collected Amount
                      </span>
                    }
                  />
                </div>
              }
            />
          </List.Item>
        ) : null}
        {scopes &&
          scopes.map((scope) => {
            if (scope.status !== "Collected") {
              return (
                <List.Item key={scope._id}>
                  <List.Item.Meta
                    title={
                      <div>
                        <Badge
                          status="warning"
                          text={
                            <span
                              className={styles.warningText}
                            >{`Status for ${scope.name} is: ${scope.status}`}</span>
                          }
                        />
                      </div>
                    }
                  />
                </List.Item>
              );
            }
            //} else if (scope.status !== "Collected" && scope.progress < 100) {
            //  return (
            //    <Fragment key={scope._id}>
            //      <List.Item>
            //        <List.Item.Meta
            //          title={
            //            <div>
            //              <Badge
            //                status="warning"
            //                text={
            //                  <span
            //                    className={styles.warningText}
            //                  >{`Status for ${scope.name} is: ${scope.status}`}</span>
            //                }
            //</List.Item>              />
            //            </div>
            //          }
            //</List>        />
            //      </List.Item>
            //      <List.Item>
            //        <List.Item.Meta
            //          title={
            //            <div>
            //              <Badge
            //                status="warning"
            //                text={
            //                  <span className={styles.warningText}>
            //                    {`Progress for ${scope.name} is: ${scope.progress}%`}
            //                  </span>
            //                }
            //              />
            //            </div>
            //          }
            //        />
            //      </List.Item>
            //    </Fragment>
            //  );
            //} else if (scope.progress < 100) {
            //  return (
            //    <List.Item key={scope._id}>
            //      <List.Item.Meta
            //        title={
            //          <div>
            //            <Badge
            //              status="warning"
            //              text={
            //                <span className={styles.warningText}>
            //                  {`Progress for ${scope.name} is: ${scope.progress}%`}
            //                </span>
            //              }
            //            />
            //          </div>
            //        }
            //      />
            //    </List.Item>
            //  );
            //}
            return null;
          })}
        {actions &&
          actions.map((action) => {
            if (action.open) {
              return (
                <List.Item key={action._id}>
                  <List.Item.Meta
                    title={
                      <div>
                        <Badge
                          status="warning"
                          text={
                            <span
                              className={styles.warningText}
                            >{`Invoice action with title "${
                              action.title
                            }" for ${currencyFormatter(
                              action.amount
                            )} is still open`}</span>
                          }
                        />
                      </div>
                    }
                  />
                </List.Item>
              );
            }
            return null;
          })}
        {warnings === 0 && (
          <List.Item>
            <List.Item.Meta
              title={
                <div>
                  <Badge
                    status="success"
                    text={
                      <span className="successText">
                        No warnings or errors could be found with this project
                      </span>
                    }
                  />
                </div>
              }
            />
          </List.Item>
        )}
      </List>
    </>
  );
};

export default Audit;
