import HighCharts from "highcharts";
import HighChartsReact from "highcharts-react-official";

import React, { useState, useEffect } from "react";
import currencyFormatter from "Utils/currencyFormatter";
import Row from "antd/es/row";
import Col from "antd/es/col";
import message from "antd/es/message";
import Card from "antd/es/card";
import api from "API";
import styles from "./styles.module.scss";

window.Highcharts = HighCharts;

HighCharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
  colors: [
    "#00c292",
    "#fec107",
    "#FF7056",
    "#343a40",
    "#01c0c8",
    "#999999",
    "#FF9655",
    "#FFF263",
    "#6AF9C4",
  ],
});

const BlockBonus = () => {
  const [state, setState] = useState({
    totalMonthly: 0,
    options: {
      chart: {
        type: "column",
        height: 550,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: "",
      },
      subtitle: {
        text: "",
      },
      xAxis: {
        categories: ["No Data"],
        labels: {
          rotation: -45,
          style: {
            fontSize: "13px",
            fontFamily: "Verdana, sans-serif",
          },
        },
      },
      yAxis: {
        title: {
          text: "",
        },
        labels: {
          formatter: function () {
            let label = this.value;
            if (this.value >= 1000000) {
              label = this.value / 1000000 + "M";
            } else if (this.value >= 1000) {
              label = this.value / 1000 + "k";
            } else if (this.value <= -1000) {
              label = this.value / 1000 + "k";
            } else if (this.value <= -1000000) {
              label = this.value / 1000000 + "M";
            }
            return `${label}`;
          },
        },
      },
      tooltip: {
        useHTML: true,
        formatter: function () {
          let table = "<table><tr><td><b>" + this.key + "</b></tr>";
          table +=
            "<tr><td>$" + HighCharts.numberFormat(this.y, 2) + "</td></tr>";
          table += `</table>`;
          return table;
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            formatter: function () {
              return "$" + HighCharts.numberFormat(this.y, 2);
            },
          },
        },
      },
      series: [
        {
          name: "No Data",
          data: [0, 0, 0, 0],
        },
      ],
    },
  });

  useEffect(() => {
    getReport();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getReport = async () => {
    try {
      const res = await api.post("/reports/blockbonus", {});

      setState((thisState) => {
        return {
          ...thisState,
          totalMonthly: res.data.totalValue,
          options: {
            ...thisState.options,
            series: res.data.series,
            xAxis: {
              ...thisState.options.xAxis,
              categories: res.data.categories,
            },
          },
        };
      });
    } catch (err) {
      console.log("err", err);
      message.error("Error getting report");
    }
  };

  return (
    <div style={{ paddingBottom: 24 }} className="content-inner">
      <Card bordered={false} className="z-depth-1" style={{ margin: 24 }}>
        <Row gutter={16} style={{ marginBottom: 24 }}>
          <Col xs={24}>
            <div className={styles.reportTitle}>BLOCK BONUS</div>
          </Col>
        </Row>

        <Row gutter={16} style={{ marginBottom: 24 }}>
          <Col xs={24}>
            <div className={styles.reportHeader}>
              <div className={styles.total}>
                {currencyFormatter(state.totalMonthly)}
                <small> TOTAL MONTHLY</small>
              </div>
            </div>
          </Col>
        </Row>

        <HighChartsReact
          highcharts={HighCharts}
          options={state.options}
          updateArgs={[true, true, true]}
        />
      </Card>
    </div>
  );
};

export default BlockBonus;
