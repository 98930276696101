import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openForm } from "Actions/formActions";
import List from "antd/es/list";
import styles from "./styles.module.scss";
import DumpTruck from "Icons/DumpTruck";

const Dumpings = ({ dumpings }) => {
  return (
    <>
      {dumpings && dumpings.length > 0 ? (
        dumpings.map((dumping) => (
          <List
            bordered
            key={dumping._id}
            itemLayout="horizontal"
            className={styles.list}
          >
            <DumpingsItem dumping={dumping} />
          </List>
        ))
      ) : (
        <List bordered itemLayout="horizontal" className={styles.list}>
          <List.Item className={`${styles.transactionList} ${styles.none}`}>
            <List.Item.Meta
              avatar={
                <div className={styles.avatar}>
                  <DumpTruck color="#108ee9" />
                </div>
              }
              title="No dumping has been logged"
            />
          </List.Item>
        </List>
      )}
    </>
  );
};

const DumpingsItem = ({ dumping }) => {
  const dispatch = useDispatch();
  const admin = useSelector((state) => state.authState.admin);
  const role = useSelector((state) => state.authState.role);

  const editDumping = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(
        openForm("dumping", {
          ...dumping,
          editing: true,
        })
      );
    },
    [dispatch, dumping]
  );

  return (
    <List.Item
      onClick={admin === "Yes" || role === "Office" ? editDumping : undefined}
      className={styles.transactionList}
    >
      <List.Item.Meta
        avatar={
          <div className={styles.avatar}>
            <DumpTruck color="#108ee9" />
          </div>
        }
        title={dumping.size}
        description={dumping.status}
      />
    </List.Item>
  );
};

export default Dumpings;
