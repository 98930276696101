import React, { useState, useEffect, useCallback } from "react";
import Button from "antd/es/button";
import message from "antd/es/message";
import Input from "antd/es/input";
// import Checkbox from "antd/es/checkbox";
import Form from "antd/es/form";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import ManageBuilders from "Components/Inputs/ManageBuilders";
import PropertyType from "Components/Inputs/PropertyType";
import Phone from "Components/Inputs/Phone";
import DatePicker from "Components/Inputs/DatePicker";
import Address from "Components/Inputs/Address";
import DuplicateCheck from "./DuplicateCheck";
// import Currency from "Components/Inputs/Currency";
import Campaigns from "Components/Inputs/Campaigns";
import Select from "Components/Inputs/Select";
import ProjectManager from "Components/Inputs/ProjectManager";
import Salesmen from "Components/Inputs/Salesmen";
import Scopes from "Components/Inputs/Scopes";
import Map from "Components/Details/fields/Map";
import useSalesmen from "Hooks/useSalesmen";
import usePrevious from "Hooks/usePrevious";
import useIsMobile from "Hooks/useIsMobile";
import styles from "./styles.module.scss";
import dayjs from "dayjs";
import api from "API";
import CancelButton from "Components/CancelButton";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";
import Modal from "antd/es/modal";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";
// import Checkbox from "antd/es/checkbox";

const messageKey = "messageKey";

const LeadForm = ({ createNew, setCreateNew }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  // const [createNew, setCreateNew] = useState(false);
  const [propertyType, setPropertyType] = useState();
  const [_lat, _setLat] = useState(42.963795);
  const [_lng, _setLng] = useState(-85.670006);
  const [salesmen, setSalesmen] = useState({});
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [copyAddress, setCopyAddress] = useState("");
  const [selectedOrigin, setSelectedOrigin] = useState(undefined);
  const [unqualifiedLead] = useState(false);
  const prevCreateNew = usePrevious(createNew);
  const sales = useSalesmen();
  const mobile = useIsMobile();

  useEffect(() => {
    if (createNew && !prevCreateNew) {
      form.setFieldsValue({
        projectAddressStreet: copyAddress,
      });
    }
  }, [createNew, prevCreateNew, sales]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCancel = useCallback(
    (cb) => {
      const el = document.getElementById("lead-form-body");
      el.scrollTop = 0;

      form.resetFields();
      // setUnqualifiedLead(false);
      setSelectedOrigin(undefined);
      setCreateNew(false);
      cb();
    },
    [form, setCreateNew]
  );

  const onFinish = useCallback(
    async (values) => {
      try {
        setLoading(true);

        message.loading({
          content: "Saving Lead...",
          duration: 0,
          key: messageKey,
        });
        values.salesObj = [];
        if (values.salesmen) {
          for (const v of values.salesmen) {
            values.salesObj.push(sales[v]);
          }
        }

        if (values.projectManager) {
          values.projectManager = sales[values.projectManager];
        }

        if (values.estimateStart) {
          values.estimateStart = new Date(values.estimateStart);
          values.estimateEnd = new Date(
            dayjs(values.estimateStart).add(3, "hours")
          );
        }

        const res = await api.post("/leads/create", values);

        if (res.status === 200) {
          setLoading(false);

          message.success({
            content: "Lead saved",
            duration: 1.5,
            key: messageKey,
          });

          const el = document.getElementById("lead-form-body");
          el.scrollTop = 0;

          form.resetFields();
          // setUnqualifiedLead(false);
          setSelectedOrigin(undefined);
          setCreateNew(false);
        } else {
          setLoading(false);
          // message.error("Error saving lead");
          message.error({
            content: "Error saving lead",
            duration: 1.5,
            key: messageKey,
          });
        }
      } catch (err) {
        if (
          err &&
          err.errorFields &&
          err.errorFields[0] &&
          err.errorFields[0].name
        ) {
          if (err.errorFields[0].name[0] === "lineItems") {
            const el = document.getElementById("top-of-form");
            el.scrollIntoView({ block: "end", behavior: "smooth" });
          } else {
            form.scrollToField(err.errorFields[0].name[0]);
          }
        }
        setLoading(false);
        // message.error("Error saving lead");
        message.error({
          content: "Error saving lead",
          duration: 1.5,
          key: messageKey,
        });
      }
    },
    [form, sales, setCreateNew]
  );

  const onFinishFailed = (err) => {
    console.log("err onFinishFailed", err);
    if (
      err &&
      err.errorFields &&
      err.errorFields[0] &&
      err.errorFields[0].name
    ) {
      if (err.errorFields[0].name[0] === "lineItems") {
        const el = document.getElementById("top-of-form");
        el.scrollIntoView({ block: "end", behavior: "smooth" });
      } else {
        form.scrollToField(err.errorFields[0].name[0]);
      }
    }
    message.error("Error creating lead");
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  const onPropertyTypeChange = (value) => {
    setPropertyType(value);
  };

  const updateCords = ({ lat, lng }) => {
    _setLat(lat);
    _setLng(lng);
  };

  const setProjectAddress = ({
    city,
    state,
    postcode,
    county,
    permitAuthority,
    lat,
    lng,
  }) => {
    form.setFieldsValue({
      projectAddressCity: city,
      projectAddressState: state,
      projectAddressZip: postcode,
      projectAddressCounty: county,
      projectAddressPermitAuthority: permitAuthority,
      projectAddressLat: lat,
      projectAddressLng: lng,
    });

    updateCords({
      lat,
      lng,
    });
  };

  const setBillingAddress = ({ city, state, postcode }) => {
    form.setFieldsValue({
      billingAddressCity: city,
      billingAddressState: state,
      billingAddressZip: postcode,
    });
  };

  const copyProjectAddress = async () => {
    try {
      const _projectAddress = await form.getFieldsValue([
        "projectAddressStreet",
        "projectAddressCity",
        "projectAddressState",
        "projectAddressZip",
      ]);

      form.setFieldsValue({
        billingAddressStreet: _projectAddress.projectAddressStreet,
        billingAddressCity: _projectAddress.projectAddressCity,
        billingAddressState: _projectAddress.projectAddressState,
        billingAddressZip: _projectAddress.projectAddressZip,
      });
    } catch (err) {
      console.log("copyProjectAddress err", err);
    }
  };

  const onSalesmenChange = async (value) => {
    try {
      let options = {};
      for (const v of value) {
        options[v] = sales[v];
      }
      setSalesmen(options);

      const _projectManager = await form.getFieldValue("projectManager");
      if (!options[_projectManager]) {
        form.setFieldsValue({
          projectManager: undefined,
        });
      }
    } catch (err) {
      console.log("onSalesmenChange err", err);
    }
  };

  const handleOriginChange = (value) => {
    setSelectedOrigin(value);
  };

  const confirmCancel = () => {
    Modal.confirm({
      icon: <QuestionCircleOutlined />,
      centered: true,
      cancelText: "Cancel",
      okText: "Confirm",
      okButtonProps: { danger: true },
      content: <div>All form data will be lost, reset anyways?</div>,
      onOk() {
        return new Promise((resolve, reject) => {
          handleCancel(() => resolve(true));
        }).catch((err) => console.log("err: ", err));
      },
      onCancel() {},
    });
  };

  // const handleUnqualifiedChange = (e) => {
  //   setUnqualifiedLead(e.target.checked);
  //   setSelectedOrigin("Office");
  //   form.setFieldsValue({
  //     origin: "Office",
  //   });
  // };

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      className="form"
      style={{
        height: "100%",
      }}
    >
      <RemoveAutocomplete />

      {/* {!createNew ? ( */}
      <div
        style={{
          padding: 24,
          height: "100%",
          overflow: "auto",
          display: createNew ? "none" : "block",
        }}
      >
        <DuplicateCheck
          type="Lead"
          bordered
          setCreateNew={setCreateNew}
          setCopyAddress={setCopyAddress}
        />
      </div>
      {/* ) : ( */}
      <FormBody
        id="lead-form-body"
        className="content-inner"
        style={{
          paddingLeft: 24,
          paddingTop: 24,
          paddingRight: 24,
          paddingBottom: 8,
          height: "100%",
          overflow: "auto",
          display: createNew ? "block" : "none",
        }}
      >
        {/* <Form.Item name="unqualifiedLead" valuePropName="checked">
          <Checkbox onChange={handleUnqualifiedChange}>
            Unqualified Lead
          </Checkbox>
        </Form.Item> */}

        <Form.Item
          hidden={unqualifiedLead}
          name="propertyType"
          label="Property Type"
          rules={[
            {
              required: !unqualifiedLead,
              message: "Property type is required",
            },
          ]}
        >
          <PropertyType onChange={onPropertyTypeChange} />
        </Form.Item>

        {propertyType === "New Construction" && <ManageBuilders form={form} />}

        <Form.Item
          name="companyName"
          label="Company Name"
          hidden={unqualifiedLead}
        >
          <InputNoAutoComplete id="companyName" />
        </Form.Item>

        <Form.Item
          name="customerName"
          label="Customer Name"
          rules={[
            {
              required: true,
              message: "Customer name is required",
            },
          ]}
        >
          <InputNoAutoComplete id="customerName" />
        </Form.Item>

        <Form.Item
          hidden={unqualifiedLead}
          name="phone"
          label="Phone"
          rules={[{ required: !unqualifiedLead, message: "Phone is required" }]}
        >
          <Phone autoComplete="lead-phone-input" id="phone" />
        </Form.Item>

        <Form.List name="alternatePhone">
          {(fields, { add, remove }) => {
            const handleAdd = () => {
              add();
            };

            const handleRemove = () => {
              remove(fields.length - 1);
            };

            return (
              <>
                {fields.map((field, i) => (
                  <div key={`${field.key}-altPhone`}>
                    <Form.Item
                      hidden={unqualifiedLead}
                      {...field}
                      name={[field.name, "description"]}
                      fieldKey={[field.fieldKey, "description"]}
                      key={`description${field.fieldKey}`}
                      label={`Alt. Phone Description ${i + 1}`}
                      rules={[
                        {
                          required: !unqualifiedLead,
                          message: "Description is required",
                        },
                      ]}
                    >
                      <InputNoAutoComplete id={[field.name, "description"]} />
                    </Form.Item>

                    <Form.Item
                      hidden={unqualifiedLead}
                      {...field}
                      name={[field.name, "contact"]}
                      label={`Alt. Phone ${i + 1}`}
                      fieldKey={[field.fieldKey, "contact"]}
                      key={`contact${field.fieldKey}`}
                      rules={
                        unqualifiedLead
                          ? []
                          : [
                              { required: true, message: "Phone is required" },
                              {
                                len: 14,
                                message: "Phone must be 10 digits long",
                              },
                            ]
                      }
                    >
                      <Phone
                        autoComplete="edit-detail-field"
                        id={[field.name, "contact"]}
                      />
                    </Form.Item>
                  </div>
                ))}

                {!unqualifiedLead && (
                  <div
                    className={`${styles.altButtonContainer} ${
                      fields.length === 0 ? styles.end : ""
                    }`}
                  >
                    {fields.length > 0 && (
                      <Button type="link" danger onClick={handleRemove}>
                        Remove Alt. Phone
                      </Button>
                    )}

                    <Button type="link" onClick={handleAdd}>
                      Add Alt. Phone
                    </Button>
                  </div>
                )}
              </>
            );
          }}
        </Form.List>

        <Form.Item
          hidden={unqualifiedLead}
          name="email"
          label="Email"
          rules={
            unqualifiedLead
              ? []
              : [{ type: "email", message: "Must be valid email" }]
          }
        >
          <InputNoAutoComplete type="email" id="email" />
        </Form.Item>

        <Form.List name="alternateEmail" hidden={unqualifiedLead}>
          {(fields, { add, remove }) => {
            const handleAdd = () => {
              add();
            };

            const handleRemove = () => {
              remove(fields.length - 1);
            };

            return (
              <>
                {fields.map((field, i) => (
                  <div key={`${field.key}-altEmail`}>
                    <Form.Item
                      hidden={unqualifiedLead}
                      {...field}
                      name={[field.name, "description"]}
                      fieldKey={[field.fieldKey, "description"]}
                      key={`description${field.fieldKey}`}
                      label={`Alt. Email Description ${i + 1}`}
                      rules={[
                        {
                          required: !unqualifiedLead,
                          message: "Description is required",
                        },
                      ]}
                    >
                      <InputNoAutoComplete id={[field.name, "description"]} />
                    </Form.Item>

                    <Form.Item
                      hidden={unqualifiedLead}
                      {...field}
                      name={[field.name, "contact"]}
                      label={`Alt. Email ${i + 1}`}
                      fieldKey={[field.fieldKey, "contact"]}
                      key={`contact${field.fieldKey}`}
                      rules={
                        unqualifiedLead
                          ? []
                          : [
                              { required: true, message: "Email is required" },
                              {
                                type: "email",
                                message: "Must be a valid email",
                              },
                            ]
                      }
                    >
                      <InputNoAutoComplete
                        autoComplete="edit-detail-field"
                        type="email"
                        id={[field.name, "contact"]}
                      />
                    </Form.Item>
                  </div>
                ))}

                {!unqualifiedLead && (
                  <div
                    className={`${styles.altButtonContainer} ${
                      fields.length === 0 ? styles.end : ""
                    }`}
                  >
                    {fields.length > 0 && (
                      <Button type="link" danger onClick={handleRemove}>
                        Remove Alt. Email
                      </Button>
                    )}

                    <Button type="link" onClick={handleAdd}>
                      Add Alt. Email
                    </Button>
                  </div>
                )}
              </>
            );
          }}
        </Form.List>

        {!unqualifiedLead && (
          <h3 style={{ marginBottom: 12 }}>Project Address</h3>
        )}

        <Form.Item
          hidden={unqualifiedLead}
          label="Street"
          name="projectAddressStreet"
          rules={[
            {
              required: !unqualifiedLead,
              message: "Project street is required",
            },
          ]}
        >
          <Address
            autoComplete="projectStreet"
            setAddress={setProjectAddress}
            id="projectAddressStreet"
          />
        </Form.Item>

        <Form.Item
          hidden={unqualifiedLead}
          label="City"
          name="projectAddressCity"
          rules={[
            {
              required: !unqualifiedLead,
              message: "Project city is required",
              type: "string",
            },
          ]}
        >
          <InputNoAutoComplete id="projectAddressCity" />
        </Form.Item>

        <Form.Item
          hidden={unqualifiedLead}
          label="State"
          name="projectAddressState"
          rules={
            unqualifiedLead
              ? []
              : [
                  {
                    required: true,
                    message: "Project state is required",
                    type: "string",
                  },
                  {
                    len: 2,
                    message: "State must be abbreviated",
                  },
                ]
          }
        >
          <InputNoAutoComplete id="projectAddressState" />
        </Form.Item>

        <Form.Item
          hidden={unqualifiedLead}
          label="Zip"
          name="projectAddressZip"
          rules={[
            {
              required: !unqualifiedLead,
              message: "Project zip is required",
              type: "string",
            },
          ]}
        >
          <InputNoAutoComplete type="phone" id="projectAddressZip" />
        </Form.Item>

        {!unqualifiedLead && (
          <Map
            type="project"
            form={form}
            lat={_lat}
            lng={_lng}
            updateCords={updateCords}
          />
        )}

        <Form.Item
          hidden={unqualifiedLead}
          style={{ display: "none" }}
          name="projectAddressCounty"
        >
          <Input autoComplete="projectCounty" type="text" />
        </Form.Item>

        <Form.Item
          hidden={unqualifiedLead}
          style={{ display: "none" }}
          name="projectAddressPermitAuthority"
        >
          <Input autoComplete="projectPermitAuthority" type="text" />
        </Form.Item>

        <Form.Item
          hidden={unqualifiedLead}
          style={{ display: "none" }}
          name="projectAddressLat"
        >
          <Input autoComplete="projectLat" type="text" />
        </Form.Item>

        <Form.Item
          hidden={unqualifiedLead}
          style={{ display: "none" }}
          name="projectAddressLng"
        >
          <Input autoComplete="projectLng" type="text" />
        </Form.Item>

        {!unqualifiedLead && (
          <h3 className={styles.headerWithButton}>
            <span>Billing Address</span>
            <Button
              type="primary"
              size="small"
              className="green"
              onClick={copyProjectAddress}
            >
              Copy Project Address
            </Button>{" "}
          </h3>
        )}

        <Form.Item
          hidden={unqualifiedLead}
          label="Street"
          name="billingAddressStreet"
          rules={[
            {
              required: !unqualifiedLead,
              message: "Billing street is required",
            },
          ]}
        >
          <Address
            autoComplete="billingStreet"
            setAddress={setBillingAddress}
            id="billingAddressStreet"
          />
        </Form.Item>

        <Form.Item
          hidden={unqualifiedLead}
          label="City"
          name="billingAddressCity"
          rules={[
            {
              required: !unqualifiedLead,
              message: "Billing city is required",
              type: "string",
            },
          ]}
        >
          <InputNoAutoComplete id="billingAddressCity" />
        </Form.Item>

        <Form.Item
          hidden={unqualifiedLead}
          label="State"
          name="billingAddressState"
          rules={
            unqualifiedLead
              ? []
              : [
                  {
                    required: true,
                    message: "Billing state is required",
                    type: "string",
                  },
                  {
                    len: 2,
                    message: "State must be abbreviated",
                  },
                ]
          }
        >
          <InputNoAutoComplete id="billingAddressState" />
        </Form.Item>

        <Form.Item
          hidden={unqualifiedLead}
          label="Zip"
          name="billingAddressZip"
          rules={[
            {
              required: !unqualifiedLead,
              message: "Billing zip is required",
              type: "string",
            },
          ]}
        >
          <InputNoAutoComplete type="phone" id="billingAddressZip" />
        </Form.Item>

        <Form.Item
          hidden={unqualifiedLead}
          name="salesmen"
          label="Salesmen"
          rules={[
            {
              required: !unqualifiedLead,
              message: "Must select at least 1 salesman",
              type: "array",
            },
          ]}
        >
          <Salesmen sales={sales} onChange={onSalesmenChange} />
        </Form.Item>

        <Form.Item
          hidden={unqualifiedLead}
          name="projectManager"
          label="Project Owner"
          rules={[
            {
              required: !unqualifiedLead,
              message: "Select a Project Owner",
            },
          ]}
        >
          <ProjectManager sales={salesmen} />
        </Form.Item>

        <Form.Item
          hidden={unqualifiedLead}
          name="scopes"
          label="Scopes"
          rules={[
            {
              required: !unqualifiedLead,
              message: "Must select at least 1 scopes",
              type: "array",
            },
          ]}
        >
          <Scopes id="scopes" />
        </Form.Item>

        <Form.Item
          name="origin"
          label="Origin"
          rules={[
            {
              required: true,
              message: "Select an Origin",
            },
          ]}
        >
          <Select
            options={["Personal", "Office"]}
            id="origin"
            onChange={handleOriginChange}
          />
        </Form.Item>

        {selectedOrigin === "Personal" && (
          <Form.Item
            name="personalReferralName"
            label="Personal Referrer Name"
            rules={[
              {
                required: true,
                message: "Must provide name of person who referred the lead",
              },
            ]}
          >
            <InputNoAutoComplete />
          </Form.Item>
        )}

        {selectedOrigin === "Office" && (
          <Form.Item
            name="campaign"
            label="Campaign"
            rules={[
              {
                required: true,
                message: "Select a Campaign",
              },
            ]}
          >
            <Campaigns />
          </Form.Item>
        )}

        <Form.Item
          hidden={unqualifiedLead}
          name="estimateStart"
          label="Estimate Start"
        >
          <DatePicker
            format="MMMM DD, YYYY h:mm A"
            showTime
            minuteStep={15}
            getPopupContainer={(trigger) =>
              !mobile
                ? trigger.parentNode.parentNode.parentNode.parentNode
                : document.body
            }
            getCalendarContainer={(trigger) =>
              !mobile
                ? trigger.parentNode.parentNode.parentNode.parentNode
                : document.body
            }
            inputReadOnly={true}
          />
        </Form.Item>

        <Form.Item
          name="notes"
          label="Notes"
          rules={[
            {
              required: unqualifiedLead,
              message: "Notes are required for Unqualified Leads",
            },
          ]}
        >
          <Input.TextArea autoComplete="lead-notes" type="text" rows={4} />
        </Form.Item>
      </FormBody>
      {/* )} */}

      {createNew && (
        <FormControls className="sticky-footer">
          <CancelButton handleClick={confirmCancel} title="Reset" />
          <Form.Item style={{ margin: 0 }}>
            <Button
              htmlType="submit"
              type="primary"
              loading={loading}
              disabled={loading || !unsavedChanges}
            >
              Submit
            </Button>
          </Form.Item>
        </FormControls>
      )}
    </Form>
  );
};

export default LeadForm;
