import React from "react";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Card from "antd/es/card";
import Typography from "antd/es/typography";
import Tag from "antd/es/tag";

const ScopeDetails = ({ scopes }) => {
  return (
    <Row gutter={16}>
      <Col xs={24}>
        <Typography.Title level={3}>Scope Details</Typography.Title>
      </Col>

      {scopes.length > 0 ? (
        scopes.map((s) => (
          <Col xs={6} key={s._id}>
            <Card
              size="small"
              className="z-depth-1"
              title={s.name}
              actions={
                s.workOrder && s.workOrder.src
                  ? [
                      <Typography.Link
                        rel="noopener noreferrer"
                        target="_BLANK"
                        href={s.workOrder.src}
                      >
                        View Work Order
                      </Typography.Link>,
                    ]
                  : []
              }
            >
              {s.insurance && (
                <>
                  <Tag color="rgb(255, 165, 0)" style={{ marginRight: 8 }}>
                    Insurance
                  </Tag>
                  {!s.repair && <br />}
                </>
              )}
              {s.repair && (
                <>
                  <Tag color="rgb(255, 165, 0)">Repair</Tag>
                  <br />
                </>
              )}
              {s.workOrder && (
                <>
                  <Typography.Text strong>Manufacturer:</Typography.Text>{" "}
                  <Typography.Text type="secondary">
                    {s.workOrder.manufacturer
                      ? s.workOrder.manufacturer
                      : s.workOrder.shingle
                      ? s.workOrder.shingle[0]
                      : s.workOrder.siding
                      ? s.workOrder.siding[0]
                      : "None provided"}
                  </Typography.Text>
                  <br />
                  <Typography.Text strong>Product:</Typography.Text>{" "}
                  <Typography.Text type="secondary">
                    {s.workOrder.productLine
                      ? s.workOrder.productLine
                      : s.workOrder.shingle
                      ? s.workOrder.shingle[1]
                      : s.workOrder.siding
                      ? s.workOrder.siding[1]
                      : "None provided"}
                  </Typography.Text>
                  <br />
                </>
              )}
            </Card>
          </Col>
        ))
      ) : (
        <Col xs={24}>0 scopes were created for this project</Col>
      )}
    </Row>
  );
};

export default ScopeDetails;
