import React, { memo, useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchTable } from "Actions/tableActions";
import Modal from "antd/es/modal";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import Button from "antd/es/button";
import Typography from "antd/es/typography";
import CancelButton from "Components/CancelButton";
import openInNewTab from "Utils/openInNewTab";
// import ClipboardText from "Icons/ClipboardText";
import api from "API";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";
import currencyFormatter from "Utils/currencyFormatter";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Divider from "antd/es/divider";
import Alert from "antd/es/alert";
import Image from "antd/es/image";
import noImage from "Assets/ina.jpg";
import csv from "Utils/csv";
import message from "antd/es/message";

const MarketingOfficeForms = ({ selected = {}, open, setOpen }) => {
  const dispatch = useDispatch();
  const table = useSelector((state) => state.tableState.table);
  const col = useSelector((state) => state.tableState.col);
  const order = useSelector((state) => state.tableState.order);
  const search = useSelector((state) => state.tableState.search);
  const activeFilter = useSelector((state) => state.tableState.activeFilter);
  const [balance, setBalance] = useState(0);
  const [sufficientBalance, setSufficientBalance] = useState(true);
  const [_totalCost, setTotalCost] = useState(0);

  const fetchBalance = useCallback(async () => {
    try {
      if (selected && selected._userId) {
        const res = await api.get(`/marketing/balance/${selected._userId}`);
        setBalance(res.data.balance);
        if (selected.type === "Gone Fishing") {
          let totalCost = 0;
          for (const item of selected.fishingAddresses) {
            if (item.occurance > 1) {
              totalCost += 1;
            }
          }

          setTotalCost(totalCost);
          if (res.data.balance - totalCost < 1) {
            setSufficientBalance(false);
          }
        }
      }
    } catch (err) {
      console.log("fetchBalance err", err);
    }
  }, [selected]);

  useEffect(() => {
    fetchBalance();
  }, [fetchBalance]);

  const onCancel = () => {
    setOpen(false);
  };

  const openLetterHead = () => {
    openInNewTab(
      "https://docs.google.com/document/d/1lCUuXLQvAmRyhp0boY2kMDNFF3GDQ1l99wpXyHJmVaw/edit?usp=sharing"
    );
  };

  const confirmCompleted = () => {
    Modal.confirm({
      zIndex: 7001,
      icon: <QuestionCircleOutlined />,
      centered: true,
      cancelText: "Cancel",
      okText: "Confirm",
      content: <div>Are you sure? This will mark this item as completed.</div>,
      onOk() {
        return new Promise((resolve, reject) => {
          handleConfirmCompleted(() => resolve(true));
        }).catch((err) => console.log("err: ", err));
      },
      onCancel() {},
    });
  };

  const handleConfirmCompleted = async (cb) => {
    try {
      await api.post("/marketing/completed", {
        _id: selected._id,
        dueDate: selected.dueDate,
        type: selected.type,
        occurance: selected.occurance,
        _userId: selected._userId,
        requestor: selected.requestor,
        customerId: selected.customerId,
      });

      dispatch(
        fetchTable({
          table,
          col,
          order,
          search,
          activeFilter,
          fetching: false,
        })
      );

      cb();
      onCancel();
    } catch (err) {
      console.log("handleConfirmCompleted err", err);
    }
  };

  const confirmDelete = () => {
    Modal.confirm({
      zIndex: 7001,
      icon: <QuestionCircleOutlined />,
      centered: true,
      cancelText: "Cancel",
      okText: "Delete",
      okButtonProps: { danger: true },
      content: <div>Are you sure? This will permanetly delete this item.</div>,
      onOk() {
        return new Promise((resolve, reject) => {
          handleConfirmDelete(() => resolve(true));
        }).catch((err) => console.log("err: ", err));
      },
      onCancel() {},
    });
  };

  const handleConfirmDelete = async (cb) => {
    try {
      await api.delete(`/marketing/${selected._id}`);

      dispatch(
        fetchTable({
          table,
          col,
          order,
          search,
          activeFilter,
          fetching: false,
        })
      );

      cb();
      onCancel();
    } catch (err) {
      console.log("handleConfirmDelete err", err);
    }
  };

  const getCsv = async () => {
    try {
      const list =
        selected.type === "Gone Fishing"
          ? selected.fishingAddresses
          : selected.addresses;

      const response = await api.post("/getCsv", list);
      if (response.data.length > 0) {
        csv(table + ".csv", response.data);
      } else {
        message.error("No data to download");
      }
    } catch (err) {
      console.log("err", err);
      message.error("Error downloading csv");
    }
  };

  return (
    <Modal
      visible={open}
      onCancel={onCancel}
      title={selected ? selected.type : undefined}
      footer={null}
      bodyStyle={{ padding: 0 }}
      centered
    >
      {selected && (
        <FormBody className="content-inner p24">
          <Row>
            {!sufficientBalance && (
              <Col xs={24}>
                <Alert
                  type="error"
                  message={`Insufficient Balance. Total Cost: ${_totalCost}`}
                  style={{ marginBottom: 24 }}
                />
              </Col>
            )}

            <Col xs={12}>
              <Typography.Text strong>Requestor: </Typography.Text>
              <Typography.Text type="secondary">
                {selected.requestor}
              </Typography.Text>
            </Col>
            <Col xs={12}>
              <Typography.Text strong>Current Balance: </Typography.Text>
              <Typography.Text type="secondary">
                {currencyFormatter(balance)}
              </Typography.Text>
            </Col>
          </Row>

          <Divider />

          {selected.type === "Jobsite Target" ? (
            <>
              <Typography.Text strong>Addresses:</Typography.Text>
              <br />
              {/* {selected.addresses.map((a) => (
                <Typography.Paragraph
                  key={a}
                  type="secondary"
                  style={{ margin: 0 }}
                  copyable={{ icon: [<ClipboardText size={18} />] }}
                >
                  {a}
                </Typography.Paragraph>
              ))} */}
              {selected.addresses.map((a) => (
                <Typography.Paragraph
                  key={a._id}
                  type="secondary"
                  style={{ margin: 0 }}
                  // copyable={{ icon: [<ClipboardText size={18} />] }}
                >
                  {`${a.address}, ${a.address2}`}
                </Typography.Paragraph>
              ))}
              <br />
              <Image
                width="100%"
                src={selected.postCard}
                fallback={noImage}
                preview={false}
              />
              <br />
              <br />
            </>
          ) : selected.type === "Gone Fishing" ? (
            <>
              <Typography.Text strong>Address: </Typography.Text>
              {selected.fishingAddresses.map((a) => (
                <Typography.Paragraph
                  key={a._projectId}
                  type="secondary"
                  style={{ margin: 0 }}
                  // copyable={{ icon: [<ClipboardText size={18} />] }}
                >
                  {`${a.address}, ${a.address2}`}
                </Typography.Paragraph>
              ))}
              <br />
              <br />
            </>
          ) : (
            <>
              <Typography.Text strong>Recipient: </Typography.Text>
              <Typography.Text type="secondary">
                {selected.recipient}
              </Typography.Text>
              <br />
              <Typography.Text strong>Address: </Typography.Text>
              <Typography.Text type="secondary">
                {selected.address}
              </Typography.Text>
              <br />
              <Typography.Text strong>General Message: </Typography.Text>
              <Typography.Text type="secondary">
                {selected.generalMessage}
              </Typography.Text>
              <br />
            </>
          )}

          {(selected.type === "Follow Up Letter" ||
            selected.type === "Thank You Letter") && (
            <>
              <br />
              <br />
              <Button
                type="primary"
                className="green"
                block
                onClick={openLetterHead}
              >
                Open Letter Head
              </Button>
            </>
          )}
        </FormBody>
      )}

      <FormControls className="sticky-footer">
        <CancelButton handleClick={onCancel} />
        <Button onClick={confirmDelete} danger>
          Delete
        </Button>

        {((selected && selected.type === "Gone Fishing") ||
          (selected && selected.type === "Jobsite Target")) && (
          <Button onClick={getCsv} type="default" style={{ marginLeft: 8 }}>
            Download List
          </Button>
        )}

        {sufficientBalance && (
          <Button
            onClick={confirmCompleted}
            type="primary"
            style={{ marginLeft: 8 }}
          >
            Completed
          </Button>
        )}
      </FormControls>
    </Modal>
  );
};

export default memo(MarketingOfficeForms);
