import React, { useState } from "react";
import { useSelector } from "react-redux";
import FormBody from "Components/FormBody";
import Typography from "antd/es/typography";
import List from "antd/es/list";
import Button from "antd/es/button";
import DeleteIcon from "Icons/Delete";
import styles from "./styles.module.scss";
import Select from "antd/es/select";
import Input from "antd/es/input";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";
import MarkUpHelper from "Components/MarkUpHelper";
import api from "API";
import CancelButton from "Components/CancelButton";
import Form from "antd/es/form";
import FormControls from "Components/FormControls";
import message from "antd/es/message";
import Switch from "antd/es/switch";

const messageKey = "mapShareKey";

const Share = ({
  handleClose,
  type,
  props,
  unsavedChanges,
  setUnsavedChanges,
}) => {
  const formDetails = useSelector(
    (state) => state.formState.shareReferences.formDetails
  );
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      // message.loading("Sending Email...", 0);
      message.loading({
        content: "Sending email...",
        duration: 0,
        key: messageKey,
      });

      values.type = values.type ? "color" : values.type;
      values.manufacturer = formDetails.manufacturer;
      values.productLine = formDetails.productLine;
      values.selected = props.selected;

      await api.post("/sendReferences", values);

      // message.success("Email sent");
      message.success({
        content: "Email sent",
        duration: 1.5,
        key: messageKey,
      });
      handleClose(true);
    } catch (err) {
      setLoading(false);
      // message.error("Error sending email");
      message.error({
        content: "Error sending email",
        duration: 1.5,
        key: messageKey,
      });
    }
  };

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error sending email");
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  const cancel = () => {
    handleClose();
  };

  return (
    <Form
      form={form}
      className="form"
      autoComplete="off"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      initialValues={{
        subject: formDetails.color
          ? `${formDetails.manufacturer} ${formDetails.productLine} Color References`
          : "Premier Roofing Project References",
      }}
    >
      <FormBody
        className="content-inner"
        style={{
          maxHeight: type === "modal" ? "calc(100vh - 161px)" : "unset",
        }}
      >
        <Typography.Title className={styles.shareHeader} level={3}>
          Share
        </Typography.Title>
        <div>
          <List className={styles.list}>
            {props.selected && props.selected.length > 0 ? (
              props.selected.map((s) => (
                <Project
                  key={s._id}
                  project={s}
                  selected={props.selected}
                  setSelected={formDetails.setSelected}
                  setMarkers={formDetails.setMarkers}
                />
              ))
            ) : (
              <List.Item
                className={styles.listItem}
                style={{ paddingTop: 8, paddingBottom: 8 }}
              >
                <List.Item.Meta title={"No projects have been selected"} />
              </List.Item>
            )}
          </List>
        </div>

        {props.selected && props.selected.length > 0 && (
          <div style={{ padding: 24, paddingBottom: 0 }}>
            <Form.Item
              name="to"
              label="To"
              rules={[
                {
                  required: true,
                  message: "You must include at least one email",
                },
              ]}
            >
              <Select mode="tags" />
            </Form.Item>

            <Form.Item
              name="subject"
              label="Subject"
              rules={[
                {
                  required: true,
                  message: "You must include a subject",
                },
              ]}
            >
              <InputNoAutoComplete id="subject" />
            </Form.Item>

            <Form.Item
              name="body"
              label={<MarkUpHelper title="Body" />}
              rules={[{ required: true, message: "You must include a body" }]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>

            <Form.Item
              label="Reference Type"
              hasFeedback={!formDetails.color}
              help={
                !formDetails.color
                  ? "Filter must be set to send color references"
                  : undefined
              }
              name="type"
              disabled={!formDetails.color}
              valuePropName="checked"
            >
              <Switch
                disabled={!formDetails.color}
                checkedChildren="Color"
                unCheckedChildren="Project"
              />
            </Form.Item>
          </div>
        )}
      </FormBody>
      <FormControls className="sticky-footer">
        <CancelButton handleClick={cancel} />
        {props.selected && props.selected.length > 0 && (
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={loading}
          >
            Send
          </Button>
        )}
      </FormControls>
    </Form>
  );
};

const Project = ({ project, selected, setSelected, setMarkers }) => {
  const removeProject = () => {
    const _filteredSelected = selected.filter((s) => s._id !== project._id);
    setSelected(_filteredSelected);
    setMarkers((_markers) => {
      _markers[project._id].marker.setIcon(
        new window.google.maps.MarkerImage(
          "https://s3.us-east-2.amazonaws.com/pr-crm/assets/blue_marker.png",
          null,
          null,
          null,
          new window.google.maps.Size(24, 36)
        )
      );
      return _markers;
    });
  };

  return (
    <List.Item className={styles.listItem}>
      <List.Item.Meta
        title={
          <span>
            <Button
              className={styles.deleteButton}
              danger
              shape="circle"
              type="text"
              onClick={removeProject}
            >
              <DeleteIcon />
            </Button>{" "}
            <span className="ant-list-item-meta-description">
              {project.projectAddress}
            </span>
          </span>
        }
      />
    </List.Item>
  );
};

export default Share;
