import React from "react";
import styles from "./styles.module.scss";

const FormBody = ({ children, style, className, id = "" }) => {
  return (
    <div
      id={id}
      className={`${styles.body} ${className} form-body`}
      style={style}
    >
      {children}
    </div>
  );
};

export default FormBody;
