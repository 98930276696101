import React, { useEffect, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import Form from "antd/es/form";
import Button from "antd/es/button";
import Drawer from "antd/es/drawer";
import Grid from "antd/es/grid";

import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import ArrowRight from "Icons/ArrowRight";
import styles from "./styles.module.scss";
import message from "antd/es/message";

import Address from "Components/Inputs/Address";
import Divider from "antd/es/divider";
import api from "API";
import Select from "Components/Inputs/Select";
import DatePicker from "Components/Inputs/DatePicker";
import useIsMobile from "Hooks/useIsMobile";
import dayjs from "dayjs";

const { useBreakpoint } = Grid;

const Filter = ({
  visible,
  setVisible,
  currentAddress,
  setCurrentAddress,
  map,
  fetchMarkers,
  manufacturer,
  setManufacturer,
  setProductLine,
  setColor,
}) => {
  const mobile = useIsMobile();
  const screens = useBreakpoint();
  const [form] = Form.useForm();
  const [type, setType] = useState("Roofing - Steep");
  const [shingles, setShingles] = useState([]);
  const [siding, setSiding] = useState([]);
  const [sidingManufacturers, setSidingManufacturers] = useState([]);
  const [roofingManufacturers, setRoofingManufacturers] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [defaultYear] = useState(dayjs().subtract(8, "years"));
  const [products, setProducts] = useState([]);
  const [colors, setColors] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchOptions();
  }, []);

  const fetchOptions = async () => {
    try {
      const res = await api.post("/dropdowns/get", {
        selects: ["Shingles", "Siding"],
      });
      let _manufacturers = [];
      for (const s of res.data.Shingles.options) {
        _manufacturers.push(s.value);
      }
      let _sidingManufacturers = [];
      for (const s of res.data.Siding.options) {
        _sidingManufacturers.push(s.value);
      }
      unstable_batchedUpdates(() => {
        setShingles(res.data.Shingles.options);
        setSiding(res.data.Siding.options);
        setManufacturers(_manufacturers);
        setRoofingManufacturers(_manufacturers);
        setSidingManufacturers(_sidingManufacturers);
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      fetchMarkers(values, () => setLoading(false));
      setVisible(false);
    } catch (err) {
      console.log("err", err);
      message.error("Error filtering map");
    }
  };

  const onFinishFailed = (e) => {
    console.log("e", e);
    message.error("Error filtering map");
  };

  const handleClose = () => {
    setVisible(false);
  };

  const reset = () => {
    form.setFieldsValue({
      manufacturer: undefined,
      productLine: undefined,
      color: undefined,
    });
  };

  const cancel = () => {
    handleClose();
    form.resetFields();
  };

  const handleAddressChange = ({ street, city, state, postcode, lat, lng }) => {
    form.setFieldsValue({
      addressWithoutAutocomplete: `${street}, ${city}, ${state} ${postcode}`,
    });
    setVisible(false);
    map.panTo({
      lat,
      lng,
    });
    map.setZoom(16);

    if (currentAddress) currentAddress.setMap(null);

    const _currentAddress = new window.google.maps.Marker({
      position: { lat, lng },
      map,
      icon: new window.google.maps.MarkerImage(
        "https://s3.us-east-2.amazonaws.com/pr-crm/assets/my_location.png",
        null,
        null,
        null,
        new window.google.maps.Size(24, 36)
      ),
    });

    setCurrentAddress(_currentAddress);
  };

  const handleTypeChange = (e) => {
    if (e) {
      setType(e);
      if (e === "Roofing - Steep") {
        setManufacturers(roofingManufacturers);
      } else {
        setManufacturers(sidingManufacturers);
      }
    }

    setProducts([]);
    setManufacturer(undefined);
    setProductLine(undefined);
    setColor(undefined);
    form.setFieldsValue({
      manufacturer: undefined,
      productLine: undefined,
      color: undefined,
    });
  };

  const handleManufacturerChange = (e) => {
    if (e) {
      const _products = [];
      let obj;
      if (type === "Roofing - Steep") {
        obj = shingles.find((s) => s.value === e);
      } else {
        obj = siding.find((s) => s.value === e);
      }
      const arr = obj.children;
      for (const a of arr) {
        _products.push(a.value);
      }
      setProducts(_products);
      setManufacturer(e);
      setProductLine(undefined);
      setColor(undefined);
      form.setFieldsValue({
        productLine: undefined,
        color: undefined,
      });
    } else {
      setProducts([]);
      setManufacturer(undefined);
      setProductLine(undefined);
      setColor(undefined);
      form.setFieldsValue({
        productLine: undefined,
        color: undefined,
      });
    }
  };

  const handleProductChange = (e) => {
    if (e) {
      const _colors = [];
      let obj1;

      if (type === "Roofing - Steep") {
        obj1 = shingles.find((s) => s.value === manufacturer);
      } else {
        obj1 = siding.find((s) => s.value === manufacturer);
      }

      const obj2 = obj1.children.find((c) => c.value === e);
      const arr = obj2.children;
      for (const a of arr) {
        _colors.push(a.value);
      }
      setColors(_colors);
      setProductLine(e);
      setColor(undefined);
      form.setFieldsValue({
        color: undefined,
      });
    } else {
      setColors([]);
      setColor(undefined);
      setProductLine(undefined);
      form.setFieldsValue({
        color: undefined,
      });
    }
  };

  const handleColorChange = (e) => {
    if (e) {
      setColor(e);
    } else {
      setColor(undefined);
    }
  };

  const disabledDate = (current) => {
    return current < dayjs().subtract(8, "years") || current > dayjs();
  };

  return (
    <Drawer
      className={styles.filterDrawer}
      title={
        <div className={styles.filterHeader}>
          <Button
            style={{ marginLeft: 12 }}
            type="secondary"
            shape="circle"
            ghost
            onClick={handleClose}
          >
            <ArrowRight color="#ffffff" />
          </Button>

          <h2 className={styles.filterTitle}>FILTER</h2>

          <div style={{ margin: "0 12px", width: 32 }}></div>
        </div>
      }
      placement="right"
      closable={false}
      maskClosable={true}
      visible={visible}
      headerStyle={{ padding: 0, borderBottom: "none" }}
      bodyStyle={{ padding: 0 }}
      width={!screens.xs ? 380 : "100%"}
      destroyOnClose
      onClose={cancel}
    >
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className="form"
        style={{
          position: "absolute",
          height: "calc(100% - 64px)",
        }}
        initialValues={{
          year: defaultYear,
        }}
      >
        <FormBody style={{ padding: 24 }}>
          <Form.Item name="addressWithoutAutocomplete" label="Address">
            <Address setAddress={handleAddressChange} />
          </Form.Item>

          <Divider />

          <Form.Item
            name="year"
            label="Year"
            rules={[{ required: true, message: "Year is required" }]}
          >
            <DatePicker
              disabledDate={disabledDate}
              picker="year"
              format="YYYY"
              inputReadOnly={true}
              getCalendarContainer={(trigger) =>
                !mobile ? trigger.parentNode : document.body
              }
            />
          </Form.Item>

          <Form.Item name="type" label="Project Type">
            <Select
              options={["Roofing - Steep", "Siding"]}
              id="type"
              onChange={handleTypeChange}
              value="Roofing - Steep"
            />
          </Form.Item>

          <Form.Item name="manufacturer" label="Manufacturer">
            <Select
              options={manufacturers}
              id="manufacturer"
              onChange={handleManufacturerChange}
            />
          </Form.Item>

          <Form.Item name="productLine" label="Product">
            <Select
              options={products}
              id="productLine"
              onChange={handleProductChange}
            />
          </Form.Item>

          <Form.Item name="color" label="Color">
            <Select options={colors} id="colors" onChange={handleColorChange} />
          </Form.Item>
        </FormBody>
        <FormControls className="sticky-footer">
          <Button
            type="text"
            danger
            style={{ padding: "4px 8px", float: "left" }}
            htmlType="button"
            onClick={reset}
          >
            Reset
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            disabled={loading}
            loading={loading}
          >
            Submit
          </Button>
        </FormControls>
      </Form>
    </Drawer>
  );
};

export default Filter;
