import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { setSelected } from "Actions/dataActions";
import Drawer from "antd/es/drawer";
// import Modal from "antd/es/modal";
import useBack from "Hooks/useBack";

const ViewContainer = ({
  title,
  form,
  visible,
  closable = true,
  mask = true,
  width = 340,
  editing = undefined,
  placement = "bottom",
  // type = "drawer",
  zIndex = 1000,
  // centered = false,
  // modalStyle = {
  //   main: { top: 50, paddingBottom: 0 },
  //   body: { padding: 0, height: "calc(100vh - 100px - 55px)" },
  //   height: "calc(100vh - 100px",
  //   width: "calc(100% - 100px",
  // },
  update = () => {
    return false;
  },
  className = "",
}) => {
  const dispatch = useDispatch();
  const handleClose = useBack();
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [_title, setTitle] = useState(title);
  const Form = form;

  const cancel = useCallback(() => {
    dispatch(setSelected(null));
    handleClose();
  }, [dispatch, handleClose]);

  // return type === "drawer" ? (
  return (
    <Drawer
      placement={placement}
      title={_title}
      destroyOnClose
      visible={visible}
      onClose={cancel}
      height="100%"
      width={width}
      bodyStyle={{ padding: 0 }}
      closable={closable}
      mask={mask}
      zIndex={zIndex}
      className={className}
    >
      <Form
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
        editing={editing}
        handleClose={handleClose}
        update={update}
        setTitle={setTitle}
      />
    </Drawer>
  );
  //  : (
  //   <Modal
  //     title={_title}
  //     destroyOnClose
  //     visible={visible}
  //     onCancel={cancel}
  //     footer={null}
  //     centered={centered}
  //     bodyStyle={modalStyle.body}
  //     width={modalStyle.width}
  //     height={modalStyle.height}
  //     style={modalStyle.main}
  //     zIndex={zIndex}
  //     className={className}
  //   >
  //     <Form
  //       unsavedChanges={unsavedChanges}
  //       setUnsavedChanges={setUnsavedChanges}
  //       editing={editing}
  //       handleClose={handleClose}
  //       update={update}
  //       setTitle={setTitle}
  //     />
  //   </Modal>
  // );
};

export default ViewContainer;
