import React, { useState } from "react";
import Modal from "antd/es/modal";
import Typography from "antd/es/typography";
import Form from "antd/es/form";
import message from "antd/es/message";
import API from "API";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";

const NamingFilterModal = ({ visible, onClose, form, update, table }) => {
  const [loading, setLoading] = useState(false);

  const onOk = async () => {
    try {
      setLoading(true);

      const values = form.getFieldsValue();

      Object.keys(values).forEach((key) =>
        values[key] === undefined || values[key] === null || values[key] === ""
          ? delete values[key]
          : {}
      );

      const res = await API.post(
        `/${table}/saveFilter/${values.filterName}`,
        values
      );

      update(res.data);
      onClose(false);
      setLoading(false);
      form.setFieldsValue({ filterName: undefined });
      message.success("Filter successfully saved");
    } catch (err) {
      console.log("err", err);
      setLoading(false);
      message.error("An error occured while saving your template");
    }
  };

  const handleClose = () => {
    onClose(false);
    form.setFieldsValue({ filterName: undefined });
  };

  return (
    <Modal
      destroyOnClose
      visible={visible}
      onClose={handleClose}
      onOk={onOk}
      okButtonProps={!loading ? {} : { loading: true, disabled: true }}
      onCancel={handleClose}
      centered
      width={300}
      okText="Save"
      cancelButtonProps={{ style: { float: "left" } }}
      zIndex={4001}
    >
      <Typography.Title level={2}>Save Filter</Typography.Title>
      <Form.Item
        label="Filter Name"
        name="filterName"
        rules={[
          {
            required: true,
            message: "Filter name is required",
          },
        ]}
        style={{ marginBottom: 0 }}
      >
        <InputNoAutoComplete autoFocus id="filterName" />
      </Form.Item>
    </Modal>
  );
};

export default NamingFilterModal;
