import React from "react";
import List from "antd/es/list";
import styles from "./styles.module.scss";
import openInNewTab from "Utils/openInNewTab";
import File from "Icons/File";

const Documents = ({ documents }) => {
  return (
    <>
      {documents.length > 0 ? (
        documents.map((document) => (
          <List
            bordered
            key={document._id}
            itemLayout="horizontal"
            className={styles.list}
          >
            <DocumentsItem document={document} />
          </List>
        ))
      ) : (
        <List className={styles.list}>
          <List.Item className={`${styles.transactionList} ${styles.none}`}>
            <List.Item.Meta
              avatar={
                <div className={styles.avatar}>
                  <File color="#ff7056" />
                </div>
              }
              title="No documents have been uploaded"
            />
          </List.Item>
        </List>
      )}
    </>
  );
};

const DocumentsItem = ({ document }) => {
  const openDocument = (e) => {
    e.stopPropagation();
    openInNewTab(document.src);
  };

  return (
    <List.Item className={styles.transactionList}>
      <List.Item.Meta
        avatar={
          <div className={styles.avatar}>
            <File color="#108ee9" />
          </div>
        }
        title={document.documentType}
        onClick={openDocument}
      />
    </List.Item>
  );
};

export default Documents;
