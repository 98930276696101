import OriginTable from "./Table";
import TableHeader from "./TableHeader";
import TableFilter from "./TableFilter";
import TableColumnHeaders from "./TableColumnHeaders";
import TableBody from "./TableBody";

const Table = OriginTable;
Table.Header = TableHeader;
Table.Filter = TableFilter;
Table.ColumnHeaders = TableColumnHeaders;
Table.Body = TableBody;

export default Table;
