import React, { useState, useEffect, useRef } from "react";
import Select from "antd/es/select";
import api from "API";
import useIsMobile from "Hooks/useIsMobile";

const InputExtendedWarranty = ({
  value,
  onChange,
  placeholder = undefined,
  scopeName = undefined,
}) => {
  const mobile = useIsMobile();
  const [options, setOptions] = useState([]);
  const ref = useRef();

  useEffect(() => {
    fetchOptions();
  }, [scopeName]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchOptions = async () => {
    try {
      if (scopeName) {
        const res = await api.post("/dropdowns/get", {
          selects: [`${scopeName} Extended Warranty`],
        });
        setOptions(res.data[`${scopeName} Extended Warranty`].options);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const triggerChange = (changedValue) => {
    if (onChange) {
      onChange(changedValue);
    }
  };

  const handleChange = (values) => {
    ref.current.blur();
    triggerChange(values);
  };

  return (
    <Select
      id="extendedWarranty"
      ref={ref}
      autoComplete="edit-detail-field"
      allowClear
      showSearch={!mobile}
      dropdownClassName={mobile ? "isMobile" : null}
      getPopupContainer={(trigger) =>
        !mobile ? trigger.parentNode : document.body
      }
      onChange={handleChange}
      value={value}
      placeholder={placeholder}
    >
      {options.map((o) => (
        <Select.Option key={o} value={o}>
          {o}
        </Select.Option>
      ))}
    </Select>
  );
};

export default InputExtendedWarranty;
