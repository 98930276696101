import * as actions from "Actions/actionTypes";

const defaultState = {
  openForms: 0,
  activity: { open: false, formDetails: {} },
  comment: { open: false, formDetails: {} },
  reply: { open: false, formDetails: {} },
  materialQuote: { open: false, formDetails: {} },
  phoneBook: { open: false, formDetails: {} },
  requests: { open: false, formDetails: {} },
  email: { open: false, formDetails: {} },
  estimate: { open: false, formDetails: {} },
  leadLost: { open: false, formDetails: {} },
  leadSold: { open: false, formDetails: {} },
  updateScope: { open: false, formDetails: {} },
  createEvent: { open: false, formDetails: {} },
  createLead: { open: false, formDetails: {} },
  createProject: { open: false, formDetails: {} },
  builder: { open: false, formDetails: {} },
  images: { open: false, formDetails: {} },
  workRequest: { open: false, formDetails: {} },
  materialPickup: { open: false, formDetails: {} },
  onHold: { open: false, formDetails: {} },
  projectCancelled: { open: false, formDetails: {} },
  scope: { open: false, formDetails: {} },
  invoice: { open: false, formDetails: {} },
  receipt: { open: false, formDetails: {} },
  completionCertificate: { open: false, formDetails: {} },
  dumping: { open: false, formDetails: {} },
  expense: { open: false, formDetails: {} },
  collection: { open: false, formDetails: {} },
  companyExpense: { open: false, formDetails: {} },
  companyPost: { open: false, formDetails: {} },
  user: { open: false, formDetails: {} },
  action: { open: false, formDetails: {} },
  documentForm: { open: false, formDetails: {} },
  workOrder: { open: false, formDetails: {} },
  orderAerial: { open: false, formDetails: {} },
  aerialVerification: { open: false, formDetails: {} },
  moldReview: { open: false, formDetails: {} },
  permit: { open: false, formDetails: {} },
  permitAuthority: { open: false, formDetails: {} },
  preJobCheck: { open: false, formDetails: {} },
  readyForMaterial: { open: false, formDetails: {} },
  addOfficeDocument: { open: false, formDetails: {} },
  dumpsterEmail: { open: false, formDetails: {} },
  workRequestRoof: { open: false, formDetails: {} },
  rescheduleScope: { open: false, formDetails: {} },
  materialEmail: { open: false, formDetails: {} },
  createAlbum: { open: false, formDetails: {} },
  materialBoardAddColumn: { open: false, formDetails: {} },
  leadTimes: { open: false, formDetails: {} },
  sub: { open: false, formDetails: {} },
  dumpster: { open: false, formDetails: {} },
  supplier: { open: false, formDetails: {} },
  multiDropdown: { open: false, formDetails: {} },
  dropdown: { open: false, formDetails: {} },
  preJobInspection: { open: false, formDetails: {} },
  postJobInspection: { open: false, formDetails: {} },
  installTodo: { open: false, formDetails: {} },
  installTodoDueDate: { open: false, formDetails: {} },
  installTodoAssignee: { open: false, formDetails: {} },
  installTodoChange: { open: false, formDetails: {} },
  projectView: { open: false, formDetails: {} },
  shareReferences: { open: false, formDetails: {} },
  drawActivity: { open: false, formDetails: {} },
  needsBrokedown: { open: false, formDetails: {} },
  officeReviewNote: { open: false, formDetails: {} },
  moldReviewNote: { open: false, formDetails: {} },
  installTodoNonPo: { open: false, formDetails: {} },
  hotArea: { open: false, formDetails: {} },
  jobsiteTarget: { open: false, formDetails: {} },
  followUpLetter: { open: false, formDetails: {} },
  thankYouLetter: { open: false, formDetails: {} },
  marketingActivity: { open: false, formDetails: {} },
  hashtags: { open: false, formDetails: {} },
  projectCloseout: { open: false, formDetails: {} },
  installTodoChangeImage: { open: false, formDetails: {} },
  updateDeliveryDate: { open: false, formDetails: {} },
  scheduleEstimate: { open: false, formDetails: {} },
  changeStatus: { open: false, formDetails: {} },
  onSiteCheck: { open: false, formDetails: {} },
};

const formReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actions.OPEN_FORM:
      return {
        ...state,
        openForms: state.openForms++,
        [action.payload.form]: {
          open: true,
          formDetails: { ...action.payload.formDetails },
        },
      };
    case actions.CLOSE_FORM:
      return {
        ...state,
        [action.payload.form]: { open: false, formDetails: {} },
        openForms: action.payload.openForms,
      };
    case actions.CLOSE_LAST_FORM:
      return {
        ...state,
        [action.payload.form]: { open: false, formDetails: {} },
        openForms: action.payload.openForms,
      };
    case actions.UPDATE_FORM_DETAILS:
      return {
        ...state,
        [action.payload.form]: {
          ...state[action.payload.form],
          formDetails: action.payload.formDetails,
        },
      };
    default:
      return state;
  }
};

export default formReducer;
