import React, { useRef } from "react";
import Select from "antd/es/select";
import useIsMobile from "Hooks/useIsMobile";

const InputSuppliers = ({
  value,
  onChange,
  suppliers,
  placeholder = undefined,
  onClick,
  autoFocus,
}) => {
  const mobile = useIsMobile();
  const ref = useRef();

  const renderSuppliers = Object.keys(suppliers).map((key) => (
    <Select.Option key={suppliers[key]._sub} value={suppliers[key]._sub}>
      {suppliers[key].companyName}
    </Select.Option>
  ));

  const triggerChange = (changedValue) => {
    if (onChange) {
      onChange(changedValue);
    }
  };

  const handleChange = (values) => {
    triggerChange(values);
    ref.current.blur();
  };

  return (
    <Select
      ref={ref}
      autoComplete="dont-use-autocomplete"
      allowClear
      showSearch={!mobile}
      dropdownClassName={mobile ? "isMobile" : null}
      getPopupContainer={(trigger) =>
        !mobile
          ? trigger.parentNode.parentNode.parentNode.parentNode.parentNode
          : document.body
      }
      onChange={handleChange}
      value={value}
      placeholder={placeholder}
      onClick={onClick}
      autoFocus={autoFocus}
    >
      {renderSuppliers}
    </Select>
  );
};

export default InputSuppliers;
