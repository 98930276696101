import * as actions from "Actions/actionTypes";

const defaultState = {
  serviceWorkerInitialized: false,
  serviceWorkerUpdated: false,
  serviceWorkerRegistration: null,
  swUpdating: false,
};

const swReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actions.SW_INIT:
      return {
        ...state,
        serviceWorkerInitialized: true,
        // serviceWorkerRegistration: action.payload,
      };
    case actions.SW_UPDATE:
      return {
        ...state,
        serviceWorkerUpdated: true,
        serviceWorkerRegistration: action.payload,
      };
    case actions.SW_UPDATING:
      return {
        ...state,
        swUpdating: true,
      };
    default:
      return state;
  }
};

export default swReducer;
