import React, { useState, useEffect } from "react";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Card from "antd/es/card";
import useSalesmen from "Hooks/useSalesmen";
import Button from "antd/es/button";
import message from "antd/es/message";
import dayjs from "dayjs";
import api from "API";
import AssignedLeadsChart from "./AssignedLeadsChart.js";

const AssignedLeads = () => {
  const sales = useSalesmen();
  const [assignedLeadsSeries, setAssignedLeadsSeries] = useState([]);

  useEffect(() => {
    getAssignedLeads();
  }, []);

  const getAssignedLeads = async () => {
    try {
      const end = dayjs();
      const start = end.subtract(7, "days");

      const res = await api.post("/reports/assigned-leads", {
        start,
        end,
      });

      setAssignedLeadsSeries(res.data.series);
    } catch (err) {
      console.log("err", err);
      message.error("Error getting report");
    }
  };

  function triggerUpdate() {
    getAssignedLeads();
  }

  return (
    <div style={{ padding: 12, height: "100%", overflow: "auto" }}>
      <Row gutter={16} style={{ height: "100%" }}>
        <Col xs={24} sm={24} md={8} style={{ marginBottom: 12 }}>
          <Card
            bordered={false}
            size="small"
            style={{ height: "100%" }}
            className="z-depth-1"
            title="Estimators"
          >
            {Object.entries(sales).map((estimator) => (
              <EstimatorRow
                key={`${estimator[1]._user}`}
                estimator={estimator[1]}
                triggerUpdate={triggerUpdate}
              />
            ))}
          </Card>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={16}
          style={{ height: "100%", marginBottom: 12 }}
        >
          <Card
            bordered={false}
            size="small"
            style={{ height: "100%" }}
            bodyStyle={{
              height: "calc(100% - 39px)",
              width: "calc(100% - 12px)",
            }}
            className="z-depth-1"
            title="Leads Assigned Last 7 Days"
          >
            <AssignedLeadsChart series={assignedLeadsSeries} />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const EstimatorRow = ({ estimator, triggerUpdate }) => {
  const [addLoading, setAddLoading] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);

  async function addLead() {
    try {
      setAddLoading(true);
      await api.post("/reports/add-assigned-lead", {
        _user: estimator._user,
        name: estimator.name,
      });
      await triggerUpdate();
      setAddLoading(false);
    } catch (err) {
      console.log("addLead err", err);
      message.error("Error adding lead count");
      setAddLoading(false);
    }
  }

  async function removeLead() {
    try {
      setRemoveLoading(true);
      await api.post("/reports/remove-assigned-lead", {
        _user: estimator._user,
      });

      await triggerUpdate();
      setRemoveLoading(false);
    } catch (err) {
      console.log("removeLead err", err);
      message.error("Error removing lead count");
      setRemoveLoading(false);
    }
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 8,
      }}
    >
      <div style={{ flexGrow: 1 }}>{estimator.name}</div>
      <Button
        type="primary"
        onClick={addLead}
        loading={addLoading}
        disabled={addLoading}
      >
        +
      </Button>
      <Button
        type="primary"
        danger
        onClick={removeLead}
        style={{ marginLeft: 12 }}
        loading={removeLoading}
        disabled={removeLoading}
      >
        -
      </Button>
    </div>
  );
};

export default AssignedLeads;
