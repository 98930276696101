import dayjs from "dayjs";
import HighCharts from "highcharts";
import HighChartsReact from "highcharts-react-official";

import React, { useState, useEffect } from "react";
import Row from "antd/es/row";
import Col from "antd/es/col";
import message from "antd/es/message";
import DatePicker from "Components/Inputs/DatePicker";
import List from "antd/es/list";
import Card from "antd/es/card";
import api from "API";
import currencyFormatter from "Utils/currencyFormatter";
import percentFormatter from "Utils/percentFormatter";
import styles from "./styles.module.scss";

window.Highcharts = HighCharts;

const { RangePicker } = DatePicker;

const today = dayjs();
const year = new Date().getFullYear();
let yearStart = new Date(`1/1/${year}`);
yearStart = dayjs(yearStart);

HighCharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
  colors: [
    "#00c292",
    "#fec107",
    "#FF7056",
    "#343a40",
    "#01c0c8",
    "#999999",
    "#FF9655",
    "#FFF263",
    "#6AF9C4",
  ],
});

const GrossCollectionBreakdown = () => {
  const [state, setState] = useState({
    value: true,
    currentValue: true,
    end: today,
    start: yearStart,
    totalValue: 0,
    table: [],
    options: {
      chart: {
        type: "pie",
      },
      credits: {
        enabled: false,
      },
      title: {
        text: "",
      },
      subtitle: {
        text: "",
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        useHTML: true,
        formatter: function () {
          let table = "<table><tr><td><b>" + this.key + "</b></tr>";
          table +=
            "<tr><td>$" + HighCharts.numberFormat(this.y, 2) + "</td></tr>";
          table += `</table>`;
          return table;
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: true,
            formatter: function () {
              return this.key + ": $" + HighCharts.numberFormat(this.y, 2);
            },
          },
        },
      },
      series: [
        {
          data: [],
        },
      ],
    },
  });

  const { value, start, end } = state;

  useEffect(() => {
    getReport();
  }, [value, start, end]); // eslint-disable-line react-hooks/exhaustive-deps

  const getReport = async () => {
    try {
      const res = await api.post("/reports/grossCollectionsBreakdown", {
        value,
        start,
        end,
      });

      const table = [];
      for (const x of res.data.data) {
        let obj = {};
        obj.name = x[0];
        obj.total = x[1];
        table.push(obj);
      }

      table.map((t, i) => {
        t.percent = res.data.percents[i][0];
        return t;
      });

      setState((thisState) => {
        return {
          ...thisState,
          table,
          totalValue: res.data.totalValue,
          currentValue: thisState.value,
          options: {
            ...thisState.options,
            series: [
              {
                data: res.data.data,
              },
            ],
          },
        };
      });
    } catch (err) {
      console.log("err", err);
      message.error("Error getting report");
    }
  };

  const handleDateChange = (e) => {
    setState((thisState) => {
      return {
        ...thisState,
        start: e[0],
        end: e[1],
      };
    });
  };

  return (
    <div style={{ paddingBottom: 24 }} className="content-inner">
      <Card bordered={false} className="z-depth-1" style={{ margin: 24 }}>
        <Row gutter={16} style={{ marginBottom: 24 }}>
          <Col xs={24}>
            <div className={styles.reportTitle}>GROSS COLLECTION BREAKDOWN</div>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginBottom: 24 }}>
          <Col xs={24}>
            <div className={styles.reportHeader}>
              <div className={styles.total}>
                {currencyFormatter(state.totalValue)}
                <small> TOTAL VAULE</small>
              </div>
            </div>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginBottom: 24 }}>
          <Col xs={24}>
            <div className={styles.reportHeader}>
              <div>
                <div className="ant-form-item-label">
                  <label title="Date Range">Date Range</label>
                </div>
                <RangePicker
                  allowClear={false}
                  format="MMMM DD, YYYY"
                  defaultValue={[
                    dayjs(state.start, "MMMM DD, YYYY"),
                    dayjs(state.end, "MMMM DD, YYYY"),
                  ]}
                  onChange={handleDateChange}
                  ranges={{
                    "Last 7 Days": [
                      dayjs().subtract(7, "days").startOf("day"),
                      dayjs(),
                    ],
                    "Last Week": [
                      dayjs().startOf("week").subtract(7, "days"),
                      dayjs().startOf("week").subtract(1, "day").endOf("day"),
                    ],
                    "Last Month": [
                      dayjs().startOf("month").subtract(1, "months"),
                      dayjs()
                        .startOf("month")
                        .subtract(1, "months")
                        .endOf("month"),
                    ],
                    "This Month": [
                      dayjs().startOf("month"),
                      dayjs().endOf("month"),
                    ],
                    "This Year": [dayjs().startOf("year"), dayjs()],
                    "Last Year": [
                      dayjs().subtract(1, "year").startOf("year"),
                      dayjs().subtract(1, "year").endOf("year"),
                    ],
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
        <HighChartsReact
          highcharts={HighCharts}
          options={state.options}
          updateArgs={[true, true, true]}
        />
      </Card>

      <Card
        bordered={false}
        className="z-depth-1"
        style={{
          marginTop: 24,
          marginLeft: 24,
          marginRight: 24,
        }}
        bodyStyle={{
          padding: 0,
        }}
      >
        <List>
          <List.Item className={styles.listHeader}>
            <List.Item.Meta title="Property Type" />
            <List.Item.Meta title="Percentage" className={styles.listRight} />
            <List.Item.Meta title="Value" className={styles.listRight} />
          </List.Item>

          {state.table.map((t, i) => {
            return (
              <List.Item key={t.name + i} className={styles.listItem}>
                <List.Item.Meta title={t.name} />
                <List.Item.Meta
                  title={percentFormatter(t.percent)}
                  className={styles.listRight}
                />
                <List.Item.Meta
                  title={currencyFormatter(t.total)}
                  className={styles.listRight}
                />
              </List.Item>
            );
          })}

          <List.Item className={styles.listTotal}>
            <List.Item.Meta title="Total" />
            <List.Item.Meta
              title={percentFormatter(100)}
              className={styles.listRight}
            />
            <List.Item.Meta
              className={styles.listRight}
              title={currencyFormatter(state.totalValue)}
            />
          </List.Item>
        </List>
      </Card>
    </div>
  );
};

export default GrossCollectionBreakdown;
