import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { openImageViewer } from "Actions/dataActions";
import Card from "antd/es/card";
import pdf from "Icons/file-icons/PDF-Basic.svg";
import styles from "./styles.module.scss";
import isPdf from "Utils/isPdf";
import openInNewTab from "Utils/openInNewTab";
import LazyLoad from "Components/LazyLoad";
import hidePdfControls from "Utils/hidePdfControls";
import ImageFallback from "Components/images/Image";

const Document = ({ media, scrollContainer }) => {
  const dispatch = useDispatch();

  const type = isPdf(media.src) ? "pdf" : "image";

  const handlePdfClick = () => {
    openInNewTab(media.src);
  };

  const handleImageOrVideoClick = useCallback(() => {
    media.viewOnly = true;
    media.link = true;
    dispatch(openImageViewer(media));
  }, [media, dispatch]);

  return (
    <Card
      bordered={false}
      hoverable
      className={styles.activity}
      bodyStyle={{ padding: 0 }}
      style={{ padding: 0 }}
      cover={
        <>
          <div
            className={styles.clickOverlay}
            onClick={type === "pdf" ? handlePdfClick : handleImageOrVideoClick}
          />
          {type === "pdf" ? (
            <LazyLoad
              offsetHorizontal={600}
              height="240px"
              root={scrollContainer}
            >
              <object data={hidePdfControls(media.src)} className={styles.pdf}>
                <div>
                  <img src={pdf} alt="pdf-icon" />
                  <div>{media.name}</div>
                </div>
              </object>
            </LazyLoad>
          ) : (
            <LazyLoad
              offsetHorizontal={600}
              height="240px"
              root={scrollContainer}
            >
              <ImageFallback alt="needs-brokedown" image={media} />
            </LazyLoad>
          )}
        </>
      }
    ></Card>
  );
};

export default Document;
