import React, { memo } from "react";
import currencyFormatter from "Utils/currencyFormatter";
import Row from "antd/es/row";
import Col from "antd/es/col";
import styles from "./styles.module.scss";

const MoldBreakdownPanel = ({
  type = "breakdown",
  price,
  materials,
  overhead,
  labor,
  dumping,
  permit,
  extras,
  gutters = 0,
  insulation = 0,
  balance,
  isProjectManager,
}) => {
  return (
    <>
      {isProjectManager ? (
        <div className={styles.moldContainer}>
          <Row gutter={16}>
            <Col xs={12}>{type === "mold" ? "Expected Labor:" : "Labor:"}</Col>
            <Col xs={12} className={styles.moldRow}>
              {currencyFormatter(labor)}
            </Col>
          </Row>
        </div>
      ) : (
        <>
          <div className={styles.moldContainer}>
            <Row gutter={16}>
              <Col xs={12}>
                {type === "mold" ? "Contract Price:" : "Total Collected:"}
              </Col>
              <Col xs={12} className={styles.moldRow}>
                {currencyFormatter(price)}
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={12}>
                {type === "mold" ? "Expected Materials:" : "Materials:"}
              </Col>
              <Col xs={12} className={styles.moldRow}>
                {currencyFormatter(materials)}
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={12}>
                {type === "mold" ? "Expected Overhead:" : "Overhead:"}
              </Col>
              <Col xs={12} className={styles.moldRow}>
                {currencyFormatter(overhead)}
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={12}>
                {type === "mold" ? "Expected Labor:" : "Labor:"}
              </Col>
              <Col xs={12} className={styles.moldRow}>
                {currencyFormatter(labor)}
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={12}>
                {type === "mold" ? "Expected Dumping:" : "Dumping:"}
              </Col>
              <Col xs={12} className={styles.moldRow}>
                {currencyFormatter(dumping)}
              </Col>
            </Row>
            {permit || permit === 0 ? (
              <Row gutter={16}>
                <Col xs={12}>Permits</Col>
                <Col xs={12} className={styles.moldRow}>
                  {currencyFormatter(permit)}
                </Col>
              </Row>
            ) : null}
            <Row gutter={16}>
              <Col xs={12}>
                {type === "mold" ? "Expected Extras:" : "Extras:"}
              </Col>
              <Col xs={12} className={styles.moldRow}>
                {currencyFormatter(extras)}
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={12}>
                {type === "mold" ? "Expected Gutters:" : "Gutters:"}
              </Col>
              <Col xs={12} className={styles.moldRow}>
                {currencyFormatter(gutters)}
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={12}>
                {type === "mold" ? "Expected Insulation:" : "Insulation:"}
              </Col>
              <Col xs={12} className={styles.moldRow}>
                {currencyFormatter(insulation)}
              </Col>
            </Row>
          </div>
          <Row className={styles.totalRow}>
            <Col xs={12}>
              {type === "mold" ? "Expected Balance:" : "Balance:"}
            </Col>
            <Col xs={12} style={{ textAlign: "right" }}>
              {currencyFormatter(balance)}
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default memo(MoldBreakdownPanel);
