import React, { useEffect } from "react";
import Permits from "Components/Inputs/Permits";
import DatePicker from "Components/Inputs/DatePicker";
import ProjectManager from "Components/Inputs/ProjectManager";
import Form from "antd/es/form";
import Checkbox from "antd/es/checkbox";
import Input from "antd/es/input";
import useIsMobile from "Hooks/useIsMobile";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";

const Details = ({ sales, projectManager, propertyType, form }) => {
  const mobile = useIsMobile();

  useEffect(() => {
    if (propertyType === "New Construction") {
      form.setFieldsValue({
        permits: ["N/A"],
      });
    }
  }, [propertyType]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Form.Item name="mccEligible" valuePropName="checked">
        <Checkbox>MyCo Contact Elibile</Checkbox>
      </Form.Item>

      <Form.Item
        label="Contract Date"
        name="contractDate"
        rules={[{ required: true, message: "Contract date is required" }]}
      >
        <DatePicker
          autoFocus
          format="MMMM DD, YYYY"
          getPopupContainer={(trigger) =>
            !mobile
              ? trigger.parentNode.parentNode.parentNode.parentNode
              : document.body
          }
          getCalendarContainer={(trigger) =>
            !mobile
              ? trigger.parentNode.parentNode.parentNode.parentNode
              : document.body
          }
          inputReadOnly={true}
        />
      </Form.Item>

      <Form.Item label="Claim Number" name="claimNumber">
        <InputNoAutoComplete id="claimNumber" />
      </Form.Item>

      <Form.Item
        label="Project Owner"
        name="projectManager"
        rules={[{ required: true, message: "Project Owner is required" }]}
        hidden={projectManager}
      >
        <ProjectManager sales={sales} defaultOpen={false} />
      </Form.Item>

      {propertyType === "New Construction" && (
        <Form.Item
          valuePropName="checked"
          name="builderPermits"
          rules={[
            {
              required: true,
              message:
                "New Construction requires that all permits are pulled by the builder",
            },
          ]}
        >
          <Checkbox>Builder is responsible for all permits</Checkbox>
        </Form.Item>
      )}

      <Form.Item
        hidden={propertyType === "New Construction"}
        label="Permits Required"
        name="permits"
        rules={[
          { required: true, message: "At lease one Option must be selected" },
        ]}
      >
        <Permits defaultOpen={false} />
      </Form.Item>

      <Form.Item
        label="Project Description"
        name="projectDescription"
        rules={[{ required: true, message: "Project description required" }]}
      >
        <Input.TextArea autosize={{ minRows: 4 }} />
      </Form.Item>
    </>
  );
};

export default Details;
