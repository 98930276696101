import React, { useState, useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  updateSelectedActivity,
  fetchActivities,
  updateFilteredActivities,
} from "Actions/dataActions";
import { useDispatch, useSelector } from "react-redux";
import { openForm } from "Actions/formActions";
import Button from "antd/es/button";
import Select from "antd/es/select";
import Activity from "./Activity";
import styles from "./styles.module.scss";
import ImagesDrawer from "Components/images/ImagesDrawer";
import useViewShow from "Hooks/useViewShow";
import useIsMobile from "Hooks/useIsMobile";
import Image from "Icons/Image";
import ViewAllMedia from "./ViewAllMedia";
import ActionButtons from "Components/Details/ActionButtons";
import LegacyFiles from "./LegacyFiles";
import Add from "Icons/Add";
import Form from "antd/es/form";

import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "Components/Loader";
import Card from "antd/es/card";
import FormBody from "Components/FormBody";

const Activities = () => {
  const activities = useSelector((state) => state.dataState.activities);
  const filteredActivities = useSelector(
    (state) => state.dataState.filteredActivities
  );
  const viewableActivities = useSelector(
    (state) => state.dataState.viewableActivities
  );
  const activitiesLength = useSelector(
    (state) => state.dataState.activitiesLength
  );
  const allMedia = useSelector((state) => state.dataState.allMedia);
  const hashtags = useSelector((state) => state.dataState.hashtags);
  const legacyFiles = useSelector((state) => state.dataState.legacyFiles);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const mobile = useIsMobile();
  const [filter, setFilter] = useState(undefined);
  const viewImages = useViewShow(5, "images");
  const viewMedia = useViewShow(5, "media");
  const viewLegacyFiles = useViewShow(5, "legacy-files");

  const viewDashboardImages = useViewShow(6, "images");
  const viewDashboardMedia = useViewShow(6, "media");
  const viewDashboardLegacyFiles = useViewShow(6, "legacy-files");
  const viewRoofingProcessImages = useViewShow(7, "images");
  const viewRoofingProcessMedia = useViewShow(7, "media");
  const viewRoofingProcessLegacyFiles = useViewShow(7, "legacy-files");

  const [form] = Form.useForm();

  useEffect(() => {
    let timer;
    if (location.search) {
      timer = setTimeout(() => {
        const index = location.search.substr(1);
        const el = document.getElementById(`${index}`);
        if (el) {
          el.scrollIntoView(true);
        }
      }, 100);
    }

    let thisActivity;
    const locArr = location.pathname.split("/");
    if (
      (location.pathname.includes("dashboard") ||
        location.pathname.includes("notifications") ||
        location.pathname.includes("feed")) &&
      !location.pathname.includes("projects") &&
      !location.pathname.includes("leads")
    ) {
      const thisId = locArr[7];
      thisActivity = filteredActivities.find((a) => a._id === thisId);
      dispatch(updateSelectedActivity(thisActivity));
    } else {
      const thisId = locArr[6];
      thisActivity = filteredActivities.find((a) => a._id === thisId);
      dispatch(updateSelectedActivity(thisActivity));
    }

    return () => {
      if (location.search) clearTimeout(timer);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onChange = useCallback(
    (e) => {
      if (e === "REMOVE FILTER") {
        setFilter(undefined);
        form.setFieldsValue({
          hashtags: undefined,
        });
        dispatch(
          updateFilteredActivities({
            activities,
            filter: "undefined",
          })
        );
      } else {
        setFilter(e);
        dispatch(
          updateFilteredActivities({
            activities,
            filter: e,
          })
        );
      }
    },
    [form, dispatch, activities]
  );

  const openMedia = () => {
    navigate(`${location.pathname}/media`);
  };

  const openActivity = () => {
    dispatch(openForm("activity"));
  };

  const rowRenderer = () => {
    return viewableActivities.map((fp) => {
      return (
        <div id={fp._id} key={fp._id}>
          <Activity activity={fp} scrollContainer={"#activity-container"} />
        </div>
      );
    });
  };

  const fetchMoreActivities = useCallback(() => {
    dispatch(
      fetchActivities({
        start: viewableActivities.length,
        filteredActivities,
        viewableActivities,
      })
    );
  }, [dispatch, viewableActivities, filteredActivities]);

  return (
    <div
      style={{
        height: "calc(100% - 64px - 34px)",
        position: "fixed",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <div
        id="activity-container"
        className={`${styles.container} content-inner`}
      >
        <ActionButtons ghost narrow />
        <div style={{ marginBottom: 12 }}>
          <Form
            form={form}
            autoComplete="off"
            style={{
              width: "calc(100% - 48px - 48px)",
              display: "inline-block",
              marginRight: 8,
            }}
          >
            <Form.Item name="hashtags" label="">
              <Select
                autoComplete="edit-detail-field"
                allowClear
                showSearch={!mobile}
                dropdownClassName={mobile ? "isMobile" : null}
                getPopupContainer={(trigger) =>
                  !mobile ? trigger.parentNode : document.body
                }
                placeholder="Search Hashtags..."
                onChange={onChange}
                className={styles.hashtagSearch}
                size="large"
              >
                {filter && (
                  <Select.Option
                    style={{
                      fontWeight: "bold",
                      borderBottom: "1px solid #f0f0f0",
                      textAlign: "center",
                    }}
                    key="remove-filter"
                    value="REMOVE FILTER"
                  >
                    REMOVE FILTER
                  </Select.Option>
                )}
                {hashtags.map((h) => (
                  <Select.Option key={h} value={h}>
                    {h}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
          <Button
            className="z-depth-1"
            size="large"
            shape="circle"
            type="default"
            onClick={openActivity}
            style={{ marginRight: 8 }}
          >
            <Add />
          </Button>
          <Button
            className="z-depth-1"
            size="large"
            shape="circle"
            type="default"
            onClick={openMedia}
          >
            <Image />
          </Button>
        </div>

        {legacyFiles && legacyFiles.length > 0 && (
          <LegacyFiles
            legacyFiles={legacyFiles}
            scrollContainer={"#activity-container"}
          />
        )}

        {viewableActivities.length > 0 ? (
          <InfiniteScroll
            dataLength={viewableActivities.length} //This is important field to render the next data
            next={fetchMoreActivities}
            hasMore={viewableActivities.length !== activitiesLength}
            loader={
              <div style={{ width: "100%" }}>
                <Loader minHeight="40px" />
              </div>
            }
            endMessage={null}
            scrollableTarget="activity-container"
          >
            {rowRenderer()}
          </InfiniteScroll>
        ) : (
          <Card
            className={styles.activity}
            bodyStyle={{ padding: 0, borderRadius: 4 }}
          >
            <FormBody className={styles.body} style={{ padding: 12 }}>
              <div className={styles.content}>No posts the last 7 days</div>
            </FormBody>
          </Card>
        )}

        <ImagesDrawer
          visible={
            viewImages || viewDashboardImages || viewRoofingProcessImages
          }
        />

        <ImagesDrawer
          legacy
          visible={
            viewLegacyFiles ||
            viewDashboardLegacyFiles ||
            viewRoofingProcessLegacyFiles
          }
        />

        <ViewAllMedia
          visible={viewMedia || viewDashboardMedia || viewRoofingProcessMedia}
          allMedia={allMedia}
        />
      </div>
    </div>
  );
};

export default Activities;
