import React, { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openForm } from "Actions/formActions";
import { setSelected } from "Actions/dataActions";
import List from "antd/es/list";
import Tag from "antd/es/tag";
import dayjs from "dayjs";
import styles from "./styles.module.scss";
import stagesObj from "Constants/stages";
import Button from "antd/es/button";
import Chat from "Icons/Chat";

const OnSiteCheckItem = ({ item, style, mobile, headers }) => {
  const selectedRow = useSelector((state) => state.dataState.selectedRow);
  const dispatch = useDispatch();

  const renderScope = () => {
    return item.workOrder.scopes.map((s, i) => {
      if (s) {
        return (
          <Tag
            key={s.toString() + i}
            className={styles.scopeBadge}
            color={s.color}
          >
            {s.initials}
          </Tag>
        );
      } else {
        return null;
      }
    });
  };

  const onClick = useCallback(() => {
    dispatch(setSelected(item._id));
    dispatch(openForm("onSiteCheck", item));
  }, [item, dispatch]);

  const openNote = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(
        openForm("officeReviewNote", {
          _scopeId: item._id,
          note: item.officeReviewNote,
          customerId: item.customerId,
        })
      );
    },
    [dispatch, item]
  );

  if (!mobile) {
    return (
      <List.Item
        style={style}
        className={`${styles.row} ${
          selectedRow === item._id ? styles.selected : ""
        } ${item.workOrder.schedule === "Urgent" ? styles.urgent : ""}`}
        onClick={onClick}
      >
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[0].width,
            textAlign: headers[0].align ? headers[0].align : "left",
          }}
          description={item.customerId}
        />
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[1].width,
            textAlign: headers[1].align ? headers[1].align : "left",
          }}
          description={item.customerName}
        />
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[2].width,
            textAlign: headers[2].align ? headers[2].align : "left",
          }}
          description={
            <Tag className={styles.statusTag} color={stagesObj[item.stage]}>
              {item.stage}
            </Tag>
          }
        />
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[3].width,
            textAlign: headers[3].align ? headers[3].align : "left",
          }}
          description={item.workOrder.projectAddress}
        />
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[4].width,
            textAlign: headers[4].align ? headers[4].align : "left",
          }}
          description={renderScope()}
        />
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[5].width,
            textAlign: headers[5].align ? headers[5].align : "left",
          }}
          description={
            <Button
              size="small"
              type={
                item.officeReviewNote && item.officeReviewNote.length > 0
                  ? "primary"
                  : "default"
              }
              onClick={openNote}
            >
              <Chat size={18} />
            </Button>
          }
        />
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[6].width,
            textAlign: headers[6].align ? headers[6].align : "left",
          }}
          description={dayjs(item.workOrder.dateSold).format("MMM D, YYYY")}
        />
      </List.Item>
    );
  } else {
    return (
      <List.Item
        style={style}
        className={`${styles.mobileListItem} ${
          selectedRow === item._id ? styles.selected : ""
        } ${item.workOrder.schedule === "Urgent" ? styles.urgent : ""}`}
        onClick={onClick}
      >
        <List.Item.Meta className={styles.customerId} title={item.customerId} />
        <List.Item.Meta
          className={styles.status}
          description={
            <Tag className={styles.statusTag} color={stagesObj[item.stage]}>
              {item.stage}
            </Tag>
          }
        />
        <List.Item.Meta
          className={styles.date}
          description={dayjs(item.workOrder.dateSold).format("MMM D, YY")}
        />
        <div className={styles.break} />
        <List.Item.Meta
          className={styles.fullLine}
          style={{ flex: "1 1 100%", paddingBottom: 3 }}
          description={item.customerName}
        />
        <div className={styles.break} />
        <List.Item.Meta
          className={styles.fullLine}
          description={item.workOrder.projectAddress}
        />
        <div className={styles.break} />
        <List.Item.Meta className={styles.scopes} description={renderScope()} />
        <List.Item.Meta
          className={styles.date}
          description={
            <Button
              size="small"
              type={
                item.officeReviewNote && item.officeReviewNote.length > 0
                  ? "primary"
                  : "default"
              }
              onClick={openNote}
            >
              <Chat size={18} />
            </Button>
          }
        />
      </List.Item>
    );
  }
};

export default memo(OnSiteCheckItem);
