import React, { useEffect, useState, memo } from "react";
import Loader from "Components/Loader";
import { ResizableBox } from "react-resizable";
import useIsMounted from "Hooks/useIsMounted";
import ArrowSplitVertical from "Icons/ArrowSplitVertical";
import styles from "./styles.module.scss";

const Map = ({
  lat = 42.963795,
  lng = -85.670006,
  form,
  type,
  readOnly,
  updateCords,
}) => {
  const _isMounted = useIsMounted();
  const [editMap, setEditMap] = useState();
  const [marker, setMarker] = useState();
  const [eventListner, setEventListner] = useState(); // eslint-disable-line
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const _editMap = new window.google.maps.Map(
      document.getElementById("editMap"),
      {
        center: { lat, lng },
        zoom: 19,
        gestureHandling: "cooperative",
        disableDefaultUI: true,
        fullscreenControl: true,
        streetViewControl: false,
        mapTypeControl: false,
        zoomControl: false,
        defaultMapTypeId: "hybrid",
        mapTypeId: "hybrid",
        tilt: 0,
      }
    );

    setEditMap(_editMap);
    setMarker(
      new window.google.maps.Marker({
        position: { lat, lng },
        map: _editMap,
        draggable: false,
        animation: window.google.maps.Animation.DROP,
        icon: new window.google.maps.MarkerImage(
          "https://s3.us-east-2.amazonaws.com/pr-crm/assets/blue_marker.png",
          null,
          null,
          null,
          new window.google.maps.Size(24, 36)
        ),
      })
    );

    _editMap.panTo({
      lat,
      lng,
    });
    _editMap.setZoom(19);

    const timer = setTimeout(() => {
      setLoaded(true);
    }, 150);

    return () => {
      clearTimeout(timer);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (editMap && lat && lng && _isMounted) {
      if (marker) {
        marker.setMap(null);
      }

      setMarker(
        new window.google.maps.Marker({
          position: { lat, lng },
          map: editMap,
          draggable: false,
          animation: window.google.maps.Animation.DROP,
          icon: new window.google.maps.MarkerImage(
            "https://s3.us-east-2.amazonaws.com/pr-crm/assets/blue_marker.png",
            null,
            null,
            null,
            new window.google.maps.Size(24, 36)
          ),
        })
      );

      editMap.panTo({
        lat,
        lng,
      });
      editMap.setZoom(19);
    }
  }, [editMap, lat, lng, _isMounted]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!readOnly && editMap && _isMounted) {
      setEventListner(
        window.google.maps.event.addListener(editMap, "click", (event) => {
          if (marker) {
            marker.setMap(null);
          }

          form.setFieldsValue({
            [`${type}AddressLat`]: event.latLng.lat(),
            [`${type}AddressLng`]: event.latLng.lng(),
          });

          updateCords({
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
          });
        })
      );
    }
  }, [editMap, _isMounted]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ResizableBox
      className="map-container"
      height={160}
      width={Infinity}
      handleSize={[24, 24]}
      minConstraints={[Infinity, 160]}
      maxConstraints={[Infinity, Infinity]}
      axis="y"
      handle={
        <div className={styles.resizeHandle}>
          <ArrowSplitVertical />
        </div>
      }
    >
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
          marginBottom: 16,
          borderRadius: 4,
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            background: "#ffffff",
            border: "1px solid #f0f0f0",
            borderRadius: 4,
            zIndex: 1,
          }}
          className={loaded ? "hide" : ""}
        >
          <Loader minHeight={160} />
        </div>
        <div
          style={{
            width: "100%",
            height: "100%",
            marginBottom: 16,
            borderRadius: 4,
          }}
          id="editMap"
        ></div>
      </div>
    </ResizableBox>
  );
};

export default memo(Map);
