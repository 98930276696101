import React, { useState, useEffect } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useSelector } from "react-redux";
import Card from "antd/es/card";
import Affix from "antd/es/affix";
import Row from "antd/es/row";
import Col from "antd/es/col";
// import Select from "antd/es/select";
import api from "API";
import styles from "./styles.module.scss";
import ImagesDrawer from "Components/images/ImagesDrawer";
import ViewContainer from "Components/ViewContainer";
import Activity from "Components/Activities/Activity";
import dayjs from "dayjs";
import useViewShow from "Hooks/useViewShow";
// import useIsMobile from "Hooks/useIsMobile";
import ViewLead from "Screens/ViewLead";
import ViewProject from "Screens/ViewProject";
// import RemoveAutocomplete from "Components/RemoveAutocomplete";
import FormBody from "Components/FormBody";
import Grid from "antd/es/grid";
import relativeTime from "dayjs/plugin/relativeTime";
import duration from "dayjs/plugin/duration";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "Components/Loader";

dayjs.extend(duration);
dayjs.extend(relativeTime);

const { useBreakpoint } = Grid;

const Feed = () => {
  const images = useSelector((state) => state.formState.images.open);
  const _user = useSelector((state) => state.authState._user);
  // const mobile = useIsMobile();
  const screens = useBreakpoint();
  const showProject = useViewShow(2, "projects", 3, "view");
  const showLead = useViewShow(2, "leads", 3, "view");
  const [activities, setActivities] = useState([]);
  const [activitiesLength, setActivitiesLength] = useState(0);
  const [container, setContainer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [today] = useState(dayjs().endOf("day"));
  const [yesterday] = useState(dayjs().subtract(1, "day").endOf("day"));
  const [day3] = useState(dayjs().subtract(2, "day").endOf("day"));
  const [day7] = useState(dayjs().subtract(6, "day").endOf("day"));
  // const [filter, setFilter] = useState(undefined);
  // const [hashtags, setHashtags] = useState([]);

  useEffect(() => {
    getActivities();

    window.socket.on(`${_user}-feed`, fetchActivities);
    window.socket.on("globalUpdate", fetchActivities);

    return () => {
      window.socket.removeAllListeners(`${_user}-feed`);
      window.socket.removeAllListeners("globalUpdate");
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const addDateBreaks = (array) => {
    let stamp;
    let dateStamp;

    for (const f of array) {
      f._dateStamp = undefined;
      const createdDate = dayjs(f.lastUpdated);
      if (createdDate.isBefore(today)) {
        if (createdDate.isBefore(yesterday)) {
          if (createdDate.isBefore(day3)) {
            if (createdDate.isBefore(day7)) {
              stamp = createdDate.format("MMM D, YYYY");
            } else {
              stamp = createdDate.format("dddd");
            }
          } else {
            stamp = "Yesterday";
          }
        } else {
          stamp = "Today";
        }
      }
      if (stamp !== dateStamp) {
        f._dateStamp = stamp;
        dateStamp = stamp;
      }
    }

    return array;
  };

  const getActivities = async () => {
    try {
      const res = await api.get(`/fetchActivities/0`);

      const _feed = addDateBreaks(res.data.feed);
      unstable_batchedUpdates(() => {
        setActivities(_feed);
        setActivitiesLength(res.data.feedCount);
        // setHashtags(res.data.hashtags);
        setLoading(false);
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const fetchActivities = async (_filter) => {
    try {
      const res = await api.get(
        `/fetchActivities/${_filter ? 0 : activities.length}`
      );

      if (_filter) {
        unstable_batchedUpdates(() => {
          const _feed = addDateBreaks(res.data.feed);

          setActivities(_feed);
          setActivitiesLength(res.data.feedCount);
        });
      } else {
        let _feed = [...activities, ...res.data.feed];
        _feed = addDateBreaks(_feed);

        unstable_batchedUpdates(() => {
          setActivities(_feed);
          setActivitiesLength(res.data.feedCount);
        });
      }
    } catch (err) {
      console.log("fetchActivities err", err);
    }
  };

  // function onChange(e) {
  //   if (e === "REMOVE FILTER") {
  //     setFilter(undefined);
  //     fetchActivities("undefined");
  //   } else {
  //     setFilter(e);
  //     fetchActivities(e);
  //   }
  // }

  const rowRenderer = () => {
    return activities.map((fp) => {
      if (!fp._dateStamp) {
        return (
          <div id={`dashboard-${fp._id}`} key={`dashboard-${fp._id}`}>
            <Activity
              dashboard
              activity={fp}
              scrollContainer={"#feed-container"}
            />
          </div>
        );
      } else {
        return (
          <div key={`dashboard-${fp._id}`}>
            <Affix
              offsetTop={0}
              target={() => container}
              className={styles.affix}
            >
              <div className={styles.dateStamp}>{fp._dateStamp}</div>
            </Affix>
            <div id={`dashboard-${fp._id}`}>
              <Activity
                dashboard
                activity={fp}
                scrollContainer={"#feed-container"}
              />
            </div>
          </div>
        );
      }
    });
  };

  return (
    <div
      id="feed-container"
      className="content-inner p16"
      style={{ height: "100%" }}
      ref={setContainer}
    >
      <Row justify="center">
        <Col xs={24} sm={24} md={12}>
          {/* <form autoComplete="off">
            <RemoveAutocomplete />
            <Select
              autoComplete="dashboard-hashtag-filter"
              allowClear
              showSearch={!mobile}
              dropdownClassName={mobile ? "isMobile" : null}
              getPopupContainer={(trigger) =>
                !mobile ? trigger.parentNode : document.body
              }
              placeholder="Search Hashtags..."
              onChange={onChange}
              className={styles.hashtagSearch}
              size="large"
              value={filter}
            >
              {hashtags.map((h) => (
                <Select.Option key={h} value={h}>
                  {h}
                </Select.Option>
              ))}
              <Select.Option
                style={{
                  fontWeight: "bold",
                  borderTop: "1px solid #f0f0f0",
                }}
                key="remove-filter"
                value="REMOVE FILTER"
              >
                REMOVE FILTER
              </Select.Option>
            </Select>
          </form> */}
          {loading ? (
            <>
              <Card loading={true} style={{ marginBottom: 8 }} />
              <Card loading={true} style={{ marginBottom: 8 }} />
              <Card loading={true} style={{ marginBottom: 8 }} />
              <Card loading={true} style={{ marginBottom: 8 }} />
              <Card loading={true} style={{ marginBottom: 8 }} />
              <Card loading={true} style={{ marginBottom: 8 }} />
              <Card loading={true} style={{ marginBottom: 8 }} />
              <Card loading={true} style={{ marginBottom: 8 }} />
              <Card loading={true} style={{ marginBottom: 8 }} />
              <Card loading={true} style={{ marginBottom: 8 }} />
              <Card loading={true} style={{ marginBottom: 8 }} />
              <Card loading={true} style={{ marginBottom: 8 }} />
              <Card loading={true} style={{ marginBottom: 8 }} />
              <Card loading={true} style={{ marginBottom: 8 }} />
            </>
          ) : activities.length > 0 ? (
            <InfiniteScroll
              dataLength={activities.length} //This is important field to render the next data
              next={fetchActivities}
              hasMore={activities.length !== activitiesLength}
              loader={
                <div style={{ width: "100%" }}>
                  <Loader minHeight="40px" />
                </div>
              }
              endMessage={null}
              scrollableTarget="feed-container"
            >
              {rowRenderer()}
            </InfiniteScroll>
          ) : (
            <Card
              className={styles.activity}
              bodyStyle={{ padding: 0, borderRadius: 4 }}
            >
              <FormBody className={styles.body} style={{ padding: 12 }}>
                <div className={styles.content}>No posts the last 7 days</div>
              </FormBody>
            </Card>
          )}
        </Col>
      </Row>

      <ImagesDrawer dashboard visible={images} />

      <ViewContainer
        type="drawer"
        visible={showLead}
        form={ViewLead}
        placement="right"
        width={!screens.sm ? "100%" : 400}
        closable={false}
        mask={false}
      />

      <ViewContainer
        type="drawer"
        visible={showProject}
        form={ViewProject}
        placement="right"
        width={!screens.sm ? "100%" : 400}
        closable={false}
        mask={false}
      />
    </div>
  );
};

export default Feed;
