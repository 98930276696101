import React from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { openForm } from "Actions/formActions";
import List from "antd/es/list";
import currencyFormatter from "Utils/currencyFormatter";
import styles from "./styles.module.scss";
// import Wallet from "Icons/Wallet";
// import timeStamp from "Utils/timeStamp";

const ScopeBreakdown = ({
  invoicedAmount,
  expensedAmount,
  materialExpenses,
  laborExpenses,
  dumpingExpenses,
  permitExpenses,
}) => {
  return (
    <List bordered itemLayout="horizontal" className={styles.list}>
      <List.Item className={styles.transactionList}>
        <List.Item.Meta
          className={styles.vendor}
          title={<span>Total Invoiced</span>}
        />
        <List.Item.Meta
          className={styles.amount}
          description={currencyFormatter(invoicedAmount)}
        />
      </List.Item>
      <List.Item className={styles.transactionList}>
        <List.Item.Meta
          className={styles.vendor}
          title={<span>Total Expenses</span>}
        />
        <List.Item.Meta
          className={styles.amount}
          description={currencyFormatter(expensedAmount)}
        />
      </List.Item>
      <List.Item className={styles.transactionList}>
        <List.Item.Meta
          className={styles.vendor}
          title={<span>Material Expenses</span>}
        />
        <List.Item.Meta
          className={styles.amount}
          description={currencyFormatter(materialExpenses)}
        />
      </List.Item>
      <List.Item className={styles.transactionList}>
        <List.Item.Meta
          className={styles.vendor}
          title={<span>Labor Expenses</span>}
        />
        <List.Item.Meta
          className={styles.amount}
          description={currencyFormatter(laborExpenses)}
        />
      </List.Item>
      <List.Item className={styles.transactionList}>
        <List.Item.Meta
          className={styles.vendor}
          title={<span>Dumping Expenses</span>}
        />
        <List.Item.Meta
          className={styles.amount}
          description={currencyFormatter(dumpingExpenses)}
        />
      </List.Item>
      <List.Item className={styles.transactionList}>
        <List.Item.Meta
          className={styles.vendor}
          title={<span>Permit Expenses</span>}
        />
        <List.Item.Meta
          className={styles.amount}
          description={currencyFormatter(permitExpenses)}
        />
      </List.Item>
    </List>
  );
};
export default ScopeBreakdown;
