import React from "react";
import BarChartWithDates from "Reports/BarChartWithDates";

export default function LeadsByOrigin() {
  return (
    <BarChartWithDates
      path="/reports/leadsByOrigin"
      summary={true}
      type="count"
      report="LEADS BY ORIGIN"
      headers={["Origin", "Total"]}
    />
  );
}
