import React, { useState, useEffect } from "react";
import Card from "antd/es/card";
import axios from "axios";
import dayjs from "dayjs";
import styles from "./styles.module.scss";
import Typography from "antd/es/typography";
import useIsMounted from "Hooks/useIsMounted";

let index = 0;
let radarLayers = {};
let map = {};
const zoom = 7;
const tenMinutes = 1000 * 60 * 10;
const oneSecond = 1000;
const optionTileSize = 256; // can be 256 or 512.
const optionColorScheme = 4; // from 0 to 8. Check the https://rainviewer.com/api/color-schemes.html for additional information
const optionSmoothData = 1; // 0 - not smooth, 1 - smooth
const optionSnowColors = 1; // 0 - do not show snow colors, 1 - show snow colors

const Radar = () => {
  const isMounted = useIsMounted();
  const [time, setTime] = useState(dayjs().format("MMM D, h:mm A"));
  const [mapFrames, setMapFrames] = useState([]);

  const mounted = isMounted.current;

  useEffect(() => {
    if (mounted) {
      mapInit();
    }
  }, [mounted]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let changeFrame, refreshData;
    if (mapFrames.length > 0) {
      changeFrame = setInterval(() => {
        radarLayers[mapFrames[index].path].setOpacity(0);

        if (index === mapFrames.length - 1) {
          index = 0;
        } else {
          index++;
        }

        if (radarLayers[mapFrames[index].path]) {
          radarLayers[mapFrames[index].path].setOpacity(1);
        }

        setTime(
          dayjs(new Date(mapFrames[index].time * 1000)).format("MMM D, h:mm A")
        );
      }, oneSecond);

      refreshData = setInterval(() => {
        clearTimeout(changeFrame);
        getWeather();
      }, tenMinutes);
    }

    return () => {
      clearInterval(refreshData);
      clearInterval(changeFrame);
    };
  }, [mapFrames]); // eslint-disable-line react-hooks/exhaustive-deps

  const getWeather = async () => {
    try {
      const _weather = await axios.get(
        "https://api.rainviewer.com/public/weather-maps.json"
      );

      if (map.overlayMapTypes) map.overlayMapTypes.clear();

      let _mapFrames = [];
      radarLayers = {};
      if (_weather.data && _weather.data.radar && _weather.data.radar.past) {
        _mapFrames = _weather.data.radar.past;
        if (_weather.data.radar.nowcast) {
          _mapFrames = _mapFrames.concat(_weather.data.radar.nowcast);
        }
      }

      for (const mf of _mapFrames) {
        radarLayers[mf.path] = {};
        addLayer(_weather.data, mf);
      }

      setMapFrames(_mapFrames);
    } catch (err) {
      console.log("err", err);
    }
  };

  const mapInit = () => {
    map = new window.google.maps.Map(document.getElementById("map"), {
      center: { lat: 42.963795, lng: -85.670006 },
      zoom,
      gestureHandling: "greedy",
      disableDefaultUI: true,
      fullscreenControl: false,
      streetViewControl: false,
      mapTypeControl: false,
      zoomControl: false,
      defaultMapTypeId: "hybrid",
      mapTypeId: "hybrid",
      tilt: 0,
    });
    getWeather();
  };

  async function addLayer(api, frame) {
    radarLayers[frame.path] = new window.google.maps.ImageMapType({
      getTileUrl: function (coord, zoom) {
        return [
          api.host + frame.path,
          optionTileSize,
          zoom,
          coord.x,
          coord.y,
          optionColorScheme,
          optionSmoothData + "_" + optionSnowColors + ".png",
        ].join("/");
      },
      isPng: true,
      tileSize: new window.google.maps.Size(optionTileSize, optionTileSize),
      opacity: 0,
    });
    map.overlayMapTypes.push(radarLayers[frame.path]);
  }

  return (
    <Card
      bordered={false}
      className="z-depth-1"
      style={{ height: "100%", overflow: "hidden" }}
      bodyStyle={{ padding: 0 }}
    >
      <div className={styles.time}>
        <Typography.Text className={styles.timeText}>{time}</Typography.Text>
      </div>
      <div id="map" style={{ height: "100%" }}></div>
    </Card>
  );
};

export default Radar;
