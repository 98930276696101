import React, { memo } from "react";
import Input from "antd/es/input";
import Form from "antd/es/form";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Typography from "antd/es/typography";
import Divider from "antd/es/divider";
import Select from "Components/Inputs/Select";

const DeliveryDetails = ({ hidden, disabled, laborOnly }) => {
  return (
    <div style={{ display: hidden ? "none" : "block" }}>
      <Typography.Title level={4}>Delivery Details</Typography.Title>
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="deliveryType"
            label="Delivery Type"
            rules={[
              {
                required: laborOnly ? false : true,
                message: "Delivery Type is required",
              },
            ]}
          >
            <Select
              options={["Ground Drop", "Roof Top", "Pick Up", "N/A"]}
              disabled={disabled}
              id="deliveryType"
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item name="deliveryNotes" label="Delivery Notes">
            <Input.TextArea
              autoComplete="dont-use-autocomplete"
              autosize={{ minRows: 1 }}
              disabled={disabled}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={12}>
          <Form.Item name="dumpsterNotes" label="Dumpster Notes">
            <Input.TextArea
              autoComplete="dont-use-autocomplete"
              autosize={{ minRows: 1 }}
              disabled={disabled}
            />
          </Form.Item>
        </Col>

        <Col xs={12}>
          <Form.Item name="officeReviewNote" label="Schedule Notes">
            <Input.TextArea
              autoComplete="dont-use-autocomplete"
              autosize={{ minRows: 1 }}
              disabled={disabled}
            />
          </Form.Item>
        </Col>
      </Row>
      {!hidden && <Divider />}
    </div>
  );
};

export default memo(DeliveryDetails);
