import React, { useState, useEffect, useCallback } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchTable } from "Actions/tableActions";
import api from "API";
import Input from "antd/es/input";
import Form from "antd/es/form";
import message from "antd/es/message";
import Button from "antd/es/button";
import Modal from "antd/es/modal";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import CancelButton from "Components/CancelButton";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";
import Sortable from "sortablejs";
import styles from "./styles.module.scss";
import Col from "antd/es/col";
import Row from "antd/es/row";
import DragVertical from "Icons/DragVertical";
import Delete from "Icons/Delete";
import Divider from "antd/es/divider";

const messageKey = "messageKey";

const Dropdown = ({
  unsavedChanges,
  setUnsavedChanges,
  handleClose,
  setTitle,
  type,
}) => {
  const dispatch = useDispatch();
  const formDetails = useSelector(
    (state) => state.formState.dropdown.formDetails
  );
  const table = useSelector((state) => state.tableState.table);
  const col = useSelector((state) => state.tableState.col);
  const order = useSelector((state) => state.tableState.order);
  const search = useSelector((state) => state.tableState.search);
  const activeFilter = useSelector((state) => state.tableState.activeFilter);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!formDetails.editing) {
      setTitle("Create Dropdown");
    } else {
      setTitle(
        <span>
          Edit Dropdown{" "}
          <Button
            size="small"
            danger
            onClick={confirmDelete}
            style={{ float: "right", marginRight: 28 }}
          >
            Delete
          </Button>
        </span>
      );
      form.setFieldsValue({
        options: formDetails.options,
      });
      handleNewSortAdd();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleNewSortAdd = () => {
    const el = document.getElementById("dropdownSortable");
    Sortable.create(el, {
      animation: 150,
      direction: "vertical",
      ghostClass: "placeholder",
      handle: ".handle",
      draggable: ".draggable",
      onUpdate: () => {
        if (!unsavedChanges) setUnsavedChanges(true);
      },
    });
  };

  const confirmDelete = () => {
    Modal.confirm({
      zIndex: 4002,
      icon: <QuestionCircleOutlined />,
      centered: true,
      cancelText: "Cancel",
      okText: "Delete",
      okButtonProps: { danger: true },
      content: <div>Are you sure you want to delete this dropdown?</div>,
      onOk() {
        return new Promise((resolve, reject) => {
          deleteDropdown(() => resolve(true));
        }).catch((err) => console.log("err: ", err));
      },
      onCancel() {},
    });
  };

  const deleteDropdown = useCallback(
    async (cb) => {
      try {
        const _id = await form.getFieldValue("_id");
        await api.delete(`/dropdown/${_id}`);

        dispatch(
          fetchTable({
            table,
            col,
            order,
            search,
            activeFilter,
            fetching: false,
          })
        );

        unstable_batchedUpdates(() => {
          setUnsavedChanges(false);
          setLoading(false);
        });

        cb();
        message.success("Dropdown deleted");
        handleClose(true);
      } catch (err) {
        console.log("err", err);
        message.error("Error deleting dropdown");
      }
    },
    [
      form,
      dispatch,
      table,
      col,
      order,
      search,
      activeFilter,
      setUnsavedChanges,
      handleClose,
    ]
  );

  const onFinish = useCallback(
    async (values) => {
      try {
        setLoading(true);
        // message.loading("Saving dropdown...", 0);
        message.loading({
          content: "Saving dropdown...",
          duration: 0,
          key: messageKey,
        });

        const _options = [];
        const el = document.getElementById("dropdownSortable");
        const childNodes = el.childNodes;
        for (const node of childNodes) {
          const input = node.getElementsByTagName("INPUT")[0];
          if (input) _options.push(input.value);
        }

        values.options = _options;

        if (!formDetails.editing) {
          await api.post("/dropdowns/create", values);
        } else {
          await api.post("/dropdowns/edit", values);
        }

        dispatch(
          fetchTable({
            table,
            col,
            order,
            search,
            activeFilter,
            fetching: false,
          })
        );

        unstable_batchedUpdates(() => {
          setUnsavedChanges(false);
          setLoading(false);
        });

        // message.success("Dropdown saved");
        message.success({
          content: "Dropdown saved",
          duration: 1.5,
          key: messageKey,
        });
        handleClose(true);
      } catch (err) {
        setLoading(false);
        // message.error("Error saving dropdown");
        message.error({
          content: "Error saving dropdown",
          duration: 1.5,
          key: messageKey,
        });
        console.log("err", err);
      }
    },
    [
      formDetails.editing,
      dispatch,
      table,
      col,
      order,
      search,
      activeFilter,
      setUnsavedChanges,
      handleClose,
    ]
  );

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error saving dropdown");
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      initialValues={{
        _id: !formDetails._id ? undefined : formDetails._id,
        name: !formDetails.name ? undefined : formDetails.name,
      }}
      className="form"
    >
      <RemoveAutocomplete />

      <FormBody
        className="content-inner"
        style={{
          paddingLeft: 24,
          paddingTop: 24,
          paddingRight: 24,
          paddingBottom: 24,
          maxHeight: type === "modal" ? "calc(100vh - 161px)" : "unset",
        }}
      >
        <Form.Item name="_id" hidden>
          <Input />
        </Form.Item>

        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Name is required" }]}
        >
          <InputNoAutoComplete autoFocus id="name" />
        </Form.Item>

        <Divider />

        <Form.List name="options">
          {(fields, { add, remove }) => {
            const handleAdd = () => {
              add();
            };

            const onRemove = (option) => {
              remove(option);
            };

            return (
              <>
                <div id="dropdownSortable">
                  {fields.map((field) => {
                    const handleRemove = () => {
                      onRemove(field.name);
                    };

                    return (
                      <div
                        key={`${field.key}-option`}
                        className={`draggable ${styles.dragContainer}`}
                      >
                        <Row>
                          <Col xs={2}>
                            <div className={`handle ${styles.dragHandle}`}>
                              <DragVertical size={32} />
                            </div>
                          </Col>
                          <Col xs={20}>
                            <Form.Item
                              {...field}
                              key={field.key}
                              rules={[
                                {
                                  required: true,
                                  message: "Option is required",
                                },
                              ]}
                            >
                              <InputNoAutoComplete id={[field.fieldKey]} />
                            </Form.Item>
                          </Col>
                          <Col xs={2}>
                            <Button
                              tabIndex="-1"
                              type="text"
                              shape="circle"
                              danger
                              className={styles.deleteButton}
                              onClick={handleRemove}
                            >
                              <Delete />
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    );
                  })}
                </div>
                <Button type="primary" block onClick={handleAdd}>
                  Add Option
                </Button>
              </>
            );
          }}
        </Form.List>
      </FormBody>
      <FormControls className="sticky-footer">
        <CancelButton handleClick={handleClose} />
        <Form.Item style={{ margin: 0 }}>
          <Button
            htmlType="submit"
            type="primary"
            loading={loading}
            disabled={loading || !unsavedChanges}
          >
            Submit
          </Button>
        </Form.Item>
      </FormControls>
    </Form>
  );
};

export default Dropdown;
