import React from "react";
import Col from "antd/es/col";
import Typography from "antd/es/typography";
import Card from "antd/es/card";
import styles from "./styles.module.scss";

const StageCard = ({ stage, title }) => {
  return (
    <Col
      xs={24}
      style={{ height: "calc(16.667% - 14px)", marginBottom: 16 }}
      className={`${styles.stageContainer} ${
        title === "Office Review"
          ? styles.two
          : title === "Ready For Materials"
          ? styles.three
          : title === "Ready To Be Scheduled"
          ? styles.four
          : title === "Work Scheduled"
          ? styles.five
          : styles.six
      }`}
    >
      <Card
        bordered={false}
        className={styles.card}
        bodyStyle={{
          padding: 0,
        }}
      >
        <Typography.Text
          text="secondary"
          className={styles.stageTitle}
          style={{ fontSize: 20 }}
        >
          {title}
        </Typography.Text>
        <Typography.Title level={1} className={styles.stageNumber}>
          {stage}
        </Typography.Title>
      </Card>
    </Col>
  );
};

export default StageCard;
