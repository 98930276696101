import dayjs from "dayjs";
import React, { useState, useEffect } from "react";
import currencyFormatter from "Utils/currencyFormatter";
import sumBy from "Utils/sumBy";
import message from "antd/es/message";
import List from "antd/es/list";
import api from "API";
import styles from "./styles.module.scss";

const today = dayjs();
const year = new Date().getFullYear();
let yearStart = new Date(`1/1/${year}`);
yearStart = dayjs(yearStart);

const Projections = () => {
  const [state, setState] = useState({
    end: today,
    start: yearStart,
    totalAR: 0,
    totalDraw: 0,
    ar12: 0,
    totalPipeline: 0,
    totalExpenses: 0,
    expenses: [],
    difference: 0,
  });

  useEffect(() => {
    getReport();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getReport = async () => {
    try {
      const res = await api.post("/reports/projections", {
        start: yearStart,
        end: today,
      });

      const totalAR = res.data.totalAR;
      const totalExpenses =
        sumBy(res.data.expenses, "value") +
        res.data.totalDraw +
        res.data.totalAR * 0.12 +
        res.data.totalPipeline;

      setState((thisState) => {
        return {
          ...thisState,
          totalAR,
          totalDraw: res.data.totalDraw,
          ar12: totalAR * 0.12,
          totalExpenses,
          totalPipeline: res.data.totalPipeline,
          expenses: res.data.expenses,
          difference: totalAR - totalExpenses,
        };
      });
    } catch (err) {
      console.log("err", err);
      message.error("Error getting report");
    }
  };

  return (
    <div style={{ padding: 24 }} className="content-inner">
      <List bordered style={{ marginBottom: 24 }}>
        <List.Item className={styles.listHeader}>
          <List.Item.Meta title="Assets" />
        </List.Item>
        <List.Item className={styles.listItem}>
          <List.Item.Meta title="Total AR" />
          <List.Item.Meta
            className={styles.listRight}
            title={currencyFormatter(state.totalAR)}
          />
        </List.Item>
        <List.Item className={styles.listItem}>
          <List.Item.Meta title="Bank Account" />
          <List.Item.Meta className={styles.listRight} title="N/A" />
        </List.Item>
        <List.Item className={styles.listTotal}>
          <List.Item.Meta title="Total" />
          <List.Item.Meta
            className={styles.listRight}
            title={currencyFormatter(state.totalAR)}
          />
        </List.Item>
      </List>
      <List bordered style={{ marginBottom: 24 }}>
        <List.Item className={styles.listHeader}>
          <List.Item.Meta title="Liabilities" />
        </List.Item>
        <List.Item className={styles.listItem}>
          <List.Item.Meta title="Outstanding Draws" />
          <List.Item.Meta
            className={styles.listRight}
            title={currencyFormatter(state.totalDraw)}
          />
        </List.Item>

        <List.Item className={styles.listItem}>
          <List.Item.Meta title="12% of AR" />
          <List.Item.Meta
            className={styles.listRight}
            title={currencyFormatter(state.ar12)}
          />
        </List.Item>
        <List.Item className={styles.listItem}>
          <List.Item.Meta title="Pipeline" />
          <List.Item.Meta
            className={styles.listRight}
            title={currencyFormatter(state.totalPipeline)}
          />
        </List.Item>

        {state.expenses.map((e) => {
          return (
            <List.Item key={e._id} className={styles.listItem}>
              <List.Item.Meta title={e._id} />
              <List.Item.Meta
                className={styles.listRight}
                title={currencyFormatter(e.value)}
              />
            </List.Item>
          );
        })}
        <List.Item className={styles.listTotal}>
          <List.Item.Meta title="Total" />
          <List.Item.Meta
            className={styles.listRight}
            title={currencyFormatter(state.totalExpenses)}
          />
        </List.Item>
      </List>
      <List bordered>
        <List.Item className={styles.listHeader}>
          <List.Item.Meta title="Summary" />
        </List.Item>
        <List.Item className={styles.listItem}>
          <List.Item.Meta title="Asset Total" />
          <List.Item.Meta
            className={styles.listRight}
            title={currencyFormatter(state.totalAR)}
          />
        </List.Item>
        <List.Item className={styles.listItem}>
          <List.Item.Meta title="Liability Total" />
          <List.Item.Meta
            className={styles.listRight}
            title={currencyFormatter(state.totalExpenses)}
          />
        </List.Item>
        <List.Item className={styles.listHeader}>
          <List.Item.Meta title="Difference" />
          <List.Item.Meta
            className={styles.listRight}
            title={currencyFormatter(state.difference)}
          />
        </List.Item>
      </List>
    </div>
  );
};

export default Projections;
