import React, { useRef } from "react";
import Select from "antd/es/select";
import useIsMobile from "Hooks/useIsMobile";

const InputVendors = ({
  value,
  onChange,
  vendors,
  placeholder = undefined,
  filter = false,
}) => {
  const mobile = useIsMobile();
  const ref = useRef();

  const renderSalesmen = Object.keys(vendors).map((key) => (
    <Select.Option key={vendors[key]._id} value={vendors[key]._id}>
      {vendors[key].companyName}
    </Select.Option>
  ));

  const triggerChange = (changedValue) => {
    if (onChange) {
      onChange(changedValue);
    }
  };

  const handleChange = (values) => {
    triggerChange(values);
    ref.current.blur();
  };

  return (
    <Select
      id="vendors"
      ref={ref}
      autoComplete="edit-detail-field"
      allowClear
      mode="multiple"
      showSearch={!mobile}
      dropdownClassName={mobile ? "isMobile" : null}
      getPopupContainer={(trigger) =>
        !mobile && filter
          ? trigger.parentNode.parentNode.parentNode.parentNode.parentNode
              .parentNode.parentNode
          : !mobile
          ? trigger.parentNode
          : document.body
      }
      onChange={handleChange}
      value={value}
      placeholder={placeholder}
    >
      {renderSalesmen}
    </Select>
  );
};

export default InputVendors;
