import React, { useState, useRef, useEffect } from "react";
import api from "API";
import { useNavigate } from "react-router-dom";
import message from "antd/es/message";
import Group from "Icons/Group";
import Build from "Icons/Build";
import styles from "./styles.module.scss";
import isLead from "Utils/isLead";
import usePrevious from "Hooks/usePrevious";
import Form from "antd/es/form";
import axios from "axios";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";
import List from "antd/es/list";
import { unstable_batchedUpdates } from "react-dom";

import ChevronRight from "Icons/ChevronRight";
import Button from "antd/es/button";

let searchTimeout;
let cancelToken;

const GlobalSearch = ({
  showSearch,
  setShowSearch,
  leads = true,
  projects = true,
}) => {
  const navigate = useNavigate();
  const prevShowSearch = usePrevious(showSearch);
  const [lastViewedResults, setLastViewedResults] = useState([]);
  const [leadsResults, setLeadsResults] = useState([]);
  const [projectsResults, setProjectsResults] = useState([]);

  const [form] = Form.useForm();

  const ref = useRef();

  useEffect(() => {
    return () => clearTimeout(searchTimeout);
  }, []);

  useEffect(() => {
    if (showSearch && !prevShowSearch) {
      ref.current.focus();
      handleSearch();
    }
  }, [showSearch, prevShowSearch]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearch = (_search) => {
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(async () => {
      try {
        if (typeof cancelToken != typeof undefined) {
          cancelToken.cancel("Operation canceled due to new request.");
        }

        cancelToken = axios.CancelToken.source();

        const res = await api.post(
          `/globalSearch`,
          {
            search: _search,
            leads,
            projects,
          },
          { cancelToken: cancelToken.token }
        );
        if (res.status !== 200) {
          message.error("Error searching everything");
        } else {
          const _lastViewed = res.data.find((d) => d.title === "last viewed");
          const _leads = res.data.find((d) => d.title === "leads");
          const _projects = res.data.find((d) => d.title === "projects");

          unstable_batchedUpdates(() => {
            setLastViewedResults(_lastViewed ? _lastViewed.children : []);
            setLeadsResults(_leads ? _leads.children : []);
            setProjectsResults(_projects ? _projects.children : []);
          });
        }
      } catch (err) {
        console.log("err", err);
      }
    }, 300);
  };

  function onSelect(item, type) {
    if (item._id) {
      if (type !== "last viewed") {
        navigate(`/${item.type}/view/details/${item._id}`);
      } else {
        if (isLead(item.projectstatus)) {
          navigate(`/leads/view/details/${item._id}`);
        } else {
          navigate(`/projects/view/details/${item._id}`);
        }
      }
    }

    setLastViewedResults([]);
    setLeadsResults([]);
    setProjectsResults([]);
    setShowSearch(false);
    form.resetFields();
    ref.current.blur();
  }

  const closeShowSearch = () => {
    setShowSearch(false);
    setLastViewedResults([]);
    setLeadsResults([]);
    setProjectsResults([]);
    form.resetFields();
    ref.current.blur();
  };

  const renderLastViewed = lastViewedResults.map((item) => (
    <List.Item
      className={styles.listItem}
      key={item._id}
      onClick={() => onSelect(item, "last viewed")}
    >
      <List.Item.Meta
        description={
          <div className={styles.globalSearchDropResult}>
            <div className="gsLeft">
              {isLead(item.projectStatus) ? (
                <Group className="gsIcon" />
              ) : (
                <Build className="gsIcon" />
              )}
            </div>
            <div className="gsRight">
              <div className="gsTopLine">{`${item.customerId} - ${item.customerName}`}</div>
              <div>{item.projectAddress}</div>
            </div>
          </div>
        }
      />
    </List.Item>
  ));

  const renderProjects = projectsResults.map((item) => (
    <List.Item
      className={styles.listItem}
      key={item._id}
      onClick={() => onSelect(item, "last viewed")}
    >
      <List.Item.Meta
        description={
          <div className={styles.globalSearchDropResult}>
            <div className="gsLeft">
              {isLead(item.projectStatus) ? (
                <Group className="gsIcon" />
              ) : (
                <Build className="gsIcon" />
              )}
            </div>
            <div className="gsRight">
              <div className="gsTopLine">{`${item.customerId} - ${item.customerName}`}</div>
              <div>{item.projectAddress}</div>
            </div>
          </div>
        }
      />
    </List.Item>
  ));

  const renderLeads = leadsResults.map((item) => (
    <List.Item
      className={styles.listItem}
      key={item._id}
      onClick={() => onSelect(item, "last viewed")}
    >
      <List.Item.Meta
        description={
          <div className={styles.globalSearchDropResult}>
            <div className="gsLeft">
              {isLead(item.projectStatus) ? (
                <Group className="gsIcon" />
              ) : (
                <Build className="gsIcon" />
              )}
            </div>
            <div className="gsRight">
              <div className="gsTopLine">{`${item.customerId} - ${item.customerName}`}</div>
              <div>{item.projectAddress}</div>
            </div>
          </div>
        }
      />
    </List.Item>
  ));

  return (
    <div
      className={`${styles.searchContainer} ${
        showSearch ? styles.searchOpen : ""
      }`}
    >
      <Button
        type="secondary"
        shape="circle"
        ghost
        style={{ verticalAlign: "middle", border: "none" }}
        onClick={closeShowSearch}
        className={`${styles.closeIcon} ${showSearch ? styles.showSearch : ""}`}
      >
        <ChevronRight color="#ffffff" />
      </Button>
      <Form
        form={form}
        autoComplete="off"
        style={{ position: "relative" }}
        className={styles.form}
      >
        <Form.Item name="global" style={{ margin: 0 }}>
          <InputNoAutoComplete
            id="global"
            ref={ref}
            placeholder="Search Leads and Projects"
            onChange={handleSearch}
          />
        </Form.Item>
      </Form>
      <div className={`${styles.searchList} content-inner`}>
        {lastViewedResults.length > 0 && (
          <List bordered={false}>
            <List.Item className={styles.listHeader}>
              <List.Item.Meta title="Last Viewed" />
            </List.Item>
            {renderLastViewed}
          </List>
        )}
        {leadsResults.length > 0 && (
          <List bordered={false}>
            <List.Item className={styles.listHeader}>
              <List.Item.Meta title="Leads" />
            </List.Item>
            {renderLeads}
          </List>
        )}
        {projectsResults.length > 0 && (
          <List bordered={false}>
            <List.Item className={styles.listHeader}>
              <List.Item.Meta title="Projects" />
            </List.Item>
            {renderProjects}
          </List>
        )}
      </div>
    </div>
  );
};

export default GlobalSearch;
