import React, { useEffect, useCallback } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getData, unsetData } from "Actions/dataActions";
import Tabs from "antd/es/tabs";
import usePrevious from "Hooks/usePrevious";
import styles from "./styles.module.scss";
import Loader from "Components/Loader";
import Details from "Components/Details";
import Activities from "Components/Activities";
import ViewLeadHeader from "Components/ViewLeadProjectHeader";
import Related from "Components/Related";
// import message from "antd/es/message";

let _activityId;

const ViewLead = ({ handleClose }) => {
  const dispatch = useDispatch();
  const projectStatus = useSelector(
    (state) => state.dataState.details.projectStatus
  );
  const fetching = useSelector((state) => state.dataState.fetching);
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const currLead = params.id;
  const prevLead = usePrevious(params.id);

  useEffect(() => {
    let timer;
    if (location.search) {
      _activityId = location.search.substring(1);
    }

    return () => {
      dispatch(unsetData());
      if (location.search) clearTimeout(timer);
      _activityId = undefined;
      window.socket.removeAllListeners(`${prevLead}`);
      window.socket.removeAllListeners(`${currLead}`);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (prevLead !== currLead && currLead !== undefined) {
      getLead();
      if (prevLead) {
        window.socket.removeAllListeners(`${prevLead}`);
      }
      window.socket.on(`${currLead}`, _callGetLead);
    }
  }, [prevLead, currLead]); // eslint-disable-line react-hooks/exhaustive-deps

  const _callGetLead = () => {
    getLead(false);
  };

  const getLead = useCallback(
    async (fetching = true) => {
      try {
        dispatch(
          getData({
            route: `/leads/get/${currLead}`,
            fetching,
            _activityId,
          })
        );

        // if (res && res.message && res.message.includes("403")) {
        //   message.error("Access Denied");
        //   handleClose();
        // }
      } catch (err) {
        console.log("err", err);
      }
    },
    [dispatch, currLead]
  );

  const onChange = (e) => {
    const pathArr = location.pathname.split("/");
    if (pathArr[2] && pathArr[2] === "lead-scheduling") {
      navigate(`/${pathArr[1]}/${pathArr[2]}/leads/view/${e}/${currLead}`);
    } else if (pathArr[2] && pathArr[2] === "reports-dashboard-admin") {
      navigate(`/${pathArr[1]}/${pathArr[2]}/leads/view/${e}/${currLead}`);
    } else if (pathArr[1] !== "leads") {
      // navigate(`/${pathArr[1]}/leads/view/${e}/${currLead}`);
      if (pathArr[1] !== "workflow" && pathArr[1] !== "install") {
        navigate(`/${pathArr[1]}/leads/view/${e}/${currLead}`);
      } else {
        navigate(`/${pathArr[1]}/${pathArr[2]}/leads/view/${e}/${currLead}`);
      }
    } else if (pathArr[1] === "leads") {
      navigate(`/leads/view/${e}/${currLead}`);
    }
  };

  return (
    <div
      className={`${styles.drawer} ${
        projectStatus === "Lead Lost" ||
        projectStatus === "Cancelled" ||
        projectStatus === "Estimate Not Sold"
          ? "lost"
          : projectStatus === "On Hold"
          ? "hold"
          : projectStatus === "Closed Out"
          ? "closed-out"
          : ""
      }`}
    >
      {fetching ? (
        <Loader minHeight="100vh" width="100%" />
      ) : (
        <>
          <ViewLeadHeader handleClose={handleClose} />
          <Tabs
            className={styles.tabs}
            activeKey={params.tab}
            onChange={onChange}
          >
            <Tabs.TabPane tab="DETAILS" key="details">
              <Details />
            </Tabs.TabPane>
            <Tabs.TabPane tab="POSTS" key="posts">
              <Activities />
            </Tabs.TabPane>
            <Tabs.TabPane tab="RELATED" key="related">
              <Related lead />
            </Tabs.TabPane>
          </Tabs>
        </>
      )}
    </div>
  );
};

export default ViewLead;
