import React from "react";
import BarChartWithSwitch from "Reports/BarChartWithSwitch";

export default function SalesByOrigin() {
  return (
    <BarChartWithSwitch
      path="/reports/salesByOrigin"
      _value="Value"
      secondary="Count"
      report="SALES BY CAMPAIGN"
      headers={["Origin", "Total"]}
    />
  );
}
