import React, { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { forgotPassword } from "Actions/authActions";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Button from "antd/es/button";
import Typography from "antd/es/typography";
import Card from "antd/es/card";
import message from "antd/es/message";
import Email from "Icons/Email";
import Logo from "Assets/logo-200x96.png";

const { Title } = Typography;

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onFinish = useCallback(
    (values) => {
      values.cb = () => {
        message.success(
          "A link to reset your password has been emailed to you"
        );
        setLoading(false);
      };
      dispatch(forgotPassword(values));
    },
    [dispatch]
  );

  function onFinishFailed(errorInfo) {
    setLoading(false);
    message.error("All fields must be completed");
  }

  const goToSignin = () => {
    navigate("/");
  };

  return (
    <Row
      type="flex"
      justify="center"
      align="middle"
      style={{ height: "100%", position: "absolute", width: "100%" }}
      className="public-background"
    >
      <Col xs={22} sm={20} md={16} lg={14} xl={12} className="text-center">
        <Row type="flex" justify="center">
          <Col xs={24}>
            <img
              src={Logo}
              alt="story-logo"
              style={{
                display: "block",
                margin: "auto",
                marginBottom: 24,
                width: 200,
              }}
            />
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col style={{ maxWidth: 353 }}>
            <Card>
              <Row type="flex" justify="center">
                <Col xs={24}>
                  <Title
                    level={3}
                    style={{ textAlign: "center", color: "#1890ff" }}
                  >
                    Don't worry, it happens to everyone!
                  </Title>
                </Col>
              </Row>
              <Form
                hideRequiredMark={true}
                colon={false}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
              >
                <Row type="flex" justify="center">
                  <Col xs={24}>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Email!",
                        },
                        {
                          type: "email",
                          message: "Must be a valid Email",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Email"
                        prefix={<Email size={16} type="email" />}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item name="city" className="hidden">
                  <Input />
                </Form.Item>
                <Row type="flex" justify="center">
                  <Col xs={24}>
                    <Form.Item style={{ marginBottom: 0 }}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        block
                        loading={loading}
                        disabled={loading}
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
            <Row type="flex" justify="center">
              <Col xs={24} className="text-center" style={{ paddingTop: 16 }}>
                Remembered your credentials?{" "}
                <Button type="link" onClick={goToSignin}>
                  Sign In
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ForgotPassword;
