import React from "react";
import styles from "./styles.module.scss";
import Card from "antd/es/card";
import Badge from "antd/es/badge";
import dayjs from "dayjs";
import Row from "antd/es/row";
import Col from "antd/es/col";
import AntTag from "antd/es/tag";

const statusColors = {
  "Not Needed": "#00c292",
  Pulled: "#00c292",
  Needed: "#ff7056",
  Undetermined: "#ff7056",
};

const Column = ({ title, tags }) => {
  return (
    <div className={styles.column}>
      <div className={styles.header}>
        <h4 className="withIcon">
          {title} <Badge count={tags.length} showZero />
        </h4>
      </div>
      <div className={styles.content}>
        {tags.length > 0 ? (
          tags.map((t) => <Tag key={t._id} content={t} />)
        ) : (
          <Card
            size="small"
            className={`z-depth-1 ${styles.empty} ${styles.tag}`}
            bordered={false}
          >
            <b>No</b> tags in <b>{title}</b> stage
          </Card>
        )}
      </div>
    </div>
  );
};

const Tag = ({ content }) => {
  return (
    <Card
      hoverable
      bordered={false}
      className={`z-depth-1 ${styles.tag}`}
      size="small"
      style={{ marginBottom: 8 }}
      // TODO: show all related project scopes
      title={
        <>
          <b>{content ? content.customerId : ""}</b> -{" "}
          {content ? content.customerName : ""}
        </>
      }
      extra={
        content ? (
          <AntTag color="#108ee9">
            {dayjs(content.dateSold).format("M/D/YY")}
          </AntTag>
        ) : undefined
      }
    >
      <Row gutter={8}>
        <Col xs={24} style={{ marginBottom: 8 }}>
          <AntTag
            color={statusColors[content.permitStatus]}
            style={{ width: "100%" }}
          >
            Permit Status: <strong>{content.permitStatus}</strong>
          </AntTag>
        </Col>
      </Row>

      <Row gutter={8}>
        <Col xs={24}>
          <b>Type</b>: {content.name}
          {content.workOrder && content.workOrder.roofType ? (
            <>
              <b>|</b> {content.workOrder.roofType.join(", ")}
            </>
          ) : (
            ""
          )}
        </Col>
      </Row>

      <Row gutter={8}>
        <Col xs={24}>
          <b>Material</b>:{" "}
          {content.workOrder ? (
            <span>
              {content.workOrder.manufacturer} <b>|</b>{" "}
              {content.workOrder.productLine} <b>|</b>{" "}
              {content.workOrder.squares} sq
            </span>
          ) : (
            ""
          )}
        </Col>
      </Row>
      <Row gutter={8}>
        <Col xs={24}>
          <b>Project Owner</b>:{" "}
          {content.workOrder ? content.workOrder.projectManagerName : ""}
        </Col>
      </Row>

      <br />

      {content.workOrder && (
        <Row gutter={8}>
          <Col xs={24}>
            {content.workOrder.projectAddressStreet},{" "}
            {content.workOrder.projectAddressCity}
          </Col>
        </Row>
      )}
    </Card>
  );
};

export default Column;
