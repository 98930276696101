import React, { useState, useEffect } from "react";
import message from "antd/es/message";
import List from "antd/es/list";
import Card from "antd/es/card";
import api from "API";
import percentFormatter from "Utils/percentFormatter";
import ArrowDown from "Icons/ArrowDown";
import styles from "./styles.module.scss";

export default function ProfitDeviation() {
  const [state, setState] = useState([]);
  const [headers] = useState([
    "Salesman",
    "Last Month",
    "Annual Avg.",
    "Trend",
  ]);

  useEffect(() => {
    getReport();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getReport = async () => {
    try {
      const res = await api.get("/reports/profitDeviation");

      setState(res.data);
    } catch (err) {
      console.log("err", err);
      message.error("Error getting reports");
    }
  };

  return (
    <div style={{ paddingBottom: 24 }} className="content-inner">
      <Card
        bordered={false}
        className="z-depth-1"
        style={{
          marginTop: 24,
          marginLeft: 24,
          marginRight: 24,
        }}
        bodyStyle={{
          padding: 0,
        }}
      >
        <List>
          <List.Item className={styles.listHeader}>
            {headers.map((header, i) => {
              if (i === 0) {
                return <List.Item.Meta key={header} title={header} />;
              } else {
                return (
                  <List.Item.Meta
                    key={header}
                    title={header}
                    className={styles.listRight}
                  />
                );
              }
            })}
          </List.Item>

          {state.map((t, i) => {
            return (
              <List.Item key={t.name + i} className={styles.listItem}>
                <List.Item.Meta title={t.name} />
                <List.Item.Meta
                  title={percentFormatter(t.lastMonthPD)}
                  className={styles.listRight}
                />
                <List.Item.Meta
                  title={percentFormatter(t.currentPD)}
                  className={styles.listRight}
                />
                <List.Item.Meta
                  title={
                    t.trend > 0 ? (
                      <span>
                        <ArrowDown
                          className={styles.iconTrendPositive}
                          size={18}
                        />{" "}
                        {percentFormatter(t.trend)}
                      </span>
                    ) : t.trend < 0 ? (
                      <span>
                        <ArrowDown size={18} /> {percentFormatter(t.trend)}
                      </span>
                    ) : (
                      percentFormatter(t.trend)
                    )
                  }
                  className={`${styles.listRight} ${
                    t.trend > 0
                      ? styles.trendPositive
                      : t.trend < 0
                      ? styles.trendNegative
                      : ""
                  }`}
                />
              </List.Item>
            );
          })}
        </List>
      </Card>
    </div>
  );
}
