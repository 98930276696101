export default function convertOffset(date) {
  const tz = date.getTimezoneOffset();
  let hours = Math.floor(tz / 60);
  hours = hours + "";
  let operator = hours.charAt(0);
  if (operator === "-") {
    hours = hours.slice(0, 1);
    hours = hours.padStart(2, "0");
    hours = "+" + hours;
  } else {
    hours = hours.padStart(2, "0");
    hours = "-" + hours;
  }
  let minutes = tz % 60;
  minutes = minutes + "";
  minutes = minutes.padEnd(2, 0);
  return `${hours}:${minutes}`;
}
