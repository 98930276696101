import React, { useState } from "react";
import Form from "antd/es/form";
import Button from "antd/es/button";
import Input from "antd/es/input";
import message from "antd/es/message";

const Swarm = ({ form }) => {
  const [loading, setLoading] = useState(false);

  const swarm = () => {
    if (navigator.geolocation) {
      let latlng = {};
      let geocoder = new window.google.maps.Geocoder();
      setLoading(true);
      navigator.geolocation.getCurrentPosition((position) => {
        latlng.lat = position.coords.latitude;
        latlng.lng = position.coords.longitude;

        geocoder.geocode({ location: latlng }, (results, status) => {
          if (status === "OK") {
            if (results[0]) {
              form.setFieldsValue({
                location: results[0].formatted_address,
              });
            } else {
              message.error("No results found!");
            }
          } else {
            message.error(`Geocoder failed due to: ${status}`);
          }
          setLoading(false);
        });
      });
    } else {
      message.error("Geolocation is not available on this device!");
    }
  };

  return (
    <>
      <Form.Item
        label="Location"
        name="location"
        rules={[
          {
            required: true,
            message: `Please provide an amount`,
          },
        ]}
      >
        <Input disabled />
      </Form.Item>
      <Button
        block
        type="primary"
        onClick={swarm}
        loading={loading}
        disabled={loading}
        style={{ marginBottom: 16 }}
      >
        Get Location
      </Button>

      <Form.Item label="Comments" name="comments">
        <Input.TextArea autosize={{ minRows: 4 }} />
      </Form.Item>
    </>
  );
};

export default Swarm;
