import React, { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateActivitiesAndHashtags } from "Actions/dataActions";
import Input from "antd/es/input";
import Form from "antd/es/form";
import Select from "antd/es/select";
import Button from "antd/es/button";
import message from "antd/es/message";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import Attachments from "Components/images/Attachments";
import api from "API";
import CancelButton from "Components/CancelButton";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";
import MarkUpHelper from "Components/MarkUpHelper";

const messageKey = "emailKey";

const ComposeEmail = ({
  unsavedChanges,
  setUnsavedChanges,
  handleClose,
  type,
}) => {
  const dispatch = useDispatch();
  const formDetails = useSelector((state) => state.formState.email.formDetails);
  const email = useSelector((state) => state.dataState.details.email);
  const viewableActivities = useSelector(
    (state) => state.dataState.viewableActivities
  );
  const [attachments, setAttachments] = useState(formDetails.content || []);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = useCallback(
    async (values) => {
      try {
        setLoading(true);
        // message.loading("Sending Email...", 0);
        message.loading({
          content: "Sending email...",
          duration: 0,
          key: messageKey,
        });
        values._projectId = attachments[0]._projectId;
        values._attachments = attachments;

        const res = await api.post("/activity/email-files", values);

        dispatch(
          updateActivitiesAndHashtags({
            viewableActivities,
            _projectId: attachments[0]._projectId,
            data: res.data,
          })
        );

        // message.success("Email sent");
        message.success({
          content: "Email sent",
          duration: 1.5,
          key: messageKey,
        });
        handleClose(true);
      } catch (err) {
        setLoading(false);
        // message.error("Error sending email");
        message.error({
          content: "Error sending email",
          duration: 1.5,
          key: messageKey,
        });
      }
    },
    [attachments, dispatch, viewableActivities, handleClose]
  );

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error sending email");
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  const removeImage = (img) => {
    setAttachments(attachments.filter((i) => i._id !== img._id));
  };

  const cancel = () => {
    handleClose();
  };

  return (
    <Form
      form={form}
      className="form"
      autoComplete="off"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      initialValues={{
        to:
          email && formDetails.emails
            ? [email, ...formDetails.emails]
            : formDetails.emails
            ? [...formDetails.emails]
            : email
            ? [email]
            : undefined,
      }}
    >
      <RemoveAutocomplete />

      <FormBody
        className="content-inner"
        style={{
          paddingLeft: 24,
          paddingTop: 24,
          paddingRight: 24,
          paddingBottom: 8,
          maxHeight: type === "modal" ? "calc(100vh - 161px)" : "unset",
        }}
      >
        <Form.Item
          name="to"
          label="To"
          rules={[
            {
              required: true,
              message: "You must include at least one email",
            },
          ]}
        >
          <Select mode="tags" />
        </Form.Item>

        <Form.Item
          name="subject"
          label="Subject"
          rules={[
            {
              required: true,
              message: "You must include a subject",
            },
          ]}
        >
          <InputNoAutoComplete id="subject" />
        </Form.Item>

        <Form.Item
          name="body"
          label={<MarkUpHelper title="Body" />}
          rules={[{ required: true, message: "You must include a body" }]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>

        {attachments.length > 0 && (
          <Attachments attachments={attachments} removeImage={removeImage} />
        )}
      </FormBody>
      <FormControls className="sticky-footer">
        <CancelButton handleClick={cancel} />
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={loading}
        >
          Send
        </Button>
      </FormControls>
    </Form>
  );
};

export default ComposeEmail;
