import React, { useState, useEffect } from "react";
import Button from "antd/es/button";
import message from "antd/es/message";
import FormControls from "Components/FormControls";
import styles from "./styles.module.scss";
import Clipboard from "react-clipboard.js";

const CopyDetails = ({ stringToCopy, setStringToCopy }) => {
  const [copyableContent, setCopyableContent] = useState("");

  useEffect(() => {
    updateCopyableContent();
  }, [stringToCopy]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateCopyableContent = () => {
    let _copyablecontent = "";
    stringToCopy.map((s, i) => {
      if (i === 0) {
        _copyablecontent = s;
      } else {
        _copyablecontent += `\n${s}`;
      }
      return s;
    });
    setCopyableContent(_copyablecontent);
  };

  function copied() {
    message.success("Content copied to clipboard");
    setStringToCopy([]);
  }

  function clearCopyableContent() {
    setStringToCopy([]);
  }

  return (
    <FormControls
      className={`${styles.shareContentContainer} ${
        stringToCopy.length > 0 ? styles.selecting : ""
      }`}
    >
      <div className={styles.shareContent}>
        <Clipboard
          data-clipboard-text={copyableContent}
          component="div"
          onSuccess={copied}
        >
          <Button type="primary">Copy</Button>
        </Clipboard>

        <Button type="danger" onClick={clearCopyableContent}>
          Cancel
        </Button>
      </div>
    </FormControls>
  );
};

export default CopyDetails;
