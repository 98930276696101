import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openForm } from "Actions/formActions";
import Row from "antd/es/row";
import Col from "antd/es/col";
import BalanceCard from "../BalanceCard";
import Transactions from "../Transactions";
import ActionCard from "../ActionCard";
import JobsiteTarget from "Forms/MarketingPortalForms/JobsiteTarget";
import FollowUpLetter from "Forms/MarketingPortalForms/FollowUpLetter";
import ThankYouLetter from "Forms/MarketingPortalForms/ThankYouLetter";
import BullseyeArrowIcon from "Icons/BullseyeArrow";
import EmailIcon from "Icons/Email";
import SendIcon from "Icons/Send";
import FishIcon from "Icons/Fish";
import message from "antd/es/message";

import FormContainer from "Forms/FormContainer";

import Grid from "antd/es/grid";

const { useBreakpoint } = Grid;

const Desktop = ({ balance, transactions, remaining, updateInformation }) => {
  const screens = useBreakpoint();
  const dispatch = useDispatch();
  const jobsiteTarget = useSelector(
    (state) => state.formState.jobsiteTarget.open
  );
  const followUpLetter = useSelector(
    (state) => state.formState.followUpLetter.open
  );
  const thankYouLetter = useSelector(
    (state) => state.formState.thankYouLetter.open
  );

  const open = useCallback(
    (form) => {
      if ((form === "createLead" && remaining > 0) || form !== "createLead") {
        dispatch(
          openForm(form, {
            marketing: true,
            callback: updateInformation,
          })
        );
      } else {
        message.error("You do not have an fishing opportunities left");
      }
    },
    [dispatch, remaining, updateInformation]
  );

  return (
    <div style={{ padding: 24, height: "100%" }}>
      <Row gutter={16} style={{ height: "100%" }}>
        <Col xs={8} style={{ height: "100%" }}>
          <BalanceCard balance={balance} />
          <Transactions transactions={transactions} />
        </Col>
        <Col xs={16}>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={12}>
              <ActionCard
                title={
                  <span>
                    Go Fish 20 <small>({remaining} Left)</small>
                  </span>
                }
                description='Choose 20 "unicorn" properties. We will mail them every month!'
                icon={FishIcon}
                color="#108ee9"
                action="createLead"
                openForm={open}
                balance={balance}
              />
            </Col>
            <Col xs={24} sm={24} md={12}>
              <ActionCard
                title="Jobsite Target"
                description="Have a project going on? Let us canvas it for you!"
                icon={BullseyeArrowIcon}
                color="#424242"
                action="jobsiteTarget"
                openForm={open}
                balance={balance}
              />
            </Col>
            <Col xs={24} sm={24} md={12}>
              <ActionCard
                title="Follow Up Letter"
                description="Want to follow-up on that lead/estimate? We can mail it for you!"
                icon={EmailIcon}
                color="#ff7056"
                action="followUpLetter"
                openForm={open}
                balance={balance}
              />
            </Col>
            <Col xs={24} sm={24} md={12}>
              <ActionCard
                title="Thank You Letter"
                description="Want to say thank you to your valued customer? We can mail it for you!"
                icon={SendIcon}
                color="#faad14"
                action="thankYouLetter"
                openForm={open}
                balance={balance}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0 },
        }}
        type={!screens.md ? "drawer" : "modal"}
        width={!screens.md ? "100%" : 320}
        title="Jobsite Target"
        visible={jobsiteTarget}
        form={JobsiteTarget}
        formType="jobsiteTarget"
      />

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0 },
        }}
        type={!screens.md ? "drawer" : "modal"}
        width={!screens.md ? "100%" : 320}
        title="Follow Up Letter"
        visible={followUpLetter}
        form={FollowUpLetter}
        formType="followUpLetter"
      />

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0 },
        }}
        type={!screens.md ? "drawer" : "modal"}
        width={!screens.md ? "100%" : 320}
        title="Thank You Letter"
        visible={thankYouLetter}
        form={ThankYouLetter}
        formType="thankYouLetter"
      />
    </div>
  );
};

export default Desktop;
