import React from "react";
import Form from "antd/es/form";
import Input from "antd/es/input";

const Apparel = () => {
  return (
    <>
      <Form.Item
        label="What are you requesting"
        name="explanation"
        rules={[
          {
            required: true,
            message:
              "You must provide an explanation of what you ware requesting!",
          },
        ]}
      >
        <Input.TextArea autosize={{ minRows: 4 }} />
      </Form.Item>

      <Form.Item
        name="quantities"
        label="Quantities and Sizing"
        rules={[
          {
            required: true,
            message: "You must provide quantities and sizes",
          },
        ]}
      >
        <Input.TextArea autosize={{ minRows: 4 }} />
      </Form.Item>
    </>
  );
};

export default Apparel;
