import React from "react";
import Drawer from "antd/es/drawer";
import Grid from "antd/es/grid";
import Media from "Components/Activities/Media";
import useBack from "Hooks/useBack";

const { useBreakpoint } = Grid;

const ViewAllMedia = ({ visible, allMedia }) => {
  const screens = useBreakpoint();
  const onClose = useBack();

  return (
    <Drawer
      visible={visible}
      onClose={onClose}
      closable={false}
      width={!screens.sm ? "100%" : 400}
      destroyOnClose
      forceRender={false}
      bodyStyle={{ padding: 0 }}
    >
      <Media onClose={onClose} allMedia={allMedia} />
    </Drawer>
  );
};

export default ViewAllMedia;
