import React from "react";
import timeStamp from "Utils/timeStamp";
import styles from "./styles.module.scss";
import CommentImage from "Components/Activities/CommentImage";
import generateAvatar from "Utils/generateAvatar";
import Comment from "antd/es/comment";
import Avatar from "antd/es/avatar";
import ActionRow from "Components/Activities/ActionRow";
import nomatch from "Assets/avatars/default.png";

const Update = ({
  comment,
  _activityId,
  _user,
  _projectId,
  _customerId,
  _projectStatus,
  reply,
}) => {
  return (
    <div key={comment._id} className={styles.updateCommentContainer}>
      <div className={styles.updateComment}>
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: comment.content }}
        />
      </div>
      {comment.images.length > 0 && (
        <div className={styles.imageRow} style={{ paddingLeft: 0 }}>
          {comment.images.map((image) => (
            <CommentImage image={image} key={image._id} />
          ))}
        </div>
      )}
      <div className={styles.actionRow}>
        <span className={styles.timeStamp}>{timeStamp(comment.created)}</span>
      </div>
      {comment.replies.map((reply, index) => (
        <div className={styles.reply} key={reply._id}>
          {reply.update ? (
            <div className={styles.updateComment}>
              <div
                className={styles.content}
                dangerouslySetInnerHTML={{ __html: reply.content }}
              />
            </div>
          ) : (
            <Comment
              avatar={
                <Avatar
                  src={generateAvatar(reply.avatar, reply.createdBy)}
                  icon={<img alt="avatar" src={nomatch} />}
                />
              }
              content={
                <span
                  className={styles.content}
                  dangerouslySetInnerHTML={{ __html: reply.content }}
                />
              }
            />
          )}

          {reply.images.length > 0 && (
            <div className={styles.imageRow}>
              {reply.images.map((image) => (
                <CommentImage key={image._id} image={image} />
              ))}
            </div>
          )}
          <ActionRow
            update={true}
            comment={comment}
            _projectId={_projectId}
            _customerId={_customerId}
            _projectStatus={_projectStatus}
            _user={_user}
            _activityId={_activityId}
            _reply={reply}
            index={index}
          />
        </div>
      ))}
    </div>
  );
};

export default Update;
