import React, { useCallback, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchTable, setUserFilters } from "Actions/tableActions";
import List from "antd/es/list";
import Button from "antd/es/button";
import Grid from "antd/es/grid";
import message from "antd/es/message";
import Download from "Icons/Download";
import Filter from "Icons/Filter";
import Add from "Icons/Add";
import Search from "Icons/Search";
import api from "API";
import FilterDropdown from "Components/TemplatesAndFiltersDropdown";
import FilterDrawer from "../Filter";
import styles from "./styles.module.scss";
import csv from "Utils/csv";
import usePrevious from "Hooks/usePrevious";
import Form from "antd/es/form";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";

const { useBreakpoint } = Grid;
let searchTimeout;

const TableHeader = ({
  downloadBtn = true,
  exportFunc = undefined,
  filterBtn = true,
  filters = [],
  addFunc = undefined,
  showSearch = true,
  hideFiltersDrop = false,
  rowsLength = undefined,
  handleBack = undefined,
  bordered = true,
  reportToFetch = undefined,
  backgroundColor,
}) => {
  const dispatch = useDispatch();
  const table = useSelector((state) => state.tableState.table);
  const col = useSelector((state) => state.tableState.col);
  const order = useSelector((state) => state.tableState.order);
  const search = useSelector((state) => state.tableState.search);
  const activeFilter = useSelector((state) => state.tableState.activeFilter);
  const globalFilters = useSelector((state) => state.tableState.globalFilters);
  const userFilters = useSelector((state) => state.tableState.userFilters);
  const rows = useSelector((state) => state.tableState.rows);
  const screens = useBreakpoint();
  const [showFilter, setShowFilter] = useState(false);
  const prevSearch = usePrevious(search);

  const [form] = Form.useForm();

  useEffect(() => {
    if ((prevSearch && !search) || (search && search.length === 0)) {
      form.setFieldsValue({
        "none-your-business": "",
      });
    } else if (!prevSearch && search) {
      form.setFieldsValue({
        "none-your-business": search,
      });
    }
  }, [search, prevSearch]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChange = useCallback(
    (value) => {
      clearTimeout(searchTimeout);
      searchTimeout = setTimeout(() => {
        dispatch(
          fetchTable({
            table,
            col,
            order,
            search: value,
            activeFilter,
          })
        );
      }, 300);
    },
    [dispatch, table, col, order, activeFilter]
  );

  const setActiveFilter = useCallback(
    (filter) => {
      dispatch(
        fetchTable({
          table,
          col,
          order,
          search,
          activeFilter: filter,
        })
      );
    },
    [dispatch, table, col, order, search]
  );

  const download = async () => {
    try {
      const response = await api.post(
        `/${table}/exportTable/${col}/${order}${search ? "/" + search : ""}`,
        activeFilter.filter ? activeFilter.filter : activeFilter
      );
      if (response.data.length > 0) {
        csv(table + ".csv", response.data);
      } else {
        message.error("No data to download");
      }
    } catch (err) {
      console.log("err", err);
      message.error("Error downloading table");
    }
  };

  const openFilter = () => {
    setShowFilter(true);
  };

  return (
    <>
      <List
        bordered={bordered}
        className={`${styles.tableHeader} ${bordered ? styles.bordered : ""}`}
        style={
          backgroundColor
            ? { background: backgroundColor, backgroundColor }
            : {}
        }
      >
        <List.Item>
          <List.Item.Meta
            className={backgroundColor ? styles.whiteText : ""}
            description={
              hideFiltersDrop ? (
                `${reportToFetch} | ${rowsLength} Total Rows`
              ) : (
                <FilterDropdown
                  type="Filter"
                  btnStyle={{ float: "left" }}
                  className={styles.dropdown}
                  userArray={userFilters}
                  globalArray={globalFilters}
                  activeKey={activeFilter ? activeFilter._id : null}
                  activeFilter={activeFilter}
                  onClick={setActiveFilter}
                  setArray={setUserFilters}
                  size={rows ? rows.length : 0}
                />
              )
            }
          />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={
              <div className="action-container">
                {screens.md && showSearch && (
                  <Form
                    autoComplete="off"
                    style={{
                      width: "calc(100% - 120px",
                      display: "inline-block",
                    }}
                    form={form}
                  >
                    <Form.Item name="none-your-business" style={{ margin: 0 }}>
                      <InputNoAutoComplete
                        prefix={<Search size={18} />}
                        placeholder="Search Table"
                        allowClear
                        onChange={onChange}
                        autoComplete="dont-use-autocomplete"
                      />
                    </Form.Item>
                  </Form>
                )}

                {addFunc && (
                  <Button
                    type="text"
                    shape="circle"
                    onClick={addFunc}
                    style={{ marginLeft: 8 }}
                  >
                    <Add />
                  </Button>
                )}

                {downloadBtn && (
                  <Button
                    type="text"
                    shape="circle"
                    onClick={download}
                    style={{ marginLeft: 8 }}
                  >
                    <Download />
                  </Button>
                )}

                {exportFunc && (
                  <Button
                    type="text"
                    shape="circle"
                    onClick={exportFunc}
                    style={{ marginLeft: 8 }}
                  >
                    <Download color="#ffffff" />
                  </Button>
                )}

                {filterBtn && (
                  <Button
                    type="text"
                    shape="circle"
                    onClick={openFilter}
                    style={{ marginLeft: 8 }}
                  >
                    <Filter />
                  </Button>
                )}

                {handleBack && (
                  <Button
                    type="primary"
                    // shape="circle"
                    onClick={handleBack}
                    style={{ marginLeft: 8 }}
                  >
                    Back to Dashboard
                  </Button>
                )}
              </div>
            }
            className="table-right col action-bar"
          />
        </List.Item>
      </List>

      <FilterDrawer
        visible={showFilter}
        showFilter={setShowFilter}
        filters={filters}
      />
    </>
  );
};

export default TableHeader;
