import React from "react";
import Modal from "antd/es/modal";
import Typography from "antd/es/typography";
import InstallImage from "Assets/install-image.png";
import Export from "Icons/Export";
const { Title, Text } = Typography;

const InstallInstructions = ({ visible, setVisible }) => {
  return (
    <Modal
      centered
      visible={visible}
      onCancel={setVisible}
      footer={null}
      destroyOnClose
      width={380}
      style={{ borderRadius: 4 }}
      bodyStyle={{ padding: 0, borderRadius: 4 }}
      closable={false}
    >
      <img
        src={InstallImage}
        style={{
          // margin: "auto",
          marginBottom: 12,
          marginTop: 52,
          // display: "block",
          width: "100%",
        }}
        alt="install"
      />

      <div className="text-center" style={{ padding: 24 }}>
        <Title>INSTALL STORY</Title>

        <Text>
          Install this application on your home screen for quick and easy access
          when you're on the go.
        </Text>
      </div>
      <div
        style={{
          marginTop: 58,
          background: "#f2f2f2",
          textAlign: "center",
          height: 38,
          lineHeight: "38px",
          borderBottomRightRadius: 4,
          borderBottomLeftRadius: 4,
        }}
      >
        <Text>Just tap</Text> <Export color="#1890ff" />{" "}
        <Text>then "Add to Home Screen"</Text>
      </div>
    </Modal>
  );
};

export default InstallInstructions;
