import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Form from "antd/es/form";
import Upload from "antd/es/upload";
import Button from "antd/es/button";
import message from "antd/es/message";
import FileOutlined from "@ant-design/icons/FileOutlined";
import api from "API";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import styles from "./styles.module.scss";
import CancelButton from "Components/CancelButton";
import MarkUpHelper from "Components/MarkUpHelper";
import Input from "antd/es/input";

import axios from "axios";
import generateFilePreview from "Utils/generateFilePreview";
import generateFileSize from "Utils/generateFileSize";
import List from "antd/es/list";
import UploadRow from "Components/images/UploadRow";
import isPdf from "Utils/isPdf";
import pdf from "Assets/pdf.svg";

const messageKey = "messageKey";

const CompanyPost = ({
  unsavedChanges,
  setUnsavedChanges,
  handleClose,
  type,
}) => {
  const formDetails = useSelector(
    (state) => state.formState.companyPost.formDetails
  );
  const [loading, setLoading] = useState(false);
  const [_fileList, _setFileList] = useState([]);

  const [uploads, setUploads] = useState({});
  const [uploading, setUploading] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    if (uploading) {
      let _uploading = false;
      Object.keys(uploads).map((key) => {
        if (uploads[key].upload !== 100 && uploads[key].upload !== true) {
          _uploading = true;
          return key;
        }
        return key;
      });
      if (!_uploading) {
        setUploading(false);
      }
    }
  }, [uploads]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleBeforeUpload = async (file) => {
    try {
      setUploading(true);
      const size = await generateFileSize(file.size);
      let preview;
      if (isPdf(file.name)) {
        preview = pdf;
      } else {
        preview = await generateFilePreview(file);
      }
      setUploads((prv) => {
        return {
          ...prv,
          [file.uid]: {
            preview,
            name: file.name,
            size,
            failed: false,
            upload: 0,
          },
        };
      });
      return file;
    } catch (err) {
      console.log("beforeUpload err", err);
    }
  };

  const handleUpload = async ({ file, onProgress }) => {
    try {
      let fileName = `${new Date().getTime()}-${file.name.replace(
        /[^a-zA-Z0-9.]/gi,
        "_"
      )}`;
      const src = `https://s3.us-east-2.amazonaws.com/pr-crm/company-posts/${fileName}`;
      let thumbnail;
      const ext = src.split(".").pop().toLowerCase();
      if (ext !== "pdf")
        thumbnail = `https://s3.us-east-2.amazonaws.com/pr-crm-thumbnail/company-posts/${fileName}`;

      _setFileList((prevState) => [
        ...prevState,
        {
          originFileObj: file,
          _id: file.uid,
          uid: file.uid,
          name: file.name,
          mediaType: isPdf(src) ? "pdf" : "image",
          _projectId: formDetails._projectId,
          size: file.size,
          type: file.type,
          src,
          thumbnail,
        },
      ]);

      const res = await api.post("/company-posts/image-upload", {
        type: file.type,
        name: fileName,
        _projectId: formDetails._projectId,
      });
      await axios
        .put(res.data, file, {
          headers: {
            "Content-Type": file.type,
          },
          onUploadProgress: (e) => {
            onProgress(e);
          },
        })
        .catch(function (err) {
          console.log("upload err", err);

          setUploads((prv) => {
            return {
              ...prv,
              [file.uid]: {
                ...prv[file.uid],
                failed: true,
              },
            };
          });

          _setFileList((prevState) => {
            const _filteredFileList = prevState.filtered(
              (prv) => prv.uid !== file.uid
            );
            return _filteredFileList;
          });
        });
    } catch (err) {
      console.log("handleUpload err", err);
    }
  };

  const handleProgress = (progress, file) => {
    const _progress = parseInt((progress.loaded * 100) / progress.total, 10);
    setUploads((prv) => {
      return {
        ...prv,
        [file.uid]: {
          ...prv[file.uid],
          upload: _progress,
        },
      };
    });
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);

      if (values.content.length === 0 && _fileList.length === 0) {
        setLoading(false);
        // message.error("You must provide a message or upload at least 1 file");
        message.error({
          content: "You must provide a message or upload at least 1 file",
          duration: 1.5,
          key: messageKey,
        });
      } else {
        message.loading({
          content: "Creating company post",
          duration: 0,
          key: messageKey,
        });
        await api.post("/company-posts/create", {
          content: values.content,
          images: _fileList,
        });

        // message.success("Post created");
        message.success({
          content: "Company post created",
          duration: 1.5,
          key: messageKey,
        });
        setLoading(false);
        handleClose(true);
      }
    } catch (err) {
      setLoading(false);
      // message.error("Error creating post");
      message.error({
        content: "Error creating company post",
        duration: 1.5,
        key: messageKey,
      });
      console.log("err", err);
    }
  };

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error creating company post");
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  const cancel = () => {
    handleClose();
  };

  return (
    <Form
      form={form}
      className="form"
      autoComplete="off"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      layout="vertical"
      initialValues={{ content: "" }}
    >
      <RemoveAutocomplete />

      <FormControls
        style={{
          borderTop: "none",
          borderBottom: "1px solid #f0f0f0",
          padding: 0,
        }}
      >
        <div className={styles.createActionButtons}>
          <Form.Item
            style={{ marginBottom: 0 }}
            name="images"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload
              multiple
              beforeUpload={handleBeforeUpload}
              customRequest={handleUpload}
              showUploadList={false}
              onProgress={handleProgress}
              accept="image/*, application/pdf"
            >
              <Button block>
                <FileOutlined /> Upload File(s)
              </Button>
            </Upload>
          </Form.Item>
        </div>
      </FormControls>
      <FormBody
        className="content-inner"
        style={{
          paddingLeft: 24,
          paddingTop: 24,
          paddingRight: 24,
          paddingBottom: 8,
          maxHeight:
            type === "modal"
              ? "calc(100vh - 151px - 43px - 63px)"
              : "calc(100% - 151px)",
          marginTop: type === "modal" ? 0 : 43,
        }}
      >
        <Form.Item name="content" label={<MarkUpHelper title="Post Content" />}>
          <Input.TextArea rows={4} />
        </Form.Item>
        {_fileList.length > 0 && (
          <List className={styles.uploadRow} bordered>
            {Object.keys(uploads).map((key) => {
              return (
                <UploadRow
                  key={key}
                  preview={uploads[key].preview}
                  name={uploads[key].name}
                  size={uploads[key].size}
                  upload={uploads[key].upload}
                  failed={uploads[key].failed}
                />
              );
            })}
          </List>
        )}
      </FormBody>
      <FormControls className="sticky-footer">
        <CancelButton handleClick={cancel} />
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={loading || !unsavedChanges || uploading}
        >
          Submit
        </Button>
      </FormControls>
    </Form>
  );
};

export default CompanyPost;
