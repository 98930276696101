import React, { useEffect, useState } from "react";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Typography from "antd/es/typography";
import Form from "antd/es/form";
import Checkbox from "antd/es/checkbox";
import Grid from "antd/es/grid";
import Affix from "antd/es/affix";
import styles from "./styles.module.scss";
const { useBreakpoint } = Grid;

const MaterialColumnHeaders = ({
  form,
  selected,
  setSelected,
  scrollContainer = null,
}) => {
  const screens = useBreakpoint();
  const [state, setState] = useState(false);

  useEffect(() => {
    setCheckAllStatus();
  }, [selected]); // eslint-disable-line react-hooks/exhaustive-deps

  const setCheckAllStatus = async () => {
    try {
      if (!selected) {
        setState(false);
      } else {
        let lineItems = await form.getFieldValue("lineItems");
        if (selected.length === lineItems.length) {
          setState(true);
        } else {
          setState("indeterminate");
        }
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const onChange = async (e) => {
    try {
      const { checked } = e.target;
      let lineItems = await form.getFieldValue("lineItems");
      let _selected = [];
      if (checked) {
        lineItems.forEach((a, i) => {
          a.checkbox = true;
          _selected.push(i);
        });
      } else {
        lineItems.forEach((a) => {
          a.checkbox = false;
        });
        _selected = undefined;
      }
      setState(checked);
      setSelected(_selected);
      form.setFieldsValue({
        lineItems,
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleAffixChange = (value) => {
    if (value) {
      document.getElementById("header-row").classList.add("z-depth-2");
    } else {
      document.getElementById("header-row").classList.remove("z-depth-2");
    }
  };

  return (
    <Affix
      offsetTop={0}
      target={() => scrollContainer}
      onChange={handleAffixChange}
    >
      <Row
        gutter={{ xs: 0, sm: 8, md: 12, lg: 24 }}
        id="header-row"
        className={styles.headerRow}
        style={
          screens.lg
            ? {
                padding: "12px 0",
                marginBottom: 2,
                marginLeft: 0,
                marginRight: 0,
                background: "#ffffff",
              }
            : screens.md
            ? {
                padding: "6px 0",
                marginBottom: 2,
                marginLeft: 0,
                marginRight: 0,
                background: "#ffffff",
              }
            : screens.sm
            ? {
                padding: "4px 0",
                marginBottom: 2,
                marginLeft: 0,
                marginRight: 0,
                background: "#ffffff",
              }
            : {
                padding: "12px 0 2px 0",
                marginBottom: 2,
                marginLeft: 0,
                marginRight: 0,
                background: "#ffffff",
              }
        }
      >
        <Col xs={2} sm={2} md={1}>
          <Form.Item
            style={!screens.xs ? { marginBottom: 0 } : { marginBottom: 12 }}
            className={styles.checkbox}
            valuePropName="checked"
          >
            <Checkbox
              checked={state === true ? true : false}
              indeterminate={state === "indeterminate"}
              onChange={(e) => onChange(e)}
            />
          </Form.Item>
        </Col>
        <Col xs={22} sm={22} md={23}>
          <Row gutter={{ xs: 0, sm: 8, md: 12, lg: 24 }}>
            <Col xs={{ span: 22, offset: 1 }} sm={{ span: 6, offset: 0 }}>
              <Typography.Title
                level={4}
                className="text-center"
                style={{ margin: 0 }}
              >
                Material
              </Typography.Title>
            </Col>
            <Col xs={{ span: 22, offset: 1 }} sm={{ span: 6, offset: 0 }}>
              <Typography.Title
                level={4}
                className="text-center"
                style={{ margin: 0 }}
              >
                Type
              </Typography.Title>
            </Col>
            <Col xs={{ span: 22, offset: 1 }} sm={{ span: 6, offset: 0 }}>
              <Typography.Title
                level={4}
                className="text-center"
                style={{ margin: 0 }}
              >
                Color
              </Typography.Title>
            </Col>
            <Col xs={{ span: 22, offset: 1 }} sm={{ span: 6, offset: 0 }}>
              <Typography.Title
                level={4}
                className="text-center"
                style={{ margin: 0 }}
              >
                Quantity
              </Typography.Title>
            </Col>
          </Row>
        </Col>
      </Row>
    </Affix>
  );
};

export default MaterialColumnHeaders;
