import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateSelectedActivity } from "Actions/dataActions";
import { closeForm } from "Actions/formActions";
import Drawer from "antd/es/drawer";
import Grid from "antd/es/grid";
import Images from "./Images";
import useBack from "Hooks/useBack";
import LegacyFiles from "Components/images/ImagesDrawer/LegacyFiles";

const { useBreakpoint } = Grid;

const ImageDrawer = ({ dashboard, visible, legacy, zIndex = 1000 }) => {
  const screens = useBreakpoint();
  const dispatch = useDispatch();
  const index = useSelector(
    (state) => state.formState.images.formDetails.index
  );
  const openForms = useSelector((state) => state.formState.openForms);
  const handleBack = useBack();

  const onClose = useCallback(() => {
    dispatch(
      updateSelectedActivity({
        images: [],
        index: 0,
        _id: null,
        _activityCreatedBy: undefined,
      })
    );
    if (dashboard) {
      dispatch(closeForm("images", openForms));
    } else {
      handleBack();
    }
  }, [dashboard, openForms, dispatch, handleBack]);

  return (
    <Drawer
      visible={visible}
      onClose={onClose}
      closable={false}
      width={!screens.sm ? "100%" : 400}
      destroyOnClose
      forceRender={false}
      bodyStyle={{ padding: 0 }}
      zIndex={zIndex}
    >
      {!legacy ? (
        <Images onClose={onClose} index={index} />
      ) : (
        <LegacyFiles onClose={onClose} index={index} />
      )}
    </Drawer>
  );
};

export default ImageDrawer;
