import React, { useEffect, useState, useCallback } from "react";
import {
  unsetTable,
  fetchTableFilters,
  setScrollbar,
} from "Actions/tableActions";
import { useDispatch, useSelector } from "react-redux";
import Table from "Components/Table";
import TableRow from "Components/Table/TableItems/LeadItem";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as Vlist } from "react-window";
import ViewContainer from "Components/ViewContainer";
import ViewLead from "Screens/ViewLead";
import useViewShow from "Hooks/useViewShow";

// const headers = [
//   {
//     _name: "customerId",
//     name: "ID",
//     width: "10%",
//   },
//   {
//     _name: "customerName",
//     name: "CUSTOMER",
//     width: "15%",
//   },
//   {
//     _name: "projectStatus",
//     name: "STATUS",
//     width: "14%",
//   },
//   {
//     _name: "address",
//     name: "ADDRESS",
//     width: "26%",
//   },
//   {
//     _name: "scopes",
//     name: "SCOPES",
//     width: "20%",
//     type: "scope",
//   },
//   {
//     _name: "created",
//     name: "CREATED",
//     width: "15%",
//     align: "right",
//     type: "date",
//   },
// ];
const leadsNotScheduled = "leads-not-scheduled";
// const estimatesWithNoResults = "estimates-with-no-results";
const estimatesSoldNotFlipped = "estimates-sold-not-flipped";
const leadsNeedRemeet = "leads-need-remeet";
const leadsInsuranceWIP = "leads-insurance-wip";
const estimatesWIP3Months = "estimates-wip";

const Leads = ({ selected }) => {
  const showLead = useViewShow(3, "leads", 4, "view");
  const dispatch = useDispatch();
  const rows = useSelector((state) => state.tableState.rows);
  const fetching = useSelector((state) => state.tableState.fetching);
  const _user = useSelector((state) => state.authState);
  const [itemSize] = useState(47);

  const headers = [
    {
      _name: "customerId",
      name: "ID",
      width: "10%",
    },
    {
      _name: "customerName",
      name: "CUSTOMER",
      width: "15%",
    },
    {
      _name: "projectStatus",
      name: "STATUS",
      width: "14%",
    },
    {
      _name: "address",
      name: "ADDRESS",
      width: "26%",
    },
    {
      _name: "scopes",
      name: "SCOPES",
      width: "20%",
      type: "scope",
    },
    {
      _name:
        selected === leadsNotScheduled
          ? "created"
          : selected === leadsNeedRemeet || selected === leadsInsuranceWIP
          ? "needsRemeet"
          : selected === estimatesSoldNotFlipped ||
            selected === estimatesWIP3Months
          ? "estimateDate"
          : "estimateStart",
      name:
        selected === leadsNotScheduled
          ? "CREATED"
          : selected === leadsNeedRemeet || selected === leadsInsuranceWIP
          ? "LAST MET"
          : selected === estimatesSoldNotFlipped ||
            selected === estimatesWIP3Months
          ? "ESTIMATE DATE"
          : "SCHEDULED",
      width: "15%",
      align: "right",
      type: "date",
    },
  ];

  useEffect(() => {
    initTable();
    return () => dispatch(unsetTable());
  }, [selected]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTimeout(() => {
      dispatch(setScrollbar());
    });
  }, [rows, dispatch]);

  const initTable = useCallback(() => {
    dispatch(
      fetchTableFilters({
        table: "leads",
        col: "customerId",
        order: 1,
        search: undefined,
        activeFilter: selected,
        user: _user,
        scheduler: true,
      })
    );
  }, [dispatch, selected, _user]);

  const Row = ({ index, style }) => {
    const item = rows[index];
    return (
      <TableRow
        style={style}
        key={item._id}
        {...item}
        headers={headers}
        bordered
        selected={selected}
        leadManagement
      />
    );
  };

  return (
    <>
      <Table loading={fetching}>
        <Table.ColumnHeaders headers={headers} bordered noSideBorders />
        <Table.Body loading={fetching} bordered>
          {rows.length > 0 && (
            <AutoSizer>
              {({ height, width }) => (
                <Vlist
                  height={height}
                  itemCount={rows.length}
                  itemSize={itemSize}
                  width={width}
                >
                  {Row}
                </Vlist>
              )}
            </AutoSizer>
          )}
        </Table.Body>
      </Table>

      <ViewContainer
        type="drawer"
        visible={showLead}
        form={ViewLead}
        placement="right"
        width={400}
        closable={false}
        mask={false}
      />
    </>
  );
};

export default Leads;
