import React from "react";
import Update from "Components/Activities/Update";
import Comment from "Components/Activities/Comment";

const ActivityComments = ({
  comments,
  _activityId,
  _user,
  _projectId,
  _customerId,
  _projectStatus,
  _global,
  dashboard,
  fetchPosts,
}) => {
  return (
    <div style={{ padding: 12 }}>
      {comments.map((comment) => {
        if (comment.update) {
          return (
            <Update
              comment={comment}
              key={comment._id}
              _activityId={_activityId}
              _user={_user}
              _projectId={_projectId}
              _customerId={_customerId}
              _projectStatus={_projectStatus}
              dashboard={dashboard}
            />
          );
        } else if (!comment.replyTo) {
          return (
            <Comment
              key={comment._id}
              comment={comment}
              _activityId={_activityId}
              _user={_user}
              _projectId={_projectId}
              _customerId={_customerId}
              _projectStatus={_projectStatus}
              _global={_global}
              dashboard={dashboard}
              fetchPosts={fetchPosts}
            />
          );
        } else {
          return (
            <Comment
              key={comment._id}
              replyTo
              comment={comment}
              _activityId={_activityId}
              _user={_user}
              _projectId={_projectId}
              _customerId={_customerId}
              _projectStatus={_projectStatus}
              _global={_global}
              dashboard={dashboard}
              fetchPosts={fetchPosts}
            />
          );
        }
      })}
    </div>
  );
};

export default ActivityComments;
