export default function downloadFile(base64, fileName, ext) {
  const url = `data:${
    ext === "pdf" ? "application/pdf" : `image/${ext}`
  };base64,${base64}`;
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute(
    "download",
    `${fileName ? fileName : "file"}.${ext ? ext : "pdf"}`
  );
  document.body.appendChild(link);
  link.click();
  link.remove();
}
