import React from "react";
import percentFormatter from "Utils/percentFormatter";
import styles from "./styles.module.scss";

const DealFlowLabels = ({ leadConversion, satConversion }) => {
  return (
    <div style={{ width: "100%", height: 0 }}>
      <div className={styles.leftSpacer}>
        <span></span>
      </div>
      <div className={styles.topLabelsContainer}>
        <div className={styles.labelContainer}>
          <span>
            {/* Leads
          <br />
          Generated */}
          </span>
        </div>
        <div className={styles.conversionContainer}>
          <div className={styles.arrowRight}>
            <p className={styles.conversionNumber}>
              {percentFormatter(leadConversion)}
            </p>
            <span></span>
          </div>
        </div>
        <div className={styles.labelContainer}>
          <span>
            {/* Sat
          <br />
          appointments */}
          </span>
        </div>
        <div className={styles.conversionContainer}>
          <div className={styles.arrowRight}>
            <p className={styles.conversionNumber}>
              {percentFormatter(satConversion)}
            </p>
            <span></span>
          </div>
        </div>
        <div className={styles.labelContainer}>
          <span>{/* Sold */}</span>
        </div>
      </div>
      <div className={styles.rightSpacer}> </div>
    </div>
  );
};

export default DealFlowLabels;
