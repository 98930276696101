import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "antd/es/button";
import Typography from "antd/es/typography";
import styles from "./styles.module.scss";
import _500 from "Assets/500.png";

const { Title, Text } = Typography;

const ServerError = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const goToSignin = () => {
    navigate("/");
  };

  return (
    <div className={styles.errorContainer}>
      <div
        style={{
          textAlign: "center",
          height: "calc(100% - 50px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <img
          src={_500}
          style={{ marginBottom: 24, display: "block" }}
          alt="500"
        />
        <Title level={3}>Internal Server Error</Title>
        <Text>
          MyCo Story has experienced an unexpected error. Please try again
          later.
        </Text>
        <div style={{ width: 200, marginTop: 42 }}>
          <Button type="primary" onClick={goBack} style={{ float: "left" }}>
            Back
          </Button>{" "}
          <Button
            type="primary"
            onClick={goToSignin}
            style={{ float: "right" }}
          >
            Sign in
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ServerError;
