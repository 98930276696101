import dayjs from "dayjs";
import HighCharts from "highcharts";
import HighChartsReact from "highcharts-react-official";

import React, { useState, useEffect } from "react";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Radio from "antd/es/radio";
import message from "antd/es/message";
import DatePicker from "Components/Inputs/DatePicker";
import List from "antd/es/list";
import Card from "antd/es/card";
import api from "API";
import currencyFormatter from "Utils/currencyFormatter";
import percentFormatter from "Utils/percentFormatter";
import addCommas from "Utils/addCommas";
import styles from "./styles.module.scss";

window.Highcharts = HighCharts;

const { RangePicker } = DatePicker;
const RadioGroup = Radio.Group;

const today = dayjs();
const year = new Date().getFullYear();
let yearStart = new Date(`1/1/${year}`);
yearStart = dayjs(yearStart);

HighCharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
  colors: [
    "#00c292",
    "#fec107",
    "#FF7056",
    "#343a40",
    "#01c0c8",
    "#999999",
    "#FF9655",
    "#FFF263",
    "#6AF9C4",
  ],
});

const BarChartWithSwitch = ({
  path,
  _value,
  report,
  secondary,
  headers,
  yearOnly,
}) => {
  const [value, setValue] = useState(_value);
  const [start, setStart] = useState(yearStart);
  const [end, setEnd] = useState(today);
  const [year, setYear] = useState(today);
  const [state, setState] = useState({
    totalValue: 0,
    table: [],
    options: {
      chart: {
        type: "column",
      },
      credits: {
        enabled: false,
      },
      title: {
        text: "",
      },
      subtitle: {
        text: "",
      },
      xAxis: {
        type: "category",
        labels: {
          rotation: -45,
          style: {
            fontSize: "13px",
            fontFamily: "Verdana, sans-serif",
          },
        },
      },
      yAxis: {
        title: {
          text: "",
        },
        labels: {
          formatter: function () {
            let label = this.value;
            if (this.value >= 1000000) {
              label = this.value / 1000000 + "M";
            } else if (this.value >= 1000) {
              label = this.value / 1000 + "k";
            } else if (this.value <= -1000) {
              label = this.value / 1000 + "k";
            } else if (this.value <= -1000000) {
              label = this.value / 1000000 + "M";
            }
            return `${label}`;
          },
        },
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        useHTML: true,
        formatter: function () {
          let table = "<table><tr><td><b>" + this.key + "</b></tr><tr><td>";

          if (_value === "Value") {
            table += "$";
          }

          table += HighCharts.numberFormat(this.y, 2);

          if (_value === "Percent") {
            table += "%";
          }

          table += "</td></tr></table>";

          return table;
        },
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            formatter: function () {
              if (_value === "Value") {
                return "$" + HighCharts.numberFormat(this.y, 2);
              } else if (_value === "Percent") {
                return HighCharts.numberFormat(this.y, 1) + "%";
              } else {
                return HighCharts.numberFormat(this.y, 1);
              }
            },
          },
        },
      },
      series: [
        {
          data: [],
        },
      ],
    },
  });

  useEffect(() => {
    getReport();
  }, [value, start, end, year]); // eslint-disable-line react-hooks/exhaustive-deps

  const getReport = async () => {
    try {
      let res;
      if (yearOnly) {
        res = await api.post(path, {
          year,
        });
      } else {
        res = await api.post(path, {
          start,
          end,
          value,
        });
      }

      const table = [];
      for (const x of res.data.series[0].data) {
        let obj = {};
        obj.name = x.name;
        obj.total = x.y;
        table.push(obj);
      }

      let plotOptionsFn, tooltipFn;

      if (value === "Value") {
        plotOptionsFn = function () {
          return "$" + HighCharts.numberFormat(this.y, 2);
        };
        tooltipFn = function () {
          let table = "<table><tr><td><b>" + this.key + "</b></tr>";
          table +=
            "<tr><td>$" + HighCharts.numberFormat(this.y, 2) + "</td></tr>";
          table += `</table>`;
          return table;
        };
      } else if (value === "Percent") {
        plotOptionsFn = function () {
          return HighCharts.numberFormat(this.y, 2) + "%";
        };
        tooltipFn = function () {
          let table = "<table><tr><td><b>" + this.key + "</b></tr>";
          table +=
            "<tr><td>" + HighCharts.numberFormat(this.y, 2) + "%</td></tr>";
          table += `</table>`;
          return table;
        };
      } else {
        plotOptionsFn = function () {
          return HighCharts.numberFormat(this.y, 1);
        };
        tooltipFn = function () {
          let table = "<table><tr><td><b>" + this.key + "</b></tr>";
          table +=
            "<tr><td>" + HighCharts.numberFormat(this.y, 1) + "</td></tr>";
          table += `</table>`;
          return table;
        };
      }

      setState((thisState) => {
        return {
          ...thisState,
          totalValue: res.data.totalValue,
          table,
          options: {
            ...thisState.options,
            plotOptions: {
              ...thisState.options.plotOptions,
              column: {
                ...thisState.options.plotOptions.column,
                dataLabels: {
                  ...thisState.options.plotOptions.column.dataLabels,
                  formatter: plotOptionsFn,
                },
              },
            },
            tooltip: {
              ...thisState.options.tooltip,
              formatter: tooltipFn,
            },
            series: res.data.series,
          },
        };
      });
    } catch (err) {
      console.log("err", err);
      message.error("Error getting report");
    }
  };

  const handleValueChange = (e) => {
    setValue(e.target.value);
  };

  const handleDateChange = (e) => {
    setStart(e[0]);
    setEnd(e[1]);
  };

  const handleYearChange = (e) => {
    setYear(e);
  };

  return (
    <div style={{ paddingBottom: 24 }} className="content-inner">
      <Card bordered={false} className="z-depth-1" style={{ margin: 24 }}>
        <Row gutter={16} style={{ marginBottom: 24 }}>
          <Col xs={24}>
            <div className={styles.reportTitle}>{report}</div>
          </Col>
        </Row>

        <Row gutter={16} style={{ marginBottom: 24 }}>
          <Col xs={24}>
            <div className={styles.reportHeader}>
              <div className={styles.total}>
                {value === "Value"
                  ? currencyFormatter(state.totalValue)
                  : value === "Percent"
                  ? percentFormatter(state.totalValue)
                  : addCommas(state.totalValue)}
                <small>
                  {" "}
                  TOTAL{" "}
                  {value === "Value"
                    ? "VALUE"
                    : value === "Percent"
                    ? "PERCENT"
                    : "COUNT"}
                </small>
              </div>
            </div>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginBottom: 24 }}>
          <Col xs={24}>
            <div className={styles.reportHeader}>
              <div>
                <div className="ant-form-item-label">
                  {yearOnly ? (
                    <label title="Year">Year</label>
                  ) : (
                    <label title="Date Range">Date Range</label>
                  )}
                </div>
                {yearOnly ? (
                  <DatePicker
                    picker="year"
                    allowClear={false}
                    format="YYYY"
                    defaultValue={dayjs(start, "YYYY")}
                    onChange={handleYearChange}
                  />
                ) : (
                  <RangePicker
                    allowClear={false}
                    format="MMMM DD, YYYY"
                    defaultValue={[
                      dayjs(start, "MMMM DD, YYYY"),
                      dayjs(end, "MMMM DD, YYYY"),
                    ]}
                    onChange={handleDateChange}
                    ranges={{
                      "Last 7 Days": [
                        dayjs().subtract(7, "days").startOf("day"),
                        dayjs(),
                      ],
                      "Last Week": [
                        dayjs().startOf("week").subtract(7, "days"),
                        dayjs().startOf("week").subtract(1, "day").endOf("day"),
                      ],
                      "Last Month": [
                        dayjs().startOf("month").subtract(1, "months"),
                        dayjs()
                          .startOf("month")
                          .subtract(1, "months")
                          .endOf("month"),
                      ],
                      "This Month": [
                        dayjs().startOf("month"),
                        dayjs().endOf("month"),
                      ],
                      "This Year": [dayjs().startOf("year"), dayjs()],
                      "Last Year": [
                        dayjs().subtract(1, "year").startOf("year"),
                        dayjs().subtract(1, "year").endOf("year"),
                      ],
                      TMLY: [
                        dayjs().subtract(1, "year").startOf("month"),
                        dayjs().subtract(1, "year").endOf("month"),
                      ],
                    }}
                  />
                )}
              </div>
              {!yearOnly && (
                <>
                  <div className={`${styles.vDivider} ${styles.hideSmDown}`} />
                  <div className={styles.hideSmDown}>
                    <br />
                    <br />
                    <RadioGroup onChange={handleValueChange} value={value}>
                      <Radio value={_value}>{_value}</Radio>
                      <Radio value={secondary}>{secondary}</Radio>
                    </RadioGroup>
                  </div>
                </>
              )}
            </div>
          </Col>
          <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
            <div>
              <br />
              <RadioGroup onChange={handleValueChange} value={value}>
                <Radio value={_value}>{_value}</Radio>
                <Radio value={secondary}>{secondary}</Radio>
              </RadioGroup>
            </div>
          </Col>
        </Row>
        <HighChartsReact
          highcharts={HighCharts}
          options={state.options}
          updateArgs={[true, true, true]}
        />
      </Card>

      <Card
        bordered={false}
        className="z-depth-1"
        style={{
          marginTop: 24,
          marginLeft: 24,
          marginRight: 24,
        }}
        bodyStyle={{
          padding: 0,
        }}
      >
        <List>
          <List.Item className={styles.listHeader}>
            {headers.map((header, i) => {
              if (i !== 0) {
                return (
                  <List.Item.Meta
                    key={header}
                    className={styles.listRight}
                    title={header}
                  />
                );
              } else {
                return <List.Item.Meta key={header} title={header} />;
              }
            })}
          </List.Item>

          {state.table.map((t, i) => {
            return (
              <List.Item key={t.name + i} className={styles.listItem}>
                <List.Item.Meta title={t.name} />
                <List.Item.Meta
                  title={
                    value === "Value"
                      ? currencyFormatter(t.total)
                      : value === "Percent"
                      ? percentFormatter(t.total)
                      : t.total.toString()
                  }
                  className={styles.listRight}
                />
              </List.Item>
            );
          })}

          <List.Item className={styles.listTotal}>
            <List.Item.Meta title="Total" />
            <List.Item.Meta
              className={styles.listRight}
              title={
                value === "Value"
                  ? currencyFormatter(state.totalValue)
                  : value === "Percent"
                  ? percentFormatter(state.totalValue)
                  : state.totalValue.toString()
              }
            />
          </List.Item>
        </List>
      </Card>
    </div>
  );
};

export default BarChartWithSwitch;
