import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Form from "antd/es/form";
import Button from "antd/es/button";
import message from "antd/es/message";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import api from "API";
import CancelButton from "Components/CancelButton";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";

const messageKey = "messageKey";

const MaterialBoardColumnName = ({
  unsavedChanges,
  setUnsavedChanges,
  handleClose,
  setTitle,
  type,
}) => {
  const formDetails = useSelector(
    (state) => state.formState.materialBoardAddColumn.formDetails
  );
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (formDetails.editing) {
      setTitle("Rename Column");
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onFinish = async (values) => {
    try {
      setLoading(true);

      let res;
      if (formDetails.editing) {
        // message.loading("Renaming column...", 0);
        message.loading({
          content: "Renaming column...",
          duration: 0,
          key: messageKey,
        });
        values.originalTitle = formDetails.title;
        res = await api.post("/ready-for-material/rename-column", values);
      } else {
        // message.loading("Adding column...", 0);
        message.loading({
          content: "Adding column...",
          duration: 0,
          key: messageKey,
        });

        res = await api.post("/ready-for-material/add-column", values);
      }

      if (formDetails.callback) {
        formDetails.callback(res.data);
      }

      setUnsavedChanges(false);

      if (formDetails.editing) {
        // message.success("Column renamed");
        message.success({
          content: "Column renamed",
          duration: 1.5,
          key: messageKey,
        });
      } else {
        // message.success("Column added");
        message.success({
          content: "Column added",
          duration: 1.5,
          key: messageKey,
        });
      }

      handleClose(true);
    } catch (err) {
      setLoading(false);
      console.log("err", err);
      // message.error(
      //   formDetails.editing ? "Error renaming column" : "Error adding column"
      // );
      message.error({
        content: formDetails.editing
          ? "Error renaming column"
          : "Error adding column",
        duration: 1.5,
        key: messageKey,
      });
    }
  };

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error(
      formDetails.editing ? "Error renaming column" : "Error adding column"
    );
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  const cancel = () => {
    handleClose();
  };

  return (
    <Form
      form={form}
      className="form"
      autoComplete="off"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      initialValues={{ title: formDetails.title }}
    >
      <RemoveAutocomplete />

      <FormBody
        className="content-inner"
        style={{
          paddingLeft: 24,
          paddingTop: 24,
          paddingRight: 24,
          paddingBottom: 8,
          maxHeight: type === "modal" ? "calc(100vh - 161px)" : "unset",
        }}
      >
        <Form.Item
          name="title"
          rules={[
            { required: true, message: "Column title is required" },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (formDetails.titles.includes(value)) {
                  return Promise.reject("Column title already exists");
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <InputNoAutoComplete id="title" autoFocus />
        </Form.Item>
      </FormBody>

      <FormControls className="sticky-footer">
        <CancelButton handleClick={cancel} />
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={loading || !unsavedChanges}
        >
          Submit
        </Button>
      </FormControls>
    </Form>
  );
};

export default MaterialBoardColumnName;
