import React from "react";
import { useNavigate } from "react-router-dom";
// import Progress from "antd/es/progress";
import Card from "antd/es/card";
import Col from "antd/es/col";
import Button from "antd/es/button";
import styles from "./styles.module.scss";
import dayjs from "dayjs";
import Typography from "antd/es/typography";
// import CheckCircleEmptyIcon from "Icons/CheckCircleEmpty";
// import CheckCircleIcon from "Icons/CheckCircle";
import Tag from "antd/es/tag";
import api from "API";
import List from "antd/es/list";

const OpenLead = ({ lead }) => {
  const navigate = useNavigate();

  // const goTo = (e) => {
  //   e.stopPropagation();
  //   navigate(`/dashboard/projects/view/posts/${scope._projectId}`);
  // };

  const getStatusAndGo = async (e) => {
    try {
      e.stopPropagation();
      const response = await api.get(`/get-state/${lead._id}`);
      navigate(`/dashboard/${response.data}/view/posts/${lead._id}`);
    } catch (err) {
      console.log("getStatusAndGo err", err);
    }
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <Col xs={24} sm={12} md={8}>
      <Card
        // onClick={getStatusAndGo}
        title={
          <span>
            <strong>{lead.customerId}</strong> - {lead.customerName}
          </span>
        }
        size="small"
        hoverable
        bordered={false}
        className={`z-depth-1 ${styles.scopeCard}`}
        actions={[
          <Button onClick={getStatusAndGo} type="link">
            View Lead
          </Button>,
        ]}
      >
        <Card.Meta
          style={{ width: "100%" }}
          // title={
          //   <div
          //     className={styles.flex}
          //     style={{
          //       overflow: "hidden",
          //       textOverflow: "ellipsis",
          //       whiteSpace: "nowrap",
          //     }}
          //   >
          //     <strong style={{ paddingRight: 8 }}>{lead.customerId}</strong>{" "}
          //   </div>
          // }
          description={
            <div style={{ width: "100%" }}>
              <List bordered={false} split={false}>
                {lead.companyName && (
                  <List.Item
                    style={{ padding: 0, paddingBottom: 8, width: "100%" }}
                  >
                    <List.Item.Meta
                      title="Company Name"
                      style={{ flex: "unset" }}
                    />
                    <List.Item.Meta
                      style={{ textAlign: "right" }}
                      title={lead.companyName}
                    />
                  </List.Item>
                )}

                <List.Item
                  style={{ padding: 0, paddingBottom: 8, width: "100%" }}
                >
                  <List.Item.Meta title="Customer" style={{ flex: "unset" }} />
                  <List.Item.Meta
                    style={{ textAlign: "right", justifyContent: "flex-end" }}
                    title={lead.customerName}
                  />
                </List.Item>
                <List.Item
                  style={{ padding: 0, paddingBottom: 8, width: "100%" }}
                >
                  <List.Item.Meta title="Status" style={{ flex: "unset" }} />
                  <List.Item.Meta
                    style={{ textAlign: "right", justifyContent: "flex-end" }}
                    title={lead.projectStatus}
                  />
                </List.Item>

                <List.Item
                  style={{ padding: 0, paddingBottom: 8, width: "100%" }}
                >
                  <List.Item.Meta title="Created" style={{ flex: "unset" }} />
                  <List.Item.Meta
                    style={{ textAlign: "right", justifyContent: "flex-end" }}
                    title={dayjs(lead.created).format("MMMM d, YYYY")}
                  />
                </List.Item>
                <List.Item
                  style={{ padding: 0, paddingBottom: 8, width: "100%" }}
                >
                  <List.Item.Meta title="Scheduled" style={{ flex: "unset" }} />
                  <List.Item.Meta
                    style={{ textAlign: "right", justifyContent: "flex-end" }}
                    title={
                      lead.estimateStart &&
                      dayjs(lead.estimateStart).isSameOrBefore(dayjs()) ? (
                        <Tag color="#ff7056" style={{ marginRight: 0 }}>
                          {dayjs(lead.estimateStart).format("MMMM d, YYYY")}
                        </Tag>
                      ) : lead.estimateStart ? (
                        <Tag color="#108ee9" style={{ marginRight: 0 }}>
                          {dayjs(lead.estimateStart).format("MMMM d, YYYY")}
                        </Tag>
                      ) : (
                        <Tag color="#ff7056" style={{ marginRight: 0 }}>
                          Not Scheduled
                        </Tag>
                      )
                    }
                  />
                </List.Item>
              </List>

              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                <strong>Street</strong>:{" "}
                <Typography.Link
                  onClick={stopPropagation}
                  href={`https://www.google.com/maps/place/${lead.projectAddressLat},${lead.projectAddressLng}`}
                  target="_blank"
                >
                  {lead.projectAddress}
                </Typography.Link>
              </div>

              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                <strong>Project Owner</strong>:{" "}
                {lead.projectManager && lead.projectManager.name}
              </div>
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              ></div>
            </div>
          }
        />
      </Card>
    </Col>
  );
};

export default OpenLead;
