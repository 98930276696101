import React from "react";
import Col from "antd/es/col";
import Row from "antd/es/row";
import Form from "antd/es/form";
import Button from "antd/es/button";
import Delete from "Icons/Delete";
import styles from "./styles.module.scss";
import Currency from "Components/Inputs/Currency";
import Select from "Components/Inputs/Select";
import Input from "antd/es/input";

const Commissions = ({ employees }) => {
  return (
    <Form.List name="commissions">
      {(fields, { add, remove }) => {
        const handleAdd = () => {
          add();
        };

        const onRemove = (option) => {
          remove(option);
        };
        return (
          <>
            {fields.map((field) => {
              const handleRemove = () => {
                onRemove(field.name);
              };

              return (
                <div key={`${field.key}-option`}>
                  <Row gutter={16}>
                    <Col xs={7}>
                      <Form.Item
                        {...field}
                        name={[field.name, "employee"]}
                        fieldKey={[field.name, "employee"]}
                        label="Employee"
                        rules={[
                          {
                            required: true,
                            message: "Employee is required",
                          },
                        ]}
                      >
                        <Select
                          options={employees}
                          id={[field.fieldKey, "employee"]}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={8}>
                      <Form.Item
                        {...field}
                        name={[field.name, "type"]}
                        fieldKey={[field.name, "type"]}
                        label="Type"
                        rules={[
                          {
                            required: true,
                            message: "Type is required",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={8}>
                      <Form.Item
                        {...field}
                        name={[field.name, "amount"]}
                        fieldKey={[field.name, "amount"]}
                        label="Amount"
                        rules={[
                          {
                            required: true,
                            message: "Amount is required",
                          },
                        ]}
                      >
                        <Currency id={[field.name, "amount"]} />
                      </Form.Item>
                    </Col>
                    <Col xs={1}>
                      <Button
                        tabIndex="-1"
                        type="text"
                        shape="circle"
                        danger
                        className={styles.deleteButton}
                        onClick={handleRemove}
                      >
                        <Delete />
                      </Button>
                    </Col>
                  </Row>
                </div>
              );
            })}
            <Button
              type="primary"
              block
              onClick={handleAdd}
              style={{ marginTop: 4 }}
            >
              Add Commission
            </Button>
          </>
        );
      }}
    </Form.List>
  );
};

export default Commissions;
