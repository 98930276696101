import * as actions from "./actionTypes";
import api from "API";
import axios from "axios";
import dayjs from "dayjs";

function getScrollBarWidth() {
  var inner = document.createElement("p");
  inner.style.width = "100%";
  inner.style.height = "200px";

  var outer = document.createElement("div");
  outer.style.position = "absolute";
  outer.style.top = "0px";
  outer.style.left = "0px";
  outer.style.visibility = "hidden";
  outer.style.width = "200px";
  outer.style.height = "150px";
  outer.style.overflow = "hidden";
  outer.appendChild(inner);

  document.body.appendChild(outer);
  var w1 = inner.offsetWidth;
  outer.style.overflow = "scroll";
  var w2 = inner.offsetWidth;

  if (w1 === w2) {
    w2 = outer.clientWidth;
  }

  document.body.removeChild(outer);

  return w1 - w2;
}

function scrollbarVisible() {
  const tableBody = document.getElementsByClassName("tableBody")[0];
  const antSpinContainer =
    tableBody.getElementsByClassName("ant-spin-container")[0];
  const parent = antSpinContainer.firstChild;
  const child = parent.firstChild;
  return child.clientHeight >= parent.clientHeight;
}

const leadsNotScheduled = "leads-not-scheduled";
const estimatesWithNoResults = "estimates-with-no-results";
const estimatesSoldNotFlipped = "estimates-sold-not-flipped";
const leadsNeedRemeet = "leads-need-remeet";
const leadsInsuranceWIP = "leads-insurance-wip";
const estimatesWIP3Months = "estimates-wip";

let cancelToken;
export const fetchTableFilters =
  ({ table, col, order, search = "", activeFilter, user, scheduler }) =>
  async (dispatch) => {
    try {
      const today = new Date(dayjs().endOf("day").subtract(1, "day"));
      const sevenDaysAgo = new Date(dayjs().endOf("day").subtract(7, "days"));
      const thirtyDaysAgo = new Date(dayjs().endOf("day").subtract(30, "days"));
      const ninteyDaysAgo = new Date(dayjs().endOf("day").subtract(90, "days"));

      dispatch({
        type: actions.FETCHING_TABLE,
        payload: {
          table,
          col,
          order,
          search,
          fetching: true,
        },
      });
      const filterResponse = await api.get(`/${table}/fetchFilter`);
      let _activeFilter = {};
      if (activeFilter) {
        if (activeFilter === leadsNotScheduled) {
          _activeFilter = {
            name: "Leads Not Scheduled",
            filter: {
              projectStatus: ["Lead"],
              "estimateStart-exists": false,
              fishing: [false],
              salesman: scheduler
                ? undefined
                : user.salesman === "Yes"
                ? [user._user]
                : undefined,
            },
          };
        } else if (activeFilter === estimatesWithNoResults) {
          _activeFilter = {
            name: "Estimates Past Due No Results",
            filter: {
              projectStatus: ["Lead"],
              "estimateStart-end": today,
              salesman: scheduler
                ? undefined
                : user.salesman === "Yes"
                ? [user._user]
                : undefined,
            },
          };
        } else if (activeFilter === estimatesSoldNotFlipped) {
          _activeFilter = {
            name: "Estimates Sold Not Flipped",
            filter: {
              projectStatus: ["Estimate Sold"],
              salesman: scheduler
                ? undefined
                : user.salesman === "Yes"
                ? [user._user]
                : undefined,
            },
          };
        } else if (activeFilter === leadsNeedRemeet) {
          _activeFilter = {
            name: "Leads Need Remeet 7 Days Old",
            filter: {
              projectStatus: ["Lead Needs Remeet"],
              "needsRemeet-end": sevenDaysAgo,
              salesman: scheduler
                ? undefined
                : user.salesman === "Yes"
                ? [user._user]
                : undefined,
            },
          };
        } else if (activeFilter === leadsInsuranceWIP) {
          _activeFilter = {
            name: "Leads Insurance WIP 30 Days Old",
            filter: {
              projectStatus: ["Lead Insurance WIP"],
              "needsRemeet-end": thirtyDaysAgo,
              salesman: scheduler
                ? undefined
                : user.salesman === "Yes"
                ? [user._user]
                : undefined,
            },
          };
        } else if (activeFilter === estimatesWIP3Months) {
          _activeFilter = {
            name: "Estimates WIP 90 Days Old",
            filter: {
              projectStatus: ["Estimate WIP"],
              "estimateDate-end": ninteyDaysAgo,
              salesman: scheduler
                ? undefined
                : user.salesman === "Yes"
                ? [user._user]
                : undefined,
            },
          };
        }
      } else {
        _activeFilter = filterResponse.data._user[0]
          ? filterResponse.data._user[0]
          : filterResponse.data._global[0]
          ? filterResponse.data._global[0]
          : {};
      }

      dispatch({
        type: actions.FETCH_TABLE_FILTER,
        payload: {
          globalFilters: filterResponse.data._global,
          userFilters: filterResponse.data._user,
          activeFilter: _activeFilter,
        },
      });

      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.");
      }

      cancelToken = axios.CancelToken.source();

      const response = await api.post(
        `/${table}/fetchList/${col}/${order}${
          search.length > 0 ? `/${search}` : ""
        }`,
        _activeFilter.filter,
        { cancelToken: cancelToken.token }
      );

      dispatch({
        type: actions.FETCH_TABLE,
        payload: {
          rows: response.data,
        },
      });
    } catch (err) {
      console.log("fetchFilters err", err);
    }
  };

export const setUserFilters = (filters) => async (dispatch) => {
  try {
    dispatch({ type: actions.SET_USER_FILTER, payload: filters });
  } catch (err) {
    console.log("setUserFilters err", err);
  }
};

export const setTableFilters =
  ({ _global, _user }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: actions.SET_TABLE_FILTER,
        payload: {
          globalFilters: _global,
          userFilters: _user,
        },
      });
    } catch (err) {
      console.log("setTableFilter err", err);
    }
  };

export const fetchTable =
  ({ table, col, order, search = "", activeFilter = {}, fetching = true }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: actions.FETCHING_TABLE,
        payload: {
          table,
          col,
          order,
          search,
          activeFilter,
          fetching,
        },
      });

      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.");
      }

      cancelToken = axios.CancelToken.source();

      const response = await api.post(
        `/${table}/fetchList/${col}/${order}${
          search.length > 0 ? `/${search}` : ""
        }`,
        activeFilter.filter,
        { cancelToken: cancelToken.token }
      );

      dispatch({
        type: actions.FETCH_TABLE,
        payload: {
          rows: response.data,
        },
      });
    } catch (err) {
      console.log("fetchData err", err);
    }
  };

export const updateTable = (payload) => async (dispatch) => {
  dispatch({
    type: actions.FETCH_TABLE,
    payload: {
      rows: payload,
    },
  });
};

export const setScrollbar = () => async (dispatch) => {
  try {
    let width = getScrollBarWidth();
    if (!scrollbarVisible()) {
      width = 0;
    }
    dispatch({
      type: actions.SET_SCROLLBAR,
      payload: width,
    });
  } catch (err) {
    console.log("setScrollbar err", err);
  }
};

export const unsetTable = () => async (dispatch) => {
  dispatch({ type: actions.UNSET_TABLE });
};
