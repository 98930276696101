import React, { useState, useEffect } from "react";
import { unstable_batchedUpdates } from "react-dom";
import api from "API";
import AutoComplete from "antd/es/auto-complete";
import Input from "antd/es/input";
import message from "antd/es/message";
// import Button from "antd/es/button";
import SearchOutlined from "@ant-design/icons/SearchOutlined";
import styles from "./styles.module.scss";
// import FormControls from "Components/FormControls";
import FormBody from "Components/FormBody";
import DuplicateList from "./DuplicateList";

let searchTimeout;

const DuplicateCheck = ({
  type,
  setCreateNew,
  handleClose,
  setCopyAddress,
}) => {
  const [dataSource, setDataSource] = useState([]);
  const [distinctData, setDistinctData] = useState([]);
  const [activeData, setActiveData] = useState([]);

  useEffect(() => {
    return () => clearTimeout(searchTimeout);
  }, []);

  const handleSearch = (search) => {
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(async () => {
      try {
        if (search.length > 2) {
          const res = await api.post(`/duplicateCheck`, { search });
          if (res.status !== 200) {
            message.error("Error searching leads & projects");
          } else {
            let { _results, _distinct } = res.data;

            if (search.length > 2 && _distinct.length === 0) {
              _distinct.push(`Create New ${type}`);
            }

            unstable_batchedUpdates(() => {
              setDataSource(_results);
              setDistinctData(_distinct);
              setActiveData([]);
            });
          }
        } else {
          unstable_batchedUpdates(() => {
            setDataSource([]);
            setDistinctData([]);
            setActiveData([]);
          });
        }
      } catch (err) {
        console.log("err", err);
      }
    }, 400);
  };

  function onSelect(value) {
    if (value !== `Create New ${type}`) {
      const _activeData = dataSource.filter((x) => {
        return x.projectAddress === value;
      });
      setActiveData(_activeData);
    } else {
      createNew();
    }
  }

  const options = distinctData.map((data, i) => (
    <AutoComplete.Option key={data} value={data} title={data}>
      {data !== `Create New ${type}` ? data : <b>{data}</b>}
    </AutoComplete.Option>
  ));

  const createNew = () => {
    const search = document.getElementById("duplicateCheck").value;
    setCopyAddress(search);
    setCreateNew(true);
  };

  return (
    <>
      <AutoComplete
        className={styles.globalSearchDropdown}
        dropdownClassName={styles.globalSearchDropdown}
        dataSource={options}
        onSearch={handleSearch}
        onSelect={onSelect}
        defaultActiveFirstOption={false}
        getPopupContainer={(trigger) =>
          trigger.parentNode.parentNode.parentNode.parentNode.parentNode
            .parentNode
        }
        autoComplete="lead-project-duplicate-check"
      >
        <div>
          <label htmlFor="" style={{ display: "none" }}>
            hidden label to mislead chrome autocomplete
          </label>
          <Input
            id="duplicateCheck"
            allowClear
            size="large"
            placeholder="Address Search"
            prefix={<SearchOutlined />}
            autoComplete="lead-project-duplicate-check"
          />
        </div>
      </AutoComplete>
      {activeData.length > 0 && (
        <FormBody
          className="content-inner create-lead-project"
          style={{ padding: 24 }}
        >
          {activeData.map((data, index) => (
            <DuplicateList
              data={data}
              key={data._id}
              index={index}
              handleClose={handleClose}
            />
          ))}
        </FormBody>
      )}

      {/* {activeData.length > 0 && (
        <FormControls className="sticky-footer">
          <Button type="primary" block onClick={createNew}>
            Create New {type}
          </Button>
        </FormControls>
      )} */}
    </>
  );
};

export default DuplicateCheck;
