import React from "react";
import Col from "antd/es/col";
import Card from "antd/es/card";
import styles from "./styles.module.scss";
import CustomerName from "Components/Details/fields/CustomerName";
import PermitsNeeded from "Components/Details/fields/Permits";
import PropertyType from "Components/Details/fields/PropertyType";
import ProjectDescription from "Components/Details/fields/ProjectDescription";
import CopyableContent from "Components/CopyableContent";
import Documents from "./Documents";
import Scopes from "./Scopes";
import currencyFormatter from "Utils/currencyFormatter";
import Address from "Components/Details/fields/Address";

const ProjectDetails = ({ project, documents, setProject, scopes }) => {
  const updateCustomername = ({ value }) => {
    window.socket.emit("update", { _id: project._projectId });
    setProject((_project) => {
      return {
        ..._project,
        customerName: value,
      };
    });
  };

  const updateAddress = ({ value }) => {
    window.socket.emit("update", { _id: project._projectId });
    setProject((_project) => {
      return {
        ..._project,
        projectAddressStreet: value.projectAddressStreet,
        projectAddressCity: value.projectAddressCity,
        projectAddressState: value.projectAddressState,
        projectAddressZip: value.projectAddressZip,
        projectAddressCounty: value.projectAddressCounty,
        projectAddressPermitAuthority: value.projectAddressPermitAuthority,
        projectAddressLat: value.projectAddressLat,
        projectAddressLng: value.projectAddressLng,
      };
    });
  };

  const updatePermits = ({ value }) => {
    window.socket.emit("update", { _id: project._projectId });
    setProject((_project) => {
      return {
        ..._project,
        permits: value,
      };
    });
  };

  const updatePropertyType = ({ value }) => {
    window.socket.emit("update", { _id: project._projectId });
    setProject((_project) => {
      return {
        ..._project,
        propertyType: value,
      };
    });
  };

  const updateProjectDescription = ({ value }) => {
    window.socket.emit("update", { _id: project._projectId });
    setProject((_project) => {
      return {
        ..._project,
        projectDescription: value,
      };
    });
  };
  return (
    <Col
      xs={24}
      sm={8}
      style={{ borderRight: "1px solid #ededed", paddingRight: 6 }}
    >
      <Card
        className="z-depth-1"
        title="Project Details"
        style={{ marginBottom: 12 }}
      >
        <div className={styles.row}>
          <h4 className={styles.withIcon} style={{ position: "relative" }}>
            Customer ID{" "}
            <CopyableContent
              title="Customer ID"
              content={`PO: ${project.customerId}`}
              single
            />
          </h4>
          <p>{project.customerId}</p>
        </div>

        <CustomerName
          value={project.customerName}
          _id={project._id}
          single
          onChange={updateCustomername}
        />

        <Address
          _id={project._id}
          street={project.projectAddressStreet}
          city={project.projectAddressCity}
          state={project.projectAddressState}
          zip={project.projectAddressZip}
          county={project.projectAddressCounty}
          permitAuthority={project.projectAddressPermitAuthority}
          lat={project.projectAddressLat}
          lng={project.projectAddressLng}
          onChange={updateAddress}
          type="project"
          map
          single
        />

        <PermitsNeeded
          value={project.permits}
          _id={project._id}
          single
          onChange={updatePermits}
        />

        <PropertyType
          value={project.propertyType}
          _id={project._id}
          single
          onChange={updatePropertyType}
        />

        <ProjectDescription
          value={project.projectDescription}
          _id={project._id}
          single
          onChange={updateProjectDescription}
        />

        <div className={styles.row}>
          <h4>Salesman</h4>
          <p>{project.salesman ? project.salesman.join(", ") : null}</p>
        </div>

        <div className={styles.row}>
          <h4>Municipality</h4>
          <p>{project.projectAddressPermitAuthority}</p>
        </div>

        <div className={styles.row}>
          <h4>County</h4>
          <p>{project.projectAddressCounty}</p>
        </div>

        <div className={styles.row}>
          <h4>Contract Price</h4>
          <p>{currencyFormatter(project.contractPrice)}</p>
        </div>
      </Card>

      <Card
        className="z-depth-1"
        title="Contracts"
        style={{ marginBottom: 12 }}
        bodyStyle={{ padding: 0 }}
      >
        <Documents documents={documents} />
      </Card>

      <Card className="z-depth-1" title="Scopes" bodyStyle={{ padding: 0 }}>
        <Scopes scopes={scopes} />
      </Card>
    </Col>
  );
};

export default ProjectDetails;
