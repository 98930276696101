import React, { useState } from "react";
import { useSelector } from "react-redux";
import Form from "antd/es/form";
import Button from "antd/es/button";
import message from "antd/es/message";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import api from "API";
import CancelButton from "Components/CancelButton";
import revertMarkup from "Utils/revertMarkup";
import ImageUploader from "Components/Inputs/ImageUploader";

const messageKey = "messageKey";

const InstallTodoUpload = ({
  unsavedChanges,
  setUnsavedChanges,
  handleClose,
  type,
}) => {
  const formDetails = useSelector(
    (state) => state.formState.installTodoChangeImage.formDetails
  );
  const [loading, setLoading] = useState(false);
  const [_fileList, _setFileList] = useState([]);
  const [uploading, setUploading] = useState({});
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      setLoading(true);
      // message.loading("Creating to-do...", 0);
      message.loading({
        content: "Updating to-do...",
        duration: 0,
        key: messageKey,
      });
      values._id = formDetails._id;
      values.image = _fileList.length > 0 ? _fileList[0] : undefined;
      const res = await api.put("/install/todos/update/upload", values);

      if (formDetails.callback) {
        formDetails.callback(res.data);
      }

      // message.success("To-do updated");
      message.success({
        content: "To-do updated",
        duration: 1.5,
        key: messageKey,
      });
      setUnsavedChanges(false);
      setLoading(false);
      handleClose(true);
    } catch (err) {
      setLoading(false);
      // message.error("Error updating to-do");
      message.error({
        content: "Error updating to-do",
        duration: 1.5,
        key: messageKey,
      });
    }
  };

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error updating to-do");
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  const cancel = () => {
    handleClose();
  };

  return (
    <Form
      form={form}
      className="form"
      autoComplete="off"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      initialValues={{
        todo: formDetails.todo ? revertMarkup(formDetails.todo) : undefined,
      }}
    >
      <RemoveAutocomplete />
      <FormBody
        className="content-inner"
        style={{
          paddingLeft: 24,
          paddingTop: 24,
          paddingRight: 24,
          paddingBottom: 8,
          maxHeight: type === "modal" ? "calc(100vh - 161px)" : "unset",
        }}
      >
        <ImageUploader
          _fileList={_fileList}
          _setFileList={_setFileList}
          uploading={uploading}
          setUploading={setUploading}
          destination="install-to-dos"
          label="Image Upload"
          multiple={false}
          required
        />
      </FormBody>

      <FormControls className="sticky-footer">
        <CancelButton handleClick={cancel} />
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={loading || !unsavedChanges || uploading}
        >
          Submit
        </Button>
      </FormControls>
    </Form>
  );
};

export default InstallTodoUpload;
