import React, { useState, useEffect, useCallback } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateDetail } from "Actions/dataActions";
import Form from "antd/es/form";
import Button from "antd/es/button";
import message from "antd/es/message";
import Input from "antd/es/input";
import usePrevious from "Hooks/usePrevious";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import api from "API";

const ProjectDescription = ({ value, _id, onChange }) => {
  const dispatch = useDispatch();
  const viewableActivities = useSelector(
    (state) => state.dataState.viewableActivities
  );
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const prevValue = usePrevious(value);

  const [form] = Form.useForm();

  useEffect(() => {
    if (!prevValue && value) form.setFieldsValue({ projectDescription: value });
  }, [prevValue, value]); // eslint-disable-line react-hooks/exhaustive-deps

  const onFinish = useCallback(
    async (values) => {
      try {
        setLoading(true);
        values._id = _id;
        values.previous = value;

        if (values.projectDescription !== value) {
          const res = await api.post("/update/projectDescription", values);

          dispatch(
            updateDetail({
              viewableActivities,
              key: "projectDescription",
              value: res.data.value,
              activities: res.data.activities,
            })
          );

          if (onChange) {
            onChange({
              value: res.data.value,
            });
          }

          message.success("Project Description updated");
        }
        unstable_batchedUpdates(() => {
          setLoading(false);
          setEdit(false);
        });
      } catch (err) {
        console.log("err", err);
        message.error("Error updating Project Description");
        setLoading(false);
      }
    },
    [_id, value, dispatch, viewableActivities, onChange]
  );

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error updating Project Description");
  };

  const toggleEdit = () => {
    setEdit(!edit);
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      initialValues={{ projectDescription: value }}
    >
      <RemoveAutocomplete />
      <h4 className="withIcon" style={{ marginBottom: 3 }}>
        Project Description
      </h4>
      {!edit && (
        <p className="editableRow" onClick={toggleEdit}>
          <span
            style={{ whiteSpace: "pre-wrap" }}
            dangerouslySetInnerHTML={{
              __html: value ? value : "Click to Edit",
            }}
          />
        </p>
      )}

      {edit && (
        <>
          <Form.Item
            name="projectDescription"
            rules={[
              { required: true, message: "Project Description is required" },
            ]}
          >
            <Input.TextArea
              autoFocus
              autoComplete="dont-use-autocomplete"
              type="text"
              rows={4}
              placeholder="Notes"
            />
          </Form.Item>

          <div className="editControls">
            <Button
              type="default"
              size="small"
              onClick={toggleEdit}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              size="small"
              loading={loading}
              disabled={loading}
            >
              Submit
            </Button>
          </div>
        </>
      )}
    </Form>
  );
};

export default ProjectDescription;
