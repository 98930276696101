import React, { useState, useEffect, useCallback } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateDetail } from "Actions/dataActions";
import Form from "antd/es/form";
import Button from "antd/es/button";
import message from "antd/es/message";
import usePrevious from "Hooks/usePrevious";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import api from "API";
import InputPropertyType from "Components/Inputs/PropertyType";

const PropertyType = ({ value, _id, onChange }) => {
  const dispatch = useDispatch();
  const viewableActivities = useSelector(
    (state) => state.dataState.viewableActivities
  );
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const prevValue = usePrevious(value);

  const [form] = Form.useForm();

  useEffect(() => {
    if (!prevValue && value) form.setFieldsValue({ propertyType: value });
  }, [prevValue, value]); // eslint-disable-line react-hooks/exhaustive-deps

  const onFinish = useCallback(
    async (values) => {
      try {
        setLoading(true);
        values._id = _id;
        values.previous = value;

        if (values.propertyType.toString() !== value.toString()) {
          const res = await api.post("/update/propertyType", values);

          dispatch(
            updateDetail({
              viewableActivities,
              key: "propertyType",
              value: res.data.value,
              activities: res.data.activities,
            })
          );

          if (onChange) {
            onChange({
              value: res.data.value,
            });
          }

          message.success("Property Type updated");
        }
        unstable_batchedUpdates(() => {
          setLoading(false);
          setEdit(false);
        });
      } catch (err) {
        console.log("err", err);
        message.error("Error updating Property Type");
        setLoading(false);
      }
    },
    [_id, value, dispatch, viewableActivities, onChange]
  );

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error updating Property Type");
  };

  const toggleEdit = () => {
    setEdit(!edit);
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      initialValues={{ propertyType: value }}
      style={{ marginBottom: 16 }}
    >
      <RemoveAutocomplete />
      <h4 style={{ marginBottom: 3 }}>Property Type</h4>
      {!edit && (
        <p
          className="editableRow"
          style={{ marginBottom: 0 }}
          onClick={toggleEdit}
        >
          <span>{value}</span>
        </p>
      )}

      {edit && (
        <>
          <Form.Item
            name="propertyType"
            rules={[
              {
                required: true,
                message: "Select a Property Type",
              },
            ]}
          >
            <InputPropertyType
              autoFocus
              defaultOpen={true}
              placeholder="Property Type"
            />
          </Form.Item>

          <div className="editControls">
            <Button
              type="default"
              size="small"
              onClick={toggleEdit}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              size="small"
              loading={loading}
              disabled={loading}
            >
              Submit
            </Button>
          </div>
        </>
      )}
    </Form>
  );
};

export default PropertyType;
