import React, { useRef } from "react";
import Select from "antd/es/select";
import useIsMobile from "Hooks/useIsMobile";

const InputSubContractors = ({
  value,
  onChange,
  thisRef,
  subs,
  placeholder = undefined,
  onClick,
}) => {
  const mobile = useIsMobile();
  const ref = useRef();

  const renderSubs = Object.keys(subs).map((key) => (
    <Select.Option
      key={subs[key]._sub}
      value={subs[key]._sub}
      style={{ lineHeight: "18px" }}
    >
      {subs[key].companyName}
      <br />
      <small>{subs[key].contactName}</small>
    </Select.Option>
  ));

  const triggerChange = (changedValue) => {
    if (onChange) {
      onChange(changedValue);
    }
  };

  const handleChange = (values) => {
    triggerChange(values);
    ref.current.blur();
  };

  return (
    <Select
      ref={ref}
      autoComplete="edit-detail-field"
      allowClear
      showSearch={!mobile}
      dropdownClassName={mobile ? "isMobile" : null}
      getPopupContainer={(trigger) =>
        !mobile
          ? trigger.parentNode.parentNode.parentNode.parentNode.parentNode
          : document.body
      }
      onChange={handleChange}
      value={value}
      placeholder={placeholder}
      onClick={onClick}
    >
      {renderSubs}
    </Select>
  );
};

export default InputSubContractors;
