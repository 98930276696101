import React, { memo } from "react";
import Input from "antd/es/input";
import Form from "antd/es/form";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Phone from "Components/Inputs/Phone";
import Address from "Components/Inputs/Address";
import Map from "Components/Details/fields/Map";
import PropertyType from "Components/Inputs/PropertyType";
import Select from "Components/Inputs/Select";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";

const CustomerDetails = ({
  form,
  _lat,
  _lng,
  handlePropertyTypeChange,
  updateCords,
  hidden,
  disabled,
}) => {
  const setProjectAddress = ({
    city,
    state,
    postcode,
    county,
    permitAuthority,
    lat,
    lng,
  }) => {
    form.setFieldsValue({
      projectAddressCity: city,
      projectAddressState: state,
      projectAddressZip: postcode,
      projectAddressCounty: county,
      projectAddressPermitAuthority: permitAuthority,
      projectAddressLat: lat,
      projectAddressLng: lng,
    });
  };

  return (
    <div style={{ display: hidden ? "none" : "block" }}>
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="customerName"
            label="Customer Name"
            rules={[{ required: true, message: "Customer Name is required" }]}
          >
            <InputNoAutoComplete disabled={disabled} id="customerName" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="phone"
            label="Customer Phone"
            rules={[{ required: true, message: "Customer Phone is required" }]}
          >
            <Phone disabled={disabled} id="phone" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Schedule"
            name="schedule"
            rules={[
              {
                required: true,
                message: "Schedule is required",
              },
            ]}
          >
            <Select
              options={[
                "Normal",
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
                "Urgent",
              ]}
              disabled={disabled}
              id="schedule"
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={12}>
          <Form.Item
            label="Property Type"
            name="propertyType"
            rules={[
              {
                required: true,
                message: "Property Type is required",
                type: "string",
              },
            ]}
          >
            <PropertyType
              handlePropertyTypeChange={handlePropertyTypeChange}
              disabled={disabled}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Project Street"
            name="projectAddressStreet"
            rules={[{ required: true, message: "Project street is required" }]}
          >
            <Address
              autoComplete="projectStreet"
              setAddress={setProjectAddress}
              disabled={disabled}
              id="projectAddressStreet"
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Project City"
            name="projectAddressCity"
            rules={[
              {
                required: true,
                message: "Project city is required",
                type: "string",
              },
            ]}
          >
            <InputNoAutoComplete disabled={disabled} id="projectAddressCity" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Project State"
            name="projectAddressState"
            rules={[
              {
                required: true,
                message: "Project state is required",
                type: "string",
              },
              {
                len: 2,
                message: "State must be abbreviated",
              },
            ]}
          >
            <InputNoAutoComplete disabled={disabled} id="projectAddressState" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Project Zip"
            name="projectAddressZip"
            rules={[
              {
                required: true,
                message: "Project zip is required",
                type: "string",
              },
            ]}
          >
            <InputNoAutoComplete disabled={disabled} id="projectAddressZip" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24}>
          <Map
            type="project"
            form={form}
            lat={_lat}
            lng={_lng}
            updateCords={updateCords}
            readOnly={disabled}
          />

          <Form.Item style={{ display: "none" }} name="projectAddressCounty">
            <Input autoComplete="projectCounty" type="text" disabled />
          </Form.Item>

          <Form.Item
            style={{ display: "none" }}
            name="projectAddressPermitAuthority"
          >
            <Input autoComplete="projectPermitAuthority" type="text" disabled />
          </Form.Item>

          <Form.Item style={{ display: "none" }} name="projectAddressLat">
            <Input autoComplete="projectLat" type="text" disabled />
          </Form.Item>

          <Form.Item style={{ display: "none" }} name="projectAddressLng">
            <Input autoComplete="projectLng" type="text" disabled />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default memo(CustomerDetails);
