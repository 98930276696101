import React, { useCallback, useState, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateActivitiesAndAllMedia,
  openImageViewer,
  updateSelectedActivity,
  updateActivitiesAndSelectedActivity,
} from "Actions/dataActions";
import { useParams } from "react-router-dom";
import LazyLoad from "Components/LazyLoad";
import Card from "antd/es/card";
import Button from "antd/es/button";
import Dropdown from "antd/es/dropdown";
import Menu from "antd/es/menu";
import message from "antd/es/message";
import Modal from "antd/es/modal";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";
import Controls from "../Controls";
import Star from "Icons/Star";
import StarBorder from "Icons/StarBorder";
import CheckCircle from "Icons/CheckCircle";
import CheckCircleEmpty from "Icons/CheckCircleEmpty";
import YouTube from "Icons/YouTube";
import MoreVert from "Icons/MoreVert";
import isPdf from "Utils/isPdf";
import isVideo from "Utils/isVideo";
import openInNewTab from "Utils/openInNewTab";
import downloadFile from "Utils/downloadFile";
import pdf from "Assets/pdf.svg";
import styles from "./styles.module.scss";
import printJS from "print-js";
import Clipboard from "react-clipboard.js";
import api from "API";
import Loader from "Components/Loader";
import ImageFallback from "Components/images/Image";

const Image = ({
  image,
  _index,
  setSelectedImages,
  selecting,
  selectedImages,
  scrollContainer,
  global,
}) => {
  const dispatch = useDispatch();
  const selectedActivity = useSelector(
    (state) => state.dataState.selectedActivity
  );
  const viewableActivities = useSelector(
    (state) => state.dataState.viewableActivities
  );
  const params = useParams();
  const [loading, setLoading] = useState(false);

  const type = isPdf(image.src)
    ? "pdf"
    : isVideo(image.src)
    ? "video"
    : "image";

  const onClick = useCallback(
    (_image) => {
      dispatch(openImageViewer(_image));
    },
    [dispatch]
  );

  const _setStar = useCallback(
    async (e) => {
      try {
        e.stopPropagation();
        if (!image._projectId) image._projectId = params.id;
        if (!image._activityId) image._activityId = selectedActivity._id;

        const res = await api.post("/activity/star-image", image);

        dispatch(
          updateActivitiesAndAllMedia({
            viewableActivities,
            activities: res.data._activities,
            allMedia: res.data._allMedia,
            _projectId: image._projectId,
            salesObj: res.data.salesObj,
            legacyFiles: res.data.legacyFiles,
          })
        );

        if (selectedActivity)
          dispatch(
            updateSelectedActivity(selectedActivity, res.data._activities)
          );
      } catch (err) {
        console.log("err", err);
        message.error("Error starring image");
      }
    },
    [image, params, selectedActivity, dispatch, viewableActivities]
  );

  const _removeStar = useCallback(
    async (e) => {
      try {
        e.stopPropagation();
        const res = await api.post("/activity/remove-star", {
          _projectId: params.id || image._projectId,
        });

        dispatch(
          updateActivitiesAndAllMedia({
            viewableActivities,
            activities: res.data._activities,
            allMedia: res.data._allMedia,
            _projectId: params.id || image._projectId,
            salesObj: res.data.salesObj,
            legacyFiles: res.data.legacyFiles,
          })
        );

        if (selectedActivity)
          dispatch(
            updateSelectedActivity(selectedActivity, res.data._activities)
          );
      } catch (err) {
        console.log("err", err);
        message.error("Error removing star");
      }
    },
    [params, image, dispatch, viewableActivities, selectedActivity]
  );

  const addToSelected = (image) => {
    const findImage = selectedImages.find((s) => s._id === image._id);
    if (!findImage) {
      if (!image._activityId) image._activityId = selectedActivity._id;
      image._projectId = params.id;
      setSelectedImages((prevState) => [...prevState, image]);
    } else {
      setSelectedImages((prevState) =>
        prevState.filter((p) => p._id !== image._id)
      );
    }
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const openPdf = (src) => {
    openInNewTab(src);
  };

  const handleMenuClick = ({ domEvent, key }) => {
    domEvent.stopPropagation();
    if (key.includes("print")) {
      printFile(image);
    } else if (key.includes("download")) {
      saveFile(image.src, type);
    } else if (key.includes("delete")) {
      handleDelete(image);
    }
  };

  const copied = () => {
    message.success("Document URL copied");
  };

  async function getBase64(src, type) {
    try {
      const res = await api.post("/getBase64", {
        src,
        type,
      });
      return res.data;
    } catch (err) {
      console.log("err", err);
    }
  }

  async function saveFile(src, type = "image") {
    try {
      setLoading(true);
      const ext = image.src.split(".").pop();
      const base64 = await getBase64(src, type);
      downloadFile(base64, type, ext);
      setLoading(false);
    } catch (err) {
      console.log("err", err);
    }
  }

  async function printFile(image) {
    printJS({
      printable: `${image.src}?${new Date().getTime()}`,
      type: image.type !== "application/pdf" ? "image" : "pdf",
    });
  }

  const handleDelete = (image) => {
    Modal.confirm({
      zIndex: 7001,
      icon: <QuestionCircleOutlined />,
      centered: true,
      cancelText: "Cancel",
      okText: "Delete",
      okButtonProps: { danger: true },
      content: <div>Are you sure? This will permanetly delete this file.</div>,
      onOk() {
        return new Promise((resolve, reject) => {
          handleConfirmDelete(() => resolve(true));
        }).catch((err) => console.log("err: ", err));
      },
      onCancel() {},
    });
  };

  const handleConfirmDelete = useCallback(
    async (cb) => {
      try {
        const res = await api.post(`/activity/delete-image`, {
          _id: image._activityId || selectedActivity._id,
          _projectId: image._projectId || selectedActivity._projectId,
          image,
        });

        dispatch(
          updateActivitiesAndSelectedActivity({
            viewableActivities,
            activities: res.data._activities,
            allMedia: res.data._allMedia,
            activity: selectedActivity,
            legacyFiles: res.data.legacyFiles,
            salesObj: res.data.salesObj,
            _projectId: image._projectId || selectedActivity._projectId,
          })
        );

        cb();
      } catch (err) {
        console.log("err", err);
      }
    },
    [image, selectedActivity, dispatch, viewableActivities]
  );

  const pdfClick = () => {
    !selecting ? openPdf(image.src) : addToSelected(image);
  };

  const imageClick = () => {
    !selecting ? onClick(image) : addToSelected(image);
  };

  return (
    <div key={image._id} id={_index}>
      {loading && (
        <div
          style={{
            position: "fixed",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            zIndex: 99999,
            background: "rgba(0,0,0,0.23)",
          }}
        >
          <Loader display={loading ? "flex" : "none"} minHeight="100%" />
        </div>
      )}

      <Card
        bordered={false}
        className={styles.activity}
        style={{ borderRadius: 0 }}
        bodyStyle={{ padding: 0 }}
        cover={
          type === "pdf" ? (
            <>
              <div
                style={{
                  borderRadius: 0,
                  zIndex: selecting ? 1 : "unset",
                }}
                className={`${styles.clickOverlay} ${
                  selecting && selectedImages.find((s) => s._id === image._id)
                    ? styles.selecting
                    : ""
                }`}
                onClick={pdfClick}
              >
                {!selecting ? (
                  <Dropdown
                    onClick={stopPropagation}
                    className="image-button more"
                    overlay={
                      <Menu onClick={handleMenuClick}>
                        <Menu.Item key={`${image._id}-download`}>
                          Download
                        </Menu.Item>
                        <Menu.Item key={`${image._id}-print`}>Print</Menu.Item>
                        <Menu.Item key={`${image._id}-copy`}>
                          <Clipboard
                            data-clipboard-text={image.src}
                            component="div"
                            onSuccess={copied}
                          >
                            Copy URL
                          </Clipboard>
                        </Menu.Item>
                        {!image.delete && !global && <Menu.Divider />}
                        {!image.delete && !global && (
                          <Menu.Item
                            className={styles.deleteText}
                            key={`${image._id}-delete`}
                          >
                            Delete
                          </Menu.Item>
                        )}
                      </Menu>
                    }
                    trigger={["click"]}
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    <Button type="text" size="large" shape="circle">
                      <MoreVert />
                    </Button>
                  </Dropdown>
                ) : selecting &&
                  selectedImages.find((s) => s._id === image._id) ? (
                  <Button
                    type="text"
                    size="large"
                    shape="circle"
                    className="image-button star"
                  >
                    <CheckCircle color="#108ee9" />
                  </Button>
                ) : selecting ? (
                  <Button
                    type="text"
                    size="large"
                    shape="circle"
                    className="image-button star"
                  >
                    <CheckCircleEmpty />
                  </Button>
                ) : null}
              </div>
              <LazyLoad offsetVertical={80} height={52} root={scrollContainer}>
                <div className={styles.pdf}>
                  <img src={pdf} alt="pdf-icon" />
                  <div>{image.name}</div>
                </div>
              </LazyLoad>
            </>
          ) : type === "image" ? (
            <>
              <div
                style={{
                  borderRadius: 0,
                  zIndex: selecting ? 1 : "unset",
                }}
                className={`${styles.clickOverlay} ${
                  selecting && selectedImages.find((s) => s._id === image._id)
                    ? styles.selecting
                    : ""
                }`}
                onClick={imageClick}
              >
                {!image.star && !selecting ? (
                  <>
                    <Dropdown
                      onClick={stopPropagation}
                      className="image-button more"
                      overlay={
                        <Menu onClick={handleMenuClick}>
                          <Menu.Item key={`${image._id}-download`}>
                            Download
                          </Menu.Item>
                          <Menu.Item key={`${image._id}-print`}>
                            Print
                          </Menu.Item>
                          <Menu.Item key={`${image._id}-copy`}>
                            <Clipboard
                              data-clipboard-text={image.src}
                              component="div"
                              onSuccess={copied}
                            >
                              Copy URL
                            </Clipboard>
                          </Menu.Item>
                          {!image.delete && !global && <Menu.Divider />}
                          {!image.delete && !global && (
                            <Menu.Item
                              className={styles.deleteText}
                              key={`${image._id}-delete`}
                            >
                              Delete
                            </Menu.Item>
                          )}
                        </Menu>
                      }
                      trigger={["click"]}
                      getPopupContainer={(trigger) => trigger.parentNode}
                    >
                      <Button type="text" size="large" shape="circle">
                        <MoreVert />
                      </Button>
                    </Dropdown>
                    {!global && (
                      <Button
                        type="text"
                        size="large"
                        shape="circle"
                        className="image-button star"
                        onClick={_setStar}
                      >
                        <StarBorder />
                      </Button>
                    )}
                  </>
                ) : image.star && !selecting ? (
                  <>
                    <Dropdown
                      onClick={stopPropagation}
                      className="image-button more"
                      overlay={
                        <Menu onClick={handleMenuClick}>
                          <Menu.Item key={`${image._id}-download`}>
                            Download
                          </Menu.Item>
                          <Menu.Item key={`${image._id}-print`}>
                            Print
                          </Menu.Item>
                          <Menu.Item key={`${image._id}-copy`}>
                            <Clipboard
                              data-clipboard-text={image.src}
                              component="div"
                              onSuccess={copied}
                            >
                              Copy URL
                            </Clipboard>
                          </Menu.Item>
                          {!image.delete && !global && <Menu.Divider />}
                          {!image.delete && !global && (
                            <Menu.Item
                              className={styles.deleteText}
                              key={`${image._id}-delete`}
                            >
                              Delete
                            </Menu.Item>
                          )}
                        </Menu>
                      }
                      trigger={["click"]}
                      getPopupContainer={(trigger) => trigger.parentNode}
                    >
                      <Button type="text" size="large" shape="circle">
                        <MoreVert />
                      </Button>
                    </Dropdown>
                    {!global && (
                      <Button
                        type="text"
                        size="large"
                        shape="circle"
                        className="image-button star"
                        onClick={_removeStar}
                      >
                        <Star color="gold" />
                      </Button>
                    )}
                  </>
                ) : selectedImages.find((s) => s._id === image._id) ? (
                  <Button
                    type="text"
                    size="large"
                    shape="circle"
                    className="image-button star"
                  >
                    <CheckCircle color="#108ee9" />
                  </Button>
                ) : (
                  <Button
                    type="text"
                    size="large"
                    shape="circle"
                    className="image-button star"
                  >
                    <CheckCircleEmpty />
                  </Button>
                )}
              </div>
              <LazyLoad
                offsetVertical={240}
                height={240}
                root={scrollContainer}
              >
                <ImageFallback image={image} alt="activity-drawer" />
              </LazyLoad>
            </>
          ) : (
            <div className={styles.videoContainer}>
              <div
                style={{
                  borderRadius: 0,
                  zIndex: 1,
                }}
                className={`${styles.clickOverlay} ${
                  selecting && selectedImages.find((s) => s._id === image._id)
                    ? styles.selecting
                    : ""
                }`}
                onClick={imageClick}
              >
                {!selecting ? (
                  <Dropdown
                    onClick={stopPropagation}
                    className="image-button more"
                    overlay={
                      <Menu onClick={handleMenuClick}>
                        <Menu.Item key={`${image._id}-download`}>
                          Download
                        </Menu.Item>
                        <Menu.Item key={`${image._id}-copy`}>
                          <Clipboard
                            data-clipboard-text={image.src}
                            component="div"
                            onSuccess={copied}
                          >
                            Copy URL
                          </Clipboard>
                        </Menu.Item>
                        {!image.delete && !global && <Menu.Divider />}
                        {!image.delete && !global && (
                          <Menu.Item
                            className={styles.deleteText}
                            key={`${image._id}-delete`}
                          >
                            Delete
                          </Menu.Item>
                        )}
                      </Menu>
                    }
                    trigger={["click"]}
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    <Button type="text" size="large" shape="circle">
                      <MoreVert />
                    </Button>
                  </Dropdown>
                ) : selecting &&
                  selectedImages.find((s) => s._id === image._id) ? (
                  <Button
                    type="text"
                    size="large"
                    shape="circle"
                    className="image-button star"
                  >
                    <CheckCircle color="#108ee9" />
                  </Button>
                ) : selecting ? (
                  <Button
                    type="text"
                    size="large"
                    shape="circle"
                    className="image-button star"
                  >
                    <CheckCircleEmpty />
                  </Button>
                ) : null}
              </div>
              <LazyLoad offsetVertical={80} height={52} root={scrollContainer}>
                <div className={styles.video}>
                  <YouTube color="#f1f1f1" size={64} />
                  <div>{image.name}</div>
                </div>
              </LazyLoad>
            </div>
          )
        }
      >
        {!image.legacy && (
          <Controls
            _activityId={
              selectedActivity ? selectedActivity._id : image._activityId
            }
            _imageSrc={image.src}
            _activityCreatedBy={
              selectedActivity ? selectedActivity.createdBy : image.createdBy
            }
            _projectId={
              selectedActivity ? selectedActivity._projectId : image._projectId
            }
            _customerId={
              selectedActivity ? selectedActivity.customerId : undefined
            }
            _projectStatus={
              selectedActivity ? selectedActivity.projectStatus : undefined
            }
          />
        )}
      </Card>
    </div>
  );
};

export default memo(Image);
