import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchTable } from "Actions/tableActions";
import List from "antd/es/list";
import Tag from "antd/es/tag";
import dayjs from "dayjs";
import splitCamal from "Utils/splitCamal";
import styles from "./styles.module.scss";
import useSalesmen from "Hooks/useSalesmen";
import useProjectManagers from "Hooks/useProjectManagers";
import isEmpty from "Utils/isEmpty";

const TableFilter = ({ hide }) => {
  const dispatch = useDispatch();
  const table = useSelector((state) => state.tableState.table);
  const col = useSelector((state) => state.tableState.col);
  const order = useSelector((state) => state.tableState.order);
  const activeFilter = useSelector((state) => state.tableState.activeFilter);
  const search = useSelector((state) => state.tableState.search);
  const sales = useSalesmen();
  const projectManagers = useProjectManagers();

  const renderFilter = useCallback(() => {
    if (activeFilter) {
      const _activeFilter = JSON.parse(JSON.stringify(activeFilter));
      const _filter = activeFilter.filter;
      if (_filter) {
        return Object.entries(_filter).map(([key, value]) => {
          const userProp = key;
          const splitProp = userProp.split("-")[1];
          const splitPropPre = userProp.split("-")[0];

          const onClose = () => {
            Object.entries(_filter).map(([_key]) => {
              if (_key === key) delete _activeFilter.filter[_key];
              return _key;
            });
            _activeFilter._id = null;

            dispatch(
              fetchTable({
                table,
                col,
                order,
                search,
                activeFilter: _activeFilter,
              })
            );
          };

          if (splitProp === "start") {
            const date = dayjs(value).format("M/D/YY");
            return (
              <Tag
                key={key}
                closable
                onClose={onClose}
                color="#108ee9"
                style={{ marginRight: "14px" }}
              >{`${splitCamal(splitPropPre)} After: ${date}`}</Tag>
            );
          } else if (splitProp === "end") {
            const date = dayjs(value).format("M/D/YY");
            return (
              <Tag
                key={key}
                closable
                onClose={onClose}
                color="#108ee9"
                style={{ marginRight: "14px" }}
              >{`${splitCamal(splitPropPre)} Before: ${date}`}</Tag>
            );
          } else if (splitProp === "exists") {
            return (
              <Tag
                key={key}
                closable
                onClose={onClose}
                color="#108ee9"
                style={{ marginRight: "14px" }}
              >{`${splitCamal(splitPropPre)} Exists: FALSE`}</Tag>
            );
          } else if (userProp === "salesman" && sales && !isEmpty(sales)) {
            if (value) {
              let arr = "";
              for (const v of value) {
                if (sales[v]) {
                  arr += `${sales[v].name}, `;
                }
              }
              if (arr.length > 0) {
                arr = arr.slice(0, -2);
                return (
                  <Tag
                    key={key}
                    closable
                    onClose={onClose}
                    color="#108ee9"
                    style={{ marginRight: "14px" }}
                  >{`${splitCamal(userProp)}: ${arr}`}</Tag>
                );
              }
              return null;
            }
            return null;
          } else if (
            userProp === "projectManager" &&
            sales &&
            !isEmpty(sales)
          ) {
            if (value) {
              let arr = "";
              for (const v of value) {
                if (sales[v]) {
                  arr += `${sales[v].name}, `;
                }
              }
              if (arr.length > 0) {
                arr = arr.slice(0, -2);
                return (
                  <Tag
                    key={key}
                    closable
                    onClose={onClose}
                    color="#108ee9"
                    style={{ marginRight: "14px" }}
                  >{`${splitCamal(userProp)}: ${arr}`}</Tag>
                );
              }
              return null;
            }
            return null;
          } else if (
            userProp === "installProjectManager" &&
            projectManagers &&
            !isEmpty(projectManagers)
          ) {
            let arr = "";
            for (const v of value) {
              arr += `${projectManagers[v].name}, `;
            }
            arr = arr.slice(0, -2);
            return (
              <Tag
                key={key}
                closable
                onClose={onClose}
                color="#108ee9"
                style={{ marginRight: "14px" }}
              >{`${splitCamal(userProp)}: ${arr}`}</Tag>
            );
          } else {
            if (value) {
              const arr = value.join(", ");
              return (
                <Tag
                  key={key}
                  closable
                  onClose={onClose}
                  color="#108ee9"
                  style={{ marginRight: "14px" }}
                >{`${splitCamal(userProp)}: ${arr}`}</Tag>
              );
            }
            return null;
          }
        });
      }
    }
  }, [
    activeFilter,
    dispatch,
    table,
    col,
    order,
    search,
    sales,
    projectManagers,
  ]);

  const onCloseSearch = useCallback(
    (key) => {
      dispatch(
        fetchTable({
          table,
          col,
          order,
          search: "",
          activeFilter,
        })
      );
    },
    [dispatch, table, col, order, activeFilter]
  );

  return (
    <List
      bordered
      className={styles.tableFilter}
      style={{ display: hide ? "none" : "block" }}
    >
      <List.Item>
        <List.Item.Meta
          description={
            <>
              {search.length > 0 && (
                <Tag color="#108ee9" closable onClose={onCloseSearch}>
                  Search: {search}
                </Tag>
              )}

              {renderFilter()}
            </>
          }
        />
      </List.Item>
    </List>
  );
};

export default TableFilter;
