import React, { useState, useCallback, useEffect } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateCollectionsActivitiesAndHashtags } from "Actions/dataActions";
import { openForm, updateFormDetails } from "Actions/formActions";
import Form from "antd/es/form";
import Button from "antd/es/button";
import message from "antd/es/message";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import api from "API";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Grid from "antd/es/grid";
import Remove from "Icons/Remove";
import sumBy from "Utils/sumBy";
import Add from "Icons/Add";
import Typography from "antd/es/typography";
import Eye from "Icons/Eye";
import Email from "Icons/Email";
import Printer from "Icons/Printer";
import openInNewTab from "Utils/openInNewTab";
import printJS from "print-js";
import CancelButton from "Components/CancelButton";
import Alert from "antd/es/alert";
import currencyFormatter from "Utils/currencyFormatter";
import LineItem from "./LineItem";
import styles from "./styles.module.scss";
import ProcessFeedback from "Components/ProcessFeedback";
import Card from "antd/es/card";

const messageKey = "messageKey";

const { useBreakpoint } = Grid;

const steps = [
  "Submitting Form",
  "Generating PDF",
  "Saving PDF",
  "Saving Receipt",
  "Creating Post",
  "Done!",
];

const Receipt = ({ unsavedChanges, setUnsavedChanges, handleClose, type }) => {
  const formDetails = useSelector(
    (state) => state.formState.receipt.formDetails
  );
  const screens = useBreakpoint();
  const details = useSelector((state) => state.dataState.details);
  const viewableActivities = useSelector(
    (state) => state.dataState.viewableActivities
  );
  const dispatch = useDispatch();
  const [_projectId] = useState(
    formDetails._projectId ? formDetails._projectId : details._id
  );
  const [customerId] = useState(
    formDetails.customerId ? formDetails.customerId : details.customerId
  );
  const [loading, setLoading] = useState(false);
  const [projectManager] = useState(
    formDetails.projectManager
      ? formDetails.projectManager
      : details.projectManager
  );
  const [_details] = useState({
    billingAddressStreet: formDetails.billingAddressCity
      ? formDetails.billingAddressStreet
      : details.billingAddressStreet,
    billingAddressCity: formDetails.billingAddressCity
      ? formDetails.billingAddressCity
      : details.billingAddressCity,
    billingAddressState: formDetails.billingAddressState
      ? formDetails.billingAddressState
      : details.billingAddressState,
    billingAddressZip: formDetails.billingAddressZip
      ? formDetails.billingAddressZip
      : details.billingAddressZip,
    customerName: formDetails.customerName
      ? formDetails.customerName
      : details.customerName,
    companyName: formDetails.companyName
      ? formDetails.companyName
      : details.companyName,
    projectManager: formDetails.projectManager
      ? formDetails.projectManager
      : details.projectManager,
    projectAddressStreet: formDetails.projectAddressStreet
      ? formDetails.projectAddressStreet
      : details.projectAddressStreet,
    projectAddressCity: formDetails.projectAddressCity
      ? formDetails.projectAddressCity
      : details.projectAddressCity,
    projectAddressState: formDetails.projectAddressState
      ? formDetails.projectAddressState
      : details.projectAddressState,
    projectAddressZip: formDetails.projectAddressZip
      ? formDetails.projectAddressZip
      : details.projectAddressZip,
    projectAddress: formDetails.projectAddress
      ? formDetails.projectAddress
      : details.projectAddress,
    brokedown: details.brokedown ? details.brokedown : "No",
    projectDescription: details.projectDescription
      ? details.projectDescription
      : "",
    phone: details.phone ? details.phone : "",
  });
  const [balance, setBalance] = useState(0);
  const [selected, setSelected] = useState(false);
  const [invoices, setInvoices] = useState([]);

  const _user = useSelector((state) => state.authState._user);
  const [feedBack, setFeedBack] = useState({
    active: "Submitting Form",
    progress: 0,
  });

  const [form] = Form.useForm();

  useEffect(() => {
    getInvoices();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getInvoices = async () => {
    try {
      const response = await api.get(`/invoices/${_projectId}`);
      setInvoices(response.data || []);
    } catch (err) {
      console.log("getInvoices err", err);
    }
  };

  const populateLineItems = async (id) => {
    try {
      const invoice = invoices.find((i) => i._id.toString() === id);
      form.setFieldsValue({
        lineItems: invoice.lineItems,
      });
    } catch (err) {
      console.log("populateLineItems err", err);
    }
  };

  const updateFeedBack = (feedBack) => {
    setFeedBack(feedBack);
  };

  const onFinish = useCallback(
    async (values) => {
      try {
        if (
          values.lineItems.length > 0 &&
          formDetails.amount.toString() !== balance.toString()
        ) {
          message.error({
            content: "Total amount must be allocated",
            duration: 1.5,
            key: messageKey,
          });
          setLoading(false);
          return;
        }

        window.socket.on(`${_user}-receipt-progress`, updateFeedBack);
        unstable_batchedUpdates(() => {
          setFeedBack({
            active: "Submitting Form",
            progress: 1,
          });
          setLoading(true);
        });

        values._projectId = _projectId;
        values.customerId = customerId;
        values._id = formDetails._id;
        values.projectManager = projectManager;
        values.projectAddressStreet = _details.projectAddressStreet;
        values.projectAddressCity = _details.projectAddressCity;
        values.projectAddressState = _details.projectAddressState;
        values.projectAddressZip = _details.projectAddressZip;
        values.billingAddressStreet = _details.billingAddressStreet;
        values.billingAddressCity = _details.billingAddressCity;
        values.billingAddressState = _details.billingAddressState;
        values.billingAddressZip = _details.billingAddressZip;
        values.customerName = _details.customerName;
        values.companyName = _details.companyName;
        values.projectAddress = _details.projectAddress;
        values.amount = formDetails.amount;
        values.transactionDate = formDetails.transactionDate;

        // message.loading("Saving Collection...", 0);
        message.loading({
          content: "Saving receipt...",
          duration: 0,
          key: messageKey,
        });
        // let res;
        // if (!editing) {
        //   res = await api.post("/collections/create", values);
        //   dispatch(
        //     updateFormDetails("receipt", formDetails, {
        //       src: res.data._collections[res.data._collections.length - 1].src,
        //       editing: true,
        //       _id: res.data._collections[res.data._collections.length - 1]._id,
        //     })
        //   );
        // } else {
        //   values._prevDetails = {
        //     collectionType: formDetails.collectionType,
        // amount: formDetails.amount,
        // transactionDate: formDetails.transactionDate,
        //     identifier: formDetails.identifier,
        //   };
        //   values._id = formDetails._id;
        //   res = await api.post("/collections/edit", values);

        //   dispatch(
        //     updateFormDetails("receipt", formDetails, {
        //       src: res.data._collections.filter(
        //         (d) => d._id === formDetails._id
        //       )[0].src,
        //     })
        //   );
        // }

        const res = await api.post("/receipt/edit", values);

        dispatch(
          updateFormDetails("receipt", formDetails, {
            src: res.data._collections.filter(
              (d) => d._id === formDetails._id
            )[0].src,
          })
        );

        unstable_batchedUpdates(() => {
          setUnsavedChanges(false);
          // setEditing(true);
          setLoading(false);
          setFeedBack({
            active: "Done!",
            progress: 100,
          });
        });

        dispatch(
          updateCollectionsActivitiesAndHashtags({
            viewableActivities,
            _projectId,
            data: res.data,
          })
        );

        if (formDetails.callback) {
          formDetails.callback();
        }

        window.socket.removeAllListeners(`${_user}-receipt-progress`);
        // message.success(!editing ? "Collection created" : "Collection updated");
        message.success({
          content: "Receipt updated",
          duration: 1.5,
          key: messageKey,
        });
      } catch (err) {
        // message.error(
        //   !editing ? "Error creating collection" : "Error updating collection"
        // );
        message.error({
          content: "Error updating receipt",
          duration: 1.5,
          key: messageKey,
        });
        window.socket.removeAllListeners(`${_user}-receipt-progress`);
        setLoading(false);
        setFeedBack({
          active: "Submitting Form",
          progress: 0,
        });
      }
    },
    [
      _user,
      _projectId,
      customerId,
      formDetails,
      projectManager,
      _details,
      dispatch,
      // editing,
      setUnsavedChanges,
      viewableActivities,
      balance,
    ]
  );

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error updating receipt");
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  const cancel = () => {
    handleClose();
  };

  const printCollection = () => {
    printJS({
      printable: `${formDetails.src}?${new Date().getTime()}`,
      type: "pdf",
    });
  };

  const openEmail = useCallback(() => {
    dispatch(
      openForm("email", {
        content: [
          {
            _id: formDetails.src,
            src: formDetails.src,
            _projectId,
            type: "receipt",
          },
        ],
      })
    );
  }, [dispatch, formDetails, _projectId]);

  const openCollection = () => {
    openInNewTab(formDetails.src);
  };

  const updateBalance = async () => {
    try {
      // setTimeout(() => {
      let lineItems = await form.getFieldValue("lineItems");
      let _balance = sumBy(lineItems, "amount");
      setBalance(_balance);
      // });
    } catch (err) {
      console.log("updateBalance err", err);
    }
  };

  const removeRow = async () => {
    try {
      let lineItems = await form.getFieldValue("lineItems");
      lineItems = lineItems.filter((m, i) => {
        if (!m.checkbox) {
          return true;
        }
        return false;
      });
      lineItems.forEach((m, i) => {
        if (m.checkbox) {
          m.disabled = true;
          m.checkbox = false;
        }
      });
      form.setFieldsValue({
        lineItems,
      });
      setSelected(undefined);
      updateBalance();
    } catch (err) {
      console.log("removeRow err", err);
    }
  };

  const onFieldsChange = async (fields) => {
    try {
      if (fields.length > 0 && fields[0].name[2] === "checkbox") {
        let lineItems = await form.getFieldValue("lineItems");
        if (lineItems) {
          let arr = [];
          let cnt = 0;
          for (const m of lineItems) {
            if (m.checkbox) {
              arr.push(cnt);
              cnt++;
            }
          }
          if (arr.length === 0) arr = undefined;
          setSelected(arr);
        }
      }
    } catch (err) {
      console.log("onFieldsChange err", err);
    }
  };

  return (
    <Form
      form={form}
      className="form"
      autoComplete="off"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      onFieldsChange={onFieldsChange}
      initialValues={{
        lineItems: formDetails.lineItems || [{}],
      }}
    >
      <RemoveAutocomplete />

      <FormBody
        className="content-inner"
        style={{
          paddingLeft: 24,
          paddingTop: 24,
          paddingRight: 24,
          paddingBottom: 8,
          maxHeight: type === "modal" ? "calc(100vh - 161px)" : "unset",
        }}
      >
        <div className={feedBack.progress !== 0 ? "hide" : ""}>
          <Row gutter={12}>
            {invoices.map((invoice) => (
              <Col xs={24} sm={12} md={8} style={{ marginBottom: 24 }}>
                <Card
                  hoverable
                  bordered={false}
                  className="z-depth-1"
                  onClick={() => populateLineItems(invoice._id)}
                >
                  <string>{invoice.customerId}</string>
                  <br />
                  <br />
                  {invoice.lineItems.map((lineItem) => (
                    <div>
                      <div style={{ display: "inline-block", width: "50%" }}>
                        {lineItem.description}
                      </div>
                      <div
                        style={{
                          display: "inline-block",
                          width: "50%",
                          textAlign: "right",
                        }}
                      >
                        {currencyFormatter(lineItem.amount)}
                      </div>
                    </div>
                  ))}
                </Card>
              </Col>
            ))}
          </Row>

          {formDetails && formDetails.amount && (
            <Alert
              style={{ marginBottom: 12 }}
              type={
                formDetails.amount.toString() !== balance.toString()
                  ? "error"
                  : "success"
              }
              message={
                <Row gutter={16}>
                  <Col xs={24} sm={8}>
                    <b>Collected Amount:</b>
                    <div>{currencyFormatter(formDetails.amount)}</div>
                  </Col>
                  <Col xs={24} sm={8}>
                    <b>Allocated Amount:</b>
                    <div>{currencyFormatter(balance)}</div>
                  </Col>
                  <Col xs={24} sm={8}>
                    <b>Unallocated Amount:</b>
                    <div>{currencyFormatter(formDetails.amount - balance)}</div>
                  </Col>
                </Row>
              }
            />
          )}

          <Typography.Title level={4}>Line Items</Typography.Title>

          <Form.List name="lineItems">
            {(fields, { add, remove }) => {
              const handleAdd = () => {
                add();
              };

              return (
                <>
                  {fields.map((field, index) => (
                    <LineItem
                      key={field.key}
                      fields={fields}
                      field={field}
                      index={index}
                      form={form}
                      selected={selected}
                      updateBalance={updateBalance}
                      // scopes={_scopes}
                    />
                  ))}

                  <Row gutter={{ xs: 8, sm: 8, md: 12, lg: 24 }}>
                    {!selected ? (
                      <>
                        <Col xs={24}>
                          <Form.Item style={{ marginRight: 0 }}>
                            <Button
                              className={`${styles.button} green`}
                              style={
                                screens.lg
                                  ? { marginTop: 12 }
                                  : screens.md
                                  ? { marginTop: 6 }
                                  : { marginTop: 12 }
                              }
                              type="primary"
                              onClick={handleAdd}
                              block
                            >
                              <Add size={18} /> Add Line
                            </Button>
                          </Form.Item>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col xs={24}>
                          <Form.Item>
                            <Button
                              style={
                                screens.lg
                                  ? { marginTop: 12 }
                                  : screens.md
                                  ? { marginTop: 6 }
                                  : { marginTop: 12 }
                              }
                              danger
                              type="primary"
                              onClick={removeRow}
                              block
                              className={styles.button}
                            >
                              <Remove size={18} /> Remove Selected
                            </Button>
                          </Form.Item>
                        </Col>
                      </>
                    )}
                  </Row>
                </>
              );
            }}
          </Form.List>
        </div>

        <div
          style={{ height: "100%" }}
          className={feedBack.progress === 0 ? "hide" : ""}
        >
          <ProcessFeedback feedBack={feedBack} steps={steps} />
        </div>
      </FormBody>
      {/* )} */}

      {/* {_projectId && ( */}
      <FormControls className="sticky-footer">
        <CancelButton
          handleClick={cancel}
          title={feedBack.progress !== 100 ? "Cancel" : "Close"}
        />

        {formDetails.src && !unsavedChanges && feedBack.progress === 100 && (
          <>
            <Button
              type="link"
              shape="circle"
              onClick={openCollection}
              style={{ marginRight: 8 }}
            >
              <Eye />
            </Button>
            <Button
              type="link"
              shape="circle"
              onClick={openEmail}
              style={{ marginRight: 8 }}
            >
              <Email />
            </Button>
            <Button
              type="link"
              shape="circle"
              onClick={printCollection}
              style={{ marginRight: 8 }}
            >
              <Printer />
            </Button>
          </>
        )}

        {feedBack.progress === 0 && (
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={loading || !unsavedChanges}
          >
            Submit
          </Button>
        )}
      </FormControls>
    </Form>
  );
};

export default Receipt;
