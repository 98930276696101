import React, { memo } from "react";
import CheckCircle from "Icons/CheckCircle";
import styles from "./styles.module.scss";
import Spin from "antd/es/spin";

const FolderLoader = ({ state }) => {
  if (state === "loading") {
    return (
      <div className={styles.state}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className={styles.state}>
      <CheckCircle size={120} color="#00c292" className={styles.complete} />
    </div>
  );
};

export default memo(FolderLoader);
