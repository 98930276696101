import { useLocation } from "react-router-dom";

export default function useViewShow(pos1, str1, pos2, str2) {
  const location = useLocation();
  const locArr = location.pathname.split("/");

  if (pos2 && str2) {
    if (locArr[pos1] === str1 && locArr[pos2] && locArr[pos2] === str2)
      return true;
  } else {
    if (locArr[pos1] === str1) return true;
  }
  return false;
}
