import React from "react";
import Slider from "antd/es/slider";

const InputDifficultyRating = ({ value, onChange }) => {
  const triggerChange = (changedValue) => {
    if (onChange) {
      onChange(changedValue);
    }
  };

  const handleChange = (values) => {
    triggerChange(values);
  };

  return (
    <Slider
      marks={{
        2: 2,
        2.5: 2.5,
        3: 3,
        3.5: 3.5,
        4: 4,
        4.5: 4.5,
      }}
      min={2}
      max={4.5}
      step={0.5}
      value={value}
      onChange={handleChange}
    />
  );
};

export default InputDifficultyRating;
