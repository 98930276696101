import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCollapsed, showMenu } from "Actions/settingsActions";
// import { openForm } from "Actions/formActions";
import styles from "./styles.module.scss";
import { fetchNotifications, updateSw } from "Actions/authActions";
import Layout from "antd/es/layout";
import Button from "antd/es/button";
import Badge from "antd/es/badge";
import Grid from "antd/es/grid";
import GlobalSearch from "Components/Inputs/GlobalSearch";
import NotificationsIcon from "Icons/Notifications";
// import SendIcon from "Icons/Send";
import ScheduleIcon from "Icons/Calendar";
import MenuIcon from "Icons/Menu";
import dashReplace from "Utils/dashReplace";
import MobileGlobalSearch from "./GlobalSearch";
import SearchIcon from "Icons/Search";
import UpdateIcon from "Icons/Update";
import * as serviceWorker from "../../serviceWorkerRegistration";
// import Popover from "antd/es/popover";
// import Notifications from "Components/Notifications";

const { Header } = Layout;
const { useBreakpoint } = Grid;

const AppHeader = () => {
  const dispatch = useDispatch();
  const activeRoute = useSelector((state) => state.settingsState.activeRoute);
  const count = useSelector((state) => state.authState.count);
  const notifications = useSelector((state) => state.authState.notifications);
  const updateAvailable = useSelector(
    (state) => state.authState.updateAvailable
  );
  const serviceWorkerRegistration = useSelector(
    (state) => state.swState.serviceWorkerRegistration
  );
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const [showSearch, setShowSearch] = useState(false);
  const [searchFocus, setSearchFocus] = useState(false);
  const userAgent = window.navigator.userAgent.toLowerCase();
  let isIos = /iphone|ipad|ipod/.test(userAgent);

  useEffect(() => {
    dispatch(fetchNotifications({ notifications }));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const openNotifications = () => {
    navigate("/notifications");
  };

  const openShowSearch = () => {
    setShowSearch(true);
  };

  const openSchedule = () => {
    navigate("/schedule");
  };

  const collapseSidebar = useCallback(
    (bool) => {
      dispatch(setCollapsed(bool));
    },
    [dispatch]
  );

  const openMenu = useCallback(() => {
    dispatch(showMenu(true));
  }, [dispatch]);

  const handleUpdate = () => {
    const registrationWaiting = serviceWorkerRegistration
      ? serviceWorkerRegistration.waiting
      : undefined;
    dispatch(updateSw());
    if (registrationWaiting && !isIos) {
      registrationWaiting.postMessage({ type: "SKIP_WAITING" });
      registrationWaiting.addEventListener("statechange", (e) => {
        if (e.target.state === "activated") {
          window.location.reload();
        }
      });
    } else {
      serviceWorker.unregister();
    }
  };

  // const openRequests = useCallback(() => {
  //   dispatch(openForm("requests"));
  // }, [dispatch]);

  return (
    <Header
      className={`${styles.header} ${showSearch ? styles.search : ""} ${
        searchFocus ? styles.searchFocus : ""
      }`}
    >
      {!screens.lg && (
        <MobileGlobalSearch
          showSearch={showSearch}
          setShowSearch={setShowSearch}
          setSearchFocus={setSearchFocus}
        />
      )}

      <div className={styles.headerLeft}>
        <div style={{ verticalAlign: "top", display: "inline-block" }}>
          <Button
            type="secondary"
            shape="circle"
            ghost
            style={{ border: "none" }}
            onClick={screens.lg ? collapseSidebar : openMenu}
            className={`${styles.menuIcon} ${showSearch ? styles.search : ""}`}
          >
            <MenuIcon color="#ffffff" />
          </Button>
        </div>
        <div
          style={{
            marginLeft: 12,
            textTransform: "uppercase",
            fontWeight: 500,
            display: "inline-block",
            verticalAlign: "middle",
          }}
          className={`${styles.route} ${showSearch ? styles.search : ""}`}
        >
          {dashReplace(activeRoute)}
        </div>
      </div>
      {screens.lg && (
        <div className={styles.headerMiddle}>
          <GlobalSearch header />
        </div>
      )}
      <div className={styles.headerRight}>
        <div style={{ height: 64 }}>
          <small
            style={{ marginRight: 8 }}
            className={`${styles.version} ${
              showSearch ? styles.showSearch : ""
            }`}
          >
            v
            {process.env.REACT_APP_VERSION || process.env.REACT_APP_VERSION_DEV}
          </small>
          {updateAvailable ? (
            <Button
              type="text"
              // shape="circle"
              style={{ color: "#ffffff", width: "unset", margin: 0 }}
              onClick={handleUpdate}
              className={styles.updateAvailable}
              // className={`${styles.notificationsIcon} ${
              //   showSearch ? styles.showSearch : ""
              // }`}
            >
              <UpdateIcon color="#ffffff" />
              <span style={{ color: "#ffffff" }}>Update Available</span>
            </Button>
          ) : (
            <>
              <Button
                type="text"
                shape="circle"
                onClick={openSchedule}
                className={`${styles.notificationsIcon} ${
                  showSearch ? styles.showSearch : ""
                }`}
                style={{ marginRight: 8 }}
              >
                <ScheduleIcon color="#ffffff" />
              </Button>
              {/* <Button
                type="text"
                shape="circle"
                onClick={openRequests}
                className={`${styles.notificationsIcon} ${
                  showSearch ? styles.showSearch : ""
                }`}
                style={{ marginRight: 8 }}
              >
                <SendIcon color="#ffffff" />
              </Button> */}
              {/* {!screens.lg ? ( */}
              <Button
                type="text"
                shape="circle"
                onClick={openNotifications}
                className={`${styles.notificationsIcon} ${
                  showSearch ? styles.showSearch : ""
                }`}
                style={{ marginRight: !screens.lg ? 8 : 0 }}
              >
                <Badge count={count}>
                  <NotificationsIcon color="#ffffff" />
                </Badge>
              </Button>
              {/* ) : (
            <Popover
              placement="bottomRight"
              // trigger={["click", "focus"]}
              trigger="click"
              align={{
                offset: [5, 0],
              }}
              title={<NotificationsHeader />}
              content={
                <>
                  <Notifications />
                  <NotificationsFooter />
                </>
              }
              overlayClassName={styles.popover}
            >
              <Button
                type="text"
                shape="circle"
                className={`${styles.notificationsIcon} ${
                  showSearch ? styles.showSearch : ""
                }`}
                style={{ marginRight: !screens.lg ? 8 : 0 }}
              >
                <Badge count={count}>
                  <NotificationsIcon color="#ffffff" />
                </Badge>
              </Button>
            </Popover>
          )} */}

              {!screens.lg && (
                <Button
                  type="text"
                  shape="circle"
                  onClick={openShowSearch}
                  className={`${styles.notificationsIcon} ${
                    showSearch ? styles.showSearch : ""
                  }`}
                >
                  <SearchIcon color="#ffffff" />
                </Button>
              )}
            </>
          )}
        </div>
      </div>
    </Header>
  );
};

export default AppHeader;
