import * as actions from "./actionTypes";
import api from "API";
import * as Sentry from "@sentry/react";
import * as serviceWorker from "../serviceWorkerRegistration";

export const init = (token) => async (dispatch) => {
  try {
    if (!token) token = await localStorage.getItem("storyToken");
    const res = await api.get(
      `/init/story/${token}/${
        process.env.REACT_APP_VERSION || process.env.REACT_APP_VERSION_DEV
      }`
    );

    Sentry.setUser({ name: res.data.name });

    // if (res.data.updateAvailable) {
    //   serviceWorker.register({
    //     onSuccess: () => dispatch({ type: actions.SW_INIT }),
    //     onUpdate: (reg) => dispatch({ type: actions.SW_UPDATE, payload: reg }),
    //   });
    // }

    dispatch({
      type: actions.SIGNIN,
      payload: {
        token,
        manager: res.data.manager,
        name: res.data.name,
        email: res.data.email,
        phone: res.data.phone,
        admin: res.data.admin,
        role: res.data.role,
        reports: res.data.reports,
        status: res.data.status,
        _user: res.data._user,
        mentions: res.data.mentions,
        salesman: res.data.salesman,
        install: res.data.install,
        menuItems: res.data.menuItems,
        updateAvailable: res.data.updateAvailable,
        notificationsCount: res.data.notificationsCount,
        storyEmailNotifications: res.data.storyEmailNotifications,
      },
    });

    if (!window.socket.hasListeners(res.data._user))
      window.socket.on(res.data._user, () => replaceNotifications()(dispatch));

    return true;
  } catch (err) {
    console.log("init err", err);
    localStorage.removeItem("storyToken");
    return false;
  }
};

export const clearErrorMessage = () => (dispatch) => {
  try {
    dispatch({ type: actions.CLEAR_ERROR_MESSAGE });
  } catch (err) {
    console.log("clearErrorMessage err", err);
  }
};

export const signin =
  ({ email, password, city, cb, failCb }) =>
  async (dispatch) => {
    try {
      const res = await api.post(
        `/signin/${
          process.env.REACT_APP_VERSION || process.env.REACT_APP_VERSION_DEV
        }`,
        {
          email,
          password,
          city,
        }
      );
      localStorage.setItem("storyToken", res.data.token);

      Sentry.setUser({ name: res.data.name });

      // if (res.data.updateAvailable) {
      //   serviceWorker.register({
      //     onSuccess: () => dispatch({ type: actions.SW_INIT }),
      //     onUpdate: (reg) => dispatch({ type: actions.SW_UPDATE, payload: reg }),
      //   });
      // }

      await dispatch({
        type: actions.SIGNIN,
        payload: {
          token: res.data.token,
          manager: res.data.manager,
          name: res.data.name,
          email: res.data.email,
          phone: res.data.phone,
          admin: res.data.admin,
          role: res.data.role,
          reports: res.data.reports,
          status: res.data.status,
          _user: res.data._user,
          mentions: res.data.mentions,
          install: res.data.install,
          salesman: res.data.salesman,
          menuItems: res.data.menuItems,
          updateAvailable: res.data.updateAvailable,
          notificationsCount: res.data.notificationsCount,
          storyEmailNotifications: res.data.storyEmailNotifications,
        },
      });

      if (!window.socket.hasListeners(res.data._user))
        window.socket.on(res.data._user, () =>
          replaceNotifications()(dispatch)
        );

      cb();
    } catch (err) {
      console.log("signin err.response", err.response);
      console.log("signin err", err);
      if (err.response) {
        if (err.response.data.status !== 429) {
          dispatch({
            type: actions.ADD_ERROR,
            payload: "The email or password provided is incorrect",
          });
        } else {
          dispatch({
            type: actions.ADD_ERROR,
            payload: `Too many failed attempts. Please try again later.`,
          });
        }
      }
      failCb();
    }
  };

export const signout = () => async (dispatch) => {
  try {
    localStorage.removeItem("storyToken");
    const _user = localStorage.getItem("user");
    Sentry.configureScope((scope) => scope.setUser(null));
    window.socket.removeAllListeners(_user);
    serviceWorker.unregister();
    dispatch({ type: actions.SIGNOUT });
  } catch (err) {
    console.log("signout err", err);
  }
};

export const forgotPassword =
  ({ email, city, cb }) =>
  async () => {
    try {
      await api.post("/forgot-password", { email, city });
      cb(true);
    } catch (err) {
      console.log("/forgot-password err", err);
      cb(false);
    }
  };

export const passwordReset =
  ({ new_password, confirm_password, _id, cb, failCb }) =>
  async (dispatch) => {
    try {
      const res = await api.post("/password-reset", {
        new_password,
        confirm_password,
        _id,
      });
      localStorage.setItem("storyToken", res.data.token);

      Sentry.setUser({ name: res.data.name });

      await dispatch({
        type: actions.SIGNIN,
        payload: {
          token: res.data.token,
          manager: res.data.manager,
          name: res.data.name,
          email: res.data.email,
          phone: res.data.phone,
          admin: res.data.admin,
          role: res.data.role,
          reports: res.data.reports,
          status: res.data.status,
          _user: res.data._user,
          mentions: res.data.mentions,
          install: res.data.install,
          menuItems: res.data.menuItems,
          salesman: res.data.salesman,
        },
      });

      cb();
    } catch (err) {
      console.log("passwordReset err.response", err.response);
      console.log("passwordReset err", err);
      if (err.response) {
        if (err.response.data.status !== 429) {
          dispatch({
            type: actions.ADD_ERROR,
            payload: "The email or password provided is incorrect",
          });
        } else {
          dispatch({
            type: actions.ADD_ERROR,
            payload: `Too many failed attempts. Please try again later.`,
          });
        }
      }
      failCb();
    }
  };

export const changePassword =
  ({ old_password, new_password, confirm_password, _id, cb }) =>
  async (dispatch) => {
    try {
      const res = await api.post("/change-password", {
        old_password,
        new_password,
        confirm_password,
        _id,
      });
      localStorage.setItem("storyToken", res.data.token);

      Sentry.setUser({ name: res.data.name });

      await dispatch({
        type: actions.SIGNIN,
        payload: {
          token: res.data.token,
          manager: res.data.manager,
          name: res.data.name,
          email: res.data.email,
          phone: res.data.phone,
          admin: res.data.admin,
          role: res.data.role,
          reports: res.data.reports,
          status: res.data.status,
          _user: res.data._user,
          mentions: res.data.mentions,
          menuItems: res.data.menuItems,
          salesman: res.data.salesman,
          install: res.data.install,
        },
      });
      cb();
    } catch (err) {
      console.log("changePassword err", err);
      cb();
    }
  };

export const fetchNotifications =
  ({ notifications }) =>
  async (dispatch) => {
    try {
      const res = await api.get(`/notifications/${notifications.length}`);
      dispatch({
        type: actions.SET_NOTIFICATIONS,
        payload: {
          notifications: res.data.notifications,
          notificationsLength: res.data.length,
          count: res.data.count,
        },
      });
    } catch (err) {
      console.log("fetchNotifications err", err);
    }
  };

export const replaceNotifications = () => async (dispatch) => {
  try {
    const res = await api.get(`/notifications/0`);
    dispatch({
      type: actions.REPLACE_NOTIFICATIONS,
      payload: {
        notifications: res.data.notifications,
        notificationsLength: res.data.length,
        count: res.data.count,
      },
    });
  } catch (err) {
    console.log("replaceNotifications err", err);
  }
};

export const markRead =
  ({ _id, notifications }) =>
  async (dispatch) => {
    try {
      const res = await api.post("/notifications/read", {
        _id,
        length: notifications.length,
      });
      dispatch({
        type: actions.REPLACE_NOTIFICATIONS,
        payload: {
          notifications: res.data.notifications,
          notificationsLength: res.data.length,
          count: res.data.count,
        },
      });
    } catch (err) {
      console.log("markRead err", err);
    }
  };

export const markUnread =
  ({ _id, notifications }) =>
  async (dispatch) => {
    try {
      const res = await api.post("/notifications/unread", {
        _id,
        length: notifications.length,
      });
      dispatch({
        type: actions.REPLACE_NOTIFICATIONS,
        payload: {
          notifications: res.data.notifications,
          notificationsLength: res.data.length,
          count: res.data.count,
        },
      });
    } catch (err) {
      console.log("markUnread err", err);
    }
  };

export const markAllRead =
  ({ notifications }) =>
  async (dispatch) => {
    try {
      const res = await api.get(
        `/notifications/readAll/${notifications.length}`
      );
      // let count = 0;
      dispatch({
        type: actions.REPLACE_NOTIFICATIONS,
        payload: {
          notifications: res.data.notifications,
          notificationsLength: res.data.length,
          count: 0,
        },
      });
    } catch (err) {
      console.log("markAllRead err", err);
    }
  };

export const updateMenuItems = (menuItem, value) => async (dispatch) => {
  try {
    const res = await api.post("/update-menu-items", {
      [menuItem]: value,
    });
    dispatch({
      type: actions.UPDATE_MENU_ITEMS,
      payload: res.data,
    });
  } catch (err) {
    console.log("updateMenuItems err", err);
  }
};

export const updateSw = () => async (dispatch) => {
  dispatch({
    type: actions.SW_UPDATING,
  });
};

export const setEmailNotifications = (bool) => (dispatch) => {
  dispatch({ type: actions.EMAIL_NOTIFICATIONS, payload: bool });
};
