import React from "react";
import Tabs from "antd/es/tabs";
import Scheduling from "./Scheduling";
import Management from "./Management";

import styles from "./styles.module.scss";

const { TabPane } = Tabs;

const LeadScheduling = () => {
  return (
    <div style={{ height: "100%" }}>
      <div
        style={{
          background: "#f0f2f5",
          height: "100%",
          paddingLeft: 12,
          paddingRight: 12,
          paddingBottom: 12,
          paddingTop: 0,
        }}
      >
        <Tabs defaultActiveKey="scheduling" className={styles.tabs} centered>
          <TabPane tab="Scheduling" key="scheduling" style={{ height: "100%" }}>
            <Scheduling />
          </TabPane>
          <TabPane tab="Management" key="management" style={{ height: "100%" }}>
            <Management />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default LeadScheduling;
