import React from "react";
import InputNumber from "antd/es/input-number";

const InputNumberNoAutoComplete = ({
  value,
  onChange,
  disabled,
  handleFocus,
  handleBlur,
  autoFocus = false,
  placeholder = undefined,
  type = "text",
  tabIndex = undefined,
  numberType = "number",
  className,
  precision = 0,
  id = undefined,
  size = "normal",
}) => {
  const triggerChange = (changedValue) => {
    if (onChange) {
      onChange(changedValue);
    }
  };

  const handleChange = (e) => {
    triggerChange(e);
  };

  return (
    <div id={id}>
      <label htmlFor="" style={{ display: "none" }}>
        hidden label to mislead chrome autocomplete
      </label>
      <InputNumber
        placeholder={placeholder}
        autoComplete="dont-use-autocomplete"
        disabled={disabled}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        value={value}
        size={size}
        autoFocus={autoFocus}
        type={type}
        tabIndex={tabIndex}
        controls={false}
        formatter={
          numberType === "currency"
            ? (value) => {
                if (!value) return value;
                value = Math.round(value * 100) / 100;
                return `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              }
            : numberType === "percent"
            ? (value) => {
                if (!value) return value;
                value = Math.round(value * 100) / 100;
                return `${value}%`;
              }
            : undefined
        }
        parser={
          numberType === "currency"
            ? (value) => {
                return value.replace(/\$\s?|(,*)/g, "");
              }
            : numberType === "percent"
            ? (value) => value.replace("%", "")
            : undefined
        }
        className={className}
        precision={precision}
      />
    </div>
  );
};

export default InputNumberNoAutoComplete;
