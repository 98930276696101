import React from "react";
import LineChartWithInterval from "Reports/LineChartWithInterval";

export default function CompanyProfitPercentage() {
  return (
    <LineChartWithInterval
      path="/reports/companyProfitPercentage"
      type="percent"
      summary={true}
      lineItems={false}
      aggregate={false}
      report="COMPANY PROFIT PERCENTAGE"
    />
  );
}
