import React, { useState, useEffect, useCallback } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchTable } from "Actions/tableActions";
import api from "API";
import Input from "antd/es/input";
import Form from "antd/es/form";
import message from "antd/es/message";
import Button from "antd/es/button";
import Modal from "antd/es/modal";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import Phone from "Components/Inputs/Phone";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import CancelButton from "Components/CancelButton";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";
import Select from "Components/Inputs/Select";
import Checkbox from "antd/es/checkbox";
import dayjs from "dayjs";
import DatePicker from "Components/Inputs/DatePicker";
import useIsMobile from "Hooks/useIsMobile";
import Divider from "antd/es/divider";

const messageKey = "messageKey";

const Sub = ({
  unsavedChanges,
  setUnsavedChanges,
  handleClose,
  setTitle,
  type,
}) => {
  const dispatch = useDispatch();
  const formDetails = useSelector((state) => state.formState.sub.formDetails);
  const table = useSelector((state) => state.tableState.table);
  const col = useSelector((state) => state.tableState.col);
  const order = useSelector((state) => state.tableState.order);
  const search = useSelector((state) => state.tableState.search);
  const activeFilter = useSelector((state) => state.tableState.activeFilter);
  const mobile = useIsMobile();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [glChecked, setGlChecked] = useState(formDetails.glRequired);
  const [wcChecked, setWcChecked] = useState(formDetails.wcRequired);
  const [expenseTypes, setExpenseTypes] = useState([]);
  const [trades, setTrades] = useState([]);
  const [status, setStatus] = useState(
    !formDetails.status ? "Active" : formDetails.status
  );

  useEffect(() => {
    fetchExpenseTypesAndTrades();
    if (!formDetails.editing) {
      setTitle("Create Sub");
    } else {
      setTitle(
        <span>
          Edit Sub{" "}
          <Button
            size="small"
            danger
            onClick={confirmDelete}
            style={{ float: "right", marginRight: 28 }}
          >
            Delete
          </Button>
        </span>
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchExpenseTypesAndTrades = async () => {
    try {
      const res = await api.post("/dropdowns/get", {
        selects: ["Expenses", "Trades"],
      });

      unstable_batchedUpdates(() => {
        setExpenseTypes(res.data.Expenses.options);
        setTrades(res.data.Trades.options);
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const confirmDelete = () => {
    Modal.confirm({
      zIndex: 4002,
      icon: <QuestionCircleOutlined />,
      centered: true,
      cancelText: "Cancel",
      okText: "Delete",
      okButtonProps: { danger: true },
      content: <div>Are you sure you want to delete this sub?</div>,
      onOk() {
        return new Promise((resolve, reject) => {
          deleteSub(() => resolve(true));
        }).catch((err) => console.log("err: ", err));
      },
      onCancel() {},
    });
  };

  const deleteSub = useCallback(
    async (cb) => {
      try {
        const _id = await form.getFieldValue("_id");
        await api.delete(`/vendor/${_id}`);

        dispatch(
          fetchTable({
            table,
            col,
            order,
            search,
            activeFilter,
            fetching: false,
          })
        );

        unstable_batchedUpdates(() => {
          setUnsavedChanges(false);
          setLoading(false);
        });

        cb();
        message.success("Sub Deleted");
        handleClose(true);
      } catch (err) {
        console.log("err", err);
        message.error("Error deleting sub");
      }
    },
    [
      form,
      dispatch,
      table,
      col,
      order,
      search,
      activeFilter,
      setUnsavedChanges,
      handleClose,
    ]
  );

  const onFinish = useCallback(
    async (values) => {
      try {
        setLoading(true);
        // message.loading("Saving Sub...", 0);
        message.loading({
          content: "Saving sub...",
          duration: 0,
          key: messageKey,
        });
        if (!formDetails.editing) {
          await api.post("/subs/create", values);
        } else {
          values.prevStatus = formDetails.status;
          await api.post("/subs/edit", values);
        }

        dispatch(
          fetchTable({
            table,
            col,
            order,
            search,
            activeFilter,
            fetching: false,
          })
        );

        unstable_batchedUpdates(() => {
          setUnsavedChanges(false);
          setLoading(false);
        });

        // message.success("Sub saved");
        message.success({
          content: "Sub saved",
          duration: 1.5,
          key: messageKey,
        });
        handleClose(true);
      } catch (err) {
        setLoading(false);
        // message.error("Error saving sub");
        message.error({
          content: "Error saving sub",
          duration: 1.5,
          key: messageKey,
        });
        console.log("err", err);
      }
    },
    [
      formDetails,
      dispatch,
      table,
      col,
      order,
      search,
      activeFilter,
      setUnsavedChanges,
      handleClose,
    ]
  );

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error saving sub");
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  const handleGLChange = (e) => {
    setGlChecked(e.target.checked);
  };

  const handleWCChange = (e) => {
    setWcChecked(e.target.checked);
  };

  const handleStatusChange = (e) => {
    setStatus(e);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      initialValues={{
        _id: !formDetails._id ? undefined : formDetails._id,
        status: !formDetails.status ? undefined : formDetails.status,
        notActiveReason: !formDetails.notActiveReason
          ? undefined
          : formDetails.notActiveReason,
        companyName: !formDetails.companyName
          ? undefined
          : formDetails.companyName,
        contactName: !formDetails.contactName
          ? undefined
          : formDetails.contactName,
        phone: !formDetails.phone ? undefined : formDetails.phone,
        email: !formDetails.email ? undefined : formDetails.email,
        trades: !formDetails.trades ? undefined : formDetails.trades,
        glRequired: !formDetails.glRequired
          ? undefined
          : formDetails.glRequired,
        wcRequired: !formDetails.wcRequired
          ? undefined
          : formDetails.wcRequired,

        glCarrier: !formDetails.glDetails
          ? undefined
          : formDetails.glDetails.carrier,
        glContactName: !formDetails.glDetails
          ? undefined
          : formDetails.glDetails.contactName,
        glPhone: !formDetails.glDetails
          ? undefined
          : formDetails.glDetails.phone,
        glEmail: !formDetails.glDetails
          ? undefined
          : formDetails.glDetails.email,
        glExpiration: !formDetails.glDetails
          ? undefined
          : formDetails.glDetails.expiration
          ? dayjs(formDetails.glDetails.expiration)
          : undefined,
        glLastVerified: !formDetails.glDetails
          ? undefined
          : formDetails.glDetails.lastVerified
          ? dayjs(formDetails.glDetails.lastVerified)
          : undefined,

        wcCarrier: !formDetails.wcDetails
          ? undefined
          : formDetails.wcDetails.carrier,
        wcContactName: !formDetails.wcDetails
          ? undefined
          : formDetails.wcDetails.contactName,
        wcPhone: !formDetails.wcDetails
          ? undefined
          : formDetails.wcDetails.phone,
        wcEmail: !formDetails.wcDetails
          ? undefined
          : formDetails.wcDetails.email,
        wcExpiration: !formDetails.wcDetails
          ? undefined
          : formDetails.wcDetails.expiration
          ? dayjs(formDetails.wcDetails.expiration)
          : undefined,
        wcLastVerified: !formDetails.wcDetails
          ? undefined
          : formDetails.wcDetails.lastVerified
          ? dayjs(formDetails.wcDetails.lastVerified)
          : undefined,

        expenseTypes: !formDetails.expenseTypes
          ? undefined
          : formDetails.expenseTypes,
      }}
      className="form"
    >
      <RemoveAutocomplete />

      <FormBody
        className="content-inner"
        style={{
          paddingLeft: 24,
          paddingTop: 24,
          paddingRight: 24,
          paddingBottom: 8,
          maxHeight: type === "modal" ? "calc(100vh - 161px)" : "unset",
        }}
      >
        <Form.Item name="_id" hidden>
          <Input />
        </Form.Item>

        {formDetails._id && (
          <>
            <Form.Item
              name="status"
              label="Status"
              rules={[{ required: true, message: "Status is required" }]}
            >
              <Select
                options={["Active", "Inactive", "Suspended"]}
                id="status"
                onChange={handleStatusChange}
              />
            </Form.Item>

            {status !== "Active" && (
              <Form.Item
                name="notActiveReason"
                label="Not Active Reason"
                rules={[
                  { required: true, message: "Not Active Reason is required" },
                ]}
              >
                <Input.TextArea rows={4} />
              </Form.Item>
            )}

            <Divider />
          </>
        )}

        <Form.Item
          name="companyName"
          label="Company Name"
          rules={[{ required: true, message: "Company name is required" }]}
        >
          <InputNoAutoComplete autoFocus id="companyName" />
        </Form.Item>

        <Form.Item
          name="contactName"
          label="Contact Name"
          rules={[{ required: true, message: "Contact name is required" }]}
        >
          <InputNoAutoComplete id="contactName" />
        </Form.Item>

        <Form.Item
          name="phone"
          label="Phone"
          rules={[{ required: true, message: "Phone is required" }]}
        >
          <Phone id="phone" />
        </Form.Item>

        <Form.Item
          name="email"
          label="Email"
          rules={[
            { type: "email", message: "Must be valid email" },
            { required: "Email is required" },
          ]}
        >
          <InputNoAutoComplete type="email" id="email" />
        </Form.Item>

        <Form.Item
          name="trades"
          label="Trades"
          rules={[{ required: true, message: "Trades is required" }]}
        >
          <Select mode="multiple" options={trades} id="trades" />
        </Form.Item>

        <Form.Item name="glRequired" valuePropName="checked">
          <Checkbox onChange={handleGLChange}>
            General Liability Required
          </Checkbox>
        </Form.Item>

        {glChecked && (
          <>
            <Form.Item
              name="glCarrier"
              label="Carrier"
              rules={[{ required: true, message: "Carrier is required" }]}
            >
              <InputNoAutoComplete id="glCarrier" />
            </Form.Item>

            <Form.Item name="glContactName" label="Contact Name">
              <InputNoAutoComplete id="glContactName" />
            </Form.Item>

            <Form.Item name="glPhone" label="Phone">
              <Phone id="glPhone" />
            </Form.Item>

            <Form.Item
              name="glEmail"
              label="Email"
              rules={[{ type: "email", message: "Must be valid email" }]}
            >
              <InputNoAutoComplete type="email" id="glEmail" />
            </Form.Item>

            <Form.Item
              name="glExpiration"
              label="Expiration"
              rules={[
                {
                  required: true,
                  message: "Expiration is required",
                },
              ]}
            >
              <DatePicker
                format="MMMM DD, YYYY"
                getPopupContainer={(trigger) =>
                  !mobile
                    ? trigger.parentNode.parentNode.parentNode.parentNode
                    : document.body
                }
                getCalendarContainer={(trigger) =>
                  !mobile
                    ? trigger.parentNode.parentNode.parentNode.parentNode
                    : document.body
                }
                inputReadOnly={true}
              />
            </Form.Item>

            <Form.Item name="glLastVerified" label="Last Verified">
              <DatePicker
                format="MMMM DD, YYYY"
                getPopupContainer={(trigger) =>
                  !mobile
                    ? trigger.parentNode.parentNode.parentNode.parentNode
                    : document.body
                }
                getCalendarContainer={(trigger) =>
                  !mobile
                    ? trigger.parentNode.parentNode.parentNode.parentNode
                    : document.body
                }
                inputReadOnly={true}
              />
            </Form.Item>
          </>
        )}

        <Form.Item name="wcRequired" valuePropName="checked">
          <Checkbox onChange={handleWCChange}>
            Workers Compensation Required
          </Checkbox>
        </Form.Item>

        {wcChecked && (
          <>
            <Form.Item
              name="wcCarrier"
              label="Carrier"
              rules={[{ required: true, message: "Carrier is required" }]}
            >
              <InputNoAutoComplete id="wcCarrier" />
            </Form.Item>

            <Form.Item name="wcContactName" label="Contact Name">
              <InputNoAutoComplete id="wcContactName" />
            </Form.Item>

            <Form.Item name="wcPhone" label="Phone">
              <Phone id="wcPhone" />
            </Form.Item>

            <Form.Item
              name="wcEmail"
              label="Email"
              rules={[{ type: "email", message: "Must be valid email" }]}
            >
              <InputNoAutoComplete type="email" id="wcEmail" />
            </Form.Item>

            <Form.Item
              name="wcExpiration"
              label="Expiration"
              rules={[
                {
                  required: true,
                  message: "Expiration is required",
                },
              ]}
            >
              <DatePicker
                format="MMMM DD, YYYY"
                getPopupContainer={(trigger) =>
                  !mobile
                    ? trigger.parentNode.parentNode.parentNode.parentNode
                    : document.body
                }
                getCalendarContainer={(trigger) =>
                  !mobile
                    ? trigger.parentNode.parentNode.parentNode.parentNode
                    : document.body
                }
                inputReadOnly={true}
              />
            </Form.Item>

            <Form.Item name="wcLastVerified" label="Last Verified">
              <DatePicker
                format="MMMM DD, YYYY"
                getPopupContainer={(trigger) =>
                  !mobile
                    ? trigger.parentNode.parentNode.parentNode.parentNode
                    : document.body
                }
                getCalendarContainer={(trigger) =>
                  !mobile
                    ? trigger.parentNode.parentNode.parentNode.parentNode
                    : document.body
                }
                inputReadOnly={true}
              />
            </Form.Item>
          </>
        )}

        <Form.Item
          name="expenseTypes"
          label="Expense Types"
          rules={[{ required: true, message: "Expense Types is required" }]}
        >
          <Select mode="multiple" options={expenseTypes} id="expenseTypes" />
        </Form.Item>
      </FormBody>
      <FormControls className="sticky-footer">
        <CancelButton handleClick={handleClose} />
        <Form.Item style={{ margin: 0 }}>
          <Button
            htmlType="submit"
            type="primary"
            loading={loading}
            disabled={loading || !unsavedChanges}
          >
            Submit
          </Button>
        </Form.Item>
      </FormControls>
    </Form>
  );
};

export default Sub;
