import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateActionsActivitiesAndHashtags } from "Actions/dataActions";
import Input from "antd/es/input";
import Form from "antd/es/form";
import Radio from "antd/es/radio";
import Button from "antd/es/button";
import message from "antd/es/message";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import api from "API";
import Currency from "Components/Inputs/Currency";
import styles from "./styles.module.scss";
import SearchLeadsAndProjects from "Components/Inputs/SearchLeadsAndProjects";
import Modal from "antd/es/modal";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";
import CancelButton from "Components/CancelButton";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";
import MarkUpHelper from "Components/MarkUpHelper";

const messageKey = "messageKey";

const Action = ({
  unsavedChanges,
  setUnsavedChanges,
  handleClose,
  setTitle,
  type,
}) => {
  const dispatch = useDispatch();
  const formDetails = useSelector(
    (state) => state.formState.action.formDetails
  );
  const details = useSelector((state) => state.dataState.details);
  const viewableActivities = useSelector(
    (state) => state.dataState.viewableActivities
  );
  const [_projectId, _setProjectId] = useState(
    formDetails._projectId ? formDetails._projectId : details._id
  );
  const [customerId, setCustomerId] = useState(
    formDetails.customerId ? formDetails.customerId : details.customerId
  );
  const [projectManager, setProjectManager] = useState(
    formDetails.projectManager
      ? formDetails.projectManager
      : details.projectManager
  );
  const [loading, setLoading] = useState(false);
  const [loadingSAC, setLoadingSAC] = useState(false);
  const [editing] = useState(formDetails.editing ? formDetails.editing : false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (!formDetails.editing) {
      setTitle("Create Invoice Action");
    } else {
      setTitle(
        <div style={{ marginRight: 28 }}>
          Edit Invoice Action{" "}
          <Button
            size="small"
            danger
            onClick={confirmDeleteAction}
            style={{ float: "right" }}
          >
            Delete
          </Button>
        </div>
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onFinish = useCallback(async () => {
    try {
      let values = await form.validateFields();
      setLoading(true);
      values._projectId = _projectId;
      values.customerId = customerId;
      values.projectManager = projectManager;
      values._id = formDetails._id ? formDetails._id : undefined;

      // message.loading("Saving Invoice Action...", 0);
      message.loading({
        content: "Saving invoice action...",
        duration: 0,
        key: messageKey,
      });
      let res;
      if (!editing) {
        res = await api.post("/actions/create", values);
      } else {
        values._prevDetails = {
          title: formDetails.title,
          description: formDetails.description,
          amount: formDetails.amount,
          open: formDetails.open,
        };
        res = await api.post("/actions/edit", values);
      }

      dispatch(
        updateActionsActivitiesAndHashtags({
          viewableActivities,
          _projectId,
          data: res.data,
        })
      );

      setUnsavedChanges(false);

      // message.success(
      //   !editing ? "Action invoice created" : "Action invoice updated"
      // );
      message.success({
        content: !editing ? "Action invoice created" : "Action invoice updated",
        duration: 1.5,
        key: messageKey,
      });
      handleClose(true);
    } catch (err) {
      setLoading(false);
      // message.error(
      // !editing
      //   ? "Error creating invoice action"
      //   : "Error updating invoice action"
      // );
      message.error({
        content: !editing
          ? "Error creating invoice action"
          : "Error updating invoice action",
        duration: 1.5,
        key: messageKey,
      });
      if (
        err &&
        err.errorFields &&
        err.errorFields[0] &&
        err.errorFields[0].name
      ) {
        form.scrollToField(err.errorFields[0].name[0]);
      }
    }
  }, [
    _projectId,
    customerId,
    dispatch,
    editing,
    form,
    formDetails,
    handleClose,
    projectManager,
    setUnsavedChanges,
    viewableActivities,
  ]);

  const submitAnother = useCallback(async () => {
    try {
      let values = await form.validateFields();
      setLoadingSAC(true);
      values._projectId = _projectId;
      values.customerId = customerId;
      values.projectManager = projectManager;
      values._id = formDetails._id ? formDetails._id : undefined;

      // message.loading("Saving Invoice Action...", 0);
      message.loading({
        content: "Saving invoice action...",
        duration: 0,
        key: messageKey,
      });
      let res;
      if (!editing) {
        res = await api.post("/actions/create", values);
      } else {
        values._prevDetails = {
          title: formDetails.title,
          description: formDetails.description,
          amount: formDetails.amount,
          open: formDetails.open,
        };
        res = await api.post("/actions/edit", values);
      }

      dispatch(
        updateActionsActivitiesAndHashtags({
          viewableActivities,
          _projectId,
          data: res.data,
        })
      );

      setUnsavedChanges(false);
      setTitle("Create Invoice Action");

      // message.success(
      //   !editing ? "Invoice action created" : "Invoice action updated"
      // );
      message.success({
        content: !editing ? "Invoice action created" : "Invoice action updated",
        duration: 1.5,
        key: messageKey,
      });
      setLoadingSAC(false);
      form.setFieldsValue({
        open: true,
        title: undefined,
        description: undefined,
        amount: undefined,
      });
    } catch (err) {
      // message.error(
      // !editing
      //   ? "Error creating invoice action"
      //   : "Error updating invoice action"
      // );
      message.error({
        content: !editing
          ? "Error creating invoice action"
          : "Error updating invoice action",
        duration: 1.5,
        key: messageKey,
      });
      if (
        err &&
        err.errorFields &&
        err.errorFields[0] &&
        err.errorFields[0].name
      ) {
        form.scrollToField(err.errorFields[0].name[0]);
      }
    }
  }, [
    _projectId,
    customerId,
    dispatch,
    editing,
    form,
    formDetails,
    projectManager,
    setTitle,
    setUnsavedChanges,
    viewableActivities,
  ]);

  const onFinishFailed = (err) => {
    console.log("err", err);
    if (
      err &&
      err.errorFields &&
      err.errorFields[0] &&
      err.errorFields[0].name
    ) {
      form.scrollToField(err.errorFields[0].name[0]);
    }
    message.error(
      !editing
        ? "Error creating invoice action"
        : "Error updating invoice action"
    );
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  const cancel = () => {
    handleClose();
  };

  const handleSelect = (selected) => {
    _setProjectId(selected._id);
    setCustomerId(selected.customerId);
    setProjectManager(selected.projectManager);
  };

  const confirmDeleteAction = () => {
    Modal.confirm({
      zIndex: 4002,
      icon: <QuestionCircleOutlined />,
      centered: true,
      cancelText: "Cancel",
      okText: "Delete",
      okButtonProps: { danger: true },
      content: <div>Are you sure you want to delete this action?</div>,
      onOk() {
        return new Promise((resolve, reject) => {
          deleteAction(() => resolve(true));
        }).catch((err) => console.log("err: ", err));
      },
      onCancel() {},
    });
  };

  const deleteAction = useCallback(
    async (cb) => {
      try {
        const res = await api.delete(`/actions/delete/${formDetails._id}`);
        dispatch(
          updateActionsActivitiesAndHashtags({
            viewableActivities,
            _projectId,
            data: res.data,
          })
        );

        cb();
        handleClose(true);
      } catch (err) {
        console.log("err", err);
        message.error("Error deleting action");
      }
    },
    [formDetails._id, dispatch, viewableActivities, _projectId, handleClose]
  );

  return (
    <Form
      form={form}
      className="form"
      autoComplete="off"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      initialValues={{
        open:
          formDetails.open !== undefined && formDetails.open === false
            ? false
            : true,
        description: formDetails.description
          ? formDetails.description
          : undefined,
        title: formDetails.title ? formDetails.title : undefined,
        amount: formDetails.amount ? formDetails.amount : undefined,
      }}
    >
      <RemoveAutocomplete />

      {!_projectId ? (
        <SearchLeadsAndProjects
          handleSelect={handleSelect}
          leads={false}
          disableLastViewed={true}
        />
      ) : (
        <FormBody
          className="content-inner"
          style={{
            paddingLeft: 24,
            paddingTop: 24,
            paddingRight: 24,
            paddingBottom: 8,
            maxHeight: type === "modal" ? "calc(100vh - 161px)" : "unset",
          }}
        >
          <Form.Item
            className={styles.radioGroup}
            name="open"
            rules={[{ required: true, message: "Must select open or closed" }]}
          >
            <Radio.Group optionType="button" buttonStyle="solid">
              <Radio.Button value={true}>Open</Radio.Button>
              <Radio.Button value={false}>Closed</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="title"
            label="Title"
            rules={[{ required: true, message: "Title is required" }]}
          >
            <InputNoAutoComplete id="title" />
          </Form.Item>

          <Form.Item
            name="description"
            label={<MarkUpHelper title="Description" />}
          >
            <Input.TextArea rows={4} />
          </Form.Item>

          <Form.Item name="amount" label="Amount">
            <Currency id="amount" />
          </Form.Item>
        </FormBody>
      )}

      {_projectId && (
        <FormControls className="sticky-footer">
          <CancelButton handleClick={cancel} />
          <Button
            type="text"
            onClick={submitAnother}
            loading={loadingSAC}
            disabled={loadingSAC || !unsavedChanges}
          >
            Submit & Create
          </Button>
          <Button
            type="primary"
            onClick={onFinish}
            loading={loading}
            disabled={loading || !unsavedChanges}
          >
            Submit
          </Button>
        </FormControls>
      )}
    </Form>
  );
};

export default Action;
