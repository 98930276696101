import React, { useCallback } from "react";
import List from "antd/es/list";
import { useLocation, useNavigate } from "react-router-dom";
import TableItem from "./TableItem";
import { useDispatch } from "react-redux";
import { openForm } from "Actions/formActions";

import styles from "./styles.module.scss";

const TableRow = ({
  style,
  mobile,
  _id,
  data,
  headers,
  rowType,
  showBreakdownInformation,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const openBreakdown = useCallback(() => {
    showBreakdownInformation(data._id);
  }, [showBreakdownInformation, data._id]);

  const openCollection = useCallback(() => {
    dispatch(
      openForm("collection", {
        _projectId: data._projectId,
        _id: data._id,
        customerId: data.customerId,
        amount: data.amount,
        transactionDate: data.transactionDate,
        identifier: data.identifier,
        collectionType: data.collectionType,
        customerName: data.customerName,
        projectManager: data.projectManager,
        billingAddressStreet: data.billingAddressStreet,
        billingAddressCity: data.billingAddressCity,
        billingAddressState: data.billingAddressState,
        billingAddressZip: data.billingAddressZip,
        projectAddressStreet: data.projectAddressStreet,
        projectAddressCity: data.projectAddressCity,
        projectAddressState: data.projectAddressState,
        projectAddressZip: data.projectAddressZip,
        projectAddress: data.projectAddress,
        src: data.src,
        scopes: data.scopes,
        editing: true,
      })
    );
  }, [
    dispatch,
    data._projectId,
    data._id,
    data.customerId,
    data.amount,
    data.transactionDate,
    data.identifier,
    data.collectionType,
    data.customerName,
    data.projectManager,
    data.billingAddressStreet,
    data.billingAddressCity,
    data.billingAddressState,
    data.billingAddressZip,
    data.projectAddressStreet,
    data.projectAddressCity,
    data.projectAddressState,
    data.projectAddressZip,
    data.projectAddress,
    data.scopes,
    data.src,
  ]);

  const onClick = useCallback(() => {
    if (rowType === "mixed") {
      if (
        data.projectStatus === "Sold" ||
        data.projectStatus === "Closed Out" ||
        data.projectStatus === "Cancelled" ||
        data.projectStatus === "On Hold"
      ) {
        navigate(
          `/reports/reports-dashboard-admin/projects/view/details/${_id}`
        );
      } else {
        navigate(`/reports/reports-dashboard-admin/leads/view/details/${_id}`);
      }
    } else if (rowType === "leads") {
      navigate(`/reports/reports-dashboard-admin/leads/view/details/${_id}`);
    } else if (rowType === "projects") {
      navigate(`/reports/reports-dashboard-admin/projects/view/details/${_id}`);
    } else if (rowType === "scopes") {
      navigate(
        `/reports/reports-dashboard-admin/projects/view/scopes/${data._projectId}`
      );
    } else if (rowType === "collections") {
      openCollection();
    } else if (rowType === "breakdowns") {
      openBreakdown();
    }
  }, [_id, navigate, rowType, data, openCollection, openBreakdown]);

  if (!mobile) {
    return (
      <List.Item
        style={style}
        className={`${styles.row} ${
          location.pathname.includes(_id) ? styles.selected : ""
        }`}
        onClick={rowType !== "draws" ? onClick : undefined}
      >
        {headers.map((header, i) => (
          <TableItem
            key={`${header._name}${i}`}
            width={header.width}
            textAlign={header.align}
            type={header.type}
            data={data[header._name]}
          />
        ))}
      </List.Item>
    );
  } else {
    return (
      <List.Item
        style={style}
        className={`${styles.mobileListItem} ${
          location.pathname.includes(data._id) ? styles.selected : ""
        }`}
        onClick={onClick}
      >
        {/* <List.Item.Meta className={styles.customerId} title={customerId} />
        <List.Item.Meta
          className={styles.status}
          description={
            <Tag className={styles.statusTag} color={statusObj[projectStatus]}>
              {projectStatus}
            </Tag>
          }
        />
        <List.Item.Meta
          className={styles.date}
          description={dayjs(date).format("MMM D, YY")}
        />
        <div className={styles.break} />
        <List.Item.Meta
          className={styles.fullLine}
          style={{ flex: "1 1 100%", paddingBottom: 3 }}
          description={name}
        />
        <div className={styles.break} />
        <List.Item.Meta className={styles.fullLine} description={address} />
        <div className={styles.break} /> */}

        {/* <List.Item.Meta className={styles.scopes} description={renderScope()} /> */}
        {/* <List.Item.Meta
              className={styles.actions}
              description={
                <>
                  <a
                    onClick={stopPropagation}
                    href={`tel:${removePhoneSymbols(phone)}`}
                  >
                    <Button shape="circle" type="text" className={styles.button}>
                      <Call />
                    </Button>
                  </a>
                  <Button
                    onClick={openSMSMobile}
                    shape="circle"
                    type="text"
                    className={styles.button}
                  >
                    <Textsms />
                  </Button>
                  <a
                    href={`https://www.google.com/maps/dir/?api=1&destination=${address}`}
                    target="_Blank"
                    rel="noopener noreferrer"
                    onClick={stopPropagation}
                  >
                    <Button shape="circle" type="text" className={styles.button}>
                      <Directions />
                    </Button>
                  </a>
                  <Button
                    onClick={openCreatePost}
                    shape="circle"
                    type="text"
                    className={styles.button}
                  >
                    <Post />
                  </Button>
                </>
              }
            /> */}
      </List.Item>
    );
  }
};

export default TableRow;
