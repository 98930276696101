import React from "react";
import List from "antd/es/list";
import Progress from "antd/es/progress";
import styles from "./styles.module.scss";
import ImageFallback from "Components/images/Image";

const UploadRow = ({ preview, name, size, upload, failed = false }) => {
  return (
    <List.Item className={styles.uploadRow}>
      <List.Item.Meta
        avatar={
          <div className={styles.preview}>
            <ImageFallback alt="comment-image" image={preview} />
          </div>
        }
        title={name}
        description={
          <>
            <span>{size}</span>
            <Progress
              className={styles.progress}
              percent={upload}
              status={
                failed ? "exception" : upload === 100 ? undefined : "active"
              }
            />
          </>
        }
      />
    </List.Item>
  );
};

export default UploadRow;
