import React, { useState, useEffect, useCallback } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchTable } from "Actions/tableActions";
import { useNavigate } from "react-router-dom";
import Form from "antd/es/form";
import message from "antd/es/message";
import Button from "antd/es/button";
import Steps from "antd/es/steps";
import Alert from "antd/es/alert";
import Row from "antd/es/row";
import Col from "antd/es/col";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import Details from "./Details";
import Mold from "./Mold";
import Scopes from "./Scopes";
import Add from "Icons/Add";
import styles from "./styles.module.scss";
import api from "API";
import currencyFormatter from "Utils/currencyFormatter";
import isEmpty from "Utils/isEmpty";
import DuplicateCheck from "Components/Inputs/DuplicateCheck";
import usePrevious from "Hooks/usePrevious";
import useSalesmen from "Hooks/useSalesmen";
import CancelButton from "Components/CancelButton";
import useProjectManagers from "Hooks/useProjectManagers";

const messageKey = "messageKey";

const CreateProject = ({
  unsavedChanges,
  setUnsavedChanges,
  handleClose,
  type,
}) => {
  const dispatch = useDispatch();
  const formDetails = useSelector(
    (state) => state.formState.createProject.formDetails
  );
  const table = useSelector((state) => state.tableState.table);
  const col = useSelector((state) => state.tableState.col);
  const order = useSelector((state) => state.tableState.order);
  const search = useSelector((state) => state.tableState.search);
  const activeFilter = useSelector((state) => state.tableState.activeFilter);
  const _user = useSelector((state) => state.authState._user);
  const salesman = useSelector((state) => state.authState.salesman);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [scopes, setScopes] = useState([]);
  const [premierWarranties, setPremierWarranties] = useState([]);
  const [scopeTotal, setScopeTotal] = useState(0);
  const [createNew, setCreateNew] = useState(
    formDetails.salesmen ? true : false
  );
  const [copyAddress, setCopyAddress] = useState("");
  const prevCreateNew = usePrevious(createNew);
  const sales = useSalesmen();
  const [salesmen, setSalesmen] = useState({});
  const [propertyType, setPropertyType] = useState(
    !formDetails.propertyType ? undefined : formDetails.propertyType
  );
  const [selectedOrigin, setSelectedOrigin] = useState(undefined);
  const projectManagers = useProjectManagers();

  useEffect(() => {
    if (createNew) {
      setTimeout(() => {
        const el = document.getElementById("steps");
        if (el) {
          el.classList.remove("ant-steps-vertical");
          el.classList.add("ant-steps-horizontal");
          el.classList.add("ant-steps-label-vertical");
        }
      });
    }
  }, [createNew]);

  useEffect(() => {
    const scrollRef = document.getElementById("create-project-scroll");
    if (scrollRef) scrollRef.scrollTop = 0;
  }, [step]);

  useEffect(() => {
    if (
      createNew &&
      !prevCreateNew &&
      !formDetails.salesmen &&
      salesman === "Yes"
    ) {
      let options = {};
      options[_user] = sales[_user];
      setSalesmen(options);

      form.setFieldsValue({
        projectAddressStreet: copyAddress,
        projectManager: _user,
      });
    } else if (
      createNew &&
      formDetails.salesmen &&
      !isEmpty(sales) &&
      salesman === "Yes"
    ) {
      let options = {};
      if (formDetails.salesmen) {
        for (const v of formDetails.salesmen) {
          options[v._user] = sales[v._user];
        }
        setSalesmen(options);

        form.setFieldsValue({
          projectManager: formDetails.projectManager._user,
        });
      }
    }
  }, [createNew, prevCreateNew, sales]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchOptions();
  }, []);

  const fetchOptions = async () => {
    try {
      const res = await api.post("/dropdowns/get", {
        selects: ["Scopes", "Premier Warranties"],
      });

      unstable_batchedUpdates(() => {
        setScopes(res.data.Scopes.options);
        setPremierWarranties(res.data["Premier Warranties"].options);
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const onFinish = useCallback(
    async (values) => {
      try {
        const contractPrice = values.contractPrice;
        let totalScopeValue = 0;
        for (const scope of values.scopes) {
          totalScopeValue += scope.amount;
        }

        if (
          contractPrice.toFixed(2).toString() !==
          totalScopeValue.toFixed(2).toString()
        ) {
          message.error({
            content: "Contract Price does not equal Total Scope Amount",
            duration: 1.5,
            key: messageKey,
          });
          return;
        }

        setLoading(true);
        // message.loading("Creating Project...", 0);
        message.loading({
          content: "Creating Project...",
          duration: 0,
          key: messageKey,
        });
        values.salesObj = [];
        for (const v of values.salesmen) {
          values.salesObj.push(sales[v]);
        }

        values.projectManager = sales[values.projectManager];

        for (const scope of values.scopes) {
          if (scope.installProjectManager) {
            scope.installProjectManager =
              projectManagers[scope.installProjectManager];
          }
        }

        const res = await api.post("/projects/create", values);

        if (res.status === 200) {
          setLoading(false);
          if (table === "projects") {
            dispatch(
              fetchTable({
                table,
                col,
                order,
                search,
                activeFilter,
                fetching: false,
              })
            );
          }
          // message.success("Project created");
          message.success({
            content: "Project created",
            duration: 1.5,
            key: messageKey,
          });
          navigate(`/projects/view/details/${res.data._id}`);
          handleClose(true);
        } else {
          setLoading(false);
          // message.error("Error creating project");
          message.error({
            content: "Error creating project",
            duration: 1.5,
            key: messageKey,
          });
        }
      } catch (err) {
        if (
          err &&
          err.errorFields &&
          err.errorFields[0] &&
          err.errorFields[0].name
        ) {
          form.scrollToField(err.errorFields[0].name[0]);
        }
        setLoading(false);
        // message.error("Error creating project");
        message.error({
          content: "Error creating project",
          duration: 1.5,
          key: messageKey,
        });
        console.log("err", err);
      }
    },
    [
      dispatch,
      table,
      col,
      order,
      search,
      activeFilter,
      navigate,
      handleClose,
      form,
      sales,
      projectManagers,
    ]
  );

  const onFinishFailed = (err) => {
    console.log("err", err);
    if (
      err &&
      err.errorFields &&
      err.errorFields[0] &&
      err.errorFields[0].name
    ) {
      form.scrollToField(err.errorFields[0].name[0]);
    }
    message.error("Error creating project");
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  const detailsNext = () => {
    form
      .validateFields([
        "contractDate",
        "propertyType",
        "customerName",
        "phone",
        "projectAddressStreet",
        "projectAddressCity",
        "projectAddressState",
        "projectAddressZip",
        "billingAddressStreet",
        "billingAddressCity",
        "billingAddressState",
        "billingAddressZip",
        "salesmen",
        "projectManager",
        "origin",
        "builderPermits",
        "permits",
        "projectDescription",
      ])
      .then((values) => {
        setStep((thisStep) => thisStep + 1);
      })
      .catch((err) => {
        console.log("err", err);
        if (
          err &&
          err.errorFields &&
          err.errorFields[0] &&
          err.errorFields[0].name
        ) {
          form.scrollToField(err.errorFields[0].name[0]);
        }
        message.error("All required fields must be completed");
      });
  };

  const moldNext = () => {
    form
      .validateFields([
        "contractPrice",
        "expectedMaterials",
        "expectedLabor",
        "expectedDumping",
        "expectedExtras",
        "expectedGutters",
        "expectedInsulation",
      ])
      .then((values) => {
        setStep((thisStep) => thisStep + 1);
      })
      .catch((err) => {
        message.error("All required fields must be completed");
        if (
          err &&
          err.errorFields &&
          err.errorFields[0] &&
          err.errorFields[0].name
        ) {
          form.scrollToField(err.errorFields[0].name[0]);
        }
      });
  };

  const prev = () => {
    setStep((thisStep) => thisStep - 1);
  };

  const onPropertyTypeChange = (value) => {
    setPropertyType(value);
  };

  const handleOriginChange = (value) => {
    setSelectedOrigin(value);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      className="form"
      style={{
        maxHeight: type === "modal" ? "calc((100vh - 100px) - 55px)" : "unset",
      }}
      initialValues={{
        propertyType: !formDetails.propertyType
          ? undefined
          : formDetails.propertyType,
        // expectedOverhead: !formDetails.propertyType ? 0
        customerName: !formDetails.customerName
          ? undefined
          : formDetails.customerName,
        phone: !formDetails.phone ? undefined : formDetails.phone,
        email: !formDetails.email ? undefined : formDetails.email,
        alternatePhone: !formDetails.alternatePhone
          ? undefined
          : formDetails.alternatePhone,
        alternateEmail: !formDetails.alternateEmail
          ? undefined
          : formDetails.alternateEmail,
        projectAddressStreet: !formDetails.projectAddressStreet
          ? undefined
          : formDetails.projectAddressStreet,
        projectAddressCity: !formDetails.projectAddressCity
          ? undefined
          : formDetails.projectAddressCity,
        projectAddressState: !formDetails.projectAddressState
          ? undefined
          : formDetails.projectAddressState,
        projectAddressZip: !formDetails.projectAddressZip
          ? undefined
          : formDetails.projectAddressZip,
        projectAddressPermitAuthority:
          !formDetails.projectAddressPermitAuthority
            ? undefined
            : formDetails.projectAddressPermitAuthority,
        projectAddressCounty: !formDetails.projectAddressCounty
          ? undefined
          : formDetails.projectAddressCounty,
        projectAddressLat: !formDetails.projectAddressLat
          ? undefined
          : formDetails.projectAddressLat,
        projectAddressLng: !formDetails.projectAddressLng
          ? undefined
          : formDetails.projectAddressLng,
        billingAddressStreet: !formDetails.billingAddressStreet
          ? undefined
          : formDetails.billingAddressStreet,
        billingAddressCity: !formDetails.billingAddressCity
          ? undefined
          : formDetails.billingAddressCity,
        billingAddressState: !formDetails.billingAddressState
          ? undefined
          : formDetails.billingAddressState,
        billingAddressZip: !formDetails.billingAddressZip
          ? undefined
          : `${formDetails.billingAddressZip}`,
        salesmen:
          !formDetails.salesmen && salesman === "Yes"
            ? [_user]
            : formDetails.salesmen
            ? formDetails.salesmen.map((s) => s._user)
            : salesman === "Yes"
            ? [_user]
            : undefined,
        projectManager:
          !formDetails.projectManager && salesman === "Yes"
            ? _user
            : formDetails.projectManager
            ? formDetails.projectManager._user
            : salesman === "Yes"
            ? _user
            : undefined,
        mccEligible: true,
        scopes: [
          {
            name: undefined,
          },
        ],
      }}
    >
      <RemoveAutocomplete />

      {!createNew ? (
        <DuplicateCheck
          type="Project"
          setCreateNew={setCreateNew}
          handleClose={handleClose}
          setCopyAddress={setCopyAddress}
        />
      ) : (
        <FormBody
          id="create-project-scroll"
          className="content-inner"
          style={{
            paddingLeft: 24,
            paddingTop: 24,
            paddingRight: 24,
            paddingBottom: 8,
            maxHeight: "calc(100% - 55px)",
          }}
        >
          <Steps
            id="steps"
            className={styles.soldSteps}
            labelPlacement="vertical"
            size="small"
            current={step}
            style={{ marginBottom: 24 }}
          >
            <Steps.Step title="Details" />
            <Steps.Step title="Mold" />
            <Steps.Step title="Scopes" />
          </Steps>

          <div className={step !== 0 ? "hide" : ""}>
            <Details
              _sales={sales}
              sales={salesmen}
              setSales={setSalesmen}
              onPropertyTypeChange={onPropertyTypeChange}
              propertyType={propertyType}
              form={form}
              step={step}
              selectedOrigin={selectedOrigin}
              handleOriginChange={handleOriginChange}
              projectAddressLat={
                !formDetails.projectAddressLat
                  ? undefined
                  : formDetails.projectAddressLat
              }
              projectAddressLng={
                !formDetails.projectAddressLng
                  ? undefined
                  : formDetails.projectAddressLng
              }
            />
          </div>

          <div className={step !== 1 ? "hide" : ""}>
            <Mold form={form} propertyType={propertyType} />
          </div>

          <div className={step !== 2 ? "hide" : ""}>
            <Form.List name="scopes">
              {(fields, { add, remove }) => {
                const handleAdd = () => {
                  add();
                };

                return (
                  <>
                    {fields.map((field) => (
                      <Scopes
                        key={`${field.key}-create-project-scope`}
                        form={form}
                        field={field}
                        fields={fields}
                        scopes={scopes}
                        remove={remove}
                        premierWarranties={premierWarranties}
                        scopeTotal={scopeTotal}
                        setScopeTotal={setScopeTotal}
                        projectManagers={projectManagers}
                      />
                    ))}

                    <Form.Item style={{ marginRight: 0 }}>
                      <Button
                        className="green"
                        style={{ marginTop: 12 }}
                        type="primary"
                        onClick={handleAdd}
                        block
                      >
                        <Add size={18} /> Add Scope
                      </Button>
                    </Form.Item>
                  </>
                );
              }}
            </Form.List>

            <Alert
              style={{ marginTop: 24 }}
              type="info"
              message={
                <Row gutter={16}>
                  <Col xs={24} sm={12}>
                    <b>Scope Total:</b>
                    <br />
                    {currencyFormatter(scopeTotal)}
                  </Col>
                  <Col xs={24} sm={12}>
                    <b>Contract Price:</b>
                    <br />
                    {isNaN(form.getFieldValue("contractPrice"))
                      ? "$0.00"
                      : currencyFormatter(form.getFieldValue("contractPrice"))}
                  </Col>
                </Row>
              }
            />
          </div>
        </FormBody>
      )}

      {createNew && (
        <FormControls className="sticky-footer">
          <CancelButton handleClick={handleClose} />

          {step === 0 && (
            <Form.Item style={{ margin: 0 }}>
              <Button type="primary" onClick={detailsNext}>
                Next
              </Button>
            </Form.Item>
          )}

          {step === 1 && (
            <>
              <Form.Item
                style={{
                  marginRight: 8,
                  marginBottom: 0,
                  display: "inline-block",
                }}
              >
                <Button onClick={prev}>Previous</Button>
              </Form.Item>
              <Form.Item style={{ margin: 0, display: "inline-block" }}>
                <Button type="primary" onClick={moldNext}>
                  Next
                </Button>
              </Form.Item>
            </>
          )}

          {step === 2 && (
            <>
              <Form.Item
                style={{
                  marginRight: 8,
                  marginBottom: 0,
                  display: "inline-block",
                }}
              >
                <Button onClick={prev}>Previous</Button>
              </Form.Item>
              <Form.Item style={{ margin: 0, display: "inline-block" }}>
                <Button
                  htmlType="submit"
                  type="primary"
                  loading={loading}
                  disabled={loading || !unsavedChanges}
                >
                  Submit
                </Button>
              </Form.Item>
            </>
          )}
        </FormControls>
      )}
    </Form>
  );
};

export default CreateProject;
