const materials = [
  {
    material: '3" Nails',
    type: "5lb Box",
    color: "N/A",
  },
  {
    material: '3" Nails',
    type: "",
    color: "N/A",
  },
  {
    material: "B & B",
    type: "",
    color: "",
  },
  {
    material: "Base Sheet",
    type: "Flintlastic SA",
    color: "N/A",
  },
  {
    material: "Bathroom Fan Vent",
    type: '4" | With Hose',
    color: "",
  },
  {
    material: "Bathroom Fan Vent",
    type: '4" | Without Hose',
    color: "",
  },
  {
    material: "Bathroom Fan Vent",
    type: '8" | With Hose',
    color: "",
  },
  {
    material: "Bathroom Fan Vent",
    type: '8" | Without Hose',
    color: "",
  },
  {
    material: "Bonding Adhesive",
    type: "",
    color: "",
  },
  {
    material: "Cap Sheet",
    type: "Flintlastic SA",
    color: "",
  },
  {
    material: "Caulk",
    type: "Solar Seal",
    color: "",
  },
  {
    material: "Caulk",
    type: "",
    color: "",
  },
  {
    material: "Caulk",
    type: "Flintbond",
    color: "N/A",
  },
  {
    material: "Coil Nails",
    type: '1 1/4"',
    color: "N/A",
  },
  {
    material: "Coil Nails",
    type: '1 1/2"',
    color: "N/A",
  },
  {
    material: "Cured Cover Tap",
    type: "",
    color: "",
  },
  {
    material: "D4 Siding",
    type: "",
    color: "",
  },
  {
    material: "Drip Edge",
    type: "T / ",
    color: "",
  },
  {
    material: "Drip Edge",
    type: "C / ",
    color: "",
  },
  {
    material: "Drip Edge",
    type: "",
    color: "",
  },
  {
    material: "Dryer Vent",
    type: "",
    color: "",
  },
  {
    material: "EPDM",
    type: ".060",
    color: "",
  },
  {
    material: "EZ Plugs",
    type: "Standard",
    color: "N/A",
  },
  {
    material: "Exhaust Vent",
    type: '4"',
    color: "",
  },
  {
    material: "Exhaust Vent",
    type: '6"',
    color: "",
  },
  {
    material: "Expandable Foam",
    type: "",
    color: "",
  },
  {
    material: "F-Channel",
    type: "",
    color: "",
  },
  {
    material: "Fascia",
    type: "",
    color: "",
  },
  {
    material: "Fiber Board",
    type: "",
    color: "N/A",
  },
  {
    material: "Finish Trim",
    type: "",
    color: "",
  },
  {
    material: "Flashing Tape",
    type: "Uncured",
    color: "",
  },
  {
    material: "Framing Nails",
    type: '2 3/8"',
    color: "N/A",
  },
  {
    material: "HDFB",
    type: "Size: ",
    color: "",
  },
  {
    material: "House Wrap",
    type: "",
    color: "N/A",
  },
  {
    material: "ISCP",
    type: "",
    color: "",
  },
  {
    material: "ISO Board",
    type: "",
    color: "N/A",
  },
  {
    material: "Ice & Water",
    type: "",
    color: "N/A",
  },
  {
    material: "Ice & Water",
    type: "Winterguard",
    color: "N/A",
  },
  {
    material: "In-Seam Tape",
    type: "",
    color: "",
  },
  {
    material: "Intake Vent",
    type: "",
    color: "",
  },
  {
    material: "Interior Trim",
    type: "",
    color: "",
  },
  {
    material: "J-Block",
    type: "",
    color: "",
  },
  {
    material: "J-Channel",
    type: "",
    color: "",
  },
  {
    material: "Lap Caulk",
    type: "",
    color: "",
  },
  {
    material: "Loose Granuales",
    type: "N/A",
    color: "",
  },
  {
    material: "Mid Ply",
    type: "",
    color: "",
  },
  {
    material: "Nails",
    type: "",
    color: "N/A",
  },
  {
    material: "OSCP",
    type: "",
    color: "",
  },
  {
    material: "Pipe Boot",
    type: "1-3",
    color: "",
  },
  {
    material: "Pipe Boot",
    type: "3-4",
    color: "",
  },
  {
    material: "Plates",
    type: "",
    color: "",
  },
  {
    material: "Poly ISO",
    type: "HD Cover Board / Size: ",
    color: "",
  },
  {
    material: "Poly ISO",
    type: "Insulation Board / Size: ",
    color: "",
  },
  {
    material: "R.M.S. Strip",
    type: "",
    color: "",
  },
  {
    material: "Ridge Cap",
    type: "",
    color: "Match",
  },
  {
    material: "Ridge Cap",
    type: "Shadow Ridge",
    color: "Match",
  },
  {
    material: "Ridge Cap",
    type: "Pro Cut",
    color: "Match",
  },
  {
    material: "Roll Insulation",
    type: "",
    color: "",
  },
  {
    material: "Screw Plates",
    type: "N/A",
    color: "N/A",
  },
  {
    material: "Screws",
    type: "",
    color: "N/A",
  },
  {
    material: "Shakes",
    type: "",
    color: "",
  },
  {
    material: "Shingles",
    type: "",
    color: "",
  },
  {
    material: "Siding Nails",
    type: "",
    color: "N/A",
  },
  {
    material: "Smart Trim",
    type: "",
    color: "",
  },
  {
    material: "Soffit Solid",
    type: "",
    color: "",
  },
  {
    material: "Soffit Vented",
    type: "",
    color: "",
  },
  {
    material: "Split Block",
    type: "",
    color: "",
  },
  {
    material: "Spray Primer",
    type: "Mulehide Seal Fast",
    color: "",
  },
  {
    material: "Spray Primer",
    type: "N/A",
    color: "N/A",
  },
  {
    material: "Staples",
    type: "T-50",
    color: "N/A",
  },
  {
    material: "Starter Strip",
    type: "Aluminum",
    color: "",
  },
  {
    material: "Starter Strip",
    type: "",
    color: "N/A",
  },
  {
    material: "Starter Strip",
    type: "Swift Start",
    color: "N/A",
  },
  {
    material: "Starter Strip",
    type: "Pro Cut",
    color: "N/A",
  },
  {
    material: "Step Flashing",
    type: "Prebent 8 x 8",
    color: "",
  },
  {
    material: "Tape Primer",
    type: "",
    color: "",
  },
  {
    material: "Termination Bar",
    type: "",
    color: "",
  },
  {
    material: "Trim Coil",
    type: "Standard",
    color: "",
  },
  {
    material: "Trim Coil",
    type: "",
    color: "",
  },
  {
    material: "Trim Nails",
    type: "",
    color: "",
  },
  {
    material: "Underlayment",
    type: "",
    color: "N/A",
  },
  {
    material: "Underlayment",
    type: "Roof Runner",
    color: "N/A",
  },
  {
    material: "Underlayment",
    type: "Summit 60",
    color: "N/A",
  },
  {
    material: "Ventilation",
    type: "",
    color: "",
  },
  {
    material: "Ventilation",
    type: "CertainTeed Ridge Vent",
    color: "N/A",
  },
  {
    material: "Ventilation",
    type: "Atlas",
    color: "N/A",
  },
  {
    material: "Water Cut Off",
    type: "",
    color: "",
  },
  {
    material: "Window Tape",
    type: "",
    color: "",
  },
  {
    material: "Wood",
    type: '7/16" OSB',
    color: "N/A",
  },
  {
    material: "Wood",
    type: "",
    color: "N/A",
  },
  {
    material: "Wrap Tape",
    type: "",
    color: "",
  },
  {
    material: "Zipper Boot",
    type: "Standard",
    color: "Black",
  },
];

export default materials;
