import React, { useState, useEffect } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useParams } from "react-router-dom";
import Typography from "antd/es/typography";
import message from "antd/es/message";
import Button from "antd/es/button";
import Card from "antd/es/card";
import Input from "antd/es/input";
import Form from "antd/es/form";
import Upload from "antd/es/upload";
import InboxOutlined from "@ant-design/icons/InboxOutlined";
import logo from "Assets/logo.png";
import api from "API";
import styles from "./styles.module.scss";
import CheckCircle from "Icons/CheckCircle";
import Error from "Icons/Error";
import Email from "Icons/Email";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";

import axios from "axios";
import generateFilePreview from "Utils/generateFilePreview";
import generateFileSize from "Utils/generateFileSize";
import List from "antd/es/list";
import UploadRow from "Components/images/UploadRow";
import isPdf from "Utils/isPdf";
import pdf from "Assets/pdf.svg";
import * as serviceWorker from "../../serviceWorkerRegistration";

const SubInvoiceSubmit = () => {
  const params = useParams();
  const [submitted, setSubmitted] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [_fileList, _setFileList] = useState([]);

  const [uploads, setUploads] = useState({});
  const [uploading, setUploading] = useState(false);

  let {
    _projectId,
    _activityId,
    customerId,
    customerName,
    projectAddress,
    token,
    _scopeId,
  } = params;

  useEffect(() => {
    serviceWorker.unregister();
  }, []);

  useEffect(() => {
    if (uploading) {
      let _uploading = false;
      Object.keys(uploads).map((key) => {
        if (uploads[key].upload !== 100 && uploads[key].upload !== true) {
          _uploading = true;
          return key;
        }
        return key;
      });
      if (!_uploading) {
        setUploading(false);
      }
    }
  }, [uploads]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleBeforeUpload = async (file) => {
    try {
      setUploading(true);
      const size = await generateFileSize(file.size);
      let preview;
      if (isPdf(file.name)) {
        preview = pdf;
      } else {
        preview = await generateFilePreview(file);
      }
      setUploads((prv) => {
        return {
          ...prv,
          [file.uid]: {
            preview,
            name: file.name,
            size,
            failed: false,
            upload: 0,
          },
        };
      });
      return file;
    } catch (err) {
      console.log("beforeUpload err", err);
    }
  };

  const handleUpload = async ({ file, onProgress }) => {
    try {
      let fileName = `${new Date().getTime()}-${file.name.replace(
        /[^a-zA-Z0-9.]/gi,
        "_"
      )}`;
      const src = `https://s3.us-east-2.amazonaws.com/pr-crm/${_projectId}/activities/${fileName}`;
      let thumbnail;
      const ext = src.split(".").pop().toLowerCase();
      if (ext !== "pdf")
        thumbnail = `https://s3.us-east-2.amazonaws.com/pr-crm-thumbnail/${_projectId}/activities/${fileName}`;

      _setFileList((prevState) => [
        ...prevState,
        {
          originFileObj: file,
          _id: file.uid,
          uid: file.uid,
          name: file.name,
          mediaType: isPdf(src) ? "pdf" : "image",
          _projectId,
          size: file.size,
          type: file.type,
          src,
          thumbnail,
        },
      ]);

      const res = await api.post("/sub-upload", {
        type: file.type,
        name: fileName,
        _projectId,
        token,
      });
      await axios
        .put(res.data, file, {
          headers: {
            "Content-Type": file.type,
          },
          onUploadProgress: (e) => {
            onProgress(e);
          },
        })
        .catch(function (err) {
          console.log("upload err", err);

          setUploads((prv) => {
            return {
              ...prv,
              [file.uid]: {
                ...prv[file.uid],
                failed: true,
              },
            };
          });

          _setFileList((prevState) => {
            const _filteredFileList = prevState.filtered(
              (prv) => prv.uid !== file.uid
            );
            return _filteredFileList;
          });
        });
    } catch (err) {
      console.log("handleUpload err", err);
    }
  };

  const handleProgress = (progress, file) => {
    const _progress = parseInt((progress.loaded * 100) / progress.total, 10);
    setUploads((prv) => {
      return {
        ...prv,
        [file.uid]: {
          ...prv[file.uid],
          upload: _progress,
        },
      };
    });
  };

  const onFinish = async (values) => {
    try {
      if (customerId !== values.customerId) {
        message.error(
          "The supplied PO# does not match our records for this project"
        );
        return false;
      }
      setSubmitting(true);
      await api.post("/sub-submit-invoice", {
        _projectId,
        _activityId,
        customerId,
        customerName,
        projectAddress,
        email: values.email,
        token,
        _scopeId,
        images: _fileList,
      });
      unstable_batchedUpdates(() => {
        setAccepted(true);
        setSubmitted(true);
        setSubmitting(false);
      });
    } catch (err) {
      unstable_batchedUpdates(() => {
        setSubmitted(true);
        setSubmitting(false);
      });

      message.error("Error submitting invoice");
    }
  };

  const onFinishFailed = (err) => {
    message.error("Error submitting invoice");
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <div className={`content-inner ${styles.container}`}>
      <img src={logo} alt="premier-logo" />
      <Card
        bordered={false}
        className={`${styles.card} ${
          !submitted
            ? styles.waiting
            : accepted
            ? styles.accepted
            : styles.declined
        }`}
      >
        <img src={logo} alt="premier-logo" />
        <div className={styles.banner}>
          {!submitted && <Typography.Title>Submit Invoice</Typography.Title>}
          {submitted && accepted && (
            <Typography.Title style={{ color: "#f1f1f1" }}>
              Invoice Submitted! <CheckCircle className={styles.success} />
            </Typography.Title>
          )}
          {submitted && !accepted && (
            <Typography.Title style={{ color: "#f1f1f1" }}>
              Failed to Submit Invoice <Error className={styles.success} />
            </Typography.Title>
          )}
        </div>

        {!accepted && (
          <div className="ant-result-subtitle">
            <div className={styles.subTitle}>
              {submitted && !accepted && (
                <div
                  style={{
                    marginBottom: 24,
                    color: "#f1f1f1",
                    textDecoration: "underline",
                  }}
                >
                  An error occured when attempting to submit your request.
                  Please confirm all fields are completed and try again.
                </div>
              )}
              <div
                className={styles.text}
                style={{
                  marginBottom: 24,
                }}
              >
                To submit your invoice please include the PO#, your email,
                upload your invoice then click "Submit Invoice".
              </div>
              <div className={styles.text}>
                <b>Customer Name</b>: {customerName}
              </div>
              <div
                className={styles.text}
                style={{
                  marginBottom: 24,
                }}
              >
                <b>Project Address</b>: {projectAddress}
              </div>

              <Form
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  className={styles.input}
                  name="customerId"
                  label="PO#"
                  rules={[{ required: true, message: "PO# is required" }]}
                >
                  <InputNoAutoComplete id="customerId" size="large" />
                </Form.Item>

                <Form.Item
                  className={styles.input}
                  name="email"
                  label="Email"
                  rules={[
                    { required: true, message: "Email is required" },
                    { type: "email", message: "Must be a valid email" },
                  ]}
                >
                  <Input prefix={<Email />} size="large" />
                </Form.Item>

                <Form.Item
                  name="invoices"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                >
                  <Upload.Dragger
                    multiple
                    beforeUpload={handleBeforeUpload}
                    customRequest={handleUpload}
                    showUploadList={false}
                    onProgress={handleProgress}
                    accept="image/*, application/pdf"
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                    <p className="ant-upload-hint">
                      Support for a single or bulk upload.
                    </p>
                  </Upload.Dragger>
                </Form.Item>

                {_fileList.length > 0 && (
                  <List
                    className={styles.uploadRow}
                    bordered
                    style={{ marginBottom: 24 }}
                  >
                    {Object.keys(uploads).map((key) => {
                      return (
                        <UploadRow
                          key={key}
                          preview={uploads[key].preview}
                          name={uploads[key].name}
                          size={uploads[key].size}
                          upload={uploads[key].upload}
                          failed={uploads[key].failed}
                        />
                      );
                    })}
                  </List>
                )}

                <Form.Item>
                  <Button
                    block
                    type="primary"
                    className="green"
                    htmlType="submit"
                    loading={submitting}
                    disabled={submitting && uploading}
                  >
                    Submit Invoice
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        )}

        {submitted && accepted && (
          <div className="ant-result-subtitle">
            <div className={styles.subTitle}>
              <div style={{ color: "#f1f1f1" }}>
                Invoice successfully submitted!
              </div>
            </div>
          </div>
        )}
      </Card>
    </div>
  );
};

export default SubInvoiceSubmit;
