import React, { useEffect, useState, useCallback } from "react";
import Grid from "antd/es/grid";
import {
  fetchTableFilters,
  unsetTable,
  setScrollbar,
} from "Actions/tableActions";
import { useDispatch, useSelector } from "react-redux";
import { openForm } from "Actions/formActions";

import Table from "Components/Table";
import TableRow from "Components/Table/TableItems/DumpsterItem";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as Vlist } from "react-window";
import usePrevious from "Hooks/usePrevious";
import useIsMobile from "Hooks/useIsMobile";
import isEmpty from "Utils/isEmpty";
import FormContainer from "Forms/FormContainer";
import DumpsterForm from "Forms/Dumpster";

const { useBreakpoint } = Grid;

const headers = [
  {
    _name: "companyName",
    name: "COMPANY",
    width: "30%",
  },
  {
    _name: "status",
    name: "STATUS",
    width: "15%",
  },
  {
    _name: "email",
    name: "EMAIL",
    width: "20%",
  },
  {
    _name: "containers",
    name: "CONTAINERS",
    width: "35%",
    align: "right",
    type: "array",
  },
];

const filters = [
  {
    title: "Status",
    type: "multi-select",
    fields: [
      {
        name: "status",
        placeholder: "Select an Option(s)",
        values: ["Active", "Inactive", "Suspended"],
      },
    ],
  },
];

const Dumpsters = () => {
  const screens = useBreakpoint();
  const mobile = useIsMobile();
  const prevScreens = usePrevious(screens);
  const rows = useSelector((state) => state.tableState.rows);
  const fetching = useSelector((state) => state.tableState.fetching);
  const search = useSelector((state) => state.tableState.search);
  const activeFilter = useSelector((state) => state.tableState.activeFilter);
  const collapsed = useSelector((state) => state.settingsState.collapsed);
  const dispatch = useDispatch();
  const dumpster = useSelector((state) => state.formState.dumpster.open);

  const [itemSize, setItemSize] = useState(135);

  useEffect(() => {
    initTable();

    return () => dispatch(unsetTable());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTimeout(() => {
      dispatch(setScrollbar());
    });
  }, [rows, dispatch]);

  const initTable = useCallback(() => {
    dispatch(
      fetchTableFilters({
        table: "dumpsters",
        col: "companyName",
        order: 1,
        search,
      })
    );
  }, [dispatch, search]);

  useEffect(() => {
    if (prevScreens && screens) {
      if (prevScreens.md && !screens.md) {
        setItemSize(135);
      } else if (!prevScreens.md && screens.md) {
        setItemSize(47);
      }
    }
  }, [screens, prevScreens]);

  const Row = ({ index, style }) => {
    const item = rows[index];
    if (!mobile)
      return (
        <TableRow style={style} key={item._id} item={item} headers={headers} />
      );
    return <TableRow style={style} key={item._id} item={item} mobile />;
  };

  const addFunc = useCallback(() => {
    dispatch(openForm("dumpster"));
  }, [dispatch]);

  return (
    <>
      <Table
        loading={fetching}
        filter={
          search ||
          (activeFilter && activeFilter.filter && !isEmpty(activeFilter.filter))
            ? true
            : false
        }
        mobile={mobile}
        sider={screens.lg}
        collapsed={collapsed}
      >
        <Table.Header filters={filters} addFunc={addFunc} />
        <Table.Filter
          hide={
            search ||
            (activeFilter &&
              activeFilter.filter &&
              !isEmpty(activeFilter.filter))
              ? false
              : true
          }
        />

        {!mobile && <Table.ColumnHeaders headers={headers} />}
        <Table.Body loading={fetching}>
          {rows.length > 0 && (
            <AutoSizer>
              {({ height, width }) => (
                <Vlist
                  height={height}
                  itemCount={rows.length}
                  itemSize={itemSize}
                  width={width}
                >
                  {Row}
                </Vlist>
              )}
            </AutoSizer>
          )}
        </Table.Body>
      </Table>

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0 },
        }}
        type={!screens.md ? "drawer" : "modal"}
        width={!screens.md ? "100%" : 320}
        visible={dumpster}
        form={DumpsterForm}
        formType="dumpster"
      />
    </>
  );
};

export default Dumpsters;
