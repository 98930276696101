import React from "react";
import LineChartWithInterval from "Reports/LineChartWithInterval";

export default function SalesCount() {
  return (
    <LineChartWithInterval
      path="/reports/adminSalesCount"
      type=""
      summary={true}
      lineItems={true}
      aggregate={true}
      report="SALES COUNT"
      headers={["Salesman", "Total"]}
    />
  );
}
