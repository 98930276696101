import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateActivitiesAllMediaAndHashtags } from "Actions/dataActions";
import Form from "antd/es/form";
import Upload from "antd/es/upload";
import Button from "antd/es/button";
import message from "antd/es/message";
import Tag from "antd/es/tag";
import Select from "antd/es/select";
import Mentions from "antd/es/mentions";
import FileOutlined from "@ant-design/icons/FileOutlined";
import api from "API";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import styles from "./styles.module.scss";
import SearchLeadsAndProjects from "Components/Inputs/SearchLeadsAndProjects";
import hashtags from "Constants/hashtags";
import CancelButton from "Components/CancelButton";
import MarkUpHelper from "Components/MarkUpHelper";
import Typography from "antd/es/typography";

import axios from "axios";
import generateFilePreview from "Utils/generateFilePreview";
import generateFileSize from "Utils/generateFileSize";
import List from "antd/es/list";
import UploadRow from "Components/images/UploadRow";
import isPdf from "Utils/isPdf";
import pdf from "Assets/pdf.svg";

const messageKey = "messageKey";

const CreateActivity = ({
  unsavedChanges,
  setUnsavedChanges,
  handleClose,
  type,
}) => {
  const dispatch = useDispatch();
  const formDetails = useSelector(
    (state) => state.formState.activity.formDetails
  );
  const mentions = useSelector((state) => state.authState.mentions);
  const details = useSelector((state) => state.dataState.details);
  const viewableActivities = useSelector(
    (state) => state.dataState.viewableActivities
  );
  const [loading, setLoading] = useState(false);
  const [_mentions] = useState({ "@": mentions, "#": hashtags });
  const [tags, setTags] = useState([]);
  const [_fileList, _setFileList] = useState([]);
  const [thisId, setThisId] = useState(
    formDetails && formDetails._id
      ? formDetails._id
      : details && details._id
      ? details._id
      : undefined
  );
  const [thisCustomerId, setThisCustomerId] = useState(
    formDetails && formDetails.customerId
      ? formDetails.customerId
      : details && details.customerId
      ? details.customerId
      : undefined
  );
  const [thisCustomerName, setThisCustomerName] = useState(
    formDetails && formDetails.customerName
      ? formDetails.customerName
      : details && details.customerName
      ? details.customerName
      : undefined
  );
  const [thisProjectManager, setThisProjectManager] = useState(
    formDetails && formDetails.projectManager
      ? formDetails.projectManager
      : details && details.projectManager
      ? details.projectManager
      : undefined
  );
  const [thisType, setThisType] = useState(
    isLead(
      formDetails && formDetails.projectStatus
        ? formDetails.projectStatus
        : details && details.projectStatus
        ? details.projectStatus
        : undefined
    )
      ? "leads"
      : "projects"
  );
  const [prefix, setPrefix] = useState("@");

  const [uploads, setUploads] = useState({});
  const [uploading, setUploading] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    if (uploading) {
      let _uploading = false;
      Object.keys(uploads).map((key) => {
        if (uploads[key].upload !== 100 && uploads[key].upload !== true) {
          _uploading = true;
          return key;
        }
        return key;
      });
      if (!_uploading) {
        setUploading(false);
      }
    }
  }, [uploads]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleBeforeUpload = async (file) => {
    try {
      setUploading(true);
      const size = await generateFileSize(file.size);
      let preview;
      if (isPdf(file.name)) {
        preview = pdf;
      } else {
        preview = await generateFilePreview(file);
      }
      setUploads((prv) => {
        return {
          ...prv,
          [file.uid]: {
            preview,
            name: file.name,
            size,
            failed: false,
            upload: 0,
          },
        };
      });
      return file;
    } catch (err) {
      console.log("beforeUpload err", err);
    }
  };

  const handleUpload = async ({ file, onProgress }) => {
    try {
      let fileName = `${new Date().getTime()}-${file.name.replace(
        /[^a-zA-Z0-9.]/gi,
        "_"
      )}`;
      const src = `https://s3.us-east-2.amazonaws.com/pr-crm/${thisId}/activities/${fileName}`;
      let thumbnail;
      const ext = src.split(".").pop().toLowerCase();
      if (ext !== "pdf")
        thumbnail = `https://s3.us-east-2.amazonaws.com/pr-crm-thumbnail/${thisId}/activities/${fileName}`;

      _setFileList((prevState) => [
        ...prevState,
        {
          originFileObj: file,
          _id: file.uid,
          uid: file.uid,
          name: file.name,
          mediaType: isPdf(src) ? "pdf" : "image",
          _projectId: thisId,
          size: file.size,
          type: file.type,
          src,
          thumbnail,
        },
      ]);

      const res = await api.post("/activity/image-upload", {
        type: file.type,
        name: fileName,
        _projectId: thisId,
      });
      await axios
        .put(res.data, file, {
          headers: {
            "Content-Type": file.type,
          },
          onUploadProgress: (e) => {
            onProgress(e);
          },
        })
        .catch(function (err) {
          console.log("upload err", err);

          setUploads((prv) => {
            return {
              ...prv,
              [file.uid]: {
                ...prv[file.uid],
                failed: true,
              },
            };
          });

          _setFileList((prevState) => {
            const _filteredFileList = prevState.filtered(
              (prv) => prv.uid !== file.uid
            );
            return _filteredFileList;
          });
        });
    } catch (err) {
      console.log("handleUpload err", err);
    }
  };

  const handleProgress = (progress, file) => {
    const _progress = parseInt((progress.loaded * 100) / progress.total, 10);
    setUploads((prv) => {
      return {
        ...prv,
        [file.uid]: {
          ...prv[file.uid],
          upload: _progress,
        },
      };
    });
  };

  const onFinish = useCallback(
    async (values) => {
      try {
        setLoading(true);
        if (values.content.length === 0 && _fileList.length === 0) {
          setLoading(false);
          // message.error("You must provide a message or upload at least 1 file");
          message.error({
            content: "You must provide a message or upload at least 1 file",
            duration: 1.5,
            key: messageKey,
          });
        } else {
          message.loading({
            content: "Creating post...",
            duration: 0,
            key: messageKey,
          });
          const selectedMentions = Mentions.getMentions(values.content, {
            prefix: ["@", "#"],
          });
          let _selectedMentions = [];
          values.hashtags = [];
          for (const s of selectedMentions) {
            if (s.prefix === "#") {
              values.hashtags.push(s.value);
            } else {
              let thisMention = _mentions["@"].find(
                (_m) => _m.name.replace(" ", "") === s.value
              );
              if (thisMention) _selectedMentions.push(thisMention);
            }
          }
          const res = await api.post("/activity/create", {
            _projectId: thisId,
            content: values.content,
            images: _fileList,
            followers: tags,
            hashtags: values.hashtags,
            mentions: _selectedMentions,
            customerId: thisCustomerId,
            type: thisType,
            customerName: thisCustomerName,
          });
          dispatch(
            updateActivitiesAllMediaAndHashtags({
              _projectId: thisId,
              data: res.data,
              dashboard: details._id ? false : true,
              viewableActivities,
            })
          );
          // message.success("Post created");
          message.success({
            content: "Post created",
            duration: 1.5,
            key: messageKey,
          });
          setLoading(false);
          handleClose(true);
        }
      } catch (err) {
        setLoading(false);
        // message.error("Error creating activity");
        message.error({
          content: "Error creating post",
          duration: 1.5,
          key: messageKey,
        });
        console.log("err", err);
      }
    },
    [
      _fileList,
      _mentions,
      details._id,
      dispatch,
      handleClose,
      tags,
      thisCustomerId,
      thisCustomerName,
      thisId,
      thisType,
      viewableActivities,
    ]
  );

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error creating post");
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  function isLead(status) {
    if (status === "Lead" || status === "Lead Lost" || status === "Estimate") {
      return true;
    }
    return false;
  }

  const handleSelect = (selected) => {
    setThisId(selected._id);
    setThisCustomerId(selected.customerId);
    setThisType(isLead(selected.projectStatus) ? "leads" : "projects");
    setThisCustomerName(selected.customerName);
    setThisProjectManager(selected.projectManager);
  };

  const onMentionSearch = (_, prefix) => {
    setPrefix(prefix);
  };

  const cancel = () => {
    handleClose();
  };

  const renderMentions = _mentions[prefix].map((_m) => {
    if (prefix === "@") {
      return (
        <Select.Option
          key={_m._user}
          value={_m.name.replace(" ", "")}
          _user={_m._user}
          name={_m.name}
          email={_m.email}
        >
          {_m.name}
        </Select.Option>
      );
    } else {
      return (
        <Select.Option key={_m} value={_m}>
          {_m}
        </Select.Option>
      );
    }
  });

  return (
    <Form
      form={form}
      className="form"
      autoComplete="off"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      layout="vertical"
      initialValues={{ content: "" }}
    >
      <RemoveAutocomplete />

      {!thisId ? (
        <SearchLeadsAndProjects handleSelect={handleSelect} />
      ) : (
        <>
          <div className={styles.identity}>
            <Typography.Text strong>Customer ID: </Typography.Text>
            <Typography.Text>{thisCustomerId}</Typography.Text>
            <br />
            <Typography.Text strong>Customer Name: </Typography.Text>
            <Typography.Text>{thisCustomerName}</Typography.Text>
            {thisProjectManager && (
              <>
                <br />
                <Typography.Text strong>Project Owner: </Typography.Text>
                <Typography.Text>{thisProjectManager.name}</Typography.Text>
              </>
            )}
          </div>
          <FormControls
            style={{
              borderBottom: "1px solid #f0f0f0",
              padding: 0,
            }}
          >
            <div className={styles.createActionButtons}>
              <Form.Item
                style={{ marginBottom: 0 }}
                name="file"
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Upload
                  multiple
                  beforeUpload={handleBeforeUpload}
                  customRequest={handleUpload}
                  showUploadList={false}
                  onProgress={handleProgress}
                  accept="image/*, application/pdf"
                >
                  <Button block>
                    <FileOutlined /> Upload File(s)
                  </Button>
                </Upload>
              </Form.Item>
            </div>
          </FormControls>
          <FormBody
            className="content-inner"
            style={{
              paddingLeft: 24,
              paddingTop: 24,
              paddingRight: 24,
              paddingBottom: 8,
              maxHeight:
                type === "modal"
                  ? "calc(100vh - 151px - 43px - 85px)"
                  : "calc(100% - 151px - 83px)",
              marginTop: type === "modal" ? 0 : 126,
            }}
          >
            <Form.Item
              name="content"
              label={
                <MarkUpHelper title="@ to mention people, # to mention tag" />
              }
            >
              <Mentions
                prefix={["@", "#"]}
                onSearch={onMentionSearch}
                autoFocus
                rows={4}
                getPopupContainer={(trigger) =>
                  trigger.parentNode.parentNode.parentNode.parentNode.parentNode
                    .parentNode.parentNode
                }
              >
                {renderMentions}
              </Mentions>
            </Form.Item>

            {tags.length > 0 && (
              <div style={{ paddingTop: "-8px", marginBottom: 8 }}>
                <label className="label">Followers</label>
                <br />
                {tags.map((t) => {
                  const removeTag = () => {
                    setTags(tags.filter((_t) => t._user !== _t._user));
                  };

                  return (
                    <Tag
                      key={t._user}
                      color="#1890ff"
                      closable
                      style={{ marginBottom: 8 }}
                      onClose={removeTag}
                    >{`${t.name}`}</Tag>
                  );
                })}
              </div>
            )}

            {_fileList.length > 0 && (
              <List className={styles.uploadRow} bordered>
                {Object.keys(uploads).map((key) => {
                  return (
                    <UploadRow
                      key={key}
                      preview={uploads[key].preview}
                      name={uploads[key].name}
                      size={uploads[key].size}
                      upload={uploads[key].upload}
                      failed={uploads[key].failed}
                    />
                  );
                })}
              </List>
            )}
          </FormBody>
          <FormControls className="sticky-footer">
            <CancelButton handleClick={cancel} />
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={loading || !unsavedChanges || uploading}
            >
              Submit
            </Button>
          </FormControls>
        </>
      )}
    </Form>
  );
};

export default CreateActivity;
