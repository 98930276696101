import React, { useState } from "react";
import { useSelector } from "react-redux";
import Form from "antd/es/form";
import Button from "antd/es/button";
import message from "antd/es/message";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import api from "API";
import CancelButton from "Components/CancelButton";
import AssignedTo from "Components/Inputs/Salesmen";
import useProjectManagers from "Hooks/useProjectManagers";

const messageKey = "messageKey";

const InstallTodoAssignee = ({
  unsavedChanges,
  setUnsavedChanges,
  handleClose,
  type,
}) => {
  const projectManagers = useProjectManagers();
  const formDetails = useSelector(
    (state) => state.formState.installTodoAssignee.formDetails
  );
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      setLoading(true);
      // message.loading("Updating assignee...", 0);
      message.loading({
        content: "Updating assignee...",
        duration: 0,
        key: messageKey,
      });
      values._id = formDetails._id;

      let array = [];
      if (values.assignedTo) {
        for (const a of values.assignedTo) {
          array.push(projectManagers[a]);
        }
      }

      values.assignedTo = array;
      values.oldAssignedTo = formDetails.assignedTo;
      const res = await api.put("/install/todos/update/assignee", values);

      if (formDetails.callback) {
        formDetails.callback(res.data);
      }

      // message.success("Assignee updated");
      message.success({
        content: "Assignee updated",
        duration: 1.5,
        key: messageKey,
      });
      setUnsavedChanges(false);
      setLoading(false);
      handleClose(true);
    } catch (err) {
      setLoading(false);
      // message.error("Error updating assignee");
      message.error({
        content: "Error updating assignee",
        duration: 1.5,
        key: messageKey,
      });
    }
  };

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error updating assignee");
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  const cancel = () => {
    handleClose();
  };

  return (
    <Form
      form={form}
      className="form"
      autoComplete="off"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      initialValues={{
        assignedTo: formDetails.assignedTo
          ? formDetails.assignedTo.map((a) => a._user)
          : undefined,
      }}
    >
      <RemoveAutocomplete />

      <FormBody
        className="content-inner"
        style={{
          paddingLeft: 24,
          paddingTop: 24,
          paddingRight: 24,
          paddingBottom: 8,
          maxHeight: type === "modal" ? "calc(100vh - 161px)" : "unset",
        }}
      >
        <Form.Item name="assignedTo" label="Assignee">
          <AssignedTo sales={projectManagers} />
        </Form.Item>
      </FormBody>

      <FormControls className="sticky-footer">
        <CancelButton handleClick={cancel} />
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={loading || !unsavedChanges}
        >
          Submit
        </Button>
      </FormControls>
    </Form>
  );
};

export default InstallTodoAssignee;
