import HighCharts from "highcharts";
import HighChartsReact from "highcharts-react-official";

import React, { useState, useEffect } from "react";

HighCharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
  colors: [
    "#00c292",
    "#fec107",
    "#FF7056",
    "#343a40",
    "#01c0c8",
    "#999999",
    "#FF9655",
    "#FFF263",
    "#6AF9C4",
  ],
});

const AssignedLeadsChart = ({ series }) => {
  const [options, setOptions] = useState({
    chart: {
      type: "bar",
      height: "100%",
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      type: "category",
      lineWidth: 0,
    },
    yAxis: {
      visible: false,
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      series: {
        pointPadding: 0.01,
        groupPadding: 0.03,
      },
      bar: {
        dataLabels: {
          enabled: true,
          formatter: function () {
            return HighCharts.numberFormat(this.y, 2);
          },
        },
      },
    },
    series: [],
  });

  useEffect(() => {
    setOptions((currentOptions) => {
      return {
        ...currentOptions.options,
        series,
      };
    });
  }, [series]);

  return (
    <HighChartsReact
      containerProps={{
        style: { height: "100%", width: "100%", paddingRight: 32 },
      }}
      highcharts={HighCharts}
      options={options}
      updateArgs={[true, true, true]}
      allowChartUpdate={true}
    />
  );
};

export default AssignedLeadsChart;
