import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchTable } from "Actions/tableActions";
import Button from "antd/es/button";
import message from "antd/es/message";
import Input from "antd/es/input";
import Checkbox from "antd/es/checkbox";
import Form from "antd/es/form";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import DuplicateCheck from "Components/Inputs/DuplicateCheck";
import ManageBuilders from "Components/Inputs/ManageBuilders";
import PropertyType from "Components/Inputs/PropertyType";
import Phone from "Components/Inputs/Phone";
import DatePicker from "Components/Inputs/DatePicker";
import Address from "Components/Inputs/Address";
// import Currency from "Components/Inputs/Currency";
import Campaigns from "Components/Inputs/Campaigns";
import Select from "Components/Inputs/Select";
import ProjectManager from "Components/Inputs/ProjectManager";
import Salesmen from "Components/Inputs/Salesmen";
import Scopes from "Components/Inputs/Scopes";
import Map from "Components/Details/fields/Map";
import useSalesmen from "Hooks/useSalesmen";
import usePrevious from "Hooks/usePrevious";
import useIsMobile from "Hooks/useIsMobile";
import styles from "./styles.module.scss";
import dayjs from "dayjs";
import api from "API";
import isEmpty from "Utils/isEmpty";
import CancelButton from "Components/CancelButton";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";

const messageKey = "messageKey";

const CreateLead = ({
  unsavedChanges,
  setUnsavedChanges,
  handleClose,
  type,
}) => {
  const dispatch = useDispatch();
  const formDetails = useSelector(
    (state) => state.formState.createLead.formDetails
  );
  const table = useSelector((state) => state.tableState.table);
  const col = useSelector((state) => state.tableState.col);
  const order = useSelector((state) => state.tableState.order);
  const search = useSelector((state) => state.tableState.search);
  const activeFilter = useSelector((state) => state.tableState.activeFilter);
  const _user = useSelector((state) => state.authState._user);
  const salesman = useSelector((state) => state.authState.salesman);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [createNew, setCreateNew] = useState(
    formDetails.salesmen ? true : false
  );
  const [propertyType, setPropertyType] = useState();
  const [_lat, _setLat] = useState(
    !formDetails.projectAddressLat ? 42.963795 : formDetails.projectAddressLat
  );
  const [_lng, _setLng] = useState(
    !formDetails.projectAddressLng ? -85.670006 : formDetails.projectAddressLng
  );
  const [salesmen, setSalesmen] = useState({});
  // const [estimate, setEstimate] = useState(false);
  // const [event, setEvent] = useState(false);
  const [copyAddress, setCopyAddress] = useState("");
  const [selectedOrigin, setSelectedOrigin] = useState(undefined);
  const prevCreateNew = usePrevious(createNew);
  const sales = useSalesmen();
  const mobile = useIsMobile();

  useEffect(() => {
    if (
      createNew &&
      !prevCreateNew &&
      !formDetails.salesmen &&
      salesman === "Yes"
    ) {
      let options = {};
      options[_user] = sales[_user];
      setSalesmen(options);

      form.setFieldsValue({
        projectAddressStreet: copyAddress,
        projectManager: _user,
      });
    } else if (
      createNew &&
      formDetails.salesmen &&
      !isEmpty(sales) &&
      salesman === "Yes"
    ) {
      let options = {};
      if (formDetails.salesmen) {
        for (const v of formDetails.salesmen) {
          options[v._user] = sales[v._user];
        }
        setSalesmen(options);

        form.setFieldsValue({
          projectManager: formDetails.projectManager._user,
        });
      }
    }
  }, [createNew, prevCreateNew, sales]); // eslint-disable-line react-hooks/exhaustive-deps

  const onFinish = useCallback(
    async (values) => {
      try {
        setLoading(true);
        // message.loading("Saving Lead...", 0);
        message.loading({
          content: "Saving Lead...",
          duration: 0,
          key: messageKey,
        });
        values.salesObj = [];
        for (const v of values.salesmen) {
          values.salesObj.push(sales[v]);
        }

        values.projectManager = sales[values.projectManager];

        if (values.estimateStart) {
          values.estimateStart = new Date(values.estimateStart);
          values.estimateEnd = new Date(
            dayjs(values.estimateStart).add(3, "hours")
          );
        }

        const res = await api.post("/leads/create", values);

        if (res.status === 200) {
          setLoading(false);
          if (table === "leads") {
            dispatch(
              fetchTable({
                table,
                col,
                order,
                search,
                activeFilter,
                fetching: false,
              })
            );
          }

          if (formDetails && formDetails.marketing) {
            // message.success("Lead saved and marked as Fishing");
            message.success({
              content: "Lead saved and marked as Fishing",
              duration: 1.5,
              key: messageKey,
            });
          } else {
            // message.success("Lead saved");
            message.success({
              content: "Lead saved",
              duration: 1.5,
              key: messageKey,
            });
            navigate(`/leads/view/details/${res.data._id}`);
          }

          if (formDetails && formDetails.callback) {
            formDetails.callback();
          }

          handleClose(true);
        } else {
          setLoading(false);
          // message.error("Error saving lead");
          message.error({
            content: "Error saving lead",
            duration: 1.5,
            key: messageKey,
          });
        }
      } catch (err) {
        if (
          err &&
          err.errorFields &&
          err.errorFields[0] &&
          err.errorFields[0].name
        ) {
          if (err.errorFields[0].name[0] === "lineItems") {
            const el = document.getElementById("top-of-form");
            el.scrollIntoView({ block: "end", behavior: "smooth" });
          } else {
            form.scrollToField(err.errorFields[0].name[0]);
          }
        }
        setLoading(false);

        // message.error("Error saving lead");
        message.error({
          content: "Error saving lead",
          duration: 1.5,
          key: messageKey,
        });
      }
    },
    [
      dispatch,
      table,
      col,
      order,
      search,
      activeFilter,
      navigate,
      handleClose,
      form,
      sales,
      formDetails,
    ]
  );

  const onFinishFailed = (err) => {
    console.log("err onFinishFailed", err);
    if (
      err &&
      err.errorFields &&
      err.errorFields[0] &&
      err.errorFields[0].name
    ) {
      if (err.errorFields[0].name[0] === "lineItems") {
        const el = document.getElementById("top-of-form");
        el.scrollIntoView({ block: "end", behavior: "smooth" });
      } else {
        form.scrollToField(err.errorFields[0].name[0]);
      }
    }
    message.error("Error creating lead");
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  const onPropertyTypeChange = (value) => {
    setPropertyType(value);
  };

  const updateCords = ({ lat, lng }) => {
    _setLat(lat);
    _setLng(lng);
  };

  const setProjectAddress = ({
    city,
    state,
    postcode,
    county,
    permitAuthority,
    lat,
    lng,
  }) => {
    form.setFieldsValue({
      projectAddressCity: city,
      projectAddressState: state,
      projectAddressZip: postcode,
      projectAddressCounty: county,
      projectAddressPermitAuthority: permitAuthority,
      projectAddressLat: lat,
      projectAddressLng: lng,
    });

    updateCords({
      lat,
      lng,
    });
  };

  const setBillingAddress = ({ city, state, postcode }) => {
    form.setFieldsValue({
      billingAddressCity: city,
      billingAddressState: state,
      billingAddressZip: postcode,
    });
  };

  const copyProjectAddress = async () => {
    try {
      const _projectAddress = await form.getFieldsValue([
        "projectAddressStreet",
        "projectAddressCity",
        "projectAddressState",
        "projectAddressZip",
      ]);

      form.setFieldsValue({
        billingAddressStreet: _projectAddress.projectAddressStreet,
        billingAddressCity: _projectAddress.projectAddressCity,
        billingAddressState: _projectAddress.projectAddressState,
        billingAddressZip: _projectAddress.projectAddressZip,
      });
    } catch (err) {
      console.log("copyProjectAddress err", err);
    }
  };

  const onSalesmenChange = async (value) => {
    try {
      let options = {};
      for (const v of value) {
        options[v] = sales[v];
      }
      setSalesmen(options);

      const _projectManager = await form.getFieldValue("projectManager");
      if (!options[_projectManager]) {
        if (value.length > 0) {
          form.setFieldsValue({
            projectManager: value[0],
          });
        } else {
          form.setFieldsValue({
            projectManager: undefined,
          });
        }
      }
    } catch (err) {
      console.log("onSalesmenChange err", err);
    }
  };

  // const onEstimateChange = (e) => {
  //   setEstimate(e.target.checked);
  // };

  // const onEventChange = (e) => {
  //   setEvent(e.target.checked);
  // };

  const handleOriginChange = (value) => {
    setSelectedOrigin(value);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      className="form"
      style={{
        maxHeight: type === "modal" ? "calc((100vh - 100px) - 55px)" : "unset",
      }}
      initialValues={{
        // origin: "Personal",
        propertyType: !formDetails ? undefined : formDetails.propertyType,
        customerName: !formDetails ? undefined : formDetails.customerName,
        companyName: !formDetails ? undefined : formDetails.companyName,
        phone: !formDetails ? undefined : formDetails.phone,
        email: !formDetails ? undefined : formDetails.email,
        alternatePhone: !formDetails ? undefined : formDetails.alternatePhone,
        alternateEmail: !formDetails ? undefined : formDetails.alternateEmail,
        projectAddressStreet: !formDetails
          ? undefined
          : formDetails.projectAddressStreet,
        projectAddressCity: !formDetails
          ? undefined
          : formDetails.projectAddressCity,
        projectAddressState: !formDetails
          ? undefined
          : formDetails.projectAddressState,
        projectAddressZip: !formDetails
          ? undefined
          : formDetails.projectAddressZip,
        projectAddressPermitAuthority: !formDetails
          ? undefined
          : formDetails.projectAddressPermitAuthority,
        projectAddressCounty: !formDetails
          ? undefined
          : formDetails.projectAddressCounty,
        projectAddressLat: !formDetails
          ? undefined
          : formDetails.projectAddressLat,
        projectAddressLng: !formDetails
          ? undefined
          : formDetails.projectAddressLng,
        billingAddressStreet: !formDetails
          ? undefined
          : formDetails.billingAddressStreet,
        billingAddressCity: !formDetails
          ? undefined
          : formDetails.billingAddressCity,
        billingAddressState: !formDetails
          ? undefined
          : formDetails.billingAddressState,
        billingAddressZip: !formDetails
          ? undefined
          : formDetails.billingAddressZip,
        salesmen:
          !formDetails && salesman === "Yes"
            ? [_user]
            : formDetails.salesmen
            ? formDetails.salesmen.map((s) => s._user)
            : salesman === "Yes"
            ? [_user]
            : undefined,
        projectManager:
          !formDetails && salesman === "Yes"
            ? _user
            : formDetails.projectManager
            ? formDetails.projectManager._user
            : salesman === "Yes"
            ? _user
            : undefined,
        fishing: formDetails && formDetails.marketing ? true : false,
      }}
    >
      <RemoveAutocomplete />

      {!createNew ? (
        <DuplicateCheck
          type="Lead"
          setCreateNew={setCreateNew}
          handleClose={handleClose}
          setCopyAddress={setCopyAddress}
        />
      ) : (
        <FormBody
          className="content-inner"
          style={{
            paddingLeft: 24,
            paddingTop: 24,
            paddingRight: 24,
            paddingBottom: 8,
            maxHeight: "calc(100% - 55px)",
          }}
        >
          <div id="top-of-form" />
          <Form.Item
            name="propertyType"
            label="Property Type"
            rules={[{ required: true, message: "Property type is required" }]}
          >
            <PropertyType onChange={onPropertyTypeChange} />
          </Form.Item>

          {propertyType === "New Construction" && (
            <ManageBuilders form={form} />
          )}

          <Form.Item name="companyName" label="Company Name">
            <InputNoAutoComplete id="companyName" />
          </Form.Item>

          <Form.Item
            name="customerName"
            label="Customer Name"
            rules={[{ required: true, message: "Customer name is required" }]}
          >
            <InputNoAutoComplete id="customerName" />
          </Form.Item>

          <Form.Item name="fishing" valuePropName="checked" hidden>
            <Checkbox>Fishing Lead</Checkbox>
          </Form.Item>

          <Form.Item
            name="phone"
            label="Phone"
            rules={[{ required: true, message: "Phone is required" }]}
          >
            <Phone autoComplete="lead-phone-input" id="phone" />
          </Form.Item>

          <Form.List name="alternatePhone">
            {(fields, { add, remove }) => {
              const handleAdd = () => {
                add();
              };

              const handleRemove = () => {
                remove(fields.length - 1);
              };

              return (
                <>
                  {fields.map((field, i) => (
                    <div key={`${field.key}-altPhone`}>
                      <Form.Item
                        {...field}
                        name={[field.name, "description"]}
                        fieldKey={[field.fieldKey, "description"]}
                        key={`description${field.fieldKey}`}
                        label={`Alt. Phone Description ${i + 1}`}
                        rules={[
                          {
                            required: true,
                            message: "Description is required",
                          },
                        ]}
                      >
                        <InputNoAutoComplete id={[field.name, "description"]} />
                      </Form.Item>

                      <Form.Item
                        {...field}
                        name={[field.name, "contact"]}
                        label={`Alt. Phone ${i + 1}`}
                        fieldKey={[field.fieldKey, "contact"]}
                        key={`contact${field.fieldKey}`}
                        rules={[
                          { required: true, message: "Phone is required" },
                          {
                            len: 14,
                            message: "Phone must be 10 digits long",
                          },
                        ]}
                      >
                        <Phone
                          autoComplete="edit-detail-field"
                          id={[field.name, "contact"]}
                        />
                      </Form.Item>
                    </div>
                  ))}

                  <div
                    className={`${styles.altButtonContainer} ${
                      fields.length === 0 ? styles.end : ""
                    }`}
                  >
                    {fields.length > 0 && (
                      <Button type="link" danger onClick={handleRemove}>
                        Remove Alt. Phone
                      </Button>
                    )}

                    <Button type="link" onClick={handleAdd}>
                      Add Alt. Phone
                    </Button>
                  </div>
                </>
              );
            }}
          </Form.List>

          <Form.Item
            name="email"
            label="Email"
            rules={[{ type: "email", message: "Must be valid email" }]}
          >
            <InputNoAutoComplete type="email" id="email" />
          </Form.Item>

          <Form.List name="alternateEmail">
            {(fields, { add, remove }) => {
              const handleAdd = () => {
                add();
              };

              const handleRemove = () => {
                remove(fields.length - 1);
              };

              return (
                <>
                  {fields.map((field, i) => (
                    <div key={`${field.key}-altEmail`}>
                      <Form.Item
                        {...field}
                        name={[field.name, "description"]}
                        fieldKey={[field.fieldKey, "description"]}
                        key={`description${field.fieldKey}`}
                        label={`Alt. Email Description ${i + 1}`}
                        rules={[
                          {
                            required: true,
                            message: "Description is required",
                          },
                        ]}
                      >
                        <InputNoAutoComplete id={[field.name, "description"]} />
                      </Form.Item>

                      <Form.Item
                        {...field}
                        name={[field.name, "contact"]}
                        label={`Alt. Email ${i + 1}`}
                        fieldKey={[field.fieldKey, "contact"]}
                        key={`contact${field.fieldKey}`}
                        rules={[
                          { required: true, message: "Email is required" },
                          { type: "email", message: "Must be a valid email" },
                        ]}
                      >
                        <InputNoAutoComplete
                          autoComplete="edit-detail-field"
                          type="email"
                          id={[field.name, "contact"]}
                        />
                      </Form.Item>
                    </div>
                  ))}

                  <div
                    className={`${styles.altButtonContainer} ${
                      fields.length === 0 ? styles.end : ""
                    }`}
                  >
                    {fields.length > 0 && (
                      <Button type="link" danger onClick={handleRemove}>
                        Remove Alt. Email
                      </Button>
                    )}

                    <Button type="link" onClick={handleAdd}>
                      Add Alt. Email
                    </Button>
                  </div>
                </>
              );
            }}
          </Form.List>

          <h3 style={{ marginBottom: 12 }}>Project Address</h3>

          <Form.Item
            label="Street"
            name="projectAddressStreet"
            rules={[{ required: true, message: "Project street is required" }]}
          >
            <Address
              autoComplete="projectStreet"
              setAddress={setProjectAddress}
              id="projectAddressStreet"
            />
          </Form.Item>

          <Form.Item
            label="City"
            name="projectAddressCity"
            rules={[
              {
                required: true,
                message: "Project city is required",
                type: "string",
              },
            ]}
          >
            <InputNoAutoComplete id="projectAddressCity" />
          </Form.Item>

          <Form.Item
            label="State"
            name="projectAddressState"
            rules={[
              {
                required: true,
                message: "Project state is required",
                type: "string",
              },
              {
                len: 2,
                message: "State must be abbreviated",
              },
            ]}
          >
            <InputNoAutoComplete id="projectAddressState" />
          </Form.Item>

          <Form.Item
            label="Zip"
            name="projectAddressZip"
            rules={[
              {
                required: true,
                message: "Project zip is required",
                type: "string",
              },
            ]}
          >
            <InputNoAutoComplete type="phone" id="projectAddressZip" />
          </Form.Item>

          <Map
            type="project"
            form={form}
            lat={_lat}
            lng={_lng}
            updateCords={updateCords}
          />

          <Form.Item style={{ display: "none" }} name="projectAddressCounty">
            <Input autoComplete="projectCounty" type="text" />
          </Form.Item>

          <Form.Item
            style={{ display: "none" }}
            name="projectAddressPermitAuthority"
          >
            <Input autoComplete="projectPermitAuthority" type="text" />
          </Form.Item>

          <Form.Item style={{ display: "none" }} name="projectAddressLat">
            <Input autoComplete="projectLat" type="text" />
          </Form.Item>

          <Form.Item style={{ display: "none" }} name="projectAddressLng">
            <Input autoComplete="projectLng" type="text" />
          </Form.Item>

          <h3 className={styles.headerWithButton}>
            <span>Billing Address</span>
            <Button
              type="primary"
              size="small"
              className="green"
              onClick={copyProjectAddress}
            >
              Copy Project Address
            </Button>{" "}
          </h3>

          <Form.Item
            label="Street"
            name="billingAddressStreet"
            rules={[{ required: true, message: "Billing street is required" }]}
          >
            <Address
              autoComplete="billingStreet"
              setAddress={setBillingAddress}
              id="billingAddressStreet"
            />
          </Form.Item>

          <Form.Item
            label="City"
            name="billingAddressCity"
            rules={[
              {
                required: true,
                message: "Billing city is required",
                type: "string",
              },
            ]}
          >
            <InputNoAutoComplete id="billingAddressCity" />
          </Form.Item>

          <Form.Item
            label="State"
            name="billingAddressState"
            rules={[
              {
                required: true,
                message: "Billing state is required",
                type: "string",
              },
              {
                len: 2,
                message: "State must be abbreviated",
              },
            ]}
          >
            <InputNoAutoComplete id="billingAddressState" />
          </Form.Item>

          <Form.Item
            label="Zip"
            name="billingAddressZip"
            rules={[
              {
                required: true,
                message: "Billing zip is required",
                type: "string",
              },
            ]}
          >
            <InputNoAutoComplete type="phone" id="billingAddressZip" />
          </Form.Item>

          <Form.Item
            name="salesmen"
            label="Salesmen"
            rules={[
              {
                required: true,
                message: "Must select at least 1 salesman",
                type: "array",
              },
            ]}
          >
            <Salesmen sales={sales} onChange={onSalesmenChange} />
          </Form.Item>

          <Form.Item
            name="projectManager"
            label="Project Owner"
            rules={[
              {
                required: true,
                message: "Select a Project Owner",
              },
            ]}
          >
            <ProjectManager sales={salesmen} />
          </Form.Item>

          <Form.Item
            name="scopes"
            label="Scopes"
            rules={[
              {
                required: true,
                message: "Must select at least 1 scopes",
                type: "array",
              },
            ]}
          >
            <Scopes id="scopes" />
          </Form.Item>

          <Form.Item
            name="origin"
            label="Origin"
            rules={[
              {
                required: true,
                message: "Select an Origin",
              },
            ]}
          >
            <Select
              options={["Personal", "Office"]}
              id="origin"
              onChange={handleOriginChange}
            />
          </Form.Item>

          {selectedOrigin === "Personal" && (
            <Form.Item
              name="personalReferralName"
              label="Personal Referrer Name"
              rules={[
                {
                  required: true,
                  message: "Must provide name of person who referred the lead",
                },
              ]}
            >
              <InputNoAutoComplete />
            </Form.Item>
          )}

          {selectedOrigin === "Office" && (
            <Form.Item
              name="campaign"
              label="Campaign"
              rules={[
                {
                  required: true,
                  message: "Select a Campaign",
                },
              ]}
            >
              <Campaigns />
            </Form.Item>
          )}

          {/* <Form.Item name="estimate" valuePropName="checked">
            <Checkbox onChange={onEstimateChange}>Estimate</Checkbox>
          </Form.Item> */}

          {/* {estimate && (
            <Form.Item
              name="estimateValue"
              label="Estimate Value"
              rules={[
                { required: true, message: "Estimate value is required" },
              ]}
            >
              <Currency id="estimateValue" />
            </Form.Item>
          )} */}

          {/* <Form.Item name="event" valuePropName="checked">
            <Checkbox onChange={onEventChange}>Create Event</Checkbox>
          </Form.Item> */}

          {/* {event && ( */}
          <Form.Item
            name="estimateStart"
            label="Estimate Start"
            // rules={[{ required: true, message: "Estimate start is required" }]}
          >
            <DatePicker
              format="MMMM DD, YYYY h:mm A"
              showTime
              minuteStep={15}
              getPopupContainer={(trigger) =>
                !mobile
                  ? trigger.parentNode.parentNode.parentNode.parentNode
                  : document.body
              }
              getCalendarContainer={(trigger) =>
                !mobile
                  ? trigger.parentNode.parentNode.parentNode.parentNode
                  : document.body
              }
              inputReadOnly={true}
            />
          </Form.Item>
          {/* )} */}

          <Form.Item name="notes" label="Notes">
            <Input.TextArea autoComplete="lead-notes" type="text" rows={4} />
          </Form.Item>
        </FormBody>
      )}

      {createNew && (
        <FormControls className="sticky-footer">
          <CancelButton handleClick={handleClose} />
          <Form.Item style={{ margin: 0 }}>
            <Button
              htmlType="submit"
              type="primary"
              loading={loading}
              disabled={loading || !unsavedChanges}
            >
              Submit
            </Button>
          </Form.Item>
        </FormControls>
      )}
    </Form>
  );
};

export default CreateLead;
