import { useEffect, useState } from "react";
import api from "API";
let isMounted = false;

export default function useSalesmen() {
  const [sales, setSales] = useState({});

  useEffect(() => {
    isMounted = true;
    fetchSales();

    return () => (isMounted = false);
  }, []);

  const fetchSales = async () => {
    try {
      const res = await api.get("/populateSales");
      let arr = {};
      for (const s of res.data) {
        arr[s._id] = {
          _user: s._id,
          name: s.name,
          email: s.email,
          phone: s.phone,
          calendarId: s.calendarId,
          hoverUserId: s.hoverUserId,
        };
      }

      if (isMounted) setSales(arr);
    } catch (err) {
      console.log("err", err);
    }
  };

  return sales;
}
