import React, { useCallback, useState, useEffect } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateExpensesActivitiesAndHashtags } from "Actions/dataActions";
import Form from "antd/es/form";
import Button from "antd/es/button";
import message from "antd/es/message";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import api from "API";
import Currency from "Components/Inputs/Currency";
import SearchLeadsAndProjects from "Components/Inputs/SearchLeadsAndProjects";
import DatePicker from "Components/Inputs/DatePicker";
import Select from "antd/es/select";
import dayjs from "dayjs";
import useIsMobile from "Hooks/useIsMobile";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";
import Modal from "antd/es/modal";
import CancelButton from "Components/CancelButton";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";
import List from "antd/es/list";
import currencyFormatter from "Utils/currencyFormatter";
import Divider from "antd/es/divider";
import Alert from "antd/es/alert";
import Input from "antd/es/input";

const messageKey = "messageKey";

const Expense = ({
  unsavedChanges,
  setUnsavedChanges,
  handleClose,
  setTitle,
  type,
}) => {
  const dispatch = useDispatch();
  const formDetails = useSelector(
    (state) => state.formState.expense.formDetails
  );
  const admin = useSelector((state) => state.authState.admin);
  const role = useSelector((state) => state.authState.role);
  const details = useSelector((state) => state.dataState.details);
  const viewableActivities = useSelector(
    (state) => state.dataState.viewableActivities
  );
  const mobile = useIsMobile();
  const [_projectId, _setProjectId] = useState(
    formDetails._projectId ? formDetails._projectId : details._id
  );
  const [customerId, setCustomerId] = useState(
    formDetails.customerId ? formDetails.customerId : details.customerId
  );
  const [loading, setLoading] = useState(false);
  const [editing] = useState(formDetails.editing ? formDetails.editing : false);

  const [projectManager, setProjectManager] = useState(
    formDetails.projectManager
  );
  const [expenses, setExpenses] = useState([]);
  const [options, setOptions] = useState([]);
  const [_expenses, _setExpenses] = useState([]);
  const [_expensesTotal, _setExpensesTotal] = useState(0);
  const [brokedown, setBrokedown] = useState(
    details.brokedown ? details.brokedown : "No"
  );
  const [scopes, setScopes] = useState([]);
  const [scopesTotal, setScopesTotal] = useState(0);
  const [helpMessage, setHelpMessage] = useState(undefined);
  const [form] = Form.useForm();

  useEffect(() => {
    if (!formDetails.editing) {
      setTitle("Create Expense");
    } else {
      setTitle(
        <div style={{ marginRight: 28 }}>
          Edit Expense{" "}
          {(admin === "Yes" || role === "Office") && (
            <Button
              size="small"
              danger
              onClick={confirmDeleteExpense}
              style={{ float: "right" }}
            >
              Delete
            </Button>
          )}
        </div>
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchOptions();
  }, []);

  useEffect(() => {
    if (editing || _projectId) {
      fetchExpenses();
      fetchDetails();
    }
  }, [editing, _projectId]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchOptions = async () => {
    try {
      const res = await api.post("/dropdowns/get", {
        selects: ["Expenses"],
      });
      setExpenses(res.data.Expenses.options);
    } catch (err) {
      console.log("err", err);
    }
  };

  const fetchExpenses = async (_id) => {
    try {
      const res = await api.get(`expenses/get/${_id ? _id : _projectId}`);
      let total = 0;
      for (const c of res.data.expenses) {
        total += c.amount;
      }

      let _scopesTotal = 0;
      if (formDetails.scopes) {
        for (const scope of formDetails.scopes) {
          const matchingScope = res.data.scopes.find(
            (_scope) => _scope._scopeId.toString() === scope._scopeId.toString()
          );
          matchingScope.amount = scope.amount;
          _scopesTotal += scope.amount;
        }
      }

      unstable_batchedUpdates(() => {
        _setExpenses(res.data.expenses);
        _setExpensesTotal(total);
        setScopes(res.data.scopes);
        setScopesTotal(_scopesTotal);
      });

      form.setFieldsValue({
        scopes: res.data.scopes,
      });
    } catch (err) {
      console.log("err", err);
      message.error("Error retreiving collection details");
    }
  };

  const fetchDetails = async () => {
    try {
      const res = await api.get(`/collections/billing/${_projectId}`);
      setBrokedown(res.data.brokedown);
    } catch (err) {
      console.log("err", err);
      message.error("Error retreiving brokedown details");
    }
  };

  const onFinish = useCallback(
    async (values) => {
      try {
        if (values.amount !== scopesTotal) {
          message.error({
            content: "Total amount must be allocated to scopes",
            duration: 1.5,
            key: messageKey,
          });
          setLoading(false);
          return;
        }

        setLoading(true);
        values._projectId = _projectId;
        values.customerId = customerId;
        values.projectManager = projectManager;

        // message.loading("Saving Expense...", 0);
        message.loading({
          content: "Saving expense...",
          duration: 0,
          key: messageKey,
        });
        let res;
        if (!editing) {
          res = await api.post("/expenses/create", values);
        } else {
          values._prevDetails = {
            type: formDetails.type,
            amount: formDetails.amount,
            transactionDate: formDetails.transactionDate,
            identifier: formDetails.identifier,
            vendor: formDetails.vendor,
          };
          values._id = formDetails._id;
          res = await api.post("/expenses/edit", values);
        }

        dispatch(
          updateExpensesActivitiesAndHashtags({
            viewableActivities,
            _projectId,
            data: res.data,
          })
        );

        if (formDetails.callback) formDetails.callback();

        setUnsavedChanges(false);

        // message.success(!editing ? "Expense created" : "Expense updated");
        message.success({
          content: !editing ? "Expense created" : "Expense updated",
          duration: 1.5,
          key: messageKey,
        });
        handleClose(true);
      } catch (err) {
        setLoading(false);
        // message.error(
        //   !editing ? "Error creating expense" : "Error updating expense"
        // );
        message.error({
          content: !editing
            ? "Error creating expense"
            : "Error updating expense",
          duration: 1.5,
          key: messageKey,
        });
      }
    },
    [
      _projectId,
      customerId,
      projectManager,
      editing,
      formDetails,
      dispatch,
      viewableActivities,
      setUnsavedChanges,
      handleClose,
      scopesTotal,
    ]
  );

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error(
      !editing ? "Error creating expense" : "Error updating expense"
    );
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  const cancel = () => {
    handleClose();
  };

  const handleSelect = (selected) => {
    _setProjectId(selected._id);
    setCustomerId(selected.customerId);
    setProjectManager(selected.projectManager);
    fetchExpenses(selected._id);
  };

  const handleTypeSelect = async (_type) => {
    try {
      const res = await api.post("/vendors/get", { expenseTypes: _type });
      setOptions(res.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  const confirmDeleteExpense = () => {
    Modal.confirm({
      zIndex: 4002,
      icon: <QuestionCircleOutlined />,
      centered: true,
      cancelText: "Cancel",
      okText: "Delete",
      okButtonProps: { danger: true },
      content: <div>Are you sure you want to delete this expense?</div>,
      onOk() {
        return new Promise((resolve, reject) => {
          deleteExpense(() => resolve(true));
        }).catch((err) => console.log("err: ", err));
      },
      onCancel() {},
    });
  };

  const deleteExpense = useCallback(
    async (cb) => {
      try {
        const res = await api.delete(`/expenses/delete/${formDetails._id}`);

        dispatch(
          updateExpensesActivitiesAndHashtags({
            viewableActivities,
            _projectId,
            data: res.data,
          })
        );

        if (formDetails.callback) formDetails.callback();

        cb();
        handleClose(true);
      } catch (err) {
        console.log("err", err);
        message.error("Error deleting expense");
      }
    },
    [formDetails, dispatch, viewableActivities, _projectId, handleClose]
  );

  const validateAmount = () => {
    const amount = form.getFieldValue("amount");
    if (amount) {
      if (amount - scopesTotal !== 0) {
        return "error";
      }
    }
    return undefined;
  };

  const renderHelpMessage = useCallback(() => {
    const amount = form.getFieldValue("amount");
    if (amount || amount === 0) {
      if (amount - scopesTotal !== 0) {
        setHelpMessage(
          `${currencyFormatter(amount - scopesTotal)} to be allocated`
        );
      } else {
        setHelpMessage(undefined);
      }
    } else {
      setHelpMessage(undefined);
    }
  }, [form, scopesTotal]);

  useEffect(() => {
    renderHelpMessage();
  }, [scopesTotal, renderHelpMessage]);

  const scopeAmountChange = async () => {
    try {
      const amounts = await form.getFieldValue("scopes");
      let total = 0;
      for (const a of amounts) {
        if (a && a.amount) {
          total += a.amount;
        }
      }
      setScopesTotal(total);
    } catch (err) {
      console.log("scopeAmountChange err", err);
    }
  };

  const amountChange = async (value) => {
    try {
      const amounts = await form.getFieldValue("scopes");
      if (scopes && scopes.length === 1) {
        amounts[0].amount = value;
        setScopesTotal(value);
      } else {
        Object.entries(amounts).map(([key, value]) => {
          form.validateFields([key]);
          return value;
        });

        renderHelpMessage();
      }
    } catch (err) {
      console.log("amount change err", err);
    }
  };

  return (
    <Form
      form={form}
      className="form"
      autoComplete="off"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      scrollToFirstError={true}
      initialValues={{
        type: formDetails.type ? formDetails.type : undefined,
        vendor: formDetails.vendor ? formDetails.vendor : undefined,
        transactionDate: formDetails.transactionDate
          ? dayjs(formDetails.transactionDate)
          : undefined,
        identifier: formDetails.identifier ? formDetails.identifier : undefined,
        amount: formDetails.amount ? formDetails.amount : undefined,
        scopes: scopes,
      }}
    >
      <RemoveAutocomplete />

      {!_projectId ? (
        <SearchLeadsAndProjects
          handleSelect={handleSelect}
          leads={false}
          disableLastViewed={true}
        />
      ) : (
        <FormBody
          className="content-inner"
          style={{
            paddingLeft: 24,
            paddingTop: 24,
            paddingRight: 24,
            paddingBottom: 8,
            maxHeight: type === "modal" ? "calc(100vh - 161px)" : "unset",
          }}
        >
          {brokedown === "Yes" && (
            <Alert
              style={{ marginBottom: 8, textAlign: "center" }}
              type="error"
              message="This project has already been broke down"
            />
          )}
          <List bordered={false} split={false}>
            <List.Item style={{ padding: 0 }}>
              <List.Item.Meta title="Expenses" />
            </List.Item>

            {_expenses.length > 0 ? (
              <>
                {_expenses.map((e) => (
                  <List.Item key={e._id} style={{ padding: 0 }}>
                    <List.Item.Meta
                      description={dayjs(e.transactionDate).format(
                        "MMM D, YYYY"
                      )}
                    />
                    <List.Item.Meta
                      description={e.vendor}
                      className="list-item-ellipsis"
                    />
                    <List.Item.Meta
                      style={{ textAlign: "right" }}
                      description={currencyFormatter(e.amount)}
                    />
                  </List.Item>
                ))}
              </>
            ) : (
              <List.Item style={{ padding: 0 }}>
                <List.Item.Meta description="No expenses have been recorded" />
              </List.Item>
            )}

            <List.Item
              style={{
                padding: 0,
                borderStyle: "double",
                borderColor: "#dddddd",
                borderLeft: "none",
                borderRight: "none",
                borderBottom: "none",
              }}
            >
              <List.Item.Meta title="Total Expenses" />
              <List.Item.Meta
                style={{ textAlign: "right" }}
                title={currencyFormatter(_expensesTotal)}
              />
            </List.Item>
          </List>

          <Divider />

          <Form.Item
            name="transactionDate"
            label="Date"
            rules={[{ required: true, message: "Date is required" }]}
          >
            <DatePicker
              format="MMMM DD, YYYY"
              getPopupContainer={(trigger) =>
                !mobile
                  ? trigger.parentNode.parentNode.parentNode.parentNode
                  : document.body
              }
              getCalendarContainer={(trigger) =>
                !mobile
                  ? trigger.parentNode.parentNode.parentNode.parentNode
                  : document.body
              }
              inputReadOnly={true}
            />
          </Form.Item>

          <Form.Item
            name="type"
            label="Type"
            rules={[{ required: true, message: "Type is required" }]}
          >
            <Select
              onChange={handleTypeSelect}
              showSearch={!mobile}
              dropdownClassName={mobile ? "isMobile" : null}
              getPopupContainer={(trigger) =>
                !mobile
                  ? trigger.parentNode.parentNode.parentNode.parentNode
                      .parentNode
                  : document.body
              }
            >
              {expenses.map((type) => {
                return (
                  <Select.Option key={type} value={type}>
                    {type}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name="vendor"
            label="Vendor"
            rules={[{ required: true, message: "Vendor is required" }]}
          >
            <Select
              showSearch={!mobile}
              dropdownClassName={mobile ? "isMobile" : null}
              getPopupContainer={(trigger) =>
                !mobile
                  ? trigger.parentNode.parentNode.parentNode.parentNode
                      .parentNode
                  : document.body
              }
            >
              {options.length > 0 ? (
                <>
                  {options.map((type) => {
                    return (
                      <Select.Option key={type} value={type}>
                        {type}
                      </Select.Option>
                    );
                  })}
                  <Select.Option key="Other" value="Other">
                    Other
                  </Select.Option>
                  <Select.Option key="N/A" value="N/A">
                    N/A
                  </Select.Option>
                </>
              ) : (
                <Select.Option key="N/A" value="N/A">
                  N/A
                </Select.Option>
              )}
            </Select>
          </Form.Item>

          <Form.Item
            name="identifier"
            label="Reference Number"
            rules={[{ type: "string" }]}
          >
            <InputNoAutoComplete id="identifier" />
          </Form.Item>

          <Form.Item
            name="descriptionOfWork"
            label="Description Of Work"
            rules={[{ type: "string" }]}
          >
            <InputNoAutoComplete id="descriptionOfWork" />
          </Form.Item>

          <Form.Item
            name="amount"
            label="Amount"
            rules={[{ required: true, message: "Amount is required" }]}
          >
            <Currency id="amount" onChange={amountChange} />
          </Form.Item>

          <Divider>Scope Allocation</Divider>

          <Form.List name="scopes">
            {(fields, { add, remove }) => {
              return (
                <>
                  {fields.map((field, index) => (
                    <div key={field.fieldKey}>
                      <Form.Item
                        {...field}
                        name={[field.name, "_scopeId"]}
                        fieldKey={[field.fieldKey, "_scopeId"]}
                        key={[field.fieldKey, "_scopeId"]}
                        help=""
                        hidden
                      >
                        <Input />
                      </Form.Item>

                      {/* <Form.Item
                        {...field}
                        name={[field.name, "name"]}
                        fieldKey={[field.fieldKey, "name"]}
                        key={[field.fieldKey, "name"]}
                        help=""
                        hidden
                      >
                        <Input />
                      </Form.Item> */}

                      <Form.Item
                        {...field}
                        name={[field.name, "amount"]}
                        fieldKey={[field.fieldKey, "amount"]}
                        key={[field.fieldKey, "amount"]}
                        label={
                          scopes[index].label
                            ? `${scopes[index].label} (${scopes[index].name}) Allocation`
                            : `${scopes[index].name} Allocation`
                        }
                        validateStatus={validateAmount()}
                        help={helpMessage}
                        rules={[
                          { required: true, message: "Amount is required" },
                        ]}
                      >
                        <Currency
                          id={[field.name, "amount"]}
                          onChange={scopeAmountChange}
                        />
                      </Form.Item>
                    </div>
                  ))}
                </>
              );
            }}
          </Form.List>
        </FormBody>
      )}

      {_projectId && (
        <FormControls className="sticky-footer">
          <CancelButton handleClick={cancel} />
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={loading || !unsavedChanges}
          >
            Submit
          </Button>
        </FormControls>
      )}
    </Form>
  );
};

export default Expense;
