import React, {
  useEffect,
  // lazy,
  useState,
  Suspense,
  useCallback,
} from "react";
import {
  Outlet,
  Route,
  // Navigate,
  Routes,
  useLocation,
  useNavigate,
  // Outlet,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import { useDispatch, useSelector } from "react-redux";
import { showMenu, setActiveRoute } from "Actions/settingsActions";
import { init, updateSw } from "Actions/authActions";
import usePrevious from "Hooks/usePrevious";
import useIsMounted from "Hooks/useIsMounted";
import useApiErrors from "Hooks/useApiErrors";
import Layout from "antd/es/layout";
import message from "antd/es/message";
import notification from "antd/es/notification";
import Button from "antd/es/button";
// import Modal from "antd/es/modal";
import Drawer from "antd/es/drawer";
import Grid from "antd/es/grid";
import * as serviceWorker from "./serviceWorkerRegistration";
import * as actions from "./Actions/actionTypes";

import InstallInstructions from "Components/InstallInstructions";
import AddToHomeScreen from "Components/AddToHomeScreen";
import NotificationPermission from "Components/NotificationPermission";

import Loader from "Components/Loader";
import Share from "Components/Share";
import ImageViewer from "Components/images/ImageViewer";
import Forms from "Forms";
import Header from "Components/Header";
import Navigation from "Components/Navigation";
import PasswordReset from "Screens/PasswordReset";
import BullpinDashboard from "Screens/BullpinDashboard";

import ErrorFallback from "Components/ErrorFallback";
import io from "socket.io-client";
import WorkAccepted from "Screens/WorkAccepted";
import WorkDeclined from "Screens/WorkDeclined";
import SubInvoiceSubmit from "Screens/SubInvoiceSubmit/SubInvoiceSubmit";

import ServerError from "Screens/ServerError";
import Unauthorized from "Screens/Unauthorized";
import NoMatch from "Screens/NoMatch";
import Signin from "Screens/Signin";
import ForgotPassword from "Screens/ForgotPassword";
import Dashboard from "Screens/Dashboard";
import Leads from "Screens/Leads";
import Projects from "Screens/Projects";
import Notifications from "Screens/Notifications";
import Feed from "Screens/Feed";
import AerialVerification from "Screens/AerialVerification";
import OnSiteCheck from "Screens/OnSiteCheck";
import MoldReview from "Screens/MoldReview";
import Permits from "Screens/Permits";
import RoofingOverview from "Screens/RoofingOverview";
import PreJobCheckMedia from "Screens/PreJobCheckMedia";
import PreJobCheck from "Screens/PreJobCheck";
import ReadyForMaterial from "Screens/ReadyForMaterial";
import ReadyForDumpster from "Screens/ReadyForDumpster";
import ReadyForSchedule from "Screens/ReadyForSchedule";
import Schedule from "Screens/Schedule";
import Documents from "Screens/Documents";
import PhotoAlbums from "Screens/PhotoAlbums";
import Scopes from "Screens/Scopes";
import Subs from "Screens/Subs";
import Dumpsters from "Screens/Dumpsters";
import Suppliers from "Screens/Suppliers";
import ManageDropdowns from "Screens/ManageDropdowns";
import ManageTieredDropdowns from "Screens/ManageTieredDropdowns";
import PostJobInspections from "Screens/PostJobInspections";
import PreJobInspections from "Screens/PreJobInspections";
import InstallTodos from "Screens/InstallTodos";
import AccountReceivables from "Screens/AccountReceivables";
import Breakdowns from "Screens/Breakdowns";
import Collections from "Screens/Collections";
import AccountReceivablesReport from "Reports/AccountReceivables";
import RoofingProducts from "Reports/RoofingProducts";
import Pipeline from "Reports/Pipeline";
import PendingAccountReceivables from "Reports/PendingAccountReceivables";
import DownPaymentSurplus from "Reports/DownPaymentSurplus";
import LeadConversionRate from "Reports/LeadConversionRate";
import LeadConversionRateByOrigin from "Reports/LeadConversionRateByOrigin";
import LeadsReport from "Reports/Leads";
import LeadsByOrigin from "Reports/LeadsByOrigin";
import LeadsByCampaign from "Reports/LeadsByCampaign";
import LeadsVsSalesBySalesman from "Reports/LeadsVsSalesBySalesman";
import ProfitPercentageByOrigin from "Reports/ProfitPercentageByOrigin";
import Sold from "Reports/Sold";
import SalesByLocation from "Reports/SalesByZip";
import SalesByOrigin from "Reports/SalesByOrigin";
import LossReasons from "Reports/LossReasons";
import AverageMedianAccountReceivables from "Reports/AverageMedianAccountReceivables";
import SoldToWorkOrder from "Reports/SoldToWorkOrder";
import CollectionsReport from "Reports/Collections";
import ProfitPercentage from "Reports/ProfitPercentage";
import Profit from "Reports/Profit";
import LeadsVsSales from "Reports/LeadsVsSales";
import CompanyProfitPercentage from "Reports/CompanyProfitPercentage";
import CompanyProfit from "Reports/CompanyProfit";
import SalesCumulative from "Reports/SalesCumulative";
import SalesCountCumulative from "Reports/SalesCountCumulative";
import SalesCount from "Reports/SalesCount";
import SoldLineChart from "Reports/SoldLineChart";
import SoldByScope from "Reports/SoldByScope";
import LeadsLineChart from "Reports/LeadsLineChart";
import LeadsCumulative from "Reports/LeadsCumulative";
import CompanyExpensesReport from "Reports/CompanyExpenses";
import CollectionsCumulative from "Reports/CollectionsCumulative";
import DrawBalances from "Reports/DrawBalances";
import Projections from "Reports/Projections";
import BlockBonus from "Reports/BlockBonus";
import ProfitDeviation from "Reports/ProfitDeviation";
import GrossCollectionBreakdown from "Reports/GrossCollectionBreakdown";
import SoldByPropertyType from "Reports/SoldByPropertyType";
import Expenses from "Screens/Expenses";
import Maps from "Screens/Maps";
import Version from "Screens/Version";
import CompanyExpenses from "Screens/CompanyExpenses";
import Users from "Screens/Users";
import DrawActivities from "Screens/DrawActivities";
import NeedsBrokedown from "Screens/NeedsBrokedown";
import CompletedRoofing from "Reports/CompletedRoofing";
import InstallLabor from "Reports/InstallLabor";
import ManageMenu from "Screens/ManageMenu";
import ChangePassword from "Screens/ChangePassword";
import ManageDumpsters from "Screens/ManageDumpsters";
import HoverRedirect from "Screens/HoverRedirect";

import MarketingPortal from "Screens/MarketingPortal";
import MarketingActivities from "Screens/MarketingActivities";
import Marketing from "Screens/Marketing";

import PhoneBook from "Screens/PhoneBook";
import SplashScreen from "Components/SplashScreen";
import ReportsDashboard from "Screens/ReportsDashboard";
import ReportsDashboardAdmin from "Screens/ReportsDashboardAdmin";
import ChrisReports from "Reports/ChrisReports";

import LeadScheduling from "Screens/LeadScheduling";
import AssignedLeads from "Screens/AssignedLeads";

import ZohoExports from "Screens/ZohoExports";

import PrivateRoute from "Components/PrivateRoute";

// const ServerError = lazy(() =>
//   import(/* webpackChunkName: "ServerError" */ "Screens/ServerError")
// );
// const Unauthorized = lazy(() =>
//   import(/* webpackChunkName: "Unauthorized" */ "Screens/Unauthorized")
// );
// const NoMatch = lazy(() =>
//   import(/* webpackChunkName: "NoMatch" */ "Screens/NoMatch")
// );
// const Signin = lazy(() =>
//   import(/* webpackChunkName: "Signin" */ "Screens/Signin")
// );
// const ForgotPassword = lazy(() =>
//   import(/* webpackChunkName: "ForgotPassword" */ "Screens/ForgotPassword")
// );
// const Dashboard = lazy(() =>
//   import(/* webpackChunkName: "Dashboard" */ "Screens/Dashboard")
// );
// const Leads = lazy(() =>
//   import(/* webpackChunkName: "Leads" */ "Screens/Leads")
// );
// const Projects = lazy(() =>
//   import(/* webpackChunkName: "Projects" */ "Screens/Projects")
// );
// const Notifications = lazy(() =>
//   import(/* webpackChunkName: "Notifications" */ "Screens/Notifications")
// );
// const Feed = lazy(() => import(/* webpackChunkName: "Feed" */ "Screens/Feed"));
// const AerialVerification = lazy(() =>
//   import(
//     /* webpackChunkName: "AerialVerification" */ "Screens/AerialVerification"
//   )
// );
// const MoldReview = lazy(() =>
//   import(/* webpackChunkName: "MoldReview" */ "Screens/MoldReview")
// );
// const Permits = lazy(() =>
//   import(/* webpackChunkName: "Permits" */ "Screens/Permits")
// );
// const RoofingOverview = lazy(() =>
//   import(/* webpackChunkName: "RoofingOverview" */ "Screens/RoofingOverview")
// );
// const PreJobCheckMedia = lazy(() =>
//   import(/* webpackChunkName: "PreJobCheckMedia" */ "Screens/PreJobCheckMedia")
// );
// const PreJobCheck = lazy(() =>
//   import(/* webpackChunkName: "PreJobCheck" */ "Screens/PreJobCheck")
// );
// const ReadyForMaterial = lazy(() =>
//   import(/* webpackChunkName: "ReadyForMaterial" */ "Screens/ReadyForMaterial")
// );
// const ReadyForDumpster = lazy(() =>
//   import(/* webpackChunkName: "ReadyForDumpster" */ "Screens/ReadyForDumpster")
// );
// const ReadyForSchedule = lazy(() =>
//   import(/* webpackChunkName: "ReadyForSchedule" */ "Screens/ReadyForSchedule")
// );
// const Schedule = lazy(() =>
//   import(/* webpackChunkName: "Schedule" */ "Screens/Schedule")
// );
// const Documents = lazy(() =>
//   import(/* webpackChunkName: "Documents" */ "Screens/Documents")
// );
// const PhotoAlbums = lazy(() =>
//   import(/* webpackChunkName: "PhotoAlbums" */ "Screens/PhotoAlbums")
// );
// const Scopes = lazy(() =>
//   import(/* webpackChunkName: "Scopes" */ "Screens/Scopes")
// );
// const Subs = lazy(() => import(/* webpackChunkName: "Subs" */ "Screens/Subs"));
// const Dumpsters = lazy(() =>
//   import(/* webpackChunkName: "Dumpsters" */ "Screens/Dumpsters")
// );
// const Suppliers = lazy(() =>
//   import(/* webpackChunkName: "Suppliers" */ "Screens/Suppliers")
// );
// const ManageDropdowns = lazy(() =>
//   import(/* webpackChunkName: "ManageDropdowns" */ "Screens/ManageDropdowns")
// );
// const ManageTieredDropdowns = lazy(() =>
//   import(
//     /* webpackChunkName: "ManageTieredDropdowns" */ "Screens/ManageTieredDropdowns"
//   )
// );
// const PostJobInspections = lazy(() =>
//   import(
//     /* webpackChunkName: "PostJobInspections" */ "Screens/PostJobInspections"
//   )
// );
// const PreJobInspections = lazy(() =>
//   import(
//     /* webpackChunkName: "PreJobInspections" */ "Screens/PreJobInspections"
//   )
// );
// const InstallTodos = lazy(() =>
//   import(/* webpackChunkName: "InstallTodos" */ "Screens/InstallTodos")
// );
// const AccountReceivables = lazy(() =>
//   import(
//     /* webpackChunkName: "AccountReceivables" */ "Screens/AccountReceivables"
//   )
// );
// const Breakdowns = lazy(() =>
//   import(/* webpackChunkName: "Breakdowns" */ "Screens/Breakdowns")
// );
// const Collections = lazy(() =>
//   import(/* webpackChunkName: "Collections" */ "Screens/Collections")
// );
// const AccountReceivablesReport = lazy(() =>
//   import(
//     /* webpackChunkName: "ReportsAccountReceivables" */ "Reports/AccountReceivables"
//   )
// );
// const RoofingProducts = lazy(() =>
//   import(
//     /* webpackChunkName: "ReportsRoofingProducts" */ "Reports/RoofingProducts"
//   )
// );
// const Pipeline = lazy(() =>
//   import(/* webpackChunkName: "ReportsPipeline" */ "Reports/Pipeline")
// );
// const PendingAccountReceivables = lazy(() =>
//   import(
//     /* webpackChunkName: "ReportsPendingAccountReceivables" */ "Reports/PendingAccountReceivables"
//   )
// );
// const DownPaymentSurplus = lazy(() =>
//   import(
//     /* webpackChunkName: "ReportsDownpaymentSurplus" */ "Reports/DownPaymentSurplus"
//   )
// );
// const LeadConversionRate = lazy(() =>
//   import(
//     /* webpackChunkName: "ReportsLeadConversionRate" */ "Reports/LeadConversionRate"
//   )
// );
// const LeadConversionRateByOrigin = lazy(() =>
//   import(
//     /* webpackChunkName: "ReportsLeadConversionRateByOrigin" */ "Reports/LeadConversionRateByOrigin"
//   )
// );
// const LeadsReport = lazy(() =>
//   import(/* webpackChunkName: "ReportsLeads" */ "Reports/Leads")
// );
// const LeadsByOrigin = lazy(() =>
//   import(/* webpackChunkName: "ReportsLeadsByOrigin" */ "Reports/LeadsByOrigin")
// );
// const LeadsVsSalesBySalesman = lazy(() =>
//   import(
//     /* webpackChunkName: "ReportsLeadsVsSalesBySalesman" */ "Reports/LeadsVsSalesBySalesman"
//   )
// );
// const ProfitPercentageByOrigin = lazy(() =>
//   import(
//     /* webpackChunkName: "ReportsProfitPercentageByOrigin" */ "Reports/ProfitPercentageByOrigin"
//   )
// );
// const Sold = lazy(() =>
//   import(/* webpackChunkName: "ReportsSold" */ "Reports/Sold")
// );
// const SalesByLocation = lazy(() =>
//   import(/* webpackChunkName: "ReportsSalesByZip" */ "Reports/SalesByZip")
// );
// const SalesByOrigin = lazy(() =>
//   import(/* webpackChunkName: "ReportsSalesByOrigin" */ "Reports/SalesByOrigin")
// );
// const LossReasons = lazy(() =>
//   import(/* webpackChunkName: "ReportsLossReasons" */ "Reports/LossReasons")
// );
// const AverageMedianAccountReceivables = lazy(() =>
//   import(
//     /* webpackChunkName: "ReportsAverageMedianAccountReceivables" */ "Reports/AverageMedianAccountReceivables"
//   )
// );
// const SoldToWorkOrder = lazy(() =>
//   import(
//     /* webpackChunkName: "ReportsSoldToWorkOrder" */ "Reports/SoldToWorkOrder"
//   )
// );
// const CollectionsReport = lazy(() =>
//   import(/* webpackChunkName: "ReportsCollections" */ "Reports/Collections")
// );
// const ProfitPercentage = lazy(() =>
//   import(
//     /* webpackChunkName: "ReportsProfitPercentage" */ "Reports/ProfitPercentage"
//   )
// );
// const Profit = lazy(() =>
//   import(/* webpackChunkName: "ReportsProfit" */ "Reports/Profit")
// );
// const LeadsVsSales = lazy(() =>
//   import(/* webpackChunkName: "ReportsLeadsVsSales" */ "Reports/LeadsVsSales")
// );
// const CompanyProfitPercentage = lazy(() =>
//   import(
//     /* webpackChunkName: "ReportsCompanyProfitPercentage" */ "Reports/CompanyProfitPercentage"
//   )
// );
// const CompanyProfit = lazy(() =>
//   import(/* webpackChunkName: "ReportsCompanyProfit" */ "Reports/CompanyProfit")
// );
// const SalesCumulative = lazy(() =>
//   import(
//     /* webpackChunkName: "ReportsSalesCumulative" */ "Reports/SalesCumulative"
//   )
// );
// const SalesCountCumulative = lazy(() =>
//   import(
//     /* webpackChunkName: "ReportsSalesCountCumulative" */ "Reports/SalesCountCumulative"
//   )
// );
// const SalesCount = lazy(() =>
//   import(/* webpackChunkName: "ReportsSalesCount" */ "Reports/SalesCount")
// );
// const SoldLineChart = lazy(() =>
//   import(/* webpackChunkName: "ReportsSoldLineChart" */ "Reports/SoldLineChart")
// );
// const LeadsLineChart = lazy(() =>
//   import(
//     /* webpackChunkName: "ReportsLeadsLineChart" */ "Reports/LeadsLineChart"
//   )
// );
// const LeadsCumulative = lazy(() =>
//   import(
//     /* webpackChunkName: "ReportsLeadsCumulative" */ "Reports/LeadsCumulative"
//   )
// );
// const CompanyExpensesReport = lazy(() =>
//   import(
//     /* webpackChunkName: "ReportsCompanyExpenses" */ "Reports/CompanyExpenses"
//   )
// );
// const CollectionsCumulative = lazy(() =>
//   import(
//     /* webpackChunkName: "ReportsCollectionsCumulative" */ "Reports/CollectionsCumulative"
//   )
// );
// const DrawBalances = lazy(() =>
//   import(/* webpackChunkName: "ReportsDrawBalances" */ "Reports/DrawBalances")
// );
// const Projections = lazy(() =>
//   import(/* webpackChunkName: "ReportsProjections" */ "Reports/Projections")
// );
// const BlockBonus = lazy(() =>
//   import(/* webpackChunkName: "ReportsBlockBonus" */ "Reports/BlockBonus")
// );
// const ProfitDeviation = lazy(() =>
//   import(
//     /* webpackChunkName: "ReportsProfitDeviation" */ "Reports/ProfitDeviation"
//   )
// );
// const GrossCollectionBreakdown = lazy(() =>
//   import(
//     /* webpackChunkName: "ReportsGrossCollectionBreakdown" */ "Reports/GrossCollectionBreakdown"
//   )
// );
// const SoldByPropertyType = lazy(() =>
//   import(
//     /* webpackChunkName: "ReportsSoldByPropertyType" */ "Reports/SoldByPropertyType"
//   )
// );
// const Expenses = lazy(() =>
//   import(/* webpackChunkName: "Expenses" */ "Screens/Expenses")
// );
// const Maps = lazy(() => import(/* webpackChunkName: "Maps" */ "Screens/Maps"));
// const Version = lazy(() =>
//   import(/* webpackChunkName: "Version" */ "Screens/Version")
// );
// const CompanyExpenses = lazy(() =>
//   import(/* webpackChunkName: "CompanyExpenses" */ "Screens/CompanyExpenses")
// );
// const Users = lazy(() =>
//   import(/* webpackChunkName: "Users" */ "Screens/Users")
// );
// const DrawActivities = lazy(() =>
//   import(/* webpackChunkName: "DrawActivities" */ "Screens/DrawActivities")
// );
// const NeedsBrokedown = lazy(() =>
//   import(/* webpackChunkName: "NeedsBrokedown" */ "Screens/NeedsBrokedown")
// );
// const CompletedRoofing = lazy(() =>
//   import(
//     /* webpackChunkName: "ReportsCompletedRoofing" */ "Reports/CompletedRoofing"
//   )
// );
// const ManageMenu = lazy(() =>
//   import(/* webpackChunkName: "ManageMenu" */ "Screens/ManageMenu")
// );
// const ChangePassword = lazy(() =>
//   import(/* webpackChunkName: "ChangePassword" */ "Screens/ChangePassword")
// );
// const ManageDumpsters = lazy(() =>
//   import(/* webpackChunkName: "ManageDumpsters" */ "Screens/ManageDumpsters")
// );

// const MarketingPortal = lazy(() =>
//   import(/* webpackChunkName: "MarketingPortal" */ "Screens/MarketingPortal")
// );
// const MarketingActivities = lazy(() =>
//   import(
//     /* webpackChunkName: "MarketingActivities" */ "Screens/MarketingActivities"
//   )
// );

const { useBreakpoint } = Grid;
const { Sider, Content } = Layout;

const offlineMessageKey = "offlineMessageKey";
const updateAvailableMessageKey = "updateAvailableMessageKey";
message.config({
  top: 2,
  maxCount: 1,
});

if (process.env.NODE_ENV === "production") {
  window.socket = io("https://api.gopremier.net", {
    transports: ["websocket"],
    forceNew: true,
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: 99999,
  });
} else {
  window.socket = io("http://localhost:5001", {
    transports: ["websocket"],
    forceNew: true,
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: 99999,
  });
}

// const PrivateRoute = () => {
//   // const Component = props.component;
//   const token = localStorage.getItem("storyToken");
//   // const { path } = props;
//   if (!token) {
//     return <Navigate to="/" />;
//   }
//   return <Outlet />;
//   // return (
//   //   <Route
//   //     exact
//   //     path={path}
//   //     render={() => {
//   //       if (!token) {
//   //         return <Navigate to={"/"} />;
//   //       }
//   //       return <Component {...props} />;
//   //     }}
//   //   />
//   // );
// };

const privateRoutes = [
  "dashboard",
  "notifications",
  "feed",
  "projects",
  "leads",
  "scopes",
  "account-receivables",
  "photo-albums",
  "documents",
  "breakdowns",
  "maps",
  "dumping",
  "squares-needed",
  "reports",
  "admin-reports",
  "office",
  "admin",
  "material-quote",
  "phone-book",
  "collections",
  "expenses",
  "dropdown-management",
  "tiered-dropdown-management",
  "permits",
  "permit-search",
  "warranties",
  "zoho-exports",
  "users",
  "needs-brokedown",
  "draw-activities",
  "company-expenses",
  "company-expenses-report",
  "sub-management",
  "aerial-verification",
  "on-site-check",
  "workflow",
  "mold-review",
  "overview",
  "office-review",
  "tag-creation",
  "ready-for-material",
  "material-board",
  "ready-for-dumpster",
  "ready-for-schedule",
  "schedule",
  "install",
  "dumpster-management",
  "supplier-management",
  "todos",
  "pre-job-inspections",
  "post-job-inspections",
  "reports-dashboard",
  "reports-dashboard-admin",
  "account-receivables-report",
  "leads-report",
  "pipeline",
  "sold",
  "leads-line-chart",
  "leads-by-origin",
  "leads-count-cumulative",
  "lead-conversion-rate",
  "lead-conversion-rate-by-origin",
  "leads-vs-estimates-vs-sales",
  "leads-vs-sales-by-salesman",
  "sold-line-chart",
  "sold-by-property-type",
  "sales-by-location",
  "sales-by-campaign",
  "sales-count",
  "sales-count-cumulative",
  "sales-value-cumulative",
  "sold-by-scope",
  "account-receivables-pending",
  "account-receivables-average-vs-median",
  "collections-report",
  "collections-cumulative",
  "gross-collection-breakdown",
  "company-profit",
  "company-profit-percentage",
  "profit",
  "profit-percentage",
  "profit-percentage-by-origin",
  "profit-deviation",
  "roofing-products",
  "block-bonus",
  "company-expenses",
  "down-payment-surplus",
  "draw-balances",
  "loss-reasons",
  "projections",
  "sold-to-work-order",
  "completed-roofs",
  "company-posts",
  "version",
  "settings",
  "menu-management",
  "change-password",
  "manage-dumpsters",
  "marketing-portal",
  "marketing-activities",
  "marketing",
  "install-labor",
  "lead-scheduling",
  "chris-reports",
  "assigned-leads",
  "leads-by-campaign",
];

const subs = ["office", "admin", "workflow", "install", "reports", "settings"];

const App = () => {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.settingsState.theme);
  const collapsed = useSelector((state) => state.settingsState.collapsed);
  const menuOpen = useSelector((state) => state.settingsState.menuOpen);
  const activeRoute = useSelector((state) => state.settingsState.activeRoute);
  const showImageViewer = useSelector(
    (state) => state.dataState.showImageViewer
  );
  const serviceWorkerUpdated = useSelector(
    (state) => state.swState.serviceWorkerUpdated
  );
  const serviceWorkerRegistration = useSelector(
    (state) => state.swState.serviceWorkerRegistration
  );
  const swUpdating = useSelector((state) => state.swState.swUpdating);
  const { status } = useApiErrors();
  const _isMounted = useIsMounted();
  const location = useLocation();
  const navigate = useNavigate();
  let base = location.pathname.split("/")[1];
  let sub = location.pathname.split("/")[2];
  const prevBase = usePrevious(base);
  const prevSub = usePrevious(sub);
  const screens = useBreakpoint();

  const [online, setOnline] = useState(true);
  const [installVisible, setInstallVisible] = useState(false);
  const [addToHomeScreen, setAddToHomeScreen] = useState(false);
  const [prompt, setPrompt] = useState(null);
  const [prompted, setPrompted] = useState(
    localStorage.getItem("isPrompted") === "true" ? true : false
  );
  const [notificationsPrompted, setNotificationsPrompted] = useState(
    localStorage.getItem("notificationsPrompted") === "true" ? true : false
  );
  const [notificationVisible, setNotificationVisible] = useState(false);
  const [checkNotifications, setCheckNotifications] = useState(false);

  const { current } = _isMounted;
  let privateRoute = false;
  if (privateRoutes.includes(activeRoute)) {
    privateRoute = true;
  }

  const prevCurrent = usePrevious(current);
  const prevPrivateRoute = usePrevious(privateRoute);

  const swCheck = useCallback(() => {
    if (
      navigator &&
      navigator.serviceWorker &&
      navigator.serviceWorker.getRegistration
    ) {
      navigator.serviceWorker.getRegistrations().then((regs) => {
        regs.forEach((reg) => {
          function awaitStateChange() {
            reg.installing.addEventListener("statechange", function () {
              if (this.state === "installed")
                dispatch({ type: actions.SW_UPDATE, payload: reg });
            });
          }

          if (!reg) return;
          if (reg.waiting)
            return dispatch({ type: actions.SW_UPDATE, payload: reg });
          if (reg.installing) awaitStateChange();

          reg.update({
            onSuccess: () => dispatch({ type: actions.SW_INIT }),
            onUpdate: (reg) =>
              dispatch({ type: actions.SW_UPDATE, payload: reg }),
          });
        });
      });
    }
  }, [dispatch]);

  useEffect(() => {
    const token = localStorage.getItem("storyToken");
    const prevValues = prevCurrent && prevPrivateRoute ? true : false;
    if (!prevValues && current && privateRoute && token) {
      serviceWorker.register({
        onSuccess: () => dispatch({ type: actions.SW_INIT }),
        onUpdate: (reg) => dispatch({ type: actions.SW_UPDATE, payload: reg }),
      });

      window.socket.on("story-update", () => swCheck);
    }

    return () => {
      window.socket.removeAllListeners("story-update");
    };
  }, [current, prevCurrent, privateRoute, prevPrivateRoute, dispatch, swCheck]);

  const callback = useCallback(() => {
    setNotificationsPrompted(true);
  }, []);

  useEffect(() => {
    if (navigator && navigator.serviceWorker) {
      var refreshing;
      navigator.serviceWorker.addEventListener("controllerchange", function () {
        if (refreshing) return;
        refreshing = true;
        window.location.reload();
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (navigator && navigator.serviceWorker) {
      // history.listen((location, action) => {
      // check for sw updates on page change
      navigator.serviceWorker.getRegistrations().then((regs) => {
        regs.forEach((reg) => {
          function awaitStateChange() {
            reg.installing.addEventListener("statechange", function () {
              if (this.state === "installed")
                dispatch({ type: actions.SW_UPDATE, payload: reg });
            });
          }

          if (!reg) return;
          if (reg.waiting)
            return dispatch({ type: actions.SW_UPDATE, payload: reg });
          if (reg.installing) awaitStateChange();

          reg.update({
            onSuccess: () => dispatch({ type: actions.SW_INIT }),
            onUpdate: (reg) =>
              dispatch({ type: actions.SW_UPDATE, payload: reg }),
          });
        });
      });
      // });
    }
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  const userAgent = window.navigator.userAgent.toLowerCase();
  let isIos = /iphone|ipad|ipod/.test(userAgent);
  let isInStandaloneMode = false;
  if (window.matchMedia("(display-mode: standalone)").matches) {
    isInStandaloneMode = true;
  }

  // const isMobile = () => {
  //   // if (window.navigator.userAgent.match(/Mobi/)) {
  //   if (/Mobi|Android/i.test(window.navigator.userAgent)) {
  //     return true;
  //   }
  //   // used to be 1366
  //   // if ("screen" in window && window.screen.width < 1366) {
  //   //   return true;
  //   // }
  //   var dv = document.getElementById("divscr");
  //   if (dv.offsetWidth - dv.clientWidth < 10) {
  //     return true;
  //   }

  //   const connection =
  //     window.navigator.connection ||
  //     window.navigator.mozConnection ||
  //     window.navigator.webkitConnection;
  //   if (connection && connection.type === "cellular") {
  //     return true;
  //   }
  //   return false;
  // };

  useEffect(() => {
    if (serviceWorkerUpdated) updateAvailableMessage();
  }, [serviceWorkerUpdated]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateAvailableMessage = () => {
    // message.open({
    //   content: (
    //     <div>
    //       A new update is availalbe!{"   "}
    //       <Button
    //         size="small"
    //         type="text"
    //         onClick={updateButton}
    //         style={{ float: "right", color: "#1890ff" }}
    //       >
    //         Update Now
    //       </Button>
    //     </div>
    //   ),
    //   duration: 0,
    //   icon: false,
    //   key: updateAvailableMessageKey,
    // });

    notification.open({
      message: "Update Available",
      description: "All tabs need to be closed for this update to take effect!",
      btn: (
        <Button
          size="small"
          type="text"
          onClick={updateButton}
          style={{ float: "right", color: "#1890ff" }}
        >
          Update Now
        </Button>
      ),
      closeIcon: <></>,
      duration: 0,
      icon: false,
      key: updateAvailableMessageKey,
    });
  };

  const updateButton = () => {
    const registrationWaiting = serviceWorkerRegistration
      ? serviceWorkerRegistration.waiting
      : undefined;
    dispatch(updateSw());
    // message.destroy(updateAvailableMessageKey);
    notification.close(updateAvailableMessageKey);
    if (registrationWaiting && !isIos) {
      registrationWaiting.postMessage({ type: "SKIP_WAITING" });
      registrationWaiting.addEventListener("statechange", (e) => {
        if (e.target.state === "activated") {
          window.location.reload();
        }
      });
    } else {
      serviceWorker.unregister();
    }
  };

  useEffect(() => {
    if (status !== null && status !== 200) {
      if (status === 401 || status === 500) {
        navigate(`/${status}`);
      } else if (status === 422 && privateRoute) {
        message.error("Error 422");
      } else if (status === 429 && privateRoute) {
        message.error("Error 429");
      }
    }
  }, [status]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (current) {
      setTimeout(() => {
        document.body.className = document.body.className.replace(
          "cancel-animations",
          ""
        );
      }, 500);
    }
  }, [current]);

  useEffect(() => {
    if (current && isIos && !isInStandaloneMode && !prompted) {
      setInstallVisible(true);
      setPrompted(true);
    } else if (current && privateRoute && prompt && !isIos && !prompted) {
      setAddToHomeScreen(true);
      setPrompted(true);
    }
  }, [current, isInStandaloneMode, isIos, prompted, privateRoute, prompt]);

  useEffect(() => {
    if (current && checkNotifications && privateRoute) {
      if (
        !notificationsPrompted &&
        "showNotification" in ServiceWorkerRegistration.prototype &&
        "PushManager" in window
      ) {
        setNotificationVisible(true);
      }
    }
  }, [notificationsPrompted, checkNotifications, current, privateRoute]);

  // useEffect(() => {
  //   if (current) {
  //     if (window.socket) {

  //     }
  //   }
  // }, [current, dispatch]);

  const openOfflineMessage = () => {
    setOnline(false);
  };

  const closeOfflineMessage = () => {
    setOnline(true);
  };

  const handleInstallPrompt = (e) => {
    e.preventDefault();
    if (e.prompt && !prompted) {
      setPrompt(e);
    }
  };

  const handleAppInstalled = () => {
    setPrompt(undefined);
    setAddToHomeScreen(false);
    setPrompted(true);
    setCheckNotifications(true);
  };

  useEffect(() => {
    const isMobile = () => {
      // if (window.navigator.userAgent.match(/Mobi/)) {
      if (/Mobi|Android/i.test(window.navigator.userAgent)) {
        return true;
      }
      // used to be 1366
      if ("screen" in window && window.screen.width < 1366) {
        return true;
      }

      // if (window && window.innerWidth < 1366) {
      //   return true;
      // }

      // var dv = document.getElementById("divscr");
      // if (dv.offsetWidth - dv.clientWidth < 10) {
      //   return true;
      // }

      const connection =
        window.navigator.connection ||
        window.navigator.mozConnection ||
        window.navigator.webkitConnection;
      if (connection && connection.type === "cellular") {
        return true;
      }
      return false;
    };

    if (isMobile()) {
      document.body.classList.add("mobile");
      document.body.classList.add("mobileForms");
    } else {
      document.body.classList.remove("mobile");
      document.body.classList.remove("mobileForms");
    }

    window.addEventListener("online", closeOfflineMessage);
    window.addEventListener("offline", openOfflineMessage);
    window.addEventListener("beforeinstallprompt", handleInstallPrompt);
    window.addEventListener("appinstalled", handleAppInstalled);

    return () => {
      window.removeEventListener("online", closeOfflineMessage);
      window.removeEventListener("offline", openOfflineMessage);
      window.removeEventListener("beforeinstallprompt", handleInstallPrompt);
      window.removeEventListener("appinstalled", handleAppInstalled);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!online) {
      offline();
    } else {
      _online();
    }
  }, [online]);

  useEffect(() => {
    if (prevBase !== base || prevSub !== sub) {
      const token = localStorage.getItem("storyToken");
      if (token) {
        dispatch(init(token));
      }

      if (subs.includes(base)) {
        dispatch(setActiveRoute(sub));
      } else {
        dispatch(setActiveRoute(base));
      }
    }
  }, [base, prevBase, sub, prevBase]); // eslint-disable-line react-hooks/exhaustive-deps

  const closeMenu = () => {
    dispatch(showMenu(false));
  };

  const offline = () => {
    message.error({
      content: "Currently Offline",
      duration: 0,
      key: offlineMessageKey,
    });
  };

  const _online = () => {
    message.destroy(offlineMessageKey);
  };

  const closeIosInstallWindow = () => {
    localStorage.setItem("isPrompted", "true");
    setPrompted(true);
    setInstallVisible(false);
    setCheckNotifications(true);
  };

  if (!_isMounted.current) {
    return <SplashScreen />;
  }

  return (
    <>
      {swUpdating && <SplashScreen />}
      <Layout style={{ height: "100%", width: "100%" }}>
        {privateRoute ? (
          screens.lg ? (
            <Sider
              trigger={null}
              collapsible
              collapsed={collapsed}
              theme={theme}
              width={240}
              className="z-depth-1"
            >
              <Navigation sider />
            </Sider>
          ) : (
            <Drawer
              className={`${theme} menu`}
              visible={menuOpen}
              onClose={closeMenu}
              bodyStyle={{ padding: 0, height: "100%" }}
              placement="left"
              width={!screens.xs ? 320 : "100%"}
            >
              <Navigation />
            </Drawer>
          )
        ) : null}
        <Layout
          style={{
            height: "100%",
            width: "100%",
            overflow: "hidden",
            background: "rgb(240, 242, 245)",
          }}
        >
          {privateRoute && <Header />}
          <Content style={{ height: "100%", width: "100%" }}>
            {/* <ErrorBoundary FallbackComponent={ErrorFallback}> */}
            <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
              <Suspense fallback={<Loader minHeight="100vh" width="100%" />}>
                <Routes>
                  <Route exact path="/" element={<Signin />} />
                  <Route
                    exact
                    path="/forgot-password"
                    element={<ForgotPassword />}
                  />
                  <Route
                    exact
                    element={<PasswordReset />}
                    path="/password-reset/:_userId/:_randoString"
                  />
                  <Route
                    exact
                    element={<BullpinDashboard />}
                    path="/bullpin-dashboard"
                  />

                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <PhoneBook />
                      </PrivateRoute>
                    }
                    path="/phone-book"
                  />

                  {/* DASHBOARD */}
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <Dashboard />
                      </PrivateRoute>
                    }
                    path="/dashboard"
                  >
                    <Route
                      exact
                      element={<Outlet />}
                      path="images/:id/:_activityId/:index"
                    />
                    <Route exact element={<Outlet />} path="material-quote" />
                    <Route exact element={<Outlet />} path="phone-book" />
                    <Route exact element={<Outlet />} path="requests" />
                    <Route
                      exact
                      element={<Outlet />}
                      path="leads/view/:tab/:id"
                    >
                      <Route
                        exact
                        element={<Outlet />}
                        path="images/:_activityId/:index"
                      />
                      <Route
                        exact
                        element={<Outlet />}
                        path="legacy-files/:index"
                      />
                      <Route exact element={<Outlet />} path="media" />
                    </Route>
                    <Route
                      exact
                      element={<Outlet />}
                      path="projects/view/:tab/:id"
                    >
                      <Route
                        exact
                        element={<Outlet />}
                        path="images/:_activityId/:index"
                      />
                      <Route
                        exact
                        element={<Outlet />}
                        path="legacy-files/:index"
                      />
                      <Route exact element={<Outlet />} path="media" />
                    </Route>
                  </Route>

                  {/* NOTIFICATIONS */}
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <Notifications />
                      </PrivateRoute>
                    }
                    path="/notifications"
                  >
                    <Route
                      exact
                      element={
                        <PrivateRoute>
                          <Notifications />
                        </PrivateRoute>
                      }
                      path="leads/view/:tab/:id"
                    >
                      <Route
                        exact
                        element={<Outlet />}
                        path="images/:_activityId/:index"
                      />
                      <Route
                        exact
                        element={<Outlet />}
                        path="legacy-files/:index"
                      />
                      <Route exact element={<Outlet />} path="media" />
                    </Route>
                    <Route
                      exact
                      element={<Outlet />}
                      path="projects/view/:tab/:id"
                    >
                      <Route
                        exact
                        element={<Outlet />}
                        path="images/:_activityId/:index"
                      />
                      <Route
                        exact
                        element={<Outlet />}
                        path="legacy-files/:index"
                      />
                      <Route exact element={<Outlet />} path="media" />
                    </Route>
                  </Route>

                  {/* FEED */}
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <Feed />
                      </PrivateRoute>
                    }
                    path="/feed"
                  >
                    <Route
                      exact
                      element={<Outlet />}
                      path="leads/view/:tab/:id"
                    >
                      <Route
                        exact
                        element={<Outlet />}
                        path="images/:_activityId/:index"
                      />
                      <Route
                        exact
                        element={<Outlet />}
                        path="legacy-files/:index"
                      />
                      <Route exact element={<Outlet />} path="media" />
                    </Route>
                    <Route
                      exact
                      element={<Outlet />}
                      path="projects/view/:tab/:id"
                    >
                      <Route
                        exact
                        element={<Outlet />}
                        path="images/:_activityId/:index"
                      />
                      <Route
                        exact
                        element={<Outlet />}
                        path="legacy-files/:index"
                      />
                      <Route exact element={<Outlet />} path="media" />
                    </Route>
                  </Route>

                  {/* ROOF SCHEDULE */}
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <Schedule />
                      </PrivateRoute>
                    }
                    path="/schedule"
                  >
                    <Route
                      exact
                      element={<Outlet />}
                      path="leads/view/:tab/:id"
                    >
                      <Route
                        exact
                        element={<Outlet />}
                        path="images/:_activityId/:index"
                      />
                      <Route exact element={<Outlet />} path="media" />
                    </Route>
                    <Route
                      exact
                      element={<Outlet />}
                      path="projects/view/:tab/:id"
                    >
                      <Route
                        exact
                        element={<Outlet />}
                        path="images/:_activityId/:index"
                      />
                      <Route exact element={<Outlet />} path="media" />
                    </Route>
                  </Route>

                  {/* LEADS */}
                  {/* <Route
                    exact
                    element={
                      <PrivateRoute>
                        <Leads />
                      </PrivateRoute>
                    }
                    path="/leads/view/:tab/:id/images/:_activityId/:index"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <Leads />
                      </PrivateRoute>
                    }
                    path="/leads/view/:tab/:id/media"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <Leads />
                      </PrivateRoute>
                    }
                    path="/leads/view/:tab/:id/legacy-files/:index"
                  /> */}
                  {/* <Route
                    exact
                    element={
                      <PrivateRoute>
                        <Leads />
                      </PrivateRoute>
                    }
                    path="/leads/view/:tab/:id"
                  /> */}
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <Leads />
                      </PrivateRoute>
                    }
                    path="/leads"
                  >
                    <Route exact element={<Outlet />} path="view/:tab/:id">
                      <Route
                        exact
                        element={<Outlet />}
                        path="images/:_activityId/:index"
                      />
                      <Route
                        exact
                        element={<Outlet />}
                        path="legacy-files/:index"
                      />
                      <Route exact element={<Outlet />} path="media" />
                    </Route>
                  </Route>

                  {/* PROJECTS */}
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <Projects />
                      </PrivateRoute>
                    }
                    path="/projects"
                  >
                    <Route exact element={<Outlet />} path="view/:tab/:id">
                      <Route
                        exact
                        element={<Outlet />}
                        path="images/:_activityId/:index"
                      />

                      <Route
                        exact
                        element={<Outlet />}
                        path="legacy-files/:index"
                      />
                      <Route exact element={<Outlet />} path="media" />
                    </Route>
                  </Route>

                  {/* SCOPES */}
                  {/* <Route
                    exact
                    element={
                      <PrivateRoute>
                        <Scopes />
                      </PrivateRoute>
                    }
                    path="/scopes/projects/view/:tab/:id/images/:_activityId/:index"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <Scopes />
                      </PrivateRoute>
                    }
                    path="/scopes/projects/view/:tab/:id/media"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <Scopes />
                      </PrivateRoute>
                    }
                    path="/scopes/projects/view/:tab/:id/legacy-files/:index"
                  /> */}
                  {/* <Route
                    exact
                    element={
                      <PrivateRoute>
                        <Scopes />
                      </PrivateRoute>
                    }
                    path="/scopes/projects/view/:tab/:id"
                  /> */}
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <Scopes />
                      </PrivateRoute>
                    }
                    path="/scopes"
                  >
                    <Route
                      exact
                      element={<Outlet />}
                      path="projects/view/:tab/:id"
                    >
                      <Route
                        exact
                        element={<Outlet />}
                        path="images/:_activityId/:index"
                      />
                      <Route
                        exact
                        element={<Outlet />}
                        path="legacy-files/:index"
                      />
                      <Route exact element={<Outlet />} path="media" />
                    </Route>
                  </Route>

                  {/* ACCOUNT RECEIVABLES */}
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <AccountReceivables />
                      </PrivateRoute>
                    }
                    path="/account-receivables"
                  >
                    <Route
                      exact
                      element={<Outlet />}
                      path="projects/view/:tab/:id"
                    >
                      <Route
                        exact
                        element={<Outlet />}
                        path="images/:_activityId/:index"
                      />
                      <Route
                        exact
                        element={<Outlet />}
                        path="legacy-files/:index"
                      />
                      <Route exact element={<Outlet />} path="media" />
                    </Route>
                  </Route>

                  {/* PHOTO ALBUMS */}
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <PhotoAlbums />
                      </PrivateRoute>
                    }
                    path="/photo-albums"
                  />

                  {/* DOCUMENTS */}
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <Documents />
                      </PrivateRoute>
                    }
                    path="/documents"
                  />

                  {/* BREAKDOWNS */}
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <Breakdowns />
                      </PrivateRoute>
                    }
                    path="/breakdowns"
                  />

                  {/* MAPS */}
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <Maps />
                      </PrivateRoute>
                    }
                    path="/maps"
                  />

                  {/* MARKETING PORTAL */}
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <MarketingPortal />
                      </PrivateRoute>
                    }
                    path="/marketing-portal"
                  />

                  {/* ROOFING PROCESS */}
                  {/* ROOFING OVERVIEW */}
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <RoofingOverview />
                      </PrivateRoute>
                    }
                    path="/workflow/overview"
                  />

                  {/* AERIAL VERIFICATION */}
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <AerialVerification />
                      </PrivateRoute>
                    }
                    path="/workflow/aerial-verification"
                  />

                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <OnSiteCheck />
                      </PrivateRoute>
                    }
                    path="/workflow/on-site-check"
                  />

                  {/* MOLD REVIEW */}
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <MoldReview />
                      </PrivateRoute>
                    }
                    path="/workflow/mold-review"
                  />

                  {/* OFFICE REVIEW */}
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <PreJobCheck />
                      </PrivateRoute>
                    }
                    path="/workflow/office-review"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <PreJobCheckMedia />
                      </PrivateRoute>
                    }
                    path="/workflow/office-review-media/:id"
                  />

                  {/* READY FOR MATERIAL */}
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <ReadyForMaterial />
                      </PrivateRoute>
                    }
                    path="/workflow/ready-for-material"
                  >
                    <Route
                      exact
                      element={<Outlet />}
                      path="projects/view/:tab/:id"
                    >
                      <Route
                        exact
                        element={<Outlet />}
                        path="images/:_activityId/:index"
                      />
                      <Route
                        exact
                        element={<Outlet />}
                        path="legacy-files/:index"
                      />
                      <Route exact element={<Outlet />} path="media" />
                    </Route>
                  </Route>

                  {/* READY FOR DUMPSTER */}
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <ReadyForDumpster />
                      </PrivateRoute>
                    }
                    path="/workflow/ready-for-dumpster"
                  >
                    <Route
                      exact
                      element={<Outlet />}
                      path="projects/view/:tab/:id"
                    >
                      <Route
                        exact
                        element={<Outlet />}
                        path="images/:_activityId/:index"
                      />

                      <Route
                        exact
                        element={<Outlet />}
                        path="legacy-files/:index"
                      />
                      <Route exact element={<Outlet />} path="media" />
                    </Route>
                  </Route>

                  {/* READY FOR SCHEDULE */}
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <ReadyForSchedule />
                      </PrivateRoute>
                    }
                    path="/workflow/ready-for-schedule"
                  >
                    <Route
                      exact
                      element={<Outlet />}
                      path="projects/view/:tab/:id"
                    >
                      <Route
                        exact
                        element={<Outlet />}
                        path="images/:_activityId/:index"
                      />
                      <Route
                        exact
                        element={<Outlet />}
                        path="legacy-files/:index"
                      />
                      <Route exact element={<Outlet />} path="media" />
                    </Route>
                  </Route>

                  {/* REPORTS */}
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <ReportsDashboard />
                      </PrivateRoute>
                    }
                    path="/reports/reports-dashboard"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <ReportsDashboardAdmin />
                      </PrivateRoute>
                    }
                    path="/reports/reports-dashboard-admin/projects/view/:tab/:id/images/:_activityId/:index"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <ReportsDashboardAdmin />
                      </PrivateRoute>
                    }
                    path="/reports/reports-dashboard-admin/projects/view/:tab/:id/media"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <ReportsDashboardAdmin />
                      </PrivateRoute>
                    }
                    path="/reports/reports-dashboard-admin/projects/view/:tab/:id/legacy-files/:index"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <ReportsDashboardAdmin />
                      </PrivateRoute>
                    }
                    path="/reports/reports-dashboard-admin/projects/view/:tab/:id"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <ReportsDashboardAdmin />
                      </PrivateRoute>
                    }
                    path="/reports/reports-dashboard-admin/leads/view/:tab/:id/images/:_activityId/:index"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <ReportsDashboardAdmin />
                      </PrivateRoute>
                    }
                    path="/reports/reports-dashboard-admin/leads/view/:tab/:id/media"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <ReportsDashboardAdmin />
                      </PrivateRoute>
                    }
                    path="/reports/reports-dashboard-admin/leads/view/:tab/:id/legacy-files/:index"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <ReportsDashboardAdmin />
                      </PrivateRoute>
                    }
                    path="/reports/reports-dashboard-admin/leads/view/:tab/:id"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <ReportsDashboardAdmin />
                      </PrivateRoute>
                    }
                    path="/reports/reports-dashboard-admin"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <ChrisReports />
                      </PrivateRoute>
                    }
                    path="/reports/chris-reports"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <AssignedLeads />
                      </PrivateRoute>
                    }
                    path="/office/assigned-leads"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <AccountReceivablesReport />
                      </PrivateRoute>
                    }
                    path="/reports/account-receivables-report"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <PendingAccountReceivables />
                      </PrivateRoute>
                    }
                    path="/reports/account-receivables-pending"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <RoofingProducts />
                      </PrivateRoute>
                    }
                    path="/reports/roofing-products"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <DownPaymentSurplus />
                      </PrivateRoute>
                    }
                    path="/reports/down-payment-surplus"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <Pipeline />
                      </PrivateRoute>
                    }
                    path="/reports/pipeline"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <LeadConversionRate />
                      </PrivateRoute>
                    }
                    path="/reports/lead-conversion-rate"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <LeadsByOrigin />
                      </PrivateRoute>
                    }
                    path="/reports/leads-by-origin"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <LeadsByCampaign />
                      </PrivateRoute>
                    }
                    path="/reports/leads-by-campaign"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <LeadConversionRateByOrigin />
                      </PrivateRoute>
                    }
                    path="/reports/lead-conversion-rate-by-origin"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <ProfitPercentageByOrigin />
                      </PrivateRoute>
                    }
                    path="/reports/profit-percentage-by-origin"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <LeadsVsSalesBySalesman />
                      </PrivateRoute>
                    }
                    path="/reports/leads-vs-sales-by-salesman"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <LeadsReport />
                      </PrivateRoute>
                    }
                    path="/reports/leads-report"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <Sold />
                      </PrivateRoute>
                    }
                    path="/reports/sold"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <SoldToWorkOrder />
                      </PrivateRoute>
                    }
                    path="/reports/sold-to-work-order"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <AverageMedianAccountReceivables />
                      </PrivateRoute>
                    }
                    path="/reports/account-receivables-average-vs-median"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <LossReasons />
                      </PrivateRoute>
                    }
                    path="/reports/loss-reasons"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <SalesByLocation />
                      </PrivateRoute>
                    }
                    path="/reports/sales-by-location"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <SalesByOrigin />
                      </PrivateRoute>
                    }
                    path="/reports/sales-by-campaign"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <CollectionsReport />
                      </PrivateRoute>
                    }
                    path="/reports/collections-report"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <ProfitPercentage />
                      </PrivateRoute>
                    }
                    path="/reports/profit-percentage"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <Profit />
                      </PrivateRoute>
                    }
                    path="/reports/profit"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <LeadsVsSales />
                      </PrivateRoute>
                    }
                    path="/reports/leads-vs-estimates-vs-sales"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <CompanyProfitPercentage />
                      </PrivateRoute>
                    }
                    path="/reports/company-profit-percentage"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <CompanyProfit />
                      </PrivateRoute>
                    }
                    path="/reports/company-profit"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <SalesCumulative />
                      </PrivateRoute>
                    }
                    path="/reports/sales-value-cumulative"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <SalesCountCumulative />
                      </PrivateRoute>
                    }
                    path="/reports/sales-count-cumulative"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <SalesCount />
                      </PrivateRoute>
                    }
                    path="/reports/sales-count"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <SoldLineChart />
                      </PrivateRoute>
                    }
                    path="/reports/sold-line-chart"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <SoldByScope />
                      </PrivateRoute>
                    }
                    path="/reports/sold-by-scope"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <LeadsLineChart />
                      </PrivateRoute>
                    }
                    path="/reports/leads-line-chart"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <LeadsCumulative />
                      </PrivateRoute>
                    }
                    path="/reports/leads-count-cumulative"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <CompanyExpensesReport />
                      </PrivateRoute>
                    }
                    path="/reports/company-expenses-report"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <CollectionsCumulative />
                      </PrivateRoute>
                    }
                    path="/reports/collections-cumulative"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <DrawBalances />
                      </PrivateRoute>
                    }
                    path="/reports/draw-balances"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <Projections />
                      </PrivateRoute>
                    }
                    path="/reports/projections"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <BlockBonus />
                      </PrivateRoute>
                    }
                    path="/reports/block-bonus"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <ProfitDeviation />
                      </PrivateRoute>
                    }
                    path="/reports/profit-deviation"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <GrossCollectionBreakdown />
                      </PrivateRoute>
                    }
                    path="/reports/gross-collection-breakdown"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <SoldByPropertyType />
                      </PrivateRoute>
                    }
                    path="/reports/sold-by-property-type"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <CompletedRoofing />
                      </PrivateRoute>
                    }
                    path="/reports/completed-roofs"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <InstallLabor />
                      </PrivateRoute>
                    }
                    path="/reports/install-labor"
                  />

                  {/* OFFICE */}
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <Collections />
                      </PrivateRoute>
                    }
                    path="/office/collections"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <Expenses />
                      </PrivateRoute>
                    }
                    path="/office/expenses"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <Permits />
                      </PrivateRoute>
                    }
                    path="/office/permits"
                  >
                    <Route exact element={<Outlet />} path="verification/:id" />
                  </Route>
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <Marketing />
                      </PrivateRoute>
                    }
                    path="/office/marketing"
                  />

                  {/* LEAD SCHEDULING/MANAGEMENT */}
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <LeadScheduling />
                      </PrivateRoute>
                    }
                    path="/office/lead-scheduling"
                  >
                    <Route
                      exact
                      element={<Outlet />}
                      path="leads/view/:tab/:id"
                    >
                      <Route
                        exact
                        element={<Outlet />}
                        path="images/:_activityId/:index"
                      />
                      <Route
                        exact
                        element={<Outlet />}
                        path="legacy-files/:index"
                      />
                      <Route exact element={<Outlet />} path="media" />
                    </Route>
                  </Route>

                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <ManageDropdowns />
                      </PrivateRoute>
                    }
                    path="/office/dropdown-management"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <ManageTieredDropdowns />
                      </PrivateRoute>
                    }
                    path="/office/tiered-dropdown-management"
                  />

                  {/* INSTALL */}
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <Subs />
                      </PrivateRoute>
                    }
                    path="/install/sub-management"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <Dumpsters />
                      </PrivateRoute>
                    }
                    path="/install/dumpster-management"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <Suppliers />
                      </PrivateRoute>
                    }
                    path="/install/supplier-management"
                  />

                  {/* PRE JOB INSPECTIONS */}
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <PreJobInspections />
                      </PrivateRoute>
                    }
                    path="/install/pre-job-inspections"
                  >
                    <Route
                      exact
                      element={<Outlet />}
                      path="projects/view/:tab/:id"
                    >
                      <Route
                        exact
                        element={<Outlet />}
                        path="images/:_activityId/:index"
                      />
                      <Route
                        exact
                        element={<Outlet />}
                        path="legacy-files/:index"
                      />
                      <Route exact element={<Outlet />} path="media" />
                    </Route>
                  </Route>

                  {/* POST JOB INSPECTIONS */}
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <PostJobInspections />
                      </PrivateRoute>
                    }
                    path="/install/post-job-inspections"
                  >
                    <Route
                      exact
                      element={<Outlet />}
                      path="projects/view/:tab/:id"
                    >
                      <Route
                        exact
                        element={<Outlet />}
                        path="images/:_activityId/:index"
                      />
                      <Route
                        exact
                        element={<Outlet />}
                        path="legacy-files/:index"
                      />
                      <Route exact element={<Outlet />} path="media" />
                    </Route>
                  </Route>

                  {/* INSTALL TO-DOS */}
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <InstallTodos />
                      </PrivateRoute>
                    }
                    path="/install/todos"
                  >
                    <Route
                      exact
                      element={<Outlet />}
                      path="projects/view/:tab/:id"
                    >
                      <Route
                        exact
                        element={<Outlet />}
                        path="images/:_activityId/:index"
                      />
                      <Route
                        exact
                        element={<Outlet />}
                        path="legacy-files/:index"
                      />
                      <Route exact element={<Outlet />} path="media" />
                    </Route>
                    <Route
                      exact
                      element={<Outlet />}
                      path="leads/view/:tab/:id"
                    >
                      <Route
                        exact
                        element={<Outlet />}
                        path="images/:_activityId/:index"
                      />
                      <Route
                        exact
                        element={<Outlet />}
                        path="legacy-files/:index"
                      />
                      <Route exact element={<Outlet />} path="media" />
                    </Route>
                  </Route>

                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <ManageDumpsters />
                      </PrivateRoute>
                    }
                    path="/install/manage-dumpsters"
                  />

                  {/* ADMIN */}
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <ZohoExports />
                      </PrivateRoute>
                    }
                    path="/admin/zoho-exports"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <Users />
                      </PrivateRoute>
                    }
                    path="/admin/users"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <NeedsBrokedown />
                      </PrivateRoute>
                    }
                    path="/admin/needs-brokedown"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <DrawActivities />
                      </PrivateRoute>
                    }
                    path="/admin/draw-activities"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <MarketingActivities />
                      </PrivateRoute>
                    }
                    path="/admin/marketing-activities"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <CompanyExpenses />
                      </PrivateRoute>
                    }
                    path="/admin/company-expenses"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <Version />
                      </PrivateRoute>
                    }
                    path="/admin/version"
                  />

                  {/* ADMIN */}

                  {/* SETTINGS */}
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <ManageMenu />
                      </PrivateRoute>
                    }
                    path="/settings/menu-management"
                  />
                  <Route
                    exact
                    element={
                      <PrivateRoute>
                        <ChangePassword />
                      </PrivateRoute>
                    }
                    path="/settings/change-password/"
                  />

                  {/* SETTINGS */}

                  <Route
                    path="/hover/:hoverJobId"
                    element={<HoverRedirect />}
                  />

                  {/* SUB PORTALS */}

                  <Route
                    path="/:_projectId/:timeStamp/:_activityId/:_commentId/:token/:customerId/:_scopeId/:customerName/:companyName/:companyEmail/:projectAddress/accepted"
                    element={<WorkAccepted />}
                  />
                  <Route
                    path="/:_projectId/:timeStamp/:_activityId/:_commentId/:token/:customerId/:_scopeId/:customerName/:companyName/:companyEmail/declined"
                    element={<WorkDeclined />}
                  />
                  <Route
                    path="/:_projectId/:_activityId/:token/:customerId/:_scopeId/:customerName/:companyName/:companyEmail/:projectAddress/submit-invoice"
                    element={<SubInvoiceSubmit />}
                  />

                  {/* ERROR PAGES */}

                  <Route path="/500" element={<ServerError />} />
                  <Route path="/401" element={<Unauthorized />} />

                  {/* NO MATCH */}
                  <Route path="*" element={<NoMatch />} />
                </Routes>
              </Suspense>
            </Sentry.ErrorBoundary>
            {/* </ErrorBoundary> */}
          </Content>
        </Layout>

        <ImageViewer visible={showImageViewer} />

        <InstallInstructions
          visible={installVisible}
          setVisible={closeIosInstallWindow}
        />
        <AddToHomeScreen
          visible={addToHomeScreen}
          setVisible={setAddToHomeScreen}
          prompt={prompt}
          setPrompt={setPrompt}
          setCheckNotifications={setCheckNotifications}
          setPrompted={setPrompted}
        />

        <NotificationPermission
          visible={notificationVisible}
          setVisible={setNotificationVisible}
          callback={callback}
        />

        {privateRoute && (
          <>
            <Forms />

            <Share />
          </>
        )}
      </Layout>
    </>
  );
};

export default App;
