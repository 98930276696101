import React from "react";
import Button from "antd/es/button";
import ArrowRight from "Icons/ArrowRight";
import styles from "./styles.module.scss";

const ImageViewerHeader = ({
  onClose,
  length = 0,
  mediaType,
  selecting,
  setSelecting,
  selectedImages,
  setSelectedImages,
  images,
}) => {
  const selectAll = () => {
    setSelectedImages(images);
  };

  const deselectAll = () => {
    setSelectedImages([]);
  };

  const toggleSelecting = () => {
    setSelecting(!selecting);
  };

  return (
    <div className={styles.header}>
      {!selecting ? (
        <Button type="text" className={styles.leftActions} onClick={onClose}>
          <ArrowRight color="#ffffff" />
        </Button>
      ) : selectedImages.length !== length ? (
        <Button type="text" className={styles.leftActions} onClick={selectAll}>
          Select All
        </Button>
      ) : (
        <Button
          type="text"
          className={styles.leftActions}
          onClick={deselectAll}
        >
          Deselect All
        </Button>
      )}

      <div className={styles.title}>
        {length} {mediaType === 1 ? "Documents" : "Photos"}
      </div>
      {!selecting ? (
        <Button
          type="text"
          shape="circle"
          className={styles.rightActions}
          onClick={toggleSelecting}
        >
          Select
        </Button>
      ) : (
        <Button
          type="text"
          shape="circle"
          className={styles.rightActions}
          onClick={toggleSelecting}
        >
          Done
        </Button>
      )}
    </div>
  );
};

export default ImageViewerHeader;
