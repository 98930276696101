import React from "react";
import LineChartWithInterval from "Reports/LineChartWithInterval";

export default function LeadsCumulative() {
  return (
    <LineChartWithInterval
      path="/reports/adminLeadsCumulative"
      type=""
      summary={true}
      lineItems={true}
      aggregate={false}
      report="LEADS CUMULATIVE"
      headers={["Salesman", "Total"]}
    />
  );
}
