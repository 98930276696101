import React from "react";
import Form from "antd/es/form";
import Input from "antd/es/input";
import InputNumber from "antd/es/input-number";
import DatePicker from "Components/Inputs/DatePicker";
import dayjs from "dayjs";
import useIsMobile from "Hooks/useIsMobile";

const Leave = ({ form }) => {
  const mobile = useIsMobile();

  const leaveDateChange = async (e, position) => {
    try {
      let firstDay = undefined,
        lastDay = undefined,
        daysOff = 0;

      if (position === "firstDay") {
        firstDay = e;
        let _lastDay = await form.getFieldValue("lastDay");
        if (_lastDay) {
          lastDay = dayjs(_lastDay);
        }
      } else {
        let _firstDay = await form.getFieldValue("firstDay");
        if (_firstDay) {
          firstDay = dayjs(_firstDay);
        }
        lastDay = e;
      }
      if (firstDay && lastDay) {
        daysOff = lastDay.diff(firstDay, "days") + 1;
      }
      form.setFieldsValue({
        daysOff,
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const disabledDate = (current) => {
    return current && current < dayjs().endOf("day");
  };

  return (
    <div>
      <Form.Item
        label="First day of leave"
        name="firstDay"
        rules={[
          {
            required: true,
            message: "Please select your first day of leave!",
          },
        ]}
      >
        <DatePicker
          format="MMMM DD, YYYY"
          disabledDate={disabledDate}
          onChange={(e) => leaveDateChange(e, "firstDay")}
          getPopupContainer={(trigger) =>
            !mobile
              ? trigger.parentNode.parentNode.parentNode.parentNode
              : document.body
          }
          getCalendarContainer={(trigger) =>
            !mobile
              ? trigger.parentNode.parentNode.parentNode.parentNode
              : document.body
          }
          inputReadOnly={true}
        />
      </Form.Item>
      <Form.Item
        label="Last day of leave"
        name="lastDay"
        rules={[
          {
            required: true,
            message: "Please select your last day of leave!",
          },
        ]}
      >
        <DatePicker
          format="MMMM DD, YYYY"
          disabledDate={disabledDate}
          onChange={(e) => leaveDateChange(e, "lastDay")}
          getPopupContainer={(trigger) =>
            !mobile
              ? trigger.parentNode.parentNode.parentNode.parentNode
              : document.body
          }
          getCalendarContainer={(trigger) =>
            !mobile
              ? trigger.parentNode.parentNode.parentNode.parentNode
              : document.body
          }
          inputReadOnly={true}
        />
      </Form.Item>
      <Form.Item label="Number of Days" name="daysOff" initialValue={0}>
        <InputNumber disabled controls={false} />
      </Form.Item>
      <Form.Item label="Comments" name="comments">
        <Input.TextArea autosize={{ minRows: 4 }} />
      </Form.Item>
    </div>
  );
};

export default Leave;
