import React from "react";
import Input from "antd/es/input";

const InputPhone = ({
  onChange,
  autoComplete = "off",
  value,
  placeholder = undefined,
  disabled = false,
  id = undefined,
  autoFocus,
}) => {
  const triggerChange = (changedValue) => {
    if (onChange) {
      onChange(changedValue);
    }
  };

  const handleChange = (e) => {
    const thisValue = e.target.value;
    const x = thisValue.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    const content =
      x[0].length === 0
        ? ""
        : !x[2]
        ? x[1]
        : x[0].length <= 6 && x[0].length > 3
        ? "(" + x[1] + ") " + x[2]
        : x[0].length > 6
        ? "(" + x[1] + ") " + x[2] + "-" + x[3]
        : "";
    triggerChange(content);
  };

  return (
    <div id={id}>
      <label htmlFor="" style={{ display: "none" }}>
        hidden label to mislead chrome autocomplete
      </label>
      <Input
        autoFocus={autoFocus}
        value={value}
        autoComplete={autoComplete}
        onChange={handleChange}
        placeholder={placeholder}
        type="tel"
        disabled={disabled}
      />
    </div>
  );
};

export default InputPhone;
