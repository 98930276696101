import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openForm } from "Actions/formActions";
import { updateDocumentsActivitiesAndHashtags } from "Actions/dataActions";
import List from "antd/es/list";
import styles from "./styles.module.scss";
import Eye from "Icons/Eye";
import Email from "Icons/Email";
import Printer from "Icons/Printer";
import Button from "antd/es/button";
import openInNewTab from "Utils/openInNewTab";
import printJS from "print-js";
import File from "Icons/File";
import Delete from "Icons/Delete";
import Modal from "antd/es/modal";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";
import api from "API";
import message from "antd/es/message";
import isPdf from "Utils/isPdf";

const Documents = ({ documents }) => {
  return (
    <>
      {documents && documents.length > 0 ? (
        documents.map((document) => (
          <List
            bordered
            key={document._id}
            itemLayout="horizontal"
            className={styles.list}
          >
            <DocumentsItem document={document} />
          </List>
        ))
      ) : (
        <List itemLayout="horizontal" className={styles.list}>
          <List.Item className={`${styles.transactionList} ${styles.none}`}>
            <List.Item.Meta
              avatar={
                <div className={styles.avatar}>
                  <File color="#108ee9" />
                </div>
              }
              title="No documents have been uploaded"
            />
          </List.Item>
        </List>
      )}
    </>
  );
};

const DocumentsItem = ({ document }) => {
  const dispatch = useDispatch();
  const viewableActivities = useSelector(
    (state) => state.dataState.viewableActivities
  );

  const openDocument = (e) => {
    e.stopPropagation();
    openInNewTab(document.src);
  };

  const printDocument = (e) => {
    e.stopPropagation();
    let type = isPdf(document.src) ? "pdf" : "image";
    printJS({
      printable: `${document.src}?${new Date().getTime()}`,
      type,
    });
  };

  const openEmail = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(
        openForm("email", {
          content: [
            {
              _id: document.src,
              src: document.src,
              _projectId: document._projectId,
              type: "document",
            },
          ],
        })
      );
    },
    [dispatch, document.src, document._projectId]
  );

  const confirmDeleteDocument = () => {
    Modal.confirm({
      zIndex: 4002,
      icon: <QuestionCircleOutlined />,
      centered: true,
      cancelText: "Cancel",
      okText: "Delete",
      okButtonProps: { danger: true },
      content: <div>Are you sure you want to delete this document?</div>,
      onOk() {
        return new Promise((resolve, reject) => {
          deleteDocument(() => resolve(true));
        }).catch((err) => console.log("err: ", err));
      },
      onCancel() {},
    });
  };

  const deleteDocument = useCallback(
    async (cb) => {
      try {
        const res = await api.delete(
          `/project-documents/delete/${document._id}`
        );

        dispatch(
          updateDocumentsActivitiesAndHashtags({
            viewableActivities,
            _projectId: document._projectId,
            data: res.data,
          })
        );

        cb();
      } catch (err) {
        console.log("err", err);
        message.error("Error deleting expense");
      }
    },
    [document, dispatch, viewableActivities]
  );

  return (
    <List.Item className={styles.transactionList}>
      <List.Item.Meta
        avatar={
          <div className={styles.avatar}>
            <File color="#108ee9" />
          </div>
        }
        title={document.documentType}
      />
      {document.src && (
        <>
          <Button
            onClick={openDocument}
            shape="circle"
            type="link"
            style={{ marginLeft: 8 }}
          >
            <Eye />
          </Button>
          <Button
            onClick={openEmail}
            shape="circle"
            type="link"
            style={{ marginLeft: 8 }}
          >
            <Email />
          </Button>
          <Button
            onClick={printDocument}
            shape="circle"
            type="link"
            style={{ marginLeft: 8 }}
          >
            <Printer />
          </Button>
          <Button
            onClick={confirmDeleteDocument}
            shape="circle"
            type="link"
            style={{ marginLeft: 8 }}
          >
            <Delete color="#ff7056" />
          </Button>
        </>
      )}
    </List.Item>
  );
};

export default Documents;
