import React, { useState, useCallback } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateDetail } from "Actions/dataActions";
import Form from "antd/es/form";
import Button from "antd/es/button";
import message from "antd/es/message";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import api from "API";
import InputProjectManager from "Components/Inputs/ProjectManager";

const InstallProjectManager = ({ value, _id, salesObj }) => {
  const dispatch = useDispatch();
  const viewableActivities = useSelector(
    (state) => state.dataState.viewableActivities
  );
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const onFinish = useCallback(
    async (values) => {
      try {
        setLoading(true);
        values._id = _id;
        values.previous = value;
        values.installProjectManager = salesObj[values.installProjectManager];

        if (
          !value ||
          (values.installProjectManager &&
            values.installProjectManager._user !== value._user) ||
          (!values.installProjectManager && value && value._user)
        ) {
          const res = await api.post("/update/installProjectManager", values);

          dispatch(
            updateDetail({
              viewableActivities,
              key: "installProjectManager",
              value: res.data.value,
              activities: res.data.activities,
            })
          );

          message.success("Install Project Manager updated");
        }
        unstable_batchedUpdates(() => {
          setLoading(false);
          setEdit(false);
        });
      } catch (err) {
        console.log("err", err);
        message.error("Error updating Install Project Manager");
        setLoading(false);
      }
    },
    [_id, value, salesObj, dispatch, viewableActivities]
  );

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error updating Install Project Manager");
  };

  const toggleEdit = () => {
    setEdit(!edit);
  };

  return (
    <Form
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      initialValues={{
        installProjectManager: value && value._user ? value._user : undefined,
      }}
      style={{ marginBottom: 16 }}
    >
      <RemoveAutocomplete />
      <h4 style={{ marginBottom: 3 }}>Install Project Manager</h4>
      {!edit && (
        <p
          className="editableRow"
          style={{ marginBottom: 0 }}
          onClick={toggleEdit}
        >
          <span>{value && value.name ? value.name : "Click to Edit"}</span>
        </p>
      )}

      {edit && (
        <>
          <Form.Item name="installProjectManager">
            <InputProjectManager
              autoFocus
              sales={salesObj}
              defaultOpen={true}
              placeholder="Install Project Project"
            />
          </Form.Item>

          <div className="editControls">
            <Button
              type="default"
              size="small"
              onClick={toggleEdit}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              size="small"
              loading={loading}
              disabled={loading}
            >
              Submit
            </Button>
          </div>
        </>
      )}
    </Form>
  );
};

export default InstallProjectManager;
