import React, { useState, useEffect, useCallback } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateDetail } from "Actions/dataActions";
import Form from "antd/es/form";
import Button from "antd/es/button";
import message from "antd/es/message";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import api from "API";
import InputProjectManager from "Components/Inputs/ProjectManager";

const ProjectManager = ({ value, _id, salesObj }) => {
  const dispatch = useDispatch();
  const viewableActivities = useSelector(
    (state) => state.dataState.viewableActivities
  );
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sales, setSales] = useState();

  useEffect(() => {
    let obj = {};
    for (const s of salesObj) {
      obj[s._user] = {
        _user: s._user,
        name: s.name,
        email: s.email,
        phone: s.phone,
        hoverUserId: s.hoverUserId,
      };
    }

    setSales(obj);
  }, [salesObj]);

  const onFinish = useCallback(
    async (values) => {
      try {
        setLoading(true);
        values._id = _id;
        values.previous = value;
        values.projectManager = sales[values.projectManager];

        if (values.projectManager._user !== value._user) {
          const res = await api.post("/update/projectOwner", values);

          dispatch(
            updateDetail({
              viewableActivities,
              key: "projectManager",
              value: res.data.value,
              activities: res.data.activities,
            })
          );

          message.success("Project Owner updated");
        }
        unstable_batchedUpdates(() => {
          setLoading(false);
          setEdit(false);
        });
      } catch (err) {
        console.log("err", err);
        message.error("Error updating Project Owner");
        setLoading(false);
      }
    },
    [_id, value, sales, dispatch, viewableActivities]
  );

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error updating Project Owner");
  };

  const toggleEdit = () => {
    setEdit(!edit);
  };

  return (
    <Form
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      initialValues={{ projectManager: value._user }}
      style={{ marginBottom: 16 }}
    >
      <RemoveAutocomplete />
      <h4 style={{ marginBottom: 3 }}>Project Owner</h4>
      {!edit && (
        <p
          className="editableRow"
          style={{ marginBottom: 0 }}
          onClick={toggleEdit}
        >
          <span>{value.name}</span>
        </p>
      )}

      {edit && (
        <>
          <Form.Item
            name="projectManager"
            rules={[
              {
                required: true,
                message: "Select a Project Owner",
              },
            ]}
          >
            <InputProjectManager
              autoFocus
              sales={sales}
              defaultOpen={true}
              placeholder="Project Owner"
            />
          </Form.Item>

          <div className="editControls">
            <Button
              type="default"
              size="small"
              onClick={toggleEdit}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              size="small"
              loading={loading}
              disabled={loading}
            >
              Submit
            </Button>
          </div>
        </>
      )}
    </Form>
  );
};

export default ProjectManager;
