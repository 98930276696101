import b64toBlob from "./b64toBlob";

export default function hidePdfControls(src) {
  let thisSrc = src;
  if (thisSrc.split(";")[0] === "data:application/pdf") {
    thisSrc =
      URL.createObjectURL(b64toBlob(thisSrc.split(",")[1], "application/pdf")) +
      "#toolbar=0&navpanes=0&scrollbar=0&view=FitH";
  } else {
    thisSrc = thisSrc + "#toolbar=0&navpanes=0&scrollbar=0&view=FitH";
  }
  return thisSrc;
}
