import React, { useEffect, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useSelector } from "react-redux";
// import { openForm } from "Actions/formActions";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "./styles.module.scss";
import api from "API";
import Card from "antd/es/card";
import Row from "antd/es/row";
import Col from "antd/es/col";
// import List from "antd/es/list";
import message from "antd/es/message";
import Grid from "antd/es/grid";
import Affix from "antd/es/affix";
// import Select from "antd/es/select";
// import Tile from "Components/dashboard/Tile";
import ViewContainer from "Components/ViewContainer";
import Activity from "Components/Activities/Activity";
import FormBody from "Components/FormBody";
import ImagesDrawer from "Components/images/ImagesDrawer";
// import ViewList from "Icons/ViewList";
// import Send from "Icons/Send";
// import Image from "Icons/Image";
// import Place from "Icons/Place";
// import Assignment from "Icons/Assignment";
// import Calendar from "Icons/Calendar";
import dayjs from "dayjs";
import useViewShow from "Hooks/useViewShow";
import useIsMounted from "Hooks/useIsMounted";
import useIsMobile from "Hooks/useIsMobile";

import ViewLead from "Screens/ViewLead";
import ViewProject from "Screens/ViewProject";
// import RemoveAutocomplete from "Components/RemoveAutocomplete";
// import Tag from "antd/es/tag";
import relativeTime from "dayjs/plugin/relativeTime";
import duration from "dayjs/plugin/duration";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "Components/Loader";
import ActiveProject from "./ActiveProject";
import StatisticCard from "./StatisticCard";
// import Button from "antd/es/button";
// import { FixedSizeList as Vlist } from "react-window";
// import { VariableSizeGrid as Vgrid } from "react-window";
// import AutoSizer from "react-virtualized-auto-sizer";
import Button from "antd/es/button";
import OpenLead from "./OpenLead";

dayjs.extend(duration);
dayjs.extend(relativeTime);

const { useBreakpoint } = Grid;

let currentLength = 0;

const Dashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const screens = useBreakpoint();
  const mobile = useIsMobile();
  const showProject = useViewShow(2, "projects", 3, "view");
  const showLead = useViewShow(2, "leads", 3, "view");
  // const dispatch = useDispatch();
  const images = useSelector((state) => state.formState.images.open);
  const _user = useSelector((state) => state.authState._user);
  const isMounted = useIsMounted();
  // const [itemSize] = useState(337);
  const [state, setState] = useState({
    // mEstimates: {
    //   value: 0,
    //   percent: 0,
    // },
    // mSales: {
    //   value: 0,
    //   percent: 0,
    // },
    // ySales: {
    //   value: 0,
    //   percent: 0,
    // },
    // leads: 0,
    // closingRate: 0,
    // pipeline: 0,
    // profitPercentage: 0,
    // drawBalance: 0,
    // last5draw: [],
    // lastWeekSales: [],
    // totalAR: 0,
    // pC: 0,
    leadTimes: {
      categories: [],
    },
    // roofingProcess: {
    //   "Order Aerial": 0,
    //   "Aerial Verification": 0,
    //   "Mold Review": 0,
    //   Permit: 0,
    //   "Office Review": 0,
    //   "Ready For Material": 0,
    //   "Ready For Dumpster": 0,
    //   "Ready For Schedule": 0,
    //   "Work Scheduled": 0,
    //   "Post-Job Inspection": 0,
    //   total: 0,
    // },
    activeScopes: [],
    openLeads: [],
    leadsNotScheduled: 0,
    estimatesNoResults: 0,
    estimatesSoldNotFlipped: 0,
    leadsNeedRemeetGreater7: 0,
    // estimateCount: 0,
    leadsInsuranceWIPGreater30: 0,
    estimateValue: 0,
  });
  const [activities, setActivities] = useState([]);
  const [activitiesLength, setActivitiesLength] = useState(0);
  const [loading, setLoading] = useState(true);
  const [container, setContainer] = useState(null);
  // const [filter, setFilter] = useState(undefined);
  // const [hashtags, setHashtags] = useState([]);

  const [today] = useState(dayjs().endOf("day"));
  const [yesterday] = useState(dayjs().subtract(1, "day").endOf("day"));
  const [day3] = useState(dayjs().subtract(2, "day").endOf("day"));
  const [day7] = useState(dayjs().subtract(6, "day").endOf("day"));

  const [activeFilter, setActiveFilter] = useState("projects");

  useEffect(() => {
    populateDashboard();

    window.socket.on(`${_user}-dashboard`, updateActivities);

    window.socket.on("globalUpdate", updateActivities);

    const timer = setTimeout(() => {
      const index = location.search.substr(1);
      const el = document.getElementById(`${index}`);
      if (el) {
        el.scrollIntoView(true);
      }
    }, 100);

    return () => {
      window.socket.removeAllListeners(`${_user}-dashboard`);
      window.socket.removeAllListeners("globalUpdate");
      clearTimeout(timer);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    currentLength = activities.length;
    return () => {
      currentLength = 0;
    };
  }, [activities]);

  const populateDashboard = async () => {
    try {
      const res = await api.get("/dashboard/populate");
      // let profitPercentage = 0,
      //   closingRate = 0;
      // if (
      //   res.data.profitPercentage === null ||
      //   res.data.profitPercentage === undefined
      // ) {
      //   profitPercentage = 0;
      // } else {
      //   profitPercentage = res.data.profitPercentage;
      // }

      // if (res.data.closingRate === null || res.data.closingRate === undefined) {
      //   closingRate = 0;
      // } else {
      //   closingRate = res.data.closingRate;
      // }

      if (isMounted.current) {
        const _feed = addDateBreaks(res.data.feed);

        unstable_batchedUpdates(() => {
          setActivities(_feed);
          // setHashtags(res.data.hashtags);
          setLoading(false);

          setState({
            // mEstimates: res.data.mEstimates,
            // mSales: res.data.mSales,
            // ySales: res.data.ySales,
            // leads: res.data.leads,
            // closingRate,
            // pipeline: res.data.pipeline,
            // profitPercentage,
            // drawBalance: res.data.drawBalance,
            // last5draw: res.data.last5draw,
            // lastWeekSales: res.data.lastWeekSales,
            // totalAR: res.data.totalAR,
            // pC: res.data.pC,
            leadTimes: res.data.leadTimes,
            roofingProcess: res.data.roofingProcess,
            activeScopes: res.data.activeScopes,
            openLeads: res.data.openLeads,
            leadsNotScheduled: res.data.leadsNotScheduled,
            estimatesNoResults: res.data.estimatesNoResults,
            estimatesSoldNotFlipped: res.data.estimatesSoldNotFlipped,
            leadsNeedRemeetGreater7: res.data.leadsNeedRemeetGreater7,
            estimateCount: res.data.estimateCount,
            estimateValue: res.data.estimateValue,
            leadsInsuranceWIPGreater30: res.data.leadsInsuranceWIPGreater30,
          });
        });
      }
    } catch (err) {
      console.log("err", err);
      message.error("Error retrieving data");
    }
  };

  const updateProjects = async () => {
    try {
      const res = await api.get("/update-active-projects");
      setState((thisState) => ({ ...thisState, activeScopes: res.data }));
    } catch (err) {
      console.log("updateScopes err", err);
    }
  };

  const addDateBreaks = (array) => {
    let stamp;
    let dateStamp;

    for (const f of array) {
      f._dateStamp = undefined;
      const createdDate = dayjs(f.lastUpdated);
      if (createdDate.isBefore(today)) {
        if (createdDate.isBefore(yesterday)) {
          if (createdDate.isBefore(day3)) {
            if (createdDate.isBefore(day7)) {
              stamp = createdDate.format("MMM D, YYYY");
            } else {
              stamp = createdDate.format("dddd");
            }
          } else {
            stamp = "Yesterday";
          }
        } else {
          stamp = "Today";
        }
      }
      if (stamp !== dateStamp) {
        f._dateStamp = stamp;
        dateStamp = stamp;
      }
    }

    return array;
  };

  const updateActivities = async () => {
    try {
      // const res = await api.get(`/updateActivities/${currentLength}/${filter}`);
      const res = await api.get(`/updateActivities/${currentLength}`);

      unstable_batchedUpdates(() => {
        const _feed = addDateBreaks(res.data.feed);

        setActivities(_feed);
        setActivitiesLength(res.data.feedCount);
      });
    } catch (err) {
      console.log("updateActivities err", err);
    }
  };

  const fetchActivities = async (_filter) => {
    try {
      // const res = await api.get(
      //   `/fetchActivities/${_filter ? 0 : activities.length}/${
      //     _filter ? _filter : filter
      //   }`
      // );

      const res = await api.get(
        `/fetchActivities/${_filter ? 0 : activities.length}`
      );

      // if (_filter) {
      //   unstable_batchedUpdates(() => {
      //     const _feed = addDateBreaks(res.data.feed);

      //     setActivities(_feed);
      //     setActivitiesLength(res.data.feedCount);
      //   });
      // } else {
      let _feed = [...activities, ...res.data.feed];
      _feed = addDateBreaks(_feed);

      unstable_batchedUpdates(() => {
        setActivities(_feed);
        setActivitiesLength(res.data.feedCount);
      });
      // }
    } catch (err) {
      console.log("fetchActivities err", err);
    }
  };

  // const openRequests = useCallback(() => {
  //   dispatch(openForm("requests"));
  // }, [dispatch]);

  // const openPhonebook = () => {
  //   navigate("/phone-book");
  // };

  // const openPhotoAlbum = () => {
  //   navigate("/photo-albums");
  // };

  // const openMap = () => {
  //   navigate("/maps");
  // };

  // const openCreateEvent = useCallback(() => {
  //   dispatch(openForm("createEvent"));
  // }, [dispatch]);

  // const openMaterialQuote = useCallback(() => {
  //   dispatch(openForm("materialQuote"));
  // }, [dispatch]);

  // const tiles = [
  //   {
  //     onClick: openRequests,
  //     icon: Send,
  //     title: "Requests",
  //     color: "rgb(52, 58, 64)",
  //   },
  //   {
  //     onClick: openPhonebook,
  //     icon: ViewList,
  //     title: "Phone Book",
  //     color: "rgb(16, 142, 233)",
  //   },
  //   {
  //     onClick: openPhotoAlbum,
  //     icon: Image,
  //     title: "Photo Album",
  //     color: "rgb(1, 192, 200)",
  //   },
  //   {
  //     onClick: openMap,
  //     icon: Place,
  //     title: "Map",
  //     color: "rgb(255, 112, 86)",
  //   },
  //   {
  //     onClick: openCreateEvent,
  //     icon: Calendar,
  //     title: "Create Event",
  //     color: "rgb(0, 194, 146)",
  //   },
  //   {
  //     onClick: openMaterialQuote,
  //     icon: Assignment,
  //     title: "Material Quote",
  //     color: "rgb(254, 193, 7)",
  //   },
  // ];

  // function onChange(e) {
  //   if (e === "REMOVE FILTER") {
  //     setFilter(undefined);
  //     fetchActivities("undefined");
  //   } else {
  //     setFilter(e);
  //     fetchActivities(e);
  //   }
  // }

  const rowRenderer = () => {
    return activities.map((fp) => {
      if (!fp._dateStamp) {
        return (
          <div id={`dashboard-${fp._id}`} key={`dashboard-${fp._id}`}>
            <Activity
              dashboard
              activity={fp}
              scrollContainer={"#feed-container"}
            />
          </div>
        );
      } else {
        return (
          <div key={`dashboard-${fp._id}`}>
            <Affix
              offsetTop={0}
              target={() => container}
              className={styles.affix}
            >
              <div className={styles.dateStamp}>{fp._dateStamp}</div>
            </Affix>
            <div id={`dashboard-${fp._id}`}>
              <Activity
                dashboard
                activity={fp}
                scrollContainer={"#feed-container"}
              />
            </div>
          </div>
        );
      }
    });
  };

  // Column Count * Row Index + Column Index

  // const Content = ({ columnIndex, rowIndex, style }) => {
  //   const columnCount = screens.md ? 3 : screens.sm ? 2 : 1;
  //   // console.log("columnIndex", columnIndex);
  //   // console.log("rowIndex", rowIndex);
  //   // console.log("index", index);
  //   const index = columnCount * rowIndex + columnIndex;
  //   // console.log("index", index);
  //   const item = state.activeScopes[index];
  //   // console.log("item", item);
  //   // return <TableRow style={style} key={item._id} {...item} mobile />;
  //   return <ActiveProject style={style} key={item._id} scope={item} />;
  // };

  const openLeadsNotScheduled = () => {
    navigate("/leads?leads-not-scheduled");
  };

  const openEstimatesNoResults = () => {
    navigate("/leads?estimates-with-no-results");
  };

  const openEstimatesSoldNotFlipped = () => {
    navigate("/leads?estimates-sold-not-flipped");
  };

  const openLeadsNeedRemeetGreater7 = () => {
    navigate("/leads?leads-need-remeet");
  };

  const openLeadsInsuranceWIPGreater30 = () => {
    navigate("/leads?leads-insurance-wip");
  };

  const toggleFilter = () => {
    setActiveFilter((thisFilter) => {
      if (thisFilter === "projects") {
        return "leads";
      }
      return "projects";
    });
  };

  return (
    <div style={{ height: "100%" }}>
      <div style={{ background: "#f0f2f5", height: "100%" }}>
        <Row gutter={16} style={{ height: "100%" }}>
          <Col xs={24} sm={24} md={16} style={{ height: "100%" }}>
            <div
              id="dashboard-container"
              className={`content-inner ${styles.dashboardContainer}`}
              style={{
                height: "100%",
                // paddingTop: 24,
                // paddingLeft: 24,
                // paddingBottom: 24,
                // paddingRight: mobile ? 24 : 0,
              }}
            >
              {/* <Row gutter={16}> */}
              {/* MY PORTAL */}
              {/* <Col xs={24} sm={24} md={12} className={styles.mb16}>
                  <Card
                    bordered={false}
                    className="z-depth-1"
                    style={{ overflow: "hidden" }}
                    bodyStyle={{
                      padding: 0,
                    }}
                    headStyle={headStyle}
                    title="My Portal"
                  >
                    <Row gutter={0}>
                      {tiles.map((t) => (
                        <Col key={t.title} sm={8} xs={12} style={{ margin: 0 }}>
                          <Tile
                            onClick={t.onClick}
                            title={t.title}
                            icon={t.icon}
                            color={t.color}
                          />
                        </Col>
                      ))}
                    </Row>
                  </Card>
                </Col> */}

              {/* LEAD TIMES */}
              {/* <Col xs={24} sm={24} md={12} className={styles.mb16}>
                  <Card
                    className={`${styles.leadTimesContainer} z-depth-1`}
                    bodyStyle={{ padding: 0 }}
                    headStyle={headStyle}
                    bordered={false}
                    style={{ overflow: "hidden" }}
                    title="Lead Times"
                    extra={
                      state.leadTimes && state.leadTimes.date ? (
                        <Tag color="#108ee9" style={{ marginRight: 0 }}>
                          {dayjs(state.leadTimes.date).fromNow()}
                        </Tag>
                      ) : null
                    }
                  >
                    <List>
                      {state.leadTimes &&
                      state.leadTimes.categories &&
                      state.leadTimes.categories.length > 0 ? (
                        state.leadTimes &&
                        state.leadTimes.categories &&
                        state.leadTimes.categories.map((lt) => {
                          return (
                            <List.Item
                              key={lt._id}
                              style={{ padding: "8.5px 12px" }}
                            >
                              <List.Item.Meta title={lt.title} />
                              <List.Item.Meta
                                description={lt.description}
                                style={{ textAlign: "right" }}
                              />
                            </List.Item>
                          );
                        })
                      ) : (
                        <List.Item style={{ padding: "8.5px 12px" }}>
                          <List.Item.Meta title="No Lead Times are Available" />
                        </List.Item>
                      )}
                    </List>
                  </Card>
                </Col>
              </Row> */}

              <Row gutter={16}>
                <Col xs={24} sm={12} md={8}>
                  <StatisticCard
                    handleClick={openLeadsNotScheduled}
                    title="Lead Not Scheduled"
                    value={state.leadsNotScheduled}
                  />
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <StatisticCard
                    handleClick={openEstimatesNoResults}
                    title="Estimate No Results"
                    value={state.estimatesNoResults}
                  />
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <StatisticCard
                    handleClick={openEstimatesSoldNotFlipped}
                    title="Estimates Sold Not Flipped"
                    value={state.estimatesSoldNotFlipped}
                  />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col xs={24} sm={12} md={8}>
                  <StatisticCard
                    handleClick={openLeadsNeedRemeetGreater7}
                    title="Lead Needs Remeet > 7 Days"
                    value={state.leadsNeedRemeetGreater7}
                  />
                </Col>
                <Col xs={24} sm={12} md={8}>
                  {/* <StatisticCard
                    title="Estimates Count"
                    value={state.estimateCount}
                  /> */}
                  <StatisticCard
                    handleClick={openLeadsInsuranceWIPGreater30}
                    title="Lead Insurance WIP > 30 Days"
                    value={state.leadsInsuranceWIPGreater30}
                  />
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <StatisticCard
                    title="Estimates Value"
                    value={state.estimateValue}
                    currency
                  />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col xs={24} style={{ marginBottom: 12 }}>
                  <Button block type="primary" onClick={toggleFilter}>
                    {activeFilter === "projects"
                      ? "Showing Active Projects"
                      : "Showing Open Leads"}
                  </Button>
                </Col>
              </Row>

              <Row gutter={16}>
                {/* {state.activeScopes.length > 0 && (
                  <AutoSizer>
                    {({ height, width }) => {
                      function calcColWidth(index) {
                        return screens.md
                          ? width / 3
                          : screens.sm
                          ? width / 2
                          : width;
                      }

                      function calcRowHeight(index) {
                        return itemSize;
                      }

                      return (
                        <Vgrid
                          columnCount={screens.md ? 3 : screens.sm ? 2 : 1}
                          columnWidth={calcColWidth}
                          rowCount={
                            screens.md
                              ? state.activeScopes.length / 3
                              : screens.sm
                              ? state.activeScopes.length / 2
                              : state.activeScopes.length
                          }
                          rowHeight={calcRowHeight}
                          height={height}
                          // itemCount={state.activeScopes.length}
                          // itemSize={itemSize}
                          width={width}
                        >
                          {Content}
                        </Vgrid>
                      );
                    }}
                  </AutoSizer>
                )} */}

                {activeFilter === "projects"
                  ? state.activeScopes.map((scope) => (
                      <ActiveProject
                        key={scope._id}
                        scope={scope}
                        updateProjects={updateProjects}
                      />
                    ))
                  : state.openLeads.map((lead) => (
                      <OpenLead key={lead._id} lead={lead} />
                    ))}
              </Row>
            </div>
          </Col>

          {/* FEED */}
          {!mobile && (
            <Col xs={0} sm={24} md={8} style={{ height: "100%" }}>
              <div
                id="dashboard-feed-container"
                ref={setContainer}
                className={`content-inner ${styles.feedContainer}`}
                style={{
                  paddingRight: 24,
                  paddingTop: 24,
                  height: "100%",
                }}
              >
                {/* <form autoComplete="off">
                  <RemoveAutocomplete />
                  <Select
                    autoComplete="dashboard-hashtag-filter"
                    allowClear
                    showSearch={!mobile}
                    dropdownClassName={mobile ? "isMobile" : null}
                    getPopupContainer={(trigger) =>
                      !mobile ? trigger.parentNode : document.body
                    }
                    placeholder="Search Hashtags..."
                    onChange={onChange}
                    className={styles.hashtagSearch}
                    size="large"
                    value={filter}
                  >
                    {hashtags.map((h) => (
                      <Select.Option key={h} value={h}>
                        {h}
                      </Select.Option>
                    ))}
                    <Select.Option
                      style={{
                        fontWeight: "bold",
                        borderTop: "1px solid #f0f0f0",
                      }}
                      key="remove-filter"
                      value="REMOVE FILTER"
                    >
                      REMOVE FILTER
                    </Select.Option>
                  </Select>
                </form> */}
                {loading ? (
                  <>
                    <Card loading={true} style={{ marginBottom: 8 }} />
                    <Card loading={true} style={{ marginBottom: 8 }} />
                    <Card loading={true} style={{ marginBottom: 8 }} />
                    <Card loading={true} style={{ marginBottom: 8 }} />
                    <Card loading={true} style={{ marginBottom: 8 }} />
                    <Card loading={true} style={{ marginBottom: 8 }} />
                    <Card loading={true} style={{ marginBottom: 8 }} />
                    <Card loading={true} style={{ marginBottom: 8 }} />
                    <Card loading={true} style={{ marginBottom: 8 }} />
                    <Card loading={true} style={{ marginBottom: 8 }} />
                    <Card loading={true} style={{ marginBottom: 8 }} />
                    <Card loading={true} style={{ marginBottom: 8 }} />
                    <Card loading={true} style={{ marginBottom: 8 }} />
                    <Card loading={true} style={{ marginBottom: 8 }} />
                  </>
                ) : activities.length > 0 ? (
                  <InfiniteScroll
                    dataLength={activities.length} //This is important field to render the next data
                    next={fetchActivities}
                    hasMore={activities.length !== activitiesLength}
                    loader={
                      <div style={{ width: "100%" }}>
                        <Loader minHeight="40px" />
                      </div>
                    }
                    endMessage={null}
                    scrollableTarget="dashboard-feed-container"
                  >
                    {rowRenderer()}
                  </InfiniteScroll>
                ) : (
                  <Card
                    className={styles.activity}
                    bodyStyle={{ padding: 0, borderRadius: 4 }}
                  >
                    <FormBody
                      className={styles.body}
                      style={{
                        paddingRight: 8,
                        paddingTop: 8,
                        paddingBottom: 8,
                      }}
                    >
                      <div className={styles.content}>
                        No posts the last 7 days
                      </div>
                    </FormBody>
                  </Card>
                )}
              </div>
            </Col>
          )}
        </Row>
      </div>

      <ImagesDrawer dashboard visible={images} />

      <ViewContainer
        type="drawer"
        visible={showLead}
        form={ViewLead}
        placement="right"
        width={!screens.sm ? "100%" : 400}
        closable={false}
        mask={false}
      />

      <ViewContainer
        type="drawer"
        visible={showProject}
        form={ViewProject}
        placement="right"
        width={!screens.sm ? "100%" : 400}
        closable={false}
        mask={false}
      />
    </div>
  );
};

// const headStyle = {
//   marginBottom: 0,
//   borderTop: "4px solid rgba(0, 0, 0, 0.65)",
//   paddingLeft: 12,
//   paddingRight: 12,
// };

export default Dashboard;
