import React, { useState } from "react";
import Form from "antd/es/form";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Divider from "antd/es/divider";
import Percent from "Components/Inputs/Percent";
import InputNumber from "antd/es/input-number";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";
import InputNumberNoAutoComplete from "Components/Inputs/InputNumberNoAutoComplete";

const convertPercent = (value) => {
  return value / 100 + 1;
};

const AerialCalculator = ({ hidden, form, preJobCheck }) => {
  const [capLength] = useState(22);
  const [dripLength] = useState(10);
  const [dripWaste] = useState(1.2);
  const [ridgeLength] = useState(4);
  const [iceAndWaterLength] = useState(180);
  const [underlaymentLength] = useState(1000);
  const [valleyLength] = useState(2);
  const [_iceAndWaterLength] = useState(60);
  const [coilNailCount] = useState(1500);
  const [stepLength] = useState(50);

  const onWasteFactorChange = async (value) => {
    try {
      let wasteFactor = convertPercent(value);
      let totalArea = await form.getFieldValue("totalArea");
      let rakeAndEave = await form.getFieldValue("rakeAndEave");

      form.setFieldsValue({
        shinglesTotal: totalArea * wasteFactor,
        shinglesRounded: Math.ceil(totalArea * wasteFactor),
        dripEdgeTotal: (rakeAndEave * dripWaste) / dripLength,
        dripEdgeRounded: Math.ceil((rakeAndEave * dripWaste) / dripLength),
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const onEaveChange = async (value) => {
    try {
      let rake = await form.getFieldValue("rake");
      let rakeAndEave = value + rake;
      // let wasteFactor = await form.getFieldValue("wasteFactor");
      // wasteFactor = convertPercent(wasteFactor);
      let manufacturer = await form.getFieldValue("manufacturer");
      let starterLength = 140;
      if (manufacturer !== "Atlas") {
        starterLength = 120;
      }
      let valley = await form.getFieldValue("valley");
      form.setFieldsValue({
        rakeAndEave,
        dripEdgeTotal: (rakeAndEave * dripWaste) / dripLength,
        dripEdgeRounded: Math.ceil((rakeAndEave * dripWaste) / dripLength),
        starterTotal: (rakeAndEave / starterLength) * 1.1,
        starterRounded: Math.ceil((rakeAndEave / starterLength) * 1.1),
        iceAndWaterTotal: (value + valley * valleyLength) / _iceAndWaterLength,
        iceAndWaterRounded: Math.ceil(
          (value + valley * valleyLength) / _iceAndWaterLength
        ),
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const onRakeChange = async (value) => {
    try {
      let eave = await form.getFieldValue("eave");
      let rakeAndEave = value + eave;
      // let wasteFactor = await form.getFieldValue("wasteFactor");
      // wasteFactor = convertPercent(wasteFactor);
      let manufacturer = await form.getFieldValue("manufacturer");
      let starterLength = 140;
      if (manufacturer !== "Atlas") {
        starterLength = 120;
      }
      form.setFieldsValue({
        rakeAndEave,
        dripEdgeTotal: (rakeAndEave * dripWaste) / dripLength,
        dripEdgeRounded: Math.ceil((rakeAndEave * dripWaste) / dripLength),
        starterTotal: (rakeAndEave / starterLength) * 1.1,
        starterRounded: Math.ceil((rakeAndEave / starterLength) * 1.1),
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const onHipChange = async (value) => {
    try {
      const ridge = await form.getFieldValue("ridge");
      form.setFieldsValue({
        ridgeCapTotal: ((value + ridge) / capLength) * 1.1,
        ridgeCapRounded: Math.ceil(((value + ridge) / capLength) * 1.1),
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const onValleyChange = async (value) => {
    try {
      let eave = await form.getFieldValue("eave");
      form.setFieldsValue({
        iceAndWaterTotal: (value + eave * valleyLength) / _iceAndWaterLength,
        iceAndWaterRounded: Math.ceil(
          (value + eave * valleyLength) / _iceAndWaterLength
        ),
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const onRidgeChange = async (value) => {
    try {
      const hip = await form.getFieldValue("hip");
      form.setFieldsValue({
        ridgeCapTotal: ((value + hip) / capLength) * 1.1,
        ridgeCapRounded: Math.ceil(((value + hip) / capLength) * 1.1),
        ventilationTotal: value / ridgeLength,
        ventilationRounded: Math.ceil(value / ridgeLength),
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const onStepFlashingChange = (value) => {
    form.setFieldsValue({
      stepFlashingTotal: value / stepLength,
      stepFlashingRounded: Math.ceil(value / stepLength),
    });
  };

  const onTotalAreaChange = async (value) => {
    try {
      let wasteFactor = await form.getFieldValue("wasteFactor");
      wasteFactor = convertPercent(wasteFactor);
      let iceAndWaterTotal = await form.getFieldValue("iceAndWaterTotal");
      form.setFieldsValue({
        shinglesTotal: value * wasteFactor,
        shinglesRounded: Math.ceil(value * wasteFactor),
        underlaymentTotal:
          (value - iceAndWaterTotal * iceAndWaterLength) / underlaymentLength,
        underlaymentRounded: Math.ceil(
          (value - iceAndWaterTotal * iceAndWaterLength) / underlaymentLength
        ),
        coilNailsTotal: value / coilNailCount,
        coilNailsRounded: Math.ceil(value / coilNailCount),
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <div style={{ display: hidden ? "none" : "block" }}>
      <div style={{ display: preJobCheck ? "none" : "block" }}>
        <Form.Item
          name="wasteFactor"
          label="Waste Factor"
          initialValue={12}
          tabIndex={hidden ? "-1" : undefined}
        >
          <Percent id="wasteFactor" onChange={onWasteFactorChange} />
        </Form.Item>
        <Row gutter={16}>
          <Col xs={24} sm={6}>
            <Form.Item name="eave" label="Eave" initialValue={0}>
              <InputNumberNoAutoComplete
                id="eave"
                tabIndex={hidden || preJobCheck ? "-1" : undefined}
                precision={1}
                onChange={onEaveChange}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={6}>
            <Form.Item name="rake" label="Rake" initialValue={0}>
              <InputNumberNoAutoComplete
                id="rake"
                tabIndex={hidden || preJobCheck ? "-1" : undefined}
                precision={1}
                onChange={onRakeChange}
              />
            </Form.Item>
            <Form.Item name="rakeAndEave" hidden initialValue={0}>
              <InputNumberNoAutoComplete
                id="rakeAndEave"
                tabIndex={hidden || preJobCheck ? "-1" : undefined}
                precision={1}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={6}>
            <Form.Item name="hip" label="Hip" initialValue={0}>
              <InputNumberNoAutoComplete
                id="hip"
                tabIndex={hidden || preJobCheck ? "-1" : undefined}
                precision={1}
                onChange={onHipChange}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={6}>
            <Form.Item name="valley" label="Valley" initialValue={0}>
              <InputNumberNoAutoComplete
                id="valley"
                tabIndex={hidden || preJobCheck ? "-1" : undefined}
                precision={1}
                onChange={onValleyChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={6}>
            <Form.Item name="ridge" label="Ridge" initialValue={0}>
              <InputNumberNoAutoComplete
                id="ridge"
                tabIndex={hidden || preJobCheck ? "-1" : undefined}
                precision={1}
                onChange={onRidgeChange}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={6}>
            <Form.Item
              name="stepFlashing"
              label="Step Flashing"
              initialValue={0}
            >
              <InputNumberNoAutoComplete
                id="stepFlashing"
                tabIndex={hidden || preJobCheck ? "-1" : undefined}
                precision={1}
                onChange={onStepFlashingChange}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={6}>
            <Form.Item name="totalArea" label="Total Area" initialValue={0}>
              <InputNumberNoAutoComplete
                id="totalArea"
                tabIndex={hidden || preJobCheck ? "-1" : undefined}
                precision={1}
                onChange={onTotalAreaChange}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={6}>
            <Form.Item name="mainPitch" label="Main Pitch">
              <InputNoAutoComplete
                id="mainPitch"
                tabIndex={hidden || preJobCheck ? "-1" : undefined}
              />
            </Form.Item>
          </Col>
        </Row>

        <Divider />
      </div>

      <Row gutter={16}>
        <Col xs={24} sm={8} style={{ marginBottom: 8 }}>
          <b>Type</b>
        </Col>
        <Col xs={24} sm={8} style={{ marginBottom: 8 }}>
          <b>Total</b>
        </Col>
        <Col xs={24} sm={8} style={{ marginBottom: 8 }}>
          <b>Rounded</b>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={8} style={{ marginBottom: 8 }}>
          Shingles
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            style={{ marginBottom: 8 }}
            name="shinglesTotal"
            initialValue={0}
          >
            <InputNumber
              tabIndex="-1"
              autoComplete="dont-use-autocomplete"
              disabled
              precision={4}
              controls={false}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            style={{ marginBottom: 8 }}
            name="shinglesRounded"
            initialValue={0}
          >
            <InputNumber
              tabIndex="-1"
              autoComplete="dont-use-autocomplete"
              disabled
              precision={0}
              controls={false}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={8} style={{ marginBottom: 8 }}>
          Ridge Cap
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            style={{ marginBottom: 8 }}
            name="ridgeCapTotal"
            initialValue={0}
          >
            <InputNumber
              tabIndex="-1"
              autoComplete="dont-use-autocomplete"
              disabled
              precision={4}
              controls={false}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            style={{ marginBottom: 8 }}
            name="ridgeCapRounded"
            initialValue={0}
          >
            <InputNumber
              tabIndex="-1"
              autoComplete="dont-use-autocomplete"
              disabled
              precision={0}
              controls={false}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={8} style={{ marginBottom: 8 }}>
          Drip Edge
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            style={{ marginBottom: 8 }}
            name="dripEdgeTotal"
            initialValue={0}
          >
            <InputNumber
              tabIndex="-1"
              autoComplete="dont-use-autocomplete"
              disabled
              precision={4}
              controls={false}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            style={{ marginBottom: 8 }}
            name="dripEdgeRounded"
            initialValue={0}
          >
            <InputNumber
              tabIndex="-1"
              autoComplete="dont-use-autocomplete"
              disabled
              precision={0}
              controls={false}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={8} style={{ marginBottom: 8 }}>
          Ventilation
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            style={{ marginBottom: 8 }}
            name="ventilationTotal"
            initialValue={0}
          >
            <InputNumber
              tabIndex="-1"
              autoComplete="dont-use-autocomplete"
              disabled
              precision={4}
              controls={false}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            style={{ marginBottom: 8 }}
            name="ventilationRounded"
            initialValue={0}
          >
            <InputNumber
              tabIndex="-1"
              autoComplete="dont-use-autocomplete"
              disabled
              precision={0}
              controls={false}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={8} style={{ marginBottom: 8 }}>
          Underlayment
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            style={{ marginBottom: 8 }}
            name="underlaymentTotal"
            initialValue={0}
          >
            <InputNumber
              tabIndex="-1"
              autoComplete="dont-use-autocomplete"
              disabled
              precision={4}
              controls={false}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            style={{ marginBottom: 8 }}
            name="underlaymentRounded"
            initialValue={0}
          >
            <InputNumber
              tabIndex="-1"
              autoComplete="dont-use-autocomplete"
              disabled
              precision={0}
              controls={false}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={8} style={{ marginBottom: 8 }}>
          Ice And Water
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            style={{ marginBottom: 8 }}
            name="iceAndWaterTotal"
            initialValue={0}
          >
            <InputNumber
              tabIndex="-1"
              autoComplete="dont-use-autocomplete"
              disabled
              precision={4}
              controls={false}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            style={{ marginBottom: 8 }}
            name="iceAndWaterRounded"
            initialValue={0}
          >
            <InputNumber
              tabIndex="-1"
              autoComplete="dont-use-autocomplete"
              disabled
              precision={0}
              controls={false}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={8} style={{ marginBottom: 8 }}>
          Starter
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            style={{ marginBottom: 8 }}
            name="starterTotal"
            initialValue={0}
          >
            <InputNumber
              tabIndex="-1"
              autoComplete="dont-use-autocomplete"
              disabled
              precision={4}
              controls={false}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            style={{ marginBottom: 8 }}
            name="starterRounded"
            initialValue={0}
          >
            <InputNumber
              tabIndex="-1"
              autoComplete="dont-use-autocomplete"
              disabled
              precision={0}
              controls={false}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={8} style={{ marginBottom: 8 }}>
          Coil Nails
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            style={{ marginBottom: 8 }}
            name="coilNailsTotal"
            initialValue={0}
          >
            <InputNumber
              tabIndex="-1"
              autoComplete="dont-use-autocomplete"
              disabled
              precision={4}
              controls={false}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            style={{ marginBottom: 8 }}
            name="coilNailsRounded"
            initialValue={0}
          >
            <InputNumber
              tabIndex="-1"
              autoComplete="dont-use-autocomplete"
              disabled
              precision={0}
              controls={false}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={8} style={{ marginBottom: 8 }}>
          Step Flashing
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            style={{ marginBottom: 8 }}
            name="stepFlashingTotal"
            initialValue={0}
          >
            <InputNumber
              tabIndex="-1"
              autoComplete="dont-use-autocomplete"
              disabled
              precision={4}
              controls={false}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            style={{ marginBottom: 8 }}
            name="stepFlashingRounded"
            initialValue={0}
          >
            <InputNumber
              tabIndex="-1"
              autoComplete="dont-use-autocomplete"
              disabled
              precision={0}
              controls={false}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default AerialCalculator;
