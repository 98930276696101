import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelected } from "Actions/dataActions";
import { openForm } from "Actions/formActions";
import List from "antd/es/list";
import dayjs from "dayjs";
import styles from "./styles.module.scss";
import currencyFormatter from "Utils/currencyFormatter";

const NeedsBrokedownItem = ({
  style,
  _id,
  customerId,
  closedOutDate,
  customerName,
  collected,
  daysClosedOut,
  salesman,
  brokedown,
  propertyType,
  projectAddress,
  headers,
  callback,
}) => {
  const selectedRow = useSelector((state) => state.dataState.selectedRow);
  const dispatch = useDispatch();

  const onClick = useCallback(() => {
    dispatch(setSelected(_id));
    dispatch(
      openForm("needsBrokedown", {
        _id,
        customerId,
        customerName,
        projectAddress,
        brokedown,
        callback,
        editing: true,
      })
    );
  }, [
    _id,
    dispatch,
    customerId,
    customerName,
    projectAddress,
    brokedown,
    callback,
  ]);

  return (
    <List.Item
      style={style}
      className={`${styles.row} ${selectedRow === _id ? styles.selected : ""}`}
      onClick={onClick}
    >
      <List.Item.Meta
        className={styles.col}
        style={{
          width: headers[0].width,
          textAlign: headers[0].align ? headers[0].align : "left",
        }}
        description={customerId}
      />
      <List.Item.Meta
        className={styles.col}
        style={{
          width: headers[1].width,
          textAlign: headers[1].align ? headers[1].align : "left",
        }}
        description={customerName}
      />
      <List.Item.Meta
        className={styles.col}
        style={{
          width: headers[2].width,
          textAlign: headers[2].align ? headers[2].align : "left",
        }}
        description={salesman}
      />
      <List.Item.Meta
        className={styles.col}
        style={{
          width: headers[3].width,
          textAlign: headers[3].align ? headers[3].align : "left",
        }}
        description={propertyType}
      />
      <List.Item.Meta
        className={styles.col}
        style={{
          width: headers[4].width,
          textAlign: headers[4].align ? headers[4].align : "left",
        }}
        description={currencyFormatter(collected)}
      />
      <List.Item.Meta
        className={styles.col}
        style={{
          width: headers[5].width,
          textAlign: headers[5].align ? headers[5].align : "left",
        }}
        description={dayjs(closedOutDate).format("MMM D, YYYY")}
      />
      <List.Item.Meta
        className={styles.col}
        style={{
          width: headers[6].width,
          textAlign: headers[6].align ? headers[6].align : "left",
        }}
        description={daysClosedOut}
      />
    </List.Item>
  );
};

export default NeedsBrokedownItem;
