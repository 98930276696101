import React, { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelected } from "Actions/dataActions";
import { openForm } from "Actions/formActions";
import List from "antd/es/list";
import Tag from "antd/es/tag";
import styles from "./styles.module.scss";
import statusObj from "Constants/vendorStatus";

const SupplierItem = ({ item, style, mobile, headers }) => {
  const selectedRow = useSelector((state) => state.dataState.selectedRow);
  const dispatch = useDispatch();

  const onClick = useCallback(() => {
    dispatch(setSelected(item._id));
    dispatch(openForm("supplier", { ...item, editing: true }));
  }, [item, dispatch]);

  if (!mobile) {
    return (
      <List.Item
        style={style}
        className={`${styles.row} ${
          selectedRow === item._id ? styles.selected : ""
        }`}
        onClick={onClick}
      >
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[0].width,
            textAlign: headers[0].align ? headers[0].align : "left",
          }}
          description={item.companyName}
        />
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[1].width,
            textAlign: headers[1].align ? headers[1].align : "left",
          }}
          description={
            <Tag className={styles.statusTag} color={statusObj[item.status]}>
              {item.status}
            </Tag>
          }
        />
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[2].width,
            textAlign: headers[2].align ? headers[2].align : "left",
          }}
          description={item.email}
        />
      </List.Item>
    );
  } else {
    return (
      <List.Item
        style={style}
        className={`${styles.mobileListItem} ${
          selectedRow === item._id ? styles.selected : ""
        }`}
        onClick={onClick}
      >
        <List.Item.Meta
          className={styles.customerId}
          title={item.companyName}
        />
        <List.Item.Meta
          className={styles.status}
          description={
            <Tag className={styles.statusTag} color={statusObj[item.status]}>
              {item.status}
            </Tag>
          }
        />
      </List.Item>
    );
  }
};

export default memo(SupplierItem);
