function toFixedIfNecessary(value) {
  return +parseFloat(value).toFixed(2);
}

export default async function generateFileSize(size) {
  try {
    let _size = size;
    if (size < 1000) {
      _size = `${toFixedIfNecessary(size)}B`;
    } else if (size < 1000000) {
      _size = size / 1000;
      _size = `${toFixedIfNecessary(_size)}KB`;
    } else if (size < 1000000000) {
      _size = size / 1000000;
      _size = `${toFixedIfNecessary(_size)}MB`;
    } else {
      _size = size / 1000000000;
      _size = `${toFixedIfNecessary(_size)}GB`;
    }
    return _size;
  } catch (err) {
    console.log("generateFileSize err", err);
  }
}
