import { combineReducers } from "redux";

import formState from "./formReducers";
import tableState from "./tableReducers";
import settingsState from "./settingsReducers";
import shareState from "./shareReducers";
import authState from "./authReducers";
import dataState from "./dataReducers";
import swState from "./swReducers";

export default combineReducers({
  formState,
  tableState,
  settingsState,
  shareState,
  authState,
  dataState,
  swState,
});
