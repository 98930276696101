function project(latLng) {
  var TILE_SIZE = 256;
  var siny = Math.sin((latLng.lat() * Math.PI) / 180);
  siny = Math.min(Math.max(siny, -0.9999), 0.9999);

  return new window.google.maps.Point(
    TILE_SIZE * (0.5 + latLng.lng() / 360),
    TILE_SIZE * (0.5 - Math.log((1 + siny) / (1 - siny)) / (4 * Math.PI))
  );
}

function getPixel(latLng, zoom) {
  var scale = 1 << zoom;
  var worldCoordinate = project(latLng);
  return new window.google.maps.Point(
    Math.floor(worldCoordinate.x * scale),
    Math.floor(worldCoordinate.y * scale)
  );
}

function getMapDimenInPixels(map) {
  var zoom = map.getZoom();
  var bounds = map.getBounds();
  var southWestPixel = getPixel(bounds.getSouthWest(), zoom);
  var northEastPixel = getPixel(bounds.getNorthEast(), zoom);
  return {
    width: Math.abs(southWestPixel.x - northEastPixel.x),
    height: Math.abs(southWestPixel.y - northEastPixel.y),
  };
}

function willAnimatePanTo(map, destLatLng, optionalZoomLevel) {
  var dimen = getMapDimenInPixels(map);

  var mapCenter = map.getCenter();
  optionalZoomLevel = !!optionalZoomLevel ? optionalZoomLevel : map.getZoom();

  var destPixel = getPixel(destLatLng, optionalZoomLevel);
  var mapPixel = getPixel(mapCenter, optionalZoomLevel);
  var diffX = Math.abs(destPixel.x - mapPixel.x);
  var diffY = Math.abs(destPixel.y - mapPixel.y);

  return diffX < dimen.width && diffY < dimen.height;
}

function getOptimalZoomOut(map, latLng, currentZoom) {
  if (willAnimatePanTo(map, latLng, currentZoom - 1)) {
    return currentZoom - 1;
  } else if (willAnimatePanTo(map, latLng, currentZoom - 2)) {
    return currentZoom - 2;
  } else {
    return currentZoom - 3;
  }
}

function smoothlyAnimatePanToWorkarround(
  map,
  destLatLng,
  optionalAnimationEndCallback
) {
  var initialZoom = map.getZoom(),
    listener;

  function zoomIn() {
    if (map.getZoom() < initialZoom) {
      map.setZoom(Math.min(map.getZoom() + 3, initialZoom));
    } else {
      window.google.maps.event.removeListener(listener);

      map.setOptions({
        draggable: true,
        scrollwheel: true,
        disableDoubleClickZoom: false,
      });

      if (!!optionalAnimationEndCallback) {
        optionalAnimationEndCallback();
      }
    }
  }

  function zoomOut() {
    if (willAnimatePanTo(map, destLatLng)) {
      window.google.maps.event.removeListener(listener);
      listener = window.google.maps.event.addListener(map, "idle", zoomIn);
      map.panTo(destLatLng);
      map.panBy(0, 50);
    } else {
      map.setZoom(getOptimalZoomOut(map, destLatLng, map.getZoom()));
    }
  }

  map.setOptions({
    draggable: false,
    zoomControl: false,
    scrollwheel: false,
    disableDoubleClickZoom: true,
  });
  map.setZoom(getOptimalZoomOut(map, destLatLng, initialZoom));
  listener = window.google.maps.event.addListener(map, "idle", zoomOut);
}

export default function smoothlyAnimatePanTo(map, destLatLng) {
  if (willAnimatePanTo(map, destLatLng)) {
    map.panTo(destLatLng);
    map.panBy(0, 50);
  } else {
    smoothlyAnimatePanToWorkarround(map, destLatLng);
  }
}
