import React, { useState } from "react";
import { useSelector } from "react-redux";
import Form from "antd/es/form";
import message from "antd/es/message";
import Button from "antd/es/button";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import api from "API";
import CancelButton from "Components/CancelButton";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";
import Checkbox from "antd/es/checkbox";

const messageKey = "messageKey";

const CreateAlbum = ({
  unsavedChanges,
  setUnsavedChanges,
  handleClose,
  type,
}) => {
  const formDetails = useSelector(
    (state) => state.formState.createAlbum.formDetails
  );
  const admin = useSelector((state) => state.authState.admin);
  const role = useSelector((state) => state.authState.role);
  const manager = useSelector((state) => state.authState.manager);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      // message.loading("Creating album...", 0);
      message.loading({
        content: "Creating album...",
        duration: 0,
        key: messageKey,
      });
      const res = await api.post("/albums/create", values);

      if (formDetails.callback) {
        formDetails.callback(res.data);
      }

      // message.success("Album created");
      message.success({
        content: "Album created",
        duration: 1.5,
        key: messageKey,
      });
      handleClose(true);
    } catch (err) {
      // message.error("Error placing project on hold");
      message.error({
        content: "Error creating album",
        duration: 1.5,
        key: messageKey,
      });
      console.log("err", err);
    }
  };

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error placing project on hold");
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      className="form"
    >
      <RemoveAutocomplete />

      <FormBody
        className="content-inner"
        style={{
          paddingLeft: 24,
          paddingTop: 24,
          paddingRight: 24,
          paddingBottom: 8,
          maxHeight: type === "modal" ? "calc(100vh - 161px)" : "unset",
        }}
      >
        <Form.Item
          name="albumName"
          label="Album Name"
          rules={[{ required: true, message: "Name is required" }]}
        >
          <InputNoAutoComplete />
        </Form.Item>

        {(admin === "Yes" || role === "Office" || manager === "Yes") && (
          <Form.Item name="global" valuePropName="checked">
            <Checkbox>Make this a global album</Checkbox>
          </Form.Item>
        )}
      </FormBody>
      <FormControls className="sticky-footer">
        <CancelButton handleClick={handleClose} />
        <Form.Item style={{ margin: 0 }}>
          <Button
            htmlType="submit"
            type="primary"
            loading={loading}
            disabled={loading || !unsavedChanges}
          >
            Create
          </Button>
        </Form.Item>
      </FormControls>
    </Form>
  );
};

export default CreateAlbum;
