import React, { useState, useEffect } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useSelector } from "react-redux";
import Form from "antd/es/form";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import SearchLeadsAndProjects from "Components/Inputs/SearchLeadsAndProjects";
import message from "antd/es/message";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import CancelButton from "Components/CancelButton";
import Button from "antd/es/button";
import api from "API";
import Loader from "Components/Loader";
import Steps from "antd/es/steps";
// import List from "antd/es/list";
import Drawer from "antd/es/drawer";
import styles from "./styles.module.scss";
import Input from "antd/es/input";

// import Material from "./Material";
import Notes from "./Notes";
import ExistingRoof from "./ExistingRoof";

import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";
import Select from "Components/Inputs/Select";
import openInNewTab from "Utils/openInNewTab";

import Row from "antd/es/row";
import Col from "antd/es/col";

import DeliveryDetails from "../WorkOrder/DeliveryDetails";
import ReviewSection from "../WorkOrder/ReviewSection";
import AerialCalculator from "../WorkOrder/AerialCalculator";
import HiddenFields from "../WorkOrder/HiddenFields";
import MaterialDeliveryDetails from "../WorkOrder/MaterialDeliveryDetails";
import CustomerDetails from "../WorkOrder/CustomerDetails";
import RoofingSteepDetails from "../WorkOrder/RoofingSteepDetails";
import RoofingFlatDetails from "../WorkOrder/RoofingFlatDetails";
import MaterialRow from "Components/materialOrderQuote/MaterialRow";
import Add from "Icons/Add";
import Remove from "Icons/Remove";
import dayjs from "dayjs";
import ProcessFeedback from "Components/ProcessFeedback";
import generateChanges from "Utils/generateChanges";

const messageKey = "messageKey";
let changedValues = {};

const customerDetails = [
  "customerName",
  "propertyType",
  "pitches",
  "stories",
  "projectAddressStreet",
  "projectAddressCity",
  "projectAddressState",
  "projectAddressZip",
  "projectAddressLat",
  "projectAddressLng",
  "projectAddressCounty",
  "projectAddressPermitAuthority",
  "projectDescription",
  "phone",
];

const steps = [
  "Submitting Form",
  "Generating PDF",
  "Creating Post",
  "Saving PDF",
  "Updating Scope",
  "Done!",
];

const PreJobInspection = ({
  unsavedChanges,
  setUnsavedChanges,
  handleClose,
  type,
  setTitle,
}) => {
  const formDetails = useSelector(
    (state) => state.formState.preJobInspection.formDetails
  );
  const details = useSelector((state) => state.dataState.details);
  const _user = useSelector((state) => state.authState._user);
  const [_projectId, _setProjectId] = useState(
    formDetails && formDetails._id
      ? formDetails._id
      : details && details._id
      ? details._id
      : undefined
  );
  const [loading, setLoading] = useState(false);
  const [scopes, setScopes] = useState([]);
  const [scope, setScope] = useState({});
  const [step, setStep] = useState(0);
  const [visible, setVisible] = useState(false);
  const [_fileList, _setFileList] = useState([]);
  const [hoContact, setHoContact] = useState("No");

  const [uploads, setUploads] = useState({});
  const [uploading, setUploading] = useState(false);

  const [aerial, setAerial] = useState(undefined);
  const [_lineItems, _setLineItems] = useState([]);
  const [selected, setSelected] = useState(undefined);
  const [startingRows, setStartingRows] = useState(0);
  const [_lat, _setLat] = useState(42.963795);
  const [_lng, _setLng] = useState(-85.670006);
  const [feedBack, setFeedBack] = useState({
    active: "Submitting Form",
    progress: 0,
  });

  const [form] = Form.useForm();

  useEffect(() => {
    if (_projectId && formDetails && formDetails._scopeId) {
      handleSelect({ _id: _projectId, _scopeId: formDetails._scopeId });
      // getAerial();
      // setWorkOrderFields();
    } else if (_projectId) {
      handleSelect({ _id: _projectId });
      // getAerial();
      // setWorkOrderFields();
    }

    return () => {
      setTitle("Pre-Job Inspection");
      _setProjectId(undefined);
      setScopes([]);
      setScope({});
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (scope._id) {
      setTitle(`Pre-Job Inspection (${scope.customerId})`);
      setTimeout(() => {
        const el = document.getElementById("steps");
        if (el) {
          el.classList.remove("ant-steps-vertical");
          el.classList.add("ant-steps-horizontal");
          el.classList.add("ant-steps-label-vertical");
        }
      });
      getAerial();
      setWorkOrderFields();
    }
  }, [scope]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const scrollRef = document.getElementById("pre-job-inspection-scroll");
    if (scrollRef) scrollRef.scrollTop = 0;
  }, [step]);

  useEffect(() => {
    handleSelectedChange();
  }, [selected]); // eslint-disable-line react-hooks/exhaustive-deps

  const scrollToBottom = () => {
    const scrollRef = document.getElementById("pre-job-inspection-scroll");
    if (scrollRef) scrollRef.scrollTo(0, scrollRef.scrollHeight);
  };

  const handleSelectedChange = async () => {
    try {
      if (!selected) {
        const lineItems = await form.getFieldValue("lineItems");
        if (lineItems) {
          lineItems.forEach((m, i) => {
            if (m.disabled) {
              form.validateFields([
                ["lineItems", i, "material"],
                ["lineItems", i, "type"],
                ["lineItems", i, "color"],
                ["lineItems", i, "quantity"],
              ]);
            }
          });
        }
      }
    } catch (err) {
      console.log("handleSelectedChange err", err);
    }
  };

  const setWorkOrderFields = () => {
    let shingle = [],
      siding = [];

    if (!scope.workOrder.shingle && !scope.workOrder.siding) {
      if (scope.workOrder.manufacturer) {
        shingle.push(scope.workOrder.manufacturer);
        siding.push(scope.workOrder.manufacturer);
      }
      if (scope.workOrder.productLine) {
        shingle.push(scope.workOrder.productLine);
        siding.push(scope.workOrder.productLine);
      }
      if (scope.workOrder.color) {
        shingle.push(scope.workOrder.color);
        siding.push(scope.workOrder.color);
      }
    } else if (scope.workOrder.shingle) {
      shingle = scope.workOrder.shingle;
    } else if (scope.workOrder.siding) {
      siding = scope.workOrder.siding;
    }

    // getDetails();
    setStartingRows(scope.workOrder.lineItems.length);
    _setLat(scope.workOrder.projectAddressLat);
    _setLng(scope.workOrder.projectAddressLng);
    _setLineItems(
      JSON.parse(
        JSON.stringify(
          scope.workOrder && scope.workOrder.lineItems
            ? scope.workOrder.lineItems.slice(0)
            : {}
        )
      )
    );
    form.setFieldsValue({
      ...scope.workOrder,
      shingle,
      siding,
      dateSold: dayjs(scope.workOrder.dateSold),
      buildDate: scope.buildDate ? dayjs(scope.buildDate) : undefined,
      originalInstallDate: scope.originalInstallDate
        ? dayjs(scope.originalInstallDate)
        : undefined,
      deliveryDate: scope.deliveryDate ? dayjs(scope.deliveryDate) : undefined,
    });
  };

  const getAerial = async () => {
    try {
      const res = await api.get(`/project-documents/get/${_projectId}`);
      if (res.data && res.data[0] && res.data[0].src) {
        setAerial(res.data[0].src);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const updateFeedBack = (feedBack) => {
    setFeedBack(feedBack);
  };

  const onFinish = async (values) => {
    try {
      window.socket.on(`${_user}-work-order-progress`, updateFeedBack);
      unstable_batchedUpdates(() => {
        setFeedBack({
          active: "Submitting Form",
          progress: 1,
        });
        setLoading(true);
      });
      // message.loading("Saving Pre-Job Inspection...", 0);
      message.loading({
        content: "Submiting pre-job inspection...",
        duration: 0,
        key: messageKey,
      });

      // message.loading("Marking passed...", 0);
      // message.loading({
      //   content: "Marking mold review passed...",
      //   duration: 0,
      //   key: messageKey,
      // });
      // let values = await form.validateFields();

      if (values.shingle) {
        values.manufacturer = values.shingle[0];
        values.productLine = values.shingle[1];
        values.color = values.shingle[2];
      }

      // console.log("values", values);

      values = await generateChanges({
        changedValues,
        values,
        customerDetails,
        _lineItems,
        workOrder: formDetails,
        woCreated: true,
      });

      values.projectAddress = `${values.projectAddressStreet}, ${values.projectAddressCity}, ${values.projectAddressState} ${values.projectAddressZip}`;
      values.companyName = formDetails.workOrder.companyName;
      // values.permitStatus = _permitStatus;

      // const res = await api.post("/mold-review/pass", values);

      // for (const s of res.data.salesObj) {
      //   window.socket.emit("updateDashboard", { _user: s._user });
      //   window.socket.emit("updateFeed", { _user: s._user });
      // }

      // window.socket.emit("update", { _id: formDetails._projectId });

      values.images = _fileList;
      values.scopeName = scope.name;
      values._projectId = scope._projectId;
      values._scopeId = scope._id;

      const res = await api.post("/scopes/pre-job-inspection", values);

      if (formDetails.callback) {
        formDetails.callback(res.data);
      }

      changedValues = {};
      unstable_batchedUpdates(() => {
        setUnsavedChanges(false);
        setLoading(false);
        setFeedBack({
          active: "Done!",
          progress: 100,
        });
      });
      // message.success("Inspection Saved");
      message.success({
        content: "Pre-job inspection submitted",
        duration: 1.5,
        key: messageKey,
      });
      window.socket.removeAllListeners(`${_user}-work-order-progress`);
      handleClose(true);
    } catch (err) {
      console.log("err", err);
      setLoading(false);
      // message.error("Error sending email");
      message.error({
        content: "Error submiting pre-job inspection",
        duration: 1.5,
        key: messageKey,
      });

      setFeedBack({
        active: "Submitting Form",
        progress: 0,
      });
      window.socket.removeAllListeners(`${_user}-work-order-progress`);
    }
  };

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error sending email");
  };

  // const onValuesChange = () => {
  //   if (!unsavedChanges) setUnsavedChanges(true);
  // };

  const handleSelect = async (selected) => {
    try {
      const res = await api.get(`/scopes/getAll/${selected._id}`);

      if (res.data.length === 1) {
        unstable_batchedUpdates(() => {
          if (res.data[0].workOrder && res.data[0].workOrder.dumpsterNotes) {
            form.setFieldsValue({
              dumpsterNotes: res.data[0].workOrder.dumpsterNotes,
            });
          }
          setScope(res.data[0]);
          _setProjectId(selected._id);
          // setWorkOrderFields();
        });
      } else if (selected._scopeId) {
        const _thisScope = res.data.find((s) => s._id === selected._scopeId);
        unstable_batchedUpdates(() => {
          if (_thisScope.workOrder && _thisScope.workOrder.dumpsterNotes) {
            form.setFieldsValue({
              dumpsterNotes: _thisScope.workOrder.dumpsterNotes,
            });
          }
          setScope(_thisScope);
          _setProjectId(selected._id);
          // setWorkOrderFields();
        });
      } else {
        unstable_batchedUpdates(() => {
          setScopes(res.data);
          _setProjectId(selected._id);
        });
      }
      // getAerial();
    } catch (err) {
      console.log("err", err);
    }
  };

  const cancel = () => {
    handleClose();
  };

  const handleScopeSelect = (_id) => {
    const _thisScope = scopes.find((s) => s._id === _id);
    setScope(_thisScope);
    // getAerial();
    // setWorkOrderFields();
    if (_thisScope.workOrder && _thisScope.workOrder.dumpsterNotes) {
      form.setFieldsValue({
        dumpsterNotes: _thisScope.workOrder.dumpsterNotes,
      });
    }
  };

  const handleToggleDrawer = () => {
    setVisible(!visible);
  };

  const goTo1 = () => {
    setStep(0);
  };

  const goTo2 = () => {
    // setStep(1);
    form
      .validateFields(["lineItems"])
      .then((values) => {
        const _lineItems = JSON.parse(JSON.stringify(values));
        let emptyRow = -1;
        _lineItems.lineItems.map((value, index) => {
          if (value.disabled) return value;
          if (
            value.color &&
            value.color.length > 0 &&
            value.material &&
            value.material.length > 0 &&
            value.quantity &&
            value.quantity.length > 0 &&
            value.type &&
            value.type.length > 0
          ) {
            // console.log("all values exist");
            return value;
          }

          if (emptyRow === -1) {
            emptyRow = index;
          }

          return value;
        });

        if (emptyRow === -1) {
          setStep(1);
        } else {
          message.error("All required fields must be completed");

          document.getElementById(`materialRow${emptyRow}`).scrollIntoView({
            behavior: "smooth",
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        message.error("All required fields must be completed");
        if (
          err &&
          err.errorFields &&
          err.errorFields[0] &&
          err.errorFields[0].name
        ) {
          form.scrollToField(err.errorFields[0].name[0]);
        }
      });
  };

  const goTo3 = async () => {
    try {
      const validationFields = [
        "layers",
        "gutters",
        "gutterCovers",
        "iwStuck",
        "dipsAndSags",
        "lowSlope",
        "shingleTieIn",
        "taper",
      ];

      const decking = await form.getFieldValue("decking");
      const chimney = await form.getFieldValue("chimney");

      if (decking) {
        for (let i = 0; i < decking.length; i++) {
          validationFields.push(["decking", i, "value"]);
        }
      }

      if (chimney) {
        for (let i = 0; i < chimney.length; i++) {
          validationFields.push(["chimney", i, "material"]);
          validationFields.push(["chimney", i, "over30"]);
          validationFields.push(["chimney", i, "buildSadle"]);
        }
      }

      await form.validateFields(validationFields);

      setStep(2);
      // .then(() => {
      //   setStep(() => 2);
      // })
      // .catch((err) => {
      //   message.error("All required fields must be completed");
      // });
    } catch (err) {
      console.log("err", err);
    }
  };

  const removeRow = async () => {
    try {
      let lineItems = await form.getFieldValue("lineItems");
      lineItems = lineItems.filter((m, i) => {
        if (
          !m.checkbox ||
          m.disabled ||
          (m.checkbox === true && i + 1 <= startingRows)
        ) {
          return true;
        }

        return false;
      });
      lineItems.forEach((m, i) => {
        if (m.checkbox || m.disabled) {
          m.disabled = true;
          m.checkbox = false;
          changedValues = {
            ...changedValues,
            [i]: {
              ...changedValues[i],
              disabled: true,
              index: i,
            },
          };
        }
      });
      form.setFieldsValue({
        lineItems,
      });
      setSelected(undefined);
    } catch (err) {
      console.log("err", err);
    }
  };

  const removeSingleRow = async (index) => {
    try {
      let lineItems = await form.getFieldValue("lineItems");
      if (index < startingRows) lineItems[index].disabled = true;
      changedValues = {
        ...changedValues,
        [index]: {
          ...changedValues[index],
          disabled: true,
          index,
        },
      };
      form.setFieldsValue({
        lineItems,
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleUnlock = async (index) => {
    try {
      let lineItems = await form.getFieldValue("lineItems");
      lineItems[index].disabled = false;
      changedValues = {
        ...changedValues,
        [index]: {
          ...changedValues[index],
          disabled: false,
          index,
        },
      };
      form.setFieldsValue({
        lineItems,
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const onValuesChange = (values) => {
    if (!unsavedChanges) setUnsavedChanges(true);

    let key = Object.keys(values)[0];
    let value = values[key];
    let index;

    if (key === "lineItems") {
      index = Object.keys(value)[0];

      let subValue = value[index];
      key = Object.keys(subValue)[0];
      value = subValue[key];
    }

    if (index && key !== "checkbox") {
      changedValues = {
        ...changedValues,
        [index]: {
          ...changedValues[index],
          [key]: value,
          index,
        },
      };
    } else if (key !== "checkbox") {
      changedValues = {
        ...changedValues,
        [key]: {
          value,
          key,
        },
      };
    }
  };

  const onFieldsChange = async (fields) => {
    try {
      if (fields.length > 0 && fields[0].name[2] === "checkbox") {
        let lineItems = await form.getFieldValue("lineItems");
        if (lineItems) {
          let arr = [];
          let cnt = 0;
          for (const m of lineItems) {
            if (m.checkbox) {
              arr.push(cnt);
              cnt++;
            }
          }
          if (arr.length === 0) arr = undefined;
          setSelected(arr);
        }
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleHoContactChange = (value) => {
    setHoContact(value);
  };

  const viewWorkOrder = () => {
    openInNewTab(scope.workOrder.src);
  };

  const viewAerial = () => {
    openInNewTab(aerial);
  };

  const goToInNewTab = () => {
    openInNewTab(
      `https://story.gopremier.net/projects/view/details/${_projectId}`
    );
  };

  return (
    <>
      <Form
        form={form}
        className="form"
        autoComplete="off"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={onValuesChange}
        onFieldsChange={onFieldsChange}
        initialValues={{}}
      >
        <RemoveAutocomplete />

        {!_projectId ? (
          <SearchLeadsAndProjects
            handleSelect={handleSelect}
            leads={false}
            disableLastViewed={true}
          />
        ) : (
          <>
            <div className={feedBack.progress === 0 ? "" : "hide"}>
              {scope._id ? (
                <>
                  <FormControls
                    style={{
                      borderTop: "none",
                      borderBottom: "1px solid #f0f0f0",
                      background: "#ffffff",
                      zIndex: 1,
                    }}
                  >
                    <Row gutter={16}>
                      <Col xs={aerial ? 8 : 12}>
                        <Button
                          type="primary"
                          className="green"
                          block
                          onClick={handleToggleDrawer}
                        >
                          Notes
                        </Button>
                      </Col>
                      <Col xs={aerial ? 8 : 0}>
                        <Button
                          type="primary"
                          className="green"
                          block
                          onClick={viewAerial}
                        >
                          View Aerial
                        </Button>
                      </Col>
                      <Col xs={aerial ? 8 : 12}>
                        <Button
                          type="primary"
                          className="green"
                          block
                          onClick={viewWorkOrder}
                        >
                          View WO
                        </Button>
                      </Col>
                    </Row>
                  </FormControls>

                  <FormBody
                    id="pre-job-inspection-scroll"
                    className="content-inner"
                    style={{
                      padding: 0,
                      overflowX: "hidden",
                      maxHeight:
                        type === "modal"
                          ? "calc(100vh - 161px - 53px)"
                          : "unset",
                      height:
                        type === "modal"
                          ? "100%"
                          : "calc(100% - 55px - 53px - 53px)",
                      marginTop: 0,
                    }}
                  >
                    <div
                      style={{ padding: 24, borderBottom: "1px solid #f0f0f0" }}
                    >
                      <Steps
                        id="steps"
                        labelPlacement="vertical"
                        direction="horizontal"
                        size="small"
                        current={step}
                      >
                        <Steps.Step title="Material" />
                        <Steps.Step title="Existing Roof" />
                        <Steps.Step title="Site Planning" />
                      </Steps>
                    </div>

                    <div
                      style={{
                        paddingTop: 24,
                        paddingLeft: 24,
                        paddingRight: 24,
                        paddingBottom: 0,
                      }}
                    >
                      <Button onClick={goToInNewTab} type="primary" block>
                        Go To In New Tab
                      </Button>
                    </div>

                    <AerialCalculator hidden form={form} />

                    <HiddenFields mold />

                    <MaterialDeliveryDetails hidden />

                    <CustomerDetails hidden _lat={_lat} _lng={_lng} />

                    {scope.name === "Roofing - Steep" ? (
                      <RoofingSteepDetails
                        hideAll
                        form={form}
                        // startingRows={startingRows}
                        // setStartingRows={setStartingRows}
                        // addFlat={() => {}}
                        review
                      />
                    ) : (
                      <RoofingFlatDetails
                        hideAll
                        form={form}
                        // startingRows={startingRows}
                        // setStartingRows={setStartingRows}
                      />
                    )}

                    <DeliveryDetails hidden />

                    <ReviewSection hideAll />

                    <div
                      className={step !== 0 ? "hide" : ""}
                      style={{
                        paddingLeft: 12,
                        paddingRight: 12,
                        paddingBottom: 66,
                      }}
                    >
                      {/* <List>
                      {scope.workOrder
                        ? scope.workOrder.lineItems.map((item) => (
                            <Material
                              key={
                                item._id
                                  ? item._id
                                  : `${item.material}${item.type}${item.color}${item.quantity}`
                              }
                              item={item}
                            />
                          ))
                        : null}
                    </List> */}

                      <Form.List name="lineItems">
                        {(fields, { add, remove }) => {
                          const handleAdd = () => {
                            add();
                            setTimeout(() => {
                              scrollToBottom();
                            }, 100);
                          };

                          const handleRemove = (i) => {
                            if (i < startingRows) {
                              removeSingleRow(i);
                            } else {
                              remove(i);
                            }
                          };

                          return (
                            <>
                              {fields.map((field, index) => (
                                <MaterialRow
                                  quote={true}
                                  key={field.key}
                                  fields={fields}
                                  field={field}
                                  index={index}
                                  form={form}
                                  selected={selected}
                                  handleRemove={handleRemove}
                                  handleUnlock={handleUnlock}
                                  forceMobile
                                />
                              ))}
                              <Row
                                gutter={{ xs: 8, sm: 8, md: 12, lg: 24 }}
                                style={{
                                  position:
                                    type === "modal" ? "absolute" : "fixed",
                                  bottom: 52,
                                  left: 0,
                                  right: 0,
                                  background: "#ffffff",
                                  borderTop: "1px solid #ededed",
                                  borderBottom: "1px solid #ededed",
                                }}
                              >
                                {!selected ? (
                                  <Col
                                    xs={24}
                                    // style={{
                                    //   position: "absolute",
                                    //   bottom: 48,
                                    //   left: 0,
                                    //   right: 0,
                                    // }}
                                  >
                                    <Form.Item
                                      style={{
                                        marginRight: 0,
                                        marginBottom: 0,
                                        padding: 12,
                                        // paddingRight: 12,
                                      }}
                                    >
                                      <Button
                                        className={`${styles.button} green`}
                                        // style={{ marginTop: 12 }}
                                        type="primary"
                                        onClick={handleAdd}
                                        block
                                      >
                                        <Add size={18} /> Add Material
                                      </Button>
                                    </Form.Item>
                                  </Col>
                                ) : (
                                  <Col
                                    xs={24}
                                    // style={{
                                    //   position: "absolute",
                                    //   bottom: 48,
                                    //   left: 0,
                                    //   right: 0,
                                    // }}
                                  >
                                    <Form.Item
                                      style={{
                                        padding: 12,
                                        marginBottom: 0,
                                        // paddingRight: 12,
                                      }}
                                    >
                                      <Button
                                        className={styles.button}
                                        // style={{ marginTop: 12 }}
                                        danger
                                        type="primary"
                                        onClick={removeRow}
                                        block
                                      >
                                        <Remove size={18} /> Remove Selected
                                      </Button>
                                    </Form.Item>
                                  </Col>
                                )}
                              </Row>
                            </>
                          );
                        }}
                      </Form.List>
                    </div>

                    <div className={step !== 1 ? "hide" : ""}>
                      <ExistingRoof />
                    </div>

                    <div
                      style={{ padding: 24 }}
                      className={step !== 2 ? "hide" : ""}
                    >
                      <Form.Item
                        name="parking"
                        label="Parking"
                        rules={[
                          { required: true, message: "Parking is required" },
                        ]}
                      >
                        <InputNoAutoComplete id="parking" />
                      </Form.Item>

                      <Form.Item
                        name="dumpsterNotes"
                        label="Dumpster Placement"
                        rules={[
                          {
                            required: true,
                            message: "Dumpster Placement is required",
                          },
                        ]}
                      >
                        <Input.TextArea
                          autoComplete="dont-use-autocomplete"
                          autosize={{ minRows: 1 }}
                        />
                      </Form.Item>

                      <Form.Item
                        name="hoContact"
                        label="HO Contact"
                        rules={[
                          { required: true, message: "HO Contact is required" },
                        ]}
                      >
                        <Select
                          options={["Yes", "No"]}
                          id="hoContact"
                          onChange={handleHoContactChange}
                        />
                      </Form.Item>

                      {hoContact === "Yes" && (
                        <Form.Item
                          name="contactDetails"
                          label="Contact Details"
                          rules={[
                            {
                              required: true,
                              message: "Contact Details is required",
                            },
                          ]}
                        >
                          <InputNoAutoComplete id="contactDetails" />
                        </Form.Item>
                      )}
                    </div>
                  </FormBody>

                  <FormControls className="sticky-footer">
                    <div className={step !== 0 ? "hide" : ""}>
                      <CancelButton handleClick={cancel} />
                      <Button type="primary" className="green" onClick={goTo2}>
                        Next
                      </Button>
                    </div>

                    <div className={step !== 1 ? "hide" : ""}>
                      <Button
                        type="link"
                        danger
                        style={{ float: "left" }}
                        onClick={goTo1}
                      >
                        Previous
                      </Button>
                      <Button type="primary" className="green" onClick={goTo3}>
                        Next
                      </Button>
                    </div>

                    <div className={step !== 2 ? "hide" : ""}>
                      <Button
                        type="link"
                        danger
                        style={{ float: "left" }}
                        onClick={goTo2}
                      >
                        Previous
                      </Button>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        disabled={loading || uploading}
                      >
                        Submit
                      </Button>
                    </div>
                  </FormControls>
                </>
              ) : scopes.length > 0 ? (
                <div
                  style={{ paddingTop: 24, paddingLeft: 24, paddingRight: 24 }}
                >
                  {scopes.map((s) => (
                    <ScopeSelect
                      key={s._id}
                      scope={s}
                      handleScopeSelect={handleScopeSelect}
                    />
                  ))}
                </div>
              ) : (
                <Loader minHeight="200px" />
              )}
            </div>

            <div
              style={{ height: "100%" }}
              className={feedBack.progress === 0 ? "hide" : ""}
            >
              <ProcessFeedback feedBack={feedBack} steps={steps} />
            </div>
          </>
        )}
        <Drawer
          placement="bottom"
          visible={visible}
          onClose={handleToggleDrawer}
          getContainer={type === "modal" ? false : true}
          style={{
            position: type === "modal" ? "absolute" : "fixed",
          }}
          width="100%"
          height="100%"
          zIndex={4001}
          className={`${styles.drawer} ${type === "modal" ? styles.modal : ""}`}
        >
          <Notes
            _projectId={_projectId}
            _fileList={_fileList}
            _setFileList={_setFileList}
            setUploading={setUploading}
            uploads={uploads}
            setUploads={setUploads}
            uploading={uploading}
          />
        </Drawer>
      </Form>
    </>
  );
};

const ScopeSelect = ({ scope, handleScopeSelect }) => {
  const scopeSelected = () => {
    handleScopeSelect(scope._id);
  };

  return (
    <Button
      type="primary"
      className="green"
      onClick={scopeSelected}
      style={{ marginBottom: 24 }}
      block
    >
      {scope.name}
    </Button>
  );
};

export default PreJobInspection;
