import React, { useState, useEffect, useCallback } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  updateScopesAndActivities,
  workOrderCreated,
} from "Actions/dataActions";
import { openForm } from "Actions/formActions";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Typography from "antd/es/typography";
import Button from "antd/es/button";
import Form from "antd/es/form";
import Grid from "antd/es/grid";
import message from "antd/es/message";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import Add from "Icons/Add";
import Remove from "Icons/Remove";
import Save from "Icons/Save";
import styles from "./styles.module.scss";
import api from "API";
import NamingTemplateModal from "Components/NamingTemplateModal";
import TemplatesDropdown from "Components/TemplatesAndFiltersDropdown";
import MaterialColumnHeaders from "Components/materialOrderQuote/MaterialColumnHeaders";
import MaterialRow from "Components/materialOrderQuote/MaterialRow";
import workOrderTemplates from "Constants/workOrderTemplates";
import CustomerDetails from "./CustomerDetails";
import RoofingSteepDetails from "./RoofingSteepDetails";
import Divider from "antd/es/divider";
import printJS from "print-js";
import openInNewTab from "Utils/openInNewTab";
import Eye from "Icons/Eye";
import Email from "Icons/Email";
import Printer from "Icons/Printer";
import DeliveryDetails from "./DeliveryDetails";
import RoofingFlatDetails from "./RoofingFlatDetails";
import GuaranteedRepairDetails from "./GuaranteedRepairDetails";
import OtherDetails from "./OtherDetails";
import GutterDetails from "./GutterDetails";
import SidingDetails from "./SidingDetails";
import dayjs from "dayjs";
import WindowDetails from "./WindowDetails";
import HammerWrench from "Icons/HammerWrench";
import CancelButton from "Components/CancelButton";
import generateChanges from "Utils/generateChanges";
import HiddenFields from "./HiddenFields";
import ReviewSection from "./ReviewSection";
import AerialCalculator from "./AerialCalculator";
import MaterialDeliveryDetails from "./MaterialDeliveryDetails";
import ProcessFeedback from "Components/ProcessFeedback";
import useSuppliers from "Hooks/useSuppliers";

const messageKey = "messageKey";

let changedValues = {};

const customerDetails = [
  "customerName",
  "propertyType",
  "projectAddressStreet",
  "projectAddressCity",
  "projectAddressState",
  "projectAddressZip",
  "projectAddressLat",
  "projectAddressLng",
  "projectAddressCounty",
  "projectAddressPermitAuthority",
  "projectDescription",
  "phone",
];

const steps = [
  "Submitting Form",
  "Generating PDF",
  "Creating Post",
  "Saving PDF",
  "Updating Scope",
  "Gathering All Media",
  "Done!",
];

const { useBreakpoint } = Grid;

const WorkOrder = ({
  unsavedChanges,
  setUnsavedChanges,
  setTitle,
  handleClose,
}) => {
  const dispatch = useDispatch();
  const formDetails = useSelector(
    (state) => state.formState.workOrder.formDetails
  );
  const allMedia = useSelector((state) => state.dataState.allMedia);
  const details = useSelector((state) => state.dataState.details);
  const viewableActivities = useSelector(
    (state) => state.dataState.viewableActivities
  );
  const screens = useBreakpoint();
  const suppliers = useSuppliers();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [globalTemplates, setGlobalTemplates] = useState([]);
  const [userTemplates, setUserTemplates] = useState([]);
  const [saveModal, setSaveModal] = useState(false);
  const [selected, setSelected] = useState(undefined);
  const [startingRows, setStartingRows] = useState(0);
  const [_lat, _setLat] = useState(details.projectAddressLat || 42.963795);
  const [_lng, _setLng] = useState(details.projectAddressLng || -85.670006);
  const [scrollContainer, setScrollContainer] = useState(null);
  const [editing, setEditing] = useState(formDetails.editing);
  const [woCreated, setWoCreated] = useState(formDetails.woCreated);
  const [woCreatedDate, setWoCreatedDate] = useState(formDetails.woCreatedDate);
  const [addFlat, setAddFlat] = useState(
    formDetails.flatManufacturer ? true : false
  );
  const [_gutterProtection] = useState(formDetails.gutterProtection);
  const [_propertyType, _setPropertyType] = useState(details.propertyType);
  const [laborOnly, setLaborOnly] = useState(
    formDetails.name === "Labor Only" && !formDetails.lineItems ? true : false
  );

  const [src, setSrc] = useState(formDetails.src);
  const [version, setVersion] = useState(formDetails.version || 0);
  const [_lineItems, _setLineItems] = useState({});
  const [permitCompleted, setPermitCompleted] = useState(
    formDetails.permitCompleted ? formDetails.permitCompleted : undefined
  );

  const _user = useSelector((state) => state.authState._user);
  const [feedBack, setFeedBack] = useState({
    active: "Submitting Form",
    progress: 0,
  });

  useEffect(() => {
    fetchTemplates();
    if (!editing) {
      setTitle("Create Work Order");
      if (
        formDetails.extendedWarranty === "3 Star" ||
        formDetails.extendedWarranty === "4 Star" ||
        formDetails.extendedWarranty === "5 Star" ||
        formDetails.extendedWarranty === "Signature Select" ||
        formDetails.extendedWarranty === "System Plus" ||
        formDetails.extendedWarranty === "Silver Pledge" ||
        formDetails.extendedWarranty === "Golden Pledge"
      ) {
        const lineItems = workOrderTemplates[formDetails.extendedWarranty];
        let pitches = [];
        let squares;
        if (
          details.roofMeasurements &&
          details.roofMeasurements.shingledArea > 0
        ) {
          const { roofMeasurements } = details;
          const wasteFactor = 1.12;
          const capLength = 22;
          const dripLength = 10;
          const dripWaste = 1.2;
          const ridgeLength = 4;
          const iceAndWaterLength = 180;
          const underlaymentLength = 1000;
          const valleyLength = 2;
          const _iceAndWaterLength = 60;
          const coilNailCount = 1500;
          const stepLength = 50;
          let starterLength = 140;
          if (
            formDetails.extendedWarranty !== "Signature Select" ||
            formDetails.extendedWarranty !== "System Plus"
          ) {
            starterLength = 120;
          }

          pitches = roofMeasurements.pitches;

          let iceAndWaterTotal =
            (roofMeasurements.valleys + roofMeasurements.eaves * valleyLength) /
            _iceAndWaterLength;

          let shingleLineItem = lineItems.find(
            (li) => li.material === "Shingles"
          );
          if (shingleLineItem)
            shingleLineItem.quantity = Math.round(
              (roofMeasurements.shingledArea * wasteFactor) / 100
            ).toString();

          squares = Math.round(
            (roofMeasurements.shingledArea * wasteFactor) / 100
          );

          let ridgeCapLineItem = lineItems.find(
            (li) => li.material === "Ridge Cap"
          );
          if (ridgeCapLineItem)
            ridgeCapLineItem.quantity = Math.ceil(
              ((roofMeasurements.ridges + roofMeasurements.hips) / capLength) *
                1.1
            ).toString();

          let tDripEdgeLineItem = lineItems.find(
            (li) => li.material === "Drip Edge" && li.type === "T / "
          );
          if (tDripEdgeLineItem)
            tDripEdgeLineItem.quantity = Math.ceil(
              ((roofMeasurements.rakes + roofMeasurements.eaves) * dripWaste) /
                dripLength
            ).toString();

          let cDripEdgeLineItem = lineItems.find(
            (li) => li.material === "Drip Edge" && li.type === "C / "
          );
          if (cDripEdgeLineItem)
            cDripEdgeLineItem.quantity = Math.ceil(
              ((roofMeasurements.rakes + roofMeasurements.eaves) * dripWaste) /
                dripLength
            ).toString();

          let ventilationLineItem = lineItems.find(
            (li) => li.material === "Ventilation"
          );
          if (ventilationLineItem)
            ventilationLineItem.quantity = Math.ceil(
              roofMeasurements.ridges / ridgeLength
            ).toString();

          let iceAndWaterLineItem = lineItems.find(
            (li) => li.material === "Ice & Water"
          );
          if (iceAndWaterLineItem)
            iceAndWaterLineItem.quantity = Math.ceil(
              (roofMeasurements.valleys +
                roofMeasurements.eaves * valleyLength) /
                _iceAndWaterLength
            ).toString();

          let underLaymentLineItem = lineItems.find(
            (li) => li.material === "Underlayment"
          );
          if (underLaymentLineItem)
            underLaymentLineItem.quantity = Math.ceil(
              (roofMeasurements.shingledArea -
                iceAndWaterTotal * iceAndWaterLength) /
                underlaymentLength
            ).toString();

          let starterLineItem = lineItems.find(
            (li) => li.material === "Starter Strips"
          );
          if (starterLineItem)
            starterLineItem.quantity = Math.ceil(
              ((roofMeasurements.rakes + roofMeasurements.eaves) /
                starterLength) *
                1.1
            ).toString();

          let stepFlashingLineItem = lineItems.find(
            (li) => li.material === "Step Flashing"
          );
          if (stepFlashingLineItem)
            stepFlashingLineItem.quantity = Math.ceil(
              roofMeasurements.step_flashing / stepLength
            ).toString();

          let coilNailsLineItem = lineItems.find(
            (li) => li.material === "Coil Nails" && li.type === '1 1/4"'
          );
          if (coilNailsLineItem)
            coilNailsLineItem.quantity = Math.ceil(
              roofMeasurements.shingledArea / coilNailCount
            ).toString();
        }

        form.setFieldsValue({
          lineItems,
          pitches,
          squares,
          _projectId: details._id,
          _id: formDetails._id,
          name: formDetails.name,
          customerId: details.customerId,
          woCreated: false,
          version: formDetails.version,
          _projectManagerId: details.projectManager._user,
          projectManagerName: details.projectManager.name,
          projectManagerPhone: details.projectManager.phone,
          projectManagerEmail: details.projectManager.email,
          extendedWarranty: formDetails.extendedWarranty,
          workmanshipWarranty: formDetails.premierWarranty,
          dateSold: dayjs(details.dateSold),
          laborOnly: false,
        });
        setStartingRows(
          workOrderTemplates[formDetails.extendedWarranty].length
        );
      } else if (
        formDetails.name === "Windows" ||
        formDetails.name === "Roofing - Steep"
      ) {
        const lineItems = workOrderTemplates[formDetails.name];
        let pitches = [];
        let squares;
        if (
          details.roofMeasurements &&
          details.roofMeasurements.shingledArea > 0 &&
          formDetails.name === "Roofing - Steep"
        ) {
          const { roofMeasurements } = details;
          const wasteFactor = 1.12;
          const capLength = 22;
          const dripLength = 10;
          const dripWaste = 1.2;
          const ridgeLength = 4;
          const iceAndWaterLength = 180;
          const underlaymentLength = 1000;
          const valleyLength = 2;
          const _iceAndWaterLength = 60;
          const coilNailCount = 1500;
          const stepLength = 50;
          let starterLength = 80;
          if (
            formDetails.extendedWarranty !== "Signature Select" ||
            formDetails.extendedWarranty !== "System Plus"
          ) {
            starterLength = 120;
          }

          pitches = roofMeasurements.pitches;

          let iceAndWaterTotal =
            (roofMeasurements.valleys + roofMeasurements.eaves * valleyLength) /
            _iceAndWaterLength;

          let shingleLineItem = lineItems.find(
            (li) => li.material === "Shingles"
          );
          if (shingleLineItem)
            shingleLineItem.quantity = Math.round(
              (roofMeasurements.shingledArea * wasteFactor) / 100
            ).toString();

          squares = Math.round(
            (roofMeasurements.shingledArea * wasteFactor) / 100
          );

          let ridgeCapLineItem = lineItems.find(
            (li) => li.material === "Ridge Cap"
          );
          if (ridgeCapLineItem)
            ridgeCapLineItem.quantity = Math.ceil(
              ((roofMeasurements.ridges + roofMeasurements.hips) / capLength) *
                1.1
            ).toString();

          let tDripEdgeLineItem = lineItems.find(
            (li) => li.material === "Drip Edge" && li.type === "T / "
          );
          if (tDripEdgeLineItem)
            tDripEdgeLineItem.quantity = Math.ceil(
              ((roofMeasurements.rakes + roofMeasurements.eaves) * dripWaste) /
                dripLength
            ).toString();

          let cDripEdgeLineItem = lineItems.find(
            (li) => li.material === "Drip Edge" && li.type === "C / "
          );
          if (cDripEdgeLineItem)
            cDripEdgeLineItem.quantity = Math.ceil(
              ((roofMeasurements.rakes + roofMeasurements.eaves) * dripWaste) /
                dripLength
            ).toString();

          let ventilationLineItem = lineItems.find(
            (li) => li.material === "Ventilation"
          );
          if (ventilationLineItem)
            ventilationLineItem.quantity = Math.ceil(
              roofMeasurements.ridges / ridgeLength
            ).toString();

          let iceAndWaterLineItem = lineItems.find(
            (li) => li.material === "Ice & Water"
          );
          if (iceAndWaterLineItem)
            iceAndWaterLineItem.quantity = Math.ceil(
              (roofMeasurements.valleys +
                roofMeasurements.eaves * valleyLength) /
                _iceAndWaterLength
            ).toString();

          let underLaymentLineItem = lineItems.find(
            (li) => li.material === "Underlayment"
          );
          if (underLaymentLineItem)
            underLaymentLineItem.quantity = Math.ceil(
              (roofMeasurements.shingledArea -
                iceAndWaterTotal * iceAndWaterLength) /
                underlaymentLength
            ).toString();

          let starterLineItem = lineItems.find(
            (li) => li.material === "Starter Strips"
          );
          if (starterLineItem)
            starterLineItem.quantity = Math.ceil(
              ((roofMeasurements.rakes + roofMeasurements.eaves) /
                starterLength) *
                1.1
            ).toString();

          let stepFlashingLineItem = lineItems.find(
            (li) => li.material === "Step Flashing"
          );
          if (stepFlashingLineItem)
            stepFlashingLineItem.quantity = Math.ceil(
              roofMeasurements.step_flashing / stepLength
            ).toString();

          let coilNailsLineItem = lineItems.find(
            (li) => li.material === "Coil Nails" && li.type === '1 1/4"'
          );
          if (coilNailsLineItem)
            coilNailsLineItem.quantity = Math.ceil(
              roofMeasurements.shingledArea / coilNailCount
            ).toString();
        }

        form.setFieldsValue({
          lineItems,
          pitches,
          squares,
          _projectId: details._id,
          _id: formDetails._id,
          name: formDetails.name,
          customerId: details.customerId,
          woCreated: false,
          version: formDetails.version,
          _projectManagerId: details.projectManager._user,
          projectManagerName: details.projectManager.name,
          projectManagerPhone: details.projectManager.phone,
          projectManagerEmail: details.projectManager.email,
          extendedWarranty: formDetails.extendedWarranty,
          workmanshipWarranty: formDetails.premierWarranty,
          dateSold: dayjs(details.dateSold),
          laborOnly: false,
        });
        setStartingRows(workOrderTemplates[formDetails.name].length);
      } else if (
        formDetails.name === "Siding" &&
        details.propertyType === "New Construction"
      ) {
        form.setFieldsValue({
          _projectId: details._id,
          _id: formDetails._id,
          name: formDetails.name,
          customerId: details.customerId,
          woCreated: false,
          version: formDetails.version,
          _projectManagerId: details.projectManager
            ? details.projectManager._user
            : undefined,
          projectManagerName: details.projectManager
            ? details.projectManager.name
            : undefined,
          projectManagerPhone: details.projectManager
            ? details.projectManager.phone
            : undefined,
          projectManagerEmail: details.projectManager
            ? details.projectManager.email
            : undefined,
          extendedWarranty: formDetails.extendedWarranty,
          workmanshipWarranty: formDetails.premierWarranty,
          dateSold: dayjs(details.dateSold),
          laborOnly: false,
          existingTrimDetails: "N/A",
          vaporBarrier: "N/A",
          buildDate: dayjs(),
          existingSiding: "N/A",
          tearOff: "No",
          dumpsterNeeded: "No",
        });
      } else if (formDetails.name === "Siding") {
        form.setFieldsValue({
          _projectId: details._id,
          _id: formDetails._id,
          name: formDetails.name,
          customerId: details.customerId,
          woCreated: false,
          version: formDetails.version,
          _projectManagerId: details.projectManager
            ? details.projectManager._user
            : undefined,
          projectManagerName: details.projectManager
            ? details.projectManager.name
            : undefined,
          projectManagerPhone: details.projectManager
            ? details.projectManager.phone
            : undefined,
          projectManagerEmail: details.projectManager
            ? details.projectManager.email
            : undefined,
          extendedWarranty: formDetails.extendedWarranty,
          workmanshipWarranty: formDetails.premierWarranty,
          dateSold: dayjs(details.dateSold),
          laborOnly: false,
          lineItems: workOrderTemplates.Siding,
        });
      } else {
        form.setFieldsValue({
          _projectId: details._id,
          _id: formDetails._id,
          name: formDetails.name,
          customerId: details.customerId,
          woCreated: false,
          version: formDetails.version,
          _projectManagerId: details.projectManager
            ? details.projectManager._user
            : undefined,
          projectManagerName: details.projectManager
            ? details.projectManager.name
            : undefined,
          projectManagerPhone: details.projectManager
            ? details.projectManager.phone
            : undefined,
          projectManagerEmail: details.projectManager
            ? details.projectManager.email
            : undefined,
          extendedWarranty: formDetails.extendedWarranty,
          workmanshipWarranty: formDetails.premierWarranty,
          dateSold: dayjs(details.dateSold),
          laborOnly: formDetails.name === "Labor Only" ? true : false,
        });
      }
    } else {
      setTitle("Edit Work Order");
      let shingle = [],
        siding = [];

      if (!formDetails.shingle && !formDetails.siding) {
        if (formDetails.manufacturer) {
          shingle.push(formDetails.manufacturer);
          siding.push(formDetails.manufacturer);
        }
        if (formDetails.productLine) {
          shingle.push(formDetails.productLine);
          siding.push(formDetails.productLine);
        }
        if (formDetails.color) {
          shingle.push(formDetails.color);
          siding.push(formDetails.color);
        }
      } else if (formDetails.shingle) {
        shingle = formDetails.shingle;
      } else if (formDetails.siding) {
        siding = formDetails.siding;
      }

      setStartingRows(formDetails.lineItems.length);
      _setLineItems(JSON.parse(JSON.stringify(formDetails.lineItems.slice(0))));
      setLaborOnly(formDetails.laborOnly ? true : false);

      form.setFieldsValue({
        ...formDetails,
        buildDate: dayjs(formDetails.buildDate),
        originalInstallDate: dayjs(formDetails.originalInstallDate),
        deliveryDate: formDetails.deliveryDate
          ? dayjs(formDetails.deliveryDate)
          : undefined,
        customerName: details.customerName,
        phone: details.phone,
        propertyType: details.propertyType,
        projectAddressStreet: details.projectAddressStreet,
        projectAddressCity: details.projectAddressCity,
        projectAddressState: details.projectAddressState,
        projectAddressZip: details.projectAddressZip,
        projectAddressCounty: details.projectAddressCounty,
        projectAddressPermitAuthority: details.projectAddressPermitAuthority,
        projectAddressLat: details.projectAddressLat,
        projectAddressLng: details.projectAddressLng,
        projectManagerName: details.projectManager.name,
        projectManagerPhone: details.projectManager.phone,
        projectManagerEmail: details.projectManager.email,
        projectDescription: details.projectDescription,
        originalPO: details.customerId,
        dateSold: dayjs(details.dateSold),
        officeReviewNote: formDetails.officeReviewNote,
        shingle,
        siding,
      });
    }

    return () => {
      form.resetFields();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (formDetails.supplier) {
      if (suppliers && Object.keys(suppliers).length > 0) {
        Object.keys(suppliers).map((s) => {
          if (suppliers[s].companyName === formDetails.supplier) {
            form.setFieldsValue({
              supplier: suppliers[s]._sub,
              supplierEmail: suppliers[s].email,
            });
          }
          return s;
        });
      }
    }
  }, [suppliers, formDetails.supplier, form]);

  useEffect(() => {
    handleSelectedChange();
  }, [selected]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectedChange = async () => {
    try {
      if (!selected) {
        const lineItems = await form.getFieldValue("lineItems");
        if (lineItems) {
          lineItems.forEach((m, i) => {
            if (m.disabled) {
              form.validateFields([
                ["lineItems", i, "material"],
                ["lineItems", i, "type"],
                ["lineItems", i, "color"],
                ["lineItems", i, "quantity"],
              ]);
            }
          });
        }
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  async function fetchTemplates() {
    try {
      const res = await api.get("/templates");
      unstable_batchedUpdates(() => {
        setGlobalTemplates(res.data._global);
        setUserTemplates(res.data._user);
      });
    } catch (err) {
      console.log("err", err);
    }
  }

  const updateFeedBack = (feedBack) => {
    setFeedBack(feedBack);
  };

  const onSaveAndSubmit = useCallback(async () => {
    try {
      window.socket.on(`${_user}-work-order-progress`, updateFeedBack);
      unstable_batchedUpdates(() => {
        setFeedBack({
          active: "Submitting Form",
          progress: 1,
        });
        setLoading(true);
      });

      // message.loading("Saving & Submitting Work Order...", 0);
      message.loading({
        content: "Submitting work order...",
        duration: 0,
        key: messageKey,
      });
      let values = await form.validateFields();

      if (values.shingle) {
        values.manufacturer = values.shingle[0];
        values.productLine = values.shingle[1];
        values.color = values.shingle[2];
      }
      if (values.siding) {
        values.manufacturer = values.siding[0];
        values.productLine = values.siding[1];
        values.color = values.siding[2];
        // TODO: need to set supplier with _sub
        // if (values.supplier) {
        //   values.supplier = suppliers[values.supplier]._sub;
        // }
        if (values.supplier) {
          values.supplierEmail = suppliers[values.supplier].email;
          values.supplier = suppliers[values.supplier].companyName;
        }
      }

      values = await generateChanges({
        changedValues,
        values,
        customerDetails,
        _lineItems,
        workOrder: details,
        woCreated,
        projectManagerChange: true,
      });

      values.projectAddress = `${values.projectAddressStreet}, ${values.projectAddressCity}, ${values.projectAddressState} ${values.projectAddressZip}`;
      values.companyName = details.companyName;

      if (!woCreatedDate) {
        values.woCreated = false;
      }

      const res = await api.post("/scopes/work-order/save-and-submit", values);

      dispatch(
        workOrderCreated({
          viewableActivities,
          _projectId: values._projectId,
          data: res.data,
        })
      );

      changedValues = {};

      unstable_batchedUpdates(() => {
        setVersion(res.data.version);
        setSrc(res.data.src);
        setWoCreated(true);
        setWoCreatedDate(new Date());
        setUnsavedChanges(false);
        setEditing(true);
        setLoading(false);
        _setLineItems(res.data._thisScope.workOrder.lineItems);
        setPermitCompleted(res.data._thisScope.permitCompleted);
        _setPropertyType(res.data._thisScope.workOrder.propertyType);
        setFeedBack({
          active: "Done!",
          progress: 100,
        });
      });
      form.setFieldsValue({
        src: res.data.src,
        version: res.data.version,
        woCreated: true,
        _activityId: res.data._thisScope.workOrder._activityId,
        _id: res.data._thisScope._id,
      });
      // message.success("Work order saved and submitted");
      message.success({
        content: "Work order submitted",
        duration: 1.5,
        key: messageKey,
      });

      window.socket.removeAllListeners(`${_user}-work-order-progress`);
    } catch (err) {
      console.log("err", err);
      if (
        err &&
        err.errorFields &&
        err.errorFields[0] &&
        err.errorFields[0].name
      ) {
        if (err.errorFields[0].name[0] === "lineItems") {
          const el = document.getElementById("top-of-form");
          el.scrollIntoView({ block: "end", behavior: "smooth" });
        } else {
          form.scrollToField(err.errorFields[0].name[0]);
        }
      }
      // message.error("An error occured while submitting");
      message.error({
        content: "Error submitting work order",
        duration: 1.5,
        key: messageKey,
      });
      setLoading(false);
      setFeedBack({
        active: "Submitting Form",
        progress: 0,
      });
      window.socket.removeAllListeners(`${_user}-work-order-progress`);
    }
  }, [
    _user,
    _lineItems,
    details,
    form,
    setUnsavedChanges,
    woCreated,
    woCreatedDate,
    viewableActivities,
    dispatch,
    suppliers,
  ]);

  const handleSave = useCallback(
    async (e) => {
      try {
        setSaveLoading(true);
        // message.loading("Work order saving...", 0);
        message.loading({
          content: "Saving work order...",
          duration: 0,
          key: messageKey,
        });
        let values = await form.getFieldsValue();

        if (values.shingle) {
          values.manufacturer = values.shingle[0];
          values.productLine = values.shingle[1];
          values.color = values.shingle[2];
        }
        if (values.siding) {
          values.manufacturer = values.siding[0];
          values.productLine = values.siding[1];
          values.color = values.siding[2];
        }

        values = await generateChanges({
          changedValues,
          values,
          customerDetails,
          _lineItems,
          workOrder: details,
          woCreated,
          projectManagerChange: true,
        });

        values.projectAddress = `${values.projectAddressStreet}, ${values.projectAddressCity}, ${values.projectAddressState} ${values.projectAddressZip}`;

        const res = await api.post("/scopes/work-order/save", values);

        dispatch(
          updateScopesAndActivities({
            viewableActivities,
            _projectId: values._projectId,
            data: res.data,
          })
        );

        changedValues = {};
        unstable_batchedUpdates(() => {
          setUnsavedChanges(false);
          setSaveLoading(false);
        });

        // message.success("Work order saved");
        message.success({
          content: "Work order saved",
          duration: 1.5,
          key: messageKey,
        });
      } catch (err) {
        console.log("err", err);
        // message.error("Error saving work order");
        message.error({
          content: "Error saving work order",
          duration: 1.5,
          key: messageKey,
        });
        setSaveLoading(false);
      }
    },
    [
      _lineItems,
      details,
      dispatch,
      form,
      setUnsavedChanges,
      viewableActivities,
      woCreated,
    ]
  );

  const setTemplate = async (template) => {
    try {
      let lineItems = await form.getFieldValue("lineItems");
      if (!lineItems) lineItems = [];
      lineItems = [...lineItems, ...template.material];
      form.setFieldsValue({
        lineItems,
      });
      setUnsavedChanges(true);
    } catch (err) {
      console.log("setTemplate err", err);
    }
  };

  const removeRow = async () => {
    try {
      let lineItems = await form.getFieldValue("lineItems");
      lineItems = lineItems.filter((m, i) => {
        if (
          !m.checkbox ||
          m.disabled ||
          (m.checkbox === true && i + 1 <= startingRows)
        ) {
          return true;
        }
        console.log("m", m);
        return false;
      });
      lineItems.forEach((m, i) => {
        if (m.checkbox || m.disabled) {
          m.disabled = true;
          m.checkbox = false;
          changedValues = {
            ...changedValues,
            [i]: {
              ...changedValues[i],
              disabled: true,
              index: i,
            },
          };
        }
      });
      form.setFieldsValue({
        lineItems,
      });
      setSelected(undefined);
    } catch (err) {
      console.log("err", err);
    }
  };

  const removeSingleRow = async (index) => {
    try {
      let lineItems = await form.getFieldValue("lineItems");
      if (index < startingRows) lineItems[index].disabled = true;
      changedValues = {
        ...changedValues,
        [index]: {
          ...changedValues[index],
          disabled: true,
          index,
        },
      };
      form.setFieldsValue({
        lineItems,
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleUnlock = async (index) => {
    try {
      let lineItems = await form.getFieldValue("lineItems");
      lineItems[index].disabled = false;
      changedValues = {
        ...changedValues,
        [index]: {
          ...changedValues[index],
          disabled: false,
          index,
        },
      };
      form.setFieldsValue({
        lineItems,
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const onValuesChange = useCallback(
    (values) => {
      if (!unsavedChanges) setUnsavedChanges(true);

      let key = Object.keys(values)[0];
      let value = values[key];
      let index;

      if (key === "lineItems") {
        index = Object.keys(value)[0];

        let subValue = value[index];
        if (subValue) {
          key = Object.keys(subValue)[0];
          value = subValue[key];
        }
      }

      if (index && key !== "checkbox") {
        changedValues = {
          ...changedValues,
          [index]: {
            ...changedValues[index],
            [key]: value,
            index,
          },
        };
      } else if (key !== "checkbox") {
        changedValues = {
          ...changedValues,
          [key]: {
            value,
            key,
          },
        };
      }
    },
    [setUnsavedChanges, unsavedChanges]
  );

  const onFieldsChange = async (fields) => {
    try {
      if (fields.length > 0 && fields[0].name[2] === "checkbox") {
        let lineItems = await form.getFieldValue("lineItems");
        if (lineItems) {
          let arr = [];
          let cnt = 0;
          for (const m of lineItems) {
            if (m.checkbox) {
              arr.push(cnt);
              cnt++;
            }
          }
          if (arr.length === 0) arr = undefined;
          setSelected(arr);
        }
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const setTemplates = ({ _global, _user }) => {
    setGlobalTemplates(_global);
    setUserTemplates(_user);
  };

  const showSaveModal = () => {
    setSaveModal(true);
  };

  const handleCancel = () => {
    handleClose();
  };

  const printWorkOrder = () => {
    printJS({
      printable: `${src}?${new Date().getTime()}`,
      type: "pdf",
    });
  };

  const openEmail = useCallback(async () => {
    try {
      const _projectId = await form.getFieldValue("_projectId");
      dispatch(
        openForm("email", {
          content: [
            {
              _id: src,
              src,
              _projectId,
              type: "work-order",
            },
          ],
        })
      );
    } catch (err) {
      console.log("err", err);
    }
  }, [form, dispatch, src]);

  const openWorkRequest = useCallback(async () => {
    try {
      const _scopeId = await form.getFieldValue("_id");
      let fileName = `${formDetails.name}-Work-Order-${version}`;
      fileName = fileName.replace(" - ", "-");
      fileName = fileName.replace(" ", "-");
      const workOrder = allMedia.find((m) => m.name === fileName);
      dispatch(
        openForm("workRequest", {
          content: [workOrder],
          _projectId: details._id,
          _scopeId,
        })
      );
    } catch (err) {
      console.log("err", err);
    }
  }, [form, dispatch, allMedia, formDetails.name, version, details._id]);

  const openWorkOrder = () => {
    openInNewTab(src);
  };

  const setFlatTemplate = async (value) => {
    try {
      if (value === "EPDM" || value === "Flintlastic") {
        const lineItems = await form.getFieldValue("lineItems");
        let _lineItems = [];
        if (lineItems) {
          _lineItems = [...lineItems, ...workOrderTemplates[value]];
        } else {
          _lineItems = workOrderTemplates[value];
        }
        setStartingRows(_lineItems.length);
        form.setFieldsValue({
          lineItems: _lineItems,
        });
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const handlePropertyTypeChange = (value) => {
    _setPropertyType(value);
  };

  const updateCords = ({ lat, lng }) => {
    _setLat(lat);
    _setLng(lng);
  };

  const updateStartingRows = (length) => {
    setStartingRows(length);
  };

  const toggleLaborOnly = () => {
    form.setFieldsValue({
      laborOnly: !laborOnly,
    });
    setLaborOnly(!laborOnly);
  };

  const _setSelected = (_selected) => {
    setSelected(_selected);
  };

  const _setUserTemplates = (_templates) => {
    setUserTemplates(_templates);
  };

  const toggleSaveModal = (_saveModal) => {
    setSaveModal(_saveModal);
  };

  const toggleAddFat = (_addFlat) => {
    setAddFlat(_addFlat);
    form.setFieldsValue({
      addFlat: _addFlat,
    });
  };

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      initialValues={{
        customerName: details.customerName,
        phone: details.phone,
        propertyType: details.propertyType,
        projectAddressStreet: details.projectAddressStreet,
        projectAddressCity: details.projectAddressCity,
        projectAddressState: details.projectAddressState,
        projectAddressZip: details.projectAddressZip,
        projectAddressCounty: details.projectAddressCounty,
        projectAddressPermitAuthority: details.projectAddressPermitAuthority,
        projectAddressLat: details.projectAddressLat,
        projectAddressLng: details.projectAddressLng,
        pitches: details.pitches,
        stories: details.stories,
        projectDescription: details.projectDescription,
        originalPO: details.customerId,
      }}
      className="form"
      onValuesChange={onValuesChange}
      onFieldsChange={onFieldsChange}
      scrollToFirstError={true}
    >
      <FormBody>
        <div
          className="content-inner p24"
          id="work-order-container"
          ref={setScrollContainer}
        >
          <div className={feedBack.progress !== 0 ? "hide" : ""}>
            <RemoveAutocomplete />

            <div id="top-of-form" />

            <HiddenFields />

            <MaterialDeliveryDetails />

            <AerialCalculator hidden form={form} />

            <CustomerDetails
              form={form}
              _lat={_lat}
              _lng={_lng}
              updateCords={updateCords}
              handlePropertyTypeChange={handlePropertyTypeChange}
            />

            <Divider />

            {formDetails.name === "Roofing - Steep" ? (
              <RoofingSteepDetails
                form={form}
                startingRows={startingRows}
                setStartingRows={updateStartingRows}
                setFlatTemplate={setFlatTemplate}
                addFlat={addFlat}
                setAddFlat={toggleAddFat}
              />
            ) : formDetails.name === "Roofing - Flat" ? (
              <RoofingFlatDetails
                form={form}
                startingRows={startingRows}
                setStartingRows={updateStartingRows}
                setFlatTemplate={setFlatTemplate}
              />
            ) : formDetails.name === "Siding" ? (
              <SidingDetails
                form={form}
                propertyType={_propertyType}
                toggleLaborOnly={toggleLaborOnly}
                laborOnly={laborOnly}
              />
            ) : formDetails.name === "Gutters" ? (
              <GutterDetails _gutterProtection={_gutterProtection} />
            ) : formDetails.name === "Windows" ? (
              <WindowDetails />
            ) : formDetails.name === "Other" ? (
              <OtherDetails />
            ) : formDetails.name === "Labor Only" ? (
              <OtherDetails
                showLaborButton
                laborOnly={laborOnly}
                setLaborOnly={toggleLaborOnly}
              />
            ) : (
              <GuaranteedRepairDetails />
            )}

            {formDetails.name !== "Gutters" && !laborOnly && (
              <>
                <DeliveryDetails />

                <Typography.Title level={4}>
                  Materails To Be Delivered
                </Typography.Title>

                {!screens.xs && (
                  <MaterialColumnHeaders
                    form={form}
                    selected={selected}
                    setSelected={_setSelected}
                    scrollContainer={scrollContainer}
                  />
                )}

                <Form.List name="lineItems">
                  {(fields, { add, remove }) => {
                    const handleAdd = () => {
                      add();
                    };

                    const handleRemove = (i) => {
                      if (i < startingRows) {
                        removeSingleRow(i);
                      } else {
                        remove(i);
                      }
                    };

                    return (
                      <>
                        {fields.map((field, index) => (
                          <MaterialRow
                            quote={true}
                            key={field.key}
                            fields={fields}
                            field={field}
                            index={index}
                            form={form}
                            selected={selected}
                            handleRemove={handleRemove}
                            handleUnlock={handleUnlock}
                          />
                        ))}
                        <Row gutter={{ xs: 8, sm: 8, md: 12, lg: 24 }}>
                          {!selected ? (
                            <Col xs={24}>
                              <Form.Item style={{ marginRight: 0 }}>
                                <Button
                                  className={`${styles.button} green`}
                                  style={
                                    screens.lg
                                      ? { marginTop: 12 }
                                      : screens.md
                                      ? { marginTop: 6 }
                                      : { marginTop: 12 }
                                  }
                                  type="primary"
                                  onClick={handleAdd}
                                  block
                                >
                                  <Add size={18} /> Add Material
                                </Button>
                              </Form.Item>
                            </Col>
                          ) : (
                            <>
                              <Col xs={12}>
                                <Form.Item>
                                  <Button
                                    className={styles.button}
                                    style={
                                      screens.lg
                                        ? { marginTop: 12 }
                                        : screens.md
                                        ? { marginTop: 6 }
                                        : { marginTop: 12 }
                                    }
                                    danger
                                    type="primary"
                                    onClick={removeRow}
                                    block
                                  >
                                    <Remove size={18} /> Remove Selected
                                  </Button>
                                </Form.Item>
                              </Col>
                              <Col xs={12}>
                                <Form.Item>
                                  <Button
                                    className={styles.button}
                                    style={
                                      screens.lg
                                        ? { marginTop: 12 }
                                        : screens.md
                                        ? { marginTop: 6 }
                                        : { marginTop: 12 }
                                    }
                                    type="primary"
                                    onClick={showSaveModal}
                                    block
                                  >
                                    <Save size={18} /> Save As Template
                                  </Button>
                                </Form.Item>
                              </Col>
                            </>
                          )}
                        </Row>
                      </>
                    );
                  }}
                </Form.List>
              </>
            )}
          </div>
          <div
            style={{ height: "100%" }}
            className={feedBack.progress === 0 ? "hide" : ""}
          >
            <ProcessFeedback feedBack={feedBack} steps={steps} />
          </div>
        </div>
      </FormBody>

      <ReviewSection hideAll />

      <FormControls className="sticky-footer">
        <CancelButton
          handleClick={handleCancel}
          title={feedBack.progress !== 100 ? "Cancel" : "Close"}
        />

        {feedBack.progress === 0 && (
          <TemplatesDropdown
            type="Template"
            btnStyle={{ float: "left" }}
            userArray={userTemplates}
            globalArray={globalTemplates}
            onClick={setTemplate}
            setArray={_setUserTemplates}
          />
        )}

        {!woCreated && (
          <Button
            type="link"
            loading={saveLoading}
            disabled={saveLoading || !unsavedChanges}
            onClick={handleSave}
            style={{ marginRight: 8 }}
          >
            Save
          </Button>
        )}

        {woCreated && !unsavedChanges && (
          <>
            <Button
              type="link"
              shape="circle"
              onClick={openWorkOrder}
              style={{ marginRight: 8 }}
            >
              <Eye />
            </Button>
            <Button
              type="link"
              shape="circle"
              onClick={openEmail}
              style={{ marginRight: 8 }}
            >
              <Email />
            </Button>
            <Button
              type="link"
              shape="circle"
              onClick={printWorkOrder}
              style={{ marginRight: 8 }}
            >
              <Printer />
            </Button>
            <Button
              disabled={
                !permitCompleted &&
                (formDetails.name === "Roofing - Steep" ||
                  formDetails.name === "Roofing - Flat")
              }
              type="link"
              shape="circle"
              onClick={openWorkRequest}
              // style={{ marginRight: 8 }}
            >
              <HammerWrench />
            </Button>
          </>
        )}

        {feedBack.progress === 0 && (
          <Button
            type="primary"
            loading={loading}
            disabled={loading || !unsavedChanges}
            onClick={onSaveAndSubmit}
          >
            Save & Submit
          </Button>
        )}
      </FormControls>

      <NamingTemplateModal
        visible={saveModal}
        onClose={toggleSaveModal}
        update={setTemplates}
        fieldName="lineItems"
        form={form}
        setSelected={setSelected}
      />
    </Form>
  );
};

export default WorkOrder;
