import * as actions from "./actionTypes";

export const openShare = ({
  content,
  _activityId,
  _projectId,
  callback,
}) => async (dispatch) => {
  try {
    dispatch({
      type: actions.OPEN_SHARE,
      payload: { content, _activityId, _projectId, callback },
    });
  } catch (err) {
    console.log("openShare err", err);
  }
};

export const closeShare = () => async (dispatch) => {
  try {
    dispatch({ type: actions.CLOSE_SHARE });
  } catch (err) {
    console.log("closeShare err", err);
  }
};
