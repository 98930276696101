import React, { useCallback } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { openImageViewer } from "Actions/dataActions";
import { useSelector, useDispatch } from "react-redux";
import FolderImage from "Icons/FolderImage";
import Image from "antd/es/image";
import Delete from "Icons/Delete";
import Eye from "Icons/Eye";
import message from "antd/es/message";
import useIsMobile from "Hooks/useIsMobile";
import noImage from "Assets/ina.jpg";
import api from "API";
import Button from "antd/es/button";
import Card from "antd/es/card";
import styles from "./styles.module.scss";
import Col from "antd/es/col";
import Modal from "antd/es/modal";

const AlbumImage = ({ image, album, setAlbums, setImages }) => {
  const dispatch = useDispatch();
  const admin = useSelector((state) => state.authState.admin);
  const role = useSelector((state) => state.authState.role);
  const manager = useSelector((state) => state.authState.manager);
  const mobile = useIsMobile();

  const handleImageClick = useCallback(() => {
    image.readOnly = true;
    dispatch(openImageViewer(image));
  }, [dispatch, image]);

  const showConfirm = (e) => {
    e.stopPropagation();
    Modal.confirm({
      centered: true,
      content: "Are you sure you want to delete this image?",
      onOk() {
        return new Promise((resolve, reject) => {
          deleteImage(() => {
            resolve(true);
          });
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() {},
    });
  };

  const deleteImage = async (cb) => {
    try {
      const res = await api.post(`/albums/image/delete/${album}`, {
        _id: image._id,
      });
      unstable_batchedUpdates(() => {
        const _album = res.data.filter((a) => a._id === album);
        setAlbums(res.data);
        setImages(_album[0].images);
      });
      cb();
    } catch (err) {
      console.log("err", err);
      message.error("Error deleting album");
    }
  };

  const setCoverImage = async () => {
    try {
      const res = await api.post("/albums/set-cover", {
        _albumId: album,
        _imageId: image._id,
      });

      unstable_batchedUpdates(() => {
        const _album = res.data.filter((a) => a._id === album._id);
        setAlbums(res.data);
        setImages(_album[0].images);
      });
    } catch (err) {
      console.log("setCoverImage err", err);
      message.error("Error setting cover image");
    }
  };

  const showFolder = () => {
    if (!album.global) {
      if (!image.cover) {
        return true;
      }
    } else {
      if (
        (admin === "Yes" || role === "Office" || manager === "Yes") &&
        !image.cover
      ) {
        return true;
      }
    }

    return false;
  };

  const showDelete = () => {
    if (!album.global) {
      return true;
    }
    if (admin === "Yes" || role === "Office" || manager === "Yes") {
      return true;
    }
    return false;
  };

  return (
    <Col xs={12} sm={6} key={image._id}>
      <Card
        className={styles.container}
        bordered={false}
        hoverable
        bodyStyle={{ padding: 0 }}
        cover={
          <Image
            preview={false}
            src={
              image.thumbnail
                ? image.thumbnail
                : image.src
                ? image.src
                : "error"
            }
            fallback={noImage}
          />
        }
        actions={
          mobile && showFolder() && showDelete()
            ? [
                <div onClick={handleImageClick}>
                  <Eye />
                </div>,
                <div onClick={setCoverImage}>
                  <FolderImage />
                </div>,
                <div onClick={showConfirm}>
                  <Delete />
                </div>,
              ]
            : mobile && !showFolder() && showDelete()
            ? [
                <div onClick={handleImageClick}>
                  <Eye />
                </div>,
                <div onClick={showConfirm}>
                  <Delete />
                </div>,
              ]
            : mobile
            ? [
                <div onClick={handleImageClick}>
                  <Eye />
                </div>,
              ]
            : null
        }
      >
        {!mobile && showFolder() && showDelete() ? (
          <div className={styles.hoverContainer}>
            <Button
              size="large"
              type="text"
              shape="circle"
              onClick={handleImageClick}
            >
              <Eye />
            </Button>
            <Button
              size="large"
              type="text"
              shape="circle"
              onClick={setCoverImage}
            >
              <FolderImage />
            </Button>
            <Button
              size="large"
              type="text"
              shape="circle"
              onClick={showConfirm}
            >
              <Delete />
            </Button>
          </div>
        ) : !mobile && !showFolder() && showDelete() ? (
          <div className={styles.hoverContainer}>
            <Button
              size="large"
              type="text"
              shape="circle"
              onClick={handleImageClick}
            >
              <Eye />
            </Button>

            <Button
              size="large"
              type="text"
              shape="circle"
              onClick={showConfirm}
            >
              <Delete />
            </Button>
          </div>
        ) : !mobile ? (
          <div className={styles.hoverContainer}>
            <Button
              size="large"
              type="text"
              shape="circle"
              onClick={handleImageClick}
            >
              <Eye />
            </Button>
          </div>
        ) : null}
      </Card>
    </Col>
  );
};

export default AlbumImage;
