import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openForm } from "Actions/formActions";
import Col from "antd/es/col";
import Card from "antd/es/card";
import Input from "antd/es/input";
import usePrevious from "Hooks/usePrevious";
import PermitAuthorities from "./PermitAuthorities";
import Button from "antd/es/button";
import PermitAuthority from "Forms/Permit/PermitAuthority";
import FormContainer from "Forms/FormContainer";
import Grid from "antd/es/grid";

const { useBreakpoint } = Grid;

const Municipalities = ({ projectAddressPermitAuthority, municipalities }) => {
  const dispatch = useDispatch();
  const permitAuthority = useSelector(
    (state) => state.formState.permitAuthority.open
  );
  const screens = useBreakpoint();
  const [search, setSearch] = useState(projectAddressPermitAuthority);
  const [filteredResults, setFiliteredResults] = useState(municipalities);
  const [_municipalities, _setMunicipalities] = useState(municipalities);

  const prevProjectAddressPermitAuthority = usePrevious(
    projectAddressPermitAuthority
  );

  useEffect(() => {
    _setMunicipalities(municipalities);
    setFiliteredResults(municipalities);
  }, [municipalities]);

  useEffect(() => {
    if (!prevProjectAddressPermitAuthority && projectAddressPermitAuthority) {
      setSearch(projectAddressPermitAuthority);

      let _filteredResults = [];

      if (search) {
        _filteredResults = _municipalities.filter((m) =>
          m.location
            .toLowerCase()
            .includes(projectAddressPermitAuthority.toLowerCase())
        );
      } else {
        _filteredResults = _municipalities;
      }
      setFiliteredResults(_filteredResults);
    } else {
      let _filteredResults = [];

      if (search) {
        _filteredResults = _municipalities.filter((m) =>
          m.location.toLowerCase().includes(search.toLowerCase())
        );
      } else {
        _filteredResults = _municipalities;
      }
      setFiliteredResults(_filteredResults);
    }
  }, [
    search,
    _municipalities,
    municipalities,
    prevProjectAddressPermitAuthority,
    projectAddressPermitAuthority,
  ]);

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const updateMunicipalities = useCallback((data) => {
    _setMunicipalities(data);
  }, []);

  const openAdd = useCallback(() => {
    dispatch(
      openForm("permitAuthority", {
        update: updateMunicipalities,
      })
    );
  }, [dispatch, updateMunicipalities]);

  return (
    <Col
      xs={24}
      sm={8}
      style={{
        borderRight: "1px solid #ededed",
        paddingLeft: 6,
        paddingRight: 6,
      }}
    >
      <Card
        className="z-depth-1"
        title="Municipality Search"
        style={{ marginBottom: 12 }}
      >
        <Input
          allowClear
          onChange={handleChange}
          placeholder="Search Municipalities"
          value={search}
          type="search"
          autoComplete="dont-use-autocomplete"
        />
      </Card>

      <Card
        className="z-depth-1"
        title="Municipalities"
        bodyStyle={{ padding: 0 }}
        extra={
          <Button type="link" size="small" onClick={openAdd}>
            Add New
          </Button>
        }
      >
        <PermitAuthorities
          municipalities={filteredResults}
          _setMunicipalities={_setMunicipalities}
        />
      </Card>

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0 },
        }}
        type={!screens.md ? "drawer" : "modal"}
        width={!screens.md ? "100%" : 320}
        title="Add Municipality"
        visible={permitAuthority}
        form={PermitAuthority}
        formType="permitAuthority"
        zIndex={4500}
      />
    </Col>
  );
};

export default Municipalities;
