import React from "react";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Typography from "antd/es/typography";
import currencyFormatter from "Utils/currencyFormatter";

const CommissionDetails = ({
  salesObj,
  origin,
  officeFee,
  regularCommission,
  managerCommission,
  salaryCommission,
  newRegularComission,
  joshHRegularComission,
  managerFee,
}) => {
  return (
    <Row gutter={16}>
      <Col xs={24}>
        <Typography.Title level={3}>Commission Details</Typography.Title>
      </Col>
      <Col xs={3}>
        <Typography.Text strong>Salesman:</Typography.Text> <br />
        <Typography.Text strong>Origin:</Typography.Text> <br />
        <Typography.Text strong>Office Fee:</Typography.Text> <br />
      </Col>
      <Col xs={9}>
        <Typography.Text type="secondary">
          {salesObj && salesObj.map((s) => s.name).join(", ")}
        </Typography.Text>
        <br />
        <Typography.Text type="secondary">{origin}</Typography.Text>
        <br />
        <Typography.Text type="secondary">
          {currencyFormatter(officeFee)}
        </Typography.Text>
        <br />
      </Col>

      <Col xs={4}>
        <Typography.Text strong>Manager Commission:</Typography.Text> <br />
        <Typography.Text strong>Regular Commission:</Typography.Text> <br />
        <Typography.Text strong>Joshua H. Commission:</Typography.Text> <br />
        <Typography.Text strong>New Regular Commission:</Typography.Text> <br />
        <Typography.Text strong>Salary Commission:</Typography.Text> <br />
        <Typography.Text strong>Manager Fee:</Typography.Text> <br />
      </Col>

      <Col xs={2} style={{ textAlign: "right", marginBottom: 24 }}>
        <Typography.Text type="secondary">
          {currencyFormatter(managerCommission)}
        </Typography.Text>
        <br />
        <Typography.Text type="secondary">
          {currencyFormatter(regularCommission)}
        </Typography.Text>
        <br />
        <Typography.Text type="secondary">
          {currencyFormatter(joshHRegularComission)}
        </Typography.Text>
        <br />
        <Typography.Text type="secondary">
          {currencyFormatter(newRegularComission)}
        </Typography.Text>
        <br />
        <Typography.Text type="secondary">
          {currencyFormatter(salaryCommission)}
        </Typography.Text>
        <br />
        <Typography.Text type="secondary">
          {currencyFormatter(managerFee)}
        </Typography.Text>
        <br />
      </Col>
    </Row>
  );
};

export default CommissionDetails;
