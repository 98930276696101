import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { openForm } from "Actions/formActions";
import ActionButtons from "Components/Details/ActionButtons";
import Scope from "./Scope";
import Tune from "Icons/Tune";
import styles from "./styles.module.scss";
import Button from "antd/es/button";

const Scopes = () => {
  const scopes = useSelector((state) => state.dataState.scopes);
  const dispatch = useDispatch();

  const openScope = () => {
    dispatch(openForm("scope"));
  };

  return (
    <div
      style={{
        height: "calc(100% - 64px - 34px)",
        position: "fixed",
        width: "100%",
        overflow: "hidden",
        background: "#ebebeb",
      }}
    >
      <div id="scopes-container" className="content-inner p12">
        <ActionButtons ghost narrow />

        <Button
          type="primary"
          onClick={openScope}
          block
          style={{ marginBottom: 12 }}
          className="green"
        >
          Add Scope
        </Button>

        {scopes.length > 0 ? (
          <>
            {scopes.map((scope) => (
              <Scope key={scope._id} scope={scope} scopes={scopes} />
            ))}
          </>
        ) : (
          <div className={styles.noScopes}>
            <Tune size={62} color="rgba(0,0,0,0.65)" />
            <div>No Scopes have been created</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Scopes;
