import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openShare } from "Actions/shareActions";
import { updateActivitiesAndSelectedActivity } from "Actions/dataActions";
import Button from "antd/es/button";
import Modal from "antd/es/modal";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";
import FormControls from "Components/FormControls";
import Delete from "Icons/Delete";
import ShareIcon from "Icons/Share";
import styles from "./styles.module.scss";
import api from "API";
import InfoCircleFilled from "@ant-design/icons/InfoCircleFilled";
import Popover from "antd/es/popover";

const twentyMB = 20971520;

const SelectingControls = ({
  setSelecting,
  selecting,
  selectedImages,
  selectedSize,
}) => {
  const dispatch = useDispatch();
  const selectedActivity = useSelector(
    (state) => state.dataState.selectedActivity
  );
  const _id = useSelector((state) => state.dataState.details._id);
  const viewableActivities = useSelector(
    (state) => state.dataState.viewableActivities
  );

  const handleDelete = () => {
    Modal.confirm({
      zIndex: 8001,
      icon: <QuestionCircleOutlined />,
      centered: true,
      cancelText: "Cancel",
      okText: "Delete",
      okButtonProps: { danger: true },
      content: (
        <div>
          Are you sure? This will permanetly delete{" "}
          {selectedImages.length > 1 ? "these images" : "this image"}.
        </div>
      ),
      onOk() {
        return new Promise((resolve, reject) => {
          handleDeleteImages(() => {
            setSelecting(false);
            resolve(true);
          });
        }).catch((err) => console.log("err: ", err));
      },
      onCancel() {},
    });
  };

  const handleDeleteImages = useCallback(
    async (cb) => {
      try {
        const res = await api.post(`/activity/delete-images`, {
          images: selectedImages,
        });

        dispatch(
          updateActivitiesAndSelectedActivity({
            viewableActivities,
            activities: res.data._activities,
            allMedia: res.data._allMedia,
            activity: selectedActivity,
            legacyFiles: res.data.legacyFiles,
            salesObj: res.data.salesObj,
            _projectId: selectedImages[0]._projectId || _id,
          })
        );

        cb();
      } catch (err) {}
    },
    [selectedImages, dispatch, viewableActivities, _id, selectedActivity]
  );

  const closeShare = useCallback(() => {
    setSelecting(false);
  }, [setSelecting]);

  const openShareMenu = useCallback(() => {
    dispatch(openShare({ content: selectedImages, callback: closeShare }));
  }, [dispatch, selectedImages, closeShare]);

  return (
    <FormControls
      className={`${styles.shareContentContainer} ${
        selecting ? styles.selecting : ""
      }`}
      style={{ zIndex: 1 }}
    >
      <div className={styles.shareContent}>
        <Button
          type="text"
          shape="circle"
          disabled={selectedImages.length === 0}
          style={{ width: 45 }}
          onClick={openShareMenu}
        >
          <ShareIcon />
        </Button>
        <div className={styles.center}>
          {selectedSize > twentyMB && (
            <Popover
              content="Selected exceeds limit, files will be listed as urls instead of attached."
              style={{ zIndex: 99999 }}
            >
              <InfoCircleFilled className={styles.infoCircle} />
            </Popover>
          )}{" "}
          {selectedImages.length} Items Selected
        </div>
        <Button
          type="text"
          shape="circle"
          disabled={selectedImages.length === 0}
          className={styles.delete}
          style={{ width: 45 }}
          onClick={handleDelete}
        >
          <Delete />
        </Button>
      </div>
    </FormControls>
  );
};

export default SelectingControls;
