import React, { useState } from "react";
import { useSelector } from "react-redux";
import api from "API";
import Image from "antd/es/image";
import dayjs from "dayjs";
import Delete from "Icons/Delete";
import Modal from "antd/es/modal";
import message from "antd/es/message";
import noImage from "Assets/ina.jpg";
import Col from "antd/es/col";
import Card from "antd/es/card";
import AlbumModal from "../AlbumModal";
import styles from "./styles.module.scss";

const Album = ({ album, setAlbums }) => {
  const admin = useSelector((state) => state.authState.admin);
  const role = useSelector((state) => state.authState.role);
  const manager = useSelector((state) => state.authState.manager);
  const [visible, setVisible] = useState(false);
  const [images, setImages] = useState([]);

  const albumClick = () => {
    setImages(album.images);
    setVisible(true);
  };

  const showConfirm = (e) => {
    e.stopPropagation();
    Modal.confirm({
      centered: true,
      content: "Are you sure you want to delete this album?",
      onOk() {
        return new Promise((resolve, reject) => {
          deleteAlbum(() => {
            resolve(true);
          });
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() {},
    });
  };

  const deleteAlbum = async (cb) => {
    try {
      const res = await api.delete(`/albums/delete/${album._id}`);
      setAlbums(res.data);
      cb();
    } catch (err) {
      console.log("err", err);
      message.error("Error deleting album");
    }
  };

  return (
    <Col xs={24} sm={12} md={6} key={album._id}>
      {!album.global ? (
        <Card
          onClick={albumClick}
          bordered={false}
          className={styles.albumCard}
          hoverable
          cover={
            <Image
              preview={false}
              src={
                album.images.length > 0
                  ? album.images[0].thumbnail
                    ? album.images[0].thumbnail
                    : album.images[0].src
                    ? album.images[0].src
                    : "error"
                  : "error"
              }
              fallback={noImage}
            />
          }
        >
          <Card.Meta
            title={
              <div>
                <span>{album.name}</span>
                <Delete className={styles.delete} onClick={showConfirm} />
              </div>
            }
            description={dayjs(album.created).format("MMMM D, YYYY")}
          />
        </Card>
      ) : (
        <Card
          onClick={albumClick}
          bordered={false}
          className={`${styles.global} ${styles.albumCard}`}
          hoverable
          cover={
            <Image
              preview={false}
              src={
                album.images.length > 0
                  ? album.images[0].thumbnail
                    ? album.images[0].thumbnail
                    : album.images[0].src
                    ? album.images[0].src
                    : "error"
                  : "error"
              }
              fallback={noImage}
            />
          }
        >
          <Card.Meta
            title={
              <div>
                <span>{album.name}</span>
                {(admin === "Yes" ||
                  role === "Office" ||
                  manager === "Yes") && (
                  <Delete className={styles.delete} onClick={showConfirm} />
                )}
              </div>
            }
            description={dayjs(album.created).format("MMMM D, YYYY")}
          />
        </Card>
      )}

      <AlbumModal
        visible={visible}
        setVisible={setVisible}
        images={images}
        album={album}
        setAlbums={setAlbums}
        setImages={setImages}
      />
    </Col>
  );
};

export default Album;
