import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Input from "antd/es/input";
import Form from "antd/es/form";
import Select from "antd/es/select";
import Button from "antd/es/button";
import message from "antd/es/message";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import useIsMobile from "Hooks/useIsMobile";
import api from "API";
import CancelButton from "Components/CancelButton";
import Steps from "antd/es/steps";
import styles from "./styles.module.scss";
import ImageSelector from "Components/images/ImageSelector";
import isVideo from "Utils/isVideo";
import YouTube from "Icons/YouTube";
import isPdf from "Utils/isPdf";
import Delete from "Icons/Delete";
import pdf from "Assets/pdf.svg";
import DatePicker from "Components/Inputs/DatePicker";
import dayjs from "dayjs";
import useSubContractor from "Hooks/useSubContractor";
import SubContractors from "Components/Inputs/SubContractors";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";
import InfoCircleFilled from "@ant-design/icons/InfoCircleFilled";
import Popover from "antd/es/popover";
import MarkUpHelper from "Components/MarkUpHelper";
import ImageFallback from "Components/images/Image";
import { unstable_batchedUpdates } from "react-dom";

import ProcessFeedback from "Components/ProcessFeedback";

const messageKey = "messageKey";

const steps = [
  "Submitting Form",
  "Creating Post",
  "Updating Scope",
  "Sending Email",
  "Updating Schedule",
  "Done!",
];

const twentyMB = 20971520;

const WorkRequest = ({
  unsavedChanges,
  setUnsavedChanges,
  handleClose,
  type,
}) => {
  const formDetails = useSelector(
    (state) => state.formState.workRequestRoof.formDetails
  );
  const [attachments, setAttachments] = useState(formDetails.content || []);
  const [attachmentSize, setAttachmentSize] = useState(0);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [scrollContainer, setScrollContainer] = useState(null);
  const [_allMedia] = useState(
    formDetails.allMedia ? formDetails.allMedia : []
  );
  const [form] = Form.useForm();
  const mobile = useIsMobile();
  const subs = useSubContractor(true);

  const _user = useSelector((state) => state.authState._user);
  const [feedBack, setFeedBack] = useState({
    active: "Submitting Form",
    progress: 0,
  });

  useEffect(() => {
    setTimeout(() => {
      const el = document.getElementById("steps");
      if (el) {
        el.classList.remove("ant-steps-vertical");
        el.classList.add("ant-steps-horizontal");
        el.classList.add("ant-steps-label-vertical");
      }
    });
  }, []);

  useEffect(() => {
    let _attachmentSize = 0;
    for (const a of attachments) {
      _attachmentSize += a.size;
    }
    setAttachmentSize(_attachmentSize);
  }, [attachments]);

  useEffect(() => {
    if (formDetails.assignToSub && subs && subs.length > 0) {
      let options = {};
      for (const a of formDetails.assignToSub) {
        options[a._sub] = subs[a._sub];
      }
      form.setFieldsValue({
        assignToSub: formDetails.assignToSub._sub,
      });
    }
  }, [formDetails.assignToSub, subs]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateFeedBack = (feedBack) => {
    setFeedBack(feedBack);
  };

  const onFinish = async (values) => {
    try {
      window.socket.on(`${_user}-work-order-progress`, updateFeedBack);
      unstable_batchedUpdates(() => {
        setFeedBack({
          active: "Submitting Form",
          progress: 1,
        });
        setLoading(true);
      });

      // message.loading("Sending Work Request...", 0);
      message.loading({
        content: "Sending work request...",
        duration: 0,
        key: messageKey,
      });
      values._projectId = formDetails._projectId;
      values._attachments = attachments;
      values.customerName = formDetails.customerName;
      values.customerId = formDetails.customerId;
      values.projectAddress = formDetails.projectAddress;
      values._scopeId = formDetails._scopeId;
      values.assignToSub = subs[values.assignToSub];
      if (formDetails.installDate) {
        values.originalInstallDate = formDetails.installDate;
        values.originalAssignToSub = formDetails.assignToSub;
      }

      const res = await api.post("/ready-for-schedule/work-request", values);

      if (formDetails.callback) {
        formDetails.callback(res.data);
      }

      // message.success("Work request sent");
      message.success({
        content: "Work request sent",
        duration: 1.5,
        key: messageKey,
      });
      setFeedBack({
        active: "Done!",
        progress: 100,
      });
      window.socket.removeAllListeners(`${_user}-work-order-progress`);
      handleClose(true);
    } catch (err) {
      console.log("err", err);
      setLoading(false);
      setFeedBack({
        active: "Submitting Form",
        progress: 0,
      });
      // message.error("Error sending work request");
      message.error({
        content: "Error sending work request",
        duration: 1.5,
        key: messageKey,
      });
      window.socket.removeAllListeners(`${_user}-work-order-progress`);
    }
  };

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error sending work request");
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  const removeImage = (img) => {
    setAttachments(attachments.filter((i) => i._id !== img._id));
  };

  const cancel = () => {
    handleClose();
  };

  const next = () => {
    setStep(1);
  };

  const previous = () => {
    setStep(0);
  };

  const onInstallDateChange = async (value) => {
    try {
      let _body = await form.getFieldValue("body");

      let splitBody = _body.split("\n\n");
      splitBody.pop();

      let body = "";

      for (const _b of splitBody) {
        body += `${_b}\n\n`;
      }

      body += `Please review the attached work order and pictures work to be started on ${dayjs(
        value
      ).format(
        "MMM D, YYYY"
      )}. After your consideration please select to either accept or deny the work offer by clicking the appropriate button below and following the prompts. Thank you for your time and consideration.`;

      form.setFieldsValue({
        body,
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleSubChange = async (sub) => {
    try {
      let newValue = [subs[sub].email];
      let toValue = await form.getFieldValue("to");
      if (toValue) {
        for (const t of toValue) {
          newValue.push(t);
        }
      }
      form.setFieldsValue({
        to: newValue,
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <Form
      layout="vertical"
      form={form}
      className="form"
      autoComplete="off"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      scrollToFirstError={true}
      initialValues={{
        assignToSub: formDetails.assignToSub
          ? formDetails.assignToSub._sub
          : undefined,
        installDate: formDetails.installDate
          ? dayjs(formDetails.installDate)
          : undefined,
        to: formDetails.emails ? formDetails.emails : undefined,
        subject: `Work Requested for ${
          formDetails.customerId ? formDetails.customerId : undefined
        }`,
        body: `**Customer ID/PO**: ${
          formDetails.customerId ? formDetails.customerId : undefined
        }\n**Customer Name**: ${
          formDetails.customerName ? formDetails.customerName : undefined
        }\n**Address**: ${
          formDetails.projectAddress ? formDetails.projectAddress : undefined
        }\n\n${
          formDetails.installDate
            ? `Please review the attached work order and pictures work to be started on ${dayjs(
                formDetails.installDate
              ).format(
                "MMM D, YYYY"
              )}. After your consideration please select to either accept or deny the work offer by clicking the appropriate button below and following the prompts. Thank you for your time and consideration.`
            : ""
        }`,
      }}
    >
      <RemoveAutocomplete />

      <FormBody>
        <div
          ref={setScrollContainer}
          className="content-inner"
          style={{
            paddingLeft: 24,
            paddingTop: 24,
            paddingRight: 24,
            paddingBottom: 8,
            maxHeight: type === "modal" ? "calc(100vh - 161px)" : "unset",
          }}
        >
          <Steps
            id="steps"
            className={styles.soldSteps}
            labelPlacement="vertical"
            size="small"
            current={step}
            style={{ marginBottom: 24 }}
          >
            <Steps.Step title="Files" />
            <Steps.Step title="Email" />
          </Steps>

          <div className={step === 0 && feedBack.progress === 0 ? "" : "hide"}>
            <ImageSelector
              allMedia={_allMedia}
              attachments={attachments}
              setAttachments={setAttachments}
              scrollContainer={scrollContainer}
            />
          </div>

          <div className={step === 1 && feedBack.progress === 0 ? "" : "hide"}>
            <Form.Item
              name="assignToSub"
              label="Sub to Assign"
              rules={[{ required: true, message: "Sub to Assign is required" }]}
            >
              <SubContractors subs={subs} onChange={handleSubChange} />
            </Form.Item>
            <Form.Item
              name="installDate"
              label="Install Date"
              rules={[{ required: true, message: "Install Date is required" }]}
            >
              <DatePicker
                format="MMMM DD, YYYY"
                inputReadOnly={true}
                getPopupContainer={(trigger) =>
                  !mobile ? trigger.parentNode : document.body
                }
                getCalendarContainer={(trigger) =>
                  !mobile ? trigger.parentNode : document.body
                }
                onChange={onInstallDateChange}
              />
            </Form.Item>
            <Form.Item
              label="To"
              name="to"
              rules={[
                {
                  required: true,
                  message: "You must include at least one email",
                },
              ]}
            >
              <Select mode="tags" placeholder="To" />
            </Form.Item>

            <Form.Item
              label="Subject"
              name="subject"
              rules={[
                {
                  required: true,
                  message: "You must include a subject",
                },
              ]}
            >
              <InputNoAutoComplete placeholder="Subject" id="subject" />
            </Form.Item>

            <Form.Item
              label={<MarkUpHelper title="Body" />}
              name="body"
              rules={[{ required: true, message: "You must include a body" }]}
            >
              <Input.TextArea rows={4} placeholder="Compose Email" />
            </Form.Item>

            {attachments.length > 0 && (
              <div className={styles.imageRow} style={{ paddingLeft: 0 }}>
                {attachments.map((attachment) => {
                  if (attachment) {
                    const handleRemove = () => {
                      removeImage(attachment);
                    };

                    return (
                      <div
                        key={attachment._id}
                        className={styles.commentImageRow}
                      >
                        <div className={styles.commentImage}>
                          {isPdf(attachment.src) ? (
                            <img src={pdf} alt="pdf-icon" />
                          ) : isVideo(attachment.src) ? (
                            <YouTube color="#f1f1f1" size={64} />
                          ) : (
                            <ImageFallback
                              alt="work-request-image"
                              image={{
                                src: attachment.src || undefined,
                                thumbnail: attachment.thumbnail || undefined,
                              }}
                            />
                          )}
                        </div>
                        <div className={styles.fileName}>{attachment.name}</div>
                        <Delete
                          className={styles.delete}
                          onClick={handleRemove}
                        />
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            )}
          </div>

          <div
            style={{ height: "100%" }}
            className={feedBack.progress === 0 ? "hide" : ""}
          >
            <ProcessFeedback feedBack={feedBack} steps={steps} />
          </div>
        </div>
      </FormBody>

      {feedBack.progress === 0 && (
        <FormControls className="sticky-footer">
          {step === 0 && (
            <>
              <CancelButton handleClick={cancel} />
              {attachmentSize > twentyMB && (
                <Popover
                  getPopupContainer={(trigger) => trigger}
                  content="Selected exceeds limit, files will be listed as urls instead of attached."
                  style={{ zIndex: 99999 }}
                >
                  <InfoCircleFilled className={styles.infoCircle} />
                </Popover>
              )}
              <Button
                type="primary"
                onClick={next}
                style={{ marginLeft: attachmentSize > twentyMB ? 4 : 0 }}
              >
                Compose Email
              </Button>
            </>
          )}

          {step === 1 && (
            <>
              <Button
                type="text"
                danger
                style={{ padding: "4px 8px", float: "left" }}
                onClick={previous}
              >
                Back
              </Button>
              {attachmentSize > twentyMB && (
                <Popover
                  getPopupContainer={(trigger) => trigger}
                  content="Selected exceeds limit, files will be listed as urls instead of attached."
                  style={{ zIndex: 99999 }}
                >
                  <InfoCircleFilled className={styles.infoCircle} />
                </Popover>
              )}

              <Button
                style={{ marginLeft: attachmentSize > twentyMB ? 4 : 0 }}
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={loading}
              >
                Send
              </Button>
            </>
          )}
        </FormControls>
      )}
    </Form>
  );
};

export default WorkRequest;
