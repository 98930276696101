import React from "react";
import Card from "antd/es/card";
import styles from "./styles.module.scss";
import ImageGrid from "Components/images/ImageGrid";

const LegacyFiles = ({ legacyFiles, scrollContainer }) => {
  return (
    <Card
      className={styles.legacyFiles}
      bodyStyle={{ padding: 0, borderRadius: 4 }}
      cover={
        <ImageGrid
          images={legacyFiles || []}
          scrollContainer={scrollContainer}
        />
      }
    />
  );
};

export default LegacyFiles;
