import React, { useState, useEffect, useCallback } from "react";
import { fetchTable } from "Actions/tableActions";
import { useDispatch, useSelector } from "react-redux";
import { openForm } from "Actions/formActions";
import Form from "antd/es/form";
import message from "antd/es/message";
import Button from "antd/es/button";
import Input from "antd/es/input";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import CancelButton from "Components/CancelButton";
import Map from "Components/Details/fields/Map";
import api from "API";
import CopyableContent from "Components/CopyableContent";
import styles from "./styles.module.scss";
import Card from "antd/es/card";
import pdf from "Assets/pdf.svg";
import isPdf from "Utils/isPdf";
import Row from "antd/es/row";
import Col from "antd/es/col";
import openInNewTab from "Utils/openInNewTab";
import Post from "Icons/Post";
import ImageFallback from "Components/images/Image";

const messageKey = "messageKey";

const OrderAerial = ({
  unsavedChanges,
  setUnsavedChanges,
  handleClose,
  setTitle,
  type,
}) => {
  const table = useSelector((state) => state.tableState.table);
  const col = useSelector((state) => state.tableState.col);
  const order = useSelector((state) => state.tableState.order);
  const search = useSelector((state) => state.tableState.search);
  const dispatch = useDispatch();
  const formDetails = useSelector(
    (state) => state.formState.orderAerial.formDetails
  );
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [aerials, setAerials] = useState([]);

  useEffect(() => {
    setTitle(
      `Order Aerial${
        formDetails.workOrder
          ? ` - ${formDetails.workOrder.projectManagerName}`
          : ""
      }`
    );

    fetchDocuments();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchDocuments = async () => {
    try {
      const res = await api.get(
        `/project-documents/get/${formDetails._projectId}`
      );
      setAerials(res.data);
    } catch (err) {
      message.error("Error retreiving documents");
      console.log("err", err);
    }
  };

  const onOrdered = useCallback(async () => {
    try {
      setLoading(true);
      // message.loading("Ordeing Aerial...", 0);
      message.loading({
        content: "Ordering aerial...",
        duration: 0,
        key: messageKey,
      });
      let values = await form.validateFields();

      values._projectId = formDetails._projectId;
      values._id = formDetails._id;

      await api.post("/aerial-verifications/order-aerial", values);

      dispatch(
        fetchTable({
          table,
          col,
          order,
          search,
          fetching: false,
        })
      );

      // message.success("Aerial ordered");
      message.success({
        content: "Aerial ordered",
        duration: 1.5,
        key: messageKey,
      });
      handleClose(true);
    } catch (err) {
      // message.error("Error ordering aerial");
      message.error({
        content: "Error ordering aerial",
        duration: 1.5,
        key: messageKey,
      });
      console.log("err", err);
      if (
        err &&
        err.errorFields &&
        err.errorFields[0] &&
        err.errorFields[0].name
      ) {
        if (err.errorFields[0].name[0] === "lineItems") {
          const el = document.getElementById("top-of-form");
          el.scrollIntoView({ block: "end", behavior: "smooth" });
        } else {
          form.scrollToField(err.errorFields[0].name[0]);
        }
      }
    }
  }, [
    form,
    formDetails._id,
    dispatch,
    table,
    col,
    order,
    search,
    handleClose,
    formDetails._projectId,
  ]);

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  const skipAerial = useCallback(async () => {
    try {
      // message.loading("Skipping Aerial...", 0);
      message.loading({
        content: "Skipping aerial...",
        duration: 0,
        key: messageKey,
      });
      let values = {};
      values._projectId = formDetails._projectId;
      values._id = formDetails._id;

      await api.post("/aerial-verifications/skip-aerial", values);

      dispatch(
        fetchTable({
          table,
          col,
          order,
          search,
          fetching: false,
        })
      );

      // message.success("Aerial skipped");
      message.success({
        content: "Aerial skipped",
        duration: 1.5,
        key: messageKey,
      });
      handleClose(true);
    } catch (err) {
      // message.error("Error skipping aerial");
      message.error({
        content: "Error skipping aerial",
        duration: 1.5,
        key: messageKey,
      });
      console.log("err", err);
    }
  }, [
    formDetails._projectId,
    formDetails._id,
    dispatch,
    table,
    col,
    order,
    search,
    handleClose,
  ]);

  const openActivity = useCallback(() => {
    dispatch(
      openForm("activity", {
        _id: formDetails._projectId,
        customerId: formDetails.customerId,
        customerName: formDetails.customerName,
        projectStatus: "Sold",
        projectManager: {
          name: formDetails.workOrder.projectManagerName,
        },
      })
    );
  }, [dispatch, formDetails]);

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      onValuesChange={onValuesChange}
      className="form"
      initialValues={{
        scope: formDetails.name,
        customerId: formDetails.customerId,
        projectAddress: formDetails.projectAddress,
        projectDescription: formDetails.projectDescription,
        scopeDescription: formDetails.scopeDescription,
      }}
    >
      <RemoveAutocomplete />

      <div id="top-of-form" />

      <FormBody
        className="content-inner"
        style={{
          paddingLeft: 24,
          paddingTop: 24,
          paddingRight: 24,
          paddingBottom: 8,
          maxHeight: type === "modal" ? "calc(100vh - 161px)" : "unset",
        }}
      >
        <h4 className="withIcon" style={{ marginBottom: 3 }}>
          Scope Name
        </h4>
        <Form.Item name="scope">
          <Input autoComplete="dont-use-autocomplete" disabled />
        </Form.Item>

        <h4 className="withIcon" style={{ marginBottom: 3 }}>
          Customer ID
          <CopyableContent
            title="Customer ID"
            content={formDetails.customerId}
            edit={false}
            single
          />
        </h4>
        <Form.Item name="customerId">
          <Input autoComplete="dont-use-autocomplete" disabled />
        </Form.Item>

        <h4 className="withIcon" style={{ marginBottom: 3 }}>
          Project Address
          <CopyableContent
            title="Project Address"
            content={formDetails.projectAddress}
            edit={false}
            single
          />
        </h4>
        <Form.Item name="projectAddress">
          <Input autoComplete="dont-use-autocomplete" disabled />
        </Form.Item>

        <Map
          type="projectAddress"
          lat={formDetails.projectAddressLat}
          lng={formDetails.projectAddressLng}
          readOnly
        />

        <h4 className="withIcon" style={{ marginBottom: 3 }}>
          Project Description
        </h4>
        <Form.Item name="projectDescription">
          <Input.TextArea autoComplete="dont-use-autocomplete" disabled />
        </Form.Item>

        <h4 className="withIcon" style={{ marginBottom: 3 }}>
          Scope Description
        </h4>
        <Form.Item name="scopeDescription">
          <Input.TextArea autoComplete="dont-use-autocomplete" disabled />
        </Form.Item>

        <Row gutter={8}>
          {aerials.map((media) => (
            <Aerial key={media._id} media={media} />
          ))}
        </Row>
      </FormBody>

      <Button
        onClick={openActivity}
        type="primary"
        danger
        shape="circle"
        className={styles.fab}
      >
        <Post />
      </Button>

      <FormControls className="sticky-footer">
        <CancelButton handleClick={handleClose} />
        <Button
          danger
          type="text"
          style={{ padding: "4px 8px", marginRight: 8 }}
          onClick={skipAerial}
        >
          Skip
        </Button>
        <Button
          onClick={onOrdered}
          type="primary"
          loading={loading}
          disabled={loading}
        >
          Aerial Ordered
        </Button>
      </FormControls>
    </Form>
  );
};

const Aerial = ({ media }) => {
  const type = isPdf(media.src) ? "pdf" : "image";

  const handleClick = () => {
    openInNewTab(media.src);
  };

  return (
    <Col xs={12}>
      <Card
        bordered={false}
        className={styles.activity}
        bodyStyle={{ padding: 0 }}
        onClick={handleClick}
        cover={
          type === "pdf" ? (
            <div className={styles.pdf}>
              <img src={pdf} alt="pdf-icon" />
              <div>{media.name}</div>
            </div>
          ) : type === "image" ? (
            <ImageFallback alt="order-aerial" image={media} />
          ) : null
        }
      ></Card>
    </Col>
  );
};

export default OrderAerial;
