import React, { useEffect, useState } from "react";
import Card from "antd/es/card";
import Input from "antd/es/input";
import styles from "./styles.module.scss";
import Typography from "antd/es/typography";
import api from "API";
import message from "antd/es/message";
import Button from "antd/es/button";
import Modal from "antd/es/modal";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";

const Version = () => {
  const [version, setVersion] = useState({});

  useEffect(() => {
    fetchVersion();
  }, []);

  const fetchVersion = async () => {
    try {
      const res = await api.get("/version/story");

      setVersion(res.data);
    } catch (err) {
      console.log("err", err);
      message.error("Error getting current version");
    }
  };

  const handleMajor = () => {
    const versionSplit = version.version.split(".");
    const major = parseInt(versionSplit[0]) + 1;
    const newVersion = `${major}.0.0`;

    Modal.confirm({
      zIndex: 7001,
      icon: <QuestionCircleOutlined />,
      centered: true,
      cancelText: "Cancel",
      okText: "Confirm",
      okButtonProps: { danger: true },
      content: (
        <div>
          Are you sure? This will change the version from{" "}
          <b>{versionSplit[0]}</b>.{`${versionSplit[1]}.${versionSplit[2]}`} to{" "}
          <b>{major}</b>.0.0
        </div>
      ),
      onOk() {
        return new Promise((resolve, reject) => {
          handleUpdate(newVersion, () => {
            resolve(true);
          });
        }).catch((err) => console.log("err: ", err));
      },
      onCancel() {},
    });
  };

  const handleMinor = () => {
    const versionSplit = version.version.split(".");
    const minor = parseInt(versionSplit[1]) + 1;
    const newVersion = `${versionSplit[0]}.${minor}.0`;

    Modal.confirm({
      zIndex: 7001,
      icon: <QuestionCircleOutlined />,
      centered: true,
      cancelText: "Cancel",
      okText: "Confirm",
      okButtonProps: { danger: true },
      content: (
        <div>
          Are you sure? This will change the version from {versionSplit[0]}.
          <b>{versionSplit[1]}</b>.{versionSplit[2]} to {versionSplit[0]}.
          <b>{minor}</b>.0
        </div>
      ),
      onOk() {
        return new Promise((resolve, reject) => {
          handleUpdate(newVersion, () => {
            resolve(true);
          });
        }).catch((err) => console.log("err: ", err));
      },
      onCancel() {},
    });
  };

  const handleBug = () => {
    const versionSplit = version.version.split(".");
    const bug = parseInt(versionSplit[2]) + 1;
    const newVersion = `${versionSplit[0]}.${versionSplit[1]}.${bug}`;

    Modal.confirm({
      zIndex: 7001,
      icon: <QuestionCircleOutlined />,
      centered: true,
      cancelText: "Cancel",
      okText: "Confirm",
      okButtonProps: { danger: true },
      content: (
        <div>
          Are you sure? This will change the version from {versionSplit[0]}.
          {versionSplit[1]}.<b>{versionSplit[2]}</b> to {versionSplit[0]}.
          {versionSplit[1]}.<b>{bug}</b>
        </div>
      ),
      onOk() {
        return new Promise((resolve, reject) => {
          handleUpdate(newVersion, () => {
            resolve(true);
          });
        }).catch((err) => console.log("err: ", err));
      },
      onCancel() {},
    });
  };

  const handleUpdate = async (_version, cb) => {
    try {
      await api.post("/version/update", {
        app: "story",
        currentVersion: version.version,
        version: _version,
      });

      setVersion((v) => {
        return {
          ...v,
          version: _version,
        };
      });
      cb();
    } catch (err) {
      console.log("err", err);
      message.error("Error updating version");
    }
  };

  return (
    <div className={styles.container}>
      <Card
        className={styles.card}
        actions={[
          <Button
            onClick={handleMajor}
            size="small"
            type="link"
            style={{ padding: 0 }}
          >
            Major
          </Button>,
          <Button
            onClick={handleMinor}
            size="small"
            type="link"
            style={{ padding: 0 }}
          >
            Minor
          </Button>,
          <Button
            onClick={handleBug}
            size="small"
            type="link"
            style={{ padding: 0 }}
          >
            Bug
          </Button>,
        ]}
      >
        <Typography.Title level={3}>Version</Typography.Title>
        <Input disabled value={version.version} />
      </Card>
    </div>
  );
};

export default Version;
