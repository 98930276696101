import React, { useState } from "react";
import AutoComplete from "antd/es/auto-complete";
import api from "API";
import addressComponents from "Utils/addressComponents";
import Input from "antd/es/input";

const InputAddress = ({
  onChange,
  autoComplete = "lookup",
  value,
  setAddress = () => false,
  disabled = false,
  id = undefined,
  autoFocus,
}) => {
  const [options, setOptions] = useState([
    {
      value: "0 Suggestions Found",
    },
  ]);

  const triggerChange = (changedValue) => {
    if (onChange) {
      onChange(changedValue);
    }
  };

  const handleSearch = async (search) => {
    triggerChange(search);
    try {
      if (search.length > 0) {
        const content = await api.get(`/addressLookup/${search}`);
        const _options = [];
        for (const prediction of content.data.predictions) {
          _options.push({
            value: prediction.description,
          });
        }
        setOptions(_options);
      } else {
        setOptions([
          {
            description: "0 Suggestions Found",
          },
        ]);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const onFocus = async (e) => {
    try {
      const search = e.target.value;
      if (search.length > 0) {
        const content = await api.get(`/addressLookup/${search}`);
        const _options = [];
        for (const prediction of content.data.predictions) {
          _options.push({
            value: prediction.description,
          });
        }
        setOptions(_options);
      } else {
        setOptions([
          {
            description: "0 Suggestions Found",
          },
        ]);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const onSelect = async (search) => {
    try {
      if (search !== "0 Suggestions Found") {
        const result = await api.get(`/addressComponents/${search}`);
        const components = addressComponents(result.data.results);
        triggerChange(components.street);
        setAddress(components);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <div id={id}>
      <AutoComplete
        autoComplete={autoComplete}
        onSearch={handleSearch}
        value={value}
        options={options}
        onFocus={onFocus}
        onSelect={onSelect}
        allowClear
        getPopupContainer={(trigger) =>
          trigger.parentNode.parentNode.parentNode.parentNode.parentNode
        }
        disabled={disabled}
      >
        <div>
          <label htmlFor="" style={{ display: "none" }}>
            hidden label to mislead chrome autocomplete
          </label>
          <Input
            autoFocus={autoFocus}
            value={value}
            allowClear
            autoComplete="dont-use-autocomplete"
          />
        </div>
      </AutoComplete>
    </div>
  );
};

export default InputAddress;
