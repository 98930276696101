import React from "react";
import Form from "antd/es/form";
import Currency from "Components/Inputs/Currency";

const Draw = () => {
  return (
    <>
      <Form.Item
        label="Amount Requested"
        name="amount"
        rules={[
          {
            required: true,
            message: `Please provide an amount`,
          },
        ]}
      >
        <Currency id="amount" />
      </Form.Item>
      *Any requests received after Tuesday may not be applied this weeks
      payroll.
    </>
  );
};

export default Draw;
