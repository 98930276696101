export default function addressComponents(arr) {
  const components = {};

  const formatted_address = arr[0].formatted_address;

  const addressArray = formatted_address.split(", ");

  components.street = addressArray[0];
  components.city = addressArray[1];
  components.state = addressArray[2].split(" ")[0];
  components.postcode = addressArray[2].split(" ")[1];
  components.lat = arr[0].geometry.location.lat;
  components.lng = arr[0].geometry.location.lng;

  const array = arr[0].address_components;

  array.find((x) => {
    if (x.types.includes("administrative_area_level_2")) {
      components.county = x.long_name;
      return true;
    } else {
      return false;
    }
  });

  array.find((x) => {
    if (x.types.includes("sublocality_level_5")) {
      if (
        components.permitAuthority === undefined ||
        components.permitAuthority === "" ||
        components.permitAuthority === null
      ) {
        components.permitAuthority = x.long_name;
      }
      return true;
    } else {
      return false;
    }
  });

  array.find((x) => {
    if (x.types.includes("sublocality_level_4")) {
      if (
        components.permitAuthority === undefined ||
        components.permitAuthority === "" ||
        components.permitAuthority === null
      ) {
        components.permitAuthority = x.long_name;
      }
      return true;
    } else {
      return false;
    }
  });

  array.find((x) => {
    if (x.types.includes("sublocality_level_3")) {
      if (
        components.permitAuthority === undefined ||
        components.permitAuthority === "" ||
        components.permitAuthority === null
      ) {
        components.permitAuthority = x.long_name;
      }
      return true;
    } else {
      return false;
    }
  });

  array.find((x) => {
    if (x.types.includes("sublocality_level_2")) {
      if (
        components.permitAuthority === undefined ||
        components.permitAuthority === "" ||
        components.permitAuthority === null
      ) {
        components.permitAuthority = x.long_name;
      }
      return true;
    } else {
      return false;
    }
  });

  array.find((x) => {
    if (x.types.includes("sublocality_level_1")) {
      if (
        components.permitAuthority === undefined ||
        components.permitAuthority === "" ||
        components.permitAuthority === null
      ) {
        components.permitAuthority = x.long_name;
      }
      return true;
    } else {
      return false;
    }
  });

  array.find((x) => {
    if (x.types.includes("administrative_area_level_5")) {
      if (
        components.permitAuthority === undefined ||
        components.permitAuthority === "" ||
        components.permitAuthority === null
      ) {
        components.permitAuthority = x.long_name;
      }
      return true;
    } else {
      return false;
    }
  });

  array.find((x) => {
    if (x.types.includes("administrative_area_level_4")) {
      if (
        components.permitAuthority === undefined ||
        components.permitAuthority === "" ||
        components.permitAuthority === null
      ) {
        components.permitAuthority = x.long_name;
      }
      return true;
    } else {
      return false;
    }
  });

  array.find((x) => {
    if (x.types.includes("administrative_area_level_3")) {
      if (
        components.permitAuthority === undefined ||
        components.permitAuthority === "" ||
        components.permitAuthority === null
      ) {
        components.permitAuthority = x.long_name;
      }
      return true;
    } else {
      return false;
    }
  });

  array.find((x) => {
    if (x.types.includes("locality")) {
      if (
        components.permitAuthority === undefined ||
        components.permitAuthority === "" ||
        components.permitAuthority === null
      ) {
        components.permitAuthority = x.long_name;
      }
      return true;
    } else {
      return false;
    }
  });

  return components;
}
