import React, {
  useCallback,
  //  useState
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { openImageViewer } from "Actions/dataActions";
import Card from "antd/es/card";
import Button from "antd/es/button";
import timeStamp from "Utils/timeStamp";
import styles from "./styles.module.scss";
import FormBody from "Components/FormBody";
import ImageGrid from "Components/images/ImageGrid";
import Controls from "Components/Activities/Controls";
import Header from "Components/Activities/Header";
import Comments from "Components/Activities/Comments";
import Camera from "Icons/Camera";
import FormControls from "Components/FormControls";
import insane from "insane";
import ImageFallback from "Components/images/Image";
import api from "API";

const Sanitized = ({ html }) => (
  <div
    className={styles.sanitizedBody}
    dangerouslySetInnerHTML={{
      __html: insane(html, {
        allowedTags: [
          "p",
          "strong",
          "em",
          "a",
          "b",
          "i",
          "span",
          "div",
          "br",
          "u",
          "img",
        ],
      }),
    }}
  />
);

// const Ellipsis = ({ expand }) => (
//   <Button
//     size="small"
//     shape="round"
//     type="primary"
//     className={styles.ellipsis}
//     onClick={expand}
//   >
//     ...see more
//   </Button>
// );

const Activity = ({
  activity,
  dashboard = false,
  scrollContainer,
  fetchPosts = undefined,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const _user = useSelector((state) => state.authState._user);
  // const [showComments, setShowComments] = useState(false);
  // const [expanded, setExpanded] = useState(false);

  const activityDate = timeStamp(activity.created);

  const {
    _id,
    _projectId,
    body,
    avatar,
    createdBy,
    images,
    likes,
    followers,
    comments,
    hashtags,
    link,
  } = activity;

  let content = "";
  if (body) {
    content = body;
  }

  const openImage = useCallback(
    (image) => {
      image._projectId = _projectId;
      image.link = true;
      dispatch(openImageViewer(image));
    },
    [dispatch, _projectId]
  );

  // const isLead = (status) => {
  //   if (status === "Lead" || status === "Lead Lost" || status === "Estimate") {
  //     return true;
  //   }
  //   return false;
  // };

  // const goTo = () => {
  //   navigate(
  //     `/${location.pathname.split("/")[1]}/${
  //       isLead(activity.projectStatus) ? "leads" : "projects"
  //     }/view/posts/${_projectId}?${_id}`
  //   );
  // };

  const getStatusAndGo = async (e) => {
    try {
      e.stopPropagation();
      const response = await api.get(`/get-state/${_projectId}`);
      navigate(
        `/${location.pathname.split("/")[1]}/${
          response.data
        }/view/posts/${_projectId}?${_id}`
      );
    } catch (err) {
      console.log("getStatusAndGo err", err);
    }
  };

  const previewImage = () => {
    openImage({ ...link, _activityId: _id });
  };

  // const toggleShowComments = () => {
  //   setShowComments(!showComments);
  // };

  // const expand = () => {
  //   setExpanded(true);
  // };

  return (
    <Card
      bordered={false}
      className={`${styles.activity} ${activity.global ? styles._global : ""}`}
      bodyStyle={{ padding: 0, borderRadius: 4 }}
    >
      {dashboard && !activity.global && (
        <FormControls className={styles.dashboardHeader}>
          <div>
            <b>{activity.customerId}</b> - {activity.customerName}
          </div>
          <Button size="small" type="link" onClick={getStatusAndGo}>
            Go To
          </Button>
        </FormControls>
      )}

      <Header
        avatar={avatar}
        createdBy={createdBy}
        activityDate={activityDate}
        likes={likes || []}
        followers={followers || []}
        comments={comments || []}
        hashtags={hashtags || []}
        scrollContainer={scrollContainer}
      />
      <FormBody
        className={styles.body}
        style={{
          borderBottom:
            comments && comments.length > 0 && !activity.global
              ? "1px solid #f0f0f0"
              : comments && comments.length > 0 && activity.global
              ? "1px solid #424242"
              : "none",
          position: "unset",
          left: "unset",
          right: "unset",
          height: "unset",
        }}
      >
        {/* {content.length > 0 ? (
          expanded ? (
            <Sanitized html={content} />
          ) : (
            <ChopLines
              maxHeight={90}
              ellipsis={
                <Ellipsis expand={expand}>
                  <span>Read More</span>
                </Ellipsis>
              }
              className={styles.content}
              style={{ marginBottom: images && images.length > 0 ? 8 : 0 }}
            >
              <Sanitized html={content} />
            </ChopLines>
          )
        ) : null} */}

        {content.length > 0 && <Sanitized html={content} />}

        {images && images.length > 0 && (
          <ImageGrid
            images={images || []}
            activity={activity}
            _global={activity.global}
            scrollContainer={scrollContainer}
          />
        )}
        {link && link.src && (
          <div
            style={{ marginTop: 8 }}
            className={`${styles.comment} ${styles.replyToComment}`}
          >
            <div
              className={styles.replyTo}
              style={{
                fontWeight: "bolder",
                fontSize: 12,
                lineHeight: "18px",
                margin: 0,
              }}
              onClick={previewImage}
            >
              <div>
                <div>{link.publisher}</div>
                <div className={styles.replyToIcon}>
                  <Camera size={14} /> Photo
                </div>
              </div>
              <div className={styles.replyToImage}>
                <ImageFallback
                  alt="comment-image"
                  image={{
                    src: link.src || undefined,
                    thumbnail: link.thumbnail || undefined,
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </FormBody>
      {/* {comments && comments.length > 0 && !showComments ? (
        <div>
          <Button
            style={{ color: "#108ee9" }}
            type="text"
            block
            onClick={toggleShowComments}
          >
            Show {comments.length}{" "}
            {comments.length === 1 ? "Comment" : "Comments"}
          </Button>
        </div>
      ) : comments && comments.length > 0 && showComments ? (
        <>
          <div>
            <Button
              style={{ color: "#108ee9" }}
              type="text"
              block
              onClick={toggleShowComments}
            >
              Hide {comments.length === 1 ? "Comment" : "Comments"}
            </Button>
          </div>
          <Comments
            comments={comments || []}
            _activityId={_id}
            _user={_user}
            _customerId={activity.customerId}
            _projectId={activity._projectId}
            _projectStatus={activity.projectStatus}
            _global={activity.global}
            dashboard={dashboard}
            fetchPosts={fetchPosts}
          />
        </>
      ) : null} */}
      {comments && comments.length > 0 ? (
        <Comments
          comments={comments || []}
          _activityId={_id}
          _user={_user}
          _customerId={activity.customerId}
          _projectId={activity._projectId}
          _projectStatus={activity.projectStatus}
          _global={activity.global}
          dashboard={dashboard}
          fetchPosts={fetchPosts}
        />
      ) : null}
      <Controls
        _activityId={_id}
        liked={
          likes ? likes.filter((l) => l._user === _user).length > 0 : false
        }
        followers={followers || []}
        hashtags={hashtags || []}
        _customerId={activity.customerId}
        _projectId={activity._projectId}
        _projectStatus={activity.projectStatus}
        _global={activity.global}
        dashboard={dashboard}
        fetchPosts={fetchPosts}
      />
    </Card>
  );
};

export default Activity;
