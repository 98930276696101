import React, { useState, useEffect, useCallback } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateCollectionsActivitiesAndHashtags } from "Actions/dataActions";
import { openForm, updateFormDetails } from "Actions/formActions";
import Form from "antd/es/form";
import Button from "antd/es/button";
import message from "antd/es/message";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import api from "API";
import Currency from "Components/Inputs/Currency";
import SearchLeadsAndProjects from "Components/Inputs/SearchLeadsAndProjects";
import DatePicker from "Components/Inputs/DatePicker";
import Select from "antd/es/select";
import dayjs from "dayjs";
import Modal from "antd/es/modal";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";

import Eye from "Icons/Eye";
import Email from "Icons/Email";
import Printer from "Icons/Printer";
import openInNewTab from "Utils/openInNewTab";
import printJS from "print-js";
import useIsMobile from "Hooks/useIsMobile";
import CancelButton from "Components/CancelButton";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";
import Divider from "antd/es/divider";
import List from "antd/es/list";
import Alert from "antd/es/alert";
import currencyFormatter from "Utils/currencyFormatter";
import Input from "antd/es/input";

import ProcessFeedback from "Components/ProcessFeedback";

const messageKey = "messageKey";

const steps = [
  "Submitting Form",
  "Generating PDF",
  "Saving PDF",
  "Saving Collection",
  "Creating Post",
  "Notifying Project Owner",
  "Done!",
];

const Collection = ({
  unsavedChanges,
  setUnsavedChanges,
  handleClose,
  setTitle,
  type,
}) => {
  const formDetails = useSelector(
    (state) => state.formState.collection.formDetails
  );
  const admin = useSelector((state) => state.authState.admin);
  const role = useSelector((state) => state.authState.role);
  const details = useSelector((state) => state.dataState.details);
  const viewableActivities = useSelector(
    (state) => state.dataState.viewableActivities
  );
  const dispatch = useDispatch();
  const mobile = useIsMobile();
  const [_projectId, _setProjectId] = useState(
    formDetails._projectId ? formDetails._projectId : details._id
  );
  const [customerId, setCustomerId] = useState(
    formDetails.customerId ? formDetails.customerId : details.customerId
  );
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(
    formDetails.editing ? formDetails.editing : false
  );
  const [projectManager, setProjectManager] = useState(
    formDetails.projectManager
      ? formDetails.projectManager
      : details.projectManager
  );
  const [_details, _setDetails] = useState({
    billingAddressStreet: formDetails.billingAddressCity
      ? formDetails.billingAddressStreet
      : details.billingAddressStreet,
    billingAddressCity: formDetails.billingAddressCity
      ? formDetails.billingAddressCity
      : details.billingAddressCity,
    billingAddressState: formDetails.billingAddressState
      ? formDetails.billingAddressState
      : details.billingAddressState,
    billingAddressZip: formDetails.billingAddressZip
      ? formDetails.billingAddressZip
      : details.billingAddressZip,
    customerName: formDetails.customerName
      ? formDetails.customerName
      : details.customerName,
    companyName: formDetails.companyName
      ? formDetails.companyName
      : details.companyName,
    projectManager: formDetails.projectManager
      ? formDetails.projectManager
      : details.projectManager,
    projectAddressStreet: formDetails.projectAddressStreet
      ? formDetails.projectAddressStreet
      : details.projectAddressStreet,
    projectAddressCity: formDetails.projectAddressCity
      ? formDetails.projectAddressCity
      : details.projectAddressCity,
    projectAddressState: formDetails.projectAddressState
      ? formDetails.projectAddressState
      : details.projectAddressState,
    projectAddressZip: formDetails.projectAddressZip
      ? formDetails.projectAddressZip
      : details.projectAddressZip,
    projectAddress: formDetails.projectAddress
      ? formDetails.projectAddress
      : details.projectAddress,
    brokedown: details.brokedown ? details.brokedown : "No",
    projectDescription: details.projectDescription
      ? details.projectDescription
      : "",
    phone: details.phone ? details.phone : "",
  });
  const [collections, setCollections] = useState([]);
  const [collectionsTotal, setCollectionsTotal] = useState(0);
  const [options, setOptions] = useState([]);
  const [scopes, setScopes] = useState([]);
  const [scopesTotal, setScopesTotal] = useState(0);
  const [helpMessage, setHelpMessage] = useState(undefined);

  const _user = useSelector((state) => state.authState._user);
  const [feedBack, setFeedBack] = useState({
    active: "Submitting Form",
    progress: 0,
  });

  const [form] = Form.useForm();

  useEffect(() => {
    if (!formDetails.editing) {
      setTitle("Create Collection");
    } else {
      setTitle(
        <div style={{ marginRight: 28 }}>
          Edit Collection{" "}
          {(admin === "Yes" || role === "Office") && (
            <Button
              size="small"
              danger
              onClick={confirmDeleteCollection}
              style={{ float: "right" }}
            >
              Delete
            </Button>
          )}
        </div>
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchOptions();
  }, []);

  useEffect(() => {
    if (editing || _projectId) {
      fetchDetails();
      fetchCollections();
    }
  }, [editing, _projectId]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchOptions = async () => {
    try {
      const res = await api.post("/dropdowns/get", {
        selects: ["Collections"],
      });
      setOptions(res.data.Collections.options);
    } catch (err) {
      console.log("err", err);
    }
  };

  const fetchDetails = async () => {
    try {
      const res = await api.get(`/collections/billing/${_projectId}`);

      unstable_batchedUpdates(() => {
        setProjectManager(res.data.projectManager);
        _setDetails((details) => {
          return {
            ...details,
            brokedown: res.data.brokedown,
            projectDescription: res.data.projectDescription,
            phone: res.data.phone,
          };
        });
      });
    } catch (err) {
      console.log("err", err);
      message.error("Error retreiving collection details");
    }
  };

  const fetchCollections = async (_id) => {
    try {
      const res = await api.get(`collections/get/${_id ? _id : _projectId}`);
      let total = 0;
      for (const c of res.data.collections) {
        total += c.amount;
      }

      for (const scope of res.data.scopes) {
        scope.amount = 0;
      }

      let _scopesTotal = 0;
      if (formDetails.scopes) {
        for (const scope of formDetails.scopes) {
          const matchingScope = res.data.scopes.find(
            (_scope) => _scope._scopeId.toString() === scope._scopeId.toString()
          );
          matchingScope.amount = scope.amount;
          _scopesTotal += scope.amount;
        }
      }

      unstable_batchedUpdates(() => {
        setCollections(res.data.collections);
        setCollectionsTotal(total);
        setScopes(res.data.scopes);
        setScopesTotal(_scopesTotal);
        // setInvoices(res.data.invoices);
      });

      form.setFieldsValue({
        scopes: res.data.scopes,
      });
    } catch (err) {
      console.log("err", err);
      message.error("Error retreiving collection details");
    }
  };

  const updateFeedBack = (feedBack) => {
    setFeedBack(feedBack);
  };

  const onFinish = useCallback(
    async (values) => {
      try {
        if (values.amount !== scopesTotal) {
          message.error({
            content: "Total amount must be allocated to scopes",
            duration: 1.5,
            key: messageKey,
          });
          setLoading(false);
          return;
        }

        window.socket.on(`${_user}-collection-progress`, updateFeedBack);
        unstable_batchedUpdates(() => {
          setFeedBack({
            active: "Submitting Form",
            progress: 1,
          });
          setLoading(true);
        });

        values._projectId = _projectId;
        values.customerId = customerId;
        values._id = formDetails._id ? formDetails._id : undefined;
        values.projectManager = projectManager;
        values.projectAddressStreet = _details.projectAddressStreet;
        values.projectAddressCity = _details.projectAddressCity;
        values.projectAddressState = _details.projectAddressState;
        values.projectAddressZip = _details.projectAddressZip;
        values.billingAddressStreet = _details.billingAddressStreet;
        values.billingAddressCity = _details.billingAddressCity;
        values.billingAddressState = _details.billingAddressState;
        values.billingAddressZip = _details.billingAddressZip;
        values.customerName = _details.customerName;
        values.companyName = _details.companyName;
        values.projectAddress = _details.projectAddress;

        // message.loading("Saving Collection...", 0);
        message.loading({
          content: "Saving collection...",
          duration: 0,
          key: messageKey,
        });
        let res;
        if (!editing) {
          res = await api.post("/collections/create", values);
          dispatch(
            updateFormDetails("collection", formDetails, {
              src: res.data._collections[res.data._collections.length - 1].src,
              editing: true,
              _id: res.data._collections[res.data._collections.length - 1]._id,
            })
          );
        } else {
          values._prevDetails = {
            collectionType: formDetails.collectionType,
            amount: formDetails.amount,
            transactionDate: formDetails.transactionDate,
            identifier: formDetails.identifier,
          };
          values._id = formDetails._id;
          res = await api.post("/collections/edit", values);

          dispatch(
            updateFormDetails("collection", formDetails, {
              src: res.data._collections.filter(
                (d) => d._id === formDetails._id
              )[0].src,
            })
          );
        }

        unstable_batchedUpdates(() => {
          setUnsavedChanges(false);
          setEditing(true);
          setLoading(false);
          setFeedBack({
            active: "Done!",
            progress: 100,
          });
        });

        dispatch(
          updateCollectionsActivitiesAndHashtags({
            viewableActivities,
            _projectId,
            data: res.data,
          })
        );

        if (formDetails.callback) {
          formDetails.callback();
        }

        window.socket.removeAllListeners(`${_user}-collection-progress`);
        // message.success(!editing ? "Collection created" : "Collection updated");
        message.success({
          content: !editing ? "Collection created" : "Collection updated",
          duration: 1.5,
          key: messageKey,
        });
      } catch (err) {
        // message.error(
        //   !editing ? "Error creating collection" : "Error updating collection"
        // );
        message.error({
          content: !editing
            ? "Error creating collection"
            : "Error updating collection",
          duration: 1.5,
          key: messageKey,
        });
        window.socket.removeAllListeners(`${_user}-collection-progress`);
        setLoading(false);
        setFeedBack({
          active: "Submitting Form",
          progress: 0,
        });
      }
    },
    [
      _user,
      _projectId,
      customerId,
      formDetails,
      projectManager,
      _details,
      dispatch,
      editing,
      setUnsavedChanges,
      viewableActivities,
      scopesTotal,
    ]
  );

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error(
      !editing ? "Error creating collection" : "Error updating collection"
    );
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  const cancel = () => {
    handleClose();
  };

  const handleSelect = (selected) => {
    _setProjectId(selected._id);
    setCustomerId(selected.customerId);
    setProjectManager(selected.projectManager);
    _setDetails({
      projectAddressStreet: selected.projectAddressStreet,
      projectAddressCity: selected.projectAddressCity,
      projectAddressState: selected.projectAddressState,
      projectAddressZip: selected.projectAddressZip,
      projectAddress: `${selected.projectAddressStreet}, ${selected.projectAddressCity}, ${selected.projectAddressState} ${selected.projectAddressZip}`,
      billingAddressStreet: selected.billingAddressStreet,
      billingAddressCity: selected.billingAddressCity,
      billingAddressState: selected.billingAddressState,
      billingAddressZip: selected.billingAddressZip,
      customerName: selected.customerName,
      companyName: selected.companyName,

      brokedown: selected.brokedown,
      projectDescription: selected.projectDescription,
      phone: selected.phone,
    });
    fetchCollections(selected._id);
  };

  const printCollection = () => {
    printJS({
      printable: `${formDetails.src}?${new Date().getTime()}`,
      type: "pdf",
    });
  };

  const openEmail = useCallback(() => {
    dispatch(
      openForm("email", {
        content: [
          {
            _id: formDetails.src,
            src: formDetails.src,
            _projectId,
            type: "receipt",
          },
        ],
      })
    );
  }, [dispatch, formDetails, _projectId]);

  const openCollection = () => {
    openInNewTab(formDetails.src);
  };

  const confirmDeleteCollection = () => {
    Modal.confirm({
      zIndex: 4002,
      icon: <QuestionCircleOutlined />,
      centered: true,
      cancelText: "Cancel",
      okText: "Delete",
      okButtonProps: { danger: true },
      content: <div>Are you sure you want to delete this collection?</div>,
      onOk() {
        return new Promise((resolve, reject) => {
          deleteCollection(() => resolve(true));
        }).catch((err) => console.log("err: ", err));
      },
      onCancel() {},
    });
  };

  const deleteCollection = useCallback(
    async (cb) => {
      try {
        const res = await api.delete(`/collections/delete/${formDetails._id}`);

        dispatch(
          updateCollectionsActivitiesAndHashtags({
            viewableActivities,
            _projectId,
            data: res.data,
          })
        );

        if (formDetails.callback) {
          formDetails.callback();
        }

        cb();
        handleClose(true);
      } catch (err) {
        console.log("err", err);
        message.error("Error deleting contact");
      }
    },
    [formDetails, dispatch, viewableActivities, _projectId, handleClose]
  );

  const validateAmount = () => {
    const amount = form.getFieldValue("amount");
    if (amount) {
      if (amount - scopesTotal !== 0) {
        return "error";
      }
    }
    return undefined;
  };

  const renderHelpMessage = useCallback(() => {
    const amount = form.getFieldValue("amount");
    if (amount || amount === 0) {
      if (amount - scopesTotal !== 0) {
        setHelpMessage(
          `${currencyFormatter(amount - scopesTotal)} to be allocated`
        );
      } else {
        setHelpMessage(undefined);
      }
    } else {
      setHelpMessage(undefined);
    }
  }, [form, scopesTotal]);

  useEffect(() => {
    renderHelpMessage();
  }, [scopesTotal, renderHelpMessage]);

  const scopeAmountChange = async () => {
    try {
      const amounts = await form.getFieldValue("scopes");
      let total = 0;
      for (const a of amounts) {
        if (a && a.amount) {
          total += a.amount;
        }
      }
      setScopesTotal(total);
    } catch (err) {
      console.log("scopeAmountChange err", err);
    }
  };

  const amountChange = async (value) => {
    try {
      const amounts = await form.getFieldValue("scopes");
      if (scopes && scopes.length === 1) {
        amounts[0].amount = value;
        setScopesTotal(value);
      } else {
        Object.entries(amounts).map(([key, value]) => {
          form.validateFields([key]);
          return value;
        });

        renderHelpMessage();
      }
    } catch (err) {
      console.log("amount change err", err);
    }
  };

  return (
    <Form
      form={form}
      className="form"
      autoComplete="off"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      initialValues={{
        collectionType: formDetails.collectionType
          ? formDetails.collectionType
          : undefined,
        vendor: formDetails.vendor ? formDetails.vendor : undefined,
        transactionDate: formDetails.transactionDate
          ? dayjs(formDetails.transactionDate)
          : undefined,
        identifier: formDetails.identifier ? formDetails.identifier : undefined,
        amount: formDetails.amount ? formDetails.amount : undefined,
        scopes: scopes,
      }}
    >
      <RemoveAutocomplete />

      {!_projectId ? (
        <SearchLeadsAndProjects
          handleSelect={handleSelect}
          leads={false}
          disableLastViewed={true}
        />
      ) : (
        <FormBody
          className="content-inner"
          style={{
            paddingLeft: 24,
            paddingTop: 24,
            paddingRight: 24,
            paddingBottom: 8,
            maxHeight: type === "modal" ? "calc(100vh - 161px)" : "unset",
          }}
        >
          <div className={feedBack.progress !== 0 ? "hide" : ""}>
            {_details.brokedown === "Yes" && (
              <Alert
                style={{ marginBottom: 8, textAlign: "center" }}
                type="error"
                message="This project has already been broke down"
              />
            )}
            <List bordered={false} split={false}>
              {_details.companyName && (
                <List.Item style={{ padding: 0, paddingBottom: 8 }}>
                  <List.Item.Meta
                    title="Company Name"
                    description={_details.companyName}
                  />
                </List.Item>
              )}

              <List.Item style={{ padding: 0, paddingBottom: 8 }}>
                <List.Item.Meta
                  title="Customer Name"
                  description={_details.customerName}
                />
              </List.Item>

              <List.Item style={{ padding: 0, paddingBottom: 8 }}>
                <List.Item.Meta
                  title="Project Address"
                  description={`${_details.projectAddressStreet}, ${_details.projectAddressCity}, ${_details.projectAddressZip}`}
                />
              </List.Item>

              <List.Item style={{ padding: 0, paddingBottom: 8 }}>
                <List.Item.Meta
                  title="Billing Address"
                  description={`${_details.billingAddressStreet}, ${_details.billingAddressCity}, ${_details.billingAddressZip}`}
                />
              </List.Item>

              <List.Item style={{ padding: 0, paddingBottom: 8 }}>
                <List.Item.Meta
                  title="Customer Phone"
                  description={_details.phone}
                />
              </List.Item>

              <List.Item style={{ padding: 0, paddingBottom: 8 }}>
                <List.Item.Meta
                  title="Project Owner"
                  description={projectManager ? projectManager.name : ""}
                />
              </List.Item>

              <List.Item style={{ padding: 0, paddingBottom: 8 }}>
                <List.Item.Meta
                  title="Project Description"
                  description={_details.projectDescription}
                />
              </List.Item>

              <List.Item style={{ padding: 0 }}>
                <List.Item.Meta title="Collections" />
              </List.Item>

              {collections.length > 0 ? (
                <>
                  {collections.map((c) => (
                    <List.Item key={c._id} style={{ padding: 0 }}>
                      <List.Item.Meta
                        description={dayjs(c.transactionDate).format(
                          "MMMM D, YYYY"
                        )}
                      />
                      <List.Item.Meta
                        style={{ textAlign: "right" }}
                        description={currencyFormatter(c.amount)}
                      />
                    </List.Item>
                  ))}
                </>
              ) : (
                <List.Item style={{ padding: 0 }}>
                  <List.Item.Meta description="No collections have been recorded" />
                </List.Item>
              )}

              <List.Item
                style={{
                  padding: 0,
                  borderStyle: "double",
                  borderColor: "#dddddd",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "none",
                }}
              >
                <List.Item.Meta title="Total Collected" />
                <List.Item.Meta
                  style={{ textAlign: "right" }}
                  title={currencyFormatter(collectionsTotal)}
                />
              </List.Item>
            </List>

            <Divider />

            <Form.Item
              name="transactionDate"
              label="Date"
              rules={[{ required: true, message: "Date is required" }]}
            >
              <DatePicker
                disabled={admin !== "Yes" && role !== "Office"}
                format="MMMM DD, YYYY"
                getPopupContainer={(trigger) =>
                  !mobile
                    ? trigger.parentNode.parentNode.parentNode.parentNode
                    : document.body
                }
                getCalendarContainer={(trigger) =>
                  !mobile
                    ? trigger.parentNode.parentNode.parentNode.parentNode
                    : document.body
                }
                inputReadOnly={true}
              />
            </Form.Item>

            <Form.Item
              name="collectionType"
              label="Type"
              rules={[{ required: true, message: "Type is required" }]}
            >
              <Select
                disabled={admin !== "Yes" && role !== "Office"}
                showSearch={!mobile}
                dropdownClassName={mobile ? "isMobile" : null}
                getPopupContainer={(trigger) =>
                  !mobile
                    ? trigger.parentNode.parentNode.parentNode.parentNode
                        .parentNode
                    : document.body
                }
              >
                {options.map((type) => {
                  return (
                    <Select.Option key={type} value={type}>
                      {type}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              name="identifier"
              label="Reference Number"
              rules={[{ type: "string" }]}
            >
              <InputNoAutoComplete
                id="identifier"
                disabled={admin !== "Yes" && role !== "Office"}
              />
            </Form.Item>

            {/* {invoices.map((invoice) => (
              <div key={invoice._id}>{invoice.customerId}</div>
            ))} */}

            <Form.Item
              name="amount"
              label="Amount"
              rules={[{ required: true, message: "Amount is required" }]}
            >
              <Currency
                id="amount"
                disabled={admin !== "Yes" && role !== "Office"}
                onChange={amountChange}
              />
            </Form.Item>

            <Divider>Scope Allocation</Divider>

            <Form.List name="scopes">
              {(fields, { add, remove }) => {
                return (
                  <>
                    {fields.map((field, index) => (
                      <div key={field.fieldKey}>
                        <Form.Item
                          {...field}
                          name={[field.name, "_scopeId"]}
                          fieldKey={[field.fieldKey, "_scopeId"]}
                          key={[field.fieldKey, "_scopeId"]}
                          help=""
                          hidden
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          {...field}
                          name={[field.name, "amount"]}
                          fieldKey={[field.fieldKey, "amount"]}
                          key={[field.fieldKey, "amount"]}
                          label={
                            scopes[index].label
                              ? `${scopes[index].label} (${scopes[index].name}) Allocation`
                              : `${scopes[index].name} Allocation`
                          }
                          validateStatus={validateAmount()}
                          help={helpMessage}
                          rules={[
                            { required: true, message: "Amount is required" },
                          ]}
                        >
                          <Currency
                            id={[field.name, "amount"]}
                            disabled={admin !== "Yes" && role !== "Office"}
                            onChange={scopeAmountChange}
                          />
                        </Form.Item>
                      </div>
                    ))}
                  </>
                );
              }}
            </Form.List>
          </div>

          <div
            style={{ height: "100%" }}
            className={feedBack.progress === 0 ? "hide" : ""}
          >
            <ProcessFeedback feedBack={feedBack} steps={steps} />
          </div>
        </FormBody>
      )}

      {_projectId && (
        <FormControls className="sticky-footer">
          <CancelButton
            handleClick={cancel}
            title={feedBack.progress !== 100 ? "Cancel" : "Close"}
          />

          {formDetails.src &&
            editing &&
            !unsavedChanges &&
            feedBack.progress === 100 && (
              <>
                <Button
                  type="link"
                  shape="circle"
                  onClick={openCollection}
                  style={{ marginRight: 8 }}
                >
                  <Eye />
                </Button>
                <Button
                  type="link"
                  shape="circle"
                  onClick={openEmail}
                  style={{ marginRight: 8 }}
                >
                  <Email />
                </Button>
                <Button
                  type="link"
                  shape="circle"
                  onClick={printCollection}
                  style={{ marginRight: 8 }}
                >
                  <Printer />
                </Button>
              </>
            )}

          {feedBack.progress === 0 && (
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={loading || !unsavedChanges}
            >
              Submit
            </Button>
          )}
        </FormControls>
      )}
    </Form>
  );
};

export default Collection;
