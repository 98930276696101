import React from "react";
import LineChartWithInterval from "Reports/LineChartWithInterval";

export default function LeadsLineChart() {
  return (
    <LineChartWithInterval
      path="/reports/adminLeads"
      type=""
      summary={true}
      lineItems={true}
      aggregate={true}
      report="LEADS"
      headers={["Salesman", "Total"]}
    />
  );
}
