export default async function generateFilePreview(file) {
  try {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener("load", (ev) => {
        resolve(ev.target.result);
      });
      reader.addEventListener("error", reject);
      reader.readAsDataURL(file);
    });
  } catch (err) {
    console.log("generateFilePreview err", err);
  }
}
