import React from "react";
import BarChart from "Reports/BarChart";

export default function DownPaymentSurplus() {
  return (
    <BarChart
      path="/reports/downpaymentSurplus"
      summary={true}
      report="DOWN PAYMENT SURPLUS"
      headers={["Salesman", "Total"]}
    />
  );
}
