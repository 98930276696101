import React, { useCallback, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateDocumentsActivitiesAndHashtags } from "Actions/dataActions";
import Form from "antd/es/form";
import Button from "antd/es/button";
import message from "antd/es/message";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import api from "API";
import styles from "./styles.module.scss";
import SearchLeadsAndProjects from "Components/Inputs/SearchLeadsAndProjects";
import Upload from "antd/es/upload";
import InboxOutlined from "@ant-design/icons/InboxOutlined";
import isPdf from "Utils/isPdf";
import pdf from "Assets/pdf.svg";
import useIsMobile from "Hooks/useIsMobile";
import AutoComplete from "antd/es/auto-complete";
import CancelButton from "Components/CancelButton";

import axios from "axios";
import generateFilePreview from "Utils/generateFilePreview";
import generateFileSize from "Utils/generateFileSize";
import List from "antd/es/list";
import UploadRow from "Components/images/UploadRow";

const messageKey = "messageKey";

const names = [
  "Aerial",
  "Contract",
  "Mold Sheet",
  "Pre-Job Survey",
  "Document Checklist",
  "Xactimate",
  "Measurements .esx",
];

const Document = ({ unsavedChanges, setUnsavedChanges, handleClose, type }) => {
  const dispatch = useDispatch();
  const formDetails = useSelector(
    (state) => state.formState.documentForm.formDetails
  );
  const details = useSelector((state) => state.dataState.details);
  const viewableActivities = useSelector(
    (state) => state.dataState.viewableActivities
  );
  const mobile = useIsMobile();
  const [_projectId, _setProjectId] = useState(
    formDetails._projectId ? formDetails._projectId : details._id
  );
  const [loading, setLoading] = useState(false);
  const [_fileList, _setFileList] = useState({});
  const [name, setName] = useState(undefined);

  const [uploads, setUploads] = useState({});
  const [uploading, setUploading] = useState(true);

  const [form] = Form.useForm();

  useEffect(() => {
    if (uploading) {
      let _uploading = false;
      Object.keys(uploads).map((key) => {
        if (uploads[key].upload !== 100 && uploads[key].upload !== true) {
          _uploading = true;
          return key;
        }
        return key;
      });
      if (!_uploading) {
        setUploading(false);
      }
    }
  }, [uploads]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleBeforeUpload = async (file) => {
    try {
      setUploading(true);
      const size = await generateFileSize(file.size);
      let preview;
      if (isPdf(file.name)) {
        preview = pdf;
      } else {
        preview = await generateFilePreview(file);
      }
      setUploads((prv) => {
        return {
          ...prv,
          [file.uid]: {
            preview,
            name: file.name,
            size,
            failed: false,
            upload: 0,
          },
        };
      });
      return file;
    } catch (err) {
      console.log("beforeUpload err", err);
    }
  };

  const handleUpload = async ({ file, onProgress }) => {
    try {
      let fileName = `${new Date().getTime()}-${file.name.replace(
        /[^a-zA-Z0-9.]/gi,
        "_"
      )}`;
      const src = `https://s3.us-east-2.amazonaws.com/pr-crm/${_projectId}/documents/${fileName}`;
      let thumbnail;
      const ext = src.split(".").pop().toLowerCase();
      if (ext !== "pdf")
        thumbnail = `https://s3.us-east-2.amazonaws.com/pr-crm-thumbnail/${_projectId}/documents/${fileName}`;

      _setFileList({
        originFileObj: file,
        _id: file.uid,
        uid: file.uid,
        name: file.name,
        mediaType: isPdf(src) ? "pdf" : "image",
        _projectId,
        size: file.size,
        type: file.type,
        src,
        thumbnail,
      });

      const res = await api.post("/project-documents/document-upload", {
        type: file.type,
        name: fileName,
        _projectId,
      });
      await axios
        .put(res.data, file, {
          headers: {
            "Content-Type": file.type,
          },
          onUploadProgress: (e) => {
            onProgress(e);
          },
        })
        .catch(function (err) {
          console.log("upload err", err);

          setUploads((prv) => {
            return {
              ...prv,
              [file.uid]: {
                ...prv[file.uid],
                failed: true,
              },
            };
          });

          _setFileList((prevState) => {
            const _filteredFileList = prevState.filtered(
              (prv) => prv.uid !== file.uid
            );
            return _filteredFileList;
          });
        });
    } catch (err) {
      console.log("handleUpload err", err);
    }
  };

  const handleProgress = (progress, file) => {
    const _progress = parseInt((progress.loaded * 100) / progress.total, 10);
    setUploads((prv) => {
      return {
        ...prv,
        [file.uid]: {
          ...prv[file.uid],
          upload: _progress,
        },
      };
    });
  };

  const onFinish = useCallback(
    async (values) => {
      try {
        setLoading(true);
        values._projectId = _projectId;
        values.src = _fileList.src;
        values.name = _fileList.name;
        values.type = _fileList.type;
        values.size = _fileList.size;
        values.documentType = name;
        values.mediaType = _fileList.mediaType;

        // message.loading("Saving Document...", 0);
        message.loading({
          content: "Saving document...",
          duration: 0,
          key: messageKey,
        });
        let res = await api.post("/project-documents/create", values);

        dispatch(
          updateDocumentsActivitiesAndHashtags({
            viewableActivities,
            _projectId,
            data: res.data,
          })
        );

        setUnsavedChanges(false);

        // message.success("Document uploaded");
        message.success({
          content: "Document uploaded",
          duration: 1.5,
          key: messageKey,
        });
        handleClose(true);
      } catch (err) {
        setLoading(false);
        // message.error("Error uploading document");
        message.error({
          content: "Error uploading document",
          duration: 1.5,
          key: messageKey,
        });
      }
    },
    [
      _projectId,
      _fileList,
      name,
      dispatch,
      viewableActivities,
      setUnsavedChanges,
      handleClose,
    ]
  );

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error uploading document");
  };

  const handleSelect = (selected) => {
    _setProjectId(selected._id);
  };

  const handleNameSelect = (name) => {
    setName(name);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  const cancel = () => {
    handleClose();
  };

  return (
    <Form
      form={form}
      className="form"
      autoComplete="off"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
    >
      <RemoveAutocomplete />

      {!_projectId ? (
        <SearchLeadsAndProjects
          handleSelect={handleSelect}
          leads={false}
          disableLastViewed={true}
        />
      ) : (
        <FormBody
          className="content-inner"
          style={{
            paddingLeft: 24,
            paddingTop: 24,
            paddingRight: 24,
            paddingBottom: 8,
            maxHeight: type === "modal" ? "calc(100vh - 161px)" : "unset",
          }}
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: "Name is required" }]}
          >
            <AutoComplete
              onChange={handleNameSelect}
              showSearch={!mobile}
              dropdownClassName={mobile ? "isMobile" : null}
              getPopupContainer={(trigger) =>
                !mobile
                  ? trigger.parentNode.parentNode.parentNode.parentNode
                      .parentNode
                  : document.body
              }
            >
              {names.map((name) => {
                return (
                  <AutoComplete.Option key={name} value={name}>
                    {name}
                  </AutoComplete.Option>
                );
              })}
            </AutoComplete>
          </Form.Item>

          {name && (
            <Form.Item
              name="images"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              rules={[{ required: true, message: "Document required" }]}
            >
              <Upload.Dragger
                multiple={false}
                beforeUpload={handleBeforeUpload}
                customRequest={handleUpload}
                showUploadList={false}
                onProgress={handleProgress}
                accept="image/*, application/pdf"
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
              </Upload.Dragger>
            </Form.Item>
          )}

          {_fileList.src && (
            <List className={styles.uploadRow} bordered>
              {Object.keys(uploads).map((key) => {
                return (
                  <UploadRow
                    key={key}
                    preview={uploads[key].preview}
                    name={uploads[key].name}
                    size={uploads[key].size}
                    upload={uploads[key].upload}
                    failed={uploads[key].failed}
                  />
                );
              })}
            </List>
          )}
        </FormBody>
      )}

      {_projectId && (
        <FormControls className="sticky-footer">
          <CancelButton handleClick={cancel} />
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={loading || !unsavedChanges || uploading || !_fileList.src}
          >
            Submit
          </Button>
        </FormControls>
      )}
    </Form>
  );
};

export default Document;
