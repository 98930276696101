import React, { memo } from "react";
import Row from "antd/es/row";
import Col from "antd/es/col";
import List from "antd/es/list";
import FeedbackItem from "./FeedbackItem";
import FolderLoader from "./FolderLoader";
import Typography from "antd/es/typography";

const ProcessFeedback = ({ feedBack, steps }) => {
  const activeIndex = steps.indexOf(feedBack.active);
  const loaderState = feedBack.progress === 100 ? "complete" : "loading";

  return (
    <Row justify="center" align="middle" style={{ height: "100%" }}>
      <Col span={24} style={{ maxWidth: 340 }}>
        <FolderLoader state={loaderState} />
        <Typography.Title
          level={3}
          style={{ marginBottom: 12, display: "block", textAlign: "center" }}
        >
          {feedBack.active}
        </Typography.Title>
        <List bordered={false} split={false} style={{ border: "none" }}>
          {steps.map((step, index) => (
            <FeedbackItem
              key={step}
              title={step}
              activeIndex={activeIndex}
              index={index}
            />
          ))}
        </List>
      </Col>
    </Row>
  );
};

export default memo(ProcessFeedback);
