import React, { useState } from "react";
import Row from "antd/es/row";
import Col from "antd/es/col";
import message from "antd/es/message";
import DatePicker from "Components/Inputs/DatePicker";
import Card from "antd/es/card";
import Select from "antd/es/select";
import Form from "antd/es/form";
import Button from "antd/es/button";
import api from "API";
import csv from "Utils/csv";
import styles from "./styles.module.scss";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";
dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault("America/Detroit");

const ChrisReports = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const endOfThisMonth = dayjs().endOf("month");
  const endOfLastMonth = dayjs().subtract(1, "month").endOf("month");

  async function onFinish(values) {
    try {
      setLoading(true);
      let { report, start, end } = values;
      start = start.startOf("day");
      end = end.endOf("day");

      const res = await api.post("/reports/chris-report", {
        start,
        end,
        report,
      });

      const startString = start.format("MM-DD-YY");
      const endString = end.format("MM-DD-YY");

      const fileName = `${report}_${startString}_to_${endString}.csv`;

      if (res.data.length > 0) {
        csv(fileName, res.data);
        form.resetFields();
      } else {
        message.error("No data to download");
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log("err", err);
      message.error("Error retrieving report");
    }
  }

  async function on1stOfThisMonth() {
    form.setFieldsValue({
      start: dayjs().startOf("month"),
    });
  }

  async function on1stOfLastMonth() {
    form.setFieldsValue({
      start: dayjs().startOf("month").subtract(1, "months"),
    });
  }

  async function onLaststOfThisMonth() {
    form.setFieldsValue({
      end: dayjs().endOf("month"),
    });
  }

  async function onLastOfLastMonth() {
    form.setFieldsValue({
      end: dayjs().subtract(1, "months").endOf("month"),
    });
  }

  return (
    <div style={{ paddingBottom: 24 }} className="content-inner">
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Card bordered={false} className="z-depth-1" style={{ margin: 24 }}>
          <Row gutter={16} style={{ marginBottom: 24 }}>
            <Col xs={24}>
              <div className={styles.reportTitle}>Chris' Reports</div>
            </Col>
          </Row>

          <Row gutter={16} style={{ marginBottom: 24 }}>
            <Col xs={24} sm={6}>
              <Form.Item
                label="Report"
                name="report"
                rules={[{ required: true, message: "Report is required" }]}
              >
                <Select>
                  <Select.Option value="expenses">Expenses</Select.Option>
                  <Select.Option value="work-completed">
                    Work Completed
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={6}>
              <Form.Item
                label="Start Date"
                name="start"
                rules={[{ required: true, message: "Start Date is required" }]}
              >
                <DatePicker
                  format="MMMM DD, YYYY"
                  inputReadOnly={true}
                  showTime={false}
                  showToday={false}
                  renderExtraFooter={() => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingTop: 8,
                        paddingBottom: 8,
                      }}
                    >
                      <Button
                        onClick={on1stOfLastMonth}
                        size="small"
                        type="link"
                      >
                        1st of {endOfLastMonth.format("MMMM")}
                      </Button>
                      <div style={{ width: 24 }} />
                      <Button
                        onClick={on1stOfThisMonth}
                        size="small"
                        type="link"
                      >
                        1st of {endOfThisMonth.format("MMMM")}
                      </Button>
                    </div>
                  )}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={6}>
              <Form.Item
                label="End Date"
                name="end"
                rules={[{ required: true, message: "End Date is required" }]}
              >
                <DatePicker
                  format="MMMM DD, YYYY"
                  inputReadOnly={true}
                  showTime={false}
                  showToday={false}
                  renderExtraFooter={() => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingTop: 8,
                        paddingBottom: 8,
                      }}
                    >
                      <Button
                        onClick={onLastOfLastMonth}
                        size="small"
                        type="link"
                      >
                        {endOfLastMonth.format("Do")} of{" "}
                        {endOfLastMonth.format("MMMM")}
                      </Button>
                      <div style={{ width: 24 }} />
                      <Button
                        onClick={onLaststOfThisMonth}
                        size="small"
                        type="link"
                      >
                        {endOfThisMonth.format("Do")} of{" "}
                        {endOfThisMonth.format("MMMM")}
                      </Button>
                    </div>
                  )}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={6}>
              <Form.Item label=" ">
                <Button
                  htmlType="submit"
                  type="primary"
                  loading={loading}
                  disabled={loading}
                >
                  Download
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};

export default ChrisReports;
