import React from "react";
import Card from "antd/es/card";
import List from "antd/es/list";
import styles from "./styles.module.scss";
import currencyFormatter from "Utils/currencyFormatter";
import percentFormatter from "Utils/percentFormatter";
import formattedNumber from "Utils/formattedNumber";
import Tag from "antd/es/tag";
import ArrowDown from "Icons/ArrowDownDropCircle";
import ArrowUp from "Icons/ArrowUpDropCircle";
import Skeleton from "antd/es/skeleton";

const TableCard = ({
  title,
  columns,
  content,
  total,
  loading,
  height = 345,
  count,
  onClick = undefined,
}) => {
  const handleClick = () => {
    onClick(title);
  };

  return (
    <Card
      bodyStyle={{
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 12,
        paddingBottom: 0,
        height,
      }}
      className="z-depth-1"
      style={{ overflow: "hidden", width: "100%" }}
      bordered={false}
      onClick={onClick ? handleClick : undefined}
      hoverable={onClick ? true : false}
    >
      <div className={styles.reportTitle}>{title}</div>
      {columns && (
        <List className={styles.tableHeader}>
          <List.Item className="px12 py4">
            {columns.map((column, i) => {
              if (i === 0) {
                return <List.Item.Meta key={column} title={column} />;
              }
              if (i + 1 === columns.length) {
                return (
                  <List.Item.Meta
                    key={column}
                    title={column}
                    style={{ textAlign: "right", paddingRight: 12 }}
                  />
                );
              }

              return (
                <List.Item.Meta
                  key={column}
                  title={column}
                  style={{ textAlign: "right" }}
                />
              );
            })}
          </List.Item>
        </List>
      )}

      <div
        className={styles.reportContainer}
        style={
          columns && total
            ? { height: 236 }
            : columns
            ? { height: 266 }
            : total
            ? { height: 276 }
            : { height: 317 }
        }
      >
        <List className={styles.tableBody}>
          {content.map((c) => (
            <List.Item className="px12" key={c.name}>
              <Skeleton
                loading={loading}
                active
                title={{ width: "80%" }}
                paragraph={{ rows: 0 }}
              >
                <List.Item.Meta description={c.name} />
              </Skeleton>
              <Skeleton
                loading={loading}
                active
                title={{ width: "80%" }}
                paragraph={{ rows: 0 }}
              >
                {c.average || c.average === 0 ? (
                  <List.Item.Meta
                    description={
                      <>
                        <strong>
                          {(Math.round(c.average * 10) / 10).toFixed(1)}
                        </strong>{" "}
                        ({c.count} {c.count > 1 ? "reviews" : "review"})
                      </>
                    }
                    style={{ textAlign: "right" }}
                  />
                ) : (
                  <List.Item.Meta
                    description={
                      !count
                        ? currencyFormatter(c.value)
                        : formattedNumber(c.value)
                    }
                    style={{ textAlign: "right" }}
                  />
                )}
              </Skeleton>
              <Skeleton
                loading={loading}
                active
                title={{ width: "80%" }}
                paragraph={{ rows: 0 }}
              >
                <List.Item.Meta
                  description={
                    <Tag
                      className={styles.percentTag}
                      color={c.trend < 1 ? "#ff7056" : "#00c292"}
                    >
                      {percentFormatter(c.trend)}
                      {c.trend < 1 ? (
                        <ArrowDown />
                      ) : (
                        <ArrowUp className={styles.up} />
                      )}
                    </Tag>
                  }
                  style={{ textAlign: "right" }}
                />
              </Skeleton>
            </List.Item>
          ))}
        </List>
      </div>
      {total && (
        <List className={styles.tableFooter}>
          <List.Item className="px12">
            <List.Item.Meta title="Total" />
            <List.Item.Meta
              title={currencyFormatter(total)}
              style={{ textAlign: "right", paddingRight: 12 }}
            />
          </List.Item>
        </List>
      )}
    </Card>
  );
};

export default TableCard;
