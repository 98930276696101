import React from "react";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Typography from "antd/es/typography";

const PricingDetails = () => {
  return (
    <Row gutter={16}>
      <Col xs={24}>
        <Typography.Title level={3}>Pricing Details</Typography.Title>
      </Col>
      <Col xs={3}>
        <Typography.Text strong>Roll of Grace:</Typography.Text> <br />
        <Typography.Text strong>Ice & Water:</Typography.Text>{" "}
      </Col>
      <Col xs={2} style={{ textAlign: "right" }}>
        <Typography.Text type="secondary">$65</Typography.Text>
        <br />
        <Typography.Text type="secondary">$50</Typography.Text>
        <br />
      </Col>
      <Col xs={7} />
      <Col xs={3}>
        <Typography.Text strong>Office Dump:</Typography.Text> <br />
        <Typography.Text strong>Dump Trailer:</Typography.Text> <br />
        <Typography.Text strong>Dump Truck:</Typography.Text> <br />
        <Typography.Text strong>Dumpster:</Typography.Text>{" "}
      </Col>
      <Col xs={2} style={{ textAlign: "right" }}>
        <Typography.Text type="secondary">$75</Typography.Text>
        <br />
        <Typography.Text type="secondary">$300</Typography.Text>
        <br />
        <Typography.Text type="secondary">$300</Typography.Text>
        <br />
        <Typography.Text type="secondary">$420</Typography.Text>
        <br />
      </Col>
    </Row>
  );
};

export default PricingDetails;
