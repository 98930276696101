import React from "react";
import BarChartWithSwitch from "Reports/BarChartWithSwitch";

export default function Sold() {
  return (
    <BarChartWithSwitch
      path="/reports/install-labor"
      _value="Value"
      //   secondary="Count"
      report="INSTALL LABOR"
      headers={["Install Member", "Total"]}
      yearOnly
    />
  );
}
