const dumpsterStatus = {
  Ordered: "#108ee9",
  "Pickup Held": "#999999",
  "Order Held": "#999999",
  "On Hold": "#999999",
  Swaped: "#108ee9",
  "Re-Sent": "#108ee9",
  Canceled: "#ff7056",
  "Picked-up": "#00c292",
};

export default dumpsterStatus;
