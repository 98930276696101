import React, { useState, useEffect, useCallback } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchTable } from "Actions/tableActions";
import api from "API";
import Input from "antd/es/input";
import Form from "antd/es/form";
import message from "antd/es/message";
import Button from "antd/es/button";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import CancelButton from "Components/CancelButton";
import Select from "Components/Inputs/Select";
import Currency from "Components/Inputs/Currency";
import Modal from "antd/es/modal";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";

const messageKey = "companyExpenseKey";

const CompanyExpense = ({
  unsavedChanges,
  setUnsavedChanges,
  handleClose,
  setTitle,
  type,
}) => {
  const dispatch = useDispatch();
  const formDetails = useSelector(
    (state) => state.formState.companyExpense.formDetails
  );
  const table = useSelector((state) => state.tableState.table);
  const col = useSelector((state) => state.tableState.col);
  const order = useSelector((state) => state.tableState.order);
  const search = useSelector((state) => state.tableState.search);
  const activeFilter = useSelector((state) => state.tableState.activeFilter);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!formDetails.editing) {
      setTitle("Create Company Expense");
    } else {
      setTitle(
        <span>
          Edit Company Expense{" "}
          <Button
            size="small"
            danger
            onClick={confirmDelete}
            style={{ float: "right", marginRight: 28 }}
          >
            Delete
          </Button>
        </span>
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onFinish = useCallback(
    async (values) => {
      try {
        setLoading(true);
        // message.loading("Saving Company Expense...", 0);
        message.loading({
          content: "Saving company expense...",
          duration: 0,
          key: messageKey,
        });

        if (!formDetails.editing) {
          await api.post("/companyExpenses/create", values);
        } else {
          await api.post("/companyExpenses/edit", values);
        }
        dispatch(
          fetchTable({
            table,
            col,
            order,
            search,
            activeFilter,
            fetching: false,
          })
        );
        unstable_batchedUpdates(() => {
          setUnsavedChanges(false);
          setLoading(false);
        });
        // message.success("Company expense saved");
        message.success({
          content: "Company expense saved",
          duration: 1.5,
          key: messageKey,
        });
        handleClose(true);
      } catch (err) {
        setLoading(false);
        // message.error("Error saving company expense");
        message.error({
          content: "Error saving company expense",
          duration: 1.5,
          key: messageKey,
        });
        console.log("err", err);
      }
    },
    [
      dispatch,
      formDetails.editing,
      table,
      col,
      order,
      search,
      activeFilter,
      handleClose,
      setUnsavedChanges,
    ]
  );

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error saving company exepnse");
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  const confirmDelete = () => {
    Modal.confirm({
      zIndex: 4002,
      icon: <QuestionCircleOutlined />,
      centered: true,
      cancelText: "Cancel",
      okText: "Delete",
      okButtonProps: { danger: true },
      content: <div>Are you sure you want to delete this company expense?</div>,
      onOk() {
        return new Promise((resolve, reject) => {
          deleteExpense(() => resolve(true));
        }).catch((err) => console.log("err: ", err));
      },
      onCancel() {},
    });
  };

  const deleteExpense = async (cb) => {
    try {
      await api.delete(`/companyExpenses/delete/${formDetails._id}`);

      fetch({
        table,
        col,
        order,
        search,
        activeFilter,
        fetching: false,
      });

      setUnsavedChanges(false);
      message.success("Company Expense Deleted");
      cb();
      handleClose(true);
    } catch (err) {
      console.log("err", err);
      message.error("Error deleting company expense");
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      initialValues={{
        _id: formDetails._id ? formDetails._id : undefined,
        type: formDetails.type ? formDetails.type : undefined,
        amount: formDetails.amount ? formDetails.amount : undefined,
      }}
      className="form"
    >
      <RemoveAutocomplete />

      <FormBody
        className="content-inner"
        style={{
          paddingLeft: 24,
          paddingTop: 24,
          paddingRight: 24,
          paddingBottom: 8,
          maxHeight: type === "modal" ? "calc(100vh - 161px)" : "unset",
        }}
      >
        <Form.Item name="_id" hidden>
          <Input />
        </Form.Item>

        <Form.Item
          name="type"
          label="Type"
          rules={[{ required: true, message: "Type is required" }]}
        >
          <Select
            options={[
              "ABC",
              "Allied-Beacon",
              "Dumping",
              "Gutters",
              "Payroll",
              "Sub Pay",
              "Square Deal",
              "Wimsatt",
            ]}
            listHeight={160}
            id="role"
          />
        </Form.Item>

        <Form.Item
          name="amount"
          label="Amount"
          rules={[{ required: true, message: "Amount is required" }]}
        >
          <Currency id="amount" />
        </Form.Item>
      </FormBody>
      <FormControls className="sticky-footer">
        <CancelButton handleClick={handleClose} />
        <Form.Item style={{ margin: 0 }}>
          <Button
            htmlType="submit"
            type="primary"
            loading={loading}
            disabled={loading || !unsavedChanges}
          >
            Submit
          </Button>
        </Form.Item>
      </FormControls>
    </Form>
  );
};

export default CompanyExpense;
