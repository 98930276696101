import React, { useRef, useState, useCallback } from "react";
import { updateActivitiesAndHashtags } from "Actions/dataActions";
import { useSelector, useDispatch } from "react-redux";
import Form from "antd/es/form";
import Button from "antd/es/button";
import message from "antd/es/message";
import Select from "antd/es/select";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import useIsMobile from "Hooks/useIsMobile";
import api from "API";
import CancelButton from "Components/CancelButton";

const EditHashTags = ({ unsavedChanges, setUnsavedChanges, handleClose }) => {
  const dispatch = useDispatch();
  const viewableActivities = useSelector(
    (state) => state.dataState.viewableActivities
  );
  const formDetails = useSelector(
    (state) => state.formState.hashtags.formDetails
  );

  const mobile = useIsMobile();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const ref = useRef();

  const onFinish = useCallback(
    async (values) => {
      try {
        setLoading(true);
        values._id = formDetails._id;
        values._projectId = formDetails._projectId;
        values.prevHashtags = formDetails.hashtags;
        values.hashtags = [];

        values._hashtags.map((v) => {
          values.hashtags.push(v.toLowerCase());
          return v;
        });

        const res = await api.post("/activity/update-hashtags", values);

        dispatch(
          updateActivitiesAndHashtags({
            viewableActivities,
            data: res.data,
            _projectId: formDetails._projectId,
            dashboard: formDetails.dashboard,
          })
        );

        if (formDetails.fetchPosts) formDetails.fetchPosts();

        handleClose(true);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        message.error("Error creating activity");
        console.log("err", err);
      }
    },
    [formDetails, dispatch, viewableActivities, handleClose]
  );

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error updating followers");
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  const cancel = () => {
    handleClose();
  };

  return (
    <Form
      form={form}
      className="form"
      autoComplete="off"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      initialValues={{ _hashtags: formDetails.hashtags }}
    >
      <RemoveAutocomplete />

      <FormBody
        className="content-inner"
        style={{
          paddingLeft: 24,
          paddingTop: 24,
          paddingRight: 24,
          paddingBottom: 8,
          maxHeight: "calc(100vh - 161px)",
        }}
      >
        <Form.Item name="_hashtags">
          <Select
            allowClear
            autoFocus
            mode="tags"
            showSearch={!mobile}
            dropdownClassName={mobile ? "isMobile" : null}
            getPopupContainer={(trigger) =>
              !mobile
                ? trigger.parentNode.parentNode.parentNode.parentNode.parentNode
                : document.body
            }
            style={{ textTransform: "lowercase" }}
            ref={ref}
            onChange={() => ref.current.blur()}
            placeholder="Hashtags..."
          >
            <Select.Option value="Contract">contract</Select.Option>
            <Select.Option value="Document">document</Select.Option>
            <Select.Option value="Images">image</Select.Option>
            <Select.Option value="Pre-Job Survey">pre-job survey</Select.Option>
          </Select>
        </Form.Item>
      </FormBody>

      <FormControls className="sticky-footer">
        <CancelButton handleClick={cancel} />
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={loading || !unsavedChanges}
        >
          Submit
        </Button>
      </FormControls>
    </Form>
  );
};

export default EditHashTags;
