module.exports = function revertMarkup(string) {
  let text = string;
  text.trim();

  text = text.replace(/<b>(.*?)<\/b>/g, "**$1**");
  text = text.replace(/<u>(.*?)<\/u>/g, "__$1__");
  text = text.replace(/<i>(.*?)<\/i>/g, "~~$1~~");
  text = text.replace(/<del>(.*?)<\/del>/g, "--$1--");
  text = text.replace(/<span style="color:red;">(.*?)<\/span>/g, "++$1++");
  text = text.replace(/<span>(.*?)<\/span>/g, "$1");
  text = text.replace(/<br \/>/g, "\n");
  text = text.replace(/<br\/>/g, "\n");
  text = text.replace(/<br>/g, "\n");

  return text;
};
