import * as actions from "Actions/actionTypes";

let defaultCollapsed = localStorage.getItem("collapsed");
let defaultActiveRoute = localStorage.getItem("activeRoute") || "signin";

if (defaultCollapsed === "true") {
  defaultCollapsed = true;
} else {
  defaultCollapsed = false;
}

const defaultState = {
  collapsed: defaultCollapsed,
  theme: "dark",
  menuOpen: false,
  activeRoute: defaultActiveRoute,
};

const settingsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actions.SET_COLLAPSED:
      return { ...state, collapsed: action.payload };
    case actions.SHOW_MENU:
      return { ...state, menuOpen: action.payload };
    case actions.SET_ACTIVE_ROUTE:
      return {
        ...state,
        activeRoute: action.payload,
        menuOpen: false,
      };
    default:
      return state;
  }
};

export default settingsReducer;
