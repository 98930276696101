import React, { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateDetail } from "Actions/dataActions";
import { fetchTable } from "Actions/tableActions";
import Form from "antd/es/form";
import message from "antd/es/message";
import Button from "antd/es/button";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import api from "API";
import CancelButton from "Components/CancelButton";
import Select from "Components/Inputs/Select";
import List from "antd/es/list";
import Modal from "antd/es/modal";

const messageKey = "messageKey";

const ChangeStatus = ({
  unsavedChanges,
  setUnsavedChanges,
  handleClose,
  type,
}) => {
  const dispatch = useDispatch();
  const formDetails = useSelector(
    (state) => state.formState.changeStatus.formDetails
  );
  const viewableActivities = useSelector(
    (state) => state.dataState.viewableActivities
  );
  const table = useSelector((state) => state.tableState.table);
  const col = useSelector((state) => state.tableState.col);
  const order = useSelector((state) => state.tableState.order);
  const search = useSelector((state) => state.tableState.search);
  const activeFilter = useSelector((state) => state.tableState.activeFilter);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [statuses, setStatuses] = useState([
    "Estimate DOA",
    "Estimate WIP",
    "Estimate Next Year",
    "Estimate Sold",
    "Estimate Not Sold",
  ]);

  useEffect(() => {
    if (formDetails.projectStatus) {
      setStatuses((currentStatuses) => {
        const newStatuses = currentStatuses.filter(
          (status) => status !== formDetails.projectStatus
        );
        return newStatuses;
      });
    }
  }, [formDetails.projectStatus]);

  const onFinish = useCallback(
    async (values) => {
      try {
        setLoading(true);

        message.loading({
          content: "Changing status...",
          duration: 0,
          key: messageKey,
        });
        values._id = formDetails._id;

        const res = await api.post("/estimates/change-status", values);

        dispatch(
          updateDetail({
            viewableActivities,
            key: "details",
            value: res.data.details,
            activities: res.data.activities,
          })
        );

        dispatch(
          fetchTable({
            table,
            col,
            order,
            search,
            activeFilter,
            fetching: false,
          })
        );

        message.success({
          content: "Status has been changed",
          duration: 1.5,
          key: messageKey,
        });
        handleClose(true);
      } catch (err) {
        setLoading(false);
        message.error({
          content: "Error changing status",
          duration: 1.5,
          key: messageKey,
        });
        console.log("err", err);
      }
    },
    [
      formDetails._id,
      dispatch,
      table,
      col,
      order,
      search,
      activeFilter,
      handleClose,
      viewableActivities,
    ]
  );

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error changing status");
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  const handleOpen = () => {
    setVisible(true);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      className="form"
      initialValues={{
        projectStatus: formDetails.projectStatus,
      }}
    >
      <RemoveAutocomplete />

      <FormBody
        className="content-inner"
        style={{
          paddingLeft: 24,
          paddingTop: 24,
          paddingRight: 24,
          paddingBottom: 8,
          maxHeight: type === "modal" ? "calc(100vh - 161px)" : "unset",
        }}
      >
        <div className="ant-col ant-form-item-label" style={{ width: "100%" }}>
          <label
            htmlFor="scopes"
            className="ant-form-item-required"
            title="Scopes"
          >
            Project Status
          </label>
          <Button
            onClick={handleOpen}
            size="small"
            type="primary"
            style={{ float: "right" }}
          >
            Explanation
          </Button>
        </div>

        <ExplanationModal visible={visible} setVisible={setVisible} />

        <Form.Item
          name="projectStatus"
          rules={[{ required: true, message: "Estimate Result is required" }]}
        >
          <Select options={statuses} id="projectStatus" />
        </Form.Item>
      </FormBody>
      <FormControls className="sticky-footer">
        <CancelButton handleClick={handleClose} />
        <Form.Item style={{ margin: 0 }}>
          <Button
            htmlType="submit"
            type="primary"
            loading={loading}
            disabled={loading || !unsavedChanges}
          >
            Submit
          </Button>
        </Form.Item>
      </FormControls>
    </Form>
  );
};

const ExplanationModal = ({ visible, setVisible }) => {
  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      visible={visible}
      onCancel={handleCancel}
      zIndex={4100}
      footer={false}
    >
      <List>
        <List.Item>
          <List.Item.Meta
            title="Estimate DOA:"
            description="An Estimate was provided but you do not have a good feeling
                    about the customer and would like rehash emailing to
                    commence."
          />
        </List.Item>
        <List.Item>
          <List.Item.Meta
            title="Estimate WIP:"
            description="An Estimate was provided and you have a good feeling about
                    the customer and you know you will stay in active contact
                    with until a decision is made."
          />
        </List.Item>
        <List.Item>
          <List.Item.Meta
            title="Estimate Next Year:"
            description="An Estimate was provided but they are getting prices to save
                    for next year. Email marketing will be sent out about
                    financing and email marketing will start up again after 10
                    months has passed."
          />
        </List.Item>
        <List.Item>
          <List.Item.Meta
            title="Estimate Sold:"
            description="An Estimate was provided and was sold on the spot."
          />
        </List.Item>
        <List.Item>
          <List.Item.Meta
            title="Estimate Not Sold:"
            description="An Estimate was provided but you do not have a good feeling
                    about the project or the customer may not be a good fit to
                    work with. This should rarely be used."
          />
        </List.Item>
      </List>
    </Modal>
  );
};

export default ChangeStatus;
