import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateDetails } from "Actions/dataActions";
import { fetchTable } from "Actions/tableActions";
import Form from "antd/es/form";
import message from "antd/es/message";
import Button from "antd/es/button";
import Select from "antd/es/select";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import api from "API";
import useIsMobile from "Hooks/useIsMobile";
import CancelButton from "Components/CancelButton";

const messageKey = "messageKey";

const LeadLost = ({ unsavedChanges, setUnsavedChanges, handleClose, type }) => {
  const dispatch = useDispatch();
  const formDetails = useSelector(
    (state) => state.formState.leadLost.formDetails
  );
  const viewableActivities = useSelector(
    (state) => state.dataState.viewableActivities
  );
  const table = useSelector((state) => state.tableState.table);
  const col = useSelector((state) => state.tableState.col);
  const order = useSelector((state) => state.tableState.order);
  const search = useSelector((state) => state.tableState.search);
  const activeFilter = useSelector((state) => state.tableState.activeFilter);
  const mobile = useIsMobile();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [lossReasons, setLossReasons] = useState([]);

  useEffect(() => {
    getReasons();
  }, []);

  async function getReasons() {
    try {
      const res = await api.post("/dropdowns/get", {
        selects: ["Lead Lost Reasons"],
      });

      setLossReasons(res.data["Lead Lost Reasons"].options);
    } catch (err) {
      console.log("err", err);
    }
  }

  const onFinish = useCallback(
    async (values) => {
      try {
        setLoading(true);
        // message.loading("Setting Lead as Lost...", 0);
        message.loading({
          content: "Marking Lead as Lost...",
          duration: 0,
          key: messageKey,
        });
        values._id = formDetails._id;

        const res = await api.post("/leads/lost", values);

        dispatch(
          updateDetails({
            viewableActivities,
            details: res.data._details,
            activities: res.data._activities,
          })
        );

        dispatch(
          fetchTable({
            table,
            col,
            order,
            search,
            activeFilter,
            fetching: false,
          })
        );

        // message.success("Lead marked lost");
        message.success({
          content: "Lead marked Lost",
          duration: 1.5,
          key: messageKey,
        });
        handleClose(true);
      } catch (err) {
        setLoading(false);
        // message.error("Error converting Lead to lost");
        message.error({
          content: "Error marking Lead as Lost",
          duration: 1.5,
          key: messageKey,
        });
        console.log("err", err);
      }
    },
    [
      formDetails._id,
      viewableActivities,
      dispatch,
      table,
      col,
      order,
      search,
      activeFilter,
      handleClose,
    ]
  );

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error converting Lead to lost");
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      className="form"
    >
      <RemoveAutocomplete />

      <FormBody
        className="content-inner"
        style={{
          paddingLeft: 24,
          paddingTop: 24,
          paddingRight: 24,
          paddingBottom: 8,
          maxHeight: type === "modal" ? "calc(100vh - 161px)" : "unset",
        }}
      >
        <Form.Item
          name="lostValue"
          label="Loss Reason"
          rules={[{ required: true, message: "Lost reason is required" }]}
        >
          <Select
            autoFocus
            getPopupContainer={(trigger) =>
              !mobile
                ? trigger.parentNode.parentNode.parentNode.parentNode.parentNode
                    .parentNode.parentNode
                : document.body
            }
            showSearch={!mobile}
            dropdownClassName={mobile ? "isMobile" : null}
          >
            {lossReasons.map((reason) => {
              return (
                <Select.Option key={reason} value={reason}>
                  {reason}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      </FormBody>
      <FormControls className="sticky-footer">
        <CancelButton onClick={handleClose} />
        <Form.Item style={{ margin: 0 }}>
          <Button
            htmlType="submit"
            type="primary"
            loading={loading}
            disabled={loading || !unsavedChanges}
          >
            Submit
          </Button>
        </Form.Item>
      </FormControls>
    </Form>
  );
};

export default LeadLost;
