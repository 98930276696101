import React from "react";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Button from "antd/es/button";
import message from "antd/es/message";
import csv from "Utils/csv";
import api from "API";

const ZohoExports = () => {
  const exportActivities = async () => {
    try {
      const response = await api.get("/reports-admin/export-activities");
      if (response.data.length > 0) {
        csv("activities.csv", response.data);
      } else {
        message.error("No data to download");
      }
    } catch (err) {
      console.log("exportActivities err", err);
    }
  };

  const exportBreakdowns = async () => {
    try {
      const response = await api.get("/reports-admin/export-breakdowns");
      if (response.data.length > 0) {
        csv("breakdowns.csv", response.data);
      } else {
        message.error("No data to download");
      }
    } catch (err) {
      console.log("exportBreakdowns err", err);
    }
  };

  const exportCollections = async () => {
    try {
      const response = await api.get("/reports-admin/export-collections");
      if (response.data.length > 0) {
        csv("collections.csv", response.data);
      } else {
        message.error("No data to download");
      }
    } catch (err) {
      console.log("exportCollections err", err);
    }
  };

  const exportCompletionCertificates = async () => {
    try {
      const response = await api.get(
        "/reports-admin/export-completion-certificates"
      );
      if (response.data.length > 0) {
        csv("completionCertificates.csv", response.data);
      } else {
        message.error("No data to download");
      }
    } catch (err) {
      console.log("exportCompletionCertificates err", err);
    }
  };

  const exportDocuments = async () => {
    try {
      const response = await api.get("/reports-admin/export-documents");
      if (response.data.length > 0) {
        csv("documents.csv", response.data);
      } else {
        message.error("No data to download");
      }
    } catch (err) {
      console.log("exportDocuments err", err);
    }
  };

  const exportDraws = async () => {
    try {
      const response = await api.get("/reports-admin/export-draws");
      if (response.data.length > 0) {
        csv("draws.csv", response.data);
      } else {
        message.error("No data to download");
      }
    } catch (err) {
      console.log("exportDraws err", err);
    }
  };

  const exportDumpings = async () => {
    try {
      const response = await api.get("/reports-admin/export-dumpings");
      if (response.data.length > 0) {
        csv("dumpings.csv", response.data);
      } else {
        message.error("No data to download");
      }
    } catch (err) {
      console.log("exportDumpings err", err);
    }
  };

  const exportExpenses = async () => {
    try {
      const response = await api.get("/reports-admin/export-expenses");
      if (response.data.length > 0) {
        csv("expenses.csv", response.data);
      } else {
        message.error("No data to download");
      }
    } catch (err) {
      console.log("exportExpenses err", err);
    }
  };

  const exportInstallTodos = async () => {
    try {
      const response = await api.get("/reports-admin/export-install-todos");
      if (response.data.length > 0) {
        csv("installTodos.csv", response.data);
      } else {
        message.error("No data to download");
      }
    } catch (err) {
      console.log("exportInstallTodos err", err);
    }
  };

  const exportInvoices = async () => {
    try {
      const response = await api.get("/reports-admin/export-invoices");
      if (response.data.length > 0) {
        csv("invoices.csv", response.data);
      } else {
        message.error("No data to download");
      }
    } catch (err) {
      console.log("exportInvoices err", err);
    }
  };

  const exportPermits = async () => {
    try {
      const response = await api.get("/reports-admin/export-permits");
      if (response.data.length > 0) {
        csv("permits.csv", response.data);
      } else {
        message.error("No data to download");
      }
    } catch (err) {
      console.log("exportPermits err", err);
    }
  };

  const exportProjectDocuments = async () => {
    try {
      const response = await api.get("/reports-admin/export-project-documents");
      if (response.data.length > 0) {
        csv("projectDocuments.csv", response.data);
      } else {
        message.error("No data to download");
      }
    } catch (err) {
      console.log("exportProjectDocuments err", err);
    }
  };

  const exportProjects = async () => {
    try {
      const response = await api.get("/reports-admin/export-projects");
      if (response.data.length > 0) {
        csv("leads.csv", response.data);
      } else {
        message.error("No data to download");
      }
    } catch (err) {
      console.log("exportProjects err", err);
    }
  };

  const exportEstimates = async () => {
    try {
      const response = await api.get("/reports-admin/export-estimates");
      if (response.data.length > 0) {
        csv("estimates.csv", response.data);
      } else {
        message.error("No data to download");
      }
    } catch (err) {
      console.log("exportEstimates err", err);
    }
  };

  const exportScopes = async () => {
    try {
      const response = await api.get("/reports-admin/export-scopes");
      if (response.data.length > 0) {
        csv("projects.csv", response.data);
      } else {
        message.error("No data to download");
      }
    } catch (err) {
      console.log("exportScopes err", err);
    }
  };

  const exportSubs = async () => {
    try {
      const response = await api.get("/reports-admin/export-subs");
      if (response.data.length > 0) {
        csv("subs.csv", response.data);
      } else {
        message.error("No data to download");
      }
    } catch (err) {
      console.log("exportSubs err", err);
    }
  };

  const exportVendors = async () => {
    try {
      const response = await api.get("/reports-admin/export-vendors");
      if (response.data.length > 0) {
        csv("vendors.csv", response.data);
      } else {
        message.error("No data to download");
      }
    } catch (err) {
      console.log("exportVendors err", err);
    }
  };

  const exportUsers = async () => {
    try {
      const response = await api.get("/reports-admin/export-users");
      if (response.data.length > 0) {
        csv("users.csv", response.data);
      } else {
        message.error("No data to download");
      }
    } catch (err) {
      console.log("exportUsers err", err);
    }
  };

  return (
    <Row style={{ height: "100%", width: "100%" }} gutter={[16, 16]}>
      <Col xs={24} sm={12} md={12} lg={8} xl={6}>
        <Button onClick={exportActivities}>Export Activities</Button>
        <Button onClick={exportBreakdowns}>Export Breakdowns</Button>
        <Button onClick={exportCollections}>Export Collections</Button>
        <Button onClick={exportCompletionCertificates}>
          Export Completion Certificates
        </Button>
        <Button onClick={exportDocuments}>Export Documents</Button>
        <Button onClick={exportDraws}>Export Draws</Button>
        <Button onClick={exportDumpings}>Export Dumpings</Button>
        <Button onClick={exportExpenses}>Export Expenses</Button>
        <Button onClick={exportInstallTodos}>Export Install Todos</Button>
        <Button onClick={exportInvoices}>Export Invoices</Button>
        <Button onClick={exportPermits}>Export Permits</Button>
        <Button onClick={exportProjectDocuments}>
          Export Project Documents
        </Button>
        <Button onClick={exportProjects}>Export Leads</Button>
        <Button onClick={exportEstimates}>Export Estimates</Button>
        <Button onClick={exportScopes}>Export Projects</Button>
        <Button onClick={exportSubs}>Export Subs</Button>
        <Button onClick={exportVendors}>Export Vendors</Button>
        <Button onClick={exportUsers}>Export Users</Button>
      </Col>
    </Row>
  );
};

export default ZohoExports;
