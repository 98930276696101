import React from "react";
import BarChartWithDrilldown from "Reports/BarChartWithDrilldown";

export default function AR(props) {
  return (
    <BarChartWithDrilldown
      path="/reports/ar"
      type="currency"
      summary={true}
      dateRange={false}
      units="VALUE"
      report="ACCOUNT RECEIVABLES"
      headers={["Salesman", "Total"]}
      subHeaders={["AR Aging", "Total"]}
      table={true}
    />
  );
}
