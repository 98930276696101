import React from "react";
import Card from "antd/es/card";
import Row from "antd/es/row";
import Col from "antd/es/col";
import List from "antd/es/list";
import Rate from "antd/es/rate";
import Avatar from "antd/es/avatar";
import styles from "./styles.module.scss";

import bbbLogo from "Assets/bbb_logo.png";
import googleLogo from "Assets/google_logo.png";
import facebookLogo from "Assets/facebook_logo.png";

const ReviewRatings = () => {
  return (
    <Card bordered={false} className={styles.card}>
      <Row gutter={16} style={{ height: "100%" }}>
        <Col xs={8} style={{ height: "100%" }}>
          <List
            bordered={false}
            split={false}
            className={styles.reviewContainer}
          >
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar size={84} src={googleLogo} />}
                title="Google Rating"
                description={
                  <div>
                    <div className={styles.rating}>
                      4.8{" "}
                      <Rate
                        style={{ marginLeft: 4 }}
                        value={4.8}
                        allowHalf
                        disabled
                      />
                    </div>
                    <div className={styles.offWhite}>Based on 240 reviews</div>
                  </div>
                }
              />
            </List.Item>
          </List>
        </Col>
        <Col xs={8} style={{ height: "100%" }}>
          <List
            bordered={false}
            split={false}
            className={styles.reviewContainer}
          >
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar size={84} src={facebookLogo} />}
                title="FaceBook Rating"
                description={
                  <div>
                    <div className={styles.rating}>
                      4.9{" "}
                      <Rate
                        style={{ marginLeft: 4 }}
                        value={4.9}
                        allowHalf
                        disabled
                      />
                    </div>
                    <div className={styles.offWhite}>Based on 55 reviews</div>
                  </div>
                }
              />
            </List.Item>
          </List>
        </Col>
        <Col xs={8} style={{ height: "100%" }}>
          <List
            bordered={false}
            split={false}
            className={styles.reviewContainer}
          >
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar size={84} src={bbbLogo} />}
                title="BBB Rating"
                description={
                  <div>
                    <div className={styles.rating}>
                      5{" "}
                      <Rate
                        style={{ marginLeft: 4 }}
                        value={5}
                        allowHalf
                        disabled
                      />
                    </div>
                    <div className={styles.offWhite}>Based on 2 reviews</div>
                  </div>
                }
              />
            </List.Item>
          </List>
        </Col>
      </Row>
    </Card>
  );
};

export default ReviewRatings;
