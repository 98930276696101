import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { openForm } from "Actions/formActions";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
import Button from "antd/es/button";
import Call from "Icons/Call";
import Post from "Icons/Post";
import Typography from "antd/es/typography";
import Link from "Icons/Link";
import removePhoneSymbols from "Utils/removePhoneSymbols";
import Card from "antd/es/card";
import dayjs from "dayjs";
import LazyLoad from "Components/LazyLoad";
import Modal from "antd/es/modal";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";
import api from "API";
import openInNewTab from "Utils/openInNewTab";
import Delete from "Icons/Delete";
import MapMarker from "Icons/MapMarker";
import Check from "Icons/Check";
import Avatar from "antd/es/avatar";
import generateAvatar from "Utils/generateAvatar";
import ImageFallback from "Components/images/Image";
import nomatch from "Assets/avatars/default.png";

const MobileScope = ({
  scope,
  scopes,
  markers,
  setScopes,
  container,
  removePins,
  index,
  carouselRef,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const openPosts = async (e) => {
    e.stopPropagation();
    const res = await api.get(`/lead-check/${scope._projectId}`);
    navigate(`/install/todos/${res.data}/view/posts/${scope._projectId}`);
  };

  const openActivity = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(
        openForm("activity", {
          _id: scope._projectId,
          customerId: scope.customerId,
          projectStatus: "Sold",
          customerName: scope.customerName,
          projectManager: {
            name:
              scope && scope.workOrder
                ? scope.workOrder.projectManagerName
                : undefined,
          },
        })
      );
    },
    [dispatch, scope]
  );

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const confirmCompleted = (e) => {
    e.stopPropagation();
    Modal.confirm({
      zIndex: 4002,
      icon: <QuestionCircleOutlined />,
      centered: true,
      cancelText: "Cancel",
      okText: "Confirm",
      content: <div>Are you sure you want to mark this to-do complete?</div>,
      onOk() {
        return new Promise((resolve, reject) => {
          markCompleted(() => resolve(true));
        }).catch((err) => console.log("err: ", err));
      },
      onCancel() {},
    });
  };

  const markCompleted = async (cb) => {
    try {
      const res = await api.put("/install/todos/complete", { _id: scope._id });
      await removePins();

      await setScopes(res.data);

      if (index > 0) {
        carouselRef.current.goTo(index - 1);
      }

      cb();
    } catch (err) {
      console.log("err", err);
    }
  };

  const confirmDelete = (e) => {
    e.stopPropagation();
    Modal.confirm({
      zIndex: 4002,
      icon: <QuestionCircleOutlined />,
      centered: true,
      cancelText: "Cancel",
      okText: "Delete",
      okButtonProps: { danger: true },
      content: <div>Are you sure you want to delete this to-do?</div>,
      onOk() {
        return new Promise((resolve, reject) => {
          deleteTodo(() => resolve(true));
        }).catch((err) => console.log("err: ", err));
      },
      onCancel() {},
    });
  };

  const deleteTodo = async (cb) => {
    try {
      const res = await api.delete(`/install/todos/${scope._id}`);
      await removePins();

      setScopes(res.data);

      cb();
    } catch (err) {
      console.log("err", err);
    }
  };

  const openMap = (e) => {
    e.stopPropagation();
    openInNewTab(
      `https://www.google.com/maps/place/${scope.projectAddressLat},${scope.projectAddressLng}`
    );
  };

  const openChangeDueDate = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(
        openForm("installTodoDueDate", {
          _id: scope._id,
          dueDate: scope.dueDate,
          callback: (data) => {
            setScopes(data);
          },
        })
      );
    },
    [dispatch, scope, setScopes]
  );

  const openChangeAssignee = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(
        openForm("installTodoAssignee", {
          _id: scope._id,
          assignedTo: scope.assignedTo,
          callback: (data) => {
            setScopes(data);
          },
        })
      );
    },
    [dispatch, scope, setScopes]
  );

  const openChangeTodo = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(
        openForm("installTodoChange", {
          _id: scope._id,
          todo: scope.todo,
          callback: (data) => {
            setScopes(data);
          },
        })
      );
    },
    [dispatch, scope, setScopes]
  );

  const openChangeImage = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(
        openForm("installTodoChangeImage", {
          _id: scope._id,
          callback: (data) => {
            setScopes(data);
          },
        })
      );
    },
    [dispatch, scope, setScopes]
  );

  return (
    <Card
      className={styles.mobileScope}
      hoverable
      size="small"
      cover={
        <>
          <Button
            danger
            shape="circle"
            size="large"
            className={styles.moreVert}
            onClick={confirmDelete}
          >
            <Delete />
          </Button>
          <div
            onClick={openChangeDueDate}
            className={`${styles.dateStamp} ${
              scope.dueDate
                ? dayjs(scope.dueDate).isSameOrBefore(dayjs(), "day")
                  ? styles.due
                  : scope.dueDate
                  ? ""
                  : styles.none
                : styles.none
            }`}
          >
            <span className={styles.stamp}>
              {scope.dueDate
                ? dayjs(scope.dueDate).format("MMMM D, YYYY")
                : "Add Due Date"}
            </span>
          </div>

          <div className={styles.assignedToContainer}>
            {scope.assignedTo && scope.assignedTo.length > 0 ? (
              <LazyLoad
                offsetVertical={200}
                height="42px"
                width="42px"
                root={container}
                style={{ display: "inline-block" }}
              >
                {scope.assignedTo.map((assignee) => (
                  <Avatar
                    key={assignee._id}
                    onClick={openChangeAssignee}
                    className={styles.avatar}
                    size={42}
                    src={generateAvatar(
                      assignee.email.split("@")[0] || "nomatch"
                    )}
                    icon={<img alt="avatar" src={nomatch} />}
                  />
                ))}
              </LazyLoad>
            ) : (
              <LazyLoad
                offsetVertical={200}
                height="42px"
                width="42px"
                root={container}
                style={{ display: "inline-block" }}
              >
                <Avatar
                  onClick={openChangeAssignee}
                  className={styles.avatar}
                  size={42}
                  src={generateAvatar("nomatch")}
                  icon={<img alt="avatar" src={nomatch} />}
                />
              </LazyLoad>
            )}
          </div>

          <span className={styles.mobilePoCustomerName}>
            <Typography.Title
              level={5}
              style={{ margin: 0, display: "inline-block" }}
            >
              {`${scope.customerId ? `${scope.customerId} - ` : ""}${
                scope.customerName
              }`}
            </Typography.Title>
          </span>
          <div
            style={{ overflow: "hidden", maxHeight: 120 }}
            className={scope.customerId ? "" : styles.imageContainer}
            onClick={scope.customerId ? null : openChangeImage}
          >
            <LazyLoad
              offsetVertical={200}
              height="120px"
              width={"100%"}
              root={container}
            >
              <ImageFallback
                alt="install-to-do"
                image={{
                  src: scope.starredImageSrc || undefined,
                  thumbnail: scope.starredImageSrcThumbnail || undefined,
                }}
              />
            </LazyLoad>
          </div>
        </>
      }
      actions={
        scope.customerId
          ? [
              <Button
                className={styles.bottomBtn}
                size="large"
                shape="circle"
                type="link"
                onClick={stopPropagation}
                href={`tel:${removePhoneSymbols(scope.phone)}`}
              >
                <Call size={32} />
              </Button>,
              <Button
                className={styles.bottomBtn}
                size="large"
                shape="circle"
                type="link"
                onClick={openActivity}
              >
                <Post size={32} />
              </Button>,
              <Button
                className={styles.bottomBtn}
                size="large"
                shape="circle"
                type="link"
                onClick={openMap}
              >
                <MapMarker size={32} />
              </Button>,

              <Button
                className={styles.bottomBtn}
                size="large"
                shape="circle"
                type="link"
                onClick={openPosts}
              >
                <Link size={32} />
              </Button>,
              <Button
                className={styles.bottomBtn}
                size="large"
                shape="circle"
                type="link"
                onClick={confirmCompleted}
              >
                <Check size={32} />
              </Button>,
            ]
          : [
              <Button
                className={styles.bottomBtn}
                size="large"
                shape="circle"
                type="link"
                onClick={stopPropagation}
                href={`tel:${removePhoneSymbols(scope.phone)}`}
              >
                <Call size={32} />
              </Button>,
              <Button
                className={styles.bottomBtn}
                size="large"
                shape="circle"
                type="link"
                onClick={openMap}
              >
                <MapMarker size={32} />
              </Button>,
              <Button
                className={styles.bottomBtn}
                size="large"
                shape="circle"
                type="link"
                onClick={confirmCompleted}
              >
                <Check size={32} />
              </Button>,
            ]
      }
    >
      <div className={styles.description}>
        <div className={styles.detailLine}>
          <Typography.Text strong style={{ display: "inline" }}>
            Created:
          </Typography.Text>{" "}
          <Typography.Text
            type="secondary"
            style={{ fontSize: 14, display: "inline" }}
          >
            {dayjs(scope.created).format("MMMM D, YYYY")}
          </Typography.Text>
        </div>
        <div
          className="ant-typography"
          onClick={openChangeTodo}
          dangerouslySetInnerHTML={{ __html: scope.todo }}
        />
      </div>
    </Card>
  );
};

export default MobileScope;
