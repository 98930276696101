import React, { useEffect, useState } from "react";
import Form from "antd/es/form";
import Currency from "Components/Inputs/Currency";
import Row from "antd/es/row";
import Col from "antd/es/col";
import styles from "./styles.module.scss";
import Checkbox from "antd/es/checkbox";
import Divider from "antd/es/divider";
import Grid from "antd/es/grid";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";
import Input from "antd/es/input";
import Select from "Components/Inputs/Select";

const { useBreakpoint } = Grid;

const LineItem = ({ field, index, form, updateBalance, scopes }) => {
  const screens = useBreakpoint();
  const [_scopes, _setScopes] = useState([]);
  const _selected =
    form.getFieldValue(["lineItems", index, "checkbox"]) || false;

  useEffect(() => {
    const newScopes = [];
    for (const scope of scopes) {
      newScopes.push({
        name: scope.name,
        label: scope.label,
        title: scope.label ? scope.label : scope.name,
        value: scope._id,
      });
    }
    // newScopes.push({
    //   name: "Not Billable Detail",
    //   label: "Not Billable Detail",
    //   title: "Not Billable Detail",
    //   value: "Not Billable Detail",
    // });
    _setScopes(newScopes);
  }, [scopes]);

  const handleScopeChange = async (scopeId) => {
    let scope = scopes.find(
      (scope) => scope._id.toString() === scopeId.toString()
    );
    let lineItems = await form.getFieldValue("lineItems");
    if (scope) {
      lineItems[index].scopeName = scope.name;
      lineItems[index].scopeLabel = scope.label;
    } else {
      lineItems[index].scopeName = "Not Billable Detail";
      lineItems[index].scopeLabel = "Not Billable Detail";
    }
    form.setFieldsValue({
      lineItems,
    });
  };

  return (
    <>
      {screens.xs && <Divider plain>{`Line Item ${index + 1}`}</Divider>}
      <Row
        gutter={16}
        style={
          screens.lg
            ? { padding: "12px 0", marginBottom: 2 }
            : screens.md
            ? { padding: "6px 0", marginBottom: 2 }
            : screens.sm
            ? { padding: "4px 0", marginBottom: 2 }
            : { padding: "12px 0 2px 0", marginBottom: 2 }
        }
        className={
          _selected ? styles.rowSelected : !screens.xs ? styles.lineItemRow : ""
        }
      >
        <Col xs={2}>
          <Form.Item
            {...field}
            name={[field.name, "checkbox"]}
            fieldKey={[field.fieldKey, "checkbox"]}
            style={!screens.xs ? { marginBottom: 0 } : { marginBottom: 12 }}
            className={styles.checkbox}
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox />
          </Form.Item>
        </Col>
        <Col xs={22}>
          <Row gutter={{ xs: 0, sm: 8, md: 12, lg: 24 }}>
            <Col xs={{ span: 22, offset: 1 }} sm={{ span: 12, offset: 0 }}>
              <Form.Item
                {...field}
                name={[field.name, "description"]}
                fieldKey={[field.fieldKey, "description"]}
                help=""
                rules={[
                  {
                    required: true,
                    message: "Description is required",
                  },
                ]}
                className={`${styles.noBorder} ${screens.xs ? styles.xs : ""}`}
              >
                <InputNoAutoComplete
                  placeholder="Description"
                  id={[field.name, "description"]}
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 22, offset: 1 }} sm={{ span: 6, offset: 0 }}>
              <Form.Item
                {...field}
                name={[field.name, "_scopeId"]}
                fieldKey={[field.fieldKey, "_scopeId"]}
                help=""
                rules={[
                  {
                    required: true,
                    message: "Scope is required",
                  },
                ]}
                className={`${styles.noBorder} ${screens.xs ? styles.xs : ""}`}
              >
                <Select
                  options={_scopes}
                  placeholder="Scope"
                  id={[field.name, "_scopeId"]}
                  onChange={handleScopeChange}
                />
              </Form.Item>

              <Form.Item
                {...field}
                name={[field.name, "scopeName"]}
                fieldKey={[field.fieldKey, "scopeName"]}
                key={[field.fieldKey, "scopeName"]}
                help=""
                hidden
              >
                <Input />
              </Form.Item>

              <Form.Item
                {...field}
                name={[field.name, "scopeLabel"]}
                fieldKey={[field.fieldKey, "scopeLabel"]}
                key={[field.fieldKey, "scopeLabel"]}
                help=""
                hidden
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={{ span: 22, offset: 1 }} sm={{ span: 6, offset: 0 }}>
              <Form.Item
                {...field}
                name={[field.name, "amount"]}
                fieldKey={[field.fieldKey, "amount"]}
                help=""
                rules={[
                  {
                    required: true,
                    message: "Amount is required",
                  },
                ]}
                className={`${styles.noBorder} ${screens.xs ? styles.xs : ""}`}
              >
                <Currency
                  align={screens.xs ? "left" : "right"}
                  placeholder="Amount"
                  autoComplete="amount-field"
                  onChange={updateBalance}
                  id={[field.name, "amount"]}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default LineItem;
