import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openForm } from "Actions/formActions";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Input from "antd/es/input";
import Button from "antd/es/button";
import api from "API";
import Document from "./Document";
import styles from "./styles.module.scss";
import FormContainer from "Forms/FormContainer";
import AddOfficeDocument from "Forms/AddOfficeDocument";
import Grid from "antd/es/grid";

const { useBreakpoint } = Grid;
let searchTimeout = 0;

const Documents = () => {
  const dispatch = useDispatch();
  const addOfficeDocument = useSelector(
    (state) => state.formState.addOfficeDocument.open
  );
  const admin = useSelector((state) => state.authState.admin);
  const screens = useBreakpoint();

  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    fetchDocuments();

    window.socket.on("office-documents", () => fetchDocuments);

    return () => {
      window.socket.removeAllListeners("office-documents");
    };
  }, []);

  const fetchDocuments = async () => {
    try {
      const res = await api.get("/office-document/get");
      setDocuments(res.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  const openAddDocument = useCallback(() => {
    dispatch(openForm("addOfficeDocument"));
  }, [dispatch]);

  const handleSearch = (e) => {
    clearTimeout(searchTimeout);

    let { value } = e.target;

    searchTimeout = setTimeout(() => {
      value = value.toLowerCase();
      value = value.replace(/\s+/g, "");
      const container = document.getElementsByClassName("office-documents")[0];
      const documents = container.getElementsByClassName("document");
      let doc;
      for (doc of documents) {
        let currentDoc = doc.getAttribute("data-doc");
        let showCurrentDoc =
          currentDoc.toLowerCase().replace(/\s+/g, "").indexOf(value) !== -1;
        if (showCurrentDoc) {
          doc.classList.remove("hide-doc");
        } else {
          doc.classList.add("hide-doc");
        }
      }
    }, 300);
  };

  return (
    <div className="content-inner p24 office-documents">
      <Row gutter={16}>
        <Col xs={24}>
          <div
            className={`${styles.searchRow} ${
              admin === "Yes" ? styles.admin : ""
            }`}
          >
            <Input
              size="large"
              placeholder="Filter Documents"
              style={{ width: "100%" }}
              onChange={handleSearch}
              allowClear
              type="search"
              autoComplete="dont-use-autocomplete"
            />
          </div>
          {admin === "Yes" && (
            <Button
              size="large"
              style={{ width: 60 }}
              type="primary"
              onClick={openAddDocument}
            >
              Add
            </Button>
          )}
        </Col>
      </Row>

      <Row gutter={16}>
        {documents.map((d) => (
          <Document
            key={d._id}
            name={d.name}
            dateUploaded={d.dateUploaded}
            document={d.document}
            admin={admin}
            id={d._id}
          />
        ))}
      </Row>

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0 },
        }}
        type={!screens.md ? "drawer" : "modal"}
        width={!screens.md ? "100%" : 320}
        title="Add Office Document"
        visible={addOfficeDocument}
        form={AddOfficeDocument}
        formType="addOfficeDocument"
      />
    </div>
  );
};

export default Documents;
