import React, { memo, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSelected } from "Actions/dataActions";
import { openForm } from "Actions/formActions";
import List from "antd/es/list";
import Button from "antd/es/button";
import Tag from "antd/es/tag";
import styles from "./styles.module.scss";
import statusObj from "Constants/status";
// import Progress from "antd/es/progress";

const ScopeItem = ({
  style,
  mobile,
  _id,
  customerId,
  customerName,
  // address,
  projectAddress,
  assignToSub,
  installStatus,
  name,
  status,
  // progress,
  statusDate,
  amount,
  extendedWarranty,
  premierWarranty,
  projectStatus,
  _projectId,
  repair,
  insurance,
  phone,
  headers,
  stage,
  woCreated,
  installProjectManager,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedRow = useSelector((state) => state.dataState.selectedRow);
  const dispatch = useDispatch();

  const onClick = useCallback(() => {
    dispatch(
      openForm("updateScope", {
        _id,
        _projectId,
        projectStatus,
        customerId,
        customerName,
        projectAddress,
        name,
        amount,
        status,
        statusDate,
        // progress,
        extendedWarranty,
        premierWarranty,
        repair,
        insurance,
        stage,
        installProjectManager,
      })
    );
  }, [
    dispatch,
    _id,
    _projectId,
    projectStatus,
    customerId,
    customerName,
    // address,
    projectAddress,
    name,
    amount,
    status,
    statusDate,
    // progress,
    extendedWarranty,
    premierWarranty,
    repair,
    insurance,
    stage,
    installProjectManager,
  ]);

  const onViewProjectClick = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(setSelected(_id));
      const locArr = location.pathname.split("/");
      if (locArr[3]) {
        navigate(`/scopes/projects/view/${locArr[3]}/${_projectId}`);
      } else {
        navigate(`/scopes/projects/view/details/${_projectId}`);
      }
    },
    [dispatch, _id, location, navigate, _projectId]
  );

  if (!mobile) {
    return (
      <List.Item
        style={style}
        className={`${styles.row} ${
          selectedRow === _id ? styles.selected : ""
        } ${woCreated ? "" : styles.danger}`}
        onClick={onClick}
      >
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[0].width,
            textAlign: headers[0].align ? headers[0].align : "left",
          }}
          description={customerId}
        />
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[1].width,
            textAlign: headers[1].align ? headers[1].align : "left",
          }}
          description={customerName}
        />
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[2].width,
            textAlign: headers[2].align ? headers[2].align : "left",
          }}
          description={projectAddress}
        />
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[3].width,
            textAlign: headers[3].align ? headers[3].align : "left",
          }}
          description={name}
        />
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[4].width,
            textAlign: headers[4].align ? headers[4].align : "left",
          }}
          description={
            <Tag className={styles.statusTag} color={statusObj[installStatus]}>
              {installStatus}
            </Tag>
          }
        />
        {/* <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[4].width,
            textAlign: headers[4].align ? headers[4].align : "left",
          }}
          description={
            <Progress
              percent={progress}
              style={{ width: "calc(100% - 4px)" }}
            />
          }
        /> */}
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[5].width,
            textAlign: headers[5].align ? headers[5].align : "left",
          }}
          description={
            <Button size="small" type="primary" onClick={onViewProjectClick}>
              View Project
            </Button>
          }
        />
      </List.Item>
    );
  } else {
    return (
      <List.Item
        style={style}
        className={`${styles.mobileListItem} ${
          selectedRow === _id ? styles.selected : ""
        } ${woCreated ? "" : styles.danger}`}
        onClick={onClick}
      >
        <List.Item.Meta className={styles.customerId} title={customerId} />
        <List.Item.Meta
          className={styles.status}
          description={
            <Tag className={styles.statusTag} color={statusObj[installStatus]}>
              {installStatus}
            </Tag>
          }
        />
        {assignToSub && assignToSub.companyName && (
          <List.Item.Meta
            className={styles.sub}
            description={assignToSub.companyName}
          />
        )}
        <div className={styles.break} />
        <List.Item.Meta
          className={styles.fullLine}
          style={{ flex: "1 1 100%", paddingBottom: 3 }}
          description={`${customerName} - ${name}`}
        />
        <div className={styles.break} />
        <List.Item.Meta
          className={styles.fullLine}
          description={projectAddress}
        />
        <div className={styles.break} />

        {/* <List.Item.Meta
          className={styles.sideBySide}
          description={<Progress percent={progress} />}
        /> */}
        <List.Item.Meta
          className={styles.sideBySide}
          description={
            <Button
              size="small"
              block
              type="primary"
              onClick={onViewProjectClick}
            >
              View Project
            </Button>
          }
        />
      </List.Item>
    );
  }
};

export default memo(ScopeItem);
