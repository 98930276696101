import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelected } from "Actions/dataActions";
import List from "antd/es/list";
import dayjs from "dayjs";
import styles from "./styles.module.scss";
import currencyFormatter from "Utils/currencyFormatter";

const BreakdownItem = ({
  style,
  mobile,
  _id,
  customerId,
  balance,
  collectionsTotal,
  expensesTotal,
  submitted,
  salesman,
  headers,
  setOpen,
}) => {
  const dispatch = useDispatch();
  const selectedRow = useSelector((state) => state.dataState.selectedRow);

  const onClick = useCallback(() => {
    dispatch(setSelected(_id));
    setOpen(true);
  }, [dispatch, _id, setOpen]);

  if (!mobile) {
    return (
      <List.Item
        style={style}
        className={`${styles.row} ${
          selectedRow === _id ? styles.selected : ""
        }`}
        onClick={onClick}
      >
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[0].width,
            textAlign: headers[0].align ? headers[0].align : "left",
          }}
          description={customerId}
        />
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[1].width,
            textAlign: headers[1].align ? headers[1].align : "left",
          }}
          description={salesman ? salesman.join(", ") : ""}
        />
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[2].width,
            textAlign: headers[2].align ? headers[2].align : "left",
          }}
          description={currencyFormatter(collectionsTotal)}
        />
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[3].width,
            textAlign: headers[3].align ? headers[3].align : "left",
          }}
          description={currencyFormatter(expensesTotal)}
        />
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[4].width,
            textAlign: headers[4].align ? headers[4].align : "left",
          }}
          description={`Balance: ${currencyFormatter(balance)}`}
        />
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[5].width,
            textAlign: headers[5].align ? headers[5].align : "left",
          }}
          description={dayjs(submitted).format("MMM D, YYYY")}
        />
      </List.Item>
    );
  } else {
    return (
      <List.Item
        style={style}
        className={`${styles.mobileListItem} ${
          selectedRow === _id ? styles.selected : ""
        }`}
        onClick={onClick}
      >
        <List.Item.Meta className={styles.customerId} title={customerId} />
        <List.Item.Meta
          className={styles.date}
          description={dayjs(submitted).format("MMM D, YY")}
        />
        <div className={styles.break} />
        <List.Item.Meta
          className={styles.fullLine}
          style={{ flex: "1 1 100%", paddingBottom: 3 }}
          description={salesman ? salesman.join(", ") : ""}
        />
        <div className={styles.break} />
        <List.Item.Meta
          className={styles.scopes}
          description={`Balance: ${currencyFormatter(balance)}`}
        />
      </List.Item>
    );
  }
};

export default BreakdownItem;
