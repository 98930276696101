import React from "react";
import HighCharts from "highcharts";
import HighChartsReact from "highcharts-react-official";
import DealFlowSider from "./DealFlowSider";
import DealFlowLabels from "./DealFlowLabels";
import Card from "antd/es/card";
import Skeleton from "antd/es/skeleton";
import styles from "./styles.module.scss";

const DealFlowReport = ({
  options,
  leads,
  sold,
  leadConversion,
  satConversion,
  soldConversion,
  loading,
  // onClick,
}) => {
  // const handleClick = () => {
  //   onClick("Deal Flow");
  // };

  return (
    <Card
      bodyStyle={{ padding: 12, height: 465 }}
      className="z-depth-1 mb16"
      style={{ overflow: "hidden" }}
      bordered={false}
      // onClick={handleClick}
      // hoverable
    >
      <div className={styles.reportTitle}>Deal Flow (90 Days Trailing)</div>
      <div style={{ height: 417 }}>
        <div className={styles.outerReportContainer}>
          <div
            className={`${styles.reportContainer} ${
              loading ? styles.loading : ""
            }`}
          >
            <Skeleton
              loading={loading}
              active
              paragraph={{ rows: 0 }}
              title={{ width: "98%" }}
            >
              <HighChartsReact
                highcharts={HighCharts}
                options={options}
                updateArgs={[true, true, true]}
              />
            </Skeleton>
          </div>
          <DealFlowSider
            leads={leads}
            sold={sold}
            soldConversion={soldConversion}
            loading={loading}
          />
          {!loading && (
            <DealFlowLabels
              leadConversion={leadConversion}
              satConversion={satConversion}
              loading={loading}
            />
          )}
        </div>
      </div>
    </Card>
  );
};

export default DealFlowReport;
