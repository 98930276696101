import React, { useState, useCallback } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchTable } from "Actions/tableActions";
import Modal from "antd/es/modal";
import Form from "antd/es/form";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import Button from "antd/es/button";
import CancelButton from "Components/CancelButton";
import ProcessFeedback from "Components/ProcessFeedback";
import message from "antd/es/message";
import api from "API";
import generateChanges from "Utils/generateChanges";
import Suppliers from "Components/Inputs/Suppliers";
import useSuppliers from "Hooks/useSuppliers";
import Input from "antd/es/input";

const messageKey = "messageKey";

const steps = [
  "Submitting Form",
  "Generating PDF",
  "Creating Post",
  "Saving PDF",
  "Updating Scope",
  "Updating List",
  "Done!",
];

const customerDetails = [
  "customerName",
  "propertyType",
  "pitches",
  "stories",
  "projectAddressStreet",
  "projectAddressCity",
  "projectAddressState",
  "projectAddressZip",
  "projectAddressLat",
  "projectAddressLng",
  "projectAddressCounty",
  "projectAddressPermitAuthority",
  "projectDescription",
  "phone",
];

const SupplierModal = ({
  form,
  visible,
  setVisible,
  supplierModalRef,
  setUnsavedChanges,
  handleClose,
  changedValues,
  _lineItems,
  companyName,
}) => {
  const dispatch = useDispatch();
  const suppliers = useSuppliers();
  const formDetails = useSelector(
    (state) => state.formState.moldReview.formDetails
  );
  const table = useSelector((state) => state.tableState.table);
  const col = useSelector((state) => state.tableState.col);
  const order = useSelector((state) => state.tableState.order);
  const search = useSelector((state) => state.tableState.search);
  const [loading, setLoading] = useState(false);

  const _user = useSelector((state) => state.authState._user);
  const [feedBack, setFeedBack] = useState({
    active: "Submitting Form",
    progress: 0,
  });

  const handleCancel = () => {
    setVisible(false);
  };

  const updateFeedBack = (feedBack) => {
    setFeedBack(feedBack);
  };

  const onApprove = useCallback(async () => {
    try {
      window.socket.on(`${_user}-work-order-progress`, updateFeedBack);
      unstable_batchedUpdates(() => {
        setFeedBack({
          active: "Submitting Form",
          progress: 1,
        });
        setLoading(true);
      });

      // message.loading("Approving...", 0);
      message.loading({
        content: "Approving office review...",
        duration: 0,
        key: messageKey,
      });
      let values = await form.validateFields();

      if (values.shingle) {
        values.manufacturer = values.shingle[0];
        values.productLine = values.shingle[1];
        values.color = values.shingle[2];
      } else if (values.siding) {
        values.manufacturer = values.siding[0];
        values.productLine = values.siding[1];
        values.color = values.siding[2];
      }

      values = await generateChanges({
        changedValues,
        values,
        customerDetails,
        _lineItems,
        workOrder: formDetails,
        woCreated: true,
      });

      if (values.suppliers) {
        values.supplierEmail = suppliers[values.suppliers].email;
        values.suppliers = suppliers[values.suppliers].companyName;
      }

      values.projectAddress = `${values.projectAddressStreet}, ${values.projectAddressCity}, ${values.projectAddressState} ${values.projectAddressZip}`;
      values.companyName = companyName;

      const res = await api.post("/pre-job-check/approved", values);

      // for (const s of res.data.salesObj) {
      //   window.socket.emit("updateDashboard", { _user: s._user });
      //   window.socket.emit("updateFeed", { _user: s._user });
      // }

      // window.socket.emit("update", { _id: formDetails._projectId });

      //   changedValues = {};
      unstable_batchedUpdates(() => {
        setUnsavedChanges(false);
        setLoading(false);
        setFeedBack({
          active: "Done!",
          progress: 100,
        });
      });

      dispatch(
        fetchTable({
          table,
          col,
          order,
          search,
          fetching: false,
        })
      );

      message.success({
        content: "Office review approved",
        duration: 1.5,
        key: messageKey,
      });
      window.socket.removeAllListeners(`${_user}-work-order-progress`);

      for (const s of res.data.salesObj) {
        window.socket.emit("updateDashboard", { _user: s._user });
        window.socket.emit("updateFeed", { _user: s._user });
      }

      window.socket.emit("update", { _id: formDetails._projectId });

      setVisible(false);
      handleClose(true);
    } catch (err) {
      console.log("err", err);
      if (
        err &&
        err.errorFields &&
        err.errorFields[0] &&
        err.errorFields[0].name
      ) {
        if (err.errorFields[0].name[0] === "lineItems") {
          const el = document.getElementById("top-of-form");
          el.scrollIntoView({ block: "end", behavior: "smooth" });
        } else {
          form.scrollToField(err.errorFields[0].name[0]);
        }
      }
      // message.error("Error approving scope");
      message.error({
        content: "Error approving office review",
        duration: 1.5,
        key: messageKey,
      });
      setLoading(false);
      setFeedBack({
        active: "Submitting Form",
        progress: 0,
      });
      window.socket.removeAllListeners(`${_user}-work-order-progress`);
    }
  }, [
    _user,
    dispatch,
    table,
    col,
    order,
    search,
    handleClose,
    _lineItems,
    form,
    formDetails,
    setUnsavedChanges,
    changedValues,
    suppliers,
    setVisible,
    companyName,
  ]);

  const handleSupplierChange = async () => {};

  return (
    <Modal
      title="Supplier Will Call"
      zIndex={5000}
      footer={null}
      centered={true}
      onCancel={handleCancel}
      visible={visible}
      //   width="80%"
      getContainer={supplierModalRef}
      forceRender
      bodyStyle={{
        maxHeight: "calc(100vh - 155px)",
        height: "100%",
        padding: 0,
      }}
      style={{
        maxHeight: "calc(100vh - 100px)",
        top: "unset",
      }}
    >
      <FormBody
        className="content-inner"
        style={{
          paddingLeft: 24,
          paddingTop: 24,
          paddingRight: 24,
          paddingBottom: 8,
          maxHeight: "calc(100vh - 161px)",
        }}
      >
        <div className={feedBack.progress === 0 ? "" : "hide"}>
          <Form.Item name="suppliers" label="Suppliers">
            <Suppliers suppliers={suppliers} onChange={handleSupplierChange} />
          </Form.Item>
          <Form.Item name="supplierEmail" hidden>
            <Input />
          </Form.Item>
        </div>

        <div
          style={{ height: "100%" }}
          className={feedBack.progress === 0 ? "hide" : ""}
        >
          <ProcessFeedback feedBack={feedBack} steps={steps} />
        </div>
      </FormBody>
      {feedBack.progress === 0 && (
        <FormControls className="sticky-footer">
          <CancelButton handleClick={handleCancel} title="Close" />

          <Button
            type="primary"
            loading={loading}
            disabled={loading}
            onClick={onApprove}
          >
            Confirm
          </Button>
        </FormControls>
      )}
    </Modal>
  );
};

export default SupplierModal;
