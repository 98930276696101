import HighCharts from "highcharts";
import HighChartsReact from "highcharts-react-official";
import drilldown from "highcharts/modules/drilldown.js";

import React, { useState, useEffect } from "react";
import { unstable_batchedUpdates } from "react-dom";
import currencyFormatter from "Utils/currencyFormatter";
import percentFormatter from "Utils/percentFormatter";
import addCommas from "Utils/addCommas";
import Row from "antd/es/row";
import Col from "antd/es/col";
import List from "antd/es/list";
import message from "antd/es/message";
import DatePicker from "Components/Inputs/DatePicker";
import Card from "antd/es/card";
import dayjs from "dayjs";
import api from "API";
import styles from "./styles.module.scss";

window.Highcharts = HighCharts;

let allowChartUpdate = true;
const { RangePicker } = DatePicker;
const end = dayjs();
const year = new Date().getFullYear();
let start = new Date(`1/1/${year}`);
start = dayjs(start);

HighCharts.setOptions({
  colors: [
    "#00c292",
    "#fec107",
    "#FF7056",
    "#343a40",
    "#01c0c8",
    "#999999",
    "#FF9655",
    "#FFF263",
    "#6AF9C4",
  ],
});

drilldown(HighCharts);

let categories = [],
  totalActive = 0;

const BarChartWithDrilldown = ({
  path,
  type,
  report,
  units,
  dateRange,
  headers,
  subHeaders,
}) => {
  const [drillOptions, setDrillOptions] = useState({
    totalValue: 0,
    table: [],
    active: "",
  });
  const [range, setRange] = useState({
    start,
    end,
  });
  const [viewingUnits, setViewingUnits] = useState("");
  const [events] = useState({
    drillup: (e) => {
      if (e.seriesOptions) {
        if (
          e.seriesOptions.name === "Manufacturers" ||
          e.seriesOptions.name === "AR"
        ) {
          allowChartUpdate = false;
          setTimeout(() => {
            unstable_batchedUpdates(() => {
              setDrillOptions({
                totalValue: totalActive,
                table: categories,
                active: "",
              });
              setViewingUnits(
                e.seriesOptions.name === "AR" ? "TOTAL VALUE" : "TOTAL SQUARES"
              );
            });
          }, 501);
        } else {
          allowChartUpdate = false;
          setTimeout(() => {
            let data = e.seriesOptions.data,
              _table = [],
              totalValue = 0;
            data.map((x) => {
              let obj = {};
              obj.name = x[0];
              obj.total = x[1];
              totalValue += x[1];
              _table.push(obj);
              return x;
            });
            unstable_batchedUpdates(() => {
              setDrillOptions({
                totalValue,
                table: _table,
                active: `${e.seriesOptions.id} `,
              });
              setViewingUnits(e.seriesOptions.units);
            });
          }, 501);
        }
      }
    },
    drilldown: (e) => {
      allowChartUpdate = false;
      if (e.seriesOptions) {
        setTimeout(() => {
          let data = e.seriesOptions.data,
            _table = [],
            totalValue = 0;
          data.map((x) => {
            let obj = {};
            obj.name = x[0];
            obj.total = x[1];
            totalValue += x[1];
            _table.push(obj);
            return x;
          });
          unstable_batchedUpdates(() => {
            setDrillOptions({
              totalValue,
              table: _table,
              active: `${e.seriesOptions.id} `,
            });
            setViewingUnits(e.seriesOptions.units);
          });
        }, 501);
      }
    },
  });
  const [state, setState] = useState({
    options: {
      chart: {
        type: "column",
        events,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: "",
      },
      subtitle: {
        text: "",
      },
      xAxis: {
        type: "category",
        labels: {
          rotation: -45,
          style: {
            fontSize: "13px",
            fontFamily: "Verdana, sans-serif",
          },
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: "",
        },
        labels: {
          formatter: function () {
            let label = this.value;
            if (this.value >= 1000000) {
              label = this.value / 1000000 + "M";
            } else if (this.value >= 1000) {
              label = this.value / 1000 + "k";
            }
            return `${label}`;
          },
        },
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        useHTML: true,
        formatter: function () {
          let _table = "<table><tr><td><b>" + this.key + "</b></tr>";
          _table += "<tr><td>";

          if (type === "currency") {
            _table += "$";
          }

          _table += HighCharts.numberFormat(this.y, 2);

          if (type === "percent") {
            _table += "%";
          }

          _table += "</td></tr></table>";
          return _table;
        },
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            formatter: function () {
              if (type === "currency") {
                return "$" + HighCharts.numberFormat(this.y, 2);
              } else if (type === "percent") {
                return HighCharts.numberFormat(this.y, 2) + "%";
              } else {
                return HighCharts.numberFormat(this.y, 2);
              }
            },
          },
        },
      },
      series: [
        {
          data: [],
          drilldown: {},
        },
      ],
    },
  });

  useEffect(() => {
    getReport();
  }, [range]); // eslint-disable-line react-hooks/exhaustive-deps

  const getReport = async () => {
    try {
      allowChartUpdate = true;
      const res = await api.post(path, { start: range.start, end: range.end });

      let _table = [];
      let theseCategories = [];
      res.data.series[0].data.map((x) => {
        let obj = {};
        obj.name = x.name;
        obj.total = x.y;
        _table.push(obj);
        theseCategories.push(obj);
        return x;
      });

      categories = theseCategories;
      totalActive = res.data.totalValue;

      unstable_batchedUpdates(() => {
        setState((thisState) => {
          return {
            ...thisState,
            options: {
              ...thisState.options,
              series: res.data.series,
              drilldown: res.data.drilldown,
            },
          };
        });

        setDrillOptions({
          totalValue: res.data.totalValue,
          table: _table,
        });

        setViewingUnits(
          res.data.series[0].name === "AR" ? "TOTAL VALUE" : "TOTAL SQUARES"
        );
      });
    } catch (err) {
      console.log("err", err);
      message.error("Error getting report");
    }
  };

  const handleDateChange = (e) => {
    setRange({ start: e[0], end: e[1] });
  };

  return (
    <div style={{ paddingBottom: 24 }} className="content-inner">
      <Card bordered={false} className="z-depth-1" style={{ margin: 24 }}>
        <Row gutter={16} style={{ marginBottom: 24 }}>
          <Col xs={24}>
            <div className={styles.reportTitle}>{report}</div>
          </Col>
        </Row>

        <Row gutter={16} style={{ marginBottom: 24 }}>
          <Col xs={24}>
            <div className={styles.reportHeader}>
              <div className={styles.total}>
                {type === "currency"
                  ? currencyFormatter(drillOptions.totalValue)
                  : type === "percent"
                  ? percentFormatter(drillOptions.totalValue)
                  : addCommas(drillOptions.totalValue.toString())}
                <small style={{ textTransform: "uppercase" }}>
                  {" "}
                  {viewingUnits}
                </small>
              </div>
            </div>
          </Col>
        </Row>
        {dateRange && (
          <Row gutter={16} style={{ marginBottom: 24 }}>
            <Col xs={24}>
              <div className={styles.reportHeader}>
                <div>
                  <div className="ant-form-item-label">
                    <label title="Date Range">Date Range</label>
                  </div>
                  <RangePicker
                    allowClear={false}
                    format="MMMM DD, YYYY"
                    defaultValue={[
                      dayjs(range.start, "MMMM DD, YYYY"),
                      dayjs(range.end, "MMMM DD, YYYY"),
                    ]}
                    onChange={handleDateChange}
                    ranges={{
                      "Last 7 Days": [
                        dayjs().subtract(7, "days").startOf("day"),
                        dayjs(),
                      ],
                      "Last Week": [
                        dayjs().startOf("week").subtract(7, "days"),
                        dayjs().startOf("week").subtract(1, "day").endOf("day"),
                      ],
                      "Last Month": [
                        dayjs().startOf("month").subtract(1, "months"),
                        dayjs()
                          .startOf("month")
                          .subtract(1, "months")
                          .endOf("month"),
                      ],
                      "This Month": [
                        dayjs().startOf("month"),
                        dayjs().endOf("month"),
                      ],
                      "This Year": [dayjs().startOf("year"), dayjs()],
                      "Last Year": [
                        dayjs().subtract(1, "year").startOf("year"),
                        dayjs().subtract(1, "year").endOf("year"),
                      ],
                      TMLY: [
                        dayjs().subtract(1, "year").startOf("month"),
                        dayjs().subtract(1, "year").endOf("month"),
                      ],
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>
        )}

        <HighChartsReact
          allowChartUpdate={allowChartUpdate}
          highcharts={HighCharts}
          options={state.options}
          updateArgs={[true, true, true]}
        />
      </Card>

      <Card
        bordered={false}
        className="z-depth-1"
        style={{
          marginTop: 24,
          marginLeft: 24,
          marginRight: 24,
        }}
        bodyStyle={{
          padding: 0,
        }}
      >
        <List>
          <List.Item className={styles.listHeader}>
            {!drillOptions.active
              ? headers.map((header, i) => {
                  if (i !== 0) {
                    return (
                      <List.Item.Meta
                        key={header}
                        className={styles.listRight}
                        title={header}
                      />
                    );
                  } else {
                    return <List.Item.Meta key={header} title={header} />;
                  }
                })
              : subHeaders.map((header, i) => {
                  if (i !== 0) {
                    return (
                      <List.Item.Meta
                        key={header}
                        className={styles.listRight}
                        title={header}
                      />
                    );
                  } else {
                    return <List.Item.Meta key={header} title={header} />;
                  }
                })}
          </List.Item>

          {drillOptions.table.map((t, i) => {
            return (
              <List.Item key={t.name + i} className={styles.listItem}>
                <List.Item.Meta title={t.name} />
                <List.Item.Meta
                  title={
                    type === "currency"
                      ? currencyFormatter(t.total)
                      : type === "percent"
                      ? percentFormatter(t.total)
                      : addCommas(t.total.toString())
                  }
                  className={styles.listRight}
                />
              </List.Item>
            );
          })}

          <List.Item className={styles.listTotal}>
            <List.Item.Meta title="Total" />
            <List.Item.Meta
              className={styles.listRight}
              title={
                type === "currency"
                  ? currencyFormatter(drillOptions.totalValue)
                  : type === "percent"
                  ? percentFormatter(drillOptions.totalValue)
                  : addCommas(drillOptions.totalValue.toString())
              }
            />
          </List.Item>
        </List>
      </Card>
    </div>
  );
};

export default BarChartWithDrilldown;
