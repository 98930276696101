import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { openImageViewer } from "Actions/dataActions";
import styles from "./styles.module.scss";
import isPdf from "Utils/isPdf";
import isVideo from "Utils/isVideo";
import openInNewTab from "Utils/openInNewTab";
import YouTube from "Icons/YouTube";
import pdf from "Assets/pdf.svg";
import ImageFallback from "Components/images/Image";

const Attachments = ({ attachments, removeImage }) => {
  const dispatch = useDispatch();
  const openImage = useCallback(
    (image) => {
      image.setImages = removeImage;
      image.link = true;
      dispatch(openImageViewer(image));
    },
    [dispatch, removeImage]
  );

  return (
    <div className={styles.imageRow} style={{ paddingLeft: 0 }}>
      {attachments.map((image) => {
        if (image) {
          const handleImageClick = () => {
            !isPdf(image.src) ? openImage(image) : openInNewTab(image.src);
          };

          return (
            <div
              key={image._id}
              className={styles.commentImage}
              onClick={handleImageClick}
            >
              {isPdf(image.src) ? (
                <img src={pdf} alt="pdf-icon" />
              ) : !isVideo(image.src) ? (
                <div className={styles.imageCarousel}>
                  <ImageFallback alt="carousel-image" image={image} />
                </div>
              ) : (
                <YouTube color="#f1f1f1" size={64} />
              )}
            </div>
          );
        }

        return null;
      })}
    </div>
  );
};

export default Attachments;
