import React, { useCallback, memo } from "react";
import { useDispatch } from "react-redux";
import { openForm } from "Actions/formActions";
import { updateSelectedActivity, openImageViewer } from "Actions/dataActions";
import { useNavigate, useLocation } from "react-router-dom";
import Col from "antd/es/col";
import isPdf from "Utils/isPdf";
import isVideo from "Utils/isVideo";
import hidePdfControls from "Utils/hidePdfControls";
import styles from "./styles.module.scss";
import pdf from "Assets/pdf.svg";
import YouTube from "Icons/YouTube";
import LazyLoad from "Components/LazyLoad";
import ImageFallback from "Components/images/Image";

const Image1 = ({ images, setImages, activity, scrollContainer, _global }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  let src0 = images[0].src;

  const openImage = useCallback(() => {
    images[0].setImages = setImages;
    dispatch(openImageViewer(images[0]));
  }, [setImages, images, dispatch]);

  const openDrawer = useCallback(() => {
    if (!images[0].legacy) {
      dispatch(updateSelectedActivity(activity));
      if (
        !location.pathname.includes("projects") &&
        !location.pathname.includes("leads")
      ) {
        dispatch(openForm("images", { index: 0 }));
      } else {
        navigate(`${location.pathname}/images/${activity._id}/${0}`);
      }
    } else {
      navigate(`${location.pathname}/legacy-files/${0}`);
    }
  }, [images, dispatch, activity, location, navigate]);

  return (
    <Col
      xs={images.length === 1 ? 24 : images.length < 4 ? 12 : 24}
      className={`${styles.border} ${_global ? styles.dark : ""} ${
        images.length < 4 ? styles.height100 : styles.height50
      } ${styles.background}`}
    >
      <div
        className={styles.clickOverlay}
        onClick={setImages ? openImage : openDrawer}
      />

      {isPdf(src0) ? (
        <LazyLoad offsetVertical={600} height={"100%"} root={scrollContainer}>
          <object data={hidePdfControls(src0)} className={styles.pdf}>
            <div>
              <img src={pdf} alt="pdf-icon" />
              <div>{images[0].name}</div>
            </div>
          </object>
        </LazyLoad>
      ) : isVideo(src0) ? (
        <LazyLoad offsetVertical={300} height={"100%"} root={scrollContainer}>
          <div className={styles.video}>
            <YouTube color="#f1f1f1" size={64} />
            <div>{images[0].name}</div>
          </div>
        </LazyLoad>
      ) : (
        <LazyLoad offsetVertical={300} height={"100%"} root={scrollContainer}>
          <ImageFallback image={images[0]} alt="activity-drawer" />
        </LazyLoad>
      )}
    </Col>
  );
};

export default memo(Image1);
