import React, { useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSelected } from "Actions/dataActions";
import { openForm } from "Actions/formActions";
import List from "antd/es/list";
import Button from "antd/es/button";
// import Call from "Icons/Call";
import Directions from "Icons/Directions";
// import Textsms from "Icons/Textsms";
import Post from "Icons/Post";
import dayjs from "dayjs";
import styles from "./styles.module.scss";
// import removePhoneSymbols from "Utils/removePhoneSymbols";
import currencyFormatter from "Utils/currencyFormatter";

const ARItem = ({
  style,
  mobile,
  _projectId,
  customerId,
  customerName,
  address,
  salesman,
  dateCompleted,
  amount,
  // phone,
  headers,
  projectStatus,
  projectManager,
  label,
  name,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedRow = useSelector((state) => state.dataState.selectedRow);
  const dispatch = useDispatch();

  const onClick = useCallback(() => {
    dispatch(setSelected(_projectId));
    const locArr = location.pathname.split("/");
    if (!locArr[3]) {
      navigate(`/account-receivables/projects/view/details/${_projectId}`);
    } else {
      navigate(`/account-receivables/projects/view/${locArr[4]}/${_projectId}`);
    }
  }, [_projectId, dispatch, location, navigate]);

  // const openSMSMobile = (e) => {
  //   e.stopPropagation();
  //   window.open(`sms:${removePhoneSymbols(phone)}`, "_self");
  //   return false;
  // };

  const openCreatePost = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(
        openForm("activity", {
          _id: _projectId,
          customerId,
          projectStatus,
          customerName,
          projectManager,
        })
      );
    },
    [
      dispatch,
      _projectId,
      customerId,
      projectStatus,
      customerName,
      projectManager,
    ]
  );

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  if (!mobile) {
    return (
      <List.Item
        style={style}
        className={`${styles.row} ${
          selectedRow === _projectId ? styles.selected : ""
        }`}
        onClick={onClick}
      >
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[0].width,
            textAlign: headers[0].align ? headers[0].align : "left",
          }}
          description={customerId}
        />
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[1].width,
            textAlign: headers[1].align ? headers[1].align : "left",
          }}
          description={customerName}
        />
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[2].width,
            textAlign: headers[2].align ? headers[2].align : "left",
          }}
          description={label || name}
        />
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[3].width,
            textAlign: headers[3].align ? headers[3].align : "left",
          }}
          description={address}
        />
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[4].width,
            textAlign: headers[4].align ? headers[4].align : "left",
          }}
          description={salesman}
        />
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[5].width,
            textAlign: headers[5].align ? headers[5].align : "left",
          }}
          description={currencyFormatter(amount)}
        />
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[6].width,
            textAlign: headers[6].align ? headers[6].align : "left",
          }}
          description={dayjs(dateCompleted).format("MMM D, YYYY")}
        />
      </List.Item>
    );
  } else {
    return (
      <List.Item
        style={style}
        className={`${styles.mobileListItem} ${
          selectedRow === _projectId ? styles.selected : ""
        }`}
        onClick={onClick}
      >
        <List.Item.Meta className={styles.customerId} title={customerId} />
        <List.Item.Meta
          className={styles.date}
          description={dayjs(dateCompleted).format("MMM D, YY")}
        />
        <div className={styles.break} />
        <List.Item.Meta
          className={styles.fullLine}
          style={{ flex: "1 1 100%", paddingBottom: 3 }}
          description={customerName}
        />
        <div className={styles.break} />
        <List.Item.Meta className={styles.fullLine} description={address} />
        <div className={styles.break} />
        <List.Item.Meta
          className={styles.scopes}
          description={currencyFormatter(amount)}
        />
        <List.Item.Meta
          className={styles.actions}
          description={
            <>
              {/* <a
                onClick={stopPropagation}
                href={`tel:${removePhoneSymbols(phone)}`}
              >
                <Button shape="circle" type="text" className={styles.button}>
                  <Call />
                </Button>
              </a>
              <Button
                onClick={openSMSMobile}
                shape="circle"
                type="text"
                className={styles.button}
              >
                <Textsms />
              </Button> */}
              <a
                href={`https://www.google.com/maps/dir/?api=1&destination=${address}`}
                target="_Blank"
                rel="noopener noreferrer"
                onClick={stopPropagation}
              >
                <Button shape="circle" type="text" className={styles.button}>
                  <Directions />
                </Button>
              </a>
              <Button
                onClick={openCreatePost}
                shape="circle"
                type="text"
                className={styles.button}
              >
                <Post />
              </Button>
            </>
          }
        />
      </List.Item>
    );
  }
};

export default ARItem;
