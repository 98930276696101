import React, { useState } from "react";
import Form from "antd/es/form";
import Divider from "antd/es/divider";
import Select from "antd/es/select";
import Button from "antd/es/button";
import Tag from "antd/es/tag";
import Currency from "Components/Inputs/Currency";
import currencyFormatter from "Utils/currencyFormatter";
import useIsMobile from "Hooks/useIsMobile";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";
import api from "API";
// import useProjectManagers from "Hooks/useProjectManagers";
// import InputSalesmen from "Components/Inputs/Salesmen";
// import ProjectManager from "Components/Inputs/ProjectManager";

const { CheckableTag } = Tag;

const Scopes = ({
  form,
  scopes,
  premierWarranties,
  field,
  fields,
  remove,
  setScopeTotal,
  scopeTotal,
}) => {
  const mobile = useIsMobile();
  const [extendedWarranties, setExtendedWarranties] = useState([]);
  // const projectManagers = useProjectManagers();

  const validateAmount = () => {
    const contractPrice = form.getFieldValue("contractPrice");
    if (contractPrice - scopeTotal !== 0) {
      return "error";
    }
    return undefined;
  };

  const renderHelpMessage = () => {
    const contractPrice = form.getFieldValue("contractPrice");
    if (contractPrice - scopeTotal !== 0) {
      return `${currencyFormatter(contractPrice - scopeTotal)} to be allocated`;
    }
    return undefined;
  };

  const amountChange = async () => {
    try {
      const amounts = await form.getFieldValue("scopes");
      let total = 0;
      for (const a of amounts) {
        if (a) {
          total += a.amount;
        }
      }
      setScopeTotal(total);
    } catch (err) {
      console.log("amountChange err", err);
    }
  };

  const nameChange = async (thisName) => {
    try {
      const res = await api.post("/dropdowns/get", {
        selects: [`${thisName} Extended Warranty`],
      });
      setExtendedWarranties(res.data[`${thisName} Extended Warranty`].options);
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleRemove = () => {
    remove(field.key);
  };

  return (
    <>
      {field.key > 0 && <Divider />}
      <h1>
        Scope
        {fields.length > 1 && (
          <Button
            size="small"
            type="danger"
            disabled={fields.length === 1}
            onClick={handleRemove}
            style={{ float: "right", marginTop: 12 }}
          >
            REMOVE
          </Button>
        )}
      </h1>

      <Form.Item
        label="Type"
        {...field}
        name={[field.name, "name"]}
        fieldKey={[field.fieldKey, "name"]}
        key={`name${field.fieldKey}`}
        rules={[
          {
            required: true,
            message: "Type is required",
          },
        ]}
      >
        <Select
          getPopupContainer={(trigger) =>
            !mobile ? trigger.parentNode : document.body
          }
          showSearch={!mobile}
          dropdownClassName={mobile ? "isMobile" : null}
          onChange={nameChange}
        >
          {scopes.map((scope) => {
            return (
              <Select.Option key={scope} value={scope}>
                {scope}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>

      <Form.Item
        label="Name"
        {...field}
        name={[field.name, "label"]}
        fieldKey={[field.fieldKey, "label"]}
        key={`label${field.fieldKey}`}
      >
        <InputNoAutoComplete id="label" />
      </Form.Item>

      <Form.Item
        label="Amount"
        {...field}
        name={[field.name, "amount"]}
        fieldKey={[field.fieldKey, "amount"]}
        key={`amount${field.fieldKey}`}
        rules={[
          {
            required: true,
            message: "Amount  is required",
          },
        ]}
        validateStatus={validateAmount()}
        help={renderHelpMessage()}
      >
        <Currency id={[field.name, "amount"]} onChange={amountChange} />
      </Form.Item>

      {/* <Form.Item
        label="Down Payment"
        {...field}
        name={[field.name, "downPayment"]}
        fieldKey={[field.fieldKey, "downPayment"]}
        key={`downPayment${field.fieldKey}`}
        rules={[
          {
            required: true,
            message: "Down Payment  is required",
          },
        ]}
      >
        <Currency id={[field.name, "downPayment"]} />
      </Form.Item> */}

      <Form.Item
        label="Premier Warranty"
        {...field}
        name={[field.name, "premierWarranty"]}
        fieldKey={[field.fieldKey, "premierWarranty"]}
        key={`premierWarranty${field.fieldKey}`}
        rules={[
          {
            required: true,
            message: "Premier Warranty is required",
          },
        ]}
      >
        <Select getPopupContainer={(trigger) => trigger.parentNode} showSearch>
          {premierWarranties.map((warranty) => {
            return (
              <Select.Option key={warranty} value={warranty}>
                {warranty}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>

      <Form.Item
        label="Extended Warranty"
        {...field}
        name={[field.name, "extendedWarranty"]}
        fieldKey={[field.fieldKey, "extendedWarranty"]}
        key={`extendedWarranty${field.fieldKey}`}
        rules={[
          {
            required: true,
            message: "Amount  is required",
          },
        ]}
      >
        <Select getPopupContainer={(trigger) => trigger.parentNode} showSearch>
          {extendedWarranties.map((warranty) => {
            return (
              <Select.Option key={warranty} value={warranty}>
                {warranty}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>

      {/* <Form.Item
        {...field}
        name={[field.name, "installProjectManager"]}
        fieldKey={[field.fieldKey, "installProjectManager"]}
        key={`installProjectManager${field.fieldKey}`}
        label="Project Manager"
      >
        <ProjectManager sales={projectManagers} />
      </Form.Item> */}

      <div className="ant-form-item-label">
        <label>Additional Details</label>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-start" }}>
        <Form.Item
          {...field}
          name={[field.name, "repair"]}
          fieldKey={[field.fieldKey, "repair"]}
          key={`repair${field.fieldKey}`}
          valuePropName="checked"
        >
          <CheckableTag className="tagOutline">Repair</CheckableTag>
        </Form.Item>
        <Form.Item
          {...field}
          name={[field.name, "insurance"]}
          fieldKey={[field.fieldKey, "insurance"]}
          key={`insurance${field.fieldKey}`}
          valuePropName="checked"
        >
          <CheckableTag className="tagOutline">Insurance</CheckableTag>
        </Form.Item>
      </div>
    </>
  );
};

export default Scopes;
