import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateScopesActivitiesAndHashtags } from "Actions/dataActions";
import { fetchTable } from "Actions/tableActions";
import Form from "antd/es/form";
import Button from "antd/es/button";
import message from "antd/es/message";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import api from "API";
import Currency from "Components/Inputs/Currency";
import SearchLeadsAndProjects from "Components/Inputs/SearchLeadsAndProjects";
import Scopes from "Components/Inputs/Scopes";
import WorkmanshipWarranty from "Components/Inputs/WorkmanshipWarranty";
import ExtendedWarranty from "Components/Inputs/ExtendedWarranty";
import CheckableTag from "antd/es/tag/CheckableTag";
import styles from "./styles.module.scss";
import CancelButton from "Components/CancelButton";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";
// import InputSalesmen from "Components/Inputs/Salesmen";
import ProjectManager from "Components/Inputs/ProjectManager";
import useProjectManagers from "Hooks/useProjectManagers";

const messageKey = "messageKey";

const Scope = ({ unsavedChanges, setUnsavedChanges, handleClose, type }) => {
  const dispatch = useDispatch();
  const projectManagers = useProjectManagers();
  const formDetails = useSelector((state) => state.formState.scope.formDetails);
  const table = useSelector((state) => state.tableState.table);
  const col = useSelector((state) => state.tableState.col);
  const order = useSelector((state) => state.tableState.order);
  const search = useSelector((state) => state.tableState.search);
  const activeFilter = useSelector((state) => state.tableState.activeFilter);
  const details = useSelector((state) => state.dataState.details);
  const viewableActivities = useSelector(
    (state) => state.dataState.viewableActivities
  );
  const [_projectId, _setProjectId] = useState(details._id);
  const [customerId, setCustomerId] = useState(details.customerId);
  const [projectStatus, setProjectStatus] = useState(details.projectStatus);
  const [customerName, setCustomerName] = useState(details.customerName);
  const [scopeName, setScopeName] = useState(
    projectStatus !== "Closed Out" ? undefined : "Guaranteed Repair"
  );

  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = useCallback(
    async (values) => {
      try {
        setLoading(true);
        values._projectId = _projectId;
        values.customerId = customerId;
        values.customerName = customerName;

        if (values.installProjectManager) {
          values.installProjectManager =
            projectManagers[values.installProjectManager];
        }

        // message.loading("Saving Scope...", 0);
        message.loading({
          content: "Creating scope...",
          duration: 0,
          key: messageKey,
        });
        const res = await api.post("/scopes/create", values);

        if (table) {
          dispatch(
            fetchTable({
              table,
              col,
              order,
              search,
              activeFilter,
              fetching: false,
            })
          );
        }

        setUnsavedChanges(false);

        dispatch(
          updateScopesActivitiesAndHashtags({
            viewableActivities,
            _projectId,
            data: res.data,
          })
        );

        // message.success("Scope created");
        message.success({
          content: "Scope created",
          duration: 1.5,
          key: messageKey,
        });
        handleClose(true);
      } catch (err) {
        setLoading(false);
        // message.error("Error creating scope");
        message.error({
          content: "Error creating scope",
          duration: 1.5,
          key: messageKey,
        });
      }
    },
    [
      _projectId,
      customerId,
      customerName,
      dispatch,
      table,
      col,
      order,
      search,
      activeFilter,
      setUnsavedChanges,
      handleClose,
      viewableActivities,
      projectManagers,
    ]
  );

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error creating scope");
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  const cancel = () => {
    handleClose();
  };

  const handleSelect = (selected) => {
    _setProjectId(selected._id);
    setCustomerId(selected.customerId);
    setCustomerName(selected.customerName);
    setProjectStatus(selected.projectStatus);
  };

  const handleScopeChange = (value) => {
    setScopeName(value);
  };

  return (
    <Form
      form={form}
      className="form"
      autoComplete="off"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      initialValues={{
        name:
          projectStatus === "Closed Out"
            ? "Guaranteed Repair"
            : formDetails.name
            ? formDetails.name
            : undefined,
        label: formDetails.label ? formDetails.label : undefined,
        premierWarranty: formDetails.premierWarranty
          ? formDetails.premierWarranty
          : undefined,
        extendedWarranty: formDetails.extendedWarranty
          ? formDetails.extendedWarranty
          : undefined,
        repair: formDetails.repair ? formDetails.repair : undefined,
        installProjectManager: formDetails.installProjectManager
          ? formDetails.installProjectManager
          : undefined,
      }}
    >
      <RemoveAutocomplete />

      {!_projectId ? (
        <SearchLeadsAndProjects
          handleSelect={handleSelect}
          leads={false}
          disableLastViewed={true}
        />
      ) : (
        <FormBody
          className="content-inner"
          style={{
            paddingLeft: 24,
            paddingTop: 24,
            paddingRight: 24,
            paddingBottom: 8,
            maxHeight: type === "modal" ? "calc(100vh - 161px)" : "unset",
          }}
        >
          <Form.Item
            name="name"
            label="Type"
            rules={[{ required: true, message: "Type is required" }]}
            disabled={projectStatus === "Closed Out"}
          >
            <Scopes
              mode="default"
              handleScopeChange={handleScopeChange}
              id="name"
            />
          </Form.Item>

          <Form.Item
            name="label"
            label="Name"
            disabled={projectStatus === "Closed Out"}
          >
            <InputNoAutoComplete id="label" />
          </Form.Item>

          <Form.Item
            name="amount"
            label="Amount"
            rules={[{ required: true, message: "Amount is required" }]}
          >
            <Currency id="amount" />
          </Form.Item>

          {/* <Form.Item
            name="downPayment"
            label="Down Payment"
            rules={[{ required: true, message: "Down Payment is required" }]}
          >
            <Currency id="downPayment" />
          </Form.Item> */}

          <Form.Item
            name="premierWarranty"
            label="Premier Warranty"
            rules={[
              { required: true, message: "Premier Warranty is required" },
            ]}
          >
            <WorkmanshipWarranty />
          </Form.Item>

          <Form.Item
            name="extendedWarranty"
            label="Extended Warranty"
            rules={[
              { required: true, message: "Extended Warranty is required" },
            ]}
          >
            <ExtendedWarranty scopeName={scopeName} />
          </Form.Item>

          <Form.Item
            name="installProjectManager"
            label="Install Project Manager"
          >
            <ProjectManager sales={projectManagers} />
          </Form.Item>

          <div className="ant-form-item-label">
            <label>Details</label>
          </div>
          <div className={styles.tagContainer}>
            <Form.Item name="repair" valuePropName="checked">
              <CheckableTag className={styles.tag}>Repair</CheckableTag>
            </Form.Item>
            <Form.Item name="insurance" valuePropName="checked">
              <CheckableTag className={styles.tag}>Insurance</CheckableTag>
            </Form.Item>
          </div>
        </FormBody>
      )}

      {_projectId && (
        <FormControls className="sticky-footer">
          <CancelButton handleClick={cancel} />

          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={loading || !unsavedChanges}
          >
            Submit
          </Button>
        </FormControls>
      )}
    </Form>
  );
};

export default Scope;
