import React, { useState, useEffect, useCallback } from "react";
import { unstable_batchedUpdates } from "react-dom";
import useIsMobile from "Hooks/useIsMobile";
import Mobile from "./Mobile";
import Desktop from "./Desktop";
import api from "API";

const MarketingPortal = () => {
  const mobile = useIsMobile();
  const [balance, setBalance] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [remaining, setRemaining] = useState(0);

  useEffect(() => {
    fetchDetails();
    fetchRemaining();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchDetails = useCallback(async () => {
    try {
      const res = await api.get(`/marketingActivities`);
      unstable_batchedUpdates(() => {
        setBalance(res.data.balance);
        setTransactions(res.data.transactions);
      });
    } catch (err) {
      console.log("fetchDetails err", err);
    }
  }, []);

  const fetchRemaining = useCallback(async () => {
    try {
      const res = await api.get(`/fishing-usage`);
      unstable_batchedUpdates(() => {
        setRemaining(res.data.remaining);
      });
    } catch (err) {
      console.log("fetchDetails err", err);
    }
  }, []);

  const updateInformation = useCallback(() => {
    fetchDetails();
    fetchRemaining();
  }, [fetchRemaining, fetchDetails]);

  return (
    <div style={{ height: "100%", width: "100%" }}>
      {mobile ? (
        <Mobile
          balance={balance}
          transactions={transactions}
          remaining={remaining}
          updateInformation={updateInformation}
        />
      ) : (
        <Desktop
          balance={balance}
          transactions={transactions}
          remaining={remaining}
          updateInformation={updateInformation}
        />
      )}
    </div>
  );
};

export default MarketingPortal;
