import React, { useState, useEffect } from "react";
import { unstable_batchedUpdates } from "react-dom";
import api from "API";
import AutoComplete from "antd/es/auto-complete";
import Input from "antd/es/input";
import message from "antd/es/message";
import Button from "antd/es/button";
import SearchOutlined from "@ant-design/icons/SearchOutlined";
import Divider from "antd/es/divider";
import styles from "./styles.module.scss";
// import FormControls from "Components/FormControls";
// import FormBody from "Components/FormBody";
import DuplicateList from "./DuplicateList";

let searchTimeout;

const DuplicateCheck = ({ setCreateNew, setCopyAddress, bordered = false }) => {
  const [dataSource, setDataSource] = useState([]);
  const [distinctData, setDistinctData] = useState([]);
  const [activeData, setActiveData] = useState([]);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState();

  useEffect(() => {
    return () => clearTimeout(searchTimeout);
  }, []);

  useEffect(() => {
    const _options = [];
    for (const data of distinctData) {
      _options.push({
        value: data,
      });
    }
    setOptions(_options);
  }, [distinctData]);

  const handleSearch = (search) => {
    setValue(search);
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(async () => {
      try {
        if (search.length > 2) {
          const res = await api.post(`/duplicateCheck`, { search });
          if (res.status !== 200) {
            message.error("Error searching leads & projects");
          } else {
            let { _results, _distinct } = res.data;

            if (search.length > 2 && _distinct.length === 0) {
              _distinct.push(`Create New Lead`);
            }

            unstable_batchedUpdates(() => {
              setDataSource(_results);
              setDistinctData(_distinct);
              setActiveData([]);
            });
          }
        } else {
          unstable_batchedUpdates(() => {
            setDataSource([]);
            setDistinctData([]);
            setActiveData([]);
          });
        }
      } catch (err) {
        console.log("err", err);
      }
    }, 400);
  };

  function onSelect(value) {
    if (value !== `Create New Lead`) {
      const _activeData = dataSource.filter((x) => {
        return x.projectAddress === value;
      });
      setActiveData(_activeData);
      setValue(undefined);
    } else {
      createNew();
    }
  }

  // const options = distinctData.map((data, i) => (
  //   <AutoComplete.Option key={data} value={data} title={data}>
  //     {data !== `Create New Lead` ? data : <b>{data}</b>}
  //   </AutoComplete.Option>
  // ));

  const createNew = () => {
    const search = document.getElementById("duplicateCheck").value;
    setCopyAddress(search);
    setCreateNew(true);
    setValue(undefined);
  };

  return (
    <>
      <AutoComplete
        className={`${styles.globalSearchDropdown} ${
          bordered ? styles.bordered : ""
        }`}
        dropdownClassName={styles.globalSearchDropdown}
        // dataSource={options}
        value={value}
        searchValue={value}
        options={options}
        onSearch={handleSearch}
        onSelect={onSelect}
        defaultActiveFirstOption={false}
        getPopupContainer={(trigger) =>
          trigger.parentNode.parentNode.parentNode.parentNode.parentNode
            .parentNode
        }
        autoComplete="lead-project-duplicate-check"
      >
        <div>
          <label htmlFor="" style={{ display: "none" }}>
            hidden label to mislead chrome autocomplete
          </label>
          <Input
            id="duplicateCheck"
            allowClear
            size="large"
            placeholder="Address Search"
            prefix={<SearchOutlined />}
            autoComplete="lead-project-duplicate-check"
            value={value}
          />
        </div>
      </AutoComplete>
      {activeData.length > 0 && (
        <div style={{ padding: 24, height: "calc(100% - 24px)" }}>
          {activeData.map((data, index) => (
            <DuplicateList data={data} key={data._id} index={index} />
          ))}
          <Divider />
          <Button type="primary" block onClick={createNew}>
            Create New Lead
          </Button>
          <div style={{ height: 32, width: "100%" }} />
        </div>
      )}
    </>
  );
};

export default DuplicateCheck;
