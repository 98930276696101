import React, { useEffect, useState, useCallback } from "react";
import Grid from "antd/es/grid";
import {
  fetchTableFilters,
  unsetTable,
  setScrollbar,
} from "Actions/tableActions";
import { useDispatch, useSelector } from "react-redux";
import { openForm } from "Actions/formActions";

import Table from "Components/Table";
import TableRow from "Components/Table/TableItems/DropdownItem";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as Vlist } from "react-window";
import usePrevious from "Hooks/usePrevious";
import useIsMobile from "Hooks/useIsMobile";
import isEmpty from "Utils/isEmpty";
import FormContainer from "Forms/FormContainer";
import DropdownForm from "Forms/MultiDropdown";

const { useBreakpoint } = Grid;

const headers = [
  {
    _name: "name",
    name: "Name",
    width: "20%",
  },
  {
    _name: "options",
    name: "OPTIONS",
    width: "80%",
    type: "array",
  },
];

const ManageTieredDropdowns = () => {
  const screens = useBreakpoint();
  const mobile = useIsMobile();
  const prevScreens = usePrevious(screens);
  const rows = useSelector((state) => state.tableState.rows);
  const fetching = useSelector((state) => state.tableState.fetching);
  const search = useSelector((state) => state.tableState.search);
  const activeFilter = useSelector((state) => state.tableState.activeFilter);
  const collapsed = useSelector((state) => state.settingsState.collapsed);
  const dispatch = useDispatch();
  const multiDropdown = useSelector(
    (state) => state.formState.multiDropdown.open
  );

  const [itemSize, setItemSize] = useState(135);

  useEffect(() => {
    initTable();

    return () => dispatch(unsetTable());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTimeout(() => {
      dispatch(setScrollbar());
    });
  }, [rows, dispatch]);

  const initTable = useCallback(() => {
    dispatch(
      fetchTableFilters({
        table: "tiered-dropdowns",
        col: "name",
        order: 1,
        search,
      })
    );
  }, [dispatch, search]);

  useEffect(() => {
    if (prevScreens && screens) {
      if (prevScreens.md && !screens.md) {
        setItemSize(135);
      } else if (!prevScreens.md && screens.md) {
        setItemSize(47);
      }
    }
  }, [screens, prevScreens]);

  const Row = ({ index, style }) => {
    const item = rows[index];
    return (
      <TableRow
        style={style}
        key={item._id}
        item={item}
        headers={headers}
        multi
      />
    );
  };

  const addFunc = useCallback(() => {
    dispatch(openForm("multiDropdown"));
  }, [dispatch]);

  return (
    <>
      <Table
        loading={fetching}
        filter={
          search ||
          (activeFilter && activeFilter.filter && !isEmpty(activeFilter.filter))
            ? true
            : false
        }
        mobile={mobile}
        sider={screens.lg}
        collapsed={collapsed}
      >
        <Table.Header addFunc={addFunc} downloadBtn={false} filterBtn={false} />
        <Table.Filter
          hide={
            search ||
            (activeFilter &&
              activeFilter.filter &&
              !isEmpty(activeFilter.filter))
              ? false
              : true
          }
        />

        {!mobile && <Table.ColumnHeaders headers={headers} />}
        <Table.Body loading={fetching}>
          {rows.length > 0 && (
            <AutoSizer>
              {({ height, width }) => (
                <Vlist
                  height={height}
                  itemCount={rows.length}
                  itemSize={itemSize}
                  width={width}
                >
                  {Row}
                </Vlist>
              )}
            </AutoSizer>
          )}
        </Table.Body>
      </Table>

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 600,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0 },
        }}
        type={!screens.md ? "drawer" : "modal"}
        width={!screens.md ? "100%" : 320}
        visible={multiDropdown}
        form={DropdownForm}
        formType="multiDropdown"
      />
    </>
  );
};

export default ManageTieredDropdowns;
