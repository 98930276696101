import React, { memo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import LazyLoad from "Components/LazyLoad";
import Card from "antd/es/card";
import Button from "antd/es/button";
import CheckCircle from "Icons/CheckCircle";
import CheckCircleEmpty from "Icons/CheckCircleEmpty";
import YouTube from "Icons/YouTube";
import isPdf from "Utils/isPdf";
import isVideo from "Utils/isVideo";
import pdf from "Assets/pdf.svg";
import styles from "./styles.module.scss";
import ImageFallback from "Components/images/Image";

const Media = ({ media, attachments, setAttachments, scrollContainer }) => {
  const selectedActivity = useSelector(
    (state) => state.dataState.selectedActivity
  );
  const params = useParams();

  const type = isPdf(media.src)
    ? "pdf"
    : isVideo(media.src)
    ? "video"
    : "image";

  const addToSelected = (image) => {
    const findImage = attachments.find((s) => s._id === media._id);
    if (!findImage) {
      if (!media._activityId) media._activityId = selectedActivity._id;
      media._projectId = params.id;
      setAttachments((prevState) => [...prevState, media]);
    } else {
      setAttachments((prevState) =>
        prevState.filter((p) => p._id !== media._id)
      );
    }
  };

  const pdfClick = () => {
    addToSelected(media);
  };

  const imageClick = () => {
    addToSelected(media);
  };

  return (
    <div
      key={media._id}
      style={{ width: "50%", display: "inline-block", verticalAlign: "top" }}
    >
      <Card
        bordered={false}
        className={
          attachments.find((s) => s._id === media._id)
            ? `${styles.activity} ${styles.selected}`
            : styles.activity
        }
        style={{ borderRadius: 0 }}
        bodyStyle={{ padding: 0 }}
        cover={
          type === "pdf" ? (
            <>
              <div
                style={{
                  borderRadius: 0,
                  zIndex: 1,
                  padding: 8,
                }}
                className={`${styles.clickOverlay} ${
                  attachments.find((s) => s._id === media._id)
                    ? styles.selecting
                    : ""
                }`}
                onClick={pdfClick}
              >
                {attachments.find((s) => s._id === media._id) ? (
                  <Button
                    type="text"
                    size="large"
                    shape="circle"
                    className="image-button star"
                  >
                    <CheckCircle color="#108ee9" />
                  </Button>
                ) : (
                  <Button
                    type="text"
                    size="large"
                    shape="circle"
                    className="image-button star"
                  >
                    <CheckCircleEmpty />
                  </Button>
                )}
              </div>
              <LazyLoad offsetVertical={80} height={52} root={scrollContainer}>
                <div className={styles.pdf}>
                  <img src={pdf} alt="pdf-icon" />
                  <div>
                    {media.documentType ? media.documentType : media.name}
                  </div>
                </div>
              </LazyLoad>
            </>
          ) : type === "image" ? (
            <>
              <div
                style={{
                  borderRadius: 0,
                  zIndex: 1,
                }}
                className={`${styles.clickOverlay} ${
                  attachments.find((s) => s._id === media._id)
                    ? styles.selecting
                    : ""
                }`}
                onClick={imageClick}
              >
                {attachments.find((s) => s._id === media._id) ? (
                  <Button
                    type="text"
                    size="large"
                    shape="circle"
                    className="image-button star"
                  >
                    <CheckCircle color="#108ee9" />
                  </Button>
                ) : (
                  <Button
                    type="text"
                    size="large"
                    shape="circle"
                    className="image-button star"
                  >
                    <CheckCircleEmpty />
                  </Button>
                )}
              </div>
              <LazyLoad
                offsetVertical={240}
                height={240}
                root={scrollContainer}
              >
                <ImageFallback alt="media-content" image={media} />
              </LazyLoad>
            </>
          ) : (
            <div className={styles.videoContainer}>
              <div
                style={{
                  borderRadius: 0,
                  zIndex: 1,
                  padding: 8,
                }}
                className={`${styles.clickOverlay} ${
                  attachments.find((s) => s._id === media._id)
                    ? styles.selecting
                    : ""
                }`}
                onClick={imageClick}
              >
                {attachments.find((s) => s._id === media._id) ? (
                  <Button
                    type="text"
                    size="large"
                    shape="circle"
                    className="image-button star"
                  >
                    <CheckCircle color="#108ee9" />
                  </Button>
                ) : (
                  <Button
                    type="text"
                    size="large"
                    shape="circle"
                    className="image-button star"
                  >
                    <CheckCircleEmpty />
                  </Button>
                )}
              </div>
              <LazyLoad offsetVertical={80} height={52} root={scrollContainer}>
                <div className={styles.video}>
                  <YouTube color="#f1f1f1" size={64} />
                  <div>{media.name}</div>
                </div>
              </LazyLoad>
            </div>
          )
        }
      ></Card>
    </div>
  );
};

export default memo(Media);
