import React, { useState, useEffect } from "react";
import Row from "antd/es/row";
import Col from "antd/es/col";
import StatisticCard from "./StatisticCard";
import Card from "antd/es/card";
import Leads from "./Leads";
import api from "API";

const leadsNotScheduled = "leads-not-scheduled";
const leadsNeedRemeet = "leads-need-remeet";
const leadsInsuranceWIP = "leads-insurance-wip";
const estimatesWithNoResults = "estimates-with-no-results";
const estimatesSoldNotFlipped = "estimates-sold-not-flipped";
const estimatesWIP3Months = "estimates-wip";

const Management = () => {
  const [selected, setSelected] = useState(leadsNotScheduled);
  const [state, setState] = useState({
    leadsNotScheduled: 0,
    estimatesNoResults: 0,
    estimatesSoldNotFlipped: 0,
    leadsNeedRemeetGreater7: 0,
    leadsInsuranceWIPGreater30: 0,
    estimateWIPGreater90: 0,
  });

  useEffect(() => {
    getCardData();
  }, []);

  const getCardData = async () => {
    try {
      const res = await api.get("/scheduler/populate");
      setState({
        leadsNotScheduled: res.data.leadsNotScheduled,
        estimatesNoResults: res.data.estimatesNoResults,
        estimatesSoldNotFlipped: res.data.estimatesSoldNotFlipped,
        leadsNeedRemeetGreater7: res.data.leadsNeedRemeetGreater7,
        leadsInsuranceWIPGreater30: res.data.leadsInsuranceWIPGreater30,
        estimateWIPGreater90: res.data.estimateWIPGreater90,
      });
    } catch (err) {
      console.log("getCardData err", err);
    }
  };

  const handleClick = (value) => {
    setSelected(value);
  };

  return (
    <>
      <Row gutter={16} style={{ height: 128 }}>
        <Col xs={4}>
          <StatisticCard
            selected={selected === leadsNotScheduled}
            handleClick={() => handleClick(leadsNotScheduled)}
            title="Lead Not Scheduled"
            value={state.leadsNotScheduled}
          />
        </Col>
        <Col xs={4}>
          <StatisticCard
            selected={selected === leadsNeedRemeet}
            handleClick={() => handleClick(leadsNeedRemeet)}
            title="Lead Needs Remeet > 7"
            value={state.leadsNeedRemeetGreater7}
          />
        </Col>
        <Col xs={4}>
          <StatisticCard
            selected={selected === leadsInsuranceWIP}
            handleClick={() => handleClick(leadsInsuranceWIP)}
            title="Lead Insurance WIP > 30 Days"
            value={state.leadsInsuranceWIPGreater30}
          />
        </Col>
        <Col xs={4}>
          <StatisticCard
            selected={selected === estimatesWithNoResults}
            handleClick={() => handleClick(estimatesWithNoResults)}
            title="Estimate No Results"
            value={state.estimatesNoResults}
          />
        </Col>
        <Col xs={4}>
          <StatisticCard
            selected={selected === estimatesSoldNotFlipped}
            handleClick={() => handleClick(estimatesSoldNotFlipped)}
            title="Estimate Sold Not Flipped"
            value={state.estimatesSoldNotFlipped}
          />
        </Col>
        <Col xs={4}>
          <StatisticCard
            selected={selected === estimatesWIP3Months}
            handleClick={() => handleClick(estimatesWIP3Months)}
            title="Estimate WIP > 3 Months"
            value={state.estimateWIPGreater90}
          />
        </Col>
      </Row>
      <Row style={{ height: "calc(100% - 128px)" }}>
        <Col xs={24} style={{ height: "100%" }}>
          <Card
            style={{ height: "calc(100% - 3px)", overflow: "hidden" }}
            className="z-depth-1"
            bordered={false}
            size="small"
            bodyStyle={{ height: "100%", padding: 0 }}
          >
            <Leads selected={selected} />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Management;
