import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
// import ReactDOM from "react-dom";
import App from "./App";
import "antd/dist/antd.css";
import "./index.css";
import "./index.scss";
import "inobounce";
import * as Sentry from "@sentry/react";
// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import reducers from "./Reducers";
// import * as actions from "./Actions/actionTypes";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducers,
  /* preloadedState, */ composeEnhancers(applyMiddleware(thunk))
);

const supportsHistory = "pushState" in window.history;

if (process.env.NODE_ENV === "production") {
  console.log("current_version", process.env.REACT_APP_VERSION);
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: "myco-story@" + process.env.REACT_APP_VERSION,
    ignoreErrors: ["ResizeObserver loop limit exceeded"],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    // tracesSampleRate: 0.7,
  });
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <Router forceRefresh={!supportsHistory} basename={process.env.PUBLIC_URL}>
      <App />
    </Router>
  </Provider>
);

// ReactDOM.render(
//   <Provider store={store}>
//     <Router forceRefresh={!supportsHistory} basename={process.env.PUBLIC_URL}>
//       <App />
//     </Router>
//   </Provider>,
//   document.getElementById("root")
// );

// serviceWorkerRegistration.register({
//   onSuccess: () => store.dispatch({ type: actions.SW_INIT }),
//   onUpdate: (reg) => store.dispatch({ type: actions.SW_UPDATE, payload: reg }),
// });
