import { useEffect, useState } from "react";
import api from "API";
let isMounted = false;

export default function useShingles() {
  const [shingles, setShingles] = useState([]);

  useEffect(() => {
    isMounted = true;
    fetchShingles();

    return () => (isMounted = false);
  }, []);

  const fetchShingles = async () => {
    try {
      const res = await api.post("/dropdowns/get", {
        selects: ["Shingles"],
      });

      if (isMounted) setShingles(res.data.Shingles.options);
    } catch (err) {
      console.log("err", err);
    }
  };

  return shingles;
}
