import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openForm } from "Actions/formActions";
import { unstable_batchedUpdates } from "react-dom";
import Button from "antd/es/button";
import Form from "antd/es/form";
import message from "antd/es/message";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import FormContainer from "Forms/FormContainer";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import styles from "./styles.module.scss";
import api from "API";
import CustomerDetails from "../WorkOrder/CustomerDetails";
import Divider from "antd/es/divider";
import DeliveryDetails from "../WorkOrder/DeliveryDetails";
import CancelButton from "Components/CancelButton";
import Post from "Icons/Post";
import HiddenFields from "Forms/WorkOrder/HiddenFields";
import dayjs from "dayjs";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Typography from "antd/es/typography";
import Grid from "antd/es/grid";
import Activity from "Components/Activities/Activity";
import ReviewSection from "Forms/WorkOrder/ReviewSection";
import AerialCalculator from "Forms/WorkOrder/AerialCalculator";
import RoofingSteepDetails from "Forms/WorkOrder/RoofingSteepDetails";
import RoofingFlatDetails from "Forms/WorkOrder/RoofingFlatDetails";
import SidingDetails from "Forms/WorkOrder/SidingDetails";
import MaterialRow from "Components/materialOrderQuote/MaterialRow";
import generateChanges from "Utils/generateChanges";
import Affix from "antd/es/affix";
import MaterialDeliveryDetails from "Forms/WorkOrder/MaterialDeliveryDetails";
import ComposeMaterialOrder from "Forms/ComposeMaterialOrder";
import Modal from "antd/es/modal";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";
import Alert from "antd/es/alert";

import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "Components/Loader";
import useSuppliers from "Hooks/useSuppliers";

const messageKey = "messageKey";
const { useBreakpoint } = Grid;

let changedValues = {};

const customerDetails = [
  "customerName",
  "propertyType",
  "pitches",
  "stories",
  "projectAddressStreet",
  "projectAddressCity",
  "projectAddressState",
  "projectAddressZip",
  "projectAddressLat",
  "projectAddressLng",
  "projectAddressCounty",
  "projectAddressPermitAuthority",
  "projectDescription",
  "phone",
];

const ReadyForMaterial = ({
  unsavedChanges,
  setUnsavedChanges,
  setTitle,
  handleClose,
  type,
}) => {
  const screens = useBreakpoint();
  const materialEmail = useSelector(
    (state) => state.formState.materialEmail.open
  );
  const formDetails = useSelector(
    (state) => state.formState.readyForMaterial.formDetails
  );

  const dispatch = useDispatch();
  const suppliers = useSuppliers();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [skipLoading, setSkipLoading] = useState(false);
  const [scrollContainer, setScrollContainer] = useState(null);
  const [_lat, _setLat] = useState(
    formDetails.workOrder ? formDetails.workOrder.projectAddressLat : 42.963795
  );
  const [_lng, _setLng] = useState(
    formDetails.workOrder ? formDetails.workOrder.projectAddressLng : -85.670006
  );
  const [posts, setPosts] = useState([]);
  const [postsLength, setPostsLength] = useState(0);
  const [_projectId] = useState(formDetails._projectId);
  const [customerId] = useState(formDetails.customerId);
  const [customerName] = useState(formDetails.customerName);
  const [startingRows, setStartingRows] = useState(0);
  const [laborOnly] = useState(
    formDetails.workOrder && formDetails.workOrder.laborOnly ? true : false
  );
  const [propertyType] = useState(
    formDetails.workOrder && formDetails.workOrder.propertyType
  );
  const [addFlat] = useState(
    formDetails.workOrder && formDetails.workOrder.flatManufacturer
      ? true
      : false
  );
  const [collapsed, setCollapsed] = useState(false);
  const [_lineItems] = useState(
    JSON.parse(
      JSON.stringify(
        formDetails.workOrder && formDetails.workOrder.lineItems
          ? formDetails.workOrder.lineItems.slice(0)
          : {}
      )
    )
  );

  useEffect(() => {
    setTitle(
      `Ready For Material${
        formDetails.workOrder
          ? ` - ${formDetails.workOrder.projectManagerName}`
          : ""
      }${formDetails.customerId ? ` (${formDetails.customerId})` : ""}`
    );
    fetchPosts();

    let shingle = [],
      siding = [];

    if (!formDetails.workOrder.shingle && !formDetails.workOrder.siding) {
      if (formDetails.workOrder.manufacturer) {
        shingle.push(formDetails.workOrder.manufacturer);
        siding.push(formDetails.workOrder.manufacturer);
      }
      if (formDetails.workOrder.productLine) {
        shingle.push(formDetails.workOrder.productLine);
        siding.push(formDetails.workOrder.productLine);
      }
      if (formDetails.workOrder.color) {
        shingle.push(formDetails.workOrder.color);
        siding.push(formDetails.workOrder.color);
      }
    } else if (formDetails.workOrder.shingle) {
      shingle = formDetails.workOrder.shingle;
    } else if (formDetails.workOrder.siding) {
      siding = formDetails.workOrder.siding;
    }

    if (formDetails.name === "Siding") {
      form.setFieldsValue({
        ...formDetails.workOrder,
        dateSold: dayjs(formDetails.workOrder.dateSold),
        deliveryDate: formDetails.materialDeliverDate
          ? dayjs(formDetails.materialDeliverDate)
          : undefined,
        shingle,
        siding,
        buildDate: dayjs(formDetails.workOrder.buildDate),
      });
    } else {
      form.setFieldsValue({
        ...formDetails.workOrder,
        dateSold: dayjs(formDetails.workOrder.dateSold),
        deliveryDate: formDetails.materialDeliverDate
          ? dayjs(formDetails.materialDeliverDate)
          : undefined,
        shingle,
        siding,
      });
    }

    return () => {
      form.resetFields();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (formDetails.supplier) {
      if (suppliers && Object.keys(suppliers).length > 0) {
        Object.keys(suppliers).map((s) => {
          if (suppliers[s].companyName === formDetails.supplier) {
            form.setFieldsValue({
              supplier: suppliers[s]._sub,
              supplierEmail: suppliers[s].email,
            });
          }
          return s;
        });
      }
    }
  }, [suppliers, formDetails.supplier, form]);

  const fetchPosts = async () => {
    try {
      const res = await api.get(`/posts/${_projectId}/${posts.length}`);
      unstable_batchedUpdates(() => {
        setPosts((_posts) => {
          return [..._posts, ...res.data.posts];
        });
        setPostsLength(res.data.postsLength);
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const onSave = async () => {
    try {
      setSaveLoading(true);
      // message.loading("Saving...", 0);
      message.loading({
        content: "Saving material order...",
        duration: 0,
        key: messageKey,
      });
      let values = await form.getFieldsValue();

      if (values.shingle) {
        values.manufacturer = values.shingle[0];
        values.productLine = values.shingle[1];
        values.color = values.shingle[2];
      } else if (values.siding) {
        values.manufacturer = values.siding[0];
        values.productLine = values.siding[1];
        values.color = values.siding[2];
        // values.supplier = suppliers[values.supplier].companyName;
        if (values.supplier) {
          values.supplierEmail = suppliers[values.supplier].email;
          values.supplier = suppliers[values.supplier].companyName;
        }
      }

      values = await generateChanges({
        changedValues,
        values,
        customerDetails,
        _lineItems,
        workOrder: formDetails,
        woCreated: true,
      });

      values.projectAddress = `${values.projectAddressStreet}, ${values.projectAddressCity}, ${values.projectAddressState} ${values.projectAddressZip}`;

      const res = await api.post("/ready-for-material/save", values);

      unstable_batchedUpdates(() => {
        setUnsavedChanges(false);
        setSaveLoading(false);
      });

      if (formDetails.callback) {
        formDetails.callback(res.data);
      }

      // message.success("Material order saved");
      message.success({
        content: "Material order saved",
        duration: 1.5,
        key: messageKey,
      });
      setSaveLoading(false);
    } catch (err) {
      setSaveLoading(false);
      console.log("err", err);
      // message.error("Error saving pre-job check");
      message.error({
        content: "Error saving material order",
        duration: 1.5,
        key: messageKey,
      });
    }
  };

  const ordered = useCallback(
    (data) => {
      for (const s of data.salesObj) {
        window.socket.emit("updateDashboard", { _user: s._user });
        window.socket.emit("updateFeed", { _user: s._user });
      }

      window.socket.emit("update", { _id: _projectId });

      unstable_batchedUpdates(() => {
        setUnsavedChanges(false);
        setLoading(false);
      });

      if (formDetails.callback) {
        formDetails.callback(data);
      }

      message.success("Material Order sent");
      handleClose(true);
    },
    [formDetails, _projectId, handleClose, setUnsavedChanges]
  );

  const onOrder = useCallback(async () => {
    try {
      let values = await form.validateFields();

      if (values.shingle) {
        values.manufacturer = values.shingle[0];
        values.productLine = values.shingle[1];
        values.color = values.shingle[2];
      } else if (values.siding) {
        values.manufacturer = values.siding[0];
        values.productLine = values.siding[1];
        values.color = values.siding[2];
        // values.supplier = suppliers[values.supplier].companyName;
        if (values.supplier) {
          values.supplierEmail = suppliers[values.supplier].email;
          values.supplier = suppliers[values.supplier].companyName;
        }
      }

      values = await generateChanges({
        changedValues,
        values,
        customerDetails,
        _lineItems,
        workOrder: formDetails,
        woCreated: true,
      });

      values.projectAddress = `${values.projectAddressStreet}, ${values.projectAddressCity}, ${values.projectAddressState} ${values.projectAddressZip}`;

      const emails = [
        "install@gopremier.net",
        formDetails.workOrder.projectManagerEmail,
      ];

      if (formDetails.supplierEmail) emails.push(formDetails.supplierEmail);

      dispatch(
        openForm("materialEmail", {
          showSuppliers: true,
          workOrderSrc: formDetails.workOrder.src,
          callback: ordered,
          supplier: formDetails.supplier,
          emails: emails,
          ...values,
        })
      );
    } catch (err) {
      console.log("err", err);
      message.error("Error sending material order");
      setLoading(false);
    }
  }, [dispatch, form, _lineItems, formDetails, ordered, suppliers]);

  const onSkip = async () => {
    try {
      setSkipLoading(true);
      // message.loading("Skipping...", 0);

      message.loading({
        content: "Skipping material order...",
        duration: 0,
        key: messageKey,
      });
      let values = {
        _id: formDetails._id,
        _projectId: formDetails._projectId,
      };

      const res = await api.post("/ready-for-material/skip", values);

      for (const s of res.data.salesObj) {
        window.socket.emit("updateDashboard", { _user: s._user });
        window.socket.emit("updateFeed", { _user: s._user });
      }

      window.socket.emit("update", { _id: formDetails._projectId });

      unstable_batchedUpdates(() => {
        setUnsavedChanges(false);
        setSkipLoading(false);
      });

      if (formDetails.callback) {
        formDetails.callback(res.data);
      }

      // message.success("Material Order skipped");
      message.success({
        content: "Material order skipped",
        duration: 1.5,
        key: messageKey,
      });
      handleClose(true);
    } catch (err) {
      setSkipLoading(false);
      console.log("err", err);
      // message.error("Error skipping material order");
      message.error({
        content: "Error skipping material order",
        duration: 1.5,
        key: messageKey,
      });
    }
  };

  const onValuesChange = (values) => {
    if (!unsavedChanges) setUnsavedChanges(true);

    let key = Object.keys(values)[0];
    let value = values[key];
    let index;

    if (key === "lineItems") {
      index = Object.keys(value)[0];

      let subValue = value[index];
      key = Object.keys(subValue)[0];
      value = subValue[key];
    }

    if (index && key !== "checkbox") {
      changedValues = {
        ...changedValues,
        [index]: {
          ...changedValues[index],
          [key]: value,
          index,
        },
      };
    } else if (key !== "checkbox") {
      changedValues = {
        ...changedValues,
        [key]: {
          value,
          key,
        },
      };
    }
  };

  const handleCancel = useCallback(() => {
    handleClose();
  }, [handleClose]);

  const openActivity = useCallback(() => {
    dispatch(
      openForm("activity", {
        _id: _projectId,
        customerId,
        customerName,
        projectStatus: "Sold",
        projectManager: {
          name: formDetails.workOrder.projectManagerName,
        },
      })
    );
  }, [dispatch, _projectId, customerId, customerName, formDetails]);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const updateCords = ({ lat, lng }) => {
    _setLat(lat);
    _setLng(lng);
  };

  const onReScheduleOrder = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(
        openForm("updateDeliveryDate", {
          _id: formDetails._id,
          deliveryDate: formDetails.materialDeliverDate,
          callback: (data) => {
            formDetails.callback(data);
            handleCancel(true);
          },
        })
      );
    },
    [dispatch, formDetails, handleCancel]
  );

  const onCancelOrder = () => {
    Modal.confirm({
      zIndex: 4002,
      icon: <QuestionCircleOutlined />,
      centered: true,
      cancelText: "Close",
      okText: "Cancel Order",
      okButtonProps: { danger: true },
      content: <div>Are you sure you want to cancel this order?</div>,
      onOk() {
        return new Promise((resolve, reject) => {
          cancelOrder(() => resolve(true));
        }).catch((err) => console.log("err: ", err));
      },
      onCancel() {},
    });
  };

  const cancelOrder = async (cb) => {
    try {
      const res = await api.post(`/ready-for-material/cancel-order`, {
        _id: formDetails._id,
      });

      for (const s of res.data.salesObj) {
        window.socket.emit("updateDashboard", { _user: s._user });
        window.socket.emit("updateFeed", { _user: s._user });
      }

      window.socket.emit("update", { _id: formDetails._projectId });

      unstable_batchedUpdates(() => {
        setUnsavedChanges(false);
        setLoading(false);
      });

      if (formDetails.callback) {
        formDetails.callback(res.data);
      }

      cb();

      message.success("Material Order cancelled");
      handleClose(true);
    } catch (err) {
      console.log("err", err);
      message.error("Error canceling material order");
    }
  };

  const rowRenderer = () => {
    return posts.map((fp) => {
      return (
        <div id={`work-order-${fp._id}`} key={`work-order-${fp._id}`}>
          <Activity activity={fp} scrollContainer={"#work-order-container"} />
        </div>
      );
    });
  };

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      className="form"
      onValuesChange={onValuesChange}
    >
      <FormBody>
        <div
          className="content-inner"
          id="work-order-container"
          ref={setScrollContainer}
          style={type === "drawer" ? { height: "calc(100% - 161px" } : {}}
        >
          <div style={{ padding: 24 }}>
            <Alert
              style={{ marginBottom: 24 }}
              description={
                formDetails.permitCompleted
                  ? "Permit has been reviewed and it is safe to proceed"
                  : "Permit has NOT been reviewed. Proceed with caution."
              }
              type={formDetails.permitCompleted ? "info" : "error"}
            />
            <RemoveAutocomplete />

            <HiddenFields />

            <AerialCalculator hidden form={form} />

            <CustomerDetails
              form={form}
              _lat={_lat}
              _lng={_lng}
              updateCords={updateCords}
            />

            {/* TODO: add Siding */}

            {formDetails.name === "Roofing - Steep" ? (
              <RoofingSteepDetails
                form={form}
                startingRows={startingRows}
                setStartingRows={setStartingRows}
                addFlat={addFlat}
                review
                hideAll
              />
            ) : formDetails.name === "Siding" ? (
              <SidingDetails
                form={form}
                startingRows={startingRows}
                setStartingRows={setStartingRows}
                laborOnly={laborOnly}
                propertyType={propertyType}
                review
              />
            ) : (
              <RoofingFlatDetails
                form={form}
                startingRows={startingRows}
                setStartingRows={setStartingRows}
                hideAll
              />
            )}

            <Divider />

            <DeliveryDetails />
          </div>

          <div style={{ display: "none" }}>
            <Form.List name="lineItems">
              {(fields, { add, remove }) => {
                return (
                  <>
                    {fields.map((field, index) => (
                      <MaterialRow
                        quote={true}
                        key={field.key}
                        fields={fields}
                        field={field}
                        index={index}
                        form={form}
                      />
                    ))}
                  </>
                );
              }}
            </Form.List>
          </div>

          <div
            style={{
              paddingLeft: 24,
              paddingRight: 24,
              paddingBottom: 24,
              background: "#ebebeb",
            }}
          >
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Affix offsetTop={0} target={() => scrollContainer}>
                  <MaterialDeliveryDetails show />
                </Affix>
              </Col>
              <Col xs={24} sm={12}>
                <Affix offsetTop={0} target={() => scrollContainer}>
                  <Typography.Title level={4} className={styles.stickyHeader}>
                    Project Posts
                  </Typography.Title>
                </Affix>

                <div style={{ paddingLeft: 4, paddingRight: 4 }}>
                  <InfiniteScroll
                    dataLength={posts.length} //This is important field to render the next data
                    next={fetchPosts}
                    hasMore={posts.length !== postsLength}
                    loader={
                      <div style={{ width: "100%" }}>
                        <Loader minHeight="40px" />
                      </div>
                    }
                    endMessage={null}
                    scrollableTarget="work-order-container"
                  >
                    {rowRenderer()}
                  </InfiniteScroll>
                </div>
              </Col>
            </Row>
          </div>

          <Button
            onClick={openActivity}
            type="primary"
            danger
            shape="circle"
            className={`${styles.fab} ${collapsed ? styles.collapsed : ""}`}
          >
            <Post />
          </Button>
        </div>
      </FormBody>

      <ReviewSection
        hideDifficulty
        collapsed={collapsed}
        toggleCollapsed={toggleCollapsed}
      />

      <FormControls className="sticky-footer">
        <CancelButton handleClick={handleCancel} />

        {formDetails.materialOrdered ? (
          <>
            <Button
              // type="primary"
              danger
              style={{ padding: "4px 8px", marginRight: 8 }}
              onClick={onReScheduleOrder}
            >
              Re-Schedule Order
            </Button>
            <Button
              type="primary"
              danger
              style={{ padding: "4px 8px", marginRight: 8 }}
              onClick={onCancelOrder}
            >
              Cancel Order
            </Button>
          </>
        ) : (
          <>
            <Button
              type="text"
              danger
              style={{ padding: "4px 8px", marginRight: 8 }}
              onClick={onSkip}
              loading={skipLoading}
              disabled={skipLoading}
            >
              Skip
            </Button>

            <Button
              type="text"
              style={{ padding: "4px 8px", marginRight: 8, color: "#1890ff" }}
              onClick={onSave}
              loading={saveLoading}
              disabled={saveLoading || !unsavedChanges}
            >
              Save
            </Button>

            <Button
              type="primary"
              loading={loading}
              disabled={loading || !unsavedChanges}
              onClick={onOrder}
            >
              Order
            </Button>
          </>
        )}
      </FormControls>

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0 },
        }}
        type={!screens.md ? "drawer" : "modal"}
        width={!screens.md ? "100%" : 320}
        title="Order Material"
        visible={materialEmail}
        form={ComposeMaterialOrder}
        formType="materialEmail"
        // formDetails={formDetails}
      />
    </Form>
  );
};

export default ReadyForMaterial;
