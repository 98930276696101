import React from "react";
import Form from "antd/es/form";
import Input from "antd/es/input";
import InputNumberNoAutoComplete from "Components/Inputs/InputNumberNoAutoComplete";

const Collateral = () => {
  return (
    <>
      <Form.Item
        label="What are you requesting"
        name="explanation"
        rules={[
          {
            required: true,
            message:
              "You must provide an explanation of what you ware requesting!",
          },
        ]}
      >
        <Input.TextArea autosize={{ minRows: 4 }} />
      </Form.Item>

      <Form.Item
        label="Quantity"
        name="quantity"
        rules={[
          {
            required: true,
            message: `Please provide a quantity`,
          },
        ]}
      >
        <InputNumberNoAutoComplete id="quantity" />
      </Form.Item>
    </>
  );
};

export default Collateral;
