import React from "react";
import Form from "antd/es/form";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";
import Currency from "Components/Inputs/Currency";

const Referral = () => {
  return (
    <div>
      <Form.Item
        label="Customer ID"
        name="customerId"
        rules={[
          {
            required: true,
            message: `Please provide a Customer ID`,
          },
        ]}
      >
        <InputNoAutoComplete id="customerId" />
      </Form.Item>

      <Form.Item
        label="Amount Requested"
        name="amount"
        rules={[
          {
            required: true,
            message: `Please provide an amount`,
          },
        ]}
      >
        <Currency id="amount" />
      </Form.Item>
    </div>
  );
};

export default Referral;
