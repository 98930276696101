import React from "react";
import LineChartWithInterval from "Reports/LineChartWithInterval";

export default function LeadsVsSales() {
  return (
    <LineChartWithInterval
      path="/reports/leadsVsSales"
      type=""
      summary={false}
      lineItems={true}
      aggregate={true}
      report="LEADS VS ESTIMATES VS SALES"
      headers={["Type", "Total"]}
    />
  );
}
