import React, { useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  fetchNotifications,
  markRead,
  markUnread,
  // markAllRead,
} from "Actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { openForm } from "Actions/formActions";
import styles from "./styles.module.scss";
import timeStamp from "Utils/timeStamp";
import Button from "antd/es/button";
import List from "antd/es/list";
import Avatar from "antd/es/avatar";
import NotificationsIcon from "Icons/Notifications";
import Group from "Icons/Group";
import AttachMoney from "Icons/AttachMoney";
import Chat from "Icons/Chat";
import Tune from "Icons/Tune";
import ClipboardCheck from "Icons/ClipboardCheck";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "Components/Loader";
// import Row from "antd/es/row";
// import Col from "antd/es/col";

const colors = {
  People: "#108ee9",
  Collection: "rgb(0, 194, 146)",
  AttachMoney: "rgb(0, 194, 146)",
  Mention: "rgb(52, 58, 64)",
  Scope: "rgb(255, 112, 86)",
  Tune: "rgb(255, 112, 86)",
  ClipboardCheck: "#108ee9",
};

const NotificationsItems = () => {
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.authState.notifications);
  const notificationsLength = useSelector(
    (state) => state.authState.notificationsLength
  );

  const rowRenderer = () => {
    return notifications.map((notification) => (
      <NotificationItem key={notification._id} notification={notification} />
    ));
  };

  const getNotifications = useCallback(() => {
    dispatch(fetchNotifications({ notifications }));
  }, [dispatch, notifications]);

  // const markAllNotificationsRead = useCallback(() => {
  //   dispatch(markAllRead({ notifications }));
  // }, [dispatch, notifications]);

  if (notifications.length > 0) {
    return (
      <div className="notificationsItems-container">
        {/* <Row justify="center">
          <Col xs={16}>
            <Button
              type="primary"
              className="green"
              block
              onClick={markAllNotificationsRead}
              style={{ marginBottom: 24 }}
            >
              Mark All Read
            </Button>
          </Col>
        </Row> */}
        <List className={styles.notificationsContainer}>
          <InfiniteScroll
            dataLength={notifications.length} //This is important field to render the next data
            next={getNotifications}
            hasMore={notifications.length !== notificationsLength}
            loader={
              <div style={{ width: "100%" }}>
                <Loader minHeight="40px" />
              </div>
            }
            endMessage={null}
            scrollableTarget="notifications-container"
          >
            {rowRenderer()}
          </InfiniteScroll>
        </List>
      </div>
    );
  } else {
    return (
      <div className={styles.notificationsItemsContainer}>
        <div className={styles.notificationsItemsEmptyContainer}>
          <div className={styles.notificationsItemsCenterEmptyMessage}>
            <NotificationsIcon
              style={{
                textAlign: "center",
                color: "#828282",
                userSelect: "none",
                fill: "currentcolor",
              }}
              color="#828282"
            />
            <div className={styles.notificationsItemsCenterEmptyText}>
              No Notifications
            </div>
          </div>
        </div>
      </div>
    );
  }
};

const NotificationItem = ({ notification }) => {
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.authState.notifications);
  const navigate = useNavigate();
  const location = useLocation();

  const onClick = () => {
    const locArr = location.pathname.split("/");
    if (
      notification.path.includes("breakdowns") ||
      notification.path.includes("todos")
    ) {
      navigate(`${notification.path}?${notification.customerId}`);
    } else if (locArr[1] === "projects" || locArr[1] === "leads") {
      const pathArr = notification.path.split("/");
      if (locArr[1] === pathArr[1]) {
        navigate(notification.path);
      } else {
        navigate(`/${locArr[1]}${notification.path}`);
      }
    } else {
      navigate(`/${locArr[1]}${notification.path}`);
    }
    // markRead(notificationItem._id);
  };

  const commentReply = useCallback(
    (e) => {
      e.stopPropagation();
      if (notification._commentId) {
        dispatch(
          openForm("reply", {
            _id: notification._activityId,
            _projectId: notification._documentId,
            customerId: notification.customerId,
            type: notification.type,
            _commentId: notification._commentId,
          })
        );
      } else {
        dispatch(
          openForm("comment", {
            _id: notification._activityId,
            _projectId: notification._documentId,
            customerId: notification.customerId,
            type: notification.type,
          })
        );
      }
    },
    [
      notification._commentId,
      dispatch,
      notification._activityId,
      notification._documentId,
      notification.customerId,
      notification.type,
    ]
  );

  const handleMarkRead = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(markRead({ _id: notification._id, notifications }));
    },
    [dispatch, notification._id, notifications]
  );

  const handleMarkUnread = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(markUnread({ _id: notification._id, notifications }));
    },
    [dispatch, notification._id, notifications]
  );

  return (
    <List.Item
      className={`z-depth-1 ${styles.notificationsItemsListItem} ${
        notification.read ? "read" : ""
      }`}
      onClick={onClick}
    >
      <List.Item.Meta
        avatar={
          <Avatar
            style={{ background: colors[notification.icon] }}
            icon={
              notification.icon === "People" ? (
                <Group color="#ffffff" />
              ) : notification.icon === "Collection" ||
                notification.icon === "AttachMoney" ? (
                <AttachMoney color="#ffffff" />
              ) : notification.icon === "Mention" ? (
                <Chat color="#ffffff" size={20} />
              ) : notification.icon === "Scope" ||
                notification.icon === "Tune" ? (
                <Tune color="#ffffff" size={20} />
              ) : notification.icon === "ClipboardCheck" ? (
                <ClipboardCheck color="#ffffff" size={20} />
              ) : null
            }
          ></Avatar>
        }
        description={
          <div
            dangerouslySetInnerHTML={{
              __html: notification.body
                ? notification.body
                : notification.title,
            }}
            style={{
              marginRight: 12,
              marginBottom: 32,
              color: "rgba(0,0,0,0.65)",
            }}
          />
        }
      />
      <div
        style={{
          position: "absolute",
          left: 70,
          bottom: 14,
          pointerEvents: "none",
          color: "rgba(0,0,0,0.45)",
        }}
      >
        <small>{timeStamp(notification.created, true)}</small>
      </div>
      {notification.icon === "Mention" && (
        <Button
          type="text"
          style={{
            position: "absolute",
            bottom: 8,
            right: 100,
          }}
          onClick={commentReply}
        >
          {notification._commentId ? "Reply" : "Comment"}
        </Button>
      )}
      {!notification.read ? (
        <Button
          type="text"
          style={{ position: "absolute", bottom: 8, right: 0 }}
          onClick={handleMarkRead}
        >
          Mark Read
        </Button>
      ) : (
        <Button
          type="text"
          style={{ position: "absolute", bottom: 8, right: 0 }}
          onClick={handleMarkUnread}
        >
          Mark Unread
        </Button>
      )}
    </List.Item>
  );
};

export default NotificationsItems;
