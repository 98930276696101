import React from "react";
import Form from "antd/es/form";
// import Col from "antd/es/col";
// import Row from "antd/es/row";
import Typography from "antd/es/typography";
import Slider from "antd/es/slider";
import FrownOutlined from "@ant-design/icons/FrownOutlined";
import SmileOutlined from "@ant-design/icons/SmileOutlined";
import styles from "./styles.module.scss";

const CloseOut = ({
  satisfaction,
  // scopeTotal,
  // collectionTotal,
  setSatisfaction,
  // warnings,
}) => {
  const preColor = satisfaction >= 49 ? "" : "#ff7056";
  const nextColor = satisfaction >= 50 ? "#00c292" : "";

  const sliderChange = (satisfaction) => {
    setSatisfaction(satisfaction);
  };

  return (
    <>
      <Typography.Title level={4}>Customer Satisfaction</Typography.Title>

      <div className={styles.sliderWrapper}>
        <FrownOutlined style={{ color: preColor }} />
        <Form.Item name="customerSatisfaction">
          <Slider step={1} tooltipVisible={false} onChange={sliderChange} />
        </Form.Item>
        <SmileOutlined style={{ color: nextColor }} />
      </div>
      {/* {scopeTotal.toString() !== collectionTotal.toString() ? (
        <Row gutter={16}>
          <Col xs={24} style={{ marginBottom: 24 }}>
            You must update Scope totals for this project to mark it as
            completed or check to make sure all Collections have been recorded.
          </Col>
        </Row>
      ) : (
        //) : warnings > 0 ? (
        //  <Row gutter={16}>
        //    <Col xs={24} style={{ marginBottom: 24 }}>
        //      By clicking Confirm Completion all scopes status will be set to
        //      Collected and their progress to 100%.
        //    </Col>
        //  </Row>
        <Row gutter={16}>
          <Col xs={24} style={{ marginBottom: 24 }}>
            This project has no warnings or errors and can be marked as
            completed!
          </Col>
        </Row>
      )} */}
    </>
  );
};

export default CloseOut;
