import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelected } from "Actions/dataActions";
import { openForm } from "Actions/formActions";
import List from "antd/es/list";
import Tag from "antd/es/tag";
import dayjs from "dayjs";
import styles from "./styles.module.scss";
import statusObj from "Constants/vendorStatus";

const UsersItem = ({
  style,
  _id,
  lName,
  fName,
  status,
  role,
  manager,
  admin,
  created,
  email,
  phone,
  reports,
  salesman,
  title,
  install,
  calendarId,
  yearlyGoal,
  // monthlyGoal,
  headers,
  callback,
}) => {
  const selectedRow = useSelector((state) => state.dataState.selectedRow);
  const dispatch = useDispatch();

  const onClick = useCallback(() => {
    dispatch(setSelected(_id));
    dispatch(
      openForm("user", {
        _id,
        lName,
        fName,
        status,
        role,
        manager,
        admin,
        email,
        phone,
        reports,
        salesman,
        title,
        install,
        calendarId,
        yearlyGoal,
        // monthlyGoal,
        callback,
        editing: true,
      })
    );
  }, [
    dispatch,
    _id,
    lName,
    fName,
    status,
    role,
    manager,
    admin,
    email,
    phone,
    reports,
    salesman,
    title,
    install,
    calendarId,
    yearlyGoal,
    // monthlyGoal,
    callback,
  ]);

  return (
    <List.Item
      style={style}
      className={`${styles.row} ${selectedRow === _id ? styles.selected : ""}`}
      onClick={onClick}
    >
      <List.Item.Meta
        className={styles.col}
        style={{
          width: headers[0].width,
          textAlign: headers[0].align ? headers[0].align : "left",
        }}
        description={lName}
      />
      <List.Item.Meta
        className={styles.col}
        style={{
          width: headers[1].width,
          textAlign: headers[1].align ? headers[1].align : "left",
        }}
        description={fName}
      />
      <List.Item.Meta
        className={styles.col}
        style={{
          width: headers[2].width,
          textAlign: headers[2].align ? headers[2].align : "left",
        }}
        description={
          <Tag className={styles.statusTag} color={statusObj[status]}>
            {status}
          </Tag>
        }
      />
      <List.Item.Meta
        className={styles.col}
        style={{
          width: headers[3].width,
          textAlign: headers[3].align ? headers[3].align : "left",
        }}
        description={role}
      />
      <List.Item.Meta
        className={styles.col}
        style={{
          width: headers[4].width,
          textAlign: headers[4].align ? headers[4].align : "left",
        }}
        description={manager}
      />
      <List.Item.Meta
        className={styles.col}
        style={{
          width: headers[5].width,
          textAlign: headers[5].align ? headers[5].align : "left",
        }}
        description={admin}
      />
      <List.Item.Meta
        className={styles.col}
        style={{
          width: headers[6].width,
          textAlign: headers[6].align ? headers[6].align : "left",
        }}
        description={dayjs(created).format("MMM D, YYYY")}
      />
    </List.Item>
  );
};

export default UsersItem;
