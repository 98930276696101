import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { openForm } from "Actions/formActions";
import List from "antd/es/list";
import currencyFormatter from "Utils/currencyFormatter";
import styles from "./styles.module.scss";
import Eye from "Icons/Eye";
import Email from "Icons/Email";
import Printer from "Icons/Printer";
import Button from "antd/es/button";
import openInNewTab from "Utils/openInNewTab";
import printJS from "print-js";
import Description from "Icons/Description";

const Invoices = ({ invoices, phone }) => {
  return (
    <>
      {invoices && invoices.length > 0 ? (
        invoices.map((invoice) => (
          <List
            bordered
            key={invoice._id}
            itemLayout="horizontal"
            className={styles.list}
          >
            <InvoicesItem invoice={invoice} phone={phone} />
          </List>
        ))
      ) : (
        <List itemLayout="horizontal" className={styles.list}>
          <List.Item className={`${styles.transactionList} ${styles.none}`}>
            <List.Item.Meta
              avatar={
                <div className={styles.avatar}>
                  <Description color="#108ee9" />
                </div>
              }
              title="No invoices have been created"
            />
          </List.Item>
        </List>
      )}
    </>
  );
};

const InvoicesItem = ({ invoice, phone }) => {
  const dispatch = useDispatch();

  const openInvoice = (e) => {
    e.stopPropagation();
    openInNewTab(invoice.src);
  };

  const editInvoice = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(
        openForm("invoice", {
          ...invoice,
          phone,
          editing: true,
        })
      );
    },
    [dispatch, invoice, phone]
  );

  const printInvoice = (e) => {
    e.stopPropagation();
    printJS({
      printable: `${invoice.src}?${new Date().getTime()}`,
      type: "pdf",
    });
  };

  const openEmail = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(
        openForm("email", {
          content: [
            {
              _id: invoice.src,
              src: invoice.src,
              _projectId: invoice._projectId,
              type: "invoice",
            },
          ],
        })
      );
    },
    [dispatch, invoice.src, invoice._projectId]
  );

  return (
    <List.Item onClick={editInvoice} className={styles.transactionList}>
      <List.Item.Meta
        avatar={
          <div className={styles.avatar}>
            <Description color="#108ee9" />
          </div>
        }
        title={`${invoice.customerId}${
          invoice.label ? ` [${invoice.label}]` : ""
        }`}
        description={currencyFormatter(invoice.balance)}
      />
      {invoice.src && (
        <>
          <Button
            onClick={openInvoice}
            shape="circle"
            type="link"
            style={{ marginLeft: 8 }}
          >
            <Eye />
          </Button>
          <Button
            onClick={openEmail}
            shape="circle"
            type="link"
            style={{ marginLeft: 8 }}
          >
            <Email />
          </Button>
          <Button
            onClick={printInvoice}
            shape="circle"
            type="link"
            style={{ marginLeft: 8 }}
          >
            <Printer />
          </Button>
        </>
      )}
    </List.Item>
  );
};

export default Invoices;
