import React from "react";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Form from "antd/es/form";
// import Address from "Components/Inputs/Address";
import Typography from "antd/es/typography";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";

const TopDetails = ({ form }) => {
  // const setBillingAddress = ({ city, state, postcode }) => {
  //   form.setFieldsValue({
  //     billingAddressCity: city,
  //     billingAddressState: state,
  //     billingAddressZip: postcode,
  //   });
  // };

  return (
    <>
      <Typography.Title level={4}>General Information</Typography.Title>
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item name="label" label="Certificate Label">
            <InputNoAutoComplete id="label" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item name="claimNumber" label="Claim Number">
            <InputNoAutoComplete allowClear id="claimNumber" />
          </Form.Item>
        </Col>
      </Row>
      {/* <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="customerName"
            label="Customer Name"
            rules={[{ required: true, message: "Customer Name is required" }]}
          >
            <InputNoAutoComplete id="customerName" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="dueDate"
            label="Due Date"
            rules={[{ required: true, message: "Due Date is required" }]}
          >
            <InputNoAutoComplete id="dueDate" />
          </Form.Item>
        </Col>
      </Row> */}
      {/* <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item name="claimNumber" label="Claim Number">
            <InputNoAutoComplete allowClear id="claimNumber" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="email"
            label="Customer Email"
            rules={[{ type: "email", message: "Must be a valid email" }]}
          >
            <InputNoAutoComplete allowClear type="email" id="email" />
          </Form.Item>
        </Col>
      </Row> */}

      {/* <Typography.Title level={4}>Billing Address</Typography.Title> */}

      {/* <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="billingAddressStreet"
            label="Street"
            rules={[{ required: true, message: "Street is required" }]}
          >
            <Address
              autoComplete="billingStreet"
              setAddress={setBillingAddress}
              id="billingAddressStreet"
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            label="City"
            name="billingAddressCity"
            rules={[
              {
                required: true,
                message: "City is required",
                type: "string",
              },
            ]}
          >
            <InputNoAutoComplete id="billingAddressCity" />
          </Form.Item>
        </Col>
      </Row> */}

      {/* <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            label="State"
            name="billingAddressState"
            rules={[
              {
                required: true,
                message: "State is required",
                type: "string",
              },
              {
                len: 2,
                message: "State must be abbreviated",
              },
            ]}
          >
            <InputNoAutoComplete id="billingAddressState" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Zip"
            name="billingAddressZip"
            rules={[
              {
                required: true,
                message: "Zip is required",
                type: "string",
              },
            ]}
          >
            <InputNoAutoComplete type="phone" id="billingAddressZip" />
          </Form.Item>
        </Col>
      </Row> */}
    </>
  );
};

export default TopDetails;
