import * as actions from "./actionTypes";

export const setCollapsed = () => async (dispatch) => {
  try {
    let collapsed = localStorage.getItem("collapsed");
    if (collapsed === "false") {
      collapsed = true;
    } else {
      collapsed = false;
    }
    localStorage.setItem("collapsed", collapsed);
    dispatch({ type: actions.SET_COLLAPSED, payload: collapsed });
  } catch (err) {
    console.log("setCollapsed err", err);
  }
};

export const showMenu = (bool) => async (dispatch) => {
  try {
    dispatch({ type: actions.SHOW_MENU, payload: bool });
  } catch (err) {
    console.log("showMenu err", err);
  }
};

export const setActiveRoute = (route) => async (dispatch) => {
  try {
    localStorage.setItem("activeRoute", route);
    dispatch({ type: actions.SET_ACTIVE_ROUTE, payload: route });
  } catch (err) {
    console.log("setActiveRoute err", err);
  }
};
