import { useEffect, useState } from "react";
import api from "API";
let isMounted = false;

export default function useSubContractor(active = false) {
  const [subs, setSubs] = useState({});

  useEffect(() => {
    isMounted = true;
    fetchSubs();

    return () => (isMounted = false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchSubs = async () => {
    try {
      const res = await api.post("/vendors/get", { type: "sub" });
      let arr = {};
      for (const s of res.data) {
        if (active && s.status === "Active") {
          arr[s._id] = {
            _sub: s._id,
            companyName: s.companyName,
            contactName: s.contactName,
            email: s.email,
            phone: s.phone,
          };
        } else if (!active) {
          arr[s._id] = {
            _sub: s._id,
            companyName: s.companyName,
            contactName: s.contactName,
            email: s.email,
            phone: s.phone,
          };
        }
      }

      if (isMounted) setSubs(arr);
    } catch (err) {
      console.log("err", err);
    }
  };

  return subs;
}
