import React, { useState, useEffect, useRef } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useDispatch } from "react-redux";
import { openForm } from "Actions/formActions";
import AutoComplete from "antd/es/auto-complete";
import Input from "antd/es/input";
import message from "antd/es/message";
import Button from "antd/es/button";
import Form from "antd/es/form";
import api from "API";
import styles from "./styles.module.scss";

const BuilderAutoComplete = ({ form }) => {
  const dispatch = useDispatch();
  const [dataSource, setDataSource] = useState([]);
  const [activeData, setActiveData] = useState([]);

  let ref = useRef();

  useEffect(() => {
    getBuilders();
  }, []);

  useEffect(() => {
    let _dataSource = dataSource;
    _dataSource.push({ _id: "Create New Builder", name: "Create New Builder" });
    setActiveData(_dataSource);
    setDataSource(_dataSource);
  }, [dataSource]);

  const getBuilders = async () => {
    try {
      const res = await api.get("/builders");
      unstable_batchedUpdates(() => {
        setDataSource(res.data);
        setActiveData(res.data);
      });
    } catch (err) {
      console.log("err", err);
      message.error("Error retrieving builders");
    }
  };

  const handleSearch = (search) => {
    const _activeData = dataSource.filter((x) => {
      return (
        x.name.toLowerCase().includes(search.toLowerCase()) ||
        x.name === "Create New Builder"
      );
    });
    setActiveData(_activeData);
  };

  const onSelect = (value, props) => {
    if (value !== "Create New Builder") {
      form.setFieldsValue({
        companyName: value,
        email: props.email,
        phone: props.phone,
        billingAddressStreet: props.street,
        billingAddressCity: props.city,
        billingAddressState: props.state,
        billingAddressZip: `${props.zip}`,
        builder: value,
      });
      document.getElementById("builder-input").value = value;
    } else {
      dispatch(openForm("builder", { setDataSource }));
      ref.current.blur();
    }
  };

  const options = activeData.map((data, i) => {
    const updateBuilder = (e) => {
      e.stopPropagation();
      dispatch(
        openForm("builder", {
          _id: data._id,
          name: data.name,
          phone: data.phone,
          email: data.email,
          street: data.street,
          city: data.city,
          state: data.state,
          zip: data.zip,
          setDataSource,
        })
      );
      ref.current.blur();
    };

    return (
      <AutoComplete.Option
        key={data._id}
        value={data.name}
        _id={data._id}
        name={data.name}
        street={data.street}
        city={data.city}
        state={data.state}
        zip={data.zip}
        email={data.email}
        phone={data.phone}
      >
        {data.name !== "Create New Builder" ? (
          <div className={styles.builderItem}>
            <span>{data.name}</span>
            <Button onClick={updateBuilder} size="small">
              Edit
            </Button>
          </div>
        ) : (
          <b>{data.name}</b>
        )}
      </AutoComplete.Option>
    );
  });

  return (
    <Form.Item name="builder" label="Builder" className={styles.builderSearch}>
      <AutoComplete
        id="builderSearch"
        ref={ref}
        dropdownClassName={styles.globalSearchDropdown}
        dataSource={options}
        onSearch={handleSearch}
        onSelect={onSelect}
        defaultActiveFirstOption={false}
        getPopupContainer={(trigger) => trigger.parentNode}
        autoComplete="lead-project-builder-finder"
      >
        <div>
          <label htmlFor="" style={{ display: "none" }}>
            hidden label to mislead chrome autocomplete
          </label>
          <Input
            id="builder-input"
            allowClear
            autoComplete="lead-project-builder-finder"
          />
        </div>
      </AutoComplete>
    </Form.Item>
  );
};

export default BuilderAutoComplete;
