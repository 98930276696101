import React, { useState, useEffect } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useSelector } from "react-redux";
import Modal from "antd/es/modal";
import Card from "antd/es/card";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Button from "antd/es/button";
import FormControls from "Components/FormControls";
import FormBody from "Components/FormBody";
import styles from "./styles.module.scss";
import Add from "Icons/Add";
import api from "API";
import Upload from "antd/es/upload";
import Form from "antd/es/form";
import axios from "axios";
import generateFilePreview from "Utils/generateFilePreview";
import generateFileSize from "Utils/generateFileSize";
import List from "antd/es/list";
import UploadRow from "Components/images/UploadRow";
import AlbumImage from "./AlbumImage";
import useIsMobile from "Hooks/useIsMobile";

const AlbumModal = ({
  visible,
  setVisible,
  images = [],
  album,
  setAlbums,
  setImages,
}) => {
  const admin = useSelector((state) => state.authState.admin);
  const role = useSelector((state) => state.authState.role);
  const manager = useSelector((state) => state.authState.manager);
  const mobile = useIsMobile();
  const [_fileList, _setFileList] = useState([]);

  const [uploads, setUploads] = useState({});
  const [uploading, setUploading] = useState(false);

  const [form] = Form.useForm();

  const handleCancel = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (uploading) {
      let _uploading = false;
      Object.keys(uploads).map((key) => {
        if (uploads[key].upload !== 100 && uploads[key].upload !== true) {
          _uploading = true;
          return key;
        }
        return key;
      });
      if (!_uploading) {
        setUploading(false);
      }
    }
  }, [uploads]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleBeforeUpload = async (file) => {
    try {
      setUploading(true);
      const size = await generateFileSize(file.size);
      const preview = await generateFilePreview(file);
      setUploads((prv) => {
        return {
          ...prv,
          [file.uid]: {
            preview,
            name: file.name,
            size,
            failed: false,
            upload: 0,
          },
        };
      });
      return file;
    } catch (err) {
      console.log("beforeUpload err", err);
    }
  };

  const handleUpload = async ({ file, onProgress }) => {
    try {
      let fileName = `${new Date().getTime()}-${file.name.replace(
        /[^a-zA-Z0-9.]/gi,
        "_"
      )}`;
      const src = `https://s3.us-east-2.amazonaws.com/pr-crm/${album}/${fileName}`;
      let thumbnail = `https://s3.us-east-2.amazonaws.com/pr-crm-thumbnail/${album}/${fileName}`;
      const fileObj = {
        originFileObj: file,
        _id: file.uid,
        uid: file.uid,
        name: file.name,
        mediaType: "image",
        size: file.size,
        type: file.type,
        src,
        thumbnail,
      };

      _setFileList((prevState) => [...prevState, fileObj]);

      const res1 = await api.post("/album/image/signed-url", {
        type: file.type,
        name: fileName,
        _albumId: album,
      });
      await axios
        .put(res1.data, file, {
          headers: {
            "Content-Type": file.type,
          },
          onUploadProgress: (e) => {
            onProgress(e);
          },
        })
        .catch(function (err) {
          console.log("upload err", err);

          setUploads((prv) => {
            return {
              ...prv,
              [file.uid]: {
                ...prv[file.uid],
                failed: true,
              },
            };
          });

          _setFileList((prevState) => {
            const _filteredFileList = prevState.filtered(
              (prv) => prv.uid !== file.uid
            );
            return _filteredFileList;
          });
        });

      const res2 = await api.post("/albums/upload", {
        file: fileObj,
        _albumId: album,
      });

      unstable_batchedUpdates(() => {
        const _album = res2.data.filter((a) => a._id === album._id);
        setAlbums(res2.data);
        setImages(_album[0].images);
      });
    } catch (err) {
      console.log("handleUpload err", err);
    }
  };

  const handleProgress = (progress, file) => {
    const _progress = parseInt((progress.loaded * 100) / progress.total, 10);
    setUploads((prv) => {
      return {
        ...prv,
        [file.uid]: {
          ...prv[file.uid],
          upload: _progress,
        },
      };
    });
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const showUpload = () => {
    if (!album.global) {
      return true;
    }
    if (admin === "Yes" || role === "Office" || manager === "Yes") {
      return true;
    }
    return false;
  };

  return (
    <Modal
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      closable={false}
      className={styles.album}
      bodyStyle={{
        overflow: "hidden",
        height: "100%",
        background: "rgb(240, 242, 245)",
        padding: 0,
      }}
      style={{
        width: "100%",
        top: 0,
        height: "100%",
        margin: 0,
        maxWidth: "100vw",
        padding: 0,
      }}
      width="100%"
    >
      <FormControls
        style={{
          border: "none",
          paddingLeft: 24,
          paddingRight: 24,
          paddingTop: 24,
          paddingBottom: 16,
          zIndex: 5,
          position: "relative",
        }}
        className="z-depth-1"
      >
        <Row gutter={16}>
          <Col
            xs={mobile && showUpload() ? 12 : 24}
            sm={mobile && showUpload() ? 12 : 24}
            md={mobile && showUpload() ? 12 : 24}
          >
            <div style={{ display: uploading > 0 ? "none" : "block" }}>
              <Button block onClick={handleCancel}>
                Back
              </Button>
            </div>
          </Col>
          {mobile && showUpload() && (
            <>
              <Col xs={12}>
                <div style={{ display: uploading > 0 ? "none" : "block" }}>
                  <Form form={form}>
                    <Form.Item
                      style={{ marginBottom: 0 }}
                      name="images"
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                    >
                      <Upload
                        className={styles.upload}
                        multiple
                        beforeUpload={handleBeforeUpload}
                        customRequest={handleUpload}
                        showUploadList={false}
                        onProgress={handleProgress}
                        accept="image/*"
                      >
                        <Button type="primary" block>
                          Upload Image
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Form>
                </div>
              </Col>
            </>
          )}
          <Col xs={24} style={{ display: uploading > 0 ? "block" : "none" }}>
            {_fileList.length > 0 && (
              <List className={styles.uploadRow} bordered>
                {Object.keys(uploads).map((key) => {
                  return (
                    <UploadRow
                      key={key}
                      preview={uploads[key].preview}
                      name={uploads[key].name}
                      size={uploads[key].size}
                      upload={uploads[key].upload}
                      failed={uploads[key].failed}
                    />
                  );
                })}
              </List>
            )}
          </Col>
        </Row>
      </FormControls>
      <FormBody
        className="content-inner"
        style={{
          paddingLeft: 24,
          paddingBottom: 24,
          paddingRight: 24,
          paddingTop: 8,
          height: "calc(100% - 56px)",
        }}
      >
        <Row gutter={16}>
          {images.map((i) => (
            <AlbumImage
              key={i._id}
              album={album}
              image={i}
              setImages={setImages}
              setAlbums={setAlbums}
            />
          ))}
          {!mobile && showUpload() && (
            <Col md={6}>
              <Form form={form}>
                <Form.Item
                  style={{ marginBottom: 0 }}
                  name="images"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                >
                  <Upload
                    className={styles.upload}
                    multiple
                    beforeUpload={handleBeforeUpload}
                    customRequest={handleUpload}
                    showUploadList={false}
                    onProgress={handleProgress}
                    accept="image/*"
                  >
                    <Card
                      className={styles.addImageContainer}
                      hoverable
                      bodyStyle={{ padding: 0 }}
                      cover={
                        <div className={styles.addImage}>
                          <Add />
                          Add Image
                        </div>
                      }
                    ></Card>
                  </Upload>
                </Form.Item>
              </Form>
            </Col>
          )}
        </Row>
      </FormBody>
    </Modal>
  );
};

export default AlbumModal;
