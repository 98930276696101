import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(duration);
dayjs.extend(relativeTime);

export default function timeStamp(date, suffix) {
  const today = dayjs();
  let commentDate = dayjs(date);
  const duration = dayjs.duration(today.diff(commentDate));
  const hours = duration.asHours();
  const year = dayjs().year();
  const thisYear = commentDate.format("YYYY");

  if (hours <= 24) {
    return commentDate.fromNow(suffix);
  } else if (thisYear !== year.toString()) {
    return commentDate.format("MMM D, YYYY @ h:mm A");
  } else {
    return commentDate.format("MMM D @ h:mm A");
  }
}
