import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { openForm } from "Actions/formActions";
import {
  updateScopes,
  startScope,
  stopScope,
  windowsReceived,
} from "Actions/dataActions";
import Card from "antd/es/card";
import styles from "./styles.module.scss";
// import currencyFormatter from "Utils/currencyFormatter";
import Button from "antd/es/button";
import Email from "Icons/Email";
import Eye from "Icons/Eye";
import Printer from "Icons/Printer";
import Edit from "Icons/Edit";
import CheckCircle from "Icons/CheckCircle";
import CheckCircleEmpty from "Icons/CheckCircleEmpty";
import openInNewTab from "Utils/openInNewTab";
import printJS from "print-js";
import List from "antd/es/list";
import dayjs from "dayjs";
import Tag from "antd/es/tag";
import Add from "Icons/Add";
// import Progress from "antd/es/progress";
import HammerWrench from "Icons/HammerWrench";
import CloseCircle from "Icons/CloseCircle";
import Chat from "Icons/Chat";
import Modal from "antd/es/modal";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";
import message from "antd/es/message";
import api from "API";

const Scope = ({ scope, scopes }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const allMedia = useSelector((state) => state.dataState.allMedia);
  const projectStatus = useSelector(
    (state) => state.dataState.details.projectStatus
  );
  const _id = useSelector((state) => state.dataState.details._id);
  const admin = useSelector((state) => state.authState.admin);

  const openWorkOrder = (e) => {
    e.stopPropagation();
    openInNewTab(scope.workOrder.src);
  };

  const createWorkOrder = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(
        openForm("workOrder", {
          name: scope.name,
          _id: scope._id,
          customerId: scope.customerId,
          extendedWarranty: scope.extendedWarranty,
          premierWarranty: scope.premierWarranty,
          scopes,
        })
      );
    },
    [
      dispatch,
      scope.name,
      scope._id,
      scope.customerId,
      scope.extendedWarranty,
      scope.premierWarranty,
      scopes,
    ]
  );

  const editWorkOrder = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(
        openForm("workOrder", {
          ...scope.workOrder,
          name: scope.name,
          _id: scope._id,
          customerId: scope.customerId,
          extendedWarranty: scope.extendedWarranty,
          premierWarranty: scope.premierWarranty,
          editing: true,
          woCreated: scope.woCreated,
          woCreatedDate: scope.woCreatedDate,
          permitCompleted: scope.permitCompleted,
          officeReviewNote: scope.officeReviewNote,
        })
      );
    },
    [
      dispatch,
      scope.workOrder,
      scope.name,
      scope._id,
      scope.customerId,
      scope.extendedWarranty,
      scope.premierWarranty,
      scope.woCreated,
      scope.woCreatedDate,
      scope.permitCompleted,
      scope.officeReviewNote,
    ]
  );

  const editScope = useCallback(
    (e) => {
      e.stopPropagation();
      let dashboard = false;
      if (
        location.pathname.includes("dashboard") ||
        location.pathname.includes("feed")
      ) {
        dashboard = true;
      }
      dispatch(openForm("updateScope", { ...scope, dashboard, editing: true }));
    },
    [dispatch, scope, location.pathname]
  );

  const printWorkOrder = (e) => {
    e.stopPropagation();
    printJS({
      printable: `${scope.workOrder.src}?${new Date().getTime()}`,
      type: "pdf",
    });
  };

  const openEmail = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(
        openForm("email", {
          content: [
            {
              _id: scope.workOrder.src,
              src: scope.workOrder.src,
              _projectId: scope._projectId,
              type: "work-order",
            },
          ],
        })
      );
    },
    [dispatch, scope.workOrder, scope._projectId]
  );

  const openWorkRequest = useCallback(() => {
    let fileName = `${scope.name}-Work-Order-${scope.workOrder.version}`;
    fileName = fileName.replace(" - ", "-");
    fileName = fileName.replace(" ", "-");
    const workOrder = allMedia.find((m) => m.name === fileName);
    dispatch(
      openForm("workRequest", {
        scopeName: scope.name,
        content: [workOrder],
        _scopeId: scope._id,
        _projectId: scope._projectId,
      })
    );
  }, [
    scope.name,
    scope.workOrder,
    allMedia,
    scope._id,
    dispatch,
    scope._projectId,
  ]);

  const _updateScopes = useCallback(() => {
    dispatch(updateScopes({ _projectId: _id }));
  }, [dispatch, _id]);

  const openNote = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(
        openForm("officeReviewNote", {
          _scopeId: scope._id,
          note: scope.officeReviewNote,
          customerId: scope.customerId,
          callback: _updateScopes,
        })
      );
    },
    [dispatch, scope, _updateScopes]
  );

  const renderActions = () => {
    const disabled =
      (projectStatus === "Closed Out" || projectStatus === "Cancelled") &&
      scope.name !== "Guaranteed Repair";

    if (
      projectStatus === "Cancelled" &&
      !scope.woCreated &&
      !scope.woCreatedDate
    ) {
      return [];
    } else if (!scope.woCreated && !scope.woCreatedDate && !scope.woSaved) {
      return [
        <Button
          disabled={disabled}
          onClick={createWorkOrder}
          block
          type="link"
          className={styles.button}
        >
          <Add className={styles.mr8} /> CREATE WORK ORDER
        </Button>,
      ];
    } else if (!scope.woCreated && !scope.woCreatedDate) {
      return [
        <Button
          disabled={disabled}
          onClick={editWorkOrder}
          block
          type="link"
          className={styles.button}
        >
          <Edit className={styles.mr8} /> FINISH WORK ORDER
        </Button>,
      ];
    } else if (scope.woCreated && !scope.woCreatedDate) {
      return [
        <Button
          disabled={disabled}
          onClick={editWorkOrder}
          block
          type="link"
          className={styles.button}
        >
          <Edit className={styles.mr8} /> FINISH WORK ORDER
        </Button>,
      ];
    } else {
      return [
        <Button disabled={disabled} onClick={editWorkOrder} block type="link">
          <Edit />
        </Button>,
        <Button onClick={openWorkOrder} block type="link">
          <Eye />
        </Button>,
        <Button onClick={openEmail} block type="link">
          <Email />
        </Button>,
        <Button onClick={printWorkOrder} block type="link">
          <Printer />
        </Button>,
        <Button
          disabled={
            (!scope.permitCompleted &&
              scope.workOrder &&
              (scope.workOrder.name === "Roofing - Steep" ||
                scope.workOrder.name === "Roofing - Flat")) ||
            disabled
          }
          onClick={openWorkRequest}
          block
          type="link"
        >
          <HammerWrench />
        </Button>,
      ];
    }
  };

  const confirmStart = () => {
    Modal.confirm({
      icon: <QuestionCircleOutlined />,
      centered: true,
      cancelText: "Cancel",
      okText: "Start",
      okButtonProps: { danger: true },
      content: <div>Are you sure you want to start this scope?</div>,
      onOk() {
        return new Promise((resolve, reject) => {
          handleStart(() => resolve(true));
        }).catch((err) => console.log("err: ", err));
      },
      onCancel() {},
    });
  };

  const confirmStop = () => {
    Modal.confirm({
      icon: <QuestionCircleOutlined />,
      centered: true,
      cancelText: "Cancel",
      okText: "Completed",
      okButtonProps: { danger: true },
      content: <div>Are you sure you want to mark this scope completed?</div>,
      onOk() {
        return new Promise((resolve, reject) => {
          handleStop(() => resolve(true));
        }).catch((err) => console.log("err: ", err));
      },
      onCancel() {},
    });
  };

  const confirmReceipt = () => {
    Modal.confirm({
      icon: <QuestionCircleOutlined />,
      centered: true,
      cancelText: "Cancel",
      okText: "Received",
      okButtonProps: { danger: true },
      content: (
        <div>Are you sure you want to mark these windows as received?</div>
      ),
      onOk() {
        return new Promise((resolve, reject) => {
          handleReceived(() => resolve(true));
        }).catch((err) => console.log("err: ", err));
      },
      onCancel() {},
    });
  };

  const handleStart = async (cb) => {
    try {
      await api.get(`/scopes/start/${scope._id}`);
      dispatch(startScope({ scopeId: scope._id }));
      cb();
    } catch (err) {
      console.log("handleStart err", err);
      message.error("Starting scope failed");
    }
  };

  const handleStop = async (cb) => {
    try {
      await api.get(`/scopes/stop/${scope._id}`);
      dispatch(stopScope({ scopeId: scope._id }));
      cb();

      dispatch(
        openForm("activity", {
          _id: scope._projectId,
          customerId: scope.customerId,
          projectStatus: "Sold",
          customerName: scope.customerName,
          projectManager: {
            name: scope.workOrder.projectManagerName,
          },
        })
      );
    } catch (err) {
      console.log("handleStop err", err);
      message.error("Completing scope failed");
    }
  };

  const handleReceived = async (cb) => {
    try {
      await api.get(`/scopes/windows-received/${scope._id}`);
      dispatch(windowsReceived({ scopeId: scope._id }));
      cb();
    } catch (err) {
      console.log("handleReceived err", err);
      message.error("Receiving windows failed");
    }
  };

  return (
    <Card
      className={styles.relatedContainer}
      bordered={false}
      title={
        <div>
          {scope.woCreated && (
            <Button
              style={{ marginRight: 4 }}
              size="small"
              shape="circle"
              type={
                scope.officeReviewNote && scope.officeReviewNote.length > 0
                  ? "primary"
                  : "default"
              }
              onClick={openNote}
            >
              <Chat size={14} />
            </Button>
          )}

          <span className={styles.h4}>
            {scope.label ? scope.label : scope.name}
          </span>
        </div>
      }
      extra={
        admin === "Yes" ? (
          <Button
            size="small"
            type="link"
            onClick={editScope}
            style={{ padding: 0 }}
          >
            EDIT SCOPE
          </Button>
        ) : (
          <Button
            disabled={
              (projectStatus === "Closed Out" ||
                projectStatus === "Cancelled") &&
              scope.name !== "Guaranteed Repair"
            }
            size="small"
            type="link"
            onClick={editScope}
            style={{ padding: 0 }}
          >
            EDIT SCOPE
          </Button>
        )
      }
      actions={renderActions()}
      bodyStyle={{ padding: 0 }}
      headStyle={{ borderBottom: "none", paddingBottom: 12 }}
    >
      <div>
        {/* <div className={styles.subHeader}>
          <div>
            <Progress percent={scope.progress} showInfo={false} />
          </div>
          <div>{currencyFormatter(scope.amount)}</div>
        </div> */}
        {scope.workRequested && !scope.materialOrdered ? (
          <List bordered={true} style={{ border: "none" }}>
            <List.Item
              className={
                scope.woCreated && scope.woCreatedDate
                  ? styles.complete
                  : styles.uncomplete
              }
            >
              <List.Item.Meta
                avatar={
                  scope.woCreated && scope.woCreatedDate ? (
                    <CheckCircle color="#00c292" />
                  ) : (
                    <CheckCircleEmpty color="rgba(0, 0, 0, 0.25)" />
                  )
                }
                title="Work Order Submitted"
              />
              {scope.woCreated && scope.woCreatedDate && (
                <List.Item.Meta
                  title={
                    <Tag color="#00c292">
                      {dayjs(scope.woCreatedDate).format("M/D/YY")}
                    </Tag>
                  }
                />
              )}
            </List.Item>

            <List.Item
              className={
                scope.workRequested ? styles.complete : styles.uncomplete
              }
            >
              <List.Item.Meta
                avatar={
                  scope.workRequested ? (
                    <CheckCircle color="#00c292" />
                  ) : (
                    <CheckCircleEmpty color="rgba(0, 0, 0, 0.25)" />
                  )
                }
                title="Work Requested"
              />
              {scope.workRequested && (
                <List.Item.Meta
                  title={
                    <Tag color="#00c292">
                      {dayjs(scope.workRequestedDate).format("M/D/YY")}
                    </Tag>
                  }
                />
              )}
            </List.Item>

            <List.Item
              className={
                scope.workAccepted || scope.workDenied
                  ? styles.complete
                  : styles.uncomplete
              }
            >
              <List.Item.Meta
                avatar={
                  scope.workAccepted ? (
                    <CheckCircle color="#00c292" />
                  ) : (
                    <CheckCircleEmpty color="rgba(0, 0, 0, 0.25)" />
                  )
                }
                title="Work Accepted"
              />
              {scope.workAccepted && (
                <List.Item.Meta
                  title={
                    <Tag color="#00c292">
                      {dayjs(scope.workAcceptedDate).format("M/D/YY")}
                    </Tag>
                  }
                />
              )}
            </List.Item>

            <List.Item
              className={
                scope.startedDate ? styles.complete : styles.uncomplete
              }
            >
              <List.Item.Meta
                avatar={
                  scope.startedDate ? (
                    <CheckCircle color="#00c292" />
                  ) : (
                    <CheckCircleEmpty color="rgba(0, 0, 0, 0.25)" />
                  )
                }
                title="Work Started"
              />
              {scope.startedDate ? (
                <List.Item.Meta
                  title={
                    <Tag color="#00c292">
                      {dayjs(scope.startedDate).format("M/D/YY")}
                    </Tag>
                  }
                />
              ) : (
                <Button size="small" type="primary" onClick={confirmStart}>
                  Click to Start
                </Button>
              )}
            </List.Item>

            <List.Item
              className={
                scope.installCompleted ? styles.complete : styles.uncomplete
              }
            >
              <List.Item.Meta
                avatar={
                  scope.installCompleted ? (
                    <CheckCircle color="#00c292" />
                  ) : (
                    <CheckCircleEmpty color="rgba(0, 0, 0, 0.25)" />
                  )
                }
                title="Work Completed"
              />
              {scope.installCompleted ? (
                <List.Item.Meta
                  title={
                    <Tag color="#00c292">
                      {dayjs(scope.installCompleted).format("M/D/YY")}
                    </Tag>
                  }
                />
              ) : scope.startedDate ? (
                <Button size="small" type="primary" onClick={confirmStop}>
                  Click to Complete
                </Button>
              ) : null}
            </List.Item>

            <List.Item
              className={
                scope.status === "Collected"
                  ? styles.complete
                  : styles.uncomplete
              }
            >
              <List.Item.Meta
                avatar={
                  scope.status === "Collected" ? (
                    <CheckCircle color="#00c292" />
                  ) : (
                    <CheckCircleEmpty color="rgba(0, 0, 0, 0.25)" />
                  )
                }
                title="Collected"
              />

              {scope.status === "Collected" && (
                <List.Item.Meta
                  title={
                    <Tag color="#00c292">
                      {dayjs(scope.collectionDate).format("M/D/YY")}
                    </Tag>
                  }
                />
              )}
            </List.Item>

            <List.Item className={styles.woHeader}>
              <List.Item.Meta title="Work Order Actions" />
            </List.Item>
          </List>
        ) : scope.name.includes("Roofing") ? (
          <List bordered={true} style={{ border: "none" }}>
            <List.Item
              className={
                scope.woCreated && scope.woCreatedDate
                  ? styles.complete
                  : styles.uncomplete
              }
            >
              <List.Item.Meta
                avatar={
                  scope.woCreated && scope.woCreatedDate ? (
                    <CheckCircle color="#00c292" />
                  ) : (
                    <CheckCircleEmpty color="rgba(0, 0, 0, 0.25)" />
                  )
                }
                title="Work Order Submitted"
              />
              {scope.woCreated && scope.woCreatedDate && (
                <List.Item.Meta
                  title={
                    <Tag color="#00c292">
                      {dayjs(scope.woCreatedDate).format("M/D/YY")}
                    </Tag>
                  }
                />
              )}
            </List.Item>

            {scope.workOrder &&
              scope.workOrder.propertyType !== "New Construction" && (
                <>
                  <List.Item
                    className={
                      scope.aerialVerified ? styles.complete : styles.uncomplete
                    }
                  >
                    <List.Item.Meta
                      avatar={
                        scope.aerialVerified ? (
                          <CheckCircle color="#00c292" />
                        ) : (
                          <CheckCircleEmpty color="rgba(0, 0, 0, 0.25)" />
                        )
                      }
                      title="Aerial Verification"
                    />
                    {scope.aerialVerified && (
                      <List.Item.Meta
                        title={
                          <Tag color="#00c292">
                            {dayjs(scope.aerialVerified).format("M/D/YY")}
                          </Tag>
                        }
                      />
                    )}
                  </List.Item>

                  <List.Item
                    className={
                      scope.moldReviewed && scope.moldPass
                        ? styles.complete
                        : scope.moldReviewed && scope.moldFail
                        ? styles.failed
                        : styles.uncomplete
                    }
                  >
                    <List.Item.Meta
                      avatar={
                        scope.moldReviewed && scope.moldPass ? (
                          <CheckCircle color="#00c292" />
                        ) : scope.moldReviewed && scope.moldFail ? (
                          <CloseCircle color="#ff7056" />
                        ) : (
                          <CheckCircleEmpty color="rgba(0, 0, 0, 0.25)" />
                        )
                      }
                      title="Mold Review"
                    />
                    {scope.moldReviewed && scope.moldPass ? (
                      <List.Item.Meta
                        title={
                          <Tag color="#00c292">
                            {dayjs(scope.moldReviewed).format("M/D/YY")}
                          </Tag>
                        }
                      />
                    ) : scope.moldReviewed && scope.moldFail ? (
                      <List.Item.Meta
                        title={<Tag color="#ff7056">FAILED</Tag>}
                      />
                    ) : null}
                  </List.Item>
                </>
              )}

            <List.Item
              className={
                scope.permitCompleted ? styles.complete : styles.uncomplete
              }
            >
              <List.Item.Meta
                avatar={
                  scope.permitCompleted ? (
                    <CheckCircle color="#00c292" />
                  ) : (
                    <CheckCircleEmpty color="rgba(0, 0, 0, 0.25)" />
                  )
                }
                title="Permit"
              />
              {scope.permitCompleted && (
                <List.Item.Meta
                  title={
                    <Tag color="#00c292">
                      {dayjs(scope.permitCompleted).format("M/D/YY")}
                    </Tag>
                  }
                />
              )}
            </List.Item>

            {scope.workOrder &&
              scope.workOrder.propertyType !== "New Construction" && (
                <List.Item
                  className={
                    scope.preJobCheckCompleted && scope.preJobCheckPass
                      ? styles.complete
                      : scope.preJobCheckCompleted && scope.preJobCheckFail
                      ? styles.failed
                      : styles.uncomplete
                  }
                >
                  <List.Item.Meta
                    avatar={
                      scope.preJobCheckCompleted && scope.preJobCheckPass ? (
                        <CheckCircle color="#00c292" />
                      ) : scope.preJobCheckCompleted &&
                        scope.preJobCheckFail ? (
                        <CloseCircle color="#ff7056" />
                      ) : (
                        <CheckCircleEmpty color="rgba(0, 0, 0, 0.25)" />
                      )
                    }
                    title="Office Review"
                  />
                  {scope.preJobCheckCompleted && scope.preJobCheckPass ? (
                    <List.Item.Meta
                      title={
                        <Tag color="#00c292">
                          {dayjs(scope.preJobCheckCompleted).format("M/D/YY")}
                        </Tag>
                      }
                    />
                  ) : scope.preJobCheckCompleted && scope.preJobCheckFail ? (
                    <List.Item.Meta title={<Tag color="#ff7056">FAILED</Tag>} />
                  ) : null}
                </List.Item>
              )}

            <List.Item
              className={
                scope.materialOrdered ? styles.complete : styles.uncomplete
              }
            >
              <List.Item.Meta
                avatar={
                  scope.materialOrdered ? (
                    <CheckCircle color="#00c292" />
                  ) : (
                    <CheckCircleEmpty color="rgba(0, 0, 0, 0.25)" />
                  )
                }
                title="Materials Ordered"
              />
              {scope.materialOrdered && (
                <List.Item.Meta
                  title={
                    <Tag color="#00c292">
                      {dayjs(scope.materialOrdered).format("M/D/YY")}
                    </Tag>
                  }
                />
              )}
            </List.Item>

            <List.Item
              className={
                scope.installDate ? styles.complete : styles.uncomplete
              }
            >
              <List.Item.Meta
                avatar={
                  scope.installDate ? (
                    <CheckCircle color="#00c292" />
                  ) : (
                    <CheckCircleEmpty color="rgba(0, 0, 0, 0.25)" />
                  )
                }
                title="Work Scheduled"
              />
              {scope.installDate && (
                <List.Item.Meta
                  title={
                    <Tag color="#00c292">
                      {dayjs(scope.installDate).format("M/D/YY")}
                    </Tag>
                  }
                />
              )}
            </List.Item>

            <List.Item
              className={
                scope.startedDate ? styles.complete : styles.uncomplete
              }
            >
              <List.Item.Meta
                avatar={
                  scope.startedDate ? (
                    <CheckCircle color="#00c292" />
                  ) : (
                    <CheckCircleEmpty color="rgba(0, 0, 0, 0.25)" />
                  )
                }
                title="Work Started"
              />
              {scope.startedDate ? (
                <List.Item.Meta
                  title={
                    <Tag color="#00c292">
                      {dayjs(scope.startedDate).format("M/D/YY")}
                    </Tag>
                  }
                />
              ) : (
                <Button size="small" type="primary" onClick={confirmStart}>
                  Click to Start
                </Button>
              )}
            </List.Item>

            <List.Item
              className={
                scope.installCompleted ? styles.complete : styles.uncomplete
              }
            >
              <List.Item.Meta
                avatar={
                  scope.installCompleted ? (
                    <CheckCircle color="#00c292" />
                  ) : (
                    <CheckCircleEmpty color="rgba(0, 0, 0, 0.25)" />
                  )
                }
                title="Work Completed"
              />
              {scope.installCompleted ? (
                <List.Item.Meta
                  title={
                    <Tag color="#00c292">
                      {dayjs(scope.installCompleted).format("M/D/YY")}
                    </Tag>
                  }
                />
              ) : scope.startedDate ? (
                <Button size="small" type="primary" onClick={confirmStop}>
                  Click to Complete
                </Button>
              ) : null}
            </List.Item>

            <List.Item
              className={
                scope.postJobInspectionCompleted
                  ? styles.complete
                  : styles.uncomplete
              }
            >
              <List.Item.Meta
                avatar={
                  scope.postJobInspectionCompleted ? (
                    <CheckCircle color="#00c292" />
                  ) : (
                    <CheckCircleEmpty color="rgba(0, 0, 0, 0.25)" />
                  )
                }
                title="Post-Job Inspection"
              />
              {scope.postJobInspectionCompleted && (
                <List.Item.Meta
                  title={
                    <Tag color="#00c292">
                      {dayjs(scope.postJobInspectionCompleted).format("M/D/YY")}
                    </Tag>
                  }
                />
              )}
            </List.Item>

            <List.Item
              className={
                scope.collectionDate ? styles.complete : styles.uncomplete
              }
            >
              <List.Item.Meta
                avatar={
                  scope.collectionDate ? (
                    <CheckCircle color="#00c292" />
                  ) : (
                    <CheckCircleEmpty color="rgba(0, 0, 0, 0.25)" />
                  )
                }
                title="Collected"
              />
              {scope.collectionDate && (
                <List.Item.Meta
                  title={
                    <Tag color="#00c292">
                      {dayjs(scope.collectionDate).format("M/D/YY")}
                    </Tag>
                  }
                />
              )}
            </List.Item>

            <List.Item className={styles.woHeader}>
              <List.Item.Meta title="Work Order Actions" />
            </List.Item>
          </List>
        ) : scope.name.includes("Siding") ? (
          <List bordered={true} style={{ border: "none" }}>
            <List.Item
              className={
                scope.woCreated && scope.woCreatedDate
                  ? styles.complete
                  : styles.uncomplete
              }
            >
              <List.Item.Meta
                avatar={
                  scope.woCreated && scope.woCreatedDate ? (
                    <CheckCircle color="#00c292" />
                  ) : (
                    <CheckCircleEmpty color="rgba(0, 0, 0, 0.25)" />
                  )
                }
                title="Work Order Submitted"
              />
              {scope.woCreated && scope.woCreatedDate && (
                <List.Item.Meta
                  title={
                    <Tag color="#00c292">
                      {dayjs(scope.woCreatedDate).format("M/D/YY")}
                    </Tag>
                  }
                />
              )}
            </List.Item>

            {(!scope.workOrder ||
              (scope.workOrder &&
                scope.workOrder.propertyType !== "New Construction")) && (
              <>
                <List.Item
                  className={
                    scope.onSiteCheck ? styles.complete : styles.uncomplete
                  }
                >
                  <List.Item.Meta
                    avatar={
                      scope.onSiteCheck ? (
                        <CheckCircle color="#00c292" />
                      ) : (
                        <CheckCircleEmpty color="rgba(0, 0, 0, 0.25)" />
                      )
                    }
                    title="On-site Check"
                  />
                  {scope.onSiteCheck && (
                    <List.Item.Meta
                      title={
                        <Tag color="#00c292">
                          {dayjs(scope.onSiteCheck).format("M/D/YY")}
                        </Tag>
                      }
                    />
                  )}
                </List.Item>

                <List.Item
                  className={
                    scope.moldReviewed && scope.moldPass
                      ? styles.complete
                      : scope.moldReviewed && scope.moldFail
                      ? styles.failed
                      : styles.uncomplete
                  }
                >
                  <List.Item.Meta
                    avatar={
                      scope.moldReviewed && scope.moldPass ? (
                        <CheckCircle color="#00c292" />
                      ) : scope.moldReviewed && scope.moldFail ? (
                        <CloseCircle color="#ff7056" />
                      ) : (
                        <CheckCircleEmpty color="rgba(0, 0, 0, 0.25)" />
                      )
                    }
                    title="Mold Review"
                  />
                  {scope.moldReviewed && scope.moldPass ? (
                    <List.Item.Meta
                      title={
                        <Tag color="#00c292">
                          {dayjs(scope.moldReviewed).format("M/D/YY")}
                        </Tag>
                      }
                    />
                  ) : scope.moldReviewed && scope.moldFail ? (
                    <List.Item.Meta title={<Tag color="#ff7056">FAILED</Tag>} />
                  ) : null}
                </List.Item>
              </>
            )}

            <List.Item
              className={
                scope.permitCompleted ? styles.complete : styles.uncomplete
              }
            >
              <List.Item.Meta
                avatar={
                  scope.permitCompleted ? (
                    <CheckCircle color="#00c292" />
                  ) : (
                    <CheckCircleEmpty color="rgba(0, 0, 0, 0.25)" />
                  )
                }
                title="Permit"
              />
              {scope.permitCompleted && (
                <List.Item.Meta
                  title={
                    <Tag color="#00c292">
                      {dayjs(scope.permitCompleted).format("M/D/YY")}
                    </Tag>
                  }
                />
              )}
            </List.Item>

            <List.Item
              className={
                scope.materialOrdered ? styles.complete : styles.uncomplete
              }
            >
              <List.Item.Meta
                avatar={
                  scope.materialOrdered ? (
                    <CheckCircle color="#00c292" />
                  ) : (
                    <CheckCircleEmpty color="rgba(0, 0, 0, 0.25)" />
                  )
                }
                title="Materials Ordered"
              />
              {scope.materialOrdered && (
                <List.Item.Meta
                  title={
                    <Tag color="#00c292">
                      {dayjs(scope.materialOrdered).format("M/D/YY")}
                    </Tag>
                  }
                />
              )}
            </List.Item>

            <List.Item
              className={
                scope.installDate ? styles.complete : styles.uncomplete
              }
            >
              <List.Item.Meta
                avatar={
                  scope.installDate ? (
                    <CheckCircle color="#00c292" />
                  ) : (
                    <CheckCircleEmpty color="rgba(0, 0, 0, 0.25)" />
                  )
                }
                title="Work Scheduled"
              />
              {scope.installDate && (
                <List.Item.Meta
                  title={
                    <Tag color="#00c292">
                      {dayjs(scope.installDate).format("M/D/YY")}
                    </Tag>
                  }
                />
              )}
            </List.Item>

            <List.Item
              className={
                scope.startedDate ? styles.complete : styles.uncomplete
              }
            >
              <List.Item.Meta
                avatar={
                  scope.startedDate ? (
                    <CheckCircle color="#00c292" />
                  ) : (
                    <CheckCircleEmpty color="rgba(0, 0, 0, 0.25)" />
                  )
                }
                title="Work Started"
              />
              {scope.startedDate ? (
                <List.Item.Meta
                  title={
                    <Tag color="#00c292">
                      {dayjs(scope.startedDate).format("M/D/YY")}
                    </Tag>
                  }
                />
              ) : (
                <Button size="small" type="primary" onClick={confirmStart}>
                  Click to Start
                </Button>
              )}
            </List.Item>

            <List.Item
              className={
                scope.installCompleted ? styles.complete : styles.uncomplete
              }
            >
              <List.Item.Meta
                avatar={
                  scope.installCompleted ? (
                    <CheckCircle color="#00c292" />
                  ) : (
                    <CheckCircleEmpty color="rgba(0, 0, 0, 0.25)" />
                  )
                }
                title="Work Completed"
              />
              {scope.installCompleted ? (
                <List.Item.Meta
                  title={
                    <Tag color="#00c292">
                      {dayjs(scope.installCompleted).format("M/D/YY")}
                    </Tag>
                  }
                />
              ) : scope.startedDate ? (
                <Button size="small" type="primary" onClick={confirmStop}>
                  Click to Complete
                </Button>
              ) : null}
            </List.Item>

            <List.Item
              className={
                scope.postJobInspectionCompleted
                  ? styles.complete
                  : styles.uncomplete
              }
            >
              <List.Item.Meta
                avatar={
                  scope.postJobInspectionCompleted ? (
                    <CheckCircle color="#00c292" />
                  ) : (
                    <CheckCircleEmpty color="rgba(0, 0, 0, 0.25)" />
                  )
                }
                title="Post-Job Inspection"
              />
              {scope.postJobInspectionCompleted && (
                <List.Item.Meta
                  title={
                    <Tag color="#00c292">
                      {dayjs(scope.postJobInspectionCompleted).format("M/D/YY")}
                    </Tag>
                  }
                />
              )}
            </List.Item>

            <List.Item
              className={
                scope.collectionDate ? styles.complete : styles.uncomplete
              }
            >
              <List.Item.Meta
                avatar={
                  scope.collectionDate ? (
                    <CheckCircle color="#00c292" />
                  ) : (
                    <CheckCircleEmpty color="rgba(0, 0, 0, 0.25)" />
                  )
                }
                title="Collected"
              />
              {scope.collectionDate && (
                <List.Item.Meta
                  title={
                    <Tag color="#00c292">
                      {dayjs(scope.collectionDate).format("M/D/YY")}
                    </Tag>
                  }
                />
              )}
            </List.Item>

            <List.Item className={styles.woHeader}>
              <List.Item.Meta title="Work Order Actions" />
            </List.Item>
          </List>
        ) : scope.name.includes("Windows") ? (
          <List bordered={true} style={{ border: "none" }}>
            <List.Item
              className={
                scope.woCreated && scope.woCreatedDate
                  ? styles.complete
                  : styles.uncomplete
              }
            >
              <List.Item.Meta
                avatar={
                  scope.woCreated && scope.woCreatedDate ? (
                    <CheckCircle color="#00c292" />
                  ) : (
                    <CheckCircleEmpty color="rgba(0, 0, 0, 0.25)" />
                  )
                }
                title="Work Order Submitted"
              />
              {scope.woCreated && scope.woCreatedDate && (
                <List.Item.Meta
                  title={
                    <Tag color="#00c292">
                      {dayjs(scope.woCreatedDate).format("M/D/YY")}
                    </Tag>
                  }
                />
              )}
            </List.Item>

            <List.Item
              className={
                scope.windowsReceived ? styles.complete : styles.uncomplete
              }
            >
              <List.Item.Meta
                avatar={
                  scope.windowsReceived ? (
                    <CheckCircle color="#00c292" />
                  ) : (
                    <CheckCircleEmpty color="rgba(0, 0, 0, 0.25)" />
                  )
                }
                title="Windows Received"
              />
              {scope.windowsReceived ? (
                <List.Item.Meta
                  title={
                    <Tag color="#00c292">
                      {dayjs(scope.startedDate).format("M/D/YY")}
                    </Tag>
                  }
                />
              ) : (
                <Button size="small" type="primary" onClick={confirmReceipt}>
                  Click to Receive
                </Button>
              )}
            </List.Item>

            <List.Item
              className={
                scope.workRequested ? styles.complete : styles.uncomplete
              }
            >
              <List.Item.Meta
                avatar={
                  scope.workRequested ? (
                    <CheckCircle color="#00c292" />
                  ) : (
                    <CheckCircleEmpty color="rgba(0, 0, 0, 0.25)" />
                  )
                }
                title="Work Requested"
              />
              {scope.workRequested && (
                <List.Item.Meta
                  title={
                    <Tag color="#00c292">
                      {dayjs(scope.workRequestedDate).format("M/D/YY")}
                    </Tag>
                  }
                />
              )}
            </List.Item>

            <List.Item
              className={
                scope.workAccepted || scope.workDenied
                  ? styles.complete
                  : styles.uncomplete
              }
            >
              <List.Item.Meta
                avatar={
                  scope.workAccepted ? (
                    <CheckCircle color="#00c292" />
                  ) : (
                    <CheckCircleEmpty color="rgba(0, 0, 0, 0.25)" />
                  )
                }
                title="Work Accepted"
              />
              {scope.workAccepted && (
                <List.Item.Meta
                  title={
                    <Tag color="#00c292">
                      {dayjs(scope.workAcceptedDate).format("M/D/YY")}
                    </Tag>
                  }
                />
              )}
            </List.Item>

            <List.Item
              className={
                scope.startedDate ? styles.complete : styles.uncomplete
              }
            >
              <List.Item.Meta
                avatar={
                  scope.startedDate ? (
                    <CheckCircle color="#00c292" />
                  ) : (
                    <CheckCircleEmpty color="rgba(0, 0, 0, 0.25)" />
                  )
                }
                title="Work Started"
              />
              {scope.startedDate ? (
                <List.Item.Meta
                  title={
                    <Tag color="#00c292">
                      {dayjs(scope.startedDate).format("M/D/YY")}
                    </Tag>
                  }
                />
              ) : (
                <Button size="small" type="primary" onClick={confirmStart}>
                  Click to Start
                </Button>
              )}
            </List.Item>

            <List.Item
              className={
                scope.installCompleted ? styles.complete : styles.uncomplete
              }
            >
              <List.Item.Meta
                avatar={
                  scope.installCompleted ? (
                    <CheckCircle color="#00c292" />
                  ) : (
                    <CheckCircleEmpty color="rgba(0, 0, 0, 0.25)" />
                  )
                }
                title="Work Completed"
              />
              {scope.installCompleted ? (
                <List.Item.Meta
                  title={
                    <Tag color="#00c292">
                      {dayjs(scope.installCompleted).format("M/D/YY")}
                    </Tag>
                  }
                />
              ) : scope.startedDate ? (
                <Button size="small" type="primary" onClick={confirmStop}>
                  Click to Complete
                </Button>
              ) : null}
            </List.Item>

            <List.Item
              className={
                scope.status === "Collected"
                  ? styles.complete
                  : styles.uncomplete
              }
            >
              <List.Item.Meta
                avatar={
                  scope.status === "Collected" ? (
                    <CheckCircle color="#00c292" />
                  ) : (
                    <CheckCircleEmpty color="rgba(0, 0, 0, 0.25)" />
                  )
                }
                title="Collected"
              />

              {scope.status === "Collected" && (
                <List.Item.Meta
                  title={
                    <Tag color="#00c292">
                      {dayjs(scope.collectionDate).format("M/D/YY")}
                    </Tag>
                  }
                />
              )}
            </List.Item>
            <List.Item className={styles.woHeader}>
              <List.Item.Meta title="Work Order Actions" />
            </List.Item>
          </List>
        ) : (
          <List bordered={true} style={{ border: "none" }}>
            <List.Item
              className={
                scope.woCreated && scope.woCreatedDate
                  ? styles.complete
                  : styles.uncomplete
              }
            >
              <List.Item.Meta
                avatar={
                  scope.woCreated && scope.woCreatedDate ? (
                    <CheckCircle color="#00c292" />
                  ) : (
                    <CheckCircleEmpty color="rgba(0, 0, 0, 0.25)" />
                  )
                }
                title="Work Order Submitted"
              />
              {scope.woCreated && scope.woCreatedDate && (
                <List.Item.Meta
                  title={
                    <Tag color="#00c292">
                      {dayjs(scope.woCreatedDate).format("M/D/YY")}
                    </Tag>
                  }
                />
              )}
            </List.Item>

            <List.Item
              className={
                scope.workRequested ? styles.complete : styles.uncomplete
              }
            >
              <List.Item.Meta
                avatar={
                  scope.workRequested ? (
                    <CheckCircle color="#00c292" />
                  ) : (
                    <CheckCircleEmpty color="rgba(0, 0, 0, 0.25)" />
                  )
                }
                title="Work Requested"
              />
              {scope.workRequested && (
                <List.Item.Meta
                  title={
                    <Tag color="#00c292">
                      {dayjs(scope.workRequestedDate).format("M/D/YY")}
                    </Tag>
                  }
                />
              )}
            </List.Item>

            <List.Item
              className={
                scope.workAccepted || scope.workDenied
                  ? styles.complete
                  : styles.uncomplete
              }
            >
              <List.Item.Meta
                avatar={
                  scope.workAccepted ? (
                    <CheckCircle color="#00c292" />
                  ) : (
                    <CheckCircleEmpty color="rgba(0, 0, 0, 0.25)" />
                  )
                }
                title="Work Accepted"
              />
              {scope.workAccepted && (
                <List.Item.Meta
                  title={
                    <Tag color="#00c292">
                      {dayjs(scope.workAcceptedDate).format("M/D/YY")}
                    </Tag>
                  }
                />
              )}
            </List.Item>

            <List.Item
              className={
                scope.startedDate ? styles.complete : styles.uncomplete
              }
            >
              <List.Item.Meta
                avatar={
                  scope.startedDate ? (
                    <CheckCircle color="#00c292" />
                  ) : (
                    <CheckCircleEmpty color="rgba(0, 0, 0, 0.25)" />
                  )
                }
                title="Work Started"
              />
              {scope.startedDate ? (
                <List.Item.Meta
                  title={
                    <Tag color="#00c292">
                      {dayjs(scope.startedDate).format("M/D/YY")}
                    </Tag>
                  }
                />
              ) : (
                <Button size="small" type="primary" onClick={confirmStart}>
                  Click to Start
                </Button>
              )}
            </List.Item>

            <List.Item
              className={
                scope.installCompleted ? styles.complete : styles.uncomplete
              }
            >
              <List.Item.Meta
                avatar={
                  scope.installCompleted ? (
                    <CheckCircle color="#00c292" />
                  ) : (
                    <CheckCircleEmpty color="rgba(0, 0, 0, 0.25)" />
                  )
                }
                title="Work Completed"
              />
              {scope.installCompleted ? (
                <List.Item.Meta
                  title={
                    <Tag color="#00c292">
                      {dayjs(scope.installCompleted).format("M/D/YY")}
                    </Tag>
                  }
                />
              ) : scope.startedDate ? (
                <Button size="small" type="primary" onClick={confirmStop}>
                  Click to Complete
                </Button>
              ) : null}
            </List.Item>

            <List.Item
              className={
                scope.status === "Collected"
                  ? styles.complete
                  : styles.uncomplete
              }
            >
              <List.Item.Meta
                avatar={
                  scope.status === "Collected" ? (
                    <CheckCircle color="#00c292" />
                  ) : (
                    <CheckCircleEmpty color="rgba(0, 0, 0, 0.25)" />
                  )
                }
                title="Collected"
              />

              {scope.status === "Collected" && (
                <List.Item.Meta
                  title={
                    <Tag color="#00c292">
                      {dayjs(scope.collectionDate).format("M/D/YY")}
                    </Tag>
                  }
                />
              )}
            </List.Item>
            <List.Item className={styles.woHeader}>
              <List.Item.Meta title="Work Order Actions" />
            </List.Item>
          </List>
        )}
      </div>
    </Card>
  );
};

export default Scope;
