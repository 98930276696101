import React, { useState, useEffect, memo } from "react";
import { unstable_batchedUpdates } from "react-dom";
import Input from "antd/es/input";
import Form from "antd/es/form";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Typography from "antd/es/typography";
import Select from "Components/Inputs/Select";
import api from "API";
import Divider from "antd/es/divider";
import RoofingFlatDetails from "../RoofingFlatDetails";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";
import Modal from "antd/es/modal";
import Button from "antd/es/button";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";
import InputNumberNoAutoComplete from "Components/Inputs/InputNumberNoAutoComplete";
import Cascader from "antd/es/cascader";

const RoofingSteepDetails = ({
  form,
  startingRows,
  setStartingRows,
  setFlatTemplate,
  addFlat,
  setAddFlat,
  review,
  hideScopeDetails,
  hideAll,
}) => {
  const [shingles, setShingles] = useState([]);

  const [layers, setLayers] = useState([]);
  const [roofTypes, setRoofTypes] = useState([]);
  const [pitches, setPitches] = useState([]);

  useEffect(() => {
    fetchOptions();
  }, []);

  const fetchOptions = async () => {
    try {
      const res = await api.post("/dropdowns/get", {
        selects: ["Shingles", "Layers", "Roof Types", "Pitches"],
      });
      unstable_batchedUpdates(() => {
        setShingles(res.data.Shingles.options);
        setLayers(res.data.Layers.options);
        setRoofTypes(res.data["Roof Types"].options);
        setPitches(res.data.Pitches.options);
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleShingleChange = async (value) => {
    try {
      const lineItems = await form.getFieldValue("lineItems");
      if (lineItems) {
        if (value.length > 0) {
          lineItems[0].type = `${value[0]} ${value[1]}`;
          lineItems[0].color = value[2];
          if (value[1] === "StormMaster Shake")
            lineItems[1].type = `${value[0]} StormMaster Cap`;
          if (value[1] === "Pinnacle")
            lineItems[1].type = `${value[0]} Pro Cut`;
          form.setFieldsValue({
            lineItems,
          });
        } else {
          lineItems[0].type = "";
          lineItems[0].color = "";
          form.setFieldsValue({
            lineItems,
          });
        }
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleSquaresBlur = async (e) => {
    try {
      const lineItems = await form.getFieldValue("lineItems");
      if (lineItems) {
        lineItems[0].quantity = `${e.target.value} Squares`;
        form.setFieldsValue({
          lineItems,
        });
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleSkyLightChange = async (value) => {
    try {
      if (value === "New Skylight") {
        const lineItems = await form.getFieldValue("lineItems");
        if (lineItems) {
          lineItems.push({
            material: "Skylight Flashing Kit",
            type: "",
            color: "",
            quantity: "",
          });
          lineItems.push({
            material: "Skylight",
            type: "",
            color: "",
            quantity: "",
          });
          form.setFieldsValue({
            lineItems,
          });
        }
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleAddFlat = () => {
    setAddFlat(true);
  };

  const confirmRemoveFlat = () => {
    Modal.confirm({
      zIndex: 4002,
      icon: <QuestionCircleOutlined />,
      centered: true,
      cancelText: "Cancel",
      okText: "Delete",
      okButtonProps: { danger: true },
      content: (
        <div>
          Are you sure you want to remove the flat roof? All flat roof progress
          will be lost.
        </div>
      ),
      onOk() {
        return new Promise((resolve, reject) => {
          setAddFlat(false);
          resolve(true);
        }).catch((err) => console.log("err: ", err));
      },
      onCancel() {},
    });
  };

  return (
    <div style={{ display: hideAll ? "none" : "block" }}>
      <Typography.Text strong>
        {addFlat ? "Steep " : ""}Project Details
      </Typography.Text>
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="layers"
            label="Existing Roof Layers"
            rules={[
              { required: true, message: "Existing Roof Layers is required" },
            ]}
          >
            <Select mode="multiple" options={layers} id="layers" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="roofType"
            label="Roof Type"
            rules={[{ required: true, message: "Roof Type is required" }]}
          >
            <Select mode="multiple" options={roofTypes} id="roofType" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="pitches"
            label="Pitches"
            rules={[{ required: true, message: "Pitches is required" }]}
          >
            <Select mode="multiple" options={pitches} id="pitches" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="stories"
            label="Stories"
            rules={[{ required: true, message: "Stories is required" }]}
          >
            <InputNoAutoComplete id="stories" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={!hideScopeDetails ? 12 : 24}>
          <Form.Item
            name="projectDescription"
            label="Project Description of Work"
            rules={[
              {
                required: true,
                message: "Project Description of Work is required",
              },
            ]}
          >
            <Input.TextArea autoComplete="dont-use-autocomplete" rows={4} />
          </Form.Item>
        </Col>
        {!hideScopeDetails && (
          <Col xs={24} sm={12}>
            <Form.Item
              name="scopeDescription"
              label="Scope Description of Work"
              rules={[
                {
                  required: true,
                  message: "Scope Description of Work is required",
                },
              ]}
            >
              <Input.TextArea autoComplete="dont-use-autocomplete" rows={4} />
            </Form.Item>
          </Col>
        )}
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="chimneyFlashing"
            label="Existing Chimney Flashing"
            rules={[
              { required: true, message: "Chimney Flashing is required" },
            ]}
          >
            <Select
              options={["Discretion", "New", "N/A"]}
              id="chimneyFlashing"
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="existingIceNWater"
            label="Existing Ice & Water"
            rules={[
              { required: true, message: "Existing Ice & Water is required" },
            ]}
          >
            <Select options={["No", "Yes"]} id="existingIceAndWaterRef" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="wallFlashing"
            label="Existing Wall Flashing"
            rules={[{ required: true, message: "Wall Flashing is required" }]}
          >
            <Select options={["New", "Discretion"]} id="wallFlashing" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="dish"
            label="Dish/Antenna"
            rules={[{ required: true, message: "Dish/Antenna is required" }]}
          >
            <Select options={["Keep", "Dump", "N/A"]} id="dish" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="gutterProtection"
            label="Gutter Protection"
            rules={[
              { required: true, message: "Gutter Protection is required" },
            ]}
          >
            <Select options={["Yes", "No", "N/A"]} id="gutterProtection" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="skylights"
            label="Sky Lights"
            rules={[{ required: true, message: "Sky Lights is required" }]}
          >
            <Select
              handleSelectChange={handleSkyLightChange}
              options={["New Skylight", "New Flashing Kit", "Remove", "N/A"]}
              id="skyLights"
            />
          </Form.Item>
        </Col>
      </Row>

      <Divider />
      <Typography.Text strong>
        {addFlat ? "Steep " : ""}Product Details
      </Typography.Text>

      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="shingle"
            label="Shingle"
            rules={[{ required: true, message: "Shingle is required" }]}
          >
            <Cascader
              showSearch
              options={shingles}
              getPopupContainer={(trigger) =>
                trigger.parentNode.parentNode.parentNode.parentNode.parentNode
              }
              onChange={handleShingleChange}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="squares"
            label="Squares"
            rules={[{ required: true, message: "Squares is required" }]}
          >
            <InputNumberNoAutoComplete
              handleBlur={handleSquaresBlur}
              id="squares"
            />
          </Form.Item>
        </Col>
      </Row>

      {!review && (
        <>
          <Divider />

          <Button
            type={addFlat ? "danger" : "primary"}
            block
            style={{ marginBottom: 24 }}
            onClick={addFlat ? confirmRemoveFlat : handleAddFlat}
          >
            {addFlat ? "Remove Flat Roof" : "Add Flat Roof"}
          </Button>
        </>
      )}

      {addFlat && (
        <RoofingFlatDetails
          addedToSteep
          form={form}
          startingRows={startingRows}
          setStartingRows={setStartingRows}
          setFlatTemplate={setFlatTemplate}
          _pitches={pitches}
          _layers={layers}
          hideAll={hideAll}
        />
      )}
    </div>
  );
};

export default memo(RoofingSteepDetails);
