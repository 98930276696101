import React, { useState, useEffect } from "react";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Card from "antd/es/card";
import LeadForm from "./LeadForm";
import dayjs from "dayjs";
import AssignedLeads from "./AssignedLeads";
import api from "API";
import message from "antd/es/message";
// import ClosingRates from "./ClosingRates";

const Scheduling = () => {
  const [createNew, setCreateNew] = useState(false);
  const [assignedLeadsSeries, setAssignedLeadsSeries] = useState([]);
  // const [estimatorCalendars, setEstimatorCalendars] = useState([]);
  // const [activeDay, setActiveDay] = useState(0);

  useEffect(() => {
    getLeadsReport();
    // fetchEvents();
  }, [createNew]);

  const getLeadsReport = async () => {
    try {
      const end = dayjs();
      const start = end.subtract(14, "days");

      const res = await api.post("/reports/leads", {
        start,
        end,
      });

      setAssignedLeadsSeries(res.data.series);
    } catch (err) {
      console.log("err", err);
      message.error("Error getting report");
    }
  };

  // const fetchEvents = async () => {
  //   try {
  //     const events = await api.get(`/fetch-events`);
  //     setEstimatorCalendars(events.data);
  //   } catch (err) {
  //     console.log("err", err);
  //     message.error("Error getting calendar events");
  //   }
  // };

  return (
    <Row guter={16} style={{ height: "100%" }}>
      <Col xs={24} style={{ height: "calc(50% - 8px)", marginBottom: 8 }}>
        <Row gutter={16} style={{ height: "100%" }}>
          <Col xs={12} style={{ height: "100%" }}>
            <Card
              bordered={false}
              size="small"
              className="z-depth-1"
              style={{ height: "100%" }}
              title="Scheduling Form"
              bodyStyle={{ height: "calc(100% - 39px)", padding: 0 }}
            >
              <LeadForm createNew={createNew} setCreateNew={setCreateNew} />
            </Card>
          </Col>
          <Col xs={12} style={{ height: "100%" }}>
            <Card
              bordered={false}
              size="small"
              style={{ height: "100%" }}
              bodyStyle={{
                height: "calc(100% - 39px)",
                paddingTop: 1,
                paddingRight: 0,
                paddingLeft: 0,
                paddingBottom: 0,
              }}
              className="z-depth-1"
              title="Leads Assigned Last 14 Days"
            >
              <AssignedLeads series={assignedLeadsSeries} />
            </Card>
          </Col>
        </Row>
      </Col>

      <Col xs={24} style={{ height: "calc(50% - 8px)", marginTop: 8 }}>
        <Row gutter={16} style={{ height: "100%" }}>
          <Col xs={24} style={{ height: "100%" }}>
            <Card
              bordered={false}
              size="small"
              style={{ height: "100%" }}
              bodyStyle={{
                height: "calc(100% - 39px)",
                paddingTop: 1,
                paddingRight: 0,
                paddingLeft: 0,
                paddingBottom: 0,
              }}
              className="z-depth-1"
              title="Schedule Next 14 Days"
            >
              {/* {estimatorCalendars.map((calendar) => (
                <Row
                  gutter={0}
                  style={{ height: 40, borderBottom: "1px solid gray" }}
                >
                  <div
                    style={{
                      width: 200,
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {calendar.estimator}
                  </div>
                </Row>
              ))} */}
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Scheduling;
