import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openForm } from "Actions/formActions";
import FormContainer from "Forms/FormContainer";
import FormBody from "Components/FormBody";
import Button from "antd/es/button";
import Card from "antd/es/card";
import api from "API";
import Row from "antd/es/row";
import Col from "antd/es/col";
import styles from "./styles.module.scss";
import Add from "Icons/Add";
import CreateAlbum from "Forms/CreateAlbum";
import Grid from "antd/es/grid";

import Album from "./Album";

const { useBreakpoint } = Grid;

const PhotoAlbums = () => {
  const dispatch = useDispatch();
  const createAlbum = useSelector((state) => state.formState.createAlbum.open);

  const screens = useBreakpoint();
  const [albums, setAlbums] = useState([]);

  useEffect(() => {
    fetchAlbums();
  }, []);

  const fetchAlbums = async () => {
    try {
      const res = await api.get("/albums");
      setAlbums(res.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  const openCreateAlbum = useCallback(() => {
    dispatch(
      openForm("createAlbum", {
        callback: (_albums) => setAlbums(_albums),
      })
    );
  }, [dispatch, setAlbums]);

  return (
    <div className="form" style={{ height: "100%" }}>
      <FormBody
        className="content-inner p24"
        style={{ height: "calc(100% - 48px - 16px)" }}
      >
        <Row gutter={16}>
          <Col xs={24} sm={24} md={0}>
            <Button
              type="primary"
              onClick={openCreateAlbum}
              block
              style={{ marginBottom: 16 }}
            >
              Create New Album
            </Button>
          </Col>
        </Row>

        <Row gutter={16}>
          {albums.map((a) => (
            <Album album={a} key={a._id} setAlbums={setAlbums} />
          ))}
          <Col xs={0} sm={0} md={6}>
            <Card
              onClick={openCreateAlbum}
              className={styles.addImageContainer}
              hoverable
              bodyStyle={{ padding: 0 }}
              cover={
                <div className={styles.addImage}>
                  <Add />
                  Add Album
                </div>
              }
            ></Card>
          </Col>
        </Row>
      </FormBody>

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0 },
        }}
        type={!screens.md ? "drawer" : "modal"}
        width={!screens.md ? "100%" : 320}
        title="Create Album"
        visible={createAlbum}
        form={CreateAlbum}
        formType="createAlbum"
      />
    </div>
  );
};

export default PhotoAlbums;
