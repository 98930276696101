import React, { useEffect, useState, useCallback, useRef } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { openForm } from "Actions/formActions";
import styles from "./styles.module.scss";
import api from "API";
import Grid from "antd/es/grid";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Affix from "antd/es/affix";
import useViewShow from "Hooks/useViewShow";
import ViewProject from "Screens/ViewProject";
import ViewLead from "Screens/ViewLead";
import ViewContainer from "Components/ViewContainer";
import FormContainer from "Forms/FormContainer";
import Carousel from "antd/es/carousel";
import Button from "antd/es/button";
import useIsMounted from "Hooks/useIsMounted";

import MobileScope from "./MobileScope";
import Scope from "./Scope";
import InstallTodoAssignee from "Forms/InstallTodoAssignee";
import InstallTodoDueDate from "Forms/InstallTodoDueDate";

import smoothlyAnimatePanTo from "Utils/smoothlyAnimatePanTo";
import InstallTodoChange from "Forms/InstallTodoChange";

import Loader from "Components/Loader";

const { useBreakpoint } = Grid;

const InstallTodos = () => {
  const dispatch = useDispatch();
  const installTodoAssignee = useSelector(
    (state) => state.formState.installTodoAssignee.open
  );
  const installTodoDueDate = useSelector(
    (state) => state.formState.installTodoDueDate.open
  );
  const installTodoChange = useSelector(
    (state) => state.formState.installTodoChange.open
  );
  const screens = useBreakpoint();
  const [installTodoMap, setInstallTodoMap] = useState();
  const [scopes, setScopes] = useState([]);
  const [container, setContainer] = useState(null);
  const [markers, setMarkers] = useState({}); // eslint-disable-line
  const [loading, setLoading] = useState(true);
  const showProject = useViewShow(3, "projects", 4, "view");
  const showLead = useViewShow(3, "leads", 4, "view");
  const isMounted = useIsMounted();

  const carouselRef = useRef();

  const mounted = isMounted.current;

  useEffect(() => {
    if (mounted) {
      fetchScopes();
      mapInit();

      if (!window.socket.hasListeners("installTodos"))
        window.socket.removeAllListeners("installTodos");
      window.socket.on("installTodos", async () => {
        fetchScopes();
      });
    }

    return () => {
      setMarkers({});
      setInstallTodoMap(undefined);
      if (!window.socket.hasListeners("installTodos"))
        window.socket.removeAllListeners("installTodos");
    };
  }, [mounted]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (installTodoMap && scopes) {
      removePins();
      pinInit();
    }
  }, [scopes, installTodoMap]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchScopes = async () => {
    try {
      const res = await api.get("/install/todos");
      await removePins();
      unstable_batchedUpdates(() => {
        setScopes(res.data);
        setMarkers({});
        setLoading(false);
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const removePins = async () => {
    try {
      for (const s of scopes) {
        if (s._id && markers[s._id] && markers[s._id].marker) {
          markers[s._id].marker.setMap(null);
        }
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const mapInit = () => {
    setInstallTodoMap(
      new window.google.maps.Map(document.getElementById("installTodoMap"), {
        center: { lat: 42.963795, lng: -85.670006 },
        zoom: 9,
        gestureHandling: "greedy",
        disableDefaultUI: true,
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        zoomControl: false,
        defaultMapTypeId: "hybrid",
        mapTypeId: "hybrid",
        tilt: 0,
      })
    );
  };

  const pinInit = () => {
    let _markers = {};
    scopes.map((s) => {
      if (s._id && s.projectAddressLat && s.projectAddressLng) {
        _markers[s._id] = {};
        _markers[s._id].marker = new window.google.maps.Marker({
          position: {
            lat: s.projectAddressLat,
            lng: s.projectAddressLng,
          },
          map: installTodoMap,
          draggable: false,
          icon: new window.google.maps.MarkerImage(
            "https://s3.us-east-2.amazonaws.com/pr-crm/assets/markers/to-do.png",
            null,
            null,
            null,
            new window.google.maps.Size(36, 36)
          ),
          zIndex: 1,
        });

        _markers[s._id].mouseover = window.google.maps.event.addListener(
          _markers[s._id].marker,
          "mouseover",
          function () {
            const _scope = document.getElementById(s._id);
            _scope.classList.add("hover");

            setMarkers((_markers) => {
              _markers[s._id].marker.setIcon(
                new window.google.maps.MarkerImage(
                  "https://s3.us-east-2.amazonaws.com/pr-crm/assets/markers/to-do-active.png",
                  null,
                  null,
                  null,
                  new window.google.maps.Size(36, 36)
                )
              );
              _markers[s._id].marker.setZIndex(10);
              return _markers;
            });
          }
        );

        _markers[s._id].mouseout = window.google.maps.event.addListener(
          _markers[s._id].marker,
          "mouseout",
          function () {
            const _scope = document.getElementById(s._id);
            _scope.classList.remove("hover");

            setMarkers((_markers) => {
              _markers[s._id].marker.setIcon(
                new window.google.maps.MarkerImage(
                  "https://s3.us-east-2.amazonaws.com/pr-crm/assets/markers/to-do.png",
                  null,
                  null,
                  null,
                  new window.google.maps.Size(36, 36)
                )
              );
              _markers[s._id].marker.setZIndex(1);
              return _markers;
            });
          }
        );

        _markers[s._id].mousedown = window.google.maps.event.addListener(
          _markers[s._id].marker,
          "mousedown",
          function () {
            if (!screens.md) {
              const index = scopes.findIndex((x) => x._id === s._id);
              carouselRef.current.goTo(index);
              setMarkers((_markers) => {
                _markers[s._id].marker.setIcon(
                  new window.google.maps.MarkerImage(
                    "https://s3.us-east-2.amazonaws.com/pr-crm/assets/markers/to-do.png",
                    null,
                    null,
                    null,
                    new window.google.maps.Size(24, 36)
                  )
                );
                _markers[s._id].marker.setZIndex(1);
                return _markers;
              });
            }
          }
        );

        _markers[s._id].click = window.google.maps.event.addListener(
          _markers[s._id].marker,
          "click",
          function () {
            if (screens.md) {
              const el = document.getElementById(s._id);
              el.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest",
              });
            }
          }
        );
      }
      return s;
    });
    setMarkers(_markers);
  };

  const handleBeforeCarouselChange = (e) => {
    const _scope = scopes[e];
    if (_scope && _scope._id && markers[_scope._id]) {
      setMarkers((_markers) => {
        _markers[_scope._id].marker.setIcon(
          new window.google.maps.MarkerImage(
            "https://s3.us-east-2.amazonaws.com/pr-crm/assets/markers/to-do.png",
            null,
            null,
            null,
            new window.google.maps.Size(24, 36)
          )
        );
        _markers[_scope._id].marker.setZIndex(1);
        return _markers;
      });
    }
  };

  const handleAfterCarouselChange = (e) => {
    const _scope = scopes[e];

    if (_scope && _scope.projectAddressLat && _scope.projectAddressLng) {
      const destLatLng = new window.google.maps.LatLng(
        _scope.projectAddressLat,
        _scope.projectAddressLng
      );
      smoothlyAnimatePanTo(installTodoMap, destLatLng);
    }

    if (_scope && _scope._id && markers[_scope._id]) {
      setMarkers((_markers) => {
        _markers[_scope._id].marker.setIcon(
          new window.google.maps.MarkerImage(
            "https://s3.us-east-2.amazonaws.com/pr-crm/assets/markers/to-do-active.png",
            null,
            null,
            null,
            new window.google.maps.Size(36, 36)
          )
        );
        _markers[_scope._id].marker.setZIndex(10);
        return _markers;
      });
    }
  };

  const panToPin = (_id) => {
    const _scope = scopes.find((s) => s._id === _id);

    if (_scope && _scope.projectAddressLat && _scope.projectAddressLng) {
      const destLatLng = new window.google.maps.LatLng(
        _scope.projectAddressLat,
        _scope.projectAddressLng
      );
      smoothlyAnimatePanTo(installTodoMap, destLatLng);
    }
  };

  const openInstallTodo = useCallback(() => {
    dispatch(
      openForm("installTodo", {
        callback: (data) => {
          setScopes(data);
        },
      })
    );
  }, [dispatch, setScopes]);

  const openInstallTodoNonPO = useCallback(() => {
    dispatch(
      openForm("installTodoNonPo", {
        callback: (data) => {
          setScopes(data);
        },
      })
    );
  }, [dispatch, setScopes]);

  return (
    <div className="form" style={{ height: "100%" }}>
      {screens.xs || (screens.sm && !screens.md) ? (
        <div style={{ height: "100%", width: "100%" }}>
          <Row gutter={16}>
            <Col xs={12}>
              <Button
                type="primary"
                className="green"
                style={{
                  position: "fixed",
                  top: 76,
                  // right: 12,
                  left: 12,
                  width: "calc(50% - 18px)",
                  zIndex: 1,
                }}
                onClick={openInstallTodo}
              >
                Add PO To-do
              </Button>
            </Col>
            <Col xs={12}>
              <Button
                type="primary"
                className="green"
                style={{
                  position: "fixed",
                  top: 76,
                  right: 12,
                  // left: 12,
                  width: "calc(50% - 18px)",
                  zIndex: 1,
                }}
                onClick={openInstallTodoNonPO}
              >
                Add Non PO To-do
              </Button>
            </Col>
          </Row>

          <div className={styles.projectCarousel} id="carousel">
            {loading ? (
              <div className={styles.mobileLoader}>
                <div>
                  <Loader minHeight="100%" />
                </div>
              </div>
            ) : scopes.length === 0 ? (
              <div className={styles.mobileLength0}>
                All to-dos have been completed!
              </div>
            ) : (
              <Carousel
                ref={carouselRef}
                centerMode={true}
                centerPadding={scopes.length === 1 ? "0px" : "16px"}
                slidesToShow={1}
                // className={styles.carousel}
                dots={false}
                beforeChange={handleBeforeCarouselChange}
                afterChange={handleAfterCarouselChange}
                infinite={false}
              >
                {scopes.map((scope, i) => (
                  <div key={scope._id} className={styles.carouselSlide}>
                    <MobileScope
                      index={i}
                      carouselRef={carouselRef}
                      scope={scope}
                      scopes={scopes}
                      container={container}
                      setMarkers={setMarkers}
                      markers={markers}
                      setScopes={setScopes}
                      fetchScopes={fetchScopes}
                      removePins={removePins}
                    />
                  </div>
                ))}
              </Carousel>
            )}
          </div>
          <div
            style={{
              width: "100%",
              height: "100%",
            }}
            id="installTodoMap"
          ></div>
        </div>
      ) : (
        <div className="content-inner p24" ref={setContainer}>
          <Row gutter={16} style={{ height: "100%" }}>
            <Col xs={12} sm={24} md={13} lg={11}>
              <Row gutter={16}>
                <Col xs={12} style={{ marginBottom: 16 }}>
                  <Button
                    type="primary"
                    className="green"
                    block
                    onClick={openInstallTodo}
                  >
                    Add PO To-do
                  </Button>
                </Col>
                <Col xs={12} style={{ marginBottom: 16 }}>
                  <Button
                    type="primary"
                    className="green"
                    block
                    onClick={openInstallTodoNonPO}
                  >
                    Add Non PO To-do
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col xs={24}>
                  {loading ? (
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                      }}
                    >
                      <Loader minHeight="100%" />
                    </div>
                  ) : scopes.length === 0 ? (
                    <div className={styles.length0}>
                      All to-dos have been completed!
                    </div>
                  ) : (
                    scopes.map((scope) => (
                      <Scope
                        key={scope._id}
                        scope={scope}
                        scopes={scopes}
                        setMarkers={setMarkers}
                        markers={markers}
                        fetchScopes={fetchScopes}
                        setScopes={setScopes}
                        container={container}
                        panToPin={panToPin}
                        removePins={removePins}
                      />
                    ))
                  )}
                </Col>
              </Row>
            </Col>
            <Col xs={0} sm={0} md={11} lg={13} className={styles.mapContainer}>
              <Affix offsetTop={25} target={() => container}>
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                    marginBottom: 16,
                    borderRadius: 4,
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      marginBottom: 16,
                      borderRadius: 4,
                    }}
                    id="installTodoMap"
                  ></div>
                </div>
              </Affix>
            </Col>
          </Row>
        </div>
      )}

      <ViewContainer
        type="drawer"
        visible={showProject}
        form={ViewProject}
        placement="right"
        width={!screens.sm ? "100%" : 400}
        closable={false}
        mask={false}
      />

      <ViewContainer
        type="drawer"
        visible={showLead}
        form={ViewLead}
        placement="right"
        width={!screens.sm ? "100%" : 400}
        closable={false}
        mask={false}
      />

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0 },
        }}
        type={!screens.md ? "drawer" : "modal"}
        width={!screens.md ? "100%" : 320}
        title="Update Due Date"
        visible={installTodoDueDate}
        form={InstallTodoDueDate}
        formType="installTodoDueDate"
      />

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0 },
        }}
        type={!screens.md ? "drawer" : "modal"}
        width={!screens.md ? "100%" : 320}
        title="Update Assignee"
        visible={installTodoAssignee}
        form={InstallTodoAssignee}
        formType="installTodoAssignee"
      />

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0 },
        }}
        type={!screens.md ? "drawer" : "modal"}
        width={!screens.md ? "100%" : 320}
        title="Update To-do"
        visible={installTodoChange}
        form={InstallTodoChange}
        formType="installTodoChange"
      />
    </div>
  );
};

export default InstallTodos;
