import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { openForm } from "Actions/formActions";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
import api from "API";
import Button from "antd/es/button";
import Divider from "antd/es/divider";
import Call from "Icons/Call";
import ClipboardText from "Icons/ClipboardText";
import Star from "Icons/Star";
import Post from "Icons/Post";
import Typography from "antd/es/typography";
import Link from "Icons/Link";
import Modal from "antd/es/modal";
import MoreVert from "Icons/MoreVert";
import Dropdown from "antd/es/dropdown";
import Menu from "antd/es/menu";
import removePhoneSymbols from "Utils/removePhoneSymbols";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";
import message from "antd/es/message";
import Clipboard from "react-clipboard.js";
import Card from "antd/es/card";
import Tag from "antd/es/tag";

import dayjs from "dayjs";
import isTomorrow from "dayjs/plugin/isTomorrow";
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";
import LazyLoad from "Components/LazyLoad";
import ImageFallback from "Components/images/Image";

import LocalShipping from "Icons/LocalShipping";

dayjs.extend(isTomorrow);
dayjs.extend(isToday);
dayjs.extend(isYesterday);

const MobileScope = ({
  scope,
  scopes,
  markers,
  setScopes,
  fetchScopes,
  container,
  copyObject,
  admin,
  manager,
  role,
  _user,
  removePins,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [link] = useState(
    process.env.NODE_ENV === "production"
      ? "https://story.gopremier.net/schedule"
      : "localhost:3001/schedule"
  );
  const copied = () => {
    message.success("Schedule has been copied");
  };

  const openRelated = (e) => {
    navigate(`/schedule/projects/view/related/${scope._projectId}`);
  };

  // const openScope = useCallback(() => {
  //   dispatch(
  //     openForm("updateScope", {
  //       ...scope,
  //       editing: true,
  //       callback: fetchScopes,
  //     })
  //   );
  // }, [dispatch, scope, fetchScopes]);

  const openActivity = useCallback(
    (e) => {
      dispatch(
        openForm("activity", {
          _id: scope._projectId,
          customerId: scope.customerId,
          projectStatus: "Sold",
          customerName: scope.customerName,
          projectManager: {
            name: scope.workOrder.projectManagerName,
          },
        })
      );
    },
    [dispatch, scope]
  );

  const rescheduleCallback = useCallback(
    (data) => {
      removePins();
      setScopes(data);
    },
    [removePins, setScopes]
  );

  const handleReSchedule = useCallback(
    ({ domEvent }) => {
      dispatch(
        openForm("rescheduleScope", {
          _id: scope._id,
          _projectId: scope._projectId,
          name: scope.name,
          installDate: scope.installDate,
          assignToSub: scope.assignToSub,
          customerId: scope.customerId,
          projectAddress: scope.workOrder.projectAddress,
          callback: rescheduleCallback,
        })
      );
    },
    [dispatch, scope, rescheduleCallback]
  );

  const handleReAssign = useCallback(
    async ({ domEvent }) => {
      try {
        let fileName = `${scope.name}-Work-Order-${scope.workOrder.version}`;
        fileName = fileName.replace(" - ", "-");
        fileName = fileName.replace(" ", "-");
        const res = await api.get(`/get-media/${scope._projectId}`);
        const allMedia = res.data._media.concat(res.data._documents);
        const workOrder = allMedia.find((m) => m.name === fileName);

        dispatch(
          openForm("workRequestRoof", {
            emails: [
              scope.workOrder && scope.workOrder.projectManagerEmail,
              "install@gopremier.net",
            ],
            assignToSub: scope.assignToSub,
            installDate: scope.installDate,
            _projectId: scope._projectId,
            content: [workOrder],
            _scopeId: scope._id,
            allMedia,
            customerId: scope.customerId,
            customerName: scope.workOrder.customerName,
            projectAddress: scope.workOrder.projectAddress,
            callback: rescheduleCallback,
          })
        );
      } catch (err) {
        console.log("err", err);
      }
    },
    [scope, dispatch, rescheduleCallback]
  );

  const confirmUnSchedule = ({ domEvent }) => {
    Modal.confirm({
      icon: <QuestionCircleOutlined />,
      centered: true,
      cancelText: "Cancel",
      okText: "Confirm",
      okButtonProps: { danger: true },
      content: (
        <div>
          Are you sure you want to un-schedule the scope for {scope.customerId}?
        </div>
      ),
      onOk() {
        return new Promise((resolve, reject) => {
          handleUnSchedule(() => resolve(true));
        }).catch((err) => console.log("err: ", err));
      },
      onCancel() {},
    });
  };

  const handleUnSchedule = async (cb) => {
    try {
      const res = await api.post("/schedule/un-schedule", {
        _id: scope._id,
        _projectId: scope._projectId,
        name: scope.name,
        assignToSub: scope.assignToSub,
        customerId: scope.customerId,
        projectAddress: scope.workOrder.projectAddress,
      });

      await removePins();

      setScopes(res.data);

      cb();

      message.success("Scope un-scheduled");
    } catch (err) {
      console.log("err", err);
      message.error("Error un-scheduling scope");
    }
  };

  const confirmDryIn = ({ domEvent }) => {
    Modal.confirm({
      icon: <QuestionCircleOutlined />,
      centered: true,
      cancelText: "Cancel",
      okText: "Confirm",
      okButtonProps: { danger: true },
      content: (
        <div>
          Are you sure you want to mark the scope for {scope.customerId} as
          dried in?
        </div>
      ),
      onOk() {
        return new Promise((resolve, reject) => {
          handleDryIn(() => resolve(true));
        }).catch((err) => console.log("err: ", err));
      },
      onCancel() {},
    });
  };

  const handleDryIn = async (cb) => {
    try {
      const res = await api.post("/schedule/dried-in", {
        _id: scope._id,
        _projectId: scope._projectId,
        name: scope.name,
      });

      await removePins();

      setScopes(res.data);

      cb();

      message.success("Scope marked dried in");
    } catch (err) {
      console.log("err", err);
      message.error("Error marking scope as dried in");
    }
  };

  const confirmCompletion = () => {
    Modal.confirm({
      icon: <QuestionCircleOutlined />,
      centered: true,
      cancelText: "Cancel",
      okText: "Confirm",
      okButtonProps: { danger: true },
      content: (
        <div>Are you sure you want to mark this installation as completed?</div>
      ),
      onOk() {
        return new Promise((resolve, reject) => {
          handleCompletion(() => resolve(true));
        }).catch((err) => console.log("err: ", err));
      },
      onCancel() {},
    });
  };

  const handleCompletion = async (cb) => {
    try {
      const res = await api.post("/schedule/completed", {
        _projectId: scope._projectId,
        _scopeId: scope._id,
      });

      await removePins();

      setScopes(res.data);

      cb();
    } catch (err) {
      console.log("err", err);
      message.error("Error marking installation as complete");
    }
  };

  // const openPreJobInspection = useCallback(() => {
  //   dispatch(
  //     openForm("preJobInspection", {
  //       _id: scope._projectId,
  //       _scopeId: scope._id,
  //     })
  //   );
  // }, [dispatch, scope]);

  const materialPickupCallback = useCallback(() => {
    fetchScopes();
  }, [fetchScopes]);

  const openMaterialPickup = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(
        openForm("materialPickup", {
          _id: scope._projectId,
          _scopeId: scope._id,
          projectAddress: scope.workOrder.projectAddress,
          customerId: scope.customerId,
          projectManager: {
            email: scope.workOrder.projectManagerEmail,
          },
          supplierEmail: scope.supplierEmail,
          supplier: scope.supplier,
          callback: materialPickupCallback,
        })
      );
    },
    [dispatch, scope, materialPickupCallback]
  );

  const menu = (
    <Menu>
      {/* <Menu.Item onClick={openScope}>Update Scope</Menu.Item> */}
      <Menu.Item onClick={handleReSchedule}>Re-Schedule</Menu.Item>
      <Menu.Item onClick={handleReAssign}>Re-Assign</Menu.Item>
      <Menu.Item onClick={confirmUnSchedule}>Un-Schedule</Menu.Item>
      {scope.workOrder &&
        scope.workOrder.propertyType === "New Construction" &&
        !scope.driedIn && (
          <Menu.Item onClick={confirmDryIn}>Dried In</Menu.Item>
        )}
      {/* <Menu.Item onClick={openPreJobInspection}>Pre-Job Inspection</Menu.Item> */}
      <Menu.Divider />
      <Menu.Item onClick={confirmCompletion}>Mark Completed</Menu.Item>
    </Menu>
  );

  return (
    <Card
      className={styles.mobileScope}
      hoverable
      size="small"
      // TODO: show all related project scopes
      cover={
        <>
          {(admin === "Yes" ||
            role === "Office" ||
            manager === "Yes" ||
            scope.salesObj.find((s) => s._user === _user)) && (
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button shape="circle" size="large" className={styles.moreVert}>
                <MoreVert />
              </Button>
            </Dropdown>
          )}
          <Clipboard
            data-clipboard-text={
              `Tomorrows schedule updated. Please let HO know cars out of the garage and driveway by 7 am for dumpster. Thanks\n\n` +
              copyObject[scope.stamp] +
              link
            }
            component="div"
            onSuccess={copied}
          >
            <div className={styles.mobileDateStamp}>
              <span className={styles.copy}>Copy</span>
              <span className={styles.stamp}>{scope.stamp}</span>
            </div>
          </Clipboard>
          <span className={styles.mobilePoCustomerName}>
            <Typography.Title level={5} style={{ margin: 0 }}>
              {scope.customerId} - {scope.workOrder.customerName}
            </Typography.Title>
          </span>

          <div style={{ overflow: "hidden", maxHeight: 120 }}>
            <LazyLoad
              offsetVertical={200}
              height="120px"
              width={"100%"}
              root={container}
            >
              <ImageFallback
                alt="schedule"
                image={{
                  src: scope.starredImageSrc || undefined,
                  thumbnail: scope.starredImageSrcThumbnail || undefined,
                }}
              />
            </LazyLoad>
          </div>
        </>
      }
      actions={[
        <Button
          className={styles.bottomBtn}
          size="large"
          shape="circle"
          type="link"
          href={`tel:${removePhoneSymbols(
            scope.workOrder && scope.workOrder.phone
          )}`}
        >
          <Call size={32} />
        </Button>,
        <Button
          className={styles.bottomBtn}
          size="large"
          shape="circle"
          type="link"
          onClick={openActivity}
        >
          <Post size={32} />
        </Button>,
        <Button
          className={styles.bottomBtn}
          size="large"
          shape="circle"
          type="link"
          danger={scope.materialPickupRequested}
          onClick={openMaterialPickup}
        >
          <LocalShipping size={32} />
        </Button>,
        <Button
          className={styles.bottomBtn}
          size="large"
          shape="circle"
          type="link"
          href={scope.workOrder.src}
          target="_blank"
        >
          <ClipboardText size={32} />
        </Button>,
        <Button
          className={styles.bottomBtn}
          size="large"
          shape="circle"
          type="link"
          onClick={openRelated}
        >
          <Link size={32} />
        </Button>,
      ]}
    >
      <div className={styles.description}>
        <div
          className={styles.detailLine}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography.Text type="secondary" style={{ fontSize: 15 }}>
            {scope.assignToSub.companyName} - {scope.assignToSub.contactName}
          </Typography.Text>
          <div>
            {scope.workOrder &&
              scope.workOrder.scopes.map((s, i) => (
                <Tag
                  key={s.initials + i}
                  className={styles.scopeBadge}
                  color={s.color}
                >
                  {s.initials}
                </Tag>
              ))}
          </div>
        </div>

        <div className={`${styles.details} ${styles.detailLine}`}>
          <Typography.Text type="secondary" style={{ fontSize: 14 }}>
            {scope.workOrder.pitches.join(", ")}
          </Typography.Text>{" "}
          <Divider type="vertical" />{" "}
          <Typography.Text type="secondary" style={{ fontSize: 14 }}>
            {scope.workOrder.squares} sq.
          </Typography.Text>{" "}
          <Divider type="vertical" />{" "}
          <Typography.Text type="secondary" style={{ fontSize: 14 }}>
            {scope.workOrder.roofType
              ? scope.workOrder.roofType.join(", ")
              : scope.workOrder.installationMethod}
          </Typography.Text>{" "}
          {/* TODO: difficultyRating overhaul */}
          {scope.workOrder.difficultyRating ? (
            <>
              <Divider type="vertical" /> <Star color="gold" />{" "}
              <Typography.Text type="secondary" style={{ fontSize: 14 }}>
                {/* TODO: difficultyRating overhaul */}
                {scope.workOrder.difficultyRating}
              </Typography.Text>
            </>
          ) : null}
        </div>
        <div className={styles.detailLine}>
          <Typography.Link
            href={`https://www.google.com/maps/place/${scope.workOrder.projectAddressLat},${scope.workOrder.projectAddressLng}`}
            target="_blank"
          >
            {scope.workOrder.projectAddress}
          </Typography.Link>
        </div>
        <div className={styles.detailLine}>
          <Typography.Text type="secondary" style={{ fontSize: 14 }}>
            {scope.workOrder.projectManagerName}
            {scope.installProjectManager
              ? ` | ${scope.installProjectManager.name}`
              : ""}
          </Typography.Text>
        </div>
      </div>
    </Card>
  );
};

export default MobileScope;
