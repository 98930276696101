import React, { useEffect, useState } from "react";
import Permits from "Components/Inputs/Permits";
import DatePicker from "Components/Inputs/DatePicker";
import ProjectManager from "Components/Inputs/ProjectManager";
import Form from "antd/es/form";
import Checkbox from "antd/es/checkbox";
import Input from "antd/es/input";
import Button from "antd/es/button";
import useIsMobile from "Hooks/useIsMobile";
import ManageBuilders from "Components/Inputs/ManageBuilders";
import PropertyType from "Components/Inputs/PropertyType";
import Phone from "Components/Inputs/Phone";
import Address from "Components/Inputs/Address";
import Campaigns from "Components/Inputs/Campaigns";
import Salesmen from "Components/Inputs/Salesmen";
import Map from "Components/Details/fields/Map";
import styles from "./styles.module.scss";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";
import Select from "Components/Inputs/Select";

const Details = ({
  sales,
  _sales,
  onPropertyTypeChange,
  form,
  setSales,
  propertyType,
  selectedOrigin,
  handleOriginChange,
  projectAddressLat,
  projectAddressLng,
}) => {
  const [_lat, _setLat] = useState(projectAddressLat || 42.963795);
  const [_lng, _setLng] = useState(projectAddressLng || -85.670006);
  const mobile = useIsMobile();

  useEffect(() => {
    if (propertyType === "New Construction") {
      form.setFieldsValue({
        permits: ["N/A"],
      });
    }
  }, [propertyType]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateCords = ({ lat, lng }) => {
    _setLat(lat);
    _setLng(lng);
  };

  const setProjectAddress = ({
    city,
    state,
    postcode,
    county,
    permitAuthority,
    lat,
    lng,
  }) => {
    form.setFieldsValue({
      projectAddressCity: city,
      projectAddressState: state,
      projectAddressZip: postcode,
      projectAddressCounty: county,
      projectAddressPermitAuthority: permitAuthority,
      projectAddressLat: lat,
      projectAddressLng: lng,
    });

    updateCords({
      lat,
      lng,
    });
  };

  const setBillingAddress = ({ city, state, postcode }) => {
    form.setFieldsValue({
      billingAddressCity: city,
      billingAddressState: state,
      billingAddressZip: postcode,
    });
  };

  const copyProjectAddress = async () => {
    try {
      const _projectAddress = await form.getFieldsValue([
        "projectAddressStreet",
        "projectAddressCity",
        "projectAddressState",
        "projectAddressZip",
      ]);

      form.setFieldsValue({
        billingAddressStreet: _projectAddress.projectAddressStreet,
        billingAddressCity: _projectAddress.projectAddressCity,
        billingAddressState: _projectAddress.projectAddressState,
        billingAddressZip: _projectAddress.projectAddressZip,
      });
    } catch (err) {
      console.log("setBillingAddress err", err);
    }
  };

  const onSalesmenChange = async (value) => {
    try {
      let options = {};
      for (const v of value) {
        options[v] = _sales[v];
      }
      setSales(options);

      const _projectManager = await form.getFieldValue("projectManager");
      if (!options[_projectManager]) {
        form.setFieldsValue({
          projectManager: undefined,
        });
      }
    } catch (err) {
      console.log("onSalesmenChange err", err);
    }
  };

  return (
    <>
      <Form.Item
        label="Contract Date"
        name="contractDate"
        rules={[{ required: true, message: "Contract date is required" }]}
      >
        <DatePicker
          format="MMMM DD, YYYY"
          getPopupContainer={(trigger) =>
            !mobile
              ? trigger.parentNode.parentNode.parentNode.parentNode
              : document.body
          }
          getCalendarContainer={(trigger) =>
            !mobile
              ? trigger.parentNode.parentNode.parentNode.parentNode
              : document.body
          }
          inputReadOnly={true}
        />
      </Form.Item>

      <Form.Item
        name="propertyType"
        label="Property Type"
        rules={[{ required: true, message: "Property type is required" }]}
      >
        <PropertyType onChange={onPropertyTypeChange} />
      </Form.Item>

      {propertyType === "New Construction" && <ManageBuilders form={form} />}

      <Form.Item name="companyName" label="Company Name">
        <InputNoAutoComplete id="companyName" />
      </Form.Item>

      <Form.Item
        name="customerName"
        label="Customer Name"
        rules={[{ required: true, message: "Customer name is required" }]}
      >
        <InputNoAutoComplete id="customerName" />
      </Form.Item>

      <Form.Item name="mccEligible" valuePropName="checked">
        <Checkbox>MyCo Contact Elibile</Checkbox>
      </Form.Item>

      <Form.Item
        name="phone"
        label="Phone"
        rules={[{ required: true, message: "Phone is required" }]}
      >
        <Phone autoComplete="lead-phone-input" id="phone" />
      </Form.Item>

      <Form.List name="alternatePhone">
        {(fields, { add, remove }) => {
          const handleAdd = () => {
            add();
          };

          const handleRemove = () => {
            remove(fields.length - 1);
          };

          return (
            <>
              {fields.map((field, i) => (
                <div key={`${field.key}-altPhone`}>
                  <Form.Item
                    {...field}
                    name={[field.name, "description"]}
                    fieldKey={[field.fieldKey, "description"]}
                    label={`Alt. Phone Description ${i + 1}`}
                    rules={[
                      {
                        required: true,
                        message: "Description is required",
                      },
                    ]}
                  >
                    <InputNoAutoComplete id={[field.name, "description"]} />
                  </Form.Item>

                  <Form.Item
                    {...field}
                    name={[field.name, "contact"]}
                    label={`Alt. Phone ${i + 1}`}
                    fieldKey={[field.fieldKey, "contact"]}
                    rules={[
                      { required: true, message: "Phone is required" },
                      {
                        len: 14,
                        message: "Phone must be 10 digits long",
                      },
                    ]}
                  >
                    <Phone
                      autoComplete="edit-detail-field"
                      id={[field.name, "contact"]}
                    />
                  </Form.Item>
                </div>
              ))}

              <div
                className={`${styles.altButtonContainer} ${
                  fields.length === 0 ? styles.end : ""
                }`}
              >
                {fields.length > 0 && (
                  <Button
                    tabIndex="-1"
                    type="link"
                    danger
                    onClick={handleRemove}
                  >
                    Remove Alt. Phone
                  </Button>
                )}

                <Button tabIndex="-1" type="link" onClick={handleAdd}>
                  Add Alt. Phone
                </Button>
              </div>
            </>
          );
        }}
      </Form.List>

      <Form.Item
        name="email"
        label="Email"
        rules={[{ type: "email", message: "Must be valid email" }]}
      >
        <InputNoAutoComplete type="email" id="email" />
      </Form.Item>

      <Form.List name="alternateEmail">
        {(fields, { add, remove }) => {
          const handleAdd = () => {
            add();
          };

          const handleRemove = () => {
            remove(fields.length - 1);
          };

          return (
            <>
              {fields.map((field, i) => (
                <div key={`${field.key}-altEmail`}>
                  <Form.Item
                    {...field}
                    name={[field.name, "description"]}
                    fieldKey={[field.fieldKey, "description"]}
                    label={`Alt. Email Description ${i + 1}`}
                    rules={[
                      {
                        required: true,
                        message: "Description is required",
                      },
                    ]}
                  >
                    <InputNoAutoComplete id={[field.name, "description"]} />
                  </Form.Item>

                  <Form.Item
                    {...field}
                    name={[field.name, "contact"]}
                    label={`Alt. Email ${i + 1}`}
                    fieldKey={[field.fieldKey, "contact"]}
                    rules={[
                      { required: true, message: "Email is required" },
                      { type: "email", message: "Must be a valid email" },
                    ]}
                  >
                    <InputNoAutoComplete
                      type="email"
                      id={[field.name, "contact"]}
                    />
                  </Form.Item>
                </div>
              ))}

              <div
                className={`${styles.altButtonContainer} ${
                  fields.length === 0 ? styles.end : ""
                }`}
              >
                {fields.length > 0 && (
                  <Button
                    tabIndex="-1"
                    type="link"
                    danger
                    onClick={handleRemove}
                  >
                    Remove Alt. Email
                  </Button>
                )}

                <Button tabIndex="-1" type="link" onClick={handleAdd}>
                  Add Alt. Email
                </Button>
              </div>
            </>
          );
        }}
      </Form.List>

      <h3 style={{ marginBottom: 12 }}>Project Address</h3>

      <Form.Item
        label="Street"
        name="projectAddressStreet"
        rules={[{ required: true, message: "Project street is required" }]}
      >
        <Address
          autoComplete="projectStreet"
          setAddress={setProjectAddress}
          id="projectAddressStreet"
        />
      </Form.Item>

      <Form.Item
        label="City"
        name="projectAddressCity"
        rules={[
          {
            required: true,
            message: "Project city is required",
            type: "string",
          },
        ]}
      >
        <InputNoAutoComplete id="projectAddressCity" />
      </Form.Item>

      <Form.Item
        label="State"
        name="projectAddressState"
        rules={[
          {
            required: true,
            message: "Project state is required",
            type: "string",
          },
          {
            len: 2,
            message: "Must be abbreviated state",
          },
        ]}
      >
        <InputNoAutoComplete id="projectAddressState" />
      </Form.Item>

      <Form.Item
        label="Zip"
        name="projectAddressZip"
        rules={[
          {
            required: true,
            message: "Project zip is required",
            type: "string",
          },
        ]}
      >
        <InputNoAutoComplete type="phone" id="projectAddressZip" />
      </Form.Item>

      <Map
        type="project"
        form={form}
        lat={_lat}
        lng={_lng}
        updateCords={updateCords}
      />

      <Form.Item style={{ display: "none" }} name="projectAddressCounty">
        <Input autoComplete="projectCounty" type="text" />
      </Form.Item>

      <Form.Item
        style={{ display: "none" }}
        name="projectAddressPermitAuthority"
      >
        <Input autoComplete="projectPermitAuthority" type="text" />
      </Form.Item>

      <Form.Item style={{ display: "none" }} name="projectAddressLat">
        <Input autoComplete="projectLat" type="text" />
      </Form.Item>

      <Form.Item style={{ display: "none" }} name="projectAddressLng">
        <Input autoComplete="projectLng" type="text" />
      </Form.Item>

      <h3 className={styles.headerWithButton}>
        <span>Billing Address</span>
        <Button
          type="primary"
          size="small"
          className="green"
          onClick={copyProjectAddress}
        >
          Copy Project Address
        </Button>{" "}
      </h3>

      <Form.Item
        label="Street"
        name="billingAddressStreet"
        rules={[{ required: true, message: "Billing street is required" }]}
      >
        <Address
          autoComplete="billingStreet"
          setAddress={setBillingAddress}
          id="billingAddressStreet"
        />
      </Form.Item>

      <Form.Item
        label="City"
        name="billingAddressCity"
        rules={[
          {
            required: true,
            message: "Billing city is required",
            type: "string",
          },
        ]}
      >
        <InputNoAutoComplete id="billingAddressCity" />
      </Form.Item>

      <Form.Item
        label="State"
        name="billingAddressState"
        rules={[
          {
            required: true,
            message: "Billing state is required",
            type: "string",
          },
          {
            len: 2,
            message: "State must be abbreviated",
          },
        ]}
      >
        <InputNoAutoComplete id="billingAddressState" />
      </Form.Item>

      <Form.Item
        label="Zip"
        name="billingAddressZip"
        rules={[
          {
            required: true,
            message: "Billing zip is required",
            type: "string",
          },
        ]}
      >
        <InputNoAutoComplete type="phone" id="billingAddressZip" />
      </Form.Item>

      <Form.Item label="Claim Number" name="claimNumber">
        <InputNoAutoComplete id="claimNumber" />
      </Form.Item>

      <Form.Item
        name="salesmen"
        label="Salesmen"
        rules={[
          {
            required: true,
            message: "Must select at least 1 salesman",
            type: "array",
          },
        ]}
      >
        <Salesmen sales={_sales} onChange={onSalesmenChange} />
      </Form.Item>

      <Form.Item
        name="projectManager"
        label="Project Owner"
        rules={[
          {
            required: true,
            message: "Select a Project Owner",
          },
        ]}
      >
        <ProjectManager sales={sales} />
      </Form.Item>

      <Form.Item
        name="origin"
        label="Origin"
        rules={[
          {
            required: true,
            message: "Select an Origin",
          },
        ]}
      >
        <Select
          options={["Personal", "Office"]}
          id="origin"
          onChange={handleOriginChange}
        />
      </Form.Item>

      {selectedOrigin === "Personal" && (
        <Form.Item
          name="personalReferralName"
          label="Personal Referrer Name"
          rules={[
            {
              required: true,
              message: "Must provide name of person who referred the lead",
            },
          ]}
        >
          <InputNoAutoComplete />
        </Form.Item>
      )}

      {selectedOrigin === "Office" && (
        <Form.Item
          name="campaign"
          label="Campaign"
          rules={[
            {
              required: true,
              message: "Select a Campaign",
            },
          ]}
        >
          <Campaigns />
        </Form.Item>
      )}

      {propertyType === "New Construction" && (
        <Form.Item
          valuePropName="checked"
          name="builderPermits"
          rules={[
            {
              required: true,
              message:
                "New Construction requires that all permits are pulled by the builder",
            },
          ]}
        >
          <Checkbox>Builder is responsible for all permits</Checkbox>
        </Form.Item>
      )}

      <Form.Item
        hidden={propertyType === "New Construction"}
        label="Permits Required"
        name="permits"
        rules={[
          { required: true, message: "At lease one Option must be selected" },
        ]}
      >
        <Permits defaultOpen={false} />
      </Form.Item>

      <Form.Item
        label="Project Description"
        name="projectDescription"
        rules={[{ required: true, message: "Project description required" }]}
      >
        <Input.TextArea autosize={{ minRows: 4 }} />
      </Form.Item>
    </>
  );
};

export default Details;
