import React, { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelected } from "Actions/dataActions";
import { openForm } from "Actions/formActions";
import List from "antd/es/list";
import Tag from "antd/es/tag";
import dayjs from "dayjs";
import styles from "./styles.module.scss";
import statusObj from "Constants/vendorStatus";

const SubsItem = ({ item, style, mobile, headers }) => {
  const selectedRow = useSelector((state) => state.dataState.selectedRow);
  const dispatch = useDispatch();

  const onClick = useCallback(() => {
    dispatch(setSelected(item._id));
    dispatch(openForm("sub", { ...item, editing: true }));
  }, [dispatch, item]);

  if (!mobile) {
    return (
      <List.Item
        style={style}
        className={`${styles.row} ${
          selectedRow === item._id ? styles.selected : ""
        }`}
        onClick={onClick}
      >
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[0].width,
            textAlign: headers[0].align ? headers[0].align : "left",
          }}
          description={item.companyName}
        />
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[1].width,
            textAlign: headers[1].align ? headers[1].align : "left",
          }}
          description={item.contactName}
        />
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[2].width,
            textAlign: headers[2].align ? headers[2].align : "left",
          }}
          description={
            <Tag className={styles.statusTag} color={statusObj[item.status]}>
              {item.status}
            </Tag>
          }
        />
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[3].width,
            textAlign: headers[3].align ? headers[3].align : "left",
          }}
          description={item.glRequired ? "TRUE" : "FALSE"}
        />
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[4].width,
            textAlign: headers[4].align ? headers[4].align : "left",
          }}
          description={item.wcRequired ? "TRUE" : "FALSE"}
        />
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[5].width,
            textAlign: headers[5].align ? headers[5].align : "left",
          }}
          description={
            item.glRequired
              ? dayjs(item.glDetails.expiration).format("MMM D, YYYY")
              : null
          }
        />
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[6].width,
            textAlign: headers[6].align ? headers[6].align : "left",
          }}
          description={
            item.wcRequired
              ? dayjs(item.wcDetails.expiration).format("MMM D, YYYY")
              : null
          }
        />
      </List.Item>
    );
  } else {
    return (
      <List.Item
        style={style}
        className={`${styles.mobileListItem} ${
          selectedRow === item._id ? styles.selected : ""
        }`}
        onClick={onClick}
      >
        <List.Item.Meta
          className={styles.customerId}
          title={item.companyName}
        />
        <List.Item.Meta
          className={styles.status}
          description={
            <Tag className={styles.statusTag} color={statusObj[item.status]}>
              {item.status}
            </Tag>
          }
        />
        <div className={styles.break} />
        <List.Item.Meta
          className={styles.fullLine}
          style={{ flex: "1 1 100%", paddingBottom: 3 }}
          description={item.contactName}
        />
        <div className={styles.break} />
        {item.glRequired && (
          <>
            <List.Item.Meta
              className={styles.fullLine}
              description={`GL Exp.: ${item.glDetails.expiration}`}
            />
            <div className={styles.break} />
          </>
        )}
        {item.wcRequired && (
          <List.Item.Meta
            className={styles.fullLine}
            description={`WC Exp.: ${item.wcDetails.expiration}`}
          />
        )}
      </List.Item>
    );
  }
};

export default memo(SubsItem);
