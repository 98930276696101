import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { openForm } from "Actions/formActions";
import List from "antd/es/list";
import currencyFormatter from "Utils/currencyFormatter";
import styles from "./styles.module.scss";
import Eye from "Icons/Eye";
import Email from "Icons/Email";
import Printer from "Icons/Printer";
import Button from "antd/es/button";
import openInNewTab from "Utils/openInNewTab";
import printJS from "print-js";
import Description from "Icons/Description";

const CompletionCertifications = ({ completionCertificates }) => {
  return (
    <>
      {completionCertificates && completionCertificates.length > 0 ? (
        completionCertificates.map((completionCertificate) => (
          <List
            bordered
            key={completionCertificate._id}
            itemLayout="horizontal"
            className={styles.list}
          >
            <CertificateItem completionCertificate={completionCertificate} />
          </List>
        ))
      ) : (
        <List itemLayout="horizontal" className={styles.list}>
          <List.Item className={`${styles.transactionList} ${styles.none}`}>
            <List.Item.Meta
              avatar={
                <div className={styles.avatar}>
                  <Description color="#108ee9" />
                </div>
              }
              title="No Summaries have been created"
            />
          </List.Item>
        </List>
      )}
    </>
  );
};

const CertificateItem = ({ completionCertificate }) => {
  const dispatch = useDispatch();

  const openInvoice = (e) => {
    e.stopPropagation();
    openInNewTab(completionCertificate.src);
  };

  const editCertificate = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(
        openForm("completionCertificate", {
          ...completionCertificate,
          editing: true,
        })
      );
    },
    [dispatch, completionCertificate]
  );

  const printInvoice = (e) => {
    e.stopPropagation();
    printJS({
      printable: `${completionCertificate.src}?${new Date().getTime()}`,
      type: "pdf",
    });
  };

  const openEmail = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(
        openForm("email", {
          content: [
            {
              _id: completionCertificate.src,
              src: completionCertificate.src,
              _projectId: completionCertificate._projectId,
              type: "completion-certificate",
            },
          ],
        })
      );
    },
    [dispatch, completionCertificate.src, completionCertificate._projectId]
  );

  return (
    <List.Item onClick={editCertificate} className={styles.transactionList}>
      <List.Item.Meta
        avatar={
          <div className={styles.avatar}>
            <Description color="#108ee9" />
          </div>
        }
        title={`${completionCertificate.customerId}${
          completionCertificate.label ? ` [${completionCertificate.label}]` : ""
        }`}
        description={currencyFormatter(completionCertificate.balance)}
      />
      {completionCertificate.src && (
        <>
          <Button
            onClick={openInvoice}
            shape="circle"
            type="link"
            style={{ marginLeft: 8 }}
          >
            <Eye />
          </Button>
          <Button
            onClick={openEmail}
            shape="circle"
            type="link"
            style={{ marginLeft: 8 }}
          >
            <Email />
          </Button>
          <Button
            onClick={printInvoice}
            shape="circle"
            type="link"
            style={{ marginLeft: 8 }}
          >
            <Printer />
          </Button>
        </>
      )}
    </List.Item>
  );
};

export default CompletionCertifications;
