import React, { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchTable } from "Actions/tableActions";
import Form from "antd/es/form";
import message from "antd/es/message";
import Button from "antd/es/button";
import Input from "antd/es/input";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import api from "API";
import CancelButton from "Components/CancelButton";

const messageKey = "messageKey";

const OfficeReviewNote = ({
  unsavedChanges,
  setUnsavedChanges,
  handleClose,
  type,
  setTitle,
}) => {
  const dispatch = useDispatch();
  const formDetails = useSelector(
    (state) => state.formState.officeReviewNote.formDetails
  );
  const table = useSelector((state) => state.tableState.table);
  const col = useSelector((state) => state.tableState.col);
  const order = useSelector((state) => state.tableState.order);
  const search = useSelector((state) => state.tableState.search);
  const activeFilter = useSelector((state) => state.tableState.activeFilter);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTitle(`Office Review Note (${formDetails.customerId})`);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onFinish = useCallback(
    async (values) => {
      try {
        setLoading(true);
        // message.loading("Updating note...", 0);
        message.loading({
          content: "Updating note...",
          duration: 0,
          key: messageKey,
        });
        values._scopeId = formDetails._scopeId;

        await api.post("/office-review/update-note", values);

        if (table) {
          dispatch(
            fetchTable({
              table,
              col,
              order,
              search,
              activeFilter,
              fetching: false,
            })
          );
        }

        if (formDetails.callback) {
          formDetails.callback();
        }

        // message.success("Note updated");
        message.success({
          content: "Note updated",
          duration: 1.5,
          key: messageKey,
        });
        handleClose(true);
      } catch (err) {
        setLoading(false);
        // message.error("Error updating note");
        message.error({
          content: "Error updating note",
          duration: 1.5,
          key: messageKey,
        });
        console.log("err", err);
      }
    },
    [
      formDetails,
      dispatch,
      table,
      col,
      order,
      search,
      activeFilter,
      handleClose,
    ]
  );

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error updating note");
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      className="form"
      initialValues={{
        note: formDetails.note,
      }}
    >
      <RemoveAutocomplete />

      <FormBody
        className="content-inner"
        style={{
          paddingLeft: 24,
          paddingTop: 24,
          paddingRight: 24,
          paddingBottom: 8,
          maxHeight: type === "modal" ? "calc(100vh - 161px)" : "unset",
        }}
      >
        <Form.Item name="note">
          <Input.TextArea rows={4} />
        </Form.Item>
      </FormBody>
      <FormControls className="sticky-footer">
        <CancelButton handleClick={handleClose} />
        <Form.Item style={{ margin: 0 }}>
          <Button
            htmlType="submit"
            type="primary"
            loading={loading}
            disabled={loading || !unsavedChanges}
          >
            Submit
          </Button>
        </Form.Item>
      </FormControls>
    </Form>
  );
};

export default OfficeReviewNote;
