import React, { memo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { signout } from "Actions/authActions";
import { openForm } from "Actions/formActions";
import { showMenu } from "Actions/settingsActions";
import { useDispatch, useSelector } from "react-redux";
import Menu from "antd/es/menu";
import Dashboard from "Icons/Dashboard";
import Build from "Icons/Build";
import Settings from "Icons/Settings";
import Group from "Icons/Group";
import Business from "Icons/Business";
import Chart from "Icons/Chart";
import Tune from "Icons/Tune";
import Place from "Icons/Place";
import Image from "Icons/Image";
import FileSign from "Icons/FileSign";
import Description from "Icons/Description";
import Wallet from "Icons/Wallet";
import Rss from "Icons/Rss";
import Notifications from "Icons/Notifications";
import ClipboardCheck from "Icons/ClipboardCheck";
import HammerWrench from "Icons/HammerWrench";
import Phone from "Icons/Call";
// import Roof from "Icons/Roof";
import Path from "Icons/Path";
import Calendar from "Icons/Calendar";
import AccountCog from "Icons/AccountCog";
import Cart from "Icons/Cart";
import SendIcon from "Icons/Send";

import api from "API";
import message from "antd/es/message";
import csv from "Utils/csv";

import logo from "Assets/logo-240x64.png";
import logoDrawer from "Assets/logo-200x96.png";
import useIsMobile from "Hooks/useIsMobile";
import openInNewTab from "Utils/openInNewTab";

const SubMenu = Menu.SubMenu;

const Navigation = ({ sider }) => {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.settingsState.theme);
  const activeRoute = useSelector((state) => state.settingsState.activeRoute);
  const admin = useSelector((state) => state.authState.admin);
  const reports = useSelector((state) => state.authState.reports);
  const role = useSelector((state) => state.authState.role);
  const manager = useSelector((state) => state.authState.manager);
  const menuItems = useSelector((state) => state.authState.menuItems);
  const email = useSelector((state) => state.authState.email);
  const mobile = useIsMobile();
  const navigate = useNavigate();

  const onClick = (route) => () => {
    navigate(route);
    dispatch(showMenu(false));
  };

  const handleSignout = useCallback(() => {
    dispatch(signout());
    dispatch(showMenu(false));
    navigate("/");
  }, [dispatch, navigate]);

  const openWriteCompanyPost = useCallback(() => {
    dispatch(openForm("companyPost"));
    dispatch(showMenu(false));
  }, [dispatch]);

  const openPreInspectionCheck = useCallback(() => {
    dispatch(openForm("preJobInspection"));
    dispatch(showMenu(false));
  }, [dispatch]);

  const openRequests = useCallback(() => {
    dispatch(openForm("requests"));
  }, [dispatch]);

  const sendPmBonusList = async () => {
    try {
      const response = await api.get("/breakdowns/pm-bonus-list");
      console.log("response", response);
    } catch (err) {
      console.log("generateReportH err", err);
    }
  };

  const sendEmail = async () => {
    try {
      const response = await api.get("/reports/send-email");
      if (response.data.length > 0) {
        csv("products_and_colors.csv", response.data);
      } else {
        message.error("No data to download");
      }
    } catch (err) {
      console.log("sendEmail err", err);
    }
  };

  const sendWeeklyReports = async () => {
    try {
      await api.get("/admin/weekly-digest");
      message.info("Reports have been emailed");
    } catch (err) {
      console.log("sendWeeklyReports err", err);
    }
  };

  const redirectEsign = async () => {
    try {
      openInNewTab(
        "https://form.asana.com/?k=t3ir1kt6wDL9WJsnxt_7sA&d=298930959187513"
      );
    } catch (err) {
      console.log("esign err", err);
    }
  };

  return (
    <>
      <div
        className={`nav-header ${theme}`}
        style={{
          overflow: "hidden",
          padding: sider ? 0 : 12,
        }}
      >
        {sider ? (
          <img src={logo} alt="story-logo" style={{ width: 240 }} />
        ) : (
          <img
            src={logoDrawer}
            alt="story-logo"
            style={{ height: 64, display: "block", margin: "auto" }}
          />
        )}
      </div>
      <div
        className={`content-inner hide-scrollbar ${sider ? "collapsed" : ""}`}
      >
        <Menu
          id="navMenu"
          mode="inline"
          theme={theme}
          inlineIndent={28}
          selectedKeys={[activeRoute]}
        >
          {((menuItems && menuItems.dashboard) || !menuItems) && (
            <Menu.Item key="dashboard" onClick={onClick("/dashboard")}>
              <Dashboard className="anticon" />
              <span style={style}>Dashboard</span>
            </Menu.Item>
          )}

          <Menu.Item key="notifications" onClick={onClick("/notifications")}>
            <Notifications className="anticon" />
            <span style={style}>Notifications</span>
            <span
              style={{
                float: "right",
                display: "inline-block",
                marginLeft: 8,
              }}
            ></span>
          </Menu.Item>

          {mobile && (
            <Menu.Item key="feed" onClick={onClick("/feed")}>
              <Rss className="anticon" />
              <span style={style}>Feed</span>
            </Menu.Item>
          )}

          {((menuItems && menuItems.roofSchedule) || !menuItems) && (
            <Menu.Item key="schedule" onClick={onClick("/schedule")}>
              <Calendar className="anticon" />
              <span style={style}>Schedule</span>
            </Menu.Item>
          )}

          {((menuItems && menuItems.leads) || !menuItems) && (
            <Menu.Item key="leads" onClick={onClick("/leads")}>
              <Group className="anticon" />
              <span style={style}>Leads</span>
            </Menu.Item>
          )}

          {((menuItems && menuItems.projects) || !menuItems) && (
            <Menu.Item key="projects" onClick={onClick("/projects")}>
              <Build className="anticon" />
              <span style={style}>Projects</span>
            </Menu.Item>
          )}

          {((menuItems && menuItems.scopes) || !menuItems) && (
            <Menu.Item key="scopes" onClick={onClick("/scopes")}>
              <Tune className="anticon" />
              <span style={style}>Scopes</span>
            </Menu.Item>
          )}

          {((menuItems && menuItems.accountReceivables) || !menuItems) && (
            <Menu.Item
              key="account-receivables"
              onClick={onClick("/account-receivables")}
            >
              <Wallet className="anticon" />
              <span style={style}>Account Receivables</span>
            </Menu.Item>
          )}

          {((menuItems && menuItems.photoAlbums) || !menuItems) && (
            <Menu.Item key="photo-albums" onClick={onClick("/photo-albums")}>
              <Image className="anticon" />
              <span style={style}>Photo Albums</span>
            </Menu.Item>
          )}

          {((menuItems && menuItems.documents) || !menuItems) && (
            <Menu.Item key="documents" onClick={onClick("/documents")}>
              <Description className="anticon" />
              <span style={style}>Documents</span>
            </Menu.Item>
          )}

          {((menuItems && menuItems.breakdowns) || !menuItems) && (
            <Menu.Item key="breakdowns" onClick={onClick("/breakdowns")}>
              <ClipboardCheck className="anticon" />
              <span style={style}>Breakdowns</span>
            </Menu.Item>
          )}

          <Menu.Item key="e-signs" onClick={redirectEsign}>
            <FileSign className="anticon" />
            <span style={style}>E-Sign</span>
          </Menu.Item>

          {((menuItems && menuItems.maps) || !menuItems) && (
            <Menu.Item key="maps" onClick={onClick("/maps")}>
              <Place className="anticon" />
              <span style={style}>Maps</span>
            </Menu.Item>
          )}

          {/* TODO: add this to menuItems */}
          <Menu.Item
            key="marketing-portal"
            onClick={onClick("/marketing-portal")}
          >
            <Cart className="anticon" />
            <span style={style}>Marketing Portal</span>
          </Menu.Item>

          {(admin === "Yes" || manager === "Yes") &&
            ((menuItems && menuItems.overview) ||
              (menuItems && menuItems.aerialVerification) ||
              (menuItems && menuItems.moldReview) ||
              (menuItems && menuItems.officeReview) ||
              (menuItems && menuItems.readyForMaterial) ||
              (menuItems && menuItems.readyForDumpster) ||
              (menuItems && menuItems.readyForSchedule)) && (
              <SubMenu
                key="workflow"
                title={
                  <span>
                    {/* <Roof className="anticon" /> */}
                    <Path className="anticon" />
                    <span style={style}>Workflow</span>
                  </span>
                }
              >
                {((menuItems && menuItems.overview) || !menuItems) && (
                  <Menu.Item
                    key="overview"
                    onClick={onClick("/workflow/overview")}
                  >
                    <span>Overview</span>
                  </Menu.Item>
                )}

                {((menuItems && menuItems.aerialVerification) ||
                  !menuItems) && (
                  <Menu.Item
                    key="aerial-verification"
                    onClick={onClick("/workflow/aerial-verification")}
                  >
                    <span>Aerial Verification</span>
                  </Menu.Item>
                )}

                <Menu.Item
                  key="on-site-check"
                  onClick={onClick("/workflow/on-site-check")}
                >
                  <span>On-site Check</span>
                </Menu.Item>

                {((menuItems && menuItems.moldReview) || !menuItems) && (
                  <Menu.Item
                    key="mold-review"
                    onClick={onClick("/workflow/mold-review")}
                  >
                    <span>Mold Review</span>
                  </Menu.Item>
                )}

                {((menuItems && menuItems.officeReview) || !menuItems) && (
                  <Menu.Item
                    key="office-review"
                    onClick={onClick("/workflow/office-review")}
                  >
                    <span>Office Review</span>
                  </Menu.Item>
                )}

                {((menuItems && menuItems.readyForMaterial) || !menuItems) && (
                  <Menu.Item
                    key="ready-for-material"
                    onClick={onClick("/workflow/ready-for-material")}
                  >
                    <span>Ready For Material</span>
                  </Menu.Item>
                )}

                {((menuItems && menuItems.readyForDumpster) || !menuItems) && (
                  <Menu.Item
                    key="ready-for-dumpster"
                    onClick={onClick("/workflow/ready-for-dumpster")}
                  >
                    <span>Ready For Dumpster</span>
                  </Menu.Item>
                )}

                {((menuItems && menuItems.readyForSchedule) || !menuItems) && (
                  <Menu.Item
                    key="ready-for-schedule"
                    onClick={onClick("/workflow/ready-for-schedule")}
                  >
                    <span>Ready For Schedule</span>
                  </Menu.Item>
                )}
              </SubMenu>
            )}

          {/* {(admin === "Yes" || role === "Office") && (
            <Menu.Item
              key="squares-needed"
              onClick={onClick("/squares-needed")}
            >
              <ViewList className="anticon" />
              <span style={style}>Squares Needed</span>
            </Menu.Item>
          )} */}

          {reports === "Yes" && (
            <SubMenu
              key="reports"
              title={
                <span>
                  <Chart className="anticon" />
                  <span style={style}>Reports</span>
                </span>
              }
            >
              {admin === "No" && (
                <Menu.ItemGroup title="Reports">
                  <Menu.Item
                    key="reports-dashboard"
                    onClick={onClick("/reports/reports-dashboard")}
                  >
                    <span>Reports Dashboard</span>
                  </Menu.Item>

                  <Menu.Item
                    key="account-receivables-report"
                    onClick={onClick("/reports/account-receivables-report")}
                  >
                    <span>Account Receivables</span>
                  </Menu.Item>

                  {/* <Menu.Item
                    key="account-receivables-pending"
                    onClick={onClick("/reports/account-receivables-pending")}
                  >
                    <span>Account Receivables (Pending)</span>
                  </Menu.Item> */}

                  {/* <Menu.Item
                    key="leads-report"
                    onClick={onClick("/reports/leads-report")}
                  >
                    <span>Leads</span>
                  </Menu.Item> */}

                  <Menu.Item
                    key="pipeline"
                    onClick={onClick("/reports/pipeline")}
                  >
                    <span>Pipeline</span>
                  </Menu.Item>

                  <Menu.Item key="sold" onClick={onClick("/reports/sold")}>
                    <span>Sold</span>
                  </Menu.Item>

                  {role === "Office" && (
                    <Menu.Item
                      key="completed-roofs"
                      onClick={onClick("/reports/completed-roofs")}
                    >
                      <span>Sold vs Completed Roofs</span>
                    </Menu.Item>
                  )}
                </Menu.ItemGroup>
              )}

              {admin === "Yes" && (
                <>
                  <Menu.ItemGroup>
                    <Menu.Item
                      key="reports-dashboard-admin"
                      onClick={onClick("/reports/reports-dashboard-admin")}
                    >
                      <span>Reports Dashboard</span>
                    </Menu.Item>

                    <Menu.Item
                      key="chris-reports"
                      onClick={onClick("/reports/chris-reports")}
                    >
                      <span>Chris' Reports</span>
                    </Menu.Item>
                  </Menu.ItemGroup>

                  <Menu.ItemGroup title="Leads">
                    {/* <Menu.Item
                      key="leads-report"
                      onClick={onClick("/reports/leads-report")}
                    >
                      <span>Leads (Bar Chart)</span>
                    </Menu.Item>
                    <Menu.Item
                      key="leads-line-chart"
                      onClick={onClick("/reports/leads-line-chart")}
                    >
                      <span>Leads (Line Chart)</span>
                    </Menu.Item> */}

                    <Menu.Item
                      key="leads-by-origin"
                      onClick={onClick("/reports/leads-by-origin")}
                    >
                      <span>Leads By Origin</span>
                    </Menu.Item>
                    <Menu.Item
                      key="leads-by-campaign"
                      onClick={onClick("/reports/leads-by-campaign")}
                    >
                      <span>Leads By Campaign</span>
                    </Menu.Item>
                    {/* <Menu.Item
                      key="leads-count-cumulative"
                      onClick={onClick("/reports/leads-count-cumulative")}
                    >
                      <span>Leads Count Cumulative</span>
                    </Menu.Item> */}
                    {/* <Menu.Item
                    key="lead-conversion-rate"
                    onClick={onClick("/reports/lead-conversion-rate")}
                  >
                    <span>Lead Conversion Rate</span>
                  </Menu.Item>
                  <Menu.Item
                    key="lead-conversion-rate-by-origin"
                    onClick={onClick("/reports/lead-conversion-rate-by-origin")}
                  >
                    <span>Lead Conversion Rate By Origin</span>
                  </Menu.Item> */}
                    {/* <Menu.Item
                      key="leads-vs-estimates-vs-sales"
                      onClick={onClick("/reports/leads-vs-estimates-vs-sales")}
                    >
                      <span>Leads vs Estimates vs Sales</span>
                    </Menu.Item> */}
                  </Menu.ItemGroup>

                  <Menu.ItemGroup title="Sales">
                    <Menu.Item key="sold" onClick={onClick("/reports/sold")}>
                      <span>Sold (Bar Chart)</span>
                    </Menu.Item>
                    {/* <Menu.Item
                      key="sold-line-chart"
                      onClick={onClick("/reports/sold-line-chart")}
                    >
                      <span>Sold (Line Chart)</span>
                    </Menu.Item>
                    <Menu.Item
                      key="sold-by-property-type"
                      onClick={onClick("/reports/sold-by-property-type")}
                    >
                      <span>Sold By Property Type</span>
                    </Menu.Item>
                    <Menu.Item
                      key="sales-by-location"
                      onClick={onClick("/reports/sales-by-location")}
                    >
                      <span>Sales By Location</span>
                    </Menu.Item> */}

                    <Menu.Item
                      key="sales-by-campaign"
                      onClick={onClick("/reports/sales-by-campaign")}
                    >
                      <span>Sales By Campaign</span>
                    </Menu.Item>
                    {/* <Menu.Item
                      key="sales-count"
                      onClick={onClick("/reports/sales-count")}
                    >
                      <span>Sales Count</span>
                    </Menu.Item> */}
                    {/* <Menu.Item
                      key="sales-count-cumulative"
                      onClick={onClick("/reports/sales-count-cumulative")}
                    >
                      <span>Sales Count Cumulative</span>
                    </Menu.Item> */}
                    {/* <Menu.Item
                      key="sales-value-cumulative"
                      onClick={onClick("/reports/sales-value-cumulative")}
                    >
                      <span>Sales Value Cumulative</span>
                    </Menu.Item> */}
                    <Menu.Item
                      key="sold-by-scope"
                      onClick={onClick("/reports/sold-by-scope")}
                    >
                      <span>Sold By Scope</span>
                    </Menu.Item>
                  </Menu.ItemGroup>

                  <Menu.ItemGroup title="Account Receivables">
                    <Menu.Item
                      key="account-receivables-report"
                      onClick={onClick("/reports/account-receivables-report")}
                    >
                      <span>Account Receivables</span>
                    </Menu.Item>
                    {/* <Menu.Item
                      key="account-receivables-pending"
                      onClick={onClick("/reports/account-receivables-pending")}
                    >
                      <span>Account Receivables (Pending)</span>
                    </Menu.Item> */}

                    {/* <Menu.Item
                      key="account-receivables-average-vs-median"
                      onClick={onClick(
                        "/reports/account-receivables-average-vs-median"
                      )}
                    >
                      <span>AR Days to Collect (Average / Median)</span>
                    </Menu.Item> */}
                  </Menu.ItemGroup>

                  <Menu.ItemGroup title="Collections">
                    <Menu.Item
                      key="collections-report"
                      onClick={onClick("/reports/collections-report")}
                    >
                      <span>Collections</span>
                    </Menu.Item>
                    <Menu.Item
                      key="collections-cumulative"
                      onClick={onClick("/reports/collections-cumulative")}
                    >
                      <span>Collections Cumulative</span>
                    </Menu.Item>
                    {/* <Menu.Item
                      key="gross-collection-breakdown"
                      onClick={onClick("/reports/gross-collection-breakdown")}
                    >
                      <span>Gross Collection Breakdown</span>
                    </Menu.Item> */}
                  </Menu.ItemGroup>

                  <Menu.ItemGroup title="Profit">
                    {/* <Menu.Item
                      key="company-profit"
                      onClick={onClick("/reports/company-profit")}
                    >
                      <span>Company Profit</span>
                    </Menu.Item> */}
                    {/* <Menu.Item
                      key="company-profit-percentage"
                      onClick={onClick("/reports/company-profit-percentage")}
                    >
                      <span>Company Profit Percentage</span>
                    </Menu.Item> */}
                    <Menu.Item
                      key="profit"
                      onClick={onClick("/reports/profit")}
                    >
                      <span>Profit</span>
                    </Menu.Item>
                    <Menu.Item
                      key="profit-percentage"
                      onClick={onClick("/reports/profit-percentage")}
                    >
                      <span>Profit Percentage</span>
                    </Menu.Item>
                    <Menu.Item
                      key="profit-percentage-by-origin"
                      onClick={onClick("/reports/profit-percentage-by-origin")}
                    >
                      <span>Profit Percentage By Origin</span>
                    </Menu.Item>
                    {/* <Menu.Item
                      key="profit-deviation"
                      onClick={onClick("/reports/profit-deviation")}
                    >
                      <span>Profit Deviation</span>
                    </Menu.Item> */}
                  </Menu.ItemGroup>

                  <Menu.ItemGroup title="Products">
                    <Menu.Item
                      key="roofing-products"
                      onClick={onClick("/reports/roofing-products")}
                    >
                      <span>Roofing Products</span>
                    </Menu.Item>
                  </Menu.ItemGroup>

                  <Menu.ItemGroup title="Administrative">
                    {/* <Menu.Item
                      key="block-bonus"
                      onClick={onClick("/reports/block-bonus")}
                    >
                      <span>Block Bonus</span>
                    </Menu.Item> */}

                    {/* <Menu.Item
                      key="company-expenses-report"
                      onClick={onClick("/reports/company-expenses-report")}
                    >
                      <span>Company Expenses</span>
                    </Menu.Item> */}
                    {/* <Menu.Item
                      key="down-payment-surplus"
                      onClick={onClick("/reports/down-payment-surplus")}
                    >
                      <span>Down Payment Surplus</span>
                    </Menu.Item> */}
                    <Menu.Item
                      key="draw-balances"
                      onClick={onClick("/reports/draw-balances")}
                    >
                      <span>Draw Balances</span>
                    </Menu.Item>
                    <Menu.Item
                      key="loss-reasons"
                      onClick={onClick("/reports/loss-reasons")}
                    >
                      <span>Loss Reasons</span>
                    </Menu.Item>
                    <Menu.Item
                      key="pipeline"
                      onClick={onClick("/reports/pipeline")}
                    >
                      <span>Pipeline</span>
                    </Menu.Item>
                    {/* <Menu.Item
                      key="projections"
                      onClick={onClick("/reports/projections")}
                    >
                      <span>Projections</span>
                    </Menu.Item> */}
                    <Menu.Item
                      key="sold-to-work-order"
                      onClick={onClick("/reports/sold-to-work-order")}
                    >
                      <span>Sold to Work Order</span>
                    </Menu.Item>
                    <Menu.Item
                      key="completed-roofs"
                      onClick={onClick("/reports/completed-roofs")}
                    >
                      <span>Sold vs Completed Roofs</span>
                    </Menu.Item>
                    <Menu.Item
                      key="install-labor"
                      onClick={onClick("/reports/install-labor")}
                    >
                      <span>Install Labor</span>
                    </Menu.Item>
                  </Menu.ItemGroup>
                </>
              )}
            </SubMenu>
          )}

          {(admin === "Yes" || role === "Office") &&
            ((menuItems && menuItems.collections) ||
              (menuItems && menuItems.expenses) ||
              (menuItems && menuItems.dropdownManagement) ||
              (menuItems && menuItems.tieredDropdownManagement) ||
              (menuItems && menuItems.permits)) && (
              <SubMenu
                key="office"
                title={
                  <span>
                    <Business className="anticon" />
                    <span style={style}>Office</span>
                  </span>
                }
              >
                {((menuItems && menuItems.collections) || !menuItems) && (
                  <Menu.Item
                    key="collections"
                    onClick={onClick("/office/collections")}
                  >
                    <span>Collections</span>
                  </Menu.Item>
                )}

                {((menuItems && menuItems.expenses) || !menuItems) && (
                  <Menu.Item
                    key="expenses"
                    onClick={onClick("/office/expenses")}
                  >
                    <span>Expenses</span>
                  </Menu.Item>
                )}

                {/* TODO: add this to menuItems */}
                <Menu.Item
                  key="marketing"
                  onClick={onClick("/office/marketing")}
                >
                  <span>Marketing</span>
                </Menu.Item>

                {((menuItems && menuItems.permits) || !menuItems) && (
                  <Menu.Item key="permits" onClick={onClick("/office/permits")}>
                    <span>Permits</span>
                  </Menu.Item>
                )}

                <Menu.Item
                  key="lead-scheduling"
                  onClick={onClick("/office/lead-scheduling")}
                >
                  <span>Lead Scheduling</span>
                </Menu.Item>

                <Menu.Item
                  key="assigned-leads"
                  onClick={onClick("/office/assigned-leads")}
                >
                  <span>Assigned Leads last 7 Days</span>
                </Menu.Item>

                {((menuItems && menuItems.dropdownManagement) ||
                  !menuItems) && (
                  <Menu.Item
                    key="dropdown-management"
                    onClick={onClick("/office/dropdown-management")}
                  >
                    <span>Dropdown Management</span>
                  </Menu.Item>
                )}

                {((menuItems && menuItems.tieredDropdownManagement) ||
                  !menuItems) && (
                  <Menu.Item
                    key="tiered-dropdown-management"
                    onClick={onClick("/office/tiered-dropdown-management")}
                  >
                    <span>Tiered Dropdown Management</span>
                  </Menu.Item>
                )}
              </SubMenu>
            )}

          {(admin === "Yes" ||
            role === "Office" ||
            manager === "Yes" ||
            email === "jhomrich@gopremier.net") &&
            ((menuItems && menuItems.subManagement) ||
              (menuItems && menuItems.dumpsterManagement) ||
              (menuItems && menuItems.supplierManagement) ||
              (menuItems && menuItems.preJobInspections) ||
              (menuItems && menuItems.postJobInspections) ||
              (menuItems && menuItems.toDos)) && (
              <SubMenu
                key="install"
                title={
                  <span>
                    <HammerWrench className="anticon" />
                    <span style={style}>Install</span>
                  </span>
                }
              >
                {((menuItems && menuItems.subManagement) || !menuItems) && (
                  <Menu.Item
                    key="sub-management"
                    onClick={onClick("/install/sub-management")}
                  >
                    <span>Sub Management</span>
                  </Menu.Item>
                )}

                {((menuItems && menuItems.dumpsterManagement) ||
                  !menuItems) && (
                  <Menu.Item
                    key="dumpster-management"
                    onClick={onClick("/install/dumpster-management")}
                  >
                    <span>Dumpster Management</span>
                  </Menu.Item>
                )}

                {((menuItems && menuItems.supplierManagement) ||
                  !menuItems) && (
                  <Menu.Item
                    key="supplier-management"
                    onClick={onClick("/install/supplier-management")}
                  >
                    <span>Supplier Management</span>
                  </Menu.Item>
                )}

                {((menuItems && menuItems.manageDumpsters) || !menuItems) && (
                  <Menu.Item
                    key="manage-dumpsters"
                    onClick={onClick("/install/manage-dumpsters")}
                  >
                    <span>Manage Dumpsters</span>
                  </Menu.Item>
                )}

                {((menuItems && menuItems.preJobInspections) || !menuItems) && (
                  <Menu.Item
                    key="pre-job-inspections"
                    onClick={onClick("/install/pre-job-inspections")}
                  >
                    <span>Pre-Job Inpsections</span>
                  </Menu.Item>
                )}

                {/* TODO: add this to menuItems */}
                <Menu.Item
                  key="complete-pre-job-inspections"
                  onClick={openPreInspectionCheck}
                >
                  <span>Complete Pre-Job Inpsection</span>
                </Menu.Item>

                {((menuItems && menuItems.postJobInspections) ||
                  !menuItems) && (
                  <Menu.Item
                    key="post-job-inspections"
                    onClick={onClick("/install/post-job-inspections")}
                  >
                    <span>Post-Job Inpsections</span>
                  </Menu.Item>
                )}

                {((menuItems && menuItems.toDos) || !menuItems) && (
                  <Menu.Item key="todos" onClick={onClick("/install/todos")}>
                    <span>To-dos</span>
                  </Menu.Item>
                )}
              </SubMenu>
            )}

          {admin === "Yes" &&
            ((menuItems && menuItems.users) ||
              (menuItems && menuItems.needsBrokedown) ||
              (menuItems && menuItems.drawActivities) ||
              (menuItems && menuItems.companyExpenses) ||
              (menuItems && menuItems.version)) && (
              <SubMenu
                key="admin"
                title={
                  <span>
                    <AccountCog className="anticon" />
                    <span style={style}>Admin</span>
                  </span>
                }
              >
                {/* <Menu.Item key="export-wip" onClick={exportWIP}>
                  <span>Export WIP</span>
                </Menu.Item> */}

                {/* <Menu.Item key="tuesdays-meeting" onClick={tuesdaysMeeting}>
                  <span>Tuesdays Meeting</span>
                </Menu.Item>

                <Menu.Item key="sold-and-hold" onClick={soldAndHold}>
                  <span>Sold And Hold</span>
                </Menu.Item> */}

                {/* <Menu.Item
                  key="update-activities-and-scopes"
                  onClick={updateActivitiesAndScopes}
                >
                  <span>Update Activites And Scopes</span>
                </Menu.Item> */}

                {/* <Menu.Item key="update-expenses" onClick={updateExpenses}>
                  <span>Update Expenses</span>
                </Menu.Item> */}

                {/* <Menu.Item
                  key="update-project-scopes"
                  onClick={updateProjectsScopes}
                >
                  <span>Update Project Scopes</span>
                </Menu.Item> */}

                {/* <Menu.Item key="report-a" onClick={generateReportA}>
                  <span>Download Report A</span>
                </Menu.Item>

                <Menu.Item key="report-b" onClick={generateReportB}>
                  <span>Download Report B</span>
                </Menu.Item>

                <Menu.Item key="report-c" onClick={generateReportC}>
                  <span>Download Report C</span>
                </Menu.Item>

                <Menu.Item key="report-d" onClick={generateReportD}>
                  <span>Download Report D</span>
                </Menu.Item>

                <Menu.Item key="report-e" onClick={generateReportE}>
                  <span>Download Report E</span>
                </Menu.Item> */}

                {/* <Menu.Item key="report-f" onClick={generateReportF}>
                  <span>Download Report F</span>
                </Menu.Item>

                <Menu.Item key="report-g" onClick={generateReportG}>
                  <span>Download Report G</span>
                </Menu.Item>

                <Menu.Item key="report-h" onClick={generateReportH}>
                  <span>Download Report H</span>
                </Menu.Item> */}

                {/* <Menu.Item key="hover-test-job" onClick={createTestHoverJob}>
                  <span>Create Hover Test Job (CH only)</span>
                </Menu.Item> */}

                {/* <Menu.Item key="hover-webhook" onClick={createHoverWebhook}>
                  <span>Create Hover Webhook (CH only)</span>
                </Menu.Item> */}

                <Menu.Item key="send-email" onClick={sendEmail}>
                  <span>Send Email (CH only)</span>
                </Menu.Item>

                <Menu.Item
                  key="send-weekly-reports"
                  onClick={sendWeeklyReports}
                >
                  <span>Send Weekly Reports (CH only)</span>
                </Menu.Item>

                {/* <Menu.Item key="zoho-leads" onClick={sendLeadsExport}>
                  <span>Send Leads Export (CH only)</span>
                </Menu.Item> */}

                {/* <Menu.Item key="zoho-projects" onClick={sendProjectsExport}>
                  <span>Send Projects Export (CH only)</span>
                </Menu.Item> */}

                {/* <Menu.Item key="zoho-expenses" onClick={sendExpensesExport}>
                  <span>Send Expenses Export (CH only)</span>
                </Menu.Item> */}

                {/* <Menu.Item
                  key="zoho-collections"
                  onClick={sendCollectionsExport}
                >
                  <span>Send Collections Export (CH only)</span>
                </Menu.Item> */}

                {/* <Menu.Item key="invoices-data" onClick={sendInvoicesData}>
                  <span>Send Invoices Data</span>
                </Menu.Item> */}

                {/* <Menu.Item key="marketing-data" onClick={sendMarketingData}>
                  <span>Send Marketing Data (CH only)</span>
                </Menu.Item> */}

                {/* <Menu.Item key="production-data" onClick={sendProductionData}>
                  <span>Send Production Data (CH only)</span>
                </Menu.Item> */}

                {/* <Menu.Item key="backlog-data" onClick={sendBacklogData}>
                  <span>Send Backlog Data (CH only)</span>
                </Menu.Item> */}

                <Menu.Item key="pm-bonus-list" onClick={sendPmBonusList}>
                  <span>PM Bonus List (CH only)</span>
                </Menu.Item>

                <Menu.Item
                  key="zoho-exports"
                  onClick={onClick("/admin/zoho-exports")}
                >
                  <span>All Zoho Exports</span>
                </Menu.Item>

                {((menuItems && menuItems.users) || !menuItems) &&
                  email !== "mkulhanek@gopremier.net" && (
                    <Menu.Item key="users" onClick={onClick("/admin/users")}>
                      <span>Users</span>
                    </Menu.Item>
                  )}

                {((menuItems && menuItems.needsBrokedown) || !menuItems) && (
                  <Menu.Item
                    key="needs-brokedown"
                    onClick={onClick("/admin/needs-brokedown")}
                  >
                    <span>Needs Brokedown</span>
                  </Menu.Item>
                )}

                {((menuItems && menuItems.drawActivities) || !menuItems) && (
                  <Menu.Item
                    key="draw-activities"
                    onClick={onClick("/admin/draw-activities")}
                  >
                    <span>Draw Activities</span>
                  </Menu.Item>
                )}

                {/* TODO: add this to menuItems */}
                <Menu.Item
                  key="marketing-activities"
                  onClick={onClick("/admin/marketing-activities")}
                >
                  <span>Marketing Activities</span>
                </Menu.Item>

                {((menuItems && menuItems.companyExpenses) || !menuItems) && (
                  <Menu.Item
                    key="company-expenses"
                    onClick={onClick("/admin/company-expenses")}
                  >
                    <span>Company Expenses</span>
                  </Menu.Item>
                )}

                <Menu.Item
                  key="write-company-post"
                  onClick={openWriteCompanyPost}
                >
                  <span>Write Company Post</span>
                </Menu.Item>

                {((menuItems && menuItems.version) || !menuItems) && (
                  <Menu.Item key="version" onClick={onClick("/admin/version")}>
                    <span>Version</span>
                  </Menu.Item>
                )}
              </SubMenu>
            )}

          <Menu.Item key="requests" onClick={openRequests}>
            <SendIcon className="anticon" />
            <span style={style}>Requests</span>
          </Menu.Item>

          <Menu.Item key="phone-book" onClick={onClick("/phone-book")}>
            <Phone className="anticon" />
            <span style={style}>Contacts & Links</span>
          </Menu.Item>

          <SubMenu
            key="settings"
            title={
              <span>
                <Settings className="anticon" />
                <span style={style}>Settings</span>
              </span>
            }
          >
            <Menu.Item
              key="menu-management"
              onClick={onClick("/settings/menu-management")}
            >
              <span style={style}>Menu Management</span>
            </Menu.Item>
            {/* <Menu.Item key="bug-report" onClick={openBugReport}>
              <span style={style}>Bug Report</span>
            </Menu.Item> */}
            <Menu.Item
              key="change-password"
              onClick={onClick("/settings/change-password")}
            >
              <span style={style}>Change Password</span>
            </Menu.Item>

            <Menu.Item key="signout" onClick={handleSignout}>
              <span style={style}>Signout</span>
            </Menu.Item>
          </SubMenu>
        </Menu>
      </div>
    </>
  );
};

const style = { display: "inline-block", verticalAlign: "middle" };

export default memo(Navigation);
