import React, { useState, useEffect } from "react";
import Form from "antd/es/form";
import message from "antd/es/message";
import api from "API";
import ProjectManager from "Components/Inputs/ProjectManager";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";
import Currency from "Components/Inputs/Currency";

const IAC = () => {
  const [mentions, setMentions] = useState([]);

  useEffect(() => {
    fetchMentions();
  }, []);

  const fetchMentions = async () => {
    try {
      const res = await api.get("/mentions");
      let _mentions = res.data;
      setMentions(_mentions);
    } catch (err) {
      console.log("err", err);
      message.error("Error occured retrieving staff list");
    }
  };

  return (
    <>
      <Form.Item
        label="Customer ID"
        name="customerId"
        rules={[
          {
            required: true,
            message:
              "You must provide the customer Id this is associated with!",
          },
        ]}
      >
        <InputNoAutoComplete id="customerId" />
      </Form.Item>

      <Form.Item
        label="Reason"
        name="reason"
        rules={[
          {
            required: true,
            message:
              "You must provide the customer Id this is associated with!",
          },
        ]}
      >
        <InputNoAutoComplete id="reason" />
      </Form.Item>

      <Form.Item
        label="Amount Requested"
        name="amount"
        rules={[
          {
            required: true,
            message: `Please provide an amount`,
          },
        ]}
      >
        <Currency id="amount" />
      </Form.Item>

      <Form.Item
        label="Project Owner"
        name="projectManager"
        rules={[{ required: true, message: "Please select the Project Owner" }]}
      >
        <ProjectManager sales={mentions} />
      </Form.Item>
    </>
  );
};

export default IAC;
