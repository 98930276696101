import React, { useState, useCallback, useEffect } from "react";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Notifications from "Components/Notifications";
import ViewLead from "Screens/ViewLead";
import ViewProject from "Screens/ViewProject";
import ViewContainer from "Components/ViewContainer";
import useViewShow from "Hooks/useViewShow";
import useIsMobile from "Hooks/useIsMobile";
import Grid from "antd/es/grid";
import NotificationSwitch from "Components/Notifications/NotificationSwitch";
import NotificationPermission from "Components/NotificationPermission";
import { markAllRead, replaceNotifications } from "Actions/authActions";
import Button from "antd/es/button";
import { useSelector, useDispatch } from "react-redux";
import EmailNotificationSwitch from "Components/Notifications/EmailNotificationSwitch";

const { useBreakpoint } = Grid;

const NotificationsScreen = () => {
  const dispatch = useDispatch();
  const mobile = useIsMobile();
  const screens = useBreakpoint();
  const showProject = useViewShow(2, "projects", 3, "view");
  const showLead = useViewShow(2, "leads", 3, "view");
  const [notificationsOn, setNotificationsOn] = useState(
    localStorage.getItem("notifications") === "true" ? true : false
  );

  const notifications = useSelector((state) => state.authState.notifications);
  const [notificationVisible, setNotificationVisible] = useState(false);
  // const [notificationsPrompted, setNotificationsPrompted] = useState(false); // eslint-disable-line

  useEffect(() => {
    dispatch(replaceNotifications());
  }, [dispatch]);

  const callback = (bool) => {
    if (bool) {
      setNotificationsOn(true);
    } else {
      setNotificationsOn(false);
    }
  };

  const markAllNotificationsRead = useCallback(() => {
    dispatch(markAllRead({ notifications }));
  }, [dispatch, notifications]);

  return (
    <div
      className={`content-inner ${!mobile ? "p24" : ""}`}
      id="notifications-container"
    >
      <Row justify="center">
        <Col xs={24} sm={20} md={16} lg={12}>
          {"showNotification" in ServiceWorkerRegistration.prototype &&
            "PushManager" in window && (
              <NotificationSwitch
                setNotificationVisible={setNotificationVisible}
                setNotificationsOn={setNotificationsOn}
                notificationsOn={notificationsOn}
              />
            )}

          {"showNotification" in ServiceWorkerRegistration.prototype &&
            "PushManager" in window && <EmailNotificationSwitch />}

          <Row justify="center">
            <Col xs={16}>
              <Button
                type="primary"
                className="green"
                block
                onClick={markAllNotificationsRead}
                style={{ marginBottom: 24, marginTop: 24 }}
              >
                Mark All Read
              </Button>
            </Col>
          </Row>

          <Notifications />
        </Col>
      </Row>

      <ViewContainer
        type="drawer"
        visible={showLead}
        form={ViewLead}
        placement="right"
        width={!screens.sm ? "100%" : 400}
        closable={false}
        mask={false}
      />

      <ViewContainer
        type="drawer"
        visible={showProject}
        form={ViewProject}
        placement="right"
        width={!screens.sm ? "100%" : 400}
        closable={false}
        mask={false}
      />

      <NotificationPermission
        visible={notificationVisible}
        setVisible={setNotificationVisible}
        // setNotificationsPrompted={setNotificationsPrompted}
        callback={callback}
      />
    </div>
  );
};

export default NotificationsScreen;
