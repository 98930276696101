Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends =
  Object.assign ||
  function(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

function _objectWithoutProperties(obj, keys) {
  var target = {};
  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }
  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }
  return obj;
}

var defaultStyles = _defineProperty(
  {
    userSelect: "none",
    width: "100%",
    fill: "currentColor",
    height: "1em",
    verticalAlign: "middle"
  },
  "width",
  "1em"
);

function Svg(props) {
  var children = props.children,
    className = props.className,
    color = props.color,
    size = props.size,
    styles = props.styles,
    viewBox = props.viewBox,
    rest = _objectWithoutProperties(props, [
      "children",
      "className",
      "color",
      "size",
      "styles",
      "viewBox"
    ]);

  var vb = viewBox || "0 0 24 24";
  return _react2.default.createElement(
    "svg",
    _extends(
      {
        className: className,
        focusable: "false",
        style: _extends(
          {},
          defaultStyles,
          { color: color, fontSize: size + "px" },
          styles
        ),
        viewBox: vb
      },
      rest
    ),
    children
  );
}

Svg.propTypes = {
  children: _propTypes2.default.node,
  color: _propTypes2.default.string,
  className: _propTypes2.default.string,
  size: _propTypes2.default.number,
  styles: _propTypes2.default.object,
  viewBox: _propTypes2.default.string
};

Svg.defaultProps = {
  color: "inherit",
  size: 24
};

exports.default = Svg;
