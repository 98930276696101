import React from "react";
import Button from "antd/es/button";
import Typography from "antd/es/typography";
import awSnap from "Assets/aw_snap.png";
import updateAvailable from "Assets/update_available.png";
import styles from "./styles.module.scss";

const chunkFailedMessage = /Loading chunk [\d]+ failed/;

export default function ErrorFallback({ error, resetError }) {
  const onClick = () => {
    if (resetError) resetError();
    window.location.reload();
  };

  return (
    <div id="error-container" className={`content-inner ${styles.container}`}>
      {error && error.message && chunkFailedMessage.test(error.message) ? (
        <>
          <div>
            <img src={updateAvailable} alt="update-available" height="140" />
          </div>
          <Typography.Title>A new update is availalbe!</Typography.Title>
          <Typography.Text>
            Required updates need to be installed. Click the button to update
            now.
          </Typography.Text>
          <div style={{ marginTop: 16 }}>
            <Button type="primary" onClick={onClick}>
              Update Now
            </Button>
          </div>
        </>
      ) : (
        <>
          <div>
            <img src={awSnap} alt="aw-snap" height="140" />
          </div>
          <Typography.Title>Aw, Snap!</Typography.Title>
          <Typography.Text>
            Something went wrong while displaying this page. Please reload the
            page and try again. If this continues notify Craig of the issue.
          </Typography.Text>
          <div style={{ marginTop: 16 }}>
            <Button type="primary" onClick={onClick}>
              Reload
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
