import React, { useState } from "react";
import api from "API";
import Select from "antd/es/select";
import Form from "antd/es/form";
import message from "antd/es/message";
import Button from "antd/es/button";
import Divider from "antd/es/divider";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import Apparel from "./Apparel";
import Collateral from "./Collateral";
import Draw from "./Draw";
import IAC from "./IAC";
import Leave from "./Leave";
import Referral from "./Referral";
import Swarm from "./Swarm";
import TruckMaintenance from "./TruckMaintenance";
import dayjs from "dayjs";
import useIsMobile from "Hooks/useIsMobile";
import CancelButton from "Components/CancelButton";
import useSalesmen from "Hooks/useSalesmen";

const messageKey = "messageKey";

const Requests = ({ unsavedChanges, setUnsavedChanges, handleClose, type }) => {
  const [form] = Form.useForm();
  const mobile = useIsMobile();
  const [loading, setLoading] = useState(false);
  const [_type, _setType] = useState(undefined);
  const sales = useSalesmen();

  const onFinish = async (values) => {
    try {
      setLoading(true);
      // message.loading(`Submitting request...`, 0);
      message.loading({
        content: "Submitting request...",
        duration: 0,
        key: messageKey,
      });
      if (values.type === "Leave") {
        values.firstDay = new Date(dayjs(values.firstDay).startOf("d"));
        values.lastDay = new Date(dayjs(values.lastDay).endOf("d"));
      }

      if (values.type === "IAC") {
        values.projectManager = sales[values.projectManager];
      }
      await api.post(`/requests/create`, values);
      setLoading(false);
      // message.success(`${_type} request submitted`);
      message.success({
        content: `${_type} request submitted`,
        duration: 1.5,
        key: messageKey,
      });
      handleClose(true);
    } catch (err) {
      setLoading(false);
      // message.error("Error submitting request");
      message.error({
        content: "Error submitting request",
        duration: 1.5,
        key: messageKey,
      });
      console.log("err", err);
    }
  };

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error submitting request");
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  const onChange = (e) => {
    _setType(e);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      className="form"
    >
      <FormBody
        className="content-inner"
        style={{
          paddingLeft: 24,
          paddingTop: 24,
          paddingRight: 24,
          paddingBottom: 8,
          maxHeight: type === "modal" ? "calc(100vh - 161px)" : "unset",
        }}
      >
        <RemoveAutocomplete />
        <Form.Item
          name="type"
          label="Type of Request"
          rules={[
            {
              required: true,
              message: "Please select a Type",
            },
          ]}
        >
          <Select
            onChange={onChange}
            autoFocus
            getPopupContainer={(trigger) =>
              !mobile
                ? trigger.parentNode.parentNode.parentNode.parentNode.parentNode
                    .parentNode.parentNode
                : document.body
            }
            showSearch={!mobile}
            dropdownClassName={mobile ? "isMobile" : null}
          >
            <Select.Option key="Apparel">Apparel</Select.Option>
            <Select.Option key="Collateral">Collateral</Select.Option>
            <Select.Option key="Draw">Draw</Select.Option>
            <Select.Option key="IAC">Invoice Against Commission</Select.Option>
            <Select.Option key="Leave">Leave</Select.Option>
            <Select.Option key="Referral Gift Card">
              Referral Gift Card
            </Select.Option>
            <Select.Option key="Swarm">Swarm</Select.Option>
            <Select.Option key="Truck Maintenance">
              Truck Maintenance
            </Select.Option>
          </Select>
        </Form.Item>

        {_type !== undefined && <Divider />}

        {_type === "Apparel" && <Apparel />}

        {_type === "Collateral" && <Collateral />}

        {_type === "Draw" && <Draw />}

        {_type === "IAC" && <IAC />}

        {_type === "Leave" && <Leave form={form} />}

        {_type === "Referral Gift Card" && <Referral />}

        {_type === "Swarm" && <Swarm form={form} />}

        {_type === "Truck Maintenance" && <TruckMaintenance />}
      </FormBody>
      <FormControls className="sticky-footer">
        <CancelButton handleClick={handleClose} />
        <Form.Item style={{ margin: 0 }}>
          <Button
            htmlType="submit"
            type="primary"
            loading={loading}
            disabled={loading || !unsavedChanges}
          >
            Submit
          </Button>
        </Form.Item>
      </FormControls>
    </Form>
  );
};

export default Requests;
