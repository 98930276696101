import React, { useState, useEffect, memo } from "react";
import { unstable_batchedUpdates } from "react-dom";
import Input from "antd/es/input";
import Form from "antd/es/form";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Typography from "antd/es/typography";
import api from "API";
import Divider from "antd/es/divider";
import Select from "Components/Inputs/Select";
import useIsMobile from "Hooks/useIsMobile";
import DatePicker from "Components/Inputs/DatePicker";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";
import InputNumberNoAutoComplete from "Components/Inputs/InputNumberNoAutoComplete";
import Cascader from "antd/es/cascader";
import Button from "antd/es/button";
import Suppliers from "Components/Inputs/Suppliers";
import useSuppliers from "Hooks/useSuppliers";

const SidingDetails = ({ form, propertyType, toggleLaborOnly, laborOnly }) => {
  const mobile = useIsMobile();
  const suppliers = useSuppliers();
  const [siding, setSiding] = useState([]);
  const [pitches, setPitches] = useState([]);

  useEffect(() => {
    fetchOptions();
  }, []);

  const fetchOptions = async () => {
    try {
      const res = await api.post("/dropdowns/get", {
        selects: ["Pitches", "Siding"],
      });
      unstable_batchedUpdates(() => {
        setSiding(res.data.Siding.options);
        setPitches(res.data.Pitches.options);
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleToggleLaborOnly = () => {
    toggleLaborOnly();
  };

  const handleSidingChange = async (value) => {
    try {
      const lineItems = await form.getFieldValue("lineItems");
      if (lineItems) {
        if (value.length > 0) {
          lineItems[0].type = `${value[0]} ${value[1]}`;
          lineItems[0].color = value[2];
          form.setFieldsValue({
            lineItems,
          });
        } else {
          lineItems[0].type = "";
          lineItems[0].color = "";
          form.setFieldsValue({
            lineItems,
          });
        }
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleSquaresBlur = async (e) => {
    try {
      const lineItems = await form.getFieldValue("lineItems");
      if (lineItems) {
        lineItems[0].quantity = `${e.target.value} Squares`;
        form.setFieldsValue({
          lineItems,
        });
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleSupplierChange = async (value) => {
    try {
      if (value) {
        let newValue = [suppliers[value].email];
        form.setFieldsValue({
          supplierEmail: suppliers[value].email,
        });
        let toValue = await form.getFieldValue("to");
        if (toValue) {
          for (const t of toValue) {
            newValue.push(t);
          }
        }
        form.setFieldsValue({
          to: newValue,
        });
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <>
      <Typography.Title level={4}>Project Details</Typography.Title>
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="pitches"
            label="Pitches"
            rules={[{ required: true, message: "Pitches is required" }]}
          >
            <Select mode="multiple" options={pitches} id="pitches" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="stories"
            label="Stories"
            rules={[{ required: true, message: "Stories is required" }]}
          >
            <InputNoAutoComplete id="stories" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="workToBeDone"
            label="Work To Be Done"
            rules={[{ required: true, message: "Work To Be Done is required" }]}
          >
            <Select
              mode="multiple"
              options={["Facia", "Siding", "Soffit", "Trim"]}
              id="workToBeDone"
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="maxPeakHeight"
            label="Max Peak Height"
            rules={[{ required: true, message: "Max Peak Height is required" }]}
          >
            <InputNoAutoComplete id="maxPeakHeight" />
          </Form.Item>
        </Col>
      </Row>

      {propertyType !== "New Construction" && (
        <>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="dumpsterNeeded"
                label="Dumpster Needed"
                rules={[
                  { required: true, message: "Dumpster Needed is required" },
                ]}
              >
                <Select options={["Yes", "No"]} id="dumpsterNeeded" />
              </Form.Item>
            </Col>
            {propertyType !== "New Construction" && (
              <Col xs={24} sm={12}>
                <Form.Item
                  name="tearOff"
                  label="Tear Off"
                  rules={[{ required: true, message: "Tear Off is required" }]}
                >
                  <Select options={["Yes", "No"]} id="tearOff" />
                </Form.Item>
              </Col>
            )}
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="existingSiding"
                label="Existing Siding"
                rules={[
                  { required: true, message: "Existing Siding is required" },
                ]}
              >
                <Select
                  mode="multiple"
                  options={[
                    "Aluminum",
                    "Cement Board",
                    "Stucco",
                    "Vinyl",
                    "Wood Painted",
                    "Wood Stained",
                    "Other",
                    "N/A",
                  ]}
                  id="existingSiding"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="buildDate"
                label="Build Date"
                rules={[{ required: true, message: "Build Date is required" }]}
              >
                <DatePicker
                  format="MMMM DD, YYYY"
                  getPopupContainer={(trigger) =>
                    !mobile
                      ? trigger.parentNode.parentNode.parentNode.parentNode
                      : document.body
                  }
                  getCalendarContainer={(trigger) =>
                    !mobile
                      ? trigger.parentNode.parentNode.parentNode.parentNode
                      : document.body
                  }
                  inputReadOnly={true}
                  autoComplete="dont-use-autocomplete"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="vaporBarrier"
                label="Vapor Barrier"
                rules={[
                  { required: true, message: "Vapor Barrier is required" },
                ]}
              >
                <Select
                  options={["Fanfold", "House Wrap", "Other", "N/A"]}
                  id="vaporBarrier"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="existingTrimDetails"
                label="Existing Trim Details"
                rules={[
                  {
                    required: true,
                    message: "Existing Trim Details is required",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  options={[
                    "Aluminum Wrap",
                    "Composite",
                    "Vinyl",
                    "Wood",
                    "Other",
                    "N/A",
                  ]}
                  id="existingTrimDetails"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="dryerVentLocation"
                label="Dryer Vent Location"
                rules={[
                  {
                    required: true,
                    message: "Dryer Vent Location is required",
                  },
                ]}
              >
                <InputNoAutoComplete id="dryerVentLocation" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="supplier"
                label="Supplier"
                rules={[
                  {
                    required: laborOnly ? false : true,
                    message: "Supplier is required",
                  },
                ]}
              >
                <Suppliers
                  suppliers={suppliers}
                  onChange={handleSupplierChange}
                />
              </Form.Item>

              <Form.Item name="supplierEmail" hidden>
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}

      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="projectDescription"
            label="Project Description of Work"
            rules={[
              {
                required: true,
                message: "Project Description of Work is required",
              },
            ]}
          >
            <Input.TextArea autoComplete="dont-use-autocomplete" rows={4} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="scopeDescription"
            label="Scope Description of Work"
            rules={[
              {
                required: true,
                message: "Scope Description of Work is required",
              },
            ]}
          >
            <Input.TextArea autoComplete="dont-use-autocomplete" rows={4} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24}>
          <Button
            onClick={handleToggleLaborOnly}
            type="primary"
            danger={laborOnly ? false : true}
            block
            style={{ marginTop: 12 }}
          >
            {laborOnly
              ? "Change to Labor and Material"
              : "Change to Labor Only"}
          </Button>
        </Col>
      </Row>

      {!laborOnly && (
        <>
          <Divider />
          <Typography.Title level={4}>Product Details</Typography.Title>

          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="siding"
                label="Siding"
                rules={[{ required: true, message: "Siding is required" }]}
              >
                <Cascader
                  showSearch
                  options={siding}
                  getPopupContainer={(trigger) =>
                    trigger.parentNode.parentNode.parentNode.parentNode
                      .parentNode
                  }
                  onChange={handleSidingChange}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="squares"
                label="Squares"
                rules={[{ required: true, message: "Squares is required" }]}
              >
                <InputNumberNoAutoComplete
                  id="squares"
                  handleBlur={handleSquaresBlur}
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider />
        </>
      )}
    </>
  );
};

export default memo(SidingDetails);
