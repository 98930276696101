import React, { useState, useCallback } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateDetail } from "Actions/dataActions";
import Form from "antd/es/form";
import Button from "antd/es/button";
import message from "antd/es/message";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import api from "API";
import InputCampaigns from "Components/Inputs/Campaigns";

const Campaigns = ({ value, _id }) => {
  const dispatch = useDispatch();
  const viewableActivities = useSelector(
    (state) => state.dataState.viewableActivities
  );
  const manager = useSelector((state) => state.authState.manager);
  const admin = useSelector((state) => state.authState.admin);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const onFinish = useCallback(
    async (values) => {
      try {
        setLoading(true);
        values._id = _id;
        values.previous = value;

        if (values.campaign !== value) {
          const res = await api.post("/update/campaigns", values);

          dispatch(
            updateDetail({
              viewableActivities,
              key: "campaigns",
              value: res.data.value,
              activities: res.data.activities,
            })
          );

          message.success("Campaigns updated");
        }
        unstable_batchedUpdates(() => {
          setLoading(false);
          setEdit(false);
        });
      } catch (err) {
        console.log("err", err);
        message.error("Error updating Campaigns");
        setLoading(false);
      }
    },
    [_id, value, dispatch, viewableActivities]
  );

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error updating Campaigns");
  };

  const toggleEdit = () => {
    setEdit(!edit);
  };

  return (
    <Form
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      initialValues={{ campaign: value }}
      style={{ marginBottom: 16 }}
    >
      <RemoveAutocomplete />
      <h4 style={{ marginBottom: 3 }}>Campaign</h4>
      {!edit && (
        <p
          style={{ marginBottom: 0 }}
          className={
            manager === "Yes" || admin === "Yes" ? "editableRow" : undefined
          }
          onClick={manager === "Yes" || admin === "Yes" ? toggleEdit : null}
        >
          <span>{value ? value : "Click to Edit"}</span>
        </p>
      )}

      {edit && (
        <>
          <Form.Item
            name="campaign"
            rules={[
              {
                required: true,
                message: "Select a Campaign",
              },
            ]}
          >
            <InputCampaigns
              autoFocus
              defaultOpen={true}
              placeholder="Campaign"
            />
          </Form.Item>

          <div className="editControls">
            <Button
              type="default"
              size="small"
              onClick={toggleEdit}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              size="small"
              loading={loading}
              disabled={loading}
            >
              Submit
            </Button>
          </div>
        </>
      )}
    </Form>
  );
};

export default Campaigns;
