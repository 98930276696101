import React, { useState, useEffect, useCallback } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { openForm } from "Actions/formActions";
import List from "antd/es/list";
import Button from "antd/es/button";
import Input from "antd/es/input";
import message from "antd/es/message";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import api from "API";
import styles from "./styles.module.scss";
import SearchOutlined from "@ant-design/icons/SearchOutlined";
import PlusOutlined from "@ant-design/icons/PlusOutlined";
import Edit from "Icons/Edit";
import Call from "Icons/Call";
import Textsms from "Icons/Textsms";
import OpenInBrowser from "Icons/OpenInBrowser";
import ContentCopy from "Icons/ContentCopy";
import Clipboard from "react-clipboard.js";
import Loader from "Components/Loader";
import removePhoneSymbols from "Utils/removePhoneSymbols";
import phoneMask from "Utils/phoneMask";

const PhoneBook = () => {
  const manager = useSelector((state) => state.authState.manager);
  const admin = useSelector((state) => state.authState.admin);
  const dispatch = useDispatch();
  const [originPhonebook, setOriginPhonebook] = useState([]);
  const [phonebook, setPhonebook] = useState([]);
  const [filter, setFilter] = useState("");
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    fetchContacts();
    return () => {
      console.log("PHONEBOOK UNMOUNTED");
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const _filteredPhonebook = originPhonebook.filter((o) =>
      o.name.toLowerCase().includes(filter.toLowerCase())
    );
    setPhonebook(_filteredPhonebook);
  }, [originPhonebook, filter]);

  const fetchContacts = async () => {
    try {
      const res = await api.get("/phone-book");
      unstable_batchedUpdates(() => {
        setPhonebook(res.data);
        setOriginPhonebook(res.data);
        setFetching(false);
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const openSMS = (phone) => () => {
    window.open(`sms:${phone}`, "_self");
    return false;
  };

  const edit = useCallback(
    (obj) => () => {
      dispatch(openForm("phoneBook", { editing: obj, setOriginPhonebook }));
    },
    [dispatch, setOriginPhonebook]
  );

  const copied = (e) => {
    const { clipboardTitle } = e.trigger.dataset;
    message.success(`${clipboardTitle} copied to clipboard`);
  };

  const openPhonebook = useCallback(() => {
    dispatch(openForm("phoneBook", { setOriginPhonebook }));
  }, [dispatch, setOriginPhonebook]);

  let type = "";

  return (
    <div className={styles.body} style={{ overflow: "hidden" }}>
      {fetching ? (
        <Loader minHeight="calc(100vh - 153px)" />
      ) : (
        <>
          <FormControls style={{ background: "#f0f0f0" }}>
            <Input
              style={
                manager === "Yes" || admin === "Yes"
                  ? { width: "calc(100% - 109px)" }
                  : {}
              }
              prefix={<SearchOutlined />}
              placeholder="Search Phone Book"
              onChange={(e) => setFilter(e.target.value)}
              type="search"
              autoComplete="dont-use-autocomplete"
            />
            {manager === "Yes" || admin === "Yes" ? (
              <Button
                style={{ marginLeft: 12 }}
                type="primary"
                onClick={openPhonebook}
              >
                <PlusOutlined /> Create
              </Button>
            ) : null}
          </FormControls>
          <FormBody className="phonebook-view">
            <div className="content-inner">
              <List className={styles.list}>
                {phonebook.length > 0 && (
                  <>
                    {phonebook.map((p, i) => {
                      let typeChange = type !== p.type;
                      type = p.type;
                      return (
                        <div key={p._id}>
                          {typeChange && (
                            <List.Item className="list-header">
                              <List.Item.Meta title={p.type} />
                            </List.Item>
                          )}
                          {p.type !== "Links" ? (
                            <List.Item className="list-item">
                              <List.Item.Meta
                                description={p.name}
                                className={styles.col1}
                              />
                              <List.Item.Meta
                                description={phoneMask(p.content)}
                              />
                              <List.Item.Meta
                                style={{ textAlign: "right", maxWidth: 97 }}
                                description={
                                  <span className={styles.actions}>
                                    <a
                                      href={`tel:${removePhoneSymbols(
                                        p.content
                                      )}`}
                                    >
                                      <Button
                                        shape="circle"
                                        type="text"
                                        size="small"
                                        icon={<Call size={24} />}
                                      />
                                    </a>
                                    <Button
                                      onClick={openSMS(
                                        removePhoneSymbols(p.content)
                                      )}
                                      style={{ marginLeft: 12 }}
                                      shape="circle"
                                      size="small"
                                      type="text"
                                      icon={<Textsms size={24} />}
                                    />
                                    {admin === "Yes" || manager === "Yes" ? (
                                      <Button
                                        style={{ marginLeft: 12 }}
                                        shape="circle"
                                        type="text"
                                        icon={<Edit size={24} />}
                                        onClick={edit(p)}
                                        size="small"
                                      />
                                    ) : null}
                                  </span>
                                }
                              />
                            </List.Item>
                          ) : (
                            <List.Item className="list-item">
                              <List.Item.Meta description={p.name} />
                              <List.Item.Meta
                                style={{ textAlign: "right" }}
                                description={
                                  <span className={styles.actions}>
                                    <a
                                      href={p.content}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <Button
                                        shape="circle"
                                        type="text"
                                        icon={<OpenInBrowser size={24} />}
                                      />
                                    </a>
                                    <Clipboard
                                      style={{ display: "inline-block" }}
                                      data-clipboard-text={p.content}
                                      component="div"
                                      onSuccess={copied}
                                      data-clipboard-title={p.name}
                                    >
                                      <Button
                                        style={{ marginLeft: 12 }}
                                        shape="circle"
                                        type="text"
                                        icon={<ContentCopy size={24} />}
                                      />
                                    </Clipboard>
                                    {admin === "Yes" || manager === "Yes" ? (
                                      <Button
                                        style={{ marginLeft: 12 }}
                                        shape="circle"
                                        type="text"
                                        icon={<Edit size={24} />}
                                        onClick={edit(p)}
                                      />
                                    ) : null}
                                  </span>
                                }
                              />
                            </List.Item>
                          )}
                        </div>
                      );
                    })}
                  </>
                )}
              </List>
            </div>
          </FormBody>
        </>
      )}
    </div>
  );
};

export default PhoneBook;
