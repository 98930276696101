import React, { useRef } from "react";
import { useSelector } from "react-redux";
import Controls from "../Controls";
import styles from "./styles.module.scss";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import isPdf from "Utils/isPdf";

const ViewedImage = () => {
  const viewingImage = useSelector((state) => state.dataState.viewingImage);
  const imageRef = useRef();

  function getTransform(el) {
    const rotate = getCurrentRotation(el);
    const transform = window.getComputedStyle(el).getPropertyValue("transform");
    var values = transform.split("(")[1];
    values = values.split(")")[0];
    values = values.split(",");
    const scale = Math.sqrt(values[0] * values[0] + values[1] * values[1]);
    return { rotate, scale };
  }

  function getCurrentRotation(el) {
    let angle;
    var st = window.getComputedStyle(el, null);
    var tr =
      st.getPropertyValue("-webkit-transform") ||
      st.getPropertyValue("-moz-transform") ||
      st.getPropertyValue("-ms-transform") ||
      st.getPropertyValue("-o-transform") ||
      st.getPropertyValue("transform") ||
      "fail...";
    if (tr !== "none") {
      var values = tr.split("(")[1];
      values = values.split(")")[0];
      values = values.split(",");
      var a = values[0];
      var b = values[1];
      var radians = Math.atan2(b, a);
      angle = Math.round(radians * (180 / Math.PI));
    } else {
      angle = 0;
    }
    return angle;
  }

  function rotateLeft() {
    const transform = getTransform(imageRef.current);
    const newDeg = transform.rotate + 90;
    imageRef.current.style.transform = `rotate(${newDeg}deg) scale(${transform.scale}) translate(0px, 0px)`;
    if (imageRef.current.tagName === "EMBED") {
      const width = imageRef.current.parentNode.offsetWidth;
      const height = imageRef.current.parentNode.offsetHeight;
      if (
        newDeg === -90 ||
        newDeg === 90 ||
        newDeg === -270 ||
        newDeg === 270
      ) {
        imageRef.current.style.height = width + "px";
        imageRef.current.style.width = height + "px";
      } else {
        imageRef.current.style.height = height + "px";
        imageRef.current.style.width = width + "px";
      }
      if (newDeg === -90) {
        imageRef.current.style.padding = 0 + "px";
        imageRef.current.style.paddingRight = 52 + "px";
      } else if (newDeg === -180) {
        imageRef.current.style.padding = 0 + "px";
        imageRef.current.style.paddingBottom = 52 + "px";
      } else if (newDeg === -270) {
        imageRef.current.style.padding = 0 + "px";
        imageRef.current.style.paddingLeft = 52 + "px";
      } else if (newDeg === 0) {
        imageRef.current.style.padding = 0 + "px";
        imageRef.current.style.paddingTop = 52 + "px";
      } else if (newDeg === 90) {
        imageRef.current.style.padding = 0 + "px";
        imageRef.current.style.paddingLeft = 52 + "px";
      } else if (newDeg === 180) {
        imageRef.current.style.padding = 0 + "px";
        imageRef.current.style.paddingBottom = 52 + "px";
      } else if (newDeg === 270) {
        imageRef.current.style.padding = 0 + "px";
        imageRef.current.style.paddingRight = 52 + "px";
      } else if (newDeg === 0) {
        imageRef.current.style.padding = 0 + "px";
        imageRef.current.style.paddingTop = 52 + "px";
      }
    }
  }

  function rotateRight() {
    const transform = getTransform(imageRef.current);
    const newDeg = transform.rotate - 90;
    imageRef.current.style.transform = `rotate(${newDeg}deg) scale(${transform.scale}) translate(0px, 0px )`;
    if (imageRef.current.tagName === "EMBED") {
      const width = imageRef.current.parentNode.offsetWidth;
      const height = imageRef.current.parentNode.offsetHeight;
      if (
        newDeg === -90 ||
        newDeg === 90 ||
        newDeg === -270 ||
        newDeg === 270
      ) {
        imageRef.current.style.height = width + "px";
        imageRef.current.style.width = height + "px";
      } else {
        imageRef.current.style.height = height + "px";
        imageRef.current.style.width = width + "px";
      }
      if (newDeg === -90) {
        imageRef.current.style.padding = 0 + "px";
        imageRef.current.style.paddingRight = 52 + "px";
      } else if (newDeg === -180) {
        imageRef.current.style.padding = 0 + "px";
        imageRef.current.style.paddingBottom = 52 + "px";
      } else if (newDeg === -270) {
        imageRef.current.style.padding = 0 + "px";
        imageRef.current.style.paddingLeft = 52 + "px";
      } else if (newDeg === 0) {
        imageRef.current.style.padding = 0 + "px";
        imageRef.current.style.paddingTop = 52 + "px";
      } else if (newDeg === 90) {
        imageRef.current.style.padding = 0 + "px";
        imageRef.current.style.paddingLeft = 52 + "px";
      } else if (newDeg === 180) {
        imageRef.current.style.padding = 0 + "px";
        imageRef.current.style.paddingBottom = 52 + "px";
      } else if (newDeg === 270) {
        imageRef.current.style.padding = 0 + "px";
        imageRef.current.style.paddingRight = 52 + "px";
      } else if (newDeg === 0) {
        imageRef.current.style.padding = 0 + "px";
        imageRef.current.style.paddingTop = 52 + "px";
      }
    }
  }

  return (
    <div className={styles.imageContainer}>
      {!isPdf(viewingImage.src) ? (
        <TransformWrapper wheel={{ step: 100 }}>
          <TransformComponent>
            <img alt="viewing" ref={imageRef} src={viewingImage.src} />
          </TransformComponent>
        </TransformWrapper>
      ) : null}

      <Controls
        rotateRight={rotateRight}
        rotateLeft={rotateLeft}
        setImages={viewingImage.setImages}
        readOnly={viewingImage.readOnly}
        viewOnly={
          viewingImage._commentId ? true : viewingImage._replyId ? true : false
        }
        _activityId={viewingImage._activityId}
        imageRef={imageRef}
      />
    </div>
  );
};

export default ViewedImage;
