import * as actions from "./actionTypes";

export const openForm = (form, formDetails) => async (dispatch) => {
  try {
    dispatch({
      type: actions.OPEN_FORM,
      payload: { form, formDetails },
    });
  } catch (err) {
    console.log("openForm err", err);
  }
};

export const closeForm = (form, openForms) => async (dispatch) => {
  try {
    const _openForms = openForms - 1;
    if (_openForms > 1) {
      dispatch({
        type: actions.CLOSE_FORM,
        payload: {
          form,
          openForms: _openForms,
        },
      });
    } else {
      dispatch({
        type: actions.CLOSE_LAST_FORM,
        payload: {
          form,
          openForms: _openForms,
        },
      });
    }
  } catch (err) {
    console.log("closeForm err", err);
  }
};

export const updateFormDetails =
  (form, currentFormDetails, updatedFormDetails) => async (dispatch) => {
    try {
      let formDetailsCopy = { ...currentFormDetails };
      Object.keys(updatedFormDetails).map((key) => {
        formDetailsCopy[key] = updatedFormDetails[key];
        return key;
      });
      dispatch({
        type: actions.UPDATE_FORM_DETAILS,
        payload: {
          form,
          formDetails: formDetailsCopy,
        },
      });
    } catch (err) {
      console.log("updateFormDetails err", err);
    }
  };
