import React, { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import FormBody from "Components/FormBody";
import LeadProjectActions from "./LeadProjectActions";
import ActionButtons from "./ActionButtons";
import Button from "antd/es/button";
import dayjs from "dayjs";
import CustomerName from "./fields/CustomerName";
import CompanyName from "./fields/CompanyName";
import Phone from "./fields/Phone";
import AlternatePhone from "./fields/AlternatePhone";
import AddAlternatePhone from "./fields/AddAlternatePhone";
import Email from "./fields/Email";
import AlternateEmail from "./fields/AlternateEmail";
import AddAlternateEmail from "./fields/AddAlternateEmail";
import Address from "./fields/Address";
import ClaimNumber from "./fields/ClaimNumber";
import Salesmen from "./fields/Salesmen";
import ProjectManager from "./fields/ProjectManager";
import PropertyType from "./fields/PropertyType";
import Scopes from "./fields/Scopes";
import Permits from "./fields/Permits";
import Campaigns from "./fields/Campaigns";
import EstimateValue from "./fields/EstimateValue";
// import ContractPrice from "./fields/ContractPrice";
import Notes from "./fields/Notes";
import ProjectDescription from "./fields/ProjectDescription";
import CopyableContent from "Components/CopyableContent";
import CopyDetails from "Components/Details/CopyDetails";
import Add from "Icons/Add";
import styles from "./styles.module.scss";
import PersonalReferralName from "./fields/PersonalReferralName";
import Origin from "./fields/Origin";
import EstimateStart from "./fields/EstimateStart";
import currencyFormatter from "Utils/currencyFormatter";
import InstallProjectManager from "./fields/InstallProjectManager";
import useProjectManagers from "Hooks/useProjectManagers";
import OnlineReview from "./fields/OnlineReview";
import HoverLinks from "./fields/HoverLinks";

const Details = () => {
  const _id = useSelector((state) => state.dataState.details._id);
  const projectStatus = useSelector(
    (state) => state.dataState.details.projectStatus
  );
  const customerId = useSelector((state) => state.dataState.details.customerId);
  const created = useSelector((state) => state.dataState.details.created);
  const estimateDate = useSelector(
    (state) => state.dataState.details.estimateDate
  );
  const leadLostDate = useSelector(
    (state) => state.dataState.details.leadLostDate
  );
  const closedOutDate = useSelector(
    (state) => state.dataState.details.closedOutDate
  );
  const brokedownDate = useSelector(
    (state) => state.dataState.details.brokedownDate
  );
  const cancelledDate = useSelector(
    (state) => state.dataState.details.cancelledDate
  );
  const contractDate = useSelector(
    (state) => state.dataState.details.contractDate
  );
  const estimateStart = useSelector(
    (state) => state.dataState.details.estimateStart
  );
  const estimateEventId = useSelector(
    (state) => state.dataState.details.estimateEventId
  );
  const dateSold = useSelector((state) => state.dataState.details.dateSold);
  const companyName = useSelector(
    (state) => state.dataState.details.companyName
  );
  const customerName = useSelector(
    (state) => state.dataState.details.customerName
  );
  const phone = useSelector((state) => state.dataState.details.phone);
  const alternatePhone = useSelector(
    (state) => state.dataState.details.alternatePhone
  );
  const email = useSelector((state) => state.dataState.details.email);
  const alternateEmail = useSelector(
    (state) => state.dataState.details.alternateEmail
  );
  const projectAddressStreet = useSelector(
    (state) => state.dataState.details.projectAddressStreet
  );
  const projectAddressCity = useSelector(
    (state) => state.dataState.details.projectAddressCity
  );
  const projectAddressState = useSelector(
    (state) => state.dataState.details.projectAddressState
  );
  const projectAddressZip = useSelector(
    (state) => state.dataState.details.projectAddressZip
  );
  const projectAddressCounty = useSelector(
    (state) => state.dataState.details.projectAddressCounty
  );
  const projectAddressPermitAuthority = useSelector(
    (state) => state.dataState.details.projectAddressPermitAuthority
  );
  const projectAddressLat = useSelector(
    (state) => state.dataState.details.projectAddressLat
  );
  const projectAddressLng = useSelector(
    (state) => state.dataState.details.projectAddressLng
  );
  const billingAddressStreet = useSelector(
    (state) => state.dataState.details.billingAddressStreet
  );
  const billingAddressCity = useSelector(
    (state) => state.dataState.details.billingAddressCity
  );
  const billingAddressState = useSelector(
    (state) => state.dataState.details.billingAddressState
  );
  const billingAddressZip = useSelector(
    (state) => state.dataState.details.billingAddressZip
  );
  const claimNumber = useSelector(
    (state) => state.dataState.details.claimNumber
  );
  const salesObj = useSelector((state) => state.dataState.details.salesObj);
  const projectManager = useSelector(
    (state) => state.dataState.details.projectManager
  );
  const installProjectManager = useSelector(
    (state) => state.dataState.details.installProjectManager
  );
  const propertyType = useSelector(
    (state) => state.dataState.details.propertyType
  );
  const scopes = useSelector((state) => state.dataState.details.scopes);
  const permits = useSelector((state) => state.dataState.details.permits);
  const origin = useSelector((state) => state.dataState.details.origin);
  const personalReferralName = useSelector(
    (state) => state.dataState.details.personalReferralName
  );
  const campaign = useSelector((state) => state.dataState.details.campaign);
  const estimateValue = useSelector(
    (state) => state.dataState.details.estimateValue
  );
  const notes = useSelector((state) => state.dataState.details.notes);
  const contractPrice = useSelector(
    (state) => state.dataState.details.contractPrice
  );
  const projectDescription = useSelector(
    (state) => state.dataState.details.projectDescription
  );

  const onlineReview = useSelector(
    (state) => state.dataState.details.onlineReview
  );
  const hoverJobId = useSelector((state) => state.dataState.details.hoverJobId);
  const hoverTransferred = useSelector(
    (state) => state.dataState.details.hoverTransferred
  );

  const installProjectManagers = useProjectManagers();
  const [addPhone, setAddPhone] = useState(false);
  const [addEmail, setAddEmail] = useState(false);
  const [stringToCopy, setStringToCopy] = useState([]);

  const toggleAddPhone = useCallback(() => {
    setAddPhone(!addPhone);
  }, [addPhone]);

  const toggleAddEmail = useCallback(() => {
    setAddEmail(!addEmail);
  }, [addEmail]);

  return (
    <>
      <FormBody
        className={`${styles.imageBody} ${
          stringToCopy.length > 0 ? styles.selecting : ""
        }`}
      >
        <div className={`content-inner ${styles.detailsContainer}`}>
          <ActionButtons />
          <LeadProjectActions />

          {projectStatus && projectStatus.includes("Lead") && (
            <EstimateStart
              estimateStart={estimateStart}
              _id={_id}
              projectManager={projectManager}
              estimateEventId={estimateEventId}
            />
          )}

          <div className={styles.row}>
            <h4>Project Status</h4>
            <p>{projectStatus}</p>
          </div>

          {customerId ? (
            <div className={styles.row}>
              <h4 className="withIcon" style={{ marginBottom: 3 }}>
                Customer ID{" "}
                <CopyableContent
                  title="Customer ID"
                  content={`PO: ${customerId}`}
                  setStringToCopy={setStringToCopy}
                  stringToCopy={stringToCopy}
                />
              </h4>
              <p>{customerId}</p>
            </div>
          ) : null}

          {projectStatus && projectStatus.includes("Lead") ? (
            <div className={styles.row}>
              <h4>Lead Created</h4>
              <p>{dayjs(created).format("MMMM DD, YYYY")}</p>
            </div>
          ) : projectStatus && projectStatus.includes("Estimate") ? (
            <div className={styles.row}>
              <h4>Estimate Date</h4>
              <p>{dayjs(estimateDate).format("MMMM DD, YYYY")}</p>
            </div>
          ) : projectStatus === "Lead Lost" ||
            projectStatus === "Estimate Not Sold" ? (
            <div className={styles.row}>
              <h4>Lost Date</h4>
              <p>{dayjs(leadLostDate).format("MMMM DD, YYYY")}</p>
            </div>
          ) : projectStatus === "Closed Out" ? (
            <>
              <div className={styles.row}>
                <h4>Closed Date</h4>
                <p>{dayjs(closedOutDate).format("MMMM DD, YYYY")}</p>
              </div>
              {brokedownDate && (
                <div className={styles.row}>
                  <h4>Brokedown Date</h4>
                  <p>{dayjs(brokedownDate).format("MMMM DD, YYYY")}</p>
                </div>
              )}
            </>
          ) : projectStatus === "Cancelled" ? (
            <div className={styles.row}>
              <h4>Cancelled Date</h4>
              <p>{dayjs(cancelledDate).format("MMMM DD, YYYY")}</p>
            </div>
          ) : (
            <>
              {contractDate && (
                <div className={styles.row}>
                  <h4>Contract Date</h4>
                  <p>{dayjs(contractDate).format("MMMM DD, YYYY")}</p>
                </div>
              )}

              <div className={styles.row}>
                <h4>Sold Date</h4>
                <p>{dayjs(dateSold).format("MMMM DD, YYYY")}</p>
              </div>
            </>
          )}

          <HoverLinks
            hoverJobId={hoverJobId}
            hoverTransferred={hoverTransferred}
            _id={_id}
          />

          <OnlineReview value={onlineReview} _id={_id} />

          <CompanyName
            value={companyName}
            _id={_id}
            setStringToCopy={setStringToCopy}
            stringToCopy={stringToCopy}
          />

          <CustomerName
            value={customerName}
            _id={_id}
            setStringToCopy={setStringToCopy}
            stringToCopy={stringToCopy}
          />

          <Phone
            value={phone}
            _id={_id}
            setStringToCopy={setStringToCopy}
            stringToCopy={stringToCopy}
          />

          {alternatePhone.map((ap) => (
            <AlternatePhone
              key={ap._id}
              _id={_id}
              _apId={ap._id}
              description={ap.description}
              contact={ap.contact}
              setStringToCopy={setStringToCopy}
              stringToCopy={stringToCopy}
            />
          ))}

          {addPhone && (
            <AddAlternatePhone toggleAddPhone={toggleAddPhone} _id={_id} />
          )}

          {!addPhone && (
            <Button
              block
              type="link"
              style={{ marginBottom: 12, padding: 0, textAlign: "right" }}
              onClick={toggleAddPhone}
            >
              <Add size={20} /> Alternate Phone
            </Button>
          )}

          <Email
            value={email}
            _id={_id}
            setStringToCopy={setStringToCopy}
            stringToCopy={stringToCopy}
          />

          {alternateEmail.map((ae) => (
            <AlternateEmail
              key={ae._id}
              _id={_id}
              _apId={ae._id}
              description={ae.description}
              contact={ae.contact}
              setStringToCopy={setStringToCopy}
              stringToCopy={stringToCopy}
            />
          ))}

          {addEmail && (
            <AddAlternateEmail toggleAddEmail={toggleAddEmail} _id={_id} />
          )}

          {!addEmail && email && (
            <Button
              block
              type="link"
              style={{ marginBottom: 12, padding: 0, textAlign: "right" }}
              onClick={toggleAddEmail}
            >
              <Add size={20} /> Alternate Email
            </Button>
          )}

          <Address
            _id={_id}
            street={projectAddressStreet}
            city={projectAddressCity}
            state={projectAddressState}
            zip={projectAddressZip}
            county={projectAddressCounty}
            permitAuthority={projectAddressPermitAuthority}
            lat={projectAddressLat}
            lng={projectAddressLng}
            type="project"
            map
            setStringToCopy={setStringToCopy}
            stringToCopy={stringToCopy}
          />

          <Address
            _id={_id}
            street={billingAddressStreet}
            city={billingAddressCity}
            state={billingAddressState}
            zip={billingAddressZip}
            type="billing"
            setStringToCopy={setStringToCopy}
            stringToCopy={stringToCopy}
          />

          <ClaimNumber
            value={claimNumber}
            _id={_id}
            setStringToCopy={setStringToCopy}
            stringToCopy={stringToCopy}
          />

          <Salesmen value={salesObj} _id={_id} />

          <ProjectManager
            value={projectManager}
            salesObj={salesObj}
            _id={_id}
          />

          {(projectStatus === "Sold" ||
            projectStatus === "Closed Out" ||
            projectStatus === "Cancelled") && (
            <InstallProjectManager
              value={installProjectManager}
              salesObj={installProjectManagers}
              _id={_id}
            />
          )}

          <PropertyType value={propertyType} _id={_id} />

          {projectStatus &&
            (projectStatus.includes("Lead") ||
              projectStatus.includes("Estimate")) && (
              <Scopes value={scopes} _id={_id} />
            )}

          <Permits value={permits} _id={_id} />

          <Origin value={origin} _id={_id} />

          {origin && origin.includes("Personal") && (
            <PersonalReferralName value={personalReferralName} _id={_id} />
          )}

          {origin && origin.includes("Office") && (
            <Campaigns value={campaign} _id={_id} />
          )}

          {projectStatus && projectStatus.includes("Estimate") && (
            <EstimateValue value={estimateValue} _id={_id} />
          )}

          {projectStatus &&
            (projectStatus.includes("Lead") ||
              projectStatus.includes("Estimate")) && (
              <Notes value={notes} _id={_id} />
            )}

          {/* {(projectStatus === "Sold" ||
            projectStatus === "Closed Out" ||
            projectStatus === "Cancelled") && (
            <ContractPrice value={contractPrice} _id={_id} />
          )} */}

          {(projectStatus === "Sold" ||
            projectStatus === "Closed Out" ||
            projectStatus === "Cancelled") && (
            <>
              <h4 style={{ marginBottom: 3 }}>Contract Price</h4>
              <p>
                <span>
                  {contractPrice
                    ? currencyFormatter(contractPrice)
                    : currencyFormatter(0)}
                </span>
              </p>
            </>
          )}

          {(projectStatus === "Sold" ||
            projectStatus === "Closed Out" ||
            projectStatus === "Cancelled") && (
            <ProjectDescription value={projectDescription} _id={_id} />
          )}
        </div>
      </FormBody>
      <CopyDetails
        stringToCopy={stringToCopy}
        setStringToCopy={setStringToCopy}
      />
    </>
  );
};

export default Details;
