import React, { useState } from "react";
import { useSelector } from "react-redux";
import Form from "antd/es/form";
import message from "antd/es/message";
import Button from "antd/es/button";
import Input from "antd/es/input";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import api from "API";
import CancelButton from "Components/CancelButton";
import SearchLeadsAndProjects from "Components/Inputs/SearchLeadsAndProjects";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";

const messageKey = "messageKey";

const ThankYouLetter = ({
  unsavedChanges,
  setUnsavedChanges,
  handleClose,
  type,
}) => {
  const formDetails = useSelector(
    (state) => state.formState.thankYouLetter.formDetails
  );
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [_projectId, _setProjectId] = useState(null);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      // message.loading("Submitting Thank You Letter...", 0);
      message.loading({
        content: "Submitting thank you letter...",
        duration: 0,
        key: messageKey,
      });
      values._id = formDetails._id;

      values._projectId = _projectId;
      await api.post("/marketing-portal/thank-you", values);

      if (formDetails.callback) {
        formDetails.callback();
      }

      // message.success("Thank You Letter submitted");
      message.success({
        content: "Thank you letter submitted",
        duration: 1.5,
        key: messageKey,
      });
      handleClose(true);
    } catch (err) {
      // message.error("Error submitting thank you letter");
      message.error({
        content: "Error submitting thank you letter",
        duration: 1.5,
        key: messageKey,
      });
      setLoading(false);
      console.log("err", err);
    }
  };

  const onFinishFailed = (err) => {
    console.log("err", err);
    setLoading(false);
    message.error("Error submitting thank you letter");
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  const handleSelect = (selected) => {
    _setProjectId(selected._id);
    form.setFieldsValue({
      recipient: selected.customerName,
      address: `${selected.billingAddressStreet}, ${selected.billingAddressCity}, ${selected.billingAddressState} ${selected.billingAddressZip}`,
    });
  };

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      className="form"
    >
      <RemoveAutocomplete />

      {!_projectId ? (
        <SearchLeadsAndProjects
          handleSelect={handleSelect}
          leads={false}
          disableLastViewed={true}
        />
      ) : (
        <FormBody
          className="content-inner"
          style={{
            paddingLeft: 24,
            paddingTop: 24,
            paddingRight: 24,
            paddingBottom: 8,
            maxHeight: type === "modal" ? "calc(100vh - 161px)" : "unset",
          }}
        >
          <Form.Item
            name="recipient"
            label="Recipient"
            rules={[{ required: true, message: "Recipient is required" }]}
          >
            <InputNoAutoComplete id="recipient" />
          </Form.Item>

          <Form.Item
            disabled
            name="address"
            label="Address"
            rules={[{ required: true, message: "Address is required" }]}
          >
            <Input disabled placeholder="Use Search for Project above" />
          </Form.Item>

          <Form.Item
            name="generalMessage"
            label="General Message"
            rules={[{ required: true, message: "General message is required" }]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
        </FormBody>
      )}
      {_projectId && (
        <FormControls className="sticky-footer">
          <CancelButton handleClick={handleClose} />
          <Form.Item style={{ margin: 0 }}>
            <Button
              htmlType="submit"
              type="primary"
              loading={loading}
              disabled={loading || !unsavedChanges}
            >
              Submit
            </Button>
          </Form.Item>
        </FormControls>
      )}
    </Form>
  );
};

export default ThankYouLetter;
