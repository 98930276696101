import React from "react";
import BarChartWithDates from "Reports/BarChartWithDates";

export default function LeadsVsSalesBySalesman() {
  return (
    <BarChartWithDates
      path="/reports/leadsVsSalesBySalesman"
      summary={false}
      type="count"
      report="LEADS VS ESTIMATES VS SALES BY SALESMAN"
      headers={["Salesman", "Leads", "Estimates", "Sales"]}
    />
  );
}
