import React, { useState, useCallback } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateDetail } from "Actions/dataActions";
import Form from "antd/es/form";
import Button from "antd/es/button";
import message from "antd/es/message";
import Modal from "antd/es/modal";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";
import CopyableContent from "Components/CopyableContent";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import api from "API";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";

const AlternateEmail = ({
  _id,
  _apId,
  description,
  contact,
  setStringToCopy,
  stringToCopy,
}) => {
  const dispatch = useDispatch();
  const viewableActivities = useSelector(
    (state) => state.dataState.viewableActivities
  );
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const onFinish = useCallback(
    async (values) => {
      try {
        setLoading(true);
        values._id = _id;
        values._apId = _apId;
        values.previous = { description, contact };

        if (values.description !== description || values.contact !== contact) {
          const res = await api.post("/update/alternateEmail", values);

          dispatch(
            updateDetail({
              viewableActivities,
              key: "alternateEmail",
              value: res.data.value,
              activities: res.data.activities,
            })
          );

          message.success("Alternate Email updated");
        }

        unstable_batchedUpdates(() => {
          setLoading(false);
          setEdit(false);
        });
      } catch (err) {
        console.log("err", err);
        message.error("Error updating Email");
        setLoading(false);
      }
    },
    [_id, _apId, description, contact, dispatch, viewableActivities]
  );

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error updating Email");
  };

  const confirmDelete = () => {
    Modal.confirm({
      icon: <QuestionCircleOutlined />,
      centered: true,
      cancelText: "Cancel",
      okText: "Delete",
      okButtonProps: { danger: true },
      content: <div>Are you sure you want to delete this Alternate Email?</div>,
      onOk() {
        return new Promise((resolve, reject) => {
          deleteAlternateEmail(() => resolve(true));
        }).catch((err) => console.log("err: ", err));
      },
      onCancel() {},
    });
  };

  const deleteAlternateEmail = useCallback(
    async (cb) => {
      try {
        const res = await api.post("/update/deleteAlternateEmail", {
          description,
          contact,
          _id,
          _apId,
        });

        dispatch(
          updateDetail({
            viewableActivities,
            key: "alternateEmail",
            value: res.data.value,
            activities: res.data.activities,
          })
        );

        message.success("Alternate Email deleted");
        cb();
        setEdit(false);
      } catch (err) {
        console.log("err", err);
        message.error("Error deleting Alternate Email");
      }
    },
    [description, contact, _id, _apId, dispatch, viewableActivities]
  );

  const toggleEdit = () => {
    setEdit(!edit);
  };

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      initialValues={{ description, contact }}
      style={{ marginBottom: 16 }}
    >
      <RemoveAutocomplete />
      <h4 className="withIcon" style={{ marginBottom: 3 }}>
        Alt. Email - {description}
        <CopyableContent
          title={`${description} Email`}
          content={contact}
          setStringToCopy={setStringToCopy}
          stringToCopy={stringToCopy}
          edit={edit}
        />
      </h4>
      {!edit && (
        <p
          className="editableRow"
          style={{ marginBottom: 0 }}
          onClick={toggleEdit}
        >
          <span>{contact}</span>
        </p>
      )}

      {edit && (
        <>
          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true, message: "Description is required" }]}
          >
            <InputNoAutoComplete autoFocus id="description" />
          </Form.Item>

          <Form.Item
            name="contact"
            rules={[
              { required: true, message: "Email is required" },
              { message: "Must be a valid email", type: "email" },
            ]}
          >
            <InputNoAutoComplete id="contact" />
          </Form.Item>

          <div className="editControls">
            <Button
              type="primary"
              danger
              size="small"
              onClick={confirmDelete}
              disabled={loading}
            >
              Delete
            </Button>
            <Button
              style={{ marginRight: 8 }}
              type="default"
              size="small"
              onClick={toggleEdit}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              size="small"
              loading={loading}
              disabled={loading}
            >
              Submit
            </Button>
          </div>
        </>
      )}
    </Form>
  );
};

export default AlternateEmail;
