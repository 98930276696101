import React from "react";

const RemoveAutocomplete = () => {
  return (
    <div
      style={{ position: "fixed", top: "-1000px", left: "-1000px", opacity: 0 }}
    >
      <label htmlFor="_email">email</label>
      <input name="_email" type="email" tabIndex="-1" />
      <label htmlFor="_password">password</label>
      <input name="_password" type="password" tabIndex="-1" />
    </div>
  );
};

export default RemoveAutocomplete;
