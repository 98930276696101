import React, { memo } from "react";
import Input from "antd/es/input";
import Form from "antd/es/form";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Typography from "antd/es/typography";
import Pitches from "Components/Inputs/Pitches";
import Select from "Components/Inputs/Select";
import DatePicker from "Components/Inputs/DatePicker";
import useIsMobile from "Hooks/useIsMobile";
import Divider from "antd/es/divider";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";

const WindowDetails = () => {
  const mobile = useIsMobile();

  return (
    <>
      <Typography.Title level={4}>Project Details</Typography.Title>
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="pitches"
            label="Roof Pitches"
            rules={[{ required: true, message: "Pitches is required" }]}
          >
            <Pitches />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="stories"
            label="Stories"
            rules={[{ required: true, message: "Stories is required" }]}
          >
            <InputNoAutoComplete id="stories" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="buildDate"
            label="Build Date"
            rules={[{ required: true, message: "Build Date is required" }]}
          >
            <DatePicker
              format="MMMM DD, YYYY"
              getPopupContainer={(trigger) =>
                !mobile
                  ? trigger.parentNode.parentNode.parentNode.parentNode
                  : document.body
              }
              getCalendarContainer={(trigger) =>
                !mobile
                  ? trigger.parentNode.parentNode.parentNode.parentNode
                  : document.body
              }
              inputReadOnly={true}
              autoComplete="dont-use-autocomplete"
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="existingWindows"
            label="Existing Windows"
            rules={[
              { required: true, message: "Existing Windows is required" },
            ]}
          >
            <Select
              mode="multiple"
              options={["Aluminum", "Vinyl", "Wood", "Other"]}
              id="existingWindows"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="windowStyle"
            label="Window Style"
            rules={[{ required: true, message: "Window Style is required" }]}
          >
            <Select
              options={["New Construction", "Replacement"]}
              id="windowStyle"
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="aluminumWrapExterior"
            label="Aluminum Wrap Exterior"
            rules={[
              { required: true, message: "Aluminum Wrap Exterior is required" },
            ]}
          >
            <Select options={["Yes", "No"]} id="aluminumWrapExterior" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="dumpsterNeeded"
            label="Dumpster Needed"
            rules={[{ required: true, message: "Dumpster Needed is required" }]}
          >
            <Select options={["Yes", "No"]} id="dumpsterNeeded" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="sidingRemoval"
            label="Siding Removal"
            rules={[
              { required: true, message: "Siding Removal In is required" },
            ]}
          >
            <Select options={["Yes", "No"]} id="sidingRemoval" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="projectDescription"
            label="Project Description of Work"
            rules={[
              {
                required: true,
                message: "Project Description of Work is required",
              },
            ]}
          >
            <Input.TextArea autoComplete="dont-use-autocomplete" rows={4} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="scopeDescription"
            label="Scope Description of Work"
            rules={[
              {
                required: true,
                message: "Scope Description of Work is required",
              },
            ]}
          >
            <Input.TextArea autoComplete="dont-use-autocomplete" rows={4} />
          </Form.Item>
        </Col>
      </Row>

      <Divider />
      <Typography.Title level={4}>Product Details</Typography.Title>

      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="manufacturer"
            label="Manufacturer"
            rules={[{ required: true, message: "Manufacturer is required" }]}
          >
            <InputNoAutoComplete id="manufacturer" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="productLine"
            label="Product Line"
            rules={[{ required: true, message: "Product Line is required" }]}
          >
            <InputNoAutoComplete id="productLine" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="interiorColor"
            label="Interior Color"
            rules={[{ required: true, message: "Interior Color is required" }]}
          >
            <InputNoAutoComplete id="interiorColor" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="exteriorColor"
            label="Exterior Color"
            rules={[{ required: true, message: "Exterior Color is required" }]}
          >
            <InputNoAutoComplete id="exteriorColor" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="grids"
            label="Grids"
            rules={[{ required: true, message: "Grids is required" }]}
          >
            <Select options={["Yes", "No"]} id="grids" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="windowQuantity"
            label="Quantity"
            rules={[{ required: true, message: "Quantity is required" }]}
          >
            <InputNoAutoComplete id="windowQuantity" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="exteriorFinishDetail"
            label="Exterior Finish Detail"
            rules={[
              { required: true, message: "Exterior Finish Detail is required" },
            ]}
          >
            <InputNoAutoComplete id="exteriorFinishDetail" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="interiorFinishDetail"
            label="Interior Finish Detail"
            rules={[
              { required: true, message: "Interior Finish Detail is required" },
            ]}
          >
            <InputNoAutoComplete id="interiorFinishDetail" />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default memo(WindowDetails);
