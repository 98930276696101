import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Button from "antd/es/button";

const Expenses = () => {
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    window.location.replace(
      `hihover://premier-roofing/capture/identifier/${params.hoverJobId}`
    );
  }, [params.hoverJobId]);

  function goToDashboard() {
    navigate("/dashboard");
  }

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      Redirecting to Hover...
      <br />
      <br />
      <Button type="primary" onClick={goToDashboard}>
        Go To Dashboard
      </Button>
    </div>
  );
};

export default Expenses;
