import React, { useState, useEffect } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useParams } from "react-router-dom";
import Typography from "antd/es/typography";
import message from "antd/es/message";
import Card from "antd/es/card";
import logo from "Assets/logo.png";
import api from "API";
import styles from "./styles.module.scss";
import CheckCircle from "Icons/CheckCircle";
import Error from "Icons/Error";
import dayjs from "dayjs";
import Loader from "Components/Loader";
import * as serviceWorker from "../../serviceWorkerRegistration";

const WorkDeclined = () => {
  const params = useParams();
  const [submitted, setSubmitted] = useState(false);
  const [declined, setDeclined] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  let {
    _projectId,
    _activityId,
    _commentId,
    customerId,
    customerName,
    token,
    _scopeId,
    companyEmail,
    timeStamp,
  } = params;

  const _t = dayjs(new Date(parseInt(timeStamp)));
  const _n = dayjs();

  let _h = _n.diff(_t, "hour");

  useEffect(() => {
    serviceWorker.unregister();
    if (_h > 72) {
      return false;
    }

    declineWork();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const declineWork = async () => {
    try {
      setSubmitting(true);
      await api.post("/declineWork", {
        _projectId,
        _activityId,
        _commentId,
        customerId,
        customerName,
        email: companyEmail,
        token,
        _scopeId,
      });
      unstable_batchedUpdates(() => {
        setDeclined(true);
        setSubmitted(true);
        setSubmitting(false);
      });
    } catch (err) {
      unstable_batchedUpdates(() => {
        setSubmitted(true);
        setSubmitting(false);
      });
      message.error("Error declining work");
    }
  };

  if (_h > 72) {
    return (
      <div className={`content-inner ${styles.container}`}>
        <img src={logo} alt="premier-logo" />
        <Card bordered={false} className={`${styles.card} ${styles.declined}`}>
          <img src={logo} alt="premier-logo" />
          <div className={styles.banner}>
            <Typography.Title>
              Failed to Decline Work <Error className={styles.success} />
            </Typography.Title>
          </div>

          <div className="ant-result-subtitle">
            <div className={styles.subTitle}>
              An error occured when attempting to submit your request. Please
              reply to the email you received to request another link.
            </div>
          </div>
        </Card>
      </div>
    );
  }

  if (_h > 72) {
    return (
      <div className={`content-inner ${styles.container}`}>
        <img src={logo} alt="premier-logo" />
        <Card bordered={false} className={`${styles.card} ${styles.declined}`}>
          <img src={logo} alt="premier-logo" />
          <div className={styles.banner}>
            <Typography.Title>
              Failed to Accept Work <Error className={styles.success} />
            </Typography.Title>
          </div>

          <div className="ant-result-subtitle">
            <div className={styles.subTitle}>
              An error occured when attempting to submit your request. Please
              reply to the email you received to request another link.
            </div>
          </div>
        </Card>
      </div>
    );
  }

  if (submitting) {
    return (
      <div style={{ height: "100%" }}>
        <Loader minHeight="100%" subTitle="Declining Work..." />
      </div>
    );
  }

  return (
    <div className={`content-inner ${styles.container}`}>
      <img src={logo} alt="premier-logo" />
      <Card
        bordered={false}
        className={`${styles.card} ${
          !submitted
            ? styles.waiting
            : declined
            ? styles.accepted
            : styles.declined
        }`}
      >
        <img src={logo} alt="premier-logo" />
        <div className={styles.banner}>
          {!submitted && <Typography.Title>Decline Work</Typography.Title>}
          {submitted && declined && (
            <Typography.Title>
              Work Declined! <CheckCircle className={styles.success} />
            </Typography.Title>
          )}
          {submitted && !declined && (
            <Typography.Title>
              Failed to Decline Work <Error className={styles.success} />
            </Typography.Title>
          )}
        </div>

        {submitted && !declined && (
          <div className="ant-result-subtitle">
            <div className={styles.subTitle}>
              An error occured when attempting to submit your request. Please
              reply to the email that was sent to you. Thank you.
            </div>
          </div>
        )}

        {submitted && declined && (
          <div className="ant-result-subtitle">
            <div className={styles.subTitle}>
              <div>Thank you for your response</div>
            </div>
          </div>
        )}
      </Card>
    </div>
  );
};

export default WorkDeclined;
