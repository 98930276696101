import React from "react";
// import { useDispatch } from "react-redux";
// import { openForm } from "Actions/formActions";
import Tag from "antd/es/tag";
import styles from "./styles.module.scss";
import scopeObj from "Constants/scopes";

const Scope = ({
  scope,
  // projectStatus,
  // customerId,
  // customerName,
  // address,
  mobile,
}) => {
  // const woCreated = scope.woCreated ? true : false;
  // const dispatch = useDispatch();

  // const onScopeClick = useCallback(
  //   (e) => {
  //     e.stopPropagation();
  //     dispatch(
  //       openForm("updateScope", {
  //         _id: scope._id,
  //         projectStatus,
  //         customerId,
  //         customerName,
  //         projectAddress: address,
  //         name: scope.name,
  //         amount: scope.amount,
  //         status: scope.status,
  //         statusDate: scope.statusDate,
  //         progress: scope.progress,
  //         extendedWarranty: scope.extendedWarranty,
  //         premierWarranty: scope.premierWarranty,
  //         repair: scope.repair,
  //         insurance: scope.insurance,
  //         stage: scope.stage,
  //       })
  //     );
  //   },
  //   [dispatch, scope, projectStatus, customerId, customerName, address]
  // );

  return (
    <Tag
      // key={scope._id}
      // className={`${mobile ? styles.mobile : ""} ${styles.scopeBadge} ${
      //   woCreated ? "" : styles.woNotCreated
      // }`}
      className={`${mobile ? styles.mobile : ""} ${styles.scopeBadge}`}
      style={{ cursor: "pointer" }}
      // color={scopeObj[scope.name].color}
      color={scopeObj[scope].color}
      // onClick={onScopeClick}
    >
      {/* {scopeObj[scope.name].initials} */}
      {scopeObj[scope].initials}
    </Tag>
  );
};

export default Scope;
