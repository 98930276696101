import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchTable, setTableFilters } from "Actions/tableActions";
import Form from "antd/es/form";
import Button from "antd/es/button";
import Drawer from "antd/es/drawer";
import Grid from "antd/es/grid";
import Menu from "antd/es/menu";
import Select from "antd/es/select";
import DatePicker from "Components/Inputs/DatePicker";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import NamingFilterModal from "Components/NamingFilterModal";
import ArrowRight from "Icons/ArrowRight";
import dayjs from "dayjs";
import styles from "./styles.module.scss";
import InputSalesmen from "Components/Inputs/Salesmen";
import useSalesmen from "Hooks/useSalesmen";
import useProjectManagers from "Hooks/useProjectManagers";
import useIsMobile from "Hooks/useIsMobile";
import useVendors from "Hooks/useVendors";
import useShingles from "Hooks/useShingles";
import InputVendors from "Components/Inputs/Vendors";
import Cascader from "antd/es/cascader";

const { useBreakpoint } = Grid;

const Filter = ({ visible, showFilter, filters }) => {
  const screens = useBreakpoint();
  const dispatch = useDispatch();
  const activeFilter = useSelector((state) => state.tableState.activeFilter);
  const table = useSelector((state) => state.tableState.table);
  const col = useSelector((state) => state.tableState.col);
  const order = useSelector((state) => state.tableState.order);
  const search = useSelector((state) => state.tableState.search);
  const fetching = useSelector((state) => state.tableState.fetching);
  const vendors = useVendors();

  const [form] = Form.useForm();
  const [saveModal, setSaveModal] = useState(false);

  const onFinish = useCallback(
    (values) => {
      let filter = {};
      Object.keys(values).forEach((key) => {
        if (key === "vendor") {
          values.vendor.map((v, i) => {
            values[key][i] = vendors[values[key]].companyName;
            return v;
          });
        } else if (key.includes("start") && values[key]) {
          values[key] = values[key].startOf("day");
        } else if (key.includes("end") && values[key]) {
          values[key] = values[key].endOf("day");
        }

        return values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
          ? delete values[key]
          : {};
      });

      filter.filter = values;

      dispatch(
        fetchTable({
          table,
          col,
          order,
          search,
          activeFilter: filter,
        })
      );
      showFilter(false);
    },
    [dispatch, table, col, order, search, showFilter, vendors]
  );

  const onFinishFailed = (e) => {
    console.log("e", e);
  };

  const handleClose = () => {
    showFilter(false);
  };

  const saveFilter = useCallback(
    (filters) => {
      setTableFilters(filters);
      dispatch(
        fetchTable({
          table,
          col,
          order,
          search,
          activeFilter: filters._newFilter,
        })
      );
      showFilter(false);
    },
    [dispatch, table, col, order, search, showFilter]
  );

  const setActiveFilter = () => {
    form.resetFields();
    const _filter = activeFilter.filter;
    if (_filter) {
      for (const [key, filter] of Object.entries(_filter)) {
        let val;
        if (key.includes("start")) {
          val = dayjs(filter).startOf("day");
        } else if (key.includes("end") && key !== "vendor") {
          val = dayjs(filter).endOf("day");
        } else {
          val = filter;
        }

        form.setFieldsValue({
          [key]: val,
        });
      }
    }
  };

  const reset = () => {
    setActiveFilter();
  };

  const openSaveModal = () => {
    setSaveModal(true);
  };

  const cancel = () => {
    handleClose();
    form.resetFields();
  };

  return (
    <Drawer
      className={styles.filterDrawer}
      title={
        <div className={styles.filterHeader}>
          <Button
            style={{ marginLeft: 12 }}
            type="secondary"
            shape="circle"
            ghost
            onClick={handleClose}
          >
            <ArrowRight color="#ffffff" />
          </Button>

          <h2 className={styles.filterTitle}>FILTER</h2>

          <div style={{ margin: "0 12px", width: 32 }}></div>
        </div>
      }
      placement="right"
      closable={false}
      maskClosable={true}
      visible={visible}
      headerStyle={{ padding: 0, borderBottom: "none", width: "100%" }}
      bodyStyle={{ padding: 0 }}
      width={!screens.xs ? 380 : "100%"}
      destroyOnClose
      onClose={cancel}
    >
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className="form"
        style={{
          position: "absolute",
          height: "calc(100% - 64px)",
        }}
      >
        <FormBody>
          <FilterBody setActiveFilter={setActiveFilter} filters={filters} />
        </FormBody>
        <FormControls className="sticky-footer">
          <Button
            type="text"
            danger
            style={{ padding: "4px 8px", float: "left" }}
            htmlType="button"
            onClick={reset}
            disabled={fetching}
          >
            Reset
          </Button>
          <Button
            type="text"
            style={{ padding: "4px 8px", marginRight: 8 }}
            htmlType="button"
            onClick={openSaveModal}
            disabled={fetching}
          >
            Submit & Save
          </Button>
          <Button type="primary" htmlType="submit" disabled={fetching}>
            Submit
          </Button>
        </FormControls>

        <NamingFilterModal
          visible={saveModal}
          onClose={setSaveModal}
          update={saveFilter}
          form={form}
          table={table}
        />
      </Form>
    </Drawer>
  );
};

const FilterBody = ({ setActiveFilter, filters }) => {
  const mobile = useIsMobile();
  const sales = useSalesmen();
  const projectManagers = useProjectManagers();
  const vendors = useVendors();
  const shingles = useShingles();

  useEffect(() => {
    setActiveFilter();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const renderFilter = () => {
    return filters.map((f, i) => (
      <Menu.SubMenu
        className={styles.filterSubMenu}
        key={f.title}
        title={f.title}
        style={{ overflow: "visible" }}
      >
        <Menu.Item style={{ overflow: "visible" }} key={`${f.title}-menuItem`}>
          {f.fields.map((field) => {
            if (f.type === "datepicker") {
              return (
                <Form.Item name={field.name} key={field.name}>
                  <DatePicker
                    placeholder={field.placeholder}
                    format="MMMM DD, YYYY"
                    inputReadOnly={true}
                    getCalendarContainer={(trigger) =>
                      !mobile ? trigger.parentNode : document.body
                    }
                  />
                </Form.Item>
              );
            } else if (f.type === "salesmen") {
              return (
                <Form.Item name={field.name} key={field.name}>
                  <InputSalesmen
                    sales={sales}
                    placeholder={field.placeholder}
                    filter
                  />
                </Form.Item>
              );
            } else if (f.type === "projectManager") {
              return (
                <Form.Item name={field.name} key={field.name}>
                  <InputSalesmen
                    sales={sales}
                    placeholder={field.placeholder}
                    filter
                  />
                </Form.Item>
              );
            } else if (f.type === "installProjectManager") {
              return (
                <Form.Item name={field.name} key={field.name}>
                  <InputSalesmen
                    sales={projectManagers}
                    placeholder={field.placeholder}
                    filter
                  />
                </Form.Item>
              );
            } else if (f.type === "vendors") {
              return (
                <Form.Item name={field.name} key={field.name}>
                  <InputVendors
                    vendors={vendors}
                    placeholder={field.placeholder}
                    filter
                  />
                </Form.Item>
              );
            } else if (f.type === "shingle") {
              return (
                <Form.Item name={field.name} key={field.name}>
                  <Cascader
                    showSearch
                    options={shingles}
                    getPopupContainer={(trigger) =>
                      !mobile
                        ? trigger.parentNode.parentNode.parentNode.parentNode
                            .parentNode.parentNode
                        : document.body
                    }
                    // onChange={handleShingleChange}
                  />
                </Form.Item>
              );
            }

            return (
              <Form.Item name={field.name} key={field.name}>
                <Select
                  showSearch={!mobile}
                  dropdownClassName={mobile ? "isMobile" : null}
                  mode="multiple"
                  placeholder={field.placeholder}
                  getPopupContainer={(trigger) =>
                    !mobile
                      ? trigger.parentNode.parentNode.parentNode.parentNode
                          .parentNode.parentNode.parentNode
                      : document.body
                  }
                >
                  {field.values.map((v) => (
                    <Select.Option value={v} key={v}>
                      {v}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            );
          })}
        </Menu.Item>
      </Menu.SubMenu>
    ));
  };

  return (
    <div className="content-inner">
      <Menu
        mode="inline"
        theme="light"
        inlineIndent={24}
        selectable={false}
        forceSubMenuRender
      >
        {renderFilter()}
      </Menu>
    </div>
  );
};

export default Filter;
