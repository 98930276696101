import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Input from "antd/es/input";
import Form from "antd/es/form";
import Button from "antd/es/button";
import message from "antd/es/message";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import api from "API";
import CancelButton from "Components/CancelButton";
import Divider from "antd/es/divider";
import DatePicker from "Components/Inputs/DatePicker";
import useIsMobile from "Hooks/useIsMobile";
import Typography from "antd/es/typography";
import styles from "./styles.module.scss";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";
import MySelect from "Components/Inputs/Select";
import Steps from "antd/es/steps";
import useDumpsterSuppliers from "Hooks/useDumpsterSuppliers";
import Suppliers from "Components/Inputs/Suppliers";
import Select from "antd/es/select";
import InputNumber from "antd/es/input-number";
import Row from "antd/es/row";
import Col from "antd/es/col";
import { unstable_batchedUpdates } from "react-dom";
import { updateFormDetails } from "Actions/formActions";

import ProcessFeedback from "Components/ProcessFeedback";
import Delete from "Icons/Delete";

const messageKey = "messageKey";

const steps = [
  "Submitting Form",
  "Creating Post",
  "Sending Email",
  "Creating Dumpster",
  "Updating Scope",
  "Done!",
];

const ComposeDumpsterEmail = ({
  unsavedChanges,
  setUnsavedChanges,
  handleClose,
  type,
}) => {
  const formDetails = useSelector(
    (state) => state.formState.dumpsterEmail.formDetails
  );
  const mobile = useIsMobile();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const suppliers = useDumpsterSuppliers();
  const [containers, setContainers] = useState([]);
  const [step, setStep] = useState(0);

  const _user = useSelector((state) => state.authState._user);
  const [feedBack, setFeedBack] = useState({
    active: "Submitting Form",
    progress: 0,
  });

  useEffect(() => {
    setTimeout(() => {
      const el = document.getElementById("steps");
      if (el) {
        el.classList.remove("ant-steps-vertical");
        el.classList.add("ant-steps-horizontal");
        el.classList.add("ant-steps-label-vertical");
      }
    });
  }, []);

  useEffect(() => {
    const scrollRef = document.getElementById("compose-dumpster-scroll");
    if (scrollRef) scrollRef.scrollTop = 0;
  }, [step]);

  useEffect(() => {
    if (formDetails.dumpsters) {
      let emails = ["install@gopremier.net"];
      // formDetails.dumpsters.map((d) => {
      //   emails.push(d.projectManagerEmail);
      //   return d;
      // });
      form.setFieldsValue({
        to: [...new Set(emails)],
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const updateFeedBack = (feedBack) => {
    setFeedBack(feedBack);
  };

  const onFinish = async (values) => {
    try {
      window.socket.on(`${_user}-work-order-progress`, updateFeedBack);
      unstable_batchedUpdates(() => {
        setFeedBack({
          active: "Submitting Form",
          progress: 1,
        });
        setLoading(true);
      });

      // message.loading("Sending Email...", 0);
      message.loading({
        content: "Ordering dumpster...",
        duration: 0,
        key: messageKey,
      });
      values.supplier = suppliers[values.supplier];

      await api.post("/ready-for-dumpster/order", values);

      if (formDetails.callback) {
        formDetails.callback();
      }

      setFeedBack({
        active: "Done!",
        progress: 100,
      });
      window.socket.removeAllListeners(`${_user}-work-order-progress`);
      // message.success("Dumpsters Ordered");
      message.success({
        content: "Dumpster ordered",
        duration: 1.5,
        key: messageKey,
      });
      handleClose(true);
    } catch (err) {
      setLoading(false);
      if (
        err &&
        err.errorFields &&
        err.errorFields[0] &&
        err.errorFields[0].name
      ) {
        if (err.errorFields[0].name[0] === "lineItems") {
          const el = document.getElementById("top-of-form");
          el.scrollIntoView({ block: "end", behavior: "smooth" });
        } else {
          form.scrollToField(err.errorFields[0].name[0]);
        }
      }
      // message.error("Error sending email");
      message.error({
        content: "Error ordering dumpster",
        duration: 1.5,
        key: messageKey,
      });
      setFeedBack({
        active: "Submitting Form",
        progress: 0,
      });
      window.socket.removeAllListeners(`${_user}-work-order-progress`);
    }
  };

  const onFinishFailed = (err) => {
    console.log("err", err);
    if (
      err &&
      err.errorFields &&
      err.errorFields[0] &&
      err.errorFields[0].name
    ) {
      if (err.errorFields[0].name[0] === "lineItems") {
        const el = document.getElementById("top-of-form");
        el.scrollIntoView({ block: "end", behavior: "smooth" });
      } else {
        form.scrollToField(err.errorFields[0].name[0]);
      }
    }
    message.error("Error sending email");
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  const cancel = () => {
    handleClose();
  };

  const handleSupplierChange = async (value) => {
    try {
      if (value) {
        let newValue = [suppliers[value].email];
        let toValue = await form.getFieldValue("to");
        if (toValue) {
          for (const t of toValue) {
            newValue.push(t);
          }
        }
        form.setFieldsValue({
          to: [...new Set(newValue)],
        });
        setContainers(suppliers[value].containers);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const detailsNext = () => {
    form
      .validateFields(["supplier", "size", "status", "deliveryDate"])
      .then(() => {
        setStep((thisStep) => thisStep + 1);
      })
      .catch((err) => {
        message.error("All required fields must be completed");
      });
  };

  const removeDumpster = (key) => {
    const _dumpsters = formDetails.dumpsters;
    _dumpsters.splice(key, 1);
    updateFormDetails("dumpsterEmail", formDetails, {
      ...formDetails,
      dumpsters: _dumpsters,
    });
  };

  return (
    <Form
      form={form}
      className="form"
      autoComplete="off"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      scrollToFirstError={true}
      initialValues={{
        subject: `Dumpster Order (${
          formDetails.dumpsters
            ? formDetails.dumpsters.map((d) => d.customerId).join(", ")
            : ""
        })`,
        dumpsters: formDetails.dumpsters,
      }}
    >
      <RemoveAutocomplete />

      <div id="top-of-form" />

      <FormBody
        id="compose-dumpster-scroll"
        className="content-inner"
        style={{
          paddingLeft: 24,
          paddingTop: 24,
          paddingRight: 24,
          paddingBottom: 8,
          maxHeight: type === "modal" ? "calc(100vh - 161px)" : "unset",
        }}
      >
        <Steps
          id="steps"
          className={styles.soldSteps}
          labelPlacement="vertical"
          size="small"
          current={step}
          style={{ marginBottom: 24 }}
        >
          <Steps.Step title="Details" />
          <Steps.Step title="Email" />
        </Steps>

        <div className={step === 0 && feedBack.progress === 0 ? "" : "hide"}>
          <Form.Item
            name="supplier"
            label="Supplier"
            rules={[{ required: true, message: "Supplier is required" }]}
          >
            <Suppliers suppliers={suppliers} onChange={handleSupplierChange} />
          </Form.Item>

          <Form.Item
            name="deliveryDate"
            label="Delivery Date"
            rules={[{ required: true, message: "Delivery Date required" }]}
          >
            <DatePicker
              format="MMMM DD, YYYY"
              inputReadOnly={true}
              getPopupContainer={(trigger) =>
                !mobile
                  ? trigger.parentNode.parentNode.parentNode.parentNode
                      .parentNode
                  : document.body
              }
              getCalendarContainer={(trigger) =>
                !mobile
                  ? trigger.parentNode.parentNode.parentNode.parentNode
                      .parentNode
                  : document.body
              }
            />
          </Form.Item>
        </div>

        <div className={step === 1 && feedBack.progress === 0 ? "" : "hide"}>
          <Form.Item
            name="to"
            label="To"
            rules={[
              {
                required: true,
                message: "You must include at least one email",
              },
            ]}
          >
            <Select mode="tags" />
          </Form.Item>

          <Form.Item
            name="subject"
            label="Email Subject"
            rules={[
              {
                required: true,
                message: "You must include a subject",
              },
            ]}
          >
            <InputNoAutoComplete id="subject" />
          </Form.Item>

          <Divider />

          <Form.List name="dumpsters">
            {(fields, { remove }) => {
              const onRemove = (key) => {
                remove(key);
              };

              return (
                <>
                  {fields.map((field, i) => {
                    const handleRemove = () => {
                      onRemove(field.name);
                      removeDumpster(field.name);
                    };

                    return (
                      <div key={`${field.key}-dumpster`}>
                        <div className={styles.dumpsterHeader}>
                          <Typography.Title
                            level={5}
                            style={{
                              display: "inline-block",
                              width: "calc(100% - 60px)",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {formDetails.dumpsters
                              ? formDetails.dumpsters[i].customerId
                              : ""}{" "}
                            -{" "}
                            {formDetails.dumpsters
                              ? formDetails.dumpsters[i].workOrder.customerName
                              : ""}
                          </Typography.Title>
                          {fields.length > 1 && (
                            <Button
                              // shape="circle"
                              danger
                              type="primary"
                              // className={styles.deleteButton}
                              size="small"
                              onClick={handleRemove}
                            >
                              Delete
                            </Button>
                          )}
                        </div>

                        <Typography.Text
                          type="secondary"
                          // style={{ marginTop: 8, display: "block" }}
                        >
                          {formDetails.dumpsters
                            ? formDetails.dumpsters[i].address
                            : ""}
                        </Typography.Text>
                        <br />
                        <Typography.Text type="secondary">
                          {formDetails.dumpsters ? (
                            <>
                              <b>Squares</b>
                              {` ${formDetails.dumpsters[i].workOrder.squares}`}
                            </>
                          ) : (
                            ""
                          )}
                        </Typography.Text>
                        <br />
                        <Typography.Text type="secondary">
                          {formDetails.dumpsters &&
                          formDetails.dumpsters[i].name ===
                            "Roofing - Steep" ? (
                            <>
                              <b>Roof Type:</b>
                              {` ${formDetails.dumpsters[
                                i
                              ].workOrder.roofType.join(", ")}`}
                            </>
                          ) : formDetails.dumpsters &&
                            formDetails.dumpsters[i].name !== "Siding" ? (
                            <>
                              <b>Existing Layers:</b>
                              {` ${formDetails.dumpsters[
                                i
                              ].workOrder.flatLayers.join(", ")}`}
                              <br />
                              <b>Installation Method:</b>
                              {` ${formDetails.dumpsters[i].workOrder.installationMethod}`}
                            </>
                          ) : (
                            ""
                          )}
                        </Typography.Text>
                        <br />
                        <Typography.Link
                          href={
                            formDetails.dumpsters
                              ? formDetails.dumpsters[i].src
                              : "#"
                          }
                          target="_blank"
                        >
                          View Work Order
                        </Typography.Link>
                        <br />
                        <br />
                        <Form.Item
                          hidden
                          {...field}
                          name={[field.name, "projectManagerEmail"]}
                          label="Project Owner Email"
                          fieldKey={[field.fieldKey, "projectManagerEmail"]}
                          key={`${field.key}-projectManagerEmail`}
                        >
                          <Input
                            autoComplete="dont-use-autocomplete"
                            disabled
                          />
                        </Form.Item>

                        <Form.Item
                          hidden
                          {...field}
                          name={[field.name, "customerId"]}
                          label="Customer ID"
                          fieldKey={[field.fieldKey, "customerId"]}
                          key={`${field.key}-customerId`}
                        >
                          <Input
                            autoComplete="dont-use-autocomplete"
                            disabled
                          />
                        </Form.Item>

                        <Form.Item
                          hidden
                          {...field}
                          name={[field.name, "customerName"]}
                          label="Customer Name"
                          fieldKey={[field.fieldKey, "customerName"]}
                          key={`${field.key}-customerName`}
                        >
                          <Input
                            autoComplete="dont-use-autocomplete"
                            disabled
                          />
                        </Form.Item>

                        <Form.Item
                          hidden
                          {...field}
                          name={[field.name, "address"]}
                          label="Project Address"
                          fieldKey={[field.fieldKey, "address"]}
                          key={`${field.key}-address`}
                        >
                          <Input
                            disabled
                            autoComplete="dont-use-autocomplete"
                          />
                        </Form.Item>

                        <div className="ant-col ant-form-item-label">
                          <label
                            className="ant-form-item-required"
                            title="Container"
                          >
                            Container(s)
                          </label>
                        </div>
                        <Form.List
                          name={[field.name, "containers"]}
                          initialValue={[{}]}
                        >
                          {(sizes, { add, remove }) => (
                            <div>
                              {sizes.map((size) => (
                                <Row key={`${size.key}-size`}>
                                  <Col xs={3}>
                                    <Form.Item
                                      {...size}
                                      name={[size.name, "count"]}
                                      fieldKey={[size.fieldKey, "count"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Count is required",
                                        },
                                      ]}
                                    >
                                      <InputNumber min={1} controls={false} />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={sizes.length > 1 ? 18 : 21}>
                                    <Form.Item
                                      {...size}
                                      name={[size.name, "size"]}
                                      fieldKey={[size.fieldKey, "size"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Size is required",
                                        },
                                      ]}
                                    >
                                      <MySelect options={containers} />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={sizes.length > 1 ? 3 : 0}>
                                    <Button
                                      type="text"
                                      danger
                                      shape="round"
                                      className={styles.deleteSize}
                                      onClick={() => remove(size.name)}
                                    >
                                      <Delete />
                                    </Button>
                                  </Col>
                                </Row>
                              ))}
                              <Form.Item>
                                <Button
                                  type="primary"
                                  className="green"
                                  block
                                  onClick={() => add()}
                                  style={{ marginBottom: 24 }}
                                >
                                  Add Container
                                </Button>
                              </Form.Item>
                            </div>
                          )}
                        </Form.List>

                        {/* <Row gutter={16}>
                        <Col xs={24} sm={12}>
                          <Form.Item
                            {...field}
                            name={[field.name, "count"]}
                            fieldKey={[field.fieldKey, "count"]}
                            key={`${field.key}-count`}
                            label="Count"
                            initialValue={1}
                            rules={[
                              {
                                required: true,
                                message: "Count is required",
                              },
                            ]}
                          >
                            <InputNumber autoComplete="dont-use-autocomplete" controls={false} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                          <Form.Item
                            {...field}
                            name={[field.name, "size"]}
                            fieldKey={[field.fieldKey, "size"]}
                            key={`${field.key}-size`}
                            label="Container"
                            rules={[
                              {
                                required: true,
                                message: "Size is required",
                              },
                            ]}
                          >
                            <MySelect options={containers} />
                          </Form.Item>
                        </Col>
                      </Row> */}

                        <Form.Item
                          {...field}
                          name={[field.name, "specialInstructions"]}
                          label="Special Instructions"
                          fieldKey={[field.fieldKey, "specialInstructions"]}
                          key={`${field.key}-specialInstructions`}
                          initialValue={
                            formDetails.dumpsters
                              ? formDetails.dumpsters[i].workOrder.dumpsterNotes
                              : undefined
                          }
                        >
                          <Input.TextArea
                            autoComplete="edit-detail-field"
                            rows={4}
                          />
                        </Form.Item>

                        {i + 1 !== fields.length && <Divider />}
                      </div>
                    );
                  })}
                </>
              );
            }}
          </Form.List>
        </div>

        <div
          style={{ height: "100%" }}
          className={feedBack.progress === 0 ? "hide" : ""}
        >
          <ProcessFeedback feedBack={feedBack} steps={steps} />
        </div>
      </FormBody>
      {feedBack.progress === 0 && (
        <FormControls className="sticky-footer">
          <CancelButton handleClick={cancel} />

          {step === 0 && (
            <Form.Item style={{ margin: 0 }}>
              <Button type="primary" onClick={detailsNext}>
                Next
              </Button>
            </Form.Item>
          )}

          {step === 1 && (
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={loading}
            >
              Send
            </Button>
          )}
        </FormControls>
      )}
    </Form>
  );
};

export default ComposeDumpsterEmail;
