import React, { useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { updateTable } from "Actions/tableActions";
import { updateDetail } from "Actions/dataActions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { openForm } from "Actions/formActions";
import Dropdown from "antd/es/dropdown";
import Button from "antd/es/button";
import Menu from "antd/es/menu";
import Modal from "antd/es/modal";
import message from "antd/es/message";
import Checkbox from "antd/es/checkbox";
import ArrowRight from "Icons/ArrowRight";
import MoreVert from "Icons/MoreVert";
import Satellite from "Icons/Satellite";
import DumpTruck from "Icons/DumpTruck";
import Delete from "Icons/Delete";
import Group from "Icons/Group";
import Build from "Icons/Build";
import styles from "./styles.module.scss";
import api from "API";
import DoneAll from "Icons/DoneAll";
import Input from "antd/es/input";
import Star from "Icons/Star";
import Rate from "antd/es/rate";
import Clipboard from "react-clipboard.js";
import ClipboardText from "Icons/ClipboardText";
import dayjs from "dayjs";

let secondaryStructures = false;
let notes;
let onlineReview = 0;

const ViewLeadProjectHeader = ({ handleClose }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const admin = useSelector((state) => state.authState.admin);
  const details = useSelector((state) => state.dataState.details);
  const viewableActivities = useSelector(
    (state) => state.dataState.viewableActivities
  );
  const dispatch = useDispatch();
  const col = useSelector((state) => state.tableState.col);
  const order = useSelector((state) => state.tableState.order);
  const search = useSelector((state) => state.tableState.search);
  const activeFilter = useSelector((state) => state.tableState.activeFilter);

  useEffect(() => {
    return () => {
      secondaryStructures = false;
      notes = undefined;
    };
  }, []);

  const leadCheck = () => {
    if (
      details.projectStatus &&
      (details.projectStatus.includes("Lead") ||
        details.projectStatus.includes("Estimate"))
    ) {
      return true;
    }
    return false;
  };

  const onClickSecondaryStructures = (e) => {
    e.stopPropagation();
    secondaryStructures = e.target.checked;
  };

  const handleNotesChange = (e) => {
    e.stopPropagation();
    notes = e.target.value;
  };

  const submitReview = () => {
    Modal.confirm({
      centered: true,
      title: "Submit Review",
      content: (
        <div>
          <Rate onChange={handleReviewChange} />
        </div>
      ),
      okText: "Submit",
      onOk() {
        return new Promise((resolve, reject) => {
          handleSubmitReview(() => {
            resolve(true);
          });
        }).catch((err) => console.log("err", err));
      },
      onCancel() {},
    });
  };

  const handleReviewChange = (e) => {
    onlineReview = e;
  };

  const handleSubmitReview = useCallback(
    async (cb) => {
      try {
        const res = await api.post("/update/online-review", {
          _id: details._id,
          onlineReview,
        });
        message.success("Online review submitted");
        dispatch(
          updateDetail({
            viewableActivities,
            key: "onlineReview",
            value: onlineReview,
            activities: res.data.activities,
          })
        );
        cb();
      } catch (err) {
        console.log("handleSubmitReview err", err);
        message.error("Error submitting review");
      }
    },
    [details._id, dispatch, viewableActivities]
  );

  const confirmAerial = () => {
    Modal.confirm({
      centered: true,
      title: "Aerial Request",
      content: (
        <div>
          <div>
            Are you sure you want to request an aerial for
            {details.projectAddressStreet} {details.projectAddressCity},{" "}
            {details.projectAddressState}
            {details.projectAddressZip}?
          </div>
          <br />
          <Checkbox onChange={onClickSecondaryStructures}>
            Include Secondary Structures
          </Checkbox>
          <br />
          <br />
          <Input.TextArea
            onChange={handleNotesChange}
            placeholder="Additional Notes (if needed)"
          />
        </div>
      ),
      okText: "Submit",
      onOk() {
        return new Promise((resolve, reject) => {
          orderAerial(() => {
            resolve(true);
          });
        }).catch((err) => console.log("err", err));
      },
      onCancel() {},
    });
  };

  const orderAerial = useCallback(
    async (cb) => {
      try {
        const res = await api.post("/aerial/request", {
          _id: details._id,
          secondaryStructures,
          notes,
        });
        message.success("Aerial ordered");
        dispatch(
          updateDetail({
            viewableActivities,
            key: "aerialRequested",
            value: true,
            activities: res.data,
          })
        );
        cb();
      } catch (err) {
        console.log("err", err);
        message.error("Error ordering aerial");
      }
    },
    [details._id, dispatch, viewableActivities]
  );

  const confirmDeleteLead = () => {
    Modal.confirm({
      centered: true,
      title: "Delete Lead",
      content: `Are you sure you want to delete this lead?`,
      okText: "Delete",
      okButtonProps: { danger: true },
      onOk() {
        return new Promise((resolve, reject) => {
          deleteLead(() => {
            resolve(true);
          });
        }).catch((err) => console.log("err", err));
      },
      onCancel() {},
    });
  };

  const deleteLead = useCallback(
    async (cb) => {
      try {
        const res = await api.post(`/leads/delete/${details._id}`, {
          col,
          search,
          order,
          activeFilter: activeFilter ? activeFilter.filter : {},
        });
        dispatch(updateTable(res.data));
        navigate("/leads");
        cb();
        message.success("Lead deleted");
      } catch (err) {
        console.log("err", err);
        message.error("Error deleting lead");
      }
    },
    [dispatch, col, search, order, activeFilter, navigate, details._id]
  );

  const confirmDeleteProject = () => {
    Modal.confirm({
      centered: true,
      title: "Delete Project",
      content: `Are you sure you want to delete this project?`,
      okText: "Delete",
      okButtonProps: { danger: true },
      onOk() {
        return new Promise((resolve, reject) => {
          deleteProject(() => {
            resolve(true);
          });
        }).catch((err) => console.log("err", err));
      },
      onCancel() {},
    });
  };

  const deleteProject = useCallback(
    async (cb) => {
      try {
        const res = await api.post(`/projects/delete/${details._id}`, {
          col,
          search,
          order,
          activeFilter: activeFilter ? activeFilter.filter : {},
        });
        dispatch(updateTable(res.data));
        navigate("/projects");
        cb();
        message.success("Project deleted");
      } catch (err) {
        console.log("err", err);
        message.error("Error deleting project");
      }
    },
    [dispatch, col, search, order, activeFilter, navigate, details._id]
  );

  const displaySalesman = () => {
    let _salesObj = details.salesObj;
    let _projectManager = details.projectManager;
    let _salesmen = "";

    _salesmen += _projectManager.name;

    for (const s of _salesObj) {
      if (!_salesmen.includes(s.name)) _salesmen += `, ${s.name}`;
    }
    return _salesmen;
  };

  const createToDo = useCallback(() => {
    dispatch(
      openForm("installTodo", {
        _projectId: details._id,
        customerId: details.customerId,
        projectAddress: details.projectAddress,
        projectAddressLat: details.projectAddressLat,
        projectAddressLng: details.projectAddressLng,
        starredImageSrc: details.starredImageSrc,
        customerName: details.customerName,
        phone: details.phone,
      })
    );
  }, [dispatch, details]);

  const openCreateLead = useCallback(() => {
    dispatch(
      openForm("createLead", {
        propertyType: details.propertyType,
        customerName: details.customerName,
        phone: details.phone,
        email: details.email,
        alternatePhone: details.alternatePhone,
        alternateEmail: details.alternateEmail,
        projectAddressStreet: details.projectAddressStreet,
        projectAddressCity: details.projectAddressCity,
        projectAddressState: details.projectAddressState,
        projectAddressZip: details.projectAddressZip,
        projectAddressPermitAuthority: details.projectAddressPermitAuthority,
        projectAddressCounty: details.projectAddressCounty,
        projectAddressLat: details.projectAddressLat,
        projectAddressLng: details.projectAddressLng,
        billingAddressStreet: details.billingAddressStreet,
        billingAddressCity: details.billingAddressCity,
        billingAddressState: details.billingAddressState,
        billingAddressZip: details.billingAddressZip,
        salesmen: details.salesObj,
        projectManager: details.projectManager,
      })
    );
  }, [dispatch, details]);

  const openCreateProject = useCallback(() => {
    dispatch(
      openForm("createProject", {
        propertyType: details.propertyType,
        customerName: details.customerName,
        phone: details.phone,
        email: details.email,
        alternatePhone: details.alternatePhone,
        alternateEmail: details.alternateEmail,
        projectAddressStreet: details.projectAddressStreet,
        projectAddressCity: details.projectAddressCity,
        projectAddressState: details.projectAddressState,
        projectAddressZip: details.projectAddressZip,
        projectAddressPermitAuthority: details.projectAddressPermitAuthority,
        projectAddressCounty: details.projectAddressCounty,
        projectAddressLat: details.projectAddressLat,
        projectAddressLng: details.projectAddressLng,
        billingAddressStreet: details.billingAddressStreet,
        billingAddressCity: details.billingAddressCity,
        billingAddressState: details.billingAddressState,
        billingAddressZip: details.billingAddressZip,
        salesmen: details.salesObj,
        projectManager: details.projectManager,
      })
    );
  }, [dispatch, details]);

  const openMateiralRequestPickup = useCallback(() => {
    dispatch(
      openForm("materialPickup", {
        _id: details._id,
        projectManager: details.projectManager,
        projectAddress: details.projectAddress,
        customerId: details.customerId,
      })
    );
  }, [dispatch, details]);

  const copied = () => {
    message.success(`Lead info copied to clipboard`);
  };

  let baseURL;
  if (process.env.NODE_ENV === "production") {
    baseURL = "https://story.gopremier.net";
  } else {
    baseURL = "http://localhost:3001";
  }

  const getContent = () => {
    return `${baseURL}${location.pathname}\n\nPO: ${details.customerId}\n${
      details.customerName
    }\n${details.phone}${details.email ? `\n${details.email}` : ""}\n${
      details.projectAddress
    }${details.notes ? `\n${details.notes}` : ""}${
      details.estimateStart
        ? `\n\nAppointment Scheduled: ${dayjs(details.estimateStart).format(
            "dddd, MMM D h:mm A"
          )}`
        : ""
    }`;
  };

  const menu = (
    <Menu>
      {!details.aerialRequested ? (
        <Menu.Item onClick={confirmAerial} key="confirm-aerial">
          <Satellite />
          <span style={{ marginLeft: 8 }}>Request Aerial</span>
        </Menu.Item>
      ) : null}

      {leadCheck() && (
        <Menu.Item key="copy-lead-info">
          <Clipboard
            option-text={getContent}
            component="div"
            onSuccess={copied}
          >
            <ClipboardText />
            <span style={{ marginLeft: 8 }}>Copy Lead Info</span>
          </Clipboard>
        </Menu.Item>
      )}

      <Menu.Item onClick={createToDo} key="create-to-do">
        <DoneAll />
        <span style={{ marginLeft: 8 }}>Create Install To-do</span>
      </Menu.Item>

      {!leadCheck() && (
        <Menu.Item
          onClick={openMateiralRequestPickup}
          key="material-pickup-request"
        >
          <DumpTruck />
          <span style={{ marginLeft: 8 }}>Request Material Pickup</span>
        </Menu.Item>
      )}

      <Menu.Item onClick={openCreateLead} key="copy-to-lead">
        <Group />
        <span style={{ marginLeft: 8 }}>Copy & Create Lead</span>
      </Menu.Item>

      {!leadCheck() && (
        <Menu.Item onClick={openCreateProject} key="copy-to-project">
          <Build />
          <span style={{ marginLeft: 8 }}>Copy & Create Project</span>
        </Menu.Item>
      )}

      {admin === "Yes" && (!leadCheck() || !details.aerialRequested) && (
        <Menu.Divider />
      )}
      {admin === "Yes" && !details.onlineReview ? (
        <Menu.Item onClick={submitReview} key="submit-review">
          <Star />
          <span style={{ marginLeft: 8 }}>Submit Review</span>
        </Menu.Item>
      ) : null}
      {admin === "Yes" && leadCheck() && (
        <Menu.Item onClick={confirmDeleteLead} key="confirm-delete-lead">
          <Delete color="#ff0000" />
          <span style={{ marginLeft: 8 }}>Delete Lead</span>
        </Menu.Item>
      )}
      {admin === "Yes" && !leadCheck() && (
        <Menu.Item onClick={confirmDeleteProject} key="confirm-delete-project">
          <Delete color="#ff0000" />
          <span style={{ marginLeft: 8 }}>Delete Project</span>
        </Menu.Item>
      )}
    </Menu>
  );

  const cancel = () => {
    handleClose(true);
  };

  return (
    <div
      className={`${styles.header} ${
        details.projectStatus === "Lead Lost" ||
        details.projectStatus === "Cancelled" ||
        details.projectStatus === "Estimate Not Sold"
          ? "lost"
          : details.projectStatus === "On Hold"
          ? "hold"
          : details.projectStatus === "Closed Out"
          ? "closed-out"
          : ""
      }`}
    >
      <Button
        type="text"
        shape="circle"
        className={`${styles.filterActions} ${styles.rightDrawerClose}`}
        onClick={cancel}
      >
        <ArrowRight color="#ffffff" />
      </Button>
      <div className={styles.title}>
        {details.projectStatus &&
        (details.projectStatus === "Sold" ||
          details.projectStatus === "Closed Out" ||
          details.projectStatus === "Cancelled") ? (
          details.permitStatus === "Pulled" ? (
            <div className={`${styles.stopLight} ${styles.go}`} />
          ) : details.permitStatus === "Not Needed" ? (
            <div className={`${styles.stopLight} ${styles.caution}`} />
          ) : (
            <div className={`${styles.stopLight} ${styles.stop}`} />
          )
        ) : null}
        {details.customerId} -{" "}
        {details.companyName
          ? `${details.companyName.toUpperCase()} (${details.customerName.toUpperCase()})`
          : details.customerName.toUpperCase()}
        <div className={styles.headerSalesmen}>{displaySalesman()}</div>
      </div>
      <Dropdown overlay={menu} trigger={["click"]}>
        <Button
          type="text"
          shape="circle"
          className={`${styles.filterActions} ${styles.rightActions}`}
        >
          <MoreVert color="#ffffff" />
        </Button>
      </Dropdown>
    </div>
  );
};

export default ViewLeadProjectHeader;
