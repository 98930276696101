import React, { useState, useEffect, memo } from "react";
import { unstable_batchedUpdates } from "react-dom";
import Input from "antd/es/input";
import Form from "antd/es/form";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Typography from "antd/es/typography";
import api from "API";
import Divider from "antd/es/divider";
import Select from "Components/Inputs/Select";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";
import InputNumberNoAutoComplete from "Components/Inputs/InputNumberNoAutoComplete";

let _mounted = false;

const RoofingFlatDetails = ({
  addedToSteep,
  setFlatTemplate = () => null,
  _pitches,
  _layers,
  hideAll,
}) => {
  const [manufacturers, setManufacturers] = useState([]);
  const [productLines, setProductLines] = useState([]);
  const [colors, setColors] = useState([]);

  const [layers, setLayers] = useState(_layers || []);
  const [existingRoof, setExistingRoof] = useState([]);
  const [pitches, setPitches] = useState(_pitches || []);
  const [roofDecking, setRoofDecking] = useState([]);
  const [slopeDesign, setSlopeDesign] = useState([]);
  const [installationTypes, setInstallationTypes] = useState([]);
  const [drainDetail, setDrainDetail] = useState([]);

  useEffect(() => {
    _mounted = true;
    fetchOptions();

    return () => {
      _mounted = false;
    };
  }, []);

  const fetchOptions = async () => {
    try {
      const res = await api.post("/dropdowns/get", {
        selects: [
          "Flat Manufacturers",
          "Flat Products",
          "Flat Colors",
          "Drain Details",
          "Flat Roof Types",
          "Flat Decking",
          "Slope Designs",
          "Layers",
          "Pitches",
          "Installation Types",
        ],
      });
      unstable_batchedUpdates(() => {
        if (_mounted) {
          setManufacturers(res.data["Flat Manufacturers"].options);
          setProductLines(res.data["Flat Products"].options);
          setColors(res.data["Flat Colors"].options);
          setDrainDetail(res.data["Drain Details"].options);
          setExistingRoof(res.data["Flat Roof Types"].options);
          setRoofDecking(res.data["Flat Decking"].options);
          setSlopeDesign(res.data["Slope Designs"].options);
          setLayers(res.data.Layers.options);
          setPitches(res.data.Pitches.options);
          setInstallationTypes(res.data["Installation Types"].options);
        }
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleProductLineChange = (value) => {
    setFlatTemplate(value);
  };

  return (
    <div style={{ display: hideAll ? "none" : "block" }}>
      <Typography.Text strong>
        {addedToSteep ? "Flat " : ""}Project Details
      </Typography.Text>
      {!addedToSteep && (
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              name="pitches"
              label="Pitches"
              rules={[{ required: true, message: "Pitches is required" }]}
            >
              <Select mode="multiple" options={pitches} id="pitches" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="stories"
              label="Stories"
              rules={[{ required: true, message: "Stories is required" }]}
            >
              <InputNoAutoComplete id="stories" />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="flatLayers"
            label="Existing Roof Layers"
            rules={[
              { required: true, message: "Existing Roof Layers is required" },
            ]}
          >
            <Select mode="multiple" options={layers} id="flatLayers" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="existingRoofType"
            label="Existing Roof Type"
            rules={[
              { required: true, message: "Existing Roof Type is required" },
            ]}
          >
            <Select
              mode="multiple"
              options={existingRoof}
              id="existingRoofType"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="roofDecking"
            label="Roof Decking"
            rules={[{ required: true, message: "Roof Decking is required" }]}
          >
            <Select mode="multiple" options={roofDecking} id="roofDecking" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="slopeDesign"
            label="Slope Design"
            rules={[{ required: true, message: "Slope Design is required" }]}
          >
            <Select mode="multiple" options={slopeDesign} id="slopeDesign" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="installationMethod"
            label="Installation Method"
            rules={[
              { required: true, message: "Installation Method is required" },
            ]}
          >
            <Select options={installationTypes} id="installationMethod" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="shingleTieIn"
            label="Shingle Tie In"
            rules={[{ required: true, message: "Shingle Tie In is required" }]}
          >
            <Select options={["Yes", "No", "N/A"]} id="shingleTieIn" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="sidingTieIn"
            label="Siding Tie In"
            rules={[{ required: true, message: "Siding Tie In is required" }]}
          >
            <Select options={["Yes", "No", "N/A"]} id="sidingTieIn" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="drainDetail"
            label="Drain Detail"
            rules={[{ required: true, message: "Drain Detail is required" }]}
          >
            <Select mode="multiple" options={drainDetail} id="drainDetail" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="parapets"
            label="Existing Parapets"
            rules={[
              { required: true, message: "Existing Parapets is required" },
              { type: "number", message: "Existing Parapets must be a number" },
            ]}
          >
            <InputNumberNoAutoComplete id="parapets" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="atticDetails"
            label="Attic Details"
            rules={[{ required: true, message: "Attic Details is required" }]}
          >
            <InputNoAutoComplete id="atticDetails" />
          </Form.Item>
        </Col>
      </Row>

      {!addedToSteep && (
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              name="projectDescription"
              label="Project Description of Work"
              rules={[
                {
                  required: true,
                  message: "Project Description of Work is required",
                },
              ]}
            >
              <Input.TextArea autoComplete="dont-use-autocomplete" rows={4} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="scopeDescription"
              label="Scope Description of Work"
              rules={[
                {
                  required: true,
                  message: "Scope Description of Work is required",
                },
              ]}
            >
              <Input.TextArea autoComplete="dont-use-autocomplete" rows={4} />
            </Form.Item>
          </Col>
        </Row>
      )}

      <Divider />
      <Typography.Text strong>
        {addedToSteep ? "Flat " : ""}Product Details
      </Typography.Text>

      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            name={addedToSteep ? "flatManufacturer" : "manufacturer"}
            label="Manufacturer"
            rules={[{ required: true, message: "Manufacturer is required" }]}
          >
            <Select
              options={manufacturers}
              id={addedToSteep ? "flatManufacturer" : "manufacturer"}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name={addedToSteep ? "flatProductLine" : "productLine"}
            label="Product Line"
            rules={[{ required: true, message: "Product Line is required" }]}
          >
            <Select
              options={productLines}
              handleSelectChange={handleProductLineChange}
              id={addedToSteep ? "flatProductLine" : "productLine"}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            name={addedToSteep ? "flatColor" : "color"}
            label="Color"
            rules={[{ required: true, message: "Color is required" }]}
          >
            <Select
              options={colors}
              id={addedToSteep ? "flatColor" : "color"}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name={addedToSteep ? "flatSquares" : "squares"}
            label="Squares"
            rules={[{ required: true, message: "Squares is required" }]}
          >
            <InputNumberNoAutoComplete
              id={addedToSteep ? "flatSquares" : "squares"}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default memo(RoofingFlatDetails);
