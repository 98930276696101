import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateDetail } from "Actions/dataActions";
import Button from "antd/es/button";
import Form from "antd/es/form";
import message from "antd/es/message";
import InputPhone from "Components/Inputs/Phone";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import api from "API";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";

const AddAlternatePhone = ({ toggleAddPhone, _id }) => {
  const dispatch = useDispatch();
  const viewableActivities = useSelector(
    (state) => state.dataState.viewableActivities
  );
  const [loading, setLoading] = useState(false);

  const onFinish = useCallback(
    async (values) => {
      try {
        setLoading(true);
        values._id = _id;
        const res = await api.post("/update/addAlternatePhone", values);

        dispatch(
          updateDetail({
            viewableActivities,
            key: "alternatePhone",
            value: res.data.value,
            activities: res.data.activities,
          })
        );

        setLoading(false);
        toggleAddPhone();
      } catch (err) {
        console.log("err", err);
        setLoading(false);
        message.error("Error submitting alternate phone");
      }
    },
    [_id, dispatch, viewableActivities, toggleAddPhone]
  );

  const onFinishFailed = (err) => {
    console.log("err", err);
  };

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      style={{ marginBottom: 16 }}
    >
      <RemoveAutocomplete />

      <Form.Item
        name="description"
        label="Description"
        rules={[{ required: true, message: "Description is required" }]}
      >
        <InputNoAutoComplete autoFocus id="description" />
      </Form.Item>

      <Form.Item
        name="contact"
        label="Phone"
        rules={[
          { required: true, message: "Phone is required" },
          { len: 14, message: "Phone must be 10 digits long" },
        ]}
      >
        <InputPhone autoComplete="add-alternate-contact" id="contact" />
      </Form.Item>

      <div className="editControls">
        <Button
          type="default"
          size="small"
          onClick={toggleAddPhone}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          htmlType="submit"
          type="primary"
          size="small"
          loading={loading}
          disabled={loading}
        >
          Submit
        </Button>
      </div>
    </Form>
  );
};

export default AddAlternatePhone;
