import React, { memo, useRef } from "react";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Divider from "antd/es/divider";
import Form from "antd/es/form";
import Grid from "antd/es/grid";
import Checkbox from "antd/es/checkbox";
import Button from "antd/es/button";
import MaterialAutoComplete from "Components/materialOrderQuote/MaterialAutoComplete";
import styles from "./styles.module.scss";
import Lock from "Icons/Lock";
import Dropdown from "antd/es/dropdown";
import Menu from "antd/es/menu";
import Remove from "Icons/Remove";
import Add from "Icons/Add";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";

const { useBreakpoint } = Grid;

const MaterialRow = ({
  field,
  index,
  form,
  handleRemove,
  handleUnlock = () => null,
  forceMobile,
}) => {
  const screens = useBreakpoint();
  const rowRef = useRef();
  const removeDisable = () => {
    handleUnlock(index);
  };

  const handleFocus = () => {
    rowRef.current.classList.add("active");
  };

  const handleBlur = () => {
    rowRef.current.classList.remove("active");
  };

  const removeRow = () => {
    handleRemove(index);
  };

  const row = form.getFieldValue(["lineItems", index]);

  const disabled =
    form.getFieldValue(["lineItems", index, "disabled"]) || false;
  const _selected =
    form.getFieldValue(["lineItems", index, "checkbox"]) || false;

  const menu = !disabled ? (
    <Menu>
      <Menu.Item className={styles.removeRow} key="1" onClick={removeRow}>
        <Remove size={18} /> Remove Row
      </Menu.Item>
    </Menu>
  ) : (
    <Menu>
      <Menu.Item key="1" onClick={removeDisable}>
        <Add size={18} /> Unlock Row
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      {(screens.xs || forceMobile) && (
        <Divider id={`materialRow${index}`} plain>{`Material ${
          index + 1
        }`}</Divider>
      )}
      <Dropdown
        getPopupContainer={(trigger) => trigger.parentNode}
        overlay={menu}
        trigger={["contextMenu"]}
        className={
          row && row.disabledUpdated && disabled ? styles.updatedDisabled : ""
        }
      >
        <Row
          gutter={forceMobile ? { xs: 0 } : { xs: 0, sm: 8, md: 12, lg: 24 }}
          key={field.key}
          style={
            screens.lg && !forceMobile
              ? { padding: "12px 0", marginBottom: 2 }
              : screens.md && !forceMobile
              ? { padding: "6px 0", marginBottom: 2 }
              : screens.sm || forceMobile
              ? { padding: "4px 0", marginBottom: 2 }
              : { padding: "12px 0 2px 0", marginBottom: 2 }
          }
          className={
            _selected
              ? styles.rowSelected
              : !screens.xs && !forceMobile
              ? styles.materialRow
              : ""
          }
          ref={rowRef}
        >
          <Col xs={2} sm={2} md={forceMobile ? 2 : 1}>
            {disabled ? (
              <Form.Item
                {...field}
                style={
                  !screens.xs || forceMobile
                    ? { marginBottom: 0 }
                    : { marginBottom: 12 }
                }
                className={styles.checkbox}
              >
                <Button
                  className={styles.button}
                  style={{ paddingRight: 4 }}
                  shape="circle"
                  icon={<Lock size={30} />}
                  type="text"
                  onClick={removeDisable}
                />
              </Form.Item>
            ) : (
              <Form.Item
                {...field}
                name={[field.name, "checkbox"]}
                fieldKey={[field.fieldKey, "checkbox"]}
                style={
                  !screens.xs || forceMobile
                    ? { marginBottom: 0 }
                    : { marginBottom: 12 }
                }
                className={styles.checkbox}
                valuePropName="checked"
                initialValue={false}
              >
                <Checkbox
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  tabIndex={-1}
                />
              </Form.Item>
            )}
          </Col>
          <Col xs={22} sm={22} md={forceMobile ? 22 : 23}>
            <Row
              gutter={
                forceMobile ? { xs: 0 } : { xs: 0, sm: 8, md: 12, lg: 24 }
              }
            >
              <Col
                xs={{ span: 22, offset: 1 }}
                sm={
                  forceMobile ? { span: 22, offset: 1 } : { span: 6, offset: 0 }
                }
              >
                <Form.Item
                  {...field}
                  name={[field.name, "material"]}
                  fieldKey={[field.fieldKey, "material"]}
                  initialValue=""
                  rules={[
                    {
                      required: !disabled,
                      message: "Material is required",
                      whitespace: !disabled,
                    },
                  ]}
                  style={
                    !screens.xs || forceMobile
                      ? { marginBottom: 0 }
                      : { marginBottom: 12 }
                  }
                  className={
                    row && row.materialUpdated ? styles.updatedValue : ""
                  }
                >
                  <MaterialAutoComplete
                    index={index}
                    form={form}
                    field={field}
                    disabled={disabled}
                    handleFocus={handleFocus}
                    handleBlur={handleBlur}
                  />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 22, offset: 1 }}
                sm={
                  forceMobile ? { span: 22, offset: 1 } : { span: 6, offset: 0 }
                }
              >
                <Form.Item
                  {...field}
                  name={[field.name, "type"]}
                  fieldKey={[field.fieldKey, "type"]}
                  initialValue=""
                  rules={[
                    {
                      required: !disabled,
                      message: "Type is required",
                      whitespace: !disabled,
                    },
                  ]}
                  style={
                    !screens.xs || forceMobile
                      ? { marginBottom: 0 }
                      : { marginBottom: 12 }
                  }
                  className={row && row.typeUpdated ? styles.updatedValue : ""}
                >
                  <InputNoAutoComplete
                    id={[field.name, "type"]}
                    placeholder="Type"
                    disabled={disabled}
                    handleFocus={handleFocus}
                    handleBlur={handleBlur}
                  />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 22, offset: 1 }}
                sm={
                  forceMobile ? { span: 22, offset: 1 } : { span: 6, offset: 0 }
                }
              >
                <Form.Item
                  {...field}
                  name={[field.name, "color"]}
                  fieldKey={[field.fieldKey, "color"]}
                  initialValue=""
                  rules={[
                    {
                      required: !disabled,
                      message: "Color is required",
                      whitespace: !disabled,
                    },
                  ]}
                  style={
                    !screens.xs || forceMobile
                      ? { marginBottom: 0 }
                      : { marginBottom: 12 }
                  }
                  className={row && row.colorUpdated ? styles.updatedValue : ""}
                >
                  <InputNoAutoComplete
                    id={[field.name, "color"]}
                    placeholder="Color"
                    disabled={disabled}
                    handleFocus={handleFocus}
                    handleBlur={handleBlur}
                  />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 22, offset: 1 }}
                sm={
                  forceMobile ? { span: 22, offset: 1 } : { span: 6, offset: 0 }
                }
              >
                <Form.Item
                  {...field}
                  name={[field.name, "quantity"]}
                  fieldKey={[field.fieldKey, "quantity"]}
                  initialValue=""
                  rules={[
                    {
                      required: !disabled,
                      message: "Quantity is required",
                      whitespace: !disabled,
                    },
                  ]}
                  className={
                    row && row.quantityUpdated
                      ? `${styles.updatedValue} ${styles.removeRow}`
                      : styles.removeRow
                  }
                  style={
                    !screens.xs || forceMobile
                      ? { marginBottom: 0 }
                      : { marginBottom: 12 }
                  }
                >
                  <InputNoAutoComplete
                    id={[field.name, "quantity"]}
                    placeholder="Quantity"
                    disabled={disabled}
                    handleFocus={handleFocus}
                    handleBlur={handleBlur}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Dropdown>
    </>
  );
};

export default memo(MaterialRow);
