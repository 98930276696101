import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setActiveRoute } from "Actions/settingsActions";
import Button from "antd/es/button";
import Typography from "antd/es/typography";
import Row from "antd/es/row";
import Col from "antd/es/col";
import styles from "./styles.module.scss";
import _404 from "Assets/404.png";

const { Title, Text } = Typography;

const NoMatch = () => {
  const dispatch = useDispatch();
  const activeRoute = useSelector((state) => state.settingsState.activeRoute);
  const navigate = useNavigate();

  if (activeRoute !== "not-found") dispatch(setActiveRoute("not-found"));

  const goBack = () => {
    navigate(-1);
  };

  const goToSignin = () => {
    navigate("/");
  };

  return (
    <Row
      type="flex"
      justify="center"
      align="middle"
      style={{ height: "100%", position: "absolute", width: "100%" }}
      className="public-background"
    >
      <Col xs={22} sm={20} md={16} lg={14} xl={12} className="text-center">
        <div className={styles.errorContainer}>
          <div
            style={{
              textAlign: "center",
              height: "calc(100% - 50px)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <img
              src={_404}
              style={{ marginBottom: 24, display: "block" }}
              alt="404"
            />
            <Title level={3}>Page Not Found</Title>
            <Text>
              Not to worry, use the links below to get back to MyCo Story
            </Text>
            <div style={{ width: 200, marginTop: 42 }}>
              <Button type="primary" onClick={goBack} style={{ float: "left" }}>
                Back
              </Button>{" "}
              <Button
                type="primary"
                onClick={goToSignin}
                style={{ float: "right" }}
              >
                Sign in
              </Button>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default NoMatch;
