import React, { useCallback, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateActivitiesOnly } from "Actions/dataActions";
import { openForm } from "Actions/formActions";
import FormControls from "Components/FormControls";
import Button from "antd/es/button";
import LikeFilled from "@ant-design/icons/LikeFilled";
import LikeOutlined from "@ant-design/icons/LikeOutlined";
import MessageOutlined from "@ant-design/icons/MessageOutlined";
import styles from "./styles.module.scss";
import Hash from "Icons/Hash";
import message from "antd/es/message";
import api from "API";

const Controls = ({
  _activityId,
  _imageSrc,
  _activityCreatedBy,
  liked,
  followers,
  image,
  hashtags,
  _customerId,
  _projectId,
  _projectStatus,
  _global,
  dashboard,
  fetchPosts,
}) => {
  const dispatch = useDispatch();
  const _id = useSelector((state) => state.dataState.details._id);
  const projectStatus = useSelector(
    (state) => state.dataState.details.projectStatus
  );
  const customerId = useSelector((state) => state.dataState.details.customerId);
  const viewableActivities = useSelector(
    (state) => state.dataState.viewableActivities
  );

  const handleLike = useCallback(async () => {
    try {
      const res = await api.post("/activity/like", {
        _id: _activityId,
        _projectId: _projectId || _id,
      });

      dispatch(
        updateActivitiesOnly({
          viewableActivities,
          salesObj: res.data.salesObj,
          activities: res.data._activities,
          _projectId: _projectId || _id,
          _global: res.data.global,
          dashboard,
        })
      );

      if (fetchPosts) fetchPosts();
    } catch (err) {
      console.log("err", err);
      message.error("Error liking post");
    }
  }, [
    _activityId,
    _projectId,
    _id,
    dispatch,
    viewableActivities,
    dashboard,
    fetchPosts,
  ]);

  const handleUnlike = useCallback(async () => {
    try {
      const res = await api.post("/activity/unlike", {
        _id: _activityId,
        _projectId: _projectId || _id,
      });

      dispatch(
        updateActivitiesOnly({
          viewableActivities,
          salesObj: res.data.salesObj,
          activities: res.data._activities,
          _projectId: _projectId || _id,
          _global: res.data.global,
          dashboard,
        })
      );

      if (fetchPosts) fetchPosts();
    } catch (err) {
      console.log("err", err);
      message.error("Error unliking post");
    }
  }, [
    _activityId,
    _projectId,
    _id,
    dispatch,
    viewableActivities,
    dashboard,
    fetchPosts,
  ]);

  const imageReply = useCallback(() => {
    dispatch(
      openForm("comment", {
        _id: _activityId,
        _projectId: _projectId || _id,
        replyTo: {
          src: _imageSrc,
          publisher: _activityCreatedBy,
        },
        customerId: _customerId || customerId,
        projectStatus: _projectStatus || projectStatus,
        dashboard,
        fetchPosts,
      })
    );
  }, [
    dispatch,
    _activityId,
    _projectId,
    _id,
    _imageSrc,
    _activityCreatedBy,
    _customerId,
    customerId,
    _projectStatus,
    projectStatus,
    dashboard,
    fetchPosts,
  ]);

  const comment = useCallback(() => {
    dispatch(
      openForm("comment", {
        _id: _activityId,
        _projectId: _projectId || _id,
        customerId: _customerId || customerId,
        projectStatus: _projectStatus || projectStatus,
        dashboard,
        fetchPosts,
      })
    );
  }, [
    dispatch,
    _activityId,
    _projectId,
    _id,
    _customerId,
    customerId,
    _projectStatus,
    projectStatus,
    dashboard,
    fetchPosts,
  ]);

  const openHashtags = useCallback(() => {
    dispatch(
      openForm("hashtags", {
        _id: _activityId,
        _projectId: _projectId || _id,
        hashtags,
        dashboard,
        fetchPosts,
      })
    );
  }, [dispatch, _activityId, _projectId, _id, hashtags, dashboard, fetchPosts]);

  return (
    <FormControls
      className={`${styles.actions} ${_global ? styles._global : ""}`}
    >
      {!image && (
        <Button block size="large" onClick={liked ? handleUnlike : handleLike}>
          {liked ? (
            <LikeFilled style={{ color: "#108ee9" }} />
          ) : (
            <LikeOutlined />
          )}
        </Button>
      )}

      <Button block size="large" onClick={image ? imageReply : comment}>
        <MessageOutlined />
      </Button>

      {!image && !_global && (
        <Button block size="large" onClick={openHashtags}>
          <Hash size={18} />
        </Button>
      )}
    </FormControls>
  );
};

export default memo(Controls);
