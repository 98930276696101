import React, { useState, useEffect, useCallback } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  updateCompletionCertificatesActivitiesAllMediaAndHashtags,
  // openImageViewer,
} from "Actions/dataActions";
import { openForm, updateFormDetails } from "Actions/formActions";
import message from "antd/es/message";
import api from "API";
import Form from "antd/es/form";
import FormBody from "Components/FormBody";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import Grid from "antd/es/grid";
import Button from "antd/es/button";
import SearchLeadsAndProjects from "Components/Inputs/SearchLeadsAndProjects";
import FormControls from "Components/FormControls";
import Row from "antd/es/row";
import Col from "antd/es/col";
import TopDetails from "./TopDetails";
import Add from "Icons/Add";
import currencyFormatter from "Utils/currencyFormatter";
import LineItem from "./LineItem";
import Remove from "Icons/Remove";
import sumBy from "Utils/sumBy";
import NotesTermsConditions from "./NotesTermsConditions";
// import InvoiceInfo from "./InvoiceInfo";
import Typography from "antd/es/typography";
import styles from "./styles.module.scss";
import Alert from "antd/es/alert";
import Eye from "Icons/Eye";
import Email from "Icons/Email";
import Printer from "Icons/Printer";
import openInNewTab from "Utils/openInNewTab";
import printJS from "print-js";
import Modal from "antd/es/modal";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";
import CancelButton from "Components/CancelButton";
// import HorizontalScroll from "Components/HorizontalScroll";
// import isPdf from "Utils/isPdf";
// import isVideo from "Utils/isVideo";
// import YouTube from "Icons/YouTube";
// import LazyLoad from "Components/LazyLoad";
// import Card from "antd/es/card";
// import pdf from "Assets/pdf.svg";
// import hidePdfControls from "Utils/hidePdfControls";
// import ImageFallback from "Components/images/Image";
import ProcessFeedback from "Components/ProcessFeedback";
// import InfiniteScroll from "react-infinite-scroll-component";
// import Loader from "Components/Loader";
// import Activity from "Components/Activities/Activity";

const messageKey = "messageKey";

const steps = [
  "Submitting Form",
  "Generating PDF",
  "Creating Post",
  "Saving Summary",
  "Saving PDF",
  "Gathering All Media",
  "Done!",
];

const { useBreakpoint } = Grid;

const CompletionCertificate = ({
  unsavedChanges,
  setUnsavedChanges,
  handleClose,
  type,
  setTitle,
}) => {
  const formDetails = useSelector(
    (state) => state.formState.completionCertificate.formDetails
  );
  const dispatch = useDispatch();
  const details = useSelector((state) => state.dataState.details);
  // const collections = useSelector((state) => state.dataState.collections);
  // const expenses = useSelector((state) => state.dataState.expenses);
  // const scopes = useSelector((state) => state.dataState.scopes);
  // const invoices = useSelector((state) => state.dataState.invoices);
  // const actions = useSelector((state) => state.dataState.actions);
  // const documents = useSelector((state) => state.dataState.documents);
  const viewableActivities = useSelector(
    (state) => state.dataState.viewableActivities
  );
  const screens = useBreakpoint();
  const [loading, setLoading] = useState(false);
  const [balance, setBalance] = useState(
    formDetails.balance ? formDetails.balance : 0
  );
  const [_invoiceId, _setInvoiceId] = useState(
    formDetails.customerId ? formDetails.customerId : null
  );
  const [selected, setSelected] = useState(false);
  const [_count, _setCount] = useState(
    formDetails.count ? formDetails.count : 0
  );
  const [editing, setEditing] = useState(
    formDetails.editing ? formDetails.editing : false
  );
  const [_details, _setDetails] = useState({
    // billingAddressStreet: formDetails.billingAddressCity
    //   ? formDetails.billingAddressStreet
    //   : details.billingAddressStreet,
    // billingAddressCity: formDetails.billingAddressCity
    //   ? formDetails.billingAddressCity
    //   : details.billingAddressCity,
    // billingAddressState: formDetails.billingAddressState
    //   ? formDetails.billingAddressState
    //   : details.billingAddressState,
    // billingAddressZip: formDetails.billingAddressZip
    //   ? formDetails.billingAddressZip
    //   : details.billingAddressZip,
    email: formDetails.email ? formDetails.email : details.email,
    claimNumber: formDetails.claimNumber
      ? formDetails.claimNumber
      : details.claimNumber,
    customerName: formDetails.customerName
      ? formDetails.customerName
      : details.customerName,
    companyName: formDetails.companyName
      ? formDetails.companyName
      : details.companyName,
    projectManager: formDetails.projectManager
      ? formDetails.projectManager
      : details.projectManager,
    projectAddressStreet: formDetails.projectAddressStreet
      ? formDetails.projectAddressStreet
      : details.projectAddressStreet,
    projectAddressCity: formDetails.projectAddressCity
      ? formDetails.projectAddressCity
      : details.projectAddressCity,
    projectAddressState: formDetails.projectAddressState
      ? formDetails.projectAddressState
      : details.projectAddressState,
    projectAddressZip: formDetails.projectAddressZip
      ? formDetails.projectAddressZip
      : details.projectAddressZip,
    customerId: formDetails.customerId
      ? formDetails.customerId
      : details.customerId,
    _projectId: formDetails._projectId ? formDetails._projectId : details._id,
    // contractPrice: details.contractPrice,
    lineItems: formDetails.lineItems ? formDetails.lineItems : [{}],
    _activityId: formDetails._activityId ? formDetails._activityId : undefined,
    label: formDetails.label ? formDetails.label : undefined,
  });
  // const [_invoices, _setInvoices] = useState([]);
  // const [_collections, _setCollections] = useState([]);
  // const [_expenses, _setExpenses] = useState(expenses);
  // const [_scopes, _setScopes] = useState(scopes);
  // const [_actions, _setActions] = useState(actions);
  // const [scrollContainer, setScrollContainer] = useState(null);
  // const [posts, setPosts] = useState([]);
  // const [postsLength, setPostsLength] = useState(0);

  const _user = useSelector((state) => state.authState._user);
  const [feedBack, setFeedBack] = useState({
    active: "Submitting Form",
    progress: 0,
  });

  const [form] = Form.useForm();
  // const documentRef = useRef();

  useEffect(() => {
    if (!formDetails.editing) {
      setTitle("Create Invoice Summary");
    } else {
      setTitle(
        <div style={{ marginRight: 28 }}>
          Edit Invoice Summary{" "}
          <Button
            size="small"
            danger
            onClick={confirmDeleteInvoice}
            style={{ float: "right" }}
          >
            Delete
          </Button>
        </div>
      );
    }
    // fetchPosts();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!editing) fetchInvoiceDetails();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   _setActions(actions);
  // }, [actions]);

  const fetchInvoiceDetails = async (_id) => {
    try {
      const res = await api.get(
        `/completion-certificate/details/${_details._projectId || _id}`
      );
      unstable_batchedUpdates(() => {
        _setInvoiceId(`${_details.customerId}-${res.data._count}`);
        _setCount(res.data._count);
        _setDetails({
          ..._details,
          // billingAddressStreet: res.data._details.billingAddressStreet,
          // billingAddressCity: res.data._details.billingAddressCity,
          // billingAddressState: res.data._details.billingAddressState,
          // billingAddressZip: res.data._details.billingAddressZip,
          email: res.data._details.email,
          claimNumber: res.data._details.claimNumber,
          customerName: res.data._details.customerName,
          companyName: res.data._details.companyName,
          projectManager: res.data._details.projectManager,
          projectAddressStreet: res.data._details.projectAddressStreet,
          projectAddressCity: res.data._details.projectAddressCity,
          projectAddressState: res.data._details.projectAddressState,
          projectAddressZip: res.data._details.projectAddressZip,
          customerId: res.data._details.customerId,
          _projectId: res.data._details._id,
          // contractPrice: res.data._details.contractPrice,
        });
        // _setCollections(res.data._collections);
        // _setInvoices(res.data._invoices);
        // _setExpenses(res.data._expenses);
        // _setScopes(res.data._scopes);
        // _setActions(res.data._actions);
      });

      const tempLineItems = [];

      for (const invoice of res.data._invoices) {
        for (const lineItem of invoice.lineItems) {
          tempLineItems.push({
            description: `${invoice.customerId} - ${lineItem.description}`,
            amount: lineItem.amount,
          });
        }
      }

      for (const collection of res.data._collections) {
        console.log("collection", collection);
        tempLineItems.push({
          description: `${collection.customerId} - Payment Received`,
          amount: collection.amount * -1,
        });
      }

      form.setFieldsValue({
        // billingAddressStreet: res.data._details.billingAddressStreet,
        // billingAddressCity: res.data._details.billingAddressCity,
        // billingAddressState: res.data._details.billingAddressState,
        // billingAddressZip: res.data._details.billingAddressZip,
        email: res.data._details.email,
        claimNumber: res.data._details.claimNumber,
        customerName: res.data._details.customerName,
        companyName: res.data._details.companyName,
        lineItems: tempLineItems,
      });

      updateBalance();
    } catch (err) {
      console.log("err", err);
    }
  };

  const updateFeedBack = (feedBack) => {
    setFeedBack(feedBack);
  };

  const onFinish = useCallback(
    async (values) => {
      try {
        window.socket.on(`${_user}-certificate-progress`, updateFeedBack);
        unstable_batchedUpdates(() => {
          setLoading(true);
          setFeedBack({
            active: "Submitting Form",
            progress: 1,
          });
        });

        let _detailsChange = [];

        // if (_details.customerName !== values.customerName)
        //   _detailsChange.push("customerName");
        if (_details.claimNumber !== values.claimNumber)
          _detailsChange.push("claimNumber");
        if (_details.email !== values.email) _detailsChange.push("email");
        // if (_details.billingAddressStreet !== values.billingAddressStreet)
        //   _detailsChange.push("billingAddressStreet");
        // if (_details.billingAddressCity !== values.billingAddressCity)
        //   _detailsChange.push("billingAddressCity");
        // if (_details.billingAddressState !== values.billingAddressState)
        //   _detailsChange.push("billingAddressState");
        // if (_details.billingAddressZip !== values.billingAddressZip)
        //   _detailsChange.push("billingAddressZip");

        // values.surCharge = balance * 0.03;
        // values.balancePlusSurcharge = balance + balance * 0.03;
        values.customerName = _details.customerName;
        values.companyName = _details.companyName;

        values._prevDetails = _details;
        values._detailsChange = _detailsChange;
        values.balance = balance;
        values.customerId = _invoiceId;
        values._projectId = _details._projectId;
        values._activityId = _details._activityId;
        values.count = _count;
        values.projectAddressStreet = _details.projectAddressStreet;
        values.projectAddressCity = _details.projectAddressCity;
        values.projectAddressState = _details.projectAddressState;
        values.projectAddressZip = _details.projectAddressZip;
        values.projectManager = _details.projectManager;
        values.transactionDate = new Date();
        values.type = "Invoice";
        values.version = !editing
          ? 1
          : formDetails.version
          ? formDetails.version + 1
          : 2;

        // message.loading("Saving Invoice...", 0);
        message.loading({
          content: "Saving summary...",
          duration: 0,
          key: messageKey,
        });
        let res;
        if (!editing) {
          res = await api.post("/completion-certificate/create", values);
        } else {
          values._id = formDetails._id;
          res = await api.post("/completion-certificate/edit", values);
        }

        // message.success("Invoice saved");
        message.success({
          content: "Summary saved",
          duration: 1.5,
          key: messageKey,
        });

        dispatch(
          updateFormDetails("completionCertificate", formDetails, {
            src: res.data._thisInvoice.src,
            editing: true,
          })
        );

        dispatch(
          updateCompletionCertificatesActivitiesAllMediaAndHashtags({
            viewableActivities,
            _projectId: _details._projectId,
            data: res.data,
          })
        );

        unstable_batchedUpdates(() => {
          // _setInvoices(res.data._invoices);
          setEditing(true);
          setUnsavedChanges(false);
          setFeedBack({
            active: "Done!",
            progress: 100,
          });
          setLoading(false);
        });

        window.socket.removeAllListeners(`${_user}-certificate-progress`);
      } catch (err) {
        if (
          err &&
          err.errorFields &&
          err.errorFields[0] &&
          err.errorFields[0].name
        ) {
          if (err.errorFields[0].name[0] === "lineItems") {
            const el = document.getElementById("top-of-form");
            el.scrollIntoView({ block: "end", behavior: "smooth" });
          } else {
            form.scrollToField(err.errorFields[0].name[0]);
          }
        }
        console.log("err", err);
        // message.error("An error occured while saving invoice");
        message.error({
          content: "Error saving summary",
          duration: 1.5,
          key: messageKey,
        });
        setLoading(false);
        setFeedBack({
          active: "Submitting Form",
          progress: 0,
        });
        window.socket.removeAllListeners(`${_user}-certificate-progress`);
      }
    },
    [
      _user,
      _count,
      _details,
      _invoiceId,
      balance,
      dispatch,
      editing,
      form,
      formDetails,
      setUnsavedChanges,
      viewableActivities,
    ]
  );

  const onFinishFailed = (err) => {
    console.log("err", err);
    if (
      err &&
      err.errorFields &&
      err.errorFields[0] &&
      err.errorFields[0].name
    ) {
      if (err.errorFields[0].name[0] === "lineItems") {
        const el = document.getElementById("top-of-form");
        el.scrollIntoView({ block: "end", behavior: "smooth" });
      } else {
        form.scrollToField(err.errorFields[0].name[0]);
      }
    }
    setLoading(false);
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  const onFieldsChange = async (fields) => {
    try {
      if (fields.length > 0 && fields[0].name[2] === "checkbox") {
        let lineItems = await form.getFieldValue("lineItems");
        if (lineItems) {
          let arr = [];
          let cnt = 0;
          for (const m of lineItems) {
            if (m.checkbox) {
              arr.push(cnt);
              cnt++;
            }
          }
          if (arr.length === 0) arr = undefined;
          setSelected(arr);
        }
      }
    } catch (err) {
      console.log("onFieldsChange err", err);
    }
  };

  const handleSelect = (selected) => {
    fetchInvoiceDetails(selected._id);
  };

  const removeRow = async () => {
    try {
      let lineItems = await form.getFieldValue("lineItems");
      lineItems = lineItems.filter((m, i) => {
        if (!m.checkbox) {
          return true;
        }
        return false;
      });
      lineItems.forEach((m, i) => {
        if (m.checkbox) {
          m.disabled = true;
          m.checkbox = false;
        }
      });
      form.setFieldsValue({
        lineItems,
      });
      setSelected(undefined);
      updateBalance();
    } catch (err) {
      console.log("removeRow err", err);
    }
  };

  const updateBalance = async () => {
    try {
      // setTimeout(() => {
      let lineItems = await form.getFieldValue("lineItems");
      let _balance = sumBy(lineItems, "amount");
      setBalance(_balance);
      // });
    } catch (err) {
      console.log("updateBalance err", err);
    }
  };

  const printInvoice = () => {
    printJS({
      printable: `${formDetails.src}?${new Date().getTime()}`,
      type: "pdf",
    });
  };

  const openEmail = useCallback(() => {
    dispatch(
      openForm("email", {
        content: [
          {
            _id: formDetails.src,
            src: formDetails.src,
            _projectId: _details._projectId,
            type: "completion-certificate",
          },
        ],
      })
    );
  }, [dispatch, formDetails, _details]);

  const openInvoice = () => {
    openInNewTab(formDetails.src);
  };

  const confirmDeleteInvoice = () => {
    Modal.confirm({
      zIndex: 4002,
      icon: <QuestionCircleOutlined />,
      centered: true,
      cancelText: "Cancel",
      okText: "Delete",
      okButtonProps: { danger: true },
      content: <div>Are you sure you want to delete this summary?</div>,
      onOk() {
        return new Promise((resolve, reject) => {
          deleteInvoice(() => resolve(true));
        }).catch((err) => console.log("err: ", err));
      },
      onCancel() {},
    });
  };

  const deleteInvoice = useCallback(
    async (cb) => {
      try {
        const res = await api.post("/completion-certificate/delete", {
          _id: formDetails._id,
          _activityId: _details._activityId,
        });
        // dispatch(
        //   updateInvoicesActivitiesAllMediaAndHashtags({
        //     viewableActivities,
        //     _projectId: formDetails._projectId,
        //     data: res.data,
        //   })
        // );

        dispatch(
          updateCompletionCertificatesActivitiesAllMediaAndHashtags({
            viewableActivities,
            _projectId: formDetails._projectId,
            data: res.data,
          })
        );

        cb();
        handleClose(true);
      } catch (err) {
        console.log("err", err);
        message.error("Error deleting summary");
      }
    },
    [
      formDetails,
      _details._activityId,
      dispatch,
      viewableActivities,
      handleClose,
    ]
  );

  // const fetchPosts = async () => {
  //   try {
  //     const res = await api.get(
  //       `/posts/${_details._projectId}/${posts.length}`
  //     );
  //     unstable_batchedUpdates(() => {
  //       setPosts((_posts) => {
  //         return [..._posts, ...res.data.posts];
  //       });
  //       setPostsLength(res.data.postsLength);
  //     });
  //   } catch (err) {
  //     console.log("err", err);
  //   }
  // };

  // const rowRenderer = () => {
  //   return posts.map((fp) => {
  //     return (
  //       <div id={`invoice-post-${fp._id}`} key={`invoice-post-${fp._id}`}>
  //         <Activity activity={fp} scrollContainer={"#invoice-post-container"} />
  //       </div>
  //     );
  //   });
  // };

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      className="form"
      onValuesChange={onValuesChange}
      onFieldsChange={onFieldsChange}
      style={{
        maxHeight: type === "modal" ? "calc((100vh - 100px) - 55px)" : "unset",
      }}
      initialValues={{
        label: _details.label,
        customerName: _details.customerName,
        companyName: _details.companyName,
        claimNumber: _details.claimNumber,
        email: _details.email,
        // billingAddressStreet: _details.billingAddressStreet,
        // billingAddressCity: _details.billingAddressCity,
        // billingAddressState: _details.billingAddressState,
        // billingAddressZip: _details.billingAddressZip,
        // dueDate: "Due upon receipt",
        notes:
          "All work completed\nThank you for choosing Premier Roofing & Exteriors!",
        tNc: "Payment is due upon receipt of invoice\nMake all checks payable to Premier Roofing\n\nDelinquent payments are subject to 1.5% monthly surcharge",
        lineItems: _details.lineItems,
      }}
    >
      <RemoveAutocomplete />

      {!_details._projectId ? (
        <SearchLeadsAndProjects
          handleSelect={handleSelect}
          leads={false}
          disableLastViewed={true}
        />
      ) : (
        <>
          <div
            className={feedBack.progress !== 0 ? "hide" : ""}
            style={{ height: "calc(100% - 53px)" }}
          >
            <Row style={{ height: "100%" }}>
              <Col
                xs={24}
                sm={24}
                // md={16}
                style={{ height: "100%" }}
              >
                <FormBody style={{ height: "100%" }}>
                  <div
                    className="content-inner"
                    // ref={setScrollContainer}
                    style={{
                      paddingLeft: 24,
                      paddingTop: 24,
                      paddingRight: 24,
                      paddingBottom: 8,
                      height: "100%",
                    }}
                  >
                    <div id="top-of-form" />

                    <TopDetails form={form} />

                    {/* {documents.length > 0 && (
                      <>
                        <Typography.Title level={4}>Documents</Typography.Title>

                        <HorizontalScroll
                          ref={documentRef}
                          scrollContainer={scrollContainer}
                          style={{ marginBottom: 16 }}
                        >
                          {documents.map((media) => (
                            <Document
                              key={media._id}
                              media={media}
                              scrollContainer={documentRef}
                            />
                          ))}
                        </HorizontalScroll>
                      </>
                    )} */}

                    {/* <InvoiceInfo
                      invoices={_invoices}
                      collections={_collections}
                      scopes={_scopes}
                      contractPrice={_details.contractPrice}
                      expenses={_expenses}
                      // actions={_actions}
                    /> */}

                    <Typography.Title level={4}>Line Items</Typography.Title>

                    <Form.List name="lineItems">
                      {(fields, { add, remove }) => {
                        const handleAdd = () => {
                          add();
                        };

                        return (
                          <>
                            {fields.map((field, index) => (
                              <LineItem
                                key={field.key}
                                fields={fields}
                                field={field}
                                index={index}
                                form={form}
                                selected={selected}
                                updateBalance={updateBalance}
                                // scopes={_scopes}
                              />
                            ))}

                            <Row gutter={{ xs: 8, sm: 8, md: 12, lg: 24 }}>
                              {!selected ? (
                                <>
                                  <Col xs={24}>
                                    <Form.Item style={{ marginRight: 0 }}>
                                      <Button
                                        className={`${styles.button} green`}
                                        style={
                                          screens.lg
                                            ? { marginTop: 12 }
                                            : screens.md
                                            ? { marginTop: 6 }
                                            : { marginTop: 12 }
                                        }
                                        type="primary"
                                        onClick={handleAdd}
                                        block
                                      >
                                        <Add size={18} /> Add Line
                                      </Button>
                                    </Form.Item>
                                  </Col>
                                </>
                              ) : (
                                <>
                                  <Col xs={24}>
                                    <Form.Item>
                                      <Button
                                        style={
                                          screens.lg
                                            ? { marginTop: 12 }
                                            : screens.md
                                            ? { marginTop: 6 }
                                            : { marginTop: 12 }
                                        }
                                        danger
                                        type="primary"
                                        onClick={removeRow}
                                        block
                                        className={styles.button}
                                      >
                                        <Remove size={18} /> Remove Selected
                                      </Button>
                                    </Form.Item>
                                  </Col>
                                </>
                              )}
                            </Row>

                            <Row gutter={{ xs: 8, sm: 8, md: 12, lg: 24 }}>
                              <Col xs={24}>
                                <Alert
                                  style={{ marginBottom: 16 }}
                                  message={
                                    <>
                                      <Typography.Title
                                        className={styles.balance}
                                        level={4}
                                      >
                                        Balance Due:{" "}
                                        {currencyFormatter(balance)}
                                      </Typography.Title>
                                      {/* <Typography.Text
                                        className={styles.balance}
                                        type="secondary"
                                      >
                                        Surcharge:{" "}
                                        {currencyFormatter(balance * 0.03)}
                                      </Typography.Text>
                                      <Typography.Text
                                        className={styles.balance}
                                        strong
                                      >
                                        Balance if paying w/ Credit Card:{" "}
                                        {currencyFormatter(
                                          balance + balance * 0.03
                                        )}
                                      </Typography.Text> */}
                                    </>
                                  }
                                />
                              </Col>
                            </Row>
                          </>
                        );
                      }}
                    </Form.List>
                    <NotesTermsConditions />
                  </div>
                  {/* <div
              style={{ height: "100%" }}
              className={feedBack.progress === 0 ? "hide" : ""}
            >
              <ProcessFeedback feedBack={feedBack} steps={steps} />
            </div> */}
                  {/* </div> */}
                </FormBody>
              </Col>
              {/* <Col xs={0} sm={0} md={8} style={{ height: "100%" }}>
                <div
                  id="invoice-post-container"
                  style={{
                    height: "100%",
                    padding: 8,
                    background: "rgb(235, 235, 235)",
                  }}
                  className="content-inner"
                >
                  <InfiniteScroll
                    dataLength={posts.length} //This is important field to render the next data
                    next={fetchPosts}
                    hasMore={posts.length !== postsLength}
                    loader={
                      <div style={{ width: "100%" }}>
                        <Loader minHeight="40px" />
                      </div>
                    }
                    endMessage={null}
                    scrollableTarget="invoice-post-container"
                  >
                    {rowRenderer()}
                  </InfiniteScroll>
                </div>
              </Col> */}
            </Row>
          </div>
          <div
            style={{ height: "100%" }}
            className={feedBack.progress === 0 ? "hide" : ""}
          >
            <ProcessFeedback feedBack={feedBack} steps={steps} />
          </div>
        </>
      )}

      {_details._projectId && _details.customerId && (
        <FormControls className="sticky-footer">
          <CancelButton
            handleClick={handleClose}
            title={feedBack.progress !== 100 ? "Cancel" : "Close"}
          />

          {formDetails.src && !unsavedChanges && editing && (
            <>
              <Button
                type="link"
                shape="circle"
                onClick={openInvoice}
                style={{ marginRight: 8 }}
              >
                <Eye />
              </Button>
              <Button
                type="link"
                shape="circle"
                onClick={openEmail}
                style={{ marginRight: 8 }}
              >
                <Email />
              </Button>
              <Button
                type="link"
                shape="circle"
                onClick={printInvoice}
                style={{ marginRight: 8 }}
              >
                <Printer />
              </Button>
            </>
          )}
          {feedBack.progress === 0 && (
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={loading || !unsavedChanges}
            >
              Submit
            </Button>
          )}
        </FormControls>
      )}
    </Form>
  );
};

// const Document = ({ media, scrollContainer }) => {
//   const dispatch = useDispatch();

//   const type = isPdf(media.src)
//     ? "pdf"
//     : isVideo(media.src)
//     ? "video"
//     : "image";

//   const handlePdfClick = () => {
//     openInNewTab(media.src);
//   };

//   const handleImageOrVideoClick = useCallback(() => {
//     media.viewOnly = true;
//     media.link = true;
//     dispatch(openImageViewer(media));
//   }, [media, dispatch]);

//   return (
//     <Card
//       bordered={false}
//       hoverable
//       className={styles.activity}
//       bodyStyle={{ padding: 0 }}
//       style={{ padding: 0 }}
//       cover={
//         <>
//           <div
//             className={styles.clickOverlay}
//             onClick={type === "pdf" ? handlePdfClick : handleImageOrVideoClick}
//           />
//           {type === "pdf" ? (
//             <LazyLoad
//               offsetHorizontal={600}
//               height="240px"
//               root={scrollContainer}
//             >
//               <object data={hidePdfControls(media.src)} className={styles.pdf}>
//                 <div>
//                   <img src={pdf} alt="pdf-icon" />
//                   <div>{media.name}</div>
//                 </div>
//               </object>
//             </LazyLoad>
//           ) : type === "image" ? (
//             <LazyLoad
//               offsetHorizontal={600}
//               height="240px"
//               root={scrollContainer}
//             >
//               <ImageFallback alt="invoice" image={media} />
//             </LazyLoad>
//           ) : (
//             <LazyLoad
//               offsetHorizontal={600}
//               height="240px"
//               root={scrollContainer}
//             >
//               <div className={styles.video}>
//                 <YouTube color="#f1f1f1" size={64} />
//                 <div>{media.name}</div>
//               </div>
//             </LazyLoad>
//           )}
//         </>
//       }
//     ></Card>
//   );
// };

export default CompletionCertificate;
