import React from "react";
import Card from "antd/es/card";
import Col from "antd/es/col";
import Modal from "antd/es/modal";
import dayjs from "dayjs";
import openInNewTab from "Utils/openInNewTab";
import Delete from "Icons/Delete";
import Eye from "Icons/Eye";
import api from "API";
import message from "antd/es/message";
import Button from "antd/es/button";

import csv from "Icons/file-icons/CSV-Basic.svg";
import doc from "Icons/file-icons/DOC-Basic.svg";
import pdf from "Assets/pdf.svg";
import rtf from "Icons/file-icons/RTF-Basic.svg";
import txt from "Icons/file-icons/TXT-Basic.svg";
import xls from "Icons/file-icons/XLS-Basic.svg";

import bmp from "Icons/file-icons/BMP-Basic.svg";
import gif from "Icons/file-icons/GIF-Basic.svg";
import jpeg from "Icons/file-icons/JPEG-Basic.svg";
import jpg from "Icons/file-icons/JPG-Basic.svg";
import png from "Icons/file-icons/PNG-Basic.svg";
import tiff from "Icons/file-icons/TIFF-Basic.svg";

import styles from "./styles.module.scss";

import useIsMobile from "Hooks/useIsMobile";

const Meta = Card.Meta;
const confirm = Modal.confirm;
var re = /(?:\.([^.]+))?$/;

const Document = ({ id, name, document, dateUploaded, admin }) => {
  const mobile = useIsMobile();

  const openDocument = () => {
    openInNewTab(document.src);
  };

  const deleteDocument = async (callback) => {
    try {
      await api.delete(`/office-documents/delete/${id}`);
      callback();
    } catch (err) {
      console.log("err", err);
      message.error("Error deleting document");
    }
  };

  const showConfirm = () => {
    confirm({
      centered: true,
      content: "Are you sure you want to delete this document?",
      onOk() {
        return new Promise((resolve, reject) => {
          deleteDocument(() => {
            resolve(true);
          });
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() {},
    });
  };

  let imageIcon;
  const ext = re.exec(document.src)[1].toLowerCase();
  if (ext === "bmp") {
    imageIcon = bmp;
  } else if (ext === "csv") {
    imageIcon = csv;
  } else if (ext === "doc") {
    imageIcon = doc;
  } else if (ext === "docx") {
    imageIcon = doc;
  } else if (ext === "gif") {
    imageIcon = gif;
  } else if (ext === "jpeg") {
    imageIcon = jpeg;
  } else if (ext === "jpg") {
    imageIcon = jpg;
  } else if (ext === "pdf") {
    imageIcon = pdf;
  } else if (ext === "png") {
    imageIcon = png;
  } else if (ext === "rtf") {
    imageIcon = rtf;
  } else if (ext === "tiff") {
    imageIcon = tiff;
  } else if (ext === "txt") {
    imageIcon = txt;
  } else if (ext === "xls") {
    imageIcon = xls;
  }

  return (
    <Col
      xs={24}
      sm={6}
      md={4}
      data-doc={name}
      className={`${styles.document} document`}
    >
      <Card
        bordered={false}
        className="z-depth-1"
        hoverable
        cover={<img src={imageIcon} alt="file-icon" />}
        actions={
          mobile
            ? admin === "Yes"
              ? [
                  <div onClick={openDocument}>
                    <Eye />
                  </div>,
                  <div onClick={showConfirm}>
                    <Delete />
                  </div>,
                ]
              : [
                  <div onClick={openDocument}>
                    <Eye />
                  </div>,
                ]
            : null
        }
      >
        <Meta
          title={name}
          description={dayjs(new Date(dateUploaded)).format("MMM D, YYYY")}
        />
        {!mobile && (
          <div className={styles.hoverContainer}>
            <Button
              size="large"
              type="text"
              shape="circle"
              onClick={openDocument}
            >
              <Eye />
            </Button>
            {admin === "Yes" && (
              <Button
                size="large"
                type="text"
                shape="circle"
                onClick={showConfirm}
              >
                <Delete />
              </Button>
            )}
          </div>
        )}
      </Card>
    </Col>
  );
};

export default Document;
