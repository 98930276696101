import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import statusObj from "Constants/status";
import styles from "./styles.module.scss";
import Tag from "antd/es/tag";
import Divider from "antd/es/divider";
import Button from "antd/es/button";

const DuplicateList = ({ data, index, handleClose }) => {
  const admin = useSelector((state) => state.authState.admin);
  const manager = useSelector((state) => state.authState.manager);
  const _user = useSelector((state) => state.authState._user);
  const navigate = useNavigate();

  const isLead = (status) => {
    if (status === "Lead" || status === "Lead Lost" || status === "Estimate") {
      return true;
    }
    return false;
  };

  const openLeadOrProject = () => {
    navigate(
      `/${isLead(data.projectStatus) ? "leads" : "projects"}/view/details/${
        data._id
      }`
    );
    handleClose(true);
  };

  return (
    <>
      {index !== 0 && <Divider />}
      <div>
        <b>{data.customerId}</b>{" "}
        <Tag className={styles.statusTag} color={statusObj[data.projectStatus]}>
          {data.projectStatus}
        </Tag>
      </div>
      <div className={styles.detail}>
        <b>Customer Name:</b> {data.customerName}
      </div>
      <div className={styles.detail}>
        <b>Project Address:</b> {data.projectAddress}
      </div>
      <div className={styles.detail}>
        <b>Project Owner:</b> {data.projectManager.name}
      </div>
      {(admin === "Yes" ||
        manager === "Yes" ||
        data.salesObj.find((s) => s._user === _user)) && (
        <Button
          type="primary"
          className="green"
          style={{ marginTop: 12 }}
          block
          onClick={openLeadOrProject}
        >
          View Lead/Project
        </Button>
      )}
    </>
  );
};

export default DuplicateList;
