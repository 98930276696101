import React, { useEffect, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import Grid from "antd/es/grid";
import Table from "Components/Table";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as Vlist } from "react-window";
import ViewContainer from "Components/ViewContainer";
import ViewLead from "Screens/ViewLead";
import ViewProject from "Screens/ViewProject";
import usePrevious from "Hooks/usePrevious";
import useViewShow from "Hooks/useViewShow";
import useIsMobile from "Hooks/useIsMobile";
import Card from "antd/es/card";
import List from "antd/es/list";
import Modal from "antd/es/modal";
import currencyFormatter from "Utils/currencyFormatter";
import percentFormatter from "Utils/percentFormatter";
import Divider from "antd/es/divider";
import dayjs from "dayjs";

import message from "antd/es/message";
import api from "API";
import TableRow from "./TableRow";

import csv from "Utils/csv";

const { useBreakpoint } = Grid;

const TableView = ({ handleSwitchView, reportToFetch, viewingStats }) => {
  const screens = useBreakpoint();
  const mobile = useIsMobile();
  const prevViewingStats = usePrevious(viewingStats);
  const prevScreens = usePrevious(screens);
  const showLead = useViewShow(3, "leads", 4, "view");
  const showProject = useViewShow(3, "projects", 4, "view");
  const [itemSize, setItemSize] = useState(135);
  const [rowType, setRowType] = useState(undefined);
  const [headers, setHeaders] = useState([]);

  const [fetching, setFetching] = useState(true);
  const [rows, setRows] = useState([]);

  const [showingBreakdown, setShowingBreakdown] = useState(undefined);

  useEffect(() => {
    if (viewingStats && !prevViewingStats) {
      setFetching(true);
      setRows([]);
      setRowType(undefined);
    }
  }, [viewingStats, prevViewingStats]);

  useEffect(() => {
    if (reportToFetch && !viewingStats && prevViewingStats) {
      populateTable();
    }
  }, [reportToFetch, viewingStats, prevViewingStats]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (prevScreens && screens) {
      if (prevScreens.md && !screens.md) {
        setItemSize(135);
      } else if (!prevScreens.md && screens.md) {
        setItemSize(47);
      }
    }
  }, [screens, prevScreens]);

  const populateTable = async () => {
    try {
      const res = await api.post("/reports-dashboard-admin/get-data", {
        reportToFetch,
      });
      unstable_batchedUpdates(() => {
        setRows(res.data.data);
        setRowType(res.data.type);
        setHeaders(res.data.headers);
        setFetching(false);
      });
    } catch (err) {
      console.log("populateTable err", err);
      message.error("Error fetching data");
    }
  };

  const exportFunc = async () => {
    try {
      const res = await api.post("/reports-dashboard-admin/get-data", {
        reportToFetch,
        export: true,
      });

      if (res.data.length > 0) {
        csv(reportToFetch + ".csv", res.data);
      } else {
        message.error("No data to download");
      }
    } catch (err) {
      console.log("exportFunc err", err);
      message.error("Error exporting data");
    }
  };

  const Row = ({ index, style }) => {
    const item = rows[index];
    if (!mobile)
      return (
        <TableRow
          style={style}
          key={item._id}
          _id={item._userId || item._id}
          data={item}
          headers={headers}
          rowType={rowType}
          showBreakdownInformation={showBreakdownInformation}
        />
      );
    return (
      <TableRow
        style={style}
        key={item._id}
        _id={item._userId || item._id}
        data={item}
        rowType={rowType}
        mobile
        showBreakdownInformation={showBreakdownInformation}
      />
    );
  };

  const handleClick = () => {
    handleSwitchView();
  };

  const showBreakdownInformation = (value) => {
    setShowingBreakdown(value);
  };

  return (
    <Card
      bordered={false}
      bodyStyle={{ padding: 0, overflow: "hidden", height: "100%" }}
      style={{ height: "100%", overflow: "hidden" }}
      className="z-depth-1"
    >
      <Table loading={fetching} mobile={mobile}>
        <Table.Header
          filters={[]}
          downloadBtn={false}
          exportFunc={reportToFetch !== "Draw Balance" ? exportFunc : undefined}
          filterBtn={false}
          showSearch={false}
          hideFiltersDrop={true}
          rowsLength={rows.length}
          handleBack={handleClick}
          bordered={false}
          reportToFetch={reportToFetch}
          backgroundColor="#424242"
        />

        {!mobile && (
          <Table.ColumnHeaders
            headers={headers}
            noSideBorders
            bordered
            notSortable
          />
        )}
        <Table.Body loading={fetching} bordered>
          {rows.length > 0 && (
            <AutoSizer>
              {({ height, width }) => (
                <Vlist
                  height={height}
                  itemCount={rows.length}
                  itemSize={itemSize}
                  width={width}
                >
                  {Row}
                </Vlist>
              )}
            </AutoSizer>
          )}
        </Table.Body>
      </Table>

      <ViewContainer
        type="drawer"
        visible={showLead}
        form={ViewLead}
        placement="right"
        width={400}
        closable={false}
        mask={false}
      />

      <ViewContainer
        type="drawer"
        visible={showProject}
        form={ViewProject}
        placement="right"
        width={400}
        closable={false}
        mask={false}
      />

      <Breakdown open={showingBreakdown} setOpen={showBreakdownInformation} />
    </Card>
  );
};

const Breakdown = ({ open, setOpen }) => {
  const [breakdown, setBreakdown] = useState({});
  const prevOpen = usePrevious(open);

  useEffect(() => {
    if (!prevOpen && open) {
      fetchBreakdown();
    }
  }, [open, prevOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchBreakdown = async () => {
    try {
      const _breakdown = await api.get(`/breakdowns/get/${open}`);
      setBreakdown(_breakdown.data);
    } catch (err) {
      console.log("err", err);
      message.error("Error getting breakdown");
    }
  };

  const onCancel = () => {
    setOpen(undefined);
  };

  return (
    <Modal visible={open} onCancel={onCancel} footer={null}>
      <Divider>Project Details</Divider>
      <List bordered={false} split={false}>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Submitted" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={dayjs(breakdown.submitted).format("MMMM D, YYYY")}
          />
        </List.Item>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Customer ID/PO#" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={breakdown.customerId}
          />
        </List.Item>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Salesman" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={
              breakdown.salesman ? breakdown.salesman.join(", ") : ""
            }
          />
        </List.Item>
      </List>

      <Divider>Collections</Divider>
      <List bordered={false} split={false}>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Total" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={currencyFormatter(breakdown.collectionsTotal)}
          />
        </List.Item>
      </List>

      <Divider>Expenses</Divider>
      <List bordered={false} split={false}>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Materials" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={currencyFormatter(breakdown.materials)}
          />
        </List.Item>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Overhead" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={currencyFormatter(breakdown.overhead)}
          />
        </List.Item>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Labor" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={currencyFormatter(breakdown.labor)}
          />
        </List.Item>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Dumping" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={currencyFormatter(breakdown.dumping)}
          />
        </List.Item>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Extra" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={currencyFormatter(breakdown.extra)}
          />
        </List.Item>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Permit" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={currencyFormatter(breakdown.permit)}
          />
        </List.Item>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Stock" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={currencyFormatter(breakdown.stock)}
          />
        </List.Item>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Total" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={currencyFormatter(breakdown.expensesTotal)}
          />
        </List.Item>
      </List>

      <Divider>Balance</Divider>
      <List bordered={false} split={false}>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Total" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={currencyFormatter(breakdown.balance)}
          />
        </List.Item>
      </List>

      <Divider>Office Fee</Divider>
      <List bordered={false} split={false}>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Origin" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={breakdown.origin}
          />
        </List.Item>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Total" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={currencyFormatter(breakdown.officeFee)}
          />
        </List.Item>
      </List>

      <Divider>Commissions</Divider>
      <List bordered={false} split={false}>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Manager Commission" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={currencyFormatter(breakdown.managerCommission)}
          />
        </List.Item>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Salesman Commissions" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={currencyFormatter(breakdown.commissionTotal)}
          />
        </List.Item>
      </List>

      <Divider>Company Profit</Divider>
      <List bordered={false} split={false}>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Total" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={currencyFormatter(breakdown.companyProfit)}
          />
        </List.Item>
      </List>

      <Divider>Block Bonus</Divider>
      <List bordered={false} split={false}>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Monthly" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={currencyFormatter(breakdown.blockBonusMonthly)}
          />
        </List.Item>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Yearly" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={currencyFormatter(breakdown.blockBonusYearly)}
          />
        </List.Item>
      </List>

      <Divider>Percentage Breakdown</Divider>
      <List bordered={false} split={false}>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Material" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={percentFormatter(breakdown.materialPercent)}
          />
        </List.Item>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Overhead" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={percentFormatter(breakdown.overheadPercent)}
          />
        </List.Item>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Labor" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={percentFormatter(breakdown.laborPercent)}
          />
        </List.Item>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Dumping" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={percentFormatter(breakdown.dumpingPercent)}
          />
        </List.Item>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Extra" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={percentFormatter(breakdown.extraPercent)}
          />
        </List.Item>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Permit" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={percentFormatter(breakdown.permitPercent)}
          />
        </List.Item>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Stock" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={percentFormatter(breakdown.stockPercent)}
          />
        </List.Item>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Balance" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={percentFormatter(breakdown.profitPercent)}
          />
        </List.Item>
      </List>
    </Modal>
  );
};

export default TableView;
