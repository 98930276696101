import React from "react";
import Tag from "antd/es/tag";

const MapKey = ({ handleClick, typeFilters, compact }) => {
  return (
    <div
      style={{
        margin: 8,
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        borderRadius: 4,
        overflow: "hidden",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 10,
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 12,
          paddingRight: 12,
          backgroundColor: "rgba(255,255,255,0.85)",
          gap: compact ? 1 : 8,
        }}
      >
        <Tag
          color={typeFilters.includes("roofing") ? "#00C292" : "#ffffff"}
          style={
            typeFilters.includes("roofing")
              ? {
                  cursor: "pointer",
                }
              : {
                  cursor: "pointer",
                  border: "1px solid #00C292",
                  color: "#000000",
                }
          }
          onClick={() => handleClick("roofing")}
        >
          Roofing
        </Tag>
        <Tag
          color={
            typeFilters.includes("roofing-flagged") ? "#FAAD14" : "#ffffff"
          }
          style={
            typeFilters.includes("roofing-flagged")
              ? {
                  cursor: "pointer",
                }
              : {
                  cursor: "pointer",
                  border: "1px solid #FAAD14",
                  color: "#000000",
                }
          }
          onClick={() => handleClick("roofing-flagged")}
        >
          Roofing Flagged
        </Tag>
        <Tag
          color={typeFilters.includes("roofing-nc") ? "#108EE9" : "#ffffff"}
          style={
            typeFilters.includes("roofing-nc")
              ? {
                  cursor: "pointer",
                }
              : {
                  cursor: "pointer",
                  border: "1px solid #108EE9",
                  color: "#000000",
                }
          }
          onClick={() => handleClick("roofing-nc")}
        >
          Roofing NC
        </Tag>
        <Tag
          color={typeFilters.includes("siding") ? "#999999" : "#ffffff"}
          style={
            typeFilters.includes("siding")
              ? {
                  cursor: "pointer",
                }
              : {
                  cursor: "pointer",
                  border: "1px solid #999999",
                  color: "#000000",
                }
          }
          onClick={() => handleClick("siding")}
        >
          Siding
        </Tag>
        <Tag
          color={typeFilters.includes("siding-nc") ? "#000000" : "#ffffff"}
          style={
            typeFilters.includes("siding-nc")
              ? {
                  cursor: "pointer",
                }
              : {
                  cursor: "pointer",
                  border: "1px solid #000000",
                  color: "#000000",
                }
          }
          onClick={() => handleClick("siding-nc")}
        >
          Siding NC
        </Tag>
      </div>
    </div>
  );
};

export default MapKey;
