const status = {
  "Lead Lost": "#ff7056",
  Estimate: "#00c292",
  Lead: "#999999",
  Sold: "#108ee9",
  Lost: "#ff7056",
  Cancelled: "#ff7056",
  "On Hold": "#999999",
  "Closed Out": "#00c292",
  "Pending Install": "#999999",
  Completed: "#00c292",
  Started: "#108ee9",
  Collected: "#D4AF37",
  "Estimate Sold": "#108ee9",
  "Estimate Not Sold": "#ff7056",
  "Lead Needs Remeet": "#999999",
  "Estimate Next Year": "#D4AF37",
  "Estimate DOA": "#ff7056",
  "Estimate WIP": "#D4AF37",
  "Lead Insurance WIP": "#999999",
};

export default status;
