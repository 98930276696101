import React, { useState } from "react";
import { useSelector } from "react-redux";
import Form from "antd/es/form";
import Button from "antd/es/button";
import message from "antd/es/message";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import api from "API";
import CancelButton from "Components/CancelButton";
import DatePicker from "Components/Inputs/DatePicker";
import useIsMobile from "Hooks/useIsMobile";
import dayjs from "dayjs";

const messageKey = "messageKey";

const UpdateDeliveryDate = ({
  unsavedChanges,
  setUnsavedChanges,
  handleClose,
  type,
}) => {
  const formDetails = useSelector(
    (state) => state.formState.updateDeliveryDate.formDetails
  );
  const mobile = useIsMobile();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      setLoading(true);
      // message.loading("Updating due date...", 0);
      message.loading({
        content: "Updating delivery date...",
        duration: 0,
        key: messageKey,
      });
      values._id = formDetails._id;

      const res = await api.put("/ready-for-material/delivery-date", values);
      if (formDetails.callback) {
        formDetails.callback(res.data);
      }
      // message.success("Due date updated");
      message.success({
        content: "Delivery date updated",
        duration: 1.5,
        key: messageKey,
      });
      setUnsavedChanges(false);
      setLoading(false);
      handleClose(true);
    } catch (err) {
      setLoading(false);
      // message.error("Error updating due date");
      message.error({
        content: "Error updating delivery date",
        duration: 1.5,
        key: messageKey,
      });
    }
  };

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error updating due date");
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  const cancel = () => {
    handleClose();
  };

  return (
    <Form
      form={form}
      className="form"
      autoComplete="off"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      initialValues={{
        deliveryDate: formDetails.deliveryDate
          ? dayjs(formDetails.deliveryDate)
          : undefined,
      }}
    >
      <RemoveAutocomplete />

      <FormBody
        className="content-inner"
        style={{
          paddingLeft: 24,
          paddingTop: 24,
          paddingRight: 24,
          paddingBottom: 8,
          maxHeight: type === "modal" ? "calc(100vh - 161px)" : "unset",
        }}
      >
        <Form.Item name="deliveryDate" label="Delivery Date">
          <DatePicker
            format="MMMM DD, YYYY"
            inputReadOnly={true}
            getPopupContainer={(trigger) =>
              !mobile
                ? trigger.parentNode.parentNode.parentNode.parentNode
                : document.body
            }
            getCalendarContainer={(trigger) =>
              !mobile
                ? trigger.parentNode.parentNode.parentNode.parentNode
                : document.body
            }
          />
        </Form.Item>
      </FormBody>

      <FormControls className="sticky-footer">
        <CancelButton handleClick={cancel} />
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={loading || !unsavedChanges}
        >
          Submit
        </Button>
      </FormControls>
    </Form>
  );
};

export default UpdateDeliveryDate;
