import React, { useState, useEffect } from "react";
import Button from "antd/es/button";
import message from "antd/es/message";
import ClipboardText from "Icons/ClipboardText";
import ClipboardTextMultiple from "Icons/ClipboardTextMultiple";
import ClipboardTextMultipleFilled from "Icons/ClipboardTextMultipleFilled";
import Clipboard from "react-clipboard.js";
import styles from "./styles.module.scss";
import usePrevious from "Hooks/usePrevious";

const CopyableContent = ({
  title,
  content,
  setStringToCopy,
  stringToCopy,
  edit,
  single,
}) => {
  const [selected, setSelected] = useState(false);
  const prevStringToCopy = usePrevious(stringToCopy);

  useEffect(() => {
    if (
      !single &&
      stringToCopy.length === 0 &&
      prevStringToCopy &&
      prevStringToCopy.length > 0
    ) {
      setSelected(false);
    }
  }, [stringToCopy, prevStringToCopy]); // eslint-disable-line react-hooks/exhaustive-deps

  const copied = () => {
    message.success(`${title} copied to clipboard`);
  };

  const copyMultipleClick = () => {
    setStringToCopy((prev) => [...prev, content]);
    setSelected(true);
  };

  const uncopyMultipleClick = () => {
    let _stringToCopy = [...stringToCopy];
    const index = _stringToCopy.indexOf(content);
    if (index > -1) {
      _stringToCopy.splice(index, 1);
    }

    setStringToCopy(_stringToCopy);
    setSelected(false);
  };

  return (
    <div style={{ width: 88, textAlign: "right" }}>
      <Clipboard
        data-clipboard-text={content}
        component="div"
        onSuccess={copied}
        style={{ display: "inline-block", marginRight: !single ? 8 : 0 }}
      >
        <Button
          shape="circle"
          type="text"
          size="large"
          className={styles.copyButton}
          style={{ top: -8, right: !single ? 48 : 0 }}
        >
          <ClipboardText
            className={edit ? "hide" : ""}
            color="rgba(0, 0, 0, 0.25)"
            size={18}
          />
        </Button>
      </Clipboard>
      {!single && !selected ? (
        <Button
          shape="circle"
          type="text"
          size="large"
          onClick={copyMultipleClick}
          className={styles.copyButton}
          style={{ top: -8, right: 0 }}
        >
          <ClipboardTextMultiple
            className={edit ? "hide" : ""}
            color="rgba(0, 0, 0, 0.25)"
            size={18}
          />
        </Button>
      ) : !single ? (
        <Button
          shape="circle"
          type="primary"
          size="large"
          onClick={uncopyMultipleClick}
          className={styles.copyButton}
          style={{ top: -8, right: 0 }}
        >
          <ClipboardTextMultipleFilled
            className={edit ? "hide" : ""}
            color="#ffffff"
            size={18}
          />
        </Button>
      ) : null}
    </div>
  );
};

export default CopyableContent;
