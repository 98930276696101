import React, { useEffect } from "react";
import Modal from "antd/es/modal";
import message from "antd/es/message";
import List from "antd/es/list";
import Button from "antd/es/button";
import DeleteFilled from "@ant-design/icons/DeleteFilled";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";
import Sortable from "sortablejs";
import api from "API";
const { confirm } = Modal;

const ManageTemplatesAndFilters = ({
  array,
  setArray,
  onClose,
  managing,
  visible,
}) => {
  useEffect(() => {
    if (visible) {
      const el = document.getElementById("templateAndFiltersSortable");
      if (Sortable.get(el)) Sortable.get(el).destroy();
      Sortable.create(el, {
        animation: 150,
        direction: "vertical",
        dragClass: "draggable",
        onSort: (e) => {
          let { oldIndex, newIndex } = e;
          oldIndex++;
          newIndex++;
          onSortEnd({ oldIndex, newIndex });
        },
      });
    }
  }, [array, visible]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = () => {
    onClose(false);
  };

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    try {
      let _array = JSON.parse(JSON.stringify(array));
      for (const a of _array) {
        if (a.order === oldIndex) {
          a.order = newIndex;
        } else if (a.order < 0) {
        } else if (oldIndex < newIndex) {
          if (a.order <= newIndex && a.order >= oldIndex) {
            a.order = a.order - 1;
          }
        } else if (oldIndex > newIndex) {
          if (a.order >= newIndex && a.order <= oldIndex) {
            a.order = a.order + 1;
          }
        }
      }

      setArray(_array);

      await api.post(`/${managing.toLowerCase()}s/update`, _array);
    } catch (err) {
      console.log("err", err);
      message.error(`Error re-ordering ${managing.toLowerCase()}s`);
    }
  };

  const deleteObj = async (_id, name, table = undefined, cb) => {
    try {
      let res;
      if (table) {
        res = await api.delete(
          `${managing.toLowerCase()}s/delete/${_id}/${table}`
        );
      } else {
        res = await api.delete(`${managing.toLowerCase()}s/delete/${_id}`);
      }
      if (res.status === 200) {
        setArray(res.data._user);
        message.success(`${name} deleted`);
        cb();
      }
    } catch (err) {
      console.log("err", err);
      message.error(`Error deleting ${name}`);
    }
  };

  const showConfirm = (_id, name, table = undefined) => {
    confirm({
      icon: <QuestionCircleOutlined />,
      zIndex: 5001,
      centered: true,
      content: (
        <div>
          Are you sure you want to delete the {managing.toLowerCase()}{" "}
          <b>"{name}"</b>?
        </div>
      ),
      onOk() {
        return new Promise((resolve, reject) => {
          deleteObj(_id, name, table, () => {
            resolve(true);
          });
        }).catch((err) => console.log("err: ", err));
      },
      onCancel() {},
    });
  };

  return (
    <Modal
      centered
      maskClosable={false}
      title={`Manage ${managing}s`}
      visible={visible}
      onCancel={handleClose}
      destroyOnClose
      bodyStyle={{
        padding: 0,
        borderRadius: "0 0 4px 4px",
        overflow: "hidden",
      }}
      footer={null}
      zIndex={5000}
      forceRender
    >
      <List style={{ background: "#f0f0f0" }}>
        <div id="templateAndFiltersSortable">
          {array.map((item, i) => {
            if (item.order >= 0) {
              return (
                <SortableItem
                  key={item._id}
                  index={i}
                  item={item}
                  showConfirm={showConfirm}
                />
              );
            } else {
              return null;
            }
          })}
        </div>
      </List>
    </Modal>
  );
};

const SortableItem = ({ item, showConfirm }) => {
  const onClick = (e) => {
    e.stopPropagation();
    showConfirm(item._id, item.name, item.table);
  };

  const onMouseDown = () => {
    document
      .querySelectorAll(".sortable_list_container")
      .forEach((el) => (el.style.cursor = "grabbing"));
  };

  const onMouseUp = () => {
    document
      .querySelectorAll(".sortable_list_container")
      .forEach((el) => (el.style.cursor = "grab"));
  };

  return (
    <List.Item
      className="z-depth-1 draggable"
      style={{
        paddingLeft: 24,
        paddingRight: 12,
        zIndex: 5001,
        background: "#ffffff",
        cursor: "grab",
      }}
      actions={[
        <Button
          onClick={onClick}
          shape="circle"
          icon={<DeleteFilled />}
          type="primary"
          danger
        />,
      ]}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
    >
      <List.Item.Meta title={item.name} />
    </List.Item>
  );
};

export default ManageTemplatesAndFilters;
