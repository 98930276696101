import React from "react";
import LineChartWithInterval from "Reports/LineChartWithInterval";

export default function SalesCumulative() {
  return (
    <LineChartWithInterval
      path="/reports/adminSalesCumulative"
      type="currency"
      summary={true}
      lineItems={true}
      aggregate={false}
      report="SALES CUMULATIVE"
      headers={["Salesman", "Total"]}
    />
  );
}
