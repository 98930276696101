import React, { useState, useEffect, useCallback, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchTable } from "Actions/tableActions";
import { updateScopesActivitiesAndHashtags } from "Actions/dataActions";
import Form from "antd/es/form";
import message from "antd/es/message";
import Button from "antd/es/button";
// import Slider from "antd/es/slider";
import Modal from "antd/es/modal";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import api from "API";
import currencyFormatter from "Utils/currencyFormatter";
import Currency from "Components/Inputs/Currency";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";
import Input from "antd/es/input";
import WorkmanshipWarranty from "Components/Inputs/WorkmanshipWarranty";
import ExtendedWarranty from "Components/Inputs/ExtendedWarranty";
import CheckableTag from "antd/es/tag/CheckableTag";
import styles from "./styles.module.scss";
import CancelButton from "Components/CancelButton";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";
import List from "antd/es/list";
import Divider from "antd/es/divider";
import useProjectManagers from "Hooks/useProjectManagers";
// import InputSalesmen from "Components/Inputs/Salesmen";
import ProjectManager from "Components/Inputs/ProjectManager";

const messageKey = "messageKey";

const UpdateScope = ({
  handleClose,
  unsavedChanges,
  setUnsavedChanges,
  setTitle,
  type,
}) => {
  const dispatch = useDispatch();
  const formDetails = useSelector(
    (state) => state.formState.updateScope.formDetails
  );
  const table = useSelector((state) => state.tableState.table);
  const col = useSelector((state) => state.tableState.col);
  const order = useSelector((state) => state.tableState.order);
  const search = useSelector((state) => state.tableState.search);
  const activeFilter = useSelector((state) => state.tableState.activeFilter);
  const viewableActivities = useSelector(
    (state) => state.dataState.viewableActivities
  );
  const admin = useSelector((state) => state.authState.admin);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(formDetails.status);
  const [pendingAR, setPendingAR] = useState(0);
  const [ar, setAr] = useState(0);
  const projectManagers = useProjectManagers();

  const {
    _id,
    customerId,
    customerName,
    projectAddress,
    name,
    label,
    amount,
    statusDate,
    // progress,
    projectStatus,
    _projectId,
    extendedWarranty,
    premierWarranty,
    repair,
    insurance,
    stage,
    collectedAmount,
    expensedAmount,
    invoicedAmount,
    firstInvoiceDate,
    installProjectManager,
  } = formDetails;

  useEffect(() => {
    let deleteButton = null;
    if (
      status !== "Collected" &&
      projectStatus === "Sold" &&
      collectedAmount === 0 &&
      invoicedAmount === 0 &&
      expensedAmount === 0
    ) {
      deleteButton = (
        <Button
          size="small"
          danger
          onClick={confirmDelete}
          style={{ float: "right", marginRight: 28 }}
        >
          Delete
        </Button>
      );
    }
    setTitle(<span>Update Scope {deleteButton}</span>);
    setPendingAR((expensedAmount || 0) - (collectedAmount || 0));
    setAr((invoicedAmount || 0) - (collectedAmount || 0));
  }, [status, projectStatus, collectedAmount, invoicedAmount, expensedAmount]); // eslint-disable-line react-hooks/exhaustive-deps

  const confirmDelete = () => {
    Modal.confirm({
      zIndex: 4002,
      icon: <QuestionCircleOutlined />,
      centered: true,
      cancelText: "Cancel",
      okText: "Delete",
      okButtonProps: { danger: true },
      content: <div>Are you sure you want to delete this scope?</div>,
      onOk() {
        return new Promise((resolve, reject) => {
          deleteScope(() => resolve(true));
        }).catch((err) => console.log("err: ", err));
      },
      onCancel() {},
    });
  };

  const deleteScope = useCallback(
    async (cb) => {
      try {
        const res = await api.delete(`/scopes/delete/${_id}`);
        setUnsavedChanges(false);
        dispatch(
          updateScopesActivitiesAndHashtags({
            viewableActivities,
            _projectId,
            data: res.data,
          })
        );

        dispatch(
          fetchTable({
            table,
            col,
            order,
            search,
            activeFilter,
            fetching: false,
          })
        );

        cb();

        message.success("Scope Deleted");
        handleClose(true);
      } catch (err) {
        console.log("err", err);
        message.error("Error deleting scope");
      }
    },
    [
      _id,
      setUnsavedChanges,
      viewableActivities,
      _projectId,
      dispatch,
      table,
      col,
      order,
      search,
      activeFilter,
      handleClose,
    ]
  );

  // const tipFormatter = (val) => {
  //   let amnt = currencyFormatter(amount * (val / 100));
  //   const string = `${val}% - ${amnt}`;
  //   return string;
  // };

  const onFinish = useCallback(
    async (values) => {
      try {
        setLoading(true);
        values.originalStatus = status;
        values.originalStatusDate = statusDate;
        values.name = name;
        values._id = _id;
        values.originalAmount = amount;
        // values.originalProgress = progress;
        values._projectId = _projectId;
        values.originalExtendedWarranty = extendedWarranty;
        values.originalPremierWarranty = premierWarranty;
        values.originalRepair = repair;
        values.originalInsurance = insurance;
        values.originalLabel = label;
        values.stage = stage;

        if (installProjectManager) {
          values.originalInstallProjectManager =
            projectManagers[installProjectManager];
        }
        if (values.installProjectManager) {
          values.installProjectManager =
            projectManagers[values.installProjectManager];
        }

        // message.loading("Scope updated...", 0);
        message.loading({
          content: "Updating scope...",
          duration: 0,
          key: messageKey,
        });
        const res = await api.post("/scopes/update", values);

        if (!formDetails.dashboard) {
          dispatch(
            fetchTable({
              table,
              col,
              order,
              search,
              activeFilter,
              fetching: false,
            })
          );
        }

        if (formDetails.callback) {
          formDetails.callback();
        }

        dispatch(
          updateScopesActivitiesAndHashtags({
            viewableActivities,
            _projectId,
            data: res.data,
          })
        );

        setUnsavedChanges(false);
        setLoading(false);
        handleClose(true);

        // message.success("Scope updated");
        message.success({
          content: "Scope updated",
          duration: 1.5,
          key: messageKey,
        });
      } catch (err) {
        setLoading(false);
        console.log("err", err);
        // message.error("Error updating scope");
        message.error({
          content: "Error updating scope",
          duration: 1.5,
          key: messageKey,
        });
      }
    },
    [
      status,
      statusDate,
      name,
      _id,
      amount,
      // progress,
      _projectId,
      extendedWarranty,
      premierWarranty,
      repair,
      insurance,
      dispatch,
      table,
      col,
      order,
      search,
      activeFilter,
      viewableActivities,
      setUnsavedChanges,
      handleClose,
      formDetails,
      stage,
      label,
      installProjectManager,
      projectManagers,
    ]
  );

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error updating scope");
  };

  const onClickCollected = () => {
    Modal.confirm({
      zIndex: 4001,
      centered: true,
      content: "Are you sure you want to mark this scope collected?",
      onOk() {
        return new Promise((resolve, reject) => {
          setLoading(true);
          markScopeCollected(() => {
            setLoading(false);
            resolve(true);
            handleClose(true);
          });
        }).catch((err) => {
          setLoading(false);
          console.log("err", err);
        });
      },
      onCancel() {},
    });
  };

  const markScopeCollected = useCallback(
    async (cb) => {
      try {
        let values = {};
        // values.progress = progress;
        values.originalStatus = status;
        values.originalStatusDate = statusDate;
        values.name = label || name;
        values._id = _id;

        const res = await api.post("/scopes/markCollected", values);

        dispatch(
          fetchTable({
            table,
            col,
            order,
            search,
            activeFilter,
            fetching: false,
          })
        );

        dispatch(
          updateScopesActivitiesAndHashtags({
            viewableActivities,
            _projectId,
            data: res.data,
          })
        );

        // form.setFieldsValue({
        //   progress: 100,
        // });

        message.success("Scope marked collected");

        cb();
      } catch (err) {
        message.error("Error marking scope collected");
        console.log("err", err);
      }
    },
    [
      // progress,
      status,
      statusDate,
      name,
      label,
      _id,
      dispatch,
      table,
      col,
      order,
      search,
      activeFilter,
      viewableActivities,
      _projectId,
      // form,
    ]
  );

  const onClickUncollected = () => {
    Modal.confirm({
      zIndex: 4001,
      centered: true,
      content: "Are you sure you want mark this scope uncollected?",
      onOk() {
        return new Promise((resolve, reject) => {
          setLoading(true);
          markScopeUncollected(() => {
            setLoading(false);
            resolve(true);
          });
        }).catch((err) => {
          setLoading(false);
          console.log("err", err);
        });
      },
      onCancel() {},
    });
  };

  const markScopeUncollected = useCallback(
    async (cb) => {
      try {
        let values = {};
        values.name = label || name;
        values._id = _id;

        const res = await api.post("/scopes/markUncollected", values);

        dispatch(
          fetchTable({
            table,
            col,
            order,
            search,
            activeFilter,
            fetching: false,
          })
        );

        dispatch(
          updateScopesActivitiesAndHashtags({
            viewableActivities,
            _projectId,
            data: res.data,
          })
        );

        setStatus("Completed");

        message.success("Scope marked uncollected");

        cb();
      } catch (err) {
        message.error("Error marking scope uncollected");
        console.log("err", err);
      }
    },
    [
      name,
      label,
      _id,
      dispatch,
      table,
      col,
      order,
      search,
      activeFilter,
      viewableActivities,
      _projectId,
    ]
  );

  const cancel = () => {
    handleClose(true);
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      className="form"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      initialValues={{
        // progress,
        amount,
        extendedWarranty,
        premierWarranty,
        repair,
        insurance,
        label,
        installProjectManager: installProjectManager
          ? installProjectManager._user
          : undefined,
      }}
    >
      <RemoveAutocomplete />

      <FormBody
        className="content-inner"
        style={{
          paddingLeft: 24,
          paddingTop: 24,
          paddingRight: 24,
          paddingBottom: 8,
          maxHeight: type === "modal" ? "calc(100vh - 161px)" : "unset",
        }}
      >
        <div style={{ marginBottom: 16 }}>
          <div className="ant-col ant-form-item-label">
            <label title="Customer">Customer</label>
          </div>
          <Input disabled value={`${customerId} - ${customerName}`} />
        </div>

        <div style={{ marginBottom: 16 }}>
          <div className="ant-col ant-form-item-label">
            <label title="Project Address">Project Address</label>
          </div>
          <Input disabled value={projectAddress} />
        </div>

        <div style={{ marginBottom: 16 }}>
          <div className="ant-col ant-form-item-label">
            <label title="Type">Type</label>
          </div>
          <Input disabled value={name} />
        </div>

        <Form.Item
          name="amount"
          label="Amount"
          rules={[{ required: true, message: "Amount is required" }]}
        >
          <Currency id="amount" />
        </Form.Item>

        <Form.Item
          name="label"
          label="Name"
          disabled={projectStatus === "Closed Out"}
        >
          <InputNoAutoComplete id="label" />
        </Form.Item>

        <div style={{ marginBottom: 16 }}>
          <div className="ant-col ant-form-item-label">
            <label title="Status">Status</label>
          </div>
          <Input disabled value={status} />
        </div>

        {/* <Form.Item label="Progress" name="progress">
          <Slider
            marks={{
              0: "0%",
              25: "25%",
              50: "50%",
              75: "75%",
              100: "100%",
            }}
            style={{ marginRight: 24 }}
            step={1}
            tipFormatter={tipFormatter}
            disabled={
              status === "Collected" ||
              projectStatus === "Closed Out" ||
              projectStatus === "Cancelled"
            }
          />
        </Form.Item> */}

        <Form.Item
          name="premierWarranty"
          label="Premier Warranty"
          rules={[{ required: true, message: "Premier Warranty is required" }]}
        >
          <WorkmanshipWarranty />
        </Form.Item>

        <Form.Item
          name="extendedWarranty"
          label="Extended Warranty"
          rules={[{ required: true, message: "Extended Warranty is required" }]}
        >
          <ExtendedWarranty scopeName={name} />
        </Form.Item>

        <Form.Item name="installProjectManager" label="Install Project Manager">
          <ProjectManager sales={projectManagers} />
        </Form.Item>

        <div className="ant-form-item-label">
          <label>Details</label>
        </div>
        <div className={styles.tagContainer}>
          <Form.Item
            name="repair"
            valuePropName="checked"
            style={{ marginBottom: 0 }}
          >
            <CheckableTag className={styles.tag}>Repair</CheckableTag>
          </Form.Item>
          <Form.Item
            name="insurance"
            valuePropName="checked"
            style={{ marginBottom: 0 }}
          >
            <CheckableTag className={styles.tag}>Insurance</CheckableTag>
          </Form.Item>
        </div>

        <Divider>Billing Details</Divider>

        <List bordered={false} split={false}>
          <List.Item style={{ padding: 0, paddingBottom: 8 }}>
            <List.Item.Meta
              title="Invoiced Amount"
              description={currencyFormatter(invoicedAmount || 0)}
            />
          </List.Item>

          <List.Item style={{ padding: 0, paddingBottom: 8 }}>
            <List.Item.Meta
              title="Collected Amount"
              description={currencyFormatter(collectedAmount || 0)}
            />
          </List.Item>

          <List.Item style={{ padding: 0, paddingBottom: 8 }}>
            <List.Item.Meta
              title="Expenses"
              description={currencyFormatter(expensedAmount || 0)}
            />
          </List.Item>

          {!firstInvoiceDate && (
            <List.Item style={{ padding: 0, paddingBottom: 8 }}>
              <List.Item.Meta
                title="Pending Account Receivables"
                description={currencyFormatter(pendingAR < 0 ? 0 : pendingAR)}
              />
            </List.Item>
          )}

          {firstInvoiceDate && (
            <List.Item style={{ padding: 0, paddingBottom: 8 }}>
              <List.Item.Meta
                title="Account Receivables"
                description={currencyFormatter(ar < 0 ? 0 : ar)}
              />
            </List.Item>
          )}
        </List>
      </FormBody>
      <FormControls className="sticky-footer">
        <CancelButton handleClick={cancel} />
        {status !== "Collected" ? (
          <Button
            type="text"
            style={{ padding: "4px 8px" }}
            onClick={onClickCollected}
          >
            Collected
          </Button>
        ) : (
          <Button
            type="text"
            danger
            style={{ padding: "4px 8px" }}
            onClick={onClickUncollected}
          >
            Uncollected
          </Button>
        )}
        {((status !== "Collected" &&
          (projectStatus !== "Closed Out" || projectStatus !== "Cancelled")) ||
          admin === "Yes") && (
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={loading || !unsavedChanges}
            style={{ marginLeft: 8 }}
          >
            Submit
          </Button>
        )}
      </FormControls>
    </Form>
  );
};

export default memo(UpdateScope);
