import React from "react";
import BarChartWithSwitch from "Reports/BarChartWithSwitch";

export default function AverageMedianAccountReceivables() {
  return (
    <BarChartWithSwitch
      path="/reports/averageMedianAR"
      _value="Average"
      secondary="Median"
      report="ACCOUNT RECEIVABLES (AVERAGE/MEDIAN) TIME TO COLLECT"
      headers={["Salesman", "Total"]}
    />
  );
}
