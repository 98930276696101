import React from "react";
import BarChartWithDates from "Reports/BarChartWithDates";

export default function LeadConversionRate() {
  return (
    <BarChartWithDates
      path="/reports/leadsConversionRateBySalesman"
      summary={true}
      type="percent"
      report="LEAD CONVERSION RATE"
      headers={["Salesman", "Leads", "Sold", "Convert %"]}
    />
  );
}
