import React, { useState } from "react";
import { useSelector } from "react-redux";
import Form from "antd/es/form";
import Button from "antd/es/button";
import message from "antd/es/message";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import api from "API";
import SearchLeadsAndProjects from "Components/Inputs/SearchLeadsAndProjects";
import CancelButton from "Components/CancelButton";
import DatePicker from "Components/Inputs/DatePicker";
import AssignedTo from "Components/Inputs/Salesmen";
import useIsMobile from "Hooks/useIsMobile";
import Input from "antd/es/input";
import MarkUpHelper from "Components/MarkUpHelper";
import useProjectManagers from "Hooks/useProjectManagers";

const messageKey = "messageKey";

const InstallTodo = ({
  unsavedChanges,
  setUnsavedChanges,
  handleClose,
  type,
}) => {
  const projectManagers = useProjectManagers();
  const formDetails = useSelector(
    (state) => state.formState.installTodo.formDetails
  );
  const mobile = useIsMobile();
  const [_projectId, _setProjectId] = useState(
    formDetails && formDetails._projectId ? formDetails._projectId : undefined
  );
  const [customerId, setCustomerId] = useState(
    formDetails && formDetails.customerId ? formDetails.customerId : undefined
  );
  const [projectAddress, setProjectAddress] = useState(
    formDetails && formDetails.projectAddress
      ? formDetails.projectAddress
      : undefined
  );
  const [projectAddressLat, setProjectAddressLat] = useState(
    formDetails && formDetails.projectAddressLat
      ? formDetails.projectAddressLat
      : undefined
  );
  const [projectAddressLng, setProjectAddressLng] = useState(
    formDetails && formDetails.projectAddressLng
      ? formDetails.projectAddressLng
      : undefined
  );
  const [starredImageSrc, setStarredImageSrc] = useState(
    formDetails && formDetails.starredImageSrc
      ? formDetails.starredImageSrc
      : undefined
  );
  const [customerName, setCustomerName] = useState(
    formDetails && formDetails.customerName
      ? formDetails.customerName
      : undefined
  );
  const [phone, setPhone] = useState(
    formDetails && formDetails.phone ? formDetails.phone : undefined
  );
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      setLoading(true);
      // message.loading("Creating to-do...", 0);
      message.loading({
        content: "Creating to-do...",
        duration: 0,
        key: messageKey,
      });
      values._projectId = _projectId;
      values.customerId = customerId;
      values.projectAddress = projectAddress;
      values.projectAddressLat = projectAddressLat;
      values.projectAddressLng = projectAddressLng;
      values.starredImageSrc = starredImageSrc;
      values.customerName = customerName;
      values.phone = phone;

      let array = [];
      if (values.assignedTo) {
        for (const a of values.assignedTo) {
          array.push(projectManagers[a]);
        }
      }

      values.assignedTo = array;
      const res = await api.post("/install/todos/create", values);

      if (formDetails.callback) {
        formDetails.callback(res.data);
      }

      // message.success("To-do created");
      message.success({
        content: "To-do created",
        duration: 1.5,
        key: messageKey,
      });
      setUnsavedChanges(false);
      setLoading(false);
      handleClose(true);
    } catch (err) {
      console.log("err", err);
      setLoading(false);
      // message.error("Error creating to-do");
      message.error({
        content: "Error creating to-do",
        duration: 1.5,
        key: messageKey,
      });
    }
  };

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error creating to-do");
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  const cancel = () => {
    handleClose();
  };

  const handleSelect = (selected) => {
    _setProjectId(selected._id);
    setCustomerId(selected.customerId);
    setProjectAddress(
      `${selected.projectAddressStreet}, ${selected.projectAddressCity}`
    );
    setProjectAddressLat(selected.projectAddressLat);
    setProjectAddressLng(selected.projectAddressLng);
    setStarredImageSrc(selected.starredImageSrc);
    setCustomerName(selected.customerName);
    setPhone(selected.phone);
  };

  const addAllAsAssignee = () => {
    const assignees = [];
    Object.keys(projectManagers).map((key, index) => {
      assignees.push(key);
      return key;
    });
    form.setFieldsValue({
      assignedTo: assignees,
    });
  };

  return (
    <Form
      form={form}
      className="form"
      autoComplete="off"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
    >
      <RemoveAutocomplete />

      {!_projectId ? (
        <SearchLeadsAndProjects
          handleSelect={handleSelect}
          leads={false}
          disableLastViewed={true}
        />
      ) : (
        <FormBody
          className="content-inner"
          style={{
            paddingLeft: 24,
            paddingTop: 24,
            paddingRight: 24,
            paddingBottom: 8,
            maxHeight: type === "modal" ? "calc(100vh - 161px)" : "unset",
          }}
        >
          <Form.Item
            name="todo"
            label={<MarkUpHelper title="To-do" />}
            rules={[{ required: true, message: "To-do is required" }]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>

          <h4 className="headerWithButton">
            <span>Assignee</span>
            <Button
              type="primary"
              size="small"
              className="green"
              onClick={addAllAsAssignee}
            >
              ADD ALL
            </Button>{" "}
          </h4>
          <Form.Item name="assignedTo">
            <AssignedTo sales={projectManagers} />
          </Form.Item>

          <Form.Item name="dueDate" label="Due Date">
            <DatePicker
              format="MMMM DD, YYYY"
              inputReadOnly={true}
              getPopupContainer={(trigger) =>
                !mobile
                  ? trigger.parentNode.parentNode.parentNode.parentNode
                  : document.body
              }
              getCalendarContainer={(trigger) =>
                !mobile
                  ? trigger.parentNode.parentNode.parentNode.parentNode
                  : document.body
              }
            />
          </Form.Item>
        </FormBody>
      )}

      {_projectId && (
        <FormControls className="sticky-footer">
          <CancelButton handleClick={cancel} />
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={loading || !unsavedChanges}
          >
            Submit
          </Button>
        </FormControls>
      )}
    </Form>
  );
};

export default InstallTodo;
