import React, { memo, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSelected } from "Actions/dataActions";
import { openForm } from "Actions/formActions";
import List from "antd/es/list";
import Button from "antd/es/button";
import Tag from "antd/es/tag";
import Call from "Icons/Call";
import Directions from "Icons/Directions";
import Textsms from "Icons/Textsms";
import Post from "Icons/Post";
import dayjs from "dayjs";
import styles from "./styles.module.scss";
import scopeObj from "Constants/scopes";
import statusObj from "Constants/status";
import removePhoneSymbols from "Utils/removePhoneSymbols";

const leadsNotScheduled = "leads-not-scheduled";
// const estimatesWithNoResults = "estimates-with-no-results";
const estimatesSoldNotFlipped = "estimates-sold-not-flipped";
const leadsNeedRemeet = "leads-need-remeet";
const leadsInsuranceWIP = "leads-insurance-wip";
const estimatesWIP3Months = "estimates-wip";

const LeadItem = ({
  style,
  mobile,
  _id,
  customerId,
  customerName,
  companyName,
  address,
  scopes,
  created,
  projectStatus,
  projectManager,
  phone,
  headers,
  leadManagement,
  selected,
  needsRemeet,
  estimateStart,
  estimateDate,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedRow = useSelector((state) => state.dataState.selectedRow);
  const dispatch = useDispatch();

  const renderScope = () => {
    let scopeArr = [];
    if (typeof scopes === "string") scopeArr = scopes.split(", ");
    return scopeArr.map((s, i) => {
      if (s && s.length > 0) {
        return (
          <Tag key={s} className={styles.scopeBadge} color={scopeObj[s].color}>
            {scopeObj[s].initials}
          </Tag>
        );
      } else {
        return null;
      }
    });
  };

  const onClick = useCallback(() => {
    if (!leadManagement) {
      dispatch(setSelected(_id));
      const locArr = location.pathname.split("/");
      if (locArr[3]) {
        navigate(`/leads/view/${locArr[3]}/${_id}`);
      } else {
        navigate(`/leads/view/details/${_id}`);
      }
    } else {
      navigate(`/office/lead-scheduling/leads/view/details/${_id}`);
    }
  }, [dispatch, _id, location, navigate, leadManagement]);

  const openSMSMobile = (e) => {
    e.stopPropagation();
    window.open(`sms:${removePhoneSymbols(phone)}`, "_self");
    return false;
  };

  const openCreatePost = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(
        openForm("activity", {
          _id,
          customerId,
          projectStatus,
          customerName,
          companyName,
          projectManager,
        })
      );
    },
    [
      dispatch,
      _id,
      customerId,
      projectStatus,
      customerName,
      companyName,
      projectManager,
    ]
  );

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  if (!mobile) {
    return (
      <List.Item
        style={style}
        className={`${styles.row} ${
          selectedRow === _id ? styles.selected : ""
        }`}
        onClick={onClick}
      >
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[0].width,
            textAlign: headers[0].align ? headers[0].align : "left",
          }}
          description={customerId}
        />
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[1].width,
            textAlign: headers[1].align ? headers[1].align : "left",
          }}
          description={
            companyName ? `${companyName} (${customerName})` : customerName
          }
        />
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[2].width,
            textAlign: headers[2].align ? headers[2].align : "left",
          }}
          description={
            <Tag className={styles.statusTag} color={statusObj[projectStatus]}>
              {projectStatus}
            </Tag>
          }
        />
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[3].width,
            textAlign: headers[3].align ? headers[3].align : "left",
          }}
          description={address}
        />
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[4].width,
            textAlign: headers[4].align ? headers[4].align : "left",
          }}
          description={renderScope()}
        />
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[5].width,
            textAlign: headers[5].align ? headers[5].align : "left",
          }}
          description={
            !leadManagement || selected === leadsNotScheduled
              ? dayjs(created).format("MMM D, YYYY")
              : selected === leadsNeedRemeet || selected === leadsInsuranceWIP
              ? dayjs(needsRemeet).format("MMM D, YYYY")
              : selected === estimatesSoldNotFlipped ||
                selected === estimatesWIP3Months
              ? dayjs(estimateDate).format("MMM D, YYYY")
              : dayjs(estimateStart).format("MMM D, YYYY")
          }
        />
      </List.Item>
    );
  } else {
    return (
      <List.Item
        style={style}
        className={`${styles.mobileListItem} ${
          selectedRow === _id ? styles.selected : ""
        }`}
        onClick={onClick}
      >
        <List.Item.Meta className={styles.customerId} title={customerId} />
        <List.Item.Meta
          className={styles.status}
          description={
            <Tag className={styles.statusTag} color={statusObj[projectStatus]}>
              {projectStatus}
            </Tag>
          }
        />
        <List.Item.Meta
          className={styles.date}
          description={dayjs(created).format("MMM D, YY")}
        />
        <div className={styles.break} />
        <List.Item.Meta
          className={styles.fullLine}
          style={{ flex: "1 1 100%", paddingBottom: 3 }}
          description={
            companyName ? `${companyName} (${customerName})` : customerName
          }
        />
        <div className={styles.break} />
        <List.Item.Meta className={styles.fullLine} description={address} />
        <div className={styles.break} />
        <List.Item.Meta className={styles.scopes} description={renderScope()} />
        <List.Item.Meta
          className={styles.actions}
          description={
            <>
              <a
                onClick={stopPropagation}
                href={`tel:${removePhoneSymbols(phone)}`}
              >
                <Button shape="circle" type="text" className={styles.button}>
                  <Call />
                </Button>
              </a>
              <Button
                onClick={openSMSMobile}
                shape="circle"
                type="text"
                className={styles.button}
              >
                <Textsms />
              </Button>
              <a
                href={`https://www.google.com/maps/dir/?api=1&destination=${address}`}
                target="_Blank"
                rel="noopener noreferrer"
                onClick={stopPropagation}
              >
                <Button shape="circle" type="text" className={styles.button}>
                  <Directions />
                </Button>
              </a>
              <Button
                onClick={openCreatePost}
                shape="circle"
                type="text"
                className={styles.button}
              >
                <Post />
              </Button>
            </>
          }
        />
      </List.Item>
    );
  }
};

export default memo(LeadItem);
