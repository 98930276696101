import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateDetails } from "Actions/dataActions";
import { fetchTable } from "Actions/tableActions";
import Form from "antd/es/form";
import message from "antd/es/message";
import Button from "antd/es/button";
import Input from "antd/es/input";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import api from "API";
import CancelButton from "Components/CancelButton";
import MarkUpHelper from "Components/MarkUpHelper";

const messageKey = "messageKey";

const OnHold = ({ unsavedChanges, setUnsavedChanges, handleClose, type }) => {
  const dispatch = useDispatch();
  const formDetails = useSelector(
    (state) => state.formState.onHold.formDetails
  );
  const viewableActivities = useSelector(
    (state) => state.dataState.viewableActivities
  );
  const table = useSelector((state) => state.tableState.table);
  const col = useSelector((state) => state.tableState.col);
  const order = useSelector((state) => state.tableState.order);
  const search = useSelector((state) => state.tableState.search);
  const activeFilter = useSelector((state) => state.tableState.activeFilter);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = useCallback(
    async (values) => {
      try {
        setLoading(true);
        // message.loading("Placing Project On Hold...", 0);
        message.loading({
          content: "Placing project on hold...",
          duration: 0,
          key: messageKey,
        });
        values._id = formDetails._id;
        const res = await api.post("/projects/onHold", values);

        dispatch(
          updateDetails({
            viewableActivities,
            details: res.data._details,
            activities: res.data._activities,
          })
        );

        dispatch(
          fetchTable({
            table,
            col,
            order,
            search,
            activeFilter,
            fetching: false,
          })
        );

        // message.success("Project placed on hold");
        message.success({
          content: "Project placed on hold",
          duration: 1.5,
          key: messageKey,
        });
        handleClose(true);
      } catch (err) {
        setLoading(false);
        // message.error("Error placing project on hold");
        message.error({
          content: "Error placing project on hold",
          duration: 1.5,
          key: messageKey,
        });
        console.log("err", err);
      }
    },
    [
      formDetails._id,
      viewableActivities,
      dispatch,
      table,
      col,
      order,
      search,
      activeFilter,
      handleClose,
    ]
  );

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error placing project on hold");
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      className="form"
    >
      <RemoveAutocomplete />

      <FormBody
        className="content-inner"
        style={{
          paddingLeft: 24,
          paddingTop: 24,
          paddingRight: 24,
          paddingBottom: 8,
          maxHeight: type === "modal" ? "calc(100vh - 161px)" : "unset",
        }}
      >
        <Form.Item
          name="holdValue"
          label={<MarkUpHelper title="Reason for putting project on hold" />}
          rules={[{ required: true, message: "Hold reason required" }]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
      </FormBody>
      <FormControls className="sticky-footer">
        <CancelButton handleClick={handleClose} />
        <Form.Item style={{ margin: 0 }}>
          <Button
            htmlType="submit"
            type="primary"
            loading={loading}
            disabled={loading || !unsavedChanges}
          >
            Submit
          </Button>
        </Form.Item>
      </FormControls>
    </Form>
  );
};

export default OnHold;
