import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { openForm } from "Actions/formActions";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
import Button from "antd/es/button";
import Divider from "antd/es/divider";
import Call from "Icons/Call";
import Star from "Icons/Star";
import Post from "Icons/Post";
import Typography from "antd/es/typography";
import Link from "Icons/Link";
import removePhoneSymbols from "Utils/removePhoneSymbols";
import Card from "antd/es/card";
import ClipboardCheck from "Icons/ClipboardCheck";
import dayjs from "dayjs";
import LazyLoad from "Components/LazyLoad";
import ImageFallback from "Components/images/Image";

const MobileScope = ({
  scope,
  scopes,
  markers,
  setScopes,
  container,
  removePins,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const openPosts = (e) => {
    e.stopPropagation();
    navigate(
      `/install/pre-job-inspections/projects/view/posts/${scope._projectId}`
    );
  };

  const openActivity = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(
        openForm("activity", {
          _id: scope._projectId,
          customerId: scope.customerId,
          customerName: scope.customerName,
          projectStatus: "Sold",
          projectManager: {
            name: scope.workOrder.projectManagerName,
          },
        })
      );
    },
    [dispatch, scope]
  );

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const openPreJobInspection = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(
        openForm("preJobInspection", {
          _id: scope._projectId,
          _scopeId: scope._id,
          scopeName: scope.name,
          callback: (data) => {
            removePins();

            setScopes(data);
          },
        })
      );
    },
    [dispatch, scope, removePins, setScopes]
  );

  return (
    <Card
      className={styles.mobileScope}
      hoverable
      size="small"
      cover={
        <>
          <div className={styles.dateStamp}>
            <span className={styles.stamp}>
              <strong>S:</strong>{" "}
              {dayjs(scope.workOrder.dateSold).format("MMMM D, YYYY")}
            </span>
          </div>
          <div className={styles.materialDateStamp}>
            <span className={styles.stamp}>
              <strong>M:</strong>{" "}
              {scope.materialDeliverDate
                ? dayjs(scope.materialDeliverDate).format("MMMM D, YYYY")
                : "Not Ordered"}
            </span>
          </div>
          <span className={styles.mobilePoCustomerName}>
            <Typography.Title level={5} style={{ margin: 0 }}>
              {scope.customerId} - {scope.workOrder.customerName}
            </Typography.Title>
          </span>
          <div style={{ overflow: "hidden", maxHeight: 120 }}>
            <LazyLoad
              offsetVertical={200}
              height="120px"
              width={"100%"}
              root={container}
            >
              <ImageFallback
                alt="pre-job-inspection"
                image={{
                  src: scope.starredImageSrc || undefined,
                  thumbnail: scope.starredImageSrcThumbnail || undefined,
                }}
              />
            </LazyLoad>
          </div>
        </>
      }
      actions={[
        <Button
          className={styles.bottomBtn}
          size="large"
          shape="circle"
          type="link"
          onClick={stopPropagation}
          href={`tel:${removePhoneSymbols(
            scope.workOrder && scope.workOrder.phone
          )}`}
        >
          <Call size={32} />
        </Button>,
        <Button
          className={styles.bottomBtn}
          size="large"
          shape="circle"
          type="link"
          onClick={openActivity}
        >
          <Post size={32} />
        </Button>,
        <Button
          className={styles.bottomBtn}
          size="large"
          shape="circle"
          type="link"
          onClick={openPreJobInspection}
        >
          <ClipboardCheck size={32} />
        </Button>,
        <Button
          className={styles.bottomBtn}
          size="large"
          shape="circle"
          type="link"
          onClick={openPosts}
        >
          <Link size={32} />
        </Button>,
      ]}
    >
      <div className={styles.description}>
        <div className={styles.detailLine}>
          <Typography.Text type="secondary" style={{ fontSize: 15 }}>
            {scope.name}
          </Typography.Text>
        </div>
        <div className={`${styles.details} ${styles.detailLine}`}>
          <Typography.Text
            type="secondary"
            style={{ fontSize: 14, display: "inline" }}
          >
            {scope.workOrder.pitches.join(", ")}
          </Typography.Text>{" "}
          <Divider type="vertical" />{" "}
          <Typography.Text
            type="secondary"
            style={{ fontSize: 14, display: "inline" }}
          >
            {scope.workOrder.squares} sq.
          </Typography.Text>{" "}
          <Divider type="vertical" />{" "}
          <Typography.Text
            type="secondary"
            style={{ fontSize: 14, display: "inline" }}
          >
            {scope.workOrder.roofType
              ? scope.workOrder.roofType.join(", ")
              : scope.workOrder.installationMethod}
          </Typography.Text>{" "}
          {/* TODO: difficultyRating overhaul */}
          {scope.workOrder.difficultyRating ? (
            <>
              <Divider type="vertical" /> <Star color="gold" />{" "}
              <Typography.Text
                type="secondary"
                style={{ fontSize: 14, display: "inline" }}
              >
                {/* TODO: difficultyRating overhaul */}
                {scope.workOrder.difficultyRating}
              </Typography.Text>
            </>
          ) : null}
        </div>
        <div className={styles.detailLine}>
          <Typography.Link
            href={`https://www.google.com/maps/place/${scope.workOrder.projectAddressLat},${scope.workOrder.projectAddressLng}`}
            target="_blank"
          >
            {scope.workOrder.projectAddress}
          </Typography.Link>
        </div>
      </div>
    </Card>
  );
};

export default MobileScope;
