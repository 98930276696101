import React, { useState, useEffect } from "react";
// import { useDispatch } from "react-redux";
// import { openForm } from "Actions/formActions";
import Alert from "antd/es/alert";
import currencyFormatter from "Utils/currencyFormatter";
import sumBy from "Utils/sumBy";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Typography from "antd/es/typography";
import Divider from "antd/es/divider";
// import Button from "antd/es/button";

const InvoiceInfo = ({
  scopes,
  contractPrice,
  // invoices,
  collections,
  expenses,
  // actions,
}) => {
  const [totalInvoiced, setTotalInvoiced] = useState(0);
  useEffect(() => {
    let _totalInvoiced = 0;
    for (const scope of scopes) {
      _totalInvoiced += scope.invoicedAmount;
    }
    setTotalInvoiced(_totalInvoiced);
  }, [scopes]);
  const totalCollected = sumBy(collections, "amount");
  // const totalInvoiced = sumBy(invoices, "balance");
  const remainingBalance = contractPrice - totalCollected;

  // const openActions = actions.filter((a) => a.open === true);
  // const closedActions = actions.filter((a) => a.open === false);
  const premierExpenses = expenses.filter((e) => e.type === "Labor");

  return (
    <>
      <Typography.Title level={4}>Billing Information</Typography.Title>
      <Alert
        style={{ marginBottom: 16 }}
        message={
          <>
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Divider>Labor Amounts</Divider>
                {premierExpenses.map((expense) => (
                  <Row key={expense._id}>
                    <Col xs={12}>
                      <b>{expense.vendor}</b>:
                    </Col>
                    <Col xs={12} style={{ textAlign: "right" }}>
                      {currencyFormatter(expense.amount)}
                    </Col>
                  </Row>
                ))}
              </Col>
              <Col xs={24} sm={12}>
                <Divider>Billing Details</Divider>
                <Row>
                  <Col xs={12}>
                    <b>Contract Price</b>:
                  </Col>
                  <Col xs={12} style={{ textAlign: "right" }}>
                    {currencyFormatter(contractPrice)}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <b>Total Invoiced</b>:
                  </Col>
                  <Col xs={12} style={{ textAlign: "right" }}>
                    {currencyFormatter(totalInvoiced)}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <b>Total Collected</b>:
                  </Col>
                  <Col xs={12} style={{ textAlign: "right" }}>
                    {currencyFormatter(totalCollected)}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <b>Remaining Balance</b>:
                  </Col>
                  <Col xs={12} style={{ textAlign: "right" }}>
                    {currencyFormatter(remainingBalance)}
                  </Col>
                </Row>
              </Col>

              <Divider>Scope Amounts</Divider>
              {scopes.map((scope) => (
                <Col xs={24} sm={12}>
                  <Divider>
                    {scope.name}
                    {scope.label ? ` [${scope.label}]` : ""}
                  </Divider>
                  <Row key={scope._id}>
                    <Col xs={12}>
                      <b>Amount</b>:
                    </Col>
                    <Col xs={12} style={{ textAlign: "right" }}>
                      {currencyFormatter(scope.amount)}
                    </Col>

                    <Col xs={12}>
                      <b>Invoiced</b>:
                    </Col>
                    <Col xs={12} style={{ textAlign: "right" }}>
                      {currencyFormatter(scope.invoicedAmount)}
                    </Col>

                    <Col xs={12}>
                      <b>Expenses</b>:
                    </Col>
                    <Col xs={12} style={{ textAlign: "right" }}>
                      {currencyFormatter(scope.expensedAmount)}
                    </Col>

                    <Col xs={12}>
                      <b>Collected</b>:
                    </Col>
                    <Col xs={12} style={{ textAlign: "right" }}>
                      {currencyFormatter(scope.collectedAmount)}
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>

            {/* {(openActions.length > 0 || closedActions.length > 0) && (
              <Row gutter={16}>
                <Col xs={24} sm={12}>
                  <Divider>Open Invoice Actions</Divider>

                  <Row gutter={16}>
                    {openActions.length > 0 ? (
                      <>
                        {openActions.map((action) => (
                          <Action action={action} key={action._id} _open />
                        ))}
                      </>
                    ) : (
                      <Col xs={24}>
                        <Row>
                          <Col xs={24}>No Open Invoice Actions</Col>
                        </Row>
                      </Col>
                    )}
                  </Row>
                </Col>

                <Col xs={24} sm={12}>
                  <Divider>Closed Invoice Actions</Divider>

                  <Row gutter={16}>
                    {closedActions.length > 0 ? (
                      <>
                        {closedActions.map((action) => (
                          <Action action={action} key={action._id} _closed />
                        ))}
                      </>
                    ) : (
                      <Col xs={24}>
                        <Row>
                          <Col xs={24}>No Closed Invoice Actions</Col>
                        </Row>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            )} */}
          </>
        }
      />
    </>
  );
};

// const Action = ({ action, _open, _closed }) => {
//   const dispatch = useDispatch();

//   const openAction = useCallback(() => {
//     dispatch(openForm("action", { ...action, editing: true }));
//   }, [dispatch, action]);

//   if (_open && action.open) {
//     return (
//       <Col xs={24}>
//         <Row>
//           <Col xs={12}>
//             <Button
//               onClick={openAction}
//               style={{ padding: 0, height: "unset" }}
//               type="link"
//             >
//               <b>{action.title}</b>
//             </Button>
//             :
//           </Col>
//           <Col xs={12} style={{ textAlign: "right" }}>
//             {currencyFormatter(action.amount)}
//           </Col>
//         </Row>
//       </Col>
//     );
//   } else if (_closed && !action.open) {
//     return (
//       <Col xs={24}>
//         <Row>
//           <Col xs={12}>
//             <Button
//               onClick={openAction}
//               style={{ padding: 0, height: "unset" }}
//               type="link"
//             >
//               <b>{action.title}</b>
//             </Button>
//             :
//           </Col>
//           <Col xs={12} style={{ textAlign: "right" }}>
//             {currencyFormatter(action.amount)}
//           </Col>
//         </Row>
//       </Col>
//     );
//   } else {
//     return null;
//   }
// };

export default InvoiceInfo;
