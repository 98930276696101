import React, { memo } from "react";
import Input from "antd/es/input";
import Form from "antd/es/form";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Typography from "antd/es/typography";
import Pitches from "Components/Inputs/Pitches";
import Divider from "antd/es/divider";
import DatePicker from "Components/Inputs/DatePicker";
import useIsMobile from "Hooks/useIsMobile";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";

const GuaranteedRepairDetails = () => {
  const mobile = useIsMobile();
  return (
    <>
      <Typography.Title level={4}>Project Details</Typography.Title>
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="pitches"
            label="Pitches"
            rules={[{ required: true, message: "Pitches is required" }]}
          >
            <Pitches />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="stories"
            label="Stories"
            rules={[{ required: true, message: "Stories is required" }]}
          >
            <InputNoAutoComplete id="stories" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="originalPO"
            label="Original PO"
            rules={[{ required: true, message: "Original PO is required" }]}
          >
            <InputNoAutoComplete type="tel" id="originalPO" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="originalInstallDate"
            label="Original Install Date"
            rules={[
              { required: true, message: "Original Install Date is required" },
            ]}
          >
            <DatePicker
              format="MMMM DD, YYYY"
              getPopupContainer={(trigger) =>
                !mobile
                  ? trigger.parentNode.parentNode.parentNode.parentNode
                  : document.body
              }
              getCalendarContainer={(trigger) =>
                !mobile
                  ? trigger.parentNode.parentNode.parentNode.parentNode
                  : document.body
              }
              inputReadOnly={true}
              autoComplete="dont-use-autocomplete"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="projectDescription"
            label="Project Description of Work"
            rules={[
              {
                required: true,
                message: "Project Description of Work is required",
              },
            ]}
          >
            <Input.TextArea autoComplete="dont-use-autocomplete" rows={4} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="scopeDescription"
            label="Scope Description of Work"
            rules={[
              {
                required: true,
                message: "Scope Description of Work is required",
              },
            ]}
          >
            <Input.TextArea autoComplete="dont-use-autocomplete" rows={4} />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
    </>
  );
};

export default memo(GuaranteedRepairDetails);
