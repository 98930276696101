import * as actions from "./actionTypes";
import api from "API";
import sumBy from "Utils/sumBy";

export const setSelected = (_id) => (dispatch) => {
  try {
    dispatch({ type: actions.SET_SELECTED, payload: _id });
  } catch (err) {
    console.log("setSelected err", err);
  }
};

export const getData =
  ({ route, fetching = true, _activityId }) =>
  async (dispatch) => {
    try {
      if (fetching) dispatch({ type: actions.FETCHING_DATA });
      const res = await api.get(route);
      let _hashtags = [];
      res.data._activities.map((a) => {
        if (a.hashtags) {
          a.hashtags.map((h) => {
            if (!_hashtags.includes(h)) _hashtags.push(h);
            return h;
          });
        }
        return a;
      });
      _hashtags = _hashtags.sort();

      let legacyFiles = [
        ...res.data._details.images,
        ...res.data._details.documents,
        ...res.data._details.contracts,
      ];

      for (const l of legacyFiles) {
        l._projectId = res.data._details._id;
        l._activityId = "false";
        l.legacy = true;
      }

      let actualExtras = 0,
        actualPermit = 0,
        actualLabor = 0,
        actualMaterials = 0,
        actualDumping = 0;

      for (const e of res.data._expenses) {
        if (e.type === "Dumping") {
          actualDumping += e.amount;
        } else if (e.type === "Materials") {
          actualMaterials += e.amount;
        } else if (e.type === "Labor") {
          actualLabor += e.amount;
        } else if (e.type === "Permit") {
          actualPermit += e.amount;
        } else {
          actualExtras += e.amount;
        }
      }

      let totalActions = 0,
        totalExpenses = 0,
        totalCollections = 0,
        totalInvoiced = 0,
        remainingBalance = 0,
        actualOverhead = 0,
        actualBalance = 0;

      if (res.data._actions && res.data._actions.length > 0)
        totalActions = sumBy(res.data._actions, "amount") || 0;
      if (res.data._expenses && res.data._expenses.length > 0)
        totalExpenses = sumBy(res.data._expenses, "amount") || 0;
      if (res.data._collections && res.data._collections.length > 0)
        totalCollections = sumBy(res.data._collections, "amount") || 0;
      if (res.data._invoices && res.data._invoices.length > 0)
        totalInvoiced = sumBy(res.data._invoices, "balance") || 0;
      if (res.data._details && res.data._details.contractPrice)
        remainingBalance = res.data._details.contractPrice - totalCollections;

      actualOverhead = totalCollections * 0.13;
      actualBalance = totalCollections - totalExpenses - actualOverhead;

      let end = 10;

      if (_activityId) {
        end =
          res.data._activities.findIndex(
            (a) => a._id.toString() === _activityId
          ) + 1;
      }

      if (end <= 10) {
        end = 10;
      }

      const viewableActivities = res.data._activities.slice(0, end);

      dispatch({
        type: actions.SET_ALL_DATA,
        payload: {
          details: res.data._details,
          activities: res.data._activities,
          filteredActivities: res.data._activities,
          activitiesLength: res.data._activities.length,
          viewableActivities,
          allMedia: res.data._allMedia,
          collections: res.data._collections,
          expenses: res.data._expenses,
          expensesByVendor: res.data.expensesByVendor,
          expensesByScope: res.data.expensesByScope,
          invoices: res.data._invoices,
          completionCertificates: res.data._completionCertificates,
          actions: res.data._actions,
          dumpings: res.data._dumpings,
          scopes: res.data._scopes,
          images: res.data._details.images,
          documents: res.data._documents,
          selectedRow: res.data._details._id,
          hashtags: _hashtags,
          legacyFiles,
          totalExpenses,
          totalCollections,
          totalInvoiced,
          totalActions,
          remainingBalance,
          mold: {
            expectedBalance: res.data._details.expectedBalance || 0,
            expectedOverhead: res.data._details.expectedOverhead || 0,
            expectedExtras: res.data._details.expectedExtras || 0,
            expectedLabor: res.data._details.expectedLabor || 0,
            expectedMaterials: res.data._details.expectedMaterials || 0,
            expectedDumping: res.data._details.expectedDumping || 0,
            expectedGutters: res.data._details.expectedGutters || 0,
            expectedInsulation: res.data._details.expectedInsulation || 0,
          },
          breakdown: {
            actualBalance,
            actualOverhead,
            actualExtras,
            actualPermit,
            actualLabor,
            actualMaterials,
            actualDumping,
          },
        },
      });
    } catch (err) {
      console.log("err", err);
      return err;
    }
  };

export const updateDetail =
  ({ key, value, activities, viewableActivities }) =>
  (dispatch) => {
    try {
      const _viewableActivities = activities.slice(
        0,
        viewableActivities.length
      );
      if (key === "salesmen") {
        dispatch({
          type: actions.UPDATE_SALESMEN,
          payload: {
            salesObj: value.salesObj,
            salesman: value.salesman,
            activities,
            filteredActivities: activities,
            activitiesLength: activities.length,
            viewableActivities: _viewableActivities,
          },
        });
      } else if (key === "projectAddress") {
        dispatch({
          type: actions.UPDATE_PROJECT_ADDRESS,
          payload: {
            projectAddress: value.projectAddress,
            projectAddressStreet: value.projectAddressStreet,
            projectAddressCity: value.projectAddressCity,
            projectAddressState: value.projectAddressState,
            projectAddressZip: value.projectAddressZip,
            projectAddressLat: value.projectAddressLat,
            projectAddressLng: value.projectAddressLng,
            projectAddressCounty: value.projectAddressCounty,
            projectAddressPermitAuthority: value.projectAddressPermitAuthority,
            activities,
            filteredActivities: activities,
            activitiesLength: activities.length,
            viewableActivities: _viewableActivities,
          },
        });
      } else if (key === "billingAddress") {
        dispatch({
          type: actions.UPDATE_BILLING_ADDRESS,
          payload: {
            billingAddress: value.billingAddress,
            billingAddressStreet: value.billingAddressStreet,
            billingAddressCity: value.billingAddressCity,
            billingAddressState: value.billingAddressState,
            billingAddressZip: value.billingAddressZip,
            billingAddressLat: value.billingAddressLat,
            billingAddressLng: value.billingAddressLng,
            billingAddressCounty: value.billingAddressCounty,
            billingAddressPermitAuthority: value.billingAddressPermitAuthority,
            activities,
            filteredActivities: activities,
            activitiesLength: activities.length,
            viewableActivities: _viewableActivities,
          },
        });
      } else if (key === "details") {
        dispatch({
          type: actions.UPDATE_DETAILS,
          payload: {
            details: value,
            activities,
            filteredActivities: activities,
            activitiesLength: activities.length,
            viewableActivities: _viewableActivities,
          },
        });
      } else {
        dispatch({
          type: actions.UPDATE_DETAIL,
          payload: {
            key,
            value,
            activities,
            filteredActivities: activities,
            activitiesLength: activities.length,
            viewableActivities: _viewableActivities,
          },
        });
      }
    } catch (err) {
      console.log("updateDetail err", err);
    }
  };

export const updateActivitiesAndSelectedActivity =
  ({
    activities,
    allMedia,
    activity,
    legacyFiles = [],
    salesObj,
    _projectId,
    viewableActivities,
  }) =>
  async (dispatch) => {
    try {
      let updatedActivity = activity;
      if (activities && activity) {
        updatedActivity = activities.find((a) => a._id === activity._id);
      }

      if (salesObj) {
        for (const s of salesObj) {
          window.socket.emit("updateDashboard", { _user: s._user });
          window.socket.emit("updateFeed", { _user: s._user });
        }
      }

      window.socket.emit("update", { _id: _projectId });

      for (const l of legacyFiles) {
        l._projectId = _projectId;
        l._activityId = "false";
        l.legacy = true;
      }

      const _viewableActivities = activities.slice(
        0,
        viewableActivities.length
      );

      dispatch({
        type: actions.UPDATE_ACTIVITIES_AND_SELECTED_ACTIVITY,
        payload: {
          activities,
          viewableActivities: _viewableActivities,
          filteredActivities: activities,
          activitiesLength: activities.length,
          allMedia,
          selectedActivity: updatedActivity,
          legacyFiles,
        },
      });
    } catch (err) {
      console.log("updateActivitiesAndSelectedActivity err", err);
    }
  };

export const openImageViewer = (image) => (dispatch) => {
  dispatch({
    type: actions.OPEN_IMAGE_VIEWER,
    payload: image,
  });
};

export const closeImageViewer = () => (dispatch) => {
  dispatch({ type: actions.CLOSE_IMAGE_VIEWER });
};

export const updateActivitiesAndAllMedia =
  ({
    activities,
    allMedia,
    _projectId,
    salesObj,
    legacyFiles = [],
    _global,
    dashboard,
    viewableActivities,
  }) =>
  async (dispatch) => {
    try {
      if (salesObj) {
        for (const s of salesObj) {
          window.socket.emit("updateDashboard", { _user: s._user, dashboard });
          window.socket.emit("updateFeed", { _user: s._user, dashboard });
        }
      }

      window.socket.emit("update", { _id: _projectId });
      if (_global) window.socket.emit("globalUpdate");

      for (const l of legacyFiles) {
        l._projectId = _projectId;
        l._activityId = "false";
        l.legacy = true;
      }

      const _viewableActivities = activities.slice(
        0,
        viewableActivities.length
      );

      dispatch({
        type: actions.UPDATE_ACTIVITIES_AND_ALL_MEDIA,
        payload: {
          activities,
          filteredActivities: activities,
          activitiesLength: activities.length,
          viewableActivities: _viewableActivities,
          allMedia,
          legacyFiles,
        },
      });
    } catch (err) {
      console.log("updateActivitiesAndAllMedia err", err);
    }
  };

export const updateSelectedActivity =
  (activity, updatedActivities) => (dispatch) => {
    try {
      let updatedActivity = activity;
      if (updatedActivities && activity) {
        updatedActivity = updatedActivities.find((a) => a._id === activity._id);
      }

      dispatch({
        type: actions.UPDATE_SELECTED_ACTIVITY,
        payload: updatedActivity,
      });
    } catch (err) {
      console.log("updateSelectedActivity err", err);
    }
  };

export const updateActivitiesAllMediaAndHashtags =
  ({ data, _projectId, dashboard, viewableActivities }) =>
  async (dispatch) => {
    try {
      let _hashtags = [];
      data._activities.map((a) => {
        if (a.hashtags) {
          a.hashtags.map((h) => {
            if (!_hashtags.includes(h)) _hashtags.push(h);
            return h;
          });
        }
        return a;
      });
      _hashtags = _hashtags.sort();

      if (data.salesObj) {
        for (const s of data.salesObj) {
          window.socket.emit("updateDashboard", { _user: s._user, dashboard });
          window.socket.emit("updateFeed", { _user: s._user, dashboard });
        }
      }

      const _viewableActivities = data._activities.slice(
        0,
        viewableActivities.length
      );

      window.socket.emit("update", { _id: _projectId });

      dispatch({
        type: actions.UPDATE_ACTIVITIES_ALL_MEDIA_AND_HASHTAGS,
        payload: {
          activities: data._activities,
          filteredActivities: data._activities,
          viewableActivities: _viewableActivities,
          activitiesLength: data._activities.length,
          allMedia: data._allMedia,
          hashtags: _hashtags,
        },
      });
    } catch (err) {
      console.log("updateActivitiesAllMediaAndHashtags err", err);
      return err;
    }
  };

export const updateInvoicesActivitiesAllMediaAndHashtags =
  ({ data, _projectId, viewableActivities }) =>
  async (dispatch) => {
    try {
      let _hashtags = [];
      data._activities.map((a) => {
        if (a.hashtags) {
          a.hashtags.map((h) => {
            if (!_hashtags.includes(h)) _hashtags.push(h);
            return h;
          });
        }
        return a;
      });
      _hashtags = _hashtags.sort();

      if (data.salesObj) {
        for (const s of data.salesObj) {
          window.socket.emit("updateDashboard", { _user: s._user });
          window.socket.emit("updateFeed", { _user: s._user });
        }
      }

      window.socket.emit("update", { _id: _projectId });

      const totalInvoiced = sumBy(data._invoices, "balance") || 0;

      const _viewableActivities = data._activities.slice(
        0,
        viewableActivities.length
      );

      dispatch({
        type: actions.UPDATE_INVOICES_ACTIVITIES_ALL_MEDIA_AND_HASHTAGS,
        payload: {
          activities: data._activities,
          filteredActivities: data._activities,
          activitiesLength: data._activities.length,
          viewableActivities: _viewableActivities,
          allMedia: data._allMedia,
          hashtags: _hashtags,
          invoices: data._invoices,
          totalInvoiced,
        },
      });
    } catch (err) {
      console.log("updateInvoicesActivitiesAllMediaAndHashtags err", err);
      return err;
    }
  };

export const updateCompletionCertificatesActivitiesAllMediaAndHashtags =
  ({ data, _projectId, viewableActivities }) =>
  async (dispatch) => {
    try {
      let _hashtags = [];
      data._activities.map((a) => {
        if (a.hashtags) {
          a.hashtags.map((h) => {
            if (!_hashtags.includes(h)) _hashtags.push(h);
            return h;
          });
        }
        return a;
      });
      _hashtags = _hashtags.sort();

      if (data.salesObj) {
        for (const s of data.salesObj) {
          window.socket.emit("updateDashboard", { _user: s._user });
          window.socket.emit("updateFeed", { _user: s._user });
        }
      }

      window.socket.emit("update", { _id: _projectId });

      const _viewableActivities = data._activities.slice(
        0,
        viewableActivities.length
      );

      dispatch({
        type: actions.UPDATE_COMPLETION_CERTIFICATES_ACTIVITIES_ALL_MEDIA_AND_HASHTAGS,
        payload: {
          activities: data._activities,
          filteredActivities: data._activities,
          activitiesLength: data._activities.length,
          viewableActivities: _viewableActivities,
          allMedia: data._allMedia,
          hashtags: _hashtags,
          completionCertificates: data._completionCertificates,
        },
      });
    } catch (err) {
      console.log(
        "updateCompletionCertificatesActivitiesAllMediaAndHashtags err",
        err
      );
      return err;
    }
  };

export const updateActivitiesAndHashtags =
  ({ data, _projectId, dashboard, viewableActivities }) =>
  async (dispatch) => {
    try {
      let _hashtags = [];
      data._activities.map((a) => {
        if (a.hashtags) {
          a.hashtags.map((h) => {
            if (!_hashtags.includes(h)) _hashtags.push(h);
            return h;
          });
        }
        return a;
      });
      _hashtags = _hashtags.sort();

      if (data.salesObj) {
        for (const s of data.salesObj) {
          window.socket.emit("updateDashboard", { _user: s._user, dashboard });
          window.socket.emit("updateFeed", { _user: s._user, dashboard });
        }
      }

      window.socket.emit("update", { _id: _projectId });

      const _viewableActivities = data._activities.slice(
        0,
        viewableActivities.length
      );

      dispatch({
        type: actions.UPDATE_HASHTAGS,
        payload: {
          activities: data._activities,
          activitiesLength: data._activities.length,
          hashtags: _hashtags,
          filteredActivities: data._activities,
          viewableActivities: _viewableActivities,
        },
      });
    } catch (err) {
      console.log("updateActivitiesAndHashtags err", err);
      return err;
    }
  };

export const updateActivitiesOnly =
  ({
    activities,
    salesObj,
    _projectId,
    _global,
    dashboard,
    viewableActivities,
  }) =>
  (dispatch) => {
    if (salesObj) {
      for (const s of salesObj) {
        window.socket.emit("updateDashboard", { _user: s._user, dashboard });
        window.socket.emit("updateFeed", { _user: s._user, dashboard });
      }
    }

    window.socket.emit("update", { _id: _projectId });

    if (_global) window.socket.emit("globalUpdate");

    const _viewableActivities = activities.slice(0, viewableActivities.length);

    dispatch({
      type: actions.UPDATE_ACTIVITIES_ONLY,
      payload: {
        activities,
        filteredActivities: activities,
        activitiesLength: activities.length,
        viewableActivities: _viewableActivities,
      },
    });
  };

export const updateDetails =
  ({ details, activities, viewableActivities }) =>
  async (dispatch) => {
    try {
      const _viewableActivities = activities.slice(
        0,
        viewableActivities.length
      );
      dispatch({
        type: actions.UPDATE_DETAILS,
        payload: {
          details,
          activities,
          filteredActivities: activities,
          activitiesLength: activities.length,
          viewableActivities: _viewableActivities,
        },
      });
    } catch (err) {
      console.log("updateDetails err", err);
      return err;
    }
  };

export const updateDumpstersActivitiesAndHashtags =
  ({ data, _projectId, viewableActivities }) =>
  async (dispatch) => {
    try {
      let _hashtags = [];
      data._activities.map((a) => {
        if (a.hashtags) {
          a.hashtags.map((h) => {
            if (!_hashtags.includes(h)) _hashtags.push(h);
            return h;
          });
        }
        return a;
      });
      _hashtags = _hashtags.sort();

      if (data.salesObj) {
        for (const s of data.salesObj) {
          window.socket.emit("updateDashboard", { _user: s._user });
          window.socket.emit("updateFeed", { _user: s._user });
        }
      }

      window.socket.emit("update", { _id: _projectId });

      const _viewableActivities = data._activities.slice(
        0,
        viewableActivities.length
      );

      dispatch({
        type: actions.UPDATE_DUMPSTERS_ACTIVITIES_AND_HASHTAGS,
        payload: {
          activities: data._activities,
          filteredActivities: data._activities,
          activitiesLength: data._activities.length,
          viewableActivities: _viewableActivities,
          hashtags: _hashtags,
          dumpings: data._dumpings,
        },
      });
    } catch (err) {
      console.log("updateDumpstersActivitiesAndHashtags err", err);
      return err;
    }
  };

export const updateActionsActivitiesAndHashtags =
  ({ data, _projectId, viewableActivities }) =>
  async (dispatch) => {
    try {
      let _hashtags = [];
      data._activities.map((a) => {
        if (a.hashtags) {
          a.hashtags.map((h) => {
            if (!_hashtags.includes(h)) _hashtags.push(h);
            return h;
          });
        }
        return a;
      });
      _hashtags = _hashtags.sort();

      if (data.salesObj) {
        for (const s of data.salesObj) {
          window.socket.emit("updateDashboard", { _user: s._user });
          window.socket.emit("updateFeed", { _user: s._user });
        }
      }

      window.socket.emit("update", { _id: _projectId });

      const totalActions = sumBy(data._actions, "amount") || 0;

      const _viewableActivities = data._activities.slice(
        0,
        viewableActivities.length
      );

      dispatch({
        type: actions.UPDATE_ACTIONS_ACTIVITIES_AND_HASHTAGS,
        payload: {
          activities: data._activities,
          filteredActivities: data._activities,
          activitiesLength: data._activities.length,
          viewableActivities: _viewableActivities,
          hashtags: _hashtags,
          actions: data._actions,
          totalActions,
        },
      });
    } catch (err) {
      console.log("updateActionsActivitiesAndHashtags err", err);
      return err;
    }
  };

export const updateDocumentsActivitiesAndHashtags =
  ({ data, _projectId, viewableActivities }) =>
  async (dispatch) => {
    try {
      let _hashtags = [];
      data._activities.map((a) => {
        if (a.hashtags) {
          a.hashtags.map((h) => {
            if (!_hashtags.includes(h)) _hashtags.push(h);
            return h;
          });
        }
        return a;
      });
      _hashtags = _hashtags.sort();

      if (data.salesObj) {
        for (const s of data.salesObj) {
          window.socket.emit("updateDashboard", { _user: s._user });
          window.socket.emit("updateFeed", { _user: s._user });
        }
      }

      window.socket.emit("update", { _id: _projectId });

      const _viewableActivities = data._activities.slice(
        0,
        viewableActivities.length
      );

      dispatch({
        type: actions.UPDATE_DOCUMENTS_ACTIVITIES_AND_HASHTAGS,
        payload: {
          activities: data._activities,
          filteredActivities: data._activities,
          activitiesLength: data._activities.length,
          viewableActivities: _viewableActivities,
          hashtags: _hashtags,
          documents: data._documents,
        },
      });
    } catch (err) {
      console.log("updateDocumentsActivitiesAndHashtags err", err);
      return err;
    }
  };

export const updateExpensesActivitiesAndHashtags =
  ({ data, _projectId, viewableActivities }) =>
  async (dispatch) => {
    try {
      let _hashtags = [];
      data._activities.map((a) => {
        if (a.hashtags) {
          a.hashtags.map((h) => {
            if (!_hashtags.includes(h)) _hashtags.push(h);
            return h;
          });
        }
        return a;
      });
      _hashtags = _hashtags.sort();

      if (data.salesObj) {
        for (const s of data.salesObj) {
          window.socket.emit("updateDashboard", { _user: s._user });
          window.socket.emit("updateFeed", { _user: s._user });
        }
      }

      window.socket.emit("update", { _id: _projectId });

      let actualExtras = 0,
        actualPermit = 0,
        actualLabor = 0,
        actualMaterials = 0,
        actualDumping = 0;

      for (const e of data._expenses) {
        if (e.type === "Dumping") {
          actualDumping += e.amount;
        } else if (e.type === "Materials") {
          actualMaterials += e.amount;
        } else if (e.type === "Labor") {
          actualLabor += e.amount;
        } else if (e.type === "Permit") {
          actualPermit += e.amount;
        } else {
          actualExtras += e.amount;
        }
      }

      const totalExpenses = sumBy(data._expenses, "amount") || 0;
      const totalCollections = sumBy(data._collections, "amount") || 0;

      const actualOverhead = totalCollections * 0.13;
      const actualBalance = totalCollections - totalExpenses - actualOverhead;

      const _viewableActivities = data._activities.slice(
        0,
        viewableActivities.length
      );

      dispatch({
        type: actions.UPDATE_EXPENSES_ACTIVITIES_AND_HASHTAGS,
        payload: {
          activities: data._activities,
          viewableActivities: _viewableActivities,
          filteredActivities: data._activities,
          activitiesLength: data._activities.length,
          hashtags: _hashtags,
          expenses: data._expenses,
          totalExpenses,
          breakdown: {
            actualBalance,
            actualOverhead,
            actualExtras,
            actualPermit,
            actualLabor,
            actualMaterials,
            actualDumping,
          },
        },
      });
    } catch (err) {
      console.log("updateExpensesActivitiesAndHashtags err", err);
      return err;
    }
  };

export const updateScopesActivitiesAndHashtags =
  ({ data, _projectId, viewableActivities }) =>
  async (dispatch) => {
    try {
      let _hashtags = [];
      data._activities.map((a) => {
        if (a.hashtags) {
          a.hashtags.map((h) => {
            if (!_hashtags.includes(h)) _hashtags.push(h);
            return h;
          });
        }
        return a;
      });
      _hashtags = _hashtags.sort();

      if (data.salesObj) {
        for (const s of data.salesObj) {
          window.socket.emit("updateDashboard", { _user: s._user });
          window.socket.emit("updateFeed", { _user: s._user });
        }
      }

      window.socket.emit("update", { _id: _projectId });

      const _viewableActivities = data._activities.slice(
        0,
        viewableActivities.length
      );

      dispatch({
        type: actions.UPDATE_SCOPES_ACTIVITIES_AND_HASHTAGS,
        payload: {
          activities: data._activities,
          filteredActivities: data._activities,
          activitiesLength: data._activities.length,
          viewableActivities: _viewableActivities,
          hashtags: _hashtags,
          scopes: data._scopes,
          contractPrice: data.contractPrice,
          mold: {
            expectedBalance: data.expectedBalance || 0,
            expectedOverhead: data.expectedOverhead || 0,
            expectedExtras: data.expectedExtras || 0,
            expectedLabor: data.expectedLabor || 0,
            expectedMaterials: data.expectedMaterials || 0,
            expectedDumping: data.expectedDumping || 0,
          },
        },
      });
    } catch (err) {
      console.log("updateScopesActivitiesAndHashtags err", err);
      return err;
    }
  };

export const updateCollectionsActivitiesAndHashtags =
  ({ data, _projectId, viewableActivities }) =>
  async (dispatch) => {
    try {
      let _hashtags = [];
      data._activities.map((a) => {
        if (a.hashtags) {
          a.hashtags.map((h) => {
            if (!_hashtags.includes(h)) _hashtags.push(h);
            return h;
          });
        }
        return a;
      });
      _hashtags = _hashtags.sort();

      if (data.salesObj) {
        for (const s of data.salesObj) {
          window.socket.emit("updateDashboard", { _user: s._user });
          window.socket.emit("updateFeed", { _user: s._user });
        }
      }

      window.socket.emit("update", { _id: _projectId });

      const totalExpenses = sumBy(data._expenses, "amount") || 0;
      const totalCollections = sumBy(data._collections, "amount") || 0;

      const actualOverhead = totalCollections * 0.13;
      const actualBalance = totalCollections - totalExpenses - actualOverhead;
      const remainingBalance = data.contractPrice - totalCollections;

      const _viewableActivities = data._activities.slice(
        0,
        viewableActivities.length
      );

      dispatch({
        type: actions.UPDATE_COLLECTIONS_ACTIVITIES_AND_HASHTAGS,
        payload: {
          activities: data._activities,
          filteredActivities: data._activities,
          activitiesLength: data._activities.length,
          viewableActivities: _viewableActivities,
          hashtags: _hashtags,
          collections: data._collections,
          totalCollections,
          actualOverhead,
          actualBalance,
          remainingBalance,
        },
      });
    } catch (err) {
      console.log("updateCollectionsActivitiesAndHashtags err", err);
      return err;
    }
  };

export const updateScopes =
  ({ _projectId }) =>
  async (dispatch) => {
    try {
      console.log("action fired");
      console.log("_projectId", _projectId);
      const res = await api.get(`/scopes/getAll/${_projectId}`);
      console.log("res.data", res.data);
      dispatch({
        type: actions.UPDATE_SCOPES,
        payload: res.data,
      });
    } catch (err) {
      console.log("updateScopes err", err);
      return err;
    }
  };

export const updateScopesAndActivities =
  ({ data, _projectId, viewableActivities }) =>
  async (dispatch) => {
    try {
      if (data.salesObj) {
        for (const s of data.salesObj) {
          window.socket.emit("updateDashboard", { _user: s._user });
          window.socket.emit("updateFeed", { _user: s._user });
        }
      }

      window.socket.emit("update", { _id: _projectId });

      const _viewableActivities = data._activities.slice(
        0,
        viewableActivities.length
      );

      dispatch({
        type: actions.UPDATE_SCOPES_AND_ACTIVITIES,
        payload: {
          activities: data._activities,
          filteredActivities: data._activities,
          activitiesLength: data._activities.length,
          viewableActivities: _viewableActivities,
          scopes: data._scopes,
          pitches: data._details.pitches,
          stories: data._details.stories,
          customerName: data._details.customerName,
          propertyType: data._details.propertyType,
          projectAddressStreet: data._details.projectAddressStreet,
          projectAddressCity: data._details.projectAddressCity,
          projectAddressState: data._details.projectAddressState,
          projectAddressZip: data._details.projectAddressZip,
          projectAddressCounty: data._details.projectAddressCounty,
          projectAddressPermitAuthority:
            data._details.projectAddressPermitAuthority,
          projectAddressLat: data._details.projectAddressLat,
          projectAddressLng: data._details.projectAddressLng,
          projectDescription: data._details.projectDescription,
          projectAddress: data._details.projectAddress,
          phone: data._details.phone,
        },
      });
    } catch (err) {
      console.log("updateScopesAndActivities err", err);
      return err;
    }
  };

export const workOrderCreated =
  ({ data, _projectId, viewableActivities }) =>
  (dispatch) => {
    try {
      let _hashtags = [];
      data._activities.map((a) => {
        if (a.hashtags) {
          a.hashtags.map((h) => {
            if (!_hashtags.includes(h)) _hashtags.push(h);
            return h;
          });
        }
        return a;
      });
      _hashtags = _hashtags.sort();

      if (data.salesObj) {
        for (const s of data.salesObj) {
          window.socket.emit("updateDashboard", { _user: s._user });
          window.socket.emit("updateFeed", { _user: s._user });
        }
      }

      window.socket.emit("update", { _id: _projectId });

      const _viewableActivities = data._activities.slice(
        0,
        viewableActivities.length
      );

      dispatch({
        type: actions.WORKORDER_CREATED,
        payload: {
          activities: data._activities,
          filteredActivities: data._activities,
          activitiesLength: data._activities.length,
          viewableActivities: _viewableActivities,
          hashtags: _hashtags,
          scopes: data._scopes,
          allMedia: data._allMedia,
          pitches: data._details.pitches,
          stories: data._details.stories,
          customerName: data._details.customerName,
          propertyType: data._details.propertyType,
          projectAddressStreet: data._details.projectAddressStreet,
          projectAddressCity: data._details.projectAddressCity,
          projectAddressState: data._details.projectAddressState,
          projectAddressZip: data._details.projectAddressZip,
          projectAddressCounty: data._details.projectAddressCounty,
          projectAddressPermitAuthority:
            data._details.projectAddressPermitAuthority,
          projectAddressLat: data._details.projectAddressLat,
          projectAddressLng: data._details.projectAddressLng,
          projectDescription: data._details.projectDescription,
          projectAddress: data._details.projectAddress,
          phone: data._details.phone,
        },
      });
    } catch (err) {
      console.log("workOrderCreated err", err);
      return err;
    }
  };

export const fetchActivities =
  ({ start, filteredActivities, viewableActivities }) =>
  async (dispatch) => {
    try {
      const additionalActivities = filteredActivities.slice(start, start + 10);
      const _viewableActivities = [
        ...viewableActivities,
        ...additionalActivities,
      ];

      dispatch({
        type: actions.ADD_ACTIVITIES,
        payload: _viewableActivities,
      });
    } catch (err) {
      console.log("fetchActivities err", err);
    }
  };

export const updateFilteredActivities =
  ({ activities, filter }) =>
  async (dispatch) => {
    try {
      let _filteredActivities = activities;
      if (filter !== "undefined") {
        _filteredActivities = activities.filter((a) => {
          if (filter === undefined) {
            return true;
          } else if (
            a.title &&
            a.title.toLowerCase() === filter.toLowerCase()
          ) {
            return true;
          } else if (a.icon && a.icon === filter.toLowerCase()) {
            return true;
          } else if (a.type && a.type === filter.toLowerCase()) {
            return true;
          } else if (a.hashtags && a.hashtags.includes(filter.toLowerCase())) {
            return true;
          } else {
            return false;
          }
        });
      }
      const _viewableActivities = _filteredActivities.slice(0, 10);

      dispatch({
        type: actions.UPDATE_FILTERED_ACTIVITIES,
        payload: {
          filteredActivities: _filteredActivities,
          viewableActivities: _viewableActivities,
          activitiesLength: _filteredActivities.length,
        },
      });
    } catch (err) {
      console.log("updateFilteredActivities err", err);
    }
  };

export const unsetData = () => async (dispatch) => {
  dispatch({ type: actions.UNSET_DATA });
};

export const startScope =
  ({ scopeId }) =>
  async (dispatch) => {
    dispatch({ type: actions.START_SCOPE, payload: scopeId });
  };

export const stopScope =
  ({ scopeId }) =>
  async (dispatch) => {
    dispatch({ type: actions.STOP_SCOPE, payload: scopeId });
  };

export const windowsReceived =
  ({ scopeId }) =>
  async (dispatch) => {
    dispatch({ type: actions.WINDOWS_RECEIVED, payload: scopeId });
  };
