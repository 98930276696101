import React from "react";
import LineChartWithInterval from "Reports/LineChartWithInterval";

export default function CompletedRoofs() {
  return (
    <LineChartWithInterval
      path="/reports/completed-roofs"
      type="count"
      summary={true}
      lineItems={true}
      aggregate={true}
      report="COMPLETED ROOFS"
      headers={["TYPE", "COUNT"]}
    />
  );
}
