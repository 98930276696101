Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function(path, name) {
  var Component = function Component(props) {
    return _react2.default.createElement(_Svg2.default, props, path);
  };
  Component.displayName = name;
  return Component;
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _Svg = require("./Svg");

var _Svg2 = _interopRequireDefault(_Svg);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}
