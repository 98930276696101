import React from "react";
import text from "Assets/splash-screen-text.png";
import icon from "Assets/splash-screen-icon.png";

const SplashScreen = () => {
  return (
    <div className="splash-container">
      <div>
        <img src={text} alt="splash-screen-text" />
        <img src={icon} alt="splash-screen-icon" className="rotate" />
      </div>
    </div>
  );
};

export default SplashScreen;
