import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openForm } from "Actions/formActions";
import FormControls from "Components/FormControls";
import Button from "antd/es/button";
import MessageOutlined from "@ant-design/icons/MessageOutlined";
import styles from "./styles.module.scss";

const Controls = ({
  _activityId,
  _imageSrc,
  _activityCreatedBy,
  _projectId,
  _customerId,
  _projectStatus,
}) => {
  const _id = useSelector((state) => state.dataState.details._id);
  const projectStatus = useSelector(
    (state) => state.dataState.details.projectStatus
  );
  const customerId = useSelector((state) => state.dataState.details.customerId);
  const dispatch = useDispatch();

  const imageReply = useCallback(() => {
    dispatch(
      openForm("comment", {
        _id: _activityId,
        _projectId: _projectId || _id,
        replyTo: {
          src: _imageSrc,
          publisher: _activityCreatedBy,
        },
        customerId: _customerId || customerId,
        projectStatus: _projectStatus || projectStatus,
      })
    );
  }, [
    dispatch,
    _activityId,
    _projectId,
    _id,
    _imageSrc,
    _activityCreatedBy,
    _customerId,
    customerId,
    _projectStatus,
    projectStatus,
  ]);

  return (
    <FormControls className={styles.actions}>
      <Button block size="large" onClick={imageReply}>
        <MessageOutlined />
      </Button>
    </FormControls>
  );
};

export default Controls;
