import React, { memo } from "react";
import FormControls from "Components/FormControls";
import Avatar from "antd/es/avatar";
import Tooltip from "antd/es/tooltip";
import Button from "antd/es/button";
import LikeFilled from "@ant-design/icons/LikeFilled";
import MessageFilled from "@ant-design/icons/MessageFilled";
import TagFilled from "@ant-design/icons/TagFilled";
import Hash from "Icons/Hash";
import styles from "./styles.module.scss";
import generateAvatar from "Utils/generateAvatar";
import LazyLoad from "Components/LazyLoad";
import nomatch from "Assets/avatars/default.png";

const ActivityHeader = ({
  avatar,
  createdBy,
  activityDate,
  likes,
  comments,
  followers,
  hashtags,
  scrollContainer,
}) => {
  return (
    <FormControls className={styles.header}>
      <div>
        <div className={styles.headerText}>
          <LazyLoad
            offsetVertical={100}
            width={32}
            height={32}
            root={scrollContainer}
          >
            <div style={{ borderRadius: "50%" }} className="z-depth-1">
              <Avatar
                src={generateAvatar(avatar, createdBy)}
                icon={<img alt="avatar" src={nomatch} />}
              />
            </div>
          </LazyLoad>
          <div>
            <div className={styles.title}>{createdBy}</div>
            <div className={styles.date}>{activityDate}</div>
          </div>
        </div>
      </div>
      <div className={styles.counts}>
        {likes && likes.length > 0 && (
          <Tooltip
            title={likes.map((l) => (
              <div key={l._user}>{l.name}</div>
            ))}
          >
            <Button>
              <div className={styles.iconCircle}>
                <LikeFilled />
              </div>{" "}
              {likes.length}
            </Button>
          </Tooltip>
        )}

        {comments && comments.length > 0 && (
          <Button>
            <div className={styles.iconCircle}>
              <MessageFilled />
            </div>{" "}
            {comments.length}
          </Button>
        )}

        {followers && followers.length > 0 && (
          <Tooltip
            title={followers.map((f) => (
              <div key={f._user}>{f.name}</div>
            ))}
          >
            <Button>
              <div className={styles.iconCircle}>
                <TagFilled />
              </div>{" "}
              {followers.length}
            </Button>
          </Tooltip>
        )}

        {hashtags && hashtags.length > 0 && (
          <Tooltip
            title={hashtags.map((h, i) => (
              <div key={h}>{h}</div>
            ))}
          >
            <Button>
              <div className={styles.iconCircle}>
                <Hash size={14} className="anticon" />
              </div>{" "}
              {hashtags.length}
            </Button>
          </Tooltip>
        )}
      </div>
    </FormControls>
  );
};

export default memo(ActivityHeader);
