import React, { useState, useEffect, useCallback } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { openForm } from "Actions/formActions";
import { useNavigate } from "react-router-dom";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import api from "API";
import Button from "antd/es/button";
import Card from "antd/es/card";
import styles from "./styles.module.scss";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Tag from "antd/es/tag";
import ViewProject from "Screens/ViewProject";
import ViewContainer from "Components/ViewContainer";
import useViewShow from "Hooks/useViewShow";
import Divider from "antd/es/divider";
import FormContainer from "Forms/FormContainer";
import ComposeDumpsterEmail from "Forms/ComposeDumpsterEmail";
import Grid from "antd/es/grid";
import isEmpty from "Utils/isEmpty";
import message from "antd/es/message";
import dayjs from "dayjs";
import Typography from "antd/es/typography";
import Star from "Icons/Star";
import ImageFallback from "Components/images/Image";
import Affix from "antd/es/affix";
import Loader from "Components/Loader";
import ProjectManager from "Components/Inputs/ProjectManager";

import Modal from "antd/es/modal";
import CheckCircleEmptyIcon from "Icons/CheckCircleEmpty";
import CheckCircleIcon from "Icons/CheckCircle";
import openInNewTab from "Utils/openInNewTab";
import ArrowLeft from "Icons/ArrowLeft";
import useIsMounted from "Hooks/useIsMounted";
import useProjectManagers from "Hooks/useProjectManagers";
import generateMarkerUrl from "Utils/generateMarkerUrl";
import MapKey from "Components/MapKey";

import Chat from "Icons/Chat";

const { useBreakpoint } = Grid;

const ReadyForDumpster = () => {
  const dispatch = useDispatch();
  const dumpsterEmail = useSelector(
    (state) => state.formState.dumpsterEmail.open
  );
  const screens = useBreakpoint();
  const showProject = useViewShow(3, "projects", 4, "view");
  const [skipLoading, setSkipLoading] = useState(false);
  const [scopes, setScopes] = useState([]);
  const [selectedScopes, setSelectedScopes] = useState({});
  const [markers, setMarkers] = useState({});
  const [dumpingMap, setDumpingMap] = useState();
  const [container, setContainer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [typeFilters, setTypeFilters] = useState([
    "roofing",
    "roofing-flagged",
    "roofing-nc",
    "siding",
    "siding-nc",
  ]);
  const isMounted = useIsMounted();
  const [selectedPM, setSelectedPM] = useState("all");
  const projectManagers = useProjectManagers();
  projectManagers.all = {
    email: "",
    name: "View All",
    phone: "",
    _user: "all",
  };

  const mounted = isMounted.current;

  const removePins = useCallback(async () => {
    try {
      for (const s of scopes) {
        if (s._id && markers[s._id] && markers[s._id].marker) {
          markers[s._id].marker.setMap(null);
        }
      }
    } catch (err) {
      console.log("err", err);
    }
  }, [markers, scopes]);

  const fetchCards = useCallback(
    async (empty = true) => {
      try {
        const res = await api.get("/ready-for-dumpster/fetchList");
        if (empty) setSelectedScopes({});
        await removePins();
        unstable_batchedUpdates(() => {
          setScopes(res.data);
          setMarkers({});
          setLoading(false);
        });
      } catch (err) {
        console.log("err", err);
      }
    },
    [setScopes, setSelectedScopes, removePins]
  );

  const fetchFilteredCards = useCallback(
    async (_id) => {
      try {
        const res = await api.get(`/ready-for-dumpster/${_id}`);
        setSelectedScopes({});
        await removePins();
        unstable_batchedUpdates(() => {
          setScopes(res.data);
          setMarkers({});
          setLoading(false);
        });
      } catch (err) {
        console.log("err", err);
      }
    },
    [setScopes, setSelectedScopes, removePins]
  );

  const fetchStagesSwitch = useCallback(() => {
    console.log("fetchStagesSwitch FIRED");
    if (selectedPM === "all") {
      console.log("all FIRED");
      fetchCards();
    } else {
      console.log("filtered FIRED");
      fetchFilteredCards(selectedPM);
    }
  }, [selectedPM, fetchCards, fetchFilteredCards]);

  useEffect(() => {
    window.socket.on("updateDumpsters", fetchStagesSwitch);

    return () => {
      window.socket.removeAllListeners("updateDumpsters");
    };
  }, [fetchStagesSwitch]);

  useEffect(() => {
    if (mounted) {
      fetchCards();
      mapInit();
    }
  }, [mounted]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (dumpingMap && scopes) {
      removePins();
      pinInit();
    }
  }, [dumpingMap, scopes, typeFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFilter = async (value) => {
    try {
      setSelectedPM(value);
      if (value === "all") {
        fetchCards();
      } else {
        fetchFilteredCards(value);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const mapInit = () => {
    setDumpingMap(
      new window.google.maps.Map(document.getElementById("dumpingMap"), {
        center: { lat: 42.963795, lng: -85.670006 },
        zoom: 9,
        gestureHandling: "greedy",
        disableDefaultUI: true,
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        zoomControl: false,
        defaultMapTypeId: "hybrid",
        mapTypeId: "hybrid",
        tilt: 0,
      })
    );
  };

  const pinInit = () => {
    let _markers = {};
    scopes.map((s) => {
      if (filterScopes(s)) {
        if (
          s._id &&
          s.workOrder &&
          s.workOrder.projectAddressLat &&
          s.workOrder.projectAddressLng
        ) {
          const _scope = s.name === "Siding" ? "siding" : "roofing";
          const nc =
            s.workOrder && s.workOrder.propertyType === "New Construction"
              ? true
              : false;
          const flag =
            s.workOrder.difficultyRating === 4 ||
            s.workOrder.difficultyRating === 4.5 ||
            s.workOrder.flag === true
              ? true
              : false;
          const url = generateMarkerUrl({
            type: "schedule",
            scope: _scope,
            nc,
            flag,
          });

          _markers[s._id] = {};
          _markers[s._id].marker = new window.google.maps.Marker({
            position: {
              lat: s.workOrder.projectAddressLat,
              lng: s.workOrder.projectAddressLng,
            },
            map: dumpingMap,
            draggable: false,
            icon: new window.google.maps.MarkerImage(
              url,
              null,
              null,
              null,
              new window.google.maps.Size(36, 36)
            ),
            zIndex: 1,
          });

          _markers[s._id].mouseover = window.google.maps.event.addListener(
            _markers[s._id].marker,
            "mouseover",
            function () {
              const _scope = document.getElementById(s._id);
              if (_scope) {
                const scope = s.name === "Siding" ? "siding" : "roofing";
                const nc =
                  s.workOrder && s.workOrder.propertyType === "New Construction"
                    ? true
                    : false;
                const flag =
                  s.workOrder.difficultyRating === 4 ||
                  s.workOrder.difficultyRating === 4.5 ||
                  s.workOrder.flag === true
                    ? true
                    : false;
                const url = generateMarkerUrl({
                  type: "schedule",
                  scope,
                  nc,
                  flag,
                  active: true,
                });

                _scope.classList.add("hover");
                setMarkers((_markers) => {
                  _markers[s._id].marker.setIcon(
                    new window.google.maps.MarkerImage(
                      url,
                      null,
                      null,
                      null,
                      new window.google.maps.Size(36, 36)
                    )
                  );
                  _markers[s._id].marker.setZIndex(10);
                  return _markers;
                });
              }
            }
          );

          _markers[s._id].mouseout = window.google.maps.event.addListener(
            _markers[s._id].marker,
            "mouseout",
            function () {
              const _scope = document.getElementById(s._id);
              if (_scope) {
                const scope = s.name === "Siding" ? "siding" : "roofing";
                const nc =
                  s.workOrder && s.workOrder.propertyType === "New Construction"
                    ? true
                    : false;
                const flag =
                  s.workOrder.difficultyRating === 4 ||
                  s.workOrder.difficultyRating === 4.5 ||
                  s.workOrder.flag === true
                    ? true
                    : false;
                const url = generateMarkerUrl({
                  type: "schedule",
                  scope,
                  nc,
                  flag,
                });

                _scope.classList.remove("hover");
                setMarkers((_markers) => {
                  _markers[s._id].marker.setIcon(
                    new window.google.maps.MarkerImage(
                      url,
                      null,
                      null,
                      null,
                      new window.google.maps.Size(36, 36)
                    )
                  );
                  _markers[s._id].marker.setZIndex(1);
                  return _markers;
                });
              }
            }
          );

          _markers[s._id].mousedown = window.google.maps.event.addListener(
            _markers[s._id].marker,
            "mousedown",
            function () {
              if (!screens.md) {
                const _scope = s.name === "Siding" ? "siding" : "roofing";
                const nc =
                  s.workOrder && s.workOrder.propertyType === "New Construction"
                    ? true
                    : false;
                const flag =
                  s.workOrder.difficultyRating === 4 ||
                  s.workOrder.difficultyRating === 4.5 ||
                  s.workOrder.flag === true
                    ? true
                    : false;
                const url = generateMarkerUrl({
                  type: "schedule",
                  scope: _scope,
                  nc,
                  flag,
                });
                // const index = scopes.findIndex((x) => x._id === s._id);
                // carouselRef.current.goTo(index);
                setMarkers((_markers) => {
                  _markers[s._id].marker.setIcon(
                    new window.google.maps.MarkerImage(
                      url,
                      null,
                      null,
                      null,
                      new window.google.maps.Size(36, 36)
                    )
                  );
                  _markers[s._id].marker.setZIndex(1);
                  return _markers;
                });
              }
            }
          );

          _markers[s._id].click = window.google.maps.event.addListener(
            _markers[s._id].marker,
            "click",
            function () {
              if (screens.md) {
                const el = document.getElementById(s._id);
                el.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }
            }
          );
        }
        return s;
      }
      return null;
    });
    setMarkers(_markers);
  };

  const openEmail = useCallback(() => {
    let dumpsters = [];
    Object.entries(selectedScopes).map(([key, value]) => {
      dumpsters.push(value);
      return value;
    });
    dispatch(
      openForm("dumpsterEmail", {
        dumpsters,
        callback: fetchCards,
      })
    );
  }, [dispatch, fetchCards, selectedScopes]);

  const onSkip = async () => {
    try {
      setSkipLoading(true);
      let dumpsters = [];
      Object.entries(selectedScopes).map(([key, value]) => {
        dumpsters.push(value);
        return value;
      });

      await api.post("/ready-for-dumpster/skip", { dumpsters });

      setSkipLoading(false);
      message.success("Dumpster order skpped");
      fetchCards();
    } catch (err) {
      console.log("err", err);
      message.error("Error skipping dumpster order");
    }
  };

  const handleTypeFilter = async (type) => {
    if (typeFilters.includes(type)) {
      setTypeFilters(typeFilters.filter((tf) => tf !== type));
    } else {
      setTypeFilters((tf) => [...tf, type]);
    }
  };

  const filterScopes = (scope) => {
    const _scope = scope.name === "Siding" ? "siding" : "roofing";
    const nc =
      scope.workOrder && scope.workOrder.propertyType === "New Construction"
        ? true
        : false;
    const flagged =
      scope.workOrder.difficultyRating === 4 ||
      scope.workOrder.difficultyRating === 4.5 ||
      scope.workOrder.flag === true
        ? true
        : false;

    const filterString = `${_scope}${flagged ? "-flagged" : ""}${
      nc ? "-nc" : ""
    }`;

    if (typeFilters.includes(filterString)) return true;
    return false;
  };

  return (
    <div className="form" style={{ height: "100%" }}>
      <FormBody
        // className="content-inner p24"
        style={{
          position: "relative",
          left: "unset",
          right: "unset",
          height: "100%",
        }}
      >
        <div className="content-inner p24" ref={setContainer}>
          <Row gutter={16} style={{ height: "100%" }}>
            <Col xs={24} sm={24} md={13} lg={11}>
              {loading ? (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                  }}
                >
                  <Loader minHeight="100%" />
                </div>
              ) : (
                <>
                  <Row gutter={8}>
                    <Col xs={24} style={{ marginBottom: 16 }}>
                      <ProjectManager
                        sales={projectManagers}
                        value={selectedPM}
                        onChange={handleFilter}
                        noClear
                      />
                    </Col>
                  </Row>

                  {scopes.length > 0 && (
                    <Row gutter={16}>
                      {scopes.map((scope) => {
                        if (filterScopes(scope)) {
                          return (
                            <Col
                              key={scope._id}
                              xs={24}
                              sm={12}
                              md={12}
                              style={{ marginBottom: 16 }}
                            >
                              <Scope
                                scope={scope}
                                selectedScopes={selectedScopes}
                                setSelectedScopes={setSelectedScopes}
                                updateScopes={fetchCards}
                                _setScopes={setScopes}
                                setMarkers={setMarkers}
                                markers={markers}
                              />
                            </Col>
                          );
                        }
                        return null;
                      })}
                    </Row>
                  )}
                </>
              )}
            </Col>
            <Col xs={0} sm={0} md={11} lg={13} className={styles.mapContainer}>
              <Affix offsetTop={25} target={() => container}>
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                    marginBottom: 16,
                    borderRadius: 4,
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      marginBottom: 16,
                      borderRadius: 4,
                    }}
                    id="dumpingMap"
                  ></div>
                  <MapKey
                    handleClick={handleTypeFilter}
                    typeFilters={typeFilters}
                  />
                </div>
              </Affix>
            </Col>
          </Row>
        </div>
      </FormBody>
      <FormControls className={styles.footer}>
        <Button
          type="text"
          danger
          style={{ padding: "4px 8px", marginRight: 8 }}
          onClick={onSkip}
          loading={skipLoading}
          disabled={skipLoading || isEmpty(selectedScopes)}
        >
          Skip
        </Button>

        <Button
          type="primary"
          disabled={isEmpty(selectedScopes)}
          onClick={openEmail}
        >
          Order
        </Button>
      </FormControls>

      <ViewContainer
        type="drawer"
        visible={showProject}
        form={ViewProject}
        placement="right"
        width={400}
        closable={false}
        mask={false}
      />

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0 },
        }}
        type={!screens.md ? "drawer" : "modal"}
        width={!screens.md ? "100%" : 320}
        title="Order Dumpster"
        visible={dumpsterEmail}
        form={ComposeDumpsterEmail}
        formType="dumpsterEmail"
      />
    </div>
  );
};

const Scope = ({
  scope,
  selectedScopes,
  setSelectedScopes,
  updateScopes,
  _setScopes,
  setMarkers,
  markers,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showDescriptions, setShowDescriptions] = useState(false);

  const handleScopeClick = () => {
    let _selectedScopes = Object.assign({}, selectedScopes);
    if (_selectedScopes[scope._id]) {
      delete _selectedScopes[scope._id];
      setSelectedScopes(_selectedScopes);
    } else {
      _selectedScopes[scope._id] = scope;
      _selectedScopes[scope._id].address = scope.workOrder.projectAddress;
      _selectedScopes[scope._id].src = scope.workOrder.src;
      _selectedScopes[scope._id].projectManagerEmail =
        scope.workOrder.projectManagerEmail;
      setSelectedScopes(_selectedScopes);
    }
  };

  const goTo = (e) => {
    e.stopPropagation();
    navigate(
      `/workflow/ready-for-dumpster/projects/view/posts/${scope._projectId}`
    );
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const openDescriptions = (e) => {
    e.stopPropagation();
    setShowDescriptions(true);
  };

  const callback = useCallback(() => {
    updateScopes(false);
  }, [updateScopes]);

  const openPreJobInspection = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(
        openForm("preJobInspection", {
          _id: scope._projectId,
          _scopeId: scope._id,
          callback,
        })
      );
    },
    [dispatch, scope, callback]
  );

  const openNote = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(
        openForm("officeReviewNote", {
          _scopeId: scope._id,
          note: scope.officeReviewNote,
          customerId: scope.customerId,
          callback: updateScopes,
        })
      );
    },
    [dispatch, scope, updateScopes]
  );

  const confirmSendToMaterialStage = (e) => {
    e.stopPropagation();
    Modal.confirm({
      zIndex: 1001,
      centered: true,
      okButtonProps: {
        danger: true,
      },
      okType: "primary",
      okText: "Confirm",
      content:
        "Are you sure you want to send this back to the ready for material stage?",
      onOk() {
        return new Promise((resolve, reject) => {
          handleSendToMaterial(() => resolve(true));
        }).catch((err) => {
          console.log("err", err);
        });
      },
      onCancel() {},
    });
  };

  const handleSendToMaterial = async (cb) => {
    try {
      const res = await api.post("/ready-for-dumpster/move-to-material", {
        _id: scope._id,
        // TODO: difficultyRating overhaul
        difficultyRating: scope.workOrder.difficultyRating,
        name: scope.name,
      });

      // setScopes(res.data);
      _setScopes(res.data);

      cb();
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleMouseEnter = () => {
    if (scope._id && markers[scope._id]) {
      const _scope = scope.name === "Siding" ? "siding" : "roofing";
      const nc =
        scope.workOrder && scope.workOrder.propertyType === "New Construction"
          ? true
          : false;
      const flag =
        scope.workOrder.difficultyRating === 4 ||
        scope.workOrder.difficultyRating === 4.5 ||
        scope.workOrder.flag === true
          ? true
          : false;
      const url = generateMarkerUrl({
        type: "schedule",
        scope: _scope,
        nc,
        flag,
        active: true,
      });

      setMarkers((_markers) => {
        _markers[scope._id].marker.setIcon(
          new window.google.maps.MarkerImage(
            url,
            null,
            null,
            null,
            new window.google.maps.Size(36, 36)
          )
        );
        _markers[scope._id].marker.setZIndex(10);
        return _markers;
      });
    }
  };

  const handleMouseLeave = () => {
    if (scope._id && markers[scope._id]) {
      const _scope = scope.name === "Siding" ? "siding" : "roofing";
      const nc =
        scope.workOrder && scope.workOrder.propertyType === "New Construction"
          ? true
          : false;
      const flag =
        scope.workOrder.difficultyRating === 4 ||
        scope.workOrder.difficultyRating === 4.5 ||
        scope.workOrder.flag === true
          ? true
          : false;
      const url = generateMarkerUrl({
        type: "schedule",
        scope: _scope,
        nc,
        flag,
      });

      setMarkers((_markers) => {
        _markers[scope._id].marker.setIcon(
          new window.google.maps.MarkerImage(
            url,
            null,
            null,
            null,
            new window.google.maps.Size(36, 36)
          )
        );
        _markers[scope._id].marker.setZIndex(1);
        return _markers;
      });
    }
  };

  return (
    <>
      <Card
        id={scope._id}
        onClick={handleScopeClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        size="small"
        title={
          <span>
            <strong>{scope.customerId}</strong> - {scope.workOrder.customerName}
          </span>
        }
        extra={
          <Button type="link" onClick={goTo} size="small">
            Go To
          </Button>
        }
        hoverable
        bordered={false}
        className={`z-depth-1 ${styles.scopeCard} ${
          selectedScopes[scope._id] ? styles.selected : ""
        } ${scope.workOrder.schedule === "Urgent" ? styles.urgent : ""}`}
        cover={
          <div style={{ position: "relative" }}>
            <div style={{ position: "absolute", top: 8, right: 8, zIndex: 1 }}>
              {scope.preJobInspectionComplete ? (
                <Button
                  shape="circle"
                  type="default"
                  className={styles.centerIcon}
                >
                  <CheckCircleIcon color="#00c292" />
                </Button>
              ) : (
                <Button
                  shape="circle"
                  type="default"
                  className={styles.centerIcon}
                  onClick={openPreJobInspection}
                >
                  <CheckCircleEmptyIcon />
                </Button>
              )}
            </div>
            <Button
              style={{ marginLeft: 4, position: "absolute", top: 49, right: 8 }}
              // size="small"
              danger
              shape="circle"
              type="primary"
              onClick={confirmSendToMaterialStage}
            >
              <ArrowLeft size={20} color="#ffffff" />
            </Button>
            <Button
              style={{ marginRight: 4, position: "absolute", top: 8, left: 8 }}
              // size="small"
              shape="circle"
              type={
                scope.officeReviewNote && scope.officeReviewNote.length > 0
                  ? "primary"
                  : "default"
              }
              onClick={openNote}
            >
              <Chat size={20} />
            </Button>
            <ImageFallback
              alt="ready-for-dumpster"
              image={{
                src: scope.starredImageSrc || undefined,
                thumbnail: scope.starredImageSrcThumbnail || undefined,
              }}
            />
          </div>
        }
      >
        <Card.Meta
          title={
            <div
              className={styles.flex}
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              <strong style={{ paddingRight: 8 }}>{scope.name}</strong>{" "}
              <small>
                {scope.workOrder &&
                scope.workOrder.propertyType === "New Construction"
                  ? "(NC)"
                  : scope.workOrder &&
                    scope.workOrder.propertyType === "Commercial"
                  ? "(C)"
                  : "(R)"}
              </small>{" "}
              {scope.workOrder &&
                scope.workOrder.propertyType !== "New Construction" && (
                  <>
                    <Divider type="vertical" /> <Star color="gold" />{" "}
                    {/* TODO: difficultyRating overhaul */}
                    {scope.workOrder && scope.workOrder.difficultyRating}
                  </>
                )}
            </div>
          }
          description={
            <>
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                <div>
                  {scope.workOrder &&
                    scope.workOrder.scopes.map((s, i) => (
                      <Tag
                        key={s.initials + i}
                        className={styles.scopeBadge}
                        color={s.color}
                      >
                        {s.initials}
                      </Tag>
                    ))}
                </div>
                {scope.workOrder.pitches.join(", ")}
                <Divider type="vertical" /> {scope.workOrder.squares} sq.{" "}
                <Divider type="vertical" />{" "}
                {scope.workOrder.roofType
                  ? scope.workOrder.roofType.join(", ")
                  : scope.workOrder.installationMethod}
              </div>

              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                <strong>Street</strong>:{" "}
                <Typography.Link
                  onClick={stopPropagation}
                  href={`https://www.google.com/maps/place/${scope.workOrder.projectAddressLat},${scope.workOrder.projectAddressLng}`}
                  target="_blank"
                >
                  {scope.workOrder.projectAddressStreet}
                </Typography.Link>
              </div>
              <div>
                <strong>City</strong>:{" "}
                <Typography.Link
                  onClick={stopPropagation}
                  href={`https://www.google.com/maps/place/${scope.workOrder.projectAddressLat},${scope.workOrder.projectAddressLng}`}
                  target="_blank"
                >
                  {scope.workOrder.projectAddressCity}
                </Typography.Link>
              </div>

              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                <strong>Project Owner</strong>:{" "}
                {scope.workOrder.projectManagerName}
              </div>
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                <strong>Project Manager</strong>:{" "}
                <span style={{ color: "#108ee9" }}>
                  {scope.installProjectManager
                    ? scope.installProjectManager.name
                    : ""}
                </span>
              </div>
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                <strong>Material Delivery</strong>:{" "}
                {scope.materialDeliverDate
                  ? dayjs(scope.materialDeliverDate).format("MMM D, YY")
                  : "Skipped"}
              </div>

              <Row gutter={8} style={{ marginTop: 4 }}>
                <Col xs={24}>
                  <Button
                    type="primary"
                    block
                    size="small"
                    style={{ paddingLeft: 0 }}
                    onClick={openDescriptions}
                  >
                    Descriptions and Notes
                  </Button>
                </Col>
              </Row>
              {scope.aerials && scope.aerials.length > 0
                ? scope.aerials.map((aerial) => (
                    <AerialButton src={aerial.src} key={aerial._id} />
                  ))
                : null}
            </>
          }
        />
      </Card>

      <DescriptionModal
        visible={showDescriptions}
        setVisible={setShowDescriptions}
        title="Descriptions and Notes"
        project={scope.workOrder.projectDescription}
        scope={scope.workOrder.scopeDescription}
        dumpster={scope.workOrder.dumpsterNotes}
        equipterAccess={scope.equipterAccess}
        officeReviewNote={scope.officeReviewNote}
        admin={scope.workOrder.adminNotes}
      />
    </>
  );
};

const AerialButton = ({ src }) => {
  const openAerial = (e) => {
    e.stopPropagation();
    openInNewTab(src);
  };

  return (
    <Row gutter={8} style={{ marginTop: 4 }}>
      <Col xs={24}>
        <Button
          type="primary"
          block
          size="small"
          className="green"
          style={{ paddingLeft: 0 }}
          onClick={openAerial}
        >
          View Aerial
        </Button>
      </Col>
    </Row>
  );
};

const DescriptionModal = ({
  title,
  visible,
  setVisible,
  project,
  scope,
  dumpster,
  equipterAccess,
  officeReviewNote,
  admin,
}) => {
  const onCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={onCancel}
      centered
      footer={null}
    >
      {project && project.length > 0 && (
        <div>
          <Typography.Title style={{ margin: 0 }} level={4}>
            Project Description
          </Typography.Title>
          <Typography.Paragraph style={{ whiteSpace: "pre-line" }}>
            {project}
          </Typography.Paragraph>
        </div>
      )}
      {scope && scope.length > 0 && (
        <div style={{ marginTop: 16 }}>
          <Divider />
          <Typography.Title style={{ margin: 0 }} level={4}>
            Scope Description
          </Typography.Title>
          <Typography.Paragraph style={{ whiteSpace: "pre-line" }}>
            {scope}
          </Typography.Paragraph>
        </div>
      )}
      {equipterAccess && equipterAccess.length > 0 && (
        <div style={{ marginTop: 16 }}>
          <Divider />
          <Typography.Title style={{ margin: 0 }} level={4}>
            Equipter Access
          </Typography.Title>
          <Typography.Paragraph style={{ whiteSpace: "pre-line" }}>
            {equipterAccess}
          </Typography.Paragraph>
        </div>
      )}
      {dumpster && dumpster.length > 0 && (
        <div style={{ marginTop: 16 }}>
          <Divider />
          <Typography.Title style={{ margin: 0 }} level={4}>
            Dumpster Notes
          </Typography.Title>
          <Typography.Paragraph style={{ whiteSpace: "pre-line" }}>
            {dumpster}
          </Typography.Paragraph>
        </div>
      )}
      {officeReviewNote && officeReviewNote.length > 0 && (
        <div style={{ marginTop: 16 }}>
          <Divider />
          <Typography.Title style={{ margin: 0 }} level={4}>
            Scheduling Notes
          </Typography.Title>
          <Typography.Paragraph style={{ whiteSpace: "pre-line" }}>
            {officeReviewNote}
          </Typography.Paragraph>
        </div>
      )}
      {admin && admin.length > 0 && (
        <div style={{ marginTop: 16 }}>
          <Divider />
          <Typography.Title style={{ margin: 0 }} level={4}>
            Admin Notes
          </Typography.Title>
          <Typography.Paragraph style={{ whiteSpace: "pre-line" }}>
            {admin}
          </Typography.Paragraph>
        </div>
      )}
    </Modal>
  );
};

export default ReadyForDumpster;
