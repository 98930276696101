import React, { useEffect, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import api from "API";
import Row from "antd/es/row";
import Col from "antd/es/col";
import StatisticCard from "./StatisticCard";
import TableCard from "./TableCard";
import useIsMounted from "Hooks/useIsMounted";
import message from "antd/es/message";
import BacklogReport from "./BacklogReport";
import HighCharts from "highcharts";

const ReportsDashboard = () => {
  const isMounted = useIsMounted();

  const [data, setData] = useState({
    loading: true,
    backlogTotal: 0,
    inProduction: 0,
    totalAR: 0,
    pipeline: 0,
    annualProductionGrossSales: 0,
    drawBalance: 0,
    closingPercentage: 0,
    profitPercentage: 0,
    thisYearsSales: {
      sales: 0,
      goal: 0,
    },
    thisMonthsSales: {
      sales: 0,
      goal: 0,
    },
    averageSaleValue: 0,
  });

  const [salesYTD, setSalesYTD] = useState([
    { name: "1", value: 0, trend: 0 },
    { name: "2", value: 0, trend: 0 },
    { name: "3", value: 0, trend: 0 },
    { name: "4", value: 0, trend: 0 },
    { name: "5", value: 0, trend: 0 },
    { name: "6", value: 0, trend: 0 },
    { name: "7", value: 0, trend: 0 },
    { name: "8", value: 0, trend: 0 },
    { name: "9", value: 0, trend: 0 },
  ]);

  const [salesThisMonth, setSalesThisMonth] = useState([
    { name: "1", value: 0, trend: 0 },
    { name: "2", value: 0, trend: 0 },
    { name: "3", value: 0, trend: 0 },
    { name: "4", value: 0, trend: 0 },
    { name: "5", value: 0, trend: 0 },
    { name: "6", value: 0, trend: 0 },
    { name: "7", value: 0, trend: 0 },
    { name: "8", value: 0, trend: 0 },
    { name: "9", value: 0, trend: 0 },
  ]);

  const [drawActivities, setDrawActivities] = useState([
    { transactionDate: "1", activity: 0, type: "0" },
    { transactionDate: "2", activity: 0, type: "0" },
    { transactionDate: "3", activity: 0, type: "0" },
    { transactionDate: "4", activity: 0, type: "0" },
    { transactionDate: "5", activity: 0, type: "0" },
    { transactionDate: "6", activity: 0, type: "0" },
    { transactionDate: "7", activity: 0, type: "0" },
    { transactionDate: "8", activity: 0, type: "0" },
    { transactionDate: "9", activity: 0, type: "0" },
  ]);

  const [companyBacklogOptions, setCompanyBacklogOptions] = useState({
    chart: {
      type: "column",
      height: 300,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      type: "category",
      labels: {
        rotation: -45,
        style: {
          fontSize: "12px",
          fontFamily: "Verdana, sans-serif",
        },
      },
    },
    yAxis: {
      title: {
        text: "",
      },
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: true,
          formatter: function () {
            return this.y;
          },
        },
      },
    },
    series: [
      {
        name: "No Data",
        data: [],
      },
    ],
  });

  const [soldByScopeOptions, setSoldByScopeOptions] = useState({
    chart: {
      type: "column",
      height: 300,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      type: "category",
      labels: {
        rotation: -45,
        style: {
          fontSize: "12px",
          fontFamily: "Verdana, sans-serif",
        },
      },
    },
    yAxis: {
      title: {
        text: "",
      },
      labels: {
        formatter: function () {
          let label = this.value;
          if (this.value >= 1000000) {
            label = this.value / 1000000 + "M";
          } else if (this.value >= 1000) {
            label = this.value / 1000 + "k";
          } else if (this.value <= -1000) {
            label = this.value / 1000 + "k";
          } else if (this.value <= -1000000) {
            label = this.value / 1000000 + "M";
          }
          return `${label}`;
        },
      },
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: true,
          formatter: function () {
            return "$" + HighCharts.numberFormat(this.y, 2);
          },
        },
      },
    },
    series: [
      {
        name: "No Data",
        data: [],
      },
    ],
  });

  const [reviewsYTDBySalesman, setReviewsYTDBySalesman] = useState([
    { name: "1", value: 0, trend: 0 },
    { name: "2", value: 0, trend: 0 },
    { name: "3", value: 0, trend: 0 },
    { name: "4", value: 0, trend: 0 },
    { name: "5", value: 0, trend: 0 },
    { name: "6", value: 0, trend: 0 },
    { name: "7", value: 0, trend: 0 },
    { name: "8", value: 0, trend: 0 },
    { name: "9", value: 0, trend: 0 },
  ]);

  useEffect(() => {
    populateDashboard();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const populateDashboard = async () => {
    try {
      const res = await api.get("/reports-dashboard/populate");

      if (isMounted.current) {
        unstable_batchedUpdates(() => {
          setData({
            loading: false,
            backlogTotal: res.data.backlogTotal,
            inProduction: res.data.inProduction,
            totalAR: res.data.totalAR,
            pipeline: res.data.pipeline,
            annualProductionGrossSales: res.data.annualProductionGrossSales,
            drawBalance: res.data.drawBalance,
            closingPercentage: res.data.closingPercentage,
            profitPercentage: res.data.profitPercentage,
            thisYearsSales: res.data.thisYearsSales,
            thisMonthsSales: res.data.thisMonthsSales,
            averageSaleValue: res.data.averageSaleValue,
          });
          setSalesYTD(res.data.salesYTD);
          setReviewsYTDBySalesman(res.data.reviewsYTDBySalesman);
          setSalesThisMonth(res.data.salesThisMonth);
          setDrawActivities(res.data.drawActivities);
          setCompanyBacklogOptions((current) => {
            return {
              ...current,
              series: [
                {
                  name: "Data",
                  data: res.data.companyBacklog,
                },
              ],
            };
          });
          setSoldByScopeOptions((current) => {
            return {
              ...current,
              series: [
                {
                  name: "Data",
                  data: res.data.soldByScope,
                },
              ],
            };
          });
        });
      }
    } catch (err) {
      console.log("err", err);
      message.error("Error retrieving data");
    }
  };

  return (
    <div style={{ height: "100%" }}>
      <div
        style={{ background: "#f0f2f5", height: "100%", padding: 16 }}
        className={`content-inner`}
      >
        <Row gutter={16}>
          <Col xs={24} sm={7} className="mb16">
            <TableCard
              title="Sales YTD"
              content={salesYTD}
              loading={data.loading}
            />
          </Col>
          <Col xs={24} sm={7} className="mb16">
            <TableCard
              title="Sales This Month"
              content={salesThisMonth}
              loading={data.loading}
            />
          </Col>
          <Col xs={24} sm={10}>
            <Row gutter={16}>
              <Col xs={24} sm={12} className="mb16">
                <StatisticCard
                  title="Backlog"
                  decimalPlaces={2}
                  value={data.backlogTotal}
                  loading={data.loading}
                />
              </Col>
              <Col xs={24} sm={12} className="mb16">
                <StatisticCard
                  title="In Production"
                  decimalPlaces={2}
                  value={data.inProduction}
                  loading={data.loading}
                />
              </Col>
              <Col xs={24} sm={12} className="mb16">
                <StatisticCard
                  title="Total AR"
                  decimalPlaces={2}
                  value={data.totalAR}
                  loading={data.loading}
                />
              </Col>
              <Col xs={24} sm={12} className="mb16">
                <StatisticCard
                  title="Pipeline"
                  decimalPlaces={2}
                  value={data.pipeline}
                  loading={data.loading}
                />
              </Col>
              <Col xs={24} className="mb16">
                <StatisticCard
                  title="Annual Production Gross Sales"
                  decimalPlaces={2}
                  value={data.annualProductionGrossSales}
                  loading={data.loading}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} sm={10}>
            <Row gutter={16}>
              <Col xs={24} sm={12} className="mb16">
                <StatisticCard
                  title="This Years Sales"
                  decimalPlaces={2}
                  value={data.thisYearsSales.sales}
                  loading={data.loading}
                  goal={data.thisYearsSales.goal}
                />
              </Col>
              <Col xs={24} sm={12} className="mb16">
                <StatisticCard
                  title="This Months Sales"
                  decimalPlaces={2}
                  value={data.thisMonthsSales.sales}
                  loading={data.loading}
                  goal={data.thisMonthsSales.goal}
                />
              </Col>
              <Col xs={24} sm={12} className="mb16">
                <StatisticCard
                  title="Average Sale Value"
                  decimalPlaces={2}
                  value={data.averageSaleValue}
                  loading={data.loading}
                />
              </Col>
              <Col xs={24} sm={12} className="mb16">
                <StatisticCard
                  title="Draw Balance"
                  decimalPlaces={2}
                  value={data.drawBalance}
                  loading={data.loading}
                />
              </Col>
              <Col xs={24} sm={12} className="mb16">
                <StatisticCard
                  title="Closing Percentage"
                  decimalPlaces={0}
                  value={data.closingPercentage}
                  percent
                  loading={data.loading}
                />
              </Col>
              <Col xs={24} sm={12} className="mb16">
                <StatisticCard
                  title="Profit Percentage"
                  decimalPlaces={0}
                  value={data.profitPercentage}
                  percent
                  loading={data.loading}
                />
              </Col>
            </Row>
          </Col>

          <Col xs={24} sm={14} className="mb16">
            <TableCard
              title="Draw Activities"
              content={drawActivities}
              loading={data.loading}
            />
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} sm={8} className="mb16">
            <TableCard
              title="Reviews YTD"
              content={reviewsYTDBySalesman}
              loading={data.loading}
              // height={224.73}
            />
          </Col>
          <Col xs={24} sm={16} className="mb16">
            <BacklogReport
              options={soldByScopeOptions}
              title="Sold By Scope"
              loading={data.loading}
            />
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24}>
            <BacklogReport
              options={companyBacklogOptions}
              title="Company Backlog"
              loading={data.loading}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ReportsDashboard;
