import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateActivitiesOnly } from "Actions/dataActions";
import { openForm } from "Actions/formActions";
import styles from "./styles.module.scss";
import LikeFilled from "@ant-design/icons/LikeFilled";
import timeStamp from "Utils/timeStamp";
import Button from "antd/es/button";
import Tooltip from "antd/es/tooltip";
import message from "antd/es/message";
import api from "API";

const ActionRow = ({
  comment,
  _projectId,
  _customerId,
  _projectStatus,
  _user,
  _activityId,
  _reply,
  index,
  update,
  _global,
  dashboard,
  fetchPosts,
}) => {
  const _id = useSelector((state) => state.dataState.details._id);
  const customerId = useSelector((state) => state.dataState.details.customerId);
  const projectStatus = useSelector(
    (state) => state.dataState.details.projectStatus
  );
  const viewableActivities = useSelector(
    (state) => state.dataState.viewableActivities
  );
  const dispatch = useDispatch();

  const likeComment = useCallback(async () => {
    try {
      const res = await api.post("/activity/like", {
        _id: _activityId,
        _projectId: _projectId || _id,
        _commentId: comment._id,
        _replyId: _reply ? _reply._id : undefined,
      });

      dispatch(
        updateActivitiesOnly({
          viewableActivities,
          salesObj: res.data.salesObj,
          activities: res.data._activities,
          _projectId: _projectId || _id,
          _global: res.data.global,
          dashboard,
        })
      );

      if (fetchPosts) fetchPosts();
    } catch (err) {
      console.log("err", err);
      message.error("Error liking comment");
    }
  }, [
    _activityId,
    _projectId,
    _id,
    comment._id,
    _reply,
    dispatch,
    viewableActivities,
    dashboard,
    fetchPosts,
  ]);

  const unLikeComment = useCallback(async () => {
    try {
      const res = await api.post("/activity/unlike", {
        _id: _activityId,
        _projectId: _projectId || _id,
        _commentId: comment._id,
        _replyId: _reply ? _reply._id : undefined,
      });

      dispatch(
        updateActivitiesOnly({
          viewableActivities,
          salesObj: res.data.salesObj,
          activities: res.data._activities,
          _projectId: _projectId || _id,
          _global: res.data.global,
          dashboard,
        })
      );

      if (fetchPosts) fetchPosts();
    } catch (err) {
      console.log("err", err);
      message.error("Error unliking comment");
    }
  }, [
    _activityId,
    _projectId,
    _id,
    comment._id,
    _reply,
    dispatch,
    viewableActivities,
    dashboard,
    fetchPosts,
  ]);

  const reply = useCallback(() => {
    dispatch(
      openForm("reply", {
        _id: _activityId,
        _projectId: _projectId || _id,
        _commentId: comment._id,
        customerId: _customerId || customerId,
        projectStatus: _projectStatus || projectStatus,
        dashboard,
        fetchPosts,
      })
    );
  }, [
    dispatch,
    _activityId,
    _projectId,
    _id,
    comment._id,
    _customerId,
    customerId,
    _projectStatus,
    projectStatus,
    dashboard,
    fetchPosts,
  ]);

  return (
    <div
      className={`${styles.actionRow} ${update ? styles.updateActionRow : ""} ${
        _global ? styles._global : ""
      }`}
    >
      <div className={styles.commentActions}>
        {!_reply &&
        comment.likes.filter((l) => l._user === _user).length === 0 ? (
          <Button size="small" type="text" onClick={likeComment}>
            Like
          </Button>
        ) : _reply &&
          _reply.likes.filter((l) => l._user === _user).length === 0 &&
          !_reply.update ? (
          <Button size="small" type="text" onClick={likeComment}>
            Like
          </Button>
        ) : (_reply && !_reply.update) || !update ? (
          <Button
            size="small"
            type="text"
            onClick={unLikeComment}
            style={{ color: "#108ee9" }}
          >
            Liked!
          </Button>
        ) : null}

        {(comment.replies.length === 0 ||
          comment.replies.length - 1 === index) && (
          <Button size="small" type="text" onClick={reply}>
            Reply
          </Button>
        )}
      </div>

      {!_reply && comment.likes.length > 0 ? (
        <div className={styles.likesContainer}>
          <Tooltip
            title={comment.likes.map((l) => (
              <div key={l._user}>{l.name}</div>
            ))}
          >
            <LikeFilled style={{ color: "#108ee9" }} /> {comment.likes.length}
          </Tooltip>
        </div>
      ) : _reply && _reply.likes.length > 0 ? (
        <div className={styles.likesContainer}>
          <Tooltip
            title={_reply.likes.map((l) => (
              <div key={l._user}>{l.name}</div>
            ))}
          >
            <LikeFilled style={{ color: "#108ee9" }} /> {_reply.likes.length}
          </Tooltip>
        </div>
      ) : null}
      <span className={styles.timeStamp}>
        {timeStamp(_reply ? _reply.created : comment.created)}
      </span>
    </div>
  );
};

export default ActionRow;
