import React, { useEffect, useState, useCallback } from "react";
import Grid from "antd/es/grid";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchTableFilters,
  unsetTable,
  setScrollbar,
} from "Actions/tableActions";
import Table from "Components/Table";
import TableRow from "Components/Table/TableItems/ScopeItem";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as Vlist } from "react-window";
import ViewContainer from "Components/ViewContainer";
import ViewProject from "Screens/ViewProject";
import usePrevious from "Hooks/usePrevious";
import useViewShow from "Hooks/useViewShow";
import useIsMobile from "Hooks/useIsMobile";
import isEmpty from "Utils/isEmpty";
import api from "API";

const { useBreakpoint } = Grid;

const headers = [
  {
    _name: "customerId",
    name: "ID",
    width: "10%",
  },
  {
    _name: "customerName",
    name: "CUSTOMER",
    width: "15%",
  },
  {
    _name: "address",
    name: "ADDRESS",
    width: "30%",
  },
  {
    _name: "name",
    name: "TYPE",
    width: "16%",
  },
  {
    _name: "status",
    name: "STATUS",
    width: "16%",
  },
  // {
  //   _name: "progress",
  //   name: "PROGRESS",
  //   width: "12%",
  //   type: "progress",
  // },
  {
    _name: "",
    name: "",
    width: "13%",
    type: "editProject",
  },
];

const Scopes = () => {
  const dispatch = useDispatch();
  const rows = useSelector((state) => state.tableState.rows);
  const fetching = useSelector((state) => state.tableState.fetching);
  const search = useSelector((state) => state.tableState.search);
  const activeFilter = useSelector((state) => state.tableState.activeFilter);
  const screens = useBreakpoint();
  const mobile = useIsMobile();
  const prevScreens = usePrevious(screens);
  const showProject = useViewShow(3, "view");
  const collapsed = useSelector((state) => state.settingsState.collapsed);
  const [itemSize, setItemSize] = useState(135);
  const [filters, setFilters] = useState([
    {
      title: "Status Date",
      type: "datepicker",
      fields: [
        {
          name: "statusDate-start",
          placeholder: "Status Date After",
        },
        {
          name: "statusDate-end",
          placeholder: "Status Date Before",
        },
      ],
    },
    {
      title: "Type",
      type: "multi-select",
      fields: [
        {
          name: "name",
          placeholder: "Select an Option(s)",
          values: [],
        },
      ],
    },
    {
      title: "Project Owner",
      type: "projectManager",
      fields: [
        {
          name: "projectManager",
          placeholder: "Select an Option(s)",
          values: [],
        },
      ],
    },
    {
      title: "Status",
      type: "multi-select",
      fields: [
        {
          name: "status",
          placeholder: "Select an Option(s)",
          values: ["Pending Install", "Started", "Completed", "Collected"],
        },
      ],
    },
    {
      title: "Install Status",
      type: "multi-select",
      fields: [
        {
          name: "installStatus",
          placeholder: "Select an Option(s)",
          values: ["Pending Install", "Started", "Completed", "Collected"],
        },
      ],
    },
    {
      title: "Install Completed Date",
      type: "datepicker",
      fields: [
        {
          name: "installCompleted-start",
          placeholder: "Install Completed After",
        },
        {
          name: "installCompleted-end",
          placeholder: "Install Completed Before",
        },
      ],
    },
    {
      title: "Project Status",
      type: "multi-select",
      fields: [
        {
          name: "projectStatus",
          placeholder: "Select an Option(s)",
          values: ["Sold", "Closed Out", "On Hold"],
        },
      ],
    },
    {
      title: "Install Project Manager",
      type: "installProjectManager",
      fields: [
        {
          name: "installProjectManager",
          placeholder: "Select an Option(s)",
          values: [],
        },
      ],
    },
    {
      title: "Work Order Created",
      type: "multi-select",
      fields: [
        {
          name: "woCreated",
          placeholder: "Select an Option(s)",
          values: ["True", "False"],
        },
      ],
    },
    {
      title: "Work Requested",
      type: "multi-select",
      fields: [
        {
          name: "workRequested",
          placeholder: "Select an Option(s)",
          values: ["True", "False"],
        },
      ],
    },
    {
      title: "Work Requested Date",
      type: "datepicker",
      fields: [
        {
          name: "workRequestedDate-start",
          placeholder: "Work Requested After",
        },
        {
          name: "workRequestedDate-end",
          placeholder: "Work Requested Before",
        },
      ],
    },
    {
      title: "Work Accepted",
      type: "multi-select",
      fields: [
        {
          name: "workAccepted",
          placeholder: "Select an Option(s)",
          values: ["True", "False"],
        },
      ],
    },
    {
      title: "Work Denied",
      type: "multi-select",
      fields: [
        {
          name: "workDenied",
          placeholder: "Select an Option(s)",
          values: ["True", "False"],
        },
      ],
    },
  ]);

  useEffect(() => {
    initTable();

    fetchOptions();

    return () => dispatch(unsetTable());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTimeout(() => {
      dispatch(setScrollbar());
    });
  }, [rows, dispatch]);

  const initTable = useCallback(() => {
    dispatch(
      fetchTableFilters({
        table: "scopes",
        col: "customerId",
        order: 1,
        search,
      })
    );
  }, [dispatch, search]);

  useEffect(() => {
    if (prevScreens && screens) {
      if (prevScreens.md && !screens.md) {
        setItemSize(135);
      } else if (!prevScreens.md && screens.md) {
        setItemSize(47);
      }
    }
  }, [screens, prevScreens]);

  const fetchOptions = async () => {
    try {
      const res = await api.post("/dropdowns/get", {
        selects: ["Scopes"],
      });
      setFilters((_filters) => {
        _filters[1].fields[0].values = res.data.Scopes.options;
        return _filters;
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const Row = ({ index, style }) => {
    const item = rows[index];
    if (!mobile)
      return (
        <TableRow style={style} key={item._id} {...item} headers={headers} />
      );
    return <TableRow style={style} key={item._id} {...item} mobile />;
  };

  return (
    <>
      <Table
        loading={fetching}
        filter={
          search ||
          (activeFilter && activeFilter.filter && !isEmpty(activeFilter.filter))
            ? true
            : false
        }
        mobile={mobile}
        sider={screens.lg}
        collapsed={collapsed}
      >
        <Table.Header filters={filters} />

        <Table.Filter
          hide={
            search ||
            (activeFilter &&
              activeFilter.filter &&
              !isEmpty(activeFilter.filter))
              ? false
              : true
          }
        />

        {!mobile && <Table.ColumnHeaders headers={headers} />}
        <Table.Body loading={fetching}>
          {rows.length > 0 && (
            <AutoSizer>
              {({ height, width }) => (
                <Vlist
                  height={height}
                  itemCount={rows.length}
                  itemSize={itemSize}
                  width={width}
                >
                  {Row}
                </Vlist>
              )}
            </AutoSizer>
          )}
        </Table.Body>
      </Table>

      <ViewContainer
        type="drawer"
        visible={showProject}
        form={ViewProject}
        placement="right"
        width={!screens.sm ? "100%" : 400}
        closable={false}
        mask={false}
      />
    </>
  );
};

export default Scopes;
