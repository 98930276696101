import React from "react";
import styles from "./styles.module.scss";
import Typography from "antd/es/typography";

const Loader = ({
  minHeight = "auto",
  width = "100%",
  display = "flex",
  justifyContent = "center",
  alignItems = "center",
  color = "#1890ff",
  subTitle = undefined,
  zIndex = "initial",
}) => {
  return (
    <div
      style={{
        minHeight,
        width,
        display,
        justifyContent,
        alignItems,
        zIndex,
      }}
    >
      <div className={styles.skCubeGrid}>
        <div
          className={`${styles.skcube} ${styles.skcube1}`}
          style={{ backgroundColor: color }}
        ></div>
        <div
          className={`${styles.skcube} ${styles.skcube2}`}
          style={{ backgroundColor: color }}
        ></div>
        <div
          className={`${styles.skcube} ${styles.skcube3}`}
          style={{ backgroundColor: color }}
        ></div>
        <div
          className={`${styles.skcube} ${styles.skcube4}`}
          style={{ backgroundColor: color }}
        ></div>
        <div
          className={`${styles.skcube} ${styles.skcube5}`}
          style={{ backgroundColor: color }}
        ></div>
        <div
          className={`${styles.skcube} ${styles.skcube6}`}
          style={{ backgroundColor: color }}
        ></div>
        <div
          className={`${styles.skcube} ${styles.skcube7}`}
          style={{ backgroundColor: color }}
        ></div>
        <div
          className={`${styles.skcube} ${styles.skcube8}`}
          style={{ backgroundColor: color }}
        ></div>
        <div
          className={`${styles.skcube} ${styles.skcube9}`}
          style={{ backgroundColor: color }}
        ></div>
      </div>
      {subTitle && (
        <Typography.Title
          level={5}
          style={{ position: "absolute", marginTop: 82 }}
        >
          {subTitle}
        </Typography.Title>
      )}
    </div>
  );
};

export default Loader;
