import React, { useState, useCallback, useEffect } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateDumpstersActivitiesAndHashtags } from "Actions/dataActions";
import { fetchTable } from "Actions/tableActions";
import Input from "antd/es/input";
import Form from "antd/es/form";
import Button from "antd/es/button";
import message from "antd/es/message";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import api from "API";
import CancelButton from "Components/CancelButton";
import DatePicker from "Components/Inputs/DatePicker";
import useIsMobile from "Hooks/useIsMobile";
import Radio from "antd/es/radio";
import styles from "./styles.module.scss";
import dayjs from "dayjs";
import SearchLeadsAndProjects from "Components/Inputs/SearchLeadsAndProjects";
import useDumpsterSuppliers from "Hooks/useDumpsterSuppliers";
import Suppliers from "Components/Inputs/Suppliers";
import Select from "antd/es/select";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";
import MySelect from "Components/Inputs/Select";
import Steps from "antd/es/steps";
import MarkUpHelper from "Components/MarkUpHelper";
import Modal from "antd/es/modal";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";

import ProcessFeedback from "Components/ProcessFeedback";

const messageKey = "messageKey";

const steps = [
  "Submitting Form",
  "Creating Post",
  "Sending Email",
  "Creating Dumpster",
  "Updating Scope",
  "Done!",
];

const ManageDumpster = ({
  unsavedChanges,
  setUnsavedChanges,
  handleClose,
  type,
  setTitle,
}) => {
  const dispatch = useDispatch();
  const formDetails = useSelector(
    (state) => state.formState.dumping.formDetails
  );
  const email = useSelector((state) => state.authState.email);
  const details = useSelector((state) => state.dataState.details);
  const viewableActivities = useSelector(
    (state) => state.dataState.viewableActivities
  );
  const table = useSelector((state) => state.tableState.table);
  const col = useSelector((state) => state.tableState.col);
  const order = useSelector((state) => state.tableState.order);
  const search = useSelector((state) => state.tableState.search);
  const activeFilter = useSelector((state) => state.tableState.activeFilter);

  const mobile = useIsMobile();
  const suppliers = useDumpsterSuppliers();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [status, setStatus] = useState(formDetails.status);

  const [_projectId, _setProjectId] = useState(
    formDetails._projectId ? formDetails._projectId : details._id
  );
  const [customerId, setCustomerId] = useState(
    formDetails.customerId ? formDetails.customerId : details.customerId
  );
  const [projectAddress, setProjectAddress] = useState(
    formDetails.projectAddress
      ? formDetails.projectAddress
      : details.projectAddress
  );
  const [customerName, setCustomerName] = useState(
    formDetails.customerName ? formDetails.customerName : details.customerName
  );
  const [projectManagerEmail, setProjectManagerEmail] = useState(
    formDetails.projectManagerEmail
      ? formDetails.projectManagerEmail
      : details.projectManager
      ? details.projectManager.email
      : undefined
  );
  const [projectAddressLat, setProjectAddressLat] = useState(
    formDetails.projectAddressLat
      ? formDetails.projectAddressLat
      : details
      ? details.projectAddressLat
      : undefined
  );
  const [projectAddressLng, setProjectAddressLng] = useState(
    formDetails.projectAddressLng
      ? formDetails.projectAddressLng
      : details
      ? details.projectAddressLng
      : undefined
  );
  const [containers, setContainers] = useState([]);
  const [step, setStep] = useState(0);

  const _user = useSelector((state) => state.authState._user);
  const [feedBack, setFeedBack] = useState({
    active: "Submitting Form",
    progress: 0,
  });

  useEffect(() => {
    if (!formDetails.editing) {
      setTitle("Order Dumpster");
    } else {
      setTitle("Manage Dumpster");
    }

    let emails = ["install@gopremier.net", email];
    if (
      formDetails.supplier &&
      formDetails.supplier.email !== "install@gopremier.net"
    ) {
      emails = [...emails, formDetails.supplier.email];
    }

    // if (formDetails.projectManagerEmail) {
    //   emails = [...emails, formDetails.projectManagerEmail];
    // } else if (details.projectManager && details.projectManager.email) {
    //   emails = [...emails, details.projectManager.email];
    // }

    emails = [...new Set(emails)];
    form.setFieldsValue({
      to: emails,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_projectId) {
      setTimeout(() => {
        const el = document.getElementById("steps");
        el.classList.remove("ant-steps-vertical");
        el.classList.add("ant-steps-horizontal");
        el.classList.add("ant-steps-label-vertical");
      });
    }
  }, [_projectId]);

  useEffect(() => {
    const scrollRef = document.getElementById("manage-dumpster-scroll");
    if (scrollRef) scrollRef.scrollTop = 0;
  }, [step]);

  const updateFeedBack = (feedBack) => {
    setFeedBack(feedBack);
  };

  const onFinish = useCallback(
    async (values) => {
      try {
        window.socket.on(`${_user}-dumping-progress`, updateFeedBack);
        unstable_batchedUpdates(() => {
          setFeedBack({
            active: "Submitting Form",
            progress: 1,
          });
          setLoading(true);
        });

        // message.loading("Sending Email...", 0);
        message.loading({
          content: "Updating dumpster...",
          duration: 0,
          key: messageKey,
        });
        values.emails = formDetails.emails;
        values.references = formDetails.references;
        values._projectId = _projectId;
        values.lastSubject = formDetails.lastSubject;
        values.messageId = formDetails.messageId;
        values._id = formDetails._id;
        values.customerId = customerId;
        values.projectAddress = projectAddress;
        values.customerName = customerName;
        values.projectManagerEmail = projectManagerEmail;
        values.projectAddressLat = projectAddressLat;
        values.projectAddressLng = projectAddressLng;
        values.subject = `Dumpster Order (${customerId})`;
        values.supplier = suppliers[values.supplier];

        let res;
        if (formDetails.editing) {
          res = await api.post("/ready-for-dumpster/update", values);
        } else {
          values.status = "Ordered";
          res = await api.post("/ready-for-dumpster/create", values);
        }

        dispatch(
          updateDumpstersActivitiesAndHashtags({
            viewableActivities,
            _projectId,
            data: res.data,
          })
        );

        unstable_batchedUpdates(() => {
          setFeedBack({
            active: "Done!",
            progress: 100,
          });
          setUnsavedChanges(false);
        });

        if (formDetails.callback) {
          formDetails.callback();
        }

        window.socket.removeAllListeners(`${_user}-dumping-progress`);
        // message.success("Dumpster updated");
        message.success({
          content: "Dumpster updated",
          duration: 1.5,
          key: messageKey,
        });
        handleClose(true);
      } catch (err) {
        console.log("err", err);
        // message.error("Error sending email");
        message.error({
          content: "Error updating dumpster",
          duration: 1.5,
          key: messageKey,
        });
        setFeedBack({
          active: "Submitting Form",
          progress: 1,
        });
        setLoading(true);
        window.socket.removeAllListeners(`${_user}-dumping-progress`);
      }
    },
    [
      _user,
      _projectId,
      customerId,
      customerName,
      dispatch,
      formDetails,
      handleClose,
      projectAddress,
      projectAddressLat,
      projectAddressLng,
      projectManagerEmail,
      setUnsavedChanges,
      suppliers,
      viewableActivities,
    ]
  );

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error sending email");
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  const cancel = () => {
    handleClose();
  };

  const handleSelect = (selected) => {
    _setProjectId(selected._id);
    setCustomerId(selected.customerId);
    setCustomerName(selected.customerName);
    setProjectAddress(
      `${selected.projectAddressStreet} ${selected.projectAddressCity}, ${selected.projectAddressState}, ${selected.projectAddressZip}`
    );
    setProjectManagerEmail(selected.projectManager.email);
    setProjectAddressLat(selected.projectAddressLat);
    setProjectAddressLng(selected.projectAddressLng);
  };

  const handleSupplierChange = async (value) => {
    try {
      if (value) {
        let newValue = [suppliers[value].email];
        let toValue = await form.getFieldValue("to");
        if (toValue) {
          for (const t of toValue) {
            newValue.push(t);
          }
        }
        newValue = [...new Set(newValue)];
        form.setFieldsValue({
          to: newValue,
        });
        setContainers(suppliers[value].containers);
      }
    } catch (err) {
      console.log("handleSupplierChange err", err);
    }
  };

  const detailsNext = async () => {
    try {
      await form.validateFields(["supplier", "size", "status", "deliveryDate"]);
      // .then(() => {

      const values = await form.getFieldsValue();

      let _subject, _body;
      if (values.status === "Pickup Held") {
        _subject = `${customerId} Pickup Hold`;
        _body = `Please hold off on picking up this box at <<https://www.google.com/maps/place/${
          projectAddressLat && projectAddressLng
            ? `${projectAddressLat},${projectAddressLng}`
            : projectAddress
        }|${projectAddress}>>, ${customerId} until further notice. Thanks!`;
      } else if (values.status === "Order Held") {
        _subject = `${customerId} Order Hold`;
        _body = `Please hold off on delivering the box to <<https://www.google.com/maps/place/${
          projectAddressLat && projectAddressLng
            ? `${projectAddressLat},${projectAddressLng}`
            : projectAddress
        }|${projectAddress}>>, ${customerId} and place in ++Will Call++.`;
      } else if (values.status === "Swaped") {
        _subject = `${customerId} Dumpster Swap`;
        _body = `Please swap the box at <<https://www.google.com/maps/place/${
          projectAddressLat && projectAddressLng
            ? `${projectAddressLat},${projectAddressLng}`
            : projectAddress
        }|${projectAddress}>>, ${customerId} with a ${values.size} on ${dayjs(
          values.deliveryDate
        ).format("MMMM DD, YYYY")}`;
      } else if (values.status === "Re-Sent") {
        _subject = `${customerId} Resend Dumpster`;
        _body = `Please send a ${values.size} on ${dayjs(
          values.deliveryDate
        ).format("MMMM DD, YYYY")} to <<https://www.google.com/maps/place/${
          projectAddressLat && projectAddressLng
            ? `${projectAddressLat},${projectAddressLng}`
            : projectAddress
        }|${projectAddress}>>, ${customerId}. Previously placed in ++Will Call++.`;
      } else if (values.status === "Canceled") {
        _subject = `${customerId} Cancel Dumpster`;
        _body = `Please cancel the box request for <<https://www.google.com/maps/place/${
          projectAddressLat && projectAddressLng
            ? `${projectAddressLat},${projectAddressLng}`
            : projectAddress
        }|${projectAddress}>>, ${customerId}.`;
      } else if (values.status === "Picked-up") {
        _subject = `${customerId} Pickup Dumpster`;
        _body = `The box at <<https://www.google.com/maps/place/${
          projectAddressLat && projectAddressLng
            ? `${projectAddressLat},${projectAddressLng}`
            : projectAddress
        }|${projectAddress}>>, ${customerId} is ready for pickup on ${dayjs(
          values.deliveryDate
        ).format(
          "MMMM DD, YYYY"
        )}. Please make sure area under dumpster is clean and wood strips are left out of the way off drive unless specified.`;
      } else if (!values.status) {
        _subject = `${customerId} Dumpster Order`;
        _body = `Please deliver a ${
          values.size
        } to <<https://www.google.com/maps/place/${
          projectAddressLat && projectAddressLng
            ? `${projectAddressLat},${projectAddressLng}`
            : projectAddress
        }|${projectAddress}>>, ${customerId} on ${dayjs(
          values.deliveryDate
        ).format("MMMM DD, YYYY")}.`;
      }
      form.setFieldsValue({
        subject: _subject,
        body: _body,
      });
      setStep((thisStep) => thisStep + 1);
    } catch (err) {
      console.log("err", err);
      message.error("All required fields must be completed");
    }
  };

  const onStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const confirmRemoveDumpster = () => {
    Modal.confirm({
      icon: <QuestionCircleOutlined />,
      zIndex: 5001,
      centered: true,
      cancelText: "Cancel",
      okText: "Confirm",
      okButtonProps: { danger: true },
      content: (
        <div>Are you sure you want to remove this dumpster from the list?</div>
      ),
      onOk() {
        return new Promise((resolve, reject) => {
          removeDumpster(() => resolve(true));
        }).catch((err) => console.log("err: ", err));
      },
      onCancel() {},
    });
  };

  const removeDumpster = useCallback(
    async (cb) => {
      try {
        await api.delete(`/manage-dumpsters/${formDetails._id}`);

        dispatch(
          fetchTable({
            table,
            col,
            order,
            search,
            activeFilter,
            fetching: false,
          })
        );

        message.success("Dumpster removed");
        handleClose(true);
        cb();
      } catch (err) {
        console.log("err", err);
        message.error("Error removing dumpster");
      }
    },
    [
      formDetails._id,
      dispatch,
      activeFilter,
      col,
      handleClose,
      order,
      search,
      table,
    ]
  );

  return (
    <Form
      form={form}
      className="form"
      autoComplete="off"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      initialValues={{
        supplier: formDetails.supplier ? formDetails.supplier._sub : undefined,
        subject: `Dumpster Order (${customerId})`,
        size: formDetails.size,
        status,
        deliveryDate:
          formDetails.status === "Re-Sent" ||
          formDetails.status === "Swapped" ||
          formDetails.status === "Picked-up"
            ? dayjs()
            : undefined,
      }}
    >
      <RemoveAutocomplete />

      {!_projectId ? (
        <SearchLeadsAndProjects
          handleSelect={handleSelect}
          leads={false}
          disableLastViewed={true}
        />
      ) : (
        <FormBody
          id="manage-dumpster-scroll"
          className="content-inner"
          style={{
            paddingLeft: 24,
            paddingTop: 24,
            paddingRight: 24,
            paddingBottom: 8,
            maxHeight: type === "modal" ? "calc(100vh - 161px)" : "unset",
          }}
        >
          <Steps
            id="steps"
            className={styles.soldSteps}
            labelPlacement="vertical"
            size="small"
            current={step}
            style={{ marginBottom: 24 }}
          >
            <Steps.Step title="Details" />
            <Steps.Step title="Email" />
          </Steps>

          <div className={step === 0 && feedBack.progress === 0 ? "" : "hide"}>
            <Form.Item
              name="supplier"
              label="Supplier"
              rules={[{ required: true, message: "Supplier is required" }]}
            >
              <Suppliers
                suppliers={suppliers}
                onChange={handleSupplierChange}
              />
            </Form.Item>

            <Form.Item
              name="size"
              label="Container"
              rules={[{ required: true, message: "Container is required" }]}
            >
              <MySelect options={containers} />
            </Form.Item>

            {formDetails.editing && (
              <Form.Item
                name="status"
                label="Status"
                className={styles.radioGroup6}
                rules={[
                  {
                    required: true,
                    message: "Status is required",
                  },
                ]}
              >
                <Radio.Group
                  optionType="button"
                  buttonStyle="solid"
                  onChange={onStatusChange}
                >
                  <Radio.Button
                    disabled={formDetails.status === "Order Held"}
                    value="Order Held"
                  >
                    Order Hold
                  </Radio.Button>
                  <Radio.Button
                    disabled={formDetails.status === "Pickup Held"}
                    value="Pickup Held"
                  >
                    Pickup Hold
                  </Radio.Button>
                  <Radio.Button
                    disabled={formDetails.status === "Re-Sent"}
                    value="Re-Sent"
                  >
                    Re-Send
                  </Radio.Button>
                  <Radio.Button
                    disabled={formDetails.status === "Swapped"}
                    value="Swaped"
                  >
                    Swap
                  </Radio.Button>
                  <Radio.Button
                    disabled={formDetails.status === "Canceled"}
                    value="Canceled"
                  >
                    Cancel
                  </Radio.Button>
                  <Radio.Button
                    disabled={formDetails.status === "Picked-up"}
                    value="Picked-up"
                  >
                    Pick-up
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            )}

            {(!status ||
              status === "Re-Sent" ||
              status === "Swaped" ||
              status === "Picked-up") && (
              <Form.Item
                name="deliveryDate"
                label={
                  status === "Picked-up"
                    ? "Pickup Date"
                    : status === "Swapped"
                    ? "Swap Date"
                    : "Delivery Date"
                }
                rules={[{ required: true, message: "Delivery Date required" }]}
              >
                <DatePicker
                  format="MMMM DD, YYYY"
                  inputReadOnly={true}
                  getPopupContainer={(trigger) =>
                    !mobile ? trigger.parentNode : document.body
                  }
                  getCalendarContainer={(trigger) =>
                    !mobile ? trigger.parentNode : document.body
                  }
                />
              </Form.Item>
            )}
          </div>

          <div className={step === 1 && feedBack.progress === 0 ? "" : "hide"}>
            <Form.Item
              name="to"
              label="To"
              rules={[
                {
                  required: true,
                  message: "You must include at least one email",
                },
              ]}
            >
              <Select mode="tags" />
            </Form.Item>

            <Form.Item
              name="subject"
              label="Subject"
              rules={[
                {
                  required: true,
                  message: "You must include a subject",
                },
              ]}
            >
              <InputNoAutoComplete id="subject" />
            </Form.Item>

            <Form.Item
              name="body"
              label={<MarkUpHelper title="Body" />}
              rules={[{ required: true, message: "Email Body is required" }]}
            >
              <Input.TextArea autoComplete="dont-use-autocomplete" rows={4} />
            </Form.Item>
          </div>

          <div
            style={{ height: "100%" }}
            className={feedBack.progress === 0 ? "hide" : ""}
          >
            <ProcessFeedback feedBack={feedBack} steps={steps} />
          </div>
        </FormBody>
      )}

      {_projectId && feedBack.progress === 0 && (
        <FormControls className="sticky-footer">
          <CancelButton handleClick={cancel} />

          {step === 0 && (
            <Form.Item style={{ margin: 0, float: "right" }}>
              <Button type="primary" onClick={detailsNext}>
                Next
              </Button>
            </Form.Item>
          )}

          {step === 1 && (
            <Button
              style={{ margin: 0, float: "right" }}
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={loading}
            >
              Send
            </Button>
          )}

          <Form.Item
            style={{
              marginRight: 8,
              marginBottom: 0,
              marginLeft: 0,
              marginTop: 0,
              float: "right",
            }}
          >
            <Button type="danger" danger onClick={confirmRemoveDumpster}>
              Remove Dumpster
            </Button>
          </Form.Item>
        </FormControls>
      )}
    </Form>
  );
};

export default ManageDumpster;
