import React, { forwardRef } from "react";
import styles from "./styles.module.scss";
import currencyFormatter from "Utils/currencyFormatter";
import sumBy from "Utils/sumBy";

const PrintBreakdown = forwardRef(
  (
    {
      customerId,
      collectionsTotal,
      materialTotal,
      laborTotal,
      overHead,
      dumpsterTotal,
      permitTotal,
      stockTotal,
      extraTotal,
      balance,
      creditCardTotal,
      financingTotal,
      warehouseTotal,
      warrantyTotal,
      guttersTotal,
      expenses,
    },
    ref
  ) => {
    const creditCardExpenses = expenses.filter((e) => e.type === "Credit Card");
    const dumpingExpenses = expenses.filter((e) => e.type === "Dumping");
    const financingExpenses = expenses.filter((e) => e.type === "Financing");
    const guaranteedRepairExpenses = expenses.filter(
      (e) => e.type === "Guaranteed Repair"
    );
    const guttersExpenses = expenses.filter((e) => e.type === "Gutters");
    const laborExpenses = expenses.filter((e) => e.type === "Labor");
    const materialsExpenses = expenses.filter((e) => e.type === "Materials");
    const permitExpenses = expenses.filter((e) => e.type === "Permit");
    const otherExpenses = expenses.filter((e) => e.type === "Other");
    const warehouseExpenses = expenses.filter((e) => e.type === "Warehouse");
    const warrantyExpenses = expenses.filter((e) => e.type === "Warranty");

    return (
      <div
        id="printContent"
        style={{
          margin: "0.5in",
          width: "7.5in",
          display: "none",
          fontSize: "26px",
          fontFamily: "Arial, Helvetica, sans-serif",
        }}
        className={styles.breakdown}
        ref={ref}
      >
        <div
          style={{
            pageBreakInside: "avoid",
            breakInside: "avoid",
            pageBreakAfter: "always",
            breakAfter: "always",
          }}
        >
          <div>
            <div
              style={{
                borderBottom: "1px solid black",
                display: "inline-block",
                lineHeight: "42px",
              }}
            >
              <b>Customer ID:</b> {customerId}
            </div>
          </div>
          <div
            style={{ borderBottom: "1px solid black", lineHeight: "48px" }}
            className={styles.bpRow}
          >
            <div
              style={{
                width: "65%",
                display: "inline-block",
                borderRight: "2px solid black",
              }}
              className={styles.col1}
            >
              <b>Total:</b>
            </div>
            <div
              style={{
                width: "34%",
                display: "inline-block",
                textAlign: "right",
              }}
              className={styles.col2}
            >
              {currencyFormatter(collectionsTotal)}
            </div>
          </div>
          <div
            style={{ borderBottom: "1px solid black", lineHeight: "48px" }}
            className={styles.bpRow}
          >
            <div
              style={{
                width: "65%",
                display: "inline-block",
                borderRight: "2px solid black",
              }}
              className={styles.col1}
            >
              <b>Materials:</b>
            </div>
            <div
              style={{
                width: "34%",
                display: "inline-block",
                textAlign: "right",
              }}
              className={styles.col2}
            >
              {currencyFormatter(materialTotal)}
            </div>
          </div>

          <div
            style={{ borderBottom: "1px solid black", lineHeight: "48px" }}
            className={styles.bpRow}
          >
            <div
              style={{
                width: "65%",
                display: "inline-block",
                borderRight: "2px solid black",
              }}
              className={styles.col1}
            >
              <b>Labor:</b>
            </div>
            <div
              style={{
                width: "34%",
                display: "inline-block",
                textAlign: "right",
              }}
              className={styles.col2}
            >
              {currencyFormatter(laborTotal)}
            </div>
          </div>

          <div
            style={{ borderBottom: "1px solid black", lineHeight: "48px" }}
            className={styles.bpRow}
          >
            <div
              style={{
                width: "65%",
                display: "inline-block",
                borderRight: "2px solid black",
              }}
              className={styles.col1}
            >
              <b>Gutters:</b>
            </div>
            <div
              style={{
                width: "34%",
                display: "inline-block",
                textAlign: "right",
              }}
              className={styles.col2}
            >
              {currencyFormatter(guttersTotal)}
            </div>
          </div>

          <div
            style={{ borderBottom: "1px solid black", lineHeight: "48px" }}
            className={styles.bpRow}
          >
            <div
              style={{
                width: "65%",
                display: "inline-block",
                borderRight: "2px solid black",
              }}
              className={styles.col1}
            >
              <b>Overhead:</b>
            </div>
            <div
              style={{
                width: "34%",
                display: "inline-block",
                textAlign: "right",
              }}
              className={styles.col2}
            >
              {currencyFormatter(overHead)}
            </div>
          </div>

          <div
            style={{ borderBottom: "1px solid black", lineHeight: "48px" }}
            className={styles.bpRow}
          >
            <div
              style={{
                width: "65%",
                display: "inline-block",
                borderRight: "2px solid black",
              }}
              className={styles.col1}
            >
              <b>Dumping:</b>
            </div>
            <div
              style={{
                width: "34%",
                display: "inline-block",
                textAlign: "right",
              }}
              className={styles.col2}
            >
              {currencyFormatter(dumpsterTotal)}
            </div>
          </div>

          <div
            style={{ borderBottom: "1px solid black", lineHeight: "48px" }}
            className={styles.bpRow}
          >
            <div
              style={{
                width: "65%",
                display: "inline-block",
                borderRight: "2px solid black",
              }}
              className={styles.col1}
            >
              <b>Permit:</b>
            </div>
            <div
              style={{
                width: "34%",
                display: "inline-block",
                textAlign: "right",
              }}
              className={styles.col2}
            >
              {currencyFormatter(permitTotal)}
            </div>
          </div>

          <div
            style={{ borderBottom: "1px solid black", lineHeight: "48px" }}
            className={styles.bpRow}
          >
            <div
              style={{
                width: "65%",
                display: "inline-block",
                borderRight: "2px solid black",
              }}
              className={styles.col1}
            >
              <b>Credit Card Fees:</b>
            </div>
            <div
              style={{
                width: "34%",
                display: "inline-block",
                textAlign: "right",
              }}
              className={styles.col2}
            >
              {currencyFormatter(creditCardTotal)}
            </div>
          </div>

          <div
            style={{ borderBottom: "1px solid black", lineHeight: "48px" }}
            className={styles.bpRow}
          >
            <div
              style={{
                width: "65%",
                display: "inline-block",
                borderRight: "2px solid black",
              }}
              className={styles.col1}
            >
              <b>Financing Fees:</b>
            </div>
            <div
              style={{
                width: "34%",
                display: "inline-block",
                textAlign: "right",
              }}
              className={styles.col2}
            >
              {currencyFormatter(financingTotal)}
            </div>
          </div>

          <div
            style={{ borderBottom: "1px solid black", lineHeight: "48px" }}
            className={styles.bpRow}
          >
            <div
              style={{
                width: "65%",
                display: "inline-block",
                borderRight: "2px solid black",
              }}
              className={styles.col1}
            >
              <b>Warehouse:</b>
            </div>
            <div
              style={{
                width: "34%",
                display: "inline-block",
                textAlign: "right",
              }}
              className={styles.col2}
            >
              {currencyFormatter(warehouseTotal)}
            </div>
          </div>

          <div
            style={{ borderBottom: "1px solid black", lineHeight: "48px" }}
            className={styles.bpRow}
          >
            <div
              style={{
                width: "65%",
                display: "inline-block",
                borderRight: "2px solid black",
              }}
              className={styles.col1}
            >
              <b>Warranty Fees:</b>
            </div>
            <div
              style={{
                width: "34%",
                display: "inline-block",
                textAlign: "right",
              }}
              className={styles.col2}
            >
              {currencyFormatter(warrantyTotal)}
            </div>
          </div>

          <div
            style={{ borderBottom: "1px solid black", lineHeight: "48px" }}
            className={styles.bpRow}
          >
            <div
              style={{
                width: "65%",
                display: "inline-block",
                borderRight: "2px solid black",
              }}
              className={styles.col1}
            >
              <b>Stock:</b>
            </div>
            <div
              style={{
                width: "34%",
                display: "inline-block",
                textAlign: "right",
              }}
              className={styles.col2}
            >
              {currencyFormatter(stockTotal)}
            </div>
          </div>

          <div
            style={{ borderBottom: "1px solid black", lineHeight: "48px" }}
            className={styles.bpRow}
          >
            <div
              style={{
                width: "65%",
                display: "inline-block",
                borderRight: "2px solid black",
              }}
              className={styles.col1}
            >
              <b>Extra:</b>
            </div>
            <div
              style={{
                width: "34%",
                display: "inline-block",
                textAlign: "right",
              }}
              className={styles.col2}
            >
              {currencyFormatter(extraTotal)}
            </div>
          </div>

          <div
            style={{
              borderBottom: "1px solid black",
              lineHeight: "48px",
              borderTop: "2px solid black",
            }}
            className={styles.bpRow}
          >
            <div
              style={{
                width: "65%",
                display: "inline-block",
                borderRight: "2px solid black",
              }}
              className={styles.col1}
            >
              <b>Balance:</b>
            </div>
            <div
              style={{
                width: "34%",
                display: "inline-block",
                textAlign: "right",
              }}
              className={styles.col2}
            >
              {currencyFormatter(balance)}
            </div>
          </div>
        </div>

        <div>
          <div style={{ lineHeight: "32px" }} className={styles.exTitle}>
            <div
              style={{ width: "100%", display: "block" }}
              className={styles.col1}
            >
              <h2 style={{ textAlign: "center" }}>All Expenses</h2>
            </div>
          </div>

          <div
            style={{ lineHeight: "32px", marginTop: "12px" }}
            className={styles.exTitle}
          >
            <div
              style={{ width: "60%", display: "inline-block" }}
              className={styles.col1}
            >
              <b>Credit Card</b>
            </div>
            <div
              style={{
                width: "39%",
                display: "inline-block",
                textAlign: "right",
              }}
              className={styles.col2}
            >
              <b>
                {currencyFormatter(sumBy(creditCardExpenses, "amount") || 0)}
              </b>
            </div>
          </div>

          {creditCardExpenses.length > 0 ? (
            creditCardExpenses.map((ex, i) => (
              <div
                key={ex._id}
                style={{
                  borderTop: i === 0 ? "1px solid black" : "none",
                  borderBottom: "1px solid black",
                  borderLeft: "1px solid black",
                  borderRight: "1px solid black",
                  lineHeight: "32px",
                }}
                className={styles.expenseRow}
              >
                <div
                  style={{
                    width: "65%",
                    display: "inline-block",
                    paddingLeft: "4px",
                  }}
                  className={styles.col1}
                >
                  {ex.vendor}:
                </div>
                <div
                  style={{
                    width: "34%",
                    display: "inline-block",
                    textAlign: "right",
                  }}
                  className={styles.col2}
                >
                  {currencyFormatter(ex.amount)}
                </div>
              </div>
            ))
          ) : (
            <div
              style={{
                border: "1px solid black",
                lineHeight: "32px",
              }}
              className={styles.expenseRow}
            >
              <div
                style={{
                  width: "65%",
                  display: "inline-block",
                  paddingLeft: "4px",
                }}
                className={styles.col1}
              >
                No Credit Card Expenses
              </div>
              <div
                style={{
                  width: "34%",
                  display: "inline-block",
                  textAlign: "right",
                }}
                className={styles.col2}
              ></div>
            </div>
          )}

          <div
            style={{ lineHeight: "32px", marginTop: "12px" }}
            className={styles.exTitle}
          >
            <div
              style={{ width: "60%", display: "inline-block" }}
              className={styles.col1}
            >
              <b>Dumping</b>
            </div>
            <div
              style={{
                width: "39%",
                display: "inline-block",
                textAlign: "right",
              }}
              className={styles.col2}
            >
              <b>{currencyFormatter(sumBy(dumpingExpenses, "amount") || 0)}</b>
            </div>
          </div>

          {dumpingExpenses.length > 0 ? (
            dumpingExpenses.map((ex, i) => (
              <div
                key={ex._id}
                style={{
                  borderTop: i === 0 ? "1px solid black" : "none",
                  borderBottom: "1px solid black",
                  borderLeft: "1px solid black",
                  borderRight: "1px solid black",
                  lineHeight: "32px",
                }}
                className={styles.expenseRow}
              >
                <div
                  style={{
                    width: "65%",
                    display: "inline-block",
                    paddingLeft: "4px",
                  }}
                  className={styles.col1}
                >
                  {ex.vendor}:
                </div>
                <div
                  style={{
                    width: "34%",
                    display: "inline-block",
                    textAlign: "right",
                  }}
                  className={styles.col2}
                >
                  {currencyFormatter(ex.amount)}
                </div>
              </div>
            ))
          ) : (
            <div
              style={{
                border: "1px solid black",
                lineHeight: "32px",
              }}
              className={styles.expenseRow}
            >
              <div
                style={{
                  width: "65%",
                  display: "inline-block",
                  paddingLeft: "4px",
                }}
                className={styles.col1}
              >
                No Dumping Expenses
              </div>
              <div
                style={{
                  width: "34%",
                  display: "inline-block",
                  textAlign: "right",
                }}
                className={styles.col2}
              ></div>
            </div>
          )}

          <div
            style={{ lineHeight: "32px", marginTop: "12px" }}
            className={styles.exTitle}
          >
            <div
              style={{ width: "60%", display: "inline-block" }}
              className={styles.col1}
            >
              <b>Financing</b>
            </div>
            <div
              style={{
                width: "39%",
                display: "inline-block",
                textAlign: "right",
              }}
              className={styles.col2}
            >
              <b>
                {currencyFormatter(sumBy(financingExpenses, "amount") || 0)}
              </b>
            </div>
          </div>

          {financingExpenses.length > 0 ? (
            financingExpenses.map((ex, i) => (
              <div
                key={ex._id}
                style={{
                  borderTop: i === 0 ? "1px solid black" : "none",
                  borderBottom: "1px solid black",
                  borderLeft: "1px solid black",
                  borderRight: "1px solid black",
                  lineHeight: "32px",
                }}
                className={styles.expenseRow}
              >
                <div
                  style={{
                    width: "65%",
                    display: "inline-block",
                    paddingLeft: "4px",
                  }}
                  className={styles.col1}
                >
                  {ex.vendor}:
                </div>
                <div
                  style={{
                    width: "34%",
                    display: "inline-block",
                    textAlign: "right",
                  }}
                  className={styles.col2}
                >
                  {currencyFormatter(ex.amount)}
                </div>
              </div>
            ))
          ) : (
            <div
              style={{
                border: "1px solid black",
                lineHeight: "32px",
              }}
              className={styles.expenseRow}
            >
              <div
                style={{
                  width: "65%",
                  display: "inline-block",
                  paddingLeft: "4px",
                }}
                className={styles.col1}
              >
                No Financing Expenses
              </div>
              <div
                style={{
                  width: "34%",
                  display: "inline-block",
                  textAlign: "right",
                }}
                className={styles.col2}
              ></div>
            </div>
          )}

          <div
            style={{ lineHeight: "32px", marginTop: "12px" }}
            className={styles.exTitle}
          >
            <div
              style={{ width: "60%", display: "inline-block" }}
              className={styles.col1}
            >
              <b>Guaranteed Repair</b>
            </div>
            <div
              style={{
                width: "39%",
                display: "inline-block",
                textAlign: "right",
              }}
              className={styles.col2}
            >
              <b>
                {currencyFormatter(
                  sumBy(guaranteedRepairExpenses, "amount") || 0
                )}
              </b>
            </div>
          </div>

          {guaranteedRepairExpenses.length > 0 ? (
            guaranteedRepairExpenses.map((ex, i) => (
              <div
                key={ex._id}
                style={{
                  borderTop: i === 0 ? "1px solid black" : "none",
                  borderBottom: "1px solid black",
                  borderLeft: "1px solid black",
                  borderRight: "1px solid black",
                  lineHeight: "32px",
                }}
                className={styles.expenseRow}
              >
                <div
                  style={{
                    width: "65%",
                    display: "inline-block",
                    paddingLeft: "4px",
                  }}
                  className={styles.col1}
                >
                  {ex.vendor}:
                </div>
                <div
                  style={{
                    width: "34%",
                    display: "inline-block",
                    textAlign: "right",
                  }}
                  className={styles.col2}
                >
                  {currencyFormatter(ex.amount)}
                </div>
              </div>
            ))
          ) : (
            <div
              style={{
                border: "1px solid black",
                lineHeight: "32px",
              }}
              className={styles.expenseRow}
            >
              <div
                style={{
                  width: "65%",
                  display: "inline-block",
                  paddingLeft: "4px",
                }}
                className={styles.col1}
              >
                No Guaranteed Repair Expenses
              </div>
              <div
                style={{
                  width: "34%",
                  display: "inline-block",
                  textAlign: "right",
                }}
                className={styles.col2}
              ></div>
            </div>
          )}

          <div
            style={{ lineHeight: "32px", marginTop: "12px" }}
            className={styles.exTitle}
          >
            <div
              style={{ width: "60%", display: "inline-block" }}
              className={styles.col1}
            >
              <b>Gutters</b>
            </div>
            <div
              style={{
                width: "39%",
                display: "inline-block",
                textAlign: "right",
              }}
              className={styles.col2}
            >
              <b>{currencyFormatter(sumBy(guttersExpenses, "amount") || 0)}</b>
            </div>
          </div>

          {guttersExpenses.length > 0 ? (
            guttersExpenses.map((ex, i) => (
              <div
                key={ex._id}
                style={{
                  borderTop: i === 0 ? "1px solid black" : "none",
                  borderBottom: "1px solid black",
                  borderLeft: "1px solid black",
                  borderRight: "1px solid black",
                  lineHeight: "32px",
                }}
                className={styles.expenseRow}
              >
                <div
                  style={{
                    width: "65%",
                    display: "inline-block",
                    paddingLeft: "4px",
                  }}
                  className={styles.col1}
                >
                  {ex.vendor}:
                </div>
                <div
                  style={{
                    width: "34%",
                    display: "inline-block",
                    textAlign: "right",
                  }}
                  className={styles.col2}
                >
                  {currencyFormatter(ex.amount)}
                </div>
              </div>
            ))
          ) : (
            <div
              style={{
                border: "1px solid black",
                lineHeight: "32px",
              }}
              className={styles.expenseRow}
            >
              <div
                style={{
                  width: "65%",
                  display: "inline-block",
                  paddingLeft: "4px",
                }}
                className={styles.col1}
              >
                No Gutter Expenses
              </div>
              <div
                style={{
                  width: "34%",
                  display: "inline-block",
                  textAlign: "right",
                }}
                className={styles.col2}
              ></div>
            </div>
          )}

          <div
            style={{ lineHeight: "32px", marginTop: "12px" }}
            className={styles.exTitle}
          >
            <div
              style={{ width: "60%", display: "inline-block" }}
              className={styles.col1}
            >
              <b>Labor</b>
            </div>
            <div
              style={{
                width: "39%",
                display: "inline-block",
                textAlign: "right",
              }}
              className={styles.col2}
            >
              <b>{currencyFormatter(sumBy(laborExpenses, "amount") || 0)}</b>
            </div>
          </div>

          {laborExpenses.length > 0 ? (
            laborExpenses.map((ex, i) => (
              <div
                key={ex._id}
                style={{
                  borderTop: i === 0 ? "1px solid black" : "none",
                  borderBottom: "1px solid black",
                  borderLeft: "1px solid black",
                  borderRight: "1px solid black",
                  lineHeight: "32px",
                }}
                className={styles.expenseRow}
              >
                <div
                  style={{
                    width: "65%",
                    display: "inline-block",
                    paddingLeft: "4px",
                  }}
                  className={styles.col1}
                >
                  {ex.vendor}:
                </div>
                <div
                  style={{
                    width: "34%",
                    display: "inline-block",
                    textAlign: "right",
                  }}
                  className={styles.col2}
                >
                  {currencyFormatter(ex.amount)}
                </div>
              </div>
            ))
          ) : (
            <div
              style={{
                border: "1px solid black",
                lineHeight: "32px",
              }}
              className={styles.expenseRow}
            >
              <div
                style={{
                  width: "65%",
                  display: "inline-block",
                  paddingLeft: "4px",
                }}
                className={styles.col1}
              >
                No Labor Expenses
              </div>
              <div
                style={{
                  width: "34%",
                  display: "inline-block",
                  textAlign: "right",
                }}
                className={styles.col2}
              ></div>
            </div>
          )}

          <div
            style={{ lineHeight: "32px", marginTop: "12px" }}
            className={styles.exTitle}
          >
            <div
              style={{ width: "60%", display: "inline-block" }}
              className={styles.col1}
            >
              <b>Materials</b>
            </div>
            <div
              style={{
                width: "39%",
                display: "inline-block",
                textAlign: "right",
              }}
              className={styles.col2}
            >
              <b>
                {currencyFormatter(sumBy(materialsExpenses, "amount") || 0)}
              </b>
            </div>
          </div>

          {materialsExpenses.length > 0 ? (
            materialsExpenses.map((ex, i) => (
              <div
                key={ex._id}
                style={{
                  borderTop: i === 0 ? "1px solid black" : "none",
                  borderBottom: "1px solid black",
                  borderLeft: "1px solid black",
                  borderRight: "1px solid black",
                  lineHeight: "32px",
                }}
                className={styles.expenseRow}
              >
                <div
                  style={{
                    width: "65%",
                    display: "inline-block",
                    paddingLeft: "4px",
                  }}
                  className={styles.col1}
                >
                  {ex.vendor}:
                </div>
                <div
                  style={{
                    width: "34%",
                    display: "inline-block",
                    textAlign: "right",
                  }}
                  className={styles.col2}
                >
                  {currencyFormatter(ex.amount)}
                </div>
              </div>
            ))
          ) : (
            <div
              style={{
                border: "1px solid black",
                lineHeight: "32px",
              }}
              className={styles.expenseRow}
            >
              <div
                style={{
                  width: "65%",
                  display: "inline-block",
                  paddingLeft: "4px",
                }}
                className={styles.col1}
              >
                No Material Expenses
              </div>
              <div
                style={{
                  width: "34%",
                  display: "inline-block",
                  textAlign: "right",
                }}
                className={styles.col2}
              ></div>
            </div>
          )}

          <div
            style={{ lineHeight: "32px", marginTop: "12px" }}
            className={styles.exTitle}
          >
            <div
              style={{ width: "60%", display: "inline-block" }}
              className={styles.col1}
            >
              <b>Permit</b>
            </div>
            <div
              style={{
                width: "39%",
                display: "inline-block",
                textAlign: "right",
              }}
              className={styles.col2}
            >
              <b>{currencyFormatter(sumBy(permitExpenses, "amount") || 0)}</b>
            </div>
          </div>

          {permitExpenses.length > 0 ? (
            permitExpenses.map((ex, i) => (
              <div
                key={ex._id}
                style={{
                  borderTop: i === 0 ? "1px solid black" : "none",
                  borderBottom: "1px solid black",
                  borderLeft: "1px solid black",
                  borderRight: "1px solid black",
                  lineHeight: "32px",
                }}
                className={styles.expenseRow}
              >
                <div
                  style={{
                    width: "65%",
                    display: "inline-block",
                    paddingLeft: "4px",
                  }}
                  className={styles.col1}
                >
                  {ex.vendor}:
                </div>
                <div
                  style={{
                    width: "34%",
                    display: "inline-block",
                    textAlign: "right",
                  }}
                  className={styles.col2}
                >
                  {currencyFormatter(ex.amount)}
                </div>
              </div>
            ))
          ) : (
            <div
              style={{
                border: "1px solid black",
                lineHeight: "32px",
              }}
              className={styles.expenseRow}
            >
              <div
                style={{
                  width: "65%",
                  display: "inline-block",
                  paddingLeft: "4px",
                }}
                className={styles.col1}
              >
                No Permit Expenses
              </div>
              <div
                style={{
                  width: "34%",
                  display: "inline-block",
                  textAlign: "right",
                }}
                className={styles.col2}
              ></div>
            </div>
          )}

          <div
            style={{ lineHeight: "32px", marginTop: "12px" }}
            className={styles.exTitle}
          >
            <div
              style={{ width: "60%", display: "inline-block" }}
              className={styles.col1}
            >
              <b>Other</b>
            </div>
            <div
              style={{
                width: "39%",
                display: "inline-block",
                textAlign: "right",
              }}
              className={styles.col2}
            >
              <b>{currencyFormatter(sumBy(otherExpenses, "amount") || 0)}</b>
            </div>
          </div>

          {otherExpenses.length > 0 ? (
            otherExpenses.map((ex, i) => (
              <div
                key={ex._id}
                style={{
                  borderTop: i === 0 ? "1px solid black" : "none",
                  borderBottom: "1px solid black",
                  borderLeft: "1px solid black",
                  borderRight: "1px solid black",
                  lineHeight: "32px",
                }}
                className={styles.expenseRow}
              >
                <div
                  style={{
                    width: "65%",
                    display: "inline-block",
                    paddingLeft: "4px",
                  }}
                  className={styles.col1}
                >
                  {ex.vendor}:
                </div>
                <div
                  style={{
                    width: "34%",
                    display: "inline-block",
                    textAlign: "right",
                  }}
                  className={styles.col2}
                >
                  {currencyFormatter(ex.amount)}
                </div>
              </div>
            ))
          ) : (
            <div
              style={{
                border: "1px solid black",
                lineHeight: "32px",
              }}
              className={styles.expenseRow}
            >
              <div
                style={{
                  width: "65%",
                  display: "inline-block",
                  paddingLeft: "4px",
                }}
                className={styles.col1}
              >
                No Other Expenses
              </div>
              <div
                style={{
                  width: "34%",
                  display: "inline-block",
                  textAlign: "right",
                }}
                className={styles.col2}
              ></div>
            </div>
          )}

          <div
            style={{ lineHeight: "32px", marginTop: "12px" }}
            className={styles.exTitle}
          >
            <div
              style={{ width: "60%", display: "inline-block" }}
              className={styles.col1}
            >
              <b>Warehouse</b>
            </div>
            <div
              style={{
                width: "39%",
                display: "inline-block",
                textAlign: "right",
              }}
              className={styles.col2}
            >
              <b>
                {currencyFormatter(sumBy(warehouseExpenses, "amount") || 0)}
              </b>
            </div>
          </div>

          {warehouseExpenses.length > 0 ? (
            warehouseExpenses.map((ex, i) => (
              <div
                key={ex._id}
                style={{
                  borderTop: i === 0 ? "1px solid black" : "none",
                  borderBottom: "1px solid black",
                  borderLeft: "1px solid black",
                  borderRight: "1px solid black",
                  lineHeight: "32px",
                }}
                className={styles.expenseRow}
              >
                <div
                  style={{
                    width: "65%",
                    display: "inline-block",
                    paddingLeft: "4px",
                  }}
                  className={styles.col1}
                >
                  {ex.vendor}:
                </div>
                <div
                  style={{
                    width: "34%",
                    display: "inline-block",
                    textAlign: "right",
                  }}
                  className={styles.col2}
                >
                  {currencyFormatter(ex.amount)}
                </div>
              </div>
            ))
          ) : (
            <div
              style={{
                border: "1px solid black",
                lineHeight: "32px",
              }}
              className={styles.expenseRow}
            >
              <div
                style={{
                  width: "65%",
                  display: "inline-block",
                  paddingLeft: "4px",
                }}
                className={styles.col1}
              >
                No Warehouse Expenses
              </div>
              <div
                style={{
                  width: "34%",
                  display: "inline-block",
                  textAlign: "right",
                }}
                className={styles.col2}
              ></div>
            </div>
          )}

          <div
            style={{ lineHeight: "32px", marginTop: "12px" }}
            className={styles.exTitle}
          >
            <div
              style={{ width: "60%", display: "inline-block" }}
              className={styles.col1}
            >
              <b>Warranty</b>
            </div>
            <div
              style={{
                width: "39%",
                display: "inline-block",
                textAlign: "right",
              }}
              className={styles.col2}
            >
              <b>{currencyFormatter(sumBy(warrantyExpenses, "amount") || 0)}</b>
            </div>
          </div>

          {warrantyExpenses.length > 0 ? (
            warrantyExpenses.map((ex, i) => (
              <div
                key={ex._id}
                style={{
                  borderTop: i === 0 ? "1px solid black" : "none",
                  borderBottom: "1px solid black",
                  borderLeft: "1px solid black",
                  borderRight: "1px solid black",
                  lineHeight: "32px",
                }}
                className={styles.expenseRow}
              >
                <div
                  style={{
                    width: "65%",
                    display: "inline-block",
                    paddingLeft: "4px",
                  }}
                  className={styles.col1}
                >
                  {ex.vendor}:
                </div>
                <div
                  style={{
                    width: "34%",
                    display: "inline-block",
                    textAlign: "right",
                  }}
                  className={styles.col2}
                >
                  {currencyFormatter(ex.amount)}
                </div>
              </div>
            ))
          ) : (
            <div
              style={{
                border: "1px solid black",
                lineHeight: "32px",
              }}
              className={styles.expenseRow}
            >
              <div
                style={{
                  width: "65%",
                  display: "inline-block",
                  paddingLeft: "4px",
                }}
                className={styles.col1}
              >
                No Warranty Expenses
              </div>
              <div
                style={{
                  width: "34%",
                  display: "inline-block",
                  textAlign: "right",
                }}
                className={styles.col2}
              ></div>
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default PrintBreakdown;
