import React, { memo } from "react";
import styles from "./styles.module.scss";
import Card from "antd/es/card";
import Statistic from "antd/es/statistic";
import currencyFormatter from "Utils/currencyFormatter";
import percentFormatter from "Utils/percentFormatter";
import ArrowDown from "Icons/ArrowDownDropCircle";
import ArrowUp from "Icons/ArrowUpDropCircle";
import Tag from "antd/es/tag";
import Skeleton from "antd/es/skeleton";

const StatisticCard = ({
  title,
  value,
  decimalPlaces = 0,
  trend,
  lastYear,
  loading,
  onClick,
  leader,
}) => {
  const handleClick = () => {
    onClick(title);
  };

  return (
    <Card
      bodyStyle={{ padding: 12, height: 104.28 }}
      className="z-depth-1"
      style={{ overflow: "hidden" }}
      bordered={false}
      onClick={handleClick}
      hoverable
    >
      <Statistic
        loading={loading}
        title={
          <>
            <div
              className={`${styles.title} ${
                loading
                  ? ""
                  : trend === 0 || trend
                  ? ""
                  : styles.trendNotIncluded
              }`}
            >
              {title}
            </div>
            {loading ? (
              <div className={styles.trend}>
                <Skeleton active paragraph={{ rows: 0 }} />
              </div>
            ) : trend === 0 || trend ? (
              <div className={styles.trend}>
                <Tag
                  className={styles.percentTag}
                  color={trend < 0 ? "#ff7056" : "#00c292"}
                >
                  {percentFormatter(trend)}{" "}
                  {trend < 0 ? (
                    <ArrowDown />
                  ) : (
                    <ArrowUp className={styles.up} />
                  )}
                </Tag>
              </div>
            ) : null}
          </>
        }
        value={
          decimalPlaces === 0
            ? `${value}${leader ? ` (${leader})` : ""}`
            : currencyFormatter(value)
        }
        precision={decimalPlaces}
        className={styles.stat}
      />
      {loading ? (
        <div className={styles.lastYear}>
          <Skeleton active paragraph={{ rows: 0 }} />
        </div>
      ) : lastYear === 0 || lastYear ? (
        <div className="ant-statistic-title">
          Last Year:{" "}
          {(lastYear || lastYear === 0) && decimalPlaces === 0
            ? lastYear
            : currencyFormatter(lastYear)}
        </div>
      ) : (
        <div className="ant-statistic-title" style={{ color: "#ffffff" }}>
          Last Year:
        </div>
      )}
    </Card>
  );
};

export default memo(StatisticCard);
