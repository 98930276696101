import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openForm } from "Actions/formActions";
import List from "antd/es/list";
import currencyFormatter from "Utils/currencyFormatter";
import styles from "./styles.module.scss";
import Wallet from "Icons/Wallet";
import timeStamp from "Utils/timeStamp";

const Expenses = ({ expenses }) => {
  return (
    <>
      {expenses && expenses.length > 0 ? (
        expenses.map((expense) => (
          <List
            bordered
            key={expense._id}
            itemLayout="horizontal"
            className={styles.list}
          >
            <ExpensesItem expense={expense} />
          </List>
        ))
      ) : (
        <List bordered itemLayout="horizontal" className={styles.list}>
          <List.Item className={`${styles.transactionList} ${styles.none}`}>
            <List.Item.Meta
              avatar={
                <div className={styles.avatar}>
                  <Wallet color="#108ee9" />
                </div>
              }
              title="No expenses have been logged"
            />
          </List.Item>
        </List>
      )}
    </>
  );
};

const ExpensesItem = ({ expense }) => {
  const dispatch = useDispatch();
  const admin = useSelector((state) => state.authState.admin);
  const role = useSelector((state) => state.authState.role);

  const editExpense = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(
        openForm("expense", {
          ...expense,
          editing: true,
        })
      );
    },
    [dispatch, expense]
  );

  return (
    <List.Item
      onClick={admin === "Yes" || role === "Office" ? editExpense : undefined}
      className={styles.transactionList}
    >
      <List.Item.Meta
        avatar={
          <div className={styles.avatar}>
            <Wallet color="#108ee9" />
          </div>
        }
        title={
          <span>
            {expense.vendor === "N/A" ? (
              <>{expense.type}</>
            ) : (
              <>
                {`${expense.vendor} `}
                <small>({expense.type})</small>
              </>
            )}
          </span>
        }
        description={
          <>
            <div>{currencyFormatter(expense.amount)}</div>
            <div>{timeStamp(expense.transactionDate)}</div>
          </>
        }
      />
    </List.Item>
  );
};

export default Expenses;
