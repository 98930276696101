import React from "react";
import Tooltip from "antd/es/tooltip";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";

const MarkUpHelper = ({ title }) => {
  return (
    <span>
      {title}&nbsp;
      <Tooltip
        title={
          <div>
            <div>Wrapping content in symbols adds emphasis:</div>
            <div>
              <span
                style={{
                  display: "inline-block",
                  width: 100,
                  textAlign: "center",
                }}
              >
                **bold**
              </span>
              =
              <strong
                style={{
                  display: "inline-block",
                  width: 100,
                  textAlign: "center",
                }}
              >
                bold
              </strong>
            </div>
            <div>
              <span
                style={{
                  display: "inline-block",
                  width: 100,
                  textAlign: "center",
                }}
              >
                __underline__
              </span>
              =
              <u
                style={{
                  display: "inline-block",
                  width: 100,
                  textAlign: "center",
                }}
              >
                underline
              </u>
            </div>
            <div>
              <span
                style={{
                  display: "inline-block",
                  width: 100,
                  textAlign: "center",
                }}
              >
                ~~italics~~
              </span>
              =
              <i
                style={{
                  display: "inline-block",
                  width: 100,
                  textAlign: "center",
                }}
              >
                italics
              </i>
            </div>
            <div>
              <span
                style={{
                  display: "inline-block",
                  width: 100,
                  textAlign: "center",
                }}
              >
                --delete--
              </span>
              =
              <del
                style={{
                  display: "inline-block",
                  width: 100,
                  textAlign: "center",
                }}
              >
                delete
              </del>
            </div>
            <span
              style={{
                display: "inline-block",
                width: 100,
                textAlign: "center",
              }}
            >
              ++red++
            </span>
            =
            <span
              style={{
                color: "red",
                display: "inline-block",
                width: 100,
                textAlign: "center",
              }}
            >
              red
            </span>
          </div>
        }
      >
        <QuestionCircleOutlined />
      </Tooltip>
    </span>
  );
};

export default MarkUpHelper;
