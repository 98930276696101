import React from "react";
import BarChartWithSwitch from "Reports/BarChartWithSwitch";

export default function LossReasons() {
  return (
    <BarChartWithSwitch
      path="/reports/lossReasons"
      _value="Leads"
      secondary="Projects"
      report="LOSS REASONS"
      headers={["Loss Reason", "Total"]}
    />
  );
}
