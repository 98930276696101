import React, { forwardRef } from "react";
import Input from "antd/es/input";

const InputNoAutoComplete = (
  {
    value,
    onChange,
    disabled,
    handleFocus,
    handleBlur,
    autoFocus = false,
    placeholder = undefined,
    type = "text",
    allowClear = false,
    tabIndex = undefined,
    id = undefined,
    prefix = undefined,
    className = undefined,
    size = "middle",
  },
  ref
) => {
  const triggerChange = (changedValue) => {
    if (onChange) {
      onChange(changedValue);
    }
  };

  const handleChange = (e) => {
    if (e.target) triggerChange(e.target.value);
    if (e.value) triggerChange(e.value);
  };

  return (
    <div id={id}>
      <label id={id} htmlFor="" style={{ display: "none" }}>
        hidden label to mislead chrome autocomplete
      </label>
      <Input
        ref={ref}
        placeholder={placeholder}
        autoComplete="dont-use-autocomplete"
        disabled={disabled}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        value={value}
        autoFocus={autoFocus}
        type={type}
        allowClear={allowClear}
        tabIndex={tabIndex}
        prefix={prefix}
        className={className}
        size={size}
      />
    </div>
  );
};

export default forwardRef(InputNoAutoComplete);
