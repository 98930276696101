import React from "react";
import styles from "./styles.module.scss";
import Typography from "antd/es/typography";
import currencyFormatter from "Utils/currencyFormatter";
import Card from "antd/es/card";

const BalanceCard = ({ balance }) => {
  return (
    <Card className={styles.balanceCard} bordered={false}>
      <Typography.Title className={styles.title}>
        {currencyFormatter(balance)}
      </Typography.Title>
      <Typography.Text type="secondary" className={styles.subTitle}>
        TOTAL BALANCE
      </Typography.Text>
    </Card>
  );
};

export default BalanceCard;
