import React from "react";
import { useSelector } from "react-redux";
import FormContainer from "./FormContainer";
import CreateActivity from "./CreateActivity";
import CreateComment from "./CreateComment";
import CreateReply from "./CreateReply";
import EditHashTags from "Components/Activities/EditHashTags";
import MaterialQuote from "Forms/MaterialQuote";
import PhoneBookForm from "Forms/PhoneBook";
import ComposeEmail from "./ComposeEmail";
import UpdateScope from "./UpdateScope";
import CreateEvent from "./CreateEvent";
import CreateLead from "./CreateLead";
import Builder from "./Builder";
import Requests from "./Requests";
import WorkRequest from "./WorkRequest";
import CreateProject from "./CreateProject";
import MaterialPickup from "./MaterialPickup";
import OnHold from "./OnHold";
import ProjectCancelled from "./ProjectCancelled";
import ProjectCloseout from "./ProjectCloseout";
import Invoice from "./Invoice";
import CompletionCertificate from "./CompletionCertificate";
import Action from "./Action";
import Expense from "./Expense";
import Collection from "./Collection";
import Document from "./Document";
import WorkOrder from "./WorkOrder";
import Scope from "./Scope";
import WorkRequestRoof from "./WorkRequestRoof";
import ManageDumpster from "./ManageDumpster";
import PreJobInspection from "./PreJobInspection";
import InstallTodo from "./InstallTodo";
import InstallTodoNonPo from "./InstallTodoNonPo";
import User from "./User";
import CompanyExpense from "./CompanyExpense";
import DrawActivity from "./DrawActivity";
import NeedsBrokedown from "./NeedsBrokedown";
import CompanyPost from "./CompanyPost";
import OfficeReviewNote from "./OfficeReviewNote";
import MoldReviewNote from "./MoldReviewNote";
import MarketingActivity from "./MarketingActivity";
import useIsMobileForms from "Hooks/useIsMobileForms";
import InstallTodoUpload from "./InstallTodoUpload";
import UpdateDeliveryDate from "./updateDeliveryDate";
import Receipt from "./Receipt";
import ScheduleEstimate from "./ScheduleEstimate";

const Forms = () => {
  const activity = useSelector((state) => state.formState.activity.open);
  const comment = useSelector((state) => state.formState.comment.open);
  const reply = useSelector((state) => state.formState.reply.open);
  const materialQuote = useSelector(
    (state) => state.formState.materialQuote.open
  );
  const phoneBook = useSelector((state) => state.formState.phoneBook.open);
  const email = useSelector((state) => state.formState.email.open);
  const updateScope = useSelector((state) => state.formState.updateScope.open);
  const createEvent = useSelector((state) => state.formState.createEvent.open);
  const createLead = useSelector((state) => state.formState.createLead.open);
  const createProject = useSelector(
    (state) => state.formState.createProject.open
  );
  const builder = useSelector((state) => state.formState.builder.open);
  const workRequest = useSelector((state) => state.formState.workRequest.open);
  const materialPickup = useSelector(
    (state) => state.formState.materialPickup.open
  );
  const onHold = useSelector((state) => state.formState.onHold.open);
  const projectCancelled = useSelector(
    (state) => state.formState.projectCancelled.open
  );
  const scope = useSelector((state) => state.formState.scope.open);
  const invoice = useSelector((state) => state.formState.invoice.open);
  const receipt = useSelector((state) => state.formState.receipt.open);
  const completionCertificate = useSelector(
    (state) => state.formState.completionCertificate.open
  );

  const dumping = useSelector((state) => state.formState.dumping.open);
  const expense = useSelector((state) => state.formState.expense.open);
  const collection = useSelector((state) => state.formState.collection.open);
  const companyExpense = useSelector(
    (state) => state.formState.companyExpense.open
  );
  const companyPost = useSelector((state) => state.formState.companyPost.open);
  const user = useSelector((state) => state.formState.user.open);
  const action = useSelector((state) => state.formState.action.open);
  const workOrder = useSelector((state) => state.formState.workOrder.open);
  const workRequestRoof = useSelector(
    (state) => state.formState.workRequestRoof.open
  );
  const preJobInspection = useSelector(
    (state) => state.formState.preJobInspection.open
  );
  const installTodo = useSelector((state) => state.formState.installTodo.open);
  const drawActivity = useSelector(
    (state) => state.formState.drawActivity.open
  );
  const needsBrokedown = useSelector(
    (state) => state.formState.needsBrokedown.open
  );
  const officeReviewNote = useSelector(
    (state) => state.formState.officeReviewNote.open
  );
  const moldReviewNote = useSelector(
    (state) => state.formState.moldReviewNote.open
  );
  const installTodoNonPo = useSelector(
    (state) => state.formState.installTodoNonPo.open
  );
  const marketingActivity = useSelector(
    (state) => state.formState.marketingActivity.open
  );
  const hashtags = useSelector((state) => state.formState.hashtags.open);
  const projectCloseout = useSelector(
    (state) => state.formState.projectCloseout.open
  );
  const requests = useSelector((state) => state.formState.requests.open);
  const documentForm = useSelector(
    (state) => state.formState.documentForm.open
  );
  const installTodoChangeImage = useSelector(
    (state) => state.formState.installTodoChangeImage.open
  );
  const updateDeliveryDate = useSelector(
    (state) => state.formState.updateDeliveryDate.open
  );
  const scheduleEstimate = useSelector(
    (state) => state.formState.scheduleEstimate.open
  );

  const mobile = useIsMobileForms();

  return (
    <>
      <FormContainer
        type={mobile ? "drawer" : "modal"}
        width={mobile ? "100%" : 320}
        title="Material Quote"
        visible={materialQuote}
        form={MaterialQuote}
        formType="materialQuote"
      />

      <FormContainer
        type={mobile ? "drawer" : "modal"}
        width={mobile ? "100%" : 320}
        title="Work Order"
        visible={workOrder}
        form={WorkOrder}
        formType="workOrder"
      />

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 340,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0 },
        }}
        type={mobile ? "drawer" : "modal"}
        width={mobile ? "100%" : 320}
        title="Create Contact"
        visible={phoneBook}
        form={PhoneBookForm}
        formType="phoneBook"
      />

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0 },
        }}
        type={mobile ? "drawer" : "modal"}
        width={mobile ? "100%" : 320}
        title="Create Post"
        visible={activity}
        form={CreateActivity}
        formType="activity"
        zIndex={4500}
      />

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0 },
        }}
        type={mobile ? "drawer" : "modal"}
        width={mobile ? "100%" : 320}
        title="Add Comment"
        visible={comment}
        form={CreateComment}
        formType="comment"
      />

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0 },
        }}
        type={mobile ? "drawer" : "modal"}
        width={mobile ? "100%" : 320}
        title="Add Reply"
        visible={reply}
        form={CreateReply}
        formType="reply"
      />

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0 },
        }}
        type={mobile ? "drawer" : "modal"}
        width={mobile ? "100%" : 320}
        title="Update Hashtags"
        visible={hashtags}
        form={EditHashTags}
        formType="hashtags"
      />

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0 },
        }}
        type={mobile ? "drawer" : "modal"}
        width={mobile ? "100%" : 320}
        title="Re-Schedule Delivery"
        visible={updateDeliveryDate}
        form={UpdateDeliveryDate}
        formType="updateDeliveryDate"
      />

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0 },
        }}
        type={mobile ? "drawer" : "modal"}
        width={mobile ? "100%" : 320}
        title="Schedule Estimate Date and Time"
        visible={scheduleEstimate}
        form={ScheduleEstimate}
        formType="scheduleEstimate"
      />

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0 },
        }}
        type={mobile ? "drawer" : "modal"}
        width={mobile ? "100%" : 320}
        title="Compose Email"
        visible={email}
        form={ComposeEmail}
        formType="email"
        zIndex={4001}
      />

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0 },
        }}
        type={mobile ? "drawer" : "modal"}
        width={mobile ? "100%" : 320}
        title="Action"
        visible={action}
        form={Action}
        formType="action"
        zIndex={4001}
      />

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0 },
        }}
        type={mobile ? "drawer" : "modal"}
        width={mobile ? "100%" : 320}
        title="Create Scope"
        visible={scope}
        form={Scope}
        formType="scope"
        zIndex={4001}
      />

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0 },
        }}
        type={mobile ? "drawer" : "modal"}
        width={mobile ? "100%" : 320}
        title="Expense"
        visible={expense}
        form={Expense}
        formType="expense"
      />

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0 },
        }}
        type={mobile ? "drawer" : "modal"}
        width={mobile ? "100%" : 320}
        title="Collection"
        visible={collection}
        form={Collection}
        formType="collection"
      />

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0 },
        }}
        type={mobile ? "drawer" : "modal"}
        width={mobile ? "100%" : 320}
        title="Work Request"
        visible={workRequest}
        form={WorkRequest}
        formType="workRequest"
        zIndex={4100}
      />

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0 },
        }}
        type={mobile ? "drawer" : "modal"}
        width={mobile ? "100%" : 320}
        title="Work Request"
        visible={workRequestRoof}
        form={WorkRequestRoof}
        formType="workRequestRoof"
        zIndex={4100}
      />

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0 },
        }}
        type={mobile ? "drawer" : "modal"}
        width={mobile ? "100%" : 320}
        title="Project Closeout"
        visible={projectCloseout}
        form={ProjectCloseout}
        formType="projectCloseout"
      />

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0 },
        }}
        type={mobile ? "drawer" : "modal"}
        width={mobile ? "100%" : 320}
        title="Request Mateiral Pickup"
        visible={materialPickup}
        form={MaterialPickup}
        formType="materialPickup"
      />

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0 },
        }}
        type={mobile ? "drawer" : "modal"}
        width={mobile ? "100%" : 320}
        title="Project Hold Reason"
        visible={onHold}
        form={OnHold}
        formType="onHold"
      />

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0 },
        }}
        type={mobile ? "drawer" : "modal"}
        width={mobile ? "100%" : 320}
        title="Project Cancellation Reason"
        visible={projectCancelled}
        form={ProjectCancelled}
        formType="projectCancelled"
      />

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0 },
        }}
        type={mobile ? "drawer" : "modal"}
        width={mobile ? "100%" : 320}
        title="Update Scope"
        visible={updateScope}
        form={UpdateScope}
        formType="updateScope"
      />

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0 },
        }}
        type={mobile ? "drawer" : "modal"}
        width={mobile ? "100%" : 320}
        title="Create Event"
        visible={createEvent}
        form={CreateEvent}
        formType="createEvent"
      />

      <FormContainer
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px - 55px)",
            overflow: "auto",
          },
          width: 450,
          maxHeight: "calc(100vh - 100px",
          main: { top: 50, paddingBottom: 0 },
        }}
        type={mobile ? "drawer" : "modal"}
        width={mobile ? "100%" : 320}
        title="Create Lead"
        visible={createLead}
        form={CreateLead}
        formType="createLead"
      />

      <FormContainer
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px - 55px)",
            overflow: "auto",
          },
          width: 450,
          maxHeight: "calc(100vh - 100px",
          main: { top: 50, paddingBottom: 0 },
        }}
        type={mobile ? "drawer" : "modal"}
        width={mobile ? "100%" : 320}
        title="Create Project"
        visible={createProject}
        form={CreateProject}
        formType="createProject"
      />

      <FormContainer
        type={mobile ? "drawer" : "modal"}
        width={mobile ? "100%" : 320}
        title="Invoice"
        visible={invoice}
        form={Invoice}
        formType="invoice"
      />

      <FormContainer
        type={mobile ? "drawer" : "modal"}
        width={mobile ? "100%" : 320}
        title="Edit Receipt"
        visible={receipt}
        form={Receipt}
        formType="receipt"
      />

      <FormContainer
        type={mobile ? "drawer" : "modal"}
        width={mobile ? "100%" : 320}
        title="Invoice Summary"
        visible={completionCertificate}
        form={CompletionCertificate}
        formType="completionCertificate"
      />

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0 },
        }}
        type={mobile ? "drawer" : "modal"}
        width={mobile ? "100%" : 320}
        visible={builder}
        form={Builder}
        formType="builder"
      />

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0, top: "unset" },
        }}
        type={mobile ? "drawer" : "modal"}
        width={mobile ? "100%" : 320}
        title="Requests"
        visible={requests}
        form={Requests}
        formType="requests"
      />

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0 },
        }}
        type={mobile ? "drawer" : "modal"}
        width={mobile ? "100%" : 320}
        title="Upload Document"
        visible={documentForm}
        form={Document}
        formType="documentForm"
      />

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0 },
        }}
        type={mobile ? "drawer" : "modal"}
        width={mobile ? "100%" : 320}
        title="Manage Dumpster"
        visible={dumping}
        form={ManageDumpster}
        formType="dumping"
      />

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0, overflow: "hidden" },
        }}
        type={mobile ? "drawer" : "modal"}
        width={mobile ? "100%" : 320}
        title="Pre-Job Inspection"
        visible={preJobInspection}
        form={PreJobInspection}
        formType="preJobInspection"
      />

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0, overflow: "hidden" },
        }}
        type={mobile ? "drawer" : "modal"}
        width={mobile ? "100%" : 320}
        title="Create To-do"
        visible={installTodo}
        form={InstallTodo}
        formType="installTodo"
      />

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0, overflow: "hidden" },
        }}
        type={mobile ? "drawer" : "modal"}
        width={mobile ? "100%" : 320}
        title="Create To-do"
        visible={installTodoNonPo}
        form={InstallTodoNonPo}
        formType="installTodoNonPo"
      />

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0, overflow: "hidden" },
        }}
        type={mobile ? "drawer" : "modal"}
        width={mobile ? "100%" : 320}
        title="Change To-do Image"
        visible={installTodoChangeImage}
        form={InstallTodoUpload}
        formType="installTodoChangeImage"
      />

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0, overflow: "hidden" },
        }}
        type={mobile ? "drawer" : "modal"}
        width={mobile ? "100%" : 320}
        title="Create User"
        visible={user}
        form={User}
        formType="user"
      />

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0, overflow: "hidden" },
        }}
        type={mobile ? "drawer" : "modal"}
        width={mobile ? "100%" : 320}
        title="Create Company Expense"
        visible={companyExpense}
        form={CompanyExpense}
        formType="companyExpense"
      />

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0, overflow: "hidden" },
        }}
        type={mobile ? "drawer" : "modal"}
        width={mobile ? "100%" : 320}
        title="Create Draw Activity"
        visible={drawActivity}
        form={DrawActivity}
        formType="drawActivity"
      />

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0, overflow: "hidden" },
        }}
        type={mobile ? "drawer" : "modal"}
        width={mobile ? "100%" : 320}
        title="Create Marketing Activity"
        visible={marketingActivity}
        form={MarketingActivity}
        formType="marketingActivity"
      />

      <FormContainer
        type={mobile ? "drawer" : "modal"}
        width={mobile ? "100%" : 320}
        title="Create Breakdown"
        visible={needsBrokedown}
        form={NeedsBrokedown}
        formType="needsBrokedown"
      />

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0 },
        }}
        type={mobile ? "drawer" : "modal"}
        width={mobile ? "100%" : 320}
        title="Create Company Post"
        visible={companyPost}
        form={CompanyPost}
        formType="companyPost"
        zIndex={4500}
      />

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0 },
        }}
        type={mobile ? "drawer" : "modal"}
        width={mobile ? "100%" : 320}
        title="Office Review Note"
        visible={officeReviewNote}
        form={OfficeReviewNote}
        formType="officeReviewNote"
        zIndex={4500}
      />

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0 },
        }}
        type={mobile ? "drawer" : "modal"}
        width={mobile ? "100%" : 320}
        title="Mold Review Note"
        visible={moldReviewNote}
        form={MoldReviewNote}
        formType="moldReviewNote"
        zIndex={4500}
      />
    </>
  );
};

export default Forms;
