import React, { useEffect, useState } from "react";
import api from "API";
import styles from "./styles.module.scss";
import Column from "./Column";

const RoofingOverview = () => {
  const [tags, setTags] = useState({
    "Order Aerial": [],
    "Aerial Verification": [],
    "On-site Check": [],
    "Mold Review": [],
    Permit: [],
    "Office Review": [],
    "Ready For Material": [],
    "Ready For Dumpster": [],
    "Ready For Schedule": [],
    "Work Scheduled": [],
    "Post-Job Inspection": [],
  });
  const [scrollContainer, setScrollContainer] = useState(null);

  useEffect(() => {
    fetchTags();
  }, []);

  const fetchTags = async () => {
    try {
      const _tags = await api.post("/scopes/fetchOverview", {});
      setTags(_tags.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <>
      <div className={styles.overviewContainer} ref={setScrollContainer}>
        {Object.entries(tags).map(([key, value]) => (
          <Column
            key={key}
            title={key}
            tags={tags[key]}
            scrollContainer={scrollContainer}
          />
        ))}
      </div>
    </>
  );
};

export default RoofingOverview;
