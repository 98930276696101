import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Form from "antd/es/form";
import Radio from "antd/es/radio";
import Button from "antd/es/button";
import message from "antd/es/message";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import api from "API";
import styles from "./styles.module.scss";
import Modal from "antd/es/modal";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";
import CancelButton from "Components/CancelButton";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Divider from "antd/es/divider";
import Phone from "Components/Inputs/Phone";
import Card from "antd/es/card";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";

const messageKey = "messageKey";

const PermitAuthority = ({
  unsavedChanges,
  setUnsavedChanges,
  handleClose,
  setTitle,
  type,
}) => {
  const formDetails = useSelector(
    (state) => state.formState.permitAuthority.formDetails
  );
  const [loading, setLoading] = useState(false);
  const [editing] = useState(formDetails.editing ? formDetails.editing : false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (!formDetails.editing) {
      setTitle("Add Municipality");
    } else {
      setTitle(
        <div style={{ marginRight: 28 }}>
          Edit Municipality{" "}
          <Button
            size="small"
            danger
            onClick={confirmDelete}
            style={{ float: "right" }}
          >
            Delete
          </Button>
        </div>
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onFinish = async (values) => {
    try {
      setLoading(true);
      // message.loading("Saving municipality...", 0);
      message.loading({
        content: "Saving municipality...",
        duration: 0,
        key: messageKey,
      });
      let res;
      if (!editing) {
        res = await api.post("/permits/create-location", values);
      } else {
        values._id = formDetails._id;
        res = await api.post("/permits/edit-location", values);
      }

      if (formDetails.update) {
        formDetails.update(res.data._municipalities);
      }

      setUnsavedChanges(false);

      // message.success(!editing ? "Municipality added" : "Municipality updated");
      message.success({
        content: !editing ? "Municipality added" : "Municipality updated",
        duration: 1.5,
        key: messageKey,
      });
      handleClose(true);
    } catch (err) {
      setLoading(false);
      // message.error(
      //   !editing ? "Error adding municipality" : "Error updating municipality"
      // );
      message.error({
        content: !editing
          ? "Error adding municipality"
          : "Error updating municipality",
        duration: 1.5,
        key: messageKey,
      });
    }
  };

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error(
      !editing ? "Error adding municipality" : "Error updating municipality"
    );
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  const cancel = () => {
    handleClose();
  };

  const confirmDelete = () => {
    Modal.confirm({
      zIndex: 4600,
      icon: <QuestionCircleOutlined />,
      centered: true,
      cancelText: "Cancel",
      okText: "Delete",
      okButtonProps: { danger: true },
      content: <div>Are you sure you want to delete this municipality?</div>,
      onOk() {
        return new Promise((resolve, reject) => {
          deleteMunicipality(() => resolve(true));
        }).catch((err) => console.log("err: ", err));
      },
      onCancel() {},
    });
  };

  const deleteMunicipality = async (cb) => {
    try {
      const res = await api.delete(
        `/permits/delete-location/${formDetails._id}`
      );

      if (formDetails.update) formDetails.update(res.data._municipalities);

      cb();
      handleClose(true);
    } catch (err) {
      console.log("err", err);
      message.error("Error deleting action");
    }
  };

  return (
    <Form
      form={form}
      className="form"
      autoComplete="off"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      initialValues={{ ...formDetails }}
    >
      <RemoveAutocomplete />

      <FormBody
        className="content-inner"
        style={{
          padding: 24,
          maxHeight: type === "modal" ? "calc(100vh - 161px)" : "unset",
        }}
      >
        <Form.Item
          name="location"
          label="Location"
          rules={[{ required: true, message: "Location must be provided" }]}
        >
          <InputNoAutoComplete id="location" />
        </Form.Item>

        <Form.Item
          className={styles.radioGroup}
          name="required"
          label="Required"
          rules={[
            { required: true, message: "Must select required or not required" },
          ]}
        >
          <Radio.Group optionType="button" buttonStyle="solid">
            <Radio.Button value={true}>Required</Radio.Button>
            <Radio.Button value={false}>Not Required</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item name="sendTo" label="Send To">
          <InputNoAutoComplete id="sendTo" />
        </Form.Item>

        <Divider />

        <Form.List name="amount">
          {(fields, { add, remove }) => {
            const handleAdd = () => {
              add();
            };

            const handleRemove = (index) => {
              remove(index);
            };

            return (
              <>
                {fields.map((field, i) => (
                  <Card
                    size="small"
                    title={`Amount Line Item ${i + 1}`}
                    className="z-depth-1"
                    key={`${field.key}-amount`}
                    style={{ marginBottom: 8 }}
                    extra={
                      <Button
                        danger
                        onClick={() => handleRemove(i)}
                        size="small"
                      >
                        Remove
                      </Button>
                    }
                  >
                    <Row gutter={16}>
                      <Col xs={12}>
                        <Form.Item
                          {...field}
                          name={[field.name, "type"]}
                          label="Type"
                          rules={[
                            {
                              required: true,
                              message: "Type is required",
                            },
                          ]}
                        >
                          <InputNoAutoComplete id={[field.name, "type"]} />
                        </Form.Item>
                      </Col>
                      <Col xs={12}>
                        <Form.Item
                          {...field}
                          name={[field.name, "amount"]}
                          label="Amount"
                        >
                          <InputNoAutoComplete id={[field.name, "amount"]} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={24}>
                        <Form.Item
                          {...field}
                          name={[field.name, "feeSchedule"]}
                          label="Fee Schedule"
                        >
                          <InputNoAutoComplete
                            id={[field.name, "feeSchedule"]}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                ))}

                <Button
                  style={{
                    marginTop: fields.length > 0 ? 16 : 0,
                    marginBottom: 24,
                  }}
                  type="primary"
                  className="green"
                  onClick={handleAdd}
                  size="small"
                  block
                >
                  Add Amount
                </Button>
              </>
            );
          }}
        </Form.List>

        <Form.Item name="checkTo" label="Check To">
          <InputNoAutoComplete id="checkTo" />
        </Form.Item>

        <Divider />

        <Form.List name="instructions">
          {(fields, { add, remove }) => {
            const handleAdd = () => {
              add();
            };

            const handleRemove = () => {
              remove(fields.length - 1);
            };

            return (
              <>
                {fields.map((field, i) => (
                  <Card
                    size="small"
                    title={`Instructions Line Item ${i + 1}`}
                    className="z-depth-1"
                    key={`${field.key}-instructions`}
                    style={{ marginBottom: 8 }}
                    extra={
                      <Button
                        danger
                        onClick={() => handleRemove(i)}
                        size="small"
                      >
                        Remove
                      </Button>
                    }
                  >
                    <Row gutter={16}>
                      <Col xs={24}>
                        <Form.Item
                          {...field}
                          name={[field.name, "text"]}
                          label="Text"
                          rules={[
                            {
                              required: true,
                              message: "Text is required",
                            },
                          ]}
                        >
                          <InputNoAutoComplete id={[field.name, "text"]} />
                        </Form.Item>
                      </Col>
                      <Col xs={24}>
                        <Form.Item
                          {...field}
                          name={[field.name, "url"]}
                          label="URL"
                        >
                          <InputNoAutoComplete id={[field.name, "url"]} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                ))}

                <Button
                  style={{ marginTop: fields.length > 0 ? 16 : 0 }}
                  type="primary"
                  className="green"
                  onClick={handleAdd}
                  size="small"
                  block
                >
                  Add Instruction
                </Button>
              </>
            );
          }}
        </Form.List>

        <Divider />

        <Form.List name="inspectors">
          {(fields, { add, remove }) => {
            const handleAdd = () => {
              add();
            };

            const handleRemove = () => {
              remove(fields.length - 1);
            };

            return (
              <>
                {fields.map((field, i) => (
                  <Card
                    size="small"
                    title={`Inspectors Line Item ${i + 1}`}
                    className="z-depth-1"
                    key={`${field.key}-inspectors`}
                    style={{ marginBottom: 8 }}
                    extra={
                      <Button
                        danger
                        onClick={() => handleRemove(field.fieldKey)}
                        size="small"
                      >
                        Remove
                      </Button>
                    }
                  >
                    <Row gutter={16}>
                      <Col xs={24}>
                        <Form.Item
                          {...field}
                          name={[field.name, "name"]}
                          label="Name"
                          rules={[
                            {
                              required: true,
                              message: "Name is required",
                            },
                          ]}
                        >
                          <InputNoAutoComplete id={[field.name, "name"]} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={12}>
                        <Form.Item
                          {...field}
                          name={[field.name, "area"]}
                          label="Area"
                        >
                          <InputNoAutoComplete id={[field.name, "area"]} />
                        </Form.Item>
                      </Col>
                      <Col xs={12}>
                        <Form.Item
                          {...field}
                          name={[field.name, "phone"]}
                          label="Phone"
                          rules={[
                            { required: true, message: "Phone is required" },
                          ]}
                        >
                          <Phone
                            autoComplete="dont-use-autocomplete"
                            id={[field.name, "phone"]}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                ))}

                <Button
                  style={{ marginTop: fields.length > 0 ? 16 : 0 }}
                  type="primary"
                  className="green"
                  onClick={handleAdd}
                  size="small"
                  block
                >
                  Add Inspector
                </Button>
              </>
            );
          }}
        </Form.List>
      </FormBody>

      <FormControls className="sticky-footer">
        <CancelButton handleClick={cancel} />
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={loading || !unsavedChanges}
        >
          Save
        </Button>
      </FormControls>
    </Form>
  );
};

export default PermitAuthority;
