import React, { useState, useCallback, useEffect } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateDetail } from "Actions/dataActions";
import { fetchTable } from "Actions/tableActions";
import Form from "antd/es/form";
import message from "antd/es/message";
import Button from "antd/es/button";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import InputCurrency from "Components/Inputs/Currency";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import api from "API";
import CancelButton from "Components/CancelButton";
import Select from "Components/Inputs/Select";
import Scopes from "Components/Inputs/Scopes";
import currencyFormatter from "Utils/currencyFormatter";
import Divider from "antd/es/divider";
import List from "antd/es/list";
import Modal from "antd/es/modal";
import Input from "antd/es/input";
import DatePicker from "Components/Inputs/DatePicker";
// import useIsMobile from "Hooks/useIsMobile";
import useIsMobile from "Hooks/useIsMobile";

const messageKey = "messageKey";

const Estimate = ({ unsavedChanges, setUnsavedChanges, handleClose, type }) => {
  const dispatch = useDispatch();
  const mobile = useIsMobile();
  const formDetails = useSelector(
    (state) => state.formState.estimate.formDetails
  );
  const viewableActivities = useSelector(
    (state) => state.dataState.viewableActivities
  );
  const table = useSelector((state) => state.tableState.table);
  const col = useSelector((state) => state.tableState.col);
  const order = useSelector((state) => state.tableState.order);
  const search = useSelector((state) => state.tableState.search);
  const activeFilter = useSelector((state) => state.tableState.activeFilter);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [scopesTotal, setScopesTotal] = useState(0);
  const [helpMessage, setHelpMessage] = useState(undefined);
  const [scopes, setScopes] = useState([]);
  const [result, setResult] = useState(undefined);
  const [visible, setVisible] = useState(false);
  // const mobile = useIsMobile();
  const [lossReasons, setLossReasons] = useState([]);

  useEffect(() => {
    getReasons();
  }, []);

  async function getReasons() {
    try {
      const res = await api.post("/dropdowns/get", {
        selects: ["Lead Lost Reasons"],
      });

      setLossReasons(res.data["Lead Lost Reasons"].options);
    } catch (err) {
      console.log("err", err);
    }
  }

  useEffect(() => {
    setScopes(formDetails.scopes);
  }, [formDetails.scopes]);

  const onFinish = useCallback(
    async (values) => {
      try {
        setLoading(true);

        message.loading({
          content: "Submitting estimate results...",
          duration: 0,
          key: messageKey,
        });
        values._id = formDetails._id;

        const res = await api.post("/leads/estimate", values);

        dispatch(
          updateDetail({
            viewableActivities,
            key: "details",
            value: res.data.details,
            activities: res.data.activities,
          })
        );

        dispatch(
          fetchTable({
            table,
            col,
            order,
            search,
            activeFilter,
            fetching: false,
          })
        );

        message.success({
          content: "Estimate results have been submitted",
          duration: 1.5,
          key: messageKey,
        });
        handleClose(true);
      } catch (err) {
        setLoading(false);
        // message.error("Error converting the Lead to an Estimate");
        message.error({
          content: "Error submitting results",
          duration: 1.5,
          key: messageKey,
        });
        console.log("err", err);
      }
    },
    [
      formDetails._id,
      dispatch,
      table,
      col,
      order,
      search,
      activeFilter,
      handleClose,
      viewableActivities,
    ]
  );

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error converting the Lead to an Estimate");
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  const validateAmount = () => {
    const amount = form.getFieldValue("amount");
    if (amount) {
      if (amount - scopesTotal !== 0) {
        return "error";
      }
    }
    return undefined;
  };

  const renderHelpMessage = useCallback(() => {
    const amount = form.getFieldValue("amount");
    if (amount || amount === 0) {
      if (amount - scopesTotal !== 0) {
        setHelpMessage(
          `${currencyFormatter(amount - scopesTotal)} to be allocated`
        );
      } else {
        setHelpMessage(undefined);
      }
    } else {
      setHelpMessage(undefined);
    }
  }, [form, scopesTotal]);

  useEffect(() => {
    renderHelpMessage();
  }, [scopesTotal, renderHelpMessage]);

  const scopeAmountChange = async () => {
    try {
      const amounts = await form.getFieldValue("scopeAllocations");
      let total = 0;
      for (const a of amounts) {
        if (a) {
          total += a;
        }
      }
      setScopesTotal(total);
    } catch (err) {
      console.log("scopeAmountChange err", err);
    }
  };

  const amountChange = async (value) => {
    try {
      const amounts = await form.getFieldValue("scopeAllocations");
      if (scopes && scopes.length === 1) {
        amounts[0] = value;
        setScopesTotal(value);
      } else {
        Object.entries(amounts).map(([key, value]) => {
          form.validateFields([key]);
          return value;
        });

        renderHelpMessage();
      }
    } catch (err) {
      console.log("amount change err", err);
    }
  };

  const handleScopeChange = async (value) => {
    try {
      const scopeAllocations = await form.getFieldValue("scopeAllocations");
      if (value && value.length > scopes.length) {
        scopeAllocations.push(0);
        unstable_batchedUpdates(() => {
          setScopes(value);
          form.setFieldsValue({
            scopeAllocations,
          });
        });
      } else {
        scopeAllocations.pop();
        unstable_batchedUpdates(() => {
          setScopes(value);
          form.setFieldsValue({
            scopeAllocations,
          });
        });
      }
    } catch (err) {
      console.log("handleScopeChange err", err);
    }
  };

  const updateResult = (value) => {
    setResult(value);
  };

  const handleOpen = () => {
    setVisible(true);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      className="form"
      initialValues={{
        scopes: formDetails.scopes,
        scopeAllocations: Array(
          formDetails.scopes ? formDetails.scopes.length : 0
        ).fill(0),
      }}
    >
      <RemoveAutocomplete />

      <FormBody
        className="content-inner"
        style={{
          paddingLeft: 24,
          paddingTop: 24,
          paddingRight: 24,
          paddingBottom: 8,
          maxHeight: type === "modal" ? "calc(100vh - 161px)" : "unset",
        }}
      >
        <div className="ant-col ant-form-item-label" style={{ width: "100%" }}>
          <Form.Item
            label="Estimate Date"
            name="estimateDate"
            rules={[{ required: true, message: "Estimate Date is required" }]}
          >
            <DatePicker
              placeholder={""}
              format="MMMM DD, YYYY"
              inputReadOnly={true}
              getPopupContainer={(trigger) =>
                !mobile
                  ? trigger.parentNode.parentNode.parentNode.parentNode
                      .parentNode.parentNode
                  : document.body
              }
              getCalendarContainer={(trigger) =>
                !mobile
                  ? trigger.parentNode.parentNode.parentNode.parentNode
                      .parentNode.parentNode
                  : document.body
              }
            />
          </Form.Item>

          <label
            htmlFor="scopes"
            className="ant-form-item-required"
            title="Scopes"
          >
            Estimate Result
          </label>
          <Button
            onClick={handleOpen}
            size="small"
            type="primary"
            style={{ float: "right" }}
          >
            Explanation
          </Button>
        </div>

        <ExplanationModal visible={visible} setVisible={setVisible} />

        <Form.Item
          name="estimateResult"
          rules={[{ required: true, message: "Estimate Result is required" }]}
        >
          <Select
            options={[
              "Lead Needs Remeet",
              "Lead Insurance WIP",
              "Estimate DOA",
              "Estimate WIP",
              "Estimate Next Year",
              "Estimate Sold",
              "Estimate Not Sold",
            ]}
            id="estimateResult"
            onChange={updateResult}
          />
        </Form.Item>

        <Form.Item
          name="scopes"
          label="Scopes"
          rules={[
            {
              required: true,
              message: "Must select at least 1 scopes",
              type: "array",
            },
          ]}
        >
          <Scopes id="scopes" onChange={handleScopeChange} />
        </Form.Item>

        <Form.Item
          hidden={
            result === "Lead Needs Remeet" || result === "Lead Insurance WIP"
          }
          name="amount"
          label="Estimate/Contract Value"
          rules={
            result !== "Lead Needs Remeet" && result !== "Lead Insurance WIP"
              ? [
                  {
                    required: true,
                    message: "Estimate/Contract Value is required",
                  },
                ]
              : []
          }
        >
          <InputCurrency onChange={amountChange} />
        </Form.Item>

        <Divider
          style={{
            display:
              result === "Lead Needs Remeet" || result === "Lead Insurance WIP"
                ? "none"
                : "block",
          }}
        >
          Scope Allocation
        </Divider>

        <Form.List name="scopeAllocations">
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map((field, index) => (
                  <div key={field.fieldKey}>
                    <Form.Item
                      hidden={
                        result === "Lead Needs Remeet" ||
                        result === "Lead Insurance WIP"
                      }
                      {...field}
                      name={[field.name]}
                      fieldKey={[field.fieldKey]}
                      key={[field.fieldKey]}
                      label={
                        scopes && scopes.length > 0 && scopes[index]
                          ? `${scopes[index]} Allocation`
                          : `Scope Allocation`
                      }
                      validateStatus={validateAmount()}
                      help={helpMessage}
                      rules={
                        result !== "Lead Needs Remeet" &&
                        result !== "Lead Insurance WIP"
                          ? [
                              {
                                required: true,
                                message: "Scope Allocation is required",
                              },
                            ]
                          : []
                      }
                    >
                      <InputCurrency
                        id={[field.name]}
                        onChange={scopeAmountChange}
                      />
                    </Form.Item>
                  </div>
                ))}
              </>
            );
          }}
        </Form.List>

        <Form.Item
          hidden={result !== "Estimate Not Sold"}
          name="leadLostReason"
          label="Loss Reason"
          rules={
            result === "Estimate Not Sold"
              ? [{ required: true, message: "Lost reason is required" }]
              : []
          }
        >
          <Select options={lossReasons} id="leadLostReason" />
        </Form.Item>

        <Form.Item name="notes" label="Notes">
          <Input.TextArea autoComplete="lead-notes" type="text" rows={4} />
        </Form.Item>

        {/* <Form.Item
          hidden={result !== "Estimate Not Sold"}
          name="leadLostReason"
          label="Loss Reason"
          rules={[{ required: true, message: "Lost reason is required" }]}
        >
          <Select
            autoFocus
            getPopupContainer={(trigger) =>
              !mobile
                ? trigger.parentNode.parentNode.parentNode.parentNode.parentNode
                    .parentNode.parentNode
                : document.body
            }
            showSearch={!mobile}
            dropdownClassName={mobile ? "isMobile" : null}
          >
            {lossReasons.map((reason) => {
              return (
                <Select.Option key={reason} value={reason}>
                  {reason}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item> */}
      </FormBody>
      <FormControls className="sticky-footer">
        <CancelButton handleClick={handleClose} />
        <Form.Item style={{ margin: 0 }}>
          <Button
            htmlType="submit"
            type="primary"
            loading={loading}
            disabled={loading || !unsavedChanges}
          >
            Submit
          </Button>
        </Form.Item>
      </FormControls>
    </Form>
  );
};

const ExplanationModal = ({ visible, setVisible }) => {
  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      visible={visible}
      onCancel={handleCancel}
      zIndex={4100}
      footer={false}
    >
      <List>
        <List.Item>
          <List.Item.Meta
            title="Lead Needs Remeet:"
            description="An Estimate was not provided and a second appointment needs
                    to be scheduled to provide one."
          />
        </List.Item>
        <List.Item>
          <List.Item.Meta
            title="Lead Insurance WIP:"
            description="An Estimate was not provided and a meeting with an adjuster or pricing is still needing to be agreed upon."
          />
        </List.Item>
        <List.Item>
          <List.Item.Meta
            title="Estimate DOA:"
            description="An Estimate was provided but you do not have a good feeling
                    about the customer and would like rehash emailing to
                    commence."
          />
        </List.Item>
        <List.Item>
          <List.Item.Meta
            title="Estimate WIP:"
            description="An Estimate was provided and you have a good feeling about
                    the customer and you know you will stay in active contact
                    with until a decision is made."
          />
        </List.Item>
        <List.Item>
          <List.Item.Meta
            title="Estimate Next Year:"
            description="An Estimate was provided but they are getting prices to save
                    for next year. Email marketing will be sent out about
                    financing and email marketing will start up again after 10
                    months has passed."
          />
        </List.Item>
        <List.Item>
          <List.Item.Meta
            title="Estimate Sold:"
            description="An Estimate was provided and was sold on the spot."
          />
        </List.Item>
        <List.Item>
          <List.Item.Meta
            title="Estimate Not Sold:"
            description="An Estimate was provided but you do not have a good feeling
                    about the project or the customer may not be a good fit to
                    work with. This should rarely be used."
          />
        </List.Item>
      </List>
    </Modal>
  );
};

export default Estimate;
