import React, { useState, useRef, useEffect } from "react";
import api from "API";
import { useNavigate } from "react-router-dom";
import AutoComplete from "antd/es/auto-complete";
import Input from "antd/es/input";
import Select from "antd/es/select";
import message from "antd/es/message";
import SearchOutlined from "@ant-design/icons/SearchOutlined";
import Group from "Icons/Group";
import Build from "Icons/Build";
import styles from "./styles.module.scss";
import isLead from "Utils/isLead";
import Form from "antd/es/form";

const { OptGroup } = Select;
const { Option } = AutoComplete;
let searchTimeout;

const GlobalSearch = ({ leads = true, projects = true }) => {
  const navigate = useNavigate();
  const [results, setResults] = useState([]);
  const [focus, setFocus] = useState(false);

  const [form] = Form.useForm();

  const ref = useRef();
  const inputRef = useRef();

  useEffect(() => {
    return () => clearTimeout(searchTimeout);
  }, []);

  const handleSearch = (_search) => {
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(async () => {
      try {
        const res = await api.post(`/globalSearch`, {
          search: _search,
          leads,
          projects,
        });
        if (res.status !== 200) {
          message.error("Error searching everything");
        } else {
          setResults(res.data);
        }
      } catch (err) {
        console.log("err", err);
      }
    }, 300);
  };

  function onSelect(e, o) {
    if (o.props._id) {
      if (o.props.type !== "last viewed") {
        navigate(`/${o.props.type}/view/details/${o.props._id}`);
      } else {
        if (isLead(o.props.projectstatus)) {
          navigate(`/leads/view/details/${o.props._id}`);
        } else {
          navigate(`/projects/view/details/${o.props._id}`);
        }
      }
    }

    setFocus(false);
    setResults([]);
    inputRef.current.blur();
    form.resetFields();
  }

  function onBlur() {
    setFocus(false);
    setResults([]);
    form.resetFields();
  }

  function onFocus() {
    setFocus(true);
    handleSearch("");
  }

  function onDropdownVisibleChange(e) {
    if (!e) {
      setResults([]);
    }
  }

  const options = results.map((group, i) => (
    <OptGroup key={group.title} label={group.title}>
      {group.children.length > 0 ? (
        group.children.map((opt) => (
          <Option
            key={`${group.title}-${opt._id}`}
            _id={opt._id}
            type={group.title}
            projectstatus={opt.projectStatus}
            value={`${opt.customerId} - ${opt.customerName}`}
          >
            <div className={styles.globalSearchDropResult}>
              <div className="gsLeft">
                {isLead(opt.projectStatus) ? (
                  <Group className="gsIcon" />
                ) : (
                  <Build className="gsIcon" />
                )}
              </div>
              <div className="gsRight">
                <div className="gsTopLine">{`${opt.customerId} - ${
                  opt.companyName
                    ? `${opt.companyName} (${opt.customerName})`
                    : opt.customerName
                }`}</div>
                <div>{opt.projectAddress}</div>
              </div>
            </div>
          </Option>
        ))
      ) : (
        <Option
          key={`no-results-${group.title}`}
          _id={undefined}
          disabled={true}
        >
          No {group.title.charAt(0).toUpperCase() + group.title.substring(1)}{" "}
          Match Your Search
        </Option>
      )}
    </OptGroup>
  ));

  return (
    <Form autoComplete="off" form={form}>
      <Form.Item name="global" style={{ margin: 0, lineHeight: "64px" }}>
        <AutoComplete
          ref={ref}
          id="globalSearchInput"
          className={`${styles.globalSearch} ${focus ? "focus" : ""}`}
          dropdownClassName={styles.globalSearchDropdown}
          dataSource={options}
          onSearch={handleSearch}
          onSelect={onSelect}
          onDropdownVisibleChange={onDropdownVisibleChange}
          onBlur={onBlur}
          onFocus={onFocus}
          defaultActiveFirstOption={false}
          virtual={false}
          autoComplete="dont-use-autocomplete"
        >
          <div>
            <label htmlFor="" style={{ display: "none" }}>
              hidden label to mislead chrome autocomplete
            </label>
            <Input
              ref={inputRef}
              allowClear
              size="large"
              placeholder="Search Everything"
              prefix={<SearchOutlined />}
              autoComplete="dont-use-autocomplete"
            />
          </div>
        </AutoComplete>
      </Form.Item>
    </Form>
  );
};

export default GlobalSearch;
