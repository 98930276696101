import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Form from "antd/es/form";
import Checkbox from "antd/es/checkbox";
import Typography from "antd/es/typography";
import message from "antd/es/message";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import CancelButton from "Components/CancelButton";
import Button from "antd/es/button";
import styles from "./styles.module.scss";
import Divider from "antd/es/divider";
import api from "API";
import UploadRow from "Components/images/UploadRow";
import Upload from "antd/es/upload";
import axios from "axios";
import generateFilePreview from "Utils/generateFilePreview";
import generateFileSize from "Utils/generateFileSize";
import List from "antd/es/list";
import isPdf from "Utils/isPdf";
import pdf from "Assets/pdf.svg";
import InboxOutlined from "@ant-design/icons/InboxOutlined";
import MarkUpHelper from "Components/MarkUpHelper";
import Input from "antd/es/input";

const messageKey = "messageKey";

const PostJobInspection = ({
  unsavedChanges,
  setUnsavedChanges,
  handleClose,
  type,
}) => {
  const formDetails = useSelector(
    (state) => state.formState.postJobInspection.formDetails
  );
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [_fileList, _setFileList] = useState([]);
  const [uploads, setUploads] = useState({});
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (uploading) {
      let _uploading = false;
      Object.keys(uploads).map((key) => {
        if (uploads[key].upload !== 100 && uploads[key].upload !== true) {
          _uploading = true;
          return key;
        }
        return key;
      });
      if (!_uploading) {
        setUploading(false);
      }
    }
  }, [uploads]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleBeforeUpload = async (file) => {
    try {
      setUploading(true);
      const size = await generateFileSize(file.size);
      let preview;
      if (isPdf(file.name)) {
        preview = pdf;
      } else {
        preview = await generateFilePreview(file);
      }
      setUploads((prv) => {
        return {
          ...prv,
          [file.uid]: {
            preview,
            name: file.name,
            size,
            failed: false,
            upload: 0,
          },
        };
      });
      return file;
    } catch (err) {
      console.log("beforeUpload err", err);
    }
  };

  const handleUpload = async ({ file, onProgress }) => {
    try {
      let fileName = `${new Date().getTime()}-${file.name.replace(
        /[^a-zA-Z0-9.]/gi,
        "_"
      )}`;
      const src = `https://s3.us-east-2.amazonaws.com/pr-crm/${formDetails._projectId}/activities/${fileName}`;
      let thumbnail;
      const ext = src.split(".").pop().toLowerCase();
      if (ext !== "pdf")
        thumbnail = `https://s3.us-east-2.amazonaws.com/pr-crm-thumbnail/${formDetails._projectId}/activities/${fileName}`;

      _setFileList((prevState) => [
        ...prevState,
        {
          originFileObj: file,
          _id: file.uid,
          uid: file.uid,
          name: file.name,
          mediaType: isPdf(src) ? "pdf" : "image",
          _projectId: formDetails._projectId,
          size: file.size,
          type: file.type,
          src,
          thumbnail,
        },
      ]);

      const res = await api.post("/activity/image-upload", {
        type: file.type,
        name: fileName,
        _projectId: formDetails._projectId,
      });
      await axios
        .put(res.data, file, {
          headers: {
            "Content-Type": file.type,
          },
          onUploadProgress: (e) => {
            onProgress(e);
          },
        })
        .catch(function (err) {
          console.log("upload err", err);

          setUploads((prv) => {
            return {
              ...prv,
              [file.uid]: {
                ...prv[file.uid],
                failed: true,
              },
            };
          });

          _setFileList((prevState) => {
            const _filteredFileList = prevState.filtered(
              (prv) => prv.uid !== file.uid
            );
            return _filteredFileList;
          });
        });
    } catch (err) {
      console.log("handleUpload err", err);
    }
  };

  const handleProgress = (progress, file) => {
    const _progress = parseInt((progress.loaded * 100) / progress.total, 10);
    setUploads((prv) => {
      return {
        ...prv,
        [file.uid]: {
          ...prv[file.uid],
          upload: _progress,
        },
      };
    });
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      // message.loading("Submitting inspection...", 0);
      message.loading({
        content: "Submitting inspection...",
        duration: 0,
        key: messageKey,
      });
      values._projectId = formDetails._projectId;
      values._scopeId = formDetails._scopeId;
      values.scopeName = formDetails.scopeName;
      values.images = _fileList;
      const res = await api.post("/post-job-inspection/submit", values);

      if (formDetails.callback) {
        formDetails.callback(res.data);
      }

      // message.success("Inspection submitted");
      message.success({
        content: "Inspection submitted",
        duration: 1.5,
        key: messageKey,
      });
      setLoading(false);
      handleClose(true);
    } catch (err) {
      setLoading(false);
      // message.error("Error submitting inspection");
      message.error({
        content: "Error submitting inspection",
        duration: 1.5,
        key: messageKey,
      });
      console.log("err", err);
    }
  };

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error submitting inspection");
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      className="form"
    >
      <RemoveAutocomplete />

      <FormBody
        className="content-inner"
        style={{
          paddingLeft: 24,
          paddingTop: 24,
          paddingRight: 24,
          paddingBottom: 8,
          maxHeight: type === "modal" ? "calc(100vh - 161px)" : "unset",
        }}
      >
        {formDetails.scopeName === "Siding" ? (
          <>
            <Form.Item
              name="images"
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload.Dragger
                multiple
                beforeUpload={handleBeforeUpload}
                customRequest={handleUpload}
                showUploadList={false}
                onProgress={handleProgress}
                accept="image/*, application/pdf"
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Support for a single or bulk upload.
                </p>
              </Upload.Dragger>
            </Form.Item>

            <Form.Item
              name="siding1"
              valuePropName="checked"
              className={styles.checkbox}
            >
              <Checkbox>No visible house wrap</Checkbox>
            </Form.Item>

            <Form.Item
              name="siding2"
              valuePropName="checked"
              className={styles.checkbox}
            >
              <Checkbox>Siding straight and level</Checkbox>
            </Form.Item>

            <Form.Item
              name="siding3"
              valuePropName="checked"
              className={styles.checkbox}
            >
              <Checkbox>Utility trim inside J-Channel where possible</Checkbox>
            </Form.Item>

            <Form.Item
              name="siding4"
              valuePropName="checked"
              className={styles.checkbox}
            >
              <Checkbox>Siding punched at utility trim</Checkbox>
            </Form.Item>

            <Form.Item
              name="siding5"
              valuePropName="checked"
              className={styles.checkbox}
            >
              <Checkbox>
                Overlapping joints 1"-1 1/2" and installed in a consistent
                direction
              </Checkbox>
            </Form.Item>

            <Form.Item
              name="siding6"
              valuePropName="checked"
              className={styles.checkbox}
            >
              <Checkbox>J-blocks level and even</Checkbox>
            </Form.Item>

            <Form.Item
              name="siding7"
              valuePropName="checked"
              className={styles.checkbox}
            >
              <Checkbox>Split blocks centered over outlets</Checkbox>
            </Form.Item>

            <Form.Item
              name="siding8"
              valuePropName="checked"
              className={styles.checkbox}
            >
              <Checkbox>Siding has room to expand and contract</Checkbox>
            </Form.Item>

            <Form.Item
              name="siding9"
              valuePropName="checked"
              className={styles.checkbox}
            >
              <Checkbox>Siding is clean</Checkbox>
            </Form.Item>

            <Form.Item
              name="siding10"
              valuePropName="checked"
              className={styles.checkbox}
            >
              <Checkbox>All vents are installed</Checkbox>
            </Form.Item>

            <Form.Item
              name="siding11"
              valuePropName="checked"
              className={styles.checkbox}
            >
              <Checkbox>Fascia covers soffit</Checkbox>
            </Form.Item>

            <Form.Item
              name="siding12"
              valuePropName="checked"
              className={styles.checkbox}
            >
              <Checkbox>Soffits and porch ceilings level</Checkbox>
            </Form.Item>

            <Form.Item
              name="siding13"
              valuePropName="checked"
              className={styles.checkbox}
            >
              <Checkbox>Bird boxes look good</Checkbox>
            </Form.Item>

            <Form.Item
              name="siding14"
              valuePropName="checked"
              className={styles.checkbox}
            >
              <Checkbox>Proper drip caps installed on natural trims</Checkbox>
            </Form.Item>

            <Form.Item
              name="siding15"
              valuePropName="checked"
              className={styles.checkbox}
            >
              <Checkbox>LP cuts are primed</Checkbox>
            </Form.Item>

            <Form.Item
              name="siding16"
              valuePropName="checked"
              className={styles.checkbox}
            >
              <Checkbox>Returns are neatly stacked</Checkbox>
            </Form.Item>

            <Form.Item
              name="comments"
              label={<MarkUpHelper title="Comments" />}
              labelCol={{ span: 24 }}
            >
              <Input.TextArea rows={4} />
            </Form.Item>

            {_fileList.length > 0 && (
              <List className={styles.uploadRow} bordered>
                {Object.keys(uploads).map((key) => {
                  return (
                    <UploadRow
                      key={key}
                      preview={uploads[key].preview}
                      name={uploads[key].name}
                      size={uploads[key].size}
                      upload={uploads[key].upload}
                      failed={uploads[key].failed}
                    />
                  );
                })}
              </List>
            )}
          </>
        ) : (
          <>
            <Form.Item
              name="images"
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload.Dragger
                multiple
                beforeUpload={handleBeforeUpload}
                customRequest={handleUpload}
                showUploadList={false}
                onProgress={handleProgress}
                accept="image/*, application/pdf"
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Support for a single or bulk upload.
                </p>
              </Upload.Dragger>
            </Form.Item>

            <Typography.Title level={5}>Walk Around</Typography.Title>
            <Form.Item
              name="wa1"
              valuePropName="checked"
              className={styles.checkbox}
            >
              <Checkbox>Look up at roof from ground for imperfections</Checkbox>
            </Form.Item>

            <Form.Item
              name="wa2"
              valuePropName="checked"
              className={styles.checkbox}
            >
              <Checkbox>
                Inspect siding, landscape & screens for damage
              </Checkbox>
            </Form.Item>

            <Divider />

            <Typography.Title level={5}>Flashings</Typography.Title>
            <Form.Item
              name="f1"
              valuePropName="checked"
              className={styles.checkbox}
            >
              <Checkbox>45's</Checkbox>
            </Form.Item>

            <Form.Item
              name="f2"
              valuePropName="checked"
              className={styles.checkbox}
            >
              <Checkbox>Chimney Flashing</Checkbox>
            </Form.Item>

            <Form.Item
              name="f3"
              valuePropName="checked"
              className={styles.checkbox}
            >
              <Checkbox>Kickouts</Checkbox>
            </Form.Item>

            <Form.Item
              name="f4"
              valuePropName="checked"
              className={styles.checkbox}
            >
              <Checkbox>Headwall Flashing</Checkbox>
            </Form.Item>

            <Form.Item
              name="f5"
              valuePropName="checked"
              className={styles.checkbox}
            >
              <Checkbox>Fascia Flashing</Checkbox>
            </Form.Item>

            <Form.Item
              name="f6"
              valuePropName="checked"
              className={styles.checkbox}
            >
              <Checkbox>Skylight Flashing</Checkbox>
            </Form.Item>

            <Divider />

            <Typography.Title level={5}>Ridgevent/Cap</Typography.Title>
            <Form.Item
              name="r1"
              valuePropName="checked"
              className={styles.checkbox}
            >
              <Checkbox>Check for starter cap</Checkbox>
            </Form.Item>

            <Form.Item
              name="r2"
              valuePropName="checked"
              className={styles.checkbox}
            >
              <Checkbox>Does ridgevent go end to end</Checkbox>
            </Form.Item>

            <Form.Item
              name="r3"
              valuePropName="checked"
              className={styles.checkbox}
            >
              <Checkbox>3" nails used</Checkbox>
            </Form.Item>

            <Form.Item
              name="r4"
              valuePropName="checked"
              className={styles.checkbox}
            >
              <Checkbox>
                {"Is cap installed North -> South | East -> West"}
              </Checkbox>
            </Form.Item>

            <Divider />

            <Typography.Title level={5}>Drip Edge</Typography.Title>
            <Form.Item
              name="d1"
              valuePropName="checked"
              className={styles.checkbox}
            >
              <Checkbox>Corners</Checkbox>
            </Form.Item>

            <Form.Item
              name="d2"
              valuePropName="checked"
              className={styles.checkbox}
            >
              <Checkbox>Bubbles on rake edge</Checkbox>
            </Form.Item>

            <Form.Item
              name="d3"
              valuePropName="checked"
              className={styles.checkbox}
            >
              <Checkbox>Cut around gutter spikes</Checkbox>
            </Form.Item>

            <Divider />

            <Typography.Title level={5}>Pipe Boots</Typography.Title>
            <Form.Item
              name="p1"
              valuePropName="checked"
              className={styles.checkbox}
            >
              <Checkbox>Relief cuts</Checkbox>
            </Form.Item>

            <Form.Item
              name="p2"
              valuePropName="checked"
              className={styles.checkbox}
            >
              <Checkbox>Caulk underneath</Checkbox>
            </Form.Item>

            <Divider />

            <Typography.Title level={5}>Miscellaneous</Typography.Title>
            <Form.Item
              name="m1"
              valuePropName="checked"
              className={styles.checkbox}
            >
              <Checkbox>Check nailing patterns</Checkbox>
            </Form.Item>

            <Form.Item
              name="m2"
              valuePropName="checked"
              className={styles.checkbox}
            >
              <Checkbox>Check for low nails & nails uncaulked</Checkbox>
            </Form.Item>

            <Form.Item
              name="m3"
              valuePropName="checked"
              className={styles.checkbox}
            >
              <Checkbox>Make sure gutters are cleaned out</Checkbox>
            </Form.Item>

            <Form.Item
              name="m4"
              valuePropName="checked"
              className={styles.checkbox}
            >
              <Checkbox>Call in material returns</Checkbox>
            </Form.Item>

            <Form.Item
              name="m5"
              valuePropName="checked"
              className={styles.checkbox}
            >
              <Checkbox>Check to make sure dumpster cleaned out</Checkbox>
            </Form.Item>

            <Form.Item
              name="m6"
              valuePropName="checked"
              className={styles.checkbox}
            >
              <Checkbox>
                Update MyCo Story with board count and extra expenses
              </Checkbox>
            </Form.Item>

            <Form.Item
              name="comments"
              label={<MarkUpHelper title="Comments" />}
              labelCol={{ span: 24 }}
            >
              <Input.TextArea rows={4} />
            </Form.Item>

            {_fileList.length > 0 && (
              <List className={styles.uploadRow} bordered>
                {Object.keys(uploads).map((key) => {
                  return (
                    <UploadRow
                      key={key}
                      preview={uploads[key].preview}
                      name={uploads[key].name}
                      size={uploads[key].size}
                      upload={uploads[key].upload}
                      failed={uploads[key].failed}
                    />
                  );
                })}
              </List>
            )}
          </>
        )}
      </FormBody>
      <FormControls className="sticky-footer">
        <CancelButton handleClick={handleClose} />
        <Form.Item style={{ margin: 0 }}>
          <Button
            htmlType="submit"
            type="primary"
            loading={loading}
            disabled={loading || !unsavedChanges}
          >
            Submit
          </Button>
        </Form.Item>
      </FormControls>
    </Form>
  );
};

export default PostJobInspection;
