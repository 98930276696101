import * as actions from "Actions/actionTypes";

const defaultState = {
  shareVisible: false,
  content: {},
  _activityId: undefined,
  _projectId: undefined,
  callback: undefined,
};

const shareReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actions.OPEN_SHARE:
      return {
        shareVisible: true,
        content: action.payload.content,
        _activityId: action.payload._activityId,
        _projectId: action.payload._projectId,
        callback: action.payload.callback,
      };
    case actions.CLOSE_SHARE:
      return {
        shareVisible: false,
        content: {},
        _activityId: undefined,
        _projectId: undefined,
        callback: undefined,
      };
    default:
      return state;
  }
};

export default shareReducer;
