import React, { useState, memo } from "react";
import Input from "antd/es/input";
import Form from "antd/es/form";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Typography from "antd/es/typography";
import Pitches from "Components/Inputs/Pitches";
import Select from "Components/Inputs/Select";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";

const GutterDetails = ({ _gutterProtection }) => {
  const [gutterProtection, setGutterProtection] = useState(_gutterProtection);

  const handleGutterProtectionChange = (value) => {
    setGutterProtection(value);
  };

  return (
    <>
      <Typography.Title level={4}>Project Details</Typography.Title>
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="pitches"
            label="Pitches"
            rules={[{ required: true, message: "Pitches is required" }]}
          >
            <Pitches />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="stories"
            label="Stories"
            rules={[{ required: true, message: "Stories is required" }]}
          >
            <InputNoAutoComplete id="stories" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="fasteningType"
            label="Fastening Type"
            rules={[{ required: true, message: "Fastening Type is required" }]}
          >
            <Select
              options={["Spikes", "Hangers", "Other", "N/A"]}
              id="fasteningType"
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="facia"
            label="Facia Type"
            rules={[{ required: true, message: "Facia Type is required" }]}
          >
            <Select
              options={["Aluminum", "Cedar", "Hardie", "Other", "N/A"]}
              id="facia"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="existingGutters"
            label="Existing Gutters"
            rules={[
              { required: true, message: "Existing Gutters is required" },
            ]}
          >
            <Select options={["Yes", "No"]} id="existingGutters" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="gutterSize"
            label="Gutter Size"
            rules={[{ required: true, message: "Gutter Size is required" }]}
          >
            <Select options={['5"', '6"', "Other"]} id="gutterSize" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="color"
            label="Gutter Color"
            rules={[{ required: true, message: "Gutter Color In is required" }]}
          >
            <InputNoAutoComplete id="color" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="downspoutSize"
            label="Downspout Size"
            rules={[{ required: true, message: "Downspout Size is required" }]}
          >
            <Select options={['5"', '6"', "Other"]} id="downspoutSizeRef" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="downspoutColor"
            label="Downspout Color"
            rules={[
              { required: true, message: "Downspout Color In is required" },
            ]}
          >
            <InputNoAutoComplete id="downspoutColor" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="gutterLinearFeet"
            label="Gutter Linear Feet"
            rules={[
              { required: true, message: "Gutter Linear Feet is required" },
            ]}
          >
            <InputNoAutoComplete id="gutterLinearFeet" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="downspoutLinearFeet"
            label="Downspout Linear Feet"
            rules={[
              { required: true, message: "Downspout Linear Feet is required" },
            ]}
          >
            <InputNoAutoComplete id="downspoutLinearFeet" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="gutterProtection"
            label="Gutter Protection"
            rules={[
              { required: true, message: "Gutter Protection In is required" },
            ]}
          >
            <Select
              handleSelectChange={handleGutterProtectionChange}
              options={["Yes", "No"]}
              id="gutterProtection"
            />
          </Form.Item>
        </Col>
        {gutterProtection === "Yes" && (
          <>
            <Col xs={24} sm={12}>
              <Form.Item
                name="protectionType"
                label="Protection Type"
                rules={[
                  { required: true, message: "Protection Type is required" },
                ]}
              >
                <InputNoAutoComplete id="protectionType" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="protectionLinearFeet"
                label="Protection Linear Feet"
                rules={[
                  {
                    required: true,
                    message: "Protection Linear Feet is required",
                  },
                ]}
              >
                <InputNoAutoComplete id="protectionLinearFeet" />
              </Form.Item>
            </Col>
          </>
        )}
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="projectDescription"
            label="Project Description of Work"
            rules={[
              {
                required: true,
                message: "Project Description of Work is required",
              },
            ]}
          >
            <Input.TextArea autoComplete="dont-use-autocomplete" rows={4} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="scopeDescription"
            label="Scope Description of Work"
            rules={[
              {
                required: true,
                message: "Scope Description of Work is required",
              },
            ]}
          >
            <Input.TextArea autoComplete="dont-use-autocomplete" rows={4} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default memo(GutterDetails);
