import React from "react";
import Card from "antd/es/card";
import List from "antd/es/list";
import currencyFormatter from "Utils/currencyFormatter";
import styles from "./styles.module.scss";
import dayjs from "dayjs";

const Transactions = ({ transactions, mobile = false }) => {
  return (
    <Card
      bordered={false}
      className={styles.transactionsCard}
      style={
        !mobile
          ? {
              marginTop: 16,
              height: "calc(100% - 130px)",
            }
          : {
              marginTop: 16,
              height: "100%",
            }
      }
      bodyStyle={{ padding: 0, height: "calc(100% - 58px)", overflow: "auto" }}
      title="Transaction History"
    >
      <List className={styles.transationsList}>
        {/* <List.Item>
          <List.Item.Meta
            title="Transaction History"
            className={styles.fullWidth}
          />
        </List.Item> */}
        {transactions.length > 0 ? (
          transactions.map((t) => (
            <List.Item key={t._id}>
              <List.Item.Meta
                style={{ maxWidth: "20%" }}
                description={dayjs(t.transactionDate).format("M/D/YY")}
              />
              <List.Item.Meta
                style={{ maxWidth: "50%" }}
                description={t.type}
              />
              <List.Item.Meta
                style={{ maxWidth: "30%" }}
                description={currencyFormatter(t.amount)}
              />
            </List.Item>
          ))
        ) : (
          <List.Item>
            <List.Item.Meta
              description="No transactions to display"
              className={styles.fullWidth}
            />
          </List.Item>
        )}
      </List>
    </Card>
  );
};

export default Transactions;
