import React, { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelected } from "Actions/dataActions";
import { openForm } from "Actions/formActions";
import { fetchTable } from "Actions/tableActions";
import List from "antd/es/list";
import Button from "antd/es/button";
import Tag from "antd/es/tag";
import Directions from "Icons/Directions";
import dayjs from "dayjs";
import styles from "./styles.module.scss";
import statusObj from "Constants/dumpsterStatus";

const ManageDumpsterItem = ({
  style,
  mobile,
  _id,
  customerId,
  customerName,
  projectAddress,
  projectAddressLat,
  projectAddressLng,
  projectManagerEmail,
  size,
  statusDate,
  _projectId,
  status,
  supplier,
  headers,
}) => {
  const selectedRow = useSelector((state) => state.dataState.selectedRow);
  const dispatch = useDispatch();
  const table = useSelector((state) => state.tableState.table);
  const col = useSelector((state) => state.tableState.col);
  const order = useSelector((state) => state.tableState.order);
  const search = useSelector((state) => state.tableState.search);
  const activeFilter = useSelector((state) => state.tableState.activeFilter);

  const onClick = useCallback(
    (e) => {
      dispatch(setSelected(_id));
      e.stopPropagation();
      dispatch(
        openForm("dumping", {
          _id,
          customerId,
          customerName,
          projectAddress,
          projectAddressLat,
          projectAddressLng,
          projectManagerEmail,
          size,
          statusDate,
          _projectId,
          status,
          supplier,
          editing: true,
          callback: () => {
            dispatch(
              fetchTable({
                table,
                col,
                order,
                search,
                activeFilter,
                fetching: false,
              })
            );
          },
        })
      );
    },
    [
      dispatch,
      _id,
      customerId,
      customerName,
      projectAddress,
      projectAddressLat,
      projectAddressLng,
      projectManagerEmail,
      size,
      statusDate,
      _projectId,
      status,
      supplier,
      table,
      col,
      order,
      search,
      activeFilter,
    ]
  );

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  if (!mobile) {
    return (
      <List.Item
        style={style}
        className={`${styles.row} ${
          selectedRow === _id ? styles.selected : ""
        }`}
        onClick={onClick}
      >
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[0].width,
            textAlign: headers[0].align ? headers[0].align : "left",
          }}
          description={customerId}
        />
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[1].width,
            textAlign: headers[1].align ? headers[1].align : "left",
          }}
          description={customerName}
        />
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[2].width,
            textAlign: headers[2].align ? headers[2].align : "left",
          }}
          description={
            <Tag className={styles.statusTag} color={statusObj[status]}>
              {status}
            </Tag>
          }
        />
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[3].width,
            textAlign: headers[3].align ? headers[3].align : "left",
          }}
          description={projectAddress}
        />
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[4].width,
            textAlign: headers[4].align ? headers[4].align : "left",
          }}
          description={size}
        />
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[5].width,
            textAlign: headers[5].align ? headers[5].align : "left",
          }}
          description={
            supplier && supplier.companyName ? supplier.companyName : ""
          }
        />
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[6].width,
            textAlign: headers[6].align ? headers[6].align : "left",
          }}
          description={dayjs(statusDate).format("MMM D, YYYY")}
        />
      </List.Item>
    );
  } else {
    return (
      <List.Item
        style={style}
        className={`${styles.mobileListItem} ${
          selectedRow === _id ? styles.selected : ""
        }`}
        onClick={onClick}
      >
        <List.Item.Meta className={styles.customerId} title={customerId} />
        <List.Item.Meta
          className={styles.status}
          description={
            <Tag className={styles.statusTag} color={statusObj[status]}>
              {status}
            </Tag>
          }
        />
        <List.Item.Meta
          className={styles.date}
          description={dayjs(statusDate).format("MMM D, YY")}
        />
        <div className={styles.break} />
        <List.Item.Meta
          className={styles.fullLine}
          style={{ flex: "1 1 100%", paddingBottom: 3 }}
          description={customerName}
        />
        <div className={styles.break} />
        <List.Item.Meta
          className={styles.fullLine}
          description={projectAddress}
        />
        <div className={styles.break} />
        <List.Item.Meta
          className={styles.scopes}
          description={
            supplier && supplier.companyName ? supplier.companyName : ""
          }
        />
        <List.Item.Meta
          className={styles.actions}
          description={
            <>
              <a
                href={`https://www.google.com/maps/dir/?api=1&destination=${projectAddress}`}
                target="_Blank"
                rel="noopener noreferrer"
                onClick={stopPropagation}
              >
                <Button shape="circle" type="text" className={styles.button}>
                  <Directions />
                </Button>
              </a>
            </>
          }
        />
      </List.Item>
    );
  }
};

export default memo(ManageDumpsterItem);
