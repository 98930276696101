import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changePassword } from "Actions/authActions";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Button from "antd/es/button";
import Card from "antd/es/card";
import message from "antd/es/message";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const _user = useSelector((state) => state.authState._user);
  const [loading, setLoading] = useState(false);

  const onFinish = useCallback(
    (values) => {
      values._id = _user;
      values.cb = (res) => {
        if (res === true) {
          message.success("Password successfully changed");
        } else {
          setLoading(false);
          message.error("Error changing password");
        }
      };
      dispatch(changePassword(values));
    },
    [_user, dispatch]
  );

  function onFinishFailed(errorInfo) {
    setLoading(false);
    message.error("All fields must be completed");
  }

  return (
    <div className="content-inner p24">
      <Row justify="center">
        <Col xs={24} sm={24} md={12}>
          <Card style={{ minWidth: 300 }}>
            <Form
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              hideRequiredMark={true}
              colon={false}
              layout="vertical"
            >
              <Form.Item
                label="Old Password"
                name="old_password"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                label="New Password"
                name="new_password"
                rules={[
                  {
                    required: true,
                    message: "Must be at least 8 characters long",
                    min: 8,
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                label="Confirm Password"
                name="confirm_password"
                dependencies={["new_password"]}
                rules={[
                  {
                    required: true,
                    message: "Please input your confirm Password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("new_password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "The two passwords that you entered do not match!"
                      );
                    },
                  }),
                ]}
                hasFeedback
              >
                <Input.Password />
              </Form.Item>

              <Button
                style={{ marginTop: 16 }}
                type="primary"
                htmlType="submit"
                block
                loading={loading}
                disabled={loading}
              >
                Change Password
              </Button>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ChangePassword;
