import splitCamal from "Utils/splitCamal";
import arraysEqual from "Utils/arraysEqual";

export default async function generateChanges({
  changedValues,
  values,
  customerDetails,
  _lineItems,
  workOrder,
  woCreated,
  projectManagerChange,
}) {
  values.updatedStrings = [];
  values.customerDetailsUpdated = false;
  Object.entries(changedValues).map(([key, value]) => {
    if (value.index && woCreated) {
      let _material = _lineItems[value.index]
        ? _lineItems[value.index].material
        : undefined;
      if (_material) {
        if (value.material && value.material !== _material) {
          values.updatedStrings.push(
            `**${_material}** Material changed from **${_material}** to **${value.material}**`
          );
          if (projectManagerChange) {
            values.lineItems[value.index].materialUpdated = false;
          } else {
            values.lineItems[value.index].materialUpdated = true;
          }
        }
        if (value.type && value.type !== _lineItems[value.index].type) {
          values.updatedStrings.push(
            `**${_material}** Type changed from **${
              _lineItems[value.index].type
            }** to **${value.type}**`
          );
          if (projectManagerChange) {
            values.lineItems[value.index].typeUpdated = false;
          } else {
            values.lineItems[value.index].typeUpdated = true;
          }
        }
        if (value.color && value.color !== _lineItems[value.index].color) {
          values.updatedStrings.push(
            `**${_material}** Color changed from **${
              _lineItems[value.index].color
            }** to **${value.color}**`
          );
          if (projectManagerChange) {
            values.lineItems[value.index].colorUpdated = false;
          } else {
            values.lineItems[value.index].colorUpdated = true;
          }
        }
        if (
          value.quantity &&
          value.quantity !== _lineItems[value.index].quantity
        ) {
          values.updatedStrings.push(
            `**${_material}** Quantity changed from **${
              _lineItems[value.index].quantity
            }** to **${value.quantity}**`
          );
          if (projectManagerChange) {
            values.lineItems[value.index].quantityUpdated = false;
          } else {
            values.lineItems[value.index].quantityUpdated = true;
          }
        }
        if (
          "disabled" in value &&
          !!value.disabled !== !!_lineItems[value.index].disabled
        ) {
          values.updatedStrings.push(
            `**${_material}** has been **${
              value.disabled ? "crossed" : "uncrossed"
            } out**`
          );
          if (projectManagerChange) {
            values.lineItems[value.index].disabledUpdated = false;
          } else {
            values.lineItems[value.index].disabledUpdated = true;
          }
        }
      } else {
        values.updatedStrings.push(
          `**${value.material}** with Type **${value.type}**, color **${value.color}** and quantity **${value.quantity}** added`
        );
        values.lineItems[value.index].materialUpdated = true;
        values.lineItems[value.index].typeUpdated = true;
        values.lineItems[value.index].colorUpdated = true;
        values.lineItems[value.index].quantityUpdated = true;
        values.lineItems[value.index].disabledUpdated = true;
      }
    } else {
      if (workOrder[key] && Array.isArray(value.value)) {
        if (!key && !arraysEqual(value.value, workOrder[key])) {
          values.updatedStrings.push(
            `**${splitCamal(key)}** changed from **${workOrder[key].join(
              ", "
            )}** to **${value.value.join(", ")}**`
          );
        }
      } else {
        if (workOrder[key] && value.value !== workOrder[key]) {
          values.updatedStrings.push(
            `**${splitCamal(key)}** changed from **${workOrder[key]}** to **${
              value.value
            }**`
          );

          if (customerDetails.includes(key)) {
            values.customerDetailsUpdated = true;
          }
        }
      }
    }
    return key;
  });
  return values;
}
