import React, { useState } from "react";
import Button from "antd/es/button";
import dayjs from "dayjs";
import Form from "antd/es/form";
import DatePicker from "Components/Inputs/DatePicker";
import useIsMobile from "Hooks/useIsMobile";
import message from "antd/es/message";
import { useSelector, useDispatch } from "react-redux";
import { updateDetail } from "Actions/dataActions";
import api from "API";
import CancelButton from "Components/CancelButton";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import Input from "antd/es/input";
import MarkUpHelper from "Components/MarkUpHelper";

const messageKey = "messageKey";

const ScheduleEstimate = ({
  unsavedChanges,
  setUnsavedChanges,
  handleClose,
  type,
}) => {
  const dispatch = useDispatch();
  const mobile = useIsMobile();
  const formDetails = useSelector(
    (state) => state.formState.scheduleEstimate.formDetails
  );
  const viewableActivities = useSelector(
    (state) => state.dataState.viewableActivities
  );
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      setLoading(true);
      message.loading({
        content: "Creating event...",
        duration: 0,
        key: messageKey,
      });

      values._id = formDetails._id;
      values.estimateStart = new Date(values.estimateStart);
      values.estimateEnd = new Date(
        dayjs(values.estimateStart).add(3, "hours")
      );
      values.projectManager = formDetails.projectManager;
      values.rescheduled = formDetails.estimateStart ? true : false;
      values.estimateEventId = formDetails.estimateEventId;

      const res = await api.post("/estimate/schedule", values);

      dispatch(
        updateDetail({
          viewableActivities,
          key: "estimateStart",
          value: res.data.value,
          activities: res.data.activities,
        })
      );

      message.success({
        content: `Estimate has been ${
          formDetails.estimateStart ? "rescheduled" : "scheduled"
        }`,
        duration: 1.5,
        key: messageKey,
      });

      setLoading(false);
      handleClose(true);
    } catch (err) {
      setLoading(false);

      message.error({
        content: `Error ${
          formDetails.estimateStart ? "rescheduleding" : "scheduleding"
        } estimate`,
        duration: 1.5,
        key: messageKey,
      });
    }
  };

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error creating event");
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      className="form"
      autoComplete="off"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      initialValues={{
        estimateStart: formDetails.estimateStart
          ? dayjs(formDetails.estimateStart)
          : undefined,
      }}
    >
      <RemoveAutocomplete />

      <FormBody
        className="content-inner"
        style={{
          paddingLeft: 24,
          paddingTop: 24,
          paddingRight: 24,
          paddingBottom: 8,
          maxHeight: type === "modal" ? "calc(100vh - 161px)" : "unset",
        }}
      >
        <Form.Item
          name="estimateStart"
          label="Estimate Start"
          rules={[
            {
              required: true,
              message: "You must include a start date and time",
            },
          ]}
        >
          <DatePicker
            format={"dddd, MMM D h:mm A"}
            showTime={true}
            getPopupContainer={(trigger) =>
              !mobile
                ? trigger.parentNode.parentNode.parentNode.parentNode
                : document.body
            }
            getCalendarContainer={(trigger) =>
              !mobile
                ? trigger.parentNode.parentNode.parentNode.parentNode
                : document.body
            }
            inputReadOnly={true}
          />
        </Form.Item>

        {formDetails.estimateStart && (
          <Form.Item
            hidden={formDetails.estimateStart ? false : true}
            style={{ marginTop: 24 }}
            name="rescheduledReason"
            label={<MarkUpHelper title="Rescheduled Reason" />}
            rules={[
              { required: true, message: "Rescheduled reason is required" },
            ]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
        )}
      </FormBody>
      <FormControls className="sticky-footer">
        <CancelButton handleClick={handleClose} />
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={loading || !unsavedChanges}
        >
          Submit
        </Button>
      </FormControls>
    </Form>
  );
};

export default ScheduleEstimate;
