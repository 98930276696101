import React, { useState, useEffect } from "react";
import Form from "antd/es/form";
import api from "API";
import styles from "./styles.module.scss";
import Upload from "antd/es/upload";
import InboxOutlined from "@ant-design/icons/InboxOutlined";
import isPdf from "Utils/isPdf";
import pdf from "Assets/pdf.svg";

import axios from "axios";
import generateFilePreview from "Utils/generateFilePreview";
import generateFileSize from "Utils/generateFileSize";
import List from "antd/es/list";
import UploadRow from "Components/images/UploadRow";

const preSrc = "https://s3.us-east-2.amazonaws.com/pr-crm/";
const preThumb = "https://s3.us-east-2.amazonaws.com/pr-crm-thumbnail/";

const ImageUploader = ({
  _fileList,
  _setFileList,
  uploading,
  setUploading,
  _projectId,
  destination,
  required = false,
  multiple = true,
  label,
}) => {
  const [uploads, setUploads] = useState({});

  useEffect(() => {
    if (uploading) {
      let _uploading = false;
      Object.keys(uploads).map((key) => {
        if (uploads[key].upload !== 100 && uploads[key].upload !== true) {
          _uploading = true;
          return key;
        }
        return key;
      });
      if (!_uploading) {
        setUploading(false);
      }
    }
  }, [uploads]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleBeforeUpload = async (file) => {
    try {
      setUploading(true);
      const size = await generateFileSize(file.size);
      let preview;
      if (isPdf(file.name)) {
        preview = pdf;
      } else {
        preview = await generateFilePreview(file);
      }
      setUploads((prv) => {
        return {
          ...prv,
          [file.uid]: {
            preview,
            name: file.name,
            size,
            failed: false,
            upload: 0,
          },
        };
      });
      return file;
    } catch (err) {
      console.log("beforeUpload err", err);
    }
  };

  const handleUpload = async ({ file, onProgress }) => {
    try {
      let fileName = `${new Date().getTime()}-${file.name.replace(
        /[^a-zA-Z0-9.]/gi,
        "_"
      )}`;
      const src = `${preSrc}${destination}/${fileName}`;
      let thumbnail;
      const ext = src.split(".").pop().toLowerCase();
      if (ext !== "pdf") thumbnail = `${preThumb}${destination}/${fileName}`;

      _setFileList((prevState) => [
        ...prevState,
        {
          originFileObj: file,
          _id: file.uid,
          uid: file.uid,
          name: file.name,
          mediaType: isPdf(src) ? "pdf" : "image",
          _projectId,
          size: file.size,
          type: file.type,
          src,
          thumbnail,
        },
      ]);

      const key = `${destination}/${fileName}`;

      const res = await api.post("/upload", {
        type: file.type,
        key,
      });
      await axios
        .put(res.data, file, {
          headers: {
            "Content-Type": file.type,
          },
          onUploadProgress: (e) => {
            onProgress(e);
          },
        })
        .catch(function (err) {
          console.log("upload err", err);

          setUploads((prv) => {
            return {
              ...prv,
              [file.uid]: {
                ...prv[file.uid],
                failed: true,
              },
            };
          });

          _setFileList((prevState) => {
            const _filteredFileList = prevState.filtered(
              (prv) => prv.uid !== file.uid
            );
            return _filteredFileList;
          });
        });
    } catch (err) {
      console.log("handleUpload err", err);
    }
  };

  const handleProgress = (progress, file) => {
    const _progress = parseInt((progress.loaded * 100) / progress.total, 10);
    setUploads((prv) => {
      return {
        ...prv,
        [file.uid]: {
          ...prv[file.uid],
          upload: _progress,
        },
      };
    });
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <>
      <Form.Item
        label={label}
        name="images"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={required ? [{ required: true, message: "Upload required" }] : []}
      >
        <Upload.Dragger
          multiple={multiple}
          beforeUpload={handleBeforeUpload}
          customRequest={handleUpload}
          showUploadList={false}
          onProgress={handleProgress}
          accept="image/*, application/pdf"
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
        </Upload.Dragger>
      </Form.Item>

      {_fileList.length > 0 && (
        <List className={styles.uploadRow} bordered>
          {Object.keys(uploads).map((key) => {
            return (
              <UploadRow
                key={key}
                preview={uploads[key].preview}
                name={uploads[key].name}
                size={uploads[key].size}
                upload={uploads[key].upload}
                failed={uploads[key].failed}
              />
            );
          })}
        </List>
      )}
    </>
  );
};

export default ImageUploader;
