import React from "react";
import LineChartWithInterval from "Reports/LineChartWithInterval";

export default function SoldLineChart() {
  return (
    <LineChartWithInterval
      path="/reports/adminSales"
      type="currency"
      summary={true}
      lineItems={true}
      aggregate={true}
      report="SALES"
      headers={["Salesman", "Total"]}
    />
  );
}
