import React from "react";
import Form from "antd/es/form";
import Alert from "antd/es/alert";
import Col from "antd/es/col";
import Row from "antd/es/row";
import currencyFormatter from "Utils/currencyFormatter";
import Input from "antd/es/input";
import Currency from "Components/Inputs/Currency";
import Divider from "antd/es/divider";
import Typography from "antd/es/typography";
import ExtendedWarranty from "Components/Inputs/ExtendedWarranty";
import WorkmanshipWarranty from "Components/Inputs/WorkmanshipWarranty";

const Corrections = ({
  scopeTotal,
  collectionTotal,
  setScopeTotal,
  scopes,
  form,
}) => {
  const handleAmountChange = async (key) => {
    try {
      const _scopes = await form.getFieldValue("scopes");

      let _scopeTotal = 0;
      for (const s of _scopes) {
        _scopeTotal += s.amount;
      }
      setScopeTotal(_scopeTotal.toFixed(2));
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <>
      <Alert
        style={{ marginBottom: 16 }}
        type={
          scopeTotal.toString() === collectionTotal.toString()
            ? "success"
            : "error"
        }
        message={
          <div>
            <Row gutter={16}>
              <Col xs={24} sm={12} style={{ whiteSpace: "pre-wrap" }}>
                {`Scope Total:\n${currencyFormatter(scopeTotal)}`}
              </Col>
              <Col xs={24} sm={12} style={{ whiteSpace: "pre-wrap" }}>
                {`Collection Total:\n${currencyFormatter(collectionTotal)}`}
              </Col>
            </Row>
          </div>
        }
      />

      <Form.List name="scopes">
        {(fields) => {
          return (
            <>
              {fields.map((field, i) => {
                return (
                  <div key={`${field.key}-dumpster`}>
                    <Typography.Title level={4}>
                      {scopes && scopes[field.name].name}
                    </Typography.Title>
                    <Form.Item
                      hidden
                      {...field}
                      name={[field.name, "_id"]}
                      fieldKey={[field.fieldKey, "_id"]}
                      key={`${field.key}-_id`}
                    >
                      <Input autoComplete="dont-use-autocomplete" disabled />
                    </Form.Item>

                    <Form.Item
                      {...field}
                      name={[field.name, "amount"]}
                      label="Amount"
                      fieldKey={[field.fieldKey, "amount"]}
                      key={`${field.key}-amount`}
                    >
                      <Currency
                        autoComplete="dont-use-autocomplete"
                        onChange={handleAmountChange}
                      />
                    </Form.Item>

                    <Form.Item
                      {...field}
                      name={[field.name, "premierWarranty"]}
                      label="Premier Warranty"
                      fieldKey={[field.fieldKey, "premierWarranty"]}
                      key={`${field.key}-premierWarranty`}
                      rules={[
                        {
                          required: true,
                          message: "Premier Warranty is required",
                        },
                      ]}
                    >
                      <WorkmanshipWarranty />
                    </Form.Item>

                    <Form.Item
                      {...field}
                      name={[field.name, "extendedWarranty"]}
                      label="Extended Warranty"
                      fieldKey={[field.fieldKey, "extendedWarranty"]}
                      key={`${field.key}-extendedWarranty`}
                      rules={[
                        {
                          required: true,
                          message: "Extended Warranty is required",
                        },
                      ]}
                    >
                      <ExtendedWarranty
                        scopeName={scopes && scopes[field.name].name}
                      />
                    </Form.Item>

                    {i + 1 !== fields.length && <Divider />}
                  </div>
                );
              })}
            </>
          );
        }}
      </Form.List>
    </>
  );
};

export default Corrections;
