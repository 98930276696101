import React from "react";

import Row from "antd/es/row";
import Col from "antd/es/col";
import Typography from "antd/es/typography";

import WeatherCloudy from "Icons/WeatherCloudy";
import WeatherSunny from "Icons/WeatherSunny";
import WeatherPartlyCloudy from "Icons/WeatherPartlyCloudy";
import WeatherPartlyRainy from "Icons/WeatherPartlyRainy";
import WeatherLightningRainy from "Icons/WeatherLightningRainy";
import WeatherFog from "Icons/WeatherFog";
import WeatherSnowy from "Icons/WeatherSnowy";
import WeatherRainy from "Icons/WeatherRainy";
import styles from "./styles.module.scss";

const iconSize = 128;

const Weather = ({ day, title }) => {
  // 800 = WeatherSunny
  // 804 = WeatherCloudy
  // 801, 802, 803= WeatherPartlyCloudy
  // 5xx = WeatherRainy
  // 3xx = WeatherPartlyRainy
  // 2xx = WeatherLightningRainy
  // 6xx = WeatherSnowy
  // 7xx = WeatherFog

  const generateIcon = (id) => {
    const _id = id + "";
    if (_id === "800") {
      return <WeatherSunny size={iconSize} color="#ffffff" />;
    } else if (_id === "804") {
      return <WeatherCloudy size={iconSize} color="#ffffff" />;
    } else if (_id.charAt(0) === "8") {
      return <WeatherPartlyCloudy size={iconSize} color="#ffffff" />;
    } else if (_id.charAt(0) === "5") {
      return <WeatherRainy size={iconSize} color="#ffffff" />;
    } else if (_id.charAt(0) === "3") {
      return <WeatherPartlyRainy size={iconSize} color="#ffffff" />;
    } else if (_id.charAt(0) === "2") {
      return <WeatherLightningRainy size={iconSize} color="#ffffff" />;
    } else if (_id.charAt(0) === "6") {
      return <WeatherSnowy size={iconSize} color="#ffffff" />;
    } else if (_id.charAt(0) === "7") {
      return <WeatherFog size={iconSize} color="#ffffff" />;
    }
  };

  const generateTemp = (temp) => {
    return Math.round((parseFloat(temp) - 273.15) * 1.8 + 32);
  };
  return (
    <>
      <Typography.Text className={styles.offWhite}>{title}</Typography.Text>
      <Row gutter={16}>
        <Col xs={6}>
          <div className={styles.temp}>
            <Typography.Text className={styles.offWhite}>LOW</Typography.Text>
            <Typography.Title level={1} style={{ color: "#ffffff" }}>
              {day.temp ? generateTemp(day.temp.min) : ""}
              &deg;
            </Typography.Title>
          </div>
        </Col>
        <Col xs={12} style={{ textAlign: "center" }}>
          {day.weather ? generateIcon(day.weather[0].id) : ""}
        </Col>
        <Col xs={6}>
          <div className={styles.temp}>
            <Typography.Text className={styles.offWhite}>HIGH</Typography.Text>
            <Typography.Title level={1} style={{ color: "#ffffff" }}>
              {day.temp ? generateTemp(day.temp.max) : ""}
              &deg;
            </Typography.Title>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Weather;
