import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelected } from "Actions/dataActions";
import List from "antd/es/list";
import dayjs from "dayjs";
import styles from "./styles.module.scss";
import Tag from "antd/es/tag";
import statusObj from "Constants/marketingStatus";

const MarketingItem = ({
  style,
  mobile,
  _id,
  dueDate,
  type,
  submitted,
  requestor,
  headers,
  status,
  setOpen,
  _projectId,
  address,
  addresses,
  generalMessage,
  recipient,
  postCard,
  _userId,
  occurance,
  customerId,
  fishingAddresses,
}) => {
  const dispatch = useDispatch();
  const selectedRow = useSelector((state) => state.dataState.selectedRow);

  const onClick = useCallback(() => {
    dispatch(
      setSelected({
        _id,
        _projectId,
        submitted,
        requestor,
        type,
        status,
        dueDate,
        address,
        addresses,
        generalMessage,
        recipient,
        postCard,
        _userId,
        occurance,
        customerId,
        fishingAddresses,
      })
    );
    setOpen(true);
  }, [
    _id,
    dispatch,
    _projectId,
    submitted,
    requestor,
    type,
    status,
    dueDate,
    address,
    addresses,
    generalMessage,
    recipient,
    postCard,
    setOpen,
    _userId,
    occurance,
    customerId,
    fishingAddresses,
  ]);

  if (!mobile) {
    return (
      <List.Item
        style={style}
        className={`${styles.row} ${
          selectedRow === _id ? styles.selected : ""
        }`}
        onClick={onClick}
      >
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[0].width,
            textAlign: headers[0].align ? headers[0].align : "left",
          }}
          description={dayjs(submitted).format("MMM D, YYYY")}
        />
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[1].width,
            textAlign: headers[1].align ? headers[1].align : "left",
          }}
          description={
            <Tag className={styles.statusTag} color={statusObj[status]}>
              {status}
            </Tag>
          }
        />
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[2].width,
            textAlign: headers[2].align ? headers[2].align : "left",
          }}
          description={type}
        />
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[3].width,
            textAlign: headers[3].align ? headers[3].align : "left",
          }}
          description={requestor}
        />
        <List.Item.Meta
          className={styles.col}
          style={{
            width: headers[4].width,
            textAlign: headers[4].align ? headers[4].align : "left",
          }}
          description={dayjs(dueDate).format("MMM D, YYYY")}
        />
      </List.Item>
    );
  } else {
    return (
      <List.Item
        style={style}
        className={`${styles.mobileListItem} ${
          selectedRow === _id ? styles.selected : ""
        }`}
        onClick={onClick}
      >
        <List.Item.Meta
          className={styles.customerId}
          title={`${type} ${(
            <Tag className={styles.statusTag} color={statusObj[status]}>
              {status}
            </Tag>
          )}`}
        />
        <List.Item.Meta
          className={styles.date}
          description={dayjs(submitted).format("MMM D, YY")}
        />
        <div className={styles.break} />
        <List.Item.Meta
          className={styles.fullLine}
          style={{ flex: "1 1 100%", paddingBottom: 3 }}
          description={requestor}
        />
        <div className={styles.break} />
        <List.Item.Meta
          className={styles.scopes}
          description={`Due Date: ${dayjs(dueDate).format("MMM D, YYYY")}`}
        />
      </List.Item>
    );
  }
};

export default MarketingItem;
