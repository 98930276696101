import { useState } from "react";
import api from "API";

const useApiErrors = () => {
  const [error, setError] = useState({
    status: null,
    error: "",
    errorMessage: "",
  });

  api.interceptors.response.use(
    async (res) => {
      return res;
    },
    async (err) => {
      if (err && err.response && err.response.data) {
        setError({
          status: err.response.data.status,
          error: err.response.data.err,
          errorMessage: err.response.data.errorMessage,
        });
      }

      return Promise.reject(err);
    }
  );

  return error;
};

export default useApiErrors;
