import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateActivitiesAndSelectedActivity,
  closeImageViewer,
  updateActivitiesAndAllMedia,
  updateSelectedActivity,
} from "Actions/dataActions";
import Button from "antd/es/button";
import Drawer from "antd/es/drawer";
import message from "antd/es/message";
import Modal from "antd/es/modal";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";
import Close from "Icons/Close";
import RotateRight from "Icons/RotateRight";
import RotateLeft from "Icons/RotateLeft";
import Star from "Icons/Star";
import StarBorder from "Icons/StarBorder";
import MoreVert from "Icons/MoreVert";
import Delete from "Icons/Delete";
import styles from "./styles.module.scss";
import Clipboard from "react-clipboard.js";
import downloadFile from "Utils/downloadFile";
import isPdf from "Utils/isPdf";
import isVideo from "Utils/isVideo";
import api from "API";
import printJS from "print-js";
import Loader from "Components/Loader";

const MoreOptions = ({
  visible,
  handleClose,
  image,
  _projectId,
  viewOnly,
  readOnly,
  _activityId,
  onClose,
  selectedActivity,
}) => {
  const dispatch = useDispatch();
  const viewableActivities = useSelector(
    (state) => state.dataState.viewableActivities
  );
  const [loading, setLoading] = useState(false);

  const copied = () => {
    message.success("Image URL copied");
  };

  async function getBase64() {
    try {
      const res = await api.post("/getBase64", {
        src: image.src,
        type: "image",
      });
      return res.data;
    } catch (err) {
      console.log("err", err);
    }
  }

  async function saveImage() {
    try {
      setLoading(true);
      const ext = image.src.split(".").pop();
      const base64 = await getBase64();
      downloadFile(base64, "image", ext);
      setLoading(false);
      handleClose(false);
    } catch (err) {
      console.log("err", err);
    }
  }

  async function printImage() {
    printJS({
      printable: `${image.src}?${new Date().getTime()}`,
      type: image.type !== "application/pdf" ? "image" : "pdf",
    });
  }

  const handleDelete = () => {
    Modal.confirm({
      zIndex: 7001,
      icon: <QuestionCircleOutlined />,
      centered: true,
      cancelText: "Cancel",
      okText: "Delete",
      okButtonProps: { danger: true },
      content: <div>Are you sure? This will permanetly delete this file.</div>,
      onOk() {
        return new Promise((resolve, reject) => {
          handleConfirmDelete(() => {
            onClose();
            resolve(true);
          });
        }).catch((err) => console.log("err: ", err));
      },
      onCancel() {},
    });
  };

  const handleConfirmDelete = useCallback(
    async (cb) => {
      try {
        const res = await api.post(`/activity/delete-image`, {
          _id: image._activityId || _activityId,
          _projectId: image._projectId || _projectId,
          image,
        });

        dispatch(
          updateActivitiesAndSelectedActivity({
            viewableActivities,
            activities: res.data._activities,
            allMedia: res.data._allMedia,
            activity: selectedActivity,
            legacyFiles: res.data.legacyFiles,
            salesObj: res.data.salesObj,
            _projectId: image._projectId || _projectId,
          })
        );

        cb();
      } catch (err) {
        console.log("err", err);
      }
    },
    [
      image,
      _activityId,
      _projectId,
      dispatch,
      viewableActivities,
      selectedActivity,
    ]
  );

  const cancel = () => {
    handleClose(false);
  };

  return (
    <Drawer
      zIndex={7000}
      closable={false}
      className={styles.moreOptions}
      placement="bottom"
      visible={visible}
      onClose={cancel}
      title={null}
      height={!viewOnly && !readOnly && !isVideo(image.src) ? 273 : 224}
      bodyStyle={{ padding: 24, background: "transparent", overflow: "hidden" }}
    >
      {loading && (
        <div
          style={{
            position: "fixed",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            zIndex: 99999,
            background: "rgba(0,0,0,0.23)",
          }}
        >
          <Loader display={loading ? "flex" : "none"} minHeight="100%" />
        </div>
      )}
      <div onClick={saveImage} className={styles.action}>
        Download
      </div>
      {!isVideo(image.src) && (
        <div onClick={printImage} className={styles.action}>
          Print
        </div>
      )}
      <div className={styles.action}>
        <Clipboard
          data-clipboard-text={image.src}
          component="div"
          onSuccess={copied}
        >
          Copy URL
        </Clipboard>
      </div>
      {!viewOnly && !readOnly && (
        <div
          className={`${styles.action} ${styles.delete}`}
          onClick={handleDelete}
        >
          Delete
        </div>
      )}

      <div className={styles.cancel} onClick={cancel}>
        Cancel
      </div>
    </Drawer>
  );
};

const Controls = ({
  rotateRight,
  rotateLeft,
  setImages,
  _activityId,
  readOnly,
  viewOnly,
}) => {
  const dispatch = useDispatch();
  const _id = useSelector((state) => state.dataState.details._id);
  const selectedActivity = useSelector(
    (state) => state.dataState.selectedActivity
  );
  const viewingImage = useSelector((state) => state.dataState.viewingImage);
  const viewableActivities = useSelector(
    (state) => state.dataState.viewableActivities
  );
  const [visible, setVisible] = useState(false);
  const [star, setStar] = useState(viewingImage.star);

  const removeImage = useCallback(() => {
    setStar(false);
    setImages(viewingImage);
    dispatch(closeImageViewer());
  }, [dispatch, viewingImage, setImages]);

  const _setStar = useCallback(async () => {
    try {
      setStar(true);
      const res = await api.post("/activity/star-image", viewingImage);

      dispatch(
        updateActivitiesAndAllMedia({
          viewableActivities,
          activities: res.data._activities,
          allMedia: res.data._allMedia,
          _projectId: viewingImage._projectId,
          salesObj: res.data.salesObj,
          legacyFiles: res.data.legacyFiles,
        })
      );

      if (selectedActivity)
        dispatch(
          updateSelectedActivity(selectedActivity, res.data._activities)
        );
    } catch (err) {
      console.log("err", err);
      message.error("Error starring image");
    }
  }, [viewingImage, dispatch, viewableActivities, selectedActivity]);

  const _removeStar = useCallback(async () => {
    try {
      setStar(false);
      const res = await api.post("/activity/remove-star", {
        _projectId: viewingImage._projectId,
      });

      dispatch(
        updateActivitiesAndAllMedia({
          viewableActivities,
          activities: res.data._activities,
          allMedia: res.data._allMedia,
          _projectId: viewingImage._projectId,
          salesObj: res.data.salesObj,
          legacyFiles: res.data.legacyFiles,
        })
      );

      if (selectedActivity)
        dispatch(
          updateSelectedActivity(selectedActivity, res.data._activities)
        );
    } catch (err) {
      console.log("err", err);
      message.error("Error removing star");
    }
  }, [viewingImage, dispatch, viewableActivities, selectedActivity]);

  const showMenu = () => {
    setVisible(true);
  };

  const close = useCallback(() => {
    dispatch(closeImageViewer());
  }, [dispatch]);

  return (
    <div className={styles.actionContainer}>
      <Button type="text" size="large" onClick={close}>
        <Close />
      </Button>
      {!isVideo(viewingImage.src) && (
        <>
          <Button type="text" size="large" onClick={rotateRight}>
            <RotateRight />
          </Button>
          <Button type="text" size="large" onClick={rotateLeft}>
            <RotateLeft />
          </Button>
        </>
      )}

      {!setImages &&
      !isPdf(viewingImage.src) &&
      !isVideo(viewingImage.src) &&
      !star &&
      !viewingImage.link &&
      !readOnly ? (
        <Button type="text" size="large" onClick={_setStar}>
          <StarBorder />
        </Button>
      ) : !setImages &&
        !isPdf(viewingImage.src) &&
        !isVideo(viewingImage.src) &&
        star &&
        !readOnly &&
        !viewingImage.link ? (
        <Button type="text" size="large" onClick={_removeStar}>
          <Star color="gold" />
        </Button>
      ) : null}

      {setImages && !readOnly && !viewOnly && (
        <Button type="text" size="large" onClick={removeImage}>
          <Delete />
        </Button>
      )}

      {!setImages && !readOnly && (
        <>
          <Button type="text" size="large" onClick={showMenu}>
            <MoreVert />
          </Button>

          <MoreOptions
            visible={visible}
            handleClose={setVisible}
            image={viewingImage}
            _projectId={_id}
            readOnly={viewOnly}
            viewOnly={viewingImage.link || setImages || viewingImage.viewOnly}
            _activityId={viewingImage._activityId}
            selectedActivity={selectedActivity}
            onClose={close}
          />
        </>
      )}
    </div>
  );
};

export default Controls;
