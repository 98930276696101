import React from "react";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Typography from "antd/es/typography";

const ProjectDetails = ({ customerId, customerName, projectAddress }) => {
  return (
    <Row gutter={16}>
      <Col xs={24}>
        <Typography.Title level={3}>Project Details</Typography.Title>
      </Col>
      <Col xs={24}>
        <Typography.Text strong>Customer ID:</Typography.Text>{" "}
        <Typography.Text type="secondary">{customerId}</Typography.Text>
      </Col>
      <Col xs={24}>
        <Typography.Text strong>Customer Name:</Typography.Text>{" "}
        <Typography.Text type="secondary">{customerName}</Typography.Text>
      </Col>
      <Col xs={24}>
        <Typography.Text strong>Project Address:</Typography.Text>{" "}
        <Typography.Text type="secondary">{projectAddress}</Typography.Text>
      </Col>
    </Row>
  );
};

export default ProjectDetails;
