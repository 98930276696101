import React, { useState, useEffect } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useSelector } from "react-redux";
import Form from "antd/es/form";
import message from "antd/es/message";
import Button from "antd/es/button";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import api from "API";
import CancelButton from "Components/CancelButton";
import SearchLeadsAndProjects from "Components/Inputs/SearchLeadsAndProjects";
import Radio from "antd/es/radio";
import noImage from "Assets/ina.jpg";
import styles from "./styles.module.scss";
import List from "antd/es/list";
import Steps from "antd/es/steps";
import Card from "antd/es/card";
import Delete from "Icons/Delete";
import CountUp from "react-countup";
import usePrevious from "Hooks/usePrevious";
import Image from "antd/es/image";

const messageKey = "messageKey";
const singleCost = 1.0;

const JobsiteTarget = ({ handleClose, type }) => {
  const formDetails = useSelector(
    (state) => state.formState.jobsiteTarget.formDetails
  );
  const name = useSelector((state) => state.authState.name);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [_projectId, _setProjectId] = useState(null);
  const [latLng, setLatLng] = useState({ lat: 42.963795, lng: -85.670006 });
  const [map, setMap] = useState();
  const [markers, setMarkers] = useState({}); // eslint-disable-line
  const [addresses, setAddresses] = useState([]);
  const [step, setStep] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const prevTotalCost = usePrevious(totalCost);

  useEffect(() => {
    const scrollRef = document.getElementById("jobsite-target-scroll");
    const scrollPage2Ref = document.getElementById(
      "jobsite-target-page2-scroll"
    );
    if (scrollRef) scrollRef.scrollTop = 0;
    if (scrollPage2Ref) scrollPage2Ref.scrollTop = 0;
  }, [step]);

  useEffect(() => {
    if (_projectId) {
      setTimeout(() => {
        const el = document.getElementById("steps");
        el.classList.remove("ant-steps-vertical");
        el.classList.add("ant-steps-horizontal");
        el.classList.add("ant-steps-label-vertical");
      });

      setMap(
        new window.google.maps.Map(
          document.getElementById("jobsiteTargetMap"),
          {
            center: { lat: 42.963795, lng: -85.670006 },
            zoom: 18,
            gestureHandling: "greedy",
            disableDefaultUI: true,
            fullscreenControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            zoomControl: false,
            defaultMapTypeId: "hybrid",
            mapTypeId: "hybrid",
            tilt: 0,
          }
        )
      );
    }
  }, [_projectId]);

  useEffect(() => {
    if (map) {
      map.setCenter(latLng);

      window.google.maps.event.addListener(map, "click", (event) => {
        const _id = `${event.latLng.lat()}${event.latLng.lng()}`;

        reverseGeocode({
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
          _id,
        });
      });

      new window.google.maps.Marker({
        position: {
          lat: latLng.lat,
          lng: latLng.lng,
        },
        map: map,
        draggable: false,
        icon: new window.google.maps.MarkerImage(
          "https://s3.us-east-2.amazonaws.com/pr-crm/assets/black_marker.png",
          null,
          null,
          null,
          new window.google.maps.Size(24, 36)
        ),
      });
    }
  }, [map]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTotalCost(addresses.length * singleCost);
  }, [addresses]);

  const reverseGeocode = async ({ lat, lng, _id }) => {
    try {
      const res = await api.get(`/reverse-geocode/${lat}/${lng}`);

      const marker = {};
      marker.marker = new window.google.maps.Marker({
        position: {
          lat,
          lng,
        },
        map,
        draggable: false,
        animation: window.google.maps.Animation.DROP,
        icon: new window.google.maps.MarkerImage(
          "https://s3.us-east-2.amazonaws.com/pr-crm/assets/blue_marker.png",
          null,
          null,
          null,
          new window.google.maps.Size(24, 36)
        ),
      });

      marker.onClick = window.google.maps.event.addListener(
        marker.marker,
        "click",
        function () {
          unstable_batchedUpdates(() => {
            marker.marker.setMap(null);
            setAddresses((_addresses) => {
              return _addresses.filter((a) => a._id !== _id);
            });
            setMarkers((_markers) => {
              delete _markers[_id];
              return _markers;
            });
          });
        }
      );

      unstable_batchedUpdates(() => {
        let fullAddress = res.data.results[0].formatted_address;
        fullAddress = fullAddress.substring(0, fullAddress.length - 5);

        fullAddress = fullAddress.split(",");
        const address = fullAddress[0];
        const address2 = `${fullAddress[1]}, ${fullAddress[2]}`;

        setAddresses((_addresses) => {
          return [
            ..._addresses,
            {
              _id,
              name: "Home Owner",
              address,
              address2,
              estimator: name,
            },
          ];
        });
        setMarkers((_markers) => {
          return {
            ..._markers,
            [_id]: marker,
          };
        });
      });
    } catch (err) {
      console.log("reverseGeocode err", err);
    }
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      // message.loading("Submitting jobsite targeting...", 0);
      message.loading({
        content: "Submitting jobsite targeting...",
        duration: 0,
        key: messageKey,
      });

      values.addresses = addresses;
      // values.addresses = [];
      // for (const a of addresses) {
      //   values.addresses.push(a.address);
      // }

      values._projectId = _projectId;
      values.totalCost = totalCost;
      await api.post("/marketing-portal/jobsite-targeting", values);

      if (formDetails.callback) {
        formDetails.callback();
      }

      // message.success("Jobsite targeting submitted");
      message.success({
        content: "Jobsite targeting submitted",
        duration: 1.5,
        key: messageKey,
      });
      handleClose(true);
    } catch (err) {
      // message.error("Error submitting jobsite targeting");
      message.error({
        content: "Error submitting jobsite targeting",
        duration: 1.5,
        key: messageKey,
      });
      setLoading(false);
      console.log("err", err);
    }
  };

  const onFinishFailed = (err) => {
    console.log("err", err);
    setLoading(false);
    message.error("Error submitting jobsite targeting");
  };

  const handleSelect = (selected) => {
    _setProjectId(selected._id);
    setLatLng({
      lat: selected.projectAddressLat,
      lng: selected.projectAddressLng,
    });
  };

  const handleNext = () => {
    if (addresses.length > 0) {
      setStep(1);
    } else {
      message.error("Must select at least 1 address");
    }
  };

  const handleBack = () => {
    setStep(0);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      className="form"
      initialValues={{
        style: "followUpLetterA",
      }}
    >
      <RemoveAutocomplete />

      {!_projectId ? (
        <SearchLeadsAndProjects
          handleSelect={handleSelect}
          leads={false}
          disableLastViewed={true}
        />
      ) : (
        <FormBody
          id="jobsite-target-scroll"
          className="content-inner"
          style={{
            padding: 0,
            height:
              type === "modal" ? "calc(100vh - 161px)" : "calc(100% - 108px)",
            maxHeight: type === "modal" ? "calc(100vh - 161px)" : "unset",
            borderRadius: 0,
          }}
        >
          <Steps
            id="steps"
            className={`${styles.steps} ${
              type === "modal" ? styles.modal : ""
            }`}
            labelPlacement="vertical"
            size="small"
            current={step}
          >
            <Steps.Step title="Select" />
            <Steps.Step title="Submit" />
          </Steps>

          <div className={step !== 0 ? "hide" : ""} style={{ height: "100%" }}>
            <div
              className={`${styles.total} ${
                type === "modal" ? styles.modal : ""
              }`}
            >
              <CountUp
                start={prevTotalCost}
                end={totalCost}
                decimals={2}
                separator=","
                prefix="$"
                duration={1}
              />
            </div>
            <div
              style={{
                position: "relative",
                width: "100%",
                height: "100%",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                }}
                id="jobsiteTargetMap"
                className={styles.jobsiteTargetMap}
              ></div>
            </div>

            <Card
              title={`Selected Addresses (${addresses.length})`}
              className={`${styles.card} ${
                type === "modal" ? styles.modal : ""
              }`}
              bordered={false}
              style={{ height: addresses.length > 3 ? "100%" : "auto" }}
            >
              <List className={styles.list}>
                {addresses.length > 0 ? (
                  addresses.map((a) => (
                    <AddressListItem
                      key={a._id}
                      setMarkers={setMarkers}
                      setAddresses={setAddresses}
                      address={a.address}
                      _id={a._id}
                    />
                  ))
                ) : (
                  <List.Item>
                    <List.Item.Meta description="No addresses have been selected" />
                  </List.Item>
                )}
              </List>
            </Card>
          </div>

          <div className={step !== 1 ? "hide" : ""} style={{ height: "100%" }}>
            <div
              className={`${styles.headerSpacer} ${
                type === "modal" ? styles.modal : ""
              }`}
            />
            <div
              className={`content-inner ${styles.page2} ${
                type === "modal" ? styles.modal : ""
              }`}
              id="jobsite-target-page2-scroll"
            >
              <Form.Item name="postCard" style={{ marginBottom: 0 }}>
                <Radio.Group>
                  <Radio
                    value="https://premierroofingandexteriors.s3.us-east-2.amazonaws.com/pardon_our_dust.png"
                    className={styles.postcardRadio}
                  >
                    <Image
                      width="100%"
                      src="https://premierroofingandexteriors.s3.us-east-2.amazonaws.com/pardon_our_dust.png"
                      alt="pardon_our_dust"
                      fallback={noImage}
                      preview={false}
                    />
                  </Radio>
                  <Radio
                    value="https://premierroofingandexteriors.s3.us-east-2.amazonaws.com/roofing_sale.png"
                    className={styles.postcardRadio}
                  >
                    <Image
                      width="100%"
                      src="https://premierroofingandexteriors.s3.us-east-2.amazonaws.com/roofing_sale.png"
                      alt="pardon_our_dust"
                      fallback={noImage}
                      preview={false}
                    />
                  </Radio>
                  <Radio
                    value="https://premierroofingandexteriors.s3.us-east-2.amazonaws.com/storm_damage.png"
                    className={styles.postcardRadio}
                  >
                    <Image
                      width="100%"
                      src="https://premierroofingandexteriors.s3.us-east-2.amazonaws.com/storm_damage.png"
                      alt="pardon_our_dust"
                      fallback={noImage}
                      preview={false}
                    />
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          </div>
        </FormBody>
      )}
      {_projectId && (
        <FormControls className="sticky-footer">
          <div className={step !== 0 ? "hide" : ""}>
            <CancelButton handleClick={handleClose} />
            <Button type="primary" className="green" onClick={handleNext}>
              Next
            </Button>
          </div>

          <div className={step !== 1 ? "hide" : ""}>
            <Button
              type="default"
              onClick={handleBack}
              style={{ float: "left" }}
            >
              Back
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              loading={loading}
              disabled={loading}
            >
              Submit
            </Button>
          </div>
        </FormControls>
      )}
    </Form>
  );
};

const AddressListItem = ({ address, _id, setMarkers, setAddresses }) => {
  const handleListHover = () => {
    setMarkers((_markers) => {
      _markers[_id].marker.setIcon(
        new window.google.maps.MarkerImage(
          "https://s3.us-east-2.amazonaws.com/pr-crm/assets/red_marker.png",
          null,
          null,
          null,
          new window.google.maps.Size(24, 36)
        )
      );
      return _markers;
    });
  };

  const handleListUnHover = () => {
    setMarkers((_markers) => {
      _markers[_id].marker.setIcon(
        new window.google.maps.MarkerImage(
          "https://s3.us-east-2.amazonaws.com/pr-crm/assets/blue_marker.png",
          null,
          null,
          null,
          new window.google.maps.Size(24, 36)
        )
      );
      return _markers;
    });
  };

  const handleDelete = () => {
    setAddresses((_addresses) => {
      return _addresses.filter((a) => a._id !== _id);
    });
    setMarkers((_markers) => {
      _markers[_id].marker.setMap(null);
      delete _markers[_id];
      return _markers;
    });
  };

  return (
    <List.Item onMouseEnter={handleListHover} onMouseLeave={handleListUnHover}>
      <List.Item.Meta description={address} />
      <List.Item.Meta
        style={{ maxWidth: 32 }}
        description={
          <Button danger type="link" shape="circle" onClick={handleDelete}>
            <Delete />
          </Button>
        }
      />
    </List.Item>
  );
};

export default JobsiteTarget;
