import React from "react";
import BarChartWithDates from "Reports/BarChartWithDates";

export default function LeadsByOrigin() {
  return (
    <BarChartWithDates
      path="/reports/leads-by-campaign"
      summary={true}
      type="count"
      report="LEADS BY CAMPAIGN"
      headers={["Origin", "Total"]}
    />
  );
}
