import React, { useState, useEffect, memo } from "react";
import noImage from "Assets/ina.jpg";
import styles from "./styles.module.scss";
import usePrevious from "Hooks/usePrevious";

const Image = ({ image, alt, style }) => {
  const [state, setState] = useState({
    src:
      image && image.thumbnail && image.thumbnail !== undefined
        ? image.thumbnail
        : image && image.src && image.src !== undefined
        ? image.src
        : image
        ? image
        : "error",
    errCount: 0,
  });
  const prevImage = usePrevious(image);

  useEffect(() => {
    const src = image ? image.src : undefined;
    const prevSrc = prevImage ? prevImage.src : undefined;
    const thumbnail = image ? image.thumbnail : undefined;
    const prevThumbnail = prevImage ? prevImage.thumbnail : undefined;

    if (src !== prevSrc || thumbnail !== prevThumbnail) {
      setState({
        src:
          image && image.thumbnail && image.thumbnail !== undefined
            ? image.thumbnail
            : image && image.src && image.src !== undefined
            ? image.src
            : image
            ? image
            : "error",
        errCount: 0,
      });
    }
  }, [image, prevImage]);

  const fallback = async (evt) => {
    if (
      state.src !== "error" &&
      state.errCount === 0 &&
      typeof image !== "string" &&
      evt.target.src !== image.src &&
      image.src !== undefined
    ) {
      setState({
        src: image.src,
        errCount: 1,
      });
    } else {
      setState({
        src: noImage,
        errCount: 2,
      });
    }
  };

  return (
    <img
      key={Date.now()}
      alt={alt}
      src={state.src}
      onError={fallback}
      className={styles.image}
      style={style}
    />
  );
};

export default memo(Image);
