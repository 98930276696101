import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelected } from "Actions/dataActions";
import { openForm } from "Actions/formActions";
import List from "antd/es/list";
import dayjs from "dayjs";
import styles from "./styles.module.scss";
import currencyFormatter from "Utils/currencyFormatter";

const CollectionItem = ({
  style,
  _id,
  _projectId,
  customerId,
  customerName,
  address,
  identifier,
  collectionType,
  amount,
  headers,
  transactionDate,
  projectManager,
  billingAddressStreet,
  billingAddressCity,
  billingAddressState,
  billingAddressZip,
  projectAddressStreet,
  projectAddressCity,
  projectAddressState,
  projectAddressZip,
  projectAddress,
  src,
  scopes,
  callback,
}) => {
  const selectedRow = useSelector((state) => state.dataState.selectedRow);
  const dispatch = useDispatch();

  const onClick = useCallback(() => {
    dispatch(setSelected(_id));
    dispatch(
      openForm("collection", {
        _projectId,
        _id,
        customerId,
        amount,
        transactionDate,
        identifier,
        collectionType,
        customerName,
        projectManager,
        billingAddressStreet,
        billingAddressCity,
        billingAddressState,
        billingAddressZip,
        projectAddressStreet,
        projectAddressCity,
        projectAddressState,
        projectAddressZip,
        projectAddress,
        src,
        scopes,
        callback,
        editing: true,
      })
    );
  }, [
    dispatch,
    _projectId,
    _id,
    customerId,
    amount,
    transactionDate,
    identifier,
    collectionType,
    customerName,
    projectManager,
    billingAddressStreet,
    billingAddressCity,
    billingAddressState,
    billingAddressZip,
    projectAddressStreet,
    projectAddressCity,
    projectAddressState,
    projectAddressZip,
    projectAddress,
    src,
    scopes,
    callback,
  ]);

  return (
    <List.Item
      style={style}
      className={`${styles.row} ${selectedRow === _id ? styles.selected : ""}`}
      onClick={onClick}
    >
      <List.Item.Meta
        className={styles.col}
        style={{
          width: headers[0].width,
          textAlign: headers[0].align ? headers[0].align : "left",
        }}
        description={dayjs(transactionDate).format("MMM D, YYYY")}
      />
      <List.Item.Meta
        className={styles.col}
        style={{
          width: headers[1].width,
          textAlign: headers[1].align ? headers[1].align : "left",
        }}
        description={customerId}
      />
      <List.Item.Meta
        className={styles.col}
        style={{
          width: headers[2].width,
          textAlign: headers[2].align ? headers[2].align : "left",
        }}
        description={customerName}
      />
      <List.Item.Meta
        className={styles.col}
        style={{
          width: headers[3].width,
          textAlign: headers[3].align ? headers[3].align : "left",
        }}
        description={projectAddress}
      />
      <List.Item.Meta
        className={styles.col}
        style={{
          width: headers[4].width,
          textAlign: headers[4].align ? headers[4].align : "left",
        }}
        description={currencyFormatter(amount)}
      />
    </List.Item>
  );
};

export default CollectionItem;
