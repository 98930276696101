import React, { useRef, useState, useEffect, useCallback } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openImageViewer } from "Actions/dataActions";
import isPdf from "Utils/isPdf";
import LazyLoad from "Components/LazyLoad";
import hidePdfControls from "Utils/hidePdfControls";
import Card from "antd/es/card";
import styles from "./styles.module.scss";
import HorizontalScroll from "Components/HorizontalScroll";
import api from "API";
import pdf from "Assets/pdf.svg";
import openInNewTab from "Utils/openInNewTab";
import Carousel from "antd/es/carousel";
import ArrowLeft from "Icons/ArrowLeft";
import ArrowRight from "Icons/ArrowRight";
import Download from "Icons/Download";
import Delete from "Icons/Delete";
import Button from "antd/es/button";
import downloadFile from "Utils/downloadFile";
import Star from "Icons/Star";
import StarBorder from "Icons/StarBorder";
import message from "antd/es/message";
import Loader from "Components/Loader";
import Modal from "antd/es/modal";
import useIsMounted from "Hooks/useIsMounted";

const PreJobCheckMedia = () => {
  const params = useParams();
  const [documents, setDocuments] = useState([]);
  const [media, setMedia] = useState([]);
  const [scrollContainer, setScrollContainer] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentImage, setCurrentImage] = useState({});
  const isMounted = useIsMounted();

  const ref = useRef();
  const carouselRef = useRef();

  useEffect(() => {
    fetchMedia();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isMounted.current) {
      carouselAfterChange();
    }
  }, [isMounted, media]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchMedia = async () => {
    try {
      const res = await api.get(`/get-media/${params.id}`);
      let __media = [];
      let { _media } = res.data;
      for (const m of _media) {
        if (!isPdf(m.src)) __media.push(m);
      }

      unstable_batchedUpdates(() => {
        setDocuments(res.data._documents);
        setMedia(__media);
        setCurrentImage(_media[0]);
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const prev = () => {
    carouselRef.current.prev();
  };

  const next = () => {
    carouselRef.current.next();
  };

  const carouselAfterChange = () => {
    const slide = document.getElementsByClassName("slick-current")[0];
    if (slide) {
      const img = slide.getElementsByTagName("IMG")[0];
      const _imageId = img.getAttribute("data-imageid");
      let _image = media.find((m) => m._id === _imageId);
      setCurrentImage(_image);
    }
  };

  const download = () => {
    const slide = document.getElementsByClassName("slick-current")[0];
    const img = slide.getElementsByTagName("IMG")[0];
    saveFile(img.src);
  };

  async function getBase64(src, type) {
    try {
      const res = await api.post("/getBase64", {
        src,
        type,
      });
      return res.data;
    } catch (err) {
      console.log("err", err);
    }
  }

  async function saveFile(src, type = "image") {
    try {
      setLoading(true);
      const ext = src.split(".").pop();
      const base64 = await getBase64(src, type);
      downloadFile(base64, type, ext);
      setLoading(false);
    } catch (err) {
      console.log("err", err);
    }
  }

  const _setStar = async (e) => {
    try {
      e.stopPropagation();
      if (currentImage) {
        currentImage.preJobCheck = true;

        const res = await api.post("/activity/star-image", currentImage);

        let __media = [];
        let { _media } = res.data;
        for (const m of _media) {
          if (!isPdf(m.src)) __media.push(m);
        }

        unstable_batchedUpdates(() => {
          setDocuments(res.data._documents);
          setMedia(__media);
        });
      } else {
        message.error("Error starring image. Refresh page and try again.");
      }
    } catch (err) {
      console.log("err", err);
      message.error("Error starring image");
    }
  };

  const _removeStar = async (e) => {
    try {
      e.stopPropagation();

      if (currentImage) {
        const res = await api.post("/activity/remove-star", {
          _projectId: currentImage._projectId,
          preJobCheck: true,
        });

        let __media = [];
        let { _media } = res.data;
        for (const m of _media) {
          if (!isPdf(m.src)) __media.push(m);
        }

        unstable_batchedUpdates(() => {
          setDocuments(res.data._documents);
          setMedia(__media);
        });
      } else {
        message.error("Error removing star. Refresh page and try again.");
      }
    } catch (err) {
      console.log("err", err);
      message.error("Error removing star");
    }
  };

  function confirmDeleteImage(e) {
    e.stopPropagation();
    Modal.confirm({
      zIndex: 1001,
      centered: true,
      content: "Are you sure you want to delete this image?",
      onOk() {
        return new Promise((resolve, reject) => {
          deleteImage(() => resolve(true));
        }).catch((err) => {
          console.log("err", err);
        });
      },
      onCancel() {},
    });
  }

  const deleteImage = async (cb) => {
    try {
      if (currentImage) {
        currentImage.preJobCheck = true;

        await api.post("/activity/delete-image", {
          _id: currentImage._activityId,
          _projectId: currentImage._projectId,
          image: currentImage,
        });

        cb();

        fetchMedia();

        // let __media = [];
        // let { _media } = res.data;
        // for (const m of _media) {
        //   if (!isPdf(m.src)) __media.push(m);
        // }

        // unstable_batchedUpdates(() => {
        //   setDocuments(res.data._documents);
        //   setMedia(__media);
        // });
      } else {
        message.error("Error deleting image. Refresh page and try again.");
      }
    } catch (err) {
      console.log("err", err);
      message.error("Error deleting image");
    }
  };

  return (
    <div ref={setScrollContainer} className={styles.container}>
      {loading && (
        <div
          style={{
            position: "fixed",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            zIndex: 99999,
            background: "rgba(0,0,0,0.23)",
          }}
        >
          <Loader display={loading ? "flex" : "none"} minHeight="100%" />
        </div>
      )}
      <HorizontalScroll
        ref={ref}
        scrollContainer={scrollContainer}
        style={{ minHeight: 240 }}
      >
        {documents.map((d) => (
          <Document key={d._id} media={d} scrollContainer={ref} />
        ))}
      </HorizontalScroll>
      <div className={styles.carousel}>
        <div className={styles.actionBar}>
          {currentImage && currentImage.star ? (
            <Button
              type="text"
              size="large"
              shape="circle"
              className={`image-button star ${styles.actionButton}`}
              onClick={_removeStar}
            >
              <Star color="gold" />
            </Button>
          ) : (
            <Button
              type="text"
              size="large"
              shape="circle"
              className={`image-button star ${styles.actionButton}`}
              onClick={_setStar}
            >
              <StarBorder />
            </Button>
          )}

          <Button
            type="text"
            shape="circle"
            size="large"
            className={`image-button star ${styles.actionButton}`}
            onClick={download}
          >
            <Download />
          </Button>

          <Button
            type="text"
            shape="circle"
            size="large"
            className={`image-button star ${styles.actionButton}`}
            onClick={confirmDeleteImage}
          >
            <Delete color="#FF0000" />
          </Button>
        </div>
        <div className={styles.prev} onClick={prev}>
          <ArrowLeft color="#ffffff" />
        </div>
        <div className={styles.next} onClick={next}>
          <ArrowRight color="#ffffff" />
        </div>
        <Carousel
          ref={carouselRef}
          infinite={false}
          afterChange={carouselAfterChange}
        >
          {media.map((m) => {
            if (!isPdf(m.src)) {
              return (
                <div key={m._id} className={styles.slide}>
                  <img
                    data-imageid={m._id}
                    src={m.src}
                    alt="prejob-check-media"
                  />
                </div>
              );
            }
            return null;
          })}
        </Carousel>
      </div>
    </div>
  );
};

const Document = ({ media, scrollContainer }) => {
  const dispatch = useDispatch();

  const type = isPdf(media.src) ? "pdf" : "image";

  const handlePdfClick = () => {
    openInNewTab(media.src);
  };

  const handleImageOrVideoClick = useCallback(() => {
    media.viewOnly = true;
    media.link = true;
    dispatch(openImageViewer(media));
  }, [dispatch, media]);

  return (
    <Card
      bordered={false}
      hoverable
      className={styles.activity}
      bodyStyle={{ padding: 0 }}
      style={{ padding: 0 }}
      cover={
        <>
          <div
            className={styles.clickOverlay}
            onClick={type === "pdf" ? handlePdfClick : handleImageOrVideoClick}
          />
          {type === "pdf" ? (
            <LazyLoad
              offsetHorizontal={600}
              height="240px"
              root={scrollContainer}
            >
              <object data={hidePdfControls(media.src)} className={styles.pdf}>
                <div>
                  <img src={pdf} alt="pdf-icon" />
                  <div>{media.name}</div>
                </div>
              </object>
            </LazyLoad>
          ) : (
            <LazyLoad
              offsetHorizontal={600}
              height="240px"
              root={scrollContainer}
            >
              <img
                className={styles.image}
                src={media.thumbnail ? media.thumbnail : media.src}
                alt="activity"
              />
            </LazyLoad>
          )}
        </>
      }
    ></Card>
  );
};

export default PreJobCheckMedia;
