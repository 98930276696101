import React from "react";
import percentFormatter from "Utils/percentFormatter";
import Skeleton from "antd/es/skeleton";
import styles from "./styles.module.scss";

const DealFlowSider = ({ leads, sold, soldConversion, loading }) => {
  return (
    <div className={styles.container}>
      <Skeleton
        loading={loading}
        active
        paragraph={{ rows: 0 }}
        title={{ width: "100%" }}
      >
        <div className={styles.innerContainer}>
          <p className={styles.dealsIn}>{leads}</p>
          <div className={styles.topSpacer}></div>
          <div className={styles.centerSpacer}>
            <p className={styles.centerNumber}>
              {percentFormatter(soldConversion)}
            </p>
            <p className={styles.centerLabel}>Conversion Rate</p>
          </div>
          <p>{sold}</p>
        </div>
      </Skeleton>
    </div>
  );
};

export default DealFlowSider;
