import React from "react";
import LineChartWithInterval from "Reports/LineChartWithInterval";

export default function CompanyExpenses() {
  return (
    <LineChartWithInterval
      path="/reports/adminCompanyExpenses"
      type="currency"
      summary={true}
      lineItems={true}
      aggregate={false}
      report="COMPANY EXPENSES"
      headers={["Vendor", "Total"]}
    />
  );
}
