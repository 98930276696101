import React, { useRef } from "react";
import Select from "antd/es/select";
import useIsMobile from "Hooks/useIsMobile";

const InputSelect = ({
  value,
  onChange,
  placeholder = undefined,
  mode = "default",
  options = [],
  handleSelectChange,
  disabled = false,
  autoComplete = "dont-use-autocomplete",
  onClick,
  id = undefined,
  listHeight = 256,
  autoFocus,
  style = {},
}) => {
  const mobile = useIsMobile();
  const ref = useRef();

  const triggerChange = (changedValue) => {
    if (onChange) {
      onChange(changedValue);
    }
  };

  const handleChange = (values) => {
    if (ref.current) ref.current.blur();
    triggerChange(values);
    if (handleSelectChange) handleSelectChange(values);
  };

  return (
    <Select
      ref={ref}
      autoComplete={autoComplete}
      allowClear
      mode={mode}
      showSearch={false}
      dropdownClassName={mobile ? "isMobile" : null}
      getPopupContainer={(trigger) =>
        !mobile ? trigger.parentNode : document.body
      }
      onChange={handleChange}
      value={value}
      placeholder={placeholder}
      disabled={disabled}
      onClick={onClick}
      id={id}
      listHeight={listHeight === 256 ? 256 : mobile ? 256 : listHeight}
      autoFocus={autoFocus}
      style={style}
    >
      {options.map((o) => {
        if (typeof o === "object" && o !== null) {
          return (
            <Select.Option key={o.value} value={o.value}>
              {o.title}
            </Select.Option>
          );
        } else {
          return (
            <Select.Option key={o} value={o}>
              {o}
            </Select.Option>
          );
        }
      })}
    </Select>
  );
};

export default InputSelect;
