import React, { useState, useEffect } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useSelector } from "react-redux";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Typography from "antd/es/typography";
import Button from "antd/es/button";
import Form from "antd/es/form";
import Grid from "antd/es/grid";
import message from "antd/es/message";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import Add from "Icons/Add";
import Remove from "Icons/Remove";
import Save from "Icons/Save";
import styles from "./styles.module.scss";
import api from "API";
import downloadFile from "Utils/downloadFile";
import NamingTemplateModal from "Components/NamingTemplateModal";
import TemplatesDropdown from "Components/TemplatesAndFiltersDropdown";
import MaterialColumnHeaders from "Components/materialOrderQuote/MaterialColumnHeaders";
import MaterialRow from "Components/materialOrderQuote/MaterialRow";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";
import Loader from "Components/Loader";

const { useBreakpoint } = Grid;
const messageKey = "messageKey";

const MaterialQuote = ({ unsavedChanges, setUnsavedChanges }) => {
  const screens = useBreakpoint();
  const name = useSelector((state) => state.authState.name);
  const phone = useSelector((state) => state.authState.phone);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [globalTemplates, setGlobalTemplates] = useState([]);
  const [userTemplates, setUserTemplates] = useState([]);
  const [saveModal, setSaveModal] = useState(false);
  const [selected, setSelected] = useState(undefined);
  const [startingRows, setStartingRows] = useState(0);

  useEffect(() => {
    fetchTemplates();

    form.setFieldsValue({
      lineItems: [{}],
    });
    setStartingRows(0);
    return () => {
      form.resetFields();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    handleSelectedChange();
  }, [selected]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectedChange = async () => {
    try {
      if (!selected) {
        const lineItems = await form.getFieldValue("lineItems");
        lineItems.forEach((m, i) => {
          if (m.disabled) {
            form.validateFields([
              ["lineItems", i, "material"],
              ["lineItems", i, "type"],
              ["lineItems", i, "color"],
              ["lineItems", i, "quantity"],
            ]);
          }
        });
      }
    } catch (err) {
      console.log("handleSelectedChange err", err);
    }
  };

  async function fetchTemplates() {
    try {
      const res = await api.get("/templates");
      unstable_batchedUpdates(() => {
        setGlobalTemplates(res.data._global);
        setUserTemplates(res.data._user);
      });
    } catch (err) {
      console.log("err", err);
    }
  }

  const onFinish = async (values) => {
    try {
      setLoading(true);
      // message.loading("Creating material quote...", 0);
      message.loading({
        content: "Creating material quote...",
        duration: 0,
        key: messageKey,
      });
      const res = await api.post("/downloadMaterialQuote", {
        lineItems: values.lineItems,
        projectManager: values.projectManager,
        phone: values.phone,
      });
      downloadFile(res.data, "material_quote", "pdf");
      unstable_batchedUpdates(() => {
        setUnsavedChanges(false);
        setLoading(false);
      });
      // message.success("Material quote downloaded");
      message.success({
        content: "Material quote downloaded",
        duration: 1.5,
        key: messageKey,
      });
    } catch (err) {
      console.log("err", err);
      if (
        err &&
        err.errorFields &&
        err.errorFields[0] &&
        err.errorFields[0].name
      ) {
        if (err.errorFields[0].name[0] === "lineItems") {
          const el = document.getElementById("top-of-form");
          el.scrollIntoView({ block: "end", behavior: "smooth" });
        } else {
          form.scrollToField(err.errorFields[0].name[0]);
        }
      }
      // message.error("An error occured while downloading");
      message.error({
        content: "Error downloading material quote",
        duration: 1.5,
        key: messageKey,
      });
      setLoading(false);
    }
  };

  const onFinishFailed = (err) => {
    console.log("err", err);
    if (
      err &&
      err.errorFields &&
      err.errorFields[0] &&
      err.errorFields[0].name
    ) {
      if (err.errorFields[0].name[0] === "lineItems") {
        const el = document.getElementById("top-of-form");
        el.scrollIntoView({ block: "end", behavior: "smooth" });
      } else {
        form.scrollToField(err.errorFields[0].name[0]);
      }
    }
    setLoading(false);
  };

  const setTemplate = async (template) => {
    try {
      let lineItems = await form.getFieldValue("lineItems");
      if (!lineItems) lineItems = [];
      lineItems = [...lineItems, ...template.material];
      form.setFieldsValue({
        lineItems,
      });
      setUnsavedChanges(true);
    } catch (err) {
      console.log("setTemplate err", err);
    }
  };

  const removeRow = async () => {
    try {
      let lineItems = await form.getFieldValue("lineItems");
      lineItems = lineItems.filter((m, i) => {
        if (!m.checkbox || (m.checkbox === true && i + 1 <= startingRows)) {
          return true;
        }
        return false;
      });
      lineItems.forEach((m, i) => {
        if (m.checkbox) {
          m.disabled = true;
          m.checkbox = false;
        }
      });
      form.setFieldsValue({
        lineItems,
      });
      setSelected(undefined);
    } catch (err) {
      console.log("removeRow err", err);
    }
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  const onFieldsChange = async (fields) => {
    try {
      if (fields.length > 0 && fields[0].name[2] === "checkbox") {
        let lineItems = await form.getFieldValue("lineItems");
        if (lineItems) {
          let arr = [];
          let cnt = 0;
          for (const m of lineItems) {
            if (m.checkbox) {
              arr.push(cnt);
              cnt++;
            }
          }
          if (arr.length === 0) arr = undefined;
          setSelected(arr);
        }
      }
    } catch (err) {
      console.log("onFieldsChange err", err);
    }
  };

  const setTemplates = ({ _global, _user }) => {
    setGlobalTemplates(_global);
    setUserTemplates(_user);
  };

  const clearForm = () => {
    form.setFieldsValue({
      lineItems: [{}],
    });
    setUnsavedChanges(false);
  };

  const showSaveModal = () => {
    setSaveModal(true);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      initialValues={{ projectManager: name, phone }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      className="form"
      onValuesChange={onValuesChange}
      onFieldsChange={onFieldsChange}
    >
      {loading && (
        <div
          style={{
            position: "fixed",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            zIndex: 99999,
            background: "rgba(0,0,0,0.23)",
          }}
        >
          <Loader display={loading ? "flex" : "none"} minHeight="100%" />
        </div>
      )}
      <FormBody>
        <div className="content-inner p24">
          <div id="top-of-form" />
          <RemoveAutocomplete />
          <Row
            gutter={{ xs: 0, sm: 8, md: 12, lg: 24 }}
            className={styles.mb24}
          >
            <Col xs={24} sm={16}>
              <Form.Item
                label="Project Owner"
                name="projectManager"
                rules={[{ required: true }]}
                style={{ marginBottom: 12 }}
              >
                <InputNoAutoComplete id="projectManager" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item
                style={{ marginRight: 0, marginBottom: 0 }}
                label="Phone"
                name="phone"
                rules={[{ required: true }]}
              >
                <InputNoAutoComplete id="phone" />
              </Form.Item>
            </Col>
          </Row>

          <div id="top-of-form" />

          <Typography.Title level={!screens.xs ? 2 : 4}>
            Materails To Be Quoted
          </Typography.Title>
          {!screens.xs && (
            <MaterialColumnHeaders
              form={form}
              selected={selected}
              setSelected={setSelected}
            />
          )}

          <Form.List name="lineItems">
            {(fields, { add, remove }) => {
              const handleAdd = () => {
                add();
              };

              const handleRemove = (i) => {
                remove(i);
              };

              return (
                <>
                  {fields.map((field, index) => (
                    <MaterialRow
                      quote={true}
                      key={field.key}
                      fields={fields}
                      field={field}
                      index={index}
                      form={form}
                      selected={selected}
                      handleRemove={handleRemove}
                    />
                  ))}
                  <Row gutter={{ xs: 8, sm: 8, md: 12, lg: 24 }}>
                    {!selected ? (
                      <Col xs={24}>
                        <Form.Item style={{ marginRight: 0 }}>
                          <Button
                            className={`${styles.button} green`}
                            style={
                              screens.lg
                                ? { marginTop: 12 }
                                : screens.md
                                ? { marginTop: 6 }
                                : { marginTop: 12 }
                            }
                            type="primary"
                            onClick={handleAdd}
                            block
                          >
                            <Add size={18} /> Add Material
                          </Button>
                        </Form.Item>
                      </Col>
                    ) : (
                      <>
                        <Col xs={12}>
                          <Form.Item>
                            <Button
                              className={styles.button}
                              style={
                                screens.lg
                                  ? { marginTop: 12 }
                                  : screens.md
                                  ? { marginTop: 6 }
                                  : { marginTop: 12 }
                              }
                              danger
                              type="primary"
                              onClick={removeRow}
                              block
                            >
                              <Remove size={18} /> Remove Selected
                            </Button>
                          </Form.Item>
                        </Col>
                        <Col xs={12}>
                          <Form.Item>
                            <Button
                              className={styles.button}
                              style={
                                screens.lg
                                  ? { marginTop: 12 }
                                  : screens.md
                                  ? { marginTop: 6 }
                                  : { marginTop: 12 }
                              }
                              type="primary"
                              onClick={showSaveModal}
                              block
                            >
                              <Save size={18} /> Save As Template
                            </Button>
                          </Form.Item>
                        </Col>
                      </>
                    )}
                  </Row>
                </>
              );
            }}
          </Form.List>
        </div>
      </FormBody>
      <FormControls className="sticky-footer">
        <TemplatesDropdown
          type="Template"
          btnStyle={{ float: "left" }}
          userArray={userTemplates}
          globalArray={globalTemplates}
          onClick={setTemplate}
          setArray={setUserTemplates}
        />
        <Button
          style={{ marginRight: !screens.xs ? 24 : 8 }}
          onClick={clearForm}
        >
          Clear
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={loading}
        >
          Download
        </Button>
      </FormControls>

      <NamingTemplateModal
        visible={saveModal}
        onClose={setSaveModal}
        update={setTemplates}
        fieldName="lineItems"
        form={form}
        setSelected={setSelected}
      />
    </Form>
  );
};

export default MaterialQuote;
