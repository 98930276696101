import React, { useState, useEffect, useCallback } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateDetail } from "Actions/dataActions";
import Form from "antd/es/form";
import Button from "antd/es/button";
import message from "antd/es/message";
import CopyableContent from "Components/CopyableContent";
import usePrevious from "Hooks/usePrevious";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import api from "API";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";

const CustomerName = ({
  value,
  _id,
  onChange,
  setStringToCopy,
  stringToCopy,
  single = false,
}) => {
  const dispatch = useDispatch();
  const viewableActivities = useSelector(
    (state) => state.dataState.viewableActivities
  );
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const prevValue = usePrevious(value);

  const [form] = Form.useForm();

  useEffect(() => {
    if (!prevValue && value) form.setFieldsValue({ customerName: value });
  }, [prevValue, value]); // eslint-disable-line react-hooks/exhaustive-deps

  const onFinish = useCallback(
    async (values) => {
      try {
        setLoading(true);
        values._id = _id;
        values.previous = value;

        if (values.customerName !== value) {
          const res = await api.post("/update/customerName", values);

          dispatch(
            updateDetail({
              viewableActivities,
              key: "customerName",
              value: res.data.value,
              activities: res.data.activities,
            })
          );

          if (onChange) {
            onChange({
              value: res.data.value,
            });
          }

          message.success("Customer Name updated");
        }
        unstable_batchedUpdates(() => {
          setLoading(false);
          setEdit(false);
        });
      } catch (err) {
        console.log("err", err);
        message.error("Error updating Customer Name");
        setLoading(false);
      }
    },
    [_id, value, dispatch, viewableActivities, onChange]
  );

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error updating Customer Name");
  };

  const toggleEdit = () => {
    setEdit(!edit);
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      initialValues={{ customerName: value }}
      style={{ marginBottom: 16 }}
    >
      <RemoveAutocomplete />
      <h4 className="withIcon" style={{ marginBottom: 3 }}>
        Customer Name
        <CopyableContent
          title="Customer Name"
          content={value}
          setStringToCopy={setStringToCopy}
          stringToCopy={stringToCopy}
          edit={edit}
          single={single}
        />
      </h4>
      {!edit && (
        <p
          className="editableRow"
          style={{ marginBottom: 0 }}
          onClick={toggleEdit}
        >
          <span>{value}</span>
        </p>
      )}

      {edit && (
        <>
          <Form.Item
            name="customerName"
            rules={[{ required: true, message: "Customer Name is required" }]}
          >
            <InputNoAutoComplete
              autoFocus
              placeholder="Customer Name"
              id="customerName"
            />
          </Form.Item>

          <div className="editControls">
            <Button
              type="default"
              size="small"
              onClick={toggleEdit}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              size="small"
              loading={loading}
              disabled={loading}
            >
              Submit
            </Button>
          </div>
        </>
      )}
    </Form>
  );
};

export default CustomerName;
