import React from "react";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Form from "antd/es/form";
import Input from "antd/es/input";

const NotesTermsConditions = () => {
  return (
    <Row gutter={16}>
      <Col xs={24}>
        <Form.Item name="notes" label="Notes">
          <Input.TextArea rows={4} />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default NotesTermsConditions;
