import React, { useState, useEffect } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Form from "antd/es/form";
import message from "antd/es/message";
import Button from "antd/es/button";
import Steps from "antd/es/steps";
import Alert from "antd/es/alert";
import Row from "antd/es/row";
import Col from "antd/es/col";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import Details from "./Details";
import Mold from "./Mold";
import Scopes from "./Scopes";
import Add from "Icons/Add";
import styles from "./styles.module.scss";
import api from "API";
import currencyFormatter from "Utils/currencyFormatter";
import CancelButton from "Components/CancelButton";
// import useProjectManagers from "Hooks/useProjectManagers";

const messageKey = "messageKey";

const LeadSold = ({ unsavedChanges, setUnsavedChanges, handleClose, type }) => {
  const formDetails = useSelector(
    (state) => state.formState.leadSold.formDetails
  );
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [sales, setSales] = useState({});
  const [scopes, setScopes] = useState([]);
  const [premierWarranties, setPremierWarranties] = useState([]);
  const [scopeTotal, setScopeTotal] = useState(0);
  // const projectManagers = useProjectManagers();

  useEffect(() => {
    fetchOptions();

    setTimeout(() => {
      const el = document.getElementById("steps");
      el.classList.remove("ant-steps-vertical");
      el.classList.add("ant-steps-horizontal");
      el.classList.add("ant-steps-label-vertical");
    });

    const total =
      formDetails.scopeAllocations && formDetails.scopeAllocations.length > 0
        ? formDetails.scopeAllocations.reduce((a, b) => a + b, 0)
        : 0;
    setScopeTotal(total);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const scrollRef = document.getElementById("create-lead-scroll");
    scrollRef.scrollTop = 0;
  }, [step]);

  const fetchOptions = async () => {
    try {
      const res = await api.post("/dropdowns/get", {
        selects: ["Scopes", "Premier Warranties"],
      });

      unstable_batchedUpdates(() => {
        setScopes(res.data.Scopes.options);
        setPremierWarranties(res.data["Premier Warranties"].options);
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    let obj = {};
    if (formDetails.salesObj) {
      for (const s of formDetails.salesObj) {
        obj[s._user] = {
          _user: s._user,
          name: s.name,
          email: s.email,
          phone: s.phone,
        };
      }

      setSales(obj);
    }
  }, [formDetails.salesObj]);

  const onFinish = async (values) => {
    try {
      const contractPrice = values.contractPrice;
      let totalScopeValue = 0;
      for (const scope of values.scopes) {
        totalScopeValue += scope.amount;
      }

      if (
        contractPrice.toFixed(2).toString() !==
        totalScopeValue.toFixed(2).toString()
      ) {
        message.error({
          content: "Contract Price does not equal Total Scope Amount",
          duration: 1.5,
          key: messageKey,
        });
        return;
      }

      setLoading(true);
      // message.loading("Moving Lead to Sold...", 0);
      message.loading({
        content: "Marking Lead as Sold...",
        duration: 0,
        key: messageKey,
      });
      values._id = formDetails._id;
      values.projectManager = sales[values.projectManager];

      values.estimateDate = formDetails.estimateDate;
      values.estimateValue = formDetails.estimateValue;

      // for (const scope of values.scopes) {
      //   if (scope.installProjectManager) {
      //     scope.installProjectManager =
      //       projectManagers[scope.installProjectManager];
      //   }
      // }

      const res = await api.post("/leads/sold", values);

      if (res.status === 200) {
        handleClose(true);
        // message.success("Lead marked Sold");
        message.success({
          content: "Lead marked Sold",
          duration: 1.5,
          key: messageKey,
        });
        navigate(`/projects/view/details/${formDetails._id}`);
      } else {
        // message.error("Error marking Lead as Sold");
        message.error({
          content: "Error marking Lead as Sold",
          duration: 1.5,
          key: messageKey,
        });
      }
    } catch (err) {
      setLoading(false);
      console.log("err", err);
      message.error({
        content: "Error marking Lead as Sold",
        duration: 1.5,
        key: messageKey,
      });
    }
  };

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error marking Lead as Sold");
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  const detailsNext = () => {
    form
      .validateFields([
        "permits",
        "projectDescription",
        "contractDate",
        "builderPermits",
      ])
      .then((values) => {
        setStep((thisStep) => thisStep + 1);
      })
      .catch((err) => {
        message.error("All required fields must be completed");
      });
  };

  const moldNext = () => {
    form
      .validateFields([
        "contractPrice",
        "expectedMaterials",
        "expectedLabor",
        "expectedDumping",
        "expectedExtras",
        "expectedInsulation",
        "expectedGutters",
      ])
      .then((values) => {
        setStep((thisStep) => thisStep + 1);
      })
      .catch((err) => {
        message.error("All required fields must be completed");
      });
  };

  const prev = () => {
    setStep((thisStep) => thisStep - 1);
  };

  const renderScopes = () => {
    let _scopes = [];
    formDetails.scopes.map((s, i) => {
      _scopes.push({
        name: s,
        amount:
          formDetails.scopeAllocations &&
          formDetails.scopeAllocations.length > 0 &&
          formDetails.scopeAllocations[i]
            ? formDetails.scopeAllocations[i]
            : 0,
        downPayment: undefined,
        premierWarranty: undefined,
        extendedWarranty: undefined,
      });
      return s;
    });
    return _scopes;
  };

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      className="form"
      initialValues={{
        mccEligible: true,
        projectManager: formDetails.projectManager
          ? formDetails.projectManager._user
          : undefined,
        scopes: formDetails.scopes ? renderScopes() : undefined,
        contractPrice: formDetails.estimateValue,
      }}
    >
      <RemoveAutocomplete />

      <FormBody
        id="create-lead-scroll"
        className="content-inner"
        style={{
          paddingLeft: 24,
          paddingTop: 24,
          paddingRight: 24,
          paddingBottom: 8,
          maxHeight: type === "modal" ? "calc(100vh - 161px)" : "unset",
        }}
      >
        <Steps
          id="steps"
          className={styles.soldSteps}
          labelPlacement="vertical"
          size="small"
          current={step}
          style={{ marginBottom: 24 }}
        >
          <Steps.Step title="Details" />
          <Steps.Step title="Mold" />
          <Steps.Step title="Scopes" />
        </Steps>

        <div className={step !== 0 ? "hide" : ""}>
          <Details
            projectManager={formDetails.projectManager}
            sales={sales}
            form={form}
            propertyType={formDetails.propertyType}
          />
        </div>

        <div className={step !== 1 ? "hide" : ""}>
          <Mold
            form={form}
            propertyType={formDetails.propertyType}
            step={step}
          />
        </div>

        <div className={step !== 2 ? "hide" : ""}>
          <Form.List name="scopes">
            {(fields, { add, remove }) => {
              const handleAdd = () => {
                add({
                  name: undefined,
                  downPayment: undefined,
                  extendedWarranty: undefined,
                  amount: undefined,
                  premierWarranty: undefined,
                });
              };

              return (
                <>
                  {fields.map((field) => (
                    <Scopes
                      key={`${field.key}-lead-sold-scopes`}
                      form={form}
                      field={field}
                      fields={fields}
                      scopes={scopes}
                      remove={remove}
                      premierWarranties={premierWarranties}
                      scopeTotal={scopeTotal}
                      setScopeTotal={setScopeTotal}
                      // projectManagers={projectManagers}
                    />
                  ))}

                  <Form.Item style={{ marginRight: 0 }}>
                    <Button
                      className="green"
                      style={{ marginTop: 12 }}
                      type="primary"
                      onClick={handleAdd}
                      block
                    >
                      <Add size={18} /> Add Scope
                    </Button>
                  </Form.Item>
                </>
              );
            }}
          </Form.List>

          <Alert
            style={{ marginTop: 24 }}
            type="info"
            message={
              <Row gutter={16}>
                <Col xs={24} sm={12}>
                  <div>
                    <b>Scope Total:</b>
                  </div>
                  <div>{currencyFormatter(scopeTotal)}</div>
                </Col>
                <Col xs={24} sm={12}>
                  <div>
                    <b>Contract Price:</b>
                  </div>
                  <div>
                    {isNaN(form.getFieldValue("contractPrice"))
                      ? "$0.00"
                      : currencyFormatter(form.getFieldValue("contractPrice"))}
                  </div>
                </Col>
              </Row>
            }
          />
        </div>
      </FormBody>
      <FormControls className="sticky-footer">
        <CancelButton handleClick={handleClose} />

        {step === 0 && (
          <Form.Item style={{ margin: 0 }}>
            <Button type="primary" onClick={detailsNext}>
              Next
            </Button>
          </Form.Item>
        )}

        {step === 1 && (
          <>
            <Form.Item
              style={{
                marginRight: 8,
                marginBottom: 0,
                display: "inline-block",
              }}
            >
              <Button onClick={prev}>Previous</Button>
            </Form.Item>
            <Form.Item style={{ margin: 0, display: "inline-block" }}>
              <Button type="primary" onClick={moldNext}>
                Next
              </Button>
            </Form.Item>
          </>
        )}

        {step === 2 && (
          <>
            <Form.Item
              style={{
                marginRight: 8,
                marginBottom: 0,
                display: "inline-block",
              }}
            >
              <Button onClick={prev}>Previous</Button>
            </Form.Item>
            <Form.Item style={{ margin: 0, display: "inline-block" }}>
              <Button
                htmlType="submit"
                type="primary"
                loading={loading}
                disabled={loading || !unsavedChanges}
              >
                Submit
              </Button>
            </Form.Item>
          </>
        )}
      </FormControls>
    </Form>
  );
};

export default LeadSold;
