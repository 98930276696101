import React from "react";
import Card from "antd/es/card";
import List from "antd/es/list";
import Switch from "antd/es/switch";
import styles from "./styles.module.scss";
import api from "API";
import { useSelector, useDispatch } from "react-redux";
import { setEmailNotifications } from "Actions/authActions";

const EmailNotificationSwitch = () => {
  const dispatch = useDispatch();
  const emailNotifications = useSelector(
    (state) => state.authState.emailNotifications
  );

  const handleClick = async (e) => {
    try {
      let route = "/email-notifications";
      if (e) {
        await api.get(route + "/true");
        dispatch(setEmailNotifications("true"));
        localStorage.setItem("emailNotifications", "true");
      } else {
        await api.get(route + "/false");
        dispatch(setEmailNotifications("false"));
        localStorage.setItem("emailNotifications", "false");
      }
    } catch (err) {
      console.log("handleClick err", err);
    }
  };

  return (
    <Card
      className="z-depth-1"
      bordered={false}
      style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
    >
      <List bordered={false} className={styles.noMarginTitle}>
        <List.Item>
          <List.Item.Meta title="Email Notifications" />
          <List.Item.Meta
            description={
              <Switch
                checked={emailNotifications === "true"}
                // onChange={handleChange}
                onClick={handleClick}
                checkedChildren="ON"
                unCheckedChildren="OFF"
              />
            }
            style={{ textAlign: "right" }}
          />
        </List.Item>
      </List>
    </Card>
  );
};

export default EmailNotificationSwitch;
