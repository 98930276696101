import React, { useCallback, useEffect, useState, useRef } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useSelector } from "react-redux";
import styles from "./styles.module.scss";
import api from "API";
import Grid from "antd/es/grid";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Affix from "antd/es/affix";
import useViewShow from "Hooks/useViewShow";
import ViewProject from "Screens/ViewProject";
import ViewContainer from "Components/ViewContainer";
import FormContainer from "Forms/FormContainer";
import message from "antd/es/message";
import Clipboard from "react-clipboard.js";
import dayjs from "dayjs";
import isTomorrow from "dayjs/plugin/isTomorrow";
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";
import ReScheduleScope from "Forms/ReScheduleScope";
import Carousel from "antd/es/carousel";
import useIsMounted from "Hooks/useIsMounted";

import MobileScope from "./MobileScope";
import Scope from "./Scope";

import Loader from "Components/Loader";

import smoothlyAnimatePanTo from "Utils/smoothlyAnimatePanTo";
import Button from "antd/es/button";
import generateMarkerUrl from "Utils/generateMarkerUrl";
import MapKey from "Components/MapKey";

dayjs.extend(isTomorrow);
dayjs.extend(isToday);
dayjs.extend(isYesterday);

const { useBreakpoint } = Grid;

let dateStamp;

const Schedule = () => {
  const rescheduleScope = useSelector(
    (state) => state.formState.rescheduleScope.open
  );
  const install = useSelector((state) => state.authState.install);
  const admin = useSelector((state) => state.authState.admin);
  const role = useSelector((state) => state.authState.role);
  const manager = useSelector((state) => state.authState.manager);
  const _user = useSelector((state) => state.authState._user);
  const screens = useBreakpoint();
  const [scopes, setScopes] = useState([]);
  const [container, setContainer] = useState(null);
  const [markers, setMarkers] = useState({});
  const [scheduleMap, setScheduleMap] = useState();
  const [mapAvailable, setMapAvailable] = useState(
    window.google && window.google.maps && window.google.maps.Map ? true : false
  );
  const [copyObject, setCopyObject] = useState({});
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState("Showing ALL");
  const [typeFilters, setTypeFilters] = useState([
    "roofing",
    "roofing-flagged",
    "roofing-nc",
    "siding",
    "siding-nc",
  ]);

  const showProject = useViewShow(2, "projects", 3, "view");
  const isMounted = useIsMounted();

  const carouselRef = useRef();

  const mounted = isMounted.current;

  useEffect(() => {
    let timer;
    if (!mapAvailable) {
      timer = setTimeout(() => {
        if (window.google && window.google.maps && window.google.maps.Map) {
          setMapAvailable(true);
        }
      }, 500);
    } else {
      if (timer) clearTimeout(timer);
    }
  }, [mapAvailable]);

  const removePins = useCallback(async () => {
    try {
      for (const s of scopes) {
        if (s._id && markers[s._id] && markers[s._id].marker) {
          markers[s._id].marker.setMap(null);
        }
      }
    } catch (err) {
      console.log("err", err);
    }
  }, [markers, scopes]);

  useEffect(() => {
    if (!window.socket.hasListeners("updateSchedule"))
      window.socket.removeAllListeners("updateSchedule");
    window.socket.on("updateSchedule", async () => {
      const res = await api.get("/schedule");
      await removePins();
      unstable_batchedUpdates(() => {
        dateStamp = undefined;
        setScopes(res.data);
        setMarkers({});
      });
    });

    return () => {
      window.socket.removeAllListeners("updateSchedule");
    };
  }, [scopes, removePins]);

  useEffect(() => {
    if (mounted && mapAvailable) {
      fetchScopes();
      mapInit();
    }
  }, [mounted, mapAvailable]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (scheduleMap && scopes) {
      removePins();
      pinInit();
    }
  }, [scheduleMap, scopes, typeFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchScopes = async () => {
    try {
      const res = await api.get("/schedule");
      await removePins();
      unstable_batchedUpdates(() => {
        dateStamp = undefined;
        setScopes(res.data);
        setMarkers({});
        setLoading(false);
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const fetchMyScopes = async () => {
    try {
      const res = await api.get("/mySchedule");
      await removePins();
      unstable_batchedUpdates(() => {
        dateStamp = undefined;
        setScopes(res.data);
        setMarkers({});
        setLoading(false);
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const mapInit = () => {
    setScheduleMap(
      new window.google.maps.Map(document.getElementById("scheduleMap"), {
        center: { lat: 42.963795, lng: -85.670006 },
        zoom: 9,
        gestureHandling: "greedy",
        disableDefaultUI: true,
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        zoomControl: false,
        defaultMapTypeId: "hybrid",
        mapTypeId: "hybrid",
        tilt: 0,
      })
    );
  };

  const pinInit = () => {
    let _markers = {};
    scopes.map((s) => {
      if (filterScopes(s)) {
        if (
          s._id &&
          s.workOrder &&
          s.workOrder.projectAddressLat &&
          s.workOrder.projectAddressLng
        ) {
          const scope = s.name === "Siding" ? "siding" : "roofing";
          const nc =
            s.workOrder && s.workOrder.propertyType === "New Construction"
              ? true
              : false;
          const flag =
            s.workOrder.difficultyRating === 4 ||
            s.workOrder.difficultyRating === 4.5 ||
            s.workOrder.flag === true
              ? true
              : false;
          const url = generateMarkerUrl({
            type: "schedule",
            scope,
            nc,
            flag,
          });

          _markers[s._id] = {};
          _markers[s._id].marker = new window.google.maps.Marker({
            position: {
              lat: s.workOrder.projectAddressLat,
              lng: s.workOrder.projectAddressLng,
            },
            map: scheduleMap,
            draggable: false,
            icon: new window.google.maps.MarkerImage(
              url,
              null,
              null,
              null,
              new window.google.maps.Size(36, 36)
            ),
            zIndex: 1,
          });

          _markers[s._id].mouseover = window.google.maps.event.addListener(
            _markers[s._id].marker,
            "mouseover",
            function () {
              const scope = s.name === "Siding" ? "siding" : "roofing";
              const nc =
                s.workOrder && s.workOrder.propertyType === "New Construction"
                  ? true
                  : false;
              const flag =
                s.workOrder.difficultyRating === 4 ||
                s.workOrder.difficultyRating === 4.5 ||
                s.workOrder.flag === true
                  ? true
                  : false;
              const url = generateMarkerUrl({
                type: "schedule",
                scope,
                nc,
                active: true,
                flag,
              });

              const _scope = document.getElementById(s._id);

              if (_scope) _scope.classList.add("hover");
              setMarkers((_markers) => {
                _markers[s._id].marker.setIcon(
                  new window.google.maps.MarkerImage(
                    url,
                    null,
                    null,
                    null,
                    new window.google.maps.Size(36, 36)
                  )
                );
                _markers[s._id].marker.setZIndex(10);
                return _markers;
              });
            }
          );

          _markers[s._id].mouseout = window.google.maps.event.addListener(
            _markers[s._id].marker,
            "mouseout",
            function () {
              const scope = s.name === "Siding" ? "siding" : "roofing";
              const nc =
                s.workOrder && s.workOrder.propertyType === "New Construction"
                  ? true
                  : false;
              const flag =
                s.workOrder.difficultyRating === 4 ||
                s.workOrder.difficultyRating === 4.5 ||
                s.workOrder.flag === true
                  ? true
                  : false;
              const url = generateMarkerUrl({
                type: "schedule",
                scope,
                nc,
                flag,
              });

              const _scope = document.getElementById(s._id);
              if (_scope) _scope.classList.remove("hover");
              setMarkers((_markers) => {
                _markers[s._id].marker.setIcon(
                  new window.google.maps.MarkerImage(
                    url,
                    null,
                    null,
                    null,
                    new window.google.maps.Size(36, 36)
                  )
                );
                _markers[s._id].marker.setZIndex(1);
                return _markers;
              });
            }
          );

          _markers[s._id].mousedown = window.google.maps.event.addListener(
            _markers[s._id].marker,
            "mousedown",
            function () {
              if (!screens.md) {
                const scope = s.name === "Siding" ? "siding" : "roofing";
                const nc =
                  s.workOrder && s.workOrder.propertyType === "New Construction"
                    ? true
                    : false;
                const flag =
                  s.workOrder.difficultyRating === 4 ||
                  s.workOrder.difficultyRating === 4.5 ||
                  s.workOrder.flag === true
                    ? true
                    : false;
                const url = generateMarkerUrl({
                  type: "schedule",
                  scope,
                  nc,
                  flag,
                });

                const index = scopes.findIndex((x) => x._id === s._id);
                carouselRef.current.goTo(index);
                setMarkers((_markers) => {
                  _markers[s._id].marker.setIcon(
                    new window.google.maps.MarkerImage(
                      url,
                      null,
                      null,
                      null,
                      new window.google.maps.Size(36, 36)
                    )
                  );
                  _markers[s._id].marker.setZIndex(1);
                  return _markers;
                });
              }
            }
          );

          _markers[s._id].click = window.google.maps.event.addListener(
            _markers[s._id].marker,
            "click",
            function () {
              if (screens.md) {
                const el = document.getElementById(s._id);
                el.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }
            }
          );
        }
        return s;
      }
      return null;
    });

    let _copyObject = {};

    for (const s of scopes) {
      if (_copyObject[s.stamp]) {
        _copyObject[s.stamp] += `${s.customerId}\n${s.customerName}\n${
          s.workOrder && s.workOrder.projectAddress
        }\n${s.workOrder && s.workOrder.projectManagerName}\n${
          s.assignToSub && s.assignToSub.companyName
        }${
          s.assignToSub && s.assignToSub.contactName
            ? ` - ${s.assignToSub.contactName}`
            : ""
        }\n\n`;
      } else {
        _copyObject[s.stamp] = `${s.customerId}\n${s.customerName}\n${
          s.workOrder && s.workOrder.projectAddress
        }\n${s.workOrder && s.workOrder.projectManagerName}\n${
          s.assignToSub && s.assignToSub.companyName
        }${
          s.assignToSub && s.assignToSub.contactName
            ? ` - ${s.assignToSub.contactName}`
            : ""
        }\n\n`;
      }
    }

    dateStamp = undefined;
    setCopyObject(_copyObject);
    setMarkers(_markers);
  };

  dateStamp = undefined;

  const handleBeforeCarouselChange = (e) => {
    const _scope = scopes[e];
    if (
      _scope &&
      _scope._id &&
      markers[_scope._id] &&
      markers[_scope._id].marker
    ) {
      const scope = _scope.name === "Siding" ? "siding" : "roofing";
      const nc =
        _scope.workOrder && _scope.workOrder.propertyType === "New Construction"
          ? true
          : false;
      const flag =
        _scope.workOrder.difficultyRating === 4 ||
        _scope.workOrder.difficultyRating === 4.5 ||
        _scope.workOrder.flag === true
          ? true
          : false;
      const url = generateMarkerUrl({
        type: "schedule",
        scope,
        nc,
        flag,
      });

      setMarkers((_markers) => {
        if (_markers[_scope._id]) {
          _markers[_scope._id].marker.setIcon(
            new window.google.maps.MarkerImage(
              url,
              null,
              null,
              null,
              new window.google.maps.Size(36, 36)
            )
          );
          _markers[_scope._id].marker.setZIndex(1);
        }
        return _markers;
      });
    }
  };

  const handleAfterCarouselChange = (e) => {
    const _scope = scopes[e];

    if (
      _scope &&
      _scope.workOrder &&
      _scope.workOrder.projectAddressLat &&
      _scope.workOrder.projectAddressLng
    ) {
      const destLatLng = new window.google.maps.LatLng(
        _scope.workOrder.projectAddressLat,
        _scope.workOrder.projectAddressLng
      );
      smoothlyAnimatePanTo(scheduleMap, destLatLng);
    }

    if (
      _scope &&
      _scope._id &&
      markers[_scope._id] &&
      markers[_scope._id].marker
    ) {
      const scope = _scope.name === "Siding" ? "siding" : "roofing";
      const nc =
        _scope.workOrder && _scope.workOrder.propertyType === "New Construction"
          ? true
          : false;
      const flag =
        _scope.workOrder.difficultyRating === 4 ||
        _scope.workOrder.difficultyRating === 4.5 ||
        _scope.workOrder.flag === true
          ? true
          : false;
      const url = generateMarkerUrl({
        type: "schedule",
        scope,
        nc,
        active: true,
        flag,
      });

      setMarkers((_markers) => {
        _markers[_scope._id].marker.setIcon(
          new window.google.maps.MarkerImage(
            url,
            null,
            null,
            null,
            new window.google.maps.Size(36, 36)
          )
        );
        _markers[_scope._id].marker.setZIndex(10);
        return _markers;
      });
    }
  };

  const panToPin = (_id) => {
    const _scope = scopes.find((s) => s._id === _id);

    if (
      _scope &&
      _scope.workOrder &&
      _scope.workOrder.projectAddressLat &&
      _scope.workOrder.projectAddressLng
    ) {
      const destLatLng = new window.google.maps.LatLng(
        _scope.workOrder.projectAddressLat,
        _scope.workOrder.projectAddressLng
      );
      smoothlyAnimatePanTo(scheduleMap, destLatLng);
    }
  };

  const handleFilter = async () => {
    try {
      if (filter === "Showing ALL") {
        fetchMyScopes();
        setFilter("Showing MINE");
      } else {
        fetchScopes();
        setFilter("Showing ALL");
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleTypeFilter = async (type) => {
    if (typeFilters.includes(type)) {
      setTypeFilters(typeFilters.filter((tf) => tf !== type));
    } else {
      setTypeFilters((tf) => [...tf, type]);
    }
  };

  const filterScopes = (scope) => {
    const _scope = scope.name === "Siding" ? "siding" : "roofing";
    const nc =
      scope.workOrder && scope.workOrder.propertyType === "New Construction"
        ? true
        : false;
    const flagged =
      scope.workOrder.difficultyRating === 4 ||
      scope.workOrder.difficultyRating === 4.5 ||
      scope.workOrder.flag === true
        ? true
        : false;

    const filterString = `${_scope}${flagged ? "-flagged" : ""}${
      nc ? "-nc" : ""
    }`;

    if (typeFilters.includes(filterString)) return true;
    return false;
  };

  return (
    <div className="form" style={{ height: "100%" }}>
      {screens.xs || (screens.sm && !screens.md) ? (
        <div style={{ height: "100%", width: "100%", position: "relative" }}>
          {install === "Yes" && (
            <Row gutter={16}>
              <Col xs={24}>
                <Button
                  type="primary"
                  className="green"
                  style={{
                    position: "fixed",
                    top: 120,
                    right: 12,
                    left: 12,
                    zIndex: 1,
                    width: "calc(100% - 24px)",
                  }}
                  // block
                  onClick={handleFilter}
                >
                  {filter}
                </Button>
              </Col>
            </Row>
          )}

          <div className={styles.projectCarousel} id="carousel">
            {loading ? (
              <div className={styles.mobileLoader}>
                <div>
                  <Loader minHeight="100%" />
                </div>
              </div>
            ) : (
              <Carousel
                ref={carouselRef}
                centerMode={true}
                centerPadding={scopes.length === 1 ? "0px" : "16px"}
                slidesToShow={1}
                className={styles.carousel}
                dots={false}
                beforeChange={handleBeforeCarouselChange}
                afterChange={handleAfterCarouselChange}
                infinite={false}
              >
                {scopes.map((scope) => {
                  if (filterScopes(scope)) {
                    return (
                      <div key={scope._id} className={styles.carouselSlide}>
                        <MobileScope
                          scope={scope}
                          container={container}
                          setMarkers={setMarkers}
                          scopes={scopes}
                          markers={markers}
                          setScopes={setScopes}
                          copyObject={copyObject}
                          fetchScopes={fetchScopes}
                          admin={admin}
                          role={role}
                          manager={manager}
                          _user={_user}
                          removePins={removePins}
                        />
                      </div>
                    );
                  }
                  return null;
                })}
              </Carousel>
            )}
          </div>
          <div
            style={{
              width: "100%",
              height: "100%",
              position: "relative",
            }}
            id="scheduleMap"
          ></div>
          <MapKey
            handleClick={handleTypeFilter}
            typeFilters={typeFilters}
            compact
          />
        </div>
      ) : (
        <div className="content-inner p24" ref={setContainer}>
          <Row gutter={16} style={{ height: "100%" }}>
            <Col xs={24} sm={24} md={13} lg={11}>
              {loading ? (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                  }}
                >
                  <Loader minHeight="100%" />
                </div>
              ) : (
                <>
                  {install === "Yes" && (
                    <Button
                      type="primary"
                      className="green"
                      block
                      onClick={handleFilter}
                    >
                      {filter}
                    </Button>
                  )}
                  {scopes.map((scope) => {
                    if (filterScopes(scope)) {
                      return (
                        <ScopeContainer
                          key={scope._id}
                          scope={scope}
                          container={container}
                          setMarkers={setMarkers}
                          scopes={scopes}
                          markers={markers}
                          setScopes={setScopes}
                          copyObject={copyObject}
                          fetchScopes={fetchScopes}
                          panToPin={panToPin}
                          admin={admin}
                          role={role}
                          manager={manager}
                          _user={_user}
                          removePins={removePins}
                        />
                      );
                    }
                    return null;
                  })}
                </>
              )}
            </Col>
            <Col xs={0} sm={0} md={11} lg={13} className={styles.mapContainer}>
              <Affix offsetTop={25} target={() => container}>
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                    marginBottom: 16,
                    borderRadius: 4,
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      marginBottom: 16,
                      borderRadius: 4,
                    }}
                    id="scheduleMap"
                  ></div>
                  <MapKey
                    handleClick={handleTypeFilter}
                    typeFilters={typeFilters}
                  />
                </div>
              </Affix>
            </Col>
          </Row>
        </div>
      )}

      <ViewContainer
        type="drawer"
        visible={showProject}
        form={ViewProject}
        placement="right"
        width={!screens.sm ? "100%" : 400}
        closable={false}
        mask={false}
      />

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0 },
        }}
        type={!screens.md ? "drawer" : "modal"}
        width={!screens.md ? "100%" : 320}
        title="Re-Schedule Scope"
        visible={rescheduleScope}
        form={ReScheduleScope}
        formType="rescheduleScope"
      />
    </div>
  );
};

const ScopeContainer = ({
  scope,
  container,
  setMarkers,
  scopes,
  markers,
  setScopes,
  copyObject,
  fetchScopes,
  panToPin,
  admin,
  _user,
  manager,
  role,
  removePins,
}) => {
  const [link] = useState(
    process.env.NODE_ENV === "production"
      ? "https://story.gopremier.net/schedule"
      : "localhost:3001/schedule"
  );
  const copied = () => {
    message.success("Schedule has been copied");
  };

  let stamp = scope.stamp;

  if (stamp === dateStamp) {
    return (
      <Scope
        scope={scope}
        setMarkers={setMarkers}
        scopes={scopes}
        markers={markers}
        fetchScopes={fetchScopes}
        setScopes={setScopes}
        container={container}
        panToPin={panToPin}
        admin={admin}
        manager={manager}
        role={role}
        _user={_user}
        removePins={removePins}
      />
    );
  } else {
    dateStamp = stamp;
    return (
      <>
        <Affix offsetTop={0} target={() => container} className={styles.affix}>
          <Clipboard
            data-clipboard-text={
              `Tomorrows schedule updated. Please let HO know cars out of the garage and driveway by 7 am for dumpster. Thanks\n\n` +
              copyObject[scope.stamp] +
              link
            }
            component="div"
            onSuccess={copied}
          >
            <div className={styles.dateStamp}>
              <span className={styles.copy}>Copy</span>
              <span className={styles.stamp}>{stamp}</span>
            </div>
          </Clipboard>
        </Affix>
        <Scope
          scope={scope}
          setMarkers={setMarkers}
          scopes={scopes}
          markers={markers}
          setScopes={setScopes}
          fetchScopes={fetchScopes}
          container={container}
          panToPin={panToPin}
          admin={admin}
          manager={manager}
          role={role}
          _user={_user}
          removePins={removePins}
        />
      </>
    );
  }
};

export default Schedule;
