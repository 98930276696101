import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchTable } from "Actions/tableActions";
import { unstable_batchedUpdates } from "react-dom";
import FormBody from "Components/FormBody";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import message from "antd/es/message";
import FormControls from "Components/FormControls";
import Button from "antd/es/button";
import CancelButton from "Components/CancelButton";
import Row from "antd/es/row";
import ProjectDetails from "./ProjectDetails";
import Municipalities from "./Municipalities";
import Comments from "./Comments";
import api from "API";
import Modal from "antd/es/modal";
import Form from "antd/es/form";
import Currency from "Components/Inputs/Currency";
import _scopes from "Constants/scopes";
import Input from "antd/es/input";
import currencyFormatter from "Utils/currencyFormatter";
import Divider from "antd/es/divider";
import usePrevious from "Hooks/usePrevious";

const messageKey = "messageKey";

const Permit = ({ handleClose }) => {
  const dispatch = useDispatch();
  const formDetails = useSelector(
    (state) => state.formState.permit.formDetails
  );
  const table = useSelector((state) => state.tableState.table);
  const col = useSelector((state) => state.tableState.col);
  const order = useSelector((state) => state.tableState.order);
  const search = useSelector((state) => state.tableState.search);
  const activeFilter = useSelector((state) => state.tableState.activeFilter);
  const [loading, setLoading] = useState(false);
  const [notNeededLoading, setNotNeededLoading] = useState(false);
  const [neededLoading, setNeededLoading] = useState(false);
  const [project, setProject] = useState({});
  const [scopes, setScopes] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [municipalities, setMunicipalities] = useState([]);
  const [openPermitCost, setOpenPermitCost] = useState(false);
  const [submittable, setSubmittable] = useState(true);

  useEffect(() => {
    fetchProject();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchProject = async () => {
    try {
      const res = await api.get(`/permits/${formDetails._id}`);

      const _submittable = res.data._scopes.some((scope) => {
        return (
          _scopes[scope.name].permitTrigger === "woCreated" && !scope.woCreated
        );
      });

      unstable_batchedUpdates(() => {
        setProject(res.data._project);
        setScopes(res.data._scopes);
        setDocuments(res.data._projectDocuments);
        setMunicipalities(res.data._municipalities);
        setSubmittable(!_submittable);
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const onNeeded = useCallback(async () => {
    try {
      setNeededLoading(true);

      // message.loading("Marking as needed", 0);
      message.loading({
        content: "Marking permit as needed...",
        duration: 0,
        key: messageKey,
      });

      const res = await api.post("/permits/needed", {
        _projectId: project._id,
      });

      for (const s of res.data.salesObj) {
        window.socket.emit("updateDashboard", { _user: s._user });
        window.socket.emit("updateFeed", { _user: s._user });
      }

      window.socket.emit("update", { _id: project._id });

      dispatch(
        fetchTable({
          table,
          col,
          order,
          search,
          activeFilter,
          fetching: false,
        })
      );

      setProject((_project) => {
        return {
          ..._project,
          permitComments: res.data._permitComments,
        };
      });

      // message.success("Marked needed");
      message.success({
        content: "Permit marked needed",
        duration: 1.5,
        key: messageKey,
      });

      setNeededLoading(false);
    } catch (err) {
      console.log("err", err);
      setNeededLoading(false);
      // message.error("Error marking permit as needed");
      message.error({
        content: "Error marking permit as needed",
        duration: 1.5,
        key: messageKey,
      });
    }
  }, [
    dispatch,
    table,
    col,
    order,
    search,
    activeFilter,
    setProject,
    project._id,
  ]);

  const onNotNeeded = useCallback(async () => {
    try {
      setNotNeededLoading(true);
      // message.loading("Marking as not needed", 0);
      message.loading({
        content: "Marking permit as not needed...",
        duration: 0,
        key: messageKey,
      });

      const res = await api.post("/permits/notNeeded", {
        _projectId: project._id,
      });

      for (const s of res.data.salesObj) {
        window.socket.emit("updateDashboard", { _user: s._user });
        window.socket.emit("updateFeed", { _user: s._user });
      }

      window.socket.emit("update", { _id: project._id });

      dispatch(
        fetchTable({
          table,
          col,
          order,
          search,
          activeFilter,
          fetching: false,
        })
      );

      // message.success("Marked not needed");
      message.success({
        content: "Marked permit not needed",
        duration: 1.5,
        key: messageKey,
      });

      setNotNeededLoading(false);
      handleClose(true);
    } catch (err) {
      console.log("err", err);
      setNotNeededLoading(false);
      // message.error("Error marking permit as not needed");
      message.error({
        content: "Error marking permit as not needed",
        duration: 1.5,
        key: messageKey,
      });
    }
  }, [
    dispatch,
    table,
    col,
    order,
    search,
    activeFilter,
    handleClose,
    project._id,
  ]);

  const confirmPulled = () => {
    setOpenPermitCost(true);
  };

  const handleCancel = () => {
    handleClose();
  };

  const closePermitCost = () => {
    setOpenPermitCost(false);
  };

  return (
    <div className="form" style={{ background: "rgb(235, 235, 235)" }}>
      <FormBody>
        <div
          className="content-inner p24"
          id="permit-container"
          style={{ padding: 12 }}
        >
          <RemoveAutocomplete />

          <Row gutter={8}>
            <ProjectDetails
              project={project}
              setProject={setProject}
              documents={documents}
              scopes={scopes}
            />
            <Municipalities
              projectAddressPermitAuthority={
                project.projectAddressPermitAuthority
              }
              municipalities={municipalities}
              setMunicipalities={setMunicipalities}
            />
            <Comments
              comments={project.permitComments}
              _id={project._id}
              setProject={setProject}
            />
          </Row>
        </div>
      </FormBody>
      <FormControls className="sticky-footer" style={{ background: "#ffffff" }}>
        <CancelButton handleClick={handleCancel} />

        <Button
          type="text"
          style={{ padding: "4px 8px", marginRight: 8, color: "#1890ff" }}
          onClick={onNeeded}
          loading={neededLoading}
          disabled={neededLoading}
        >
          Needed
        </Button>

        <Button
          type="primary"
          danger
          style={{ marginRight: 8 }}
          loading={notNeededLoading}
          disabled={notNeededLoading}
          onClick={onNotNeeded}
        >
          Not Needed
        </Button>

        <Button
          type="primary"
          loading={loading}
          disabled={loading || !submittable}
          onClick={confirmPulled}
        >
          Pulled
        </Button>
      </FormControls>

      <PermitCost
        visible={openPermitCost}
        onCancel={closePermitCost}
        setLoading={setLoading}
        handleClose={handleClose}
        _projectId={project._id}
        customerId={project.customerId}
        scopes={scopes}
      />
    </div>
  );
};

const PermitCost = ({
  visible,
  onCancel,
  setLoading,
  handleClose,
  _projectId,
  customerId,
  scopes,
}) => {
  const prevVisible = usePrevious(visible);
  const dispatch = useDispatch();
  const table = useSelector((state) => state.tableState.table);
  const col = useSelector((state) => state.tableState.col);
  const order = useSelector((state) => state.tableState.order);
  const search = useSelector((state) => state.tableState.search);
  const activeFilter = useSelector((state) => state.tableState.activeFilter);
  const [scopesTotal, setScopesTotal] = useState(0);
  const [helpMessage, setHelpMessage] = useState(undefined);

  const [form] = Form.useForm();

  useEffect(() => {
    if (visible && !prevVisible) {
      const _scopes = [];
      for (const scope of scopes) {
        _scopes.push({
          _scopeId: scope._id,
          amount: 0,
        });
      }
      form.setFieldsValue({
        scopes: _scopes,
      });
    }
  }, [visible, prevVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  const submit = () => {
    form.submit();
  };

  const onFinish = useCallback(
    async (values) => {
      try {
        if (values.permitFee !== scopesTotal) {
          message.error({
            content: "Total amount must be allocated to scopes",
            duration: 1.5,
            key: messageKey,
          });
          setLoading(false);
          return;
        }

        setLoading(true);
        values._projectId = _projectId;
        values.customerId = customerId;

        // message.loading("Marking as pulled", 0);
        message.loading({
          content: "Marking permit as pulled...",
          duration: 0,
          key: messageKey,
        });

        const res = await api.post("/permits/pulled", values);

        for (const s of res.data.salesObj) {
          window.socket.emit("updateDashboard", { _user: s._user });
          window.socket.emit("updateFeed", { _user: s._user });
        }

        window.socket.emit("update", { _id: _projectId });

        dispatch(
          fetchTable({
            table,
            col,
            order,
            search,
            activeFilter,
            fetching: false,
          })
        );

        // message.success("Marked permit as pulled");
        message.success({
          content: "Marked permit as pulled",
          duration: 1.5,
          key: messageKey,
        });

        setLoading(false);
        handleClose(true);
      } catch (err) {
        console.log("err", err);
        setLoading(false);
        // message.error("Error marking permit as pulled");
        message.error({
          content: "Error marking permit as pulled",
          duration: 1.5,
          key: messageKey,
        });
      }
    },
    [
      dispatch,
      table,
      col,
      order,
      search,
      activeFilter,
      _projectId,
      customerId,
      handleClose,
      setLoading,
      scopesTotal,
    ]
  );

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error marking permit as pulled");
  };

  const validateAmount = () => {
    const amount = form.getFieldValue("permitFee");
    if (amount) {
      if (amount - scopesTotal !== 0) {
        return "error";
      }
    }
    return undefined;
  };

  const renderHelpMessage = useCallback(() => {
    const amount = form.getFieldValue("permitFee");
    if (amount || amount === 0) {
      if (amount - scopesTotal !== 0) {
        setHelpMessage(
          `${currencyFormatter(amount - scopesTotal)} to be allocated`
        );
      } else {
        setHelpMessage(undefined);
      }
    } else {
      setHelpMessage(undefined);
    }
  }, [form, scopesTotal]);

  useEffect(() => {
    renderHelpMessage();
  }, [scopesTotal, renderHelpMessage]);

  const scopeAmountChange = async () => {
    try {
      const amounts = await form.getFieldValue("scopes");
      let total = 0;
      for (const a of amounts) {
        if (a && a.amount) {
          total += a.amount;
        }
      }
      setScopesTotal(total);
    } catch (err) {
      console.log("scopeAmountChange err", err);
    }
  };

  const amountChange = async () => {
    try {
      const amounts = await form.getFieldValue("scopes");
      Object.entries(amounts).map(([key, value]) => {
        form.validateFields([key]);
        return value;
      });
      renderHelpMessage();
    } catch (err) {
      console.log("amount change err", err);
    }
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      layout="vertical"
      labelCol={{ span: 24 }}
      initialValues={{
        scopes: scopes,
      }}
    >
      <Modal
        visible={visible}
        onCancel={onCancel}
        title="Permit Fee"
        zIndex={4800}
        cancelButtonProps={{ style: { float: "left" } }}
        okText="Submit"
        onOk={submit}
      >
        <Form.Item
          label="Permit Fee"
          name="permitFee"
          rules={[{ required: true, message: "Permit Fee is required" }]}
        >
          <Currency id="permitFee" autoFocus={true} onChange={amountChange} />
        </Form.Item>

        <Divider>Scope Allocation</Divider>

        <Form.List name="scopes">
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map((field, index) => (
                  <div key={field.fieldKey}>
                    <Form.Item
                      {...field}
                      name={[field.name, "_scopeId"]}
                      fieldKey={[field.fieldKey, "_scopeId"]}
                      key={[field.fieldKey, "_scopeId"]}
                      help=""
                      hidden
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      {...field}
                      name={[field.name, "amount"]}
                      fieldKey={[field.fieldKey, "amount"]}
                      key={[field.fieldKey, "amount"]}
                      label={
                        scopes[index].label ||
                        scopes[index].name + " Allocation"
                      }
                      validateStatus={validateAmount()}
                      help={helpMessage}
                      rules={[
                        { required: true, message: "Amount is required" },
                      ]}
                    >
                      <Currency
                        id={[field.name, "amount"]}
                        onChange={scopeAmountChange}
                      />
                    </Form.Item>
                  </div>
                ))}
              </>
            );
          }}
        </Form.List>
      </Modal>
    </Form>
  );
};

export default Permit;
