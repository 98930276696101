import HighCharts from "highcharts";
import HighChartsReact from "highcharts-react-official";

import React, { useState, useEffect } from "react";
import currencyFormatter from "Utils/currencyFormatter";
import Row from "antd/es/row";
import Col from "antd/es/col";
import message from "antd/es/message";
import List from "antd/es/list";
import Card from "antd/es/card";
import api from "API";
import styles from "./styles.module.scss";

window.Highcharts = HighCharts;

HighCharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
  colors: [
    "#00c292",
    "#fec107",
    "#FF7056",
    "#343a40",
    "#01c0c8",
    "#999999",
    "#FF9655",
    "#FFF263",
    "#6AF9C4",
  ],
});

const BarChart = ({ path, report, summary, headers }) => {
  const [state, setState] = useState({
    totalValue: 0,
    table: [],
    options: {
      chart: {
        type: "column",
        height: 550,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: "",
      },
      subtitle: {
        text: "",
      },
      xAxis: {
        categories: ["No Data"],
        labels: {
          rotation: -45,
          style: {
            fontSize: "13px",
            fontFamily: "Verdana, sans-serif",
          },
        },
      },
      yAxis: {
        title: {
          text: "",
        },
        labels: {
          formatter: function () {
            let label = this.value;
            if (this.value >= 1000000) {
              label = this.value / 1000000 + "M";
            } else if (this.value >= 1000) {
              label = this.value / 1000 + "k";
            } else if (this.value <= -1000) {
              label = this.value / 1000 + "k";
            } else if (this.value <= -1000000) {
              label = this.value / 1000000 + "M";
            }
            return `${label}`;
          },
        },
      },
      tooltip: {
        useHTML: true,
        formatter: function () {
          let table = "<table><tr><td><b>" + this.key + "</b></tr>";
          table +=
            "<tr><td>$" + HighCharts.numberFormat(this.y, 2) + "</td></tr>";
          table += `</table>`;
          return table;
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            formatter: function () {
              return "$" + HighCharts.numberFormat(this.y, 2);
            },
          },
        },
      },
      series: [
        {
          name: "No Data",
          data: [0, 0, 0, 0],
        },
      ],
    },
  });

  useEffect(() => {
    getReport();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getReport = async () => {
    try {
      const res = await api.post(path, {});

      const table = [];
      res.data.series[0].data.map((x, i) => {
        let obj = {};
        obj.name = res.data.categories[i];
        obj.total = x;
        table.push(obj);
        return x;
      });

      setState((thisState) => {
        return {
          ...thisState,
          totalValue: res.data.totalValue,
          table,
          options: {
            ...thisState.options,
            series: res.data.series,
            xAxis: {
              ...thisState.options.xAxis,
              categories: res.data.categories,
            },
          },
        };
      });
    } catch (err) {
      console.log("err", err);
      message.error("Error getting report");
    }
  };

  return (
    <div style={{ paddingBottom: 24 }} className="content-inner">
      <Card bordered={false} className="z-depth-1" style={{ margin: 24 }}>
        <Row gutter={16} style={{ marginBottom: 24 }}>
          <Col xs={24}>
            <div className={styles.reportTitle}>{report}</div>
          </Col>
        </Row>
        {summary && (
          <Row gutter={16} style={{ marginBottom: 24 }}>
            <Col xs={24}>
              <div className={styles.reportHeader}>
                <div className={styles.total}>
                  {currencyFormatter(state.totalValue)}
                  <small> TOTAL VALUE</small>
                </div>
              </div>
            </Col>
          </Row>
        )}

        <HighChartsReact
          highcharts={HighCharts}
          options={state.options}
          updateArgs={[true, true, true]}
        />
      </Card>

      <Card
        bordered={false}
        className="z-depth-1"
        style={{
          marginTop: 24,
          marginLeft: 24,
          marginRight: 24,
        }}
        bodyStyle={{
          padding: 0,
        }}
      >
        <List>
          <List.Item className={styles.listHeader}>
            {headers.map((header, i) => {
              if (i !== 0) {
                return (
                  <List.Item.Meta
                    key={header}
                    className={styles.listRight}
                    title={header}
                  />
                );
              } else {
                return <List.Item.Meta key={header} title={header} />;
              }
            })}
          </List.Item>

          {state.table.map((t, i) => {
            return (
              <List.Item key={t.name + i} className={styles.listItem}>
                <List.Item.Meta title={t.name} />
                <List.Item.Meta
                  title={currencyFormatter(t.total)}
                  className={styles.listRight}
                />
              </List.Item>
            );
          })}

          {summary && (
            <List.Item className={styles.listTotal}>
              <List.Item.Meta title="Total" />
              <List.Item.Meta
                className={styles.listRight}
                title={currencyFormatter(state.totalValue)}
              />
            </List.Item>
          )}
        </List>
      </Card>
    </div>
  );
};

export default BarChart;
