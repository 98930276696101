import React from "react";
import styles from "./styles.module.scss";

const Table = ({ children, filter, mobile, sider, collapsed }) => {
  return (
    <div
      className={`${styles.tableContainer} ${filter ? "filter" : ""} ${
        mobile ? "isMobile" : ""
      } ${sider ? "sider" : ""} ${collapsed ? "collapsed" : ""}`}
    >
      {children}
    </div>
  );
};

export default Table;
