import React, { useState, useEffect, useCallback } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchTable } from "Actions/tableActions";
import api from "API";
import Input from "antd/es/input";
import Form from "antd/es/form";
import message from "antd/es/message";
import Button from "antd/es/button";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import Phone from "Components/Inputs/Phone";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import CancelButton from "Components/CancelButton";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";
import Select from "Components/Inputs/Select";
import Divider from "antd/es/divider";
import Currency from "Components/Inputs/Currency";

const messageKey = "messageKey";

const User = ({
  unsavedChanges,
  setUnsavedChanges,
  handleClose,
  setTitle,
  type,
}) => {
  const dispatch = useDispatch();
  const formDetails = useSelector((state) => state.formState.user.formDetails);
  const table = useSelector((state) => state.tableState.table);
  const col = useSelector((state) => state.tableState.col);
  const order = useSelector((state) => state.tableState.order);
  const search = useSelector((state) => state.tableState.search);
  const activeFilter = useSelector((state) => state.tableState.activeFilter);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!formDetails.editing) {
      setTitle("Create User");
    } else {
      setTitle("Edit User");
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onFinish = useCallback(
    async (values) => {
      try {
        setLoading(true);
        // message.loading("Saving User...", 0);
        message.loading({
          content: "Saving user...",
          duration: 0,
          key: messageKey,
        });
        if (!formDetails.editing) {
          await api.post("/users/create", values);
        } else {
          await api.post("/users/edit", values);
        }

        dispatch(
          fetchTable({
            table,
            col,
            order,
            search,
            activeFilter,
            fetching: false,
          })
        );

        unstable_batchedUpdates(() => {
          setUnsavedChanges(false);
          setLoading(false);
        });

        // message.success("User saved");
        message.success({
          content: "User saved",
          duration: 1.5,
          key: messageKey,
        });
        handleClose(true);
      } catch (err) {
        setLoading(false);
        // message.error("Error saving user");
        message.error({
          content: "Error saving user",
          duration: 1.5,
          key: messageKey,
        });
        console.log("err", err);
      }
    },
    [
      formDetails.editing,
      dispatch,
      table,
      col,
      order,
      search,
      activeFilter,
      setUnsavedChanges,
      handleClose,
    ]
  );

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error saving user");
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      initialValues={{
        _id: formDetails._id ? formDetails._id : undefined,
        fName: formDetails.fName ? formDetails.fName : undefined,
        lName: formDetails.lName ? formDetails.lName : undefined,
        email: formDetails.email ? formDetails.email : undefined,
        phone: formDetails.phone ? formDetails.phone : undefined,
        role: formDetails.role ? formDetails.role : undefined,
        manager: formDetails.manager ? formDetails.manager : undefined,
        admin: formDetails.admin ? formDetails.admin : undefined,
        salesman: formDetails.salesman ? formDetails.salesman : undefined,
        reports: formDetails.reports ? formDetails.reports : undefined,
        status: formDetails.status ? formDetails.status : undefined,
        title: formDetails.title ? formDetails.title : undefined,
        calendarId: formDetails.calendarId ? formDetails.calendarId : undefined,
        yearlyGoal: formDetails.yearlyGoal ? formDetails.yearlyGoal : undefined,
        install: formDetails.install ? formDetails.install : undefined,
      }}
      className="form"
    >
      <RemoveAutocomplete />

      <FormBody
        className="content-inner"
        style={{
          paddingLeft: 24,
          paddingTop: 24,
          paddingRight: 24,
          paddingBottom: 8,
          maxHeight: type === "modal" ? "calc(100vh - 161px)" : "unset",
        }}
      >
        <Form.Item name="_id" hidden>
          <Input />
        </Form.Item>

        {formDetails.editing && (
          <>
            <Form.Item
              name="status"
              label="Status"
              rules={[{ required: true, message: "Status is required" }]}
            >
              <Select
                options={["Active", "Suspended", "Deactivate"]}
                id="status"
              />
            </Form.Item>
            <Divider />
          </>
        )}

        <Form.Item
          name="fName"
          label="First Name"
          rules={[{ required: true, message: "First name is required" }]}
        >
          <InputNoAutoComplete autoFocus id="fName" />
        </Form.Item>

        <Form.Item
          name="lName"
          label="Last Name"
          rules={[{ required: true, message: "Last name is required" }]}
        >
          <Input id="lName" />
        </Form.Item>

        <Form.Item
          name="email"
          label="Email"
          rules={[
            { type: "email", message: "Must be valid email" },
            { required: "Email is required" },
          ]}
        >
          <InputNoAutoComplete type="email" id="email" />
        </Form.Item>

        <Form.Item
          name="phone"
          label="Phone"
          rules={[{ required: true, message: "Phone is required" }]}
        >
          <Phone id="phone" />
        </Form.Item>

        <Form.Item
          name="title"
          label="Title"
          rules={[{ required: true, message: "Title is required" }]}
        >
          <InputNoAutoComplete id="title" />
        </Form.Item>

        <Form.Item
          name="role"
          label="Role"
          rules={[{ required: true, message: "Role is required" }]}
        >
          <Select options={["Canvasser", "Office", "Sales"]} id="role" />
        </Form.Item>

        <Form.Item
          name="manager"
          label="Manager"
          rules={[{ required: true, message: "Manager is required" }]}
        >
          <Select options={["No", "Yes"]} id="manager" />
        </Form.Item>

        <Form.Item
          name="admin"
          label="Admin"
          rules={[{ required: true, message: "Admin is required" }]}
        >
          <Select options={["No", "Yes"]} id="admin" />
        </Form.Item>

        <Form.Item
          name="reports"
          label="Reports"
          rules={[{ required: true, message: "Reports is required" }]}
        >
          <Select options={["No", "Yes"]} id="reports" />
        </Form.Item>

        <Form.Item
          name="salesman"
          label="Salesman"
          rules={[{ required: true, message: "Salesman is required" }]}
        >
          <Select options={["No", "Yes"]} id="salesman" />
        </Form.Item>

        <Form.Item name="yearlyGoal" label="Yearly Goal">
          <Currency id="yearlyGoal" />
        </Form.Item>

        {/* <Form.Item name="monthlyGoal" label="Monthly Goal">
          <InputNumberNoAutoComplete id="monthlyGoal" />
        </Form.Item> */}

        <Form.Item
          name="install"
          label="Install"
          rules={[{ required: true, message: "Install is required" }]}
        >
          <Select options={["No", "Yes"]} id="install" />
        </Form.Item>

        <Form.Item
          name="calendarId"
          label="Google Calendar ID"
          // rules={[{ required: true, message: "Last name is required" }]}
        >
          <InputNoAutoComplete id="calendarId" />
        </Form.Item>
      </FormBody>
      <FormControls className="sticky-footer">
        <CancelButton handleClick={handleClose} />
        <Form.Item style={{ margin: 0 }}>
          <Button
            htmlType="submit"
            type="primary"
            loading={loading}
            disabled={loading || !unsavedChanges}
          >
            Submit
          </Button>
        </Form.Item>
      </FormControls>
    </Form>
  );
};

export default User;
