import React, { useState, useEffect, useRef, useCallback } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { fetchTable } from "Actions/tableActions";
import { useDispatch, useSelector } from "react-redux";
import { openForm } from "Actions/formActions";
import { openImageViewer } from "Actions/dataActions";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Typography from "antd/es/typography";
import Button from "antd/es/button";
import Form from "antd/es/form";
import Grid from "antd/es/grid";
import message from "antd/es/message";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import Add from "Icons/Add";
import Remove from "Icons/Remove";
import styles from "./styles.module.scss";
import api from "API";
import MaterialColumnHeaders from "Components/materialOrderQuote/MaterialColumnHeaders";
import MaterialRow from "Components/materialOrderQuote/MaterialRow";
import CustomerDetails from "../WorkOrder/CustomerDetails";
import RoofingSteepDetails from "../WorkOrder/RoofingSteepDetails";
import DeliveryDetails from "../WorkOrder/DeliveryDetails";
import RoofingFlatDetails from "../WorkOrder/RoofingFlatDetails";
import CancelButton from "Components/CancelButton";
import Post from "Icons/Post";
import generateChanges from "Utils/generateChanges";
import HiddenFields from "Forms/WorkOrder/HiddenFields";
import dayjs from "dayjs";
import ReviewSection from "Forms/WorkOrder/ReviewSection";
import AerialCalculator from "Forms/WorkOrder/AerialCalculator";
import MaterialDeliveryDetails from "Forms/WorkOrder/MaterialDeliveryDetails";
import HorizontalScroll from "Components/HorizontalScroll";
import isPdf from "Utils/isPdf";
import isVideo from "Utils/isVideo";
import openInNewTab from "Utils/openInNewTab";
import hidePdfControls from "Utils/hidePdfControls";
import Card from "antd/es/card";
import LazyLoad from "Components/LazyLoad";
import YouTube from "Icons/YouTube";
import pdf from "Assets/pdf.svg";
import Collapse from "antd/es/collapse";
import ImageFallback from "Components/images/Image";
import ProcessFeedback from "Components/ProcessFeedback";
import Divider from "antd/es/divider";

const messageKey = "messageKey";

let changedValues = {};

const customerDetails = [
  "customerName",
  "propertyType",
  "pitches",
  "stories",
  "projectAddressStreet",
  "projectAddressCity",
  "projectAddressState",
  "projectAddressZip",
  "projectAddressLat",
  "projectAddressLng",
  "projectAddressCounty",
  "projectAddressPermitAuthority",
  "projectDescription",
  "phone",
];

const steps = [
  "Submitting Form",
  "Generating PDF",
  "Creating Post",
  "Saving PDF",
  "Updating Scope",
  "Done!",
];

const { useBreakpoint } = Grid;

const WorkOrder = ({
  unsavedChanges,
  setUnsavedChanges,
  setTitle,
  handleClose,
  type,
}) => {
  const formDetails = useSelector(
    (state) => state.formState.aerialVerification.formDetails
  );
  const table = useSelector((state) => state.tableState.table);
  const col = useSelector((state) => state.tableState.col);
  const order = useSelector((state) => state.tableState.order);
  const search = useSelector((state) => state.tableState.search);
  const screens = useBreakpoint();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(undefined);
  const [startingRows, setStartingRows] = useState(0);

  const [_lat, _setLat] = useState(
    formDetails.workOrder && formDetails.workOrder.projectAddressLat
      ? formDetails.workOrder.projectAddressLat
      : 42.963795
  );
  const [_lng, _setLng] = useState(
    formDetails.workOrder && formDetails.workOrder.projectAddressLng
      ? formDetails.workOrder.projectAddressLng
      : -85.670006
  );
  const [scrollContainer, setScrollContainer] = useState(null);
  const [addFlat] = useState(
    formDetails.workOrder && formDetails.workOrder.flatManufacturer
      ? true
      : false
  );
  const [_lineItems] = useState(
    JSON.parse(
      JSON.stringify(
        formDetails.workOrder && formDetails.workOrder.lineItems
          ? formDetails.workOrder.lineItems.slice(0)
          : {}
      )
    )
  );
  const [collapsed, setCollapsed] = useState(true);
  const [_documents, _setDocuments] = useState([]);
  const [_allMedia, _setAllMedia] = useState([]);

  const _user = useSelector((state) => state.authState._user);
  const [feedBack, setFeedBack] = useState({
    active: "Submitting Form",
    progress: 0,
  });

  const documentRef = useRef();

  useEffect(() => {
    setTitle(
      `Aerial Verification${
        formDetails.workOrder
          ? ` - ${formDetails.workOrder.projectManagerName}`
          : ""
      }${formDetails.customerId ? ` (${formDetails.customerId})` : ""}`
    );
    let shingle = [],
      siding = [];

    if (!formDetails.workOrder.shingle && !formDetails.workOrder.siding) {
      if (formDetails.workOrder.manufacturer) {
        shingle.push(formDetails.workOrder.manufacturer);
        siding.push(formDetails.workOrder.manufacturer);
      }
      if (formDetails.workOrder.productLine) {
        shingle.push(formDetails.workOrder.productLine);
        siding.push(formDetails.workOrder.productLine);
      }
      if (formDetails.workOrder.color) {
        shingle.push(formDetails.workOrder.color);
        siding.push(formDetails.workOrder.color);
      }
    } else if (formDetails.workOrder.shingle) {
      shingle = formDetails.workOrder.shingle;
    } else if (formDetails.workOrder.siding) {
      siding = formDetails.workOrder.siding;
    }

    getDocuments();
    setStartingRows(formDetails.workOrder.lineItems.length);
    form.setFieldsValue({
      ...formDetails.workOrder,
      shingle,
      siding,
      dateSold: dayjs(formDetails.workOrder.dateSold),
    });

    initialCalculations(shingle);

    return () => {
      form.resetFields();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const initialCalculations = async (shingle) => {
    try {
      const res = await api.get(
        `/mold-review/get-details/${formDetails._projectId}`
      );

      let eave;
      let dripEdgeTotal;
      let dripEdgeRounded;
      let rake;
      let rakeAndEave;
      let starterTotal;
      let starterRounded;
      let hip;
      let valley;
      let ridge;
      let ridgeCapTotal;
      let ridgeCapRounded;
      let ventilationTotal;
      let ventilationRounded;
      let stepFlashing;
      let stepFlashingTotal;
      let stepFlashingRounded;
      let totalArea;
      let shinglesTotal;
      let shinglesRounded;
      let underlaymentTotal;
      let underlaymentRounded;
      let iceAndWaterTotal;
      let iceAndWaterRounded;
      let coilNailsTotal;
      let coilNailsRounded;
      let mainPitch;

      if (
        res.data.roofMeasurements &&
        res.data.roofMeasurements.shingledArea > 0
      ) {
        console.log("calculating measurements...");
        const { roofMeasurements } = res.data;
        const wasteFactor = 1.12;
        const capLength = 22;
        const dripLength = 10;
        const dripWaste = 1.2;
        const ridgeLength = 4;
        const iceAndWaterLength = 180;
        const underlaymentLength = 1000;
        const valleyLength = 2;
        const _iceAndWaterLength = 60;
        const coilNailCount = 1500;
        const stepLength = 50;
        let starterLength = 140;
        if (shingle[0] !== "Atlas") {
          starterLength = 120;
        }

        // let eave;
        eave = roofMeasurements.eaves;
        // let rake;
        rake = roofMeasurements.rakes;
        // let rakeAndEave;
        rakeAndEave = rake + eave;
        // let dripEdgeTotal;
        dripEdgeTotal = (rakeAndEave * dripWaste) / dripLength;
        // let dripEdgeRounded;
        dripEdgeRounded = Math.ceil((rakeAndEave * dripWaste) / dripLength);
        // let starterTotal;
        starterTotal = (rakeAndEave / starterLength) * 1.1;
        // let starterRounded;
        starterRounded = Math.ceil((rakeAndEave / starterLength) * 1.1);
        // let hip;
        hip = roofMeasurements.hips;
        // let valley;
        valley = roofMeasurements.valleys;
        // let ridge;
        ridge = roofMeasurements.ridges;
        // let ridgeCapTotal;
        ridgeCapTotal = ((ridge + hip) / capLength) * 1.1;
        // let ridgeCapRounded;
        ridgeCapRounded = Math.ceil(((ridge + hip) / capLength) * 1.1);
        // let ventilationTotal;
        ventilationTotal = ridge / ridgeLength;
        // let ventilationRounded;
        ventilationRounded = Math.ceil(ridge / ridgeLength);
        // let stepFlashing;
        stepFlashing = roofMeasurements.step_flashing;
        // let stepFlashingTotal;
        stepFlashingTotal = stepFlashing / stepLength;
        // let stepFlashingRounded;
        stepFlashingRounded = Math.ceil(stepFlashing / stepLength);
        // let iceAndWaterTotal;
        iceAndWaterTotal = (valley + eave * valleyLength) / _iceAndWaterLength;
        // let iceAndWaterRounded;
        iceAndWaterRounded = Math.ceil(
          (valley + eave * valleyLength) / _iceAndWaterLength
        );
        // let totalArea;
        totalArea = roofMeasurements.shingledArea;
        // let shinglesTotal;
        shinglesTotal = totalArea * wasteFactor;
        // let shinglesRounded;
        shinglesRounded = Math.ceil(totalArea * wasteFactor);
        // let underlaymentTotal;
        underlaymentTotal =
          (totalArea - iceAndWaterTotal * iceAndWaterLength) /
          underlaymentLength;
        // let underlaymentRounded;
        underlaymentRounded = Math.ceil(
          (totalArea - iceAndWaterTotal * iceAndWaterLength) /
            underlaymentLength
        );
        // let coilNailsTotal;
        coilNailsTotal = totalArea / coilNailCount;
        // let coilNailsRounded;
        coilNailsRounded = Math.ceil(totalArea / coilNailCount);
        // let mainPitch;
        mainPitch = roofMeasurements.predominatPitch;
      }

      form.setFieldsValue({
        eave,
        dripEdgeTotal,
        dripEdgeRounded,
        rake,
        rakeAndEave,
        starterTotal,
        starterRounded,
        hip,
        valley,
        ridge,
        ridgeCapTotal,
        ridgeCapRounded,
        ventilationTotal,
        ventilationRounded,
        stepFlashing,
        stepFlashingTotal,
        stepFlashingRounded,
        totalArea,
        shinglesTotal,
        shinglesRounded,
        underlaymentTotal,
        underlaymentRounded,
        iceAndWaterTotal,
        iceAndWaterRounded,
        coilNailsTotal,
        coilNailsRounded,
        mainPitch,
      });
    } catch (err) {
      console.log("initialCalculation err", err);
    }
  };

  const getDocuments = async () => {
    try {
      const res = await api.get(
        `/aerial-verifications/get-details/${formDetails._projectId}`
      );
      _setDocuments(res.data._documents);
      _setAllMedia(res.data._allMedia);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    handleSelectedChange();
  }, [selected]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectedChange = async () => {
    try {
      if (!selected) {
        const lineItems = await form.getFieldValue("lineItems");
        if (lineItems) {
          lineItems.forEach((m, i) => {
            if (m.disabled) {
              form.validateFields([
                ["lineItems", i, "material"],
                ["lineItems", i, "type"],
                ["lineItems", i, "color"],
                ["lineItems", i, "quantity"],
              ]);
            }
          });
        }
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const updateFeedBack = (feedBack) => {
    setFeedBack(feedBack);
  };

  const onVerify = useCallback(async () => {
    try {
      window.socket.on(`${_user}-work-order-progress`, updateFeedBack);
      unstable_batchedUpdates(() => {
        setFeedBack({
          active: "Submitting Form",
          progress: 1,
        });
        setLoading(true);
      });

      // message.loading("Verifying aerial...", 0);
      message.loading({
        content: "Verifying aerial...",
        duration: 0,
        key: messageKey,
      });
      let values = await form.validateFields();

      if (values.shingle) {
        values.manufacturer = values.shingle[0];
        values.productLine = values.shingle[1];
        values.color = values.shingle[2];
      }

      values = await generateChanges({
        changedValues,
        values,
        customerDetails,
        _lineItems,
        workOrder: formDetails,
        woCreated: true,
      });

      values.projectAddress = `${values.projectAddressStreet}, ${values.projectAddressCity}, ${values.projectAddressState} ${values.projectAddressZip}`;
      values.companyName = formDetails.workOrder.companyName;

      const res = await api.post("/aerial-verifications/verify", values);

      for (const s of res.data.salesObj) {
        window.socket.emit("updateDashboard", { _user: s._user });
        window.socket.emit("updateFeed", { _user: s._user });
      }
      window.socket.emit("update", { _id: formDetails._projectId });

      changedValues = {};
      unstable_batchedUpdates(() => {
        setUnsavedChanges(false);
        setLoading(false);
        setFeedBack({
          active: "Done!",
          progress: 100,
        });
      });

      dispatch(
        fetchTable({
          table,
          col,
          order,
          search,
          fetching: false,
        })
      );

      // message.success("Aerial verification complete");
      message.success({
        content: "Aerial verification complete",
        duration: 1.5,
        key: messageKey,
      });
      window.socket.removeAllListeners(`${_user}-work-order-progress`);
      handleClose(true);
    } catch (err) {
      console.log("err", err);
      if (
        err &&
        err.errorFields &&
        err.errorFields[0] &&
        err.errorFields[0].name
      ) {
        if (
          err &&
          err.errorFields &&
          err.errorFields[0] &&
          err.errorFields[0].name
        ) {
          if (err.errorFields[0].name[0] === "lineItems") {
            const el = document.getElementById("top-of-form");
            el.scrollIntoView({ block: "end", behavior: "smooth" });
          } else {
            form.scrollToField(err.errorFields[0].name[0]);
          }
        }
      }
      // message.error("Error verifying aerial");
      message.error({
        content: "Error verifying aerial",
        duration: 1.5,
        key: messageKey,
      });
      setLoading(false);
      setFeedBack({
        active: "Submitting Form",
        progress: 0,
      });
      window.socket.removeAllListeners(`${_user}-work-order-progress`);
    }
  }, [
    _user,
    form,
    dispatch,
    _lineItems,
    col,
    formDetails,
    handleClose,
    order,
    search,
    setUnsavedChanges,
    table,
  ]);

  const onFinishFailed = (err) => {
    if (
      err &&
      err.errorFields &&
      err.errorFields[0] &&
      err.errorFields[0].name
    ) {
      if (
        err &&
        err.errorFields &&
        err.errorFields[0] &&
        err.errorFields[0].name
      ) {
        if (err.errorFields[0].name[0] === "lineItems") {
          const el = document.getElementById("top-of-form");
          el.scrollIntoView({ block: "end", behavior: "smooth" });
        } else {
          form.scrollToField(err.errorFields[0].name[0]);
        }
      }
    }
    setLoading(false);
  };

  const removeRow = async () => {
    try {
      let lineItems = await form.getFieldValue("lineItems");
      lineItems = lineItems.filter((m, i) => {
        if (
          m.checkbox === false ||
          m.disabled ||
          (m.checkbox === true && i + 1 <= startingRows)
        ) {
          return true;
        }
        return false;
      });
      lineItems.forEach((m, i) => {
        if (m.checkbox || m.disabled) {
          m.disabled = true;
          m.checkbox = false;
          changedValues = {
            ...changedValues,
            [i]: {
              ...changedValues[i],
              disabled: true,
              index: i,
            },
          };
        }
      });
      form.setFieldsValue({
        lineItems,
      });
      setSelected(undefined);
    } catch (err) {
      console.log("err", err);
    }
  };

  const removeSingleRow = async (index) => {
    try {
      let lineItems = await form.getFieldValue("lineItems");
      if (index < startingRows) lineItems[index].disabled = true;
      changedValues = {
        ...changedValues,
        [index]: {
          ...changedValues[index],
          disabled: true,
          index,
        },
      };
      form.setFieldsValue({
        lineItems,
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleUnlock = async (index) => {
    try {
      let lineItems = await form.getFieldValue("lineItems");
      lineItems[index].disabled = false;
      changedValues = {
        ...changedValues,
        [index]: {
          ...changedValues[index],
          disabled: false,
          index,
        },
      };
      form.setFieldsValue({
        lineItems,
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const onValuesChange = (values) => {
    if (!unsavedChanges) setUnsavedChanges(true);

    let key = Object.keys(values)[0];
    let value = values[key];
    let index;

    if (key === "lineItems") {
      index = Object.keys(value)[0];

      let subValue = value[index];
      key = Object.keys(subValue)[0];
      value = subValue[key];
    }

    if (index && key !== "checkbox") {
      changedValues = {
        ...changedValues,
        [index]: {
          ...changedValues[index],
          [key]: value,
          index,
        },
      };
    } else if (key !== "checkbox") {
      changedValues = {
        ...changedValues,
        [key]: {
          value,
          key,
        },
      };
    }
  };

  const onFieldsChange = async (fields) => {
    try {
      if (fields.length > 0 && fields[0].name[2] === "checkbox") {
        let lineItems = await form.getFieldValue("lineItems");
        if (lineItems) {
          let arr = [];
          let cnt = 0;
          for (const m of lineItems) {
            if (m.checkbox) {
              arr.push(cnt);
              cnt++;
            }
          }
          if (arr.length === 0) arr = undefined;
          setSelected(arr);
        }
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleCancel = () => {
    handleClose();
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const updateCords = ({ lat, lng }) => {
    _setLat(lat);
    _setLng(lng);
  };

  const openActivity = useCallback(() => {
    dispatch(
      openForm("activity", {
        _id: formDetails._projectId,
        customerId: formDetails.customerId,
        customerName: formDetails.customerName,
        projectStatus: "Sold",
        projectManager: {
          name: formDetails.workOrder.projectManagerName,
        },
      })
    );
  }, [dispatch, formDetails]);

  const goToInNewTab = () => {
    openInNewTab(
      `https://story.gopremier.net/projects/view/details/${formDetails._projectId}`
    );
  };

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      onFinishFailed={onFinishFailed}
      className="form"
      onValuesChange={onValuesChange}
      onFieldsChange={onFieldsChange}
    >
      <FormBody>
        <div
          className="content-inner p24"
          id="work-order-container"
          ref={setScrollContainer}
          style={
            type === "drawer"
              ? { height: `calc(100% - ${collapsed ? "46px" : "161px"})` }
              : {}
          }
        >
          <div id="top-of-form" />
          <div className={feedBack.progress !== 0 ? "hide" : ""}>
            <Button
              onClick={goToInNewTab}
              type="primary"
              block
              style={{ marginBottom: 24 }}
            >
              Go To In New Tab
            </Button>

            <RemoveAutocomplete />

            <HiddenFields />

            {/* {_documents.length > 0 && (
              <HorizontalScroll
                ref={documentRef}
                scrollContainer={scrollContainer}
                style={{ marginTop: 24 }}
              >
                {_documents.map((media) => (
                  <Document
                    key={media._id}
                    media={media}
                    scrollContainer={documentRef}
                  />
                ))}
              </HorizontalScroll>
            )} */}

            <HorizontalScroll
              ref={documentRef}
              scrollContainer={scrollContainer}
            >
              {_documents.map((media) => (
                <Document
                  key={media._id}
                  media={media}
                  scrollContainer={documentRef}
                />
              ))}
              {_allMedia.map((media) => (
                <Document
                  key={media._id}
                  media={media}
                  scrollContainer={documentRef}
                />
              ))}
            </HorizontalScroll>

            <Divider />

            <MaterialDeliveryDetails />

            <Collapse
              defaultActiveKey={[
                "Aerial Calculations",
                "Roofing Steep",
                "Roofing Flat",
              ]}
              className={styles.collapse}
            >
              <Collapse.Panel
                header="Aerial Calculations"
                key="Aerial Calculations"
              >
                <AerialCalculator
                  form={form}
                  scrollContainer={scrollContainer}
                />
              </Collapse.Panel>

              <CustomerDetails
                form={form}
                _lat={_lat}
                _lng={_lng}
                updateCords={updateCords}
                hidden={true}
              />

              {formDetails.name === "Roofing - Steep" ? (
                <Collapse.Panel header="Roofing Steep" key="Roofing Steep">
                  <RoofingSteepDetails
                    form={form}
                    startingRows={startingRows}
                    setStartingRows={setStartingRows}
                    addFlat={addFlat}
                    review
                  />
                </Collapse.Panel>
              ) : (
                <Collapse.Panel header="Roofing Flat" key="Roofing Flat">
                  <RoofingFlatDetails
                    form={form}
                    startingRows={startingRows}
                    setStartingRows={setStartingRows}
                  />
                </Collapse.Panel>
              )}
            </Collapse>

            <DeliveryDetails hidden />

            <Typography.Title level={4}>
              Materails To Be Delivered
            </Typography.Title>

            {!screens.xs && (
              <MaterialColumnHeaders
                form={form}
                selected={selected}
                setSelected={setSelected}
                scrollContainer={scrollContainer}
              />
            )}

            <Form.List name="lineItems">
              {(fields, { add, remove }) => {
                const handleAdd = () => {
                  add();
                };

                const handleRemove = (i) => {
                  if (i < startingRows) {
                    removeSingleRow(i);
                  } else {
                    remove(i);
                  }
                };

                return (
                  <>
                    {fields.map((field, index) => (
                      <MaterialRow
                        quote={true}
                        key={field.key}
                        fields={fields}
                        field={field}
                        index={index}
                        form={form}
                        selected={selected}
                        handleRemove={handleRemove}
                        handleUnlock={handleUnlock}
                      />
                    ))}
                    <Row gutter={{ xs: 8, sm: 8, md: 12, lg: 24 }}>
                      {!selected ? (
                        <Col xs={24}>
                          <Form.Item style={{ marginRight: 0 }}>
                            <Button
                              className={`${styles.button} green`}
                              style={
                                screens.lg
                                  ? { marginTop: 12 }
                                  : screens.md
                                  ? { marginTop: 6 }
                                  : { marginTop: 12 }
                              }
                              type="primary"
                              onClick={handleAdd}
                              block
                            >
                              <Add size={18} /> Add Material
                            </Button>
                          </Form.Item>
                        </Col>
                      ) : (
                        <Col xs={24}>
                          <Form.Item>
                            <Button
                              className={styles.button}
                              style={
                                screens.lg
                                  ? { marginTop: 12 }
                                  : screens.md
                                  ? { marginTop: 6 }
                                  : { marginTop: 12 }
                              }
                              danger
                              type="primary"
                              onClick={removeRow}
                              block
                            >
                              <Remove size={18} /> Remove Selected
                            </Button>
                          </Form.Item>
                        </Col>
                      )}
                    </Row>
                  </>
                );
              }}
            </Form.List>

            <Button
              onClick={openActivity}
              type="primary"
              danger
              shape="circle"
              className={`${styles.fab} ${collapsed ? styles.collapsed : ""}`}
            >
              <Post />
            </Button>
          </div>
          <div
            style={{ height: "100%" }}
            className={feedBack.progress === 0 ? "hide" : ""}
          >
            <ProcessFeedback feedBack={feedBack} steps={steps} />
          </div>
        </div>
      </FormBody>

      {feedBack.progress === 0 && (
        <ReviewSection
          hideDifficulty
          collapsed={collapsed}
          toggleCollapsed={toggleCollapsed}
        />
      )}

      <FormControls className="sticky-footer">
        <CancelButton
          handleClick={handleCancel}
          title={feedBack.progress !== 100 ? "Cancel" : "Close"}
        />

        {feedBack.progress === 0 && (
          <Button
            type="primary"
            loading={loading}
            disabled={loading}
            onClick={onVerify}
          >
            Verify
          </Button>
        )}
      </FormControls>
    </Form>
  );
};

const Document = ({ media, scrollContainer }) => {
  const dispatch = useDispatch();

  const type = isPdf(media.src)
    ? "pdf"
    : isVideo(media.src)
    ? "video"
    : "image";

  const handlePdfClick = () => {
    openInNewTab(media.src);
  };

  const handleImageOrVideoClick = useCallback(() => {
    media.viewOnly = true;
    media.link = true;
    dispatch(openImageViewer(media));
  }, [media, dispatch]);

  return (
    <Card
      bordered={false}
      hoverable
      className={styles.activity}
      bodyStyle={{ padding: 0 }}
      style={{ padding: 0 }}
      cover={
        <>
          <div
            className={styles.clickOverlay}
            onClick={type === "pdf" ? handlePdfClick : handleImageOrVideoClick}
          />
          {type === "pdf" ? (
            <LazyLoad
              offsetHorizontal={600}
              height="240px"
              root={scrollContainer}
            >
              <object data={hidePdfControls(media.src)} className={styles.pdf}>
                <div>
                  <img src={pdf} alt="pdf-icon" />
                  <div>{media.name}</div>
                </div>
              </object>
            </LazyLoad>
          ) : type === "image" ? (
            <LazyLoad
              offsetHorizontal={600}
              height="240px"
              root={scrollContainer}
            >
              <ImageFallback alt="aerial-verification" image={media} />
            </LazyLoad>
          ) : (
            <LazyLoad
              offsetHorizontal={600}
              height="240px"
              root={scrollContainer}
            >
              <div className={styles.video}>
                <YouTube color="#f1f1f1" size={64} />
                <div>{media.name}</div>
              </div>
            </LazyLoad>
          )}
        </>
      }
    ></Card>
  );
};

export default WorkOrder;
