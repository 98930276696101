import React from "react";
import Tag from "antd/es/tag";
import dayjs from "dayjs";
import currencyFormatter from "Utils/currencyFormatter";
import percentFormatter from "Utils/percentFormatter";
import statusObj from "Constants/status";
import Scope from "Components/Table/TableItems/Scope";

import styles from "./styles.module.scss";
import Rate from "antd/es/rate";

export const generateData = (data, type) => {
  if (type === "date") {
    return dayjs(data).format("MMM D, YYYY");
  } else if (type === "currency") {
    return currencyFormatter(data);
  } else if (type === "percent") {
    return percentFormatter(data);
  } else if (type === "tag") {
    return (
      <Tag className={styles.statusTag} color={statusObj[data]}>
        {data}
      </Tag>
    );
  } else if (type === "rate") {
    return <Rate disabled value={data} />;
  } else if (type === "scope" && Array.isArray(data)) {
    return data.map((scope, i) => <Scope key={i} scope={scope} />);
  } else if (type === "join" && data) {
    return data.join(", ");
  }
  return data;
};
