import React from "react";
import List from "antd/es/list";
import { generateData } from "./generateData";

import styles from "./styles.module.scss";

const TableItem = ({ data, width, textAlign, type }) => {
  return (
    <List.Item.Meta
      className={styles.col}
      style={{
        width,
        textAlign,
      }}
      description={generateData(data, type)}
    />
  );
};

export default TableItem;
