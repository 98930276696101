import dayjs from "dayjs";
import HighCharts from "highcharts";
import HighChartsReact from "highcharts-react-official";

import React, { useState, useEffect } from "react";
import Row from "antd/es/row";
import Col from "antd/es/col";
import DatePicker from "Components/Inputs/DatePicker";
import Radio from "antd/es/radio";
import List from "antd/es/list";
import message from "antd/es/message";
import Card from "antd/es/card";
import api from "API";
import currencyFormatter from "Utils/currencyFormatter";
import percentFormatter from "Utils/percentFormatter";
import addCommas from "Utils/addCommas";
import styles from "./styles.module.scss";

window.Highcharts = HighCharts;

const { MonthPicker, WeekPicker } = DatePicker;
const RadioGroup = Radio.Group;

const today = dayjs();
const yearStart = dayjs().startOf("year");

const ranges = {
  "Last 7 Days": [dayjs().subtract(7, "days").startOf("day"), dayjs()],
  "Last Week": [
    dayjs().startOf("week").subtract(7, "days"),
    dayjs().startOf("week").subtract(1, "day").endOf("day"),
  ],
  "Last Month": [
    dayjs().startOf("month").subtract(1, "months"),
    dayjs().startOf("month").subtract(1, "months").endOf("month"),
  ],
  "This Month": [dayjs().startOf("month"), dayjs().endOf("month")],
  "This Year": [dayjs().startOf("year"), dayjs()],
};

HighCharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
  colors: [
    "#00c292",
    "#fec107",
    "#FF7056",
    "#343a40",
    "#01c0c8",
    "#999999",
    "#FF9655",
    "#FFF263",
    "#6AF9C4",
  ],
});

const LineChartWithInterval = ({
  path,
  type,
  aggregate,
  report,
  summary,
  headers,
  lineItems,
  table = true,
}) => {
  const [start, setStart] = useState(yearStart);
  const [end, setEnd] = useState(today);
  const [interval, setInterval] = useState("week");
  const [state, setState] = useState({
    totalValue: 0,
    table: [],
    options: {
      chart: {
        type: "line",
        marginLeft: 65,
        marginRight: 24,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: "",
      },
      subtitle: {
        text: "",
      },
      xAxis: {
        type: "category",
        categories: [],
        labels: {
          rotation: -45,
          style: {
            fontSize: "13px",
            fontFamily: "Verdana, sans-serif",
          },
        },
      },
      yAxis: {
        title: {
          text: "",
        },
        labels: {
          formatter: function () {
            let label = this.value;
            if (this.value >= 1000000) {
              label = this.value / 1000000 + "M";
            } else if (this.value >= 1000) {
              label = this.value / 1000 + "k";
            } else if (this.value <= -1000) {
              label = this.value / 1000 + "k";
            } else if (this.value <= -1000000) {
              label = this.value / 1000000 + "M";
            }
            return `$${label}`;
          },
        },
      },
      legend: {
        layout: "horizontal",
        align: "center",
        verticalAlign: "bottom",
        borderWidth: 0,
      },
      plotOptions: {
        series: {
          pointPlacement: "on",
        },
        line: {
          dataLabels: {
            enabled: true,
            formatter: function () {
              if (type === "currency") {
                return "$" + HighCharts.numberFormat(this.y, 2);
              } else if (type === "percent") {
                return HighCharts.numberFormat(this.y, 1) + "%";
              } else {
                return HighCharts.numberFormat(this.y, 1);
              }
            },
          },
        },
      },
      tooltip: {
        crosshairs: true,
        shared: true,
        useHTML: true,
        formatter: function () {
          let t = 0;
          let table = "<table><tr><td><b>" + this.x + "</b></td><td /></tr>";
          this.points.map((point) => {
            table +=
              '<tr><td style="color:' +
              point.color +
              ';">' +
              point.series.name +
              ':</td><td style="text-align:right;color:' +
              point.color +
              ';">';
            if (type === "currency") {
              table += "$";
            }

            table += HighCharts.numberFormat(point.y, 2);

            if (type === "percent") {
              table += "%";
            }
            table += "</td></tr>";
            t += parseFloat(point.y);
            return point;
          });

          table += `<tr style="border-top:2px solid #a5a5a5;">
              <td><b>Total:</b></td>
              <td style="text-align:right;"><b>`;

          if (type === "currency") {
            table += "$";
          }

          table += HighCharts.numberFormat(t, 2);

          if (type === "percent") {
            table += "%";
          }

          table += "</b></td></tr></table>";

          return table;
        },
      },
      series: [],
    },
  });

  useEffect(() => {
    getReport();
  }, [interval, start, end]); // eslint-disable-line react-hooks/exhaustive-deps

  const getReport = async () => {
    try {
      const res = await api.post(path, {
        start,
        end,
        interval,
      });

      const _table = [];
      for (const x of res.data.series) {
        if (table) {
          let obj = {};
          obj.name = x.name;
          if (aggregate) {
            obj.total = x.data.reduce((partial_sum, a) => partial_sum + a);
          } else if (x.total) {
            obj.total = x.total;
          } else {
            obj.total = x.data[x.data.length - 1];
          }

          _table.push(obj);
        }
      }

      setState((thisState) => {
        return {
          ...thisState,
          table: _table,
          totalValue: res.data.backLog ? res.data.backLog : res.data.totalValue,
          backLog: res.data.backLog ? true : false,
          options: {
            ...thisState.options,
            series: res.data.series,
            xAxis: {
              ...thisState.options.xAxis,
              categories: res.data.weeks,
            },
          },
        };
      });
    } catch (err) {
      console.log("err", err);
      message.error("Error getting report");
    }
  };

  const handleIntervalChange = (e) => {
    setInterval(e.target.value);
  };

  const onStartChange = (value) => {
    setStart(value);
  };

  const onEndChange = (value) => {
    setEnd(value);
  };

  const renderFooter = () => {
    return (
      <div style={{ textAlign: "left" }}>
        <div className="ant-tag ant-tag-blue" onClick={setRange("Last 7 Days")}>
          Last 7 Days
        </div>
        <div className="ant-tag ant-tag-blue" onClick={setRange("Last Week")}>
          Last Week
        </div>
        <div className="ant-tag ant-tag-blue" onClick={setRange("Last Month")}>
          Last Month
        </div>
        <div className="ant-tag ant-tag-blue" onClick={setRange("This Month")}>
          This Month
        </div>
        <div className="ant-tag ant-tag-blue" onClick={setRange("This Year")}>
          This Year
        </div>
      </div>
    );
  };

  const setRange = (rangeString) => () => {
    let range = ranges[rangeString];
    setStart(range[0]);
    setEnd(range[1]);
  };

  return (
    <div style={{ paddingBottom: 24 }} className="content-inner">
      <Card bordered={false} className="z-depth-1" style={{ margin: 24 }}>
        <Row gutter={16} style={{ marginBottom: 24 }}>
          <Col xs={24}>
            <div className={styles.reportTitle}>{report}</div>
          </Col>
        </Row>
        {summary && (
          <Row gutter={16} style={{ marginBottom: 24 }}>
            <Col xs={24}>
              <div className={styles.reportHeader}>
                <div className={styles.total}>
                  {type === "currency"
                    ? currencyFormatter(state.totalValue)
                    : type === "percent"
                    ? percentFormatter(state.totalValue)
                    : addCommas(state.totalValue)}
                  <small> {state.backLog ? "BACKLOG" : "TOTAL VALUE"}</small>
                </div>
              </div>
            </Col>
          </Row>
        )}

        <Row gutter={16} style={{ marginBottom: 24 }}>
          <Col xs={24}>
            <div className={styles.reportHeader}>
              <div>
                <div className="ant-form-item-label">
                  <label title="Date Range">Date Range</label>
                </div>
                {interval === "week" && (
                  <div>
                    <WeekPicker
                      allowClear={false}
                      format="MMM DD, YY"
                      defaultValue={dayjs(start, "MMM DD, YY")}
                      style={{ width: "calc(50% - 14px" }}
                      onChange={onStartChange}
                      renderExtraFooter={renderFooter}
                      value={start}
                    />
                    <span
                      style={{
                        width: 16,
                        textAlign: "center",
                        display: "inline-block",
                        paddingLeft: 6,
                        paddingRight: 6,
                      }}
                    >
                      to
                    </span>
                    <WeekPicker
                      allowClear={false}
                      format="MMM DD, YY"
                      defaultValue={dayjs(end, "MMM DD, YY")}
                      style={{ width: "calc(50% - 14px", float: "right" }}
                      onChange={onEndChange}
                      renderExtraFooter={renderFooter}
                    />
                  </div>
                )}
                {interval === "month" && (
                  <div>
                    <MonthPicker
                      allowClear={false}
                      format="MMM, YY"
                      defaultValue={dayjs(start, "MMM, YY")}
                      style={{ width: "calc(50% - 14px" }}
                      onChange={onStartChange}
                      renderExtraFooter={renderFooter}
                      value={start}
                    />
                    <span
                      style={{
                        width: 16,
                        textAlign: "center",
                        display: "inline-block",
                        paddingLeft: 6,
                        paddingRight: 6,
                      }}
                    >
                      to
                    </span>
                    <MonthPicker
                      allowClear={false}
                      format="MMM, YY"
                      defaultValue={dayjs(end, "MMM, YY")}
                      style={{ width: "calc(50% - 14px", float: "right" }}
                      onChange={onEndChange}
                      renderExtraFooter={renderFooter}
                    />
                  </div>
                )}
                {interval === "year" && (
                  <div>
                    <MonthPicker
                      allowClear={false}
                      format="YYYY"
                      defaultValue={dayjs(start, "YYYY")}
                      style={{ width: "calc(50% - 14px" }}
                      onChange={onStartChange}
                      renderExtraFooter={renderFooter}
                      value={start}
                    />
                    <span
                      style={{
                        width: 16,
                        textAlign: "center",
                        display: "inline-block",
                        paddingLeft: 6,
                        paddingRight: 6,
                      }}
                    >
                      to
                    </span>
                    <MonthPicker
                      allowClear={false}
                      format="YYYY"
                      defaultValue={dayjs(end, "YYYY")}
                      style={{ width: "calc(50% - 14px", float: "right" }}
                      onChange={onEndChange}
                      renderExtraFooter={renderFooter}
                    />
                  </div>
                )}
              </div>
              <div className={`${styles.vDivider} ${styles.hideSmDown}`} />
              <div className={styles.hideSmDown}>
                <br />
                <br />
                <RadioGroup onChange={handleIntervalChange} value={interval}>
                  <Radio value="week">Week</Radio>
                  <Radio value="month">Month</Radio>
                  <Radio value="year">Year</Radio>
                </RadioGroup>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
            <div className="options">
              <br />
              <RadioGroup onChange={handleIntervalChange} value={interval}>
                <Radio value="week">Week</Radio>
                <Radio value="month">Month</Radio>
                <Radio value="year">Year</Radio>
              </RadioGroup>
            </div>
          </Col>
        </Row>

        <HighChartsReact
          highcharts={HighCharts}
          options={state.options}
          updateArgs={[true, true, true]}
        />
      </Card>

      {table && (
        <Card
          bordered={false}
          className="z-depth-1"
          style={{
            marginTop: 24,
            marginLeft: 24,
            marginRight: 24,
          }}
          bodyStyle={{
            padding: 0,
          }}
        >
          <List>
            {report !== "COMPANY PROFIT" &&
              report !== "COMPANY PROFIT PERCENTAGE" && (
                <List.Item className={styles.listHeader}>
                  {headers.map((header, i) => {
                    if (i !== 0) {
                      return (
                        <List.Item.Meta
                          key={header}
                          className={styles.listRight}
                          title={header}
                        />
                      );
                    } else {
                      return <List.Item.Meta key={header} title={header} />;
                    }
                  })}
                </List.Item>
              )}

            {lineItems
              ? state.table.map((t, i) => {
                  return (
                    <List.Item key={t.name + i} className={styles.listItem}>
                      <List.Item.Meta title={t.name} />
                      <List.Item.Meta
                        title={
                          type === "currency"
                            ? currencyFormatter(t.total)
                            : type === "percent"
                            ? percentFormatter(t.total)
                            : t.total.toString()
                        }
                        className={styles.listRight}
                      />
                    </List.Item>
                  );
                })
              : null}

            {summary && (
              <List.Item className={styles.listTotal}>
                <List.Item.Meta title="Total" />
                <List.Item.Meta
                  className={styles.listRight}
                  title={
                    type === "currency"
                      ? currencyFormatter(state.totalValue)
                      : type === "percent"
                      ? percentFormatter(state.totalValue)
                      : state.totalValue
                      ? state.totalValue.toString()
                      : "0"
                  }
                />
              </List.Item>
            )}
          </List>
        </Card>
      )}
    </div>
  );
};

export default LineChartWithInterval;
