import React, { useState, useEffect, useCallback } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateDetail } from "Actions/dataActions";
import Form from "antd/es/form";
import Button from "antd/es/button";
import message from "antd/es/message";
import Input from "antd/es/input";
import InputAddress from "Components/Inputs/Address";
import Map from "./Map";
import CopyableContent from "Components/CopyableContent";
import usePrevious from "Hooks/usePrevious";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import capitalize from "Utils/capitalize";
import api from "API";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";

const Address = ({
  street,
  _id,
  type,
  city,
  state,
  zip,
  county = undefined,
  lat = undefined,
  lng = undefined,
  permitAuthority = undefined,
  map = false,
  onChange,
  setStringToCopy,
  stringToCopy,
  single = false,
}) => {
  const dispatch = useDispatch();
  const viewableActivities = useSelector(
    (state) => state.dataState.viewableActivities
  );
  const [form] = Form.useForm();
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [_lat, _setLat] = useState(lat);
  const [_lng, _setLng] = useState(lng);
  const prevStreet = usePrevious(street);

  useEffect(() => {
    if (!prevStreet && street) {
      form.setFieldsValue({
        [`${type}AddressStreet`]: street,
        [`${type}AddressCity`]: city,
        [`${type}AddressState`]: state,
        [`${type}AddressZip`]: zip,
        [`${type}AddressCounty`]: county,
        [`${type}AddressPermitAuthority`]: permitAuthority,
        [`${type}AddressLat`]: lat,
        [`${type}AddressLng`]: lng,
      });

      _setLat(lat);
      _setLng(lng);
    }
  }, [prevStreet, street]); // eslint-disable-line react-hooks/exhaustive-deps

  const onFinish = useCallback(
    async (values) => {
      try {
        setLoading(true);
        values._id = _id;
        values.type = type;
        values.previous = {};
        values.previous.street = street;
        values.previous.city = city;
        values.previous.state = state;
        values.previous.zip = zip;
        values.previous.lat = lat;
        values.previous.lng = lng;

        if (
          values[`${type}AddressStreet`] !== street ||
          values[`${type}AddressCity`] !== city ||
          values[`${type}AddressState`] !== state ||
          values[`${type}AddressZip`] !== zip ||
          values[`${type}AddressLat`] !== lat ||
          values[`${type}AddressLng`] !== lng
        ) {
          const res = await api.post("/update/address", values);

          dispatch(
            updateDetail({
              viewableActivities,
              key: `${type}Address`,
              value: res.data.value,
              activities: res.data.activities,
            })
          );

          if (onchange) {
            onChange({
              value: res.data.value,
            });
          }

          message.success("Address updated");
        }
        unstable_batchedUpdates(() => {
          setLoading(false);
          setEdit(false);
        });
      } catch (err) {
        console.log("err", err);
        message.error("Error updating Address");
        setLoading(false);
      }
    },
    [
      _id,
      type,
      street,
      city,
      state,
      zip,
      lat,
      lng,
      dispatch,
      viewableActivities,
      onChange,
    ]
  );

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error updating Address");
  };

  const updateCords = ({ lat, lng }) => {
    _setLat(lat);
    _setLng(lng);
  };

  const setAddress = ({
    city,
    state,
    postcode,
    county,
    permitAuthority,
    lat,
    lng,
  }) => {
    form.setFieldsValue({
      [`${type}AddressCity`]: city,
      [`${type}AddressState`]: state,
      [`${type}AddressZip`]: postcode,
      [`${type}AddressCounty`]: county,
      [`${type}AddressPermitAuthority`]: permitAuthority,
      [`${type}AddressLat`]: lat,
      [`${type}AddressLng`]: lng,
    });

    updateCords({
      lat,
      lng,
    });
  };

  const toggleEdit = () => {
    setEdit(!edit);
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      initialValues={{
        [`${type}AddressStreet`]: street,
        [`${type}AddressCity`]: city,
        [`${type}AddressState`]: state,
        [`${type}AddressZip`]: zip,
        [`${type}AddressCounty`]: county,
        [`${type}AddressPermitAuthority`]: permitAuthority,
        [`${type}AddressLat`]: lat,
        [`${type}AddressLng`]: lng,
      }}
      style={{ marginBottom: 16 }}
    >
      <RemoveAutocomplete />
      <h4
        style={{ textTransform: "capitalize", marginBottom: 3 }}
        className="withIcon"
      >
        {type} Address{" "}
        <CopyableContent
          title={`${capitalize(type)} Address`}
          content={`${street} ${city}, ${state} ${zip}`}
          setStringToCopy={setStringToCopy}
          stringToCopy={stringToCopy}
          edit={edit}
          single={single}
        />
      </h4>
      {!edit && (
        <p
          className="editableRow"
          style={{ marginBottom: 0 }}
          onClick={toggleEdit}
        >
          <span>{street}</span>
          <br />
          <span>
            {city}, {state} {zip}
          </span>
        </p>
      )}

      {edit && (
        <>
          <Form.Item
            label="Street"
            name={`${type}AddressStreet`}
            rules={[{ required: true, message: "Street is required" }]}
          >
            <InputAddress
              autoFocus
              autoComplete="edit-detail-field"
              type="text"
              setAddress={setAddress}
              id={`${type}AddressStreet`}
            />
          </Form.Item>

          <Form.Item
            label="City"
            name={`${type}AddressCity`}
            rules={[
              { required: true, message: "City is required", type: "string" },
            ]}
          >
            <InputNoAutoComplete id={`${type}AddressCity`} />
          </Form.Item>

          <Form.Item
            label="State"
            name={`${type}AddressState`}
            rules={[
              {
                required: true,
                message: "State is required",
                type: "string",
              },
              {
                len: 2,
                message: "State must be abbreviated",
              },
            ]}
          >
            <InputNoAutoComplete id={`${type}AddressState`} />
          </Form.Item>

          <Form.Item
            label="Zip"
            name={`${type}AddressZip`}
            rules={[
              { required: true, message: "Zip is required", type: "string" },
            ]}
          >
            <InputNoAutoComplete id={`${type}AddressZip`} />
          </Form.Item>

          {map && (
            <Map
              type={type}
              form={form}
              lat={_lat}
              lng={_lng}
              updateCords={updateCords}
            />
          )}

          <Form.Item style={{ display: "none" }} name={`${type}AddressCounty`}>
            <Input autoComplete="dont-use-autocomplete" type="text" />
          </Form.Item>

          <Form.Item
            style={{ display: "none" }}
            name={`${type}AddressPermitAuthority`}
          >
            <Input autoComplete="dont-use-autocomplete" type="text" />
          </Form.Item>

          <Form.Item style={{ display: "none" }} name={`${type}AddressLat`}>
            <Input autoComplete="dont-use-autocomplete" type="text" />
          </Form.Item>

          <Form.Item style={{ display: "none" }} name={`${type}AddressLng`}>
            <Input autoComplete="dont-use-autocomplete" type="text" />
          </Form.Item>

          <div className="editControls">
            <Button
              type="default"
              size="small"
              onClick={toggleEdit}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              size="small"
              loading={loading}
              disabled={loading}
            >
              Submit
            </Button>
          </div>
        </>
      )}
    </Form>
  );
};

export default Address;
