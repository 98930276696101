import * as actions from "Actions/actionTypes";

const defaultState = {
  details: {},
  activities: [],
  filteredActivities: [],
  activitiesLength: 0,
  viewableActivities: [],
  collections: [],
  expenses: [],
  expensesByVendor: [],
  expensesByScope: [],
  invoices: [],
  completionCertificates: [],
  actions: [],
  dumpings: [],
  scopes: [],
  allMedia: [],
  legacyFiles: [],
  documents: [],
  fetching: true,
  selectedRow: null,
  showImageViewer: false,
  viewingImage: {
    src: undefined,
    _id: undefined,
  },
  selectedActivity: {
    images: [],
    index: 0,
    _id: null,
    _activityCreatedBy: undefined,
  },
  hashtags: [],
  totalExpenses: 0,
  totalCollections: 0,
  totalActions: 0,
  totalInvoiced: 0,
  remainingBalance: 0,
  mold: {
    expectedBalance: 0,
    expectedOverhead: 0,
    expectedExtras: 0,
    expectedLabor: 0,
    expectedMaterials: 0,
    expectedDumping: 0,
    expectedGutters: 0,
    expectedInsulation: 0,
  },
  breakdown: {
    actualBalance: 0,
    actualOverhead: 0,
    actualExtras: 0,
    actualPermit: 0,
    actualLabor: 0,
    actualMaterials: 0,
    actualDumping: 0,
  },
};

const dataReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actions.SET_ALL_DATA:
      return {
        details: action.payload.details,
        activities: action.payload.activities,
        filteredActivities: action.payload.filteredActivities,
        activitiesLength: action.payload.activitiesLength,
        viewableActivities: action.payload.viewableActivities,
        allMedia: action.payload.allMedia,
        collections: action.payload.collections,
        expenses: action.payload.expenses,
        expensesByVendor: action.payload.expensesByVendor,
        expensesByScope: action.payload.expensesByScope,
        invoices: action.payload.invoices,
        completionCertificates: action.payload.completionCertificates,
        actions: action.payload.actions,
        dumpings: action.payload.dumpings,
        documents: action.payload.documents,
        scopes: action.payload.scopes,
        legacyFiles: action.payload.legacyFiles,
        selectedRow: action.payload.selectedRow,
        hashtags: action.payload.hashtags,
        totalExpenses: action.payload.totalExpenses,
        totalCollections: action.payload.totalCollections,
        totalInvoiced: action.payload.totalInvoiced,
        totalActions: action.payload.totalActions,
        remainingBalance: action.payload.remainingBalance,
        mold: action.payload.mold,
        breakdown: action.payload.breakdown,
        fetching: false,
      };
    case actions.UPDATE_DETAILS:
      return {
        ...state,
        details: action.payload.details,
        activities: action.payload.activities,
        filteredActivities: action.payload.filteredActivities,
        activitiesLength: action.payload.activitiesLength,
        viewableActivities: action.payload.viewableActivities,
      };
    case actions.UPDATE_DETAIL:
      return {
        ...state,
        activities: action.payload.activities,
        filteredActivities: action.payload.filteredActivities,
        activitiesLength: action.payload.activitiesLength,
        viewableActivities: action.payload.viewableActivities,
        details: {
          ...state.details,
          [action.payload.key]: action.payload.value,
        },
      };
    case actions.UPDATE_PROJECT_ADDRESS:
      return {
        ...state,
        activities: action.payload.activities,
        filteredActivities: action.payload.filteredActivities,
        activitiesLength: action.payload.activitiesLength,
        viewableActivities: action.payload.viewableActivities,
        details: {
          ...state.details,
          projectAddress: action.payload.projectAddress,
          projectAddressStreet: action.payload.projectAddressStreet,
          projectAddressCity: action.payload.projectAddressCity,
          projectAddressState: action.payload.projectAddressState,
          projectAddressZip: action.payload.projectAddressZip,
          projectAddressLat: action.payload.projectAddressLat,
          projectAddressLng: action.payload.projectAddressLng,
          projectAddressCounty: action.payload.projectAddressCounty,
          projectAddressPermitAuthority:
            action.payload.projectAddressPermitAuthority,
        },
      };
    case actions.UPDATE_BILLING_ADDRESS:
      return {
        ...state,
        activities: action.payload.activities,
        filteredActivities: action.payload.filteredActivities,
        activitiesLength: action.payload.activitiesLength,
        viewableActivities: action.payload.viewableActivities,
        details: {
          ...state.details,
          billingAddress: action.payload.billingAddress,
          billingAddressStreet: action.payload.billingAddressStreet,
          billingAddressCity: action.payload.billingAddressCity,
          billingAddressState: action.payload.billingAddressState,
          billingAddressZip: action.payload.billingAddressZip,
          billingAddressLat: action.payload.billingAddressLat,
          billingAddressLng: action.payload.billingAddressLng,
          billingAddressCounty: action.payload.billingAddressCounty,
          billingAddressPermitAuthority:
            action.payload.billingAddressPermitAuthority,
        },
      };
    case actions.UPDATE_SALESMEN:
      return {
        ...state,
        activities: action.payload.activities,
        filteredActivities: action.payload.filteredActivities,
        activitiesLength: action.payload.activitiesLength,
        viewableActivities: action.payload.viewableActivities,
        details: {
          ...state.details,
          salesman: action.payload.salesman,
          salesObj: action.payload.salesObj,
        },
      };
    case actions.SET_SELECTED:
      return {
        ...state,
        selectedRow: action.payload,
      };
    case actions.UPDATE_ACTIVITIES_AND_ALL_MEDIA:
      return {
        ...state,
        activities: action.payload.activities,
        filteredActivities: action.payload.filteredActivities,
        activitiesLength: action.payload.activitiesLength,
        viewableActivities: action.payload.viewableActivities,
        allMedia: action.payload.allMedia,
        legacyFiles: action.payload.legacyFiles,
      };
    case actions.UPDATE_ACTIVITIES_ONLY:
      return {
        ...state,
        activities: action.payload.activities,
        filteredActivities: action.payload.filteredActivities,
        activitiesLength: action.payload.activitiesLength,
        viewableActivities: action.payload.viewableActivities,
      };
    case actions.UPDATE_HASHTAGS:
      return {
        ...state,
        activities: action.payload.activities,
        hashtags: action.payload.hashtags,
        activitiesLength: action.payload.activitiesLength,
        filteredActivities: action.payload.filteredActivities,
        viewableActivities: action.payload.viewableActivities,
      };
    case actions.OPEN_IMAGE_VIEWER:
      return {
        ...state,
        showImageViewer: true,
        viewingImage: action.payload,
      };
    case actions.CLOSE_IMAGE_VIEWER:
      return {
        ...state,
        showImageViewer: false,
        viewingImage: {
          src: undefined,
          _id: undefined,
        },
      };
    case actions.UPDATE_ACTIVITIES_AND_SELECTED_ACTIVITY:
      return {
        ...state,
        selectedActivity: action.payload.selectedActivity,
        activities: action.payload.activities,
        viewableActivities: action.payload.viewableActivities,
        filteredActivities: action.payload.filteredActivities,
        activitiesLength: action.payload.activitiesLength,
        allMedia: action.payload.allMedia,
        legacyFiles: action.payload.legacyFiles,
      };
    case actions.UPDATE_ACTIVITIES_ALL_MEDIA_AND_HASHTAGS:
      return {
        ...state,
        activities: action.payload.activities,
        allMedia: action.payload.allMedia,
        hashtags: action.payload.hashtags,
      };
    case actions.UPDATE_INVOICES_ACTIVITIES_ALL_MEDIA_AND_HASHTAGS:
      return {
        ...state,
        activities: action.payload.activities,
        viewableActivities: action.payload.viewableActivities,
        filteredActivities: action.payload.filteredActivities,
        activitiesLength: action.payload.activitiesLength,
        allMedia: action.payload.allMedia,
        hashtags: action.payload.hashtags,
        invoices: action.payload.invoices,
        totalInvoiced: action.payload.totalInvoiced,
      };
    case actions.UPDATE_COMPLETION_CERTIFICATES_ACTIVITIES_ALL_MEDIA_AND_HASHTAGS:
      return {
        ...state,
        activities: action.payload.activities,
        viewableActivities: action.payload.viewableActivities,
        filteredActivities: action.payload.filteredActivities,
        activitiesLength: action.payload.activitiesLength,
        allMedia: action.payload.allMedia,
        hashtags: action.payload.hashtags,
        completionCertificates: action.payload.completionCertificates,
      };
    case actions.UPDATE_ACTIONS_ACTIVITIES_AND_HASHTAGS:
      return {
        ...state,
        activities: action.payload.activities,
        filteredActivities: action.payload.filteredActivities,
        viewableActivities: action.payload.viewableActivities,
        activitiesLength: action.payload.activitiesLength,
        hashtags: action.payload.hashtags,
        actions: action.payload.actions,
        totalActions: action.payload.totalActions,
      };
    case actions.UPDATE_DUMPSTERS_ACTIVITIES_AND_HASHTAGS:
      return {
        ...state,
        activities: action.payload.activities,
        filteredActivities: action.payload.filteredActivities,
        activitiesLength: action.payload.activitiesLength,
        viewableActivities: action.payload.viewableActivities,
        hashtags: action.payload.hashtags,
        dumpings: action.payload.dumpings,
      };
    case actions.UPDATE_EXPENSES_ACTIVITIES_AND_HASHTAGS:
      return {
        ...state,
        expenses: action.payload.expenses,
        hashtags: action.payload.hashtags,
        activities: action.payload.activities,
        viewableActivities: action.payload.viewableActivities,
        filteredActivities: action.payload.filteredActivities,
        activitiesLength: action.payload.activitiesLength,
        totalExpenses: action.payload.totalExpenses,
        breakdown: action.payload.breakdown,
        remainingBalance: action.payload.remainingBalance,
      };
    case actions.UPDATE_DOCUMENTS_ACTIVITIES_AND_HASHTAGS:
      return {
        ...state,
        hashtags: action.payload.hashtags,
        activities: action.payload.activities,
        documents: action.payload.documents,
        viewableActivities: action.payload.viewableActivities,
        filteredActivities: action.payload.filteredActivities,
        activitiesLength: action.payload.activitiesLength,
      };
    case actions.UPDATE_COLLECTIONS_ACTIVITIES_AND_HASHTAGS:
      return {
        ...state,
        collections: action.payload.collections,
        hashtags: action.payload.hashtags,
        activities: action.payload.activities,
        viewableActivities: action.payload.viewableActivities,
        filteredActivities: action.payload.filteredActivities,
        activitiesLength: action.payload.activitiesLength,
        totalCollections: action.payload.totalCollections,
        remainingBalance: action.payload.remainingBalance,
        breakdown: {
          ...state.breakdown,
          actualBalance: action.payload.actualBalance,
          actualOverhead: action.payload.actualOverhead,
        },
      };
    case actions.UPDATE_SCOPES:
      return {
        ...state,
        scopes: action.payload,
      };
    case actions.UPDATE_SCOPES_ACTIVITIES_AND_HASHTAGS:
      return {
        ...state,
        activities: action.payload.activities,
        viewableActivities: action.payload.viewableActivities,
        filteredActivities: action.payload.filteredActivities,
        activitiesLength: action.payload.activitiesLength,
        hashtags: action.payload.hashtags,
        scopes: action.payload.scopes,
        contractPrice: action.payload.contractPrice,
        mold: action.payload.mold,
      };
    case actions.UPDATE_SCOPES_AND_ACTIVITIES:
      return {
        ...state,
        activities: action.payload.activities,
        viewableActivities: action.payload.viewableActivities,
        filteredActivities: action.payload.filteredActivities,
        activitiesLength: action.payload.activitiesLength,
        scopes: action.payload.scopes,
        details: {
          ...state.details,
          pitches: action.payload.pitches,
          stories: action.payload.stories,
          customerName: action.payload.customerName,
          propertyType: action.payload.propertyType,
          projectAddressStreet: action.payload.projectAddressStreet,
          projectAddressCity: action.payload.projectAddressCity,
          projectAddressState: action.payload.projectAddressState,
          projectAddressZip: action.payload.projectAddressZip,
          projectAddressCounty: action.payload.projectAddressCounty,
          projectAddressPermitAuthority:
            action.payload.projectAddressPermitAuthority,
          projectAddressLat: action.payload.projectAddressLat,
          projectAddressLng: action.payload.projectAddressLng,
          projectDescription: action.payload.projectDescription,
          projectAddress: action.payload.projectAddress,
          phone: action.payload.phone,
        },
      };
    case actions.WORKORDER_CREATED:
      return {
        ...state,
        activities: action.payload.activities,
        filteredActivities: action.payload.filteredActivities,
        activitiesLength: action.payload.activitiesLength,
        viewableActivities: action.payload.viewableActivities,
        scopes: action.payload.scopes,
        allMedia: action.payload.allMedia,
        hashtags: action.payload.hashtags,
        details: {
          ...state.details,
          pitches: action.payload.pitches,
          stories: action.payload.stories,
          customerName: action.payload.customerName,
          propertyType: action.payload.propertyType,
          projectAddressStreet: action.payload.projectAddressStreet,
          projectAddressCity: action.payload.projectAddressCity,
          projectAddressState: action.payload.projectAddressState,
          projectAddressZip: action.payload.projectAddressZip,
          projectAddressCounty: action.payload.projectAddressCounty,
          projectAddressPermitAuthority:
            action.payload.projectAddressPermitAuthority,
          projectAddressLat: action.payload.projectAddressLat,
          projectAddressLng: action.payload.projectAddressLng,
          projectDescription: action.payload.projectDescription,
          projectAddress: action.payload.projectAddress,
          phone: action.payload.phone,
        },
      };
    case actions.UPDATE_SELECTED_ACTIVITY:
      return {
        ...state,
        selectedActivity: action.payload,
      };
    case actions.ADD_ACTIVITIES:
      return {
        ...state,
        viewableActivities: action.payload,
      };
    case actions.UPDATE_FILTERED_ACTIVITIES:
      return {
        ...state,
        filteredActivities: action.payload.filteredActivities,
        activitiesLength: action.payload.activitiesLength,
        viewableActivities: action.payload.viewableActivities,
      };
    case actions.START_SCOPE:
      return {
        ...state,
        scopes: state.scopes.map((scope) =>
          scope._id.toString() === action.payload.toString()
            ? {
                ...scope,
                startedDate: new Date(),
              }
            : scope
        ),
      };
    case actions.STOP_SCOPE:
      return {
        ...state,
        scopes: state.scopes.map((scope) =>
          scope._id.toString() === action.payload.toString()
            ? {
                ...scope,
                installStatus: "Completed",
                status: "Completed",
                installCompleted: new Date(),
                completedDate: new Date(),
              }
            : scope
        ),
      };
    case actions.FETCHING_DATA:
      return {
        ...state,
        fetching: true,
      };
    case actions.UNSET_DATA:
      return {
        details: {},
        activities: [],
        filteredActivities: [],
        activitiesLength: 0,
        viewableActivities: [],
        collections: [],
        expenses: [],
        expensesByVendor: [],
        expensesByScope: [],
        invoices: [],
        completionCertificates: [],
        actions: [],
        dumpings: [],
        documents: [],
        scopes: [],
        legacyFiles: [],
        fetching: true,
        selectedRow: null,
        showImageViewer: false,
        viewingImage: {
          src: undefined,
          _id: undefined,
        },
        selectedActivity: {
          images: [],
          index: 0,
          _id: null,
          _activityCreatedBy: undefined,
        },
        hashtags: [],
        totalExpenses: 0,
        totalCollections: 0,
        totalInvoiced: 0,
        totalActions: 0,
        remainingBalance: 0,
        mold: {
          expectedBalance: 0,
          expectedOverhead: 0,
          expectedExtras: 0,
          expectedLabor: 0,
          expectedMaterials: 0,
          expectedDumping: 0,
          expectedGutters: 0,
          expectedInsulation: 0,
        },
        breakdown: {
          actualBalance: 0,
          actualOverhead: 0,
          actualExtras: 0,
          actualPermit: 0,
          actualLabor: 0,
          actualMaterials: 0,
          actualDumping: 0,
        },
      };
    default:
      return state;
  }
};

export default dataReducer;
