import React, { useState, useEffect, memo } from "react";
import { useParams } from "react-router-dom";
import FormControls from "Components/FormControls";
import FormBody from "Components/FormBody";
import ImagesDrawerHeader from "Components/images/ImagesDrawer/Header";
import styles from "./styles.module.scss";
import SelectingControls from "Components/images/ImagesDrawer/SelectingControls";
import Image from "Components/images/ImagesDrawer/Image";
import usePrevious from "Hooks/usePrevious";

const Media = ({ onClose, allMedia }) => {
  const params = useParams();

  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedSize, setSelectedSize] = useState(0);
  const [selecting, setSelecting] = useState(false);
  const prevSelecting = usePrevious(selecting);

  useEffect(() => {
    let _selectedSize = 0;
    for (const si of selectedImages) {
      _selectedSize += si.size;
    }
    setSelectedSize(_selectedSize);
  }, [selectedImages]);

  useEffect(() => {
    const timer = setTimeout(() => {
      const el = document.getElementById(`media-${params.index}`);
      if (el) {
        el.scrollIntoView(true);
      }
    }, 100);
    return () => {
      clearTimeout(timer);
      document.body.classList.remove("selecting");
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!prevSelecting && selecting) {
      document.body.classList.add("selecting");
    } else if (!selecting && prevSelecting) {
      document.body.classList.remove("selecting");
    }
  }, [selecting, prevSelecting]);

  useEffect(() => {
    if (!selecting) {
      setSelectedImages([]);
    }
  }, [selecting]);

  return (
    <div className={styles.mediaContainer}>
      <FormControls className={styles.headerContainer}>
        <ImagesDrawerHeader
          length={allMedia.length}
          onClose={onClose}
          setSelecting={setSelecting}
          selecting={selecting}
          selectedImages={selectedImages}
          setSelectedImages={setSelectedImages}
          images={allMedia}
        />
      </FormControls>
      <FormBody
        className={`${styles.imageBody} ${selecting ? styles.selecting : ""}`}
        style={{ background: "#f0f0f0" }}
      >
        <div className="content-inner" id="mediaViewer">
          {allMedia.map((image, _index) => (
            <Image
              image={image}
              key={image._id || image.name}
              _index={_index}
              selecting={selecting}
              selectedImages={selectedImages}
              setSelectedImages={setSelectedImages}
              scrollContainer="#mediaViewer"
            />
          ))}
        </div>
      </FormBody>
      <SelectingControls
        selecting={selecting}
        selectedImages={selectedImages}
        setSelecting={setSelecting}
        selectedSize={selectedSize}
      />
    </div>
  );
};

export default memo(Media);
