import React, { useState } from "react";
import { useSelector } from "react-redux";
import Form from "antd/es/form";
import Menu from "antd/es/menu";
import Dropdown from "antd/es/dropdown";
import Tag from "antd/es/tag";
import Button from "antd/es/button";
import message from "antd/es/message";
import Checkbox from "antd/es/checkbox";
import TagOutlined from "@ant-design/icons/TagOutlined";
import DatePicker from "Components/Inputs/DatePicker";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import dayjs from "dayjs";
import api from "API";
import useIsMobile from "Hooks/useIsMobile";
import isLead from "Utils/isLead";
import CancelButton from "Components/CancelButton";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";

const messageKey = "messageKey";

const CreateEvent = ({
  unsavedChanges,
  setUnsavedChanges,
  handleClose,
  type,
}) => {
  const mentions = useSelector((state) => state.authState.mentions);
  const details = useSelector((state) => state.dataState.details);
  const mobile = useIsMobile();
  const [loading, setLoading] = useState(false);
  const [allDay, setAllDay] = useState(true);
  const [_mentions, _setMentions] = useState(mentions);
  const [tags, setTags] = useState([]);
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      setLoading(true);
      // message.loading("Creating Event...", 0);
      message.loading({
        content: "Creating event...",
        duration: 0,
        key: messageKey,
      });
      values.attendees = tags;
      if (details._id) {
        let baseURL;
        if (process.env.NODE_ENV === "production") {
          baseURL = "https://story.gopremier.net";
        } else {
          baseURL = "http://localhost:3001";
        }
        const type = isLead(details.projectStatus) ? "leads" : "projects";
        values.url = `${baseURL}/${type}/view/details/${details._id}`;
        values.location = details.projectAddress;
        values.customerId = details.customerId;
        values.customerName = details.customerName;
        values.lat = details.projectAddressLat;
        values.lng = details.projectAddressLng;
      }

      if (values.allDay) {
        values.start = new Date(dayjs(values.start).startOf("d"));
        values.end = new Date(dayjs(values.end).endOf("d"));
      }
      await api.post("/events/create", values);
      // message.success("Event created");
      message.success({
        content: "Event created",
        duration: 1.5,
        key: messageKey,
      });
      setLoading(false);
      handleClose(true);
    } catch (err) {
      setLoading(false);
      // message.error("Error creating event");
      message.error({
        content: "Error creating event",
        duration: 1.5,
        key: messageKey,
      });
    }
  };

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error creating event");
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  const handleAllDayChange = (e) => {
    setAllDay(e.target.checked);
  };

  const menu = (
    <Menu>
      {_mentions.map((m) => {
        const tagClick = () => {
          setTags([...tags, m]);
          _setMentions(_mentions.filter((_m) => m._user !== _m._user));
        };

        return (
          <Menu.Item key={m._user} onClick={tagClick}>{`${m.name}`}</Menu.Item>
        );
      })}
    </Menu>
  );

  const cancel = () => {
    handleClose();
  };

  return (
    <Form
      form={form}
      layout="vertical"
      className="form"
      autoComplete="off"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      initialValues={{ allDay }}
    >
      <RemoveAutocomplete />

      <FormBody
        className="content-inner"
        style={{
          paddingLeft: 24,
          paddingTop: 24,
          paddingRight: 24,
          paddingBottom: 8,
          maxHeight: type === "modal" ? "calc(100vh - 161px)" : "unset",
        }}
      >
        <Form.Item
          name="title"
          label="Title"
          rules={[
            {
              required: true,
              message: "You must include a title",
            },
          ]}
        >
          <InputNoAutoComplete placeholder="Title" id="title" />
        </Form.Item>

        <Form.Item name="allDay" valuePropName="checked">
          <Checkbox onChange={handleAllDayChange}>All Day</Checkbox>
        </Form.Item>

        <Form.Item
          name="start"
          label="Start Date"
          rules={[
            {
              required: true,
              message: "You must include a start date",
            },
          ]}
        >
          <DatePicker
            format={!allDay ? "MMMM DD, YYYY h:mm A" : "MMMM DD, YYYY"}
            showTime={!allDay}
            getPopupContainer={(trigger) =>
              !mobile
                ? trigger.parentNode.parentNode.parentNode.parentNode
                : document.body
            }
            getCalendarContainer={(trigger) =>
              !mobile
                ? trigger.parentNode.parentNode.parentNode.parentNode
                : document.body
            }
            inputReadOnly={true}
          />
        </Form.Item>

        <Form.Item
          name="end"
          label="End Date"
          rules={[
            {
              required: true,
              message: "You must include an end date",
            },
          ]}
        >
          <DatePicker
            format={!allDay ? "MMMM DD, YYYY h:mm A" : "MMMM DD, YYYY"}
            showTime={!allDay}
            getPopupContainer={(trigger) =>
              !mobile
                ? trigger.parentNode.parentNode.parentNode.parentNode
                : document.body
            }
            getCalendarContainer={(trigger) =>
              !mobile
                ? trigger.parentNode.parentNode.parentNode.parentNode
                : document.body
            }
            inputReadOnly={true}
          />
        </Form.Item>

        <div className="drop180">
          <Dropdown
            overlay={menu}
            trigger={["click"]}
            getPopupContainer={(trigger) => trigger.parentNode}
          >
            <Button
              className="green"
              type="primary"
              block
              style={{ marginTop: 12, marginBottom: 24 }}
            >
              <TagOutlined /> Add Attendees (other than yourself)
            </Button>
          </Dropdown>
        </div>

        {tags.length > 0 && (
          <div style={{ paddingTop: "-8px", marginBottom: 8 }}>
            <div>Attendees:</div>
            {tags.map((t) => {
              const removeTag = () => {
                setTags(tags.filter((_t) => t._user !== _t._user));
                _setMentions(
                  [..._mentions, t].sort((a, b) => (a.name > b.name ? 1 : -1))
                );
              };

              return (
                <Tag
                  key={t._user}
                  color="#1890ff"
                  closable
                  style={{ marginBottom: 8 }}
                  onClose={removeTag}
                >{`${t.name}`}</Tag>
              );
            })}
          </div>
        )}

        {details._id && (
          <Form.Item name="includeLocation" valuePropName="checked">
            <Checkbox>Include Location</Checkbox>
          </Form.Item>
        )}
      </FormBody>
      <FormControls className="sticky-footer">
        <CancelButton handleClick={cancel} />
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={loading || !unsavedChanges}
        >
          Submit
        </Button>
      </FormControls>
    </Form>
  );
};

export default CreateEvent;
