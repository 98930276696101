import React, { useState } from "react";
import { useSelector } from "react-redux";
import Form from "antd/es/form";
import message from "antd/es/message";
import Button from "antd/es/button";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import api from "API";
import CancelButton from "Components/CancelButton";
import DatePicker from "Components/Inputs/DatePicker";
import useIsMobile from "Hooks/useIsMobile";
import dayjs from "dayjs";

const messageKey = "messageKey";

const ReScheduleScope = ({
  unsavedChanges,
  setUnsavedChanges,
  handleClose,
  type,
}) => {
  const formDetails = useSelector(
    (state) => state.formState.rescheduleScope.formDetails
  );
  const mobile = useIsMobile();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      // message.loading("Re-scheduling...", 0);
      message.loading({
        content: "Re-scheduling...",
        duration: 0,
        key: messageKey,
      });
      values._id = formDetails._id;
      values._projectId = formDetails._projectId;
      values.name = formDetails.name;
      values.assignToSub = formDetails.assignToSub;
      values.customerId = formDetails.customerId;
      values.projectAddress = formDetails.projectAddress;

      const res = await api.post("/schedule/re-schedule", values);

      if (formDetails.callback) {
        formDetails.callback(res.data);
      }

      // message.success("Scope has been re-scheduled");
      message.success({
        content: "Scope has been re-scheduled",
        duration: 1.5,
        key: messageKey,
      });
      handleClose(true);
    } catch (err) {
      setLoading(false);
      // message.error("Error re-scheduling scope");
      message.error({
        content: "Error re-scheduling scope",
        duration: 1.5,
        key: messageKey,
      });
      console.log("err", err);
    }
  };

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error re-scheduling scope");
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      className="form"
      initialValues={{
        installDate: dayjs(formDetails.installDate),
      }}
    >
      <RemoveAutocomplete />

      <FormBody
        className="content-inner"
        style={{
          paddingLeft: 24,
          paddingTop: 24,
          paddingRight: 24,
          paddingBottom: 8,
          maxHeight: type === "modal" ? "calc(100vh - 161px)" : "unset",
        }}
      >
        <Form.Item
          name="installDate"
          label="Install Date"
          rules={[{ required: true, message: "Install Date is required" }]}
        >
          <DatePicker
            format="MMMM DD, YYYY"
            inputReadOnly={true}
            getPopupContainer={(trigger) =>
              !mobile
                ? trigger.parentNode.parentNode.parentNode.parentNode
                : document.body
            }
            getCalendarContainer={(trigger) =>
              !mobile
                ? trigger.parentNode.parentNode.parentNode.parentNode
                : document.body
            }
          />
        </Form.Item>
      </FormBody>
      <FormControls className="sticky-footer">
        <CancelButton handleClick={handleClose} />
        <Form.Item style={{ margin: 0 }}>
          <Button
            htmlType="submit"
            type="primary"
            loading={loading}
            disabled={loading || !unsavedChanges}
          >
            Submit
          </Button>
        </Form.Item>
      </FormControls>
    </Form>
  );
};

export default ReScheduleScope;
