import React, { useState, useEffect } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useSelector } from "react-redux";
import api from "API";
import Input from "antd/es/input";
import Select from "antd/es/select";
import Form from "antd/es/form";
import message from "antd/es/message";
import Button from "antd/es/button";
import Modal from "antd/es/modal";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import InputPhone from "Components/Inputs/Phone";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import phoneMask from "Utils/phoneMask";
import useIsMobile from "Hooks/useIsMobile";
import CancelButton from "Components/CancelButton";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";

const messageKey = "messageKey";

const PhoneBook = ({
  unsavedChanges,
  setUnsavedChanges,
  handleClose,
  setTitle,
  type,
}) => {
  const formDetails = useSelector(
    (state) => state.formState.phoneBook.formDetails
  );
  const mobile = useIsMobile();
  const [form] = Form.useForm();
  const [_type, _setType] = useState(
    !formDetails.editing ? undefined : formDetails.editing.type
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!formDetails.editing) {
      setTitle("Create Contact");
    } else {
      setTitle(
        <span>
          Edit Contact{" "}
          <Button
            size="small"
            danger
            onClick={confirmDelete}
            style={{ float: "right", marginRight: 28 }}
          >
            Delete
          </Button>
        </span>
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const confirmDelete = () => {
    Modal.confirm({
      zIndex: 4002,
      icon: <QuestionCircleOutlined />,
      centered: true,
      cancelText: "Cancel",
      okText: "Delete",
      okButtonProps: { danger: true },
      content: <div>Are you sure you want to delete this contact?</div>,
      onOk() {
        return new Promise((resolve, reject) => {
          deleteContact(() => resolve(true));
        }).catch((err) => console.log("err: ", err));
      },
      onCancel() {},
    });
  };

  const deleteContact = async (cb) => {
    try {
      const _id = await form.getFieldValue("_id");
      const res = await api.delete(`/phone-book/delete/${_id}`);

      if (formDetails.setOriginPhonebook)
        formDetails.setOriginPhonebook(res.data);

      unstable_batchedUpdates(() => {
        setUnsavedChanges(false);
        setLoading(false);
      });

      cb();
      message.success("Contact Deleted");
      handleClose(true);
    } catch (err) {
      console.log("err", err);
      message.error("Error deleting contact");
    }
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      // message.loading("Saving Contact...", 0);
      message.loading({
        content: "Saving contact...",
        duration: 0,
        key: messageKey,
      });
      if (!formDetails.editing) {
        const res = await api.post("/phone-book/create", values);
        if (formDetails.setOriginPhonebook)
          formDetails.setOriginPhonebook(res.data);
        unstable_batchedUpdates(() => {
          setUnsavedChanges(false);
          setLoading(false);
        });
      } else {
        const res = await api.post("/phone-book/edit", values);
        if (formDetails.setOriginPhonebook)
          formDetails.setOriginPhonebook(res.data);
        unstable_batchedUpdates(() => {
          setUnsavedChanges(false);
          setLoading(false);
        });
      }
      // message.success("Contact saved");
      message.success({
        content: "Contact saved",
        duration: 1.5,
        key: messageKey,
      });
      handleClose(true);
    } catch (err) {
      // message.error("Error saving contact");
      message.error({
        content: "Error saving contact",
        duration: 1.5,
        key: messageKey,
      });
      console.log("err", err);
      setLoading(false);
    }
  };

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error saving contact");
    setLoading(false);
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  const onChange = (value) => {
    _setType(value);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      initialValues={{
        _id: !formDetails.editing ? undefined : formDetails.editing._id,
        type: !formDetails.editing ? undefined : formDetails.editing.type,
        name: !formDetails.editing ? undefined : formDetails.editing.name,
        content: !formDetails.editing
          ? undefined
          : formDetails.editing.type === "Links"
          ? formDetails.editing.content
          : phoneMask(formDetails.editing.content),
      }}
      className="form"
    >
      <RemoveAutocomplete />

      <FormBody
        className="content-inner"
        style={{
          paddingLeft: 24,
          paddingTop: 24,
          paddingRight: 24,
          paddingBottom: 8,
          maxHeight: type === "modal" ? "calc(100vh - 161px)" : "unset",
        }}
      >
        <Form.Item name="_id" hidden>
          <Input />
        </Form.Item>
        <Form.Item
          name="type"
          label="Type"
          rules={[
            {
              required: true,
              message: "Type is required",
            },
          ]}
        >
          <Select
            onChange={onChange}
            autoFocus
            showSearch={!mobile}
            dropdownClassName={mobile ? "isMobile" : null}
            getPopupContainer={(trigger) =>
              !mobile ? trigger.parentNode : document.body
            }
          >
            <Select.Option key="Contractors">Contractors</Select.Option>
            <Select.Option key="Links">Links</Select.Option>
            <Select.Option key="Manufacturers">Manufacturers</Select.Option>
            <Select.Option key="Partners">Partner</Select.Option>
            <Select.Option key="Premier">Premier</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="name"
          label={_type === "Links" ? "Title" : "Name"}
          rules={[
            {
              required: true,
              message: "Name is required",
              whitespace: true,
            },
          ]}
        >
          <InputNoAutoComplete id="name" />
        </Form.Item>

        <Form.Item
          name="content"
          label={_type === "Links" ? "URL" : "Phone"}
          rules={[
            {
              required: true,
              message: `${_type === "Links" ? "URL" : "Phone"} is required`,
              whitespace: true,
            },
          ]}
        >
          {_type !== "Links" ? (
            <InputPhone autoComplete="contact-addition-editing" id="content" />
          ) : (
            <InputNoAutoComplete type="url" id="content" />
          )}
        </Form.Item>
      </FormBody>
      <FormControls className="sticky-footer">
        <CancelButton handleClick={handleClose} />
        <Form.Item style={{ margin: 0 }}>
          <Button
            htmlType="submit"
            type="primary"
            loading={loading}
            disabled={loading || !unsavedChanges}
          >
            Submit
          </Button>
        </Form.Item>
      </FormControls>
    </Form>
  );
};

export default PhoneBook;
