import React from "react";
import Card from "antd/es/card";
import Statistic from "antd/es/statistic";
import styles from "./styles.module.scss";

const StatisticCard = ({ handleClick, title, value, selected }) => {
  return (
    <Card
      className={`z-depth-1 ${selected ? styles.selected : ""}`}
      style={{
        overflow: "hidden",
        // background: color,
        marginBottom: 16,
        position: "relative",
      }}
      bordered={false}
      hoverable={handleClick ? true : false}
      onClick={handleClick}
    >
      <Statistic
        className={styles.statisticTitle}
        title={title}
        value={value}
      />
    </Card>
  );
};

export default StatisticCard;
