import { useLocation, useNavigate } from "react-router-dom";

export default function useBack() {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const locArr = pathname.split("/");
  let locArrLength = locArr.length;
  let position = 1;
  if (
    pathname.includes("create") ||
    pathname.includes("add-comment") ||
    pathname.includes("media")
  ) {
    position = 1;
  } else if (
    pathname.includes("legacy-files") ||
    pathname.includes("verification")
  ) {
    position = 2;
  } else {
    if (pathname.includes("edit")) position = 2;
    if (pathname.includes("images")) {
      if (
        (pathname.includes("dashboard") ||
          pathname.includes("account-receivables") ||
          locArr[1] === "scopes" ||
          pathname.includes("workflow") ||
          pathname.includes("install") ||
          pathname.includes("schedule") ||
          pathname.includes("notifications") ||
          pathname.includes("lead-scheduling") ||
          pathname.includes("reports-dashboard-admin") ||
          pathname.includes("feed")) &&
        !pathname.includes("projects") &&
        !pathname.includes("leads")
      ) {
        position = 4;
      } else {
        position = 3;
      }
    } else if (pathname.includes("view")) {
      if (
        pathname.includes("dashboard") ||
        pathname.includes("lead-scheduling") ||
        pathname.includes("reports-dashboard-admin") ||
        pathname.includes("account-receivables") ||
        locArr[1] === "scopes" ||
        pathname.includes("notifications") ||
        pathname.includes("feed") ||
        pathname.includes("workflow") ||
        pathname.includes("install") ||
        pathname.includes("schedule")
      ) {
        position = 4;
      } else {
        position = 3;
      }
    }
  }
  let newPath = "";
  for (let i = 0; i < locArrLength - position; i++) {
    if (i !== locArrLength - position - 1) {
      newPath += locArr[i] + "/";
    } else {
      newPath += locArr[i];
    }
  }
  return () => navigate(newPath);
}
