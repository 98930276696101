import axios from "axios";

let baseURL;

if (process.env.NODE_ENV === "production") {
  baseURL = "https://api.gopremier.net";
} else {
  baseURL = "http://localhost:5001";
}

const instance = axios.create({
  baseURL,
});

instance.interceptors.request.use(
  async (config) => {
    const token = await localStorage.getItem("storyToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers.AccessControlAllowOrigin = "*";
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export default instance;
