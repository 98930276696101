import React, { memo } from "react";
import List from "antd/es/list";
import styles from "./styles.module.scss";
import CheckCircle from "Icons/CheckCircle";
import CheckCircleEmpty from "Icons/CheckCircleEmpty";
import Loading from "Icons/Loading";

const ProcessFeedbackItem = ({ title, activeIndex, index }) => {
  const status =
    index < activeIndex
      ? "complete"
      : index > activeIndex
      ? "incomplete"
      : title === "Done!"
      ? "complete"
      : "active";

  return (
    <List.Item className={styles[status]}>
      <List.Item.Meta
        avatar={
          status === "complete" ? (
            <CheckCircle color="#00c292" />
          ) : status === "incomplete" ? (
            <CheckCircleEmpty color="rgba(0, 0, 0, 0.25)" />
          ) : (
            <Loading color="#108ee9" />
          )
        }
        title={title}
      />
    </List.Item>
  );
};

export default memo(ProcessFeedbackItem);
