import React, { memo } from "react";
import Row from "antd/es/row";
import styles from "./styles.module.scss";
import Image1 from "./Image1";
import Image2to3 from "./Image2to3";
import Image2to4orMore from "./Image2to4orMore";

const ImageGrid = ({
  images,
  setImages,
  activity,
  scrollContainer,
  _global,
}) => {
  return (
    <div className={styles.gridContainer}>
      <div className={styles.grid}>
        <Row style={{ height: "100%" }}>
          <Image1
            images={images}
            setImages={setImages}
            activity={activity}
            scrollContainer={scrollContainer}
            _global={_global}
          />
          {images.length >= 2 && images.length < 4 && (
            <Image2to3
              images={images}
              setImages={setImages}
              activity={activity}
              scrollContainer={scrollContainer}
              _global={_global}
            />
          )}
          {images.length >= 4 && (
            <Image2to4orMore
              images={images}
              setImages={setImages}
              activity={activity}
              scrollContainer={scrollContainer}
              _global={_global}
            />
          )}
        </Row>
      </div>
    </div>
  );
};

export default memo(ImageGrid);
