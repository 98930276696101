import React, { useState, useEffect } from "react";
import List from "antd/es/list";
import message from "antd/es/message";
import Card from "antd/es/card";
import api from "API";
import currencyFormatter from "Utils/currencyFormatter";
import styles from "./styles.module.scss";

const headers = ["Salesman", "Total"];

export default function DrawBalances() {
  const [state, setState] = useState({
    totalValue: 0,
    table: [],
  });

  useEffect(() => {
    getReport();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getReport = async () => {
    try {
      const res = await api.post("/reports/adminDrawsCumulative", {});

      const table = [];
      for (const x of res.data.series) {
        let obj = {};
        obj.name = x.name;
        if (x.total) {
          obj.total = x.total;
        } else {
          obj.total = x.data[x.data.length - 1];
        }

        table.push(obj);
      }

      setState((thisState) => {
        return {
          ...thisState,
          totalValue: res.data.totalValue,
          table,
        };
      });
    } catch (err) {
      console.log("err", err);
      message.error("Error getting report");
    }
  };

  return (
    <div style={{ paddingBottom: 24 }} className="content-inner">
      <Card
        bordered={false}
        className="z-depth-1"
        style={{
          marginTop: 24,
          marginLeft: 24,
          marginRight: 24,
        }}
        bodyStyle={{
          padding: 0,
        }}
      >
        <List>
          <List.Item className={styles.listHeader}>
            {headers.map((header, i) => {
              if (i !== 0) {
                return (
                  <List.Item.Meta
                    key={header}
                    className={styles.listRight}
                    title={header}
                  />
                );
              } else {
                return <List.Item.Meta key={header} title={header} />;
              }
            })}
          </List.Item>

          {state.table.map((t, i) => {
            return (
              <List.Item key={t.name + i} className={styles.listItem}>
                <List.Item.Meta title={t.name} />
                <List.Item.Meta
                  title={currencyFormatter(t.total)}
                  className={styles.listRight}
                />
              </List.Item>
            );
          })}

          <List.Item className={styles.listTotal}>
            <List.Item.Meta title="Total" />
            <List.Item.Meta
              className={styles.listRight}
              title={currencyFormatter(state.totalValue)}
            />
          </List.Item>
        </List>
      </Card>
    </div>
  );
}
