import React, { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Grid from "antd/es/grid";
import {
  fetchTableFilters,
  unsetTable,
  setScrollbar,
} from "Actions/tableActions";
import Table from "Components/Table";
import TableRow from "Components/Table/TableItems/BreakdownItem";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as Vlist } from "react-window";
import usePrevious from "Hooks/usePrevious";
import useIsMobile from "Hooks/useIsMobile";
import isEmpty from "Utils/isEmpty";

import Modal from "antd/es/modal";
import message from "antd/es/message";
import api from "API";
import List from "antd/es/list";
import dayjs from "dayjs";
import currencyFormatter from "Utils/currencyFormatter";
import percentFormatter from "Utils/percentFormatter";
import Divider from "antd/es/divider";

const { useBreakpoint } = Grid;

const headers = [
  {
    _name: "customerId",
    name: "ID",
    width: "10%",
  },
  {
    _name: "salesman",
    name: "SALESMAN",
    width: "20%",
    type: "join",
  },
  {
    _name: "collectionsTotal",
    name: "COLLECTIONS",
    width: "20%",
    type: "currency",
  },
  {
    _name: "expensesTotal",
    name: "EXPENSES",
    width: "20%",
    type: "currency",
  },
  {
    _name: "balance",
    name: "BALANCE",
    width: "15%",
    type: "currency",
  },
  {
    _name: "submitted",
    name: "SUBMITTED",
    width: "15%",
    align: "right",
    type: "date",
  },
];

const filters = [
  {
    title: "Salesman",
    type: "salesmen",
    fields: [
      {
        name: "salesman",
        placeholder: "Select an Option(s)",
        values: [],
      },
    ],
  },
];

const Breakdowns = () => {
  const dispatch = useDispatch();
  const screens = useBreakpoint();
  const mobile = useIsMobile();
  const prevScreens = usePrevious(screens);
  const location = useLocation();
  const rows = useSelector((state) => state.tableState.rows);
  const fetching = useSelector((state) => state.tableState.fetching);
  const search = useSelector((state) => state.tableState.search);
  const activeFilter = useSelector((state) => state.tableState.activeFilter);
  const collapsed = useSelector((state) => state.settingsState.collapsed);
  const selectedRow = useSelector((state) => state.dataState.selectedRow);
  const [itemSize, setItemSize] = useState(100);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    initTable();

    return () => dispatch(unsetTable());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTimeout(() => {
      dispatch(setScrollbar());
    });
  }, [rows, dispatch]);

  const initTable = useCallback(() => {
    dispatch(
      fetchTableFilters({
        table: "breakdowns",
        col: "submitted",
        order: 1,
        search: location.search ? location.search.substring(1) : search,
      })
    );
  }, [dispatch, location, search]);

  useEffect(() => {
    if (prevScreens && screens) {
      if (prevScreens.md && !screens.md) {
        setItemSize(100);
      } else if (!prevScreens.md && screens.md) {
        setItemSize(47);
      }
    }
  }, [screens, prevScreens]);

  const Row = ({ index, style }) => {
    const item = rows[index];
    if (!mobile)
      return (
        <TableRow
          style={style}
          key={item._id}
          {...item}
          headers={headers}
          setOpen={setOpen}
        />
      );
    return (
      <TableRow
        style={style}
        key={item._id}
        {...item}
        setOpen={setOpen}
        mobile
      />
    );
  };

  return (
    <>
      <Table
        loading={fetching}
        filter={
          search ||
          (activeFilter && activeFilter.filter && !isEmpty(activeFilter.filter))
            ? true
            : false
        }
        mobile={mobile}
        sider={screens.lg}
        collapsed={collapsed}
      >
        <Table.Header filters={filters} />
        <Table.Filter
          hide={
            search ||
            (activeFilter &&
              activeFilter.filter &&
              !isEmpty(activeFilter.filter))
              ? false
              : true
          }
        />

        {!mobile && <Table.ColumnHeaders headers={headers} />}
        <Table.Body loading={fetching}>
          {rows.length > 0 && (
            <AutoSizer>
              {({ height, width }) => (
                <Vlist
                  height={height}
                  itemCount={rows.length}
                  itemSize={itemSize}
                  width={width}
                >
                  {Row}
                </Vlist>
              )}
            </AutoSizer>
          )}
        </Table.Body>
      </Table>

      <Breakdown open={open} setOpen={setOpen} selected={selectedRow} />
    </>
  );
};

const Breakdown = ({ selected, open, setOpen }) => {
  const admin = useSelector((state) => state.authState.admin);
  const email = useSelector((state) => state.authState.email);
  const [breakdown, setBreakdown] = useState({});
  const prevOpen = usePrevious(open);

  useEffect(() => {
    if (!prevOpen && open) {
      fetchBreakdown();
    }
  }, [open, prevOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchBreakdown = async () => {
    try {
      const _breakdown = await api.get(`/breakdowns/get/${selected}`);
      setBreakdown(_breakdown.data);
    } catch (err) {
      console.log("err", err);
      message.error("Error getting breakdown");
    }
  };

  const onCancel = () => {
    setOpen(false);
  };

  return (
    <Modal visible={open} onCancel={onCancel} footer={null}>
      <Divider>Project Details</Divider>
      <List bordered={false} split={false}>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Submitted" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={dayjs(breakdown.submitted).format("MMMM D, YYYY")}
          />
        </List.Item>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Customer ID/PO#" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={breakdown.customerId}
          />
        </List.Item>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Salesman" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={
              breakdown.salesman ? breakdown.salesman.join(", ") : ""
            }
          />
        </List.Item>
      </List>

      <Divider>Collections</Divider>
      <List bordered={false} split={false}>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Total" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={currencyFormatter(breakdown.collectionsTotal)}
          />
        </List.Item>
      </List>

      <Divider>Expenses</Divider>
      <List bordered={false} split={false}>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Materials" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={currencyFormatter(breakdown.materials)}
          />
        </List.Item>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Overhead" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={currencyFormatter(breakdown.overhead)}
          />
        </List.Item>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Labor" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={currencyFormatter(breakdown.labor)}
          />
        </List.Item>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Dumping" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={currencyFormatter(breakdown.dumping)}
          />
        </List.Item>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Extra" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={currencyFormatter(breakdown.extra)}
          />
        </List.Item>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Permit" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={currencyFormatter(breakdown.permit)}
          />
        </List.Item>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Stock" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={currencyFormatter(breakdown.stock)}
          />
        </List.Item>

        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Gutters" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={currencyFormatter(breakdown.gutters)}
          />
        </List.Item>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Credit Card Fees" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={currencyFormatter(breakdown.creditCards)}
          />
        </List.Item>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Financing Fees" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={currencyFormatter(breakdown.financing)}
          />
        </List.Item>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Warehouse" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={currencyFormatter(breakdown.warhouse)}
          />
        </List.Item>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Warranty Fees" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={currencyFormatter(breakdown.warranty)}
          />
        </List.Item>

        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Total" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={currencyFormatter(breakdown.expensesTotal)}
          />
        </List.Item>
      </List>

      <Divider>Balance</Divider>
      <List bordered={false} split={false}>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Total" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={currencyFormatter(breakdown.balance)}
          />
        </List.Item>
      </List>

      {admin === "Yes" && (
        <>
          <Divider>Office Fee</Divider>
          <List bordered={false} split={false}>
            <List.Item style={{ padding: 0 }}>
              <List.Item.Meta title="Origin" />
              <List.Item.Meta
                style={{ textAlign: "right" }}
                description={breakdown.origin}
              />
            </List.Item>
            <List.Item style={{ padding: 0 }}>
              <List.Item.Meta title="Total" />
              <List.Item.Meta
                style={{ textAlign: "right" }}
                description={currencyFormatter(breakdown.officeFee)}
              />
            </List.Item>
          </List>

          {email !== "mkulhanek@gopremier.net" && (
            <>
              <Divider>Commissions</Divider>
              <List bordered={false} split={false}>
                <List.Item style={{ padding: 0 }}>
                  <List.Item.Meta title="Manager Commission" />
                  <List.Item.Meta
                    style={{ textAlign: "right" }}
                    description={currencyFormatter(breakdown.managerCommission)}
                  />
                </List.Item>
                <List.Item style={{ padding: 0 }}>
                  <List.Item.Meta title="Salesman Commissions" />
                  <List.Item.Meta
                    style={{ textAlign: "right" }}
                    description={currencyFormatter(breakdown.commissionTotal)}
                  />
                </List.Item>
              </List>

              <Divider>Company Profit</Divider>
              <List bordered={false} split={false}>
                <List.Item style={{ padding: 0 }}>
                  <List.Item.Meta title="Total" />
                  <List.Item.Meta
                    style={{ textAlign: "right" }}
                    description={currencyFormatter(breakdown.companyProfit)}
                  />
                </List.Item>
              </List>
            </>
          )}

          <Divider>Block Bonus</Divider>
          <List bordered={false} split={false}>
            <List.Item style={{ padding: 0 }}>
              <List.Item.Meta title="Monthly" />
              <List.Item.Meta
                style={{ textAlign: "right" }}
                description={currencyFormatter(breakdown.blockBonusMonthly)}
              />
            </List.Item>
            <List.Item style={{ padding: 0 }}>
              <List.Item.Meta title="Yearly" />
              <List.Item.Meta
                style={{ textAlign: "right" }}
                description={currencyFormatter(breakdown.blockBonusYearly)}
              />
            </List.Item>
          </List>
        </>
      )}

      <Divider>Percentage Breakdown</Divider>
      <List bordered={false} split={false}>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Material" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={percentFormatter(breakdown.materialPercent)}
          />
        </List.Item>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Overhead" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={percentFormatter(breakdown.overheadPercent)}
          />
        </List.Item>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Labor" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={percentFormatter(breakdown.laborPercent)}
          />
        </List.Item>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Dumping" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={percentFormatter(breakdown.dumpingPercent)}
          />
        </List.Item>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Extra" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={percentFormatter(breakdown.extraPercent)}
          />
        </List.Item>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Permit" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={percentFormatter(breakdown.permitPercent)}
          />
        </List.Item>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Stock" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={percentFormatter(breakdown.stockPercent)}
          />
        </List.Item>

        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Gutters" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={percentFormatter(breakdown.guttersPercent)}
          />
        </List.Item>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Credit Card Fees" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={percentFormatter(breakdown.creditCardPercent)}
          />
        </List.Item>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Financing Fees" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={percentFormatter(breakdown.financingPercent)}
          />
        </List.Item>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Warehouse" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={percentFormatter(breakdown.warehousePercent)}
          />
        </List.Item>
        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Warranty Fees" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={percentFormatter(breakdown.warrantyPercent)}
          />
        </List.Item>

        <List.Item style={{ padding: 0 }}>
          <List.Item.Meta title="Balance" />
          <List.Item.Meta
            style={{ textAlign: "right" }}
            description={percentFormatter(breakdown.profitPercent)}
          />
        </List.Item>
      </List>
    </Modal>
  );
};

export default Breakdowns;
