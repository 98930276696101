import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSelected } from "Actions/dataActions";
import { closeForm } from "Actions/formActions";
import Drawer from "antd/es/drawer";
import Modal from "antd/es/modal";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";

const FormContainer = ({
  props,
  formType,
  title,
  form,
  visible,
  closable = true,
  mask = true,
  width = 340,
  editing = undefined,
  setEditing = () => {
    return false;
  },
  placement = "bottom",
  type = "drawer",
  zIndex = 4000,
  centered = false,
  modalStyle = {
    main: { top: 50, paddingBottom: 0, maxWidth: "calc(100vw - 100px)" },
    body: {
      padding: 0,
      height: "calc(100vh - 100px - 55px)",
      maxHeight: "calc(100vh - 100px - 55px)",
      maxWidth: "calc(100vw - 100px)",
    },
    height: "calc(100vh - 100px)",
    width: "calc(100vw - 100px)",
    maxHeight: "calc(100vh - 100px)",
  },
}) => {
  const openForms = useSelector((state) => state.formState.openForms);
  const dispatch = useDispatch();
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [_title, setTitle] = useState(title);
  const Form = form;

  const handleClose = useCallback(
    (pass) => {
      if (unsavedChanges && pass !== true) {
        Modal.confirm({
          zIndex: 4999,
          icon: <QuestionCircleOutlined />,
          centered: true,
          cancelText: "Stay",
          okText: "Close",
          okButtonProps: { danger: true },
          content: (
            <div>Are you sure? There are unsaved changes you will lose.</div>
          ),
          onOk() {
            return new Promise((resolve, reject) => {
              if (openForms < 2) dispatch(setSelected(null));
              dispatch(closeForm(formType, openForms));
              setUnsavedChanges(false);
              setEditing(undefined);
              resolve(true);
            }).catch((err) => console.log("err: ", err));
          },
          onCancel() {},
        });
      } else {
        if (openForms < 2) dispatch(setSelected(null));
        dispatch(closeForm(formType, openForms));
        setEditing(undefined);
      }
    },
    [dispatch, formType, openForms, setEditing, unsavedChanges]
  );

  const cancel = () => {
    handleClose();
  };

  return type === "drawer" ? (
    <Drawer
      placement={placement}
      title={_title}
      destroyOnClose
      visible={visible}
      onClose={cancel}
      height="100%"
      width={width}
      bodyStyle={{ padding: 0, height: "100%" }}
      closable={closable}
      mask={mask}
      zIndex={zIndex}
      // headerStyle={{
      //   whiteSpace: "nowrap",
      //   overflow: "hidden",
      //   textOverflow: "ellipsis",
      // }}
      className="form-drawer-container"
    >
      <Form
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
        editing={editing}
        handleClose={handleClose}
        setTitle={setTitle}
        type={type}
        props={props}
      />
    </Drawer>
  ) : (
    <Modal
      title={_title}
      destroyOnClose
      visible={visible}
      onCancel={cancel}
      footer={null}
      centered={centered}
      bodyStyle={modalStyle.body}
      width={modalStyle.width}
      height={modalStyle.height}
      style={modalStyle.main}
      zIndex={zIndex}
      id="#parent-modal"
    >
      <Form
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
        editing={editing}
        handleClose={handleClose}
        setTitle={setTitle}
        props={props}
        type={type}
      />
    </Modal>
  );
};

export default FormContainer;
