import React, { memo } from "react";
import styles from "./styles.module.scss";
import Card from "antd/es/card";
import Statistic from "antd/es/statistic";
import currencyFormatter from "Utils/currencyFormatter";
import percentFormatter from "Utils/percentFormatter";
import Tag from "antd/es/tag";
import Skeleton from "antd/es/skeleton";

const StatisticCard = ({
  title,
  value,
  decimalPlaces = 0,
  goal,
  lastYear,
  loading,
  percent,
}) => {
  return (
    <Card
      bodyStyle={{ padding: 12, height: 104.28 }}
      className="z-depth-1"
      style={{ overflow: "hidden" }}
      bordered={false}
    >
      <Statistic
        loading={loading}
        title={
          <>
            <div
              className={`${styles.title} ${
                loading ? "" : goal === 0 || goal ? "" : styles.trendNotIncluded
              }`}
            >
              {title}
            </div>
            {loading ? (
              <div className={styles.trend}>
                <Skeleton active paragraph={{ rows: 0 }} />
              </div>
            ) : goal === 0 || goal ? (
              <div className={styles.trend}>
                <Tag
                  className={styles.percentTag}
                  color={goal < 100 ? "#ff7056" : "#00c292"}
                >
                  {percentFormatter(goal)}
                </Tag>
              </div>
            ) : null}
          </>
        }
        value={
          percent
            ? percentFormatter(value)
            : decimalPlaces === 0
            ? value
            : currencyFormatter(value)
        }
        precision={decimalPlaces}
        className={styles.stat}
      />
      {loading ? (
        <div className={styles.lastYear}>
          <Skeleton active paragraph={{ rows: 0 }} />
        </div>
      ) : lastYear === 0 || lastYear ? (
        <div className="ant-statistic-title">
          Last Year:{" "}
          {lastYear && decimalPlaces === 0
            ? lastYear
            : currencyFormatter(lastYear)}
        </div>
      ) : (
        <div className="ant-statistic-title" style={{ color: "#ffffff" }}>
          Last Year:
        </div>
      )}
    </Card>
  );
};

export default memo(StatisticCard);
