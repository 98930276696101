import React, { useCallback, memo } from "react";
import { useDispatch } from "react-redux";
import { openForm } from "Actions/formActions";
import { updateSelectedActivity, openImageViewer } from "Actions/dataActions";
import { useNavigate, useLocation } from "react-router-dom";
import Row from "antd/es/row";
import Col from "antd/es/col";
import isPdf from "Utils/isPdf";
import isVideo from "Utils/isVideo";
import hidePdfControls from "Utils/hidePdfControls";
import styles from "./styles.module.scss";
import pdf from "Assets/pdf.svg";
import YouTube from "Icons/YouTube";
import LazyLoad from "Components/LazyLoad";
import ImageFallback from "Components/images/Image";

const Image2to3 = ({
  images,
  setImages,
  activity,
  _global,
  scrollContainer,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  let src1 = images[1].src;
  let src2 = undefined;
  if (images.length > 2) {
    src2 = images[2].src;
  }

  const openImage1 = useCallback(() => {
    images[1].setImages = setImages;
    dispatch(openImageViewer(images[1]));
  }, [setImages, dispatch, images]);

  const openDrawer1 = useCallback(() => {
    if (!images[1].legacy) {
      dispatch(updateSelectedActivity(activity));
      if (
        !location.pathname.includes("projects") &&
        !location.pathname.includes("leads")
      ) {
        dispatch(openForm("images", { index: 1 }));
      } else {
        navigate(`${location.pathname}/images/${activity._id}/${1}`);
      }
    } else {
      navigate(`${location.pathname}/legacy-files/${1}`);
    }
  }, [images, dispatch, activity, location, navigate]);

  const openImage2 = useCallback(() => {
    images[2].setImages = setImages;
    dispatch(openImageViewer(images[2]));
  }, [setImages, dispatch, images]);

  const openDrawer2 = useCallback(() => {
    if (!images[2].legacy) {
      dispatch(updateSelectedActivity(activity));
      if (
        !location.pathname.includes("projects") &&
        !location.pathname.includes("leads")
      ) {
        dispatch(openForm("images", { index: 2 }));
      } else {
        navigate(`${location.pathname}/images/${activity._id}/${2}`);
      }
    } else {
      navigate(`${location.pathname}/legacy-files/${2}`);
    }
  }, [images, dispatch, activity, location, navigate]);

  return (
    <Col xs={12} style={{ height: "100%" }}>
      <Row style={{ height: "100%" }}>
        <Col
          xs={24}
          className={`${styles.border} ${_global ? styles.dark : ""} ${
            images.length === 3 ? styles.height50 : styles.height100
          } ${styles.background}`}
        >
          <div
            className={styles.clickOverlay}
            onClick={setImages ? openImage1 : openDrawer1}
          />
          {isPdf(src1) ? (
            <LazyLoad
              offsetVertical={600}
              height={"100%"}
              root={scrollContainer}
            >
              <object data={hidePdfControls(src1)} className={styles.pdf}>
                <div>
                  <img src={pdf} alt="pdf-icon" />
                  <div>{images[1].name}</div>
                </div>
              </object>
            </LazyLoad>
          ) : isVideo(src1) ? (
            <LazyLoad
              offsetVertical={300}
              height={"100%"}
              root={scrollContainer}
            >
              <div className={styles.video}>
                <YouTube color="#f1f1f1" size={64} />
                <div>{images[1].name}</div>
              </div>
            </LazyLoad>
          ) : (
            <LazyLoad
              offsetVertical={300}
              height={"100%"}
              root={scrollContainer}
            >
              <ImageFallback image={images[1]} alt="activity-drawer" />
            </LazyLoad>
          )}
        </Col>
        {images.length > 2 && (
          <Col
            xs={24}
            className={`${styles.border} ${_global ? styles.dark : ""} ${
              images.length === 3 ? styles.height50 : styles.height100
            } ${styles.background}`}
          >
            <div
              className={styles.clickOverlay}
              onClick={setImages ? openImage2 : openDrawer2}
            />
            {isPdf(src2) ? (
              <LazyLoad
                offsetVertical={600}
                height={"100%"}
                root={scrollContainer}
              >
                <object data={hidePdfControls(src2)} className={styles.pdf}>
                  <div>
                    <img src={pdf} alt="pdf-icon" />
                    <div>{images[2].name}</div>
                  </div>
                </object>
              </LazyLoad>
            ) : isVideo(src2) ? (
              <LazyLoad
                offsetVertical={300}
                height={"100%"}
                root={scrollContainer}
              >
                <div className={styles.video}>
                  <YouTube color="#f1f1f1" size={64} />
                  <div>{images[2].name}</div>
                </div>
              </LazyLoad>
            ) : (
              <LazyLoad
                offsetVertical={300}
                height={"100%"}
                root={scrollContainer}
              >
                <ImageFallback image={images[2]} alt="activity-drawer" />
              </LazyLoad>
            )}
          </Col>
        )}
      </Row>
    </Col>
  );
};

export default memo(Image2to3);
