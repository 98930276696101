import React from "react";
import BarChartWithDates from "Reports/BarChartWithDates";

export default function ProfitPercentageByOrigin() {
  return (
    <BarChartWithDates
      path="/reports/profitPercentageByOrigin"
      summary={false}
      type="percent"
      report="PROFIT PERCENTAGE BY ORIGIN"
      headers={["Origin", "Sales", "Profit %"]}
      headerType={["string", "currency", "percent"]}
    />
  );
}
