import dayjs from "dayjs";
import HighCharts from "highcharts";
import HighChartsReact from "highcharts-react-official";

import React, { useState, useEffect } from "react";
import Row from "antd/es/row";
import Col from "antd/es/col";
import message from "antd/es/message";
import DatePicker from "Components/Inputs/DatePicker";
import List from "antd/es/list";
import Card from "antd/es/card";
import api from "API";
import percentFormatter from "Utils/percentFormatter";
import currencyFormatter from "Utils/currencyFormatter";
import addCommas from "Utils/addCommas";
import styles from "./styles.module.scss";

window.Highcharts = HighCharts;

const { RangePicker } = DatePicker;

const end = dayjs();
const year = new Date().getFullYear();
let start = new Date(`1/1/${year}`);
start = dayjs(start);

HighCharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
  colors: [
    "#00c292",
    "#fec107",
    "#FF7056",
    "#343a40",
    "#01c0c8",
    "#999999",
    "#FF9655",
    "#FFF263",
    "#6AF9C4",
  ],
});

const BarChartWithDates = ({ path, report, type, summary, headers }) => {
  const [range, setRange] = useState({
    start,
    end,
  });
  const [state, setState] = useState({
    totalValue: 0,
    type: "",
    table: [],
    options: {
      chart: {
        type: "column",
      },
      credits: {
        enabled: false,
      },
      title: {
        text: "",
      },
      subtitle: {
        text: "",
      },
      xAxis: {
        type: "category",
        labels: {
          rotation: -45,
          style: {
            fontSize: "13px",
            fontFamily: "Verdana, sans-serif",
          },
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: "",
        },
        labels: {
          formatter: function () {
            let label = this.value;
            if (this.value >= 1000000) {
              label = this.value / 1000000 + "M";
            } else if (this.value >= 1000) {
              label = this.value / 1000 + "k";
            }
            return `${label}`;
          },
        },
      },
      legend: {
        enabled: undefined,
      },
      tooltip: {
        useHTML: true,
        formatter: function () {
          let table = "<table><tr><td><b>" + this.key + "</b></tr><tr><td>";
          if (type === "currency") {
            table += "$";
          }
          table += HighCharts.numberFormat(this.y, 2);
          if (type === "percent") {
            table += "%";
          }
          table += "</td></tr></table>";
          return table;
        },
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            formatter: function () {
              if (type === "currency") {
                return "$" + HighCharts.numberFormat(this.y, 2);
              } else if (type === "percent") {
                return HighCharts.numberFormat(this.y, 2) + "%";
              } else {
                return HighCharts.numberFormat(this.y, 2);
              }
            },
          },
        },
      },
      series: [],
    },
  });

  useEffect(() => {
    getReport();
  }, [range]); // eslint-disable-line react-hooks/exhaustive-deps

  const getReport = async () => {
    try {
      const res = await api.post(path, { start: range.start, end: range.end });

      const table = [];
      const dataLoop = res.data.series[0].data.map((x, i) => {
        let obj = {};
        obj.name = x.name;
        obj.total = x.y;
        obj.type = x.type;
        if (res.data.col1) {
          obj.col1 = {
            y: res.data.col1[i].y,
            type: res.data.col1[i].type,
            total: res.data.col1[i].total,
          };
        }
        if (res.data.col2) {
          obj.col2 = {
            y: res.data.col2[i].y,
            type: res.data.col1[i].type,
            total: res.data.col2[i].total,
          };
        }
        if (i === 0) {
          obj.totals = x.total;
        }
        table.push(obj);
        return x;
      });

      await Promise.all(dataLoop);

      setState((thisState) => {
        return {
          ...thisState,
          table,
          type: res.data.type,
          totalValue: res.data.totalValue,
          options: {
            ...thisState.options,
            series: res.data.series,
            legend: {
              ...thisState.options.legend,
              enabled: res.data.legend,
            },
          },
        };
      });
    } catch (err) {
      console.log("err", err);
      message.error("Error getting report");
    }
  };

  const handleDateChange = (e) => {
    setRange({ start: e[0], end: e[1] });
  };

  return (
    <div style={{ paddingBottom: 24 }} className="content-inner">
      <Card bordered={false} className="z-depth-1" style={{ margin: 24 }}>
        <Row gutter={16} style={{ marginBottom: 24 }}>
          <Col xs={24}>
            <div className={styles.reportTitle}>{report}</div>
          </Col>
        </Row>

        {summary && (
          <Row gutter={16} style={{ marginBottom: 24 }}>
            <Col xs={24}>
              <div className={styles.reportHeader}>
                <div className={styles.total}>
                  {state.type === "currency"
                    ? currencyFormatter(state.totalValue)
                    : state.type === "percent"
                    ? percentFormatter(state.totalValue)
                    : addCommas(state.totalValue.toString())}
                  <small>
                    {" "}
                    {state.type === "currency"
                      ? "TOTAL VALUE"
                      : state.type === "percent"
                      ? "AVERAGE"
                      : "TOTAL COUNT"}
                  </small>
                </div>
              </div>
            </Col>
          </Row>
        )}

        <Row gutter={16} style={{ marginBottom: 24 }}>
          <Col xs={24}>
            <div className={styles.reportHeader}>
              <div>
                <div className="ant-form-item-label">
                  <label title="Date Range">Date Range</label>
                </div>
                <RangePicker
                  allowClear={false}
                  format="MMMM DD, YYYY"
                  defaultValue={[
                    dayjs(range.start, "MMMM DD, YYYY"),
                    dayjs(range.end, "MMMM DD, YYYY"),
                  ]}
                  onChange={handleDateChange}
                  ranges={{
                    "Last 7 Days": [
                      dayjs().subtract(7, "days").startOf("day"),
                      dayjs(),
                    ],
                    "Last Week": [
                      dayjs().startOf("week").subtract(7, "days"),
                      dayjs().startOf("week").subtract(1, "day").endOf("day"),
                    ],
                    "Last Month": [
                      dayjs().startOf("month").subtract(1, "months"),
                      dayjs()
                        .startOf("month")
                        .subtract(1, "months")
                        .endOf("month"),
                    ],
                    "This Month": [
                      dayjs().startOf("month"),
                      dayjs().endOf("month"),
                    ],
                    "This Year": [dayjs().startOf("year"), dayjs()],
                    "Last Year": [
                      dayjs().subtract(1, "year").startOf("year"),
                      dayjs().subtract(1, "year").endOf("year"),
                    ],
                    TMLY: [
                      dayjs().subtract(1, "year").startOf("month"),
                      dayjs().subtract(1, "year").endOf("month"),
                    ],
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
        <HighChartsReact
          highcharts={HighCharts}
          options={state.options}
          updateArgs={[true, true, true]}
          allowChartUpdate={true}
        />
      </Card>

      <Card
        bordered={false}
        className="z-depth-1"
        style={{
          marginTop: 24,
          marginLeft: 24,
          marginRight: 24,
        }}
        bodyStyle={{
          padding: 0,
        }}
      >
        <List>
          <List.Item className={styles.listHeader}>
            {headers.map((header, i) => {
              if (i !== 0) {
                return (
                  <List.Item.Meta
                    key={header}
                    className={styles.listRight}
                    title={header}
                  />
                );
              } else {
                return <List.Item.Meta key={header} title={header} />;
              }
            })}
          </List.Item>

          {state.table.map((t, i) => {
            return (
              <List.Item key={t.name + i} className={styles.listItem}>
                <List.Item.Meta title={t.name} />
                {t.col1 && (
                  <List.Item.Meta
                    title={
                      t.col1.type === "currency"
                        ? currencyFormatter(t.col1.y)
                        : t.col1.type === "percent"
                        ? percentFormatter(t.col1.y)
                        : addCommas(t.col1.y.toString())
                    }
                    className={styles.listRight}
                  />
                )}

                {t.col2 && (
                  <List.Item.Meta
                    title={
                      t.col2.type === "currency"
                        ? currencyFormatter(t.col2.y)
                        : t.col2.type === "percent"
                        ? percentFormatter(t.col2.y)
                        : addCommas(t.col2.y.toString())
                    }
                    className={styles.listRight}
                  />
                )}

                <List.Item.Meta
                  title={
                    t.type === "currency"
                      ? currencyFormatter(t.total)
                      : t.type === "percent"
                      ? percentFormatter(t.total)
                      : addCommas(t.total.toString())
                  }
                  className={styles.listRight}
                />
              </List.Item>
            );
          })}

          {summary && (
            <List.Item className={styles.listTotal}>
              <List.Item.Meta title="Total" />
              {state.table.length > 0
                ? state.table[0].col1 && (
                    <List.Item.Meta
                      className={styles.listRight}
                      title={
                        state.table[0].col1.type === "currency"
                          ? currencyFormatter(state.table[0].col1.total)
                          : state.table[0].col1.type === "percent"
                          ? percentFormatter(state.table[0].col1.total)
                          : addCommas(state.table[0].col1.total.toString())
                      }
                    />
                  )
                : null}

              {state.table.length > 0
                ? state.table[0].col2 && (
                    <List.Item.Meta
                      className={styles.listRight}
                      title={
                        state.table[0].col2.type === "currency"
                          ? currencyFormatter(state.table[0].col2.total)
                          : state.table[0].col1.type === "percent"
                          ? percentFormatter(state.table[0].col2.total)
                          : addCommas(state.table[0].col2.total.toString())
                      }
                    />
                  )
                : null}

              {state.table.length > 0 ? (
                <List.Item.Meta
                  className={styles.listRight}
                  title={
                    state.table[0].type === "currency"
                      ? currencyFormatter(state.table[0].totals)
                      : state.table[0].type === "percent"
                      ? percentFormatter(state.table[0].totals)
                      : addCommas(state.table[0].totals.toString())
                  }
                />
              ) : null}
            </List.Item>
          )}
        </List>
      </Card>
    </div>
  );
};

export default BarChartWithDates;
