import React, { useState, useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { passwordReset } from "Actions/authActions";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Button from "antd/es/button";
import Typography from "antd/es/typography";
import Card from "antd/es/card";
import message from "antd/es/message";
import Logo from "Assets/logo-200x96.png";

const { Title } = Typography;

const PasswordReset = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const sentTimestamp = Buffer.from(params._randoString, "base64").toString(
    "binary"
  );
  let timestamp = new Date().getTime();
  const diff = timestamp - parseInt(sentTimestamp);
  if (Math.floor(diff / 60000) > 10) {
    navigate("/");
  }

  useEffect(() => {
    const sentTimestamp = Buffer.from(params._randoString, "base64").toString(
      "binary"
    );
    let timestamp = new Date().getTime();
    const diff = timestamp - parseInt(sentTimestamp);
    if (Math.floor(diff / 60000) > 10) {
      navigate("/");
    }
  }, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  const onFinish = useCallback(
    (values) => {
      values._id = params._userId;
      values.cb = () => navigate("/dashboard");
      values.failCb = () => message.error("Error resetting password");
      dispatch(passwordReset(values));
    },
    [dispatch, params._userId, navigate]
  );

  function onFinishFailed(errorInfo) {
    setLoading(false);
    message.error("All fields must be completed");
  }

  return (
    <Row
      type="flex"
      justify="center"
      align="middle"
      style={{
        height: "100%",
        position: "absolute",
        width: "100%",
      }}
      className="public-background"
    >
      <Col xs={22} sm={20} md={16} lg={14} xl={12} className="text-center">
        <Row type="flex" justify="center">
          <Col xs={24}>
            <img
              src={Logo}
              alt="story-logo"
              style={{
                display: "block",
                margin: "auto",
                marginBottom: 24,
                width: 200,
              }}
            />
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col style={{ maxWidth: 353 }}>
            <Card style={{ minWidth: 300 }}>
              <Row type="flex" justify="center">
                <Col xs={24}>
                  <Title
                    level={3}
                    style={{ textAlign: "center", color: "#1890ff" }}
                  >
                    Password Reset
                  </Title>
                </Col>
              </Row>
              <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                hideRequiredMark={true}
                colon={false}
                layout="vertical"
              >
                <Row type="flex" justify="center">
                  <Col xs={24}>
                    <Form.Item
                      label="New Password"
                      name="new_password"
                      rules={[
                        {
                          required: true,
                          message: "Must be at least 8 characters long",
                          min: 8,
                        },
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                  </Col>
                </Row>
                <Row type="flex" justify="center">
                  <Col xs={24}>
                    <Form.Item
                      label="Confirm Password"
                      name="confirm_password"
                      dependencies={["new_password"]}
                      rules={[
                        {
                          required: true,
                          message: "Please input your confirm Password!",
                        },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (
                              !value ||
                              getFieldValue("new_password") === value
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              "The two passwords that you entered do not match!"
                            );
                          },
                        }),
                      ]}
                      hasFeedback
                    >
                      <Input.Password />
                    </Form.Item>
                  </Col>
                </Row>
                <Row type="flex" justify="center">
                  <Col xs={24}>
                    <Button
                      style={{ marginTop: 16 }}
                      type="primary"
                      htmlType="submit"
                      block
                      loading={loading}
                      disabled={loading}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default PasswordReset;
