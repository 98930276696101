import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openForm } from "Actions/formActions";
import { unstable_batchedUpdates } from "react-dom";
import MarkerClusterer from "@googlemaps/markerclustererplus";
import message from "antd/es/message";
import api from "API";
import CrossHairs from "Icons/CrossHairs";
import FilterIcon from "Icons/Filter";
import ShareIcon from "Icons/Share";
// import CloseIcon from "Icons/Close";
import Button from "antd/es/button";
import convertOffset from "Utils/convertOffset";
import styles from "./styles.module.scss";
import ProjectView from "Components/Map/ProjectView";
import FormContainer from "Forms/FormContainer";
import Grid from "antd/es/grid";
import Filter from "Components/Map/Filter";
import Loader from "Components/Loader";
import Share from "Components/Map/Share";
import dayjs from "dayjs";
import useIsMounted from "Hooks/useIsMounted";

const { useBreakpoint } = Grid;

const Maps = () => {
  const screens = useBreakpoint();
  const dispatch = useDispatch();
  const projectView = useSelector((state) => state.formState.projectView.open);
  const shareReferences = useSelector(
    (state) => state.formState.shareReferences.open
  );
  const [map, setMap] = useState();
  const [markers, setMarkers] = useState({});
  const [projects, setProjects] = useState([]);
  const [markerCluster, setMarkerCluster] = useState({});
  const [selected, setSelected] = useState([]);
  const [currentAddress, setCurrentAddress] = useState();
  const [filterVisible, setFilterVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [fetching, setFetching] = useState(false);

  const [manufacturer, setManufacturer] = useState();
  const [productLine, setProductLine] = useState();
  const [color, setColor] = useState();
  const isMounted = useIsMounted();

  const mounted = isMounted.current;

  useEffect(() => {
    if (mounted) {
      fetchMarkers({
        year: dayjs().subtract(8, "years"),
      });
      mapInit();
    }

    return () => {
      setMarkers({});
      setMap(undefined);
    };
  }, [mounted]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (map && fetching && projects.length > 0) {
      pinInit();
    } else if (map && fetching) {
      if (Object.keys(markers).length > 0) {
        Object.keys(markers).map((m) => {
          markers[m].marker.setMap(null);
          return m;
        });
        markerCluster.clearMarkers();
      }
      setMarkers({});
      setMarkerCluster({});
      setTimeout(() => {
        setLoading(false);
        setFetching(false);
      }, 300);
    }
  }, [projects]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchMarkers = async (obj = {}, cb) => {
    try {
      unstable_batchedUpdates(() => {
        setLoading(true);
        setFetching(true);
      });

      const offset = convertOffset(new Date());
      const res = await api.post(`/markers/fetch/${offset}`, obj);

      setProjects(res.data);
      if (cb) cb();
    } catch (err) {
      console.log("err", err);
      message.error("Error getting projects");
    }
  };

  const mapInit = () => {
    setMap(
      new window.google.maps.Map(document.getElementById("map"), {
        center: { lat: 42.963795, lng: -85.670006 },
        zoom: 9,
        gestureHandling: "greedy",
        disableDefaultUI: true,
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        zoomControl: false,
        defaultMapTypeId: "hybrid",
        mapTypeId: "hybrid",
        tilt: 0,
      })
    );
  };

  const pinInit = useCallback(() => {
    let _markers = {};
    if (Object.keys(markers).length > 0) {
      Object.keys(markers).map((m) => {
        markers[m].marker.setMap(null);
        return m;
      });
      markerCluster.clearMarkers();
    }

    projects.map((p) => {
      if (p.lat && p.lng) {
        _markers[p._id] = {};
        _markers[p._id].marker = new window.google.maps.Marker({
          position: { lat: p.lat, lng: p.lng },
          map,
          draggable: false,
          animation: window.google.maps.Animation.DROP,
          icon: new window.google.maps.MarkerImage(
            "https://s3.us-east-2.amazonaws.com/pr-crm/assets/blue_marker.png",
            null,
            null,
            null,
            new window.google.maps.Size(24, 36)
          ),
        });

        _markers[p._id].click = window.google.maps.event.addListener(
          _markers[p._id].marker,
          "click",
          function () {
            dispatch(
              openForm("projectView", { ...p, setSelected, setMarkers })
            );
          }
        );
      }
      return p;
    });

    let theseMarkersInView = [];
    Object.keys(_markers).map((key) => {
      if (_markers[key]) {
        if (map.getBounds().contains(_markers[key].marker.getPosition())) {
          theseMarkersInView.push(_markers[key].marker);
        }
      }
      return key;
    });

    unstable_batchedUpdates(() => {
      setMarkerCluster(
        new MarkerClusterer(map, theseMarkersInView, {
          imagePath:
            "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
          averageCenter: true,
          enableRetinaIcons: true,
          gridSize: 60,
          maxZoom: 18,
        })
      );
      setMarkers(_markers);
      setFetching(false);
    });

    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [map, markerCluster, markers, projects, dispatch]);

  const openReferralList = useCallback(() => {
    dispatch(
      openForm("shareReferences", {
        manufacturer,
        productLine,
        color,
        setSelected,
        setMarkers,
      })
    );
  }, [dispatch, manufacturer, productLine, color, setSelected, setMarkers]);

  const openFilter = () => {
    setFilterVisible(true);
  };

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      let lat, lng;
      navigator.geolocation.getCurrentPosition((position) => {
        lat = position.coords.latitude;
        lng = position.coords.longitude;
        map.panTo({
          lat,
          lng,
        });
        map.setZoom(16);

        if (currentAddress) {
          currentAddress.setMap(null);
        }

        setCurrentAddress(
          new window.google.maps.Marker({
            position: { lat, lng },
            map,
            icon: new window.google.maps.MarkerImage(
              "https://s3.us-east-2.amazonaws.com/pr-crm/assets/my_location.png",
              null,
              null,
              null,
              new window.google.maps.Size(24, 36)
            ),
          })
        );
      });
    }
  };

  const unselectAll = () => {
    setMarkers((_markers) => {
      for (const s of selected) {
        _markers[s._id].marker.setIcon(
          new window.google.maps.MarkerImage(
            "https://s3.us-east-2.amazonaws.com/pr-crm/assets/blue_marker.png",
            null,
            null,
            null,
            new window.google.maps.Size(24, 36)
          )
        );
      }
      return _markers;
    });

    setSelected([]);
  };

  return (
    <div className={styles.container}>
      <div
        className={`${styles.loadingContainer} ${
          loading ? styles.loading : ""
        }`}
      >
        <Loader minHeight="100%" />
      </div>
      <div
        style={{
          width: "100%",
          height: "100%",
        }}
        id="map"
      ></div>
      <div className={styles.selectedPinsCount}>
        {selected.length} Selected{" "}
        {selected.length > 0 && (
          <Button
            size="small"
            type="primary"
            danger
            style={{ marginLeft: 8 }}
            onClick={unselectAll}
          >
            Unselect All
          </Button>
        )}
      </div>
      <div
        className={`${styles.mapIconCircle} ${styles.mapButtonPin}`}
        onClick={openReferralList}
      >
        <ShareIcon className={styles.icon} />
      </div>

      <div
        className={`${styles.mapIconCircle} ${styles.mapButtonFilter}`}
        onClick={openFilter}
      >
        <FilterIcon className={styles.icon} />
      </div>
      <div
        className={`${styles.mapIconCircle} ${styles.mapButtonLocation}`}
        onClick={getCurrentLocation}
      >
        <CrossHairs className={styles.icon} />
      </div>

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0 },
        }}
        type={!screens.md ? "drawer" : "modal"}
        width={!screens.md ? "100%" : 320}
        visible={projectView}
        form={ProjectView}
        props={{ selected }}
        formType="projectView"
        zIndex={4001}
      />

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0 },
        }}
        type={!screens.md ? "drawer" : "modal"}
        width={!screens.md ? "100%" : 320}
        title={null}
        visible={shareReferences}
        form={Share}
        props={{ selected }}
        formType="shareReferences"
        zIndex={4001}
      />

      <Filter
        visible={filterVisible}
        setVisible={setFilterVisible}
        map={map}
        currentAddress={currentAddress}
        setCurrentAddress={setCurrentAddress}
        fetchMarkers={fetchMarkers}
        manufacturer={manufacturer}
        setManufacturer={setManufacturer}
        setProductLine={setProductLine}
        setColor={setColor}
      />
    </div>
  );
};

export default Maps;
