import React from "react";
import List from "antd/es/list";
import Loader from "Components/Loader";
import styles from "./styles.module.scss";

const TableBody = ({ children, loading, bordered }) => {
  return (
    <List
      bordered
      className={`${styles.tableBody} tableBody ${
        bordered ? styles.leadManagement : ""
      }`}
    >
      {loading ? <Loader minHeight="100%" /> : children}
    </List>
  );
};

export default TableBody;
