import React from "react";
import LineChartWithInterval from "Reports/LineChartWithInterval";

export default function CompanyProfit() {
  return (
    <LineChartWithInterval
      path="/reports/companyProfit"
      type="currency"
      summary={true}
      lineItems={false}
      aggregate={true}
      report="COMPANY PROFIT"
    />
  );
}
