import React, { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelected } from "Actions/dataActions";
import { openForm } from "Actions/formActions";
import List from "antd/es/list";
import styles from "./styles.module.scss";

const DropdownItem = ({ item, style, headers, multi }) => {
  const selectedRow = useSelector((state) => state.dataState.selectedRow);
  const dispatch = useDispatch();

  const onClick = useCallback(() => {
    dispatch(setSelected(item._id));
    if (multi) {
      dispatch(openForm("multiDropdown", { ...item, editing: true }));
    } else {
      dispatch(openForm("dropdown", { ...item, editing: true }));
    }
  }, [dispatch, item, multi]);

  return (
    <List.Item
      style={style}
      className={`${styles.row} ${
        selectedRow === item._id ? styles.selected : ""
      }`}
      onClick={onClick}
    >
      <List.Item.Meta
        className={styles.col}
        style={{
          width: headers[0].width,
          textAlign: headers[0].align ? headers[0].align : "left",
        }}
        description={item.name}
      />
      <List.Item.Meta
        className={styles.col}
        style={{
          width: headers[1].width,
          textAlign: headers[1].align ? headers[1].align : "left",
        }}
        description={
          multi
            ? item.options.map((o) => o.value).join(", ")
            : item.options.join(", ")
        }
      />
    </List.Item>
  );
};

export default memo(DropdownItem);
