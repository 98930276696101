Object.defineProperty(exports, "__esModule", {
  value: true,
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _createSvg = require("./utils/createSvg");

var _createSvg2 = _interopRequireDefault(_createSvg);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

exports.default = (0, _createSvg2.default)(
  _react2.default.createElement(
    "g",
    null,
    _react2.default.createElement("path", {
      d: "M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z",
    })
  ),
  "ChevronUp"
);
