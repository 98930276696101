import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import FormControls from "Components/FormControls";
import FormBody from "Components/FormBody";
import ImagesDrawerHeader from "../Header";
import styles from "./styles.module.scss";
import SelectingControls from "../SelectingControls";
import Image from "../Image";
import usePrevious from "Hooks/usePrevious";

const Images = ({ onClose, index }) => {
  const selectedActivity = useSelector(
    (state) => state.dataState.selectedActivity
  );
  const params = useParams();
  const [selectedImages, setSelectedImages] = useState([]);
  const [selecting, setSelecting] = useState(false);
  const prevSelecting = usePrevious(selecting);

  useEffect(() => {
    const timer = setTimeout(() => {
      const el = document.getElementById(`${index || params.index}`);
      if (el) {
        el.scrollIntoView(true);
      }
    }, 100);
    return () => {
      clearTimeout(timer);
      document.body.classList.remove("selecting");
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!prevSelecting && selecting) {
      document.body.classList.add("selecting");
    } else if (!selecting && prevSelecting) {
      document.body.classList.remove("selecting");
    }
  }, [selecting, prevSelecting]);

  useEffect(() => {
    if (!selecting) {
      setSelectedImages([]);
    }
  }, [selecting]);

  return (
    <div className={styles.container}>
      <FormControls className={styles.headerContainer}>
        <ImagesDrawerHeader
          length={
            selectedActivity &&
            selectedActivity.images &&
            selectedActivity.images.length
          }
          onClose={onClose}
          setSelecting={setSelecting}
          selecting={selecting}
          selectedImages={selectedImages}
          setSelectedImages={setSelectedImages}
          images={selectedActivity && selectedActivity.images}
        />
      </FormControls>
      <FormBody
        className={`${styles.imageBody} ${selecting ? styles.selecting : ""}`}
        style={{ background: "#f0f0f0" }}
      >
        <div className="content-inner" id="imageViewer">
          {selectedActivity &&
            selectedActivity.images &&
            selectedActivity.images.map((image, _index) => (
              <Image
                image={image}
                key={image._id}
                _index={_index}
                selecting={selecting}
                selectedImages={selectedImages}
                setSelectedImages={setSelectedImages}
                scrollContainer="#imageViewer"
                global={selectedActivity && selectedActivity.global}
              />
            ))}
        </div>
      </FormBody>
      <SelectingControls
        selecting={selecting}
        selectedImages={selectedImages}
        setSelecting={setSelecting}
      />
    </div>
  );
};

export default Images;
