import React, { useState, useEffect, useCallback } from "react";
import { updateDetails } from "Actions/dataActions";
import { useSelector, useDispatch } from "react-redux";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import Form from "antd/es/form";
import Steps from "antd/es/steps";
import Button from "antd/es/button";
import message from "antd/es/message";
import api from "API";
import styles from "./styles.module.scss";
import sumBy from "Utils/sumBy";
import CancelButton from "Components/CancelButton";

import Audit from "./Audit";
import Corrections from "./Corrections";
import CloseOut from "./CloseOut";

const messageKey = "messageKey";

const ProjectCloseout = ({
  unsavedChanges,
  setUnsavedChanges,
  handleClose,
  type,
}) => {
  const dispatch = useDispatch();
  const formDetails = useSelector(
    (state) => state.formState.projectCloseout.formDetails
  );
  const viewableActivities = useSelector(
    (state) => state.dataState.viewableActivities
  );
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [scopeTotal, setScopeTotal] = useState(0);
  const [collectionTotal, setCollectionTotal] = useState(0);
  const [warnings, setWarnings] = useState(0);
  const [satisfaction, setSatisfaction] = useState(0);

  useEffect(() => {
    let _collectionTotal = sumBy(formDetails.collections, "amount").toFixed(2);
    let _scopeTotal = sumBy(formDetails.scopes, "amount").toFixed(2);
    let _warnings = 0;
    if (_scopeTotal.toString() !== _collectionTotal.toString()) _warnings++;
    for (const s of formDetails.scopes) {
      // if (s.progress < 100) _warnings++;
      if (s.status !== "Collected") _warnings++;
    }
    for (const a of formDetails.actions) {
      if (a.open) _warnings++;
    }

    setCollectionTotal(_collectionTotal);
    setScopeTotal(_scopeTotal);
    setWarnings(_warnings);

    setTimeout(() => {
      const el = document.getElementById("steps");
      el.classList.remove("ant-steps-vertical");
      el.classList.add("ant-steps-horizontal");
      el.classList.add("ant-steps-label-vertical");
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onFinish = useCallback(
    async (values) => {
      try {
        setLoading(true);
        message.loading({
          content: "Closing out project...",
          duration: 0,
          key: messageKey,
        });
        values._id = formDetails._id;
        values.project = {};
        values.project.checkListContract = values.checklistContract || false;
        values.project.checkListDumpster = values.checklistDumpster || false;
        values.project.checkListImages = values.checklistImages || false;
        values.project.checkListReturns = values.checklistReturns || false;
        values.project.checkListWorkOrder = values.checklistWorkOrder || false;
        values.project.customerSatisfaction =
          values.customerSatisfaction || false;
        values.project.contractPrice = scopeTotal;

        const res = await api.post("/projects/audit", values);

        dispatch(
          updateDetails({
            viewableActivities,
            details: res.data._details,
            activities: res.data._activities,
          })
        );

        message.success({
          content: "Project closed out",
          duration: 1.5,
          key: messageKey,
        });

        setLoading(false);
        handleClose(true);
      } catch (err) {
        console.log("err", err);
        setLoading(false);
        // message.error("Error closing out project");
        message.error({
          content: "Error closing out project",
          duration: 1.5,
          key: messageKey,
        });
      }
    },
    [formDetails, dispatch, viewableActivities, scopeTotal, handleClose]
  );

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error closing out project");
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  const step1Next = () => {
    setStep(1);
  };

  const step2Next = () => {
    if (scopeTotal.toString() === collectionTotal.toString()) {
      setStep(2);
    } else {
      message.error("Scope Total does not equal Collection Total");
    }
  };

  const prev = () => {
    setStep((thisStep) => thisStep - 1);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      className="form"
      initialValues={{
        scopes: formDetails.scopes,
      }}
    >
      <RemoveAutocomplete />

      <FormBody
        className="content-inner"
        style={{
          paddingLeft: 24,
          paddingTop: 24,
          paddingRight: 24,
          paddingBottom: 8,
          maxHeight: type === "modal" ? "calc(100vh - 161px)" : "unset",
        }}
      >
        <Steps
          id="steps"
          className={styles.closedoutSteps}
          labelPlacement="vertical"
          size="small"
          current={step}
          style={{ marginBottom: 24 }}
        >
          <Steps.Step title="Audit" />
          <Steps.Step title={`Corrections/\nConfirmation`} />
          <Steps.Step title={`Customer\nSatisfaction`} />
        </Steps>

        <div className={step === 0 ? "" : "hide"}>
          <Audit
            scopes={formDetails.scopes}
            actions={formDetails.actions}
            collectionTotal={collectionTotal}
            scopeTotal={scopeTotal}
            warnings={warnings}
          />
        </div>

        <div className={step === 1 ? "" : "hide"}>
          <Corrections
            scopeTotal={scopeTotal}
            setScopeTotal={setScopeTotal}
            collectionTotal={collectionTotal}
            scopes={formDetails.scopes}
            form={form}
          />
        </div>

        <div className={step === 2 ? "" : "hide"}>
          <CloseOut
            satisfaction={satisfaction}
            setSatisfaction={setSatisfaction}
            scopeTotal={scopeTotal}
            collectionTotal={collectionTotal}
            warnings={warnings}
          />
        </div>
      </FormBody>
      <FormControls className="sticky-footer">
        <CancelButton handleClick={handleClose} />

        {step === 0 && (
          <Form.Item style={{ margin: 0 }}>
            <Button type="primary" onClick={step1Next}>
              Next
            </Button>
          </Form.Item>
        )}

        {step === 1 && (
          <>
            <Form.Item
              style={{
                marginRight: 8,
                marginBottom: 0,
                display: "inline-block",
              }}
            >
              <Button onClick={prev}>Previous</Button>
            </Form.Item>
            <Form.Item style={{ margin: 0, display: "inline-block" }}>
              <Button type="primary" onClick={step2Next}>
                Next
              </Button>
            </Form.Item>
          </>
        )}

        {step === 2 && (
          <>
            <Form.Item
              style={{
                marginRight: 8,
                marginBottom: 0,
                display: "inline-block",
              }}
            >
              <Button onClick={prev}>Previous</Button>
            </Form.Item>
            <Form.Item style={{ margin: 0, display: "inline-block" }}>
              <Button
                htmlType="submit"
                type="primary"
                loading={loading}
                disabled={loading}
              >
                Submit
              </Button>
            </Form.Item>
          </>
        )}
      </FormControls>
    </Form>
  );
};

export default ProjectCloseout;
