import React, { useState, useEffect, useCallback } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchTable } from "Actions/tableActions";
import { openForm } from "Actions/formActions";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Typography from "antd/es/typography";
import Button from "antd/es/button";
import Form from "antd/es/form";
import Grid from "antd/es/grid";
import message from "antd/es/message";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import Add from "Icons/Add";
import Remove from "Icons/Remove";
import styles from "./styles.module.scss";
import api from "API";
import MaterialColumnHeaders from "Components/materialOrderQuote/MaterialColumnHeaders";
import MaterialRow from "Components/materialOrderQuote/MaterialRow";
import CustomerDetails from "../WorkOrder/CustomerDetails";
import SidingDetails from "../WorkOrder/SidingDetails";
import RoofingSteepDetails from "../WorkOrder/RoofingSteepDetails";
import DeliveryDetails from "../WorkOrder/DeliveryDetails";
import RoofingFlatDetails from "../WorkOrder/RoofingFlatDetails";
import CancelButton from "Components/CancelButton";
import Input from "antd/es/input";
import Post from "Icons/Post";
import generateChanges from "Utils/generateChanges";
import HiddenFields from "Forms/WorkOrder/HiddenFields";
import dayjs from "dayjs";
import openInNewTab from "Utils/openInNewTab";
import ReviewSection from "Forms/WorkOrder/ReviewSection";
import AerialCalculator from "Forms/WorkOrder/AerialCalculator";
import MaterialDeliveryDetails from "Forms/WorkOrder/MaterialDeliveryDetails";
import Collapse from "antd/es/collapse";
import Modal from "antd/es/modal";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";
import ChevronUp from "Icons/ChevronUp";
import Checkbox from "antd/es/checkbox";
// import ProcessFeedback from "Components/ProcessFeedback";
import SupplierModal from "./SupplierModal";

const messageKey = "messageKey";

let changedValues = {};

const customerDetails = [
  "customerName",
  "propertyType",
  "pitches",
  "stories",
  "projectAddressStreet",
  "projectAddressCity",
  "projectAddressState",
  "projectAddressZip",
  "projectAddressLat",
  "projectAddressLng",
  "projectAddressCounty",
  "projectAddressPermitAuthority",
  "projectDescription",
  "phone",
];

// const steps = [
//   "Submitting Form",
//   "Generating PDF",
//   "Creating Post",
//   "Saving PDF",
//   "Updating Scope",
//   "Updating List",
//   "Done!",
// ];

const { useBreakpoint } = Grid;

const PreJobCheck = ({
  unsavedChanges,
  setUnsavedChanges,
  setTitle,
  handleClose,
  type,
}) => {
  const formDetails = useSelector(
    (state) => state.formState.preJobCheck.formDetails
  );
  const table = useSelector((state) => state.tableState.table);
  const col = useSelector((state) => state.tableState.col);
  const order = useSelector((state) => state.tableState.order);
  const search = useSelector((state) => state.tableState.search);
  const screens = useBreakpoint();
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [supplierModalVisible, setSupplierModalVisible] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [failLoading, setFailLoading] = useState(false);
  const [selected, setSelected] = useState(undefined);
  const [startingRows, setStartingRows] = useState(0);
  const [scrollContainer, setScrollContainer] = useState(null);
  const [addFlat] = useState(
    formDetails.workOrder && formDetails.workOrder.flatManufacturer
      ? true
      : false
  );
  const [_lineItems] = useState(
    JSON.parse(
      JSON.stringify(
        formDetails.workOrder && formDetails.workOrder.lineItems
          ? formDetails.workOrder.lineItems.slice(0)
          : {}
      )
    )
  );
  const [collapsed, setCollapsed] = useState(false);
  const [scopeDescCollapsed, setScopeDescCollapsed] = useState(false);
  const [_lat, _setLat] = useState(
    formDetails.workOrder && formDetails.workOrder.projectAddressLat
      ? formDetails.workOrder.projectAddressLat
      : 42.963795
  );
  const [_lng, _setLng] = useState(
    formDetails.workOrder && formDetails.workOrder.projectAddressLng
      ? formDetails.workOrder.projectAddressLng
      : -85.670006
  );

  // const _user = useSelector((state) => state.authState._user);
  // const [feedBack, setFeedBack] = useState({
  //   active: "Submitting Form",
  //   progress: 0,
  // });

  useEffect(() => {
    setTitle(
      `Office Review${
        formDetails.workOrder
          ? ` - ${formDetails.workOrder.projectManagerName}`
          : ""
      }${formDetails.customerId ? ` (${formDetails.customerId})` : ""}`
    );

    let shingle = [],
      siding = [];

    if (!formDetails.workOrder.shingle && !formDetails.workOrder.siding) {
      if (formDetails.workOrder.manufacturer) {
        shingle.push(formDetails.workOrder.manufacturer);
        siding.push(formDetails.workOrder.manufacturer);
      }
      if (formDetails.workOrder.productLine) {
        shingle.push(formDetails.workOrder.productLine);
        siding.push(formDetails.workOrder.productLine);
      }
      if (formDetails.workOrder.color) {
        shingle.push(formDetails.workOrder.color);
        siding.push(formDetails.workOrder.color);
      }
    } else if (formDetails.workOrder.shingle) {
      shingle = formDetails.workOrder.shingle;
    } else if (formDetails.workOrder.siding) {
      siding = formDetails.workOrder.siding;
    }

    setStartingRows(formDetails.workOrder.lineItems.length);
    form.setFieldsValue({
      ...formDetails.workOrder,
      shingle,
      siding,
      dateSold: dayjs(formDetails.workOrder.dateSold),
      // TODO: difficultyRating overhaul
      difficultyRating: formDetails.workOrder.difficultyRating || 2,
    });

    return () => {
      form.resetFields();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    handleSelectedChange();
  }, [selected]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectedChange = async () => {
    try {
      if (!selected) {
        const lineItems = await form.getFieldValue("lineItems");
        if (lineItems) {
          lineItems.forEach((m, i) => {
            if (m.disabled) {
              form.validateFields([
                ["lineItems", i, "material"],
                ["lineItems", i, "type"],
                ["lineItems", i, "color"],
                ["lineItems", i, "quantity"],
              ]);
            }
          });
        }
      }
    } catch (err) {
      console.log("handleSelectedChange err", err);
    }
  };

  const onSave = useCallback(async () => {
    try {
      setSaveLoading(true);
      // message.loading("Saving...", 0);
      message.loading({
        content: "Saving office review...",
        duration: 0,
        key: messageKey,
      });
      let values = await form.getFieldsValue();

      if (values.shingle) {
        values.manufacturer = values.shingle[0];
        values.productLine = values.shingle[1];
        values.color = values.shingle[2];
      } else if (values.siding) {
        values.manufacturer = values.siding[0];
        values.productLine = values.siding[1];
        values.color = values.siding[2];
      }

      values = await generateChanges({
        changedValues,
        values,
        customerDetails,
        _lineItems,
        workOrder: formDetails,
        woCreated: true,
      });

      values.projectAddress = `${values.projectAddressStreet}, ${values.projectAddressCity}, ${values.projectAddressState} ${values.projectAddressZip}`;
      values.companyName = formDetails.workOrder.companyName;

      await api.post("/pre-job-check/save", values);

      changedValues = {};
      unstable_batchedUpdates(() => {
        setUnsavedChanges(false);
        setSaveLoading(false);
      });

      dispatch(
        fetchTable({
          table,
          col,
          order,
          search,
          fetching: false,
        })
      );

      // message.success("Office Review saved");
      message.success({
        content: "Office review saved",
        duration: 1.5,
        key: messageKey,
      });
    } catch (err) {
      console.log("err", err);
      setSaveLoading(false);
      // message.error("Error saving scope");
      message.error({
        content: "Error saving office review",
        duration: 1.5,
        key: messageKey,
      });
    }
  }, [
    dispatch,
    table,
    col,
    order,
    search,
    setUnsavedChanges,
    _lineItems,
    form,
    formDetails,
  ]);

  const confirmFail = () => {
    Modal.confirm({
      zIndex: 4002,
      icon: <QuestionCircleOutlined />,
      centered: true,
      cancelText: "Cancel",
      okText: "Delete",
      okButtonProps: { danger: true },
      content: <div>Are you sure you want to deny this scope?</div>,
      onOk() {
        return new Promise((resolve, reject) => {
          handleFail(() => resolve(true));
        }).catch((err) => console.log("err: ", err));
      },
      onCancel() {},
    });
  };

  const handleFail = useCallback(
    async (callback) => {
      try {
        setFailLoading(true);
        // message.loading("Denying...", 0);
        message.loading({
          content: "Denying office review...",
          duration: 0,
          key: messageKey,
        });
        let values = await form.getFieldsValue();

        if (values.shingle) {
          values.manufacturer = values.shingle[0];
          values.productLine = values.shingle[1];
          values.color = values.shingle[2];
        } else if (values.siding) {
          values.manufacturer = values.siding[0];
          values.productLine = values.siding[1];
          values.color = values.siding[2];
        }

        values = await generateChanges({
          changedValues,
          values,
          customerDetails,
          _lineItems,
          workOrder: formDetails,
          woCreated: true,
        });

        values.projectAddress = `${values.projectAddressStreet}, ${values.projectAddressCity}, ${values.projectAddressState} ${values.projectAddressZip}`;
        values.companyName = formDetails.workOrder.companyName;

        const res = await api.post("/pre-job-check/denied", values);

        for (const s of res.data.salesObj) {
          window.socket.emit("updateDashboard", { _user: s._user });
          window.socket.emit("updateFeed", { _user: s._user });
        }

        window.socket.emit("update", { _id: formDetails._projectId });

        unstable_batchedUpdates(() => {
          setUnsavedChanges(false);
          setFailLoading(false);
        });

        dispatch(
          fetchTable({
            table,
            col,
            order,
            search,
            fetching: false,
          })
        );

        // message.success("Scope denied");
        message.success({
          content: "Office review denied",
          duration: 1.5,
          key: messageKey,
        });

        setSupplierModalVisible(false);
        callback();
        handleClose(true);
      } catch (err) {
        console.log("err", err);
        setFailLoading(false);
        // message.error("Error denying scope");
        message.error({
          content: "Error denying office review",
          duration: 1.5,
          key: messageKey,
        });
      }
    },
    [
      dispatch,
      table,
      col,
      order,
      search,
      handleClose,
      _lineItems,
      form,
      formDetails,
      setUnsavedChanges,
    ]
  );

  // const updateFeedBack = (feedBack) => {
  //   setFeedBack(feedBack);
  // };

  // const onApprove = useCallback(async () => {
  //   try {
  //     window.socket.on(`${_user}-work-order-progress`, updateFeedBack);
  //     unstable_batchedUpdates(() => {
  //       setFeedBack({
  //         active: "Submitting Form",
  //         progress: 1,
  //       });
  //       setLoading(true);
  //     });

  //     // message.loading("Approving...", 0);
  //     message.loading({
  //       content: "Approving office review...",
  //       duration: 0,
  //       key: messageKey,
  //     });
  //     let values = await form.validateFields();

  //     if (values.shingle) {
  //       values.manufacturer = values.shingle[0];
  //       values.productLine = values.shingle[1];
  //       values.color = values.shingle[2];
  //     }

  //     values = await generateChanges({
  //       changedValues,
  //       values,
  //       customerDetails,
  //       _lineItems,
  //       workOrder: formDetails,
  //       woCreated: true,
  //     });

  //     values.projectAddress = `${values.projectAddressStreet}, ${values.projectAddressCity}, ${values.projectAddressState} ${values.projectAddressZip}`;

  //     const res = await api.post("/pre-job-check/approved", values);

  //     for (const s of res.data.salesObj) {
  //       window.socket.emit("updateDashboard", { _user: s._user });
  //       window.socket.emit("updateFeed", { _user: s._user });
  //     }

  //     window.socket.emit("update", { _id: formDetails._projectId });

  //     changedValues = {};
  //     unstable_batchedUpdates(() => {
  //       setUnsavedChanges(false);
  //       setLoading(false);
  //       setFeedBack({
  //         active: "Done!",
  //         progress: 100,
  //       });
  //     });

  //     dispatch(
  //       fetchTable({
  //         table,
  //         col,
  //         order,
  //         search,
  //         fetching: false,
  //       })
  //     );

  //     // message.success("Scope approved");
  //     message.success({
  //       content: "Office review approved",
  //       duration: 1.5,
  //       key: messageKey,
  //     });
  //     window.socket.removeAllListeners(`${_user}-work-order-progress`);
  //     handleClose(true);
  //   } catch (err) {
  //     console.log("err", err);
  //     if (
  //       err &&
  //       err.errorFields &&
  //       err.errorFields[0] &&
  //       err.errorFields[0].name
  //     ) {
  //       if (err.errorFields[0].name[0] === "lineItems") {
  //         const el = document.getElementById("top-of-form");
  //         el.scrollIntoView({ block: "end", behavior: "smooth" });
  //       } else {
  //         form.scrollToField(err.errorFields[0].name[0]);
  //       }
  //     }
  //     // message.error("Error approving scope");
  //     message.error({
  //       content: "Error approving office review",
  //       duration: 1.5,
  //       key: messageKey,
  //     });
  //     setLoading(false);
  //     setFeedBack({
  //       active: "Submitting Form",
  //       progress: 0,
  //     });
  //     window.socket.removeAllListeners(`${_user}-work-order-progress`);
  //   }
  // }, [
  //   _user,
  //   dispatch,
  //   table,
  //   col,
  //   order,
  //   search,
  //   handleClose,
  //   _lineItems,
  //   form,
  //   formDetails,
  //   setUnsavedChanges,
  // ]);
  const onApprove = () => {
    setSupplierModalVisible(true);
  };

  const removeRow = async () => {
    try {
      let lineItems = await form.getFieldValue("lineItems");
      lineItems = lineItems.filter((m, i) => {
        if (
          !m.checkbox ||
          m.disabled ||
          (m.checkbox === true && i + 1 <= startingRows)
        ) {
          return true;
        }
        return false;
      });
      lineItems.forEach((m, i) => {
        if (m.checkbox || m.disabled) {
          m.disabled = true;
          m.checkbox = false;
          changedValues = {
            ...changedValues,
            [i]: {
              ...changedValues[i],
              disabled: true,
              index: i,
            },
          };
        }
      });
      form.setFieldsValue({
        lineItems,
      });
      setSelected(undefined);
    } catch (err) {
      console.log("err", err);
    }
  };

  const removeSingleRow = async (index) => {
    try {
      let lineItems = await form.getFieldValue("lineItems");
      if (index < startingRows) lineItems[index].disabled = true;
      changedValues = {
        ...changedValues,
        [index]: {
          ...changedValues[index],
          disabled: true,
          index,
        },
      };
      form.setFieldsValue({
        lineItems,
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleUnlock = async (index) => {
    try {
      let lineItems = await form.getFieldValue("lineItems");
      lineItems[index].disabled = false;
      changedValues = {
        ...changedValues,
        [index]: {
          ...changedValues[index],
          disabled: false,
          index,
        },
      };
      form.setFieldsValue({
        lineItems,
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const onValuesChange = (values) => {
    if (!unsavedChanges) setUnsavedChanges(true);

    let key = Object.keys(values)[0];
    let value = values[key];
    let index;

    if (key === "lineItems") {
      index = Object.keys(value)[0];

      let subValue = value[index];
      key = Object.keys(subValue)[0];
      value = subValue[key];
    }

    if (index && key !== "checkbox") {
      changedValues = {
        ...changedValues,
        [index]: {
          ...changedValues[index],
          [key]: value,
          index,
        },
      };
    } else if (key !== "checkbox") {
      changedValues = {
        ...changedValues,
        [key]: {
          value,
          key,
        },
      };
    }
  };

  const onFieldsChange = async (fields) => {
    try {
      if (fields.length > 0 && fields[0].name[2] === "checkbox") {
        let lineItems = await form.getFieldValue("lineItems");
        if (lineItems) {
          let arr = [];
          let cnt = 0;
          for (const m of lineItems) {
            if (m.checkbox) {
              arr.push(cnt);
              cnt++;
            }
          }
          if (arr.length === 0) arr = undefined;
          setSelected(arr);
        }
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleCancel = () => {
    handleClose();
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const toggleScopeDescCollapsed = () => {
    setScopeDescCollapsed(!scopeDescCollapsed);
  };

  const openActivity = useCallback(() => {
    dispatch(
      openForm("activity", {
        _id: formDetails._projectId,
        customerId: formDetails.customerId,
        customerName: formDetails.customerName,
        projectStatus: "Sold",
        projectManager: {
          name: formDetails.workOrder.projectManagerName,
        },
      })
    );
  }, [dispatch, formDetails]);

  const openMediaWindow = () => {
    openInNewTab(
      `/workflow/office-review-media/${formDetails.workOrder._projectId}`
    );
  };

  const updateCords = ({ lat, lng }) => {
    _setLat(lat);
    _setLng(lng);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      className="form"
      onValuesChange={onValuesChange}
      onFieldsChange={onFieldsChange}
    >
      {/* {feedBack.progress === 0 && ( */}
      <FormControls
        className={`z-depth-1 ${styles.scopeDesc} ${
          scopeDescCollapsed ? styles.scopeDescCollapsed : ""
        }`}
        style={{
          padding: 0,
          zIndex: 1,
        }}
      >
        <div
          className={`${styles.reviewSectionChild} ${
            scopeDescCollapsed ? styles.scopeDescCollapsed : ""
          }`}
        >
          <div style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.06)" }}>
            <Button
              block
              type="link"
              className={styles.collapseTrigger}
              onClick={toggleScopeDescCollapsed}
            >
              {scopeDescCollapsed ? "SHOW SCOPE DESC " : "HIDE SCOPE DESC "}
              <ChevronUp />
            </Button>
          </div>
          <div style={{ padding: "10px 24px" }}>
            <Form.Item
              style={{ margin: 0 }}
              name="scopeDescription"
              label="Scope Description of Work"
              rules={[
                {
                  required: true,
                  message: "Scope Description of Work is required",
                },
              ]}
            >
              <Input.TextArea autoComplete="dont-use-autocomplete" rows={4} />
            </Form.Item>
          </div>
        </div>
      </FormControls>
      {/* )} */}

      <FormBody>
        <div
          className="content-inner p24"
          id="work-order-container"
          ref={setScrollContainer}
          style={
            type === "drawer"
              ? { height: `calc(100% - ${collapsed ? "46px" : "161px"})` }
              : {}
          }
        >
          <RemoveAutocomplete />

          <div id="top-of-form" />
          {/* <div className={feedBack.progress !== 0 ? "hide" : ""}> */}
          <HiddenFields />

          <MaterialDeliveryDetails />

          <Button
            block
            className="green"
            style={{ marginBottom: 24 }}
            type="primary"
            onClick={openMediaWindow}
          >
            Open Media Window
          </Button>

          <Collapse
            defaultActiveKey={[
              "Customer Details",
              "Aerial Calculations",
              "Roofing Steep",
              "Roofing Flat",
              "Delivery Details",
            ]}
            className={styles.collapse}
          >
            <Collapse.Panel header="Customer Details" key="Customer Details">
              <CustomerDetails
                form={form}
                _lat={_lat}
                _lng={_lng}
                updateCords={updateCords}
              />
            </Collapse.Panel>

            {formDetails.name === "Siding" ? (
              <Collapse.Panel
                header="Aerial Calculations"
                key="Aerial Calculations"
              >
                <AerialCalculator preJobCheck form={form} />
              </Collapse.Panel>
            ) : null}

            {formDetails.name === "Roofing - Steep" ? (
              <Collapse.Panel header="Roofing Steep" key="Roofing Steep">
                <RoofingSteepDetails
                  form={form}
                  startingRows={startingRows}
                  setStartingRows={setStartingRows}
                  addFlat={addFlat}
                  review
                  hideScopeDetails
                />
              </Collapse.Panel>
            ) : formDetails.name === "Siding" ? (
              <SidingDetails
                form={form}
                startingRows={startingRows}
                setStartingRows={setStartingRows}
                review
              />
            ) : (
              <Collapse.Panel header="Roofing Flat" key="Roofing Flat">
                <RoofingFlatDetails
                  form={form}
                  startingRows={startingRows}
                  setStartingRows={setStartingRows}
                />
              </Collapse.Panel>
            )}

            <Collapse.Panel header="Deliver Details" key="Delivery Details">
              <DeliveryDetails />
            </Collapse.Panel>
          </Collapse>

          <Typography.Title level={4}>
            Materails To Be Delivered
          </Typography.Title>

          {!screens.xs && (
            <MaterialColumnHeaders
              form={form}
              selected={selected}
              setSelected={setSelected}
              scrollContainer={scrollContainer}
            />
          )}

          <Form.List name="lineItems">
            {(fields, { add, remove }) => {
              const handleAdd = () => {
                add();
              };

              const handleRemove = (i) => {
                if (i < startingRows) {
                  removeSingleRow(i);
                } else {
                  remove(i);
                }
              };

              return (
                <>
                  {fields.map((field, index) => (
                    <MaterialRow
                      quote={true}
                      key={field.key}
                      fields={fields}
                      field={field}
                      index={index}
                      form={form}
                      selected={selected}
                      handleRemove={handleRemove}
                      handleUnlock={handleUnlock}
                    />
                  ))}
                  <Row gutter={{ xs: 8, sm: 8, md: 12, lg: 24 }}>
                    {!selected ? (
                      <Col xs={24}>
                        <Form.Item style={{ marginRight: 0 }}>
                          <Button
                            className={`${styles.button} green`}
                            style={
                              screens.lg
                                ? { marginTop: 12 }
                                : screens.md
                                ? { marginTop: 6 }
                                : { marginTop: 12 }
                            }
                            type="primary"
                            onClick={handleAdd}
                            block
                          >
                            <Add size={18} /> Add Material
                          </Button>
                        </Form.Item>
                      </Col>
                    ) : (
                      <Col xs={24}>
                        <Form.Item>
                          <Button
                            className={styles.button}
                            style={
                              screens.lg
                                ? { marginTop: 12 }
                                : screens.md
                                ? { marginTop: 6 }
                                : { marginTop: 12 }
                            }
                            danger
                            type="primary"
                            onClick={removeRow}
                            block
                          >
                            <Remove size={18} /> Remove Selected
                          </Button>
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                </>
              );
            }}
          </Form.List>

          <Button
            onClick={openActivity}
            type="primary"
            danger
            shape="circle"
            className={`${styles.fab} ${collapsed ? styles.collapsed : ""}`}
          >
            <Post />
          </Button>
          {/* </div> */}

          {/* <div
            style={{ height: "100%" }}
            className={feedBack.progress === 0 ? "hide" : ""}
          >
            <ProcessFeedback feedBack={feedBack} steps={steps} />
          </div> */}
        </div>
      </FormBody>

      {/* {feedBack.progress === 0 && ( */}
      <ReviewSection collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
      {/* )} */}

      <FormControls className="sticky-footer">
        <CancelButton handleClick={handleCancel} title="Cancel" />

        {/* {feedBack.progress === 0 && ( */}
        {/* <> */}
        <Form.Item
          name="skipPreJobInspection"
          valuePropName="checked"
          style={{ margin: 0, display: "inline-flex" }}
        >
          <Checkbox>Skip On-site Inspection</Checkbox>
        </Form.Item>

        <Button
          type="text"
          style={{ padding: "4px 8px", marginRight: 8, color: "#1890ff" }}
          onClick={onSave}
          loading={saveLoading}
          disabled={saveLoading}
        >
          Save
        </Button>

        <Button
          type="primary"
          danger
          style={{ marginRight: 8 }}
          loading={failLoading}
          disabled={failLoading}
          onClick={confirmFail}
        >
          Deny
        </Button>

        <Button
          type="primary"
          // loading={loading}
          // disabled={loading}
          onClick={onApprove}
        >
          Approve
        </Button>
        {/* </>
        )} */}
      </FormControls>

      <SupplierModal
        visible={supplierModalVisible}
        setVisible={setSupplierModalVisible}
        form={form}
        setUnsavedChanges={setUnsavedChanges}
        handleClose={handleClose}
        changedValues={changedValues}
        _lineItems={_lineItems}
        companyName={
          formDetails &&
          formDetails.workOrder &&
          formDetails.workOrder.companyName
        }
      />
    </Form>
  );
};

export default PreJobCheck;
