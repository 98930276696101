import React, { useState } from "react";
import AutoComplete from "antd/es/auto-complete";
import Input from "antd/es/input";
import Select from "antd/es/select";
import materials from "Constants/materials";
import styles from "./styles.module.scss";

const { Option } = Select;

const MaterialAutoComplete = ({
  index,
  form,
  disabled,
  onChange,
  value,
  handleBlur,
  handleFocus,
}) => {
  const [_materials, _setMaterials] = useState(materials);
  const triggerChange = (changedValue) => {
    if (onChange) {
      onChange(changedValue.value);
    }
  };

  const options = _materials.map((m) => (
    <Option
      key={JSON.stringify(m)}
      material={m.material}
      type={m.type}
      color={m.color}
      style={{ borderBottom: "1px solid #f0f0f0" }}
    >
      <div>
        <b>{m.material}</b>
      </div>
      {m.type && (
        <div
          style={{ width: "70%", display: "inline-block", fontSize: ".8em" }}
        >
          Type: {m.type}
        </div>
      )}
      {m.color && (
        <div
          style={{ width: "30%", display: "inline-block", fontSize: ".8em" }}
        >
          Color: {m.color}
        </div>
      )}
    </Option>
  ));

  const handleSearch = (e) => {
    _setMaterials(
      materials.filter((obj) => {
        let m = obj.material.toLowerCase();
        let v = e.toLowerCase();
        return m.includes(v);
      })
    );
  };

  const onSelect = async (p) => {
    try {
      let lineItems = await form.getFieldValue("lineItems");

      lineItems[index].material = p.material;
      lineItems[index].type = p.type;
      lineItems[index].color = p.color;

      form.setFieldsValue({
        lineItems,
      });

      triggerChange({
        value: p.material,
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const onValueChange = (newMaterial) => {
    triggerChange({
      value: newMaterial,
    });
  };

  return (
    <AutoComplete
      dataSource={options}
      onSearch={handleSearch}
      onSelect={(e, p) => onSelect(p)}
      defaultActiveFirstOption={false}
      value={value}
      disabled={disabled}
      dropdownClassName={styles.dropdownClassName}
      getPopupContainer={(trigger) => trigger.parentNode}
      autoComplete="dont-use-autocomplete"
      onFocus={handleFocus}
      onBlur={handleBlur}
    >
      <div>
        <label htmlFor="" style={{ display: "none" }}>
          hidden label to mislead chrome autocomplete
        </label>
        <Input
          placeholder="Material"
          autoComplete="dont-use-autocomplete"
          onChange={(e) => onValueChange(e.target.value)}
          value={value}
          disabled={disabled}
        />
      </div>
    </AutoComplete>
  );
};

export default MaterialAutoComplete;
