import React from "react";
import FormControls from "Components/FormControls";
import Button from "antd/es/button";
import Form from "antd/es/form";
import ChevronUp from "Icons/ChevronUp";
import Row from "antd/es/row";
import Col from "antd/es/col";
import styles from "./styles.module.scss";
import Input from "antd/es/input";
import DifficultyRating from "Components/Inputs/DifficultyRating";
import useIsMobile from "Hooks/useIsMobile";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";

const ReviewSection = ({
  hideDifficulty,
  collapsed,
  toggleCollapsed,
  hideAll,
}) => {
  const mobile = useIsMobile();
  return (
    <FormControls
      className={`sticky-footer z-depth_1 ${styles.reviewSection} ${
        collapsed ? styles.collapsed : ""
      }`}
      style={{
        top: "unset",
        bottom: mobile ? "56px" : "105px",
        padding: 0,
        display: hideAll ? "none" : "block",
      }}
    >
      <div
        className={`${styles.reviewSectionChild} ${
          collapsed ? styles.collapsed : ""
        }`}
      >
        <div style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.06)" }}>
          <Button
            block
            type="link"
            className={styles.collapseTrigger}
            onClick={toggleCollapsed}
          >
            {collapsed ? "SHOW REVIEW NOTES " : "HIDE REVIEW NOTES "}
            <ChevronUp />
          </Button>
        </div>

        <div style={{ padding: "10px 24px" }}>
          <Row gutter={16}>
            <Col xs={24} sm={hideDifficulty ? 12 : 8}>
              <Form.Item
                className={`${styles.reviewNotes} ${
                  collapsed ? styles.collapsed : ""
                }`}
                name="adminNotes"
                label="Admin Notes"
              >
                <Input.TextArea
                  autoComplete="dont-use-autocomplete"
                  autoSize={{ minRows: 4, maxRows: 4 }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={hideDifficulty ? 12 : 8}>
              <Form.Item
                className={`${styles.reviewNotes} ${
                  collapsed ? styles.collapsed : ""
                }`}
                name="reviewNotes"
                label="Install Notes"
              >
                <Input.TextArea
                  autoComplete="dont-use-autocomplete"
                  autoSize={{ minRows: 4, maxRows: 4 }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={hideDifficulty ? 0 : 8}>
              <Row>
                {/* TODO: difficultyRating overhaul */}
                <Col xs={24}>
                  <Form.Item
                    className={`${styles.reviewNotes} ${
                      collapsed ? styles.collapsed : ""
                    }`}
                    name="difficultyRating"
                    label="Difficulty Rating"
                    rules={[
                      {
                        required: !hideDifficulty && !hideAll,
                        message: "Difficulty Rating is required",
                      },
                    ]}
                  >
                    <DifficultyRating />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    name="difficultyRatingInput"
                    className={`${styles.reviewNotes} ${
                      collapsed ? styles.collapsed : ""
                    }`}
                  >
                    <InputNoAutoComplete id="difficultyRatingInput" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </FormControls>
  );
};

export default ReviewSection;
