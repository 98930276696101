import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "antd/es/grid";
import {
  fetchTableFilters,
  setScrollbar,
  unsetTable,
} from "Actions/tableActions";
import { setSelected } from "Actions/dataActions";
import Table from "Components/Table";
import TableRow from "Components/Table/TableItems/MarketingItem";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as Vlist } from "react-window";
import usePrevious from "Hooks/usePrevious";
import useIsMobile from "Hooks/useIsMobile";
import isEmpty from "Utils/isEmpty";
import MarketingOfficeForms from "Forms/MarketingOfficeForms";

const { useBreakpoint } = Grid;

const headers = [
  {
    _name: "dateSubmitted",
    name: "SUBMITTED",
    width: "20%",
    type: "date",
  },
  {
    _name: "status",
    name: "STATUS",
    width: "10%",
  },
  {
    _name: "type",
    name: "TYPE",
    width: "20%",
  },
  {
    _name: "salesman",
    name: "SALESMAN",
    width: "30%",
  },
  {
    _name: "dueDate",
    name: "DUE DATE",
    width: "20%",
    type: "date",
    align: "right",
  },
];

const filters = [
  {
    title: "Type",
    type: "type",
    fields: [
      {
        name: "type",
        placeholder: "Select an Option(s)",
        values: [
          "Follow Up Letter",
          "Gone Fishing",
          "Jobsite Target",
          "Thank You Letter",
        ],
      },
    ],
  },
  {
    title: "Status",
    type: "status",
    fields: [
      {
        name: "status",
        placeholder: "Select an Option(s)",
        values: ["Active", "Completed"],
      },
    ],
  },
];

const Marketing = () => {
  const dispatch = useDispatch();
  const screens = useBreakpoint();
  const mobile = useIsMobile();
  const prevScreens = usePrevious(screens);
  const rows = useSelector((state) => state.tableState.rows);
  const fetching = useSelector((state) => state.tableState.fetching);
  const search = useSelector((state) => state.tableState.search);
  const activeFilter = useSelector((state) => state.tableState.activeFilter);
  const collapsed = useSelector((state) => state.settingsState.collapsed);
  const selectedRow = useSelector((state) => state.dataState.selectedRow);
  const [itemSize, setItemSize] = useState(100);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    initTable();

    return () => dispatch(unsetTable());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTimeout(() => {
      dispatch(setScrollbar());
    });
  }, [rows, dispatch]);

  const initTable = useCallback(() => {
    dispatch(
      fetchTableFilters({
        table: "marketing",
        col: "dueDate",
        order: 1,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (prevScreens && screens) {
      if (prevScreens.md && !screens.md) {
        setItemSize(100);
      } else if (!prevScreens.md && screens.md) {
        setItemSize(47);
      }
    }
  }, [screens, prevScreens]);

  const toggleOpen = useCallback(
    (bool) => {
      setOpen(bool);
      if (!bool) {
        dispatch(setSelected({}));
      }
    },
    [dispatch]
  );

  const Row = ({ index, style }) => {
    const item = rows[index];
    if (!mobile)
      return (
        <TableRow
          style={style}
          key={item._id}
          {...item}
          headers={headers}
          setOpen={setOpen}
        />
      );
    return (
      <TableRow
        style={style}
        key={item._id}
        {...item}
        setOpen={setOpen}
        mobile
      />
    );
  };

  return (
    <>
      <Table
        loading={fetching}
        filter={
          search ||
          (activeFilter && activeFilter.filter && !isEmpty(activeFilter.filter))
            ? true
            : false
        }
        mobile={mobile}
        sider={screens.lg}
        collapsed={collapsed}
      >
        <Table.Header
          filters={filters}
          showSearch={false}
          downloadBtn={false}
        />
        <Table.Filter
          hide={
            search ||
            (activeFilter &&
              activeFilter.filter &&
              !isEmpty(activeFilter.filter))
              ? false
              : true
          }
        />

        {!mobile && <Table.ColumnHeaders headers={headers} />}
        <Table.Body loading={fetching}>
          {rows.length > 0 && (
            <AutoSizer>
              {({ height, width }) => (
                <Vlist
                  height={height}
                  itemCount={rows.length}
                  itemSize={itemSize}
                  width={width}
                >
                  {Row}
                </Vlist>
              )}
            </AutoSizer>
          )}
        </Table.Body>
      </Table>

      <MarketingOfficeForms
        open={open}
        setOpen={toggleOpen}
        selected={selectedRow}
      />
    </>
  );
};

export default Marketing;
