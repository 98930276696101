import React from "react";
import { useInView } from "react-intersection-observer";
import styles from "./styles.module.scss";

const LazyImage = ({
  width = "100%",
  height = "100%",
  once = true,
  root = "#body",
  offsetVertical = 0,
  offsetHorizontal = 0,
  children,
}) => {
  const scrollContainer = document.getElementById(root);
  const [ref, inView] = useInView({
    triggerOnce: once,
    rootMargin: `${offsetVertical}px ${offsetHorizontal}px`,
    root: scrollContainer,
  });

  return (
    <div
      ref={ref}
      style={{
        width,
        height,
      }}
      className={styles.lazyEl}
    >
      {inView ? children : null}
    </div>
  );
};

export default LazyImage;
