import React from "react";
import Form from "antd/es/form";
import Input from "antd/es/input";
import InputNumberNoAutoComplete from "Components/Inputs/InputNumberNoAutoComplete";

const TruckMaintenance = () => {
  return (
    <>
      <Form.Item
        label="What maintenance is needed"
        name="explanation"
        rules={[
          {
            required: true,
            message:
              "You must provide an explanation of what you ware requesting!",
          },
        ]}
      >
        <Input.TextArea autosize={{ minRows: 4 }} />
      </Form.Item>

      <Form.Item
        label="How many days does this need to be completed"
        name="days"
        rules={[
          {
            required: true,
            message: `Please provide a number of days`,
          },
        ]}
      >
        <InputNumberNoAutoComplete id="days" />
      </Form.Item>
    </>
  );
};

export default TruckMaintenance;
