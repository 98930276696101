import React, { useState, useEffect } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useSelector } from "react-redux";
import api from "API";
import Input from "antd/es/input";
import Form from "antd/es/form";
import message from "antd/es/message";
import Button from "antd/es/button";
import Modal from "antd/es/modal";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";
import FormBody from "Components/FormBody";
import FormControls from "Components/FormControls";
import Phone from "Components/Inputs/Phone";
import Address from "Components/Inputs/Address";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import phoneMask from "Utils/phoneMask";
import CancelButton from "Components/CancelButton";
import InputNoAutoComplete from "Components/Inputs/InputNoAutoComplete";

const messageKey = "builderKey";

const Builder = ({
  unsavedChanges,
  setUnsavedChanges,
  handleClose,
  setTitle,
  type,
}) => {
  const formDetails = useSelector(
    (state) => state.formState.builder.formDetails
  );
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!formDetails.name) {
      setTitle("Create Builder");
    } else {
      setTitle(
        <span>
          Edit Builder{" "}
          <Button
            size="small"
            danger
            onClick={confirmDelete}
            style={{ float: "right", marginRight: 28 }}
          >
            Delete
          </Button>
        </span>
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const confirmDelete = () => {
    Modal.confirm({
      zIndex: 4002,
      icon: <QuestionCircleOutlined />,
      centered: true,
      cancelText: "Cancel",
      okText: "Delete",
      okButtonProps: { danger: true },
      content: <div>Are you sure you want to delete this builder?</div>,
      onOk() {
        return new Promise((resolve, reject) => {
          deleteBuilder(() => resolve(true));
        }).catch((err) => console.log("err: ", err));
      },
      onCancel() {},
    });
  };

  const deleteBuilder = async (cb) => {
    try {
      const _id = await form.getFieldValue("_id");
      const res = await api.delete(`/builders/${_id}`);

      if (formDetails.setDataSource) formDetails.setDataSource(res.data);

      unstable_batchedUpdates(() => {
        setUnsavedChanges(false);
        setLoading(false);
      });
      message.success("Builder Deleted");
      cb();
      handleClose(true);
    } catch (err) {
      console.log("err", err);
      message.error("Error deleting builder");
    }
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      // message.loading("Saving Builder...", 0);
      message.loading({
        content: "Saving builder...",
        duration: 0,
        key: messageKey,
      });
      if (!formDetails.name) {
        const res = await api.post("/builders/add", values);
        if (formDetails.setDataSource) formDetails.setDataSource(res.data);
        unstable_batchedUpdates(() => {
          setUnsavedChanges(false);
          setLoading(false);
        });
      } else {
        const res = await api.post("/builders/update", values);
        if (formDetails.setDataSource) formDetails.setDataSource(res.data);
        unstable_batchedUpdates(() => {
          setUnsavedChanges(false);
          setLoading(false);
        });
      }
      // message.success("Builder saved");
      message.success({
        content: "Builder saved",
        duration: 1.5,
        key: messageKey,
      });
      handleClose(true);
    } catch (err) {
      setLoading(false);
      // message.error("Error saving builder");
      message.error({
        content: "Error saving builder",
        duration: 1.5,
        key: messageKey,
      });
      console.log("err", err);
    }
  };

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error saving builder");
  };

  const onValuesChange = () => {
    if (!unsavedChanges) setUnsavedChanges(true);
  };

  const setAddress = ({ city, state, postcode }) => {
    form.setFieldsValue({
      city,
      state,
      zip: postcode,
    });
  };

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      initialValues={{
        _id: !formDetails._id ? undefined : formDetails._id,
        name: !formDetails.name ? undefined : formDetails.name,
        phone: !formDetails.phone ? undefined : phoneMask(formDetails.phone),
        email: !formDetails.email ? undefined : formDetails.email,
        street: !formDetails.street ? undefined : formDetails.street,
        city: !formDetails.city ? undefined : formDetails.city,
        state: !formDetails.state ? undefined : formDetails.state,
        zip: !formDetails.zip ? undefined : formDetails.zip,
      }}
      className="form"
    >
      <RemoveAutocomplete />

      <FormBody
        className="content-inner"
        style={{
          paddingLeft: 24,
          paddingTop: 24,
          paddingRight: 24,
          paddingBottom: 8,
          maxHeight: type === "modal" ? "calc(100vh - 161px)" : "unset",
        }}
      >
        <Form.Item name="_id" hidden>
          <Input />
        </Form.Item>

        <Form.Item
          name="name"
          label="Company Name"
          rules={[{ required: true, message: "Company name is required" }]}
        >
          <InputNoAutoComplete autoFocus id="name" />
        </Form.Item>

        <Form.Item
          name="phone"
          label="Phone"
          rules={[{ required: true, message: "Phone is required" }]}
        >
          <Phone autoComplete="builder-phone-input" id="phone" />
        </Form.Item>

        <Form.Item
          name="email"
          label="Email"
          rules={[{ type: "email", message: "Must be valid email" }]}
        >
          <InputNoAutoComplete type="email" id="email" />
        </Form.Item>

        <Form.Item
          label="Street"
          name="street"
          rules={[{ required: true, message: "Street is required" }]}
        >
          <Address
            autoComplete="builder-street"
            setAddress={setAddress}
            id="street"
          />
        </Form.Item>

        <Form.Item
          label="City"
          name="city"
          rules={[
            {
              required: true,
              message: "City is required",
              type: "string",
            },
          ]}
        >
          <InputNoAutoComplete id="city" />
        </Form.Item>

        <Form.Item
          label="State"
          name="state"
          rules={[
            {
              required: true,
              message: "State is required",
              type: "string",
            },
            {
              len: 2,
              message: "State must be abbreviated",
            },
          ]}
        >
          <InputNoAutoComplete id="state" />
        </Form.Item>

        <Form.Item
          label="Zip"
          name="zip"
          rules={[
            {
              required: true,
              message: "Zip is required",
            },
          ]}
        >
          <InputNoAutoComplete type="phone" id="zip" />
        </Form.Item>
      </FormBody>
      <FormControls className="sticky-footer">
        <CancelButton handleClick={handleClose} />
        <Form.Item style={{ margin: 0 }}>
          <Button
            htmlType="submit"
            type="primary"
            loading={loading}
            disabled={loading || !unsavedChanges}
          >
            Submit
          </Button>
        </Form.Item>
      </FormControls>
    </Form>
  );
};

export default Builder;
