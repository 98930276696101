import React from "react";
import Col from "antd/es/col";
import Row from "antd/es/row";
import Form from "antd/es/form";
import Button from "antd/es/button";
import Delete from "Icons/Delete";
import styles from "./styles.module.scss";
import Currency from "Components/Inputs/Currency";

const ExpenseColumn = ({ type, allowDelete, onChange }) => {
  return (
    <Form.List name={type}>
      {(fields, { add, remove }) => {
        const handleAdd = () => {
          add();
        };

        const onRemove = (option) => {
          remove(option);
          setTimeout(() => {
            onChange();
          });
        };
        return (
          <>
            {fields.map((field) => {
              const handleRemove = () => {
                onRemove(field.name);
              };

              return (
                <div key={`${field.key}-option`}>
                  <Row gutter={4}>
                    <Col xs={allowDelete ? 19 : 24}>
                      <Form.Item
                        {...field}
                        name={[field.name, "amount"]}
                        fieldKey={[field.name, "amount"]}
                        rules={[
                          {
                            required: true,
                            message: "Amount is required",
                          },
                        ]}
                      >
                        <Currency
                          onChange={onChange}
                          size="small"
                          id={[field.name, "amount"]}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={allowDelete ? 5 : 0}>
                      <Button
                        size="small"
                        tabIndex="-1"
                        type="text"
                        shape="circle"
                        danger
                        className={styles.deleteButton}
                        onClick={handleRemove}
                      >
                        <Delete />
                      </Button>
                    </Col>
                  </Row>
                </div>
              );
            })}
            <Button
              size="small"
              type="primary"
              block
              onClick={handleAdd}
              style={{ marginTop: 4 }}
            >
              Add
            </Button>
          </>
        );
      }}
    </Form.List>
  );
};

export default ExpenseColumn;
