const templates = {
  "Roofing - Steep": [
    {
      material: "Shingles",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "Ridge Cap",
      type: "",
      color: "Match",
      quantity: "",
    },
    {
      material: "Drip Edge",
      type: "T / ",
      color: "",
      quantity: "",
    },
    {
      material: "Drip Edge",
      type: "C / ",
      color: "",
      quantity: "",
    },
    {
      material: "Ventilation",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "Ventilation",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "Underlayment",
      type: "",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Ice & Water",
      type: "",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Pipe Boot",
      type: "1-3",
      color: "",
      quantity: "",
    },
    {
      material: "Pipe Boot",
      type: "3-4",
      color: "",
      quantity: "",
    },
    {
      material: "Zipper Boot",
      type: "Standard",
      color: "Black",
      quantity: "",
    },
    {
      material: "Starter Strips",
      type: "",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Coil Nails",
      type: '1 1/4"',
      color: "N/A",
      quantity: "",
    },
    {
      material: "Coil Nails",
      type: '1 1/2"',
      color: "N/A",
      quantity: "",
    },
    {
      material: '3" Nails',
      type: "5lb Box",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Staples",
      type: "T-50",
      color: "N/A",
      quantity: "2",
    },
    {
      material: "Step Flashing",
      type: "Prebent 8 x 8",
      color: "",
      quantity: "",
    },
    {
      material: "Wood",
      type: '7/16" OSB',
      color: "N/A",
      quantity: "",
    },
    {
      material: "Wood",
      type: "",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Framing Nails",
      type: '2 3/8"',
      color: "N/A",
      quantity: "",
    },
    {
      material: "Caulk",
      type: "Solar Seal",
      color: "",
      quantity: "",
    },
    {
      material: "Trim Coil",
      type: "Standard",
      color: "",
      quantity: "",
    },
    {
      material: "EZ Plugs",
      type: "Standard",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Bathroom Fan Vent",
      type: '4" / 8" | With Hose',
      color: "",
      quantity: "",
    },
  ],
  "3 Star": [
    {
      material: "Shingles",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "Ridge Cap",
      type: "Shadow Ridge",
      color: "Match",
      quantity: "",
    },
    {
      material: "Drip Edge",
      type: "T / ",
      color: "",
      quantity: "",
    },
    {
      material: "Drip Edge",
      type: "C / ",
      color: "",
      quantity: "",
    },
    {
      material: "Ventilation",
      type: "CertainTeed Ridge Vent",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Ventilation",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "Underlayment",
      type: "Roof Runner",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Ice & Water",
      type: "Winterguard",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Pipe Boot",
      type: "1-3",
      color: "",
      quantity: "",
    },
    {
      material: "Pipe Boot",
      type: "3-4",
      color: "",
      quantity: "",
    },
    {
      material: "Zipper Boot",
      type: "Standard",
      color: "Black",
      quantity: "",
    },
    {
      material: "Starter Strips",
      type: "Swift Start",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Coil Nails",
      type: '1 1/4"',
      color: "N/A",
      quantity: "",
    },
    {
      material: "Coil Nails",
      type: '1 1/2"',
      color: "N/A",
      quantity: "",
    },
    {
      material: '3" Nails',
      type: "5lb Box",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Staples",
      type: "T-50",
      color: "N/A",
      quantity: "2",
    },
    {
      material: "Step Flashing",
      type: "Prebent 8 x 8",
      color: "",
      quantity: "",
    },
    {
      material: "Wood",
      type: "",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Wood",
      type: "",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Framing Nails",
      type: '2 3/8"',
      color: "N/A",
      quantity: "",
    },
    {
      material: "Caulk",
      type: "Solar Seal",
      color: "",
      quantity: "",
    },
    {
      material: "Trim Coil",
      type: "Standard",
      color: "",
      quantity: "",
    },
    {
      material: "EZ Plugs",
      type: "Standard",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Bathroom Fan Vent",
      type: '4" / 8" | With Hose',
      color: "N/A",
      quantity: "",
    },
  ],
  "4 Star": [
    {
      material: "Shingles",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "Ridge Cap",
      type: "Shadow Ridge",
      color: "Match",
      quantity: "",
    },
    {
      material: "Drip Edge",
      type: "T / ",
      color: "",
      quantity: "",
    },
    {
      material: "Drip Edge",
      type: "C / ",
      color: "",
      quantity: "",
    },
    {
      material: "Ventilation",
      type: "CertainTeed Ridge Vent",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Ventilation",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "Underlayment",
      type: "Roof Runner",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Ice & Water",
      type: "Wintergaurd",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Pipe Boot",
      type: "1-3",
      color: "",
      quantity: "",
    },
    {
      material: "Pipe Boot",
      type: "3-4",
      color: "",
      quantity: "",
    },
    {
      material: "Zipper Boot",
      type: "Standard",
      color: "Black",
      quantity: "",
    },
    {
      material: "Starter Strips",
      type: "Swift Start",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Coil Nails",
      type: '1 1/4"',
      color: "N/A",
      quantity: "",
    },
    {
      material: "Coil Nails",
      type: '1 1/2"',
      color: "N/A",
      quantity: "",
    },
    {
      material: '3" Nails',
      type: "5lb Box",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Staples",
      type: "T-50",
      color: "N/A",
      quantity: "2",
    },
    {
      material: "Step Flashing",
      type: "Prebent 8 x 8",
      color: "",
      quantity: "",
    },
    {
      material: "Wood",
      type: "",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Wood",
      type: "",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Framing Nails",
      type: '2 3/8"',
      color: "N/A",
      quantity: "",
    },
    {
      material: "Caulk",
      type: "Solar Seal",
      color: "",
      quantity: "",
    },
    {
      material: "Trim Coil",
      type: "Standard",
      color: "",
      quantity: "",
    },
    {
      material: "EZ Plugs",
      type: "Standard",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Bathroom Fan Vent",
      type: '4" / 8" | With Hose',
      color: "N/A",
      quantity: "",
    },
  ],
  "5 Star": [
    {
      material: "Shingles",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "Ridge Cap",
      type: "Shadow Ridge",
      color: "Match",
      quantity: "",
    },
    {
      material: "Drip Edge",
      type: "T / ",
      color: "",
      quantity: "",
    },
    {
      material: "Drip Edge",
      type: "C / ",
      color: "",
      quantity: "",
    },
    {
      material: "Ventilation",
      type: "CertainTeed Ridge Vent",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Ventilation",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "Underlayment",
      type: "Roof Runner",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Ice & Water",
      type: "Wintergaurd",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Pipe Boot",
      type: "1-3",
      color: "",
      quantity: "",
    },
    {
      material: "Pipe Boot",
      type: "3-4",
      color: "",
      quantity: "",
    },
    {
      material: "Zipper Boot",
      type: "Standard",
      color: "Black",
      quantity: "",
    },
    {
      material: "Starter Strips",
      type: "Swift Start",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Coil Nails",
      type: '1 1/4"',
      color: "N/A",
      quantity: "",
    },
    {
      material: "Coil Nails",
      type: '1 1/2"',
      color: "N/A",
      quantity: "",
    },
    {
      material: '3" Nails',
      type: "5lb Box",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Staples",
      type: "T-50",
      color: "N/A",
      quantity: "2",
    },
    {
      material: "Step Flashing",
      type: "Prebent 8 x 8",
      color: "",
      quantity: "",
    },
    {
      material: "Wood",
      type: "",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Wood",
      type: "",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Framing Nails",
      type: '2 3/8"',
      color: "N/A",
      quantity: "",
    },
    {
      material: "Caulk",
      type: "Solar Seal",
      color: "",
      quantity: "",
    },
    {
      material: "Trim Coil",
      type: "Standard",
      color: "",
      quantity: "",
    },
    {
      material: "EZ Plugs",
      type: "Standard",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Bathroom Fan Vent",
      type: '4" / 8" | With Hose',
      color: "N/A",
      quantity: "",
    },
  ],
  "Signature Select": [
    {
      material: "Shingles",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "Ridge Cap",
      type: "Pro Cut",
      color: "Match",
      quantity: "",
    },
    {
      material: "Drip Edge",
      type: "T / ",
      color: "",
      quantity: "",
    },
    {
      material: "Drip Edge",
      type: "C / ",
      color: "",
      quantity: "",
    },
    {
      material: "Ventilation",
      type: "Atlas",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Ventilation",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "Underlayment",
      type: "Summit 60",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Ice & Water",
      type: "Wintergaurd",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Pipe Boot",
      type: "1-3",
      color: "",
      quantity: "",
    },
    {
      material: "Pipe Boot",
      type: "3-4",
      color: "",
      quantity: "",
    },
    {
      material: "Zipper Boot",
      type: "Standard",
      color: "Black",
      quantity: "",
    },
    {
      material: "Starter Strips",
      type: "Pro Cut",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Coil Nails",
      type: '1 1/4"',
      color: "N/A",
      quantity: "",
    },
    {
      material: "Coil Nails",
      type: '1 1/2"',
      color: "N/A",
      quantity: "",
    },
    {
      material: '3" Nails',
      type: "5lb Box",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Staples",
      type: "T-50",
      color: "N/A",
      quantity: "2",
    },
    {
      material: "Step Flashing",
      type: "Prebent 8 x 8",
      color: "",
      quantity: "",
    },
    {
      material: "Wood",
      type: "",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Wood",
      type: "",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Framing Nails",
      type: '2 3/8"',
      color: "N/A",
      quantity: "",
    },
    {
      material: "Caulk",
      type: "Solar Seal",
      color: "",
      quantity: "",
    },
    {
      material: "Trim Coil",
      type: "Standard",
      color: "",
      quantity: "",
    },
    {
      material: "EZ Plugs",
      type: "Standard",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Bathroom Fan Vent",
      type: '4" / 8" | With Hose',
      color: "N/A",
      quantity: "",
    },
  ],
  "Pro 25": [
    {
      material: "Shingles",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "Ridge Cap",
      type: "Pro Cut",
      color: "Match",
      quantity: "",
    },
    {
      material: "Drip Edge",
      type: "T / ",
      color: "",
      quantity: "",
    },
    {
      material: "Drip Edge",
      type: "C / ",
      color: "",
      quantity: "",
    },
    {
      material: "Ventilation",
      type: "Atlas",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Ventilation",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "Underlayment",
      type: "Summit 60",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Ice & Water",
      type: "Atlas Weather Master",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Pipe Boot",
      type: "1-3",
      color: "",
      quantity: "",
    },
    {
      material: "Pipe Boot",
      type: "3-4",
      color: "",
      quantity: "",
    },
    {
      material: "Zipper Boot",
      type: "Standard",
      color: "Black",
      quantity: "",
    },
    {
      material: "Starter Strips",
      type: "Pro Cut",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Coil Nails",
      type: '1 1/4"',
      color: "N/A",
      quantity: "",
    },
    {
      material: "Coil Nails",
      type: '1 1/2"',
      color: "N/A",
      quantity: "",
    },
    {
      material: '3" Nails',
      type: "5lb Box",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Staples",
      type: "T-50",
      color: "N/A",
      quantity: "2",
    },
    {
      material: "Step Flashing",
      type: "Prebent 8 x 8",
      color: "",
      quantity: "",
    },
    {
      material: "Wood",
      type: "",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Wood",
      type: "",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Framing Nails",
      type: '2 3/8"',
      color: "N/A",
      quantity: "",
    },
    {
      material: "Caulk",
      type: "Solar Seal",
      color: "",
      quantity: "",
    },
    {
      material: "Trim Coil",
      type: "Standard",
      color: "",
      quantity: "",
    },
    {
      material: "EZ Plugs",
      type: "Standard",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Bathroom Fan Vent",
      type: '4" / 8" | With Hose',
      color: "N/A",
      quantity: "",
    },
  ],
  "Pro 50": [
    {
      material: "Shingles",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "Ridge Cap",
      type: "Pro Cut",
      color: "Match",
      quantity: "",
    },
    {
      material: "Drip Edge",
      type: "T / ",
      color: "",
      quantity: "",
    },
    {
      material: "Drip Edge",
      type: "C / ",
      color: "",
      quantity: "",
    },
    {
      material: "Ventilation",
      type: "Atlas",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Ventilation",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "Underlayment",
      type: "Summit 60",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Ice & Water",
      type: "Atlas Weather Master",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Pipe Boot",
      type: "1-3",
      color: "",
      quantity: "",
    },
    {
      material: "Pipe Boot",
      type: "3-4",
      color: "",
      quantity: "",
    },
    {
      material: "Zipper Boot",
      type: "Standard",
      color: "Black",
      quantity: "",
    },
    {
      material: "Starter Strips",
      type: "Pro Cut",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Coil Nails",
      type: '1 1/4"',
      color: "N/A",
      quantity: "",
    },
    {
      material: "Coil Nails",
      type: '1 1/2"',
      color: "N/A",
      quantity: "",
    },
    {
      material: '3" Nails',
      type: "5lb Box",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Staples",
      type: "T-50",
      color: "N/A",
      quantity: "2",
    },
    {
      material: "Step Flashing",
      type: "Prebent 8 x 8",
      color: "",
      quantity: "",
    },
    {
      material: "Wood",
      type: "",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Wood",
      type: "",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Framing Nails",
      type: '2 3/8"',
      color: "N/A",
      quantity: "",
    },
    {
      material: "Caulk",
      type: "Solar Seal",
      color: "",
      quantity: "",
    },
    {
      material: "Trim Coil",
      type: "Standard",
      color: "",
      quantity: "",
    },
    {
      material: "EZ Plugs",
      type: "Standard",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Bathroom Fan Vent",
      type: '4" / 8" | With Hose',
      color: "N/A",
      quantity: "",
    },
  ],
  "System Plus": [
    {
      material: "Shingles",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "Ridge Cap",
      type: "Sealaridge",
      color: "Match",
      quantity: "",
    },
    {
      material: "Drip Edge",
      type: "T / ",
      color: "",
      quantity: "",
    },
    {
      material: "Drip Edge",
      type: "C / ",
      color: "",
      quantity: "",
    },
    {
      material: "Ventilation",
      type: "Snow & Country",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Ventilation",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "Underlayment",
      type: "Felt Buster",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Ice & Water",
      type: "Weather Watch",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Pipe Boot",
      type: "1-3",
      color: "",
      quantity: "",
    },
    {
      material: "Pipe Boot",
      type: "3-4",
      color: "",
      quantity: "",
    },
    {
      material: "Zipper Boot",
      type: "Standard",
      color: "Black",
      quantity: "",
    },
    {
      material: "Starter Strips",
      type: "Pros Start",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Coil Nails",
      type: '1 1/4"',
      color: "N/A",
      quantity: "",
    },
    {
      material: "Coil Nails",
      type: '1 1/2"',
      color: "N/A",
      quantity: "",
    },
    {
      material: '3" Nails',
      type: "5lb Box",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Staples",
      type: "T-50",
      color: "N/A",
      quantity: "2",
    },
    {
      material: "Step Flashing",
      type: "Prebent 8 x 8",
      color: "",
      quantity: "",
    },
    {
      material: "Wood",
      type: "",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Wood",
      type: "",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Framing Nails",
      type: '2 3/8"',
      color: "N/A",
      quantity: "",
    },
    {
      material: "Caulk",
      type: "Solar Seal",
      color: "",
      quantity: "",
    },
    {
      material: "Trim Coil",
      type: "Standard",
      color: "",
      quantity: "",
    },
    {
      material: "EZ Plugs",
      type: "Standard",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Bathroom Fan Vent",
      type: '4" / 8" | With Hose',
      color: "N/A",
      quantity: "",
    },
  ],
  "Silver Pledge": [
    {
      material: "Shingles",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "Ridge Cap",
      type: "Sealaridge",
      color: "Match",
      quantity: "",
    },
    {
      material: "Drip Edge",
      type: "T / ",
      color: "",
      quantity: "",
    },
    {
      material: "Drip Edge",
      type: "C / ",
      color: "",
      quantity: "",
    },
    {
      material: "Ventilation",
      type: "Snow & Country",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Ventilation",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "Underlayment",
      type: "Felt Buster",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Ice & Water",
      type: "Weather Watch",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Pipe Boot",
      type: "1-3",
      color: "",
      quantity: "",
    },
    {
      material: "Pipe Boot",
      type: "3-4",
      color: "",
      quantity: "",
    },
    {
      material: "Zipper Boot",
      type: "Standard",
      color: "Black",
      quantity: "",
    },
    {
      material: "Starter Strips",
      type: "Pros Start",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Coil Nails",
      type: '1 1/4"',
      color: "N/A",
      quantity: "",
    },
    {
      material: "Coil Nails",
      type: '1 1/2"',
      color: "N/A",
      quantity: "",
    },
    {
      material: '3" Nails',
      type: "5lb Box",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Staples",
      type: "T-50",
      color: "N/A",
      quantity: "2",
    },
    {
      material: "Step Flashing",
      type: "Prebent 8 x 8",
      color: "",
      quantity: "",
    },
    {
      material: "Wood",
      type: "",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Wood",
      type: "",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Framing Nails",
      type: '2 3/8"',
      color: "N/A",
      quantity: "",
    },
    {
      material: "Caulk",
      type: "Solar Seal",
      color: "",
      quantity: "",
    },
    {
      material: "Trim Coil",
      type: "Standard",
      color: "",
      quantity: "",
    },
    {
      material: "EZ Plugs",
      type: "Standard",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Bathroom Fan Vent",
      type: '4" / 8" | With Hose',
      color: "N/A",
      quantity: "",
    },
  ],
  "Golden Pledge": [
    {
      material: "Shingles",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "Ridge Cap",
      type: "Sealaridge",
      color: "Match",
      quantity: "",
    },
    {
      material: "Drip Edge",
      type: "T / ",
      color: "",
      quantity: "",
    },
    {
      material: "Drip Edge",
      type: "C / ",
      color: "",
      quantity: "",
    },
    {
      material: "Ventilation",
      type: "Snow & Country",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Ventilation",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "Underlayment",
      type: "Felt Buster",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Ice & Water",
      type: "Weather Watch",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Pipe Boot",
      type: "1-3",
      color: "",
      quantity: "",
    },
    {
      material: "Pipe Boot",
      type: "3-4",
      color: "",
      quantity: "",
    },
    {
      material: "Zipper Boot",
      type: "Standard",
      color: "Black",
      quantity: "",
    },
    {
      material: "Starter Strips",
      type: "Pros Start",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Coil Nails",
      type: '1 1/4"',
      color: "N/A",
      quantity: "",
    },
    {
      material: "Coil Nails",
      type: '1 1/2"',
      color: "N/A",
      quantity: "",
    },
    {
      material: '3" Nails',
      type: "5lb Box",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Staples",
      type: "T-50",
      color: "N/A",
      quantity: "2",
    },
    {
      material: "Step Flashing",
      type: "Prebent 8 x 8",
      color: "",
      quantity: "",
    },
    {
      material: "Wood",
      type: "",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Wood",
      type: "",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Framing Nails",
      type: '2 3/8"',
      color: "N/A",
      quantity: "",
    },
    {
      material: "Caulk",
      type: "Solar Seal",
      color: "",
      quantity: "",
    },
    {
      material: "Trim Coil",
      type: "Standard",
      color: "",
      quantity: "",
    },
    {
      material: "EZ Plugs",
      type: "Standard",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Bathroom Fan Vent",
      type: '4" / 8" | With Hose',
      color: "N/A",
      quantity: "",
    },
  ],
  EPDM: [
    {
      material: "EPDM",
      type: ".060",
      color: "",
      quantity: "",
    },
    {
      material: "Bonding Adhesive",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "Tape Primer",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "In-Seam Tape",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "Cured Cover Tap",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "Flashing Tape",
      type: "Uncured",
      color: "",
      quantity: "",
    },
    {
      material: "R.M.S. Strip",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "Lap Caulk",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "Water Cut Off",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "Termination Bar",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "HDFB",
      type: "Size: ",
      color: "",
      quantity: "",
    },
    {
      material: "Poly ISO",
      type: "HD Cover Board / Size: ",
      color: "",
      quantity: "",
    },
    {
      material: "Poly ISO",
      type: "Insulation Board / Size: ",
      color: "",
      quantity: "",
    },
    {
      material: "Screws",
      type: "Size: ",
      color: "",
      quantity: "",
    },
    {
      material: "Plates",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "Drip Edge",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "Spray Primer",
      type: "Mulehide Seal Fast",
      color: "",
      quantity: "",
    },
  ],
  Flintlastic: [
    {
      material: "Base Sheet",
      type: "Flintlastic SA",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Mid Ply",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "Cap Sheet",
      type: "Flintlastic SA",
      color: "",
      quantity: "",
    },
    {
      material: "Spray Primer",
      type: "N/A",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Screw Plates",
      type: "N/A",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Loose Granuales",
      type: "N/A",
      color: "",
      quantity: "",
    },
    {
      material: "Wood",
      type: "",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Caulk",
      type: "Flintbond",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Drip Edge",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "Screws",
      type: "",
      color: "N/A",
      quantity: "",
    },
    {
      material: "ISO Board",
      type: "",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Fiber Board",
      type: "",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Spray Primer",
      type: "Mulehide Seal Fast",
      color: "",
      quantity: "",
    },
  ],
  Siding: [
    {
      material: "D4 Siding",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "Shakes",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "B & B",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "J-Channel",
      type: "Vinyl",
      color: "",
      quantity: "",
    },
    {
      material: "J-Channel",
      type: "Vinyl",
      color: "",
      quantity: "",
    },
    {
      material: "Finish Trim",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "Starter Strip",
      type: "Aluminum",
      color: "",
      quantity: "",
    },
    {
      material: "ISCP",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "OSCP",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "J-Block",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "Split Block",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "Soffit Vented",
      type: "Aluminum",
      color: "",
      quantity: "",
    },
    {
      material: "Soffit Solid",
      type: "Aluminum",
      color: "",
      quantity: "",
    },
    {
      material: "J-Channel",
      type: "Aluminum",
      color: "",
      quantity: "",
    },
    {
      material: "F-Channel",
      type: "Aluminum",
      color: "",
      quantity: "",
    },
    {
      material: "Trim Nails",
      type: "Standard",
      color: "",
      quantity: "",
    },
    {
      material: "Trim Coil",
      type: "Roll",
      color: "",
      quantity: "",
    },
    {
      material: "Fascia",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "Dryer Vent",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "Intake Vent",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "Exhaust Vent",
      type: '4"',
      color: "",
      quantity: "",
    },
    {
      material: "Exhaust Vent",
      type: '6"',
      color: "",
      quantity: "",
    },
    {
      material: "Smart Trim",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "Smart Trim",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "Smart Trim",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "PVC Trim",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "PVC Trim",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "PVC Trim",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "Caulk",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "House Wrap",
      type: "",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Wrap Tape",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "Siding Nails",
      type: "",
      color: "N/A",
      quantity: "",
    },
  ],
  Windows: [
    {
      material: "Trim Coil",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "Nails",
      type: "",
      color: "N/A",
      quantity: "",
    },
    {
      material: "Caulk",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "Interior Trim",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "Screws",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "Window Tape",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "Roll Insulation",
      type: "",
      color: "",
      quantity: "",
    },
    {
      material: "Expandable Foam",
      type: "",
      color: "",
      quantity: "",
    },
  ],
};

export default templates;
