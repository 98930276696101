import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { openForm } from "Actions/formActions";
import FormContainer from "Forms/FormContainer";
import FormBody from "Components/FormBody";
import styles from "./styles.module.scss";
import dayjs from "dayjs";
import Button from "antd/es/button";
import message from "antd/es/message";
import Badge from "antd/es/badge";
import List from "antd/es/list";
import Menu from "antd/es/menu";
import Dropdown from "antd/es/dropdown";
import FullScreen from "Icons/FullScreen";
import FullScreenExit from "Icons/FullScreenExit";
import Sortable from "sortablejs";
import Grid from "antd/es/grid";
import Modal from "antd/es/modal";

import Avatar from "antd/es/avatar/avatar";
import ViewColumn from "Icons/ViewColumn";
import Tooltip from "antd/es/tooltip";
import { unstable_batchedUpdates } from "react-dom";
import api from "API";
import usePrevious from "Hooks/usePrevious";
import MoreVert from "Icons/MoreVert";
import MaterialBoardColumnName from "Forms/MaterialBoardColumnName";
import ReadyForMaterial from "Forms/ReadyForMaterial";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";
import LeadTimes from "Forms/LeadTimes";
import OrderStamp from "Assets/order-stamp.png";
import Loader from "Components/Loader";
import Chat from "Icons/Chat";
import CheckCircleIcon from "Icons/CheckCircle";
import CheckCircleEmptyIcon from "Icons/CheckCircleEmpty";

import ViewProject from "Screens/ViewProject";
import ViewContainer from "Components/ViewContainer";
import useViewShow from "Hooks/useViewShow";

let scopesToUpdate = [];
let columnsToUpdate = [];
let columnsUpdating = [];
let columnChange = 0;
let _item;
let _from;
let _oldIndex;

const { useBreakpoint } = Grid;

const MaterialBoard = () => {
  const dispatch = useDispatch();
  const materialBoardAddColumn = useSelector(
    (state) => state.formState.materialBoardAddColumn.open
  );
  const readyForMaterial = useSelector(
    (state) => state.formState.readyForMaterial.open
  );
  const leadTimes = useSelector((state) => state.formState.leadTimes.open);
  const [state, setState] = useState([]);
  const [days, setDays] = useState({});
  const [onDeck, setOnDeck] = useState([]);
  const [columns, setColumns] = useState({});
  const prevColumns = usePrevious(columns);
  const [tagCount, setTagCount] = useState(0);
  const [daysTagCount, setDaysTagCount] = useState(0);
  const [columnCount, setColumnCount] = useState(0);
  const [init, setInit] = useState(false);
  const prevInit = usePrevious(init);
  const [zoom, setZoom] = useState(false);
  const prevZoom = usePrevious(zoom);
  const screens = useBreakpoint();
  const [loading, setLoading] = useState(true);

  const showProject = useViewShow(3, "projects", 4, "view");

  const ref = useRef();

  useEffect(() => {
    fetchTags();

    return () => {
      scopesToUpdate = [];
    };
  }, []);

  const fetchTags = async () => {
    try {
      setLoading(true);
      const res = await api.post("/ready-for-material/fetchTags", {});

      unstable_batchedUpdates(() => {
        setState(res.data.state);
        setDays(res.data.days);
        setOnDeck(res.data.onDeck);
        setColumns(res.data.columns);
        setTagCount(res.data._tagCount);
        setDaysTagCount(res.data._daysTagCount);
        setColumnCount(res.data._columnCount);
        setInit(true);
        setLoading(false);
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const updateColumn = async () => {
    try {
      setLoading(true);
      const res = await api.post("/ready-for-material/updateTags", {
        array: scopesToUpdate,
      });

      if (_from && _item)
        _from.insertBefore(_item, _from.childNodes[_oldIndex]);

      scopesToUpdate = [];
      columnsUpdating = [];
      columnChange = 0;
      _item = undefined;
      _from = undefined;

      unstable_batchedUpdates(() => {
        setState(res.data.state);
        setDays(res.data.days);
        setOnDeck(res.data.onDeck);
        setColumns(res.data.columns);
        setTagCount(res.data._tagCount);
        setDaysTagCount(res.data._daysTagCount);
        setColumnCount(res.data._columnCount);
        setLoading(false);
      });
    } catch (err) {
      console.log("err", err);
      message.error("Error saving schedule");
    }
  };

  const updateColumnOrder = async () => {
    try {
      setLoading(true);
      const res = await api.post("/ready-for-material/updateColumns", {
        array: columnsToUpdate,
      });

      columnsToUpdate = [];

      setColumns(res.data.columns);
      setLoading(false);
    } catch (err) {
      console.log("err", err);
      message.error("Error saving column order");
    }
  };

  const addColumn = async () => {
    try {
      if (columnChange === 0) {
        columnChange++;
      } else {
        updateColumn();
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const removeColumn = async () => {
    try {
      if (columnChange === 0) {
        columnChange++;
      } else {
        updateColumn();
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    if (
      (!prevInit && init) ||
      (prevZoom !== undefined && prevZoom !== zoom) ||
      prevColumns !== columns
    ) {
      Object.entries(days).map(([key, value]) => {
        const title = `_${key.toLowerCase().replace(" ", "")}`;
        const el = document.getElementById(title);
        if (Sortable.get(el)) Sortable.get(el).destroy();
        Sortable.create(el, {
          group: "shared",
          animation: 150,
          ghostClass: "placeholder",
          direction: "vertical",
          delay: 300,
          onAdd: (e) => {
            handleSortableAdd(e);
          },
          onRemove: (e) => {
            handleSortableRemove(e);
          },
          onUpdate: (e) => {
            handleSortableUpdate(e);
          },
          onChange: (e) => {
            handleSortableChange(e, el);
          },
        });
        return value;
      });

      let el = document.getElementById(`_onDeck`);
      if (Sortable.get(el)) Sortable.get(el).destroy();
      Sortable.create(el, {
        group: "shared",
        animation: 150,
        ghostClass: "placeholder",
        direction: "horizontal",
        delay: 300,
        onAdd: (e) => {
          handleSortableAdd(e);
        },
        onRemove: (e) => {
          handleSortableRemove(e);
        },
        onUpdate: (e) => {
          handleSortableUpdate(e);
        },
        onChange: (e) => {
          handleSortableChange(e, el);
        },
      });

      Object.entries(columns).map(([key, value]) => {
        const title = `_${key.toLowerCase().replace(" ", "")}`;
        const el = document.getElementById(title);
        if (Sortable.get(el)) Sortable.get(el).destroy();
        Sortable.create(el, {
          group: "shared",
          animation: 150,
          ghostClass: "placeholder",
          direction: "vertical",
          delay: 300,
          onAdd: (e) => {
            handleSortableAdd(e);
          },
          onRemove: (e) => {
            handleSortableRemove(e);
          },
          onUpdate: (e) => {
            handleSortableUpdate(e);
          },
          onChange: (e) => {
            handleSortableChange(e, el);
          },
        });
        return value;
      });

      el = document.getElementById(`bottom-row`);
      if (Sortable.get(el)) Sortable.get(el).destroy();
      Sortable.create(el, {
        animation: 150,
        direction: "horizontal",
        delay: 300,
        handle: ".column-header",
        onUpdate: (e) => {
          const { childNodes } = e.target;
          for (let i = 0; i < childNodes.length; i++) {
            let _thisObj = {
              title: childNodes[i].dataset.title,
              index: i,
            };
            columnsToUpdate.push(_thisObj);
          }
          updateColumnOrder();
        },
      });
    }
  }, [prevInit, init, zoom, columns]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSortableAdd = (e) => {
    const { childNodes } = e.target;
    const _thisState = e.target.dataset.state;
    const _thisColumn = e.target.dataset.column;
    const _columnId = e.target.id;
    let _stateClone = JSON.parse(JSON.stringify(state));
    for (let i = 0; i < childNodes.length; i++) {
      const _thisId = childNodes[i].dataset._id;
      let _thisObj = _stateClone.filter((d) => d._id === _thisId)[0];
      _thisObj.index = i;
      _thisObj.column = _thisColumn;
      _thisObj.state = _thisState;
      scopesToUpdate.push(_thisObj);
    }
    _item = e.item;
    _from = e.from;
    _oldIndex = e.oldIndex;
    columnsUpdating.push(_columnId);
    addColumn();
  };

  const handleSortableRemove = (e) => {
    const { childNodes } = e.target;
    const _thisState = e.target.dataset.state;
    const _thisColumn = e.target.dataset.column;
    const _columnId = e.target.id;
    let _stateClone = JSON.parse(JSON.stringify(state));
    for (let i = 0; i < childNodes.length; i++) {
      const _thisId = childNodes[i].dataset._id;
      let _thisObj = _stateClone.filter((d) => d._id === _thisId)[0];
      _thisObj.index = i;
      _thisObj.column = _thisColumn;
      _thisObj.state = _thisState;
      scopesToUpdate.push(_thisObj);
    }
    columnsUpdating.push(_columnId);
    removeColumn();
  };

  const handleSortableUpdate = (e) => {
    const { childNodes } = e.target;
    const _thisState = e.target.dataset.state;
    const _thisColumn = e.target.dataset.column;
    const _columnId = e.target.id;
    let _stateClone = JSON.parse(JSON.stringify(state));
    for (let i = 0; i < childNodes.length; i++) {
      const _thisId = childNodes[i].dataset._id;
      let _thisObj = _stateClone.filter((d) => d._id === _thisId)[0];
      _thisObj.index = i;
      _thisObj.column = _thisColumn;
      _thisObj.state = _thisState;
      scopesToUpdate.push(_thisObj);
    }
    columnsUpdating.push(_columnId);
    updateColumn();
  };

  const handleSortableChange = (e, el) => {
    const _tag = e.item;
    const _toState = e.to.dataset.state;

    if (zoom) {
      if (_toState === "days") {
        _tag.classList.remove("onDeckTag");
        _tag.classList.remove("bottomRowTag");
        _tag.classList.add("topRowTag");

        _tag.style.height = `calc(${100 / tagCount}% - 4px)`;
        _tag.style.width = "100%";
        _tag.style.display = "block";
      } else if (_toState === "onDeck") {
        _tag.classList.remove("topRowTag");
        _tag.classList.remove("bottomRowTag");
        _tag.classList.add("onDeckTag");

        _tag.style.width = `calc(${100 / onDeck.length}% - 4px)`;
        _tag.style.height = "76px";
        _tag.style.display = "inline-block";
      } else {
        _tag.classList.remove("onDeckTag");
        _tag.classList.remove("topRowTag");
        _tag.classList.add("bottomRowTag");

        _tag.style.height = `calc(${100 / tagCount}% - 4px)`;
        _tag.style.width = "100%";
        _tag.style.display = "block";
      }
    }
  };

  const onZoom = () => {
    ref.current.scrollLeft = 0;
    setZoom(!zoom);
  };

  const updateAll = useCallback(
    (data) => {
      unstable_batchedUpdates(() => {
        setState(data.state);
        setDays(data.days);
        setOnDeck(data.onDeck);
        setColumns(data.columns);
        setTagCount(data._tagCount);
        setDaysTagCount(data._daysTagCount);
        setColumnCount(data._columnCount);
        setLoading(false);
      });
    },
    [
      setState,
      setDays,
      setOnDeck,
      setColumns,
      setColumnCount,
      setTagCount,
      setDaysTagCount,
      setLoading,
    ]
  );

  const handleAddColumn = useCallback(() => {
    const titles = [];
    Object.entries(columns).map(([key]) => {
      titles.push(key);
      return key;
    });

    dispatch(
      openForm("materialBoardAddColumn", {
        titles,
        callback: updateAll,
      })
    );
  }, [columns, dispatch, updateAll]);

  const deleteColumn = async (title, cb) => {
    try {
      setLoading(true);
      const res = await api.delete(
        `/ready-for-material/delete-column/${title}`
      );
      updateAll(res.data);
      cb();
    } catch (err) {
      console.log("err", err);
      message.error("Error deleting invoice");
    }
  };

  const openSetLeadTimes = useCallback(() => {
    let count = 0;
    const categories1 = [];
    let categories2 = [];
    Object.entries(columns).map(([key]) => {
      count++;
      let obj = {};
      obj.title = key;
      obj.description = "";

      if (count < 6) {
        categories1.push(obj);
      } else {
        categories2.push(obj);
      }
      return key;
    });

    if (categories2.length === 0) categories2 = undefined;

    dispatch(
      openForm("leadTimes", {
        categories1,
        categories2,
      })
    );
  }, [columns, dispatch]);

  const getCutOff = async () => {
    try {
      await api.get("/ready-for-material/clear-show-ready-for-material");
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <FormBody className={`${zoom ? styles.zoomContainer : ""} content-inner`}>
      <div
        style={
          loading
            ? {
                position: "fixed",
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                width: "100%",
                height: "100%",
                background: "rgba(0,0,0,0.23)",
                zIndex: 100,
              }
            : { display: "none" }
        }
      >
        <Loader minHeight="100%" />
      </div>
      <div className={`${styles.topRow} ${zoom ? styles.zoomRow : ""}`}>
        {Object.entries(days).map(([key, value]) => (
          <div
            key={key}
            className={styles.column}
            style={zoom ? { width: `${100 / 6}%` } : {}}
          >
            <div className={styles.header}>
              <List bordered={false}>
                <List.Item>
                  <List.Item.Meta title={key} onClick={getCutOff} />
                  <List.Item.Meta
                    style={{ textAlign: "right" }}
                    title={<Badge count={value.length} showZero />}
                  />
                </List.Item>
              </List>
            </div>
            <div
              id={`_${key.toLowerCase().replace(" ", "")}`}
              data-column={key}
              data-state="days"
              className={`${styles.content} ${zoom ? styles.zoomColumn : ""}`}
            >
              {value.map((tag) => (
                <Tag
                  key={tag._id}
                  _id={tag._id}
                  tag={tag}
                  zoom={zoom}
                  tagCount={daysTagCount}
                  topRow
                  column={key}
                  state="days"
                  updateAll={updateAll}
                  fetchTags={fetchTags}
                />
              ))}
            </div>
          </div>
        ))}
      </div>

      <div
        id="_onDeck"
        data-column="onDeck"
        data-state="onDeck"
        className={`${styles.onDeck} ${zoom ? styles.zoomRow : ""}`}
      >
        {onDeck.map((tag) => (
          <Tag
            key={tag._id}
            _id={tag._id}
            tag={tag}
            zoom={zoom}
            onDeck={onDeck}
            column="onDeck"
            state="onDeck"
            updateAll={updateAll}
            fetchTags={fetchTags}
          />
        ))}
      </div>

      <div
        ref={ref}
        id="bottom-row"
        className={`${styles.bottomRow} ${zoom ? styles.zoomRow : ""}`}
      >
        {Object.entries(columns).map(([key, value]) => {
          const handleRenameColumn = () => {
            const titles = [];
            Object.entries(columns).map(([key]) => {
              titles.push(key);
              return key;
            });

            dispatch(
              openForm("materialBoardAddColumn", {
                editing: true,
                title: key,
                titles,
                callback: updateAll,
              })
            );
          };

          const confirmDelete = () => {
            Modal.confirm({
              zIndex: 4002,
              icon: <QuestionCircleOutlined />,
              centered: true,
              cancelText: "Cancel",
              okText: "Delete",
              okButtonProps: { danger: true },
              content: <div>Are you sure you want to delete this column?</div>,
              onOk() {
                return new Promise((resolve, reject) => {
                  deleteColumn(key, () => resolve(true));
                }).catch((err) => console.log("err: ", err));
              },
              onCancel() {},
            });
          };

          return (
            <div
              key={key}
              data-title={key}
              className={styles.column}
              style={zoom ? { width: `${100 / columnCount}%` } : {}}
            >
              <div className={`column-header ${styles.header}`}>
                <List bordered={false}>
                  <List.Item>
                    <List.Item.Meta
                      title={zoom ? <Tooltip title={key}>{key}</Tooltip> : key}
                    />
                    <List.Item.Meta
                      style={{ textAlign: "right" }}
                      title={
                        <>
                          <Badge count={value.length} showZero />
                          <Dropdown
                            trigger={["click"]}
                            getPopupContainer={(trigger) =>
                              trigger.parentNode.parentNode.parentNode
                                .parentNode.parentNode.parentNode.parentNode
                                .parentNode.parentNode
                            }
                            overlay={
                              <Menu>
                                <Menu.Item onClick={openSetLeadTimes}>
                                  Set Lead Times
                                </Menu.Item>
                                {/* TODO: difficulty overhaul */}
                                {key !== "4 Rating" &&
                                  key !== "3 Rating" &&
                                  key !== "2 Rating" &&
                                  key !== "NC" &&
                                  key !== "Siding" && (
                                    <Menu.Item onClick={handleRenameColumn}>
                                      Rename Column
                                    </Menu.Item>
                                  )}
                                {/* TODO: difficulty overhaul */}
                                {key !== "4 Rating" &&
                                  key !== "3 Rating" &&
                                  key !== "2 Rating" &&
                                  key !== "NC" &&
                                  key !== "Siding" && (
                                    <Menu.Item
                                      disabled={value.length !== 0}
                                      danger={value.length === 0}
                                      onClick={confirmDelete}
                                    >
                                      Delete Column
                                    </Menu.Item>
                                  )}
                              </Menu>
                            }
                          >
                            <Button
                              style={{ marginLeft: 8 }}
                              size="small"
                              type="primary"
                              shape="circle"
                              className={styles.columnOptions}
                            >
                              <MoreVert />
                            </Button>
                          </Dropdown>
                        </>
                      }
                    />
                  </List.Item>
                </List>
              </div>
              <div
                id={`_${key.toLowerCase().replace(" ", "")}`}
                data-column={key}
                data-state="columns"
                className={`${styles.content} ${zoom ? styles.zoomColumn : ""}`}
              >
                {value.map((tag) => (
                  <Tag
                    key={tag._id}
                    _id={tag._id}
                    tag={tag}
                    zoom={zoom}
                    tagCount={tagCount}
                    bottomRow
                    column={key}
                    state="columns"
                    updateAll={updateAll}
                    fetchTags={fetchTags}
                  />
                ))}
              </div>
            </div>
          );
        })}

        <div
          className={styles.column}
          style={zoom ? { width: `${100 / columnCount}%` } : {}}
        >
          <div className={styles.addColumnContent}>
            <Avatar size="large">
              <ViewColumn size={48} />
            </Avatar>
            <Button type="primary" className="green" onClick={handleAddColumn}>
              Add Column
            </Button>
          </div>
        </div>
      </div>

      <div className={styles.zoomFab}>
        <Button
          onClick={onZoom}
          type="primary"
          shape="circle"
          className={styles.fab}
        >
          <FullScreen
            className={`${styles.fullScreen} ${zoom ? styles.zoomed : ""}`}
          />
          <FullScreenExit
            className={`${styles.fullScreenExit} ${zoom ? styles.zoomed : ""}`}
          />
        </Button>
      </div>

      <ViewContainer
        type="drawer"
        visible={showProject}
        form={ViewProject}
        placement="right"
        width={400}
        closable={false}
        mask={false}
      />

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0 },
        }}
        type={!screens.md ? "drawer" : "modal"}
        width={!screens.md ? "100%" : 320}
        title="Add Column"
        visible={materialBoardAddColumn}
        form={MaterialBoardColumnName}
        formType="materialBoardAddColumn"
      />

      <FormContainer
        centered={true}
        modalStyle={{
          body: {
            padding: 0,
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          },
          width: 400,
          maxHeight: "calc(100vh - 100px",
          main: { paddingBottom: 0 },
        }}
        type={!screens.md ? "drawer" : "modal"}
        width={!screens.md ? "100%" : 320}
        title="Set Lead Times"
        visible={leadTimes}
        form={LeadTimes}
        formType="leadTimes"
      />

      <FormContainer
        type={!screens.md ? "drawer" : "modal"}
        width={!screens.md ? "100%" : 320}
        title="Ready For Material"
        visible={readyForMaterial}
        form={ReadyForMaterial}
        formType="readyForMaterial"
      />
    </FormBody>
  );
};

const Tag = ({
  tag,
  zoom,
  tagCount,
  onDeck,
  topRow,
  bottomRow,
  _id,
  column,
  state,
  updateAll,
  fetchTags,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClick = useCallback(() => {
    tag.callback = updateAll;
    dispatch(openForm("readyForMaterial", tag));
  }, [dispatch, tag, updateAll]);

  const openNote = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(
        openForm("officeReviewNote", {
          _scopeId: tag._id,
          note: tag.officeReviewNote,
          customerId: tag.customerId,
          callback: fetchTags,
        })
      );
    },
    [dispatch, tag, fetchTags]
  );

  const openTray = (e) => {
    e.stopPropagation();
    navigate(
      `/workflow/ready-for-material/projects/view/posts/${tag._projectId}`
    );
  };

  return (
    <div
      data-_id={_id}
      data-column={column}
      data-state={state}
      // TODO: difficultyRating overhaul
      className={`${styles.tag} ${
        tag.workOrder.schedule === "Urgent"
          ? styles.urgent
          : tag.workOrder.propertyType === "New Construction"
          ? styles.dr1
          : tag.workOrder.difficultyRating === 2 ||
            tag.workOrder.difficultyRating === 2.5
          ? styles.dr2
          : tag.workOrder.difficultyRating === 3 ||
            tag.workOrder.difficultyRating === 3.5
          ? styles.dr3
          : styles.dr4
      } ${zoom ? styles.zoom : ""} ${
        onDeck ? "onDeckTag" : topRow ? "topRowTag" : "bottomRowTag"
      }`}
      style={
        zoom && !onDeck
          ? { height: `calc(${100 / tagCount}% - 4px)` }
          : zoom && onDeck
          ? { width: `calc(${100 / onDeck.length}% - 4px)` }
          : {}
      }
      onClick={topRow ? handleClick : openTray}
    >
      {tag.materialOrdered ? (
        <img src={OrderStamp} alt="order-stamp" className={styles.orderStamp} />
      ) : null}
      <div className={styles.topRowZoom}>
        <Tooltip
          title={
            <div className={styles.regularRowContent}>
              <div className={styles.row}>
                <div className={styles.col1}>
                  <strong>
                    {/* TODO: difficultyRating overhaul */}
                    {tag.workOrder.customerName} -
                    {` (${tag.workOrder.difficultyRating}) `}
                  </strong>
                </div>
                <div className={styles.col2}>
                  {tag.workOrder.customerId} |{" "}
                  {dayjs(tag.workOrder.dateSold).format("MMM D")}
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.col1}>
                  {`${tag.workOrder.squares} sq. | ${
                    tag.name !== "Siding"
                      ? tag.workOrder.roofType
                        ? " | " + tag.workOrder.roofType.join(", ")
                        : " | " + tag.workOrder.installationMethod
                      : ""
                  } - [
                    ${
                      tag.workOrder.siding
                        ? tag.workOrder.siding[1]
                        : tag.workOrder.shingle
                        ? tag.workOrder.shingle[1]
                        : tag.workOrder.productLine
                    } | 
                    ${
                      tag.workOrder.siding
                        ? tag.workOrder.siding[2]
                        : tag.workOrder.shingle
                        ? tag.workOrder.shingle[2]
                        : tag.workOrder.color
                    }]`}
                </div>
                <div className={styles.col2} style={{ fontWeight: "bold" }}>
                  {tag.supplier
                    ? tag.supplier.substring(0, 3).toUpperCase()
                    : "N/A"}
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.col1}>
                  {`${tag.workOrder.projectAddressStreet}, ${tag.workOrder.projectAddressCity}`}
                </div>
                <div className={styles.col2}>
                  {tag.workOrder.projectManagerName}
                </div>
              </div>
            </div>
          }
        >
          <div className={styles.topRowContent}>
            <div className={styles.row}>
              <div className={styles.col1}>{tag.workOrder.customerName}</div>
              <div className={styles.col2}>{tag.workOrder.customerId}</div>
            </div>
          </div>
        </Tooltip>
      </div>

      <div className={styles.bottomRowZoom}>
        <Tooltip
          title={
            <div className={styles.regularRowContent}>
              <div className={styles.row}>
                <div className={styles.col1}>
                  <strong>
                    <Button
                      style={{ marginRight: 4 }}
                      size="small"
                      shape="circle"
                      type={
                        tag.officeReviewNote && tag.officeReviewNote.length > 0
                          ? "primary"
                          : "default"
                      }
                      onClick={openNote}
                    >
                      <Chat size={16} />
                    </Button>
                    {tag.preJobInspectionComplete ? (
                      <Button
                        size="small"
                        shape="circle"
                        type="primary"
                        style={{ background: "#ffffff" }}
                        className={styles.centerIcon}
                      >
                        <CheckCircleIcon color="#00c292" size={18} />
                      </Button>
                    ) : (
                      <Button
                        size="small"
                        shape="circle"
                        type="dafault"
                        className={styles.centerIcon}
                      >
                        <CheckCircleEmptyIcon size={18} />
                      </Button>
                    )}
                    {/* TODO: difficultyRating overhaul */}
                    {tag.workOrder.customerName}
                    {tag.workOrder.difficultyRating
                      ? ` - (${tag.workOrder.difficultyRating})`
                      : ""}
                  </strong>
                </div>
                <div className={styles.col2}>
                  {tag.workOrder.customerId} |{" "}
                  {dayjs(tag.workOrder.dateSold).format("MMM D")}
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.col1}>
                  {`${tag.workOrder.squares} sq. | ${
                    tag.name !== "Siding"
                      ? tag.workOrder.roofType
                        ? " | " + tag.workOrder.roofType.join(", ")
                        : " | " + tag.workOrder.installationMethod
                      : ""
                  } - [
                    ${
                      tag.workOrder.siding
                        ? tag.workOrder.siding[1]
                        : tag.workOrder.shingle
                        ? tag.workOrder.shingle[1]
                        : tag.workOrder.productLine
                    } | 
                    ${
                      tag.workOrder.siding
                        ? tag.workOrder.siding[2]
                        : tag.workOrder.shingle
                        ? tag.workOrder.shingle[2]
                        : tag.workOrder.color
                    }]`}
                </div>
                <div className={styles.col2} style={{ fontWeight: "bold" }}>
                  {tag.supplier
                    ? tag.supplier.substring(0, 3).toUpperCase()
                    : "N/A"}
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.col1}>
                  {`${tag.workOrder.projectAddressStreet}, ${tag.workOrder.projectAddressCity}`}
                </div>
                <div className={styles.col2}>
                  {tag.workOrder.projectManagerName}
                </div>
              </div>
            </div>
          }
        >
          <div className={styles.bottomRowContent}>
            <div className={styles.row}>
              <div className={styles.col1}>{`${
                tag.workOrder.squares
              } sq. | ${dayjs(tag.workOrder.dateSold).format("M/D")} | ${
                tag.workOrder.shingle
                  ? tag.workOrder.shingle[2]
                  : tag.workOrder.color
              }`}</div>
            </div>
          </div>
        </Tooltip>
      </div>

      <div className={styles.regularRow}>
        <div className={styles.regularRowContent}>
          <div className={styles.row}>
            <div className={styles.col1}>
              <strong>
                <Button
                  style={{ marginRight: 4 }}
                  size="small"
                  shape="circle"
                  type={
                    tag.officeReviewNote && tag.officeReviewNote.length > 0
                      ? "primary"
                      : "default"
                  }
                  onClick={openNote}
                >
                  <Chat size={16} />
                </Button>
                {tag.preJobInspectionComplete ? (
                  <Button
                    size="small"
                    shape="circle"
                    type="primary"
                    style={{ background: "#ffffff" }}
                    className={styles.centerIcon}
                  >
                    <CheckCircleIcon color="#00c292" size={18} />
                  </Button>
                ) : (
                  <Button
                    size="small"
                    shape="circle"
                    type="dafault"
                    className={styles.centerIcon}
                  >
                    <CheckCircleEmptyIcon size={18} />
                  </Button>
                )}
                {/* TODO: difficultyRating overhaul */}
                {tag.workOrder.customerName}
                {tag.workOrder.difficultyRating
                  ? ` - (${tag.workOrder.difficultyRating})`
                  : ""}
              </strong>
            </div>
            <div className={styles.col2}>
              {tag.workOrder.customerId} |{" "}
              {dayjs(tag.workOrder.dateSold).format("MMM D")}
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.col1}>
              {`${tag.workOrder.squares} sq.${
                tag.name !== "Siding"
                  ? tag.workOrder.roofType
                    ? " | " + tag.workOrder.roofType.join(", ")
                    : " | " + tag.workOrder.installationMethod
                  : ""
              } - [
                    ${
                      tag.workOrder.siding
                        ? tag.workOrder.siding[1]
                        : tag.workOrder.shingle
                        ? tag.workOrder.shingle[1]
                        : tag.workOrder.productLine
                    } | 
                    ${
                      tag.workOrder.siding
                        ? tag.workOrder.siding[2]
                        : tag.workOrder.shingle
                        ? tag.workOrder.shingle[2]
                        : tag.workOrder.color
                    }]`}
            </div>
            <div className={styles.col2} style={{ fontWeight: "bold" }}>
              {tag.supplier
                ? tag.supplier.substring(0, 3).toUpperCase()
                : "N/A"}
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.col1}>
              {`${tag.workOrder.projectAddressStreet}, ${tag.workOrder.projectAddressCity}`}
            </div>
            <div className={styles.col2}>
              {tag.workOrder.projectManagerName}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaterialBoard;
