import React, { useState, useCallback } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateDetail } from "Actions/dataActions";
import Form from "antd/es/form";
import Button from "antd/es/button";
import message from "antd/es/message";
import CopyableContent from "Components/CopyableContent";
import InputPhone from "Components/Inputs/Phone";
import RemoveAutocomplete from "Components/RemoveAutocomplete";
import api from "API";

const Phone = ({ value, _id, setStringToCopy, stringToCopy }) => {
  const dispatch = useDispatch();
  const viewableActivities = useSelector(
    (state) => state.dataState.viewableActivities
  );
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const onFinish = useCallback(
    async (values) => {
      try {
        setLoading(true);
        values._id = _id;
        values.previous = value;

        if (values.phone !== value) {
          const res = await api.post("/update/phone", values);

          dispatch(
            updateDetail({
              viewableActivities,
              key: "phone",
              value: res.data.value,
              activities: res.data.activities,
            })
          );

          message.success("Phone updated");
        }
        unstable_batchedUpdates(() => {
          setLoading(false);
          setEdit(false);
        });
      } catch (err) {
        console.log("err", err);
        message.error("Error updating Phone");
        setLoading(false);
      }
    },
    [_id, value, dispatch, viewableActivities]
  );

  const onFinishFailed = (err) => {
    console.log("err", err);
    message.error("Error updating Phone");
  };

  const toggleEdit = () => {
    setEdit(!edit);
  };

  return (
    <Form
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      initialValues={{ phone: value }}
      style={{ marginBottom: 16 }}
    >
      <RemoveAutocomplete />
      <h4 className="withIcon" style={{ marginBottom: 3 }}>
        Phone
        <CopyableContent
          title="Phone"
          content={value}
          setStringToCopy={setStringToCopy}
          stringToCopy={stringToCopy}
          edit={edit}
        />
      </h4>
      {!edit && (
        <p
          className="editableRow"
          style={{ marginBottom: 0 }}
          onClick={toggleEdit}
        >
          <span>{value}</span>
        </p>
      )}

      {edit && (
        <>
          <Form.Item
            name="phone"
            rules={[
              { required: true, message: "Phone is required" },
              { len: 14, message: "Phone must be 10 digits long" },
            ]}
          >
            <InputPhone
              autoFocus
              autoComplete="edit-detail-field"
              placeholder="Phone"
              id="phone"
            />
          </Form.Item>

          <div className="editControls">
            <Button
              type="default"
              size="small"
              onClick={toggleEdit}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              size="small"
              loading={loading}
              disabled={loading}
            >
              Submit
            </Button>
          </div>
        </>
      )}
    </Form>
  );
};

export default Phone;
